import React, { Component } from "react";
// import { Map, TileLayer, withLeaflet, MapControl } from "react-leaflet";
import MapInfo from "./MapInfo";
import Routing from "./RoutingMachine";
import "../../../../src/assets/css/styleMap.css"
import { 
    Map,
    TileLayer,
    Marker,
    Popup,
    Polyline,
  } from "react-leaflet";
// import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class MapComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: 0,
      lng: 0,
      zoom: 6,
      isMapInit: false 
    };
  }

  componentDidMount() {
    if (this.props.AllCode) {
        this.setState({
            AllCode: this.props.AllCode
        },()=>{
    
        })
    }
    if (this.props.firstlatitide) {
      this.setState({
        lat: this.props.firstlatitide
      },()=>{
  
      })
  }
  if (this.props.firstlongitude) {
    this.setState({
      lng: this.props.firstlongitude
    },()=>{

    })
}

  }
  saveMap = (map) => {
    this.map = map;
    this.setState({ 
      isMapInit: true
    });
  };

  render() {
    
    const { lat, lng, zoom } = this.state;
    const position = [lat, lng];

    return (
        
      <Map center={position} zoom={zoom} ref={this.saveMap}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {this.state.isMapInit && <Routing
         map={this.map}
         AllCode={this.state.AllCode}

         />}
      </Map>
    );
  }
}

export default MapComponent;
