import React, {Component, Fragment} from "react";
import AppLayout from "../../../layout/AppLayout";
import FooterHome from "./FooterHome";
import {
    Button,
    Container,
    CustomInput,
    FormGroup,
    Input,
    Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Select from "react-select";
import IntlMessages from "../../../helpers/IntlMessages";
import OriginDestinationDropdownForAirport from "../../../components/OriginDestinationDropdownForAirport";
import { UncontrolledTooltip } from 'reactstrap';
import {
    COUPON_OFFER_SEND,
    CREATE_BOOKING_API,
    GET_CHARGES_API, GET_COUNTRIES_LIST, GET_COURIER_CHARGES_API, GET_FCL_CONTAINER_DATA,
    GET_LAST_SEARCH_DATA,
    GUEST_CUSTOMER_DATA, MANAGER_OMAN_CITY_LIST_API,
    MANAGER_PICKUPORIGIN_API, MANAGER_PICKUPORIGINPINCODE_API,
    ORIGIN_DESTINATION_MASTER_MANAGER_API,
    PORT_MASTER_MANANGER_API, STORE_BOOKING_API, STORE_GUEST_QUERY_API, STORE_QUERY_API, STORE_QUERY_RATES_API
} from "../../../constants";

import PickUpDropDown from "../../../components/wizard/PickUpDropDown";
import {Field, Form, Formik, isObject} from "formik";
import {
    calculateChargesAccordingtoStructure,calculateChargesAccordingtoStructureIf30DaysAndDiscount,calculateChargesAccordingtoStructureIf15DaysAndDiscount,calculateChargesAccordingtoStructureIf30DaysNoDiscount,calculateChargesAccordingtoStructureIf15DaysNoDiscount,calculateChargesAccordingtoStructureIfDiscount,
    convertDateISO,
    convertDateISOToCustomiseFormat, getBaseCurrency, getCustomerBranchId, getCustomerCurrency,
    getCustomerData,
    getCustomerGStTypeData, getCustomerId,
    getCustomerPosData, getCustomerUserBranchCodeData, getCustomerUserBranchIDData, getCustomerUserBranchNameData,
    getCustomerUserBranchPosData,
    getLoggedInUserEmail,
    parseFloatValue
} from "../../../util/CommonUtility";
import RestClient from "../../../util/RestClient";
import {toast} from "react-toastify";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
    getCharges,
    getCourierChargestError, getCourierCharges,
    getOriginList,
    OpenOfferSection,
    sendBookingRates,
    sendBookingRatesSuccess,
    sendCourierQuery,
    SendHouseData,
    sendQuery,
    sendQueryForStore,
    sendRatesForStore,
    spotRateQueryAdd, getChargestError
} from "../../../redux/advanceSearch/advancSearchAction";
import {CheckOfferCode, loginUser, subscribeNotification} from "../../../redux/auth/actions";
import SimpleReactValidator from "simple-react-validator";
import SignUp from "../../user/signUp";
import ContactDeatils from "./ContactDeatils";
import TagsInput from "react-tagsinput";
import UploadMsdsNew from "../../../components/UploadMsdsNew";
import {toastr} from "react-redux-toastr";
import * as Yup from "yup";
import ShipperConsigneeModal from "./addShipperAndConsignee";
import {PAGE_PATHS} from "../../../util/PageRoute";
import RateNotFoundCard from "./RateNotFoundCard";
import AOS from "aos";
import "aos/dist/aos.css";
import CityMasterListing from "../../../components/CityMasterListing";
import CourierRatesCard from "../../../components/cards/CourierRatesCard";
import CourierShipperConsigneeModal from "./addCourierShipperAndConsignee";
import CountryDropDown from "../../../components/countryDropDown";
import RateChargesNotavilbale from "./RateChargesNotavilbale";
import {Link} from "react-router-dom";
import LeafletMap from "./LeafletMap";
import CouponSendMail from "./CouponSendMail";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChevronLeft, faChevronRight, faClock} from "@fortawesome/free-solid-svg-icons";



const formSchema = Yup.object().shape({
    length: Yup.number("Only Number").required("Required"),
    width: Yup.number().required("Required"),
    height: Yup.number().required("Required"),
    pieces: Yup.number().required("Required")
});
class courierNewQuery extends Component {
    MAX_LENGTH_CM = 318;
    MAX_WIDTH_CM = 224;
    MAX_HEIGHT_CM = 168;
    MAX_LENGTH_INCH = 125.197;
    MAX_WIDTH_INCH = 88.189;
    MAX_HEIGHT_INCH = 64.1732;
    MAX_LENGTH_MM = 3180;
    MAX_WIDTH_MM = 2240;
    MAX_HEIGHT_MM = 1680;
    MAX_LENGTH_MTR = 3.18;
    MAX_WIDTH_MTR = 2.24;
    MAX_HEIGHT_MTR = 1.63;

    MAX_LENGTH_FT = 10.4331;
    MAX_WIDTH_FT = 7.34908;
    MAX_HEIGHT_FT = 5.51181;
    currentEnteredData = {totalPieces: 0, totalVolume: 0, totalGross: 0, totalChargeable: 0, totalDensity: 0};
    unNumberCalculation = [];
    TotalContainer = [];
    TotalContainerWeight = [];
    TotalContainerCbm = [];
    constructor(props) {
        super(props);
        this.myDivCharges = React.createRef()
        this.state = {
            shipmentMode:"LCL",
            courierMode:"Document",
            firstCourierStatus:true,
            ShowFirsttab:true,
            termsUse:false,
            ChargeRateNotFoundModel:false,
            rateStoreForGuest: false,
            ContainerList:[],
            contactLarge:false,
            activityType: "Door To Door",
            tarrifMode:"General Cargo",
            queryFor: "Courier",
            queryType: "Courier",
            temperature: "Normal Temp",
            movementType:"Door Pick Up",
            TotalChargeable: "",
            origin: {
                airport: {
                    originId: "",
                    originCode: "",
                    originName: ""
                },
                door: {
                    originPincode: "",
                    originAddressLine1: "",
                    originAddressLine2: "",
                    originCity: "",
                    originstate: "",
                    originCountry: ""
                }
            },
            destination: {
                airport: {
                    destinationId: "",
                    destinationCode: "",
                    destinationName: ""
                },
                door: {
                    destinationPincode: "",
                    destinationAddressLine1: "",
                    destinationAddressLine2: "",
                    destinationCity: "",
                    destinationstate: "",
                    destinationCountry: ""
                }
            },
            OriginAirportValue: "",
            originId: "",
            mainQueryData:{},
            customerId:"",
            financePerson: "",
            baseCurrency: "",
            customerCurrency: "",
            customer: {
                customerId: "",
                customerBranchId: "",
                customerName: ""
            },
            customerBranchId: "",
            csBuddy: "",
            csBuddyName: "",
            pricingHead: "",
            pricingHeadName: "",
            salesPerson: "",
            salesPersonName: "",
            clearenceDate:"yyyy-mm-dd",
            pickUpDate:"yyyy-mm-dd",
            grossWeight: 0,
            volumeWeight: 0,
            chargeableWeight: 0,
            uploadedDimensions: [],
            customerMails: [],
            selectedDimensionType: "CM",
            allowedEdit: true,
            additionalService: [
                {
                    "value": "Air Freight",
                    "label": "Air Freight"
                }
            ],
            IncoTerms: [],
            targetRate: "",
            packagingType: "",
            preferredAirlines: [],
            stackable: "true",
            tiltable: "true",
            rateType: "Detailed",
            remarks: "",
            attachments: [],
            shipmentType: "Prepaid",
            queryEnteredBy: "",
            allSlabRate: "false",
            isContractRate: false,
            contractRateId: "",
            allIn: "",
            serviceType: "Domestic",
            customerAckMails: [],
            emailsDropdown: false,
            iataEmailsDropdown: false,
            guestLogin: false,
            emailsSelect: [],
            iataEmailsSelect: [],
            tagsEmailSearch: [],
            iataTagsEmailSearch: [],
            tags: [],
            iataTags: [],
            queryEmails: "",
            iataQueryEmails: "",
            contactEmail: "",
            contactNumber: "",
            showEmails: false,
            iataShowEmails: false,
            contratcedratenumber: "",
            items: [],
            animals: "",
            isCheckedTerms: "",
            commodity: {},
            revertData:"",
            shipperConsigneeData: {},
            dimention: {
                dimentionType: "",
                dimentions: []
            },
            allCharges: [],
            allChargesData:[],
            modalLarge: false,
            shipperModel: false,
            rateSuccessResponce: "",
            queryNo: "",
            containerType: "",
            pickUpDetails:false,
            dropOffDetails:false,
            firstTabs:true,
            showDropDetails:false,
            packagingDetails:false,
            searchButtonShow:false,
            courierTabOpen:false,
            returnAddresses: true,
            totalPieces : "1",
            couponDetails:false,
            contactCompulsorySubmit:false,

        };
        this.mandatoryValidations = new SimpleReactValidator({
            autoForceUpdate: this
        });
        this.myDivFocus = React.createRef();
    }


    componentDidMount() {
        AOS.init({
            duration: 600,
            // easing: 'ease-in-sine',
            delay: 100,
        });
        if ((localStorage.getItem('queryData')) !== null && (localStorage.getItem('queryData')).length > 0) {
            let queryData = JSON.parse(localStorage.getItem('queryData'))
            this.setState({mainQueryData: queryData})
        }
        this.getDummyCustomerData().then()
        this.getCountryData().then()
    }

componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.allCharges && this.props.allCharges !== prevProps.allCharges) {
        this.setState({allCharges: this.props.allCharges}, () => {
            // this.handleOnChargesget();
            if (this.state.rateStoreForGuest === true && this.state.GuestQueryNo !== undefined) {
                let shipmentMode = this.props.queryDataForcard && this.props.queryDataForcard.shipmentMode ? this.props.queryDataForcard.shipmentMode : ""
                let obj = {
                    quoteId: this.state.GuestQueryNo,
                    revertData: this.state.allCharges,
                    shipmentMode: shipmentMode
                }
                if (shipmentMode !== "") {
                    // this.props.sendRatesForStore(obj)
                }

            }
            localStorage.setItem('AllCharges', JSON.stringify(this.props.allCharges))


        })
    }
    if (this.props.OpenPerForma && this.props.OpenPerForma !== prevProps.OpenPerForma) {
        if (this.props.BookingMassage) {
            let bookingid = this.props.BookingDataFromRevert && this.props.BookingDataFromRevert._id ? this.props.BookingDataFromRevert._id : ""
            if (this.props.OpenPerForma === "Added Successfully") {
                localStorage.removeItem("selectedCharge");
                this.setState({allCharges: [], rateSuccessResponce: this.props.BookingDataFromRevert}, () => {
                        this.sendtoFinalBooking()
                })
            }
        }
        if (this.props.BookingMassage && this.props.BookingMassage.success === false) {
            this.setState({RateNotFoundModel: true})
        }
        this.props.sendBookingRatesSuccess({result: "", message: ""})

    }
    if (this.props.RateNotFound && this.props.RateNotFound !== prevProps.RateNotFound) {
        this.setState({ChargeRateNotFoundModel: !this.state.ChargeRateNotFoundModel, StoredRateData: [], allCharges: []})
    }
    if (this.props.queryNo && this.props.queryNo !== prevProps.queryNo) {
        let queryNoId = this.props.queryNo && this.props.queryNo._id ? this.props.queryNo._id : ""
        let ShipperConsigneeAlreadyStoreData = this.props.queryNo && this.props.queryNo.shipperConsigneeData ? this.props.queryNo.shipperConsigneeData : ""
        this.setState({queryNo: queryNoId})

        localStorage.setItem('queryNo', JSON.stringify(queryNoId))

        let containerTypeData = this.state.containerType;

        if (this.state.withLogin === true) {
            let obj = {
                quoteId: queryNoId,
                revertData: this.state.allCharges,
                shipmentMode: this.state.shipmentMode
            }
            if (this.state.shipmentMode !== "") {
                    this.props.sendRatesForStore(obj)
            }

        } else {
            let discount = localStorage.getItem('discountGiven')
            if(discount){
                let discountCharges = localStorage.getItem('afterDiscountCharges')
                discountCharges = JSON.parse(discountCharges)
                this.state.allCharges = discountCharges
            }
            let rateObj = {
                quoteId: queryNoId,
                revertData: this.state.allCharges,
                shipmentMode: this.state.shipmentMode
            }
            this.setState({quoteIdForVess: queryNoId})
            this.StoreRatesApi(rateObj).then(responce => {

                if (responce.success) {
                    let savedRate = responce && responce.result ? responce.result : undefined;
                    if (savedRate !== undefined) {

                        let chargesData = JSON.parse(localStorage.getItem("selectedCharge"))
                        let selectedCharge = {}
                        if (chargesData !== undefined && chargesData !== "" && chargesData !== null) {

                            savedRate && savedRate.map((item, i) => {

                                if (item.shippingLineId === chargesData.shippingLineId) {
                                    selectedCharge = item;
                                }
                            })
                            let chargesArray = chargesData && chargesData.charges ? chargesData.charges : [];
                            let CustGstType = getCustomerGStTypeData()
                            let Custpos = getCustomerPosData()
                            let branchPos = getCustomerUserBranchPosData()

                            // let salevalue = calculateChargesAccordingtoStructure(chargesArray, CustGstType, Custpos, branchPos)
                            let discountApply = localStorage.getItem("discountGiven")
                            let creditDays = localStorage.getItem("creditDays")
                            // let salevalue = calculateChargesAccordingtoStructure(chargesArray, CustGstType, Custpos, branchPos)
                            let saleData = []
                            if(discountApply){
                                saleData =calculateChargesAccordingtoStructureIfDiscount(chargesArray,CustGstType,Custpos,branchPos)
                            }
                            if(creditDays == '15 Days' && !discountApply){
                                saleData =calculateChargesAccordingtoStructureIf15DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
                            }
                            if(creditDays == "30 Days" && !discountApply){
                                saleData =calculateChargesAccordingtoStructureIf30DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
                            }
                            if(creditDays == '15 Days' && discountApply){
                                saleData =calculateChargesAccordingtoStructureIf15DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
                            }
                            if(creditDays == '30 Days' && discountApply){
                                saleData =calculateChargesAccordingtoStructureIf30DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
                            }
                            if(creditDays != "15 Days" && creditDays != '30 Days' && !discountApply){
                                saleData =calculateChargesAccordingtoStructure(chargesArray,CustGstType,Custpos,branchPos)
                            }
                            let salevalue = saleData
                            let Dataobj = {
                                quoteId: queryNoId,
                                houses: this.state.houses,
                                rateId: selectedCharge && selectedCharge._id ? selectedCharge._id : "",
                                remarks: "",
                                otherRemarks: "",
                                customer: getCustomerData(),
                                branchId: getCustomerUserBranchIDData(),
                                branchName: getCustomerUserBranchNameData(),
                                branchCode: getCustomerUserBranchCodeData(),
                                branchPos: getCustomerUserBranchPosData(),
                                reason: "",
                                buyValue: [],
                                saleValue: salevalue ? salevalue : [],
                                otherIncentive: [],
                                otherShipment: [],
                                gstType: getCustomerGStTypeData() === "O-OVERSEAS" ? "IGST" : "",
                                revertData: chargesData,
                                remainingCharges: this.state.remainingCharges,
                                shipperConsigneeData: ShipperConsigneeAlreadyStoreData,
                                NoOfContainer: this.state.NoOfContainer,
                                totalGross: this.state.totalGross,
                                totalVolume: this.state.totalVolume,
                                activityType: this.state.activityType,
                                incoTermsId: this.state.incoTermsId,
                                incoTermsName: this.state.incoTermsName,
                                containerType: containerTypeData,

                            }
                            this.setState({BookRateData: Dataobj}, () => {
                                this.props.sendBookingRates(Dataobj)
                            })

                        }
                    }

                }

            })

        }


    }
    if (this.props.messageShowShipper && this.props.messageShowShipper !== prevProps.messageShowShipper) {
        if (this.props.messageShowShipper && localStorage.getItem("ShowShipperAndConsignee") !== null && localStorage.getItem("ShowShipperAndConsignee") === "true") {
            this.setState({shipperModel: true},()=>{})
        }
    }
    if (this.props.StoredRateData !== undefined && this.props.StoredRateData !== prevProps.StoredRateData) {
        this.setState({StoredRateData: this.props.StoredRateData})
    }
    if (this.props.queryDataForcard && this.props.queryDataForcard !== prevProps.queryDataForcard) {
        this.setState({queryDataForcard: this.props.queryDataForcard}, () => {
            let shipmentMode = this.state.queryDataForcard && this.state.queryDataForcard.shipmentMode ? this.state.queryDataForcard.shipmentMode : ""

            this.setState({shipmentMode: shipmentMode})
            if (this.state.queryNo !== "") {
                let obj = {
                    quoteId: this.state.queryNo,
                    revertData: this.state.allCharges,
                    shipmentMode: shipmentMode
                }
                this.props.sendRatesForStore(obj)
            }

        })
    }
}

    getDummyCustomerData = async () => {
        this.GetGuestCustomerData().then(responce => {
            let customerData = responce && responce.result ? responce.result : undefined;
            if (customerData !== undefined) {
                let customerId = customerData.customerData && customerData.customerData._id ? customerData.customerData._id : "";
                let customerEmail = customerData.customerBranchData && customerData.customerBranchData.emails ? customerData.customerBranchData.emails : "";
                let baseCurrency = customerData.csBuddyBranchData && customerData.csBuddyBranchData.currency ? customerData.csBuddyBranchData.currency : "";
                let customerCurrency = customerData.customerData && customerData.customerData.custCurrency ? customerData.customerData.custCurrency : "";
                let customerBranchId = customerData.customerBranchData && customerData.customerBranchData._id ? customerData.customerBranchData._id : null;
                let csBuddy = customerData && customerData.customerBranchData && customerData.customerBranchData.csBuddy ? customerData.customerBranchData.csBuddy :customerData && customerData.customerBranchData && customerData.customerBranchData.csBuddy2 ? customerData.customerBranchData.csBuddy2:"";
                let pricingHead = customerData && customerData.customerBranchData && customerData.customerBranchData.pricingHead ? customerData.customerBranchData.pricingHead : "";
                let salesPerson = customerData && customerData.customerBranchData && customerData.customerBranchData.salesPerson ? customerData.customerBranchData.salesPerson : "";
                let financePerson = customerData && customerData.customerBranchData && customerData.customerBranchData.financePerson ? customerData.customerBranchData.financePerson : "";
                let UserBranchId = customerData && customerData.csBuddyData && customerData.csBuddyData.branchId && customerData.csBuddyData.branchId._id ? customerData.csBuddyData.branchId._id : "";

                this.setState({
                    customerId: customerId,
                    baseCurrency: baseCurrency,
                    customerCurrency: customerCurrency,
                    customerBranchId: customerBranchId,
                    csBuddy: csBuddy,
                    pricingHead: pricingHead,
                    salesPerson: salesPerson,
                    financePerson: financePerson,
                    UserBranchId: UserBranchId
                })

            }


        })

    };
    StoreRatesApi = async (data) => {
        return await RestClient({
            url: STORE_QUERY_RATES_API,
            method: "POST",
            data: data
        });
    };
    GetGuestCustomerData = async (dataForServer) => {
        return await RestClient({
            url: GUEST_CUSTOMER_DATA,
            method: "POST",
            data: dataForServer
        })
    }

    checkFrightType = () => {

        if (isObject(this.state.OriginAirportValue) && JSON.stringify(this.state.OriginAirportValue).length > 0 && isObject(this.state.destinationAirportValue) && JSON.stringify(this.state.destinationAirportValue).length > 0) {
            try {
                if (
                    this.state.OriginAirportValue.countryName.toLowerCase() ===
                    this.state.destinationAirportValue.countryName.toLowerCase()
                ) {
                    this.setState(
                        {
                            queryType: "Air Domestic"
                        },
                        () => {

                            // this.toggleCountryCheck();
                        }
                    );
                } else if (
                    this.state.OriginAirportValue.countryName.toLowerCase() !== "india" &&
                    this.state.destinationAirportValue.countryName.toLowerCase() === "india"
                ) {
                    this.setState(
                        {
                            queryType: "Air Import"
                        },
                        () => {

                            // this.toggleCountryCheck();
                        }
                    );
                } else if (
                    this.state.OriginAirportValue.countryName.toLowerCase() !== "india" &&
                    this.state.destinationAirportValue.countryName.toLowerCase() !== "india"
                ) {
                    this.setState(
                        {
                            queryType: "Air Third Country"
                        },
                        () => {

                        }
                    );
                } else {
                    this.setState({
                        queryType: "Courier"
                    });
                }
            } catch (e) {
            }
        }
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value,
            clearanceDate: e.target.value,},()=>{

        });
    };
    handlePickDate = (e) =>{
        this.setState({[e.target.name]: e.target.value,
            pickUpDate: e.target.value,},()=>{

        });
    }

    handleChangeRe = (e) => {
        this.setState({[e.target.name]: e.target.value,
            weight: this.calculateOverallDimensionData(),
            dimensionArray: this.state.uploadedDimensions,
            selectedDimensionType: this.state.selectedDimensionType,}, () => {
            this.calculateChargeable()

        },()=>{
        });
    };
    handleChangeShipment = (e) => {
        this.setState({[e.target.name]: e.target.value,})}
    handleChangeGross = (e) => {
        let key = e.target.name
        let keyValue = e.target.value
        this.setState({[key]: keyValue}, () => {
            let grossWeightLbs = this.state.grossWeightLbs
            let grossWeight = this.state.grossWeight
            if (key === "grossWeightLbs") {
                grossWeight = parseFloatValue(grossWeightLbs * 0.453592)
                this.setState({grossWeight: grossWeight,
                    weight: this.calculateOverallDimensionData(),
                    dimensionArray: this.state.uploadedDimensions,
                    selectedDimensionType: this.state.selectedDimensionType,},()=>{
                    this.calculateChargeable()
                })
            } else {
                grossWeightLbs= parseFloatValue(grossWeight*2.20462)
                this.setState({grossWeightLbs:grossWeightLbs,
                    weight: this.calculateOverallDimensionData(),
                    dimensionArray: this.state.uploadedDimensions,
                    selectedDimensionType: this.state.selectedDimensionType,},()=>{
                    this.calculateChargeable()
                })
            }
        });
    };



    calculateChargeable = async ()=> {

        try {
            if(this.state.uploadedDimensions.length >0)
            {
                if (this.state.volumeWeight && this.state.grossWeight)
                {
                    let volumeWeight = parseFloat(this.state.volumeWeight).toFixed(2);
                    let grossWeight = parseFloat(this.state.grossWeight).toFixed(2);
                    volumeWeight > grossWeight
                        ?
                        this.setState({
                            TotalChargeable: volumeWeight,
                            unit: "Kg"
                        }, () => {

                            if (this.state.TotalChargeable === "0") {
                                this.setState({
                                    calculatedDensity: "0"
                                });
                            } else {
                                let density = (grossWeight / grossWeight) * 100;
                                this.setState({
                                    calculatedDensity: parseFloat(density).toFixed(2)
                                });
                            }

                        })
                        : this.setState({
                            TotalChargeable: grossWeight,
                            unit: "Kg"
                        }, () => {
                            if (this.state.TotalChargeable === "0") {
                                this.setState({
                                    calculatedDensity: "0"
                                });
                            } else {
                                let density = (volumeWeight / grossWeight) * 100;
                                this.setState({
                                    calculatedDensity: parseFloat(density).toFixed(2)
                                });
                            }
                        });


                }
            }
            else
            {
                if (this.state.grossWeight) {
                    let volumeWeight = parseFloat(this.state.grossWeight).toFixed(2);
                    let grossWeight = parseFloatValue(this.state.grossWeight).toFixed(2);
                    volumeWeight > grossWeight
                        ?
                        this.setState({
                            TotalChargeable: volumeWeight,
                            unit: "Kg"
                        }, () => {

                            if (this.state.TotalChargeable === "0") {
                                this.setState({
                                    calculatedDensity: "0"
                                });
                            } else {
                                let density = (grossWeight / grossWeight) * 100;
                                this.setState({
                                    calculatedDensity: parseFloat(density).toFixed(2)
                                });
                            }

                        })
                        : this.setState({
                            TotalChargeable: grossWeight,
                            unit: "Kg"
                        }, () => {
                            if (this.state.TotalChargeable === "0") {
                                this.setState({
                                    calculatedDensity: "0"
                                });
                            } else {
                                let density = (volumeWeight / grossWeight) * 100;
                                this.setState({
                                    calculatedDensity: parseFloat(density).toFixed(2)
                                });
                            }
                        });


                }
            }

        } catch (e) {
        }
    };

    addNewDimension = async values => {

        if (values.length && values.width && values.height && values.pieces && this.state.selectWeight && values.gw_pc) {
            values.selectWeight = this.state.selectWeight

            await this.validateMaxDimensions(values);
            let dimensions = Array.isArray(this.state.uploadedDimensions) && this.state.uploadedDimensions.length >= 1 ? this.state.uploadedDimensions : [];
            dimensions.push(values);
            this.setState({uploadedDimensions: dimensions,
                // length:0,
                // width: "",
                // height: "",
                // pieces: "",
                // selectWeight : "",
                // gw_pc:"",
                weight: this.calculateOverallDimensionData(),
                dimensionArray: this.state.uploadedDimensions,
                selectedDimensionType: this.state.selectedDimensionType,});
                this.calculateOverallDimensionData()

        }
    }
    validateMaxDimensions = values => {
        if (this.state.selectedTariff === "oddDimensions") {
            return true;
        }
        let isValid = false;
        let length = values.length;
        let width = values.width;
        let height = values.height;

        if (this.state.selectedDimensionType === "CM") {
            if (
                length <= this.MAX_LENGTH_CM &&
                width <= this.MAX_WIDTH_CM &&
                height <= this.MAX_HEIGHT_CM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "FT") {
            if (
                length <= this.MAX_LENGTH_FT &&
                width <= this.MAX_WIDTH_FT &&
                height <= this.MAX_HEIGHT_FT
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "MM") {
            if (
                length <= this.MAX_LENGTH_MM &&
                width <= this.MAX_WIDTH_MM &&
                height <= this.MAX_HEIGHT_MM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "INCHES") {
            if (
                length <= this.MAX_LENGTH_INCH &&
                width <= this.MAX_WIDTH_INCH &&
                height <= this.MAX_HEIGHT_INCH
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "METERS") {
            if (
                length <= this.MAX_LENGTH_MTR &&
                width <= this.MAX_WIDTH_MTR &&
                height <= this.MAX_HEIGHT_MTR
            ) {
                isValid = true;
            }
        }
        if (isValid === false) {
            toastr.success(
                "Dimension Exceed Values ",
                "Tariff Mode Changed to Odd Dimension ",
                {
                    transitionIn: "bounceIn",
                    transitionOut: "bounceOut"
                }
            );
            // this.changeTariffModeTo("Odd Dimention");
        }

        return isValid;
    };
    calculateOverallDimensionData() {

        let uploadedDimensions = this.state.uploadedDimensions;
        let totalVolume = this.state.volumeWeight
        let totalGross = this.state.grossWeight
        let totalGrossLbs = this.state.grossWeightLbs
        let totalPieces = (this.state.totalPieces)

        if (uploadedDimensions.length > 0) {
            totalVolume = 0
            totalGross = 0
            totalPieces = 0
        }
        for (let i = 0; i < uploadedDimensions.length; i++) {
            let vol = this.calculateVolume(uploadedDimensions[i])
            if (uploadedDimensions[i].selectWeight === "Lbs" && uploadedDimensions[i].converetd===undefined) {
                uploadedDimensions[i].gw_pc = parseFloatValue(uploadedDimensions[i].gw_pc) * 0.453592
                uploadedDimensions[i].selectWeight = "Kgs"
                uploadedDimensions[i].converetd = true
            }
            let grossWeight = parseFloatValue(uploadedDimensions[i].gw_pc) * parseInt(uploadedDimensions[i].pieces)
            totalVolume = parseFloatValue(totalVolume + vol)
            totalGross = parseFloatValue(parseFloatValue(totalGross) + grossWeight);
            //totalGrossLbs = parseFloatValue(totalGross) * 0.453592
            totalGrossLbs = parseFloatValue(totalGross) * 2.20462;
            totalPieces = parseInt(totalPieces + parseInt(uploadedDimensions[i].pieces));
        }
        this.setState({uploadedDimensions})

        if (uploadedDimensions.length > 0) {
            this.currentEnteredData.totalVolume = totalVolume
            this.currentEnteredData.totalGross = totalGross
            this.currentEnteredData.totalGrossLbs = totalGrossLbs
            this.currentEnteredData.totalPieces = totalPieces
        } else {
            this.currentEnteredData.totalVolume = totalVolume
            this.currentEnteredData.totalGross = this.state.grossWeight
            this.currentEnteredData.totalGrossLbs = this.state.grossWeightLbs
            this.currentEnteredData.totalPieces = totalPieces
        }

        try {
            if(this.state.queryFor !=="Air")
            {
                let volumeWeight = parseFloatValue(this.currentEnteredData.totalVolume);
                let grossWeight = parseFloatValue(this.currentEnteredData.totalGross) / 1000;
                let lclvol = 0;

                if (volumeWeight < 1)
                {lclvol = 1}
                else
                {lclvol = volumeWeight}
                if(this.state.shipmentMode==="LCL")
                {


                    grossWeight > lclvol ?
                        this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                        :
                        this.currentEnteredData.totalChargeable = parseInt(lclvol)
                }
                else
                {
                    grossWeight > volumeWeight ?
                        this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                        :
                        this.currentEnteredData.totalChargeable = parseInt(volumeWeight)
                }


                if (this.currentEnteredData.totalGross === "0") {
                    this.currentEnteredData.totalDensity = "0"
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.currentEnteredData.totalDensity = parseFloat(density).toFixed(2)
                }
            }
            else
            {
                let volumeWeight = parseFloatValue(this.currentEnteredData.totalVolume);
                let grossWeight = parseFloatValue(this.currentEnteredData.totalGross) ;

                grossWeight > volumeWeight ?
                    this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                    :
                    this.currentEnteredData.totalChargeable = parseInt(volumeWeight)



                if (this.currentEnteredData.totalGross === "0") {
                    this.currentEnteredData.totalDensity = "0"
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.currentEnteredData.totalDensity = parseFloat(density).toFixed(2)
                }
            }


            // }
            //   debugger
        } catch (e) {
        }

        this.setState({
            grossWeight: parseFloatValue(this.currentEnteredData.totalGross),
            grossWeightLbs: parseFloatValue(this.currentEnteredData.totalGrossLbs),
            volumeWeight: parseFloatValue(this.currentEnteredData.totalVolume).toFixed(2),
            chargeableWeight: parseFloatValue(this.currentEnteredData.totalChargeable),
            calculatedDensity: parseFloatValue(this.currentEnteredData.totalDensity),
        },()=>{

        })
        return this.currentEnteredData;

    }

    calculateVolume = values => {
        let length = parseInt(values.length);
        let width = parseInt(values.width);
        let height = parseInt(values.height);
        let pieceCount = parseInt(values.pieces);
        if(this.state.queryFor !== "Air")
        {
            if (this.state.selectedDimensionType === "CM") {
                return (length * width * height * pieceCount) / 1000000;
            } else if (this.state.selectedDimensionType === "FT") {
                return (length * width * height * pieceCount) / 0.211888;
            } else if (this.state.selectedDimensionType === "MM") {
                return (length * width * height * pieceCount) / 1000000000;
            } else if (this.state.selectedDimensionType === "INCHES") {
                return (length * width * height * pieceCount) / 61023.8;
            } else if (this.state.selectedDimensionType === "METERS") {
                return (length * width * height * pieceCount);
            }
        }
        else
        {
            if (this.state.selectedDimensionType === "CM") {
                return (length * width * height * pieceCount) / 6000;
            } else if (this.state.selectedDimensionType === "FT") {
                return (length * width * height * pieceCount) / 0.211888;
            } else if (this.state.selectedDimensionType === "MM") {
                return (length * width * height * pieceCount) / 6000000;
            } else if (this.state.selectedDimensionType === "INCHES") {
                return (length * width * height * pieceCount) / 366;
            } else if (this.state.selectedDimensionType === "METERS") {
                return (length * width * height * pieceCount) / 0.006;
            }
        }

    };


    DomesticTab = () =>{
        this.setState({
            courierTabOpen : !this.state.courierTabOpen,
            firstTabs:false,
            serviceType:"Domestic",
            firstCourierStatus:false,
        },()=>{
            if(this.myDivFocus.current){
                this.myDivFocus.current.scrollIntoView({
                    behavior: "smooth",
                    block: 'center',
                    inline: 'center'

                })
            }
        })
    }


    InternationalTab = () =>{
        this.setState({
            courierTabOpen : !this.state.courierTabOpen,
            firstTabs:false,
            serviceType:"International",
            firstCourierStatus:false,
        },()=>{
            if(this.myDivFocus.current){
                this.myDivFocus.current.scrollIntoView({
                    behavior: "smooth",
                    block: 'center',
                    inline: 'center'

                })
            }
        })
    }
    serviceTypeAfter = () =>{
        if(this.state.serviceType !== ""){
            this.setState({
                courierTabOpen :true,
                firstCourierStatus:false,
            })
        }
    }
    courierModeDocument = () =>{
        this.setState({
            movementTabOpen : !this.state.movementTabOpen,
            courierMode:"Document",
            courierTabOpen:false,

        },()=>{
            if(this.myDivFocus.current){
                this.myDivFocus.current.scrollIntoView({
                    behavior: "smooth",
                    block: 'center',
                    inline: 'center'

                })
            }
        })
    }

    courierModeNonDocument = () =>{
        this.setState({
            movementTabOpen : !this.state.movementTabOpen,
            courierMode:"Parcel",
            courierTabOpen:false,
        },()=>{
            if(this.myDivFocus.current){
                this.myDivFocus.current.scrollIntoView({
                    behavior: "smooth",
                    block: 'center',
                    inline: 'center'

                })
            }
        })
    }

    movementTypePick = () =>{
        this.setState({
            pickUpDetails : !this.state.pickUpDetails,
            firstTabs:false,
            movementType:"Door Pick Up",
            movementTabOpen:false,
        },()=>{
            if(this.myDivFocus.current){
                this.myDivFocus.current.scrollIntoView({
                    behavior: "smooth",
                    block: 'center',
                    inline: 'center'

                })
            }
        })
    }
    movementTypeDrop = () =>{
        this.setState({
            dropOffDetails : !this.state.dropOffDetails,
            firstTabs:false,
            movementType:"Depots Drop Off",
            movementTabOpen:false,
        },()=>{
            if(this.myDivFocus.current){
                this.myDivFocus.current.scrollIntoView({
                    behavior: "smooth",
                    block: 'center',
                    inline: 'center'

                })
            }
        })
    }
    MovementTypeAfter = () =>{
        if(this.state.movementType === "Door Pick Up"){
            this.setState({
                pickUpDetails:true,
                movementTabOpen:false,
            })
        }else{
            this.setState({
                dropOffDetails:true,
                movementTabOpen:false,
            })
        }

    }

    PickUpPrevious = ()=>{
        this.setState({
            movementTabOpen:true,
            pickUpDetails:false,
            dropOffDetails:false,
        })
    }
    DropToPrevious = () =>{
        if(this.state.movementType==="Door Pick Up"){
            this.setState({
                showDropDetails:false,
                pickUpDetails:true,
                dropOffDetails:false,
            })
        }else{
            this.setState({
                showDropDetails:false,
                pickUpDetails:false,
                dropOffDetails:true,
            })
        }
    }
    PickUpNext = ()=>{
        let valid =true
            if(this.state.serviceType === "Domestic")
            {
                if (this.state.pinCode == "" || this.state.pinCode == undefined || this.state.clearanceDate == "" || this.state.clearanceDate==undefined) {
                    valid=false;
                    toast.error("Please Select PinCode and Date", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }else{
                    this.setState({
                        showDropDetails:!this.state.showDropDetails,
                        pickUpDetails:false,
                        dropOffDetails:false,

                    })
                }
            }else if(this.state.serviceType === "International"){
                if(this.state.pickUpCountryId === "" || this.state.pickUpCountryId === undefined || this.state.clearanceDate == "" || this.state.clearanceDate==undefined){
                    valid=false;
                    toast.error("Please Select Country and date", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }else{
                    this.setState({
                        showDropDetails:!this.state.showDropDetails,
                        pickUpDetails:false,
                        dropOffDetails:false,

                    })
                }
                if(this.state.pickupCountryName === "India"){
                    if (this.state.pinCode == "" || this.state.pinCode == undefined || this.state.clearanceDate == "" || this.state.clearanceDate==undefined) {
                        valid=false;
                        toast.error("Please Select PinCode and date", {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }else{
                        this.setState({
                            showDropDetails:!this.state.showDropDetails,
                            pickUpDetails:false,
                            dropOffDetails:false,

                        })
                    }
                }
            }


        return valid;

    }

    movementPrevious = () =>{
        this.setState({
            courierTabOpen:true,
            movementTabOpen:false,
        })

    }
    courierSectionAfter = () =>{
        this.setState({
            movementTabOpen : true,
            courierTabOpen:false,
        })
    }
    courierModePrevious = () =>{
        this.setState({
            courierTabOpen:false,
            firstCourierStatus:true,
        })
    }
    PackageDetails = () =>{
        let valid =true
        if(this.state.serviceType === "Domestic")
        {
            if (this.state.pinCode1 == "" || this.state.pinCode1 == undefined) {
                valid=false;
                toast.error("Please Select PinCode ", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                this.setState({
                    showDropDetails:false,
                    pickUpDetails:false,
                    dropOffDetails:false,
                    packagingDetails:true

                })
            }
        }else if(this.state.serviceType === "International"){
            if(this.state.dropUpCountryId === "" || this.state.dropUpCountryId === undefined){
                valid=false;
                toast.error("Please Select Country", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                this.setState({
                    showDropDetails:false,
                    pickUpDetails:false,
                    dropOffDetails:false,
                    packagingDetails:true

                })
            }
            if(this.state.dropUpCountryName === "India"){
                if (this.state.pinCode1 == "" || this.state.pinCode1 == undefined) {
                    valid=false;
                    toast.error("Please Select PinCode ", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }else{
                    this.setState({
                        showDropDetails:false,
                        pickUpDetails:false,
                        dropOffDetails:false,
                        packagingDetails:true

                    })
                }
            }
        }


        return valid;

    }

    searchButton =() =>{

        if(this.state.courierMode==="Parcel"){
            if(this.state.gross === undefined || this.state.gross === "" || this.state.pieces === ""|| this.state.pieces === undefined || this.state.productDeclaredValue === "" || this.state.productDeclaredValue === undefined){
                toast.error("Please Fill all Mandatory Field", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else if(this.state.gross <= 0){
                toast.error("Gross Weight is more then 0", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else if(this.state.pieces <1){
                toast.error("Total Pieces cannot be less than 1", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            else{
                this.setState({
                    packagingDetails:false,
                    searchButtonShow:!this.state.searchButtonShow,
                })
            }
        }else {
            if(this.state.gross === undefined || this.state.gross === "" || this.state.pieces === "" ){
                toast.error("Please Fill all Mandatory Field", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else if(this.state.gross <= 0){
                toast.error("Gross Weight is more then 0", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else if(this.state.pieces <= 0){
                toast.error("Pieces is more then 0", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                this.setState({
                    packagingDetails:false,
                    searchButtonShow:!this.state.searchButtonShow,
                })
            }
        }


    }
    targetDetailsPrevious = () =>{
        this.setState({
            packagingDetails:true,
            searchButtonShow:false,

        })

    }
    targetRateClick = () =>{
        this.setState({
            targetClick :!this.state.targetClick,

        })
    }
    targetRateNextFunc = () =>{
        this.setState({
            targetClickNext :!this.state.targetClickNext,
            searchButtonShow:false,
        })
    }
    shipmentDetailsPrevious =() =>{
        this.setState({
            courierTabOpen:false,
            showDropDetails:true,
            packagingDetails:false

        })
    }

    goToHome = () =>{
        this.props.history.push(PAGE_PATHS.MAIN_PAGE);
    }


    termsOfUse = (e) => {
        this.setState({[e.target.name]: e.target.value,
        termsUse:!this.state.termsUse})}


    handleSubmit = () => {
        let baseCurrency = this.state.baseCurrency;
        let customerId = this.state.customerId;
        let customerCurrency = this.state.customerCurrency;
        let customerBranchId = this.state.customerBranchId;
        let customerUserBranchId = this.state.UserBranchId;

        let csBuddy = "";
        let pricingHead = "";
        let salesPerson = "";
        let financePerson = "";

        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            csBuddy = userData && userData.result && userData.result.csBuddyData && userData.result.csBuddyData._id ? userData.result.csBuddyData._id : "";
            pricingHead = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.pricingHead ? userData.result.customerBranchData.pricingHead : "";
            salesPerson = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.salesPerson ? userData.result.customerBranchData.salesPerson : "";
            financePerson = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.financePerson ? userData.result.customerBranchData.financePerson : "";

        }
        if (csBuddy === "" || csBuddy === undefined) {
            csBuddy = this.state.csBuddy
        }
        if (pricingHead === "" || pricingHead === undefined) {
            pricingHead = this.state.pricingHead
        }
        if (salesPerson === "" || salesPerson === undefined) {
            salesPerson = this.state.salesPerson
        }
        if (financePerson === "" || financePerson === undefined) {
            financePerson = this.state.financePerson
        }



        if (this.mandatoryValidations.allValid() ) {
            let mainDataFortest = {}
            mainDataFortest.originId = this.state.OriginAirportValue ? this.state.OriginAirportValue.value : "";
            mainDataFortest.destinationId = this.state.destinationAirportValue ? this.state.destinationAirportValue.value : "";
            mainDataFortest.chargeableWeight = this.state.gross;
            mainDataFortest.weight = {};
            mainDataFortest.weight.gross = this.state.gross;
            mainDataFortest.productDeclaredValue=this.state.productDeclaredValue;
            mainDataFortest.weight.volume = this.state.volume;
            mainDataFortest.weight.chargeable = this.state.TotalChargeable;
            mainDataFortest.tarrifMode = this.state.tarrifMode;
            mainDataFortest.queryType = this.state.queryType;

            mainDataFortest.totalPieces = this.state.courierMode === "Document" ? "1" : this.state.pieces;
            mainDataFortest.density = 100;
            mainDataFortest.clearenceDate = this.state.clearanceDate;
            mainDataFortest.queryType = this.state.queryType;
            mainDataFortest.rateType = this.state.rateType;
            mainDataFortest.shipmentMode = this.state.shipmentMode;
            mainDataFortest.queryFor = this.state.queryFor;
            mainDataFortest.queryFrom = "Customer";
            mainDataFortest.containerType = this.state.containerType;
            mainDataFortest.stuffingType = this.state.stuffingType;
            mainDataFortest.additionalService = this.state.additionalService;
            mainDataFortest.custType = "Shipper";
            mainDataFortest.shipmentFirst = this.props.shipmentFirst;
            mainDataFortest.iecStatus = this.props.iecStatus;
            mainDataFortest.gstStatus = this.props.gstStatus;
            mainDataFortest.chatResponse = this.props.chatResponse;
            mainDataFortest.typeOfCustomer = this.props.typeOfCustomer;
            mainDataFortest.customerType = this.props.customerType;
            mainDataFortest.branchId = customerUserBranchId;
            mainDataFortest.csBuddy = csBuddy;
            mainDataFortest.pricingHead = pricingHead;
            mainDataFortest.salesPerson = salesPerson;
            mainDataFortest.financePerson = financePerson;
            mainDataFortest.customerId = customerId;
            mainDataFortest.courierMode = this.state.courierMode;
            mainDataFortest.movementType = this.state.movementType;
            mainDataFortest.productDeclaredValue = this.state.productDeclaredValue;
            mainDataFortest.customerBranchId = customerBranchId;
            mainDataFortest.customerCurrency = customerCurrency;
            mainDataFortest.baseCurrency = baseCurrency;
            mainDataFortest.fromPincode = this.state.pinCode?this.state.pinCode:this.state.pickupCountryName
            mainDataFortest.fromCityId = this.state.cityId
            mainDataFortest.fromStateId = this.state.stateId
            mainDataFortest.fromCountryId = this.state.serviceType==="Domestic" ? this.state.indiaCountryId : this.state.pickUpCountryId;

            mainDataFortest.toPincode = this.state.pinCode1?this.state.pinCode1:this.state.dropUpCountryName
            mainDataFortest.toCityId = this.state.CityId1
            mainDataFortest.toStateId = this.state.stateId2
            mainDataFortest.toCountryId = this.state.serviceType==="Domestic" ? this.state.indiaCountryId : this.state.dropUpCountryId
            let containerArry = []
            if (this.state.shipmentMode === "FCL") {
                this.state.ContainerList.map((item, i) => {
                    if (item.count !== undefined) {
                        containerArry.push({
                            name: item.container,
                            count: item.count
                        })
                    }

                })
                mainDataFortest.containerType = containerArry;
                mainDataFortest.stuffingType = this.state.stuffingType;

            }
            if (this.state.OtherCommodityCheck === true) {
                mainDataFortest.commodity = this.state.commodity.description;
            } else {
                mainDataFortest.commodity = this.state.commodity.description;
                mainDataFortest.commodityHsnCode = this.state.commodity.hsnCode;
            }
            this.GetRatesQuery(mainDataFortest).then(result => {
                if (result.success && result.message === "Rates Available") {

                    this.setState({couponDetails:true,
                        // contactLarge: true,
                        guestLogin: true,
                        targetClickNext:false})
                } else {
                    this.setState({couponDetails:true,
                        // contactLarge: true,
                        Compalsari: true,
                        guestLogin: true})
                }

            })


            } else {
                toast.error("Please Select your origin and destination for shipment", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

    }

    GetRatesQuery = async (dataForServer) => {
        return await RestClient({
            url: GET_COURIER_CHARGES_API,
            method: "POST",
            data: dataForServer
        });
    }

    handleChangeEmbedded = e => {
        this.setState({
            clearanceDate: e.target.value,
        }, () => {

        });
    };
    handleStoreSubmit = () => {

        if (localStorage.getItem("already_registered") === "true" && !localStorage.getItem("customer") === false && localStorage.getItem("customer").length > 0 || !localStorage.getItem("ContactDeatails") === false) {

            let EmailArry = []
            let containerArry = []
            let customerEmail = getLoggedInUserEmail()
            if (!localStorage.getItem("customer") === true) {
                EmailArry.push(this.state.contactEmail)
            } else {
                EmailArry.push(customerEmail)
            }
            let baseCurrency = getBaseCurrency()
            if (baseCurrency === null) {
                baseCurrency = this.state.baseCurrency
            }

            let customerId = getCustomerId()
            if (!localStorage.getItem("customer") === true) {
                customerId = this.state.customerId
            }

            let customerCurrency = getCustomerCurrency()
            if (customerCurrency === null) {
                customerCurrency = this.state.customerCurrency
            }

            let customerBranchId = getCustomerBranchId()
            if (!localStorage.getItem("customer") === true) {
                customerBranchId = this.state.customerBranchId
            }

            let customerUserBranchId = getCustomerUserBranchIDData()
            if (customerUserBranchId === "") {
                customerUserBranchId = this.state.UserBranchId
            }

            let csBuddy = "";
            let pricingHead = "";
            let salesPerson = "";
            let financePerson = "";
            let gstType = "";
            let gstNo = "";
            let stateCode = "";


            if (!localStorage.getItem("customer") === false) {
                let userData = JSON.parse(localStorage.getItem("customer"))
                csBuddy = userData && userData.result && userData.result.csBuddyData && userData.result.csBuddyData._id ? userData.result.csBuddyData._id : "";
                pricingHead = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.pricingHead ? userData.result.customerBranchData.pricingHead : "";
                salesPerson = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.salesPerson ? userData.result.customerBranchData.salesPerson : "";
                financePerson = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.financePerson ? userData.result.customerBranchData.financePerson : "";
                gstType = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.gstType ? userData.result.customerBranchData.gstType : "";
                gstNo = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.gstNo ? userData.result.customerBranchData.gstNo : "";
                stateCode = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.stateCode ? userData.result.customerBranchData.stateCode : "";

            }
            if (csBuddy === "") {
                csBuddy = this.state.csBuddy
            }

            if (pricingHead === "") {
                pricingHead = this.state.pricingHead
            }
            if (salesPerson === "") {
                salesPerson = this.state.salesPerson
            }
            if (financePerson === "") {
                financePerson = this.state.financePerson
            }
            if (gstType === "") {
                gstType = this.state.gstType
            }
            if (gstNo === "") {
                gstNo = this.state.gstNo
            }
            if (stateCode === "") {
                stateCode = this.state.stateCode
            }

            if (this.mandatoryValidations.allValid()) {
                let mainData = {}

                mainData.originId = this.state.OriginAirportValue ? this.state.OriginAirportValue.value : "";
                mainData.destinationId = this.state.destinationAirportValue ? this.state.destinationAirportValue.value : "";
                mainData.chargeableWeight = this.state.gross;
                mainData.productDeclaredValue = this.state.productDeclaredValue;
                mainData.customerId = customerId;
                mainData.customerBranchId = customerBranchId;
                mainData.customerCurrency = customerCurrency;
                mainData.baseCurrency = baseCurrency;
                mainData.pricingHeadName = "";
                mainData.salesPersonName = "";
                mainData.branchId = customerUserBranchId;
                mainData.csBuddy = csBuddy;
                mainData.pricingHead = pricingHead;
                mainData.salesPerson = salesPerson;
                mainData.financePerson = financePerson;
                mainData.gstType = gstType;
                mainData.stateCode = stateCode;
                mainData.gstNo = gstNo;

                mainData.temperature = "Normal Temp";
                mainData.temperatureDetails = "";
                mainData.dg = [];

                mainData.contactEmail = this.state.contactEmail;
                mainData.contactNumber = this.state.contactNumber;

                let OriginValue = {
                    shipperId: this.state.shipperId,
                    shipperName: this.state.shipperName,
                    shipperBranchName: this.state.shipperBranchName,
                    shipperBranchId: this.state.shipperBranchId,
                    pincode: this.state.pinCode?this.state.pinCode:this.state.pickupCountryName,
                    address: this.state.address,
                    addressLine2: this.state.addressLine2,
                    city: this.state.city,
                    state: "",
                    country: this.state.serviceType==="Domestic" ? this.state.indiaCountryId : this.state.pickUpCountryId
                };
                let DestinationValue = {
                    consigneeId: this.state.consigneeId,
                    consigneeName: this.state.consigneeName,
                    consigneeBranchId: this.state.consigneeBranchId,
                    consigneeBranchName: this.state.consigneeBranchName,
                    pincode: this.state.pinCode1?this.state.pinCode1:this.state.dropUpCountryName,
                    address: this.state.address1,
                    addressLine1: this.state.AddressLine1,
                    city: this.state.city1,
                    state: "",
                    country: this.state.serviceType==="Domestic" ? this.state.indiaCountryId : this.state.dropUpCountryId
                };
                let Shipper = {
                    ReturnAsSame: this.state.returnAddresses,
                    CompanyID: this.state.shipperId,
                    CompanyName: this.state.shipperName,
                    AddressLine1: this.state.address,
                    City: this.state.pickupCountryName,
                    cityId: this.state.pickUpCountryId,
                    Country:  this.state.serviceType==="Domestic" ? this.state.indiaCountryName : this.state.pickupCountryName,
                    countryId:  this.state.serviceType==="Domestic" ? this.state.indiaCountryId : this.state.pickUpCountryId,
                    ZipCode: this.state.pinCode?this.state.pinCode:this.state.pickupCountryName,
                };
                let Consignee = {
                    CompanyID: this.state.consigneeId,
                    CompanyName: this.state.consigneeName,
                    AddressLine1: this.state.address1,
                    City: this.state.dropUpCountryName,
                    cityId: this.state.dropUpCountryId,
                    ZipCode: this.state.pinCode1?this.state.pinCode1:this.state.dropUpCountryName,
                    Country:  this.state.serviceType==="Domestic" ? this.state.indiaCountryName : this.state.dropUpCountryName,
                    countryId:  this.state.serviceType==="Domestic" ? this.state.indiaCountryId : this.state.dropUpCountryId,
                };

                let Return = {
                    CompanyID: this.state.shipperId,
                    CompanyName: this.state.shipperName,
                    ZipCode: this.state.pinCode,
                    AddressLine1: this.state.address,
                    City: this.state.pickupCountryName,
                    cityId: this.state.pickUpCountryId,
                    Country:  this.state.pickupCountryName,
                    countryId:  this.state.pickUpCountryId,
                };

                mainData.originAirport = {};
                mainData.originDoor = OriginValue;
                mainData.Return = Return;
                mainData.Shipper = Shipper;
                mainData.Consignee = Consignee;
                mainData.paymentType = this.state.paymentType;
                mainData.packagingType = this.state.packagingType;
                mainData.document = this.state.document;
                mainData.shipmentDescription = this.state.shipmentDescription;
                mainData.senderReference = this.state.senderReference;

                mainData.destination = {};
                mainData.destinationAirport = {};
                mainData.destinationDoor = DestinationValue;
                mainData.originAirport.id = this.state.OriginAirportValue ? this.state.OriginAirportValue.value : "";
                mainData.originAirport.code = this.state.OriginAirportValue ? this.state.OriginAirportValue.code : "";
                mainData.originAirport.name = this.state.OriginAirportValue ? this.state.OriginAirportValue.label : "";

                mainData.destinationAirport.id = this.state.destinationAirportValue ? this.state.destinationAirportValue.value : "";
                mainData.destinationAirport.code = this.state.destinationAirportValue ? this.state.destinationAirportValue.code : "";
                mainData.destinationAirport.name = this.state.destinationAirportValue ? this.state.destinationAirportValue.label : "";

                mainData.weight = {};
                mainData.grossWeight = this.state.gross;
                mainData.productDeclaredValue = this.state.productDeclaredValue;
                mainData.volumeWeight = this.state.uploadedDimensions.length >0 ? this.state.volume:this.state.gross;
                mainData.chargeableWeight = this.state.gross;

                mainData.totalPieces = this.state.courierMode === "Document" ? "1" : this.state.pieces;;
                /*mainData.noOfContainers =  this.state.totalPieces;*/
                mainData.density = 100;
                mainData.clearenceDate = this.state.clearanceDate;
                mainData.courierMode = this.state.courierMode;
                mainData.movementType = this.state.movementType;
                mainData.rateType = this.state.rateType;
                mainData.shipmentMode = this.state.shipmentMode;
                // mainData.containerType = this.state.containerType;
                if (this.state.shipmentMode === "FCL") {
                    this.state.ContainerList.map((item, i) => {
                        if (item.count !== undefined) {
                            containerArry.push({
                                name: item.container,
                                count: item.count
                            })
                        }

                    })
                    mainData.containerType = containerArry;
                    mainData.stuffingType = this.state.stuffingType;

                }

                mainData.custType = "Shipper";
                mainData.otherCommodity = this.state.otherCommodity;
                if(this.state.commodity.hsnCode!==""){
                    mainData.commodity = this.state.commodity.description;
                    mainData.commodityHsnCode = this.state.commodity.hsnCode;
                }
                else{
                    mainData.commodity = this.state.commodity.description
                }

                /***********************************  Dimension Page Details  ********************************************/

                mainData.dimentionType = this.state.selectedDimensionType;
                mainData.dimentions = this.state.uploadedDimensions;

                /*
                 **********************************  Additional Page Details  *******************************************
                 */

                mainData.IncoTerms = this.state.IncoTerms;
                mainData.targetRate = this.state.targetRate;
                mainData.additionalService = this.state.additionalService;
                mainData.packagingType = this.state.packagingType;
                mainData.preferredAirlines = [];
                mainData.stackable = this.state.stackable;
                mainData.tiltable = this.state.tiltable;
                mainData.remarks = this.state.remarks;
                mainData.attachments = this.state.attachments;
                mainData.shipmentType = this.state.shipmentType;
                mainData.queryEnteredBy = this.state.queryEnteredBy;
                mainData.allSlabRate = this.state.allSlabRate;
                mainData.contractRateId = this.state.contractRateId;
                mainData.contractNo = this.state.contractNo;
                mainData.isContractRate = this.state.isContractRate;
                mainData.serviceType = this.state.serviceType;
                mainData.customerMails = EmailArry;
                mainData.iataMails = this.state.iataEmailsSelect;
                mainData.customerAckMails = this.state.customerAckMails;
                mainData.activityType = this.state.activityType;
                mainData.tarrifMode = this.state.tarrifMode;
                mainData.queryType = this.state.queryType;
                mainData.queryFor = this.state.queryFor;
                mainData.productDeclaredValue = this.state.productDeclaredValue;
                mainData.queryFrom = "Customer";
                mainData.ShipmentPerformaInvoice = this.state.shipmentItem;

                mainData.fromPincode = this.state.pinCode?this.state.pinCode:this.state.pickupCountryName;
                mainData.fromCountryId = this.state.serviceType==="Domestic" ? this.state.indiaCountryId : this.state.pickUpCountryId;

                mainData.toPincode = this.state.pinCode1?this.state.pinCode1:this.state.dropUpCountryName;
                mainData.toCountryId = this.state.serviceType==="Domestic" ? this.state.indiaCountryId : this.state.dropUpCountryId
                mainData.queryType = this.state.queryType;

                this.setState({mainData: mainData})
                localStorage.setItem('queryData', JSON.stringify(mainData))
                if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                    mainData.login=true
                    this.props.sendCourierDataToAddQuery(mainData)
                    this.props.getCourierCharges(mainData)
                } else if (this.state.guestLogin === true && !localStorage.getItem("ContactDeatails") === false) {
                    this.StoryGuestQuery(mainData).then(responce => {
                        if (responce.success) {
                            let GuestQueryNo = responce.result && responce.result._id ? responce.result._id : "";

                            this.setState({guestLogin: false, GuestQueryNo: GuestQueryNo},()=>{
                                mainData.quoteId = this.state.GuestQueryNo;
                        this.setState({mainData: mainData})
                                this.GetRatesQuery(mainData).then(responce => {
                                    if (responce.success)
                                    {
                                        let ratesResult=Array.isArray(responce.result)?responce.result:[];
                                        let queryDataForcard =responce.result2?responce.result2:{};
                                        this.setState({
                                            queryDataForcard:queryDataForcard,
                                            allCharges:ratesResult,
                                            rateStoreForGuest: true
                                        },()=>{
                                            if (this.state.rateStoreForGuest === true && this.state.GuestQueryNo !== undefined) {
                                                let shipmentMode = this.state.shipmentMode;
                                                let obj = {
                                                    quoteId: this.state.GuestQueryNo,
                                                    revertData: this.state.allCharges,
                                                    shipmentMode: shipmentMode
                                                }
                                                if (shipmentMode !== "") {
                                                    this.props.sendRatesForStore(obj)
                                                }
                                            }
                                            localStorage.setItem('AllCharges', JSON.stringify(this.state.allCharges))
                                        });
                                    }
                                })
                            })
                            this.props.getCourierCharges(mainData)
                        }
                    })
                }
            }
            else {
                toast.error("Please Select your origin and destination for shipment", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    sumArray = (input) => {
        if (toString.call(input) !== "[object Array]")
            return false;

        let total = 0;
        for (let i = 0; i < input.length; i++) {
            if (isNaN(input[i])) {
                continue;
            }
            total += Number(input[i]);
        }
        return total;
    }
    calculateConatainer = () => {
        let totalNumber = this.TotalContainer
        let totalWeight = this.TotalContainerWeight
        let totalcbm = this.TotalContainerCbm
        let sum = this.sumArray(totalNumber)
        let sumWeight = this.sumArray(totalWeight)
        let sumCbm = this.sumArray(totalcbm)
        this.setState({noOfContainers: sum, totalConatinerWeight: sumWeight, totalConatinerCbm: sumCbm}, () => {

        })
    }
    StoryGuestQuery = async (dataForServer) => {
        return await RestClient({
            url: STORE_GUEST_QUERY_API,
            method: "POST",
            data: dataForServer
        });
    }
    storeQuerApi = async (params) => {
        return await RestClient({
            url: STORE_QUERY_API,
            method: "POST",
            data: params
        })

    };
    storeQuerRatesDataApi = async (dataForServer) => {
        // console.log(JSON.stringify("In Hit API" + JSON.stringify(dataForServer)));
        return await RestClient({
            url: STORE_QUERY_RATES_API,
            method: "POST",
            data: dataForServer
        });
    }
    handleOnChargesget = (event) => {

        if (this.myDivCharges.current) {

            this.myDivCharges.current.scrollIntoView({
                behavior: "smooth",
                //block: "center"
            })
        }
    }
    prepareToBookData = () => {
        this.setState({showLoader: true})
        let chargesData = JSON.parse(localStorage.getItem("selectedCharge"))
        let StoredRateData = this.state.StoredRateData
        let containerTypeData = this.state.containerType
        let container = [];
        let SelectedRateId = ""
        let isRatesStored = false
        if (Array.isArray(StoredRateData) && StoredRateData.length >= 1) {
            StoredRateData.map(item => {
                if (item._id === chargesData._id) {
                    SelectedRateId = item._id
                    isRatesStored = true
                }
            })

            let chargesArray = chargesData && chargesData.charges ? chargesData.charges : [];
            let CustGstType = getCustomerGStTypeData()
            let Custpos = getCustomerPosData()
            let branchPos = getCustomerUserBranchPosData()
            let discountApply = localStorage.getItem("discountGiven")
            let saleData = []
            if(discountApply){
                console.log('chargesaraychargesaraychargesaray',JSON.stringify(chargesArray))
                saleData =calculateChargesAccordingtoStructureIfDiscount(chargesArray,CustGstType,Custpos,branchPos)
                console.log('saleDatasaleDatasaleDatasaleData',JSON.stringify(saleData))
              }else{
              saleData =calculateChargesAccordingtoStructure(chargesArray,CustGstType,Custpos,branchPos)
              }
            // let salevalue = calculateChargesAccordingtoStructure(chargesArray, CustGstType, Custpos, branchPos)
            let salevalue = saleData

            let obj = {
                quoteId: this.state.queryNo,
                houses: this.state.houses,
                rateId: SelectedRateId,
                remarks: "",
                otherRemarks: "",
                customer: getCustomerData(),
                branchId: getCustomerUserBranchIDData(),
                branchName: getCustomerUserBranchNameData(),
                branchCode: getCustomerUserBranchCodeData(),
                branchPos: getCustomerUserBranchPosData(),
                reason: "",
                buyValue: [],
                saleValue: salevalue ? salevalue : [],
                otherIncentive: [],
                otherShipment: [],
                gstType: getCustomerGStTypeData() === "O-OVERSEAS" ? "IGST" : "",
                revertData: chargesData,
                remainingCharges: this.state.remainingCharges,
                shipperConsigneeData: this.state.shipperConsigneeData,
                NoOfContainer: this.state.NoOfContainer,
                totalGross: this.state.totalGross,
                totalVolume: this.state.totalVolume,
                activityType: this.state.activityType,
                containerType: containerTypeData,
                isRatesStored: isRatesStored,
                accountType: this.state.accountType,
                incoTermsId: this.state.incoTermsId,
                incoTerms: this.state.incoTerms,
                confirmedRateId: SelectedRateId,

            }
            this.setState({BookRateData: obj}, () => {
                this.props.sendBookingRates(obj)
            })


        } else {
            let chargesArray = chargesData && chargesData.charges ? chargesData.charges : [];
            let CustGstType = getCustomerGStTypeData()
            let Custpos = getCustomerPosData()
            let branchPos = getCustomerUserBranchPosData()
            let salevalue = calculateChargesAccordingtoStructure(chargesArray, CustGstType, Custpos, branchPos)

            let obj = {
                quoteId: this.state.queryNo,
                houses: this.state.houses,
                rateId: SelectedRateId,
                remarks: "",
                otherRemarks: "",
                customer: getCustomerData(),
                branchId: getCustomerUserBranchIDData(),
                branchName: getCustomerUserBranchNameData(),
                branchCode: getCustomerUserBranchCodeData(),
                branchPos: getCustomerUserBranchPosData(),
                reason: "",
                buyValue: [],
                saleValue: salevalue ? salevalue : [],
                otherIncentive: [],
                otherShipment: [],
                gstType: getCustomerGStTypeData() === "O-OVERSEAS" ? "IGST" : "",

                revertData: chargesData,
                remainingCharges: this.state.remainingCharges,
                shipperConsigneeData: this.state.shipperConsigneeData,
                NoOfContainer: this.state.NoOfContainer,
                totalGross: this.state.totalGross,
                totalVolume: this.state.totalVolume,
                activityType: this.state.activityType,
                containerType: containerTypeData,
                isRatesStored: isRatesStored,


            }
            this.setState({BookRateData: obj}, () => {
                this.props.sendBookingRates(obj)
            })
        }
    }

    prepareToSaveDataWithoutLogin = () => {
        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
            let queryData = JSON.parse(localStorage.getItem("queryData"))
            queryData.shipperConsigneeData = this.state.shipperConsigneeData;
            queryData.customerId = getCustomerId();
            queryData.customerBranchId = getCustomerBranchId();
            queryData.customerCurrency = getCustomerCurrency();
            queryData.baseCurrency = getBaseCurrency();
            queryData.branchId = getCustomerUserBranchIDData();
            let customerEmail = getLoggedInUserEmail()
            let EmailArry = [];
            if (!localStorage.getItem("customer") === false) {
                let userData = JSON.parse(localStorage.getItem("customer"))
                let csBuddy = "";
                let pricingHead = "";
                let salesPerson = "";
                let financePerson = "";
                csBuddy = userData && userData.result && userData.result.csBuddyData && userData.result.csBuddyData._id ? userData.result.csBuddyData._id : "";
                pricingHead = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.pricingHead ? userData.result.customerBranchData.pricingHead : "";
                salesPerson = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.salesPerson ? userData.result.customerBranchData.salesPerson : "";
                financePerson = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.financePerson ? userData.result.customerBranchData.financePerson : "";
                queryData.csBuddy = csBuddy;
                queryData.pricingHead = pricingHead;
                queryData.salesPerson = salesPerson;
                queryData.financePerson = financePerson;
                queryData.customerMails = EmailArry;
            }

            this.props.sendCourierQuery(queryData)
        }
    }
    prepareToSaveDataForLogin = () => {
        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
            let queryData = JSON.parse(localStorage.getItem("queryData"))
            // queryData.shipperConsigneeData = this.state.shipperConsigneeData;
            // queryData.customerMails = this.state.customerMails;
            this.props.sendQuery(queryData)
        }
    }
    toggleLarge = (i) => {
        let array = []
        if (localStorage.getItem("already_registered") === "true") {
            this.storeCharge(i)
        } else {
            localStorage.setItem("ShowShipperAndConsignee", JSON.stringify(true))
                this.state.allCharges && this.state.allCharges.map((obj, j) => {
                    if (i === j) {
                        localStorage.setItem('selectedCharge', JSON.stringify(obj))
                    } else if (i !== j) {
                        array.push(obj)
                    }
                    this.setState({remainingCharges: array})
                })
                this.setState(prevState => ({
                    modalLarge: !prevState.modalLarge
                }));

            
        }
    };

    storeCharge = (i) => {
        let array = []
        let discountApply = localStorage.getItem("discountGiven")
            if(discountApply){
                let getDiscountCharges = localStorage.getItem("afterDiscountCharges")
                getDiscountCharges = JSON.parse(getDiscountCharges)
                getDiscountCharges && getDiscountCharges.map((obj, j) => {
                    if (i === j) {
                        localStorage.setItem('selectedCharge', JSON.stringify(obj))
                    } else if (i !== j) {
                        array.push(obj)
                    }
                    this.setState({remainingCharges: array})
                })
                this.setState(prevState => ({
                    modalLarge: !prevState.modalLarge
                }));

            }else{
        this.state.StoredRateData && this.state.StoredRateData.map((obj, j) => {
            if (i === j) {
                localStorage.setItem('selectedCharge', JSON.stringify(obj))
            } else if (i !== j) {
                array.push(obj)
            }
            this.setState({remainingCharges: array})
        })
    }
        if (localStorage.getItem("already_registered") === "true") {

            this.setState({
                shipperModel: true
            })
            // this.prepareToSaveData(i)
        }


    }
    sendtoFinalBooking = () => {
        const {rateId, _id, quoteId, customer, branchId, shippingLineName, shippingLineId} = this.state.rateSuccessResponce !== "" ? this.state.rateSuccessResponce : {};
        const {containerType, NoOfContainer, houses, shipperConsigneeData, totalGross, totalVolume, revertData, activityType} = this.state.BookRateData !== "" ? this.state.BookRateData : {};
        let obj = {}
        obj.rateId = rateId;
        obj.buySaleId = _id;
        obj.quoteId = quoteId;
        obj.isCopied = "";
        obj.copiedFrom = {};
        obj.isRejected = "Pending";
        obj.rejectedMessage = "";
        obj.shipmentType = "Direct";
        obj.customerId = customer && customer.customerId ? customer.customerId : "";
        obj.branchId = branchId;
        obj.containerType = containerType;
        obj.containerDetails = containerType;
        obj.noOfContainers = NoOfContainer;
        obj.noOfHouses = houses && houses.length !== undefined ? houses.length : 0;
        obj.houses = houses;
        obj.scacNumber = "";
        obj.shippingLineName = revertData && revertData.shippingLineName ? revertData.shippingLineName : "";
        obj.shippingLineId = revertData && revertData.shippingLineId ? revertData.shippingLineId : "";
        obj.svcContract = "";
        obj.vesselNo = "";
        obj.voyageNo = "";
        obj.placeOfReceipt = "";
        obj.placeOfDelivery = "";
        obj.asAgreed = "";
        obj.blPdfUrl = "";
        obj.blStatus = "Draft";
        obj.notifyPartyName = "";
        obj.notifyPartyAddress = "";
        obj.notifyPartyCity = "";
        obj.notifyPartyZipCode = "";
        obj.notifyPartyCountryId = "";
        obj.notifyPartyAccountInfo = "";
        obj.currency = "";
        obj.shipperOrAgentSignature = "";
        obj.dateOfSignature = convertDateISO(new Date());
        obj.carrierOrAgentSignature = "";
        obj.SCI = "";
        obj.otherInformation = "";
        obj.shipmentBookingNo = "";
        obj.shipmentBookingDate = "";
        obj.natureAndQuantityOfGoods = "";
        obj.isShipmentBooked = false;
        obj.isNegativeShipment = false;
        obj.shipperId = shipperConsigneeData && shipperConsigneeData.shipperId ? shipperConsigneeData.shipperId : "";
        obj.shipperBranchId = shipperConsigneeData && shipperConsigneeData.shipperBranchId ? shipperConsigneeData.shipperBranchId : "";
        obj.shipperAccountNo = shipperConsigneeData && shipperConsigneeData.shipperAccountNo ? shipperConsigneeData.shipperAccountNo : "";
        obj.consigneeId = shipperConsigneeData && shipperConsigneeData.consigneeId ? shipperConsigneeData.consigneeId : "";
        obj.consigneeBranchId = shipperConsigneeData && shipperConsigneeData.consigneeBranchId ? shipperConsigneeData.consigneeBranchId : "";
        obj.consigneeAccountNo = shipperConsigneeData && shipperConsigneeData.consigneeAccountNo ? shipperConsigneeData.consigneeAccountNo : "";
        obj.shipperDetails = shipperConsigneeData && shipperConsigneeData.shipperDetails ? shipperConsigneeData.shipperDetails : "";
        obj.consigneeDetails = shipperConsigneeData && shipperConsigneeData.consigneeDetails ? shipperConsigneeData.consigneeDetails : "";
        obj.issuedBySelectionBranch = "";
        obj.handlingInfo = "";
        obj.remarks = "";
        obj.bookingNo = "";
        obj.blNo = "";
        obj.volumeWeight = totalVolume;
        obj.noOfPieces = this.state.totalPieces;
        obj.accountType = this.state.accountType;
        obj.incoTermsId = this.state.incoTermsId;
        obj.incoTerms = this.state.incoTerms;
        obj.grossWeight = totalGross;
        obj.kgOrLBS = "kg";
        obj.chargeableWeight = this.state.chargeableWeight;

        this.FinalBookingApi(obj).then(responce => {
            if (responce.success) {
                toast.success(responce.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                let bookingId = responce.result && responce.result._id ? responce.result._id : ""
                let HouseNo = responce.result && responce.result.noOfHouses >= 1 ? responce.result.noOfHouses : undefined;
                // if(HouseNo !== undefined && HouseNo >= 1){
                //     this.props.history.push(PAGE_PATHS.HOUSE_VIEW + "/" + bookingId)
                // }else{
                this.props.history.push(PAGE_PATHS.BOOKING_COURIER_VIEW + "/" + bookingId)
                // }

            } else {
                toast.error(responce.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }


        })
    }

    FinalBookingApi = async (data) => {
        return await RestClient({
            url: CREATE_BOOKING_API,
            method: "POST",
            data: data
        });
    };

    onCountrySelected2 = ({country, countryCode, countryId}) => {

        let pickUpCountryId = countryId;
        let pickupCountryName = country;
        this.setState({
            pickUpCityId:"",
            pickUpCityName:"",
            pickUpCountryId: pickUpCountryId,
            pickupCountryName: pickupCountryName,
            pickUpStateName: "",
            pickUpStateId: "",
        })
    };
    onCountrySelected3 = ({country, countryCode, countryId}) => {

        let dropUpCountryId = countryId;
        let dropUpCountryName = country;
        this.setState({
            dropUpCityId:"",
            dropUpCityName:"",
            dropUpCountryId: dropUpCountryId,
            dropUpCountryName: dropUpCountryName,
            dropUpStateName: "",
            dropUpStateId: "",
        })
    };

    getCountryData = async () =>{
        this.fetchCountries().then(responce => {
            let countriesData = responce && responce.result ? responce.result : undefined;
            let result = "";
            let countryID = "";
            let countryName = "";
            countriesData && countriesData.map((itemOuter, i) => {
                debugger
                if (itemOuter.name === "India") {
                    result = itemOuter;
                    countryID= result._id;
                    countryName = result.name;
                }
            });

            this.setState({
                indiaCountryId: countryID,
                indiaCountryName: countryName,
            });

        })
    }
    fetchCountries = async (dataForServer) => {
        {
            return await RestClient({
                url: GET_COUNTRIES_LIST,
                method: "POST",
                data: dataForServer
            });
        }
    };
    handleTargetRate = (e) => {
        this.setState({[e.target.name]: e.target.value,})}
    toggleCouponDetails = () =>{
        this.setState({
            couponDetails:false,
            // contactLarge:true,
        },()=>{
            let data = {
                contactEmail:this.state.contactEmail,
                contactNumber:this.state.contactNumber !== "" ? this.state.contactNumber : "",
            }
            localStorage.setItem("ContactDeatails",JSON.stringify(data))
            this.handleStoreSubmit()
        })
    }
    couponDetailsYes = () =>{
        this.setState({
            contactLarge:true,
            couponDetails:false,
            couponCompulsari: true,
        })
    }
    offerEmailPopup = () =>{
        const body = {
            contactEmail: [this.state.contactEmail],
            queryFor: this.state.queryFor,
        }
        this.offerMail(body).then(response=>{
            if(response.success){
                toast.success("Mail Sent Successfully", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    contactCompulsorySubmit:!this.state.contactCompulsorySubmit,
                })
            }
        })


    }
    offerMail = async (body)=> {
        return await RestClient({
            url: COUPON_OFFER_SEND,
            method: "POST",
            data: body
        })
    };
    render() {
        const queryDataForcard = this.state.queryDataForcard;
        const allCharges = Array.isArray(this.state.StoredRateData) && this.state.StoredRateData.length >= 1 ? this.state.StoredRateData : this.state.allCharges;
        // console.log('allChargesallChargesallChargesallCharges',JSON.stringify(this.state.allCharges))
        return (
            <>
                    <ContactDeatils
                        couponCompulsari = {this.state.couponCompulsari}
                        modalLarge={this.state.contactLarge}
                        setmodal={values => values === false ? this.setState({contactLarge: false}) : ""}
                        Compalsari={this.state.Compalsari}
                        recivedContactInfo={value => {
                            this.setState({
                                contactEmail: value.contactEmail,
                                contactNumber: value.contactNumber,
                            }, () => {
                                if(this.state.contactEmail === "" || this.state.contactEmail === undefined){
                                    this.handleStoreSubmit()
                                }else{
                                    this.offerEmailPopup()
                                }
                            })
                        }}
                    />
                <CouponSendMail
                    contactCompulsorySubmit = {this.state.contactCompulsorySubmit}
                    contactMail = {this.state.contactEmail}
                    queryFor={this.state.queryFor}
                    setmodalToggle={values => values === false ? this.setState({contactCompulsorySubmit: false},()=>{
                        this.handleStoreSubmit()
                    }) : ""}
                    recivedCouponMail = {values => values === false ? this.setState({contactCompulsorySubmit: false},()=>{
                        this.handleStoreSubmit()
                    }) : ""}
                />
                <Modal className="dangerous_pop coupon_new_pop"
                       isOpen={this.state.couponDetails}
                       size="sm"
                       toggle={this.toggleCouponDetails}>
                    <ModalHeader toggle={this.toggleCouponDetails} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                    </ModalHeader>
                    <ModalBody>
                        <div className="pop_coupons">
                            <div className="pop-text">
                                <img style={{width:'100%'}} src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9230838828293-1644412151342.png" alt="Coupon"/>

                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div style={{width:'100%'}}>
                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                <button className="btn coupon_btns" style={{background:'#ff0600'}} onClick={this.couponDetailsYes}>Yes</button>
                                <button className="btn coupon_btns" onClick={this.toggleCouponDetails}>No</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                    <SignUp
                        modalLarge={this.state.modalLarge}
                        setmodal={values => values === false ? this.setState({modalLarge: false}) : ""}
                    />
                    <RateNotFoundCard
                        RateNotFoundModel={this.state.RateNotFoundModel}
                        returnResult={(value) => {
                            if (value === true) {
                                this.setState({RateNotFoundModel: false, allCharges: []})
                            }
                        }}
                    />
                    <RateChargesNotavilbale
                        RateNotFoundModel={this.state.ChargeRateNotFoundModel}
                        returnResult={(value) => {
                            if (value === true) {
                                this.setState({ChargeRateNotFoundModel: false}, () => {
                                    this.props.getChargestError(null)
                                })
                            }
                        }}
                    />
                    <CourierShipperConsigneeModal
                        shipperModel={this.state.shipperModel}
                        setmodal={values => values === false ? this.setState({shipperModel: false}) : ""}
                        sendNext={values => this.setState({sendNext: values}, () => {
                        })}
                        queryDataForcard={this.state.queryDataForcard}
                        sendResultdata={values => {
                            this.setState({
                                shipperConsigneeData: values.shipperConsignee ? values.shipperConsignee : {},
                                houses: values.houses ? values.houses : undefined,
                                NoOfContainer: values.NoOfContainer ? values.NoOfContainer : undefined,
                                containerType: values.containerType ? values.containerType : undefined,
                                totalGross: values.totalGross ? values.totalGross : undefined,
                                totalVolume: values.totalVolume ? values.totalVolume : undefined,
                                totalPieces: values.totalPieces ? values.totalPieces : undefined,
                                activityType: values.activityType ? values.activityType : undefined,
                                accountType: values.accountType ? values.accountType : undefined,
                                incoTerms: values.incoTerms ? values.incoTerms : undefined,
                                incoTermsId: values.incoTermsId ? values.incoTermsId : undefined,
                                chargeableWeight: values.chargeableWeight ? values.chargeableWeight : undefined,
                            }, () => {
                                if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0 && this.state.withLogin === true) {
                                    this.prepareToBookData()
                                } else {
                                    this.prepareToSaveDataWithoutLogin()
                                }
                            })
                        }}
                    />

                        <div className="container">
                            <div className="row">
                                <div className="diff-forms">
                                    {localStorage.getItem("queryType") === "CourierQuery"  && this.state.firstCourierStatus ===true?
                                    <div data-aos={"fade-left"} className="second-sec">
                                        <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                            <p className="text-center">Choose your Service Type</p>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className={this.state.serviceType === "Domestic" ? "user-box active" : "user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.DomesticTab()
                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="../../../assets/img/truck1.png" className="width_full" alt="Domestic"/>
                                                    <p>Domestic</p>
                                                </div>
                                                <div className={this.state.serviceType === "International" ? "user-box active" : "user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.InternationalTab()
                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="../../../assets/img/inernational.png" alt="International"/>
                                                    <p>International</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                <button className="btn prev_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.props.prevCourier({
                                                        firstShipmentNo: this.props.firstShipmentNo,
                                                        gstYes: this.props.gstYes,
                                                        showAirTab : this.props.showAirTab,
                                                        showOceanTab : this.props.showOceanTab,
                                                        showCourierTab : this.props.showCourierTab,
                                                        showRoadTab : this.props.showRoadTab,
                                                        CustomerDetailsStatus : this.props.CustomerDetailsStatus,
                                                    })
                                                }}><FontAwesomeIcon icon={faChevronLeft}/></button>

                                                <button className="btn next_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.serviceTypeAfter()
                                                }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                            </div>
                                        </div>
                                    </div>
                                    :undefined}
                                    {this.state.courierTabOpen=== true ?
                                        <div data-aos={"fade-left"} className="second-sec" ref={this.myDivFocus}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                                <p className="text-center">Choose your Courier Category</p>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <div className={this.state.courierMode === "Document" ? "user-box active" : "user-box"} onClick={e => {
                                                        e.preventDefault()
                                                        this.courierModeDocument()
                                                    }}>
                                                        <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                        <img src="../../../assets/img/document.png" alt="Document"/>
                                                        <p>Document</p>
                                                    </div>
                                                    <div className={this.state.courierMode === "Parcel" ? "user-box active" : "user-box"} onClick={e => {
                                                        e.preventDefault()
                                                        this.courierModeNonDocument()
                                                    }}>
                                                        <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                        <img src="../../../assets/img/parcel.png" alt="Non Document"/>
                                                        <p>Non Document</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                    <button className="btn next_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.courierSectionAfter()
                                                    }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                                    <button className="btn prev_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.courierModePrevious()
                                                    }}><FontAwesomeIcon icon={faChevronLeft}/></button>

                                                </div>
                                            </div>
                                        </div>
                                        : undefined}
                                    {this.state.movementTabOpen=== true ?
                                        <div data-aos={"fade-left"} className="second-sec" ref={this.myDivFocus}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                                <p className="text-center">Where do you want to Handover the Shipment</p>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <div className={this.state.movementType === "Door Pick Up" ? "user-box active" : "user-box"} onClick={e => {
                                                        e.preventDefault()
                                                        this.movementTypePick()
                                                    }}>
                                                        <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                        <img src="../../../assets/img/pick-up.png" alt="Pick Up"/>
                                                        <p>Pick Up</p>
                                                    </div>
                                                    <div className={this.state.movementType === "Depots Drop Off" ? "user-box active" : "user-box"} onClick={e => {
                                                        e.preventDefault()
                                                        this.movementTypeDrop()
                                                    }}>
                                                        <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                        <img src="../../../assets/img/drop-off.png" alt="Drop Off"/>
                                                        <p>Drop Off</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                    <button className="btn next_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.MovementTypeAfter()
                                                    }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                                    <button className="btn prev_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.movementPrevious()
                                                    }}><FontAwesomeIcon icon={faChevronLeft}/></button>

                                                </div>
                                            </div>
                                        </div>
                                    : undefined}
                                    {this.state.pickUpDetails === true || this.state.dropOffDetails === true?
                                        <>
                                            <Modal className="popup_activity_type" isOpen={true} size="md" toggle={this.PickUpPrevious}>
                                                <ModalHeader toggle={this.PickUpPrevious} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <div className="">
                                                        <div className="pop-text">
                                                            <div className="dangerous_pop">
                                                                <div className="pick_drop_details">
                                                                    <div className="d-flex flex-wrap align-items-stretch justify-content-between">
                                                                        <div className="width_pick_full pick_details">
                                                                            <h2>Pick Up Details</h2>
                                                                            <hr/>
                                                                            <div className="">
                                                                                <FormGroup className="">
                                                                                    <label htmlFor="date" className="origin-label">Pick Up Date <sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></label>
                                                                                    <Input type="date"
                                                                                           className="form-control inpt"
                                                                                           name="clearanceDate"
                                                                                           placeholder="Handover Date"
                                                                                           min={convertDateISO(
                                                                                               new Date()
                                                                                                   .toJSON()
                                                                                                   .slice(0, 10)
                                                                                                   .replace(/-/g, "/")
                                                                                           )}
                                                                                           value={
                                                                                               this.state.clearanceDate === undefined
                                                                                                   ? ""
                                                                                                   : convertDateISO(this.state.clearanceDate)
                                                                                           }
                                                                                           onChange={e => {
                                                                                               if (
                                                                                                   convertDateISO(e.target.value) <
                                                                                                   convertDateISO(
                                                                                                       new Date()
                                                                                                           .toJSON()
                                                                                                           .slice(0, 10)
                                                                                                           .replace(/-/g, "/")
                                                                                                   )
                                                                                               ) {
                                                                                                   e.target.value = convertDateISO(
                                                                                                       new Date()
                                                                                                           .toJSON()
                                                                                                           .slice(0, 10)
                                                                                                           .replace(/-/g, "/")
                                                                                                   );
                                                                                                   this.handleChangeEmbedded(e)
                                                                                               } else {
                                                                                                   this.handleChangeEmbedded(e)
                                                                                               }
                                                                                           }}
                                                                                    />

                                                                                </FormGroup>
                                                                                <div className="form-group">
                                                                                    {this.state.serviceType !== "Domestic" ?
                                                                                        <>
                                                                                            <label htmlFor="zip code" className="origin-label">Select Country <sup style={{
                                                                                                fontWeight: '700',
                                                                                                fontSize: '14px'
                                                                                            }}>*</sup></label>
                                                                                            <CountryDropDown
                                                                                                handler={this.onCountrySelected2}
                                                                                                selectedCountry={
                                                                                                    this.state.pickUpCountryId
                                                                                                        ? this.state.pickUpCountryId
                                                                                                        : ""
                                                                                                }
                                                                                            />
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <label htmlFor="address" className="origin-label">Country ({this.state.indiaCountryName})</label>
                                                                                            <Input type="text"
                                                                                                   className="form-control inpt"
                                                                                                   name="pickupCountryName"
                                                                                                   placeholder="Enter Your City"
                                                                                                //hidden={true}
                                                                                                   readOnly
                                                                                                   value={this.state.indiaCountryName}
                                                                                                   onChange={e => {
                                                                                                       this.setState({
                                                                                                           pickupCountryName: e.target.value,
                                                                                                           pickUpCountryId: this.state.indiaCountryId
                                                                                                       }, () => {
                                                                                                       })
                                                                                                   }}
                                                                                            />
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                                {this.state.serviceType==="Domestic" ?
                                                                                    <div className="form-group">
                                                                                        <Label className="origin-label text-dark">Enter Zip Code <sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></Label>
                                                                                        <PickUpDropDown
                                                                                            APIRout={MANAGER_PICKUPORIGINPINCODE_API}
                                                                                            queryFor={"Courier"}
                                                                                            PlaceHolder={"Enter Zip Code"}
                                                                                            value={this.state.pinCode}
                                                                                            handler={(values) => {

                                                                                                this.setState({
                                                                                                    pinCode: values.label,
                                                                                                });

                                                                                            }}
                                                                                            selectedObj={{label: this.state.pinCode}}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        { this.state.pickupCountryName==="India"?
                                                                                            <>
                                                                                                <Label className="origin-label text-dark">Enter Zip Code <sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></Label>
                                                                                                <PickUpDropDown
                                                                                                    APIRout={MANAGER_PICKUPORIGINPINCODE_API}
                                                                                                    PlaceHolder={"Enter Zip Code"}
                                                                                                    queryFor={"Courier"}
                                                                                                    value={this.state.pinCode}
                                                                                                    handler={(values) => {
                                                                                                        this.setState({
                                                                                                            pinCode: values.label,
                                                                                                        });
                                                                                                    }}
                                                                                                    selectedObj={{label: this.state.pinCode}}

                                                                                                />
                                                                                            </>
                                                                                            :
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="Zip Code" className="origin-label">Enter Zip Code</label>
                                                                                                <Input type="text"
                                                                                                       className="form-control inpt"
                                                                                                       name="pinCode"
                                                                                                       placeholder="Enter Zip Code"
                                                                                                       value={this.state.pinCode}
                                                                                                       onChange={e => {
                                                                                                           this.setState({pinCode: e.target.value})
                                                                                                       }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="drop_pick_btn">
                                                                        <div className="btn_drop mr-1" onClick={this.PickUpPrevious}>Previous</div>
                                                                        <div className="btn_drop" onClick={this.PickUpNext}>Next</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                            </Modal>
                                        </>

                                    :undefined}

                                    {this.state.showDropDetails === true ?
                                        <>
                                            <Modal className="popup_activity_type" isOpen={true} size="md" toggle={this.DropToPrevious}>
                                                <ModalHeader toggle={this.DropToPrevious} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <div className="">
                                                        <div className="pop-text">
                                                            <div className="dangerous_pop">
                                                                <div className="pick_drop_details">
                                                                    <div className="d-flex flex-wrap align-items-stretch justify-content-between">
                                                                        <div className="width_pick_full pick_details">
                                                                            <h2>Drop Off Details</h2>
                                                                            <hr/>
                                                                            <div className="">
                                                                                <div className="form-group">
                                                                                    {this.state.serviceType !== "Domestic" ?
                                                                                        <>
                                                                                            <label htmlFor="zip code" className="origin-label">Select Country  <sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></label>
                                                                                            <CountryDropDown
                                                                                                handler={this.onCountrySelected3}
                                                                                                selectedCountry={
                                                                                                    this.state.dropUpCountryId
                                                                                                        ? this.state.dropUpCountryId
                                                                                                        : ""
                                                                                                }
                                                                                            />
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <Input type="text"
                                                                                                   className="form-control inpt"
                                                                                                   name="dropUpCountryName"
                                                                                                   placeholder="Enter Your City"
                                                                                                   readOnly
                                                                                                   value={this.state.indiaCountryName}
                                                                                                   onChange={e => {
                                                                                                       this.setState({
                                                                                                           dropUpCountryName: e.target.value,
                                                                                                           dropUpCountryId: this.state.indiaCountryId
                                                                                                       }, () => {
                                                                                                       })
                                                                                                   }}
                                                                                            />

                                                                                        </>
                                                                                    }


                                                                                </div>
                                                                                {this.state.serviceType==="Domestic" ?
                                                                                    <div className="form-group">
                                                                                        <Label className="origin-label text-dark">Enter Zip Code  {this.state.serviceType==="Domestic" ?<sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup>:""}</Label>
                                                                                        <PickUpDropDown
                                                                                            APIRout={MANAGER_PICKUPORIGINPINCODE_API}
                                                                                            PlaceHolder={"Enter Zip Code *"}
                                                                                            value={this.state.pinCode1}
                                                                                            queryFor={"Courier"}
                                                                                            handler={(values) => {

                                                                                                this.setState({
                                                                                                    pinCode1: values.label,
                                                                                                });

                                                                                            }}
                                                                                            selectedObj={{label: this.state.pinCode1}}

                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        {this.state.dropUpCountryName==="India"?
                                                                                            <>
                                                                                                <div className="form-group">
                                                                                                    <Label className="origin-label text-dark">Enter Zip Code <sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></Label>
                                                                                                    <PickUpDropDown
                                                                                                        APIRout={MANAGER_PICKUPORIGINPINCODE_API}
                                                                                                        PlaceHolder={"Enter Zip Code *"}
                                                                                                        value={this.state.pinCode1}
                                                                                                        queryFor={"Courier"}
                                                                                                        handler={(values) => {
                                                                                                            this.setState({
                                                                                                                pinCode1: values.label,
                                                                                                            });
                                                                                                        }}
                                                                                                        selectedObj={{label: this.state.pinCode1}}
                                                                                                    />
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <div className="form-group">
                                                                                                <Label className="origin-label text-dark">Enter Zip Code</Label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control inpt"
                                                                                                    placeholder="Enter Zip Code"
                                                                                                    name="pinCode1"
                                                                                                    value={this.state.pinCode1}
                                                                                                    onChange={e => {
                                                                                                        this.setState({pinCode1: this.state.pinCode1}, () => {
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </>


                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="drop_pick_btn">
                                                                        <div className="btn_drop mr-1" onClick={this.DropToPrevious}>Previous</div>
                                                                        <div className="btn_drop" onClick={this.PackageDetails}>Next</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                            </Modal>
                                        </>

                                    :undefined}
                                    {this.state.packagingDetails===true ?
                                        <>
                                            <Modal className="popup_activity_type" isOpen={true} size="md" toggle={this.shipmentDetailsPrevious}>
                                                <ModalHeader toggle={this.shipmentDetailsPrevious} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <div className="">
                                                        <div className="pop-text">
                                                            <div className="dangerous_pop">
                                                                <div className="pick_drop_details">
                                                                    <div className="d-flex flex-wrap align-items-stretch justify-content-between">
                                                                        <div className="width_pick_full pick_details">
                                                                            <h2>Please Fill Package Details</h2>
                                                                            <hr/>
                                                                            <div>
                                                                                <div className="form-group">
                                                                                    <Label className="origin-label text-dark">Gross Weight ( Kgs ) <sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></Label>
                                                                                    <Input type="number"
                                                                                           className="form-control inpt"
                                                                                           name="gross"
                                                                                           placeholder="Gross Weight"
                                                                                           value={this.state.gross}
                                                                                           onChange={e => {
                                                                                               this.setState({gross: e.target.value}, () => {

                                                                                               })
                                                                                           }}
                                                                                    />
                                                                                </div>
                                                                                {this.state.courierMode === "Document" ?
                                                                                    <div className="form-group">
                                                                                        <Label className="origin-label text-dark">Total Pieces<sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></Label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control ipt1"
                                                                                            name="pieces"
                                                                                            placeholder="Total Pieces"
                                                                                            readOnly={true}
                                                                                            onChange={e => {
                                                                                                this.setState({pieces: "1"}, () => {
                                                                                                })
                                                                                            }}
                                                                                            value="1"
                                                                                        />

                                                                                    </div>
                                                                                    :""}
                                                                                {this.state.courierMode === "Parcel" ?
                                                                                    <>
                                                                                        <div className="form-group">
                                                                                            <Label className="origin-label text-dark">Total Pieces<sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></Label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control inpt"
                                                                                                name="pieces"
                                                                                                placeholder="Total Pieces"
                                                                                                id="pieces"
                                                                                                onChange={this.handleChangeRe}
                                                                                                value={this.state.pieces}
                                                                                                readOnly={this.state.uploadedDimensions.length > 0 ? true : false}
                                                                                            />

                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <Label className="origin-label text-dark">Product Declared Value(INR)<sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup></Label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control ipt1"
                                                                                                name="productDeclaredValue"
                                                                                                placeholder="Product Value"
                                                                                                onChange={e => {
                                                                                                    this.setState({productDeclaredValue: e.target.value}, () => {
                                                                                                    })
                                                                                                }}
                                                                                                value={this.state.productDeclaredValue}
                                                                                            />

                                                                                        </div>
                                                                                    </>
                                                                                    :undefined}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="drop_pick_btn">
                                                                        <div className="btn_drop mr-1" onClick={this.shipmentDetailsPrevious}>Previous</div>
                                                                        <div className="btn_drop" onClick={this.searchButton}>Next</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                            </Modal>

                                        </>

                                        :undefined}
                                    {this.state.searchButtonShow === true ?
                                        <div className="diff-buttons text-center margin-50" data-aos={"fade-left"} ref={this.searchButtonDetails}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                                <div className="forms-boxes1">
                                                    <h4 className="text-center">Any preferred rate you are looking for?</h4>
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-center align-items-center">
                                                    <div className="position-relative" >
                                                        <div className="user-box" onClick={e => {
                                                            e.preventDefault()
                                                            this.targetRateClick()
                                                        }}>
                                                            <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                            {/*<img src="../../../assets/img/drop-track-ship.png" alt="pick"/>*/}
                                                            <p>Target Rate</p>
                                                        </div>
                                                        <div className="origin_dropdown responsive_width_pop" hidden={this.state.targetClick !== true}>
                                                            <div className="new-originbox padding_port">
                                                                <FormGroup className="">
                                                                    <label htmlFor="address1" className="origin-label">Target Rate</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control inpt"
                                                                        name="targetRate"
                                                                        placeholder="Target Rate"
                                                                        id="height"
                                                                        onChange={this.handleTargetRate}
                                                                        value={this.state.targetRate}
                                                                    />

                                                                </FormGroup>
                                                                <div className="form-group">
                                                                    <button className="btn btn_new_form" onClick={e => {
                                                                        e.preventDefault()
                                                                        this.targetRateNextFunc()
                                                                    }}>Next</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups mb-3">
                                                    <button className="btn prev_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.targetDetailsPrevious()
                                                    }}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                                    <button className="btn next_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.targetRateNextFunc()
                                                    }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                                </div>
                                            </div>
                                        </div>
                                        :""}
                                    {this.state.targetClickNext === true ?
                                        <div className="search-btn2 text-center margin-50" data-aos={"fade-left"}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="search-textwidth">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input"
                                                               name="termsOfUse"
                                                               onChange={this.termsOfUse}
                                                               id="exampleCheck1"/>
                                                        <label className="form-check-label" htmlFor="exampleCheck1">By clicking this checkbox you confirm that the details provided are true and correct and you agree to the <br/> <Link to="/TermsCondition" target="_blank" style={{color:'blue',textDecoration:'underline'}}>terms of use</Link></label>
                                                    </div>
                                                </div>
                                                {this.state.termsUse === true ?
                                                    <button className="btn pack-btns" onClick={e => {
                                                        e.preventDefault()
                                                        this.handleSubmit()
                                                    }}>Search</button>:""}
                                            </div>
                                        </div>
                                        :""}
                                </div>

                            </div>
                        </div>

                        {Array.isArray(this.state.allCharges) && this.state.allCharges.length > 0 ?
                            <div ref={this.myDivCharges}
                                 className={Array.isArray(this.state.allCharges) && this.state.allCharges.length > 0 ? "express_card" : undefined}>
                                <Container>
                                    <div className={this.state.allCharges && this.state.allCharges.length==1?"flights-types1 our_card d-flex flex-wrap justify-content-between stretched-align":"flights-types1 our_card d-flex flex-wrap justify-content-between stretched-align"}>
                                        <div className="tracking-card d-flex flex-wrap justify-content-start flex-column">
                                            <div className="bg-white map-tracking">
                                                <div className="ship-heading">
                                                    <h3 className="font-weight-bold">
                                                        Activity Tracking
                                                    </h3>
                                                </div>
                                                <div className="new-tracking">
                                                    <div className="track">
                                                        <div className="step active">
                                                            <span className="icon"> <FontAwesomeIcon icon={faCheck}/></span>
                                                            <div className="text position-relative">
                                                                <div className="button-popups">Open</div>
                                                            </div>
                                                        </div>
                                                        <div className="step active">
                                                            <span className="icon"> <FontAwesomeIcon icon={faCheck}/></span>
                                                            <span className="text"> Rates Quoted </span>
                                                        </div>
                                                        <div className="step">
                                                            <span className="icon"></span>
                                                            <span className="text">Booking</span>
                                                        </div>
                                                        <div  className="step">
                                                            <span className="icon">  </span>
                                                            <span className="text" >Payment</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="shipment_history shipment_history_dash">
                                                <div className="">
                                                    <div className="ship-heading"><h3 className="font-weight-bold">Shipment Details</h3></div>
                                                    <div className="details-other">
                                                        <div className="text-shipment">Origin :
                                                            {queryDataForcard.queryFor !== "Courier" ?


                                                                <span>{queryDataForcard.originAirport && queryDataForcard.originAirport.name ?
                                                                    queryDataForcard.originAirport.name.split("(")[queryDataForcard.originAirport.name.split("(").length - 2]
                                                                    : "NA"}</span>
                                                                :
                                                                <span>
                                                                                {queryDataForcard && queryDataForcard.Shipper && queryDataForcard.Shipper.Country !=="" ?queryDataForcard.Shipper.Country:"" },{queryDataForcard && queryDataForcard.Shipper && queryDataForcard.Shipper.ZipCode !=="" ?queryDataForcard.Shipper.ZipCode:"" }
                                                                            </span>
                                                            }

                                                        </div>
                                                        <div className="text-shipment">Destination :
                                                            {queryDataForcard.queryFor !== "Courier" ?
                                                                <span>{queryDataForcard.destinationAirport && queryDataForcard.destinationAirport.name ?
                                                                    queryDataForcard.destinationAirport.name.split("(")[queryDataForcard.destinationAirport.name.split("(").length - 2]
                                                                    : "NA"}</span>
                                                                :
                                                                <span>
                                                                                {queryDataForcard && queryDataForcard.Consignee && queryDataForcard.Consignee.Country !=="" ?queryDataForcard.Consignee.Country:"" },{queryDataForcard && queryDataForcard.Consignee && queryDataForcard.Consignee.ZipCode !=="" ?queryDataForcard.Consignee.ZipCode:"" }
                                                                            </span>
                                                            }
                                                        </div>
                                                        <div className="text-shipment">Total Pcs. : <span>{queryDataForcard.totalPieces? queryDataForcard.totalPieces : "NA"}</span></div>
                                                        <div className="text-shipment">Volume Wt. :
                                                            {queryDataForcard.queryFor !== "Ocean" ?
                                                                <span>{queryDataForcard && queryDataForcard.volumeWeight ? queryDataForcard.volumeWeight : "NA"}Kg</span>
                                                                :
                                                                <span>{queryDataForcard && queryDataForcard.volumeWeight ? queryDataForcard.volumeWeight : "NA"}CBM</span>
                                                            }
                                                        </div>
                                                        <div className="text-shipment">Gross Wt. : <span>{queryDataForcard && queryDataForcard.grossWeight ? queryDataForcard.grossWeight : "NA"}Kg</span></div>

                                                        <div className="text-shipment">Chargeable Wt. :
                                                            {queryDataForcard.queryFor !== "Ocean" ?
                                                                <span>{queryDataForcard && queryDataForcard.chargeableWeight ? queryDataForcard.chargeableWeight : "NA"}Kg</span>
                                                                :
                                                                <span>{queryDataForcard && queryDataForcard.chargeableWeight ? queryDataForcard.chargeableWeight : "NA"}CBM</span>
                                                            }
                                                        </div>
                                                        <div className="text-shipment">Activity Type : <span>{queryDataForcard && queryDataForcard.activityType ? queryDataForcard.activityType : ""}</span></div>
                                                        {queryDataForcard.queryFor !== "Courier" ?<div className="text-shipment">Tarrif Mode : <span>{queryDataForcard && queryDataForcard.tarrifMode ? queryDataForcard.tarrifMode : ""}</span></div>:""}
                                                        <div className="text-shipment">Commodity : <span>{queryDataForcard.commodityHsnCode  ? queryDataForcard.commodityHsnCode : queryDataForcard.commodity}</span></div>
                                                        {queryDataForcard.queryFor === "Ocean" ?
                                                            <>
                                                                <div className="text-shipment">Shipment Mode : <span>{queryDataForcard && queryDataForcard.shipmentMode ? queryDataForcard.shipmentMode : ""}</span></div>
                                                                <div className="text-shipment">No. of Container : <span>{queryDataForcard && queryDataForcard.noOfContainers ? queryDataForcard.noOfContainers : ""}</span></div>
                                                            </>
                                                            :""}
                                                        <div className="text-shipment">{queryDataForcard.queryFor === "Air" ?"Clearance Date":"Handover Date"} : <span>{queryDataForcard && queryDataForcard.clearenceDate ? queryDataForcard.clearenceDate : ""}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard-ratesheading">
                                            <div className="ship-heading">
                                                <h3 className="font-weight-bold">Rates Quoted</h3>
                                            </div>
                                            <div className={this.state.allCharges && this.state.allCharges.length==1?"dashboard-rate dashboard-heights":"dashboard-rate"}>
                                                <div className="d-flex flex-wrap flex-column cards-first">
                                                    {this.state.allCharges && this.state.allCharges.map((obj, i) => {
                                                        return (
                                                            <>
                                                                <CourierRatesCard
                                                                    isUserLoggedIn = {(values) => {
                                                                        this.setState({
                                                                            modalLarge : values,
                                                                        })
                                                                    }}
                                                                    chargeIndex = {i}
                                                                    coupanRemove = {this.state.coupanRemove}
                                                                    getCoupanDetails = {(value)=>{
                                                                        this.setState({
                                                                            applyCoupan : value.applyCoupan,
                                                                            coupanId : value.coupanId,
                                                                            rateIdForCoupan : value.rateId
                                                                        },()=>{
                                                                        })
                                                                    }}
                                                                    createLoggedSucess = {this.props.StoredRateData}
                                                                    BookingID={i}
                                                                    data={obj}
                                                                    lengthCard={this.state.allCharges.length}
                                                                    mainQueryData={this.state.mainQueryData}
                                                                    queryDataForcard={this.state.queryDataForcard}
                                                                    title={obj && obj.shippingLineName ? obj.shippingLineName : ""}
                                                                    detail={obj.transitTime}
                                                                    sendDataToAddQuery={mainQueryData => {
                                                                        this.setState({
                                                                            mainQueryData: mainQueryData,
                                                                            withLogin: true
                                                                        }, () => {
                                                                            this.prepareToSaveDataForLogin()
                                                                        })
                                                                    }}
                                                                    // percent={100}
                                                                    progressText={obj && obj.converted === true ? obj.convertedAmount : obj.amountC}
                                                                    login={(values) => {
                                                                        if (values === true) {
                                                                            this.toggleLarge(i)
                                                                        }
                                                                    }}
                                                                />

                                                            </>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <LeafletMap />
                                </Container>
                            </div>
                            : ""}




            </>
        )}
}

const mapStateToProps = (state) => {
    const {RateNotFound,OpenPerForma,BookingDataFromRevert,BookingMassage,queryNo,StoredRateData,allCharges,queryDataForcard} = state.advanceSearchReducer;

    const {messageShowShipper} = state.authUser;
    return {
        messageShowShipper,OpenPerForma,BookingDataFromRevert,BookingMassage,queryNo,RateNotFound,StoredRateData,allCharges,queryDataForcard

    }
};

const courierNewQueryWithRoute = withRouter(courierNewQuery)

export default connect(mapStateToProps, {sendBookingRates, getCourierCharges,sendBookingRatesSuccess,sendCourierQuery,getCharges,sendRatesForStore,getCourierChargestError,getChargestError
})(courierNewQueryWithRoute)

