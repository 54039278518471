import React, {Component} from "react";
import {PAGE_PATHS} from "../../util/PageRoute";
import AppLayout from "../../layout/AppLayout";

class Error extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }




    render() {

        return (
            <AppLayout>
                <div className="auth-page">
                    <div className="auth-center"><img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.32237908367778223-1679998858777.png" alt="404 Page"/>
                    {/*<h2>404</h2>*/}
                    {/*    <h4 className="unauth">OOPS! PAGE NOT FOUND</h4>*/}
                        <hr style={{border: '1px solid #000'}}/><h3>Sorry, the page you're looking for doesn't exists.</h3>
                        <div className="back-auths">
                            <button onClick={(e)=>{
                                this.props.history.push(PAGE_PATHS.MAIN_PAGE);
                                window.location.reload();
                            }}>Go To Home Page</button>
                        </div>
                    </div>
                </div>
            </AppLayout>
        )}

}



export default Error;
