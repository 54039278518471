import React from "react";
import {Route,Redirect} from "react-router-dom";
import {connect} from "react-redux";
import AppLayout from "./AppLayout";
const MainRoutesManagerForProfileWithoutAuth = ({render, ...rest}) => {
        switch (rest.path) {
            default:
                return (
                    <Route
                        {...rest}
                        render={matchProps => (
                            <AppLayout>
                                <div className="image-change">
                                {render(matchProps)}
                                </div>
                                </AppLayout>
                        )}
                    />
                );
        }

};

const mapStateToProps = (state) => {
    return {state}
};

export default connect(mapStateToProps)(MainRoutesManagerForProfileWithoutAuth);