import {
    INVOICE_FETCH_FAILURE,
    INVOICE_FETCH_MASTER,
    INVOICE_FETCH_RESPONSE,

    INVOICE_PREVIEW,
    INVOICE_CREATE,
    INVOICE_CREATE_RESPONSE,
    INVOICE_UPDATE,
    INVOICE_UPDATE_RESPONSE,
    INVOICE_DELETE,
    INVOICE_DELETE_RESPONSE,
    INVOICE_PRINT_REQUEST,
    INVOICE_PRINT_REQUEST_SUCCESS,
    INVOICE_PRINT_REQUEST_FAIL,
    INVOICE_GETDATA_REQUEST, INVOICE_GETDATA_REQUEST_FAIL,INVOICE_GETDATA_REQUEST_SUCCESS,
    INVOICE_CHECKED_REQUEST,
    INVOICE_CHECKED_REQUEST_SUCCESS,
    INVOICE_CHECKED_REQUEST_FAIL,

} from "../actions";

const initState = {
    loading: false,
    error: '',
    totalItemCount: 0,
    invoiceFormValues: {}
};


const invoiceMasterReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case INVOICE_FETCH_MASTER:
            // console.log("In Invoice Master Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};
        case INVOICE_FETCH_RESPONSE:
            // console.log("in Invoice Master Success");
            return {...state, loading: false, items: payload.data, totalItemCount: payload.total, error: ''};

        case INVOICE_FETCH_FAILURE:
            // console.log("in Invoice Master Failure" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload.message};

        case INVOICE_PREVIEW:
            // console.log("In Invoice Master Preview  " + JSON.stringify(payload));
            return {...state, loading: false, airfreightFormValues: payload};

        case INVOICE_CREATE:
            // console.log("In Invoice Reducer Save " + payload);
            return {...state, loading: true, response: ''};

        case INVOICE_CREATE_RESPONSE:
            // console.log("in Invoice CREATE Response" + JSON.stringify(payload));
            return {...state, loading: false, result: '', response: payload.message};

        case INVOICE_UPDATE:
            // console.log("In INVOICE_DESTINATION_UPDATE " + payload);
            return {...state, loading: true, response: ''};

        case INVOICE_UPDATE_RESPONSE:
            // console.log("in INVOICE_DESTINATION_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state, loading: false, result: '', response: payload.message};

        case INVOICE_DELETE_RESPONSE:
            // console.log("in INVOICE_DESTINATION_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state, loading: false, result: '', response: payload.message};

        case INVOICE_PRINT_REQUEST:
            // console.log("In Invoice print Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};
        case INVOICE_PRINT_REQUEST_SUCCESS:
            // console.log("in Invoice print Success");
            return {...state, loading: false, items: payload.data, totalItemCount: payload.total, error: ''};
        case INVOICE_PRINT_REQUEST_FAIL:
            // console.log("in Invoice print Failure" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload.message};
        case INVOICE_GETDATA_REQUEST:
            // console.log("In Invoice print Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};
        case INVOICE_GETDATA_REQUEST_SUCCESS:
            // console.log("in Invoice print Success");
            return {...state, loading: false, items: payload, error: ''};
        case INVOICE_GETDATA_REQUEST_FAIL:
            // console.log("in Invoice print Failure" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload};
        case INVOICE_CHECKED_REQUEST_SUCCESS:
            // console.log("in Invoice print Success");
            return {...state, loading: false, items: payload, error: ''};
        case INVOICE_CHECKED_REQUEST_FAIL:
            // console.log("in Invoice print Failure" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload};
        default:
            return initState;
    }
};

export default invoiceMasterReducer;
