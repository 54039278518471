import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";
class CourierFreight extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();
    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        return (
    <>
<MetaTags>
        <title> Inland Transportation  | Zipaworld </title>
        <meta name="title" content="Expert in Digital Freight, Courier Services, and Express Delivery"/>
        <meta name="description" content="Explore ZipaWorld's cutting-edge digital freight and courier services. We ensure swift and secure express deliveries. Experience the future of logistics with ZipaWorld"/>
        <meta name="keywords" content="Cheapest Courier Shipping from India to USA, Free Home Pick Up Courier Service, Document & Parcel Shipping Services, Fastest courier service in delhi, Fastest courier service near me, Best international courier services in india, Send courier from uk to india, Home pick up courier service near me, With Zipaworld send Courier to Anywhere from India in most cheapest and simple way"/>
    </MetaTags>

            <AppLayout>
                <div className="services_my services_content_courier" ref={this.testRef}>
                    {/*<hiii></hiii>*/}
                </div>
                <div className="services_content paraHyperlink">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1><a href="https://zipaworld.com/BlogDescription/Digital-Freight-Forwarding" target="_blank">DIGITAL FREIGHT FORWARDING</a> AND <span>Courier Services</span></h1>
                                <p><Link to="/About">The aim of Zipaworld</Link> is not to provide digital freight forwarding services & courier services only.
                                    Since our inception, we carry along with the larger aim of integrating the whole supply chain
                                    digitally. We target at filling the loopholes of the supply chain and tying loose ends with the help
                                    of technology. </p>
                                <p>Digital freight, <Link to="/OceanFreight">ocean freight</Link>, <Link to="/RoadFreight">road and inland freight</Link> are encompassed in the B2B model of
                                    <Link to="/"> Zipaworld</Link> catering to the exporters, importers, manufacturers, traders, and distributors of
                                    various goods, where they can easily avail logistics services without having to go from pillar to
                                    post. The ease of availing all logistics services from a single window. We are the best option for
                                    those who are searching for the fastest courier service in Delhi. </p>
                                <p>In view of this need of the hour, where everyone seeks quick and prompt service levels of the
                                    highest degree, Zipaworld has embodied the Courier Services module in the same portal where
                                    not just the B2B but B2C relations can be a part of Zipaworld. </p>

                            </div>
                        </div>
                        <div className="row other_image_services">
                            <div className="col-lg-6">
                                <p>Zipaworld is not just for businesses, but also for individuals. Now anyone can book their courier
                                    and express deliveries with ease. Zipaworld is catering to more than 15000 zip codes across
                                    India and is ever-expanding. </p>
                                <p>Not just that, Zipaworld provides you digital seamless integrated booking process. We understand your priorities and love to connect with your
                                    emotions. Zipaworld provides home pick-up services from more than 2000 zip codes across
                                    India. We are working relentlessly to increase these numbers. When the parcels can be
                                    delivered easily to every corner of the country in this era of e-commerce, we believe they can be
                                    easily picked up as well. </p>
                            </div>
                            <div className="col-lg-6">
                                <div className="courier_two_img">
                                    <img className="lazyload" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.533697086848921-1681908573987.jpg" alt="Courier Service"/>
                                    <img className="lazyload" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6792892272187281-1681908621374.png" alt="Courier Service"/>

                                </div>
                            </div>

                        </div>

                        <div className="row other_image_services">
                            <div className="col-lg-6">
                                <div className="left_service_Air">
                                    <img className="lazyload" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5473362153609331-1681908673340.jpg" alt="Courier Service"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p>Zipaworld is your source for booking courier service of the topmost quality for not just domestic zip codes within India, but also international zip codes. One can book and ship their documents, parcels, goods to more than 200 countries across the world.</p>
                                <p>You name the Zip Code and we have it in our portal. <Link to="/">See your courier prices, book your courier, get instant docket number</Link>, <Link to="/TrackingSearchPage">track your courier</Link> all from the same website and at your convenience and ease.</p>
                                <p>While we have made the portal so easy to use that even a first time user can easily relate to it. Yet the below video can help you understand how to book courier services from Zipaworld portal.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="demo_tour mt-5">
                    <div className="container">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4>Courier Demo Tour</h4>
                                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda blanditiis et laudantium nobis non odio quaerat quo sed voluptas voluptate. Aliquam corporis doloribus hic minus modi nulla odio quaerat sunt?</p>*/}
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="demo_tour_vedio">
                    <div className="container">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="box-video wow fadeInUp" data-wow-delay="400ms">
                                        <div className="box">
                                            {/*<div className="overlay"></div>*/}
                                            <div className="flat-video-fancybox">
                                                <a className="fancybox" data-type="iframe" target="_blank"
                                                   href="https://youtu.be/8zG7N3Lnly8">
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterHome/>
            </AppLayout>
            </>

        );
    }


}



export default CourierFreight;
