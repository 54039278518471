import React, {Component, Fragment} from "react";
import AppLayout from "../../../layout/AppLayout";
import FooterHome from "./FooterHome";
import RestClient from "../../../../src/util/RestClient";
import { GET_TRACKING_API} from "../../../../src/constants";
import {faAngleDown, faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class TrackingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsMoreDetails:false,
            pickUpTrack : false,
            pickUpDate : '',
            originClearanceTrack : false,
            originClearanceDate : '',
        };

    }

    componentDidMount() {
        let quoteId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        this.GET_TRACKING_DATA({quoteId:quoteId}).then(responce=> {
            if(responce.success && responce.result){
                let TrackingResult = responce && responce.result ? responce.result : "";
                this.setState({
                    TrackingResult:TrackingResult,
                    blNo: TrackingResult.queryData.blNo,
                    jobNo: TrackingResult.queryData.jobNo,
                    data:responce.result && responce.result.data ? responce.result.data: "",
                },()=>{
                    let pickUp =this.state.TrackingResult && this.state.TrackingResult.data.filter(ele =>(ele.activityType==="Pick up" && ele.status ==="Completed"))
            if(pickUp.length){
                this.setState({
                    pickUpTrack : true,
                    pickUpDate : pickUp[0].date
                })
            }
            let drop =this.state.TrackingResult && this.state.TrackingResult.data.filter(ele =>(ele.activityType==="Drop" && ele.status ==="Completed"))
            if(drop.length){
                this.setState({
                    dropTrack : true,
                    dropDate : drop[0].date
                })
            }
            let originClearance =this.state.TrackingResult && this.state.TrackingResult.data.filter(ele =>(ele.activityType==="Origin Clearance" && ele.status ==="Completed"))
            if(originClearance.length){
                this.setState({
                    originClearanceTrack : true,
                    originClearanceDate : originClearance[0].date
                })
            }
            let freight =this.state.TrackingResult && this.state.TrackingResult.data.filter(ele =>(ele.activityType==="Freight" && ele.status ==="Completed"))
            if(freight.length){
                this.setState({
                    freightTrack : true,
                    freightDate : freight[0].date
                })
            }
            let destinationClearance =this.state.TrackingResult && this.state.TrackingResult.data.filter(ele =>(ele.activityType==="Destination Clearance" && ele.status ==="Completed"))
            if(destinationClearance.length){
                this.setState({
                    destinationClearanceTrack : true,
                    destinationClearanceDate : destinationClearance[0].date
                })
            }
                })
            }
        })
        // this.GET_QUERY_PREVIEW_DATA({id:quoteId}).then(responce=> {
        //     if(responce.success && responce.result){
        //         let QueryResult = responce && responce.result ? responce.result : "";
        //         // console.log(JSON.stringify(QueryResult))

        //     }
        // })
        // this.GET_TRACK_QUOTEID_DATA({id:quoteId}).then(responce=> {
        //     if(responce.success && responce.result){
        //         let TrackQuoteResult = responce && responce.result ? responce.result : "";
        //         // console.log("value of tracking quote ifdddd",JSON.stringify(TrackQuoteResult))
        //     }
        // })

            
        

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.collapsMoreDetails && this.state.collapsMoreDetails !== prevProps.collapsMoreDetails) {
            this.setState({collapsMoreDetails: this.props.collapsMoreDetails})
        }
    }
    GET_TRACKING_DATA = async (dataForServer) => {
        return await RestClient({
            url: GET_TRACKING_API,
            method: "POST",
            data: dataForServer
        });
    }

    render() {

        return (
            <>
                <AppLayout>
                    <div className="dashboard-tracking">
                        <div className="tracking-shipment">
                            <div className="container">
                                <div className="flights-types1 our_card">

                                </div>
                                <div className="table-background">
                                    <div className="table-data">
                                        <h4>Tracking Shipment</h4>
                                        <hr/>
                                        <div className="track-imgs">
                                            <div className="ori-desti">
                                                <img src="../../../assets/img/path-design.png" alt="path"/>
                                                <div className="pick-off">{this.state.TrackingResult && this.state.TrackingResult.queryData && this.state.TrackingResult.queryData.originAirport.name}</div>
                                                <div className="drop-off">{this.state.TrackingResult && this.state.TrackingResult.queryData && this.state.TrackingResult.queryData.destinationAirport.name}</div>
                                            </div>
                                            <div className="d-flex flex-wrap mt-70 mb-70">
                                            <div className="p-2">
                                                <div className="main-card mb-3">
                                                    <div className="">
                                                        <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">

                                                        <div  className="vertical-timeline-item vertical-timeline-element">
                                                                    <div><span className="vertical-timeline-element-icon bounce-in">
                                                                    <i className="badge badge-dot badge-dot-xl  badge-success"> </i> </span>
                                                                        <div
                                                                            className="vertical-timeline-element-content bounce-in">

                                                                            <h4 className={this.state.pickUpTrack ? "green-status" :"timeline-title" }>Pick Up</h4>
                                                                            <span
                                                                                className="vertical-timeline-element-date">{this.state.pickUpDate}</span> 
                                                                              
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            <div  className="vertical-timeline-item vertical-timeline-element">
                                                                <div><span className="vertical-timeline-element-icon bounce-in">
                                                                    <i className="badge badge-dot badge-dot-xl  badge-success"> </i> </span>
                                                                    <div
                                                                        className="vertical-timeline-element-content bounce-in">
                                                                        <h4 className={this.state.originClearanceTrack ? "green-status" :"timeline-title" }>Origin Clearance</h4>
                                                                            <span
                                                                                className="vertical-timeline-element-date">{this.state.originClearanceDate}</span> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="vertical-timeline-item vertical-timeline-element">
                                                                <div><span
                                                                    className="vertical-timeline-element-icon bounce-in"> <i
                                                                    className="badge badge-dot badge-dot-xl  badge-success"> </i> </span>
                                                                    <div className="vertical-timeline-element-content bounce-in">
                                                                    <h4 className={this.state.freightTrack ? "green-status" :"timeline-title" }>Freight</h4>

                                                                        
                                                                        <span className="vertical-timeline-element-date">{this.state.freightDate}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="vertical-timeline-item vertical-timeline-element">
                                                                <div><span
                                                                    className="vertical-timeline-element-icon bounce-in"> <i
                                                                    className="badge badge-dot badge-dot-xl  badge-success"> </i> </span>
                                                                    <div
                                                                        className="vertical-timeline-element-content bounce-in">
                                                                                                                                                    <h4 className={this.state.destinationClearanceTrack ? "green-status" :"timeline-title" }>Destination Clearance</h4>
                                                                            <span className="vertical-timeline-element-date">{this.state.destinationClearanceDate}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="vertical-timeline-item vertical-timeline-element">
                                                                <div><span
                                                                    className="vertical-timeline-element-icon bounce-in"> <i
                                                                    className="badge badge-dot badge-dot-xl badge-success"> </i> </span>
                                                                    <div
                                                                        className="vertical-timeline-element-content bounce-in">
                                                                        <h4 className={this.state.dropTrack ? "green-status" :"timeline-title" }>Drop</h4>
                                                                            <span className="vertical-timeline-element-date">{this.state.dropDate}</span>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <table className="table table-hover table-fixed">
                                            <thead>
                                                <tr>
                                                <th>#</th>
                                                <th>Shipment Name</th>
                                                <th>Status</th>
                                                <th>BL No.</th>
                                                    {/* <th>City</th>
                                                    <th>Position</th>
                                                    <th>Age</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* <tr>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                             <input type="checkbox" className="form-check-input" value=""/>
                                                        </div>
                                                    </th>
                                                    <td>Jerry</td>
                                                    <td><span className="green-status">Delivered</span></td>
                                                    <td>Italy</td>
                                                    <td>Bari</td>
                                                    <td>Editor-in-chief</td>
                                                    <td>41</td>
                                                </tr> */}
                                                <tr>
                                                    <td scope="row">
                                                        <div className="tracking-more" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            collapsMoreDetails: !this.state.collapsMoreDetails,
                                                        })

                                                            }}><a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1"
                                                                  aria-expanded="true"
                                                                  aria-controls="collapseOne1" className="collapsed"><FontAwesomeIcon icon={faAngleDown} className="rotate-icon"/>
                                                            </a>
                                                        </div>
                                                    </td>

                                                    <td>{this.state.jobNo}</td>
                                                <td><span className="green-status">Joplin</span></td>
                                                <td>{this.state.blNo}</td>
                                                    {/* <td>Warsaw</td>
                                                    <td>Video Maker</td>
                                                    <td>39</td> */}
                                                </tr>
                                                {this.state.collapsMoreDetails === true ?
                                                <tr style={{backgroundColor:'rgba(0,0,0,.075)'}}>
                                                    <td colSpan="7">
                                                        <div className={this.state.collapsMoreDetails === true ? "collapse  show" : "collapse "}
                                                            role="tabpanel" aria-labelledby="headingOne1"
                                                            data-parent="#accordionEx">
                                                                <tr>
                                                {/* <th>#</th> */}
                                                <th>Status</th>
                                                <th>Activity Type</th>
                                                <th>Date</th>
                                                <th>Details</th>
                                                {/* <th>Position</th>
                                                <th>Age</th> */}
                                            </tr>
                                                            {/* {Array.isArray(this.state.data) && this.state.data.length >= 1 ? */}
                                                                {this.state.TrackingResult && this.state.TrackingResult.data.map((obj, i) => {
                                                                    return (
                                                                        <>
                                                                            <div className="d-flex flex-wrap justify-content-between">
                                                                            <td>
                                                                            <div className="">
                                                                                {/* <h3><b>Status</b></h3> */}
                                                                                <p>{obj.status}</p>
                                                                            </div>
                                                                            </td>
                                                                            <td>
                                                                            <div className="">
                                                                                {/* <h3><b>Activity Type</b></h3> */}
                                                                                <p>{obj.activityType}</p>
                                                                            </div>
                                                                            </td>
                                                                            <td>
                                                                            <div className="">
                                                                                {/* <h3><b>Date</b></h3> */}
                                                                                <p>{obj.date}</p>
                                                                            </div></td>
                                                                            <td>
                                                                            <div className="details-track">
                                                                                {/* <h3><b>Details</b></h3> */}
                                                                                <p>{obj.discription}</p>
                                                                            </div>
                                                                            </td>
                                                                        </div>
                                                                        </>
                                                                    );
                                                                })}

                                                        </div>
                                                    </td>
                                                </tr> : ""
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <FooterHome/>
                    </div>
                </AppLayout>


            </>
        )}
}




export default TrackingPage;

