import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD, NOTIFICATION_USER,
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError, subscribeNotificationError, subscribeNotificationSuccess
} from './actions';
import RestClient, {setToken} from "../../util/RestClient";
import {LOGIN_API, NOTIFICATION_SUBSCRIPTION, SIGNUP_API} from "../../constants";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cryptr from 'cryptr';
import {PAGE_PATHS} from "../../util/PageRoute";
import  {history}  from "../../helpers/history";

const cryptr=new Cryptr('ccwcjwciwegfwedwedviwedwe')

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
    yield takeEvery(NOTIFICATION_USER, notificationSubscribe);
}

// const loginWithEmailPasswordAsync = async (email, password) =>
//     await auth.signInWithEmailAndPassword(email, password)
//         .then(authUser => authUser)
//         .catch(error => error);



function* loginWithEmailPassword({ payload }) {

    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, payload);
        if (loginUser.success) {

            toast.success(loginUser.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.setItem('already_registered', JSON.stringify(true));
            localStorage.setItem('customer', JSON.stringify(loginUser));
            localStorage.setItem('auth_key', loginUser.result.authToken);
            localStorage.setItem('cust_id', loginUser.result.customerData.customerName);
            localStorage.setItem('otpVerified', true);
            localStorage.removeItem('ContactDeatails');
            setToken(loginUser.result.authToken)
            localStorage.setItem('customerLogin', cryptr.encrypt(JSON.stringify(payload)))
            localStorage.setItem('typeOfBussiness', loginUser && loginUser.result &&  loginUser.result.customerData && loginUser.result.customerData.typeOfBussiness);
            let typeOfBussiness = localStorage.getItem('typeOfBussiness')
            if(localStorage.getItem('typeOfBussiness')){
                if(typeOfBussiness === "Individual"){
                    localStorage.setItem("queryType","CourierQuery")
                }
            }
            yield put(loginUserSuccess(loginUser.message));
            //window.location.reload()
        } else {
            toast.error(loginUser.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(loginUserError(loginUser.message));
        }

    } catch (error) {
        yield put(loginUserError(error));
    }
}
const loginWithEmailPasswordAsync = async (params) => {
    return await RestClient({
        url: LOGIN_API,
        method: "POST",
        data: params
    })
};


function* notificationSubscribe({ payload }) {

    try {
        const loginUser = yield call(notificationSubscribeAsync, payload);
        if (loginUser.success) {
            // toast.success(loginUser.message, {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });

            yield put(subscribeNotificationSuccess(loginUser.message));
        } else {
            // toast.error(loginUser.message, {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });


            yield put(subscribeNotificationError(loginUser.message));
        }

    } catch (error) {
        yield put(subscribeNotificationError(error));
    }
}
const notificationSubscribeAsync = async (params) => {
    // console.log(JSON.stringify("In Hit API" + JSON.stringify(params)));
    return await RestClient({
        url: NOTIFICATION_SUBSCRIPTION,
        method: "POST",
        data: params
    })
};


export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

function* registerWithEmailPassword({ payload }) {
    // const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, payload);
        if (registerUser.success) {
            toast.success(registerUser.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.setItem('already_registered', JSON.stringify(true));
            localStorage.setItem('customer', JSON.stringify(registerUser));
            localStorage.setItem('auth_key', registerUser.result.authToken);
            localStorage.setItem('cust_id', registerUser.result.customerData.customerName);
            localStorage.removeItem('ContactDeatails');
            setToken(registerUser.result.authToken)
            localStorage.setItem('customerLogin', cryptr.encrypt(JSON.stringify(payload)))
            localStorage.setItem('typeOfBussiness', registerUser && registerUser.result &&  registerUser.result.customerData && registerUser.result.customerData.typeOfBussiness);
            let typeOfBussiness = localStorage.getItem('typeOfBussiness')
            if(localStorage.getItem('typeOfBussiness')){
                if(typeOfBussiness === "Individual"){
                    localStorage.setItem("queryType","CourierQuery")
                }
            }
            if (typeOfBussiness === "Individual"){
                history.push('/CustomerKYCForIndividuals')
            }else {
                history.push('/CustomerKYC')
            }
            
            yield put(registerUserSuccess(registerUser.message));
            /*window.location.reload()*/

        } else {
            toast.error(registerUser.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(registerUserError(registerUser.message));
        }

    } catch (error) {
        yield put(registerUserError(error));
    }
}
const registerWithEmailPasswordAsync = async (params) => {
    return await RestClient({
        url: SIGNUP_API,
        method: "POST",
        data: params
    })
};



export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({ payload }) {
    const { history } = payload

    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('user_id');
        localStorage.removeItem('already_registered');
        localStorage.removeItem('auth_key');
        localStorage.removeItem('custUser');
        localStorage.removeItem('customer');
        localStorage.removeItem('customerLogin');
    } catch (error) {

    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await auth.sendPasswordResetEmail(email)
        .then(user => user)
        .catch(error => error);
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (!forgotPasswordStatus) {
            yield put(forgotPasswordSuccess("success"));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        if (!resetPasswordStatus) {
            yield put(resetPasswordSuccess("success"));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
    ]);
}