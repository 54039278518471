import {
        CONSIGNEE_MANAGER_FETCH_FAILURE,
        CONSIGNEE_MANAGER_FETCH_MASTER,
        CONSIGNEE_MANAGER_FETCH_RESPONSE,
        CONSIGNEE_CREATE,
        CONSIGNEE_CREATE_RESPONSE,
        CONSIGNEE_MASTER_PREVIEW,
        CONSIGNEE_MANAGER_DELETE,
        CONSIGNEE_MANAGER_DELETE_RESPONSE,
        CONSIGNEE_MASTER_UPDATE,
        CONSIGNEE_MASTER_UPDATE_RESPONSE,


} from "../actions";

export const saveConsignee = (shipperDetails) => ({
    type:   CONSIGNEE_CREATE,
    payload: shipperDetails
});
export const showConsigneeResponce = (message) => ({
    type:   CONSIGNEE_CREATE_RESPONSE,
    payload: {message}
});

export const onConsigneeMasterLoadRequest = (shipperManagerParameters) => ({
    type:   CONSIGNEE_MANAGER_FETCH_MASTER,
    payload: shipperManagerParameters
});
export const onConsigneeMasterFetched = (shipperManagerResponse) => ({
    type:   CONSIGNEE_MANAGER_FETCH_RESPONSE,
    payload: shipperManagerResponse
});
export const onConsigneeMasterFetchedFailed = (message) => ({
    type:   CONSIGNEE_MANAGER_FETCH_FAILURE,
    payload: {message}
});
export const previewConsigneeMasterDetail = (shipperDetails) => ({
    type:   CONSIGNEE_MASTER_PREVIEW,
    payload: shipperDetails
});

export const deleteConsigneeMasterDetail = (ConsigneeDetails) => ({
    type: CONSIGNEE_MANAGER_DELETE,
    payload: ConsigneeDetails
});

export const updatConsigneeMaster = (DgDetails) => ({
    type:CONSIGNEE_MASTER_UPDATE,
    payload: DgDetails
});

export const updatConsigneeMasterResponse = (message) => ({
    type:CONSIGNEE_MASTER_UPDATE_RESPONSE,
    payload: {message}
});

export const deleteConsigneeMasterResponse = (message) => ({
    type: CONSIGNEE_MANAGER_DELETE_RESPONSE,
    payload: {message}
});