import React, {Component} from "react";
import {Label, Input, FormGroup, Modal, ModalHeader, ModalBody} from "reactstrap";
import {Link, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {loginUser, registerUser} from "../../../redux/actions";
import axios from "axios"
import FilterRates from "../../../components/FilterRates";
import {history} from "../../../helpers/history";

import {
    CREATE_BOOKING_API_ROAD,
    CREATE_BOOKING_API_COURIER,
    GET_BY_QUOTE_ID_ROAD,
    GET_BY_QUOTE_ID_COURIER,
    FASTEST_CHEAPEST_DATA_ROAD,
    FASTEST_CHEAPEST_DATA_COURIER,
    RATE_SINGLE_DATA_ROAD,
    RATE_SINGLE_DATA_COURIER,
    GET_BY_QUOTE_ID_TRANSIT_TIME_ROAD,
    GET_BY_QUOTE_ID_TRANSIT_TIME_COURIER,
    STORE_BOOKING_API_ROAD,
    STORE_BOOKING_API_COURIER,
    STORE_QUERY_API_ROAD,
    STORE_QUERY_API_COURIER,
    UPDATE_RATE_FOR_CREDIT_ROAD,
    UPDATE_RATE_FOR_CREDIT_COURIER,
    GET_COURIER_CHARGES_API,
    STORE_QUERY_RATES_API,
    TRANSIT_TIME_GET_DATA_COURIER,
    TRANSIT_TIME_GET_DATA_ROAD,
    GET_QUERY_REVERT_AUTO_API_ROAD,
    GET_QUERY_REVERT_AUTO_API_COURIER,
    OFFER_VIEW_API,
    UPDATE_QUOTE_ID_API_ROAD,
    UPDATE_QUOTE_ID_API_COURIER,
    UPDATE_QUERY_API_ROAD,
    UPDATE_QUERY_API_COURIER,
    REMOVE_COUPAN_COURIER,
    REMOVE_COUPAN_ROAD,
    STORE_QUERY_RATES_API_ROAD,
    STORE_QUERY_RATES_API_COURIER, GET_COURIER_CHARGES_API_ROAD, GET_BY_QUOTE_ID_AIR, FASTEST_CHEAPEST_DATA_AIR
} from "../../../constants";
import {withRouter} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
    calculateChargesAccordingtoStructure,
    calculateChargesAccordingtoStructureIfDiscount,
    convertDateISO,
    getCustomerData,
    getCustomerGStTypeData,
    getCustomerPosData,
    getCustomerUserBranchCodeData,
    getCustomerUserBranchIDData,
    getCustomerUserBranchNameData,
    getCustomerUserBranchPosData,
    getCustomerIsInEuropeanValue,
    calculateChargesAccordingtoStructureIf15DaysNoDiscount,
    calculateChargesAccordingtoStructureIf30DaysNoDiscount,
    calculateChargesAccordingtoStructureIf15DaysAndDiscount,
    calculateChargesAccordingtoStructureIf30DaysAndDiscount,
    getBaseCurrency,
    getCustomerBranchId,
    getCustomerCurrency,
    getCustomerId,
    getLoggedInUserEmail,convertDateISOToCustomiseFormat,
} from "../../../util/CommonUtility";
import RestClient from "../../../util/RestClient";
import {PAGE_PATHS} from "../../../util/PageRoute";
import RateNotFoundCard from "../dashboards/RateNotFoundCard";
import {sendBookingRates, sendQuery} from "../../../redux/advanceSearch/advancSearchAction";
import PreviousCourierShipmentHistoryRateCard from "../../../components/PreviousCourierShipmentHistoryRateCard";
import CourierShipperConsigneeModal from "../dashboards/addCourierShipperAndConsignee";
import SignUp from "../../user/signUp";
import AddCoupanApplyForHome from "../dashboards/AddCoupanApplyForHome";
import AddCoupanApplyForPreview from "../dashboards/AddCoupanApplyForPreview";
import {faCircle, faClock, faDollarSign, faEuroSign, faPoundSign, faRupeeSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class CourierRateQuotedCardNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ChargeRateNotFoundModel:false,

            modalLarge: false,
            data: {},
            priviousRateData: {},
            title: "title",
            detail: "detail",
            percent: 80,
            progressText: "8/10",
            BookingID: "1",
            login: undefined,
            showCollaps:false,
            rateData:[],
            queryData:{},
            quoteData :{},
            originId:"",
            shipmentMode:"",
            destinationId:"",
            tarrifMode:"",
            priviousSelectedId:"",
            customerCurrency:"",
            baseCurrency:"",
            clearenceDate:"",
            queryNo:"",
            gross :"",
            volume :"",
            chargeable :"",
            totalPieces :"",
            density :"",
            noOfContainers :"",
            additionalService :"",
            commodity :"",
            customerId :"",
            custType :"",
            containerType :"",
            quoteId :"",
            rateType :"",
            shipperModel: false,
            modelLogin: false,
            shipperConsigneeData: {},
            remainingCharges: undefined,
            RateNotFoundModel: false,
            houses : "",
            NoOfContainer : "",
            rateSuccessResponce : "",
            totalGross : "",
            totalVolume : "",
            chargeableWeight : "",
            uploadedDimensions: [],
            dimention: {
                dimentionType: "",
                dimentions: []
            },
            serviceProvider:true,
            selectedTransit:[],
            activeFilter:"All",
            activeFilter1:"All",
            customerRatesCurrency:"",
            baseRatesCurrency:"",
            switchCurrency:true,
            creditDays:"",
            customerIDByLocalStorage:"",
            couponData:[],
            coupanNames:false,
            coupanApplyCheapest:false,
            coupanApplyFastest:false,
            currencyChangePop:false,
            currencySelected:"",
            currencyChange:false,
        };

    }


    componentDidMount() {

        if (this.props.rateQuotedShow) {
            this.setState({
                modalLarge: this.props.rateQuotedShow
            })
        }
        if (this.props.quoteDataForCard ) {
            this.setState({queryStatus: this.props.quoteDataForCard
            })
        }
        if (this.props.rateDataForCard ) {
            this.setState({rateDataForCard: this.props.rateDataForCard
            })
        }
        if(JSON.parse(localStorage.getItem("customer"))){
            let data = JSON.parse(localStorage.getItem("customer")).result;
            let typeOfCustomer = data && data.customerData && data.customerData.typeOfBussiness ? data.customerData.typeOfBussiness : "Bussiness"
            this.setState({
                typeOfBussiness : typeOfCustomer,
                customerIDByLocalStorage : data && data.customerBranchData && data.customerBranchData.customerId ? data.customerBranchData.customerId :""
            })
        }
    }

    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if(this.state.modalLarge === false)
            {
                this.props.setmodal(false)
            }
        });
    };
    StoreQueryApi =async (dataForServer)=>{
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? STORE_QUERY_API_ROAD : STORE_QUERY_API_COURIER,
            method: "POST",
            data: dataForServer
        });
    }
    GetRatesApi = async (dataForServer) => {
        return await RestClient({
            // url: GET_COURIER_CHARGES_API,
            url : this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? GET_COURIER_CHARGES_API_ROAD : GET_COURIER_CHARGES_API,
            method: "POST",
            data: dataForServer
        });
    }
    StoreRatesApi = async (data) => {
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? STORE_QUERY_RATES_API_ROAD : STORE_QUERY_RATES_API_COURIER,
          // url: STORE_QUERY_RATES_API,
          method: "POST",
          data: data,
        });
      };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }

    GetRatesQuery =async (dataForServer)=>{
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? GET_BY_QUOTE_ID_ROAD : GET_BY_QUOTE_ID_COURIER,
            method: "POST",
            data: dataForServer
        });
    }
    prepareToSaveDataWithoutLogin = () => {
        if (
          localStorage.getItem("already_registered") === "true" &&
          localStorage.getItem("already_registered").length > 0
        ) {
          let queryData = JSON.parse(localStorage.getItem("queryData"));
          queryData.shipperConsigneeData = this.state.shipperConsigneeData;
          queryData.customerId = getCustomerId();
          queryData.customerBranchId = getCustomerBranchId();
          queryData.customerCurrency = getCustomerCurrency();
          queryData.baseCurrency = getBaseCurrency();
          queryData.branchId = getCustomerUserBranchIDData();
          queryData.commercialInvoiceUrl= this.state.commercialInvoiceUrl;
            queryData.packingListUrl= this.state.packingListUrl;
          let EmailArry = [];
          let customerEmail = getLoggedInUserEmail();
          EmailArry.push(customerEmail)
          if (!localStorage.getItem("customer") === false) {
            let userData = JSON.parse(localStorage.getItem("customer"));
            let csBuddy = "";
            let pricingHead = "";
            let salesPerson = "";
            let financePerson = "";
            csBuddy =
              userData &&
              userData.result &&
              userData.result.csBuddyData &&
              userData.result.csBuddyData._id
                ? userData.result.csBuddyData._id
                : "";
    
            pricingHead =
              userData &&
              userData.result &&
              userData.result.customerBranchData &&
              userData.result.customerBranchData.pricingHead
                ? userData.result.customerBranchData.pricingHead
                : "";
    
            salesPerson =
              userData &&
              userData.result &&
              userData.result.customerBranchData &&
              userData.result.customerBranchData.salesPerson
                ? userData.result.customerBranchData.salesPerson
                : "";
    
            financePerson =
              userData &&
              userData.result &&
              userData.result.customerBranchData &&
              userData.result.customerBranchData.financePerson
                ? userData.result.customerBranchData.financePerson
                : "";
            queryData.csBuddy = csBuddy;
            queryData.pricingHead = pricingHead;
            queryData.salesPerson = salesPerson;
            queryData.financePerson = financePerson;
            queryData.customerMails = EmailArry;
            queryData.queryFor = this.state.queryFor ? this.state.queryFor : this.state.QueryData?.queryFor ;
          }
    
          this.props.sendQuery(queryData);
        }
      };

    prepareToSaveData = async () => {
        let queryNo = this.state.quoteId
        let ShipperConsigneeAlreadyStoreData = this.state.shipperConsigneeData
        this.setState({queryNo: queryNo})

        localStorage.setItem('queryNo', JSON.stringify(queryNo))
        let chargesData = JSON.parse(localStorage.getItem("selectedCharge"))
        let chargesArray = chargesData && chargesData.charges ? chargesData.charges : [] ;
        let CustGstType1 = getCustomerGStTypeData()
        let CustGstType = CustGstType1 && CustGstType1.gstType  ? CustGstType1.gstType : "" ;
        let Custpos = getCustomerPosData()
        let branchPos = getCustomerUserBranchPosData()
        let creditDays = localStorage.getItem("creditDays")
        let saleData = []
        if(chargesData.coupanApply){
            saleData =calculateChargesAccordingtoStructureIfDiscount(chargesArray,CustGstType,Custpos,branchPos)
        }
        if(creditDays == '15 Days' && !chargesData.coupanApply){
            saleData =calculateChargesAccordingtoStructureIf15DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
        }
        if(creditDays == "30 Days" && !chargesData.coupanApply){
            saleData =calculateChargesAccordingtoStructureIf30DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
        }
        if(creditDays == '15 Days' && chargesData.coupanApply){
            saleData =calculateChargesAccordingtoStructureIf15DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
        }
        if(creditDays == '30 Days' && chargesData.coupanApply){
            saleData =calculateChargesAccordingtoStructureIf30DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
        }
        if(creditDays != "15 Days" && creditDays != '30 Days' && !chargesData.coupanApply){
            saleData =calculateChargesAccordingtoStructure(chargesArray,CustGstType,Custpos,branchPos)
        }
        let salevalue = saleData
        let isCustomerBelongsToEuropeanCountry = getCustomerIsInEuropeanValue()
        // let salevalue = chargesData.coupanApply ? calculateChargesAccordingtoStructureIfDiscount(chargesArray,CustGstType,Custpos,branchPos,isCustomerBelongsToEuropeanCountry):calculateChargesAccordingtoStructure(chargesArray,CustGstType,Custpos,branchPos,isCustomerBelongsToEuropeanCountry)
        let containerTypeData = this.state.containerType
        let Dataobj = {
            quoteId: queryNo,
            houses: this.state.houses,
            rateId: chargesData && chargesData._id ? chargesData._id : "",
            remarks:"",
            otherRemarks:"",
            
            dimentions: this.state.uploadedDimensions,
            dimensions: this.state.uploadedDimensions,
            dimentionType: this.state.dimentionType,
            dimensionsType: this.state.dimentionType,
            customer:getCustomerData(),
            branchId : getCustomerUserBranchIDData(),
            branchName : getCustomerUserBranchNameData(),
            branchCode : getCustomerUserBranchCodeData(),
            branchPos : getCustomerUserBranchPosData(),
            reason : "",
            buyValue :[],
            saleValue : salevalue ? salevalue : [],
            otherIncentive : [],
            otherShipment : [],
            gstType : getCustomerGStTypeData() === "O-OVERSEAS" ? "IGST" :"",
            revertData: chargesData,
            remainingCharges : this.state.remainingCharges,
            shipperConsigneeData : ShipperConsigneeAlreadyStoreData,
            NoOfContainer : this.state.NoOfContainer,
            totalGross : this.state.totalGross,
            totalVolume : this.state.totalVolume,
            queryFor : this.state.queryFor ? this.state.queryFor : this.state.QueryData.queryFor,
            containerType : containerTypeData,
            selectedCustomerCurrency : this.state.currencySelected ? this.state.currencySelected : chargesData && chargesData.customerCurrency ? chargesData.customerCurrency:"",
            selectedXRate : this.state.selectedCurrencyRate ? this.state.selectedCurrencyRate : "",

        }
        this.setState({BookRateData:Dataobj,selectedCustomerCurrency:Dataobj && Dataobj.selectedCustomerCurrency ? Dataobj.selectedCustomerCurrency : this.state.customerCurrency},()=>{
            this.StoreBuySaleValue(Dataobj).then(OpenPerForma=>{
                if(OpenPerForma.message === "Added Successfully"){
                    localStorage.removeItem("selectedCharge")
                    // this.props.history.push(PAGE_PATHS.VIEW_MAWB_CUSTOMER + "/" + bookingid)
                    this.setState({allCharges:[], rateSuccessResponce : OpenPerForma && OpenPerForma.result ? OpenPerForma.result : {} },()=>{
                        this.sendtoFinalBooking()
                    })
                }
            })
        })

    }

    StoreBuySaleValue =async (dataForServer)=>{
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? STORE_BOOKING_API_ROAD : STORE_BOOKING_API_COURIER,
            method: "POST",
            data: dataForServer
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.OpenPerForma && this.props.OpenPerForma !== prevProps.OpenPerForma) {
            if(this.props.BookingMassage )
            {
                let bookingid = this.props.BookingDataFromRevert && this.props.BookingDataFromRevert._id ? this.props.BookingDataFromRevert._id : ""
                if(this.props.OpenPerForma === "Rate Inserted Successfully"){
                    this.props.history.push(PAGE_PATHS.VIEW_MAWB_CUSTOMER + "/" + bookingid)
                }
                if(this.props.OpenPerForma === "Added Successfully"){
                    localStorage.removeItem("selectedCharge")
                    // this.props.history.push(PAGE_PATHS.VIEW_MAWB_CUSTOMER + "/" + bookingid)
                    if(localStorage.getItem("typeOfBussiness")) {
                        let typeOfBussiness1 = localStorage.getItem("typeOfBussiness")
                        this.setState({
                            typeOfBussiness:typeOfBussiness1
                        },()=>{
                            this.setState({allCharges:[], rateSuccessResponce : this.props.BookingDataFromRevert },()=>{
                                this.sendtoFinalBooking()
                            })
                        })
                    }else{
                        this.setState({allCharges:[], rateSuccessResponce : this.props.BookingDataFromRevert },()=>{
                            this.sendtoFinalBooking()
                        })
                    }

                }
            }
            if(this.props.BookingMassage && this.props.BookingMassage.success === false)
            {
                this.setState({RateNotFoundModel : true})
            }

        }

        if (this.props.closeModal && this.props.closeModal !== prevProps.closeModal) {
            if (this.props.closeModal === true) {
                this.setState(prevState => ({modalLarge: prevState.modalLarge}),()=>{
                    if(JSON.parse(localStorage.getItem("customer"))){
                        let data = JSON.parse(localStorage.getItem("customer")).result;
                        this.setState({
                            customerIDByLocalStorage : data && data.customerBranchData && data.customerBranchData.customerId ? data.customerBranchData.customerId :""
                        },()=>{
                            if(this.state.ratesProceed && this.state.ratesProceed.coupanId){
                                this.removeCouponFunctionality(true)
                            }
                        })
                    }
                })
            }
        }

        if (this.props.queryNo && this.props.queryNo !== prevProps.queryNo) {
            let queryNoId =
              this.props.queryNo && this.props.queryNo._id
                ? this.props.queryNo._id
                : "";
            let ShipperConsigneeAlreadyStoreData =
              this.props.queryNo && this.props.queryNo.shipperConsigneeData
                ? this.props.queryNo.shipperConsigneeData
                : "";
            this.setState({ queryNo: queryNoId },()=>{
                localStorage.setItem("queryNo", JSON.stringify(queryNoId));
                let getChargesObj = JSON.parse(localStorage.getItem('selectedCharge'))
                let getCharges;
                if(getChargesObj){
                    getCharges = [getChargesObj]
                }
                let containerTypeData = this.state.containerType;
                let rateObj = {
                    quoteId: queryNoId,
                    revertData: getCharges,
                    shipmentMode: this.state.shipmentMode,
                };
                let newPayload = {
                    quoteId : this.state.quoteId,
                    newQuoteId : this.state.queryNo,
                    referenceJobNo:this.state.referenceJobNo
                }
                this.UpdateQuoteId(newPayload).then((responce) => {
                    if (responce.success) {
                        let savedRate = responce && responce.result ? responce.result : undefined;
                        if (responce.result== "") {

                            // if (savedRate !== undefined) {
                                let chargesData = JSON.parse(
                                    localStorage.getItem("selectedCharge")
                                );
                                let selectedCharge = {};
                                if (
                                    chargesData !== undefined &&
                                    chargesData !== "" &&
                                    chargesData !== null
                                ) {

                                    let CustGstType1 = getCustomerGStTypeData()
                                    let CustGstType = CustGstType1 && CustGstType1.gstType  ? CustGstType1.gstType : "" ;
                                    let Custpos = getCustomerPosData();
                                    let branchPos = getCustomerUserBranchPosData();
                                    let chargesData = JSON.parse(localStorage.getItem("selectedCharge"));
                                    let chargesArray =
                                        chargesData && chargesData.charges ? chargesData.charges : [];
                                    let discountApply = localStorage.getItem("discountGiven")
                                    let creditDays = localStorage.getItem("creditDays")
                                    let saleData = []
                                    if(discountApply){
                                        // alert('1')
                                        saleData =calculateChargesAccordingtoStructureIfDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays == '15 Days' && !discountApply){
                                        // alert('15Days no discount')
                                        saleData =calculateChargesAccordingtoStructureIf15DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays == "30 Days" && !discountApply){
                                        // alert('30 days no discount')
                                        saleData =calculateChargesAccordingtoStructureIf30DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays == '15 Days' && discountApply){
                                        // alert('15 days and discount')
                                        saleData =calculateChargesAccordingtoStructureIf15DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays == '30 Days' && discountApply){
                                        // alert('30 days and discount')
                                        saleData =calculateChargesAccordingtoStructureIf30DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays != "15 Days" && creditDays != '30 Days' && !discountApply){
                                        // alert('no credit and no discount')
                                        saleData =calculateChargesAccordingtoStructure(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    let salevalue = saleData

                                    let Dataobj = {
                                        quoteId: queryNoId,
                                        houses: this.state.houses,
                                        rateId:
                                            chargesData && chargesData._id
                                                ? chargesData._id
                                                : "",
                                        remarks: "",
                                        otherRemarks: "",
                                        pricingApproval: false,
                                        csBuddyApproval: false,

                                        customer: getCustomerData(),
                                        branchId: getCustomerUserBranchIDData(),
                                        branchName: getCustomerUserBranchNameData(),
                                        branchCode: getCustomerUserBranchCodeData(),
                                        branchPos: getCustomerUserBranchPosData(),
                                        reason: "",
                                        buyValue: [],
                                        saleValue: salevalue ? salevalue : [],
                                        otherIncentive: [],
                                        otherShipment: [],
                                        gstType:
                                            CustGstType === "O-OVERSEAS" ? "IGST" : "",
                                        revertData: chargesData,
                                        remainingCharges: this.state.remainingCharges,
                                        shipperConsigneeData: ShipperConsigneeAlreadyStoreData,
                                        NoOfContainer: this.state.NoOfContainer,
                                        totalGross: this.state.totalGross,
                                        totalVolume: this.state.totalVolume,
                                        activityType: this.state.activityType,
                                        incoTermsId: this.state.incoTermsId,
                                        incoTermsName: this.state.incoTermsName,
                                        containerType: containerTypeData,
                                        selectedCustomerCurrency : this.state.currencySelected ? this.state.currencySelected : chargesData && chargesData.customerCurrency ? chargesData.customerCurrency:"",
                                        selectedXRate : this.state.selectedCurrencyRate ? this.state.selectedCurrencyRate : "",

                                    };
                                    this.setState({ BookRateData: Dataobj }, () => {
                                        this.props.sendBookingRates(Dataobj);
                                    });
                                }
                            // }
                        }
                    }
                })

            });
      

            // }
          }
        if (this.props.rateQuotedShow && this.props.rateQuotedShow !== prevProps.rateQuotedShow) {
            this.setState({
                modalLarge: this.props.rateQuotedShow
            })
        }

        if (this.props.rateDataForCard && this.props.rateDataForCard !== prevProps.rateDataForCard) {
            this.setState({
                queryData: this.props.rateDataForCard,
                priviousRateData: this.props.rateDataForCard && this.props.rateDataForCard.bookingData && this.props.rateDataForCard.bookingData.rateData  ? this.props.rateDataForCard.bookingData.rateData : {} ,
                //quoteData : this.props.rateDataForCard && this.props.rateDataForCard.bookingData && this.props.rateDataForCard.bookingData.quoteData  ? this.props.rateDataForCard.bookingData.quoteData : {} ,
                originId:this.props.rateDataForCard && this.props.rateDataForCard.originDoor && this.props.rateDataForCard.originDoor.country && this.props.rateDataForCard.originDoor.country ? this.props.rateDataForCard.originDoor.country : "",
                destinationId:this.props.rateDataForCard && this.props.rateDataForCard.originDoor && this.props.rateDataForCard.destinationDoor.country && this.props.rateDataForCard.destinationDoor.country ? this.props.rateDataForCard.destinationDoor.country : "",
                clearenceDate:this.props.rateDataForCard && this.props.rateDataForCard.clearenceDate  ? this.props.rateDataForCard.clearenceDate : "",
                quoteId:this.props.rateDataForCard && this.props.rateDataForCard._id  ? this.props.rateDataForCard._id : "",
                gross : this.props.rateDataForCard && this.props.rateDataForCard.grossWeight && this.props.rateDataForCard.grossWeight  ? this.props.rateDataForCard.grossWeight : "",
                volume : this.props.rateDataForCard && this.props.rateDataForCard.volumeWeight && this.props.rateDataForCard.volumeWeight  ? this.props.rateDataForCard.volumeWeight : "",
                chargeable : this.props.rateDataForCard && this.props.rateDataForCard.chargeableWeight && this.props.rateDataForCard.chargeableWeight  ? this.props.rateDataForCard.chargeableWeight : "",
                totalPieces : this.props.rateDataForCard && this.props.rateDataForCard.totalPieces   ? this.props.rateDataForCard.totalPieces : "",
                noOfContainers : this.props.rateDataForCard && this.props.rateDataForCard.noOfContainers   ? this.props.rateDataForCard.noOfContainers : "",
                density : this.props.rateDataForCard && this.props.rateDataForCard.density   ? this.props.rateDataForCard.density : "",
                commodity : this.props.rateDataForCard && this.props.rateDataForCard.commodity   ? this.props.rateDataForCard.commodity : "",
                custType : this.props.rateDataForCard && this.props.rateDataForCard.custType   ? this.props.rateDataForCard.custType : "",
                customerCurrency : this.props.rateDataForCard && this.props.rateDataForCard.customerCurrency   ? this.props.rateDataForCard.customerCurrency : "INR",
                baseCurrency : this.props.rateDataForCard && this.props.rateDataForCard.baseCurrency   ? this.props.rateDataForCard.baseCurrency : "INR",
                additionalService : this.props.rateDataForCard && this.props.rateDataForCard.additionalService   ? this.props.rateDataForCard.additionalService : [],
                customerId : this.props.rateDataForCard && this.props.rateDataForCard.customerId ? this.props.rateDataForCard.customerId : "",
                custBranchId : this.props.rateDataForCard && this.props.rateDataForCard.customerBranchId ? this.props.rateDataForCard.customerBranchId :"",
                containerType : this.props.rateDataForCard && this.props.rateDataForCard.containerType   ? this.props.rateDataForCard.containerType : "",
                shipmentMode : this.props.rateDataForCard && this.props.rateDataForCard.shipmentMode   ? this.props.rateDataForCard.shipmentMode : "",
                tarrifMode : this.props.rateDataForCard && this.props.rateDataForCard.tarrifMode   ? this.props.rateDataForCard.tarrifMode : "",
                rateType : this.props.rateDataForCard && this.props.rateDataForCard.rateType   ? this.props.rateDataForCard.rateType : "",
                priviousSelectedId: this.props.rateDataForCard  && this.props.rateDataForCard.buySaleData   && this.props.rateDataForCard.buySaleData.rateId  ? this.props.rateDataForCard.buySaleData.rateId : {} ,
                queryFor : this.props.rateDataForCard && this.props.rateDataForCard.queryFor   ? this.props.rateDataForCard.queryFor : "",
                referenceJobNo : this.props.rateDataForCard && this.props.rateDataForCard.referenceJobNo   ? this.props.rateDataForCard.referenceJobNo : "",

            },()=>{

                // if(this.state.originId !== "" && this.state.destinationId !== ""){

                    let mainDataFortest = {}
                    mainDataFortest.quoteId = this.state.quoteId ;
                    mainDataFortest.originId = this.state.originId ;
                    mainDataFortest.destinationId = this.state.destinationId;
                    mainDataFortest.chargeableWeight = this.state.chargeable;
                    mainDataFortest.grossWeight = this.state.gross;
                    mainDataFortest.volumeWeight = this.state.volume;
                    mainDataFortest.tarrifMode = this.state.tarrifMode;
                    mainDataFortest.totalPieces = this.state.totalPieces;
                    mainDataFortest.density = this.state.density;
                    mainDataFortest.clearenceDate = this.state.clearanceDate;
                    mainDataFortest.rateType = this.state.rateType;
                    mainDataFortest.custType = this.state.custType;
                    mainDataFortest.commodity = this.state.commodity;
                    mainDataFortest.customerId = this.state.customerId;
                    mainDataFortest.noOfContainers = this.state.noOfContainers;
                    mainDataFortest.containerType = this.state.containerType;
                    mainDataFortest.shipmentMode = this.state.shipmentMode;
                    mainDataFortest.baseCurrency = this.state.baseCurrency;
                    mainDataFortest.customerCurrency = this.state.customerCurrency;
                    mainDataFortest.additionalService = this.state.additionalService;
                    mainDataFortest.referenceJobNo = this.state.referenceJobNo;
                    this.GetRatesQuery(mainDataFortest).then(result=>{
                        if(result.success && result.message === "Data Available"){
                            let RevertRate = result && result.result && result.result[0] ? result.result[0] : []
                            let xRateCustomerINR =  0
                            let xRateCustomerUSD = 0
                            let xRateCustomerEUR = 0
                            let xRateCustomerGBP = 0
                            let customerCurrencyCharges = ""
                            this.setState({
                                rateData: result && result.result ? result.result : []
                            },()=>{
                                if(RevertRate && RevertRate.charges && RevertRate.charges.length > 0){
                                    let chargesCurrencyValue = RevertRate && RevertRate.charges && RevertRate.charges[0]
                                    // xRateCustomerINR = chargesCurrencyValue &&  chargesCurrencyValue.xRateCustomerCurrencyINR ? chargesCurrencyValue.xRateCustomerCurrencyINR :""
                                    // xRateCustomerUSD = chargesCurrencyValue &&  chargesCurrencyValue.xRateCustomerCurrencyUSD ? chargesCurrencyValue.xRateCustomerCurrencyUSD :""
                                    // xRateCustomerEUR = chargesCurrencyValue &&  chargesCurrencyValue.xRateCustomerCurrencyEUR ? chargesCurrencyValue.xRateCustomerCurrencyEUR :""
                                    // xRateCustomerGBP = chargesCurrencyValue &&  chargesCurrencyValue.xRateCustomerCurrencyGBP ? chargesCurrencyValue.xRateCustomerCurrencyGBP :""
                                    xRateCustomerINR = chargesCurrencyValue &&  chargesCurrencyValue.xRateCustomerINR ? chargesCurrencyValue.xRateCustomerINR :""
                                    xRateCustomerUSD = chargesCurrencyValue &&  chargesCurrencyValue.xRateCustomerUSD ? chargesCurrencyValue.xRateCustomerUSD :""
                                    xRateCustomerEUR = chargesCurrencyValue &&  chargesCurrencyValue.xRateCustomerEUR ? chargesCurrencyValue.xRateCustomerEUR :""
                                    xRateCustomerGBP = chargesCurrencyValue &&  chargesCurrencyValue.xRateCustomerGBP ? chargesCurrencyValue.xRateCustomerGBP :""
                                    customerCurrencyCharges = chargesCurrencyValue &&  chargesCurrencyValue.customerCurrency ? chargesCurrencyValue.customerCurrency :""

                                }
                                this.setState({
                                    xRateCustomerINR:xRateCustomerINR,
                                    xRateCustomerUSD:xRateCustomerUSD,
                                    xRateCustomerEUR:xRateCustomerEUR,
                                    xRateCustomerGBP:xRateCustomerGBP,
                                    customerCurrencyCharges:customerCurrencyCharges,
                                },()=>{
                                    this.currencyCalculationCustomerCurrency()
                                })
                                this.chepestRateApi(this.state.quoteId)
                                this.fastestRateApi(this.state.quoteId)
                                this.transitTimeApi({ id: this.state.quoteId,referenceJobNo:this.state.referenceJobNo}).then(res => {
                                    if (res.success) {
                                        this.setState({ trasitTimeData: res.result ? res.result : [] })
                                    }
                                    
                                })
                                this.couponApiData().then(res=>{
                                    if(res.success){
                                        this.setState({
                                            couponData : res.result && res.result.data ? res.result.data : []
                                        })
                                    }
                                })

                            })
                        }else {
                            this.setState({
                               ChargeRateNotFoundModel:true
                           },()=>{
                            this.couponApiData().then(res=>{
                                if(res.success){
                                    this.setState({
                                        couponData : res.result && res.result.data ? res.result.data : []
                                    })
                                }
                            })
                        })
                        }

                    })
                


            })
        }
        if (this.props.quoteDataForCard && this.props.quoteDataForCard !== prevProps.quoteDataForCard) {
            this.setState({queryStatus: this.props.quoteDataForCard
            })
        }

    }
    UpdateQuoteId = async (data) => {
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? UPDATE_QUOTE_ID_API_ROAD : UPDATE_QUOTE_ID_API_COURIER,
            method: "POST",
            data: data,
        });
    };
    removeCouponFunctionality = (value) =>{
        let obj = {
            rateId : this.state.ratesProceed && this.state.ratesProceed._id ? this.state.ratesProceed._id : "",
            customerBranchId : this.state.custBranchId,
            coupanId : this.state.ratesProceed && this.state.ratesProceed.coupanId ? this.state.ratesProceed.coupanId : "",
        }
        this.removeCoupan({rateId : obj.rateId,customerBranchId:obj.customerBranchId,coupanId:obj.coupanId}).then(res=>{
            if(res.success){
                if(value===false) {
                    toast.success("Coupon Removed.", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                localStorage.removeItem('afterDiscountCharges')
                localStorage.removeItem('discountGiven')
                this.GetSingleRate({id : obj.rateId}).then(responce=>{
                    if(responce.success){
                        let rateData = responce && Array.isArray(responce.result) && responce.result.length >= 1 ? responce.result[0] : "";
                        this.setState(
                            {
                                ratesProceed: rateData,
                            },()=>{
                                this.setState({
                                    coupanNames : !this.state.coupanNames,
                                    coupanName : "",
                                })
                                let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                this.currencyChangeValues(custCurrency,baseCurrency)
                                this.getServiceProviderApi()
                            });
                    }
                })

            }
        })

    }
    couponApiData = async (dataForServer) => {
        return await RestClient({
            url: OFFER_VIEW_API,
            method: "POST",
            data: dataForServer
        });
    }
    transitTimeApi = async (dataForServer) => {
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? TRANSIT_TIME_GET_DATA_ROAD : TRANSIT_TIME_GET_DATA_COURIER ,
            method: "POST",
            data: dataForServer
        });
    }
    callGetQuoteApiHit = () =>{
        this.getQuoteData({quoteId:this.state.quoteId,transitTime:this.state.selectedTransit,referenceJobNo:this.state.referenceJobNo}).then(res => {
            if (res.success) {
                let quoteData = res && res.result ?  res.result : []
                this.setState({
                    rateDataTransit : quoteData,
                    activeFilter : "Transit",
                    activeFilter1:""
                })
                const filteredchepestRate = this.state.rateDataTransit.filter((rate) => !rate.isEdited);
                this.setState({
                    rateDataTransit:filteredchepestRate
                },()=>{
                })
            }
        })
    }
    getQuoteData = async (dataForServer) => {
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? GET_QUERY_REVERT_AUTO_API_ROAD : GET_QUERY_REVERT_AUTO_API_COURIER,
            method: "POST",
            data: dataForServer
        })
    };
    ServiceProviderSelected = () =>{
        this.setState({
            serviceProvider : true
        })
    }
    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});

    };

    sendtoFinalBooking =()=>{
        const {rateId,_id,quoteId,customer,branchId} = this.state.rateSuccessResponce !== ""  ? this.state.rateSuccessResponce  : {};
        const {containerType,NoOfContainer,houses,dimentionType, dimensionsType, dimensions,shipperConsigneeData,totalGross,totalVolume,revertData,dimentions} = this.state.BookRateData !== ""  ? this.state.BookRateData  : {};
        let obj = {}
        obj.rateId =rateId;
        obj.buySaleId =_id;
        obj.quoteId =quoteId;
        obj.isCopied = "";
        obj.copiedFrom = {};
        obj.isRejected = "Pending";
        obj.rejectedMessage = "";
        obj.shipmentType = "Direct";
        obj.customerId = customer && customer.customerId ? customer.customerId : "";
        obj.branchId = branchId;
        obj.containerType = containerType;
        obj.containerDetails = containerType;
        obj.noOfContainers = NoOfContainer;
        obj.noOfHouses = houses && houses.length !== undefined ? houses.length : 0;
        obj.houses = houses;
        obj.dimentions = dimentions;
        obj.dimensions = dimensions;
        obj.dimentionType = dimentionType;
        obj.dimensionsType = dimensionsType;
        obj.scacNumber = "";
        obj.shippingLineName =  revertData && revertData.shippingLineName ?  revertData.shippingLineName : "";
        obj.shippingLineId = revertData && revertData.shippingLineId ?revertData.shippingLineId : "" ;
        obj.svcContract = "";
        obj.vesselNo = "";
        obj.voyageNo = "";
        obj.placeOfReceipt = "";
        obj.placeOfDelivery = "";
        obj.asAgreed = "";
        obj.blPdfUrl = "";
        obj.blStatus = "Draft";
        obj.notifyPartyName = "";
        obj.notifyPartyAddress = "";
        obj.notifyPartyCity = "";
        obj.notifyPartyZipCode = "";
        obj.notifyPartyCountryId = "";
        obj.notifyPartyAccountInfo = "";
        obj.currency = "";
        obj.shipperOrAgentSignature = "";
        obj.dateOfSignature = convertDateISO(new Date());
        obj.carrierOrAgentSignature = "";
        obj.SCI = "";
        obj.otherInformation = "";
        obj.shipmentBookingNo = "";
        obj.shipmentBookingDate = "";
        obj.natureAndQuantityOfGoods = "";
        obj.isShipmentBooked = false;
        obj.isNegativeShipment = false;
        obj.shipperId = shipperConsigneeData && shipperConsigneeData.shipperId ? shipperConsigneeData.shipperId : "";
        obj.shipperBranchId = shipperConsigneeData && shipperConsigneeData.shipperBranchId ? shipperConsigneeData.shipperBranchId : "";
        obj.shipperAccountNo = shipperConsigneeData && shipperConsigneeData.shipperAccountNo ? shipperConsigneeData.shipperAccountNo : "";
        obj.consigneeId = shipperConsigneeData && shipperConsigneeData.consigneeId ? shipperConsigneeData.consigneeId : "";
        obj.consigneeBranchId = shipperConsigneeData && shipperConsigneeData.consigneeBranchId ? shipperConsigneeData.consigneeBranchId : "";
        obj.consigneeAccountNo = shipperConsigneeData && shipperConsigneeData.consigneeAccountNo ? shipperConsigneeData.consigneeAccountNo : "";
        obj.shipperDetails = shipperConsigneeData && shipperConsigneeData.shipperDetails ? shipperConsigneeData.shipperDetails : "";
        obj.consigneeDetails = shipperConsigneeData && shipperConsigneeData.consigneeDetails ? shipperConsigneeData.consigneeDetails : "";
        

        obj.shipperContactName = shipperConsigneeData && shipperConsigneeData.shipperContactName ? shipperConsigneeData.shipperContactName : "";
        obj.shipperContactNumber = shipperConsigneeData && shipperConsigneeData.shipperContactNumber ? shipperConsigneeData.shipperContactNumber : "";
        obj.consigneeContactName = shipperConsigneeData && shipperConsigneeData.consigneeContactName ? shipperConsigneeData.consigneeContactName : "";
        obj.consigneeEmail = shipperConsigneeData && shipperConsigneeData.consigneeEmail ? shipperConsigneeData.consigneeEmail : "";
        obj.shipperEmail = shipperConsigneeData && shipperConsigneeData.shipperEmail ? shipperConsigneeData.shipperEmail : "";
        obj.consigneeContactNumber = shipperConsigneeData && shipperConsigneeData.consigneeContactNumber ? shipperConsigneeData.consigneeContactNumber : "";
        obj.consigneePincode = shipperConsigneeData && shipperConsigneeData.consigneePincode ? shipperConsigneeData.consigneePincode : "";
        obj.shipperPincode = shipperConsigneeData && shipperConsigneeData.shipperPincode ? shipperConsigneeData.shipperPincode : "";
        obj.issuedBySelectionBranch = "";
        /*obj.issuedBySelection = "";*/
        obj.handlingInfo = "";
        obj.remarks = "";
        obj.bookingNo = "";
        obj.blNo = "";
        obj.volumeWeight = totalVolume;
        obj.noOfPieces = this.state.totalPieces;
        obj.totalPieces = this.state.totalPieces;
        obj.grossWeight = totalGross;
        obj.kgOrLBS = "kg";
        obj.chargeableWeight = this.state.chargeableWeight;
        obj.accountType = this.state.accountType;
        obj.incoTermsId = this.state.incoTermsId;
        obj.incoTerms = this.state.incoTerms;
        obj.issuedBySelection = this.state.issuedBySelection;
        obj.selectedCustomerCurrency = this.state.currencySelected ? this.state.currencySelected : this.state.customerCurrencyCharges;
        obj.selectedXRate = this.state.selectedCurrencyRate ? this.state.selectedCurrencyRate : "";
        this.FinalBookingApi(obj).then(responce=>{
            // console.log("finalBookingData",JSON.stringify(obj))
            let creditDays = localStorage.getItem('creditDays')
            if(creditDays != "" && creditDays){
              this.updateRates({toUpdate  :rateId,creditDaysStatus : true,rateStatus :false,creditDays : creditDays}).then(res=>{
                if(res.success){
                  localStorage.removeItem('creditDays')
                }
              })
            }
            if(responce.success){
                toast.success(responce.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                let bookingId = responce.result && responce.result._id ? responce.result._id : ""

                // if(this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road")
                // {
                //     this.props.history.push(PAGE_PATHS.BOOKING_COURIER_VIEW + "/Road/" + bookingId)
                // }
                // else
                // {
                    if(this.state.typeOfBussiness === "Individual"){
                        this.props.history.push(PAGE_PATHS.INDIVIDUAL_BOOKING_PAGE + "/" + this.props.rateDataForCard.queryFor+ "/"+bookingId)
                    }else{
                        if(this.props.paymentTerms === "0"){
                            this.props.history.push(PAGE_PATHS.INDIVIDUAL_BOOKING_PAGE + "/" + this.props.rateDataForCard.queryFor+ "/"+bookingId)
                        }else{
                            this.props.history.push(PAGE_PATHS.BOOKING_COURIER_VIEW + "/" + this.props.rateDataForCard.queryFor+ "/"+bookingId)

                        }

                    }
                // }
            }else {
                toast.error(responce.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }


        })
    }
    updateRates = async (data) => {
        return await RestClient({
          url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? UPDATE_RATE_FOR_CREDIT_ROAD : UPDATE_RATE_FOR_CREDIT_COURIER,
          method: "POST",
          data: data,
        });
      };
    FinalBookingApi = async (data) => {
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? CREATE_BOOKING_API_ROAD : CREATE_BOOKING_API_COURIER,
            method: "POST",
            data: data
        });
    };

    chepestRateApi = (value) =>{
        this.cheapestFastestAPi({ id:value,data:"cheapest",referenceJobNo:this.state.referenceJobNo}).then(res => {
            if (res.success) {
                // parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC).toFixed(2)
                this.setState({ chepestRate: res.result ? res.result : [], },()=>{
                    this.state.chepestRate && this.state.chepestRate.map((item,i)=>{
                        this.setState({
                            cheapestAmountB: item&&item.amountB?parseFloat(item.amountB).toFixed(2):0,
                            cheapestAmountB15: item&&item.amountB15?parseFloat(item.amountB15).toFixed(2):0,
                            cheapestAmountB30: item&&item.amountB30?parseFloat(item.amountB30).toFixed(2):0,
                            cheapestAmountBDiscount: item&&item.amountBDiscount?parseFloat(item.amountBDiscount).toFixed(2):0,
                            cheapestAmountB15Discount: item&&item.amountB15Discount?parseFloat(item.amountB15Discount).toFixed(2):0,
                            cheapestAmountB30Discount: item&&item.amountB30Discount?parseFloat(item.amountB30Discount).toFixed(2):0,
                            coupanApplyCheapest : item&&item.coupanApply?parseFloat(item.coupanApply).toFixed(2):0,
                            cheapestAmountC:item&&item.amountC?parseFloat(item.amountC).toFixed(2):0,
                            cheapestAmountC15:item&&item.amountC15?parseFloat(item.amountC15).toFixed(2):0,
                            cheapestAmountC30:item&&item.amountC30?parseFloat(item.amountC30).toFixed(2):0,
                            cheapestAmountCDiscount:item&&item.amountCDiscount?parseFloat(item.amountCDiscount).toFixed(2):0,
                            cheapestAmountC15Discount:item&&item.amountC15Discount?parseFloat(item.amountC15Discount).toFixed(2):0,
                            cheapestAmountC30Discount:item&&item.amountC30Discount?parseFloat(item.amountC30Discount).toFixed(2):0,
                            cheapestTransitTime : item&&item.transitTime?item.transitTime:"",
                            chepestCustomerCurrency : item&&item.customerCurrency?item.customerCurrency:"",
                            chepestBaseCurrency : item&&item.baseCurrency?item.baseCurrency:"",
                        },()=>{
                            const filteredchepestRate = this.state.chepestRate.filter((rate) => !rate.isEdited);
this.setState({
    chepestRate:filteredchepestRate
},()=>{
    
})
                        })
                    })

                })
            }
        })
    }
    fastestRateApi = (value) =>{
        this.cheapestFastestAPi({ id:value,data:"fastest",referenceJobNo:this.state.referenceJobNo}).then(res => {
            if (res.success) {
                this.setState({ fastestRate: res.result ? res.result : [] },()=>{
                    this.state.fastestRate && this.state.fastestRate.map((item,i)=>{
                        this.setState({
                            fastestAmountB: item&&item.amountB?parseFloat(item.amountB).toFixed(2):0,
                            fastestAmountB15: item&&item.amountB?parseFloat(item.amountB15).toFixed(2):0,
                            fastestAmountB30: item&&item.amountB?parseFloat(item.amountB30).toFixed(2):0,
                            fastestAmountBDiscount: item&&item.amountBDiscount?parseFloat(item.amountBDiscount).toFixed(2):0,
                            fastestAmountB15Discount: item&&item.amountB15Discount?parseFloat(item.amountB15Discount).toFixed(2):0,
                            fastestAmountB30Discount: item&&item.amountB30Discount?parseFloat(item.amountB30Discount).toFixed(2):0,
                            coupanApplyFastest : item&&item.coupanApply?parseFloat(item.coupanApply).toFixed(2):0,
                            fastestAmountC:item&&item.amountC?parseFloat(item.amountC).toFixed(2):0,
                            fastestAmountC15:item&&item.amountC?parseFloat(item.amountC15).toFixed(2):0,
                            fastestAmountC30:item&&item.amountC?parseFloat(item.amountC30).toFixed(2):0,
                            fastestAmountCDiscount:item&&item.amountCDiscount?parseFloat(item.amountCDiscount).toFixed(2):0,
                            fastestAmountC15Discount:item&&item.amountC15Discount?parseFloat(item.amountC15Discount).toFixed(2):0,
                            fastestAmountC30Discount:item&&item.amountC30Discount?parseFloat(item.amountC30Discount).toFixed(2):0,
                            fastestTransitTime : item&&item.transitTime?item.transitTime:"",
                            fastestCustomerCurrency : item&&item.customerCurrency?item.customerCurrency:"",
                            fastestBaseCurrency : item&&item.baseCurrency?item.baseCurrency:"",
                        },()=>{
                            const filteredchepestRate = this.state.fastestRate.filter((rate) => !rate.isEdited);
this.setState({
    fastestRate:filteredchepestRate
},()=>{
    
})
                        })
                    })
                })
            }
        })
    }
    cheapestFastestAPi = async (dataForServer) => {
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? FASTEST_CHEAPEST_DATA_ROAD : FASTEST_CHEAPEST_DATA_COURIER,
            method: "POST",
            data: dataForServer
        });
    }
    switchCurrency = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if(e.target.name == "switchCurrency"){
                this.setState({
                    switchCurrency : !this.state.switchCurrency,
                })
        }

    }
    getServiceProviderApi = () =>{
        if(this.state.originId !== "" && this.state.destinationId !== "") {
            let mainDataFortest = {}
            mainDataFortest.quoteId = this.state.quoteId;
            mainDataFortest.originId = this.state.originId;
            mainDataFortest.destinationId = this.state.destinationId;
            mainDataFortest.chargeableWeight = this.state.chargeable;
            mainDataFortest.grossWeight = this.state.gross;
            mainDataFortest.volumeWeight = this.state.volume;
            mainDataFortest.tarrifMode = this.state.tarrifMode;
            mainDataFortest.totalPieces = this.state.totalPieces;
            mainDataFortest.density = this.state.density;
            mainDataFortest.clearenceDate = this.state.clearanceDate;
            mainDataFortest.rateType = this.state.rateType;
            mainDataFortest.custType = this.state.custType;
            mainDataFortest.commodity = this.state.commodity;
            mainDataFortest.customerId = this.state.customerId;
            mainDataFortest.noOfContainers = this.state.noOfContainers;
            mainDataFortest.containerType = this.state.containerType;
            mainDataFortest.shipmentMode = this.state.shipmentMode;
            mainDataFortest.baseCurrency = this.state.baseCurrency;
            mainDataFortest.customerCurrency = this.state.customerCurrency;
            mainDataFortest.additionalService = this.state.additionalService;
            mainDataFortest.referenceJobNo = this.state.referenceJobNo;
            this.GetRatesQuery(mainDataFortest).then(result=>{
                if(result.success && result.message === "Data Available") {
                    this.setState({
                        rateData: result && result.result ? result.result : []
                    },()=>{
                        this.chepestRateApi(this.state.quoteId)
                        this.fastestRateApi(this.state.quoteId)
                    })
                }})
        }

    }
    currencyChangeValues = (custCurrency,baseCurrency) =>{
        if(baseCurrency === "INR"){
            this.setState({
                baseRatesCurrency : faRupeeSign,
            })
        }else if(baseCurrency === "USD"){
            this.setState({
                baseRatesCurrency : faDollarSign,
            })
        }else if(baseCurrency === "EUR"){
            this.setState({
                baseRatesCurrency : faEuroSign,
            })
        }
        if(custCurrency === "INR"){
            this.setState({
                customerRatesCurrency : faRupeeSign,
            })
        }else if(custCurrency === "USD"){
            this.setState({
                customerRatesCurrency : faDollarSign,
            })
        }else if(custCurrency === "EUR"){
            this.setState({
                customerRatesCurrency : faEuroSign,
            })
        }
    }
    storeCharge = () => {
        let array = [];
        let discountGiven = localStorage.getItem('discountGiven')
        if(discountGiven){
            let discountedArray = JSON.parse(localStorage.getItem('afterDiscountCharges'))
            let proceedData = this.state.ratesProceed ? this.state.ratesProceed : {}
            discountedArray && discountedArray.map((obj, j) => {
                if (proceedData._id === obj._id) {
                    localStorage.setItem("selectedCharge", JSON.stringify(obj));
                    if(Array.isArray(obj.awaitedRates) && obj.awaitedRates.length >= 1){
                        this.setState({
                            awaitPopup:!this.state.awaitPopup,
                            RateIds:obj._id,
                        })
                    }else{
                        if(this.state.currencySelected){
                            if(this.state.customerCurrency !== this.state.currencySelected){
                                this.setState({
                                    currencyChangePop : true
                                })
                            }else{
                                this.setState({shipperModel: true,});
                            }
                        }else{
                            this.setState({shipperModel: true,});
                        }
                    }
                } else if (proceedData._id !== obj._id) {
                    array.push(obj);
                }
                this.setState({ remainingCharges: array });
            });
        }else{
            let proceedData = this.state.ratesProceed ? this.state.ratesProceed : {}
            localStorage.setItem("selectedCharge", JSON.stringify(proceedData));
            if(Array.isArray(proceedData.awaitedRates) && proceedData.awaitedRates.length >= 1){
                this.setState({
                    awaitPopup:!this.state.awaitPopup,
                    RateIds:proceedData._id,
                })
            }else{
                if(this.state.currencySelected){
                    if(this.state.customerCurrency !== this.state.currencySelected){
                        this.setState({
                            currencyChangePop : true
                        })
                    }else{
                        this.setState({shipperModel: true,});
                    }
                }else{
                    this.setState({shipperModel: true,});
                }
            }
        }
    };
    proceedBtn = (e) =>{
        e.preventDefault()
        let value = this.custDetails()
        let typeOfCustomer = value && value.typeOfCust && value.typeOfCust.result && value.typeOfCust.result.customerData && value.typeOfCust.result.customerData.typeOfBussiness ? value.typeOfCust.result.customerData.typeOfBussiness : "Bussiness"
        if(this.checkUser()){
            if(value.custKycStatus){
                this.storeCharge()
            }else{
                if(typeOfCustomer == "Business"){
                    history.push(PAGE_PATHS.CUSTOMER_KYC);
                }else{
                    history.push(PAGE_PATHS.CUSTOMER_KYC_FOR_INDIVIDUAL);
                }
                window.location.reload("false")
            }

        }else{
            this.setState({
                modelLogin: true,
            });

        }

    }
    checkUser = () =>{
        let typeOfCust = JSON.parse(localStorage.getItem("customer"))
        if(typeOfCust){
            return true
        }else{
            return false
        }
    }
    custDetails = () => {
        let typeOfCust = JSON.parse(localStorage.getItem("customer"))
        const custType = typeOfCust && typeOfCust.result && typeOfCust.result.customerData && typeOfCust.result.customerData.typeOfBussiness
        const custKycStatus = typeOfCust && typeOfCust.result && typeOfCust.result.customerBranchData && typeOfCust.result.customerBranchData.kycStatus
        return {typeOfCust,custType,custKycStatus}
    }
    removeCoupan = async (dataForServer) => {
        return await RestClient({
            url: this.state.queryData && this.state.queryData.queryFor === "Courier" ? REMOVE_COUPAN_COURIER : REMOVE_COUPAN_ROAD,
            method: "POST",
            data: dataForServer
        })
    }
    GetSingleRate =async (dataForServer)=>{
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? RATE_SINGLE_DATA_ROAD : RATE_SINGLE_DATA_COURIER,
            method: "POST",
            data: dataForServer
        });
    }
    handleChangeForTransit = value => {

        let transitTime ={
            transitTime:value,
            quoteId:this.state.quoteId,
            referenceJobNo:this.state.referenceJobNo
        }
        if(value == "Cheapest"){

        
        this.TransitApi(transitTime).then(res => {
            if (res.success) {
                this.setState({
                    chepestRate: res && res.result ? res.result : []

                },()=>{
                    const filteredchepestRate = this.state.chepestRate.filter((rate) => !rate.isEdited);
                    this.setState({
                        chepestRate:filteredchepestRate
                    },()=>{
                    })
                })
            } else {
                this.setState({ 
                    chepestRate: []
                })
            }
        })
    }else if (value == "Fastest"){
        this.TransitApi(transitTime).then(res => {
            if (res.success) {
                this.setState({
                    fastestRate: res && res.result ? res.result : []

                },()=>{
                    const filteredchepestRate = this.state.fastestRate.filter((rate) => !rate.isEdited);
                    this.setState({
                        fastestRate:filteredchepestRate
                    },()=>{
                        
                    })
                })
            } else {
                this.setState({ 
                    fastestRate: []
                })
            }
        })
    }else {
        this.setState({
            activeFilter:"All"
        })
    }
    }
    TransitApi = async (dataForServer) => {
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? GET_BY_QUOTE_ID_TRANSIT_TIME_ROAD : GET_BY_QUOTE_ID_TRANSIT_TIME_COURIER,
            method: "POST",
            data:  dataForServer 
        });
    }
    validateEmail = (value) => {

        if (!value) {
            toast.error("Please enter your email address", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!this.validateEmails(value)) {

            this.setState({
                contactEmail:"",contactEmailError : "* Please enter a valid email Id."
            })

        }else{
            this.setState({

            })
        }

    }
    validateEmails = email => {
        let emailLower = String(email).toLowerCase();
        let re1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re2 = /^[a-zA-Z0-9][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re3 = /^.{1,64}@.{1,256}$/;
        let reOther = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re1.test(emailLower)){
            if(reOther.test(emailLower)){
                if(re.test(emailLower)){
                    if(re2.test(emailLower)){
                        if(re3.test(emailLower)){
                            this.setState({
                                contactEmailError : ""
                            })
                            return emailLower
                        }
                    }
                }
            }
        }
    };
    handleSubmit = (e) =>{
        e.preventDefault()
        if(!this.state.contactEmail){
            toast.error("Please Enter Email Id", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else {
            let queryData = this.state.queryData
            let customerMails = []
            customerMails.push(this.state.contactEmail)
            queryData.customerMails = customerMails
            queryData.contactNumber = this.state.contactNumber
            queryData.toUpdate = this.state.queryData && this.state.queryData._id ? this.state.queryData._id : ""
            this.setState({
                queryData
            }, () => {
                this.updateRates(this.state.queryData).then(response => {
                    if (response.success) {
                        toast.success("Successfully Sent", {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.props.history.push(PAGE_PATHS.MAIN_PAGE)
                        window.location.reload("false")
                    }
                })
            })
        }

    }
    updateRates = async (dataForServer) =>{
        return await RestClient({
            url: this.props.rateDataForCard && this.props.rateDataForCard.queryFor === "Road" ? UPDATE_QUERY_API_ROAD : UPDATE_QUERY_API_COURIER,
            method: "POST",
            data: dataForServer
        });
    }
    currencyCalculation = () =>{
        if(this.state.currencySelected === "INR"){
            this.setState({
                selectedCurrencyRate : this.state.xRateCustomerINR
            })
        }else if(this.state.currencySelected === "USD"){
            this.setState({
                selectedCurrencyRate : this.state.xRateCustomerUSD
            })
        }else if(this.state.currencySelected === "EUR"){
            this.setState({
                selectedCurrencyRate : this.state.xRateCustomerEUR
            })
        }else if(this.state.currencySelected === "GBP"){
            this.setState({
                selectedCurrencyRate : this.state.xRateCustomerGBP
            })
        }else{
            this.setState({
                selectedCurrencyRate : ""
            })
        }
    }
    currencyCalculationCustomerCurrency = () =>{
        if(this.state.customerCurrencyCharges === "INR"){
            this.setState({
                selectedCurrencyRate : this.state.xRateCustomerINR
            })
        }else if(this.state.customerCurrencyCharges === "USD"){
            this.setState({
                selectedCurrencyRate : this.state.xRateCustomerUSD
            })
        }else if(this.state.customerCurrencyCharges === "EUR"){
            this.setState({
                selectedCurrencyRate : this.state.xRateCustomerEUR
            })
        }else if(this.state.customerCurrencyCharges === "GBP"){
            this.setState({
                selectedCurrencyRate : this.state.xRateCustomerGBP
            })
        }else{
            this.setState({
                selectedCurrencyRate : ""
            })
        }
    }

    render() {
        const {quoteData} = this.state
        const rateData = this.state.rateData
        const {queryData} = this.state
        const quoteStatuss = this.state.queryStatus && this.state.queryStatus.queryStatus ? this.state.queryStatus.queryStatus : ""
        return (
            <>

                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <>

                    {this.state.shipperModel === true ?
                        <CourierShipperConsigneeModal
                        rateData ={this.state.rateData}
                            shipperModel={this.state.shipperModel}
                            setmodal={values => values === false ? this.setState({shipperModel: false}) : ""}
                            queryDataForcard={ this.state.queryData}
                            rateDataForCard = {this.props.rateDataForCard && this.props.rateDataForCard}


                            sendResultdata={values => {
                                this.setState({shipperConsigneeData: values.shipperConsignee ? values.shipperConsignee : {} ,
                                    houses : values.houses ? values.houses : [],
                                    NoOfContainer : values.NoOfContainer ? values.NoOfContainer : "",
                                    containerType : values.containerType ? values.containerType : "",
                                    totalGross : values.totalGross ? values.totalGross : "",
                                    totalVolume : values.totalVolume ? values.totalVolume : "",
                                    totalPieces : values.totalPieces ? values.totalPieces : "",
                                    chargeableWeight : values.chargeableWeight ? values.chargeableWeight : "",
                                    accountType : values.accountType ? values.accountType : "",
                                    incoTermsId : values.incoTermsId ? values.incoTermsId : "",
                                    incoTerms : values.incoTerms ? values.incoTerms : "",
                                    issuedBySelection : values.issuedBySelection ? values.issuedBySelection : "",
                                    uploadedDimensions:values.dimentions ? values.dimentions : [],
                                    dimentionType: values.dimentionType ? values.dimentionType : "",

                                },()=>{

                                    if ( localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0 && this.state.customerId === this.state.customerIDByLocalStorage){
                                        this.prepareToSaveData()
                                    }else{
                                        this.prepareToSaveDataWithoutLogin()
                                    }
                                })

                            }}
                        />
                        :
                        undefined
                    }
                    {this.state.currencyChangePop ?
                        <Modal className="" isOpen={this.state.currencyChangePop} size="md" toggle={()=>{this.setState({currencyChangePop : false,shipperModel:true})}}>
                            <ModalHeader style={{ borderBottom: '1px solid #ddd' }} toggle={()=>{this.setState({currencyChangePop : false,shipperModel:true})}} className="">
                                <h3><strong>Currency Change</strong></h3>
                            </ModalHeader>
                            <ModalBody className="">
                                <div className="booking-slab">
                                    <h3 className="pb-3 g20Text">You have selected <b>{this.state.currencySelected}</b> as currency, do you want to process your shipment with the selected currency</h3>
                                    <div>
                                        <button onClick={e => {
                                            this.setState({currencyChangePop : false,shipperModel:true,currencyChange:true})
                                        }} className="btn slab_btns mr-2" style={{width:'auto',fontWeight:'600'}}>OK</button>
                                        <button onClick={e => {
                                            this.setState({currencyChangePop : false,shipperModel:true,currencySelected:"",switchCurrency:true},()=>{
                                                this.currencyCalculationCustomerCurrency()
                                            })
                                        }} className="btn slab_btns" style={{width:'auto',fontWeight:'600'}}>Cancel</button>
                                    </div>

                                </div>
                            </ModalBody>
                        </Modal>:""}
                    {this.state.modelLogin?
                    <SignUp
                        // modelLogin={this.state.modelLogin}
                        modalLarge={this.state.modelLogin}
                        setmodal={(values) =>
                            values === false
                                ? this.setState({ modelLogin: false })
                                : ""
                        }
                    />
                    :""}
                    {this.state.RateNotFoundModel?
                    <RateNotFoundCard
                        RateNotFoundModel={this.state.RateNotFoundModel}
                        returnResult = {(value) => { if(value === true){
                            this.setState({RateNotFoundModel:false})
                        } }}
                    />:""}
                    <div className={this.state.sideBarOpen || this.props.sideSuggestion?"d-flex flex-wrap justify-content-between":this.state.couponData && this.state.couponData.length === 0 ?"d-flex flex-wrap justify-content-start":"d-flex flex-wrap justify-content-between"}>
                        <div className={this.props.FilterSide ? "Query_filter sideFilterPopup":this.state.sideBarOpen || this.props.sideSuggestion?"Query_filter sideBar_margin":this.state.couponData && this.state.couponData.length === 0 ?"Query_filter":"Query_filter sideBar_margin"}>
                            <div className="position-relative h-100">
                                <h4>Popular Filter</h4>
                                <div className="transit_filter">
                                    <div className="heading_filter">Transit Time </div>
                                    <div className="transit_checkBox">
                                        {this.state.trasitTimeData && this.state.trasitTimeData.map((items,i)=>{
                                            return(
                                                <>
                                                    <FormGroup check>
                                                        <Input id="exampleCheck" name={"check"+[i]} value={items} type="checkbox"  onChange={(e)=>{
                                                            let selectedTransit = this.state.selectedTransit;
                                                            if(e.target.checked === true){
                                                                selectedTransit.push(e.target.value)
                                                                this.setState({
                                                                    selectedTransit : selectedTransit
                                                                },()=>{
                                                                    this.callGetQuoteApiHit()
                                                                })
                                                            }else{
                                                                let index = selectedTransit.findIndex(obj=>obj == e.target.value)
                                                                selectedTransit.splice(index,1)
                                                                this.setState({
                                                                    selectedTransit : selectedTransit
                                                                },()=>{
                                                                    this.callGetQuoteApiHit()
                                                                })
                                                            }


                                                        }}/>
                                                        <Label check for="exampleCheck" > {items} </Label>
                                                    </FormGroup>
                                                </>
                                            )
                                        })}

                                    </div>
                                    <div className="heading_filter">Service Provider</div>
                                    <div className="select_clear_filter d-flex flex-wrap align-items-stretch">
                                        <div className="tab_filter tab_btns" onClick={()=>{
                                            this.setState({
                                                serviceProvider : true,
                                                bookButtonHide : false
                                            })
                                        }}>Select All</div>
                                        <div className="tab_line"></div>
                                        <div className="tab_filter tab_btns" onClick={()=>{
                                            this.setState({
                                                serviceProvider : false,
                                                sideBarOpen: false
                                            })
                                        }}>Clear All</div>
                                    </div>
                                    <div className="transit_checkBox">
                                        <div className="form-check">
                                            <Input id="exampleCheck" name="serviceProvider" type="checkbox" checked={this.state.serviceProvider === true ? true :false} onClick={()=>{
                                                this.setState({
                                                    serviceProvider : !this.state.serviceProvider,
                                                    sideBarOpen:false,
                                                    bookButtonHide : false
                                                })
                                            }}/>
                                            <label htmlFor="exampleCheck" className="form-check-label"> Zipaworld </label>
                                        </div>
                                    </div>
                                </div>
                                {this.props.FilterSide ?
                                    <div className="filterApply">
                                        <div className="apply_now_btn close_btn_apply" onClick={()=>{
                                            this.props.filterSideClose(false)
                                        }}>
                                            Close
                                        </div>
                                        <div className="centerFilterLine"></div>
                                        <div className="apply_now_btn" onClick={()=>{
                                            this.props.filterSideClose(false)
                                        }}>
                                            Apply
                                        </div>
                                    </div>
                                    :""}
                            </div>
                        </div>
                        <div className={this.state.sideBarOpen?"dashboard-ratesheading ratesAll":"dashboard-ratesheading"}>
                            <div className="dashboard-rate">
                                <div className="d-flex flex-wrap flex-column min-ratesheight">
                                    <div className="sort_filter">
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <div className="need_credit_switch">
                                                {/*<div className="switch_currency">*/}
                                                {/*    <div className="form-check">*/}
                                                {/*        <input type="checkbox" className="form-check-input" name="switchCurrency" checked={this.state.switchCurrency} value={this.state.switchCurrency}*/}
                                                {/*               onChange={(e)=>this.setState({switchCurrency:e.target.checked},()=>{*/}
                                                {/*               })}*/}
                                                {/*               id=""/>*/}
                                                {/*        <label className="form-check-label" htmlFor="">Switch to own Currency</label>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*{queryData.quoteStatus==="Rates Quoted"?*/}
                                                {/* <div className="switch_currency need_credit_query">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" onClick={this.creditDaysSTatusFunc}/>
                                                        <label className="form-check-label" >Need Credit</label>
                                                    </div>
                                                    {this.state.creditDaysSTatus === true ?
                                                        <select className="" name="creditDays" onChange={this.switchNeedCredit} value={this.state.creditDays}>
                                                            <option value={""}>No Credit</option>
                                                            <option value={"15 Days"}>15 Days</option>
                                                            <option value={"30 Days"}>30 Days</option>
                                                        </select>
                                                        :""}
                                                </div> */}
                                                {/*// :*/}
                                                {/*// <>*/}
                                                {/*//     {this.state.creditDays !== "" ?*/}
                                                {/*//         <div className="coupon_apply_pop">{this.state.creditDays} Credit</div>*/}
                                                {/*//         :""}*/}
                                                {/*// </>}*/}
                                            </div>
                                            <div className="sort_label">
                                                <div className="d-flex justify-content-center align-items-center sorting_filter">
                                                    <label>Sort By</label>
                                                    <select className="custom-select" name="activeFilter1" value={this.state.activeFilter1} onChange={(e)=>{
                                                        this.setState({
                                                            activeFilter1 : e.target.value,
                                                            sideBarOpen:false,bookButtonHide:false,
                                                            activeFilter:""
                                                        },()=>{
                                                            this.handleChangeForTransit(this.state.activeFilter1)

                                                        })
                                                    }}>
                                                        <option value={"All"}>Select</option>
                                                        <option value={"Fastest"}>Fastest Option</option>
                                                        <option value={"Cheapest"}>Cheapest Option</option>
                                                    </select>
                                                </div>
                                                <div className="currency_filter">
                                                    <select className="custom-select" name="currencySelected" value={this.state.currencySelected} onChange={(e)=>{
                                                        this.setState({
                                                            currencySelected : e.target.value,
                                                        },()=>{
                                                            if(this.state.currencySelected !== ""){
                                                                this.setState({
                                                                    switchCurrency : false
                                                                },()=>{
                                                                    this.currencyCalculation()
                                                                })
                                                            }else{
                                                                this.setState({
                                                                    switchCurrency : true
                                                                },()=>{
                                                                    this.currencyCalculationCustomerCurrency()
                                                                })
                                                            }
                                                        })
                                                    }}>
                                                        <option value="">Select Currency</option>
                                                        <option value={"INR"}>INR</option>
                                                        <option value={"USD"}>USD</option>
                                                        <option value={"EUR"}>EUR</option>
                                                        <option value={"GBP"}>GBP</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cheapest_fastest ">
                                            <div className={this.state.activeFilter === "All"?"cheapest_rates active":"cheapest_rates"} onClick={()=>{
                                                this.setState({activeFilter:"All",activeFilter1:"",sideBarOpen:false,bookButtonHide:false})
                                            }}>
                                                <h4 className="view_all">View All</h4>
                                                <div className="cheapestRate">
                                                    {/*₹ 13,273*/}
                                                </div>
                                            </div>
                                            <div className={this.state.activeFilter === "Cheapest"?"cheapest_rates active":"cheapest_rates"} onClick={()=>{
                                                this.setState({activeFilter:"Cheapest",activeFilter1:"",sideBarOpen:false,bookButtonHide:false},()=>{
                                                    this.chepestRateApi(this.state.quoteId)

                                                })
                                            }}>
                                                <h4>Cheapest</h4>
                                                <div className="cheapestRate">
                                                    {this.state.switchCurrency && !this.state.currencySelected ?
                                                        <>
                                                            {this.state.chepestCustomerCurrency === "INR"? <FontAwesomeIcon icon={faRupeeSign} /> :this.state.chepestCustomerCurrency === "USD" ? <FontAwesomeIcon icon={faDollarSign}/> : this.state.chepestCustomerCurrency === "EUR" ? <FontAwesomeIcon icon={faEuroSign}/>:""}
                                                            {" "}
                                                            {this.state.coupanApplyCheapest ?
                                                                <>
                                                                    {this.state.creditDays === "" ? this.state.cheapestAmountCDiscount :""}
                                                                    {this.state.creditDays === "15 Days" ? this.state.cheapestAmountC15Discount :""}
                                                                    {this.state.creditDays === "30 Days" ? this.state.cheapestAmountC30Discount :""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? this.state.cheapestAmountC :""}
                                                                    {this.state.creditDays === "15 Days" ? this.state.cheapestAmountC15 :""}
                                                                    {this.state.creditDays === "30 Days" ? this.state.cheapestAmountC30 :""}
                                                                </>
                                                            }
                                                        </>:<>
                                                            {this.state.currencySelected === "INR"? <FontAwesomeIcon icon={faRupeeSign} /> :this.state.currencySelected === "USD" ? <FontAwesomeIcon icon={faDollarSign} /> : this.state.currencySelected === "EUR" ? <FontAwesomeIcon icon={faEuroSign} /> : this.state.currencySelected === "GBP" ? <FontAwesomeIcon icon={faPoundSign} />:""}
                                                            {" "}
                                                            {this.state.coupanApplyCheapest ?
                                                                <>
                                                                    {this.state.creditDays === "" ? (this.state.cheapestAmountBDiscount * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                    {this.state.creditDays === "15 Days" ? (this.state.cheapestAmountB15Discount * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                    {this.state.creditDays === "30 Days" ? (this.state.cheapestAmountB30Discount * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? (this.state.cheapestAmountB * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                    {this.state.creditDays === "15 Days" ? (this.state.cheapestAmountB15 * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                    {this.state.creditDays === "30 Days" ? (this.state.cheapestAmountB30 * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    <p>{this.state.cheapestTransitTime}</p>
                                                </div>
                                            </div>
                                            <div className={this.state.activeFilter === "Fastest"?"cheapest_rates active":"cheapest_rates"} onClick={()=>{
                                                this.setState({activeFilter:"Fastest", activeFilter1:"",sideBarOpen:false,bookButtonHide:false},()=>{
                                                    this.fastestRateApi(this.state.quoteId)
                                                })
                                            }}>
                                                <h4>Fastest</h4>
                                                <div className="cheapestRate">
                                                    {this.state.switchCurrency && !this.state.currencySelected?
                                                        <>
                                                            {this.state.fastestCustomerCurrency === "INR"? <FontAwesomeIcon icon={faRupeeSign}/> :this.state.fastestCustomerCurrency === "USD" ? <FontAwesomeIcon icon={faDollarSign}/> : this.state.fastestCustomerCurrency === "EUR" ? <FontAwesomeIcon icon={faEuroSign}/>:""}
                                                            {" "}
                                                            {this.state.coupanApplyFastest ?
                                                                <>
                                                                    {this.state.creditDays === "" ? this.state.fastestAmountCDiscount :""}
                                                                    {this.state.creditDays === "15 Days" ? this.state.fastestAmountC15Discount :""}
                                                                    {this.state.creditDays === "30 Days" ? this.state.fastestAmountC30Discount :""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? this.state.fastestAmountC :""}
                                                                    {this.state.creditDays === "15 Days" ? this.state.fastestAmountC15 :""}
                                                                    {this.state.creditDays === "30 Days" ? this.state.fastestAmountC30 :""}
                                                                </>
                                                            }
                                                        </>:<>
                                                            {this.state.currencySelected === "INR"? <FontAwesomeIcon icon={faRupeeSign} /> :this.state.currencySelected === "USD" ? <FontAwesomeIcon icon={faDollarSign} /> : this.state.currencySelected === "EUR" ? <FontAwesomeIcon icon={faEuroSign} /> : this.state.currencySelected === "GBP" ? <FontAwesomeIcon icon={faPoundSign} />:""}
                                                            {" "}
                                                            {this.state.coupanApplyFastest ?
                                                                <>
                                                                    {this.state.creditDays === "" ? (this.state.fastestAmountBDiscount * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                    {this.state.creditDays === "15 Days" ? (this.state.fastestAmountB15Discount * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                    {this.state.creditDays === "30 Days" ? (this.state.fastestAmountB30Discount * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? (this.state.fastestAmountB * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                    {this.state.creditDays === "15 Days" ? (this.state.fastestAmountB15 * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                    {this.state.creditDays === "30 Days" ? (this.state.fastestAmountB30 * this.state.selectedCurrencyRate ).toFixed(2) : ""}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <p>{this.state.fastestTransitTime}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.ChargeRateNotFoundModel ?
                                                
                                            <div className="contect-soon">
                                                <div className="soon_text">
                                                    <div className="book-imgs">
                                                        <img src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/mobile.png" alt="Mobile Icon"/>
                                                    </div>
                                                    <div className="book-text">
                                                        <div className="padding_contact text-center">
                                                            <h5><FontAwesomeIcon icon={faCircle} style={{color: "green"}} /> Rates Not Available  </h5>
                                                            <h2>We are working on your query </h2>
                                                            {!localStorage.getItem("already_registered") ?
                                                                <>
                                                                    <h4 className="mb-2 text-center cont-titles" style={{fontWeight: "bold",}} ><span>Please Provide Contact Details</span></h4>
                                                                    <div className="lables_invoice mb-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="contactEmail" className="red">Email</label>
                                                                            <input placeholder="Enter Email ID" type="text"
                                                                                   className="form-control inpt"
                                                                                   name="contactEmail"
                                                                                   value={this.state.contactEmail}
                                                                                   onBlur={e=>{this.validateEmail(e.target.value)}}
                                                                                   onChange={e=>{this.setState({contactEmail: e.target.value})} }
                                                                            />
                                                                            <p style={{color:"#ff0600",fontWeight:"700",fontSize:'13px',textAlign:'left'}}>{this.state.contactEmailError}</p>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="contactNumber" className="red">Contact Number</label>
                                                                            <input placeholder="Enter Contact No." type="text"
                                                                                   className="form-control inpt"
                                                                                   name="contactNumber"
                                                                                // onBlur={e=>{this.validatePhonNumber(e.target.value)}}
                                                                                   value={this.state.contactNumber}
                                                                                   onChange={e=>{this.setState({contactNumber: e.target.value})} }/>
                                                                        </div>


                                                                        <div className="mt-3 text-left">
                                                                            <button className="book-btn contact-btnss btn " style={{borderRadius: "50px;"}}
                                                                                    onClick={this.handleSubmit}>
                                                                                Submit
                                                                            </button>
                                                                        </div>
                                                                        {/*<div className="text-center mt-2 pop_text">*/}
                                                                        {/*    <p><sup>*</sup> For special Discount & Offers please provide you contact Details or close the icon</p>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </>
                                                                :""}
                                                            <h6> Our CS Person will Contact you Soon for further assistance. </h6>
                                                            <h6> For any problem Please call on <span>+91 120 691 6910 .</span> </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bottom_btn">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
:""}
                                    <div className={this.state.ChargeRateNotFoundModel?"":"form-side text-center"}>
                                    {this.state.serviceProvider === true ?
                                        <>
                                            {this.state.activeFilter === "All" ?
                                                <>
                                                    {Array.isArray(rateData) && rateData.map((obj, i) => {
                                                        return (
                                                            <>

                                                                <PreviousCourierShipmentHistoryRateCard
                                                                switchCurrency={this.state.switchCurrency}
                                                                    previousSelected={this.state.priviousSelectedId}
                                                                    BookingID={i}
                                                                    data={obj}
                                                                    queryData={this.state.queryData}
                                                                    queryDataForcard={quoteData ? quoteData : {} }
                                                                    rateDataForCard = {this.props.rateDataForCard && this.props.rateDataForCard}
                                                                    title={obj && obj.shippingLineName !=="" ? obj.shippingLineName : obj.product}
                                                                    product={obj && obj.product}
                                                                    shippingLineId={obj.shippingLineId || ""}
                                                                    detail={obj.transitTime}
                                                                    chargeId={obj._id}
                                                                    // percent={100}
                                                                    progressText={obj && obj.converted === true ? obj.convertedAmount : obj.amountB }
                                                                    login={(values) => {
                                                                        if (values === true) {
                                                                            if(localStorage.getItem("already_registered") === "true"){
                                                                                let array = []
                                                                                localStorage.setItem('queryData', JSON.stringify(this.state.queryData))
                                                                                localStorage.setItem('selectedCharge', JSON.stringify(obj))
                                                                                this.setState({SelectedCharges : obj,shipperModel:!this.state.shipperModel },()=>{
                                                                                    Array.isArray(rateData) && rateData.map((itm, j) => {
                                                                                        if(i !== j){
                                                                                            array.push(itm)
                                                                                        }
                                                                                        this.setState({remainingCharges : array})
                                                                                    })
                                                                                })

                                                                            }
                                                                            else{
                                                                                let array = []
                                                                                localStorage.setItem('queryData', JSON.stringify(this.state.queryData))
                                                                                localStorage.setItem('selectedCharge', JSON.stringify(obj))
                                                                                this.setState({SelectedCharges : obj,modelLogin:!this.state.modelLogin },()=>{
                                                                                    Array.isArray(rateData) && rateData.map((itm, j) => {
                                                                                        if(i !== j){
                                                                                            array.push(itm)
                                                                                        }
                                                                                        this.setState({remainingCharges : array})
                                                                                    })
                                                                                })
                                                                            }

                                                                        }
                                                                    }}
                                                                    sideBarCss = {(values)=>{
                                                                        if(values === true){
                                                                            this.setState({
                                                                                sideBarOpen:false,
                                                                                bookButtonHide:false
                                                                            })
                                                                        }
                                                                    }}
                                                                    bookButtonHide = {this.state.bookButtonHide}
                                                                    isUserLoggedIn={(values) => {
                                                                        this.setState({
                                                                            modelLogin: values,
                                                                        });
                                                                    }}
                                                                    coupanName = {this.state.coupanName}
                                                                    coupanNames = {this.state.coupanNames}
                                                                    ratesSelect={(values)=>{
                                                                        this.setState({
                                                                            ratesProceed : values,
                                                                            sideBarOpen : true,
                                                                            bookButtonHide:true
                                                                        },()=>{
                                                                            if(values && values.coupanApply){
                                                                                this.setState({
                                                                                    coupanName : values && values.coupanId ? values.coupanId : "",
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    coupanName :  "",
                                                                                })
                                                                            }
                                                                            let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                                                            let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                                                            this.currencyChangeValues(custCurrency,baseCurrency)
                                                                        })
                                                                    }}
                                                                    currencySelected = {this.state.currencySelected ? this.state.currencySelected : "Not"}
                                                                    xRateCustomerINR = {this.state.xRateCustomerINR}
                                                                    xRateCustomerUSD = {this.state.xRateCustomerUSD}
                                                                    xRateCustomerEUR = {this.state.xRateCustomerEUR}
                                                                    xRateCustomerGBP = {this.state.xRateCustomerGBP}
                                                                />


                                                            </>
                                                        );
                                                    })}
                                                </>
                                                :""}
                                            {this.state.activeFilter === "Cheapest" || this.state.activeFilter1 === "Cheapest"  ?
                                                <>
                                                    {Array.isArray(this.state.chepestRate) && this.state.chepestRate.map((obj, i) => {
                                                        return (
                                                            <>
                                                                <FilterRates
                                                                    quoteFor={this.props.quoteFor}
                                                                    previousSelected={this.state.priviousSelectedId}
                                                                    BookingID={i}
                                                                    data={obj}
                                                                    queryData={this.state.queryData}
                                                                    queryDataForcard={quoteData ? quoteData : {} }
                                                                    rateDataForCard = {this.props.rateDataForCard && this.props.rateDataForCard}
                                                                    title={obj && obj.shippingLineName !=="" ? obj.shippingLineName : obj.product}
                                                                    product={obj && obj.product}
                                                                    shippingLineId={obj.shippingLineId || ""}
                                                                    detail={obj.transitTime}
                                                                    chargeId={obj._id}
                                                                    progressText={obj && obj.converted === true ? obj.convertedAmount : obj.amountB }
                                                                    switchCurrency={this.state.switchCurrency}
                                                                    login={(values) => {
                                                                        if (values === true) {
                                                                            if(localStorage.getItem("already_registered") === "true"){
                                                                                let array = []
                                                                                localStorage.setItem('queryData', JSON.stringify(this.state.queryData))
                                                                                localStorage.setItem('selectedCharge', JSON.stringify(obj))
                                                                                this.setState({SelectedCharges : obj,shipperModel:!this.state.shipperModel },()=>{
                                                                                    Array.isArray(rateData) && rateData.map((itm, j) => {
                                                                                        if(i !== j){
                                                                                            array.push(itm)
                                                                                        }
                                                                                        this.setState({remainingCharges : array})
                                                                                    })
                                                                                })

                                                                            }
                                                                            else{
                                                                                let array = []
                                                                                localStorage.setItem('queryData', JSON.stringify(this.state.queryData))
                                                                                localStorage.setItem('selectedCharge', JSON.stringify(obj))
                                                                                this.setState({SelectedCharges : obj,modelLogin:!this.state.modelLogin },()=>{
                                                                                    Array.isArray(rateData) && rateData.map((itm, j) => {
                                                                                        if(i !== j){
                                                                                            array.push(itm)
                                                                                        }
                                                                                        this.setState({remainingCharges : array})
                                                                                    })
                                                                                })
                                                                            }

                                                                        }
                                                                    }}
                                                                    bookButtonHide = {this.state.bookButtonHide}
                                                                    ratesSelect={(values)=>{
                                                                        this.setState({
                                                                            ratesProceed : values,
                                                                            sideBarOpen : true,
                                                                            bookButtonHide : true
                                                                        },()=>{
                                                                            let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                                                            let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                                                            this.currencyChangeValues(custCurrency,baseCurrency)
                                                                        })
                                                                    }}
                                                                    isUserLoggedIn={(values) => {
                                                                        this.setState({
                                                                            modelLogin: values,
                                                                        });
                                                                    }}
                                                                    currencySelected = {this.state.currencySelected ? this.state.currencySelected : "Not"}
                                                                    xRateCustomerINR = {this.state.xRateCustomerINR}
                                                                    xRateCustomerUSD = {this.state.xRateCustomerUSD}
                                                                    xRateCustomerEUR = {this.state.xRateCustomerEUR}
                                                                    xRateCustomerGBP = {this.state.xRateCustomerGBP}
                                                                />
                                                                </>
                                                        );
                                                    })}
                                                </>
                                                :""}
                                            {this.state.activeFilter === "Fastest" || this.state.activeFilter1 === "Fastest" ?
                                                <>
                                                    {Array.isArray(this.state.fastestRate) && this.state.fastestRate.map((obj, i) => {
                                                        return (
                                                            <>
                                                                <FilterRates
                                                                    quoteFor={this.props.quoteFor}
                                                                    previousSelected={this.state.priviousSelectedId}
                                                                    BookingID={i}
                                                                    data={obj}
                                                                    queryData={this.state.queryData}
                                                                    queryDataForcard={quoteData ? quoteData : {} }
                                                                    rateDataForCard = {this.props.rateDataForCard && this.props.rateDataForCard}
                                                                    title={obj && obj.shippingLineName !=="" ? obj.shippingLineName : obj.product}
                                                                    product={obj && obj.product}
                                                                    shippingLineId={obj.shippingLineId || ""}
                                                                    detail={obj.transitTime}
                                                                    chargeId={obj._id}
                                                                    switchCurrency={this.state.switchCurrency}
                                                                    progressText={obj && obj.converted === true ? obj.convertedAmount : obj.amountB }
                                                                    login={(values) => {
                                                                        if (values === true) {
                                                                            if(localStorage.getItem("already_registered") === "true"){
                                                                                let array = []
                                                                                localStorage.setItem('queryData', JSON.stringify(this.state.queryData))
                                                                                localStorage.setItem('selectedCharge', JSON.stringify(obj))
                                                                                this.setState({SelectedCharges : obj,shipperModel:!this.state.shipperModel },()=>{
                                                                                    Array.isArray(rateData) && rateData.map((itm, j) => {
                                                                                        if(i !== j){
                                                                                            array.push(itm)
                                                                                        }
                                                                                        this.setState({remainingCharges : array})
                                                                                    })
                                                                                })

                                                                            }
                                                                            else{
                                                                                let array = []
                                                                                localStorage.setItem('queryData', JSON.stringify(this.state.queryData))
                                                                                localStorage.setItem('selectedCharge', JSON.stringify(obj))
                                                                                this.setState({SelectedCharges : obj,modelLogin:!this.state.modelLogin },()=>{
                                                                                    Array.isArray(rateData) && rateData.map((itm, j) => {
                                                                                        if(i !== j){
                                                                                            array.push(itm)
                                                                                        }
                                                                                        this.setState({remainingCharges : array})
                                                                                    })
                                                                                })
                                                                            }

                                                                        }
                                                                    }}
                                                                    bookButtonHide = {this.state.bookButtonHide}
                                                                    ratesSelect={(values)=>{
                                                                        this.setState({
                                                                            ratesProceed : values,
                                                                            sideBarOpen : true,
                                                                            bookButtonHide : true
                                                                        },()=>{
                                                                            let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                                                            let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                                                            this.currencyChangeValues(custCurrency,baseCurrency)
                                                                        })
                                                                    }}
                                                                    isUserLoggedIn={(values) => {
                                                                        this.setState({
                                                                            modelLogin: values,
                                                                        });
                                                                    }}
                                                                    currencySelected = {this.state.currencySelected ? this.state.currencySelected : "Not"}
                                                                    xRateCustomerINR = {this.state.xRateCustomerINR}
                                                                    xRateCustomerUSD = {this.state.xRateCustomerUSD}
                                                                    xRateCustomerEUR = {this.state.xRateCustomerEUR}
                                                                    xRateCustomerGBP = {this.state.xRateCustomerGBP}
                                                                />
                                                            </>
                                                        );
                                                    })}
                                                </>
                                                :""}
                                            {this.state.activeFilter === "Transit" ?
                                                <>
                                                    {Array.isArray(this.state.rateDataTransit) && this.state.rateDataTransit.map((obj, i) => {
                                                        return (
                                                            <>
                                                                <FilterRates
                                                                   quoteFor={this.props.quoteFor}
                                                                    previousSelected={this.state.priviousSelectedId}
                                                                    BookingID={i}
                                                                    data={obj}
                                                                    queryData={this.state.queryData}
                                                                    queryDataForcard={quoteData ? quoteData : {} }
                                                                    rateDataForCard = {this.props.rateDataForCard && this.props.rateDataForCard}
                                                                    title={obj && obj.shippingLineName !=="" ? obj.shippingLineName : obj.product}
                                                                    product={obj && obj.product}
                                                                    shippingLineId={obj.shippingLineId || ""}
                                                                    detail={obj.transitTime}
                                                                    chargeId={obj._id}
                                                                    switchCurrency={this.state.switchCurrency}
                                                                    progressText={obj && obj.converted === true ? obj.convertedAmount : obj.amountB }
                                                                    login={(values) => {
                                                                        if (values === true) {
                                                                            if(localStorage.getItem("already_registered") === "true"){
                                                                                this.storeCharge(i,obj._id)
                                                                            }else{
                                                                                let array = []
                                                                                Array.isArray(rateData) && rateData.map((item, j) => {
                                                                                    if (obj._id === item._id) {
                                                                                        localStorage.setItem("selectedCharge", JSON.stringify(item));
                                                                                        if(Array.isArray(item.awaitedRates) && item.awaitedRates.length >= 1){
                                                                                            this.setState({
                                                                                                awaitPopup:!this.state.awaitPopup,
                                                                                                RateIds:item._id,
                                                                                            })
                                                                                        }
                                                                                    } else if (obj._id !== item._id) {
                                                                                        array.push(item);
                                                                                    }
                                                                                    this.setState({ remainingCharges: array },()=>{

                                                                                    });
                                                                                });
                                                                            }
                                                                        }
                                                                    }}
                                                                    bookButtonHide = {this.state.bookButtonHide}
                                                                    ratesSelect={(values)=>{
                                                                        this.setState({
                                                                            ratesProceed : values,
                                                                            sideBarOpen : true,
                                                                            bookButtonHide : true
                                                                        },()=>{
                                                                            let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                                                            let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                                                            this.currencyChangeValues(custCurrency,baseCurrency)
                                                                        })
                                                                    }}
                                                                    isUserLoggedIn={(values) => {
                                                                        this.setState({
                                                                            modelLogin: values,
                                                                        });
                                                                    }}
                                                                   currencySelected = {this.state.currencySelected ? this.state.currencySelected : "Not"}
                                                                   xRateCustomerINR = {this.state.xRateCustomerINR}
                                                                   xRateCustomerUSD = {this.state.xRateCustomerUSD}
                                                                   xRateCustomerEUR = {this.state.xRateCustomerEUR}
                                                                   xRateCustomerGBP = {this.state.xRateCustomerGBP}
                                                                />
                                                            </>
                                                        );
                                                    })}
                                                </>
                                                :""}
                                            {this.state.sideBarOpen ?
                                                <div className={this.state.sideBarOpen?"Query_filter side_bar_rate sideBar_margin bookNowSideBar":"Query_filter side_bar_rate bookNowSideBar "}>
                                                    <h4>Freight Summary</h4>
                                                    <table className="table freight_diff_table">
                                                        <tbody>
                                                        <tr>
                                                            <th>Total Amount</th>
                                                            <td>{this.state.switchCurrency && !this.state.currencySelected ? <>
                                                                    <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>{" "}
                                                                    {this.state.creditDays === "" ? parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC)).toFixed(2) :""}
                                                                    {this.state.creditDays === "15 Days" ? parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC15)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC15)).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days" ? parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC30)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC30)).toFixed(2) :""}
                                                                </>:
                                                                <> {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                    <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                    {this.state.creditDays === "" ? (parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB))* this.state.selectedCurrencyRate).toFixed(2) :""}
                                                                    {this.state.creditDays === "15 Days" ? (parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB15)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB15))* this.state.selectedCurrencyRate).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days" ? (parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB30)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB30))* this.state.selectedCurrencyRate).toFixed(2) :""}
                                                                </>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Discount</th>
                                                            {this.state.ratesProceed && this.state.ratesProceed.coupanApply ?
                                                                <td style={{color:'#009476',fontWeight:'700'}}><FontAwesomeIcon size="sm" icon={this.state.baseRatesCurrency}/>{" "}
                                                                    {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.discountAmount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.discountAmount).toFixed(2):"" :""}
                                                                    {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.discountAmount15 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.discountAmount15).toFixed(2):"" :""}
                                                                    {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.discountAmount30 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.discountAmount30).toFixed(2):"" :""}
                                                                </td>:
                                                                <td>-</td>}
                                                        </tr>
                                                        <tr>
                                                            <th>GST</th>
                                                            <td>
                                                                {this.state.ratesProceed && this.state.ratesProceed.coupanApply ?
                                                                    <>
                                                                        {this.state.switchCurrency && !this.state.currencySelected  ? <>
                                                                                <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>{" "}
                                                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount15 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount15).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount30 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount30).toFixed(2):"" :""}

                                                                            </>:
                                                                            <>
                                                                                {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                                    <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount15 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount15)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount30 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount30)* this.state.selectedCurrencyRate).toFixed(2).toFixed(2):"" :""}
                                                                            </>}

                                                                    </>
                                                                    : <>
                                                                        {this.state.switchCurrency && !this.state.currencySelected  ? <>
                                                                                <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>
                                                                                {" "}
                                                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountC != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountC15 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC15).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountC30 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC30).toFixed(2):"" :""}
                                                                            </>:
                                                                            <>
                                                                                {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                                    <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountB != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountB15 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB15)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountB30 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB30)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                            </>}

                                                                    </>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="total_amount_diff">Net Payable Amount</th>
                                                            <td className="total_amount_diff">
                                                                {this.state.ratesProceed && this.state.ratesProceed.coupanApply ?
                                                                    <>
                                                                        {this.state.switchCurrency && !this.state.currencySelected ? <>
                                                                                <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>{" "}
                                                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.amountCDiscount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountCDiscount).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountC15Discount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC15Discount).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountC30Discount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC30Discount).toFixed(2):"" :""}

                                                                            </>:
                                                                            <>
                                                                                {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                                    <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.amountBDiscount != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountBDiscount)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountB15Discount != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB15Discount)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountB30Discount != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB30Discount)* this.state.selectedCurrencyRate).toFixed(2):"" :""}

                                                                            </>}

                                                                    </>
                                                                    : <>
                                                                        {this.state.switchCurrency && !this.state.currencySelected ? <>
                                                                                <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>{" "}
                                                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.amountC != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountC15 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC15).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountC30 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC30).toFixed(2):"" :""}

                                                                            </>:
                                                                            <>
                                                                                {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                                    <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.amountB != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountB15 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB15)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountB30 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB30)* this.state.selectedCurrencyRate).toFixed(2):"" :""}

                                                                            </>}

                                                                    </>}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    {this.state.ratesProceed && this.state.ratesProceed.coupanApply ?
                                                        <div className="apply_coupon_div" onClick={e=>{
                                                            e.preventDefault()
                                                            this.removeCouponFunctionality(false)
                                                        }}>Remove Coupon</div>:
                                                        // <div className="apply_coupon_div" onClick={()=>{
                                                        //     this.setState({
                                                        //         payLarge : true
                                                        //     })
                                                        // }}>Apply Coupon</div>
                                                        ""
                                                    }
                                                    <div className="btn proceed_btn" onClick={this.proceedBtn}>Proceed</div>
                                                    {this.state.coupanNames ?
                                                        <>
                                                            <AddCoupanApplyForPreview
                                                                modalLarge={this.state.payLarge1}
                                                                setmodal1={values => values === false ? this.setState({payLarge: false}) : ""}
                                                                quoteId = {this.state.quoteId}
                                                                customerBranchId = {this.state.custBranchId}
                                                                customerId = {this.state.customerId}
                                                                rateId = {this.state.ratesProceed && this.state.ratesProceed._id ? this.state.ratesProceed._id : ""}
                                                                coupanName = {this.state.coupanName ? this.state.coupanName : ""}
                                                                coupanNames = {this.state.coupanNames}
                                                                sendDataAfterCoupan={(values)=>{
                                                                    this.setState({
                                                                        ratesProceed : values,
                                                                        coupanNames:false,
                                                                        // sideBarOpen : true
                                                                    },()=>{
                                                                        if(values && values.coupanApply){
                                                                            this.setState({
                                                                                coupanName : values && values.coupanId ? values.coupanId : "",
                                                                            })
                                                                        }else{
                                                                            this.setState({
                                                                                coupanName : "",
                                                                            })
                                                                        }
                                                                        let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                                                        let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                                                        this.currencyChangeValues(custCurrency,baseCurrency)
                                                                        this.getServiceProviderApi()
                                                                    })
                                                                }}
                                                                couponData = {this.state.couponData}
                                                                queryFor={this.state.queryData.queryFor}
                                                                sendDataAfterCoupan1 = {values =>{
                                                                    values && values.map((obj, i) => {
                                                                        if((this.state.ratesProceed && this.state.ratesProceed._id) === values[i]._id){
                                                                            this.setState({
                                                                                applyCoupan : obj.coupanApply,
                                                                                discountAmount : obj.discountAmount,
                                                                                discountAmount15 : obj.discountAmount15,
                                                                                discountAmount30 : obj.discountAmount30,
                                                                                amountCDiscount : obj.amountCDiscount,
                                                                                amountC15Discount : obj.amountC15Discount,
                                                                                amountC30Discount : obj.amountC30Discount,
                                                                                amountBDiscount : obj.amountBDiscount,
                                                                                amountB15Discount : obj.amountB15Discount,
                                                                                amountB30Discount : obj.amountB30Discount,
                                                                                taxAmountB15Discount : obj.taxAmountB15Discount,
                                                                                taxAmountB30Discount : obj.taxAmountB30Discount,
                                                                                taxAmountBDiscount : obj.taxAmountBDiscount,
                                                                                taxAmountC15Discount : obj.taxAmountC15Discount,
                                                                                taxAmountC30Discount : obj.taxAmountC30Discount,
                                                                                taxAmountCDiscount : obj.taxAmountCDiscount,
                                                                                coupanName : obj.coupanName,
                                                                                coupanId : obj.coupanId,
                                                                                rateeId : obj._id,
                                                                            })
                                                                        }
                                                                    })

                                                                }}


                                                            />
                                                        </>
                                                        :
                                                        <AddCoupanApplyForPreview
                                                            modalLarge={this.state.payLarge1}
                                                            setmodal1={values => values === false ? this.setState({payLarge: false}) : ""}
                                                            quoteId = {this.state.quoteId}
                                                            customerBranchId = {this.state.custBranchId}
                                                            customerId = {this.state.customerId}
                                                            rateId = {this.state.ratesProceed && this.state.ratesProceed._id ? this.state.ratesProceed._id : ""}
                                                            coupanName = {this.state.coupanName ? this.state.coupanName : ""}
                                                            coupanNames = {this.state.coupanNames}
                                                            sendDataAfterCoupan={(values)=>{
                                                                this.setState({
                                                                    ratesProceed : values,
                                                                    coupanNames:false,
                                                                    // sideBarOpen : true
                                                                },()=>{
                                                                    if(values && values.coupanApply){
                                                                        this.setState({
                                                                            coupanName : values && values.coupanId ? values.coupanId : "",
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            coupanName : "",
                                                                        })
                                                                    }
                                                                    let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                                                    let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                                                    this.currencyChangeValues(custCurrency,baseCurrency)
                                                                    this.getServiceProviderApi()
                                                                })
                                                            }}
                                                            couponData = {this.state.couponData}
                                                            queryFor={this.state.queryData.queryFor}
                                                            sendDataAfterCoupan1 = {values =>{
                                                                values && values.map((obj, i) => {
                                                                    if((this.state.ratesProceed && this.state.ratesProceed._id) === values[i]._id){
                                                                        this.setState({
                                                                            applyCoupan : obj.coupanApply,
                                                                            discountAmount : obj.discountAmount,
                                                                            discountAmount15 : obj.discountAmount15,
                                                                            discountAmount30 : obj.discountAmount30,
                                                                            amountCDiscount : obj.amountCDiscount,
                                                                            amountC15Discount : obj.amountC15Discount,
                                                                            amountC30Discount : obj.amountC30Discount,
                                                                            amountBDiscount : obj.amountBDiscount,
                                                                            amountB15Discount : obj.amountB15Discount,
                                                                            amountB30Discount : obj.amountB30Discount,
                                                                            taxAmountB15Discount : obj.taxAmountB15Discount,
                                                                            taxAmountB30Discount : obj.taxAmountB30Discount,
                                                                            taxAmountBDiscount : obj.taxAmountBDiscount,
                                                                            taxAmountC15Discount : obj.taxAmountC15Discount,
                                                                            taxAmountC30Discount : obj.taxAmountC30Discount,
                                                                            taxAmountCDiscount : obj.taxAmountCDiscount,
                                                                            coupanName : obj.coupanName,
                                                                            coupanId : obj.coupanId,
                                                                            rateeId : obj._id,
                                                                        })
                                                                    }
                                                                })

                                                            }}


                                                        />
                                                    }
                                                </div>
                                                :""}
                                        </>
                                    :""}

                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.sideBarOpen ?
                            <>
                            {this.state.serviceProvider === true ?
                            <div className={this.state.sideBarOpen?"Query_filter side_bar_rate sideBar_margin sideBarDesktop":"Query_filter side_bar_rate sideBarDesktop"}>
                            <h4>Freight Summary</h4>
                                <table className="table freight_diff_table">
                                    <tbody>
                                    <tr>
                                        <th>Total Amount</th>
                                        <td>{this.state.switchCurrency && !this.state.currencySelected ? <>
                                                <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>{" "}
                                                {this.state.creditDays === "" ? parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC)).toFixed(2) :""}
                                                {this.state.creditDays === "15 Days" ? parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC15)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC15)).toFixed(2) :""}
                                                {this.state.creditDays === "30 Days" ? parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC30)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC30)).toFixed(2) :""}
                                            </>:
                                            <> {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                {this.state.creditDays === "" ? (parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB))* this.state.selectedCurrencyRate).toFixed(2) :""}
                                                {this.state.creditDays === "15 Days" ? (parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB15)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB15))* this.state.selectedCurrencyRate).toFixed(2) :""}
                                                {this.state.creditDays === "30 Days" ? (parseFloat(parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB30)-parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB30))* this.state.selectedCurrencyRate).toFixed(2) :""}
                                            </>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Discount</th>
                                        {this.state.ratesProceed && this.state.ratesProceed.coupanApply ?
                                            <td style={{color:'#009476',fontWeight:'700'}}><FontAwesomeIcon size="sm" icon={this.state.baseRatesCurrency}/>{" "}
                                                {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.discountAmount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.discountAmount).toFixed(2):"" :""}
                                                {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.discountAmount15 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.discountAmount15).toFixed(2):"" :""}
                                                {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.discountAmount30 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.discountAmount30).toFixed(2):"" :""}
                                            </td>:
                                            <td>-</td>}
                                    </tr>
                                    <tr>
                                        <th>GST</th>
                                        <td>
                                            {this.state.ratesProceed && this.state.ratesProceed.coupanApply ?
                                                <>
                                                    {this.state.switchCurrency && !this.state.currencySelected  ? <>
                                                            <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>{" "}
                                                            {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount).toFixed(2):"" :""}
                                                            {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount15 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount15).toFixed(2):"" :""}
                                                            {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount30 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountCDiscount30).toFixed(2):"" :""}

                                                        </>:
                                                        <>
                                                            {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                            {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                            {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount15 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount15)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                            {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount30 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountBDiscount30)* this.state.selectedCurrencyRate).toFixed(2).toFixed(2):"" :""}
                                                        </>}

                                                </>
                                                : <>
                                                    {this.state.switchCurrency && !this.state.currencySelected  ? <>
                                                            <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>
                                                            {" "}
                                                            {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountC != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC).toFixed(2):"" :""}
                                                            {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountC15 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC15).toFixed(2):"" :""}
                                                            {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountC30 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountC30).toFixed(2):"" :""}
                                                        </>:
                                                        <>
                                                            {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                            {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountB != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                            {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountB15 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB15)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                            {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.taxAmountB30 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.taxAmountB30)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                        </>}

                                                </>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="total_amount_diff">Net Payable Amount</th>
                                        <td className="total_amount_diff">
                                            {this.state.ratesProceed && this.state.ratesProceed.coupanApply ?
                                                <>
                                                    {this.state.switchCurrency && !this.state.currencySelected ? <>
                                                            <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>{" "}
                                                            {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.amountCDiscount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountCDiscount).toFixed(2):"" :""}
                                                            {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountC15Discount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC15Discount).toFixed(2):"" :""}
                                                            {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountC30Discount != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC30Discount).toFixed(2):"" :""}

                                                        </>:
                                                        <>
                                                            {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                            {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.amountBDiscount != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountBDiscount)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                            {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountB15Discount != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB15Discount)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                            {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountB30Discount != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB30Discount)* this.state.selectedCurrencyRate).toFixed(2):"" :""}

                                                        </>}

                                                </>
                                                : <>
                                                    {this.state.switchCurrency && !this.state.currencySelected ? <>
                                                            <FontAwesomeIcon size="sm" icon={this.state.customerRatesCurrency}/>{" "}
                                                            {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.amountC != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC).toFixed(2):"" :""}
                                                            {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountC15 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC15).toFixed(2):"" :""}
                                                            {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountC30 != undefined ? parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountC30).toFixed(2):"" :""}

                                                        </>:
                                                        <>
                                                            {this.state.currencySelected === "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected === "USD" ? (<FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected === "EUR" ? (
                                                                <FontAwesomeIcon icon={faEuroSign} />) : this.state.currencySelected === "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                            {this.state.creditDays === "" ? this.state.ratesProceed && this.state.ratesProceed.amountB != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                            {this.state.creditDays === "15 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountB15 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB15)* this.state.selectedCurrencyRate).toFixed(2):"" :""}
                                                            {this.state.creditDays === "30 Days" ? this.state.ratesProceed && this.state.ratesProceed.amountB30 != undefined ? (parseFloat(this.state.ratesProceed && this.state.ratesProceed.amountB30)* this.state.selectedCurrencyRate).toFixed(2):"" :""}

                                                        </>}

                                                </>}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            {this.state.ratesProceed && this.state.ratesProceed.coupanApply ?
                                <div className="apply_coupon_div" onClick={e=>{
                                    e.preventDefault()
                                    this.removeCouponFunctionality(false)
                                }}>Remove Coupon</div>:
                                // <div className="apply_coupon_div" onClick={()=>{
                                //     this.setState({
                                //         payLarge : true
                                //     })
                                // }}>Apply Coupon</div>
                                ""

                            }

                            <div className="btn proceed_btn" onClick={this.proceedBtn}>Proceed</div>
                                {this.state.coupanNames ?
                                    <>
                                        <AddCoupanApplyForPreview
                                            modalLarge={this.state.payLarge1}
                                            setmodal1={values => values === false ? this.setState({payLarge: false}) : ""}
                                            quoteId = {this.state.quoteId}
                                            customerBranchId = {this.state.custBranchId}
                                            customerId = {this.state.customerId}
                                            rateId = {this.state.ratesProceed && this.state.ratesProceed._id ? this.state.ratesProceed._id : ""}
                                            coupanName = {this.state.coupanName ? this.state.coupanName : ""}
                                            coupanNames = {this.state.coupanNames}
                                            sendDataAfterCoupan={(values)=>{
                                                this.setState({
                                                    ratesProceed : values,
                                                    coupanNames:false,
                                                    // sideBarOpen : true
                                                },()=>{
                                                    if(values && values.coupanApply){
                                                        this.setState({
                                                            coupanName : values && values.coupanId ? values.coupanId : "",
                                                        })
                                                    }else{
                                                        this.setState({
                                                            coupanName : "",
                                                        })
                                                    }
                                                    let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                                    let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                                    this.currencyChangeValues(custCurrency,baseCurrency)
                                                    this.getServiceProviderApi()
                                                })
                                            }}
                                            couponData = {this.state.couponData}
                                            queryFor={this.state.queryData.queryFor}
                                            sendDataAfterCoupan1 = {values =>{
                                                values && values.map((obj, i) => {
                                                    if((this.state.ratesProceed && this.state.ratesProceed._id) === values[i]._id){
                                                        this.setState({
                                                            applyCoupan : obj.coupanApply,
                                                            discountAmount : obj.discountAmount,
                                                            discountAmount15 : obj.discountAmount15,
                                                            discountAmount30 : obj.discountAmount30,
                                                            amountCDiscount : obj.amountCDiscount,
                                                            amountC15Discount : obj.amountC15Discount,
                                                            amountC30Discount : obj.amountC30Discount,
                                                            amountBDiscount : obj.amountBDiscount,
                                                            amountB15Discount : obj.amountB15Discount,
                                                            amountB30Discount : obj.amountB30Discount,
                                                            taxAmountB15Discount : obj.taxAmountB15Discount,
                                                            taxAmountB30Discount : obj.taxAmountB30Discount,
                                                            taxAmountBDiscount : obj.taxAmountBDiscount,
                                                            taxAmountC15Discount : obj.taxAmountC15Discount,
                                                            taxAmountC30Discount : obj.taxAmountC30Discount,
                                                            taxAmountCDiscount : obj.taxAmountCDiscount,
                                                            coupanName : obj.coupanName,
                                                            coupanId : obj.coupanId,
                                                            rateeId : obj._id,
                                                        })
                                                    }
                                                })

                                            }}


                                        />
                                    </>
                                    :
                                    <AddCoupanApplyForPreview
                                        modalLarge={this.state.payLarge1}
                                        setmodal1={values => values === false ? this.setState({payLarge: false}) : ""}
                                        quoteId = {this.state.quoteId}
                                        customerBranchId = {this.state.custBranchId}
                                        customerId = {this.state.customerId}
                                        rateId = {this.state.ratesProceed && this.state.ratesProceed._id ? this.state.ratesProceed._id : ""}
                                        coupanName = {this.state.coupanName ? this.state.coupanName : ""}
                                        coupanNames = {this.state.coupanNames}
                                        sendDataAfterCoupan={(values)=>{
                                            this.setState({
                                                ratesProceed : values,
                                                coupanNames:false,
                                                // sideBarOpen : true
                                            },()=>{
                                                if(values && values.coupanApply){
                                                    this.setState({
                                                        coupanName : values && values.coupanId ? values.coupanId : "",
                                                    })
                                                }else{
                                                    this.setState({
                                                        coupanName : "",
                                                    })
                                                }
                                                let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                                let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                                this.currencyChangeValues(custCurrency,baseCurrency)
                                                this.getServiceProviderApi()
                                            })
                                        }}
                                        couponData = {this.state.couponData}
                                        queryFor={this.state.queryData.queryFor}
                                        sendDataAfterCoupan1 = {values =>{
                                            values && values.map((obj, i) => {
                                                if((this.state.ratesProceed && this.state.ratesProceed._id) === values[i]._id){
                                                    this.setState({
                                                        applyCoupan : obj.coupanApply,
                                                        discountAmount : obj.discountAmount,
                                                        discountAmount15 : obj.discountAmount15,
                                                        discountAmount30 : obj.discountAmount30,
                                                        amountCDiscount : obj.amountCDiscount,
                                                        amountC15Discount : obj.amountC15Discount,
                                                        amountC30Discount : obj.amountC30Discount,
                                                        amountBDiscount : obj.amountBDiscount,
                                                        amountB15Discount : obj.amountB15Discount,
                                                        amountB30Discount : obj.amountB30Discount,
                                                        taxAmountB15Discount : obj.taxAmountB15Discount,
                                                        taxAmountB30Discount : obj.taxAmountB30Discount,
                                                        taxAmountBDiscount : obj.taxAmountBDiscount,
                                                        taxAmountC15Discount : obj.taxAmountC15Discount,
                                                        taxAmountC30Discount : obj.taxAmountC30Discount,
                                                        taxAmountCDiscount : obj.taxAmountCDiscount,
                                                        coupanName : obj.coupanName,
                                                        coupanId : obj.coupanId,
                                                        rateeId : obj._id,
                                                    })
                                                }
                                            })

                                        }}


                                    />
                                }
                        </div>
                            :""}
                            </>
                                :
                            <div className="Query_filter side_bar_rate sideBar_margin sideBarDesktop offersAds" hidden={this.state.couponData && this.state.couponData.length === 0}>
                                <div className="advertiseMent">
                                    <h4>Offers</h4>
                                    {this.state.couponData && this.state.couponData.length >0?
                                        this.state.couponData.map((item,index)=>{

                                            if((item.coupanData && item.coupanData.queryFor) === (this.state.queryData && this.state.queryData.queryFor)){
                                                return(
                                                    <div className="deal">
                                                        <div className="imgbox"><img
                                                            src={item.attachments[0]} alt={item.title}/></div>
                                                        <div className="dealTxt">
                                                            <div className="dealName">{item.title}</div>
                                                            {/*<div className="coupon-base-benefit ">Upto <b> {item && item.coupanData && item.coupanData.isInPersentage ? "" : item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR" :""} {item && item.coupanData && item.coupanData.isInPersentage ?  item && item.coupanData ? item.coupanData.discountPercent + " %": "": item && item.coupanData ? item.coupanData.maxDiscountAmount:""}</b> Off</div>*/}
                                                            <p>Upto {item && item.coupanData && item.coupanData.isInPersentage ? "" : item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR" :""} {item && item.coupanData && item.coupanData.isInPersentage ?  item && item.coupanData ? item.coupanData.discountPercent + " %": "": item && item.coupanData ? item.coupanData.maxDiscountAmount:""} off on minimum order of {item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR":""} {item && item.coupanData ? item.coupanData.applicableAmount:""}.</p>
                                                            <div className="promo-val d-flex flex-wrap align-items-center justify-content-between">
                                                                <Link to="/OfferMy" target="_blank">
                                                                    <div className="apply_coupon_div termsCondition">Terms & Conditions</div>
                                                                </Link>
                                                                <div className="sm_vld"><FontAwesomeIcon icon={faClock}/> {" "}
                                                                    Valid till: {item && item.validityEndDate ? convertDateISOToCustomiseFormat(item.validityEndDate) : ""}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    // <div className="allCoupon">
                                                    //
                                                    //     <div className="coupon-details d-flex flex-wrap">
                                                    //         <div className="coupon-base-customCheckbox">
                                                    //         {/*<i className="fas fa-circle"></i>*/}
                                                    //            <span className="coupon-name">{item && item.coupanData ? item.coupanData.name : ""}</span>
                                                    //         </div>
                                                    //         <div className="icon_coupon">
                                                    //             <div className="coupon-base-benefit ">Upto <b> {item && item.coupanData && item.coupanData.isInPersentage ? "" : item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR" :""} {item && item.coupanData && item.coupanData.isInPersentage ?  item && item.coupanData ? item.coupanData.discountPercent + " %": "": item && item.coupanData ? item.coupanData.maxDiscountAmount:""}</b> Off</div>
                                                    //             <div className="readmore-base-fullWidthContainer">Upto {item && item.coupanData && item.coupanData.isInPersentage ? "" : item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR" :""} {item && item.coupanData && item.coupanData.isInPersentage ?  item && item.coupanData ? item.coupanData.discountPercent + " %": "": item && item.coupanData ? item.coupanData.maxDiscountAmount:""} off on minimum order of {item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR":""} {item && item.coupanData ? item.coupanData.applicableAmount:""}.</div>
                                                    //             <div className="coupon-base-expiryBlock">Expires on : {" "}
                                                    //                 <b>{item && item.coupanData && item.coupanData.validityEndDate ? convertDateISOToCustomiseFormat(item.coupanData.validityEndDate) : ""}</b> <span className="coupon-base-expiryTime">11:59 PM</span>.
                                                    //             </div>
                                                    //             <Link to="/OfferMy" target="_blank">
                                                    //                 <div className="apply_coupon_div termsCondition">Terms & Conditions</div>
                                                    //             </Link>
                                                    //
                                                    //         </div>
                                                    //     </div>
                                                    // </div>
                                                )
                                            }
                                            if(!item.coupanData){
                                                return(
                                                    <div className="deal">
                                                        <div className="imgbox"><img
                                                            src={item.attachments[0]} alt={item.title}/></div>
                                                        <div className="dealTxt">
                                                            <div className="dealName">{item.title}</div>
                                                            <p>{item.description}</p>
                                                            <div className={item.hitUrl?"promo-val d-flex flex-wrap align-items-center justify-content-between":"promo-val"}>
                                                                {item.hitUrl?<a href={item && item.hitUrl ? item.hitUrl : ""} target="_blank">
                                                                    <div className="apply_coupon_div termsCondition">View Details</div>
                                                                </a>:""}
                                                                <div className="sm_vld"><span
                                                                    className="timeIcn2"></span>
                                                                    Valid till: {item && item.validityEndDate ? convertDateISOToCustomiseFormat(item.validityEndDate) : ""}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            }
                                        })
                                        : ""}
                                </div>

                            </div>
                        }
                        </div>

                    <AddCoupanApplyForHome
                        modalLarge={this.state.payLarge}
                        setmodal1={values => values === false ? this.setState({payLarge: false}) : ""}
                        quoteId = {this.state.quoteId}
                        customerBranchId = {this.state.custBranchId}
                        customerId = {this.state.customerId}
                        rateId = {this.state.ratesProceed && this.state.ratesProceed._id ? this.state.ratesProceed._id : ""}
                        sendDataAfterCoupan={(values)=>{
                            this.setState({
                                ratesProceed : values,
                                coupanNames:false,
                                // sideBarOpen : true
                            },()=>{
                                let custCurrency = this.state.ratesProceed && this.state.ratesProceed.customerCurrency ? this.state.ratesProceed.customerCurrency:""
                                let baseCurrency = this.state.ratesProceed && this.state.ratesProceed.baseCurrency ? this.state.ratesProceed.baseCurrency:""
                                this.currencyChangeValues(custCurrency,baseCurrency)
                                this.getServiceProviderApi()
                            })
                        }}
                        sendDataAfterCoupan1 = {values =>{
                            values && values.map((obj, i) => {
                                if((this.state.ratesProceed && this.state.ratesProceed._id) === values[i]._id){
                                    this.setState({
                                        applyCoupan : obj.coupanApply,
                                        discountAmount : obj.discountAmount,
                                        discountAmount15 : obj.discountAmount15,
                                        discountAmount30 : obj.discountAmount30,
                                        amountCDiscount : obj.amountCDiscount,
                                        amountC15Discount : obj.amountC15Discount,
                                        amountC30Discount : obj.amountC30Discount,
                                        amountBDiscount : obj.amountBDiscount,
                                        amountB15Discount : obj.amountB15Discount,
                                        amountB30Discount : obj.amountB30Discount,
                                        taxAmountB15Discount : obj.taxAmountB15Discount,
                                        taxAmountB30Discount : obj.taxAmountB30Discount,
                                        taxAmountBDiscount : obj.taxAmountBDiscount,
                                        taxAmountC15Discount : obj.taxAmountC15Discount,
                                        taxAmountC30Discount : obj.taxAmountC30Discount,
                                        taxAmountCDiscount : obj.taxAmountCDiscount,
                                        coupanName : obj.coupanName,
                                        coupanId : obj.coupanId,
                                        rateeId : obj._id,
                                    })
                                }
                            })
                        }}


                    />
                    {/*</ModalBody>*/}
                </>

                {/*</Modal>*/}
            </>
        )}
}

const mapStateToProps = (state) => {
    const {user, loading, message, LoginMessage, closeModal} = state.authUser;
    const {RateNotFound, allOriginItems, allCharges,BookingMassage, queryNo,sporRateQueryId, error,OpenPerForma,queryDataForcard,BookingDataFromRevert} = state.advanceSearchReducer;
    return {user, loading, message, LoginMessage, closeModal,queryNo,OpenPerForma,BookingMassage,BookingDataFromRevert};
};

const RateQuotedCardNewWithRoute = withRouter(CourierRateQuotedCardNew);

export default connect(
    mapStateToProps,
    {
        registerUser, loginUser,sendQuery,sendBookingRates
    }
)(RateQuotedCardNewWithRoute);
