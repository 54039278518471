import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import Slider from "react-slick";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faLinkedinIn, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";

class OurTeam extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        centerMode: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        centerMode: false,
                        initialSlide: 2,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        };
        return (
    <>
            <AppLayout>
            <div className="bg-white" ref={this.testRef}>
                <div className="News_Section team_section">
                    <h2>Our Team</h2>
                </div>
                <div className="services_content ">
                    <div className="container-fluid">
                        <div className="">
                            <div className="our_team">
                                <Slider {...settings}>
                                    <div>
                                        <div className="team_img">
                                            <div className="team_section_img">
                                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.13367551340434325-1638946223401.png" alt="Dr. Ambrish Kumar"/>
                                                <div className="team_overlay">
                                                    <div className="team_social">
                                                        <div>
                                                            <Link className="facebook" to={{  pathname: "https://www.facebook.com/zipaworld/" }}  target="_blank"><FontAwesomeIcon icon={faFacebookF}/></Link>
                                                            {/*<a className="facebook" href="https://www.facebook.com/zipaworld/"*/}
                                                            {/*    target="_blank"><FontAwesomeIcon icon={faFacebookF}/></a>*/}
                                                        </div>
                                                        <div>
                                                            <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>
                                                            {/*<Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>*/}
                                                        </div>
                                                        <div>
                                                            <Link className="linkedin" to={{  pathname: "https://in.linkedin.com/company/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></Link>
                                                            {/*<a className="linkedin" href="https://in.linkedin.com/company/zipaworld"*/}
                                                            {/*   target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></a>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="team_name">
                                                <h3>Dr. Ambrish Kumar</h3>
                                                <h5>Founder and Group CEO</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="team_img">
                                            <div className="team_section_img">
                                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.7024682444286672-1639207258221.jpg" alt="Shashank Jain"/>
                                                <div className="team_overlay">
                                                    <div className="team_social">
                                                        <div><Link className="facebook" to={{  pathname: "https://www.facebook.com/zipaworld/" }}  target="_blank"><FontAwesomeIcon icon={faFacebookF}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="linkedin" to={{  pathname: "https://in.linkedin.com/company/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="team_name">
                                                <h3>Shashank Jain</h3>
                                                <h5>Penny Keeper</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div>*/}
                                    {/*    <div className="team_img">*/}
                                    {/*        <div className="team_section_img">*/}
                                    {/*            <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8489403822245021-1639207004587.jpg"/>*/}
                                    {/*            <div className="team_overlay">*/}
                                    {/*                <div className="team_social">*/}
                                    {/*                    <div><a className="facebook" href="https://www.facebook.com/zipaworld/"*/}
                                    {/*                            target="_blank"><FontAwesomeIcon icon={faFacebookF}/></a>*/}
                                    {/*                    </div>*/}
                                    {/*                    <div>*/}
                                    {/*                        <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>*/}
                                    {/*                    </div>*/}
                                    {/*                    <div>*/}
                                    {/*                        <a className="linkedin" href="https://in.linkedin.com/company/zipaworld"*/}
                                    {/*                           target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></a>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="team_name">*/}
                                    {/*            <h3>Aditi Sood</h3>*/}
                                    {/*            <h5>Strategic Matchmaker</h5>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div>
                                        <div className="team_img">
                                            <div className="team_section_img">
                                                <img src = "https://test231220.s3.amazonaws.com/unTracked/s3Bucketoo0.6982381475434416-1639207168592.jpg" alt="Rohit Singh"/>
                                                <div className="team_overlay">
                                                    <div className="team_social">
                                                        <div><Link className="facebook" to={{  pathname: "https://www.facebook.com/zipaworld/" }}  target="_blank"><FontAwesomeIcon icon={faFacebookF}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="linkedin" to={{  pathname: "https://in.linkedin.com/company/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="team_name">
                                                <h3>Rohit Singh</h3>
                                                <h5>Operations Ninja</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div>*/}
                                    {/*    <div className="team_img">*/}
                                    {/*        <div className="team_section_img">*/}
                                    {/*            <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.2667414249533979-1639207228095.jpg"/>*/}
                                    {/*            <div className="team_overlay">*/}
                                    {/*                <div className="team_social">*/}
                                    {/*                    <div><a className="facebook" href="https://www.facebook.com/zipaworld/"*/}
                                    {/*                           target="_blank"><FontAwesomeIcon icon={faFacebookF}/></a>*/}
                                    {/*                    </div>*/}
                                    {/*                    <div>*/}
                                    {/*                        <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>*/}
                                    {/*                    </div>*/}
                                    {/*                    <div>*/}
                                    {/*                        <a className="linkedin" href="https://in.linkedin.com/company/zipaworld"*/}
                                    {/*                           target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></a>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}

                                    {/*        <div className="team_name">*/}
                                    {/*            <h3>Sangeeta Kittu</h3>*/}
                                    {/*            <h5>Second Home Creator</h5>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div>*/}
                                    {/*    <div className="team_img">*/}
                                    {/*        <div className="team_section_img">*/}
                                    {/*            <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.18453025651212407-1639207197814.jpg"/>*/}
                                    {/*            <div className="team_overlay">*/}
                                    {/*                <div className="team_social">*/}
                                    {/*                    <div><a className="facebook" href="https://www.facebook.com/zipaworld/"*/}
                                    {/*                            target="_blank"><FontAwesomeIcon icon={faFacebookF}/></a>*/}
                                    {/*                    </div>*/}
                                    {/*                    <div>*/}
                                    {/*                        <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>*/}
                                    {/*                    </div>*/}
                                    {/*                    <div>*/}
                                    {/*                        <a className="linkedin" href="https://in.linkedin.com/company/zipaworld"*/}
                                    {/*                           target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></a>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="team_name">*/}
                                    {/*            <h3>Sachid Satyen</h3>*/}
                                    {/*            <h5>Commotion Creator</h5>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div>
                                        <div className="team_img">
                                            <div className="team_section_img">
                                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.992719137726155-1639207284967.jpg" alt="Suveer Chaturvedi"/>
                                                <div className="team_overlay">
                                                    <div className="team_social">
                                                        <div><Link className="facebook" to={{  pathname: "https://www.facebook.com/zipaworld/" }}  target="_blank"><FontAwesomeIcon icon={faFacebookF}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="linkedin" to={{  pathname: "https://in.linkedin.com/company/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="team_name">
                                                <h3>Suveer Chaturvedi</h3>
                                                <h5>Master Handshaker</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="team_img">
                                            <div className="team_section_img">
                                                <img src = "https://test231220.s3.amazonaws.com/unTracked/s3Bucketoo0.5693766529079156-1639207572300.jpg" alt="Vaibhav Jajoo"/>
                                                <div className="team_overlay">
                                                    <div className="team_social">
                                                        <div><Link className="facebook" to={{  pathname: "https://www.facebook.com/zipaworld/" }}  target="_blank"><FontAwesomeIcon icon={faFacebookF}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="linkedin" to={{  pathname: "https://in.linkedin.com/company/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="team_name">
                                                <h3>Vaibhav Jajoo</h3>
                                                <h5>Lead Door Knocker</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="team_img">
                                            <div className="team_section_img">
                                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.2533041436754593-1639207083637.jpg" alt="Rohit Sharma"/>
                                                <div className="team_overlay">
                                                    <div className="team_social">
                                                        <div><Link className="facebook" to={{  pathname: "https://www.facebook.com/zipaworld/" }}  target="_blank"><FontAwesomeIcon icon={faFacebookF}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="linkedin" to={{  pathname: "https://in.linkedin.com/company/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="team_name">
                                                <h3>Rohit Sharma</h3>
                                                <h5>Code Breaker</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="team_img">
                                            <div className="team_section_img">
                                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.935391258049822-1639207127377.jpg" alt="Harsh Kumar"/>
                                                <div className="team_overlay">
                                                    <div className="team_social">
                                                        <div><Link className="facebook" to={{  pathname: "https://www.facebook.com/zipaworld/" }}  target="_blank"><FontAwesomeIcon icon={faFacebookF}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="twitter" to={{  pathname: "https://twitter.com/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faTwitter}/></Link>
                                                        </div>
                                                        <div>
                                                            <Link className="linkedin" to={{  pathname: "https://in.linkedin.com/company/zipaworld" }}  target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="team_name">
                                                <h3>Harsh Kumar</h3>
                                                <h5>The Tech Chilly</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="media_img">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4>PHOTO GALLERY</h4>
                            </div>
                        </div>
                        <div className="gallery_my">
                            <div className="img_gallery">
                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9156214701238254-1634981006747.JPG" alt="Gallery"/>
                            </div>

                            <div className="img_gallery">
                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.46407694983503656-1634981043919.JPG" alt="Gallery"/>
                            </div>

                            <div className="img_gallery">
                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8191514985401938-1634981075932.JPG" alt="Gallery"/>
                            </div>

                            <div className="img_gallery">
                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.4589013092220664-1634981096804.JPG" alt="Gallery"/>
                            </div>

                            <div className="img_gallery">
                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.47305904227562223-1634981120485.JPG" alt="Gallery"/>
                            </div>
                        </div>

                        <div className="btn gallery_btn " onClick={e => {
                            history.push(PAGE_PATHS.LIFE_AT_ZIPA)
                            window.location.reload();
                        }}>Show More</div>
                    </div>
                </div>

                <FooterHome/>
            </div>
            </AppLayout>
            </>

        );
    }


}



export default OurTeam;
