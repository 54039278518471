import {all, call, fork, put} from 'redux-saga/effects';
import RestClient from "../../util/RestClient";
import {
    BOOKING_MANAGER_MASTER_UPDATE_HOUSE_DATA,
} from "../../constants";

import {
    HAWB_UPDATE
} from "../actions";

import {toastr} from "react-redux-toastr";
import routeRedirectTo from "../../util/routeWrapper";
import {takeLatest} from "@redux-saga/core/effects";

import {
    updateHawbResponce
} from "./hawbAction";

export function* watchhawbSaga() {
    yield takeLatest(HAWB_UPDATE, updateMawbDetails);
}

function* updateMawbDetails({payload}) {
    // console.log("In fetch Mawb Deatail Attempt" + JSON.stringify(payload))
    try {
        const updateHawbResponse = yield call(updateHawbDeatailAsync, payload);
        // console.log("fetch Booking Deatail" + updateHawbResponse);
        if (updateHawbResponse.success) {
            toastr.success(updateHawbResponse.message)
            yield put(updateHawbResponce(updateHawbResponse));
        } else {
            toastr.error(updateHawbResponse.message)
            //// console.log(" fetch Booking Deatail Not Success" + updateHawbResponse)
            // yield put(onPreviewBookingDetailFetchedFailed(updateHawbResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch Booking Deatail" + JSON.stringify(error))
            // yield put(onPreviewBookingDetailFetchedFailed(error.response.data.message));
        }
    }
}

const updateHawbDeatailAsync = async (hawbdeatils) => {
    {
        // console.log(JSON.stringify("In fetch  mawb update  API" + JSON.stringify(hawbdeatils)))
        return await RestClient({
            url: BOOKING_MANAGER_MASTER_UPDATE_HOUSE_DATA,
            method: "POST",
            data: hawbdeatils
        })

    }
}

function forwardTo(location) {
    routeRedirectTo.push(location);
}

export default function* Saga() {
    yield all([
        fork(watchhawbSaga)
    ]);
}
