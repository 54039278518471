import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
class CouponSendMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactCompulsorySubmit:false,
            contactMail : "",
            queryFor : ""
        };

    }



    componentDidMount() {
          if(this.props.contactCompulsorySubmit){
            this.setState({
                contactCompulsorySubmit:this.props.contactCompulsorySubmit
            })
        }

    }

    toggleLargecontactcompulsory = () =>{
        this.setState(prevState => ({
            contactCompulsorySubmit: !prevState.contactCompulsorySubmit
        }),()=>{
            if (this.state.contactCompulsorySubmit === false){
                this.props.setmodalToggle(false)
            }
        });
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.contactCompulsorySubmit && this.props.contactCompulsorySubmit !== prevProps.contactCompulsorySubmit)
        {
            this.setState({
                contactCompulsorySubmit:this.props.contactCompulsorySubmit
            })
        }

    }
    submitLargecontactcompulsory = () =>{
        this.setState(prevState => ({
            contactCompulsorySubmit: !prevState.contactCompulsorySubmit
        }),()=>{
            if (this.state.contactCompulsorySubmit === false){
                this.props.setmodalToggle(false)
            }
        });
    }

    render() {
        return (
            <>
                <Modal className="model_contact"
                       isOpen={this.state.contactCompulsorySubmit}
                       size="md"
                       toggle={this.toggleLargecontactcompulsory}
                >
                    <ModalHeader className="model_head" toggle={this.toggleLargecontactcompulsory} style={{padding: "0"}}>
                    </ModalHeader>
                    <ModalBody className="contact-later1">
                        <div className="contect-soon">
                            <div className="soon_text soon_text1 offer_mail_pop">
                                <div className="book-imgs1">
                                    <img src="https://test231220.s3.amazonaws.com/unTracked/s3Bucketoo0.7298441922769248-1638340215472.png" className="img-fluid" alt="Coupon Offer"/>
                                </div>
                                <div className="book-text">
                                    <h2>The exclusive offer code has been successfully sent to your mail-id.</h2>
                                    <h6> You shall soon hear from us.</h6>
                                </div>
                            </div>
                            <div className="bottom_btn bottom_btn1">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="request_btn" id="subscribeBtn"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.submitLargecontactcompulsory()
                                                }}> Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}


export default CouponSendMail;
