import {
    NOTIFICATION_LOAD_REQUEST,
    NOTIFICATION_LOAD_REQUEST_RESPONSE,
    NOTIFICATION_LOAD_REQUEST_RESPONSE_FAILURE,
    NOTIFICATION_READ_CONFIRM,
    NOTIFICATION_READ_CONFIRM_RESPONSE,
    NOTIFICATION_READ_CONFIRM_RESPONSE_FAILURE
} from "../actions";

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
};

const notificationReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case NOTIFICATION_LOAD_REQUEST:
            // console.log("In NOTIFICATION_LOAD_REQUEST  " + JSON.stringify(payload));
            return {...state, loading: true};
        case NOTIFICATION_LOAD_REQUEST_RESPONSE:
            // console.log("in NOTIFICATION_LOAD_REQUEST_RESPONSE "+JSON.stringify(payload));
            return {...state, loading: false,notificationList: payload.data,notificationCount:payload.total, error: '',refreshNotification:undefined};
            case NOTIFICATION_LOAD_REQUEST_RESPONSE_FAILURE:
            // console.log("in NOTIFICATION_LOAD_REQUEST_RESPONSE_FAILURE "+JSON.stringify(payload));
            return {...state, loading: false,  error: payload};

            case NOTIFICATION_READ_CONFIRM:
            // console.log("In NOTIFICATION_LOAD_REQUEST  " + JSON.stringify(payload));
            return {...state, loading: true};
            case NOTIFICATION_READ_CONFIRM_RESPONSE:
            // console.log("in NOTIFICATION_LOAD_REQUEST_RESPONSE "+JSON.stringify(payload));
            return {...state, loading: false, notificationList: payload, error: ''};
            case NOTIFICATION_READ_CONFIRM_RESPONSE_FAILURE:
            // console.log("in NOTIFICATION_LOAD_REQUEST_RESPONSE_FAILURE "+JSON.stringify(payload));
            return {...state, loading: false,  error: payload};
        default:
            return initState;
    }
};
export default notificationReducer;
