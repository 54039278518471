import React, { Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ModalFooter from "reactstrap/es/ModalFooter";
import RestClient from "../../../util/RestClient";
import {
    BOOKING_MANAGER_MASTER_GET_DATA,
    DRIVER_OTP_CHECK,
    GET_BOOKING_NO,
    PICK_UP_MAIL_MSG,
    SEND_CARGO_MESSAGE_MAIL,
    USER_LOGIN_SEND_MOBILE,
    SEARCH_VEHICLE_INFO_ENGINE,
    SEARCH_VEHICLE_INFO_CHESIS,
    SEARCH_DRIVER_DETAILS,
    SEARCH_VEHICLE_INFO_VEHICLENUMBER,
    SEARCH_VEHICLE_FASTAGE_DETAILS,
    GET_BOOKING_NO_AIR,
    GET_BOOKING_NO_OCEAN,
    GET_BOOKING_NO_COURIER,
    GET_BOOKING_NO_ROAD,
    BOOKING_MANAGER_MASTER_GET_DATA_AIR,
    BOOKING_MANAGER_MASTER_GET_DATA_OCEAN,
    BOOKING_MANAGER_MASTER_GET_DATA_COURIER,
    BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
    SEND_CARGO_MESSAGE_MAIL_ROAD,
    SEND_CARGO_MESSAGE_MAIL_COURIER,
    SEND_CARGO_MESSAGE_MAIL_OCEAN,
    SEND_CARGO_MESSAGE_MAIL_AIR,
    PICK_UP_MAIL_MSG_AIR,
    PICK_UP_MAIL_MSG_OCEAN, PICK_UP_MAIL_MSG_COURIER, PICK_UP_MAIL_MSG_ROAD,
} from "../../../constants";
import { toast } from "react-toastify";
import { PAGE_PATHS } from "../../../util/PageRoute";
class DriverDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpPopup: false,
            otpPopupNext: false,
            buttonName: "Get Otp",
            firstPopup: true,
            searchVehicle:"",

        };
    }

    componentDidMount() {
        let quoteId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        this.setState({
            bookingId: quoteId,quoteFor:quoteFor
        },()=>{
            this.getPrsData({ BookingNo: quoteId }).then((responce) => {
                if (responce && responce.success) {
                    let prsData = responce && responce.result ? responce.result : {};
                    let driverName = prsData && prsData.drivers && prsData.drivers[0] && prsData.drivers[0].label ? prsData.drivers[0].label : "";
                    let driverImage = prsData && prsData.drivers && prsData.drivers[0] && prsData.drivers[0].label ? prsData.drivers[0].driverImage : "";
                    let mobileNo = prsData && prsData.drivers && prsData.drivers[0] && prsData.drivers[0].mobileNo ? prsData.drivers[0].mobileNo : "";
                    let age = prsData && prsData.drivers && prsData.drivers[0] && prsData.drivers[0].age ? prsData.drivers[0].age : "";
                    let dob = prsData && prsData.drivers && prsData.drivers[0] && prsData.drivers[0].dob ? prsData.drivers[0].dob : "2001-02-26";

                    let vehicleNo = prsData && prsData.vehicleNo ? prsData.vehicleNo : "";
                    let prsId = prsData && prsData._id ? prsData._id : "";
                    let licenceNo = prsData && prsData.drivers && prsData.drivers[0] && prsData.drivers[0].licenceNo ? prsData.drivers[0].licenceNo : "";
                    let viewCustomer = prsData && prsData.viewCustomer ? prsData.viewCustomer : false;
                    this.setState({
                        driverName: driverName,
                        driverImage: driverImage,
                        age: age,
                        dob:dob,
                        mobileNo: mobileNo,
                        vehicleNo: vehicleNo,
                        licenceNo: licenceNo,
                        viewCustomer: viewCustomer,
                        prsId: prsId,
                    }, () => {
                        if(this.state.viewCustomer){

                            this.setState({
                                firstPopup: false
                            })
                        }
                        this.SearchDriver()
                        setTimeout(this.searchVehicleInfo(), 1000);


                    })
                }
            })
            this.getbookingData({ id: quoteId }).then((idResponce) => {
                if (idResponce && idResponce.success) {
                    let quoteData = idResponce && idResponce.result ? idResponce.result : {};
                    //let originName =  quoteData && quoteData.originAirport && quoteData.originAirport.name ? quoteData.originAirport.name : "";
                    let originDoor = quoteData && quoteData.originDoor && quoteData.originDoor.pincode ? quoteData.originDoor.pincode : "";
                    let originName = quoteData && quoteData.originDoor && quoteData.originDoor.address ? quoteData.originDoor.address : "";
                    //let destinationName =  quoteData && quoteData.destinationAirport && quoteData.destinationAirport.name ? quoteData.destinationAirport.name : "";
                    let destinationDoor = quoteData && quoteData.destinationDoor && quoteData.destinationDoor.pincode ? quoteData.destinationDoor.pincode : "";
                    //let destinationName =  quoteData && quoteData.destinationDoor && quoteData.destinationDoor.address ? quoteData.destinationDoor.address : "";
                    let destinationName = quoteData && quoteData.originAirport && quoteData.originAirport.name ? quoteData.originAirport.name : "";
                    let totalPieces = quoteData && quoteData.quoteId && quoteData.quoteId.totalPieces ? quoteData.quoteId.totalPieces : "";
                    let bookingNo = quoteData && quoteData.quoteId && quoteData.quoteId.jobNo ? quoteData.quoteId.jobNo : "";
                    let queryFor = quoteData && quoteData.queryFor ? quoteData.queryFor : "";
                    let grossWeight = quoteData && quoteData.grossWeight ? quoteData.grossWeight : "";
                    let volumeWeight = quoteData && quoteData.volumeWeight ? quoteData.volumeWeight : "";
                    let activityType = quoteData && quoteData.activityType ? quoteData.activityType : "";
                    let jobNo = quoteData && quoteData.jobNo ? quoteData.jobNo : "";
                    let bookingId = quoteData && quoteData.quoteId && quoteData.quoteId.bookingId ? quoteData.quoteId.bookingId : "";
                    let quoteId = quoteData && quoteData._id ? quoteData._id : "";
                    let blNo = quoteData && quoteData.blNo ? quoteData.blNo : "";
                    let isGoodsPickedup = quoteData && quoteData.isGoodsPickedup ? quoteData.isGoodsPickedup : "";
                    let HouseNo = quoteData && quoteData.noOfHouses ? quoteData.noOfHouses : ""
                    let proforma = quoteData && quoteData.proforma ? quoteData.proforma : false
                    let skipStatus = quoteData && quoteData.proformaData && quoteData.proformaData.skipStatus ? quoteData.proformaData.skipStatus : ""

                    this.setState({
                        originName: originName,
                        originDoor: originDoor,
                        destinationName: destinationName,
                        destinationDoor: destinationDoor,
                        totalPieces: totalPieces,
                        grossWeight: grossWeight,
                        volumeWeight: volumeWeight,
                        jobNo: jobNo,
                        bookingId: bookingId,
                        quoteId: quoteId,
                        bookingNo: bookingNo,
                        activityType: activityType,
                        queryFor: queryFor,
                        blNo: blNo,
                        isGoodsPickedup: isGoodsPickedup,
                        proforma: proforma,
                        skipStatus: skipStatus,
                        HouseNo: HouseNo,
                    }, () => {
                        let performaStatus = this.state.proforma && this.state.skipStatus === "Paid" ? true : false
                        this.setState({
                            performaStatus: performaStatus
                        }, () => {
                        })
                    })
                } else {

                }
            })
        })


        // setTimeout(this.SearchDriver(), 4000);

        

    }
    getbookingData = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Air"?BOOKING_MANAGER_MASTER_GET_DATA_AIR : this.state.quoteFor === "Ocean"?BOOKING_MANAGER_MASTER_GET_DATA_OCEAN:this.state.quoteFor === "Courier"?BOOKING_MANAGER_MASTER_GET_DATA_COURIER : BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
            method: "POST",
            data: dataForServer,
        });
    };
    getPrsData = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Air"?GET_BOOKING_NO_AIR : this.state.quoteFor === "Ocean"?GET_BOOKING_NO_OCEAN:this.state.quoteFor === "Courier"?GET_BOOKING_NO_COURIER : GET_BOOKING_NO_ROAD,
            method: "POST",
            data: dataForServer,
        });
    };
    cargoSendMail = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Air"?SEND_CARGO_MESSAGE_MAIL_AIR : this.state.quoteFor === "Ocean"?SEND_CARGO_MESSAGE_MAIL_OCEAN:this.state.quoteFor === "Courier"?SEND_CARGO_MESSAGE_MAIL_COURIER : SEND_CARGO_MESSAGE_MAIL_ROAD,
            method: "POST",
            data: dataForServer
        })
    }
    cargoPickUpSendMail = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Air"?PICK_UP_MAIL_MSG_AIR : this.state.quoteFor === "Ocean"?PICK_UP_MAIL_MSG_OCEAN:this.state.quoteFor === "Courier"?PICK_UP_MAIL_MSG_COURIER : PICK_UP_MAIL_MSG_ROAD,
            method: "POST",
            data: dataForServer
        })
    }
    toggleLarges = () => {
        this.setState({
            otpPopup: false,
        })
    }
    toggleLarge = () => {
        this.setState({
            otpPopupNext: false,
        })
    }
    openPop = () => {
        if (this.state.isGoodsPickedup) {
            this.goodsHandedFuntionlity()
        } else {
            this.cargoSendMail({ bookingId: this.state.bookingId }).then((responce) => {
                if (responce && responce.success) {
                    this.setState({
                        otpPopup: true,
                    })
                }
            })
        }


    }
    sendOTPForLogin = (e) => {
        e.preventDefault()
        let obj = {
            countryCode: '+91',
            mobileNo: this.state.mobileNo,
        }

        this.apiToSendOtp(obj).then(res => {
            if (res.success) {
                this.setState({
                    sessionIds: res.result.Details,
                    buttonName: "Resend Otp",
                    sendOtpButtonClicked: true
                })
            }
        })


    }
    apiToSendOtp = async (obj) => {
        return await RestClient({
            url: USER_LOGIN_SEND_MOBILE,
            method: "POST",
            data: obj
        })
    }
    verifyOTP = (e) => {
        e.preventDefault()
        let obj = {
            otp: this.state.otp,
            sessionId: this.state.sessionIds,
        }
        this.apiToVerifyOtp(obj).then(res => {
            if (res.success) {
                this.setState({ otpPopup: false, otpPopupNext: true, })
            } else {
                toast.error("OTP does not match", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    apiToVerifyOtp = async (obj) => {
        return await RestClient({
            url: DRIVER_OTP_CHECK,
            method: "POST",
            data: obj
        })
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    goodHandover = () => {
        this.cargoPickUpSendMail({ bookingId: this.state.bookingId, prsId: this.state.prsId }).then((responce) => {
            if (responce && responce.success) {
                this.goodsHandedFuntionlity()
            } else {
                this.goodsHandedFuntionlity()
            }
        })
    }
    goodNotHandover = () => {
        this.setState({ otpPopupNext: false, })
    }
    goodsHandedFuntionlity = () => {
        if (this.state.quoteFor === "Courier" || this.state.quoteFor === "Road") {
            this.props.history.push(PAGE_PATHS.EDOCKET_PDF_VIEW + "/" + this.state.queryFor + "/"+this.state.bookingId)
        }
        else {
            if (this.state.blNo === "" || this.state.blNo === undefined) {
                if (this.state.queryFor === "Air") {
                    if (this.state.HouseNo >= 1 && this.state.performaStatus == true) {
                        this.props.history.push(PAGE_PATHS.HOUSE_MAWB_VIEW + "/" + this.state.bookingId)
                    } else {
                        toast.error("Awaiting For AWB Number", {
                            position: "top-left",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.props.history.push(
                            PAGE_PATHS.VIEW_MAWB_CUSTOMER +
                            "/Air/" +
                            this.state.bookingId
                        );
                    }
                } else if (this.state.queryFor === "Ocean") {
                    if (this.state.HouseNo >= 1 && this.state.performaStatus == true) {
                        this.props.history.push(PAGE_PATHS.HOUSE_VIEW_FINAL_OCEAN + "/" + this.state.bookingId)
                    } else {
                        toast.error("Awaiting For BL Number", {
                            position: "top-left",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.props.history.push(
                            PAGE_PATHS.VIEW_MAWB_CUSTOMER +
                            "/Ocean/" +
                            this.state.bookingId
                        );
                    }


                } else {
                    toast.error("Awaiting For Docket Number", {
                        position: "top-left",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.props.history.push(PAGE_PATHS.EDOCKET_PDF_VIEW + "/" + this.state.queryFor + "/"+this.state.bookingId)
                }
            } else {
                if (this.state.quoteFor === "Air") {
                    if (this.state.HouseNo >= 1 && this.state.performaStatus == true) {
                        this.props.history.push(PAGE_PATHS.HOUSE_MAWB_VIEW + "/" + this.state.bookingId)
                    } else {
                        this.props.history.push(
                            PAGE_PATHS.AIR_MAWB_VIEW + "/Air/" + this.state.bookingId
                        );
                    }

                } else if (this.state.quoteFor === "Ocean") {
                    if (this.state.HouseNo >= 1 && this.state.performaStatus == true) {
                        this.props.history.push(PAGE_PATHS.HOUSE_VIEW_FINAL_OCEAN + "/" + this.state.bookingId)
                    } else {
                        this.props.history.push(
                            PAGE_PATHS.VIEW_MAWB + "/Ocean/" + this.state.bookingId
                        );
                    }
                }
            }
        }
    }

    SearchDriver = (e) => {
        // e.preventDefault()
        let obj = {
            dob: this.state.dob,
            dlnumber: this.state.licenceNo,
            

        }
        this.searchDriverInfo(obj).then(res => {
            if (res && res.success) {
                let DriverData = res && res.result ? res.result : "";
                let DriverDatafirst =  DriverData  && DriverData.dldetobj[0] ? DriverData.dldetobj[0]:""
                let DriverData1 =  DriverDatafirst  && DriverDatafirst.dldetobj[0] ? DriverDatafirst.dldetobj[0]:""

                let FullName =  DriverData1 && DriverData1.bioObj && DriverData1.bioObj.bioFullName ?DriverData1.bioObj.bioFullName:""
                let MobileNo =  DriverData1 && DriverData1.bioObj && DriverData1.bioObj.bioMobileNo ?DriverData1.bioObj.bioMobileNo:""
                let ValidTill =DriverData1 && DriverData1.dlobj && DriverData1.dlobj.dlNtValdtoDt ?DriverData1.dlobj.dlNtValdtoDt:""
                let RtoName =DriverData1 && DriverData1.dlobj && DriverData1.dlobj.omRtoFullname ?DriverData1.dlobj.omRtoFullname:""

                
                 
                
   this.setState({
    DriverData1:DriverData1,
    DriverDetailsShow:true,
    driverName:FullName,
    MobileNo:MobileNo,
    ValidTill:ValidTill,
    RtoName:RtoName
   },()=>{
   })

            } else {
                toast.error(res.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    searchDriverInfo = async (obj) => {
        return await RestClient({
            url: SEARCH_DRIVER_DETAILS,
            method: "POST",
            data: obj
        })
    }
    SearchFastag = (e) => {
        // e.preventDefault()
        let obj = {
            vehiclenumber: this.state.vehicleNo,

        }
        this.vehicleFastagInfo(obj).then(res => {
            if (res && res.success) {

            } else {
                toast.error(res.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    vehicleFastagInfo = async (obj) => {
        return await RestClient({
            url: SEARCH_VEHICLE_FASTAGE_DETAILS,
            method: "POST",
            data: obj
        })
    }
    searchVehicleInfo = (e) => {

        if (this.state.vehicleNo) {
            let obj = {
                vehiclenumber: this.state.vehicleNo,

            }
            this.vehicleInfo(obj).then(res => {
                if (res && res.success) {
                    let VehicleDataInfo = res && res.result ? res.result : "";
                    let VehicleData = VehicleDataInfo && VehicleDataInfo.vehicleDetails ? VehicleDataInfo.vehicleDetails:""
                    let RegistrationNo =  VehicleData && VehicleData.rc_regn_no ? VehicleData.rc_regn_no:"";
                    let ownerName =  VehicleData && VehicleData.rc_owner_name ? VehicleData.rc_owner_name:"";
                    let VehicleType =  VehicleData && VehicleData.rc_vh_class_desc ? VehicleData.rc_vh_class_desc:"";
                    let FuelType =  VehicleData && VehicleData.rc_fuel_desc ? VehicleData.rc_fuel_desc:"";
                    let chasisNo =  VehicleData && VehicleData.rc_chasi_no ? VehicleData.rc_chasi_no:"";
                    let Manufacture =  VehicleData && VehicleData.rc_maker_desc ? VehicleData.rc_maker_desc:"";
                    let ownerContact =  VehicleData && VehicleData.rc_mobile_no ? VehicleData.rc_mobile_no:"-";


                    this.setState({
                        RegistrationNo:RegistrationNo,
                        ownerName:ownerName,
                        VehicleType:VehicleType,
                        FuelType:FuelType,
                        chasisNo:chasisNo,
                        Manufacture:Manufacture,
                        ownerContact:ownerContact


                    })



                } else {
                    toast.error(res.message, {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        } else if (this.state.chasisnumber) {
           let obj = {
                chasisnumber: this.state.chasisnumber,

            }
            this.vehicleInfoChesis(obj).then(res => {
                if (res && res.success) {
                    let VehicleDataInfo = res && res.result ? res.result : "";
                    let VehicleData = VehicleDataInfo && VehicleDataInfo.vehicleDetails ? VehicleDataInfo.vehicleDetails:""
                    let RegistrationNo =  VehicleData && VehicleData.rc_regn_no ? VehicleData.rc_regn_no:"";
                    let ownerName =  VehicleData && VehicleData.rc_owner_name ? VehicleData.rc_owner_name:"";
                    let VehicleType =  VehicleData && VehicleData.rc_vh_class_desc ? VehicleData.rc_vh_class_desc:"";
                    let FuelType =  VehicleData && VehicleData.rc_fuel_desc ? VehicleData.rc_fuel_desc:"";
                    let chasisNo =  VehicleData && VehicleData.rc_chasi_no ? VehicleData.rc_chasi_no:"";
                    let Manufacture =  VehicleData && VehicleData.rc_maker_desc ? VehicleData.rc_maker_desc:"";
                    let ownerContact =  VehicleData && VehicleData.rc_mobile_no ? VehicleData.rc_mobile_no:"-";


                    this.setState({
                        RegistrationNo:RegistrationNo,
                        ownerName:ownerName,
                        VehicleType:VehicleType,
                        FuelType:FuelType,
                        chasisNo:chasisNo,
                        Manufacture:Manufacture,
                        ownerContact:ownerContact


                    })



                }else {
                    toast.error("Vehicle Data Not Available", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        } else {
            let obj = {
                enginenumber: this.state.enginenumber,

            }
            this.vehicleInfoEngine(obj).then(res => {
                if (res && res.success) {
                    let VehicleDataInfo = res && res.result ? res.result : "";
                    let VehicleData = VehicleDataInfo && VehicleDataInfo.vehicleDetails ? VehicleDataInfo.vehicleDetails:""
                    let RegistrationNo =  VehicleData && VehicleData.rc_regn_no ? VehicleData.rc_regn_no:"";
                    let ownerName =  VehicleData && VehicleData.rc_owner_name ? VehicleData.rc_owner_name:"";
                    let VehicleType =  VehicleData && VehicleData.rc_vh_class_desc ? VehicleData.rc_vh_class_desc:"";
                    let FuelType =  VehicleData && VehicleData.rc_fuel_desc ? VehicleData.rc_fuel_desc:"";
                    let chasisNo =  VehicleData && VehicleData.rc_chasi_no ? VehicleData.rc_chasi_no:"";
                    let Manufacture =  VehicleData && VehicleData.rc_maker_desc ? VehicleData.rc_maker_desc:"";
                    let ownerContact =  VehicleData && VehicleData.rc_mobile_no ? VehicleData.rc_mobile_no:"-";


                    this.setState({
                        RegistrationNo:RegistrationNo,
                        ownerName:ownerName,
                        VehicleType:VehicleType,
                        FuelType:FuelType,
                        chasisNo:chasisNo,
                        Manufacture:Manufacture,
                        ownerContact:ownerContact


                    })



                } else {
                    toast.error("Vehicle Data Not Available", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        }



    }
    vehicleInfo = async (obj) => {
        return await RestClient({
            url: SEARCH_VEHICLE_INFO_VEHICLENUMBER,
            method: "POST",
            data: obj
        })
    }
    vehicleInfoChesis = async (obj) => {
        return await RestClient({
            url: SEARCH_VEHICLE_INFO_CHESIS,
            method: "POST",
            data: obj
        })
    }
    vehicleInfoEngine = async (obj) => {
        return await RestClient({
            url: SEARCH_VEHICLE_INFO_ENGINE,
            method: "POST",
            data: obj
        })
    }


    render() {


        return (
            <AppLayout>
                <div className="dashboard-wrapper">
                    <div className="invoiceDetails">
                        <div className="container">
                            <Modal className="housepop-width mawb_popup"
                                isOpen={this.state.firstPopup}
                                size="md"
                                toggle={() => { this.goodsHandedFuntionlity() }}>
                                <ModalHeader toggle={() => { this.goodsHandedFuntionlity() }} className="" style={{ padding: "0" }}></ModalHeader>
                                <ModalBody style={{ padding: '0 0 15px' }}>
                                    <div className="">
                                        <div className="pop-text-error">
                                            <h6 style={{ fontWeight: '600' }}>Awaiting For Pickup Confirmation</h6>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter className="justify-content-center">
                                    <button className="btn mawb_popup_btn" style={{ borderRadius: "40px;" }}
                                        onClick={e => {
                                            e.preventDefault()
                                            this.goodsHandedFuntionlity()
                                        }}>
                                        Ok
                                    </button>


                                </ModalFooter>
                            </Modal>
                            {!this.state.firstPopup ?
                                <div className="row">
                                    <div className="col-lg-11 offset-lg-1">
                                        <div className="view-all mb-2"><h4>Pick Up Requested Generated</h4></div>
                                        {/*<div className="d-flex flex-wrap align-items-stretch justify-content-between">*/}
                                        <div className="AAA-shipper-consignee-approve-data-8 mb-3">
                                            <div className="shipment_details">
                                                <h5>Shipment Details</h5>
                                                <hr />
                                                <div className="d-flex flex-wrap">
                                                    {this.state.queryFor === "Air" || this.state.queryFor === "Ocean" ?
                                                        <>
                                                            <div className="pick_shipment">
                                                                <h5>Pick Up From</h5>
                                                                <p>{this.state.originName ? this.state.originName : this.state.originDoor}</p>
                                                            </div>
                                                            <div className="pick_shipment">
                                                                <h5>Drop To</h5>
                                                                <p>{this.state.destinationName}</p>
                                                            </div>

                                                        </>
                                                        :
                                                        <>
                                                            {this.state.queryFor === "Courier" || this.state.queryFor === "Road" ?
                                                                <>
                                                                    <div className="pick_shipment">
                                                                        <h5>Pick Up</h5>
                                                                        <p>{this.state.originName ? this.state.originName : this.state.originDoor}</p>
                                                                    </div>
                                                                    <div className="pick_shipment">
                                                                        <h5>Drop Off</h5>
                                                                        <p>{this.state.destinationName}</p>
                                                                    </div>

                                                                </>

                                                                : ""}
                                                        </>
                                                    }

                                                    <div className="pick_shipment">
                                                        <h5>Job No.</h5>
                                                        <p>{this.state.bookingNo}</p>
                                                    </div>
                                                    <div className="pick_shipment">
                                                        <h5>Pcs.</h5>
                                                        <p>{this.state.totalPieces}</p>
                                                    </div>
                                                    <div className="pick_shipment">
                                                        <h5>Gross Weight</h5>
                                                        <p>{this.state.grossWeight}</p>
                                                    </div>
                                                    <div className="pick_shipment">
                                                        <h5>Volume Weight</h5>
                                                        <p>{this.state.volumeWeight}</p>
                                                    </div>
                                                <hr />

                                                   </div>
                                            </div>
                                        </div>

                                        
                                        <div className="AAA-shipper-consignee-approve-data-8  mb-3">
                                            <div className="shipment_details">
                                                <h5>Pick Up Executive Details</h5>
                                                <hr />
                                                <div className="d-flex flex-wrap align-items-center flex-wrap-mob">
                                                    <div className="driver_imgs">
                                                        <img src={this.state.driverImage ? this.state.driverImage : "https://test231220.s3.amazonaws.com/unTracked/s3Bucketoo0.6820706731556374-1661255095677.png"} alt={this.state.driverName ? this.state.driverName : "Driver Image"} />
                                                    </div>
                                                    <div className="d-flex flex-wrap align-items-center drivers_details">
                                                        <div className="pick_shipment">
                                                            <h5>Name</h5>
                                                            <p>{this.state.driverName}</p>
                                                        </div>
                                                        {/* <div className="pick_shipment">
                                                            <h5>Age</h5>
                                                            <p>{this.state.age}</p>
                                                        </div> */}
                                                        <div className="pick_shipment">
                                                            <h5>Mobile No.</h5>
                                                            <p>{this.state.MobileNo}</p>
                                                        </div>

                                                        <div style={{ width: '100%' }}>
                                                            <h5 className="mb-3">ID Proof :</h5>
                                                            <div className="d-flex flex-wrap align-items-center ">
                                                                {/* <div className="pick_shipment">
                                                                    <h5>Vehicle No.</h5>
                                                                    <p>{this.state.vehicleNo}</p>
                                                                </div> */}
                                                                <div className="pick_shipment">
                                                                    <h5>Driver License No.</h5>
                                                                    <p>{this.state.licenceNo}</p>
                                                                </div>
                                                                <div className="pick_shipment">
                                                                    <h5>License Valid Till </h5>
                                                                    <p>{this.state.ValidTill}</p>
                                                                </div>
                                                                <div className="pick_shipment">
                                                                    <h5>RTO Details</h5>
                                                                    <p>{this.state.RtoName}</p>
                                                                </div>
                                                                {/*<div className="pick_shipment">*/}
                                                                {/*    <h5>Pick Up Time Slot</h5>*/}
                                                                {/*    <p>10:00 AM - 12:00 PM</p>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="send_btn">
                                                <button className="btn edit" onClick={this.openPop}>Next</button>
                                                {/*<button className="btn edit" onClick={this.goodHandover}>Next</button>*/}
                                            </div>
                                        </div>

                                        <div className="AAA-shipper-consignee-approve-data-8 ">
                                            <div className="shipment_details">
                                                <h5>Vehicle  Details</h5>
                                                <hr />
                                                {/* <div className="d-flex flex-wrap align-items-center flex-wrap-mob"> */}
                                                    {/* <div className="driver_imgs">
                                                        <img src={"https://test231220.s3.amazonaws.com/unTracked/s3Bucketoo0.6820706731556374-1661255095677.png"} alt="img" />
                                                    </div> */}
                                                    <div className="d-flex flex-wrap align-items-center drivers_detail">
                                                        <div className="pick_shipments">
                                                            <h5>  type</h5>
                                                            <p>{this.state.VehicleType}</p>
                                                        </div>
                                                        <div className="pick_shipments">
                                                            <h5>  Owner Name</h5>
                                                            <p>{this.state.ownerName}</p>
                                                        </div>
                                                        <div className="pick_shipments">
                                                            <h5> RegistrationNo</h5>
                                                            <p>{this.state.RegistrationNo}</p>
                                                        </div>
                                                        <div className="pick_shipments">
                                                                    <h5>Owner Contact</h5>
                                                                    <p>{this.state.ownerContact}</p>
                                                                </div>
                                                                <div className="pick_shipments">
                                                                    <h5>Chaisis No.</h5>
                                                                    <p>{this.state.chasisNo}</p>
                                                                </div>
                                                                <div className="pick_shipments">
                                                                    <h5>Manufactured Name</h5>
                                                                    <p>{this.state.Manufacture}</p>
                                                                </div>
                                                                <div className="pick_shipments">
                                                                    <h5>Fuel Type</h5>
                                                                    <p>{this.state.FuelType}</p>
                                                                </div>

                                                        {/* <div style={{ width: '100%' }}>
                                                            <h5 className="mb-3">Vehicle Info :</h5>
                                                            <div className="d-flex flex-wrap align-items-center ">
                                                                
                                                                <div className="pick_shipment">
                                                                    <h5>Chaisis No.</h5>
                                                                    <p>{this.state.chasisNo}</p>
                                                                </div>
                                                                <div className="pick_shipment">
                                                                    <h5>Manufactured Name</h5>
                                                                    <p>{this.state.Manufacture}</p>
                                                                </div>
                                                                <div className="pick_shipment">
                                                                    <h5>Fuel Type</h5>
                                                                    <p>{this.state.FuelType}</p>
                                                                </div>
                                                                
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                {/* </div> */}

                                            </div>
                                            <div className="send_btn">
                                                {/* <button className="btn edit" onClick={this.openPop}>Next</button> */}
                                                {/*<button className="btn edit" onClick={this.goodHandover}>Next</button>*/}
                                            </div>
                                        </div>

                                        


                                    </div>
                                </div> : ""}
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.otpPopup}
                    size="md"
                    toggle={this.toggleLarges}
                >
                    <>
                        <ModalHeader style={{ borderBottom: '1px solid #d7d7d7' }} toggle={this.toggleLarges} className="rates_heads">
                            Sent Otp
                        </ModalHeader>
                        <ModalBody style={{ background: '#fcfcfc' }}>
                            <div className="back-img otp_pickup">
                                <h2 className="rates_head">Please enter the one time password
                                    shared by driver</h2>
                                {this.state.sendOtpButtonClicked ?
                                    <input name="otp" className="form-control" type="text" onKeyUp={this.handleChange} /> : ""}
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center rates-not">
                            {this.state.sendOtpButtonClicked ?
                                <button type="button" className="btn btn-secondary" style={{ border: '1px solid #ff0600', background: '#ff0600' }}
                                    onClick={(e) => this.verifyOTP(e)}>Verify OTP</button> : ""}
                            <button type="button" className="btn btn-secondary" style={{ border: '1px solid #ff0600', background: '#ff0600' }} onClick={(e) => this.sendOTPForLogin(e)}>{this.state.buttonName}</button>
                        </ModalFooter>
                    </>
                </Modal>

                <Modal
                    className="sendOtp_popup"
                    isOpen={this.state.otpPopupNext}
                    size="md"
                    toggle={this.toggleLarge}
                >
                    <>
                        <ModalHeader style={{ borderBottom: '1px solid #d7d7d7', padding: '0' }} toggle={this.toggleLarge} className="rates_heads"></ModalHeader>
                        <ModalBody style={{ background: '#fcfcfc' }}>
                            <div className="back-img otp_pickup goods_handover">
                                <h2 className="rates_head">Goods Handed over to Executive</h2>
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center rates-not">
                            <button type="button" className="btn btn-secondary" style={{ border: '1px solid #ff0600', background: '#ff0600' }} onClick={this.goodHandover}>Yes</button>
                            <button type="button" className="btn btn-secondary" style={{ border: '1px solid #ff0600', background: '#ff0600' }} onClick={this.goodNotHandover}>No</button>
                        </ModalFooter>
                    </>
                </Modal>
            </AppLayout >
        );
    }


}


export default DriverDetails;
