import {
    HAWB_UPDATE,
    HAWB_UPDATE_RESPONSE
} from "../actions";

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
};

const hawbReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case HAWB_UPDATE:
            // console.log("In mawb Deatils update  " + JSON.stringify(payload));
            return {...state, loading: false, bookingId: payload};
        case HAWB_UPDATE_RESPONSE:
            // console.log("in mawb Deatils update responce"+JSON.stringify(payload));
            return {...state, loading: false, responce:payload.message, error: ''};
        default:
            return initState;
    }
};
export default hawbReducer;
