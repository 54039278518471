import React, {Component} from "react";
import RestClient from "../../util/RestClient";
import {
    GET_QUERY_PREVIEW_API_ROAD,
    GET_QUERY_PREVIEW_API_COURIER,
} from "../../constants";
import AppLayout from "../../layout/AppLayout";
import CourierRateQuotedCardNew from "../../views/app/ShipementHistory/CourierRateQuotedCardNew";
import {PAGE_PATHS} from "../../util/PageRoute";
import {convertDateISOToCustomiseFormat} from "../../util/CommonUtility";
import {faAngleLeft, faAngleRight, faArrowRight, faFilter, faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class RatesQueryPreviewCourier extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openTrack: true,
            rateQuotedTrack: false,
            bookingTrack: false,
            airwayBillTrack: false,
            invoiceTrack: false,
        }

    }

    componentDidMount() {
        let quoteId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        if(JSON.parse(localStorage.getItem("customer"))){
            let data = JSON.parse(localStorage.getItem("customer")).result;
            // result.customerBranchData.creditDays
            let typeOfCustomer = data && data.customerData && data.customerData.typeOfBussiness ? data.customerData.typeOfBussiness : "Bussiness"
            let creditDayValue
            let creditDaysFromCustomerData = data && data.customerBranchData && data.customerBranchData.creditDays ? data.customerBranchData.creditDays : "0"
            if(creditDaysFromCustomerData === 0){
                creditDayValue = "0"
            }else{
                creditDayValue = creditDaysFromCustomerData
            }
            this.setState({
                typeOfBussiness : typeOfCustomer,creditDayValue:creditDayValue
            })
        }
        this.GET_BY_QUOTEID_DATA({id:quoteId , quoteFor:quoteFor}).then(responce=> {
            if(responce.success && responce.result){
                        let getQuoteResult = responce && responce.result ? responce.result : "";
                        let originName = getQuoteResult && getQuoteResult.originAirport && getQuoteResult.originAirport.name ? getQuoteResult.originAirport.name : "";
                        let destinationName = getQuoteResult && getQuoteResult.destinationAirport && getQuoteResult.destinationAirport.name ? getQuoteResult.destinationAirport.name : "";
                        let totalPieces = getQuoteResult && getQuoteResult.totalPieces ? getQuoteResult.totalPieces : "";
                        let volumeWeight = getQuoteResult && getQuoteResult.volumeWeight ? getQuoteResult.volumeWeight : "";
                        let grossWeight = getQuoteResult && getQuoteResult.grossWeight ? getQuoteResult.grossWeight : "";
                        let activityType = getQuoteResult && getQuoteResult.activityType ? getQuoteResult.activityType : "";
                        let chargeableWeight = getQuoteResult && getQuoteResult.chargeableWeight ? getQuoteResult.chargeableWeight : "";
                        let shipmentMode = getQuoteResult && getQuoteResult.shipmentMode ? getQuoteResult.shipmentMode : "";
                        let tarrifMode = getQuoteResult && getQuoteResult.tarrifMode ? getQuoteResult.tarrifMode : "";
                        let dimentions = getQuoteResult && getQuoteResult.dimentions ? getQuoteResult.dimentions : [];
                        let dimentionType = getQuoteResult && getQuoteResult.dimentionType ? getQuoteResult.dimentionType : "";
                        let queryFor = getQuoteResult && getQuoteResult.queryFor ? getQuoteResult.queryFor : "";
                        let noOfContainers = getQuoteResult && getQuoteResult.noOfContainers ? getQuoteResult.noOfContainers : "";
                        let commodity = getQuoteResult && getQuoteResult.commodity ? getQuoteResult.commodity : "";
                        let serviceType = getQuoteResult && getQuoteResult.serviceType ? getQuoteResult.serviceType : "";
                        let productType = getQuoteResult && getQuoteResult.courierMode ? getQuoteResult.courierMode : "";
                        let clearenceDate = getQuoteResult && getQuoteResult.clearenceDate ? getQuoteResult.clearenceDate : "";
                        let activeStatus = getQuoteResult && getQuoteResult.activeStatus ? getQuoteResult.activeStatus : "";
                        let quoteStatus = getQuoteResult && getQuoteResult.quoteStatus ? getQuoteResult.quoteStatus : "";
                        let pickUpCountry = getQuoteResult && getQuoteResult.Shipper && getQuoteResult.Shipper.Country? getQuoteResult.Shipper.Country : getQuoteResult && getQuoteResult.customerBranchData && getQuoteResult.customerBranchData.countryName ? getQuoteResult.customerBranchData.countryName:"";
                        let pickUpCountryPin = getQuoteResult && getQuoteResult.Shipper && getQuoteResult.Shipper.ZipCode? getQuoteResult.Shipper.ZipCode : "";
                        let dropOffCountry = getQuoteResult && getQuoteResult.Consignee && getQuoteResult.Consignee.Country? getQuoteResult.Consignee.Country : getQuoteResult && getQuoteResult.customerBranchData && getQuoteResult.customerBranchData.countryName? getQuoteResult.customerBranchData.countryName:"";
                        let dropOffCountryPin = getQuoteResult && getQuoteResult.Consignee && getQuoteResult.Consignee.ZipCode? getQuoteResult.Consignee.ZipCode : "";
                        let invoiceId = getQuoteResult && getQuoteResult.prevNext && getQuoteResult.prevNext.InvoiceApproved && getQuoteResult.prevNext.InvoiceApproved.nId? getQuoteResult.prevNext.InvoiceApproved.nId : "";
                        let bookingId = getQuoteResult && getQuoteResult.prevNext && getQuoteResult.prevNext.Executed && getQuoteResult.prevNext.Executed.pId? getQuoteResult.prevNext.Executed.pId : "";
                        let shipperName = getQuoteResult && getQuoteResult.proformaData && getQuoteResult.proformaData.shipperName? getQuoteResult.proformaData.shipperName : "";
                        let ConsigneeName = getQuoteResult && getQuoteResult.proformaData && getQuoteResult.proformaData.consigneeName? getQuoteResult.proformaData.consigneeName : "";
                        let shipperDetails = getQuoteResult && getQuoteResult.proformaData && getQuoteResult.proformaData.shipperDetail? getQuoteResult.proformaData.shipperDetail : "";
                        let consigneeDetails = getQuoteResult && getQuoteResult.proformaData && getQuoteResult.proformaData.consigneeDetail? getQuoteResult.proformaData.consigneeDetail : "";
                        let referenceJobNo = getQuoteResult && getQuoteResult.referenceJobNo? getQuoteResult.referenceJobNo : "";
                        let jobNo = getQuoteResult && getQuoteResult.jobNo? getQuoteResult.jobNo : "";
                let skipStatus = getQuoteResult && getQuoteResult.proformaData && getQuoteResult.proformaData.skipStatus? getQuoteResult.proformaData.skipStatus : "";
                let paymentTerms = getQuoteResult && getQuoteResult.proformaData && getQuoteResult.proformaData.paymentTerms? getQuoteResult.proformaData.paymentTerms : this.state.creditDayValue;

                this.setState({
                            quoteFor:quoteFor,
                            getQuoteResult:getQuoteResult,
                            // rateDataForCard:getQuoteResult,
                            originName:originName,
                            destinationName:destinationName,
                            pickUpCountry:pickUpCountry,
                            pickUpCountryPin:pickUpCountryPin,
                            dropOffCountry:dropOffCountry,
                            dropOffCountryPin:dropOffCountryPin,
                            shipperName:shipperName,
                            shipperDetails:shipperDetails,
                            ConsigneeName:ConsigneeName,
                            consigneeDetails:consigneeDetails,
                            serviceType:serviceType,
                            productType:productType,
                            totalPieces:totalPieces,
                            volumeWeight:volumeWeight,
                            grossWeight:grossWeight,
                            activityType:activityType,
                            shipmentMode:shipmentMode,
                            chargeableWeight:chargeableWeight,
                            tarrifMode:tarrifMode,
                            commodity:commodity,
                            clearenceDate:clearenceDate,
                            noOfContainers:noOfContainers,
                            dimentions:dimentions,
                            dimentionType:dimentionType,
                            queryFor:queryFor,
                            activeStatus:activeStatus,
                            quoteStatus:quoteStatus,
                            invoiceId:invoiceId,
                            bookingId:bookingId,
                            referenceJobNo:referenceJobNo,
                    skipStatus:skipStatus,
                    paymentTerms:paymentTerms,
                            jobNo:jobNo,
                        },()=>{
                            localStorage.setItem('queryData', JSON.stringify(getQuoteResult))
                        })
                    }
        })


    }


    GET_BY_QUOTEID_DATA = async (getByQuoteData) => {
        {
            return await RestClient({
                url: getByQuoteData && getByQuoteData.quoteFor === "Road" ? GET_QUERY_PREVIEW_API_ROAD : GET_QUERY_PREVIEW_API_COURIER,
                method: "POST",
                data: getByQuoteData
            });
        }
    };

    tracking = (value) => {
        let maindata = {}
        if (value === "Invoice") {

            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = true;
            maindata.invoiceTrack = false;

        }

        if (value === "Shipment Lost") {
            maindata.openTrack = false;
            maindata.rateQuotedTrack = false;
            maindata.bookingTrack = false;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Shipment Executed") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = true;
            maindata.invoiceTrack = false;
        }
        if (value === "Shipment Final") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Shipment Created") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = true;
            maindata.invoiceTrack = false;
        }
        if (value === "Booking") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Rates Confirmed") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = false;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Rates Quoted") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = false;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Open") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = false;
            maindata.bookingTrack = false;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        return maindata;

    }



    render() {



        return (
            <>
                <AppLayout>
                    <div className="NewHomeSection">
                        <div className={this.state.queryFor === "Courier"?"courier_freight_banner pb-4 change_theme":this.state.queryFor==="Road"?"road_freight_banner pb-4 change_theme":"other_service_banner pb-4 change_theme"}>
                            <div className="container-fluid">
                                <div className="row shipment_details_up">
                                    <div className="details_for_rate">
                                        {this.state.queryFor === "Others" ? "":
                                        <div className="d-flex flex-wrap align-items-center"><h3>{this.state.pickUpCountry}, {this.state.pickUpCountryPin}</h3>
                                            <div className="icon_origin_destination"><FontAwesomeIcon icon={faArrowRight}/></div>
                                            <h3>{this.state.dropOffCountry}, {this.state.dropOffCountryPin}</h3>
                                        </div>}
                                        <div className="details-other">
                                            <div className="text-shipment"><span>{this.state.jobNo + " | "} {this.state.totalPieces} Pcs. | {this.state.chargeableWeight+ "Kgs"} | {this.state.productType} |

                                           <>
                                             {this.state.queryFor === "Others" ?

                                             <>
                                             {this.state.serviceType === "OriginClearence" || this.state.serviceType === "DestinationClearence" ?
                                             <>
                                             {this.state.serviceType === "OriginClearence" ? "Origin Clearence":""}
                                             {this.state.serviceType === "DestinationClearence" ? "Destination Clearence":""}
    </>:
                                          <>  {" "+this.state.serviceType+" "} </>
            }
                                            </>
                                            :
                                            <>

                                            {" "+this.state.serviceType+" "}
                                            </>
        }
                                            </>
                                            |
                                                {this.state.productType ==="Document" ? "":this.state.commodity + " | "}  {this.state.clearenceDate  ? " "+convertDateISOToCustomiseFormat(this.state.clearenceDate)  : ""}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row rates_all_details">
                                    <div className="col-lg-12">
                                        <CourierRateQuotedCardNew
                                            quoteFor={this.state.quoteFor}
                                            paymentTerms={this.state.paymentTerms}
                                            rateDataForCard={this.state.getQuoteResult}
                                            FilterSide = {this.state.FilterSide}
                                            filterSideClose = {(value)=>{
                                                if(value === false){
                                                    this.setState({
                                                        FilterSide : false
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<LeafletMapShipmentManager origin={this.state.pickUpCountryPin} destination={this.state.dropOffCountryPin} />*/}
                    <div className="fixed-btnsbot">
                        <div className="d-flex flex-wrap justify-content-end">
                            <button type="button" className=" manager delete rounded-circles filterBtn btn btn-secondary" onClick={()=>{
                                this.setState({
                                    FilterSide : true
                                })
                            }}>
                                <FontAwesomeIcon icon={faFilter}/> Filter
                            </button>
                            <button type="button" className=" manager delete rounded-circles btn btn-secondary" onClick={e => {
                                this.props.history.push(PAGE_PATHS.MAIN_PAGE)
                            }}>
                                <FontAwesomeIcon icon={faHome}/> Home
                            </button>
                            {this.state.quoteStatus ===  "Open" || this.state.quoteStatus ===  "Rates Quoted" ?
                                <button type="button" className="manager edit rounded-circles btn btn-secondary" onClick={e => {
                                    e.preventDefault()
                                    let queryData = JSON.parse(localStorage.getItem("queryData"));
                                    if(this.state.queryFor ==="Courier"){
                                        localStorage.setItem("queryType", "CourierQuery");
                                        localStorage.setItem("shipmentHistoryEditDataCourier", JSON.stringify(queryData));
                                        setTimeout(()=>{ this.props.history.push(PAGE_PATHS.MAIN_PAGE)},1000)
                                    }
                                    else if(this.state.queryFor ==="Road"){
                                        localStorage.setItem("queryType", "RoadQuery");
                                        localStorage.setItem("shipmentHistoryEditDataRoad", JSON.stringify(queryData));
                                        setTimeout(()=>{ this.props.history.push(PAGE_PATHS.MAIN_PAGE)},1000)
                                    }else{
                                        localStorage.setItem("queryType", "OtherQuery");
                                        localStorage.setItem("shipmentHistoryEditDataOther", JSON.stringify(queryData));
                                        setTimeout(()=>{ this.props.history.push(PAGE_PATHS.MAIN_PAGE)},1000)
                                    }

                                }}>
                                    <FontAwesomeIcon icon={faAngleLeft}/> Previous
                                </button>
                                :""}
                            {this.state.quoteStatus ===  "Open" || this.state.quoteStatus ===  "Rates Quoted" ? "" :
                                <button type="button" className="manager edit rounded-circles btn btn-secondary" onClick={e => {
                                    // if(this.state.queryFor === "Courier"){
                                        if(this.state.typeOfBussiness === "Individual" ){
                                            if(this.state.skipStatus === "Paid"){
                                                this.props.history.push(PAGE_PATHS.BOOKING_COURIER_VIEW + "/" + this.state.quoteFor + "/"+this.state.bookingId)
                                            }else{
                                                this.props.history.push(PAGE_PATHS.INDIVIDUAL_BOOKING_PAGE + "/" + this.state.quoteFor + "/"+this.state.bookingId)
                                            }
                                        }else{
                                            if(this.state.paymentTerms === "0"){
                                                if(this.state.skipStatus === "Paid"){
                                                    this.props.history.push(PAGE_PATHS.BOOKING_COURIER_VIEW + "/" + this.state.quoteFor + "/"+this.state.bookingId)
                                                }
                                                else{
                                                    this.props.history.push(PAGE_PATHS.INDIVIDUAL_BOOKING_PAGE + "/" + this.state.quoteFor + "/"+this.state.bookingId)
                                                }
                                            }else{
                                                this.props.history.push(PAGE_PATHS.BOOKING_COURIER_VIEW + "/" + this.state.quoteFor + "/"+this.state.bookingId)

                                            }

                                        }
                                    // }else{
                                    //     this.props.history.push(PAGE_PATHS.BOOKING_COURIER_VIEW + "/" + this.state.quoteFor + "/"+this.state.bookingId)
                                    //
                                    // }

                                }}>
                                     Next <FontAwesomeIcon icon={faAngleRight}/>
                                </button>
                            }

                        </div>
                    </div>
                </AppLayout>
            </>
        )}

};



export default RatesQueryPreviewCourier;
