import React, {Component} from 'react';

class FormStart extends Component {

    constructor(props) {
        super(props);

    }


    render() {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aliquid, aperiam autem deleniti distinctio
                            dolor doloribus, enim impedit iste labore laudantium magni nam nesciunt placeat praesentium quibusdam sunt unde, voluptatem.</p>
                    </div>
                </div>


            </>
        );
    }
}


export default FormStart;
