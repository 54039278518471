import { all, call, fork, put } from 'redux-saga/effects';
import RestClient from "../../util/RestClient";
import {PAGE_PATHS} from "../../util/PageRoute";
import {
    REGISTRATION_MASTER_CREATE_HIT,
    CUSTOMER_MASTER_BRANCH_CREATE_HIT,
    GET_ALL_CUSTOMER_MASTER_LIST
} from "../../constants";
import { toastr } from "react-redux-toastr";
import {
    REGISTRATION_MASTER_CREATE,
    CUSTOMER_MASTER_BRANCH_CREATE,
    CUSTOMER_MASTER_FETCH_MASTER

} from "../actions";
import routeRedirectTo from "../../util/routeWrapper";
import { takeLatest } from "@redux-saga/core/effects";
import {
    saveRegistrationMaster,
    saveRegistrationMasterResponse,
    saveRegistrationMasterBranch,
    saveRegistrationMasterBranchResponse,
    onRegistrationMasterFetchedResponse,

    onRegistrationMasterFetchedFailed
    // onOriginDestinationMasterFetched,
    // onOriginDestinationMasterFetchedFailed, updateOriginDestinationResponse, deleteOriginDestinationResponse
} from "./registrationAction";
import { PAGE_PATHS as PageRoute } from "../../util/PageRoute";


export function* watchRegistrationMaster() {
    yield takeLatest(REGISTRATION_MASTER_CREATE, storeRegistrationMaster);
    yield takeLatest(CUSTOMER_MASTER_BRANCH_CREATE, storeRegistrationMasterBranch);
    yield takeLatest(CUSTOMER_MASTER_FETCH_MASTER, fecthRegistrationMaster);
    // yield takeLatest(ORIGIN_DESTINATION_DELETE, deleteOriginDestination);
}

//fetch data

function* fecthRegistrationMaster({payload}) {

    //console.log("In fetch Airfreight Attempt" + JSON.stringify(payload))

    try {
        const fecthRegistrationMasterManagerResponse = yield call(fecthRegistrationMasterAsync, payload);
        //console.log("fecthRegistrationMasterManager" + fecthRegistrationMasterManagerResponse);
        if (fecthRegistrationMasterManagerResponse.success) {
            yield put(onRegistrationMasterFetchedResponse(fecthRegistrationMasterManagerResponse.result));
        } else {
            //console.log("fecthRegistrationMasterManager Not Success" + fecthRegistrationMasterManagerResponse)
            yield put(onRegistrationMasterFetchedFailed(fecthRegistrationMasterManagerResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            //console.log("In Error fecthRegistrationMasterManager" + JSON.stringify(error))
            yield put(onRegistrationMasterFetchedFailed(error.response.data.message));
        }

    }
}

const fecthRegistrationMasterAsync = async (fecthRegistrationMasterData) => {
        //console.log(JSON.stringify("data from api calling", + JSON.stringify(fecthRegistrationMasterData)))
        return await RestClient({
            url: GET_ALL_CUSTOMER_MASTER_LIST,
            method: "POST",
            data: fecthRegistrationMasterData
        })
}




function* storeRegistrationMaster({ payload }) {
    try {
        const storeRegistrationMasterResponse = yield call(storeRegistrationMasterAsync, payload);
        if (storeRegistrationMasterResponse.success) {
            yield put(saveRegistrationMasterResponse(storeRegistrationMasterResponse.message));
            //console.log("result::" +JSON.stringify(storeRegistrationMasterResponse.result))
            // window.location='/';
            toastr.success("Saved Successfully", {
                transitionIn: "bounceIn",
                transitionOut: "bounceOut"
              });
              // let pdf is variable 
            //   routeRedirectTo.push(PAGE_PATHS.REGISTRATION_RED)
            let pdfRoutes = storeRegistrationMasterResponse.result
           // on window. loaction variable is pdfRoutes
           window.print = pdfRoutes
            // routeRedirectTo.push(PAGE_PATHS.REGISTRATION_RED)
        //. after file donload
            
           window.location='/Registration';
         
        }
    } catch
    (error) {
        if (Object.keys(error).length !== 0) {
            yield put(saveRegistrationMaster(error.response.data.message));
        }
    }
}

const storeRegistrationMasterAsync = async (StoreRegistrationMasterData) => {
    //console.log(JSON.stringify("In Customer Master Save API" + JSON.stringify(StoreRegistrationMasterData)))

    return await RestClient({
        url: REGISTRATION_MASTER_CREATE_HIT,
        method: "POST",
        data: StoreRegistrationMasterData
    })
}
function* storeRegistrationMasterBranch({ payload }) {
    try {
        const storeRegistrationMasterBranchResponse = yield call(storeRegistrationMasterBranchAsync, payload);
        if (storeRegistrationMasterBranchResponse.success) {
            yield put(saveRegistrationMasterBranchResponse(storeRegistrationMasterBranchResponse.message));;
        }
    } catch
    (error) {
        if (Object.keys(error).length !== 0) {

            yield put(saveRegistrationMasterBranch(error.response.data.message));
        }
    }
}


const storeRegistrationMasterBranchAsync = async (payload) => {
    {
        //console.log(JSON.stringify("In Customer Master Save API" + JSON.stringify(payload)))

        return await RestClient({
            url: CUSTOMER_MASTER_BRANCH_CREATE_HIT,
            method: "POST",
            data: payload
        })

    }
}


function forwardTo(location) {
    routeRedirectTo.push(location);
}

export default function* Saga() {
    yield all([
        fork(watchRegistrationMaster)
    ]);
}
