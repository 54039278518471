import {
    CONSIGNEE_CREATE,
    CONSIGNEE_CREATE_RESPONSE,

    CONSIGNEE_MANAGER_FETCH_FAILURE,
    CONSIGNEE_MANAGER_FETCH_MASTER,
    CONSIGNEE_MANAGER_FETCH_RESPONSE,

    CONSIGNEE_MASTER_PREVIEW,

    CONSIGNEE_MASTER_UPDATE,
    CONSIGNEE_MASTER_UPDATE_RESPONSE,

} from "../actions";

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
    ConsigneeFormValues: {}
};


const masterConsigneeReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case CONSIGNEE_CREATE:
            // console.log("In Consignee Reducer Save " + payload);
            return {...state, loading: true, response: ''};

        case CONSIGNEE_CREATE_RESPONSE:
            // console.log("in Consignee CREATE Response" + JSON.stringify(payload));
            return {...state, loading: false, result: '', AddConsigneeResponse: payload.message};




        case CONSIGNEE_MANAGER_FETCH_MASTER:
            // console.log("In Consignee Master Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};
        case CONSIGNEE_MANAGER_FETCH_RESPONSE:
            // console.log("in Consignee Master Success");
            return {...state, loading: false, items: payload.data, totalItemCount: payload.total, error: ''};

        case CONSIGNEE_MANAGER_FETCH_FAILURE:
            // console.log("in Consignee Master Failure" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload.message};

        case CONSIGNEE_MASTER_PREVIEW:
            // console.log("In Consignee Master Preview  " + JSON.stringify(payload));
            return {...state, loading: false, ConsigneeFormValues: payload};



        case CONSIGNEE_MASTER_UPDATE:
            // console.log("In CONSIGNEE_MASTER_UPDATE " + payload);
            return {...state, loading: true, response: ''};

        case CONSIGNEE_MASTER_UPDATE_RESPONSE:
            // console.log("in CONSIGNEE_MASTER_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state, loading: false, responseConsignee: payload.message};



        default:
            return initState;
    }
};

export default masterConsigneeReducer;
