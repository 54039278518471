import React, { Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class Pricing extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});



    render() {
        return (
            <AppLayout>

                <div className="LatestPlan" ref={this.testRef}>
                    <section className="PlansOur">
                        <div className="container-fluid">
                            <div className="row plans_width">
                                <div className="plans_heading">
                                    <h1 className="text-dark mt-xl"><span>Choose a Plan That Works Best for You</span></h1>
                                    <p className="font-20">Start with a free account and upgrade as you grow</p>
                                </div>
                                <div className="plans_details">
                                    <div className="price-plans">
                                        <h3><span>Silver</span> Package</h3>

                                        <ul>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> The First two domestic couriers are free.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> The first two shipment’s Airway bill charges are Free.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> Custom clearance for Air shipments over 5,000 Kgs
                                                General cargo charges are free, and only receipted
                                                charges will be assessed.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> To enroll for Silver Package, the required consolidated
                                                monthly billing is Rs. 2 Lac.</li>

                                        </ul>
                                        <button className="planBtn btn" onClick={(e)=>{
                                            e.preventDefault()
                                            this.props.history.push(PAGE_PATHS.CONTACT_FOR_PLAN + "/" + "SILVER")
                                        }}>Contact Sales</button>
                                    </div>
                                    <div className="price-plans">
                                        <h3><span>Gold</span> Package</h3>

                                        <ul>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> No Awb Charges for first five shipments.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> First five domestic courier are free.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> Custom clearance for Air shipments over 5,000 Kgs
                                                general cargo charges are free and only receipted
                                                charges will be assessed.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> Free Website Development (only fixed template).</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> To enroll for GoldPackage, the required consolidated
                                                monthly billing is Rs. 5 Lac.</li>

                                        </ul>
                                        <button className="planBtn btn" onClick={(e)=>{
                                            e.preventDefault()
                                            this.props.history.push(PAGE_PATHS.CONTACT_FOR_PLAN + "/" + "GOLD")
                                        }}>Contact Sales</button>
                                    </div>
                                    <div className="price-plans">
                                        <h3><span>Diamond</span> Package</h3>

                                        <ul>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> As cashback, 0.50% of your total monthly billing is returned
                                                to your account.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> Customs clearance is free for general cargo weighing more
                                                than 5,000 kg, only receipted charges will be assessed.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> Free website development with numerous modifications.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> Free Domain and Hosting.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> First ten domestic courier are free.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> No Awb Charges for first ten shipments.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> To enroll for Diamond Package, the required consolidated
                                                monthly billing is Rs. 10 Lac.</li>
                                        </ul>
                                        {/*<button className="planBtn btn"><a*/}
                                        {/*    href="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5481464906133195-1674477213479.pdf"*/}
                                        {/*>Download Pdf</a></button>*/}
                                        <button className="planBtn btn" onClick={(e)=>{
                                            e.preventDefault()
                                            this.props.history.push(PAGE_PATHS.CONTACT_FOR_PLAN + "/" + "DIAMOND")
                                        }}>Contact Sales</button>
                                    </div>

                                </div>
                                <div className="termsPlans">
                                    <h1 className="text-dark"><span>Thing To Remember</span></h1>
                                    <hr/>
                                    <div className="price-plans1">
                                        <ul>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> If your monthly billing is less / more than the
                                                recommended package billing, the plan will be
                                                downgraded and upgraded accordingly;
                                                otherwise, it will expire after two months.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> To sign up, please download the mobile app,
                                                register, and continue taking advantage of the
                                                benefits.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> We are offering the first 100 Customers in
                                                the conference Diamond Package at no
                                                cost.</li>
                                            <li><FontAwesomeIcon icon={faCheckCircle}/> Payment terms: 100% in advance at the time
                                                the shipment is booked.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterHome/>

            </AppLayout>
        )
    }


}


export default Pricing;
