import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import advanceSearchSaga from "./advanceSearch/advanceSearchSaga";
import spotRateSaga from "./spotRateRedux/spotRateSaga";
import invoiceSaga from "./invoiceRedux/invoiceSaga";
import shipmentHistorySaga from "./shipmentHistoryRedux/shipmentHistorySaga";
import watchmawbSaga from "./mawbRedux/mawbSaga";
import ShipperConsigneeSaga from "./shipperConsigneeRedux/shipperConsigneeSaga";
import watchhawbSaga from "./hawbRedux/hawbSaga";
import ShipperConsigneeManagerSaga from "./ShipperConsigneeApprovalRedux/shipperConsigneeApprovalSaga";
import ragistrationSaga from "./registrationMasterRedux/registrationSaga";
import eDocketSaga from "./eDocketRedux/eDocketSaga";
import shipperSaga from "./shipperRedux/shipperSaga";
import notificationSaga from "./notificationRedux/notificationSaga";
import consigneeSaga from "./consigneeRedux/consigneeSaga";
import NewFlashSaga from "./NewsFlashRedux/NewsFlashSaga";
import QuerySaga from "../../src/redux/QueryModule/QuerySaga";
import {QuerySagaAir} from "../redux/QueryRedux/QuerySaga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    advanceSearchSaga(),
    spotRateSaga(),
    invoiceSaga(),
    shipmentHistorySaga(),
    watchmawbSaga(),
    ShipperConsigneeSaga(),
    watchhawbSaga(),
    ShipperConsigneeManagerSaga(),
    ragistrationSaga(),
    eDocketSaga(),
    shipperSaga(),
    consigneeSaga(),
    notificationSaga(),
    NewFlashSaga(),
    QuerySaga(),
    QuerySagaAir(),
  ]);
}
