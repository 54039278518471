import {
    ORIGIN_GET_LIST,
    ORIGIN_GET_LIST_SUCCESS,
    ORIGIN_GET_LIST_ERROR,
    GET_CHARGES,
    GET_CHARGES_SUCCESS,
    GET_CHARGES_ERROR,
    STORE_QUERY,
    STORE_QUERY_SUCCESS,
    STORE_QUERY_ERROR,
    UPDATE_QUERY,
    UPDATE_QUERY_SUCCESS,
    UPDATE_QUERY_ERROR,
    STORE_SELECTED_BOOKING_RATE,
    STORE_SELECTED_BOOKING_RATE_SUCCESS,
    STORE_SELECTED_BOOKING_RATE_ERROR,
    GET_SPOT_BOOKING_RATE,
    GET_SPOT_BOOKING_RATE_SUCCESS,
    GET_SPOT_BOOKING_RATE_SUCCESS_ERROR,
    SPOT_RATE_QUERY_STORE,
    SPOT_RATE_QUERY_STORE_SUCCESS,
    SPOT_RATE_QUERY_STORE_SUCCESS_ERROR,
    SEND_TO_QUERY_FORM,
    STORE_QUERY_FOR_DATA,
    STORE_SELECTED_ALL_RATE,
    STORE_SELECTED_ALL_RATE_SUCCESS,
    STORE_SELECTED_ALL_RATE_ERROR,
    OPEN_OFFER_SECTION_CARD,
    SEND_NEXT_MBL_SUCCESS,
    SPOT_RATE_HOUSE_DATA,
    SEND_CONATAINER_LIST_TO_HBL,
    STORE_COURIER_QUERY,
    STORE_COURIER_QUERY_SUCCESS,
    STORE_COURIER_QUERY_ERROR,
    STORE_COURIER_QUERY_FOR_DATA,
    GET_COURIER_CHARGES_SUCCESS,
    GET_COURIER_CHARGES_ERROR, GET_COURIER_CHARGES,

} from '../actions';


export const getOriginList = (param) => ({
    type: ORIGIN_GET_LIST,
    payload: param
});

export const getOriginListSuccess = (items) => ({
    type: ORIGIN_GET_LIST_SUCCESS,
    payload: items
});

export const getOriginListError = (error) => ({
    type: ORIGIN_GET_LIST_ERROR,
    payload: error
});

export const getCharges = (parameters) => ({
    type: GET_CHARGES,
    payload: parameters
});

export const getChargesSuccess = (items) => ({
    type: GET_CHARGES_SUCCESS,
    payload: items
});

export const getChargestError = (error) => ({
    type: GET_CHARGES_ERROR,
    payload: error
});


export const getCourierCharges = (parameters) => ({
    type: GET_COURIER_CHARGES,
    payload: parameters
});

export const getCourierChargesSuccess = (items) => ({
    type: GET_COURIER_CHARGES_SUCCESS,
    payload: items
});

export const getCourierChargestError = (error) => ({
    type: GET_COURIER_CHARGES_ERROR,
    payload: error
});
export const sendQuery = (parameters) => ({
    type: STORE_QUERY,
    payload: parameters
});
export const updateQuery = (parameters) => ({
    type: UPDATE_QUERY,
    payload: parameters
});

export const sendQueryForStore = (parameters) => ({
    type: STORE_QUERY_FOR_DATA,
    payload: parameters
});

export const sendQuerySuccess = (items) => ({
    type: STORE_QUERY_SUCCESS,
    payload: items
});
export const updateQuerySuccess = (items) => ({
    type: UPDATE_QUERY_SUCCESS,
    payload: items
});
export const updateQueryError = (error) => ({
    type: UPDATE_QUERY_ERROR,
    payload: error
});
export const sendQueryError = (error) => ({
    type: STORE_QUERY_ERROR,
    payload: error
});
export const sendCourierQuery = (parameters) => ({
    type: STORE_COURIER_QUERY,
    payload: parameters
});
export const sendCourierQueryForStore = (parameters) => ({
    type: STORE_COURIER_QUERY_FOR_DATA,
    payload: parameters
});
export const sendCourierQuerySuccess = (items) => ({
    type: STORE_COURIER_QUERY_SUCCESS,
    payload: items
});

export const sendCourierQueryError = (error) => ({
    type: STORE_COURIER_QUERY_ERROR,
    payload: error
});
export const sendBookingRates = (parameters,suggestion) => ({
    type: STORE_SELECTED_BOOKING_RATE,
    payload: parameters,
    suggestion: suggestion
});

export const sendBookingRatesSuccess = (items) => ({
    type: STORE_SELECTED_BOOKING_RATE_SUCCESS,
    payload: items
});

export const sendNextToDashboard = (items) => ({
    type: SEND_NEXT_MBL_SUCCESS,
    payload: items
});

export const SendConatinerList = (items) => ({
    type: SEND_CONATAINER_LIST_TO_HBL,
    payload: items
});

export const sendBookingRatesError = (error) => ({
    type: STORE_SELECTED_BOOKING_RATE_ERROR,
    payload: error
});

export const sendRatesForStore = (parameters) => ({
    type: STORE_SELECTED_ALL_RATE,
    payload: parameters
});

export const sendRatesForStoreSuccess = (items) => ({
    type: STORE_SELECTED_ALL_RATE_SUCCESS,
    payload: items
});

export const sendRatesForStoreError = (error) => ({
    type: STORE_SELECTED_ALL_RATE_ERROR,
    payload: error
});

export const spotRateChargesFetch = (parameters) => ({
    type: GET_SPOT_BOOKING_RATE,
    payload: parameters
});



export const spotRateChargesSuccess = (items) => ({
    type: GET_SPOT_BOOKING_RATE_SUCCESS,
    payload: items
});

export const spotRateChargesError = (error) => ({
    type: GET_SPOT_BOOKING_RATE_SUCCESS_ERROR,
    payload: error
});

export const spotRateQueryAdd = (parameters) => ({
    type: SPOT_RATE_QUERY_STORE,
    payload: parameters
});
export const SendHouseData = (parameters) => ({
    type: SPOT_RATE_HOUSE_DATA,
    payload: parameters
});

export const SpotRateQueryAddSuccess = (items) => ({
    type: SPOT_RATE_QUERY_STORE_SUCCESS,
    payload: items
});

export const SpotRateQueryAddError = (error) => ({
    type: SPOT_RATE_QUERY_STORE_SUCCESS_ERROR,
    payload: error
});
export const sendToquery = (parameters) => ({
    type: SEND_TO_QUERY_FORM,
    payload: parameters
});

export const OpenOfferSection = (parameters) => ({
    type: OPEN_OFFER_SECTION_CARD,
    payload: parameters
});
