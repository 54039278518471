import {
  SHIPPER_FETCH_ALL,
  SHIPPER_FETCH_ALL_FAILURE,
  SHIPPER_FETCH_ALL_RESPONSE,
  CONSIGNEE_FETCH_ALL,
  CONSIGNEE_FETCH_ALL_FAILURE,
  CONSIGNEE_FETCH_ALL_RESPONSE
} from "../actions";

const initState = {};


const shipperConsigneeReducer = (state = initState, {type, payload}) => {
  switch (type) {

    case SHIPPER_FETCH_ALL:
      // console.log(" onRequested Reducer  " + JSON.stringify(payload));
      return {...state, loading: true, response: ''};

    case SHIPPER_FETCH_ALL_RESPONSE:
      // console.log("onSuccess Reducer" + JSON.stringify(payload));
      return {...state, loading: false, shipperData: payload, totalShipperCount: payload.total, error: ''};
    case SHIPPER_FETCH_ALL_FAILURE:
      // console.log("onFailure Reducer" + JSON.stringify(payload));
      return {...state, loading: false, shipperData: [], error: payload.message};


    case CONSIGNEE_FETCH_ALL:
      // console.log(" onRequested Reducer  " + JSON.stringify(payload));
      return {...state, loading: true, response: ''};
    case CONSIGNEE_FETCH_ALL_RESPONSE:
      // console.log("onSuccess Reducer" + JSON.stringify(payload));
      return {...state, loading: false, consigneeData: payload, totalConsigneeCount: payload.total, error: ''};
    case CONSIGNEE_FETCH_ALL_FAILURE:
      // console.log("onFailure Reducer" + JSON.stringify(payload));
      return {...state, loading: false, consigneeData: [], error: payload.message};

    default:

      return initState;
  }
};

export default shipperConsigneeReducer;
