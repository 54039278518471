import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


class OceanFreight extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        return (
    <>
    <MetaTags>
        {/*<title> Zipaworld: Ocean/sea freight forwarder & shipping digital logistics </title>*/}
        <title> ocean freight forwarder </title>
        {/*<meta name="title" content="Zipaworld: Ocean/sea freight forwarder & shipping digital logistics"/>*/}
        <meta name="title" content="Streamlining Trade with Expert Ocean Freight/Sea Freight Forwarding Services"/>
        <meta name="description" content="ZipaWorld opens the gateway to global connectivity through our expert sea freight services. We prioritize reliability and efficiency in ocean freight forwarding."/>
        <meta name="keywords" content="sea freight services,sea freight forwarding services,sea shipping,sea freight,sea freight shipping,sea freight forwarder,ocean freight,ocean shipping,ocean transport,ocean logistics,ocean freight forwarder,ocean freight shipping"/>
    </MetaTags>
         
            <AppLayout>
                <div ref={this.testRef} className="services_my services_content_ocean">
                    {/*NE-1305*/}
                </div>
                <div className="services_content paraHyperlink">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Ocean freight <span> forwarding</span></h1>
                                {/*<p>Zipaworld, is coming up with the digital portal for ocean freight as an upshot of the expertise of its personnel in ocean freight movement and long-term association with shipping lines, port handlers, NVOCC and various trade bodies. The ocean freight web platform offers the most attractive pricing and deals for complete FCL, LCL and consolidation services.</p>*/}
                                {/*<p>Searching your best ocean freight forwarder or Sea freight forwarder near you ? Zipaworld is the best platform for you.<Link to="/">Zipaworld</Link> is coming up with the digital portal for ocean freight shipping as an upshot of the expertise of its personnel in ocean freight movement and long-term association with shipping lines, port handlers, NVOCC, and various trade bodies. The ocean/sea freight web platform offers the most attractive pricing and deals for complete FCL, LCL, and consolidation services.</p>*/}
                                <p><a href="https://zipaworld.com/BlogDescription/Understanding-what-ocean-freight-forwarding-Is" target="_blank"> Ocean freight forwarding </a>  has been an integral part of supply chain and
                                    logistics movements in global trade, covering all seas/oceans.
                                    Conventionally, this industry has relied heavily on manual work and
                                    processes, which include extensive paperwork. However, the advent
                                    of digitalization in logistics and supply chain is transforming this
                                    landscape, introducing a new era of efficiency and innovation. Digital
                                    technology has significantly transformed the ocean freight forwarding
                                    and with the use of <a href="https://zipaworld.com/BlogDescription/Ocean_freight_forwarding" target="_blank">cutting-edge technology</a>, now everything is visible and traceable. </p>
                            </div>
                        </div>

                        <div className="row other_image_services">
                            <div className="col-lg-6">
                                <div className="left_service_Air">
                                    <img className="lazyload mobileHidden" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.540856744472967-1681906100832.jpg" alt="Ocean Freight"/>
                                    <img className="lazyload mobileShown" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/images/ShipImageMobile.jpg" alt="Ocean Freight"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/*<p>Zipaworld works out the best options for your Project Cargo and Break Bulk Cargo handling and the operational processes. In addition, Zipaworld also provides buyers and vendor consolidation services.</p>*/}

                                {/*<p>If you want to transport your goods through the ocean, then we are the best choice for you. Because we are the best ocean/sea freight forwarder in Delhi, India. Zipaworld works out the best options for your Project Cargo and Break Bulk Cargo handling and the operational processes. In addition, Zipaworld also provides buyers and vendor consolidation services,and the best ocean freight forwarder based in Noida.</p>*/}
                                {/*<br/>*/}
                                {/*<p>Entrust Zipaworld with your ocean freight and logistics services, a one stop solution for Freight Management Services and outsourcing solutions.</p>*/}
                                <h5 className="marginParaBottom" style={{fontWeight : '700'}}>Why Ocean freight?</h5>
                                <p>In the dynamic landscape of global trade, the choice of transportation
                                    mode is a critical decision that profoundly impacts <a href="https://zipaworld.com/BlogDescription/Challenges-in-Ocean-Freight-Forwarding" target="_blank">supply chain</a> efficiency and cost-effectiveness. Of all the
                                    transportation options at our disposal, ocean freight emerges as the
                                    most reliable and economical choice for transporting large volumes of
                                    goods, bulk commodities, heavy equipment, etc. </p>
                            </div>
                        </div>
                        <div className="row differentContent">
                            <div className="col-lg-12">
                                <h5 className="marginParaBottom" style={{fontWeight : '700'}}>Why choose us?</h5>
                                <p>Leveraging technology efficiently, Zipaworld has streamlined all
                                    processes involved in <a href="https://zipaworld.com/BlogDescription/ocean-freight-forwarders-zipaworld" target="_blank">sea freight forwarding</a>.
                                    From query to invoice, every aspect of freight forwarding
                                    that previously depended on manual processes is now completely
                                    digitalized, reducing dependency and errors. </p>
                                <p><Link to="/About">Zipaworld</Link> is at the forefront of the
                                    digital transformation of the logistics industry. Utilizing the expertise
                                    of our team in ocean and sea freight forwarding and long-standing
                                    collaborations with shipping lines, port handlers, <a href="https://zipaworld.com/BlogDescription/nvocc_ocean_freight" target="_blank">NVOCCs</a>, and various trade organizations, Zipaworld has developed a digital
                                    platform to enhance the sea freight forwarding experience for its
                                    clients.</p>
                                <p>Zipaworld offers comprehensive <a href="https://zipaworld.com/BlogDescription/Choosing-The-Right-Ocean-Freight-Forwarder" target="_blank">shipping solutions</a> with the most attractive pricing and deals for
                                    complete FCL, LCL, and consolidation services. Zipaworld works out
                                    the best options for your project cargo and breaks down bulk cargo
                                    handling and operational processes. </p>
                                <h5 className="marginParaBottom" style={{fontWeight : '700'}}>What do we offer?</h5>
                                <div className="listsData">
                                    <p>1. Seamless documentation.</p>
                                    <p>2. Vessel schedule, route planning, and optimization</p>
                                    <p>3. <a href="https://zipaworld.com/TrackingSearchPage" target="_blank">Live navigation.</a></p>
                                    <p>4. 800+ port combinations</p>
                                    <p>5. Integrated network with shipping lines and NVOCC</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <FooterHome/>
            </AppLayout>
            </>

        );
    }


}



export default OceanFreight;
