import React from 'react';
import truckPNG from "../../assets/img/delivery-truck.png"
import truckMobile from "../../assets/img/truckMobile.png"
// import sentaPNG from "../../assets/img/senta_img.png"
// import sentaPNGOTHER from "../../assets/img/sentaBlack.png"

import "./Loader.css"

const Loader = () => {
  return <div className="loader-container">
    <img className="lazyload truck-image truckDesktop" src={truckPNG} alt="loader" />
    <img className="lazyload truck-image truckMobile" src={truckMobile} alt="loader" />
  </div>;
}

export default Loader;
