import React, {Component, Fragment} from "react";
import AppLayout from "../../../layout/AppLayout";
import ReactHtmlParser from "react-html-parser";
import RestClient from "../../../util/RestClient";
import {
    ADD_COMMENT_BLOG,
    GET_BLOG_MASTER, LIKE_DISLIKE_BLOG,
    MANAGER_BLOG_MASTER,
    REPLY_COMMENT_BLOG, REPLY_TO_REPLY,
    UPDATE_EDIT_RATES,
    GET_BLOG_MASTER_TITLE
} from "../../../constants";
import {convertDateISO, convertDateISOToCustomiseFormat} from "../../../util/CommonUtility";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {toast} from "react-toastify";
import {faCalendar, faCalendarAlt, faClock, faReply} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class BlogDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name : "",
            email : "",
            commentMsg : "",
            commentReplayState:false,
            replyReplyState:false,
            commentMsgReply : "",
            nameReply:"",
            emailReply:"",
            commentMsgReplyReply:"",
            nameReplyReply:"",
            emailReplyReply:"",
            likeBlog :false,
            compareReplyId:"",
            disLikeBlog :false,
        };
    }

    componentDidMount() {
        let _id =
            window.location.href.split("/")[
            window.location.href.split("/").length - 1
                ];
        this.setState({
            id : _id
        })
        //if (localStorage.getItem("BlogTitle") === "true") {


            this.getBlogsDatasforTitle({ urlTitle: _id }).then(responce=> {
                if (responce.success) {
                    let getData = responce && responce.result ? responce.result : [];
                    this.setState({
                        BlogDataTitle : getData.title,
                        BlogDataImg : getData.imgLocation,
                        BlogDataDescription : getData.Description,
                        BlogDataCategory : getData.category,
                        BlogDataDate : convertDateISO(getData.date),
                        comment : getData.comment,
                        reply : getData.reply,
                        replyReply : getData.replyReply,
                        id:getData._id
                    },()=>{

                    })
                }
            })

        //}else {

            this.getBlogsDatas({id: _id}).then(responce => {
                if (responce.success) {
                    let getData = responce && responce.result ? responce.result : [];
                    this.setState({
                        BlogDataTitle: getData.title,
                        BlogDataImg: getData.imgLocation,
                        BlogDataDescription: getData.Description,
                        BlogDataCategory: getData.category,
                        BlogDataDate: convertDateISO(getData.date),
                        comment: getData.comment,
                        reply: getData.reply,
                        replyReply: getData.replyReply,
                        id:getData._id
                    }, () => {

                    })
                }
            })
       // }
        this.getBlogsData({BlogPage:true,}).then(BlogData => {
            if (BlogData && BlogData.success){
                this.setState({BlogDataResult:BlogData.result.data},()=>{

                })
            }
        })
        localStorage.removeItem("BlogTitle");
    }
    getBlogsData = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_BLOG_MASTER,
            method: "POST",
            data: dataForServer
        })

    }

    getBlogsDatas = async (dataForServer) => {
        return await RestClient({
            url: GET_BLOG_MASTER,
            method: "POST",
            data: dataForServer
        })
    }
    getBlogsDatasforTitle = async (dataForServer) => {
        return await RestClient({
            url: GET_BLOG_MASTER_TITLE,
            method: "POST",
            data: dataForServer
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    inputOnChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        },()=>{

        });

    }

    commentSubmit = (e) =>{
        e.preventDefault();
        const body = {
            name: this.state.name,
            email: this.state.email,
            commentMsg: this.state.commentMsg,
            blogId:this.state.id
        };
        this.SubmitComment(body).then(response=> {
            if (response.success) {
                toast.success("Commented Added", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                window.location.reload("false")
            }
        })
    }
    SubmitComment = async (body)=> {
        return await RestClient({
            url: ADD_COMMENT_BLOG,
            method: "POST",
            data: body
        })
    };

    commentReply = (idComment) =>{
        this.setState({
            commentReplayState : !this.state.commentReplayState,
            compareId : idComment
        })


    }
    commentReplyReply =(replyId) =>{
        this.setState({
            replyReplyState : !this.state.replyReplyState,
            compareReplyId : replyId
        })
    }

    commentPerticularReply = (e, idComment) =>{
        e.preventDefault();
        if(this.state.commentMsgReply !==""){
            const body = {
                blogId : this.state.id,
                commentId:idComment,
                name: this.state.nameReply,
                email: this.state.emailReply,
                commentMsg: this.state.commentMsgReply,
            };
            this.SubmitCommentReply(body).then(response=> {
                if (response.success) {
                    toast.success("Commented Added", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    window.location.reload("false")
                }
            })
        }else{
            toast.error("Comment blank", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    perticularReplyReply = (e, resultReply) =>{
        if(this.state.commentMsgReplyReply !==""){
            const body = {
                replyId : resultReply._id,
                blogId : this.state.id,
                commentId:resultReply.commentId,
                name: this.state.nameReplyReply,
                email: this.state.emailReplyReply,
                commentMsg: this.state.commentMsgReplyReply,
            };
            this.SubmitReplyReply(body).then(response=> {
                if (response.success) {
                    toast.success("Commented Added", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    window.location.reload("false")
                }
            })
        }else{
            toast.error("Comment blank", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    SubmitCommentReply = async (body)=> {
        return await RestClient({
            url: REPLY_COMMENT_BLOG,
            method: "POST",
            data: body
        })
    };
    SubmitReplyReply = async (body)=> {
        return await RestClient({
            url: REPLY_TO_REPLY,
            method: "POST",
            data: body
        })
    };

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1
        };
        return (
            <AppLayout>
                <div className="blog_page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog_description_heading"><h4>{this.state.BlogDataTitle}</h4></div>
                                <div className="description_blog">
                                    <div className="description_img">
                                        <img src={this.state.BlogDataImg} alt={this.state.BlogDataTitle}/>
                                    </div>
                                    {/*<div className="like_dislike">*/}
                                    {/*    <div className="likes_comment">*/}
                                    {/*        <div className="comment_like" onClick={e=>{*/}
                                    {/*            this.setState({*/}
                                    {/*                likeBlog  :!this.state.likeBlog,*/}
                                    {/*                disLikeBlog:false*/}
                                    {/*            },()=>{*/}
                                    {/*                this.likeDislikeFunc()*/}
                                    {/*            })*/}
                                    {/*        }}>{this.state.likeBlog  ? <i className="fas fa-thumbs-up"></i>:<i className="far fa-thumbs-up"></i>}*/}
                                    {/*        </div>*/}
                                    {/*        {this.state.like>0 ?<div className="likesCount">{this.state.like} like</div>:""}*/}
                                    {/*    </div>*/}
                                    {/*    <div className="dislikes_comment">*/}
                                    {/*        <div className="comment_dislike" onClick={e=>{*/}
                                    {/*            this.setState({*/}
                                    {/*                disLikeBlog :!this.state.disLikeBlog ,*/}
                                    {/*                likeBlog:false*/}
                                    {/*            },()=>{*/}
                                    {/*                this.likeDislikeFunc()*/}
                                    {/*            })*/}
                                    {/*        }}>{this.state.disLikeBlog  ? <i className="fas fa-thumbs-down"></i>:<i className="far fa-thumbs-down"></i>}*/}
                                    {/*        </div>*/}
                                    {/*        {this.state.disLike>0 ?<div className="likesCount">{this.state.disLike} dislike</div>:""}*/}
                                    {/*    </div>*/}

                                    {/*</div>*/}
                                    <div className="description_text">
                                        <div className="date_mention">
                                            <FontAwesomeIcon icon={faCalendarAlt}/> {this.state.BlogDataDate}
                                        </div>
                                        <div className="description_my">{ReactHtmlParser(this.state.BlogDataDescription)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="blog_right_heading"><h4>Other News</h4></div>
                                <div className="short_news description_scroll_small">
                                    {Array.isArray(this.state.BlogDataResult) && this.state.BlogDataResult.length >= 1
                                        ? this.state.BlogDataResult.map((obj, i) => {
                                            return (
                                                <>
                                                    {obj && obj.Description ?
                                                    <div className="small_newss" key={i} onClick={e => {
                                                        e.preventDefault()
                                                        if (obj.urlTitle){
                                                            localStorage.setItem("BlogTitle","true");
                                                            history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj.urlTitle)
                                                            window.location.reload("false")

                                                        }else {

                                                            history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj._id)
                                                            window.location.reload("false")
                                                        }
                                                    }}>
                                                        <img src={obj && obj.imgLocation} alt={obj && obj.title}/>
                                                        <div className="small_description">
                                                            <h3>{obj && obj.title}</h3>
                                                            <h6>(<FontAwesomeIcon icon={faCalendarAlt}/> {obj && convertDateISOToCustomiseFormat(obj.date)})</h6>
                                                        </div>
                                                    </div>
                                                        :
                                                        <a className="small_newss" key={i} href={obj && obj.newsCategoryLink} target="_blank">
                                                            <img src={obj && obj.imgLocation} alt={obj && obj.title}/>
                                                            <div className="small_description">
                                                                <h3>{obj && obj.title}</h3>
                                                                <h6>(<FontAwesomeIcon icon={faCalendarAlt}/> {obj && convertDateISOToCustomiseFormat(obj.date)})</h6>
                                                            </div>
                                                        </a>}
                                                </>
                                            )
                                        })
                                        : ""}

                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6">
                                {Array.isArray(this.state.comment) && this.state.comment.length >= 1
                                    ? this.state.comment.map((obj, i) => {
                                        console.log("obj.reply",JSON.stringify(obj))
                                        return(
                                            <div className="show_comment">
                                                <div className="name_comment">{obj.name}</div>
                                                <div className="msg_comment">
                                                    <p>{obj.message}</p>
                                                    <div className="replay_icon btn" onClick={e=>{
                                                        this.commentReply(obj._id)
                                                    }} ><FontAwesomeIcon icon={faReply}/> Reply</div>
                                                    {this.state.commentReplayState && (obj._id === this.state.compareId) ?
                                                        <div className="col-lg-12">
                                                            <div className="comment_submit">
                                                                <h4>Leave A Reply</h4>
                                                                <div className="comment_form">
                                                                    <div className="form-group">
                                                                        <input type="text" name="nameReply" placeholder="Your Name"  onChange={this.inputOnChange}/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <input type="text" name="emailReply" placeholder="Email" onChange={this.inputOnChange}/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <textarea name="commentMsgReply" rows="6" placeholder="Comment" onChange={this.inputOnChange}></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <button className="btn"
                                                                                onClick={e=>{
                                                                            this.commentPerticularReply(e,obj._id)
                                                                        }}
                                                                        >Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :""}
                                                    {this.state.reply && this.state.reply.map((objReply, i) => {
                                                        return(
                                                            <>
                                                            {objReply.commentId === obj._id?<div className="sub_comment" key={i}>
                                                                <div className="name_comment">{objReply.name}</div>
                                                                <div className="msg_comment_reply">
                                                                    <p>{objReply.message}</p>
                                                                    <div className="replay_icon btn" onClick={e=>{
                                                                        this.commentReplyReply(objReply._id)
                                                                    }} ><FontAwesomeIcon icon={faReply}/> Reply</div>
                                                                    {this.state.replyReplyState && (objReply._id === this.state.compareReplyId) ?
                                                                        <div className="col-lg-12">
                                                                            <div className="comment_submit">
                                                                                <h4>Leave A Reply</h4>
                                                                                <div className="comment_form">
                                                                                    <div className="form-group">
                                                                                        <input type="text" name="nameReplyReply" placeholder="Your Name"  onChange={this.inputOnChange}/>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <input type="text" name="emailReplyReply" placeholder="Email" onChange={this.inputOnChange}/>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <textarea name="commentMsgReplyReply" rows="6" placeholder="Comment" onChange={this.inputOnChange}></textarea>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <button className="btn"
                                                                                                onClick={e=>{
                                                                                                    this.perticularReplyReply(e,objReply)
                                                                                                }}
                                                                                        >Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :""}
                                                                    {this.state.replyReply && this.state.replyReply.map((itemReplyReply, i) => {
                                                                        return(
                                                                            <>
                                                                                {objReply._id === itemReplyReply.replyId?<div className="sub_reply" key={i}>
                                                                                    <div className="name_comment">{itemReplyReply.name}</div>
                                                                                    <div className="msg_comment_reply">
                                                                                        <p>{itemReplyReply.message}</p>
                                                                                    </div>
                                                                                </div>:""}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>:""}
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                    :""}
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="comment_submit">
                                    <h4>Leave A Reply</h4>
                                    <div className="comment_form">
                                        <div className="form-group">
                                            <input type="text" name="name" placeholder="Your Name" value={this.state.name} onChange={this.inputOnChange}/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.inputOnChange}/>
                                        </div>
                                        <div className="form-group">
                                            <textarea name="commentMsg" rows="6" placeholder="Comment" value={this.state.commentMsg} onChange={this.inputOnChange}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn" onClick={this.commentSubmit}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
        );
    }


}

export default BlogDescription;
