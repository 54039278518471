import moment from "moment";
import "firebase/auth";
import {NotificationManager} from "../components/common/react-notifications";
import IntlMessages from "../helpers/IntlMessages";
import {NavLink} from "react-router-dom";
import React from "react";


const options = {
    year: "numeric",
    month: "long",
    day: "numeric"
};

export const createNotification = (type, className) => {
    let cName = className || "";
    try {
        return () =>
        {
        switch (type) {
            case "primary":
                NotificationManager.primary(
                    "This is a notification!",
                    "Primary Notification",
                    3000,
                    null,
                    null,
                    cName
                );
                break;
            case "secondary":
                NotificationManager.secondary(
                    "This is a notification!",
                    "Secondary Notification",
                    3000,
                    null,
                    null,
                    cName
                );
                break;
            case "info":
                NotificationManager.info("Info message", "", 3000, null, null, cName);
                break;
            case "success":
                NotificationManager.success(
                    "Success message",
                    "Title here",
                    3000,
                    null,
                    null,
                    cName
                );
                break;
            case "warning":
                NotificationManager.warning(
                    "Warning message",
                    "Close after 3000ms",
                    3000,
                    null,
                    null,
                    cName
                );
                break;
            case "error":
                NotificationManager.error(
                    "Error message",
                    "Click me!",
                    5000,
                    () => {
                        // /*//alert("callback");*/
                    },
                    null,
                    cName
                );
                break;
            default:
                NotificationManager.info("Info message");
                break;
        }
    };
    }
    catch (e) {
        return ""
    }

};



export const convertDate = function (dateToConvert) {
    try {

        return new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        }).format(new Date(dateToConvert.replace(/['"]+/g, '')))
    } catch (e) {
        return ""
    }
}
export const convertDateWithDay = function (dateToConvert) {
    try {

        return new Date(convertDate(dateToConvert)).toLocaleDateString(
            undefined,
            options
        )
    } catch (e) {
        return ""
    }
}


export const convertDateISO = function (dateToConvert) {
    try {
        return moment(dateToConvert).format('YYYY-MM-DD');
    } catch (e) {
        return ""
    }
}

export const convertDateISOToCustomiseFormat = function (dateToConvert) {
    try {
        return moment(dateToConvert).format('DD-MMM-YYYY');
    } catch (e) {
        return ""
    }
}

export const convertDateISOToDDMMYYYY = function (dateToConvert) {
    try {
        return moment(dateToConvert).format('DD-MM-YYYY');
    } catch (e) {
        return ""
    }
}

export const convertDateISOToNewsFormat = function (dateToConvert) {
    try {
        return moment(dateToConvert).format('MMM DD,YYYY');
    } catch (e) {
        return ""
    }
}

export const convertDateFromISO = function (dateToConvert, dateFormat) {
    try {
        return moment(dateToConvert).format(dateFormat);
    } catch (e) {
        return ""
    }
}
export const convertISOToTime = function (dateToConvert, dateFormat) {
    try {
        return new Date(dateToConvert).getMinutes()
    } catch (e) {
        return ""
    }
}
export const getExchangeRate = function () {
    try {
        return parseFloatValue(localStorage.getItem("ExchangeRate")) === 0 ? 1 : localStorage.getItem("ExchangeRate")
    } catch (e) {
        return 1
    }
}
export const queryPreferenceUtility = function () {
    try {
        if (
            localStorage.getItem("queryPreferenceCache") !== undefined ||
            localStorage.getItem("queryPreferenceCache") !== null ||
            localStorage.getItem("queryPreferenceCache").length !== 0
        )
        {
            let data = localStorage.getItem("queryPreferenceCache") === "Oceans" ? "Oceans" : "AirPort"
            return data
        }


    } catch (e) {
        return "AirPort"
    }
}
export const getExchangeCurrency = function () {
    try {
        return localStorage.getItem("ExchangeCurrency") ? localStorage.getItem("ExchangeCurrency") : "INR"
    } catch (e) {
        return "INR"
    }
}
export const getHomeCurrency = function () {
    try {
        return "INR"
    } catch (e) {
        return "INR"
    }
}
export const dynamicSort = function (property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}


export const removeValuesFromArray = function (array, toRemove) {

    if (Array.isArray(array) && array.length) {
        let index = array.indexOf(toRemove);
        if (index !== -1) array.splice(index, 1);
    }
}
export const isDataArrayChanged = function (currentArray, prevPropArray) {
    if (!currentArray) {
        return false
    }
    if (Array.isArray(currentArray) && currentArray.length) {
        return JSON.stringify(currentArray) !== JSON.stringify(prevPropArray)
    }
}

export const checkEmpty = function (value) {
    if (typeof value !== 'undefined' && value) {
        //deal with value'
    }
}

export const getLoggedInUserCountry = function () {
    try {
        if (localStorage.getItem("user") !== undefined) {

            let userData = JSON.parse(localStorage.getItem("user"))
            return userData.result.branch.address.countryName
        }
    } catch (e) {
        return ""
    }
}
export const getLoggedInUserEmail = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let email = userData.result && userData.result.customerBranchData && userData.result.customerBranchData.emails[0] !== undefined ? userData.result.customerBranchData.emails[0] : null;
            return email
        }else {
            return null
        }
    } catch (e) {
        return ""
    }
}



export const getCustomerId = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let customerId = userData.result && userData.result.customerData && userData.result.customerData._id ? userData.result.customerData._id : null;
            return customerId
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}

export const getCustomerIsCustomerBelongsToEuropeanCountry = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let customerId = userData.result && userData.result.customerData && userData.result.customerData.isCustomerBelongsToEuropeanCountry ? userData.result.customerData.isCustomerBelongsToEuropeanCountry : null;
            return customerId
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}

export const getCustomerCode = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let CustomerCode = userData.result && userData.result.customerData && userData.result.customerData.customerCode ? userData.result.customerData.customerCode : null;
            return CustomerCode
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}

export const airShipmentHistoryStatsBy = function () {
    try {
        if (localStorage.getItem("airShipmentHistoryStatsBy") !== undefined) {
            let airShipmentHistoryStatsBy =localStorage.getItem("airShipmentHistoryStatsBy")
            return airShipmentHistoryStatsBy
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}

export const oceanShipmentHistoryStatsBy = function () {
    try {
        if (localStorage.getItem("oceanShipmentHistoryStatsBy") !== undefined) {
            let oceanShipmentHistoryStatsBy =localStorage.getItem("oceanShipmentHistoryStatsBy")
            return oceanShipmentHistoryStatsBy
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}


export const getCustomerName = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let customerId = userData.result && userData.result.customerData && userData.result.customerData.customerName ? userData.result.customerData.customerName : "";
            return customerId
        }else {
            return ""
        }
    } catch (e) {
        return ""
    }
}

export const getBaseCurrency = function () {
    try {

        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let currency = userData.result && userData.result.csBuddyData && userData.result.csBuddyData.branchId && userData.result.csBuddyData.branchId.currency ? userData.result.csBuddyData.branchId.currency : null;
            return currency
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}
export const getCurrenctCompanyBranchData = function () {
    try {

        if (!localStorage.getItem("customer") === false) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let branchData = userData.result && userData.result.csBuddyData && userData.result.csBuddyData.branchId  ? userData.result.csBuddyData.branchId : null;

            return branchData


        }else {
            return null
        }
    } catch (e) {
        return null
    }
}

export const getCurrenctCompanyData = function () {
    try {

        if (!localStorage.getItem("customer") === false) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let branchData = userData.result && userData.result.companyData  ? userData.result.companyData : {};
            return branchData
        }else {
            return {}
        }
    } catch (e) {
        return {}
    }
}

export const getCustomerCurrency = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let custCurrency = userData.result && userData.result.customerData && userData.result.customerData.custCurrency ? userData.result.customerData.custCurrency : null;
            return custCurrency
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}

export const getCustomerBranchId = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let branchId = userData.result && userData.result.customerBranchData && userData.result.customerBranchData._id ? userData.result.customerBranchData._id : null;
            return branchId
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}

export const getLoggedInUserId = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            return userData.result.customerData._id
        }
    } catch (e) {
        return null
    }
}
export const getLoggedInCustomerId = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            return userData.result.customerBranchData._id
        }
    } catch (e) {
        return null
    }
}

export const updateLoggedInCustomerId = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            return userData.result.customerBranchData._id
        }
    } catch (e) {
        return null
    }
}
export const getLoggedInCustomerCurrency = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData =JSON.parse(localStorage.getItem("customer"))
            let custCurrency = userData.result && userData.result.customerBranchData && userData.result.customerBranchData.custCurrency ? userData.result.customerBranchData.custCurrency : null;
            return custCurrency
        }else {
            return null
        }
    } catch (e) {
        return null
    }
}
export const getCustomerData = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            let {customerBranchData,customerData} = userData && userData.result ? userData.result : {}
            let customer = {
                customerId: customerBranchData && customerBranchData.customerId ? customerBranchData.customerId : "",
                customerBranchId: customerBranchData && customerBranchData._id ? customerBranchData._id : "",
                customerName: customerData && customerData.customerName ? customerData.customerName : "",
                customerBranchName: customerBranchData && customerBranchData.branchName ? customerBranchData.branchName : "",
                pos: customerBranchData && customerBranchData.stateCode ? customerBranchData.stateCode : "",
                gstType:customerBranchData && customerBranchData.gstType ? customerBranchData.gstType : "",
                gstNo: customerBranchData && customerBranchData.gstNo ? customerBranchData.gstNo : "",
            }

            return customer
        }
    } catch (e) {
        return ""
    }
}

export const getCustomerUserBranchIDData = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            let {csBuddyData,customerBranchData} = userData && userData.result ? userData.result : {}

               // let branchId = csBuddyData && csBuddyData.branchId && csBuddyData.branchId._id ? csBuddyData.branchId._id : "" ;
               let branchId = customerBranchData && customerBranchData.companyBranchId ? customerBranchData.companyBranchId : "" ;
                // console.log("branches Id",JSON.stringify(customerBranchData.companyBranchId))
            return branchId
        }
    } catch (e) {
        return ""
    }
}

export const getCustomerUserBranchNameData = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            let {csBuddyData} = userData && userData.result ? userData.result : {}

            let branchCompanyName = csBuddyData && csBuddyData.branchId && csBuddyData.branchId.branchName ? csBuddyData.branchId.branchName : "" ;

            return branchCompanyName
        }
    } catch (e) {
        return ""
    }
}


export const getCustomerGStTypeData = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            let {customerBranchData} = userData && userData.result ? userData.result : {}

            let gstType = customerBranchData && customerBranchData.gstType  ? customerBranchData.gstType : "" ;
            let gstNo = customerBranchData && customerBranchData.gstNo  ? customerBranchData.gstNo : "" ;
            let stateCode = customerBranchData && customerBranchData.stateCode  ? customerBranchData.stateCode : "" ;

            return customerBranchData
        }
    } catch (e) {
        return ""
    }
}

export const getCustomerUserBranchCodeData = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            let {csBuddyData} = userData && userData.result ? userData.result : {}

            let branchCode = csBuddyData && csBuddyData.branchId && csBuddyData.branchId.branchCode ? csBuddyData.branchId.branchCode : "" ;

            return branchCode
        }
    } catch (e) {
        return ""
    }
}


export const getCustomerUserBranchPosData = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            let {csBuddyData} = userData && userData.result ? userData.result : {}

            let branchPos = csBuddyData && csBuddyData.branchId && csBuddyData.branchId.gstNo ? csBuddyData.branchId.gstNo.substr(0, 2) : "" ;

            return branchPos
        }
    } catch (e) {
        return ""
    }
}

export const getCustomerIsInEuropeanValue = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            let {customerData} = userData && userData.result ? userData.result : {}
            let isCustomerBelongsToEuropeanCountry = customerData && customerData.isCustomerBelongsToEuropeanCountry  ? customerData.isCustomerBelongsToEuropeanCountry : false ;
            return isCustomerBelongsToEuropeanCountry
        }
    } catch (e) {
        return ""
    }
}

export const getCustomerPosData = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            let {customerBranchData} = userData && userData.result ? userData.result : {}

            let CustPos = customerBranchData &&  customerBranchData.stateCode ? customerBranchData.stateCode : "" ;

            return CustPos
        }
    } catch (e) {
        return ""
    }
}

export const getLoggedInCustomerCode = function () {
    try {
        if (localStorage.getItem("customer") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("customer"))
            return userData.result.customerCode
        }
    } catch (e) {
        return ""
    }
}

export const getLoggedInUserName = function () {
    try {
        if (localStorage.getItem("user") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("user"))
            return userData.result.user.name
        }
    } catch (e) {
        return ""
    }
}

export const getLoggedInUserRolesFor = async function (roleName) {
    try {
        if (localStorage.getItem("user") !== undefined) {

            let userData = JSON.parse(localStorage.getItem("user"))
            let role = userData.result.user.roles
            let roleData = role[roleName].operations
            // console.log("roleData::" + JSON.stringify(roleData))
            return roleData

        }
    } catch (e) {
        return {}
    }
}
export const getLoggedInUserRolesForManager = function (roleName, roleAction) {
    try {
        if (localStorage.getItem("user") !== undefined) {

            let userData = JSON.parse(localStorage.getItem("user"))
            let role = userData.result.user.roles
            let roleData = role[roleName].operations[roleAction]
            // console.log("roleData::" + JSON.stringify(roleData))
            return roleData

        }
    } catch (e) {
        return {}
    }
}


export const parseFloatValue = function (value) {
    try {
        let valueParsed = parseFloat(parseFloat(value).toFixed(2))
        return isNaN(valueParsed) ? 0 : valueParsed;
    } catch (e) {
        return 0
    }
}

export const parseArrayLength = function (value) {
    try {
        let valueParsed = Array.isArray(value) ? value.length : 0
        return isNaN(valueParsed) ? 0 : valueParsed;
    } catch (e) {
        return 0
    }
}

export const prepareDynamicUrl = function (value,) {
    switch (value) {

    }
}

export const asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};

export const getQueryType = function () {
    // console.log("localstorage",localStorage.getItem("queryType"))
    try {
        if (localStorage.getItem("queryType")) {
            // console.log("catcif")
            let queryType = localStorage.getItem("queryType")

            return queryType
        }
        else {
            if (localStorage.getItem("customer") !== undefined) {
                let userData = JSON.parse(localStorage.getItem("customer"))
                let typeOfBussiness = userData && userData.result && userData.result.customerData && userData.result.customerData.typeOfBussiness ? userData.result.customerData.typeOfBussiness:""
                if(typeOfBussiness === "Individual"){
                    localStorage.setItem("queryType","CourierQuery")
                    return "CourierQuery"
                }else{
                    localStorage.setItem("queryType","OceanQuery")
                    return "OceanQuery"
                }
            }else{
                localStorage.setItem("queryType","OceanQuery")
                return "OceanQuery"
            }

        }
    } catch (e) {
        // console.log("catch")
        return "AirQuery"
    }
}
// export const getCustomerDetails = function () {
//     try {
//             let typeOfCust = JSON.parse(localStorage.getItem("customer"))
//             let custType = typeOfCust && typeOfCust.result && typeOfCust.result.customerData && typeOfCust.result.customerData.typeOfBussiness
//             let custKycStatus = typeOfCust && typeOfCust.result && typeOfCust.result.customerBranchData && typeOfCust.result.customerBranchData.kycStatus
            
//             return {typeOfCust,custType,custKycStatus}
        
//     } catch (e) {
//         return false
//     }
// }

export const getProcessFlowResult = function (statusCode) {

    // {
    //     "0": {
    //     "displayStatus": "Open",
    //         "processFlow": "Open",
    //         "timing": "2020-04-08T03:51:45.679Z"
    // },
    //     "1": {
    //     "displayStatus": "Rates Quoted",
    //         "processFlow": "Rates Quoted"
    // },
    //     "2": {
    //     "displayStatus": "Rates Quoted",
    //         "processFlow": "Rates Confirmed"
    // },
    //     "3": {
    //     "displayStatus": "Booked",
    //         "processFlow": "Booking Approved"
    // },
    //     "4": {
    //     "displayStatus": "Booked",
    //         "processFlow": "Booking Confirmed"
    // },
    //     "5": {
    //     "displayStatus": "Booked",
    //         "processFlow": "Hawbs Created"
    // },
    //     "6": {
    //     "displayStatus": "Airway Bill",
    //         "processFlow": "Mawb Draft"
    // },
    //     "7": {
    //     "displayStatus": "Airway Bill",
    //         "processFlow": "Mawb Final"
    // },
    //     "8": {
    //     "displayStatus": "Airway Bill",
    //         "processFlow": "Mawb Executed"
    // },
    //     "9": {
    //     "displayStatus": "Invoice Created",
    //         "processFlow": "Invoice Created"
    // },
    //     "10": {
    //     "displayStatus": "Invoice Approved",
    //         "processFlow": "Invoice Approved"
    // },
    //     "current": "0"
    // }

    try {
        switch (parseFloatValue(statusCode)) {
            case 0:
                return 0
            case 1:
            case 2:
            case 3:
                return 1


            case 4:
            case 5:
                return 2
            case 6:
            case 7:
            case 8:
                return 3
            case 9:
            case 10:
                return 4
            default:
                return 0
        }
    } catch (e) {
        return 0
    }
}

export const getProcessFlowResultForBooking = function (awbStatus, awbUrl) {

    try {
        if (!awbStatus || !awbUrl) {
            // {Booking}
            return 2
        }
        if (awbStatus != "Executed") {
            // {Airwaybill}
            return 3
        } else {
            // {Invoice}
            return 4
        }
    } catch (e) {
        return 2
    }
}

export const calculateChargesAccordingtoStructure =  (chargesArray, CustGstType,Custpos,UserPos,customerCurrency,currencyRateConversion) =>{
    try {

        const chargesArrayForMap = chargesArray;
       if(Array.isArray(chargesArray) && chargesArray.length >= 1 ) {

           let chargesArray = [];

           chargesArrayForMap && chargesArrayForMap.map((charges,i)=>{


               let discountB = charges && charges.discountB ? charges.discountB : 0 ;
               let discountB15 = charges && charges.discountB15 ? charges.discountB15 : 0 ;
               let discountB30 = charges && charges.discountB30 ? charges.discountB30 : 0 ;
               let discountC = charges && charges.discountC ? charges.discountC : 0 ;
               let discountC15 = charges && charges.discountC15 ? charges.discountC15 : 0 ;
               let discountC30 = charges && charges.discountC30 ? charges.discountC30 : 0 ;
               let id = charges && charges._id ? charges._id : "" ;
               let chargeFor = charges && charges.chargeFor ? charges.chargeFor : "" ;
               let chargeName = charges && charges.chargeName ? charges.chargeName : "" ;
               let chargeCode = charges && charges.chargeCode ? charges.chargeCode : "" ;
               let chargeHsnCode = charges && charges.chargeHsnCode ? charges.chargeHsnCode : "" ;
               let chargeType = charges && charges.chargeType ? charges.chargeType : "" ;
               let currency = charges && charges.currency ? charges.currency : "" ;
               let altName = charges && charges.altName ? charges.altName : "" ;
               let chargeRate = charges && charges.chargeRate ? charges.chargeRate : 0 ;
               let chargeAccording = charges && charges.chargeAccording ? charges.chargeAccording : '' ;
               let chargeQty = charges && charges.chargeQty ? charges.chargeQty : 0;
               let xRateBase = charges && charges.xRateBase ? charges.xRateBase : 0;
               let rateOfTaxFromCharge = charges && charges.chargeIgstRate ? charges.chargeIgstRate : 0;
               let chargeIgstAmountB = ""
               let taxableB = ""
               let chargeTaxableB = ""
               if(chargeRate !== "" && chargeQty !== "" && xRateBase !== "" ){
                   taxableB = chargeRate * chargeQty * xRateBase
                   chargeTaxableB = chargeRate * chargeQty * xRateBase
               }
               chargesArray[i] = {...chargesArray[i], chargeRate: chargeRate};
               chargesArray[i] = {...chargesArray[i], altName: altName};
               chargesArray[i] = {...chargesArray[i], currency: currency};
               chargesArray[i] = {...chargesArray[i], chargeType: chargeType};
               chargesArray[i] = {...chargesArray[i], chargeHsnCode: chargeHsnCode};
               chargesArray[i] = {...chargesArray[i], chargeCode: chargeCode};
               chargesArray[i] = {...chargesArray[i], chargeFor: chargeFor};
               chargesArray[i] = {...chargesArray[i], chargeAccording: chargeAccording};
               chargesArray[i] = {...chargesArray[i], chargeName: chargeName};
               chargesArray[i] = {...chargesArray[i], id: id};
               chargesArray[i] = {...chargesArray[i], chargeQty: discountB};
               chargesArray[i] = {...chargesArray[i], chargeQty: discountC};
               chargesArray[i] = {...chargesArray[i], chargeQty: chargeQty};
               chargesArray[i] = {...chargesArray[i], xRateBase: xRateBase};
               chargesArray[i] = {...chargesArray[i], taxableB: taxableB};
               chargesArray[i] = {...chargesArray[i], chargeTaxableB: chargeTaxableB};
               chargesArray[i] = {...chargesArray[i], rateOfTaxFromCharge: rateOfTaxFromCharge};
               let taxable = charges && charges.isTaxable  ? charges.isTaxable : false;
               chargeTaxableB = charges && charges.chargeTaxableB  ? charges.chargeTaxableB : 0;
               if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" ||CustGstType === "U-UIN"){
               //alert("inside CustGstType === O-OVERSEAS"+CustGstType)
                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB};
                       chargesArray[i] = {...chargesArray[i], isTaxable: false};


               }
               else {
                   if (taxable !== "" && taxable === false) {
                    //alert("inside taxable "+taxable)
                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB};
                       chargesArray[i] = {...chargesArray[i], isTaxable: false};

                   } else{

                       if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
                        //alert("inside Custpos "+Custpos+"!="+UserPos)
                           let amountCalculateForPOS = chargeTaxableB && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB : 0
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculateForPOS * 2)};
                           chargesArray[i] = {...chargesArray[i], isTaxable: true};

                       } else {
                           //alert("inside Custpos "+Custpos+"=="+UserPos)

                           let amountCalculate = chargeTaxableB && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB : 0
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountB: (parseFloatValue(chargeTaxableB)  + parseFloatValue(amountCalculate))};
                           chargesArray[i] = {...chargesArray[i], isTaxable: true};
                       }

                   }
               }

               let xRateCustomer = charges && charges.xRateCustomer ? charges.xRateCustomer : 0;
                taxableB = charges && charges.taxableB ? charges.taxableB : 0;
               let taxableC = charges && charges.taxableC ? charges.taxableC : 0;
               let chargeTaxableC = charges && charges.chargeTaxableC ? charges.chargeTaxableC : 0;

               if(chargeRate !== "" && chargeQty !== "" && xRateCustomer !== "" ){
                   if(customerCurrency === currency){
                       taxableC = chargeRate * chargeQty
                       chargeTaxableC = chargeRate * chargeQty
                   }else{
                       if(currencyRateConversion){
                           taxableC = taxableB * xRateCustomer * currencyRateConversion
                           chargeTaxableC = taxableB * xRateCustomer * currencyRateConversion
                       }else{
                           taxableC = taxableB * xRateCustomer
                           chargeTaxableC = taxableB * xRateCustomer
                       }

                   }

               }
               chargesArray[i] = {...chargesArray[i], isTaxable: taxable};
               chargesArray[i] = {...chargesArray[i], taxableC: taxableC};
               chargesArray[i] = {...chargesArray[i], chargeTaxableC: chargeTaxableC};
               chargesArray[i] = {...chargesArray[i], xRateCustomer: xRateCustomer};
               if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" || CustGstType === "U-UIN" )
               {
                   //if (taxable !== "" && (taxable === false)) {
                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC};
                       chargesArray[i] = {...chargesArray[i], isTaxable: false};


               }else
               {

                   if (taxable !== "" && taxable === false) {
                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC};
                       chargesArray[i] = {...chargesArray[i], isTaxable: false};

                   } else{

                       if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {

                           let amountCalculateForPOS = chargeTaxableC && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC : 0
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculateForPOS * 2)};
                           chargesArray[i] = {...chargesArray[i], isTaxable: true};

                       } else {
                           let amountCalculate = chargeTaxableC && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC : 0
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountC: (parseFloatValue(chargeTaxableC)  + parseFloatValue(amountCalculate))};
                           chargesArray[i] = {...chargesArray[i], isTaxable: true};
                       }

                   }
               }

           })

           let saleValue = {};
           let totalIgstAmountB = 0 ;
           let totalIgstAmountC = 0 ;
           let totalSgstAmountB = 0 ;
           let totalSgstAmountC = 0 ;
           let totalCgstAmountB = 0 ;
           let totalCgstAmountC = 0 ;
           let totalChargeTaxableB = 0;
           let totalChargeTaxableC = 0;
           let amountB = 0;
           let amountC = 0;

           chargesArray.map((item,i)=>{
               totalIgstAmountB = parseFloat(totalIgstAmountB)  + parseFloat(item.chargeIgstAmountB !== undefined && item.chargeIgstAmountB !== "" && item.chargeIgstAmountB !== null ? item.chargeIgstAmountB : 0 ) ;
               totalIgstAmountC = parseFloat(totalIgstAmountC)  + parseFloat( item.chargeIgstAmountC !== undefined && item.chargeIgstAmountC !== "" && item.chargeIgstAmountC !== null ? item.chargeIgstAmountC : 0) ;
               totalSgstAmountB = parseFloat(totalSgstAmountB)  + parseFloat( item.chargeSgstAmountB !== undefined && item.chargeSgstAmountB !== "" && item.chargeSgstAmountB !== null ? item.chargeSgstAmountB : 0) ;
               totalSgstAmountC = parseFloat(totalSgstAmountC)  + parseFloat( item.chargeSgstAmountC !== undefined && item.chargeSgstAmountC !== "" && item.chargeSgstAmountC !== null ? item.chargeSgstAmountC : 0) ;
               totalCgstAmountB = parseFloat(totalCgstAmountB)  + parseFloat( item.chargeCgstAmountB !== undefined && item.chargeCgstAmountB !== "" && item.chargeCgstAmountB !== null ? item.chargeCgstAmountB : 0) ;
               totalCgstAmountC = parseFloat(totalCgstAmountC)  + parseFloat( item.chargeCgstAmountC !== undefined && item.chargeCgstAmountC !== "" && item.chargeCgstAmountC !== null ? item.chargeCgstAmountC : 0) ;
               totalChargeTaxableB = parseFloat(totalChargeTaxableB)  + parseFloat( item.chargeTaxableB !== undefined && item.chargeTaxableB !== "" && item.chargeTaxableB !== null ? item.chargeTaxableB : 0) ;
               totalChargeTaxableC = parseFloat(totalChargeTaxableC)  + parseFloat( item.chargeTaxableC !== undefined && item.chargeTaxableC !== "" && item.chargeTaxableC !== null ? item.chargeTaxableC : 0) ;
               amountB =parseFloat(amountB)  + parseFloat( item.chargeAmountB !== undefined && item.chargeAmountB !== "" && item.chargeAmountB !== null ? item.chargeAmountB : 0) ;
               amountC =parseFloat(amountC)  + parseFloat( item.chargeAmountC !== undefined && item.chargeAmountC !== "" && item.chargeAmountC !== null ? item.chargeAmountC : 0) ;
           })

           saleValue.charges = chargesArray;
           saleValue.totalIgstAmountB = totalIgstAmountB;
           saleValue.totalIgstAmountC = totalIgstAmountC;
           saleValue.totalSgstAmountB = totalSgstAmountB;
           saleValue.totalSgstAmountC = totalSgstAmountC;
           saleValue.totalCgstAmountB = totalCgstAmountB;
           saleValue.totalCgstAmountC = totalCgstAmountC;
           saleValue.totalChargeTaxableB = totalChargeTaxableB;
           saleValue.totalChargeTaxableC = totalChargeTaxableC;
           saleValue.amountB = amountB;
           saleValue.amountC = amountC;


           return saleValue

       }



    } catch (e) {
        return ""
    }
}
export const calculateChargesAccordingtoStructureIfDiscount =  (chargesArray, CustGstType,Custpos,UserPos,isCustomerBelongsToEuropeanCountry) =>{
// console.log('chargesArraychargesArraychargesArray',JSON.stringify(chargesArray))
    try {

        const chargesArrayForMap = chargesArray;
       if(Array.isArray(chargesArray) && chargesArray.length >= 1 ) {

           let chargesArray = [];

           chargesArrayForMap && chargesArrayForMap.map((charges,i)=>{


               let taxableBDiscount = charges && charges.taxableBDiscount ? charges.taxableBDiscount : 0 ;
               //alert("charges.chargeName"+charges.chargeName)
               //alert("charges.taxableBDiscount"+charges.taxableBDiscount)
               //alert("taxableBDiscount"+taxableBDiscount)
               let discountB = charges && charges.discountB ? charges.discountB : 0 ;
               let discountB15 = charges && charges.discountB15 ? charges.discountB15 : 0 ;
               let discountB30 = charges && charges.discountB30 ? charges.discountB30 : 0 ;
               let discountC = charges && charges.discountC ? charges.discountC : 0 ;
               let discountC15 = charges && charges.discountC15 ? charges.discountC15 : 0 ;
               let discountC30 = charges && charges.discountC30 ? charges.discountC30 : 0 ;
               let id = charges && charges._id ? charges._id : "" ;
               let chargeFor = charges && charges.chargeFor ? charges.chargeFor : "" ;
               let chargeName = charges && charges.chargeName ? charges.chargeName : "" ;
               let chargeAccording = charges && charges.chargeAccording ? charges.chargeAccording : "" ;
               let chargeCode = charges && charges.chargeCode ? charges.chargeCode : "" ;
               let chargeHsnCode = charges && charges.chargeHsnCode ? charges.chargeHsnCode : "" ;
               let chargeType = charges && charges.chargeType ? charges.chargeType : "" ;
               let currency = charges && charges.currency ? charges.currency : "" ;
               let altName = charges && charges.altName ? charges.altName : "" ;
               let chargeRate = charges && charges.chargeRate ? charges.chargeRate : 0 ;
               let chargeQty = charges && charges.chargeQty ? charges.chargeQty : 0;
               let xRateBase = charges && charges.xRateBase ? charges.xRateBase : 0;
               let rateOfTaxFromCharge = charges && charges.chargeIgstRate ? charges.chargeIgstRate : 0;
               let chargeTaxableBDiscount = charges && charges.chargeTaxableBDiscount ? charges.chargeTaxableBDiscount : 0;
               let chargeIgstAmountB = ""
               let taxableB = ""
               let chargeTaxableB = ""
               if(chargeRate !== "" && chargeQty !== "" && xRateBase !== "" ){
                   taxableB = chargeRate * chargeQty * xRateBase
                   chargeTaxableB = chargeRate * chargeQty * xRateBase
               }
               chargesArray[i] = {...chargesArray[i], chargeRate: chargeRate};
               chargesArray[i] = {...chargesArray[i], altName: altName};
               chargesArray[i] = {...chargesArray[i], currency: currency};
               chargesArray[i] = {...chargesArray[i], chargeType: chargeType};
               chargesArray[i] = {...chargesArray[i], chargeHsnCode: chargeHsnCode};
               chargesArray[i] = {...chargesArray[i], chargeCode: chargeCode};
               chargesArray[i] = {...chargesArray[i], chargeFor: chargeFor};
               chargesArray[i] = {...chargesArray[i], chargeName: chargeName};
               chargesArray[i] = {...chargesArray[i], chargeAccording: chargeAccording};
               chargesArray[i] = {...chargesArray[i], id: id};
               chargesArray[i] = {...chargesArray[i], discountB: discountB};
               chargesArray[i] = {...chargesArray[i], discountC: discountC};
               chargesArray[i] = {...chargesArray[i], chargeQty: chargeQty};
               chargesArray[i] = {...chargesArray[i], xRateBase: xRateBase};
               // chargesArray[i] = {...chargesArray[i], taxableB: taxableBDiscount == 0 ? taxableB : taxableBDiscount};
               chargesArray[i] = {...chargesArray[i], taxableB: taxableBDiscount};
               // chargesArray[i] = {...chargesArray[i], chargeTaxableB: chargeTaxableBDiscount == 0 ? chargeTaxableB : chargeTaxableBDiscount};
               chargesArray[i] = {...chargesArray[i], chargeTaxableB: chargeTaxableBDiscount};
               chargesArray[i] = {...chargesArray[i], rateOfTaxFromCharge: rateOfTaxFromCharge};
               let taxable = charges && charges.isTaxable  ? charges.isTaxable : false;
               chargeTaxableB = charges && charges.chargeTaxableB  ? charges.chargeTaxableB : 0;
               if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" ||CustGstType === "U-UIN"){
                   //alert("1115inside CustGstType === O-OVERSEAS"+CustGstType)
                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                       // chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableBDiscount == 0 ? chargeTaxableB : chargeTaxableBDiscount};
                       chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableBDiscount};
                       chargesArray[i] = {...chargesArray[i], isTaxable: false};


               }
               else {

                   if (taxable !== "" && taxable === false) {
                       //alert("inside taxable 1130"+taxable)
                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                       chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableBDiscount};
                       // chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableBDiscount == 0 ? chargeTaxableB : chargeTaxableBDiscount};
                       chargesArray[i] = {...chargesArray[i], isTaxable: false};

                   } else{

                       if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
                        //alert("inside Custpos 1143"+Custpos+"!="+UserPos)
                        //    let amountCalculateForPOS = chargeTaxableBDiscount != 0 && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableBDiscount : ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB
                           let amountCalculateForPOS = chargeTaxableBDiscount && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableBDiscount:0
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                           // chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableBDiscount == 0 ?chargeTaxableB  + (amountCalculateForPOS * 2):chargeTaxableBDiscount  + (amountCalculateForPOS * 2)};
                           chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableBDiscount  + (amountCalculateForPOS * 2)};
                           chargesArray[i] = {...chargesArray[i], isTaxable: true};

                       } else {
                           //alert("inside Custpos 1155"+Custpos+"=="+UserPos)
                           // let amountCalculate = chargeTaxableBDiscount != 0 && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableBDiscount : (rateOfTaxFromCharge / 100) * chargeTaxableB
                           let amountCalculate = chargeTaxableBDiscount && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableBDiscount : 0
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                           // chargesArray[i] = {...chargesArray[i], chargeAmountB: (parseFloatValue(chargeTaxableBDiscount == 0 ? chargeTaxableB :chargeTaxableBDiscount)  + parseFloatValue(amountCalculate))};
                           chargesArray[i] = {...chargesArray[i], chargeAmountB: (parseFloatValue(chargeTaxableBDiscount)  + parseFloatValue(amountCalculate))};
                           chargesArray[i] = {...chargesArray[i], isTaxable: true};
                       }

                   }
               }

               let xRateCustomer = charges && charges.xRateCustomer ? charges.xRateCustomer : 0;
                taxableB = charges && charges.taxableB ? charges.taxableB : 0;
               let taxableC = charges && charges.taxableC ? charges.taxableC : 0;
               let chargeTaxableC = charges && charges.chargeTaxableC ? charges.chargeTaxableC : 0;
               let chargeTaxableCDiscount = charges && charges.chargeTaxableCDiscount ? charges.chargeTaxableCDiscount : 0;
               let taxableCDiscount = charges && charges.taxableCDiscount ? charges.taxableCDiscount : 0;

               if(chargeRate !== "" && chargeQty !== "" && xRateCustomer !== "" ){
                   taxableC = taxableB * xRateCustomer
                   chargeTaxableC = taxableB * xRateCustomer
               }
               chargesArray[i] = {...chargesArray[i], isTaxable: taxable};
               // chargesArray[i] = {...chargesArray[i], taxableC: taxableCDiscount == 0 ? taxableC:taxableCDiscount};
               // chargesArray[i] = {...chargesArray[i], chargeTaxableC: chargeTaxableCDiscount == 0 ? chargeTaxableC:chargeTaxableCDiscount};
               chargesArray[i] = {...chargesArray[i], taxableC: taxableCDiscount};
               chargesArray[i] = {...chargesArray[i], chargeTaxableC: chargeTaxableCDiscount};
               chargesArray[i] = {...chargesArray[i], xRateCustomer: xRateCustomer};
               if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" || CustGstType === "U-UIN" )
               {
                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                       // chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableCDiscount == 0 ? chargeTaxableC:chargeTaxableCDiscount};
                       chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableCDiscount};
                       chargesArray[i] = {...chargesArray[i], isTaxable: false};


               }else
               {

                   if (taxable !== "" && taxable === false) {
                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                       // chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableCDiscount == 0 ? chargeTaxableC:chargeTaxableCDiscount};
                       chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableCDiscount};
                       chargesArray[i] = {...chargesArray[i], isTaxable: false};

                   } else{

                       if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {

                           // let amountCalculateForPOS = chargeTaxableCDiscount!= 0 && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableCDiscount : ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC
                           let amountCalculateForPOS = chargeTaxableCDiscount && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableCDiscount : 0
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableCDiscount  + (amountCalculateForPOS * 2)};
                           // chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableCDiscount == 0 ? chargeTaxableC  + (amountCalculateForPOS * 2):chargeTaxableCDiscount  + (amountCalculateForPOS * 2)};
                           chargesArray[i] = {...chargesArray[i], isTaxable: true};

                       } else {
                           let amountCalculate = chargeTaxableCDiscount && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableCDiscount : 0
                           // let amountCalculate = chargeTaxableCDiscount!=0 && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableCDiscount : (rateOfTaxFromCharge / 100) * chargeTaxableC
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountC: (parseFloatValue(chargeTaxableCDiscount)  + parseFloatValue(amountCalculate))};
                           // chargesArray[i] = {...chargesArray[i], chargeAmountC: (parseFloatValue(chargeTaxableCDiscount == 0 ? chargeTaxableC : chargeTaxableCDiscount)  + parseFloatValue(amountCalculate))};
                           chargesArray[i] = {...chargesArray[i], isTaxable: true};
                       }

                   }
               }

           })

           let saleValue = {};
           let totalIgstAmountB = 0 ;
           let totalIgstAmountC = 0 ;
           let totalSgstAmountB = 0 ;
           let totalSgstAmountC = 0 ;
           let totalCgstAmountB = 0 ;
           let totalCgstAmountC = 0 ;
           let totalChargeTaxableB = 0;
           let totalChargeTaxableC = 0;
           let amountB = 0;
           let amountC = 0;

           chargesArray.map((item,i)=>{
               totalIgstAmountB = parseFloat(totalIgstAmountB)  + parseFloat(item.chargeIgstAmountB !== undefined && item.chargeIgstAmountB !== "" && item.chargeIgstAmountB !== null ? item.chargeIgstAmountB : 0 ) ;
               totalIgstAmountC = parseFloat(totalIgstAmountC)  + parseFloat( item.chargeIgstAmountC !== undefined && item.chargeIgstAmountC !== "" && item.chargeIgstAmountC !== null ? item.chargeIgstAmountC : 0) ;
               totalSgstAmountB = parseFloat(totalSgstAmountB)  + parseFloat( item.chargeSgstAmountB !== undefined && item.chargeSgstAmountB !== "" && item.chargeSgstAmountB !== null ? item.chargeSgstAmountB : 0) ;
               totalSgstAmountC = parseFloat(totalSgstAmountC)  + parseFloat( item.chargeSgstAmountC !== undefined && item.chargeSgstAmountC !== "" && item.chargeSgstAmountC !== null ? item.chargeSgstAmountC : 0) ;
               totalCgstAmountB = parseFloat(totalCgstAmountB)  + parseFloat( item.chargeCgstAmountB !== undefined && item.chargeCgstAmountB !== "" && item.chargeCgstAmountB !== null ? item.chargeCgstAmountB : 0) ;
               totalCgstAmountC = parseFloat(totalCgstAmountC)  + parseFloat( item.chargeCgstAmountC !== undefined && item.chargeCgstAmountC !== "" && item.chargeCgstAmountC !== null ? item.chargeCgstAmountC : 0) ;
               totalChargeTaxableB = parseFloat(totalChargeTaxableB)  + parseFloat( item.chargeTaxableB !== undefined && item.chargeTaxableB !== "" && item.chargeTaxableB !== null ? item.chargeTaxableB : 0) ;
               totalChargeTaxableC = parseFloat(totalChargeTaxableC)  + parseFloat( item.chargeTaxableC !== undefined && item.chargeTaxableC !== "" && item.chargeTaxableC !== null ? item.chargeTaxableC : 0) ;
               amountB =parseFloat(amountB)  + parseFloat( item.chargeAmountB !== undefined && item.chargeAmountB !== "" && item.chargeAmountB !== null ? item.chargeAmountB : 0) ;
               amountC =parseFloat(amountC)  + parseFloat( item.chargeAmountC !== undefined && item.chargeAmountC !== "" && item.chargeAmountC !== null ? item.chargeAmountC : 0) ;
           })

           saleValue.charges = chargesArray;
           saleValue.totalIgstAmountB = totalIgstAmountB;
           saleValue.totalIgstAmountC = totalIgstAmountC;
           saleValue.totalSgstAmountB = totalSgstAmountB;
           saleValue.totalSgstAmountC = totalSgstAmountC;
           saleValue.totalCgstAmountB = totalCgstAmountB;
           saleValue.totalCgstAmountC = totalCgstAmountC;
           saleValue.totalChargeTaxableB = totalChargeTaxableB;
           saleValue.totalChargeTaxableC = totalChargeTaxableC;
           saleValue.amountB = amountB;
           saleValue.amountC = amountC;


           console.log("ChargesCalculatedArray",JSON.stringify(saleValue))
           return saleValue

       }



    } catch (e) {
        return ""
    }
}
export const calculateChargesAccordingtoStructureIf15DaysNoDiscount =  (chargesArray, CustGstType,Custpos,UserPos,isCustomerBelongsToEuropeanCountry) =>{
// //alert('yha aao2')
// console.log('chargesArraychargesArraychargesArray',JSON.stringify(chargesArray))
        try {
    
    
            const chargesArrayForMap = chargesArray;
           if(Array.isArray(chargesArray) && chargesArray.length >= 1 ) {
    
               let chargesArray = [];
    
               chargesArrayForMap && chargesArrayForMap.map((charges,i)=>{
    
    
                   let taxableB15 = charges && charges.taxableB15 ? charges.taxableB15 : 0 ;
                   let discountB = charges && charges.discountB ? charges.discountB : 0 ;
                   let discountB15 = charges && charges.discountB15 ? charges.discountB15 : 0 ;
                   let discountB30 = charges && charges.discountB30 ? charges.discountB30 : 0 ;
                   let discountC = charges && charges.discountC ? charges.discountC : 0 ;
                   let discountC15 = charges && charges.discountC15 ? charges.discountC15 : 0 ;
                   let discountC30 = charges && charges.discountC30 ? charges.discountC30 : 0 ;
                   let id = charges && charges._id ? charges._id : "" ;
                   let chargeFor = charges && charges.chargeFor ? charges.chargeFor : "" ;
                   let chargeName = charges && charges.chargeName ? charges.chargeName : "" ;
                   let chargeCode = charges && charges.chargeCode ? charges.chargeCode : "" ;
                   let chargeHsnCode = charges && charges.chargeHsnCode ? charges.chargeHsnCode : "" ;
                   let chargeType = charges && charges.chargeType ? charges.chargeType : "" ;
                   let currency = charges && charges.currency ? charges.currency : "" ;
                   let chargeAccording = charges && charges.chargeAccording ? charges.chargeAccording : "" ;
                   let altName = charges && charges.altName ? charges.altName : "" ;
                   let chargeRate = charges && charges.chargeRate15 ? charges.chargeRate15 : 0 ;
                   let chargeQty = charges && charges.chargeQty ? charges.chargeQty : 0;
                   let xRateBase = charges && charges.xRateBase ? charges.xRateBase : 0;
                   let rateOfTaxFromCharge = charges && charges.chargeIgstRate ? charges.chargeIgstRate : 0;
                   let chargeTaxableB15 = charges && charges.chargeTaxableB15 ? charges.chargeTaxableB15 : 0;
                   let chargeIgstAmountB = ""
                   let taxableB = ""
                   let chargeTaxableB = ""
                   if(chargeRate !== "" && chargeQty !== "" && xRateBase !== "" ){
                       taxableB = chargeRate * chargeQty * xRateBase
                       chargeTaxableB = chargeRate * chargeQty * xRateBase
                   }
                   chargesArray[i] = {...chargesArray[i], chargeRate: chargeRate};
                   chargesArray[i] = {...chargesArray[i], altName: altName};
                   chargesArray[i] = {...chargesArray[i], currency: currency};
                   chargesArray[i] = {...chargesArray[i], chargeAccording: chargeAccording};
                   chargesArray[i] = {...chargesArray[i], chargeType: chargeType};
                   chargesArray[i] = {...chargesArray[i], chargeHsnCode: chargeHsnCode};
                   chargesArray[i] = {...chargesArray[i], chargeCode: chargeCode};
                   chargesArray[i] = {...chargesArray[i], chargeFor: chargeFor};
                   chargesArray[i] = {...chargesArray[i], chargeName: chargeName};
                   chargesArray[i] = {...chargesArray[i], id: id};
                   chargesArray[i] = {...chargesArray[i], chargeQty: discountB};
                   chargesArray[i] = {...chargesArray[i], chargeQty: discountC};
                   chargesArray[i] = {...chargesArray[i], chargeQty: chargeQty};
                   chargesArray[i] = {...chargesArray[i], xRateBase: xRateBase};
                   chargesArray[i] = {...chargesArray[i], taxableB: taxableB15};
                   chargesArray[i] = {...chargesArray[i], chargeTaxableB: chargeTaxableB15};
                   chargesArray[i] = {...chargesArray[i], rateOfTaxFromCharge: rateOfTaxFromCharge};
                   let taxable = charges && charges.isTaxable  ? charges.isTaxable : false;
                   chargeTaxableB = charges && charges.chargeTaxableB  ? charges.chargeTaxableB : 0;
                   if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" ||CustGstType === "U-UIN"){
                    //    /*//alert("inside CustGstType === O-OVERSEAS"+CustGstType)*/
                       //if (taxable !== "" && (taxable === false)) {
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB15};
                           chargesArray[i] = {...chargesArray[i], isTaxable: false};
                       // } else if (taxable !== "" && taxable === "Taxable" || taxable === true) {
                       //
                       //     if (Custpos !== "" && UserPos !== "" && UserPos === Custpos) {
                       //
                       //         let amountCalculateForPOS = chargeTaxableB && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB : 0
                       //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculateForPOS * 2)};
                       //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                       //
                       //     } else {
                       //
                       //         let amountCalculate = chargeTaxableB && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB : 0
                       //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                       //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                       //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculate)};
                       //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                       //     }
                       // }
    
                   }
                   else {
    
                       if (taxable !== "" && taxable === false) {
                        //    /*//alert("inside taxable "+taxable)*/
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB15};
                           chargesArray[i] = {...chargesArray[i], isTaxable: false};
    
                       } else{
    
                           if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
                            //    /*//alert("inside Custpos "+Custpos+"!="+UserPos)*/
                               let amountCalculateForPOS = chargeTaxableB15 && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB15 : 0
                               chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                               chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB15  + (amountCalculateForPOS * 2)};
                               chargesArray[i] = {...chargesArray[i], isTaxable: true};
    
                           } else {
                            //    /*//alert("inside Custpos "+Custpos+"=="+UserPos)*/
                               let amountCalculate = chargeTaxableB15 && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB15 : 0
                               chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeAmountB: (parseFloatValue(chargeTaxableB15)  + parseFloatValue(amountCalculate))};
                               chargesArray[i] = {...chargesArray[i], isTaxable: true};
                           }
    
                       }
                   }
    
                   let xRateCustomer = charges && charges.xRateCustomer ? charges.xRateCustomer : 0;
                    taxableB = charges && charges.taxableB ? charges.taxableB : 0;
                   let taxableC = charges && charges.taxableC ? charges.taxableC : 0;
                   let chargeTaxableC = charges && charges.chargeTaxableC ? charges.chargeTaxableC : 0;
                   let chargeTaxableC15 = charges && charges.chargeTaxableC15 ? charges.chargeTaxableC15 : 0;
                   let taxableC15 = charges && charges.taxableC15 ? charges.taxableC15 : 0;
    
                   if(chargeRate !== "" && chargeQty !== "" && xRateCustomer !== "" ){
                       taxableC = taxableB * xRateCustomer
                       chargeTaxableC = taxableB * xRateCustomer
                   }
                   chargesArray[i] = {...chargesArray[i], isTaxable: taxable};
                   chargesArray[i] = {...chargesArray[i], taxableC: taxableC15};
                   chargesArray[i] = {...chargesArray[i], chargeTaxableC: chargeTaxableC15};
                   chargesArray[i] = {...chargesArray[i], xRateCustomer: xRateCustomer};
                   if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" || CustGstType === "U-UIN" )
                   {
                       //if (taxable !== "" && (taxable === false)) {
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC15};
                           chargesArray[i] = {...chargesArray[i], isTaxable: false};
                       // } else if (taxable !== "" && taxable === "Taxable" || taxable === true) {
                       //
                       //     if (Custpos !== "" && UserPos !== "" && UserPos === Custpos) {
                       //         let amountCalculateForPOS = chargeTaxableC && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC : 0
                       //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculateForPOS * 2)};
                       //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                       //
                       //     } else {
                       //
                       //         let amountCalculate = chargeTaxableC && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC : 0
                       //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                       //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                       //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                       //         chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculate)};
                       //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                       //     }
                       // }
    
                   }else
                   {
    
                       if (taxable !== "" && taxable === false) {
                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                           chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC15};
                           chargesArray[i] = {...chargesArray[i], isTaxable: false};
    
                       } else{
    
                           if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
    
                               let amountCalculateForPOS = chargeTaxableC15 && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC15 : 0
                               chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                               chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC15  + (amountCalculateForPOS * 2)};
                               chargesArray[i] = {...chargesArray[i], isTaxable: true};
    
                           } else {
                               let amountCalculate = chargeTaxableC15 && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC15 : 0
                               chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                               chargesArray[i] = {...chargesArray[i], chargeAmountC: (parseFloatValue(chargeTaxableC15)  + parseFloatValue(amountCalculate))};
                               chargesArray[i] = {...chargesArray[i], isTaxable: true};
                           }
    
                       }
                   }
    
               })
    
               let saleValue = {};
               let totalIgstAmountB = 0 ;
               let totalIgstAmountC = 0 ;
               let totalSgstAmountB = 0 ;
               let totalSgstAmountC = 0 ;
               let totalCgstAmountB = 0 ;
               let totalCgstAmountC = 0 ;
               let totalChargeTaxableB = 0;
               let totalChargeTaxableC = 0;
               let amountB = 0;
               let amountC = 0;
    
               chargesArray.map((item,i)=>{
                   totalIgstAmountB = parseFloat(totalIgstAmountB)  + parseFloat(item.chargeIgstAmountB !== undefined && item.chargeIgstAmountB !== "" && item.chargeIgstAmountB !== null ? item.chargeIgstAmountB : 0 ) ;
                   totalIgstAmountC = parseFloat(totalIgstAmountC)  + parseFloat( item.chargeIgstAmountC !== undefined && item.chargeIgstAmountC !== "" && item.chargeIgstAmountC !== null ? item.chargeIgstAmountC : 0) ;
                   totalSgstAmountB = parseFloat(totalSgstAmountB)  + parseFloat( item.chargeSgstAmountB !== undefined && item.chargeSgstAmountB !== "" && item.chargeSgstAmountB !== null ? item.chargeSgstAmountB : 0) ;
                   totalSgstAmountC = parseFloat(totalSgstAmountC)  + parseFloat( item.chargeSgstAmountC !== undefined && item.chargeSgstAmountC !== "" && item.chargeSgstAmountC !== null ? item.chargeSgstAmountC : 0) ;
                   totalCgstAmountB = parseFloat(totalCgstAmountB)  + parseFloat( item.chargeCgstAmountB !== undefined && item.chargeCgstAmountB !== "" && item.chargeCgstAmountB !== null ? item.chargeCgstAmountB : 0) ;
                   totalCgstAmountC = parseFloat(totalCgstAmountC)  + parseFloat( item.chargeCgstAmountC !== undefined && item.chargeCgstAmountC !== "" && item.chargeCgstAmountC !== null ? item.chargeCgstAmountC : 0) ;
                   totalChargeTaxableB = parseFloat(totalChargeTaxableB)  + parseFloat( item.chargeTaxableB !== undefined && item.chargeTaxableB !== "" && item.chargeTaxableB !== null ? item.chargeTaxableB : 0) ;
                   totalChargeTaxableC = parseFloat(totalChargeTaxableC)  + parseFloat( item.chargeTaxableC !== undefined && item.chargeTaxableC !== "" && item.chargeTaxableC !== null ? item.chargeTaxableC : 0) ;
                   amountB =parseFloat(amountB)  + parseFloat( item.chargeAmountB !== undefined && item.chargeAmountB !== "" && item.chargeAmountB !== null ? item.chargeAmountB : 0) ;
                   amountC =parseFloat(amountC)  + parseFloat( item.chargeAmountC !== undefined && item.chargeAmountC !== "" && item.chargeAmountC !== null ? item.chargeAmountC : 0) ;
               })
    
               saleValue.charges = chargesArray;
               saleValue.totalIgstAmountB = totalIgstAmountB;
               saleValue.totalIgstAmountC = totalIgstAmountC;
               saleValue.totalSgstAmountB = totalSgstAmountB;
               saleValue.totalSgstAmountC = totalSgstAmountC;
               saleValue.totalCgstAmountB = totalCgstAmountB;
               saleValue.totalCgstAmountC = totalCgstAmountC;
               saleValue.totalChargeTaxableB = totalChargeTaxableB;
               saleValue.totalChargeTaxableC = totalChargeTaxableC;
               saleValue.amountB = amountB;
               saleValue.amountC = amountC;
    
    
               // console.log("ChargesCalculatedArray",JSON.stringify(saleValue))
               return saleValue
    
           }
    
    
    
        } catch (e) {
            return ""
        }
    }
    export const calculateChargesAccordingtoStructureIf30DaysNoDiscount =  (chargesArray, CustGstType,Custpos,UserPos,isCustomerBelongsToEuropeanCountry) =>{
// //alert('yha aao3')
// console.log('chargesArraychargesArraychargesArray',JSON.stringify(chargesArray))
            try {
        
        
                const chargesArrayForMap = chargesArray;
               if(Array.isArray(chargesArray) && chargesArray.length >= 1 ) {
        
                   let chargesArray = [];
        
                   chargesArrayForMap && chargesArrayForMap.map((charges,i)=>{
        
        
                       let taxableB30 = charges && charges.taxableB30 ? charges.taxableB30 : 0 ;
                       let discountB = charges && charges.discountB ? charges.discountB : 0 ;
                       let discountB15 = charges && charges.discountB15 ? charges.discountB15 : 0 ;
                       let discountB30 = charges && charges.discountB30 ? charges.discountB30 : 0 ;
                       let discountC = charges && charges.discountC ? charges.discountC : 0 ;
                       let discountC15 = charges && charges.discountC15 ? charges.discountC15 : 0 ;
                       let discountC30 = charges && charges.discountC30 ? charges.discountC30 : 0 ;
                       let id = charges && charges._id ? charges._id : "" ;
                       let chargeFor = charges && charges.chargeFor ? charges.chargeFor : "" ;
                       let chargeAccording = charges && charges.chargeAccording ? charges.chargeAccording : "" ;
                       let chargeName = charges && charges.chargeName ? charges.chargeName : "" ;
                       let chargeCode = charges && charges.chargeCode ? charges.chargeCode : "" ;
                       let chargeHsnCode = charges && charges.chargeHsnCode ? charges.chargeHsnCode : "" ;
                       let chargeType = charges && charges.chargeType ? charges.chargeType : "" ;
                       let currency = charges && charges.currency ? charges.currency : "" ;
                       let altName = charges && charges.altName ? charges.altName : "" ;
                       let chargeRate = charges && charges.chargeRate30 ? charges.chargeRate30 : 0 ;
                       let chargeQty = charges && charges.chargeQty ? charges.chargeQty : 0;
                       let xRateBase = charges && charges.xRateBase ? charges.xRateBase : 0;
                       let rateOfTaxFromCharge = charges && charges.chargeIgstRate ? charges.chargeIgstRate : 0;
                       let chargeTaxableB30 = charges && charges.chargeTaxableB30 ? charges.chargeTaxableB30 : 0;
                       let chargeIgstAmountB = ""
                       let taxableB = ""
                       let chargeTaxableB = ""
                       if(chargeRate !== "" && chargeQty !== "" && xRateBase !== "" ){
                           taxableB = chargeRate * chargeQty * xRateBase
                           chargeTaxableB = chargeRate * chargeQty * xRateBase
                       }
                       chargesArray[i] = {...chargesArray[i], chargeRate: chargeRate};
                       chargesArray[i] = {...chargesArray[i], altName: altName};
                       chargesArray[i] = {...chargesArray[i], currency: currency};
                       chargesArray[i] = {...chargesArray[i], chargeType: chargeType};
                       chargesArray[i] = {...chargesArray[i], chargeHsnCode: chargeHsnCode};
                       chargesArray[i] = {...chargesArray[i], chargeCode: chargeCode};
                       chargesArray[i] = {...chargesArray[i], chargeFor: chargeFor};
                       chargesArray[i] = {...chargesArray[i], chargeAccording: chargeAccording};
                       chargesArray[i] = {...chargesArray[i], chargeName: chargeName};
                       chargesArray[i] = {...chargesArray[i], id: id};
                       chargesArray[i] = {...chargesArray[i], chargeQty: discountB};
                       chargesArray[i] = {...chargesArray[i], chargeQty: discountC};
                       chargesArray[i] = {...chargesArray[i], chargeQty: chargeQty};
                       chargesArray[i] = {...chargesArray[i], xRateBase: xRateBase};
                       chargesArray[i] = {...chargesArray[i], taxableB: taxableB30};
                       chargesArray[i] = {...chargesArray[i], chargeTaxableB: chargeTaxableB30};
                       chargesArray[i] = {...chargesArray[i], rateOfTaxFromCharge: rateOfTaxFromCharge};
                       let taxable = charges && charges.isTaxable  ? charges.isTaxable : false;
                       chargeTaxableB = charges && charges.chargeTaxableB  ? charges.chargeTaxableB : 0;
                       if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" ||CustGstType === "U-UIN"){
                        //    /*//alert("inside CustGstType === O-OVERSEAS"+CustGstType)*/
                           //if (taxable !== "" && (taxable === false)) {
                               chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                               chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB30};
                               chargesArray[i] = {...chargesArray[i], isTaxable: false};
                           // } else if (taxable !== "" && taxable === "Taxable" || taxable === true) {
                           //
                           //     if (Custpos !== "" && UserPos !== "" && UserPos === Custpos) {
                           //
                           //         let amountCalculateForPOS = chargeTaxableB && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB : 0
                           //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculateForPOS * 2)};
                           //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                           //
                           //     } else {
                           //
                           //         let amountCalculate = chargeTaxableB && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB : 0
                           //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                           //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                           //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculate)};
                           //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                           //     }
                           // }
        
                       }
                       else {
        
                           if (taxable !== "" && taxable === false) {
                            //    /*//alert("inside taxable "+taxable)*/
                               chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                               chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB30};
                               chargesArray[i] = {...chargesArray[i], isTaxable: false};
        
                           } else{
        
                               if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
                                //    /*//alert("inside Custpos "+Custpos+"!="+UserPos)*/
                                   let amountCalculateForPOS = chargeTaxableB30 && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB30 : 0
                                   chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB30  + (amountCalculateForPOS * 2)};
                                   chargesArray[i] = {...chargesArray[i], isTaxable: true};
        
                               } else {
                                //    /*//alert("inside Custpos "+Custpos+"=="+UserPos)*/
                                   let amountCalculate = chargeTaxableB30 && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB30 : 0
                                   chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeAmountB: (parseFloatValue(chargeTaxableB30)  + parseFloatValue(amountCalculate))};
                                   chargesArray[i] = {...chargesArray[i], isTaxable: true};
                               }
        
                           }
                       }
        
                       let xRateCustomer = charges && charges.xRateCustomer ? charges.xRateCustomer : 0;
                        taxableB = charges && charges.taxableB ? charges.taxableB : 0;
                       let taxableC = charges && charges.taxableC ? charges.taxableC : 0;
                       let chargeTaxableC = charges && charges.chargeTaxableC ? charges.chargeTaxableC : 0;
                       let chargeTaxableC30 = charges && charges.chargeTaxableC30 ? charges.chargeTaxableC30 : 0;
                       let taxableC30 = charges && charges.taxableC30 ? charges.taxableC30 : 0;
        
                       if(chargeRate !== "" && chargeQty !== "" && xRateCustomer !== "" ){
                           taxableC = taxableB * xRateCustomer
                           chargeTaxableC = taxableB * xRateCustomer
                       }
                       chargesArray[i] = {...chargesArray[i], isTaxable: taxable};
                       chargesArray[i] = {...chargesArray[i], taxableC: taxableC30};
                       chargesArray[i] = {...chargesArray[i], chargeTaxableC: chargeTaxableC30};
                       chargesArray[i] = {...chargesArray[i], xRateCustomer: xRateCustomer};
                       if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" || CustGstType === "U-UIN" )
                       {
                           //if (taxable !== "" && (taxable === false)) {
                               chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                               chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC30};
                               chargesArray[i] = {...chargesArray[i], isTaxable: false};
                           // } else if (taxable !== "" && taxable === "Taxable" || taxable === true) {
                           //
                           //     if (Custpos !== "" && UserPos !== "" && UserPos === Custpos) {
                           //         let amountCalculateForPOS = chargeTaxableC && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC : 0
                           //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculateForPOS * 2)};
                           //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                           //
                           //     } else {
                           //
                           //         let amountCalculate = chargeTaxableC && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC : 0
                           //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                           //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                           //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                           //         chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculate)};
                           //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                           //     }
                           // }
        
                       }else
                       {
        
                           if (taxable !== "" && taxable === false) {
                               chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                               chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                               chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC30};
                               chargesArray[i] = {...chargesArray[i], isTaxable: false};
        
                           } else{
        
                               if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
        
                                   let amountCalculateForPOS = chargeTaxableC30 && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC30 : 0
                                   chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC30  + (amountCalculateForPOS * 2)};
                                   chargesArray[i] = {...chargesArray[i], isTaxable: true};
        
                               } else {
                                   let amountCalculate = chargeTaxableC30 && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC30 : 0
                                   chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                                   chargesArray[i] = {...chargesArray[i], chargeAmountC: (parseFloatValue(chargeTaxableC30)  + parseFloatValue(amountCalculate))};
                                   chargesArray[i] = {...chargesArray[i], isTaxable: true};
                               }
        
                           }
                       }
        
                   })
        
                   let saleValue = {};
                   let totalIgstAmountB = 0 ;
                   let totalIgstAmountC = 0 ;
                   let totalSgstAmountB = 0 ;
                   let totalSgstAmountC = 0 ;
                   let totalCgstAmountB = 0 ;
                   let totalCgstAmountC = 0 ;
                   let totalChargeTaxableB = 0;
                   let totalChargeTaxableC = 0;
                   let amountB = 0;
                   let amountC = 0;
        
                   chargesArray.map((item,i)=>{
                       totalIgstAmountB = parseFloat(totalIgstAmountB)  + parseFloat(item.chargeIgstAmountB !== undefined && item.chargeIgstAmountB !== "" && item.chargeIgstAmountB !== null ? item.chargeIgstAmountB : 0 ) ;
                       totalIgstAmountC = parseFloat(totalIgstAmountC)  + parseFloat( item.chargeIgstAmountC !== undefined && item.chargeIgstAmountC !== "" && item.chargeIgstAmountC !== null ? item.chargeIgstAmountC : 0) ;
                       totalSgstAmountB = parseFloat(totalSgstAmountB)  + parseFloat( item.chargeSgstAmountB !== undefined && item.chargeSgstAmountB !== "" && item.chargeSgstAmountB !== null ? item.chargeSgstAmountB : 0) ;
                       totalSgstAmountC = parseFloat(totalSgstAmountC)  + parseFloat( item.chargeSgstAmountC !== undefined && item.chargeSgstAmountC !== "" && item.chargeSgstAmountC !== null ? item.chargeSgstAmountC : 0) ;
                       totalCgstAmountB = parseFloat(totalCgstAmountB)  + parseFloat( item.chargeCgstAmountB !== undefined && item.chargeCgstAmountB !== "" && item.chargeCgstAmountB !== null ? item.chargeCgstAmountB : 0) ;
                       totalCgstAmountC = parseFloat(totalCgstAmountC)  + parseFloat( item.chargeCgstAmountC !== undefined && item.chargeCgstAmountC !== "" && item.chargeCgstAmountC !== null ? item.chargeCgstAmountC : 0) ;
                       totalChargeTaxableB = parseFloat(totalChargeTaxableB)  + parseFloat( item.chargeTaxableB !== undefined && item.chargeTaxableB !== "" && item.chargeTaxableB !== null ? item.chargeTaxableB : 0) ;
                       totalChargeTaxableC = parseFloat(totalChargeTaxableC)  + parseFloat( item.chargeTaxableC !== undefined && item.chargeTaxableC !== "" && item.chargeTaxableC !== null ? item.chargeTaxableC : 0) ;
                       amountB =parseFloat(amountB)  + parseFloat( item.chargeAmountB !== undefined && item.chargeAmountB !== "" && item.chargeAmountB !== null ? item.chargeAmountB : 0) ;
                       amountC =parseFloat(amountC)  + parseFloat( item.chargeAmountC !== undefined && item.chargeAmountC !== "" && item.chargeAmountC !== null ? item.chargeAmountC : 0) ;
                   })
        
                   saleValue.charges = chargesArray;
                   saleValue.totalIgstAmountB = totalIgstAmountB;
                   saleValue.totalIgstAmountC = totalIgstAmountC;
                   saleValue.totalSgstAmountB = totalSgstAmountB;
                   saleValue.totalSgstAmountC = totalSgstAmountC;
                   saleValue.totalCgstAmountB = totalCgstAmountB;
                   saleValue.totalCgstAmountC = totalCgstAmountC;
                   saleValue.totalChargeTaxableB = totalChargeTaxableB;
                   saleValue.totalChargeTaxableC = totalChargeTaxableC;
                   saleValue.amountB = amountB;
                   saleValue.amountC = amountC;
        
        
                   // console.log("ChargesCalculatedArray",JSON.stringify(saleValue))
                   return saleValue
        
               }
        
        
        
            } catch (e) {
                return ""
            }
        }
        export const calculateChargesAccordingtoStructureIf15DaysAndDiscount =  (chargesArray, CustGstType,Custpos,UserPos,isCustomerBelongsToEuropeanCountry) =>{
// //alert('yha aao4')
// console.log('chargesArraychargesArraychargesArray',JSON.stringify(chargesArray))
                try {
            
            
                    const chargesArrayForMap = chargesArray;
                   if(Array.isArray(chargesArray) && chargesArray.length >= 1 ) {
            
                       let chargesArray = [];
            
                       chargesArrayForMap && chargesArrayForMap.map((charges,i)=>{
            
            
                           let taxableB15Discount = charges && charges.taxableB15Discount ? charges.taxableB15Discount : 0 ;
                           let discountB = charges && charges.discountB ? charges.discountB : 0 ;
                           let discountB15 = charges && charges.discountB15 ? charges.discountB15 : 0 ;
                           let discountB30 = charges && charges.discountB30 ? charges.discountB30 : 0 ;
                           let discountC = charges && charges.discountC ? charges.discountC : 0 ;
                           let discountC15 = charges && charges.discountC15 ? charges.discountC15 : 0 ;
                           let discountC30 = charges && charges.discountC30 ? charges.discountC30 : 0 ;
                           let id = charges && charges._id ? charges._id : "" ;
                           let chargeFor = charges && charges.chargeFor ? charges.chargeFor : "" ;
                           let chargeAccording = charges && charges.chargeAccording ? charges.chargeAccording : "" ;
                           let chargeName = charges && charges.chargeName ? charges.chargeName : "" ;
                           let chargeCode = charges && charges.chargeCode ? charges.chargeCode : "" ;
                           let chargeHsnCode = charges && charges.chargeHsnCode ? charges.chargeHsnCode : "" ;
                           let chargeType = charges && charges.chargeType ? charges.chargeType : "" ;
                           let currency = charges && charges.currency ? charges.currency : "" ;
                           let altName = charges && charges.altName ? charges.altName : "" ;
                           let chargeRate = charges && charges.chargeRate15 ? charges.chargeRate15 : 0 ;
                           let chargeQty = charges && charges.chargeQty ? charges.chargeQty : 0;
                           let xRateBase = charges && charges.xRateBase ? charges.xRateBase : 0;
                           let rateOfTaxFromCharge = charges && charges.chargeIgstRate ? charges.chargeIgstRate : 0;
                           let chargeTaxableB15Discount = charges && charges.chargeTaxableB15Discount ? charges.chargeTaxableB15Discount : 0;
                           let chargeIgstAmountB = ""
                           let taxableB = ""
                           let chargeTaxableB = ""
                           if(chargeRate !== "" && chargeQty !== "" && xRateBase !== "" ){
                               taxableB = chargeRate * chargeQty * xRateBase
                               chargeTaxableB = chargeRate * chargeQty * xRateBase
                           }
                           chargesArray[i] = {...chargesArray[i], chargeRate: chargeRate};
                           chargesArray[i] = {...chargesArray[i], altName: altName};
                           chargesArray[i] = {...chargesArray[i], currency: currency};
                           chargesArray[i] = {...chargesArray[i], chargeType: chargeType};
                           chargesArray[i] = {...chargesArray[i], chargeHsnCode: chargeHsnCode};
                           chargesArray[i] = {...chargesArray[i], chargeCode: chargeCode};
                           chargesArray[i] = {...chargesArray[i], chargeFor: chargeFor};
                           chargesArray[i] = {...chargesArray[i], chargeAccording: chargeAccording};
                           chargesArray[i] = {...chargesArray[i], chargeName: chargeName};
                           chargesArray[i] = {...chargesArray[i], id: id};
                           chargesArray[i] = {...chargesArray[i], chargeQty: discountB};
                           chargesArray[i] = {...chargesArray[i], chargeQty: discountC};
                           chargesArray[i] = {...chargesArray[i], chargeQty: chargeQty};
                           chargesArray[i] = {...chargesArray[i], xRateBase: xRateBase};
                           chargesArray[i] = {...chargesArray[i], taxableB: taxableB15Discount};
                           chargesArray[i] = {...chargesArray[i], chargeTaxableB: chargeTaxableB15Discount};
                           chargesArray[i] = {...chargesArray[i], rateOfTaxFromCharge: rateOfTaxFromCharge};
                           let taxable = charges && charges.isTaxable  ? charges.isTaxable : false;
                           chargeTaxableB = charges && charges.chargeTaxableB  ? charges.chargeTaxableB : 0;
                           if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" ||CustGstType === "U-UIN"){
                            //    /*//alert("inside CustGstType === O-OVERSEAS"+CustGstType)*/
                               //if (taxable !== "" && (taxable === false)) {
                                   chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB15Discount};
                                   chargesArray[i] = {...chargesArray[i], isTaxable: false};
                               // } else if (taxable !== "" && taxable === "Taxable" || taxable === true) {
                               //
                               //     if (Custpos !== "" && UserPos !== "" && UserPos === Custpos) {
                               //
                               //         let amountCalculateForPOS = chargeTaxableB && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB : 0
                               //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculateForPOS * 2)};
                               //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                               //
                               //     } else {
                               //
                               //         let amountCalculate = chargeTaxableB && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB : 0
                               //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                               //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                               //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculate)};
                               //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                               //     }
                               // }
            
                           }
                           else {
            
                               if (taxable !== "" && taxable === false) {
                                //    /*//alert("inside taxable "+taxable)*/
                                   chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB15Discount};
                                   chargesArray[i] = {...chargesArray[i], isTaxable: false};
            
                               } else{
            
                                   if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
                                    //    /*//alert("inside Custpos "+Custpos+"!="+UserPos)*/
                                       let amountCalculateForPOS = chargeTaxableB15Discount && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB15Discount : 0
                                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB15Discount  + (amountCalculateForPOS * 2)};
                                       chargesArray[i] = {...chargesArray[i], isTaxable: true};
            
                                   } else {
                                    //    /*//alert("inside Custpos "+Custpos+"=="+UserPos)*/
                                       let amountCalculate = chargeTaxableB15Discount && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB15Discount : 0
                                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeAmountB: (parseFloatValue(chargeTaxableB15Discount)  + parseFloatValue(amountCalculate))};
                                       chargesArray[i] = {...chargesArray[i], isTaxable: true};
                                   }
            
                               }
                           }
            
                           let xRateCustomer = charges && charges.xRateCustomer ? charges.xRateCustomer : 0;
                            taxableB = charges && charges.taxableB ? charges.taxableB : 0;
                           let taxableC = charges && charges.taxableC ? charges.taxableC : 0;
                           let chargeTaxableC = charges && charges.chargeTaxableC ? charges.chargeTaxableC : 0;
                           let chargeTaxableC15Discount = charges && charges.chargeTaxableC15Discount ? charges.chargeTaxableC15Discount : 0;
                           let taxableC15Discount = charges && charges.taxableC15Discount ? charges.taxableC15Discount : 0;
            
                           if(chargeRate !== "" && chargeQty !== "" && xRateCustomer !== "" ){
                               taxableC = taxableB * xRateCustomer
                               chargeTaxableC = taxableB * xRateCustomer
                           }
                           chargesArray[i] = {...chargesArray[i], isTaxable: taxable};
                           chargesArray[i] = {...chargesArray[i], taxableC: taxableC15Discount};
                           chargesArray[i] = {...chargesArray[i], chargeTaxableC: chargeTaxableC15Discount};
                           chargesArray[i] = {...chargesArray[i], xRateCustomer: xRateCustomer};
                           if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" || CustGstType === "U-UIN" )
                           {
                               //if (taxable !== "" && (taxable === false)) {
                                   chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC15Discount};
                                   chargesArray[i] = {...chargesArray[i], isTaxable: false};
                               // } else if (taxable !== "" && taxable === "Taxable" || taxable === true) {
                               //
                               //     if (Custpos !== "" && UserPos !== "" && UserPos === Custpos) {
                               //         let amountCalculateForPOS = chargeTaxableC && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC : 0
                               //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculateForPOS * 2)};
                               //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                               //
                               //     } else {
                               //
                               //         let amountCalculate = chargeTaxableC && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC : 0
                               //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                               //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                               //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                               //         chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculate)};
                               //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                               //     }
                               // }
            
                           }else
                           {
            
                               if (taxable !== "" && taxable === false) {
                                   chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                                   chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC15Discount};
                                   chargesArray[i] = {...chargesArray[i], isTaxable: false};
            
                               } else{
            
                                   if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
            
                                       let amountCalculateForPOS = chargeTaxableC15Discount && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC15Discount : 0
                                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC15Discount  + (amountCalculateForPOS * 2)};
                                       chargesArray[i] = {...chargesArray[i], isTaxable: true};
            
                                   } else {
                                       let amountCalculate = chargeTaxableC15Discount && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC15Discount : 0
                                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                                       chargesArray[i] = {...chargesArray[i], chargeAmountC: (parseFloatValue(chargeTaxableC15Discount)  + parseFloatValue(amountCalculate))};
                                       chargesArray[i] = {...chargesArray[i], isTaxable: true};
                                   }
            
                               }
                           }
            
                       })
            
                       let saleValue = {};
                       let totalIgstAmountB = 0 ;
                       let totalIgstAmountC = 0 ;
                       let totalSgstAmountB = 0 ;
                       let totalSgstAmountC = 0 ;
                       let totalCgstAmountB = 0 ;
                       let totalCgstAmountC = 0 ;
                       let totalChargeTaxableB = 0;
                       let totalChargeTaxableC = 0;
                       let amountB = 0;
                       let amountC = 0;
            
                       chargesArray.map((item,i)=>{
                           totalIgstAmountB = parseFloat(totalIgstAmountB)  + parseFloat(item.chargeIgstAmountB !== undefined && item.chargeIgstAmountB !== "" && item.chargeIgstAmountB !== null ? item.chargeIgstAmountB : 0 ) ;
                           totalIgstAmountC = parseFloat(totalIgstAmountC)  + parseFloat( item.chargeIgstAmountC !== undefined && item.chargeIgstAmountC !== "" && item.chargeIgstAmountC !== null ? item.chargeIgstAmountC : 0) ;
                           totalSgstAmountB = parseFloat(totalSgstAmountB)  + parseFloat( item.chargeSgstAmountB !== undefined && item.chargeSgstAmountB !== "" && item.chargeSgstAmountB !== null ? item.chargeSgstAmountB : 0) ;
                           totalSgstAmountC = parseFloat(totalSgstAmountC)  + parseFloat( item.chargeSgstAmountC !== undefined && item.chargeSgstAmountC !== "" && item.chargeSgstAmountC !== null ? item.chargeSgstAmountC : 0) ;
                           totalCgstAmountB = parseFloat(totalCgstAmountB)  + parseFloat( item.chargeCgstAmountB !== undefined && item.chargeCgstAmountB !== "" && item.chargeCgstAmountB !== null ? item.chargeCgstAmountB : 0) ;
                           totalCgstAmountC = parseFloat(totalCgstAmountC)  + parseFloat( item.chargeCgstAmountC !== undefined && item.chargeCgstAmountC !== "" && item.chargeCgstAmountC !== null ? item.chargeCgstAmountC : 0) ;
                           totalChargeTaxableB = parseFloat(totalChargeTaxableB)  + parseFloat( item.chargeTaxableB !== undefined && item.chargeTaxableB !== "" && item.chargeTaxableB !== null ? item.chargeTaxableB : 0) ;
                           totalChargeTaxableC = parseFloat(totalChargeTaxableC)  + parseFloat( item.chargeTaxableC !== undefined && item.chargeTaxableC !== "" && item.chargeTaxableC !== null ? item.chargeTaxableC : 0) ;
                           amountB =parseFloat(amountB)  + parseFloat( item.chargeAmountB !== undefined && item.chargeAmountB !== "" && item.chargeAmountB !== null ? item.chargeAmountB : 0) ;
                           amountC =parseFloat(amountC)  + parseFloat( item.chargeAmountC !== undefined && item.chargeAmountC !== "" && item.chargeAmountC !== null ? item.chargeAmountC : 0) ;
                       })
            
                       saleValue.charges = chargesArray;
                       saleValue.totalIgstAmountB = totalIgstAmountB;
                       saleValue.totalIgstAmountC = totalIgstAmountC;
                       saleValue.totalSgstAmountB = totalSgstAmountB;
                       saleValue.totalSgstAmountC = totalSgstAmountC;
                       saleValue.totalCgstAmountB = totalCgstAmountB;
                       saleValue.totalCgstAmountC = totalCgstAmountC;
                       saleValue.totalChargeTaxableB = totalChargeTaxableB;
                       saleValue.totalChargeTaxableC = totalChargeTaxableC;
                       saleValue.amountB = amountB;
                       saleValue.amountC = amountC;
            
            
                       // console.log("ChargesCalculatedArray",JSON.stringify(saleValue))
                       return saleValue
            
                   }
            
            
            
                } catch (e) {
                    return ""
                }
            }
            export const calculateChargesAccordingtoStructureIf30DaysAndDiscount =  (chargesArray, CustGstType,Custpos,UserPos,isCustomerBelongsToEuropeanCountry) =>{
// //alert('yha aao5')
// console.log('chargesArraychargesArraychargesArray',JSON.stringify(chargesArray))
                    try {
                
                
                        const chargesArrayForMap = chargesArray;
                       if(Array.isArray(chargesArray) && chargesArray.length >= 1 ) {
                
                           let chargesArray = [];
                
                           chargesArrayForMap && chargesArrayForMap.map((charges,i)=>{
                
                
                               let taxableB30Discount = charges && charges.taxableB30Discount ? charges.taxableB30Discount : 0 ;
                               let discountB = charges && charges.discountB ? charges.discountB : 0 ;
                               let discountB15 = charges && charges.discountB15 ? charges.discountB15 : 0 ;
                               let discountB30 = charges && charges.discountB30 ? charges.discountB30 : 0 ;
                               let discountC = charges && charges.discountC ? charges.discountC : 0 ;
                               let discountC15 = charges && charges.discountC15 ? charges.discountC15 : 0 ;
                               let discountC30 = charges && charges.discountC30 ? charges.discountC30 : 0 ;
                               let id = charges && charges._id ? charges._id : "" ;
                               let chargeFor = charges && charges.chargeFor ? charges.chargeFor : "" ;
                               let chargeAccording = charges && charges.chargeAccording ? charges.chargeAccording : "" ;
                               let chargeName = charges && charges.chargeName ? charges.chargeName : "" ;
                               let chargeCode = charges && charges.chargeCode ? charges.chargeCode : "" ;
                               let chargeHsnCode = charges && charges.chargeHsnCode ? charges.chargeHsnCode : "" ;
                               let chargeType = charges && charges.chargeType ? charges.chargeType : "" ;
                               let currency = charges && charges.currency ? charges.currency : "" ;
                               let altName = charges && charges.altName ? charges.altName : "" ;
                               let chargeRate = charges && charges.chargeRate30 ? charges.chargeRate30 : 0 ;
                               let chargeQty = charges && charges.chargeQty ? charges.chargeQty : 0;
                               let xRateBase = charges && charges.xRateBase ? charges.xRateBase : 0;
                               let rateOfTaxFromCharge = charges && charges.chargeIgstRate ? charges.chargeIgstRate : 0;
                               let chargeTaxableB30Discount = charges && charges.chargeTaxableB30Discount ? charges.chargeTaxableB30Discount : 0;
                               let chargeIgstAmountB = ""
                               let taxableB = ""
                               let chargeTaxableB = ""
                               if(chargeRate !== "" && chargeQty !== "" && xRateBase !== "" ){
                                   taxableB = chargeRate * chargeQty * xRateBase
                                   chargeTaxableB = chargeRate * chargeQty * xRateBase
                               }
                               chargesArray[i] = {...chargesArray[i], chargeRate: chargeRate};
                               chargesArray[i] = {...chargesArray[i], altName: altName};
                               chargesArray[i] = {...chargesArray[i], currency: currency};
                               chargesArray[i] = {...chargesArray[i], chargeType: chargeType};
                               chargesArray[i] = {...chargesArray[i], chargeHsnCode: chargeHsnCode};
                               chargesArray[i] = {...chargesArray[i], chargeCode: chargeCode};
                               chargesArray[i] = {...chargesArray[i], chargeFor: chargeFor};
                               chargesArray[i] = {...chargesArray[i], chargeAccording: chargeAccording};
                               chargesArray[i] = {...chargesArray[i], chargeName: chargeName};
                               chargesArray[i] = {...chargesArray[i], id: id};
                               chargesArray[i] = {...chargesArray[i], chargeQty: discountB};
                               chargesArray[i] = {...chargesArray[i], chargeQty: discountC};
                               chargesArray[i] = {...chargesArray[i], chargeQty: chargeQty};
                               chargesArray[i] = {...chargesArray[i], xRateBase: xRateBase};
                               chargesArray[i] = {...chargesArray[i], taxableB: taxableB30Discount};
                               chargesArray[i] = {...chargesArray[i], chargeTaxableB: chargeTaxableB30Discount};
                               chargesArray[i] = {...chargesArray[i], rateOfTaxFromCharge: rateOfTaxFromCharge};
                               let taxable = charges && charges.isTaxable  ? charges.isTaxable : false;
                               chargeTaxableB = charges && charges.chargeTaxableB  ? charges.chargeTaxableB : 0;
                               if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" ||CustGstType === "U-UIN"){
                                //    /*//alert("inside CustGstType === O-OVERSEAS"+CustGstType)*/
                                   //if (taxable !== "" && (taxable === false)) {
                                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB30Discount};
                                       chargesArray[i] = {...chargesArray[i], isTaxable: false};
                                   // } else if (taxable !== "" && taxable === "Taxable" || taxable === true) {
                                   //
                                   //     if (Custpos !== "" && UserPos !== "" && UserPos === Custpos) {
                                   //
                                   //         let amountCalculateForPOS = chargeTaxableB && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB : 0
                                   //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculateForPOS * 2)};
                                   //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                                   //
                                   //     } else {
                                   //
                                   //         let amountCalculate = chargeTaxableB && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB : 0
                                   //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB  + (amountCalculate)};
                                   //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                                   //     }
                                   // }
                
                               }
                               else {
                
                                   if (taxable !== "" && taxable === false) {
                                    //    /*//alert("inside taxable "+taxable)*/
                                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB30Discount};
                                       chargesArray[i] = {...chargesArray[i], isTaxable: false};
                
                                   } else{
                
                                       if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
                                        //    /*//alert("inside Custpos "+Custpos+"!="+UserPos)*/
                                           let amountCalculateForPOS = chargeTaxableB30Discount && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableB30Discount : 0
                                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountB: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: ""};
                                           chargesArray[i] = {...chargesArray[i], chargeAmountB: chargeTaxableB30Discount  + (amountCalculateForPOS * 2)};
                                           chargesArray[i] = {...chargesArray[i], isTaxable: true};
                
                                       } else {
                                        //    /*//alert("inside Custpos "+Custpos+"=="+UserPos)*/
                                           let amountCalculate = chargeTaxableB30Discount && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableB30Discount : 0
                                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountB: ""};
                                           chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountB:""};
                                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountB: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeAmountB: (parseFloatValue(chargeTaxableB30Discount)  + parseFloatValue(amountCalculate))};
                                           chargesArray[i] = {...chargesArray[i], isTaxable: true};
                                       }
                
                                   }
                               }
                
                               let xRateCustomer = charges && charges.xRateCustomer ? charges.xRateCustomer : 0;
                                taxableB = charges && charges.taxableB ? charges.taxableB : 0;
                               let taxableC = charges && charges.taxableC ? charges.taxableC : 0;
                               let chargeTaxableC = charges && charges.chargeTaxableC ? charges.chargeTaxableC : 0;
                               let chargeTaxableC30Discount = charges && charges.chargeTaxableC30Discount ? charges.chargeTaxableC30Discount : 0;
                               let taxableC30Discount = charges && charges.taxableC30Discount ? charges.taxableC30Discount : 0;
                
                               if(chargeRate !== "" && chargeQty !== "" && xRateCustomer !== "" ){
                                   taxableC = taxableB * xRateCustomer
                                   chargeTaxableC = taxableB * xRateCustomer
                               }
                               chargesArray[i] = {...chargesArray[i], isTaxable: taxable};
                               chargesArray[i] = {...chargesArray[i], taxableC: taxableC30Discount};
                               chargesArray[i] = {...chargesArray[i], chargeTaxableC: chargeTaxableC30Discount};
                               chargesArray[i] = {...chargesArray[i], xRateCustomer: xRateCustomer};
                               if (CustGstType === "O-OVERSEAS" || CustGstType === "S-SEZ" || CustGstType === "U-UIN" )
                               {
                                   //if (taxable !== "" && (taxable === false)) {
                                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC30Discount};
                                       chargesArray[i] = {...chargesArray[i], isTaxable: false};
                                   // } else if (taxable !== "" && taxable === "Taxable" || taxable === true) {
                                   //
                                   //     if (Custpos !== "" && UserPos !== "" && UserPos === Custpos) {
                                   //         let amountCalculateForPOS = chargeTaxableC && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC : 0
                                   //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculateForPOS * 2)};
                                   //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                                   //
                                   //     } else {
                                   //
                                   //         let amountCalculate = chargeTaxableC && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC : 0
                                   //         chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                                   //         chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC  + (amountCalculate)};
                                   //         chargesArray[i] = {...chargesArray[i], isTaxable: true};
                                   //     }
                                   // }
                
                               }else
                               {
                
                                   if (taxable !== "" && taxable === false) {
                                       chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                                       chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC30Discount};
                                       chargesArray[i] = {...chargesArray[i], isTaxable: false};
                
                                   } else{
                
                                       if (Custpos !== "" && UserPos !== "" && UserPos === Custpos && 2==3) {
                
                                           let amountCalculateForPOS = chargeTaxableC30Discount && rateOfTaxFromCharge ? ((rateOfTaxFromCharge / 2) / 100) * chargeTaxableC30Discount : 0
                                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeSgstRate: rateOfTaxFromCharge ? (rateOfTaxFromCharge / 2) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountC: amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: ""};
                                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: ""};
                                           chargesArray[i] = {...chargesArray[i], chargeAmountC: chargeTaxableC30Discount  + (amountCalculateForPOS * 2)};
                                           chargesArray[i] = {...chargesArray[i], isTaxable: true};
                
                                       } else {
                                           let amountCalculate = chargeTaxableC30Discount && rateOfTaxFromCharge ? (rateOfTaxFromCharge / 100) * chargeTaxableC30Discount : 0
                                           chargesArray[i] = {...chargesArray[i], chargeCgstRate: ""};
                                           chargesArray[i] = {...chargesArray[i], chargeCgstAmountC: ""};
                                           chargesArray[i] = {...chargesArray[i], chargeSgstRate:""};
                                           chargesArray[i] = {...chargesArray[i], chargeSgstAmountC:""};
                                           chargesArray[i] = {...chargesArray[i], chargeIgstRate: rateOfTaxFromCharge ? rateOfTaxFromCharge : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeIgstAmountC: amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""};
                                           chargesArray[i] = {...chargesArray[i], chargeAmountC: (parseFloatValue(chargeTaxableC30Discount)  + parseFloatValue(amountCalculate))};
                                           chargesArray[i] = {...chargesArray[i], isTaxable: true};
                                       }
                
                                   }
                               }
                
                           })
                
                           let saleValue = {};
                           let totalIgstAmountB = 0 ;
                           let totalIgstAmountC = 0 ;
                           let totalSgstAmountB = 0 ;
                           let totalSgstAmountC = 0 ;
                           let totalCgstAmountB = 0 ;
                           let totalCgstAmountC = 0 ;
                           let totalChargeTaxableB = 0;
                           let totalChargeTaxableC = 0;
                           let amountB = 0;
                           let amountC = 0;
                
                           chargesArray.map((item,i)=>{
                               totalIgstAmountB = parseFloat(totalIgstAmountB)  + parseFloat(item.chargeIgstAmountB !== undefined && item.chargeIgstAmountB !== "" && item.chargeIgstAmountB !== null ? item.chargeIgstAmountB : 0 ) ;
                               totalIgstAmountC = parseFloat(totalIgstAmountC)  + parseFloat( item.chargeIgstAmountC !== undefined && item.chargeIgstAmountC !== "" && item.chargeIgstAmountC !== null ? item.chargeIgstAmountC : 0) ;
                               totalSgstAmountB = parseFloat(totalSgstAmountB)  + parseFloat( item.chargeSgstAmountB !== undefined && item.chargeSgstAmountB !== "" && item.chargeSgstAmountB !== null ? item.chargeSgstAmountB : 0) ;
                               totalSgstAmountC = parseFloat(totalSgstAmountC)  + parseFloat( item.chargeSgstAmountC !== undefined && item.chargeSgstAmountC !== "" && item.chargeSgstAmountC !== null ? item.chargeSgstAmountC : 0) ;
                               totalCgstAmountB = parseFloat(totalCgstAmountB)  + parseFloat( item.chargeCgstAmountB !== undefined && item.chargeCgstAmountB !== "" && item.chargeCgstAmountB !== null ? item.chargeCgstAmountB : 0) ;
                               totalCgstAmountC = parseFloat(totalCgstAmountC)  + parseFloat( item.chargeCgstAmountC !== undefined && item.chargeCgstAmountC !== "" && item.chargeCgstAmountC !== null ? item.chargeCgstAmountC : 0) ;
                               totalChargeTaxableB = parseFloat(totalChargeTaxableB)  + parseFloat( item.chargeTaxableB !== undefined && item.chargeTaxableB !== "" && item.chargeTaxableB !== null ? item.chargeTaxableB : 0) ;
                               totalChargeTaxableC = parseFloat(totalChargeTaxableC)  + parseFloat( item.chargeTaxableC !== undefined && item.chargeTaxableC !== "" && item.chargeTaxableC !== null ? item.chargeTaxableC : 0) ;
                               amountB =parseFloat(amountB)  + parseFloat( item.chargeAmountB !== undefined && item.chargeAmountB !== "" && item.chargeAmountB !== null ? item.chargeAmountB : 0) ;
                               amountC =parseFloat(amountC)  + parseFloat( item.chargeAmountC !== undefined && item.chargeAmountC !== "" && item.chargeAmountC !== null ? item.chargeAmountC : 0) ;
                           })
                
                           saleValue.charges = chargesArray;
                           saleValue.totalIgstAmountB = totalIgstAmountB;
                           saleValue.totalIgstAmountC = totalIgstAmountC;
                           saleValue.totalSgstAmountB = totalSgstAmountB;
                           saleValue.totalSgstAmountC = totalSgstAmountC;
                           saleValue.totalCgstAmountB = totalCgstAmountB;
                           saleValue.totalCgstAmountC = totalCgstAmountC;
                           saleValue.totalChargeTaxableB = totalChargeTaxableB;
                           saleValue.totalChargeTaxableC = totalChargeTaxableC;
                           saleValue.amountB = amountB;
                           saleValue.amountC = amountC;
                
                
                           // console.log("ChargesCalculatedArray",JSON.stringify(saleValue))
                           return saleValue
                
                       }
                
                
                
                    } catch (e) {
                        return ""
                    }
                }
export const concatExtraStringWithCheck = function (mainString,stringToCheck,StringToAttach) {
    try {
        if(mainString.includes(stringToCheck) || mainString.includes(stringToCheck)){
            return mainString
        }else{
            mainString=mainString+" "+StringToAttach
            return mainString
        }
    } catch (e) {
        return mainString
    }
}