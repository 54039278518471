import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import {toast} from "react-toastify";
import RestClient from "../../../util/RestClient";
import {CAREER_DATA_API, MEMBERSHIP_DATA_SEND_API, TRADE_DATA_API} from "../../../constants";
import MailSendSuccess from "./MailSendSuccess";
import MailSendSuccessTrade from "./MailSendSuccessTrade";

class Support extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();
        this.state = {
            errors :{},
            mailSucessMailPopup:false,
            nameOfCompany:"",
            monthlyRevenue:"",
            annualTurnover:"",
            name:"",
            email:"",
            phone:"",
            coverLetter:"",
            ServiceName : ""
        }
    }
    componentDidMount() {
        this.scrollToElement();
        let ServiceName = window.location.href.split("/")[window.location.href.split("/").length - 1];
        this.setState({
            ServiceName : ServiceName
        })
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    validateForm() {
        let formIsValid = true;
        let errors = {}
        if (!this.state.nameOfCompany) {
            formIsValid = false;
            errors["nameOfCompany"] = "*  Name of the company is required";
        }

        if (!this.state.name) {
            formIsValid = false;
            errors["name"] = "*  Name is required";
        }
        if (!this.state.email) {
            formIsValid = false;
            errors["email"] = "* Email is required";
        }

        if (!this.state.phone) {
            formIsValid = false;
            errors["phone"] = "* Phone is required";
        }

        this.setState({
            errors: errors,
        });
        return formIsValid;
    }
    sendMailToHr = (e) =>{

        e.preventDefault();
        if (this.validateForm()) {
            const body = {
                emailTo : ["shashank@zipaworld.com"],
                // emailTo : ["kanchan@aaa2innovate.com"],
                emailSubject : "For MemberShip Plan",
                coverLetter : this.state.coverLetter,
                name:this.state.name,
                email:this.state.email,
                phone:this.state.phone,
                nameOfCompany : this.state.nameOfCompany,
                package : this.state.ServiceName,
                emailBody:"",
            };
            this.CareerApiCall(body).then(response=>{
                if(response.success){
                    toast.success("Mail Sanded Successfully", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        mailSucessMailPopup:true,
                    })
                }
            })
        }

    }

    CareerApiCall = async (body)=> {
        return await RestClient({
            url: MEMBERSHIP_DATA_SEND_API,
            method: "POST",
            data: body
        })
    };
    handleChange = e => {
        this.setState({[e.target.name]: e.target.value},()=>{
        });
    };
    render() {
        const {errors } = this.state;
        return (
            <>
            <AppLayout>
                <MailSendSuccessTrade
                    mailPopupSucess={this.state.mailSucessMailPopup}
                />
                <div className="services_content1 trade_page" ref={this.testRef}>
                    <div className="container">
                        {/*<div className="row">*/}
                        {/*    <div className="col-lg-12">*/}
                        {/*        <h1>Trade <span>Finance</span></h1>*/}
                        {/*        <p>Zipaworld is coming up with the digital portal for ocean freight shipping as an upshot of the expertise of its personnel in ocean freight movement and long-term association with shipping lines, port handlers, NVOCC, and various trade bodies. The ocean/sea freight web platform offers the most attractive pricing and deals for complete FCL, LCL, and consolidation services.</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <h2 style={{color:'#ff0600'}}>Submit Contact Details</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Package </label>
                                            <input className="input100" id="name" type="text" name="name" placeholder="Name" value={this.state.ServiceName}
                                                   style={{cursor: "not-allowed",
                                                       background: '#f3f3f3'}}
                                                   readOnly/>
                                            <div className="red_text1">{errors && errors.package}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Name <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup></label>
                                            <input className="input100" id="name" type="text" name="name" placeholder="Name" onChange={this.handleChange}/>
                                            {/*<label className="label-job" htmlFor="name">*/}
                                            {/*    /!*<i className="fas fa-user"></i>*!/*/}
                                            {/*    <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>*/}
                                            {/*</label>*/}
                                            <div className="red_text1">{errors && errors.name}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Email <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup></label>
                                            <input className="input100" id="email" type="text" name="email" placeholder="Email" onChange={this.handleChange}/>
                                            {/*<label className="label-job" htmlFor="email">*/}
                                            {/*    /!*<i className="fas fa-envelope"></i>*!/*/}
                                            {/*    <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>*/}
                                            {/*</label>*/}
                                            <div className="red_text1">{errors && errors.email}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Phone No. <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup></label>
                                            <input className="input100" id="phone" type="text" name="phone" placeholder="Phone" onChange={this.handleChange}/>
                                            {/*<label className="label-job" htmlFor="phone">*/}
                                            {/*    /!*<i className="fas fa-phone-alt"></i>*!/*/}
                                            {/*    <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>*/}
                                            {/*</label>*/}
                                            <div className="red_text1">{errors && errors.phone}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label>Name Of the Company <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup></label>
                                            <input className="input100" id="email" type="text" name="nameOfCompany" placeholder="Name Of the Company" onChange={this.handleChange}/>
                                            {/*<label className="label-job" htmlFor="email">*/}
                                            {/*    /!*<i className="fas fa-envelope"></i>*!/*/}
                                            {/*    <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>*/}
                                            {/*</label>*/}
                                            <div className="red_text1">{errors && errors.nameOfCompany}</div>
                                        </div>
                                    </div>


                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label>Message</label>
                                            <textarea className="input100" name="coverLetter" placeholder="Message (Optional)" rows="6" onChange={this.handleChange}></textarea>
                                            {/*<label className="label-job" htmlFor="postName">*/}
                                            {/*    /!*<i className="far fa-edit"></i>*!/*/}
                                            {/*    <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>*/}
                                            {/*</label>*/}
                                            <div className="red_text1">{errors && errors.coverLetter}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <button type="button" onClick={this.sendMailToHr} className="btn resume_btn" style={{background:'#ff0600'}}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact-info-box">
                                    <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.44769095364968536-1681987306592.png"
                                         className="left-right" alt="Email Icon"/>
                                    <strong>Email Us</strong>
                                    <p><a href="mailto:info@zipaworld.com">info@zipaworld.com</a></p>
                                </div>
                                <div className="contact-info-box"><img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.20005248025940614-1681987279142.png"
                                                                       className="left-right" alt="Call Icon"/><strong>Call Us</strong>
                                    <p>Call us: 0120-6916910, <br/>022-62360945</p>

                                </div>
                                <div className="contact-info-box">
                                    <img src="../../../assets/img/address.png" className="left-right" alt="Office Icon"/>
                                    <strong>Corporate Office</strong>
                                    <p>B 29, 3rd floor Sector 1, <br />Noida-201301, Uttar Pradesh
                                        <br/>Contact No: 0120-6916910</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterHome/>
            </AppLayout>
            </>

        );
    }


}



export default Support;
