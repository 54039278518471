import React, { Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ModalFooter from "reactstrap/es/ModalFooter";
import RestClient from "../../../util/RestClient";
import {
    BOOKING_MANAGER_MASTER_GET_DATA,
    BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
    BOOKING_MANAGER_MASTER_GET_DATA_COURIER,
    BOOKING_MANAGER_MASTER_GET_DATA_AIR,
    BOOKING_MANAGER_MASTER_GET_DATA_OCEAN,
    DRIVER_OTP_CHECK,
    PICK_UP_MAIL_MSG,
    PICK_UP_MAIL_MSG_ROAD,
    PICK_UP_MAIL_MSG_COURIER,
    SEND_CARGO_MESSAGE_MAIL,
    SEND_CARGO_MESSAGE_MAIL_ROAD,
    SEND_CARGO_MESSAGE_MAIL_COURIER,
    USER_LOGIN_SEND_MOBILE,
    SEND_CARGO_MESSAGE_MAIL_AIR,
    SEND_CARGO_MESSAGE_MAIL_OCEAN,
    PICK_UP_MAIL_MSG_AIR,
    PICK_UP_MAIL_MSG_OCEAN
} from "../../../constants";
import {toast} from "react-toastify";
import {PAGE_PATHS} from "../../../util/PageRoute";
class DriverDetailsDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpPopup:false,
            otpPopupNext:false,
            buttonName:"Get Otp",
            driverDetails : false,
            vehicleDetails : false,
            width:0,
        };
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        let quoteId = window.location.href.split("/")[ window.location.href.split("/").length - 1];
        let quoteFor = window.location.href.split("/")[ window.location.href.split("/").length - 2];
        this.setState({
            bookingId : quoteId,
            quoteFor:quoteFor,

        },()=>{
            this.getbookingData({ id: quoteId , quoteFor:quoteFor }).then((idResponce) => {
                if (idResponce && idResponce.success) {
                    let quoteData = idResponce && idResponce.result ? idResponce.result : {};
                    //let originName =  quoteData && quoteData.originAirport && quoteData.originAirport.name ? quoteData.originAirport.name : "";
                    let originDoor =  quoteData && quoteData.originDoor && quoteData.originDoor.pincode ? quoteData.originDoor.pincode : "";
                    let originName =  quoteData && quoteData.originDoor && quoteData.originDoor.address ? quoteData.originDoor.address : "";
                    //let destinationName =  quoteData && quoteData.destinationAirport && quoteData.destinationAirport.name ? quoteData.destinationAirport.name : "";
                    let destinationDoor =  quoteData && quoteData.destinationDoor && quoteData.destinationDoor.pincode ? quoteData.destinationDoor.pincode : "";
                    //let destinationName =  quoteData && quoteData.destinationDoor && quoteData.destinationDoor.address ? quoteData.destinationDoor.address : "";
                    let destinationName =  quoteData && quoteData.originAirport && quoteData.originAirport.name ? quoteData.originAirport.name : "";
                    let totalPieces =  quoteData && quoteData.quoteId && quoteData.quoteId.totalPieces? quoteData.quoteId.totalPieces : "";
                    let bookingNo =  quoteData && quoteData.quoteId && quoteData.quoteId.jobNo ? quoteData.quoteId.jobNo : "";
                    let queryFor =  quoteData && quoteData.queryFor ? quoteData.queryFor : "";
                    let grossWeight =  quoteData && quoteData.grossWeight ? quoteData.grossWeight : "";
                    let volumeWeight =  quoteData && quoteData.volumeWeight ? quoteData.volumeWeight : "";
                    let activityType =  quoteData && quoteData.activityType ? quoteData.activityType : "";
                    let jobNo =  quoteData && quoteData.jobNo ? quoteData.jobNo : "";
                    let bookingId = quoteData && quoteData.quoteId && quoteData.quoteId.bookingId ? quoteData.quoteId.bookingId : "";
                    let quoteId = quoteData && quoteData._id ? quoteData._id : "";
                    let blNo = quoteData && quoteData.blNo ? quoteData.blNo : "";
                    let customerId = quoteData && quoteData.customerId ? quoteData.customerId : "";
                    let isGoodsPickedup = quoteData && quoteData.isGoodsPickedup ? quoteData.isGoodsPickedup : "";

                    this.setState({
                        originName : originName,
                        originDoor : originDoor,
                        destinationName:destinationName,
                        destinationDoor:destinationDoor,
                        totalPieces:totalPieces,
                        grossWeight:grossWeight,
                        volumeWeight:volumeWeight,
                        jobNo:jobNo,
                        bookingId:bookingId,
                        quoteId:quoteId,
                        bookingNo:bookingNo,
                        activityType:activityType,
                        queryFor:queryFor,
                        blNo:blNo,
                        isGoodsPickedup:isGoodsPickedup,
                        customerId:customerId,
                    })
                }else{

                }
            })
        })



    }
    getbookingData = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Air"?BOOKING_MANAGER_MASTER_GET_DATA_AIR : this.state.quoteFor === "Ocean"?BOOKING_MANAGER_MASTER_GET_DATA_OCEAN:this.state.quoteFor === "Courier"?BOOKING_MANAGER_MASTER_GET_DATA_COURIER : BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
            method: "POST",
            data: dataForServer,
        });
    };

    cargoSendMail = async (dataForServer) =>{
        return await RestClient({
            url: this.state.quoteFor === "Air"?SEND_CARGO_MESSAGE_MAIL_AIR : this.state.quoteFor === "Ocean"?SEND_CARGO_MESSAGE_MAIL_OCEAN:this.state.quoteFor === "Courier"?SEND_CARGO_MESSAGE_MAIL_COURIER : SEND_CARGO_MESSAGE_MAIL_ROAD,
            method : "POST",
            data : dataForServer
        })
    }
    cargoPickUpSendMail = async (dataForServer) =>{
        return await RestClient({
            url: this.state.quoteFor === "Air"?PICK_UP_MAIL_MSG_AIR : this.state.quoteFor === "Ocean"?PICK_UP_MAIL_MSG_OCEAN:this.state.quoteFor === "Courier"?PICK_UP_MAIL_MSG_COURIER : PICK_UP_MAIL_MSG_ROAD,
            method : "POST",
            data : dataForServer
        })
    }
    toggleLarges = () =>{
        this.setState({
            otpPopup:false,
        })
    }
    toggleLarge = () =>{
        this.setState({
            otpPopupNext:false,
        })
    }
    openPop = () =>{
        if(this.state.isGoodsPickedup){
            this.goodsHandedFuntionlity()
        }else{
            this.cargoSendMail({ bookingId: this.state.bookingId , quoteFor : this.state.quoteFor }).then((responce) => {
                if(responce && responce.success){
                    this.setState({
                        otpPopup:true,
                    })
                }
            })
        }


    }
    sendOTPForLogin =  (e) =>{
        e.preventDefault()
        let obj = {}
        if(this.state.customerId === "614857a761e12f13179a9e6c"){
            obj ={
                countryCode : '+91',
                mobileNo : "9910880666",
            }
        }else if(this.state.customerId === "627e07b967dc79384b1c6410"){
            obj ={
                countryCode : '+91',
                mobileNo : "9015419908",
            }
        }
        else if(this.state.customerId === "632bedb3e7f2c271a8091ed8"){
            obj ={
                countryCode : '+91',
                mobileNo : "9582216229",
            }
        }
        else if(this.state.customerId === "610e2f73b4591d0a9a9ab5f2"){
            obj ={
                countryCode : '+91',
                mobileNo : "8882572566",
            }
        }
        else if(this.state.customerId === "627620d9862c09376baea263"){
            obj ={
                countryCode : '+91',
                mobileNo : "9953239737",
            }
        }
        else{
            obj ={
                countryCode : '+91',
                mobileNo : this.state.mobileNo,
            }
        }

        this.apiToSendOtp(obj).then(res=>{
            if(res.success){
                this.setState({
                    sessionIds : res.result.Details,
                    buttonName : "Resend Otp",
                    sendOtpButtonClicked : true
                })
            }
        })


    }
    apiToSendOtp = async (obj) =>{
        return await RestClient({
            url: USER_LOGIN_SEND_MOBILE,
            method: "POST",
            data: obj
        })
    }
    verifyOTP = (e) =>{
        e.preventDefault()
        let obj = {
            otp : this.state.otp,
            sessionId : this.state.sessionIds,
        }
        this.apiToVerifyOtp(obj).then(res=>{
            if(res.success){
                this.setState({otpPopup:false,otpPopupNext:true,})
            }else{
                toast.error("OTP does not match", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    apiToVerifyOtp = async (obj) =>{
        return await RestClient({
            url: DRIVER_OTP_CHECK,
            method: "POST",
            data: obj
        })
    }
    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    goodHandover = () =>{
        this.cargoPickUpSendMail({ bookingId: this.state.bookingId,prsId:this.state.prsId  ,  quoteFor: this.state.quoteFor}).then((responce) => {
            if(responce && responce.success){
                this.goodsHandedFuntionlity()
            }else{
                this.goodsHandedFuntionlity()
            }
        })
    }
    goodNotHandover = () =>{
        this.setState({otpPopupNext:false,})
    }
    goodsHandedFuntionlity = () =>{
        if(this.state.queryFor === "Courier" || this.state.queryFor === "Road"){
            this.props.history.push(PAGE_PATHS.EDOCKET_PDF_VIEW + "/" + this.state.queryFor + "/"+ this.state.bookingId)
        }
        else{
            if (this.state.blNo === "" || this.state.blNo === undefined) {
                if (this.state.queryFor === "Air") {
                    toast.error("Awaiting For AWB Number", {
                        position: "top-left",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.props.history.push(
                        PAGE_PATHS.VIEW_MAWB_CUSTOMER +
                        "/Air/" +
                        this.state.bookingId
                    );


                } else if (this.state.queryFor === "Ocean") {
                    toast.error("Awaiting For BL Number", {
                        position: "top-left",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.props.history.push(
                        PAGE_PATHS.VIEW_MAWB_CUSTOMER +
                        "/Ocean/" +
                        this.state.bookingId
                    );

                } else {
                    toast.error("Awaiting For Docket Number", {
                        position: "top-left",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.props.history.push(
                        this.props.history.push(PAGE_PATHS.EDOCKET_PDF_VIEW + "/" + this.state.queryFor + "/"+this.state.bookingId)
                    );
                }
            } else {
                if (this.state.queryFor === "Air") {
                    this.props.history.push(
                        PAGE_PATHS.AIR_MAWB_VIEW + "/Air/" + this.state.bookingId
                    );
                } else if (this.state.queryFor === "Ocean") {
                    this.props.history.push(
                        PAGE_PATHS.VIEW_MAWB + "/Ocean/" + this.state.bookingId
                    );
                }
            }
        }
    }
    driverVehicleClose = () =>{
        this.setState({
            driverDetails : false,
            vehicleDetails : false,
        })
    }
    render() {
        return (
            <AppLayout>
                <div className="dashboard-wrapper">
                    <div className="invoiceDetails">
                        <div className="container">
                            <Modal className="housepop-width mawb_popup"
                                   isOpen={this.state.firstPopup}
                                   size="md"
                                   toggle={()=>{this.setState({ firstPopup: false, })}}>
                                <ModalHeader toggle={()=>{this.setState({ firstPopup: false, })}} className="" style={{ padding: "0" }}></ModalHeader>
                                <ModalBody style={{ padding: '0 0 15px' }}>
                                    <div className="">
                                        <div className="pop-text-error">
                                            <h6 style={{ fontWeight: '600' }}>Awaiting For Pickup Confirmation</h6>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter className="justify-content-center">
                                    <button className="btn mawb_popup_btn" style={{ borderRadius: "40px;" }}
                                            onClick={e => {
                                                e.preventDefault()
                                                this.goodsHandedFuntionlity()
                                            }}>
                                        Ok
                                    </button>


                                </ModalFooter>
                            </Modal>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-all mb-2">
                                        <div className="d-flex flex-wrap align-items-stretch justify-content-between mb-3">
                                            <h4>Pick Up Requested Generated</h4>
                                            <div className="d-flex flex-wrap ButtonGrp">
                                                <div className="btn edit" onClick={()=>{
                                                    this.setState({
                                                        driverDetails : true,
                                                        vehicleDetails : false
                                                    })
                                                }}>Driver Details</div>
                                                <div className="btn edit" onClick={()=> {
                                                    this.setState({
                                                        vehicleDetails: true,
                                                        driverDetails : false
                                                    })
                                                }}>Vehicle Details</div>
                                            </div>
                                        </div>
                                        {/*<div className="d-flex flex-wrap align-items-stretch justify-content-between">*/}
                                            <div className="AAA-shipper-consignee-approve-data-8 mb-3">
                                                <div className="shipment_details">
                                                    <h5>Shipment Details</h5>
                                                    <hr/>
                                                    <div className="d-flex flex-wrap">
                                                        {this.state.queryFor === "Air" || this.state.queryFor === "Ocean" ?
                                                            <>
                                                                <div className="pick_shipment">
                                                                    <h5>Pick Up From</h5>
                                                                    <p>{this.state.originName ? this.state.originName : this.state.originDoor}</p>
                                                                </div>
                                                                <div className="pick_shipment">
                                                                    <h5>Drop To</h5>
                                                                    <p>{this.state.destinationName}</p>
                                                                </div>

                                                            </>
                                                            :
                                                            <>
                                                                {this.state.queryFor === "Courier" || this.state.queryFor === "Road"?
                                                                    <>
                                                                        <div className="pick_shipment">
                                                                            <h5>Pick Up</h5>
                                                                            <p>{this.state.originName ? this.state.originName : this.state.originDoor}</p>
                                                                        </div>
                                                                        <div className="pick_shipment">
                                                                            <h5>Drop Off</h5>
                                                                            <p>{this.state.destinationName}</p>
                                                                        </div>

                                                                    </>

                                                                    :""}
                                                            </>
                                                        }

                                                        <div className="pick_shipment">
                                                            <h5>Job No.</h5>
                                                            <p>{this.state.bookingNo}</p>
                                                        </div>
                                                        <div className="pick_shipment">
                                                            <h5>Pcs.</h5>
                                                            <p>{this.state.totalPieces}</p>
                                                        </div>
                                                        <div className="pick_shipment">
                                                            <h5>Gross Weight</h5>
                                                            <p>{this.state.grossWeight}</p>
                                                        </div>
                                                        <div className="pick_shipment">
                                                            <h5>Volume Weight</h5>
                                                            <p>{this.state.volumeWeight}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="AAA-shipper-consignee-approve-data-8 ">
                                                <div className="shipment_details">
                                                    <h5>Pick Up Executive Details</h5>
                                                    <hr/>
                                                    <div className="d-flex flex-wrap align-items-center flex-wrap-mob">
                                                        <div className="driver_imgs">
                                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6072148085231863-1669270428847.png" alt="Saurabh Rana"/>
                                                        </div>
                                                        <div className="d-flex flex-wrap align-items-center drivers_details">
                                                            <div className="pick_shipment">
                                                                <h5>Name</h5>
                                                                <p>SHOBHIT RAM</p>
                                                            </div>
                                                            <div className="pick_shipment">
                                                                <h5>Age</h5>
                                                                <p>46</p>
                                                            </div>
                                                            <div className="pick_shipment">
                                                                <h5>Mobile No.</h5>
                                                                <p>9811226990</p>
                                                            </div>

                                                            <div style={{width:'100%'}}>
                                                                <h5 className="mb-3">ID Proof :</h5>
                                                                <div className="d-flex flex-wrap align-items-center ">
                                                                    <div className="pick_shipment">
                                                                        <h5>Vehicle No.</h5>
                                                                        <p>UP 14 KT 7616</p>
                                                                    </div>
                                                                    <div className="pick_shipment">
                                                                        <h5>Driver License No.</h5>
                                                                        <p>WB01 20030902785</p>
                                                                    </div>
                                                                    {/*<div className="pick_shipment">*/}
                                                                    {/*    <h5>Pick Up Time Slot</h5>*/}
                                                                    {/*    <p>10:00 AM - 12:00 PM</p>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="send_btn">
                                                    <button className="btn edit" onClick={this.openPop}>Next</button>
                                                    {/*<button className="btn edit" onClick={this.goodHandover}>Next</button>*/}
                                                </div>
                                            </div>
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.driverDetails}
                    size={"md"}
                    toggle={this.driverVehicleClose}
                >
                    <>
                        <ModalHeader style={{borderBottom: '1px solid #d7d7d7'}} toggle={this.driverVehicleClose} className="rates_heads">
                            Driver Details
                        </ModalHeader>
                        <ModalBody style={{background: '#fcfcfc'}}>
                                <div className="back-img otp_pickup vehicleDetailsDemo">
                                    <h6><strong>Name</strong> <span className="dot">-</span> <span className="detVehicle">SHOBHIT RAM</span></h6>
                                    <h6><strong>Gender</strong> <span className="dot">-</span> <span className="detVehicle">Male</span></h6>
                                    <h6><strong>DL</strong> <span className="dot">-</span> <span className="detVehicle">WB01 20030902785</span></h6>
                                    <h6><strong>State</strong> <span className="dot">-</span> <span className="detVehicle">West Bengal</span></h6>
                                    <h6><strong>Type Of vehicle</strong> <span className="dot">-</span> <span className="detVehicle">Transport Vehicle-M/HMV (Goods and Passenger)</span></h6>
                                    {/*<h6><strong>DL Issue Date</strong> <span className="dot">-</span> <span className="detVehicle">19-02-2003</span></h6>*/}
                                    <h6><strong>DL Valid From Date</strong> <span className="dot">-</span> <span className="detVehicle">21-02-2012</span></h6>
                                    <h6><strong>DL Expiry Date</strong> <span className="dot">-</span> <span className="detVehicle">29-07-2026</span></h6>
                                    <h6><strong>Dl Status</strong> <span className="dot">-</span> <span className="detVehicle">Active</span></h6>
                                </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center rates-not">
                            <button type="button" className="btn btn-secondary" style={{border:'1px solid #ff0600',background:'#ff0600'}} onClick={(e)=>this.driverVehicleClose(e)}>Close</button>
                        </ModalFooter>
                    </>
                </Modal>
                <Modal className="popupLarge"
                       isOpen={this.state.vehicleDetails}
                       size={this.state.width < 860 ?"md" : "lg"}
                       toggle={this.driverVehicleClose}
                >
                    <>
                        <ModalHeader style={{borderBottom: '1px solid #d7d7d7'}} toggle={this.driverVehicleClose} className="rates_heads">
                            Vehicle Details
                        </ModalHeader>
                        <ModalBody style={{background: '#fcfcfc'}}>
                            <div className="back-img otp_pickup vehicleDetailsDemo vehicleTwoPart">
                                <h6><strong>Vehicle No</strong> <span className="dot">-</span> <span className="detVehicle">UP14KT7616</span></h6>
                                <h6><strong>RC Registration Date</strong> <span className="dot">-</span> <span className="detVehicle">29-Mar-2022</span></h6>
                                <h6><strong>Vehicle Class</strong> <span className="dot">-</span> <span className="detVehicle">Articulated Vehicle(HGV)</span></h6>
                                <h6><strong>Chasi no</strong> <span className="dot">-</span> <span className="detVehicle">MAT828022NAB02166</span></h6>
                                <h6><strong>Engine No</strong> <span className="dot">-</span> <span className="detVehicle">22B84969460</span></h6>
                                <h6><strong>Maker</strong> <span className="dot">-</span> <span className="detVehicle">TATA MOTORS LTD</span></h6>
                                <h6><strong>Fuel Type</strong> <span className="dot">-</span> <span className="detVehicle">DIESEL</span></h6>
                                <h6><strong>Colour</strong> <span className="dot">-</span> <span className="detVehicle">TITANIUM_WHITE</span></h6>
                                <h6><strong>Norms description</strong> <span className="dot">-</span> <span className="detVehicle">BHARAT STAGE VI</span></h6>
                                <h6><strong>RC Fit Upto</strong> <span className="dot">-</span> <span className="detVehicle">28-Mar-2024</span></h6>
                                <h6><strong>RC Issued By</strong> <span className="dot">-</span> <span className="detVehicle">RTO, Ghaziabad</span></h6>
                                <h6><strong>RC Tax Upto</strong> <span className="dot">-</span> <span className="detVehicle">31-May-2023</span></h6>
                                <h6><strong>Financer</strong> <span className="dot">-</span>  <span className="detVehicle">HDFC BANK LTD</span></h6>
                                <h6><strong>Insurance Company</strong> <span className="dot">-</span> <span className="detVehicle">Oriental Insurance Co. Ltd.</span></h6>
                                <h6><strong>Insurance Policy No</strong> <span className="dot">-</span> <span className="detVehicle">242596/31/2023/TMC/51795</span></h6>
                                <h6><strong>Insurance Valid Till</strong> <span className="dot">-</span> <span className="detVehicle">20-Mar-2024</span></h6>
                                <h6><strong>PUC Upto</strong> <span className="dot">-</span> <span className="detVehicle">20-Jun-2024</span></h6>
                                <h6><strong>Puc No.</strong> <span className="dot">-</span> <span className="detVehicle">UP01402700005493</span></h6>
                                <h6><strong>Permit No.</strong> <span className="dot">-</span> <span className="detVehicle">UP2022-NP-2478B</span></h6>
                                <h6><strong>Permit Issue Date</strong> <span className="dot">-</span>  <span className="detVehicle">06-Apr-2022</span></h6>
                                <h6><strong>Permit valid Till</strong> <span className="dot">-</span> <span className="detVehicle">05-Apr-2027</span></h6>
                                <h6><strong>Permit Type</strong> <span className="dot">-</span> <span className="detVehicle">National Permit [MULTI-AXLE TRAILER (> 50tons)</span></h6>
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center rates-not">
                            <button type="button" className="btn btn-secondary" style={{border:'1px solid #ff0600',background:'#ff0600'}} onClick={(e)=>this.driverVehicleClose(e)}>Close</button>
                        </ModalFooter>
                    </>
                </Modal>
                <Modal
                    isOpen={this.state.otpPopup}
                    size="md"
                    toggle={this.toggleLarges}
                >
                    <>
                        <ModalHeader style={{borderBottom: '1px solid #d7d7d7'}} toggle={this.toggleLarges} className="rates_heads">
                            Sent Otp
                        </ModalHeader>
                        <ModalBody style={{background: '#fcfcfc'}}>
                            <div className="back-img otp_pickup">
                                <h2 className="rates_head">Please enter the one time password
                                    shared by driver</h2>
                                {this.state.sendOtpButtonClicked ?
                                <input name="otp" className="form-control" type="text" onKeyUp={this.handleChange}/>:""}
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center rates-not">
                            {this.state.sendOtpButtonClicked ?
                                <button type="button" className="btn btn-secondary" style={{border:'1px solid #ff0600',background:'#ff0600'}}
                                        onClick={(e)=>this.verifyOTP(e)}>Verify OTP</button>:""}
                            <button type="button" className="btn btn-secondary" style={{border:'1px solid #ff0600',background:'#ff0600'}} onClick={(e)=>this.sendOTPForLogin(e)}>{this.state.buttonName}</button>
                        </ModalFooter>
                    </>
                </Modal>

                <Modal
                    className="sendOtp_popup"
                    isOpen={this.state.otpPopupNext}
                    size="md"
                    toggle={this.toggleLarge}
                >
                    <>
                        <ModalHeader style={{borderBottom: '1px solid #d7d7d7',padding:'0'}} toggle={this.toggleLarge} className="rates_heads"></ModalHeader>
                        <ModalBody style={{background: '#fcfcfc'}}>
                            <div className="back-img otp_pickup goods_handover">
                                <h2 className="rates_head">Goods Handed over to Executive</h2>
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center rates-not">
                            <button type="button" className="btn btn-secondary" style={{border:'1px solid #ff0600',background:'#ff0600'}} onClick={this.goodHandover}>Yes</button>
                            <button type="button" className="btn btn-secondary" style={{border:'1px solid #ff0600',background:'#ff0600'}} onClick={this.goodNotHandover}>No</button>
                        </ModalFooter>
                    </>
                </Modal>
            </AppLayout >
        );
    }


}


export default DriverDetailsDemo;
