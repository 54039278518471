import React, {Component, Fragment} from "react";
import {Modal, ModalHeader, ModalBody, FormGroup} from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {withRouter} from "react-router";
class NotPaidStatusCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: "",
            toUpdate: "",

            paymentStatusShow:false,
            message:"",
            bookingid:"",
            selectNewShipper: false,
            selectNewConsignee: false,

            showNewShipper:false,
            showNewConsignee:false,
            ResponcePayment:{}


        };

    }



    componentDidMount() {
          if(this.props.paymentStatusShow)
        {
            this.setState({
                paymentStatusShow:this.props.paymentStatusShow
            })
        }
          if(this.props.ResponcePayment)
        {
            this.setState({
                ResponcePayment:this.props.ResponcePayment
            })
        }
          if(this.props.bookingid)
        {
            this.setState({
                bookingid:this.props.bookingid
            })
        }

          if (localStorage.getItem("already_registered") === "true")
          {
              this.setState({
                  showNewShipper:false,
                  showNewConsignee:false,
              })
          }
          else {
              this.setState({
                  showNewShipper:true,
                  showNewConsignee:true,
              })
          }

    }

    toggleLarge = () => {
        this.setState(prevState => ({
            paymentStatusShow: !prevState.paymentStatusShow
        }),()=>{
            if(this.state.paymentStatusShow === false){
                this.props.returnResult(true)
            }
        });
    };

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.paymentStatusShow && this.props.paymentStatusShow !== prevProps.paymentStatusShow)
        {
            this.setState({
                paymentStatusShow:this.props.paymentStatusShow
            })
        }
        if(this.props.ResponcePayment && this.props.ResponcePayment !== prevProps.ResponcePayment)
        {
            this.setState({
                ResponcePayment:this.props.ResponcePayment
            })
        }
        if(this.props.bookingid && this.props.bookingid !== prevProps.bookingid)
        {
            this.setState({
                bookingid:this.props.bookingid
            })
        }

    }



    render() {
        const {

           showNewConsignee,showNewShipper} = this.state;
        const initialValues = {
            showNewConsignee,showNewShipper
        };

        return (
            <>
                <Modal className="contactsss"
                    isOpen={this.state.paymentStatusShow}
                    size="md"
                    toggle={this.toggleLarge}
                >


                            <ModalHeader toggle={this.toggleLarge} style={{padding: "0"}}>
                                <NavLink to={`/`} className="white">
                                    {/*<span className="logo-single" style={{marginBottom: "-9px"}}/>*/}
                                </NavLink>
                            </ModalHeader>
                            <ModalBody className="contact-later1" >
                                {/*//edit shipper start*/}
                                <div className="contect-soon">
                                    <div className="soon_text">
                                        <div className="book-imgs" style={{background: "linear-gradient(96deg, #173ebc, #ff0600)"}}>
                                            <img src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/correct.png" alt="Success"/>
                                        </div>
                                        <div className="book-text">
                                            {/*<h5><i className="fa fa-circle" style={{color:"green"}}></i> Available Now </h5>*/}
                                            <h2> Please First Pay Proforma Amount.  </h2>

                                        </div>
                                    </div>
                                    <div className="bottom_btn">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button onClick={(e) => {
                                                    e.preventDefault()
                                                    this.setState({paymentStatusShow:false})
                                                    this.props.returnResult(true)
                                                }} className="request_btn"> OK
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*//edit shipper end*/}
                            </ModalBody>


                </Modal>
            </>
        );
    }
}
const mapStateToProps = ( state ) => {

};

const PaymentStatusCardWithRoute = withRouter(NotPaidStatusCard);

export default connect(
    mapStateToProps,
    {
    }
)(PaymentStatusCardWithRoute);
