import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@elfalem/leaflet-curve";

class LeafletMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    // this.originMarkerRef = React.createRef();
    // this.destinationMarkerRef = React.createRef();
    this.state = {
      mapData: "",
      originCoordinates: [],
      destinationCoordinates: [],
      dataLoaded: false,
    };
  }
  drawPolyLine = (map, origin, destination) => {
    let latlngs = [];

    let latlng1 = origin;
    let latlng2 = destination;

    let offsetX = latlng2[1] - latlng1[1];
    let offsetY = latlng2[0] - latlng1[0];

    let r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
    let theta = Math.atan2(offsetY, offsetX);

    let thetaOffset = 3.14 / 10;

    let r2 = r / 2 / Math.cos(thetaOffset);
    let theta2 = theta + thetaOffset;

    let midpointX = r2 * Math.cos(theta2) + latlng1[1];
    let midpointY = r2 * Math.sin(theta2) + latlng1[0];

    let midpointLatLng = [midpointY, midpointX];

    latlngs.push(latlng1, midpointLatLng, latlng2);

    let pathOptions = {
      color: "red",
      weight: 3,
      dashArray: "10, 10",
      dashOffset: "10",
    };

    L.curve(["M", latlng1, "Q", midpointLatLng, latlng2], pathOptions).addTo(
      map
    );
  };
  getLocation = async (origin, destination) => {
    try {
      let originArr = [];
      let destinationArr = [];
      const resOrigin = await fetch(
        `https://geocode.search.hereapi.com/v1/geocode?q=${origin}&apiKey=tWG0KQ0Zcp4AL3UxegRBswdS-tqot6Cg3ypXs-lO-YA`
      );
      const resDestination = await fetch(
        `https://geocode.search.hereapi.com/v1/geocode?q=${destination}&apiKey=tWG0KQ0Zcp4AL3UxegRBswdS-tqot6Cg3ypXs-lO-YA`
      );
      const dataOrigin = await resOrigin.json();
      const dataDestination = await resDestination.json();
      for (let key in dataOrigin.items[0].position) {
        originArr.push(dataOrigin.items[0].position[key]);
      }
      for (let key in dataDestination.items[0].position) {
        destinationArr.push(dataDestination.items[0].position[key]);
      }
      this.setState(
        {
          originCoordinates: originArr,
          destinationCoordinates: destinationArr,
          // originCity: dataOrigin.items[0].address.city,
          // destinationCity: dataDestination.items[0].address.city,
        },
        () => {
          this.setState({ dataLoaded: true });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  changeCenter(coordinate) {
    if (coordinate === "Origin" && this.state.dataLoaded === true) {
      this.mapRef.current.flyTo(this.state.originCoordinates);
    } else if (coordinate === "Destination" && this.state.dataLoaded === true) {
      this.mapRef.current.flyTo(this.state.destinationCoordinates);
    }
  }
  componentDidMount() {
    this.setState(
      {
        mapData: JSON.parse(localStorage.getItem("queryData")),
      },
      () => {
        // Road
        if (this.state.mapData.queryType === "Road") {
          this.getLocation(
            this.state.mapData.fromPincode,
            this.state.mapData.toPincode
          );
        }
        // Courier
        else if (this.state.mapData.queryType === "Courier") {
          this.getLocation(
            this.state.mapData.fromPincode,
            this.state.mapData.toPincode
          );
        }
        // Ocean Export
        else if (
          this.state.mapData.queryType === "Ocean Export" &&
          this.state.mapData.activityType === "Port To Port"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Ocean Export" &&
          this.state.mapData.activityType === "Door To Port"
        ) {
          this.getLocation(
            this.state.mapData.originDoor.city,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Ocean Export" &&
          this.state.mapData.activityType === "Door To Door"
        ) {
          this.getLocation(
            this.state.mapData.originDoor.city,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Ocean Export" &&
          this.state.mapData.activityType === "Port To Door"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        }
        // Ocean Domestic
        else if (
          this.state.mapData.queryType === "Ocean Domestic" &&
          this.state.mapData.activityType === "Port To Port"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Ocean Domestic" &&
          this.state.mapData.activityType === "Door To Door"
        ) {
          this.getLocation(
            this.state.mapData.originDoor.city,
            this.state.mapData.destinationDoor.city
          );
        } else if (
          this.state.mapData.queryType === "Ocean Domestic" &&
          this.state.mapData.activityType === "Door To Port"
        ) {
          this.getLocation(
            this.state.mapData.originDoor.city,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Ocean Domestic" &&
          this.state.mapData.activityType === "Port To Door"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationDoor.city
          );
        }
        // Ocean Import
        else if (
          this.state.mapData.queryType === "Ocean Import" &&
          this.state.mapData.activityType === "Port To Port"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Ocean Import" &&
          this.state.mapData.activityType === "Door To Port"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Ocean Import" &&
          this.state.mapData.activityType === "Door To Door"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationDoor.city
          );
        } else if (
          this.state.mapData.queryType === "Ocean Import" &&
          this.state.mapData.activityType === "Port To Door"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationDoor.city
          );
        }
        // Air Export
        else if (
          this.state.mapData.queryType === "Air Export" &&
          this.state.mapData.activityType === "Airport To Airport"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Air Export" &&
          this.state.mapData.activityType === "Door To Airport"
        ) {
          this.getLocation(
            this.state.mapData.originDoor.city,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Air Export" &&
          this.state.mapData.activityType === "Door To Door"
        ) {
          this.getLocation(
            this.state.mapData.originDoor.city,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Air Export" &&
          this.state.mapData.activityType === "Airport To Door"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        }
        // Air Import
        else if (
          this.state.mapData.queryType === "Air Import" &&
          this.state.mapData.activityType === "Airport To Airport"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Air Import" &&
          this.state.mapData.activityType === "Door To Airport"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Air Import" &&
          this.state.mapData.activityType === "Door To Door"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationDoor.city
          );
        } else if (
          this.state.mapData.queryType === "Air Import" &&
          this.state.mapData.activityType === "Airport To Door"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationDoor.city
          );
        }
        // Air Domestic
        else if (
          this.state.mapData.queryType === "Air Domestic" &&
          this.state.mapData.activityType === "Airport To Airport"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Air Domestic" &&
          this.state.mapData.activityType === "Door To Door"
        ) {
          this.getLocation(
            this.state.mapData.originDoor.city,
            this.state.mapData.destinationDoor.city
          );
        } else if (
          this.state.mapData.queryType === "Air Domestic" &&
          this.state.mapData.activityType === "Door To Airport"
        ) {
          this.getLocation(
            this.state.mapData.originDoor.city,
            this.state.mapData.destinationAirport.name
          );
        } else if (
          this.state.mapData.queryType === "Air Domestic" &&
          this.state.mapData.activityType === "Airport To Door"
        ) {
          this.getLocation(
            this.state.mapData.originAirport.name,
            this.state.mapData.destinationDoor.city
          );
        }
      }
    );
  }
  render() {
    const location = [
      this.state.originCoordinates,
      this.state.destinationCoordinates,
    ];
    const iconMarker = new Icon({
      iconUrl:
        "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.0022683117337751746-1640689412133.png",
      iconSize: [40, 40],
    });
    const polylineOptions = {
      color: "#ff0600",
      dashArray: "10, 10",
      dashOffset: "10",
    };

    return (
      <>
        <div className="map_back">
          <div
            id="leaflet-container"
            style={{
              width: "100%",
              height: "400px",
              position: "relative",
              zIndex: 0,
            }}
          >
            {/* <MapContainer
              key={JSON.stringify(this.state.originCoordinates)}
              center={
                this.state.dataLoaded === true && this.state.originCoordinates
                  ? this.state.originCoordinates
                  : [28.582121, 77.326698]
              }
              zoom={12}
              doubleClickZoom={true}
              scrollWheelZoom={true}
              style={{ height: "400px" }}
              whenCreated={(map) => {
                this.mapRef.current = map;
                if (this.state.dataLoaded) {
                  this.mapRef.current.flyTo(this.state.originCoordinates);
                  this.mapRef.current.fitBounds([
                    this.state.originCoordinates,
                    this.state.destinationCoordinates,
                  ]);
                  this.drawPolyLine(
                    map,
                    this.state.originCoordinates,
                    this.state.destinationCoordinates
                  );
               
                }
              }}
            >
              
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
              />
              <Marker
                position={
                  this.state.dataLoaded === true && this.state.originCoordinates
                    ? this.state.originCoordinates
                    : [28.582121, 77.326698]
                }
                icon={iconMarker}
              >
                <Popup>This is your origin</Popup>
              </Marker>
              <Marker
                position={
                  this.state.dataLoaded === true &&
                  this.state.destinationCoordinates
                    ? this.state.destinationCoordinates
                    : [28.582121, 77.326698]
                }
                icon={iconMarker}
              >
                <Popup>This is your destination</Popup>
              </Marker>

            </MapContainer> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <button
            className="delete rounded-circles btn"
            onClick={() => {
              this.changeCenter("Origin");
            }}
          >
            Show Origin
          </button>
          <button
            className="delete rounded-circles btn"
            onClick={() => {
              this.changeCenter("Destination");
            }}
          >
            Show Destination
          </button>
        </div>
      </>
    );
  }
}

export default LeafletMap;
