import React, { Component } from "react";

import {Link} from "react-router-dom";
class OtherPagesSection extends Component {
    constructor(props) {
        super(props);
        this.state = {


        };
    }

    render() {

        
        return (
            <>

                <section className="visitorSection">
                    <div className="container">
                        <h2 className="title_shipment">Zipaworld Network</h2>
                        <div className="visitors_img">
                            <img className="lazyload mobileDesktop" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.3878818433576716-1681468258553.png" alt="map_image"  width={"100%"} height={"100%"}/>
                            <img className="lazyload mobileMap" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.957953544363858-1681815285225.png" alt="map_image"  width={"100%"} height={"100%"}/>
                            <div className="middle_text_visitors">
                                <div className="visitor_list">
                                    <div className="number_vistors">5000+</div>
                                    <h3 className="vistors_heading">Daily Visitors</h3>
                                </div>
                            </div>
                            <div className="left_text_visitors">
                                <div className="visitor_list">
                                    <div className="number_vistors">4800+</div>
                                    <h3 className="vistors_heading">Happy Customers</h3>
                                </div>
                            </div>
                            <div className="right_text_visitors">
                                <div className="visitor_list">
                                    <div className="number_vistors">1.2 Million+</div>
                                    <h3 className="vistors_heading">Successful Deliveries</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="servicesSections">
                    <div className="container">
                        <h2 className="title_shipment"><span></span> <br/>Our Services</h2>
                        <div className="two_section">
                            <div className="services_img mobileShowHome">
                                <img className="lazyload img-fluid mobileHidden" data-src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.985865856748738-1671777211835.jpg" alt="air-freight"  width={"100%"} height={"100%"}/>
                                <img className="lazyload img-fluid mobileShown" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/images/AirFreightMobile.jpg" alt="air-freight"  width={"100%"} height={"100%"}/>
                            </div>
                            <div className="services_text">
                                <h3><Link to="/AirFreight">Air Freight Forwarder And <span>Cargo Logistics Services</span></Link></h3>
                                <p>Are you searching for the best air freight forwarder in Noida, Delhi? Then, you are at the right place!
                                    Air Freight is the most dynamic and fastest mode of transporting cargo from one place to another.
                                    However, air cargo lags in technological advancement. Air cargo still heavily depends on manual working
                                    and paper-based processes.
                                    In air cargo logistics service, there is a dearth of online platforms and websites like
                                    in air travel where one can book their cargo so easily as they book their
                                    travel and vacation.</p>
                                <Link to="/AirFreight">
                                    <p className="read_more">Read More</p>
                                </Link>

                            </div>
                            <div className="services_img desktopShowHome">
                                <img className="lazyload img-fluid" data-src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.985865856748738-1671777211835.jpg" alt="air-freight"  width={"100%"} height={"100%"}/>
                            </div>
                        </div>
                        <div className="two_section">
                            <div className="services_img">
                                <img className="lazyload img-fluid mobileHidden" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.404020746430523-1672034674651.png" alt="Ocean Freight"  width={"100%"} height={"100%"}/>
                                <img className="lazyload img-fluid mobileShown" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/images/OceanFreightServices.jpg" alt="Ocean Freight" />
                            </div>
                            <div className="services_text">
                                <h3><Link to="/OceanFreight">Ocean Freight <span>Shipping Services</span></Link></h3>
                                <p>Zipaworld is coming up with the digital portal for ocean freight shipping as an upshot of
                                    the expertise of its personnel in ocean freight movement and long-term association with
                                    shipping lines, port handlers, NVOCC, and various trade bodies. The ocean/sea freight
                                    web platform offers the most attractive pricing and deals for complete FCL, LCL, and
                                    consolidation services.</p>
                                <p>If you want to transport your goods through the ocean, then we are the best choice for you. Because we are the best ocean/sea freight forwarder in Delhi, India.</p>
                                <Link to="/OceanFreight">
                                    <p className="read_more">Read More</p>
                                </Link>
                            </div>
                        </div>
                        <div className="two_section">
                            <div className="services_img mobileShowHome">
                                <img className="lazyload img-fluid mobileHidden" data-src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6980678851451969-1635401300230.jpg" alt="Courier Services"  width={"100%"} height={"100%"}/>
                                <img className="lazyload img-fluid mobileShown" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/images/CourierFreightServices.jpg" alt="Courier Services"  width={"100%"} height={"100%"}/>
                            </div>
                            <div className="services_text">
                                <h3><Link to="/CourierFreight">Digital Freight Forwarding  <span>And Courier Services</span></Link></h3>
                                <p>The aim of Zipaworld is not to provide digital freight forwarding services & courier
                                    services only. Since our inception, we carry along with the larger aim of integrating the
                                    whole supply chain digitally. We target at filling the loopholes of the supply chain and
                                    tying loose ends with the help of technology.</p>
                                    <p>Digital freight, ocean freight, road and inland freight are encompassed in the B2B model of Zipaworld catering to the exporters, importers, manufacturers... </p>
                                <Link to="/CourierFreight">
                                    <p className="read_more">Read More</p>
                                </Link>


                            </div>
                            <div className="services_img desktopShowHome" >
                                <img className="lazyload img-fluid" data-src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6980678851451969-1635401300230.jpg" alt="Courier Services"  width={"100%"} height={"100%"}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="otherServices">
                    <div className="container">
                        <h2 className="title_shipment">Other Services</h2>
                        <div className="otherServiceSection">
                            <Link to="/TradeFinance" className="services_item">
                                <div className="">
                                    <div className="item_img">
                                        <img className="lazyload img-fluid" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.7225889819154341-1681467968665.png"  width={"100%"} height={"100%"}
                                             alt="trade-finance" />
                                    </div>
                                    <div className="item_text">
                                        <h3>TRADE <span>FINANCE</span></h3>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/RoadFreight" className="services_item">
                                <div className="">
                                    <div className="item_img">
                                        <img className="lazyload img-fluid" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.10159033582260579-1681467844015.png"
                                             width={"100%"} height={"100%"} alt="road-freight" />
                                    </div>
                                    <div className="item_text">
                                        <h3>ROAD FREIGHT AND <span>INLAND TRANSPORT SERVICES</span></h3>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/WarehousingSolutions" className="services_item">
                                <div className="">
                                    <div className="item_img">
                                        <img className="lazyload img-fluid" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.7635224475784126-1681468051646.png"
                                             alt="warehousing-services-and-solutions"  width={"100%"} height={"100%"}/>
                                    </div>
                                    <div className="item_text">
                                        <h3>WAREHOUSING SERVICES <span>AND SOLUTIONS</span></h3>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/CustomsBrokerage" className="services_item">
                                <div className="">
                                    <div className="item_img">
                                        <img className="lazyload img-fluid" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6918287028496481-1681468112032.png"
                                             alt="customs-brokerage-and-documentation"  width={"100%"} height={"100%"}/>
                                    </div>
                                    <div className="item_text">
                                        <h3>CUSTOMS BROKERAGE <span>AND DOCUMENTATION</span></h3>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/PackagingServices" className="services_item">
                                <div className="">
                                    <div className="item_img">
                                        <img className="img-fluid lazyload" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.3025473650161383-1681468170357.png"
                                             alt="packing-and-relocation"  width={"100%"} height={"100%"}/>
                                    </div>
                                    <div className="item_text">
                                        <h3>PACKAGING AND <span>RELOCATION</span></h3>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>

                <div className="download_app">
                    <h3>Download <span>App Now !</span></h3>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <a href="https://play.google.com/store/apps/details?id=com.zipaworld.app" target="_blank"><img className="lazyload" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.537423332837782-1681468745965.png" alt="Mobile App"  width={"100%"} height={"100%"}/></a>
                        <a href="https://apps.apple.com/in/app/zipaworld/id1644871960" target="_blank"><img className="lazyload" data-src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.23740265980524322-1681468784645.png" alt="Iphone App"  width={"100%"} height={"100%"}/></a>
                    </div>
                </div>
            </>
        );
    }
}

export default OtherPagesSection;
