import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleRight} from "@fortawesome/free-solid-svg-icons";


export class faqPage extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        return (
            <>
               <MetaTags>
        <title> FAQ | Zipaworld </title>
        <meta name="title" content="Top Freight Forwarding Companies in the India | Zipaworld"/>
        <meta name="description" content="Zipaworld is a door to door Express Cargo logistics service provider in India offers 3PL solutions."/>
        <meta name="keywords" content="Goods transportation via Road, Air, Sea & Rail. We deliver your cargo on-time, Guide to The Best Shipping Companies for Small Business, AWB Tracking, Air Waybill Tracking, Air Cargo Tracking, International shipping, Tracking Shipment or Packages, Online Load, Truck Booking, Transporters, Logistics, Top Freight Forwarding Companies in the India, what is air freight?, what is ocean freight?, what is airwaybill?, how to book air cargo online?, which is the best logistics company near me?”>"/>
    </MetaTags>
                <AppLayout>
                    <div className="News_Section" ref={this.testRef}>
                        <h2>FAQ</h2>
                    </div>
                    <section className="Faq_page">
                        <div className="container">
                            <div className="faq-content">
                                <Accordion>
                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                               <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> How do I check air freight rates on Zipaworld?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="0">
                                            <div className="content_faq">
                                                <Card.Body>Checking air freight rates is a child’s play on Zipaworld. Log on to the Zipaworld portal www.zipaworld.com. Select Air Freight Modality. Feed basic shipment details like Origin, Destination, Clearance date, Gross Weight, tentative Volume Weight, Total number of pieces, and Commodity. Click on search. Get the most competitive market rates for more than 200 trade lanes instantly. For Origin-Destination combinations for which the instant rates are not available, our expert team will quote you the price based on your query details.  From there in either of the cases, you can proceed for booking as per your choice of selection of services.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> How do I check ocean freight rates on Zipaworld?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="2">
                                            <div className="content_faq">
                                                <Card.Body>Select ‘Ocean’ Freight modality on the Query screen on www.zipaworld.com. Feed basic shipment details like Origin, Destination, Cargo Readiness Date, Shipment mode whether FCL or LCL, container type, Gross Weight, Volume Weight, Number of pieces and Commodity. Get price quotes instantly for the query on multiple carriers on the most common lanes.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> How do I check road freight rates on Zipaworld?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="3">
                                            <div className="content_faq">
                                                <Card.Body>Select ‘Road’ Freight modality on the Query screen on www.zipaworld.com. Select the product whether ‘Part Load’ or ‘Full Load’. Select the service type whether ‘Pick Up’ or ‘Drop Off’. Enter the pin code of the pick-up location and the pin code of the delivery location. Feed the basic details of the goods like Date, Gross Weight, Total Pieces, declared value, and enter the commodity. Get the most suitable transportation cost for your road freight.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="4">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> What are the services/ products of Zipaworld?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="4">
                                            <div className="content_faq">
                                                <Card.Body>Zipaworld is a single window online portal for all services related to air freight, ocean freight, road and inland freight, first and last mile connectivity, express pick-up and distribution, and courier, express and parcel (CEP) services. Zipaworld also embodies other associated logistics services like transportation, warehousing, customs clearance, packaging, documentation, logistics consultancy and audit, logistics process outsourcing.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="5">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> What do you mean by Economy, Premium and Express rates in air freight quotes?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="5">
                                            <div className="content_faq">
                                                <Card.Body>Air freight quotations for many trade lanes on Zipaworld portal are listed into three different service and pricing categories. Economy rates will be the most competitive and cheapest rates offered by carriers in the market, but the transit time would be the highest. Premium rates would be slightly higher than Economy rates with shorter transit time. Express rates option will have the shortest transit time, most often on direct carriers, but would fetch the highest pricing among the three options. You can select your preferred option based on your requirement, and we map you with the carrier most suited to your requisites.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>


                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="8">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> What are the basic information we should have about a shipment or cargo to get air, ocean and road freight rates?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="8">
                                            <div className="content_faq">
                                                <Card.Body>You only need to have the basic details of your cargo like the number of pieces, weight, tentative volumetric weight, and commodity type to get your instant prices for air, ocean and road freight on Zipaworld.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="9">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> What happens when I do not get instant price quotation for my origin and destination pair?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="9">
                                            <div className="content_faq">
                                                <Card.Body>
                                                    There could be multiple reasons if you have not received an instant price quotation for your enquiry. First, it could mean that there are not too many readily available carrier options for that sector. For the same reason and to be precise about what we quote to you, we have avoided such sectors where there is dearth of easy connectivity or extreme price fluctuations.
                                                    <br/>
                                                    Second, if your enquiry is for a special commodity other than general cargo, you will receive a quotation only after the acceptance of the commodity is checked with the carrier.
                                                    <br/>
                                                    Third, if your cargo dimensions entered exceed the permissible dimensions acceptable as general cargo, in that case also you will receive a quotation post acceptance from the carrier.
                                                    <br/>
                                                    Hence, for such enquiries where instant price quotations are not available, our customer service team will get in touch with you and respond with the best available prices within 60-90 min of your query.
                                                </Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="10">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> Do I need to login to check freight rates on Zipaworld?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="10">
                                            <div className="content_faq">
                                                <Card.Body>No! That’s the best thing about Zipaworld. You can view all the prices for air, ocean, road freight, courier, and all your logistics requisites without even logging in to the portal. You can get an idea of the prevalent market rates for all modes of freight which will help you calculate your overall logistics budget. Now, no need to wait for business hours to start to get your freight quotations from your freight forwarding partner. Check for prices 24 x 7 on Zipaworld.
                                                    <br/>
                                                    Click here to check your logistics requisites now! </Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="11">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> How to get on-boarded with Zipaworld?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="11">
                                            <div className="content_faq">
                                                <Card.Body>If you wish to book your cargo or shipment through Zipaworld against the price quotation received for your enquiry, then you need to furnish a few basic details about your company if you are an organisation or an individual. We need to know our customer for which we require some information that will help us get you on-boarded with Zipaworld. </Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="12">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> How does Zipaworld prepare automated freight documents?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="12">
                                            <div className="content_faq">
                                                <Card.Body>Based on the details of the shipment, shipper, consignee details, handling information, and so on, furnished by you during your enquiry and booking confirmation process, the Zipaworld system maps the same with the requisite fields on the freight documents like Air Waybill, Shipping Instructions, Bill of Lading, etc.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="13">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> How does Zipaworld provide instant invoices?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="13">
                                            <div className="content_faq">
                                                <Card.Body>Zipaworld system is designed to prepare automated instant invoices based on the shipment details, price quoted, date of shipment execution, applicable taxes and other requisite data. Hence you are not required to wait and chase for invoices after shipment execution which is the prevalent process with most freight forwarders.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="14">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> How do I get to make freight related payments on Zipaworld?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="14">
                                            <div className="content_faq">
                                                <Card.Body>Zipaworld provides you with multiple online payment gateways once you plan to execute the shipment with us. You can easily use the payment gateways and make payments.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="15">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> Do you have capacity constraints?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="15">
                                            <div className="content_faq">
                                                <Card.Body>Yes, like any other service provider, we do come across capacity constraints and price fluctuations. But, since our system is automated and works on the freight quotations and other logistics data received from multiple carriers and vendors across India and the globe, we are equipped to provide you alternate options instantly. Hence, we ensure our customers do not face any hiccups or surprise hassles as the process is well cushioned for any unforeseen hurdles.</Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                    <div className="faq_description">
                                        <div className="faq-ques">
                                            <Accordion.Toggle as={Card.Header} eventKey="16">
                                                <span className="circle_faq"><FontAwesomeIcon icon={faChevronCircleRight}/></span> How competitive is your pricing?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="16">
                                            <div className="content_faq">
                                                <Card.Body>Zipaworld gets the most competitive market rates from our vast network of carrier partners and vendors for all sorts of logistics services against committed volumes and seasonal contracts. As we ensure to deliver our commitments to our carrier and vendor partners, they ensure to reciprocate with their best services and prices. So, we ensure our customers are the ones who get benefitted the most. </Card.Body>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>

                                </Accordion>
                            </div>
                        </div>
                    </section>
                </AppLayout>
            </>
        );
    }
}

export default faqPage;