
import React, { Component, Fragment } from "react";
import RestClient from "../util/RestClient";
import {
  ADD_ATTACHMENT,
  ADD_ATTACHMENT_AIR, ADD_ATTACHMENT_COURIER, ADD_ATTACHMENT_OCEAN,
  ADD_ATTACHMENT_ROAD,
  AIRLINE_UPDATE_URLs3,
  GET_ORIGIN_LIST
} from "../constants";
import { toastr } from "react-redux-toastr";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

import { onEdocketGetRequested } from "../redux/eDocketRedux/eDocketActions";
import { RefreshCw, UploadCloud, Plus } from "react-feather";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCharges,
  getOriginList, SendHouseData,
  sendQueryForStore,
  spotRateQueryAdd
} from "../redux/advanceSearch/advancSearchAction";
import {CheckOfferCode} from "../redux/auth/actions";

class UploadFilesCloudes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      awsFileUpload: [],
      returnResponce: false,
      returnResponceManager: false,
      awsFileUploadSingle: "",
      fileName: "",
      isLoading: true,
      isUploading: false,
      fromEdocket: false,
      error: "",
      selectedOrigin: undefined,
      quoteId: undefined,
      selectionOptions: []
    };
  }

  onDrop = files => {
    this.setState({
      awsFileUpload: files
    });
  };

  uploadAttachments = async values => {
    return await RestClient({
      url: AIRLINE_UPDATE_URLs3,
      method: "POST",
      data: values,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  };

  uploadQuoteIDFiles =()=> {
    let obj = {}
    if(this.props.isUploaded){
      obj = {
        name: this.state.fileName,
        link: this.state.uploadedFilesUrls,
        isUploaded : true
      }
    }else{
      obj = {
        name: this.state.fileName,
        link: this.state.uploadedFilesUrls,
      }
    }

    let data = {
      id: this.state.quoteId,
      uploadObj: obj
    }
    this.attachments(data).then(r => {
      this.setState({
        returnResponce: true,

      },()=>{
        if(r.success){
          this.props.returnResponce(true)
          this.setState({awsFileUpload:[],fileName:"",awsFileUploadSingle:""})
        }
      })

      let obj = {
        quoteId: this.state.quoteId
      }
      // this.props.onEdocketGetRequested(obj);

      toastr.success("Saved Successfully", {
        transitionIn: "bounceIn",
        transitionOut: "bounceOut"
      });
    })
  }

  attachments = async values => {

    return await RestClient({
      url: this.props.quoteFor === "Air" ? ADD_ATTACHMENT_AIR : this.props.quoteFor === "Ocean" ?ADD_ATTACHMENT_OCEAN : this.props.quoteFor === "Courier" ?ADD_ATTACHMENT_COURIER : this.props.quoteFor === "Road" ?ADD_ATTACHMENT_ROAD : ADD_ATTACHMENT,
      method: "POST",
      data: values,
    });
  };

  componentDidMount() {
    if (this.props.fromEdocket !== this.state.fromEdocket) {
      this.setState({
        fromEdocket: this.props.fromEdocket
      })
    }

    if (this.props.quoteId !== this.state.quoteId) {
      this.setState({
        quoteId: this.props.quoteId
      })
    }if (this.props.quoteFor) {
      this.setState({
        quoteFor: this.props.quoteFor
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.fromEdocket !== this.state.fromEdocket) {
      this.setState({
        fromEdocket: this.props.fromEdocket
      })
    }
    if (this.props.quoteId !== this.state.quoteId) {
      this.setState({
        quoteId: this.props.quoteId
      })
    }
    if (this.props.quoteFor && this.props.quoteFor !== prevProps.quoteFor) {
      this.setState({quoteFor: this.props.quoteFor})
    }
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };

  saveImages = e => {
    const image = [];
    let t = e.target.files;

    for (let i = 0; i < t.length; i++) {
      image.push(t[i]);
    }
    this.setState({ [e.target.name]: image });
    if (image && image.length > 0) {
      if (this.props.isSingleUpload) {
        this.setState({ awsFileUploadSingle: image[0] });
      } else {
        this.setState({ [e.target.name]: image });
      }

    }
  };

  render() {
    try {
      return (
        <Fragment>

              {this.state.fromEdocket !== false ?
                <>
                  <div className="edocket-100">
                      <FormGroup>
                        <Label for="fileName"><strong>Enter File Name</strong></Label>
                        <Input type="text" id="fileName" placeholder="File Name"
                          name="fileName" onChange={this.handleChange}
                               required={true}
                        />
                      </FormGroup>
                  </div>

                  <div className="width-80">
                    <div className="form-group">
                      <div className="custom-file">
                        <label
                            className="custom-file-label " htmlFor="customFile">
                          {this.props.placeHolder}{" "}
                          {(this.state.awsFileUpload && !this.props.isSingleUpload) ? this.state.awsFileUpload : ""}
                        </label>
                        <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={e => this.saveImages(e)}
                            name="awsFileUpload"
                            multiple
                        />

                        <ul className="AAA-uploader-sender-only-data-1 mt-1">
                          {this.state.awsFileUpload && !this.props.isSingleUpload ? this.state.awsFileUpload.map(ex => (
                              <li>{ex.name}</li>)) : this.state.awsFileUploadSingle ?
                              <li>{this.state.awsFileUploadSingle.name}</li> : ""}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="doc_btnss">
                    <div className="form-group">
                      <Button
                          className="AAA-css-btn-main-top"
                          disabled={this.state.isUploading}
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              isUploading: true
                            })
                            this.props.isUploadingFiles(true)
                            const data = new FormData();
                            if (this.props.isSingleUpload) {
                              data.append("awsFileUpload", this.state.awsFileUploadSingle);
                            } else {
                              data.append("awsFileUpload", this.state.awsFileUpload);
                            }

                            if(this.state.fileName !== "" && this.state.fileName !== undefined )
                            {
                              this.uploadAttachments(data)
                                  .then(response => {
                                        this.props.isUploadingFiles(false)
                                        if (response.success) {
                                          if (this.props.isSingleUpload && response.result.length > 0) {
                                            this.props.onUploadFinish(this.props.name, response.result)
                                          } else {
                                            this.props.onUploadFinish(this.props.name, response.result)
                                          }
                                          this.setState({
                                            uploadedFilesUrls: response.result,
                                            isUploading: false
                                          }, () => {
                                            this.uploadQuoteIDFiles()
                                          })

                                          toastr.success("Upload Successfully", "" + response.message, {
                                            transitionIn: "bounceIn",
                                            transitionOut: "bounceOut"
                                          });
                                        } else {
                                          this.setState({
                                            isUploading: false
                                          })
                                          // toastr.success("Upload Error", "" + response.message, {
                                          //   transitionIn: "bounceIn",
                                          //   transitionOut: "bounceOut"
                                          // });
                                        }
                                      }
                                  )
                                  .catch(
                                      e => {
                                        this.props.onUploadFinish(this.props.name, [])
                                        this.props.isUploadingFiles(false)
                                        this.setState({
                                          isUploading: false
                                        })
                                        // toastr.success("Upload Error", "" + "", {
                                        //   transitionIn: "bounceIn",
                                        //   transitionOut: "bounceOut"
                                        // });
                                      }
                                  );
                            }else {
                              toastr.success("Please enter file Name", {
                                transitionIn: "bounceIn",
                                transitionOut: "bounceOut"
                              });
                              this.setState({
                                isUploading: false
                              })
                            }

                          }}>
                        Upload
                      </Button>
                    </div>
                  </div>

                </>
                :
                <>
                  <div className="width-6">
                    <div className="custom-file">
                      <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          placeholder="File Name"
                          onChange={e => this.saveImages(e)}
                          name="awsFileUpload"
                          multiple
                      />
                      <label className="custom-file-label " htmlFor="customFile">
                        {this.props.placeHolder}{" "}

                      </label>
                      <ul className="AAA-uploader-sender-only-data-1 mt-2">
                        {this.state.awsFileUpload && !this.props.isSingleUpload ? this.state.awsFileUpload.map((ex, index) => (
                            <li key={index}>{ex.name}</li>)) : this.state.awsFileUploadSingle ?
                            <li>{this.state.awsFileUploadSingle.name}</li> : ""}
                      </ul>
                    </div>
                  </div>
                  <div className="doc_btnss">
                    <Button
                        className="AAA-css-btn-main-top"
                        disabled={this.state.isUploading}
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            isUploading: true
                          })
                          this.props.isUploadingFiles(true)
                          const data = new FormData();
                          if (this.props.isSingleUpload) {
                            data.append("awsFileUpload", this.state.awsFileUploadSingle);
                          } else {
                            data.append("awsFileUpload", this.state.awsFileUpload);
                          }

                          this.uploadAttachments(data)
                              .then(response => {
                                    this.props.isUploadingFiles(false)
                                    if (response.success) {
                                      if (this.props.isSingleUpload && response.result.length > 0) {
                                        this.props.onUploadFinish(this.props.name, response.result)

                                      } else {

                                        this.props.onUploadFinish(this.props.name, response.result)
                                      }
                                      this.setState({
                                        uploadedFilesUrls: response.result,
                                        isUploading: false
                                      })

                                      toastr.success("Upload Successfully", "" + response.message, {
                                        transitionIn: "bounceIn",
                                        transitionOut: "bounceOut"
                                      });

                                      if (this.state.returnResponce === true) {
                                        this.props.returnResponce(true)

                                      } else {
                                        this.props.returnResponceManager(true)
                                      }

                                    } else {
                                      this.setState({
                                        isUploading: false
                                      })
                                      this.props.onUploadFinish(this.props.name, [])
                                      // toastr.success(response.message, {
                                      //   transitionIn: "bounceIn",
                                      //   transitionOut: "bounceOut"
                                      // });
                                    }
                                  }
                              )
                              .catch(
                                  e => {
                                    this.props.isUploadingFiles(false)
                                    this.setState({
                                      isUploading: false
                                    })
                                    // toastr.success("Upload Error", "" + "", {
                                    //   transitionIn: "bounceIn",
                                    //   transitionOut: "bounceOut"
                                    // });
                                  }
                              );
                        }}>
                      Upload
                    </Button>
                  </div>
                </>
              }

        </Fragment>
      );
    } catch (e) {
      // alert(e);
      return "";
    }
  }
}

const propTypes = {
  isUploadingFiles: PropTypes.func.isRequired,
  isSingleUpload: PropTypes.bool.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  quoteId: PropTypes.string,
  fromEdocket: PropTypes.bool
};

const defaultProps = {
  isUploadingFiles: undefined,
  quoteId: undefined,
  isSingleUpload: false,
  fromEdocket: false,
  onUploadFinish: undefined,
  name: "",
  placeHolder: "Select Attachment"
};

UploadFilesCloudes.propTypes = propTypes;
UploadFilesCloudes.defaultProps = defaultProps;

const mapStateToProps = (state) => {

};


export default connect(mapStateToProps, {
  onEdocketGetRequested
})(UploadFilesCloudes);