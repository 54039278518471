import {
    CHANGE_CURRENCY_FOR_SPOT_RATE,
    SPOT_RATE_CREATE_REQUEST,
    SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS, SPOT_RATE_DATA_PAGE,SPOT_RATE_DATA_PAGE_AIR,
    SPOT_RATE_DETAIL_REQUEST,
    SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS,
    SPOT_RATE_TICKER_REQUEST,
    SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS,
    SPOT_RATE_UPDATE_REQUEST,
    SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS,
} from "../actions";

const initState = {
    loading: false,
    originId:undefined,
    destinationId:undefined,
    chargeableWeight:undefined
};


const spotRateReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case SPOT_RATE_CREATE_REQUEST:
            // console.log("SPOT_RATE_CREATE_REQUEST Reducer" + JSON.stringify(payload));
            return {...state, loading: true, error: ""};
        case SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS:
            // console.log("SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, responseMessage: payload};

        case SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE:
            // console.log("SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
            return {...state, loading: false, errorMessage: payload};
        case SPOT_RATE_UPDATE_REQUEST:
            // console.log("SPOT_RATE_UPDATE_REQUEST Reducer" + JSON.stringify(payload));
            return {...state, loading: true, error: ""};
        case SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS:
            // console.log("SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));

            return {...state, loading: false, spotRateList: payload.data};

        case SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE:
            // console.log("SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
            return {...state, loading: false, errorMessage: payload};

        case SPOT_RATE_DETAIL_REQUEST:
            // console.log("SPOT_RATE_DETAIL_REQUEST Reducer" + JSON.stringify(payload));
            return {...state, loading: true, error: ""};
        case SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS:
            // console.log("SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, spotRateDetail: payload};

        case SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE:
            // console.log("SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
            return {...state, loading: false, errorMessage: payload};

        case SPOT_RATE_TICKER_REQUEST:
            // console.log("SPOT_RATE_TICKER_REQUEST Reducer" + JSON.stringify(payload));
            return {...state, loading: true, error: ""};

        case SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS:
            // console.log("SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, spotRateList: payload.data};

            case CHANGE_CURRENCY_FOR_SPOT_RATE:
            // console.log("SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, changeCurrencyData :payload };
        case SPOT_RATE_DATA_PAGE:
            // console.log("SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, originId: payload.originId , destinationId :payload.destinationId, chargeableWeight:payload.chargeableWeight,originName:payload.originName,destinationName:payload.destinationName };
        case SPOT_RATE_DATA_PAGE_AIR:
            // console.log("SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, originId: payload.originId , destinationId :payload.destinationId, chargeableWeight:payload.chargeableWeight,originName:payload.originName,destinationName:payload.destinationName };
        case SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE:
           // console.log("SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
            return {...state, loading: false, errorMessage: payload};

        default:
            return initState;
    }
};

export default spotRateReducer;
