import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    NOTIFICATION_USER,
    NOTIFICATION_USER_SUCCESS,
    NOTIFICATION_USER_ERROR,
    CHEKE_OFFER_CODE,
    LOGIN_OTP_SUCCESS
} from '../actions';

const INIT_STATE = {
    user: localStorage.getItem('user_id'),
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false,
                LoginMessage: action.payload,
                messageShowShipper: action.payload ,
                error: '',
                closeModal: true
            };

            case LOGIN_OTP_SUCCESS:
            return { ...state, loading: false,
                LoginMessage: action.payload,
                messageShowShipper: action.payload ,
                error: '',
                 closeModal: true
            };
        case LOGIN_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case FORGOT_PASSWORD:
            return { ...state, loading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, forgotUserMail: action.payload, error: '' };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, forgotUserMail: '', error: action.payload.message };
        case RESET_PASSWORD:
            return { ...state, loading: true, error: '' };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, newPassword: action.payload, resetPasswordCode: '', error: '' };
        case RESET_PASSWORD_ERROR:
            return { ...state, loading: false, newPassword: '', resetPasswordCode: '', error: action.payload.message };
        case CHEKE_OFFER_CODE:
            return { ...state, loading: true, error: '',offerChek : action.payload};

            case REGISTER_USER:
            return { ...state, loading: true, error: '' };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, message: action.payload,messageShowShipper: action.payload ,error: '',newShipper : true,signUpSuccessMsg :action.payload.message  };
        case REGISTER_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };

            case NOTIFICATION_USER:
            return { ...state, loading: true, error: '' };
        case NOTIFICATION_USER_SUCCESS:
            return { ...state, loading: false, message: action.payload};
        case NOTIFICATION_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload };
        case LOGOUT_USER:
            return { ...state, user: null, error: '' };
        default: return { ...state };
    }
}
