import {
    SHIPPER_APPROVAL_FETCH_FAILURE,
    SHIPPER_APPROVAL_FETCH_MASTER,
    SHIPPER_APPROVAL_FETCH_RESPONSE,
    SHIPPER_APPROVAL_CREATE,
    SHIPPER_APPROVAL_CREATE_RESPONSE,
    SHIPPER_APPROVAL_MASTER_PREVIEW,

    CONSIGNEE_APPROVAL_FETCH_FAILURE,
    CONSIGNEE_APPROVAL_FETCH_MASTER,
    CONSIGNEE_APPROVAL_FETCH_RESPONSE,
    CONSIGNEE_APPROVAL_CREATE,
    CONSIGNEE_APPROVAL_CREATE_RESPONSE,
    CONSIGNEE_APPROVAL_MASTER_PREVIEW,
    ADD_SHIPPER_CONSIGNEE_FROM_POPUP,
    ADD_SHIPPER_CONSIGNEE_FROM_POPUP_FAILURE,
    ADD_SHIPPER_CONSIGNEE_FROM_POPUP_SUCCESS,

} from "../actions";

//Shipper Action
export const saveShipper = (shipperDetails) => ({
    type: SHIPPER_APPROVAL_CREATE,
    payload: shipperDetails
});
export const showShipperResponce = (message) => ({
    type: SHIPPER_APPROVAL_CREATE_RESPONSE,
    payload: {message}
});

export const onShipperMasterLoadRequest = (shipperManagerParameters) => ({
    type: SHIPPER_APPROVAL_FETCH_MASTER,
    payload: shipperManagerParameters
});
export const onShipperMasterFetched = (shipperManagerResponse) => ({
    type: SHIPPER_APPROVAL_FETCH_RESPONSE,
    payload: shipperManagerResponse
});
export const onShipperMasterFetchedFailed = (message) => ({
    type: SHIPPER_APPROVAL_FETCH_FAILURE,
    payload: {message}
});
export const previewShipperMasterDetail = (shipperDetails) => ({
    type: SHIPPER_APPROVAL_MASTER_PREVIEW,
    payload: shipperDetails
});

//Consignee Action
export const saveConsignee = (shipperDetails) => ({
    type:   CONSIGNEE_APPROVAL_CREATE,
    payload: shipperDetails
});
export const showConsigneeResponce = (message) => ({
    type:   CONSIGNEE_APPROVAL_CREATE_RESPONSE,
    payload: {message}
});

export const onConsigneeMasterLoadRequest = (shipperManagerParameters) => ({
    type:   CONSIGNEE_APPROVAL_FETCH_MASTER,
    payload: shipperManagerParameters
});
export const onConsigneeMasterFetched = (shipperManagerResponse) => ({
    type:   CONSIGNEE_APPROVAL_FETCH_RESPONSE,
    payload: shipperManagerResponse
});
export const onConsigneeMasterFetchedFailed = (message) => ({
    type:   CONSIGNEE_APPROVAL_FETCH_FAILURE,
    payload: {message}
});
export const previewConsigneeMasterDetail = (shipperDetails) => ({
    type:   CONSIGNEE_APPROVAL_MASTER_PREVIEW,
    payload: shipperDetails
});


export const AddShipperConsignee = (shipperManagerResponse) => ({
    type:   ADD_SHIPPER_CONSIGNEE_FROM_POPUP,
    payload: shipperManagerResponse
});
export const AddShipperConsigneeFailed = (message) => ({
    type:   ADD_SHIPPER_CONSIGNEE_FROM_POPUP_FAILURE,
    payload: {message}
});
export const AddShipperConsigneeSuccess = (shipperDetails) => ({
    type:   ADD_SHIPPER_CONSIGNEE_FROM_POPUP_SUCCESS,
    payload: shipperDetails
});