import React, { Component } from "react";
import AppLayout from "../../../layout/AppLayout";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ModalFooter from "reactstrap/es/ModalFooter";
import FooterHome from "./FooterHome";
import {
    faChevronRight, faEdit,
    faEnvelope,
    faHandPointer,
    faPencilAlt,
    faPhoneAlt,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


 class PostResume extends Component {
     constructor(props) {
         super(props);
         this.state = {
             modalLarge: false,
         };
     }

     applyButtonFunc = () =>{
         this.setState({
             modalLarge:!this.state.modalLarge,})
     }

     toggleLarges = () => {
         this.setState(prevState => ({
             modalLarge: !prevState.modalLarge
         }));
     };


    render() {

        return (
            <>
                <AppLayout>

                    <div className="News_Section">
                        <h2>Post Your Resume</h2>
                    </div>

                    <section className="hire_page">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2>Submit Resume</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input className="input100" id="name" type="text" name="name"
                                                       placeholder="Name"/>
                                                <label className="label-job" htmlFor="name">
                                                    <FontAwesomeIcon icon={faUser}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input className="input100" id="name" type="text" name="name"
                                                       placeholder="Sur Name"/>
                                                <label className="label-job" htmlFor="name">
                                                    <FontAwesomeIcon icon={faUser}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input className="input100" id="email" type="text" name="email"
                                                       placeholder="Email"/>
                                                <label className="label-job" htmlFor="email">
                                                    <FontAwesomeIcon icon={faEnvelope}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input className="input100" id="phone" type="text" name="phone"
                                                       placeholder="Phone"/>
                                                <label className="label-job" htmlFor="phone">
                                                    <FontAwesomeIcon icon={faPhoneAlt}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input className="input100" id="postName" type="text" name="postName"
                                                       placeholder="Job Type"/>
                                                <label className="label-job" htmlFor="postName">
                                                    <FontAwesomeIcon icon={faPencilAlt}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select className="input100" id="experience" name="experience">
                                                    <option>Select Experience Year</option>
                                                    <option>Fresher</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                </select>
                                                <label className="label-job" htmlFor="postName">
                                                    <FontAwesomeIcon icon={faHandPointer}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <textarea className="input100" name="coverletter" placeholder="Cover Letter" rows="6"></textarea>
                                                <label className="label-job" htmlFor="postName">
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input className="input-checkbox100" type="file" name="resume"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <button type="button" className="btn resume_btn">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-info-box">
                                        <img src="../../../assets/img/mail.png"
                                             className="left-right" alt="Email Icon"/>
                                        <strong>Email Us</strong>
                                        <p><a href="mailto:info@aaa2innovate.com">info@aaa2innovate.com</a></p>
                                    </div>
                                    <div className="contact-info-box"><img src="../../../assets/img/call-cont.png"
                                                                           className="left-right" alt="Call Icon"/><strong>Call Us</strong>
                                        <p>Call us: 0120-6916910, 022-62360945</p>

                                    </div>
                                    <div className="contact-info-box">
                                        <img src="../../../assets/img/address.png" className="left-right" alt="Office Icon"/>
                                        <strong>Corporate Office</strong>
                                        <p>B 29, 3rd floor Sector 1, <br />Noida-201301, Uttar Pradesh
                                            <br/>Contact No: 0120-6916910</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <FooterHome/>
                </AppLayout>
            </>
        );
    }
}
export default PostResume;