import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {NavbarToggler, NavbarBrand,NavItem,UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem,Collapse,Nav,Navbar,} from "reactstrap";
import {connect} from "react-redux";
import {onNotificationLoadRequest, readNotificationHit} from "../../redux/notificationRedux/notificationAction";
import {OpenOfferSection} from "../../redux/advanceSearch/advancSearchAction";
import {setContainerClassnames, clickOnMobileMenu, logoutUser, changeLocale} from "../../redux/actions";
import SignUp from "../../views/user/signUp";
import {getExchangeCurrency, getExchangeRate, getQueryType} from "../../util/CommonUtility";
import {PAGE_PATHS} from "../../util/PageRoute";
import {changeCurrency} from "../../redux/spotRateRedux/spotRateActions";
import {toast} from "react-toastify";
import Notification from "./Notification";
import {getCurrenctCompanyBranchData} from "../../util/CommonUtility";
import RestClient from "../../util/RestClient";
import {GUEST_CUSTOMER_DATA} from "../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faEdit,
    faFileAlt,
    faFileInvoiceDollar,
    faHome, faQuestionCircle,
    faReceipt, faSignOutAlt, faTachometerAlt, faTimes, faUser,
    faUserCircle,
    faUserEdit
} from "@fortawesome/free-solid-svg-icons";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
class TopNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInFullScreen: false,
            searchKeyword: "",
            notificationList: [],
            LogOut: false,
            modalLarge: false,
            showLogin: false,
            currentCurrency: "INR",
            OfferHomeModel: false,
            OfferModel: false,
            selectedOfferData: {},
            showSideBarOther:true,
            showSideBar:false,
            isOpen: false,
            currencyName :'',
            currencyIcon : localStorage.getItem("currencyIcon"),
            prevScrollpos: window.pageYOffset,
            visible: true,
            LogoHeader : "https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.43087163708218656-1672405888553.png",
            // LogoHeader : "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5998214349555855-1640085198550.png",
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    callNotification = () => {
        this.props.onNotificationLoadRequest()
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        let locationHome = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let queryType = getQueryType()
            this.setState({
                activeQueryType: queryType
            })

        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("auth_key") !== undefined) {
            let CompanyData  =  getCurrenctCompanyBranchData();
            let LogoHeader = CompanyData && CompanyData.branchCompanyLogo ? CompanyData.branchCompanyLogo:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.43087163708218656-1672405888553.png"
            this.setState({
                LogoHeader : LogoHeader
            })
        }else{
            if(locationHome !== "AirFreight" && locationHome !== "OceanFreight" && locationHome !=="RoadFreight" && locationHome !=="CourierFreight" && locationHome !=="WarehousingSolutions" && locationHome !=="CustomsBrokerage" && locationHome !=="PackagingServices" && locationHome !=="TradeFinance"
                && locationHome !=="About" && locationHome !== "OurTeam" && locationHome !== "AwardsAndRecognition" && locationHome !== "LifeAtZipaworld" && locationHome !== "Pricing" && locationHome !== "FaqPage" && locationHome !=="CareerPage"){
                this.getDummyCustomerData().then()
            }else{

            }
        }


        this.setState({
            exchangeRate: getExchangeRate(),
            currentCurrency: getExchangeCurrency(),

        })
    }
    getDummyCustomerData = async () => {
        this.GetGuestCustomerData().then(responce => {
            let customerData = responce && responce.result ? responce.result : undefined;
            if (customerData !== undefined) {
                let CompanyData = customerData && customerData.csBuddyData && customerData.csBuddyData.branchId ? customerData.csBuddyData.branchId : null;
                let LogoHeader = CompanyData && CompanyData.branchCompanyLogo? CompanyData.branchCompanyLogo :"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.43087163708218656-1672405888553.png"
                this.setState({
                    LogoHeader : LogoHeader
                })
            }
        })

    };
    GetGuestCustomerData = async (dataForServer) => {
        return await RestClient({
            url: GUEST_CUSTOMER_DATA,
            method: "POST",
            data: dataForServer
        });
    }
    handlePageReload= (e)=>{
        e.preventDefault()
        window.location.reload(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.messageShowShipper && this.props.messageShowShipper !== prevProps.messageShowShipper) {
            this.setState({showLogin: true})
        }
        if (this.props.notificationList && prevProps.notificationList !== this.props.notificationList) {
            this.setState({
                notificationList: this.props.notificationList
            }, () => {
                let notificationList = Array.isArray(this.state.notificationList) && this.state.notificationList.length >= 1 ? this.state.notificationList : undefined

                if (notificationList !== undefined) {
                    let CurrentNotificationId = notificationList && notificationList[0]._id ? notificationList[0]._id : undefined
                    if (!localStorage.getItem("CurrentNotificationId") === false) {
                        let StoredNotificationId = localStorage.getItem("CurrentNotificationId")

                        if (CurrentNotificationId !== StoredNotificationId) {
                            toast.info("You have new notification", {
                                position: "top-left",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            localStorage.setItem("CurrentNotificationId", CurrentNotificationId)
                        }
                    } else {
                        toast.info("You have new notification", {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        localStorage.setItem("CurrentNotificationId", CurrentNotificationId)
                    }
                }


            })
        }
        if (this.props.OpenOffer !== undefined && this.props.OpenOffer !== prevProps.OpenOffer) {

            this.setState({OfferHomeModel: this.props.OpenOffer})
        }
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleLogout = () => {
        localStorage.clear()
        this.props.history.push(PAGE_PATHS.MAIN_PAGE)
        window.location.reload("false")
        this.setState({showLogin: false})
    };
    handleLogin = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }));
    };
    handleScroll = () => {
        const { prevScrollpos } = this.state;
        const currentScrollPos = window.pageYOffset;
        const visible = 710 > currentScrollPos;
        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    };

    render() {
        let userName = !localStorage.getItem("customer") === false && JSON.parse(localStorage.getItem("customer")).result !== undefined ? JSON.parse(localStorage.getItem("customer")).result.customerData.customerName : "Login"
        let notificationList = this.state.notificationList;
        let locationHome = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let locationBlog = window.location.href.split("/")[window.location.href.split("/").length - 2];
        if ( userName !== undefined && userName.length > 10) {
            userName = userName.split(" ", 1)[0]
        }

        return (
            <>
                <div className="menusss position-relative">
                    <Navbar color="white" light expand="lg" className={this.props.className ? "fixed-top zIndexHigh" : "fixed-top"}>
                        <NavbarBrand href={localStorage.getItem("already_registered") === "true" || this.state.showLogin === true ? "/" : "/"}>
                            {/*<img className="lazyload" data-src={this.state.LogoHeader ? this.state.LogoHeader : "https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.43087163708218656-1672405888553.png"} alt="Zipaworld" />*/}
                            {/*<img className="lazyload" data-src={"https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6791711182543703-1635936860355.png"} alt="Zipaworld" />*/}
                            {/*//////Christmas Theme ///////////////////*/}
                            <img className="lazyload" data-src={"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.7682568786368993-1711429836089.png"} alt="Zipaworld" />
                        </NavbarBrand>
                        <Collapse isOpen={this.state.isOpen} navbar className="width-fullcolla order-1 order-lg-0">
                            {localStorage.getItem("already_registered") === "true" ||
                            this.state.showLogin === true ? (
                                <>
                                    <Nav className="ml-auto align-items-center mobile-nots" navbar>
                                        <NavItem
                                            onClick={(e) => {
                                                this.props.history.push(PAGE_PATHS.MAIN_PAGE);
                                                window.location.reload();
                                            }}
                                        >
                                            <a className="nav-link">
                                                <FontAwesomeIcon icon={faHome} size="lg"/> Home
                                            </a>
                                        </NavItem>
                                        <NavItem
                                            onClick={(e) => {
                                                this.props.history.push(PAGE_PATHS.ABOUT_PAGE);
                                                window.location.reload();
                                            }}
                                        >
                                            <a className="nav-link">About Us</a>
                                        </NavItem>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                Product
                                            </DropdownToggle>
                                            <DropdownMenu className="product_dropdown" right>
                                                <div
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.AIR_FREIGHT);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Air Freight{" "}
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.OCEAN_FREIGHT);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Sea Freight
                                                    </DropdownItem>
                                                </div>

                                                <div
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.ROAD_FREIGHT);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Road,Rail & Inland Freight
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.COURIER_SERVICES);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Courier Services
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.WAREHOUSING_SERVICES);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Warehousing
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.CUSTOMS_BROKERAGE);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Customs Brokerage & Documentation
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.PACKAGE_RELOCATIONS);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Packaging & Relocations
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.OTHER_SERVICES);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Trade Finance
                                                    </DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                Explore!
                                            </DropdownToggle>
                                            <DropdownMenu className="product_dropdown" right>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.TEAM_SECTION);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Our Team
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.AWARDS_PAGE);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Awards and Certifications
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.LIFE_AT_ZIPA);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Life At Zipaworld
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.PRICING_PLAN);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Membership Plan
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.BLOG_PAGE);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Our Blog
                                                    </DropdownItem>
                                                </div>

                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.JOB_OPENINGS);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Career
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.OFFER_PAGE_OTHER);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        Offer
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(PAGE_PATHS.FAQ_SECTION);
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>FAQ</DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <NavItem
                                            className=""
                                            onClick={(e) => {
                                                // e.preventDefault()
                                                this.props.history.push(PAGE_PATHS.SEARCH_SHIPMENTS);
                                                window.location.reload();
                                            }}
                                        >
                                            <a className="nav-link"> Tracking</a>
                                        </NavItem>
                                        <NavItem
                                            className=""
                                            onClick={(e) => {
                                                this.props.history.push(PAGE_PATHS.VESSEL_SCHEDULES);
                                                window.location.reload();
                                            }}
                                        >
                                            <a className="nav-link"> Vessel Schedules</a>
                                        </NavItem>
                                        <NavItem className="mr-2">
                                            <a href="https://rzp.io/l/zipa" className="nav-link">
                                                Make Payment
                                            </a>
                                        </NavItem>

                                        <UncontrolledDropdown className="dropdown-menu-right loginButtonChange nav-notdisplay mr-3">
                                            <DropdownToggle className="p-0  login-back" color="">
                <span className="icon-text textLogin">
                  <span
                      className={
                          localStorage.getItem("already_registered") === "true"
                              ? "mob-not loginTextChange"
                              : "mob-not"
                      }
                  >
                    <span style={{ textTransform: "capitalize" }}>
                      {userName && userName === "Login" ? "" : "Hi "}
                    </span>
                      {userName}
                  </span>
                </span>
                                            </DropdownToggle>
                                            <DropdownMenu className="mt-3" right>
                                                <div
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.STATICS);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem
                                                        style={{ fontWeight: "700" }}
                                                        hidden={
                                                            !(
                                                                this.state.showLogin === true ||
                                                                (localStorage.getItem("already_registered") ===
                                                                    "true" &&
                                                                    localStorage.getItem("auth_key") !== undefined)
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faTachometerAlt} className="my-iconss" size="lg"/>statics
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        if(this.state.activeQueryType === "AirQuery"){
                                                            this.props.history.push(PAGE_PATHS.shipmentHistoryAir);
                                                            window.location.reload();
                                                        }else if(this.state.activeQueryType === "OceanQuery"){
                                                            this.props.history.push(PAGE_PATHS.shipmentHistory);
                                                            window.location.reload();
                                                        }else if(this.state.activeQueryType === "CourierQuery"){
                                                            this.props.history.push(PAGE_PATHS.shipmentHistoryCourier);
                                                            window.location.reload();
                                                        }else if(this.state.activeQueryType === "RoadQuery"){
                                                            this.props.history.push(PAGE_PATHS.shipmentHistoryRoad);
                                                            window.location.reload();
                                                        }
                                                    }}
                                                >
                                                    <DropdownItem
                                                        style={{ fontWeight: "700" }}
                                                        hidden={
                                                            !(
                                                                this.state.showLogin === true ||
                                                                (localStorage.getItem("already_registered") ===
                                                                    "true" &&
                                                                    localStorage.getItem("auth_key") !== undefined)
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faFileAlt} className="my-iconss" size="lg"/> Past Shipment
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.SHIPPER_INVOICE_MANAGER);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem
                                                        style={{ fontWeight: "700" }}
                                                        hidden={
                                                            !(
                                                                this.state.showLogin === true ||
                                                                (localStorage.getItem("already_registered") ===
                                                                    "true" &&
                                                                    localStorage.getItem("auth_key") !== undefined)
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" className="my-iconss"/>  Invoices
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.EDOCKET_MANAGER);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem
                                                        style={{ fontWeight: "700" }}
                                                        hidden={
                                                            !(
                                                                this.state.showLogin === true ||
                                                                (localStorage.getItem("already_registered") ===
                                                                    "true" &&
                                                                    localStorage.getItem("auth_key") !== undefined)
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faReceipt} size="lg" className="my-iconss"/> Documents
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                    onClick={(e) => {

                                                        setTimeout(() => {
const custType = localStorage.getItem("typeOfBussiness")

                                                            if(custType === "Individual"){
                                                                this.props.history.push(PAGE_PATHS.CUSTOMER_KYC_FOR_INDIVIDUAL)
                                                            }else{
                                                                this.props.history.push(PAGE_PATHS.CUSTOMER_KYC)
                                                            }
                                                            window.location.reload();
                                                          }, 500);

                                                        
                                                    }}
                                                >
                                                    <DropdownItem
                                                        style={{ fontWeight: "700" }}
                                                        hidden={
                                                            !(
                                                                this.state.showLogin === true ||
                                                                (localStorage.getItem("already_registered") ===
                                                                    "true" &&
                                                                    localStorage.getItem("auth_key") !== undefined)
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faUserCircle} className="my-iconss" size="lg"/> Profile
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.FRIGHT_MANAGEMENT);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem
                                                        style={{ fontWeight: "700" }}
                                                        hidden={
                                                            !(
                                                                this.state.showLogin === true ||
                                                                (localStorage.getItem("already_registered") ===
                                                                    "true" &&
                                                                    localStorage.getItem("auth_key") !== undefined)
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} className="my-iconss" size="lg"/> Freight Check
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        this.props.history.push(PAGE_PATHS.FAQ_SECTION);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem
                                                        style={{ fontWeight: "700" }}
                                                        hidden={
                                                            !(
                                                                this.state.showLogin === true ||
                                                                (localStorage.getItem("already_registered") ===
                                                                    "true" &&
                                                                    localStorage.getItem("auth_key") !== undefined)
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faQuestionCircle} className="my-iconss" size="lg"/> Faq
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        this.props.history.push(
                                                            PAGE_PATHS.SHIPPER_CONSIGNEE_MANAGER
                                                        );
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <DropdownItem
                                                        style={{ fontWeight: "700" }}
                                                        hidden={
                                                            !(
                                                                this.state.showLogin === true ||
                                                                (localStorage.getItem("already_registered") ===
                                                                    "true" &&
                                                                    localStorage.getItem("auth_key") !== undefined)
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faUserEdit} className="my-iconss" size="lg"/>Shipper/Consignee
                                                    </DropdownItem>
                                                </div>

                                                <DropdownItem
                                                    style={{ fontWeight: "700" }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        localStorage.getItem("already_registered") === "true"
                                                            ? this.handleLogout()
                                                            : this.handleLogin();
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="my-iconss"/> {" "}
                                                    {localStorage.getItem("already_registered") === "true" ||
                                                    this.state.showLogin === true
                                                        ? "SIGN OUT"
                                                        : "CUSTOMER LOGIN"}
                                                </DropdownItem>
                                                <div
                                                    hidden={
                                                        this.state.showLogin === true ||
                                                        (localStorage.getItem("already_registered") === "true" &&
                                                            localStorage.getItem("auth_key") !== undefined)
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location = "https://user.zipaworld.com/";
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        <FontAwesomeIcon icon={faSignOutAlt} className="my-iconss" size="lg"/> ZIPA TEAM
                                                        LOGIN
                                                    </DropdownItem>
                                                </div>
                                                <div
                                                    hidden={
                                                        this.state.showLogin === true ||
                                                        (localStorage.getItem("already_registered") === "true" &&
                                                            localStorage.getItem("auth_key") !== undefined)
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location = "https://partner.zipaworld.com/";
                                                    }}
                                                >
                                                    <DropdownItem style={{ fontWeight: "500" }}>
                                                        <FontAwesomeIcon icon={faSignOutAlt} className="my-iconss" size="lg"/> PARTNER LOGIN
                                                    </DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <NavItem className="mr-2">
                                            <div className=" display_tour demoCssIcon">
                                                <a
                                                    target="_blank"
                                                    // href="https://youtu.be/MOW0ws21dwg"
                                                    href="https://youtu.be/8zG7N3Lnly8"
                                                    style={{ color: "#fff" }}
                                                >
                                                    <FontAwesomeIcon icon={faYoutube} size="lg"/>
                                                </a>
                                            </div>
                                            <a
                                                className="nav-link display_tour1 demoCssIcon"
                                                target="_blank"
                                                // href="https://youtu.be/MOW0ws21dwg"
                                                href="https://youtu.be/8zG7N3Lnly8"
                                                style={{ color: "#fff" }}
                                            >
                                                <FontAwesomeIcon icon={faYoutube} size="lg"/>{" "}
                                            </a>
                                        </NavItem>
                                        {/*<NavItem className="full-height" onClick={e => {*/}
                                        {/*    this.props.history.push(PAGE_PATHS.DASHBOARD)*/}
                                        {/*    window.location.reload();*/}
                                        {/*}}>*/}
                                        {/*    <a className="nav-link"><i className="fa fa-paper-plane" style={{color:'#fff'}}/>New Quotation</a>*/}
                                        {/*</NavItem>*/}
                                        {this.state.showLogin === true ||
                                        (localStorage.getItem("already_registered") === "true" &&
                                            localStorage.getItem("auth_key") !== undefined) ? (
                                            <Notification />
                                        ) : undefined}
                                    </Nav>
                                    <Nav className="mr-auto menu-other align-items-center" navbar>
                                        <NavItem
                                            onClick={(e) => {
                                                if(this.state.activeQueryType === "AirQuery"){
                                                    this.props.history.push(PAGE_PATHS.shipmentHistoryAir);
                                                    window.location.reload();
                                                }else if(this.state.activeQueryType === "OceanQuery"){
                                                    this.props.history.push(PAGE_PATHS.shipmentHistory);
                                                    window.location.reload();
                                                }else if(this.state.activeQueryType === "CourierQuery"){
                                                    this.props.history.push(PAGE_PATHS.shipmentHistoryCourier);
                                                    window.location.reload();
                                                }else if(this.state.activeQueryType === "RoadQuery"){
                                                    this.props.history.push(PAGE_PATHS.shipmentHistoryRoad);
                                                    window.location.reload();
                                                }

                                            }}
                                        >
                                            <a className="nav-link">
                                                <FontAwesomeIcon icon={faFileAlt} size="lg"/> Past Shipment
                                            </a>
                                        </NavItem>

                                        <NavItem
                                            onClick={(e) => {
                                                this.props.history.push(PAGE_PATHS.SHIPPER_CONSIGNEE_MANAGER);
                                                window.location.reload();
                                            }}
                                        >
                                            <a className="nav-link">
                                                <FontAwesomeIcon icon={faUserEdit} size="lg"/> Shipper /Consignee
                                            </a>
                                        </NavItem>

                                        <NavItem
                                            onClick={(e) => {
                                                this.props.history.push(PAGE_PATHS.EDOCKET_MANAGER);
                                                window.location.reload();
                                            }}
                                        >
                                            <a className="nav-link">
                                                <FontAwesomeIcon icon={faReceipt} size="lg"/> Documents
                                            </a>
                                        </NavItem>
                                        <NavItem
                                            onClick={(e) => {
                                                this.props.history.push(PAGE_PATHS.SHIPPER_INVOICE_MANAGER);
                                                window.location.reload();
                                            }}
                                        >
                                            <a className="nav-link">
                                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg"/> Invoices
                                            </a>
                                        </NavItem>
                                        <NavItem
                                            onClick={(e) => {

                                                setTimeout(() => {
                                                    const custType = localStorage.getItem("typeOfBussiness")
                                                    
                                                                                                                if(custType === "Individual"){
                                                                                                                    this.props.history.push(PAGE_PATHS.CUSTOMER_KYC_FOR_INDIVIDUAL)
                                                                                                                }else{
                                                                                                                    this.props.history.push(PAGE_PATHS.CUSTOMER_KYC)
                                                                                                                }
                                                                                                                window.location.reload();
                                                                                                              }, 500);
                                                    
                                                                                                            
                                                                                                        }}
                                        >
                                            <a className="nav-link">
                                                <FontAwesomeIcon icon={faUserCircle} size="lg"/> Profile
                                            </a>
                                        </NavItem>
                                    </Nav>
                                </>
                            ) : (
                                <Nav className="ml-auto align-items-center mobile-nots" navbar>
                                    {localStorage.getItem("already_registered") === "true" ? (
                                        <NavItem className="responsive-bars" onClick={(e) => {
                                                this.setState({showSideBar: !this.state.showSideBar,});}}>
                                            <a className="nav-link side-column">
                                                {this.state.showSideBar === true ? (<FontAwesomeIcon icon={faTimes} size="lg"/>) : (
                                                    <FontAwesomeIcon icon={faBars} size="lg"/>)}
                                            </a>
                                        </NavItem>
                                    ) : (
                                        ""
                                    )}
                                    <NavItem
                                        onClick={(e) => {
                                            this.props.history.push(PAGE_PATHS.MAIN_PAGE);
                                            window.location.reload();
                                        }}
                                    >
                                        <a className="nav-link">
                                            <FontAwesomeIcon icon={faHome} size="lg"/> Home
                                        </a>
                                    </NavItem>

                                    <NavItem
                                        onClick={(e) => {
                                            this.props.history.push(PAGE_PATHS.ABOUT_PAGE);
                                            window.location.reload();
                                        }}
                                    >
                                        <a className="nav-link">About Us</a>
                                    </NavItem>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            Product
                                        </DropdownToggle>
                                        <DropdownMenu className="product_dropdown" right>
                                            <div
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.AIR_FREIGHT);
                                                    window.location.reload();
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Air Freight{" "}
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.OCEAN_FREIGHT);
                                                    window.location.reload();
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Sea Freight
                                                </DropdownItem>
                                            </div>

                                            <div
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.ROAD_FREIGHT);
                                                    window.location.reload();
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Road,Rail & Inland Freight
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.COURIER_SERVICES);
                                                    window.location.reload();
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Courier Services
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.WAREHOUSING_SERVICES);
                                                    window.location.reload();
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Warehousing
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.CUSTOMS_BROKERAGE);
                                                    window.location.reload();
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Customs Brokerage & Documentation
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.PACKAGE_RELOCATIONS);
                                                    window.location.reload();
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Packaging & Relocations
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.OTHER_SERVICES);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Trade Finance
                                                </DropdownItem>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            Explore!
                                        </DropdownToggle>
                                        <DropdownMenu className="product_dropdown" right>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.TEAM_SECTION);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Our Team
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.AWARDS_PAGE);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Awards and Certifications
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.LIFE_AT_ZIPA);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Life At Zipaworld
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.PRICING_PLAN);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Membership Plan
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.BLOG_PAGE);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Our Blog
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.FAQ_SECTION);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>FAQ</DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.JOB_OPENINGS);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    Career
                                                </DropdownItem>
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(PAGE_PATHS.OFFER_PAGE_OTHER);
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>Offer</DropdownItem>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem
                                        onClick={(e) => {
                                            this.props.history.push(PAGE_PATHS.SEARCH_SHIPMENTS);
                                            window.location.reload();
                                        }}
                                    >
                                        <a className="nav-link"> Tracking</a>
                                    </NavItem>
                                    <NavItem className="mr-2 ">
                                        <a href="https://rzp.io/l/zipa" className="nav-link">
                                            Make Payment
                                        </a>
                                    </NavItem>
                                    <UncontrolledDropdown nav inNavbar className="dropdown-menu-right nav-notdisplay loginButtonChange mr-3">
                                        <DropdownToggle className="p-0  login-back" color="">
                                          <span className="icon-text textLogin">
                                            <span className={localStorage.getItem("already_registered") === "true" ? "mob-not loginTextChange" : "mob-not"}>
                                              <span style={{ textTransform: "capitalize" }}>{userName && userName === "Login" ? "" : "Hi "}</span>{" "}{userName}
                                            </span>
                                          </span>
                                        </DropdownToggle>
                                        <DropdownMenu className="mt-3" right>
                                            <div hidden={
                                                    this.state.showLogin === true ||
                                                    (localStorage.getItem("already_registered") === "true" &&
                                                        localStorage.getItem("auth_key") !== undefined)
                                                }
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.STATICS);
                                                    window.location.reload();
                                                }}
                                            >
                                                <DropdownItem
                                                    style={{ fontWeight: "700" }}
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faFileAlt} size="lg" className="my-iconss"/> DashBoard
                                                </DropdownItem>
                                            </div>
                                            <DropdownItem
                                                style={{ fontWeight: "700" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    localStorage.getItem("already_registered") === "true"
                                                        ? this.handleLogout()
                                                        : this.handleLogin();
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="my-iconss"/>{" "}
                                                {localStorage.getItem("already_registered") === "true" ||
                                                this.state.showLogin === true
                                                    ? "Sign Out"
                                                    : "CUSTOMER LOGIN"}
                                            </DropdownItem>
                                            <div
                                                hidden={
                                                    this.state.showLogin === true ||
                                                    (localStorage.getItem("already_registered") === "true" &&
                                                        localStorage.getItem("auth_key") !== undefined)
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location = "https://user.zipaworld.com/";
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="my-iconss"/>  ZIPA TEAM
                                                    LOGIN
                                                </DropdownItem>
                                            </div>
                                            <div
                                                hidden={
                                                    this.state.showLogin === true ||
                                                    (localStorage.getItem("already_registered") === "true" &&
                                                        localStorage.getItem("auth_key") !== undefined)
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location = "https://partner.zipaworld.com/";
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    <FontAwesomeIcon icon={faSignOutAlt} className="my-iconss" size="lg"/> PARTNER LOGIN
                                                </DropdownItem>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem className="">
                                        <div className=" display_tour demoCssIcon">
                                            <a target="_blank"
                                                href="https://youtu.be/8zG7N3Lnly8"
                                                style={{ color: "#fff" }}>
                                                <FontAwesomeIcon icon={faYoutube} size="lg"/>
                                            </a>
                                        </div>
                                        <a className="nav-link display_tour1 demoCssIcon"
                                            target="_blank"
                                            href="https://youtu.be/8zG7N3Lnly8"
                                            style={{ color: "#fff" }}>
                                            <FontAwesomeIcon icon={faYoutube} size="lg"/>{" "}
                                        </a>
                                    </NavItem>
                                </Nav>
                            )}
                            {this.state.showLogin === true ||
                            (localStorage.getItem("already_registered") === "true" &&
                                localStorage.getItem("auth_key") !== undefined)
                                ? ""
                                : undefined}
                        </Collapse>

                        <div className="order-0 header-mobile">
                            <div className="width-full ml-auto d-flex align-items-center">
                                <div className="d-inline-block d-flex">
                                    <DropdownToggle
                                        className="p-0  login-back change-mob1 change-mob mr-1"
                                        style={{ borderColor: "#ff0600!important" }}
                                        onClick={(e) => {
                                            this.props.history.push(PAGE_PATHS.MAIN_PAGE);
                                            window.location.reload();
                                        }}>
                                    <span className="icon-text">
                                      <FontAwesomeIcon icon={faHome} size="lg"/> <span className="mob-not"></span>
                                    </span>
                                    </DropdownToggle>
                                    <UncontrolledDropdown className="dropdown-menu-right">
                                        <DropdownToggle className="p-0  login-back change-mob1 change-mob">
                                            <span className="icon-text">
                                                <FontAwesomeIcon icon={faUser} size="lg"/>{" "}
                                                <span className="mob-not">{userName}</span>
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu className="mt-3" right>
                                            <div hidden={
                                                    this.state.showLogin === true ||
                                                    (localStorage.getItem("already_registered") === "true" &&
                                                        localStorage.getItem("auth_key") !== undefined)}
                                                onClick={(e) => {
                                                    this.props.history.push(PAGE_PATHS.STATICS);
                                                    window.location.reload();
                                                }}>
                                                <DropdownItem
                                                    style={{ fontWeight: "700" }}
                                                    hidden={
                                                        !(
                                                            this.state.showLogin === true ||
                                                            (localStorage.getItem("already_registered") === "true" &&
                                                                localStorage.getItem("auth_key") !== undefined)
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faFileAlt} size="lg" className="my-iconss"/> DashBoard
                                                </DropdownItem>
                                            </div>

                                            <DropdownItem
                                                style={{ fontWeight: "700" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    localStorage.getItem("already_registered") === "true"
                                                        ? this.handleLogout()
                                                        : this.handleLogin();
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="my-iconss"/> {" "}
                                                {localStorage.getItem("already_registered") === "true" ||
                                                this.state.showLogin === true
                                                    ? "Sign Out"
                                                    : "CUSTOMER LOGIN"}
                                            </DropdownItem>
                                            <div hidden={
                                                    this.state.showLogin === true ||
                                                    (localStorage.getItem("already_registered") === "true" &&
                                                        localStorage.getItem("auth_key") !== undefined)
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location = "https://user.zipaworld.com/";
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "700" }}>
                                                    <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="my-iconss"/> ZIPA TEAM
                                                    LOGIN
                                                </DropdownItem>
                                            </div>
                                            <div
                                                hidden={
                                                    this.state.showLogin === true ||
                                                    (localStorage.getItem("already_registered") === "true" &&
                                                        localStorage.getItem("auth_key") !== undefined)
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location = "https://partner.zipaworld.com/";
                                                }}
                                            >
                                                <DropdownItem style={{ fontWeight: "500" }}>
                                                    <FontAwesomeIcon icon={faSignOutAlt} className="my-iconss" size="lg"/> PARTNER LOGIN
                                                </DropdownItem>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>

                                <NavbarToggler onClick={this.toggle} />
                                <div className="query-prev d-inline-block">
                                    {this.state.showLogin === true ||
                                    (localStorage.getItem("already_registered") === "true" &&
                                        localStorage.getItem("auth_key") !== undefined) ? (
                                        <Notification />
                                    ) : undefined}
                                </div>
                            </div>
                        </div>
                        {this.state.modalLarge ?
                        <SignUp
                            modalLarge={this.state.modalLarge}
                            setmodal={(values) =>
                                values === false ? this.setState({ modalLarge: false }) : ""
                            }
                            openLogin={true}
                        />:""}
                    </Navbar>
                </div>
                {locationHome ===  ""?"":
                    <div className="powered-by bg-white">

                        {locationHome ===  "" ||locationBlog ===  "BlogDescription" || locationHome === "BlogPage" || locationHome === "NewsListing" || locationHome === "Home"? "":
                            <p>Copyright @ Zipaworld Innovation Pvt. Ltd.</p>
                        }
                    </div>
                }
            </>

        )}
}

const mapStateToProps = (state) => {
    const {containerClassnames, menuClickCount, selectedMenuHasSubItems} = state.menu;
    const {messageShowShipper} = state.authUser;
    let {notificationList} = state.notificationData;
    const {locale} = state.settings;
    const {OpenOffer} = state.advanceSearchReducer;
    return {
        OpenOffer,
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
        locale,
        messageShowShipper,
        notificationList
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        {
            setContainerClassnames,
            clickOnMobileMenu,
            logoutUser,
            changeLocale,
            onNotificationLoadRequest,
            OpenOfferSection,
            changeCurrency,
            readNotificationHit
        }
    )(TopNav)
);
