import {
    QUERY_PREVIEW_DATA,
    QUERY_PREVIEW_DATA_SUCCESS,
    QUERY_PREVIEW_DATA_FAILURE,

} from "../actions";



/**
 *
 * @QueryPreview
 */
export const getQueryPreviewData = (data) => ({
    type: QUERY_PREVIEW_DATA,
    payload:data,
});
export const getQueryPreviewDataSuccess = (data) => ({
    type: QUERY_PREVIEW_DATA_SUCCESS,
    payload: data,
});
export const getQueryPreviewDataFailuer = (data) => ({
    type:QUERY_PREVIEW_DATA_FAILURE,
    payload:data,
});
