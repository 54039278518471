import {
    GST_NUMBER_TO_PERFORMA,
    MAWB_UPDATE,
    MAWB_UPDATE_RESPONSE
} from "../actions";

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
};


const mawbReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case MAWB_UPDATE:
           // console.log("In mawb Deatils update  " + JSON.stringify(payload));
            return {...state, loading: false, bookingId: payload};


            case GST_NUMBER_TO_PERFORMA:
           // console.log("In mawb Deatils update  " + JSON.stringify(payload));
            return {...state, loading: false, gstNumber: payload};

        case MAWB_UPDATE_RESPONSE:
           // console.log("in mawb Deatils update responce"+JSON.stringify(payload));
            return {...state, loading: false, responce: payload.message, error: ''};

        default:
            return initState;
    }
};

export default mawbReducer;
