import React, {Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import FooterHome from "./FooterHome";

class SeprateNews extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {

        return (
            <AppLayout>

                <FooterHome/>
            </AppLayout>
        );
    }


}

export default SeprateNews;
