import React, { Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {
    faAngleDown, faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RestClient from "../../../util/RestClient";
import {
    COURIER_API_TRACKING, E_DOCKET_REQUEST_REQUEST_COURIER,
    E_DOCKET_REQUEST_REQUEST_ROAD,
    ROAD_API_TRACKING,
    SEARCH_TRACKING_BY_BOOKING_REFERENCE_NO
} from "../../../constants";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {convertDateISOToCustomiseFormat} from "../../../util/CommonUtility";
import SignUp from "../../user/signUp";
import {Button} from "reactstrap";


class TrackingNewPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modes : "Air",
            viewDetails:false,
            pickupConfirm:false,
            pickedUp:false,
            outForDelivered:false,
            delivered:false,
            expectedDeliveryDate:"",
            deliveryDate:"",
            activityTypeStatus:"",
            modelLogin : false,
        };

    }
    componentDidMount() {
        let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        let TrackNo = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let quoteId = window.location.href.split("/")[window.location.href.split("/").length - 3];
        this.setState({quoteFor : quoteFor,quoteId:quoteId},()=>{
            if(this.state.quoteId && this.state.quoteId !== "TrackShipment"){
                this.quoteIdApiCalling({id : quoteId,quoteFor : quoteFor}).then((responce)=>{
                    if (responce.success) {
                        let QueryResult = responce && responce.result ? responce.result : "";
                        this.setState({
                            buttonShowing : true,
                            invoiceId: QueryResult && QueryResult.invoice ? QueryResult.invoice._id:"",
                            approvalStatus: QueryResult && QueryResult.invoice && QueryResult.invoice.approvalStatus ? QueryResult.invoice.approvalStatus:"",
                        })
                    }
                })
            }
            if(this.state.quoteFor === "Courier"){
                this.courierTrackingApiCalling({ trackingNo: TrackNo}).then(responce => {
                    if(responce.success){
                        let courierTrackData = responce && responce.result && responce.result.data ? responce.result.data : []
                        let shipmentDetails =  responce && responce.result && responce.result.shipmentData ? responce.result.shipmentData : {}
                        courierTrackData.reverse()
                        this.setState({
                            courierTrackData : courierTrackData,
                            BlNo : shipmentDetails && shipmentDetails.blNo ? shipmentDetails.blNo :"",
                            pickUpPinCode : shipmentDetails && shipmentDetails.originDoor &&  shipmentDetails.originDoor.pincode ? shipmentDetails.originDoor.pincode :"",
                            dropOffPinCode : shipmentDetails && shipmentDetails.destinationDoor &&  shipmentDetails.destinationDoor.pincode ? shipmentDetails.destinationDoor.pincode :"",
                        },()=>{
                            let activityTypeStatus = this.state.courierTrackData && this.state.courierTrackData.length>0 && this.state.courierTrackData[0] && this.state.courierTrackData[0].activityType ? this.state.courierTrackData[0].activityType : ""
                            this.setState({
                                activityTypeStatus : activityTypeStatus
                            })
                            if(this.state.courierTrackData && this.state.courierTrackData.length>0){
                                this.state.courierTrackData.map((item,index)=>{
                                    if(this.state.expectedDeliveryDate == ""){
                                        if(item.expectedDeliveryDate){
                                            this.setState({
                                                expectedDeliveryDate : item.expectedDeliveryDate ? convertDateISOToCustomiseFormat(item.expectedDeliveryDate) : "",
                                            })
                                        }
                                    }
                                    if(item.sNo === 1){
                                        this.setState({
                                            pickupConfirm : true,
                                            pickupDate:item && item.date ? convertDateISOToCustomiseFormat(item.date) : "",
                                        })
                                    }
                                    if(item.sNo === 2){
                                        this.setState({
                                            pickedUp : true
                                        })
                                    }
                                    if(item.activityType === "Out For Delivery"){
                                        this.setState({
                                            outForDelivered : true
                                        })
                                    }
                                    if(item.activityType === "Delivered"){
                                        let deliveryDate = item && item.date ? convertDateISOToCustomiseFormat(item.date) : ""
                                        this.setState({
                                            delivered : true,
                                            deliveryDate: deliveryDate
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })

    }
    quoteIdApiCalling = async (dataForServer) => {
        return await RestClient({
            url: E_DOCKET_REQUEST_REQUEST_COURIER,
            method: "POST",
            data: dataForServer
        });
    };
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    courierTrackingApiCalling = async (dataForServer) => {
        return await RestClient({
            url: COURIER_API_TRACKING,
            method: "POST",
            data: dataForServer
        });
    };
    roadTrackingApiCalling = async (dataForServer) => {
        return await RestClient({
            url: ROAD_API_TRACKING,
            method: "POST",
            data: dataForServer
        });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {

        return (
            <>

                <MetaTags>
                    <title>Live Tracking  | Zipaworld </title>
                    <meta name="title" content="Live tracking for air, ocean, road cargo on Zipaworld" />
                    <meta name="description" content="Zipaworld helps in 24/7 live tracking of multimodal freight and cargo" />
                    <meta name="keywords" content="airwaybill tracking, AWB tracking, container tracking online, how to track my air cargo, track shipping container online" />
                </MetaTags>
                <AppLayout>
                    {this.state.modelLogin ?
                        <SignUp
                            modalLarge={this.state.modelLogin}
                            setmodal={(values) =>
                                values === false
                                    ? this.setState({ modelLogin: false })
                                    : ""
                            }
                        />
                        : ""}
                    <div className="dashboard-tracking">
                        <div className="tracking-shipment">
                            <div className="container"><h3 className="track-head">Tracking Details</h3>
                                <div className="stepper-wrapper">
                                    <div className={this.state.pickupConfirm ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">1</div>
                                        <div className="step-name">{this.state.quoteFor === "Courier" ? "Pickup Confirm" :"Pickup Generated"}</div>
                                    </div>
                                    <div className={this.state.pickedUp ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">2</div>
                                        <div className="step-name">{this.state.quoteFor === "Courier" ? "Picked Up" :"Goods Picked"}</div>
                                    </div>
                                    {this.state.quoteFor === "Road" ?
                                        <div className={this.state.pickedUp ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                            if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                                this.setState({
                                                    viewDetails: !this.state.viewDetails
                                                })
                                            }else{
                                                this.setState({
                                                    modelLogin : true
                                                })
                                            }
                                        }}>
                                            <div className="step-counter">3</div>
                                            <div className="step-name">Received At Hub/Warehouse</div>
                                        </div>
                                    :""}
                                    <div className={this.state.outForDelivered ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">{this.state.quoteFor === "Road" ? 4 : 3}</div>
                                        <div className="step-name">Out For Delivery</div>
                                    </div>
                                    <div className={this.state.delivered ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">{this.state.quoteFor === "Road" ? 5 : 4}</div>
                                        <div className="step-name">Delivered</div>
                                    </div>
                                </div>
                                <div className="tracking-search">
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="tracking-No ">
                                            <div className="heading-track">Docket Number</div>
                                            <div className="tracking-left">{this.state.BlNo}</div>
                                        </div>
                                        <div className="tracking-No ">
                                            <div className="heading-track">Pick Up</div>
                                            <div className="tracking-left">{this.state.pickUpPinCode}</div>
                                        </div>
                                        <div className="tracking-No ">
                                            <div className="heading-track">Drop Off</div>
                                            <div className="tracking-left">{this.state.dropOffPinCode}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="tracking_details">
                                    <div className="d-flex flex-wrap justify-content-between align-stretched">
                                        <div className="d-flex flex-wrap align-items-center dd_track width-80">
                                            <img src="../../../assets/img/location.png" alt="Location Icon"/>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between width-80">
                                                <div className="">
                                                    <div className="heading-track">Pick Up Date</div>
                                                    <div className="trackingDate">{this.state.pickupDate}</div>
                                                </div>
                                                <div className="">
                                                    <div className="heading-track">Date of Delivery</div>
                                                    <div className="trackingDate">{this.state.deliveryDate ? this.state.deliveryDate : this.state.expectedDeliveryDate}</div>
                                                </div>
                                                <div className="">
                                                    <div className="heading-track">Current Status</div>
                                                    <div className="trackingDate">{this.state.activityTypeStatus}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="view-btn" onClick={()=>{
                                            if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                                this.setState({
                                                    viewDetails: !this.state.viewDetails
                                                })
                                            }else{
                                                this.setState({
                                                    modelLogin : true
                                                })
                                            }
                                        }}>More Details {" "}{this.state.viewDetails === true ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>}
                                        </div>
                                    </div>
                                    {this.state.viewDetails ?
                                        <div className="more-details">
                                            <div id="top" style={{padding : 0}}>
                                                <div className="table-responsive tableHeightTracking trackingCourierDesign">
                                                    <table className="table airine_revert_table">
                                                        <thead>
                                                        <tr>
                                                            <th >Location</th>
                                                            <th >Status</th>
                                                            <th >Date</th>
                                                            <th>Time</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {this.state.quoteFor === "Courier" ?
                                                            <>
                                                                {this.state.courierTrackData && this.state.courierTrackData.map((obj, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{obj.hubLocation}</td>
                                                                                <td>{obj.activityType}</td>
                                                                                <td>{obj.date ? convertDateISOToCustomiseFormat(obj.date) : ""}</td>
                                                                                <td>{obj.time}</td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </>

                                                        :
                                                            <>
                                                                {this.state.courierTrackData && this.state.courierTrackData.map((obj, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{obj.events?.activityType}</td>
                                                                                <td>{obj.events?.vehicle}</td>
                                                                                <td>{obj?.events?.sNo === 1 ? obj.events?.pickupDate ? convertDateISOToCustomiseFormat(obj.events?.pickupDate) : "" :
                                                                                    obj.events?.date ? convertDateISOToCustomiseFormat(obj.events?.date) : ""}</td>
                                                                                <td>{obj.events?.time}</td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </>
                                                        }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    :""}

                                </div>
                            </div>
                        </div>
                    </div>

                </AppLayout>
                <div className="fixed-btnsbot fixed-btnsbot1">
                    <div className="d-flex flex-wrap justify-content-end">
                        {this.state.buttonShowing ?
                            <Button
                                onClick={e => {
                                    e.preventDefault()
                                    history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + "/" + this.state.quoteFor + "/" +this.state.quoteId)
                                    window.location.reload("false")

                                }}
                                type="button"
                                className="btn delete border-cir"
                            >Previous</Button>:""}
                        <Button onClick={e => {
                            e.preventDefault()
                            history.push(PAGE_PATHS.DASHBOARD)
                            window.location.reload("false")
                        }}
                                type="button"
                                className="btn delete border-cir"
                        >Home</Button>
                        {this.state.buttonShowing ?
                            <Button onClick={e => {
                                e.preventDefault()
                                if(this.state.approvalStatus === "Approved"){
                                    history.push(PAGE_PATHS.VIEW_FOR_INVOICE_MANAGER + "/" + this.state.quoteFor + "/" + this.state.invoiceId)
                                    window.location.reload("false")
                                }else{
                                    history.push(PAGE_PATHS.DASHBOARD)
                                    window.location.reload("false")
                                }

                            }}
                                    type="button"
                                    className="btn delete border-cir"
                            >Next</Button>:""}

                    </div>
                </div>

            </>
        )
    }
}




export default TrackingNewPage;

