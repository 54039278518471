import React, { Component } from "react";

 class Ocean extends Component {
     constructor(props) {
         super(props);
         this.state = {
         };
     }
     componentDidMount() {
        localStorage.setItem("queryType","OceanQuery")
        this.props.history.push("/");
     }
    render() {
        return(
           <></>
        )
     
    }
}
export default Ocean;