import React, { Component, Suspense} from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "./helpers/Firebase";
import AppLocale from "./lang";
import ColorSwitcher from "./components/common/ColorSwitcher";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import { isMultiColorActive, isDemo } from "./constants/defaultValues";
import { getDirection } from "./helpers/Utils";
import Row from "react-bootstrap/Row";
import Container from "@material-ui/core/Container";
import { ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import Routers from "./router";
import "./assets/css/fonts.css";
import {CREATE_PAGE_LOGS} from "./constants";
import RestClient from "./util/RestClient";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css';

// Importing Loader Component
import Loader from "../src/components/loader/Loader";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer : 0,
      intervalStatus: true,
    };

    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }
  }
naigateToRoute = () =>{
  const { locale, loginUser } = this.props;
    const currentAppLocale = AppLocale[locale];
  let oldPageName = sessionStorage.getItem('pageName') || ''
  let oldPageTimer = sessionStorage.getItem('pageTimer') || 0
  let oldTimer = this.state.timer
  let urlName = window.location.href.split("/")[3];
  if (urlName == ""){
    urlName = "Home"
  }
  if(oldPageName != urlName){
    // alert('timr'+oldPageTimer)
    let body = {
      pageName : oldPageName,
      pageTime : oldPageTimer
    }
    this.createLog(body)
  }
  sessionStorage.setItem('pageName',urlName)
  sessionStorage.setItem('pageTimer',this.state.timer)
  return (
    <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
    <React.Fragment>
            <NotificationContainer />
            <ToastContainer autoClose={2000} position="top-center" />
            <Container className="center-column">
              <Row>
                <Col>{/*<Messaging />*/}</Col>
              </Row>
            </Container>

            {isMultiColorActive && <ColorSwitcher />}
            {/*<Routers/>*/}
            <Suspense
              fallback={
                <Loader />

              }
            >
            <Routers />
              
            </Suspense>

          </React.Fragment>
        </IntlProvider>

  )
}
createLog = async (dataForServer) => {
  return await RestClient({
      url: CREATE_PAGE_LOGS,
      method: "POST",
      data: dataForServer
  });
}
  render() {
    let timer = this.state.timer
    setTimeout(()=>{
       this.setState({timer : timer+1})
    },1000)
    // requestFirebaseNotificationPermission()
    //     .then((firebaseToken) => {
    //       console.log("abcToken : ",firebaseToken);
    //       if(!localStorage.getItem("firebaseBrowserId") === false){
    //         localStorage.setItem("firebaseBrowserId",firebaseToken)
    //       }
    //
    //     })
    //     .catch((err) => {
    //       return err;
    //     });
    return (
      <div className="h-100">
        
          {this.naigateToRoute()}
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user: loginUser } = authUser;
  const { locale } = settings;
  return { loginUser, locale };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
