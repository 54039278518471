import React, { Component } from "react";
import Slider from "react-slick";

 class testimonialsReviews extends Component {
    render() {
        const settingsMy = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
            speed: 500
        };
        return (
            <div>
                <h2>Center Mode</h2>
                <div className="container testimonial_reviews">
                <Slider {...settingsMy}>
                    <div>
                        <h3>1</h3>
                    </div>
                    <div>
                        <h3>2</h3>
                    </div>
                    <div>
                        <h3>3</h3>
                    </div>
                    <div>
                        <h3>4</h3>
                    </div>
                    <div>
                        <h3>5</h3>
                    </div>
                    <div>
                        <h3>6</h3>
                    </div>
                </Slider>
                </div>
            </div>
        );
    }
}
export default testimonialsReviews;