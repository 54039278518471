import React, { Component } from "react";
import {toast } from "react-toastify";
import {
    convertDateISO,
    convertDateISOToCustomiseFormat,
} from "../util/CommonUtility";
import {
    RATE_SINGLE_DATA,
    RATE_SINGLE_DATA_AIR,
    RATE_SINGLE_DATA_OCEAN,
    RATE_SINGLE_DATA_ROAD,
    RATE_SINGLE_DATA_COURIER,
    GET_DATA_ADDINTENEDED_PLAN_API,
    GET_ALL_SLAB_RATE_OCEAN,
    CURRENCY_RATE_CONVERSION_API,
    REMOVE_COUPAN,
    GUEST_CUSTOMER_DATA,
    GET_ALL_SLAB_RATE_AIR,

} from "../constants";
import RestClient from "../util/RestClient";
import SlabRatePoup from "../views/app/dashboards/SlabRatePopup";
import AwaitedPopupShipment from "../views/app/dashboards/AwaitedPopupShipment";
import SlabRatePopupForOcean from "../views/app/dashboards/SlabRatePopupForOcean";
import {
    faChevronDown, faChevronUp,
    faDollarSign,
    faEuroSign,
    faGift,
    faPlane, faPoundSign,
    faRupeeSign,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class FilterRates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSlabRateOpen: "",
            availableIntends: [],
            data: {},
            isSlabRateOpenForOcean: "",
            title: "title",
            product: "product",
            detail: "detail",
            percent: 80,
            payLarge: false,
            progressText: "8/10",
            creditDays: "",
            BookingID: "1",
            login: undefined,
            creditDaysSTatus: false,
            showCollaps: false,
            showCollapsForRate: false,
            switchCurrency: true,
            rateIdSelected:"",
            selectedFreightId:"",

        };
    }
    custDetails = () => {
        let typeOfCust = JSON.parse(localStorage.getItem("customer"));
        const custType =
            typeOfCust &&
            typeOfCust.result &&
            typeOfCust.result.customerData &&
            typeOfCust.result.customerData.typeOfBussiness;
        const custKycStatus =
            typeOfCust &&
            typeOfCust.result &&
            typeOfCust.result.customerBranchData &&
            typeOfCust.result.customerBranchData.kycStatus;
        return { typeOfCust, custType, custKycStatus };
    };
    checkUser = () => {
        let typeOfCust = JSON.parse(localStorage.getItem("customer"));
        if (typeOfCust) {
            return true;
        } else {
            return false;
        }
    };
    sendToIntendedTransportPlan = (item) => {
        let QuoteId = this.state.quoteId;
        let intendedTransportPlan = [];
        let quoteId = QuoteId;
        intendedTransportPlan.push({
            id: item._id,
            from: item.originDetails.name,
            to: item.destinationDetails.name,
            vesselNo: item.vessel,
            voyageNo: item.voyageNo,
            eta: item.arrival,
            etd: item.departure,
        });

        let availableIntends = this.state.availableIntends;
        availableIntends.push({
            id: item._id,
            from: item.originDetails.name,
            to: item.destinationDetails.name,
            vesselNo: item.vessel,
            voyageNo: item.voyageNo,
            eta: item.arrival,
            etd: item.departure,
        });
        this.setState(
            {
                availableIntends: availableIntends,
                intendedTransportPlan: intendedTransportPlan,
                quoteId: QuoteId,
            },
            () => {
                this.addIntenededPlan({ quoteId, intendedTransportPlan }).then((dd) => {
                    if (dd.success) {
                        toast.success("Planned For booking", {
                            position: "top-left",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error("Something Wrong Please Contact Customer Service", {
                            position: "top-left",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
            }
        );
    };
    switchCurrency = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        if (e.target.name == "switchCurrency") {
            this.setState(
                {
                    switchCurrency: !this.props.switchCurrency,
                },
                () => {}
            );
        }
    };
    switchNeedCredit = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                localStorage.setItem("creditDays", this.state.creditDays);
            }
        );
    };
    checkIdAvailable = (id) => {
        let st = false;
        for (let i = 0; i < this.state.availableIntends.length; i++) {
            if (id == this.state.availableIntends[i].id) {
                st = true;
            }
        }
        return st;
    };
    addIntenededPlan = async (indenededData) => {
        {
            return await RestClient({
                url: GET_DATA_ADDINTENEDED_PLAN_API,
                method: "POST",
                data: indenededData,
            });
        }
    };

    componentDidMount() {
        if(this.props.additionalIcon){
            this.setState({
                showCollaps : false
            })
        }
        if (this.props.queryData) {
            this.setState(
                {
                    queryData: this.props.queryData,
                    quoteIDdd: this.props.queryData && this.props.queryData._id,
                },
                () => {
                    this.setState({
                        quoteId: this.state.queryData && this.state.queryData._id,
                        availableIntends: this.state.queryData && this.state.queryData.intendedTransportPlan,
                        activityType : this.state.queryData && this.state.queryData.activityType
                    });
                }
            );
        }
        if(this.props.switchCurrency){
            this.setState({
                switchCurrency : this.props.switchCurrency
            })
        }
        if(this.props.creditDays){
            this.setState({
                creditDays : this.props.creditDays
            },()=>{
                if(this.props.creditDays === "0 Days"){
                    this.setState({
                        creditDays : ""
                    })
                }
            })
        }
        if(this.props.currencySelected){
            this.setState({
                currencySelected : this.props.currencySelected === "Not" ? "" : this.props.currencySelected
            },()=>{
                this.currencyCalculation()
            })
        }
        if (this.props.data) {
            this.setState(
                {
                    creditDays1: this.props.data,
                },
                () => {
                    this.setState({
                        creditDays1:
                            this.state.creditDays1 && this.state.creditDays1.creditDays
                                ? this.state.creditDays1.creditDays
                                : "",
                    },()=>{
                        let originDetails = ""
                        let destinationDetails = ""
                        if(this.state.queryData &&  this.state.queryData.queryFor === "Ocean"){
                            if((this.state.activityType === "Door To Door" || this.state.activityType === "Door To Port")){
                                let checkSign =  this.props.data && this.props.data.originCode.includes("-");
                                let checkSignDestination =  this.props.data && this.props.data.destinationCode.includes("-");
                                if(this.state.queryData && this.state.queryData.activityType === "Door To Door"){
                                    if(checkSign) {
                                        originDetails = this.props.data && this.props.data.originCode && this.props.data.originCode.substring(0, this.props.data.originCode.indexOf("-"))
                                    }else{
                                        originDetails = this.props.data && this.props.data.originCode
                                    }
                                    if(checkSignDestination){
                                        destinationDetails = this.props.data && this.props.data.destinationCode && this.props.data.destinationCode.substring(0, this.props.data.destinationCode.indexOf("-"))
                                    }else{
                                        destinationDetails = this.props.data && this.props.data.destinationCode
                                    }
                                }
                                else{
                                    if(checkSign) {
                                        originDetails = this.props.data && this.props.data.originCode && this.props.data.originCode.substring(0, this.props.data.originCode.indexOf("-"))
                                        destinationDetails = this.props.data && this.props.data.destinationCode
                                    }else{
                                        originDetails = this.props.data && this.props.data.originCode
                                        destinationDetails = this.props.data && this.props.data.destinationCode
                                    }
                                }
                            }else if((this.state.queryData && this.state.queryData.activityType === "Port To Door" || this.state.queryData && this.state.queryData.activityType === "Port To Port")){
                                let checkSignDestination =  this.props.data && this.props.data.destinationCode.includes("-");
                                if(this.state.queryData && this.state.queryData.activityType === "Port To Door"){
                                    if(checkSignDestination){
                                        destinationDetails = this.props.data && this.props.data.destinationCode && this.props.data.destinationCode.substring(0, this.props.data.destinationCode.indexOf("-"))
                                        originDetails = this.props.data && this.props.data.originCode
                                    }else{
                                        destinationDetails = this.props.data && this.props.data.destinationCode
                                        originDetails = this.props.data && this.props.data.originCode
                                    }
                                }else{
                                    originDetails = this.props.data && this.props.data.originCode
                                    destinationDetails = this.props.data && this.props.data.destinationCode
                                }
                            }
                        }else{
                            if((this.state.queryData && this.state.queryData.activityType === "Door To Door" || this.state.queryData && this.state.queryData.activityType === "Door To Airport")){
                                let checkSign =  this.props.data && this.props.data.originCode.includes("-");
                                let checkSignDestination =  this.props.data && this.props.data.destinationCode.includes("-");
                                if(this.state.queryData && this.state.queryData.activityType === "Door To Door"){
                                    if(checkSign) {
                                        originDetails = this.props.data && this.props.data.originCode && this.props.data.originCode.substring(0, this.props.data.originCode.indexOf("-"))
                                    }else{
                                        originDetails = this.props.data && this.props.data.originCode
                                    }
                                    if(checkSignDestination){
                                        destinationDetails = this.props.data && this.props.data.destinationCode && this.props.data.destinationCode.substring(0, this.props.data.destinationCode.indexOf("-"))
                                    }else{
                                        destinationDetails = this.props.data && this.props.data.destinationCode
                                    }
                                }
                                else{
                                    if(checkSign) {
                                        originDetails = this.props.data && this.props.data.originCode && this.props.data.originCode.substring(0, this.props.data.originCode.indexOf("-"))
                                        destinationDetails = this.props.data && this.props.data.destinationCode
                                    }else{
                                        originDetails = this.props.data && this.props.data.originCode
                                        destinationDetails = this.props.data && this.props.data.destinationCode
                                    }
                                }
                            }else if((this.state.queryData && this.state.queryData.activityType === "Airport To Door" || this.state.queryData && this.state.queryData.activityType === "Airport To Airport")){
                                let checkSignDestination =  this.props.data && this.props.data.destinationCode.includes("-");
                                if(this.state.queryData && this.state.queryData.activityType === "Airport To Door"){
                                    if(checkSignDestination){
                                        destinationDetails = this.props.data && this.props.data.destinationCode && this.props.data.destinationCode.substring(0, this.props.data.destinationCode.indexOf("-"))
                                        originDetails = this.props.data && this.props.data.originCode
                                    }else{
                                        destinationDetails = this.props.data && this.props.data.destinationCode
                                        originDetails = this.props.data && this.props.data.originCode
                                    }
                                }else{
                                    originDetails = this.props.data && this.props.data.originCode
                                    destinationDetails = this.props.data && this.props.data.destinationCode
                                }
                            }
                        }
                        this.setState({
                            originDetails : originDetails,
                            destinationDetails : destinationDetails,
                        })
                    });
                }
            );
        }
        let selectedCurrency = localStorage.getItem("appCurrency");
        if (
            selectedCurrency === "INR" ||
            selectedCurrency === "USD" ||
            selectedCurrency === "EUR" ||
            selectedCurrency === "GBP"
        ) {
            let data = this.props.data;
            let obj = {};
            obj.to = selectedCurrency;
            obj.from = data.baseCurrency;
            this.handleCurrencyCovertor(obj).then((res) => {
                if (res.success) {
                    this.setState(
                        {
                            currencySelected: selectedCurrency,
                            selectedCurrencyRate: res.result,
                        },
                        () => {
                            // console.log("selectedCurrencyRates",this.state.selectedCurrencyRate)
                        }
                    );
                }
            });
        }
        if (!localStorage.getItem("already_registered") === "true") {
            this.getDummyCustomerData().then();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.additionalIcon && this.props.additionalIcon !== prevProps.additionalIcon){
            this.setState({
                showCollaps : false
            })
        }
        if (this.props.switchCurrency && this.props.switchCurrency !== prevProps.switchCurrency) {
            this.setState({
                switchCurrency : this.props.switchCurrency
            })
        }
        if(this.props.creditDays && this.props.creditDays !== prevProps.creditDays){
            this.setState({
                creditDays : this.props.creditDays ? this.props.creditDays : ""
            },()=>{
                if(this.props.creditDays === "0 Days"){
                    this.setState({
                        creditDays : ""
                    })
                }
            })
        }
        if(this.props.currencySelected && this.props.currencySelected !== prevProps.currencySelected){
            this.setState({
                currencySelected : this.props.currencySelected === "Not" ? "" : this.props.currencySelected
            },()=>{
                this.currencyCalculation()
            })
        }
       
    }
    handleCurrencyCovertor = async (dataForServer) => {
        return await RestClient({
            url: CURRENCY_RATE_CONVERSION_API,
            method: "POST",
            data: dataForServer,
        });
    };
    awaitPopupOpen = (id) => {
        this.setState({
            awaitPopup: !this.state.awaitPopup,
            RateIds: id,
        });
    };
    handleClickForSlab = () => {
        let { queryData, product } = this.props;
        this.getAllSlabRate({
            originDetails: queryData.originAirport.id,
            destinationDetails: queryData.destinationAirport.id,
            product: product,
            tarrifMode: queryData.tarrifMode,
        }).then((res) => {
            if (res.success) {
                this.setState({
                    ODPData: res.result,
                });
            }
        });
    };
    async getAllSlabRate(body) {
        return await RestClient({
            url: GET_ALL_SLAB_RATE_AIR,
            method: "POST",
            data: body,
        });
    }
    handleClickForOceanSlab = () => {
        let { queryData, shippingLineId } = this.props;
        this.getAllSlabRateForOcean({
            originDetails: queryData.originAirport.id,
            destinationDetails: queryData.destinationAirport.id,
            shippingLineId: shippingLineId,
        }).then((res) => {
            if (res.success) {
                this.setState({
                    ODPDataForOcean: res.result,
                });
            }
        });
    };
    async getAllSlabRateForOcean(body) {
        return await RestClient({
            url: GET_ALL_SLAB_RATE_OCEAN,
            method: "POST",
            data: body,
        });
    }
    removeCoupan = async () => {
        let customerBranchData = JSON.parse(localStorage.getItem("customer"));
        let obj = {
            rateId: this.props.data && this.props.data._id,
            customerBranchId: customerBranchData
                ? customerBranchData &&
                customerBranchData.result &&
                customerBranchData.result.customerBranchData._id
                : "",
            coupanId: this.props.data && this.props.data.coupanId,
        };
        {
            return await RestClient({
                url: REMOVE_COUPAN,
                method: "POST",
                data: obj,
            });
        }
    };
    creditDaysSTatusFunc = () => {
        this.setState(
            {
                creditDaysSTatus: !this.state.creditDaysSTatus,
            },
            () => {
                if (this.state.creditDaysSTatus === false) {
                    this.setState({
                        creditDays: "",
                    });
                }
            }
        );
    };
    getDummyCustomerData = async () => {
        this.GetGuestCustomerData().then((responce) => {
            let customerData =
                responce && responce.result ? responce.result : undefined;
            // console.log("customersdata",JSON.stringify(customerData))
            // console.log("customerData.customerBranchDatacustomerData.customerBranchData"+JSON.stringify(customerData.customerBranchData))
            let custKycStatusWithOutLogin =
                customerData && customerData.customerBranchData
                    ? customerData.customerBranchData.kycStatus
                    : "";
            this.setState(
                {
                    custKycStatus: custKycStatusWithOutLogin,
                },
                () => {
                    this.props.login(true);
                }
            );
        });
    };
    GetGuestCustomerData = async (dataForServer) => {
        return await RestClient({
            url: GUEST_CUSTOMER_DATA,
            method: "POST",
            data: dataForServer,
        });
    };
    getrateData = async (dataForServer) => {

        return await RestClient({
            url: this.props.quoteFor == "Air" ? RATE_SINGLE_DATA_AIR : this.props.quoteFor == "Ocean" ? RATE_SINGLE_DATA_OCEAN : this.props.quoteFor == "Road" ? RATE_SINGLE_DATA_ROAD : RATE_SINGLE_DATA_COURIER ,
            method: "POST",
            data: dataForServer,
        });
    };
    currencyCalculation = () =>{
        if(this.state.currencySelected === "INR"){
            this.setState({
                selectedCurrencyRate : this.props.xRateCustomerINR
            })
        }else if(this.state.currencySelected === "USD"){
            this.setState({
                selectedCurrencyRate : this.props.xRateCustomerUSD
            })
        }else if(this.state.currencySelected === "EUR"){
            this.setState({
                selectedCurrencyRate : this.props.xRateCustomerEUR
            })
        }else if(this.state.currencySelected === "GBP"){
            this.setState({
                selectedCurrencyRate : this.props.xRateCustomerGBP
            })
        }else{
            this.setState({
                selectedCurrencyRate : ""
            })
        }
    }

    render() {
        let {
            data,
            queryData,
            detail
        } = this.props;
        // console.log('progressTextprogressTextprogressText',JSON.stringify(this.props.queryData))
        // detail=concatExtraStringWithCheck(detail,"Day","Days")
        // detail=concatExtraStringWithCheck(detail,"day","Days")
        const SelectedWightSlab =
            data && data.selectedWeightSlab ? data.selectedWeightSlab : "";
        const ValidityDate =
            data && data.validityEndDate ? new Date(data.validityEndDate) : "";
        if (ValidityDate !== "" && ValidityDate !== undefined) {
            ValidityDate.setHours(0, 0, 0, 0);
        }

        const CurrrentDate = new Date();
        if (CurrrentDate !== "" && CurrrentDate !== undefined) {
            CurrrentDate.setHours(0, 0, 0, 0);
        }

        let obj = this.props.data;

        return (
            <>

                {this.state.awaitPopup ? (
                    <AwaitedPopupShipment
                        modalLarge={this.state.awaitPopup}
                        RateId={this.state.RateIds}
                        queryNo={this.state.quoteIDdd}
                        rateDataForCard={
                            this.props.rateDataForCard && this.props.rateDataForCard
                        }
                        availRatesForShipmentPage={this.props.availRatesForShipmentPage}
                        data={this.props.data}
                        iRateIndex={this.props.BookingID}
                        queryDataForcard={this.props.queryData}
                        setmodal={(values) =>
                            values === false ? this.setState({ awaitPopup: false }) : ""
                        }
                        openShipperModel={(values) => this.setState({})}
                    />
                ) : (
                    ""
                )}
                <div id="three-cards" className="rate-card mb-3 half-rates queryPreviewChange NewRatesCard">
                    <div className="d-flex flex-wrap align-items-stretch rates_partition">
                        <div className="destination_origin_app">
                            <div className="width-new-rates">
                                <div className="origin_destination_rates">
                                    <div className="text_origin">
                                    {queryData.queryFor==="Ocean" || queryData.queryFor==="Air" ?
                                        <p>origin</p>:<p>Pick Up</p>}
                                        <div className="destination_name">
                                            {this.state.originDetails}
                                            {/*{data && data.originCode}*/}
                                        </div>
                                    </div>
                                    {data?.queryFor==="Ocean" ?
                                        <div className="centerLine ">
                                            <img title={data && data.shippingLineName ? data && data.shippingLineName : ""} src={data && data.shippingLineLogo}  alt={data && data.shippingLineName ? data && data.shippingLineName : "logo-img"}/>
                                            <div className="d-flex center_width shipsImage">
                                                <div className="lineCenter"></div>
                                                <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.25566777561002096-1671173102699.png" alt="ship-small-icon"/>
                                                {/*<i className="fas fa-ship"></i>*/}
                                            </div>
                                            <div className="spot_transit2">
                                                {data && data.transitTime}
                                            </div>
                                        </div>
                                        :
                                        <>
                                        {data?.queryFor === "Air" && data.shippingLineLogo ?
                                            <>
                                                <div className="centerLine ">
                                                    <img title={data && data.shippingLineName ? data && data.shippingLineName : ""} src={data && data.shippingLineLogo}  alt={data && data.shippingLineName ? data && data.shippingLineName : "logo-img"}/>
                                                    <div className="d-flex center_width shipsImage">
                                                        <div className="lineCenter"></div>
                                                        <FontAwesomeIcon icon={faPlane} />
                                                        {/*<i className="fas fa-ship"></i>*/}
                                                    </div>
                                                    <div className="spot_transit2">
                                                        {data && data.transitTime}
                                                    </div>
                                                </div>

                                            </>
                                            :
                                            <div className="centerLine">
                                                <div className={data.queryFor === "Courier"?"spot_transit2 visi-hide":"spot_transit2"}>
                                                    {detail ? detail :""}
                                                </div>
                                                <div className="d-flex center_width">
                                                    <div className="lineCenter"></div>
                                                    {data?.queryFor === "Air" ?
                                                        <FontAwesomeIcon icon={faPlane} />
                                                        : data?.queryFor === "Road" ?
                                                            <FontAwesomeIcon icon={faTruck} />:<FontAwesomeIcon icon={faGift} />
                                                    }

                                                </div>
                                                <div className="spot_transit2 color-product">
                                                    {data.product}
                                                </div>
                                            </div>}
                                        </>

                                    }
                                    <div className="text_origin">
                                    {queryData.queryFor==="Ocean" || queryData.queryFor==="Air" ?
                                        <p>Destination</p>:<p>Drop To</p>}
                                        <div className="destination_name">
                                            {/*{data && data.destinationCode}*/}
                                            {this.state.destinationDetails}
                                        </div>
                                    </div>
                                </div>
                                <div className="rates_new_screen">
                                    <div className="origin_app">
                                        {obj.co2Emittion  && obj.co2Emittion!==""?<p className="co2EmissionText"><img src={'https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.4373335420475917-1695213160416.png'} width={25}/> CO<sub>2</sub> {parseFloat(obj.co2Emittion).toFixed(2)} Kg (Est)</p>:""}

                                        <p className="full_name rate_new_design">
                                            {(this.props.switchCurrency==true && !this.state.currencySelected )?
                                                <>
                                                    {obj.customerCurrency == "INR" ?
                                                        <FontAwesomeIcon icon={faRupeeSign} /> : obj.customerCurrency == "USD" ?
                                                            <FontAwesomeIcon icon={faDollarSign} /> : obj.customerCurrency == "EUR" ?
                                                                <FontAwesomeIcon icon={faEuroSign} /> : ""}
                                                </>:
                                                <>
                                                    {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                        <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                    ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}
                                                </>
                                            }{" "}
                                            {obj.coupanApply ?
                                                <>
                                                    {(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                        <>
                                                            {data?.creditDaysStatus ?
                                                                <>
                                                                    {data?.creditDays === ""
                                                                        ? parseFloat(obj.amountCDiscount).toFixed(2)
                                                                        : ""}
                                                                    {data?.creditDays === "15 Days"
                                                                        ? parseFloat(obj.amountC15Discount).toFixed(2)
                                                                        : ""}
                                                                    {data?.creditDays === "30 Days"
                                                                        ? parseFloat(obj.amountC30Discount).toFixed(2)
                                                                        : ""}
                                                                </>
                                                                :
                                                                <>
                                                                {this.state.creditDays === ""? parseFloat(obj.amountCDiscount).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"? parseFloat(obj.amountC15Discount).toFixed(2):""}
                                                                {this.state.creditDays === "30 Days"? parseFloat(obj.amountC30Discount).toFixed(2) :""}
                                                            </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {data?.creditDaysStatus ? <>
                                                                    {data?.creditDays === ""
                                                                        ? (parseFloat(
                                                                            obj.amountBDiscount
                                                                        )* this.state.selectedCurrencyRate).toFixed(2)
                                                                        : ""}
                                                                    {data?.creditDays === "15 Days"
                                                                        ? (parseFloat(
                                                                            obj.amountB15Discount
                                                                        )* this.state.selectedCurrencyRate).toFixed(2)
                                                                        : ""}
                                                                    {data?.creditDays === "30 Days"
                                                                        ? (parseFloat(
                                                                            obj.amountB30Discount
                                                                        )* this.state.selectedCurrencyRate).toFixed(2)
                                                                        : ""}
                                                                </> :
                                                                <>
                                                                    {this.state.creditDays === "" ? (parseFloat(obj.amountBDiscount)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                    {this.state.creditDays === "15 Days" ? (parseFloat(obj.amountB15Discount)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                    {this.state.creditDays === "30 Days" ? (parseFloat(obj.amountB30Discount)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </> :
                                                <>
                                                    {(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                        <>
                                                            {data?.creditDaysStatus ?
                                                                <>
                                                                    {data?.creditDays === ""
                                                                        ? obj && obj.amountC ? parseFloat(obj.amountC).toFixed(2)
                                                                            : ""
                                                                        : ""}
                                                                    {data?.creditDays === "15 Days"
                                                                        ? obj && obj.amountC15 ? parseFloat(obj.amountC15).toFixed(2) : 0
                                                                        : ""}
                                                                    {data?.creditDays === "30 Days"
                                                                        ? obj && obj.amountC30 ? parseFloat(obj.amountC30).toFixed(2) : 0
                                                                        : ""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""? obj && obj.amountC ? parseFloat(obj.amountC).toFixed(2) :0:""}
                                                                    {this.state.creditDays === "15 Days"? obj && obj.amountC15 ? parseFloat(obj.amountC15).toFixed(2) : 0:""}
                                                                    {this.state.creditDays === "30 Days"? obj && obj.amountC30 ? parseFloat(obj.amountC30).toFixed(2) : 0:""}
                                                                </>
                                                            }
                                                            </>
                                                        :
                                                        <>
                                                            {data?.creditDaysStatus ?
                                                                <>
                                                                    {data?.creditDays === ""
                                                                        ? obj && obj.amountB ? (parseFloat(obj.amountB) * (this.state.selectedCurrencyRate)).toFixed(2) : 0
                                                                        : ""}
                                                                    {data?.creditDays === "15 Days"
                                                                        ? obj && obj.amountB15 ? (parseFloat(obj.amountB15) * (this.state.selectedCurrencyRate)).toFixed(2) : 0
                                                                        : ""}
                                                                    {data?.creditDays === "30 Days"
                                                                        ? obj && obj.amountB30 ? (parseFloat(obj.amountB30) * (this.state.selectedCurrencyRate)).toFixed(2) : 0
                                                                        : ""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? obj && obj.amountB ? (parseFloat(obj.amountB) * (this.state.selectedCurrencyRate)).toFixed(2) : 0 : ""}
                                                                    {this.state.creditDays === "15 Days" ? obj && obj.amountB15 ? (parseFloat(obj.amountB15) * (this.state.selectedCurrencyRate)).toFixed(2) : 0 : ""}
                                                                    {this.state.creditDays === "30 Days" ? obj && obj.amountB30 ? (parseFloat(obj.amountB30) * (this.state.selectedCurrencyRate)).toFixed(2) : 0 : ""}
                                                                </>
                                                            }
                                                            </>
                                                    }
                                                </>
                                            }
                                        </p>
                                        {/*{obj.validityEndDate?<p className="valid_till">VALID: <b>{obj.validityEndDate? convertDateISOToCustomiseFormat(obj.validityEndDate):""}</b></p>:""}*/}
                                    </div>
                                    <div
                                        className="fright_summery_icon"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({showCollaps: !this.state.showCollaps})
                                        }}
                                    >
                                        {this.state.showCollaps?<FontAwesomeIcon icon={faChevronUp} />:
                                            <FontAwesomeIcon icon={faChevronDown} />}
                                    </div>
                                </div>
                            </div>
                            <div id="collapseOne1" className={this.state.showCollaps === true ? "collapse border-top show" : "collapse border-top"} role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="all-price list-fare">
                                <table className="fare_table_design">
                                    <tr>
                                        <th><strong>Charges Name</strong></th>
                                        {data?.queryFor==="Ocean" ?
                                            <>
                                                {data?.shipmentMode === "FCL"?
                                                    <th><strong>No of Containers</strong></th>
                                                    :
                                                    <th><strong>Volume (CBM)</strong></th>
                                                }
                                            </>

                                            :
                                            <th><strong>Ch Wt.(Kg)</strong></th>}
                                        <th><strong>Rate</strong></th>
                                        <th><strong>Total Amount</strong></th>
                                    </tr>

                                    {data?.queryFor==="Ocean" ?
                                        <>

                                            {data.charges !== undefined && queryData && queryData.shipmentMode === "FCL" ?

                                                data.charges.map((item,i)=>{
                                                    return(
                                                        <tr>
                                                            {item.chargeName === "Ocean Freight" ?
                                                                <th>
                                                                    <strong>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</strong>
                                                                </th>
                                                                :
                                                                item.chargeName === "Ocean Import" ?
                                                                    <th><strong>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeFor + " - " + item.altName : item.chargeName}</strong>
                                                                    </th>
                                                                    :
                                                                <th><strong>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.altName : item.chargeName}</strong>
                                                                </th>
                                                            }
                                                            <td>{item.chargeQty}</td>
                                                            <td>
                                                                {data?.creditDaysStatus ?
                                                                    <>
                                                                        {data?.creditDays === ""
                                                                            ? item.currency + " " + item.chargeRate
                                                                            : ""}
                                                                        {data?.creditDays === "15 Days"
                                                                            ? item.currency + " " + item.chargeRate15
                                                                            : ""}
                                                                        {data?.creditDays === "30 Days"
                                                                            ? item.currency + " " + item.chargeRate30
                                                                            : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                        {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                        {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}
                                                                    </>
                                                                }
                                                            </td>
                                                            {/* <td>{(this.props.switchCurrency == true && !this.state.currencySelected )?data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : ""}</td> */}
                                                            <td>
                                                                {(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                                    <>{data && data.customerCurrency}{" "}
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>{this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                        <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                    ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                        {this.state.creditDays === ""?item.taxableB != undefined ? (parseFloat(item.taxableB) * this.state.selectedCurrencyRate).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?item.taxableB15 != undefined ? (parseFloat(item.taxableB15) * this.state.selectedCurrencyRate).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?item.taxableB30 != undefined ? (parseFloat(item.taxableB30) * this.state.selectedCurrencyRate).toFixed(2) : "":""}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                                :
                                                data.charges.map((item,i)=>{
                                                    return(
                                                        <tr>
                                                            <th> <strong>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.altName : item.chargeName}</strong></th>
                                                            <td>{item.chargeQty}</td>
                                                            <td>
                                                                {data?.creditDaysStatus ?
                                                                    <>
                                                                        {data?.creditDays === ""
                                                                            ? item.currency + " " + item.chargeRate
                                                                            : ""}
                                                                        {data?.creditDays === "15 Days"
                                                                            ? item.currency + " " + item.chargeRate15
                                                                            : ""}
                                                                        {data?.creditDays === "30 Days"
                                                                            ? item.currency + " " + item.chargeRate30
                                                                            : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                        {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                        {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}
                                                                </>
                                                                }
                                                                </td>
                                                            {/* <td>{(this.props.switchCurrency == true && !this.state.currencySelected )?data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":data.baseCurrency +" " +item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : ""}</td> */}
                                                            <td>
                                                                {(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                                    <>{data && data.customerCurrency}{" "}
                                                                        {data?.creditDaysStatus ?
                                                                            <>
                                                                                {data?.creditDays === ""
                                                                                    ? item.taxableC !=
                                                                                    undefined
                                                                                        ? parseFloat(item.taxableC).toFixed(2)
                                                                                        : ""
                                                                                    : ""}
                                                                                {data?.creditDays === "15 Days"
                                                                                    ? item.taxableC15 !=
                                                                                    undefined
                                                                                        ? parseFloat(item.taxableC15).toFixed(
                                                                                            2
                                                                                        )
                                                                                        : ""
                                                                                    : ""}
                                                                                {data?.creditDays === "30 Days"
                                                                                    ? item.taxableC30 !=
                                                                                    undefined
                                                                                        ? parseFloat(item.taxableC30).toFixed(
                                                                                            2
                                                                                        )
                                                                                        : ""
                                                                                    : ""}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {this.state.creditDays === "" ? item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "" : ""}
                                                                                {this.state.creditDays === "15 Days" ? item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "" : ""}
                                                                                {this.state.creditDays === "30 Days" ? item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "" : ""}
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>{this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                        <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                    ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                        {data?.creditDaysStatus ?
                                                                            <>
                                                                                {data?.creditDays === ""
                                                                                    ? item.taxableB !=
                                                                                    undefined
                                                                                        ? (parseFloat(item.taxableB) * this.state.selectedCurrencyRate).toFixed(2)
                                                                                        : ""
                                                                                    : ""}
                                                                                {data?.creditDays === "15 Days"
                                                                                    ? item.taxableB15 !=
                                                                                    undefined
                                                                                        ? (parseFloat(item.taxableB15) * this.state.selectedCurrencyRate).toFixed(
                                                                                            2
                                                                                        )
                                                                                        : ""
                                                                                    : ""}
                                                                                {data?.creditDays === "30 Days"
                                                                                    ? item.taxableB30 !=
                                                                                    undefined
                                                                                        ? (parseFloat(item.taxableB30) * this.state.selectedCurrencyRate).toFixed(
                                                                                            2
                                                                                        )
                                                                                        : ""
                                                                                    : ""}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {this.state.creditDays === "" ? item.taxableB != undefined ? (parseFloat(item.taxableB) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                                {this.state.creditDays === "15 Days" ? item.taxableB15 != undefined ? (parseFloat(item.taxableB15) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                                {this.state.creditDays === "30 Days" ? item.taxableB30 != undefined ? (parseFloat(item.taxableB30) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                        :

                                        data.charges.map((item,i)=>{
                                            return(
                                                <tr>
                                                    <th><strong>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.altName : item.chargeName}</strong></th>
                                                    <td>{item.chargeQty}</td>
                                                    <td>
                                                        {data?.creditDaysStatus ?
                                                            <>
                                                                {data?.creditDays === ""
                                                                    ? item.currency + " " + item.chargeRate
                                                                    : ""}
                                                                {data?.creditDays === "15 Days"
                                                                    ? item.currency + " " + item.chargeRate15
                                                                    : ""}
                                                                {data?.creditDays === "30 Days"
                                                                    ? item.currency + " " + item.chargeRate30
                                                                    : ""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === "" ? item.currency + " " + item.chargeRate : ""}
                                                                {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15 : ""}
                                                                {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30 : ""}
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        {(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                            <>{data && data.customerCurrency}{" "}
                                                                {data?.creditDaysStatus ?
                                                                    <>
                                                                        {data?.creditDays === ""
                                                                            ? item.taxableC !=
                                                                            undefined
                                                                                ? parseFloat(item.taxableC).toFixed(2)
                                                                                : ""
                                                                            : ""}
                                                                        {data?.creditDays === "15 Days"
                                                                            ? item.taxableC15 !=
                                                                            undefined
                                                                                ? parseFloat(item.taxableC15).toFixed(
                                                                                    2
                                                                                )
                                                                                : ""
                                                                            : ""}
                                                                        {data?.creditDays === "30 Days"
                                                                            ? item.taxableC30 !=
                                                                            undefined
                                                                                ? parseFloat(item.taxableC30).toFixed(
                                                                                    2
                                                                                )
                                                                                : ""
                                                                            : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === "" ? item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "15 Days" ? item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "30 Days" ? item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "" : ""}
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>{this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                            ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                {data?.creditDaysStatus ?
                                                                    <>
                                                                        {data?.creditDays === ""
                                                                            ? item.taxableB !=
                                                                            undefined
                                                                                ? (parseFloat(item.taxableB) * this.state.selectedCurrencyRate).toFixed(2)
                                                                                : ""
                                                                            : ""}
                                                                        {data?.creditDays === "15 Days"
                                                                            ? item.taxableB15 !=
                                                                            undefined
                                                                                ? (parseFloat(item.taxableB15) * this.state.selectedCurrencyRate).toFixed(
                                                                                    2
                                                                                )
                                                                                : ""
                                                                            : ""}
                                                                        {data?.creditDays === "30 Days"
                                                                            ? item.taxableB30 !=
                                                                            undefined
                                                                                ? (parseFloat(item.taxableB30) * this.state.selectedCurrencyRate).toFixed(
                                                                                    2
                                                                                )
                                                                                : ""
                                                                            : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === "" ? item.taxableB != undefined ? (parseFloat(item.taxableB) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "15 Days" ? item.taxableB15 != undefined ? (parseFloat(item.taxableB15) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "30 Days" ? item.taxableB30 != undefined ? (parseFloat(item.taxableB30) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })


                                    }
                                    {data && data.awaitedRates && data.awaitedRates.length >0 ?
                                        data.awaitedRates.map((items , index)=>{
                                            return(
                                                <tr>
                                                    <th><strong><strong>{items.chargeFor && items.chargeFor === "Origin Clearence" ? "Origin Clearance" : items.chargeFor === "Destination Clearence" ? "Destination Clearance" : items.chargeFor}</strong></strong></th>
                                                    <td> - </td>
                                                    <td> - </td>
                                                    <td><strong>Awaited</strong></td>
                                                </tr>
                                            )
                                        })

                                        :""}
                                    {obj.coupanApply ? "" :
                                        <>
                                            {data.taxAmountB !== null ?

                                                <tr>
                                                    <th><strong>GST</strong></th>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>
                                                        {this.props.switchCurrency == true && !this.state.currencySelected ?
                                                            <>{data && data.customerCurrency}{" "}
                                                                {data?.creditDaysStatus ?
                                                                    <>
                                                                        {data?.creditDays === "" ? data?.taxAmountC != undefined ? parseFloat(data?.taxAmountC).toFixed(2) : "" : ""}
                                                                        {data?.creditDays === "15 Days" ? data?.taxAmountC15 != undefined ? parseFloat(data?.taxAmountC15).toFixed(2) : "" : ""}
                                                                        {data?.creditDays === "30 Days" ? data?.taxAmountC30 != undefined ? parseFloat(data?.taxAmountC30).toFixed(2) : "" : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === "" ? data.taxAmountC != undefined ? parseFloat(data.taxAmountC).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "15 Days" ? data.taxAmountC15 != undefined ? parseFloat(data.taxAmountC15).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "30 Days" ? data.taxAmountC30 != undefined ? parseFloat(data.taxAmountC30).toFixed(2) : "" : ""}
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>{this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                            ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                {data?.creditDaysStatus ?
                                                                    <>
                                                                        {data?.creditDays === "" ? data?.taxAmountB != undefined ? (parseFloat(data?.taxAmountB)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                        {data?.creditDays === "15 Days" ? data?.taxAmountB15 != undefined ? (parseFloat(data?.taxAmountB15)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                        {data?.creditDays === "30 Days" ? data?.taxAmountB30 != undefined ? (parseFloat(data?.taxAmountB30)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === "" ? data.taxAmountB != undefined ? (parseFloat(data?.taxAmountB)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "15 Days" ? data.taxAmountB15 != undefined ? (parseFloat(data?.taxAmountB15)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "30 Days" ? data.taxAmountB30 != undefined ? (parseFloat(data?.taxAmountB30)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                undefined}
                                            <tr>
                                                <th><strong>TOTAL AMOUNT</strong></th>
                                                <td><strong /> </td>
                                                <td><strong /> </td>
                                                <td>
                                                    <strong>{(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                        <>
                                                            {data?.creditDaysStatus ?
                                                                <>
                                                                    {data?.creditDays === ""
                                                                        ? data?.customerCurrency +
                                                                        " " +
                                                                        parseFloat(
                                                                            data?.amountC
                                                                        ).toFixed(2)
                                                                        : ""}
                                                                    {data?.creditDays === "15 Days"
                                                                        ? data?.customerCurrency +
                                                                        " " +
                                                                        parseFloat(
                                                                            data?.amountC15
                                                                        ).toFixed(2)
                                                                        : ""}
                                                                    {data?.creditDays === "30 Days"
                                                                        ? data?.customerCurrency +
                                                                        " " +
                                                                        parseFloat(
                                                                            data?.amountC30
                                                                        ).toFixed(2)
                                                                        : ""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? data.customerCurrency + " " + parseFloat(data.amountC).toFixed(2) : ""}
                                                                    {this.state.creditDays === "15 Days" ? data.customerCurrency + " " + parseFloat(data.amountC15).toFixed(2) : ""}
                                                                    {this.state.creditDays === "30 Days" ? data.customerCurrency + " " + parseFloat(data.amountC30).toFixed(2) : ""}
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                            ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                            {data?.creditDaysStatus ?
                                                                <>
                                                                    {data?.creditDays === ""
                                                                        ? (parseFloat(
                                                                            data?.amountB
                                                                        ) * this.state.selectedCurrencyRate).toFixed(2)
                                                                        : ""}
                                                                    {data?.creditDays === "15 Days"
                                                                        ? (parseFloat(
                                                                            data?.amountB15
                                                                        ) * this.state.selectedCurrencyRate).toFixed(2)
                                                                        : ""}
                                                                    {data?.creditDays === "30 Days"
                                                                        ? (parseFloat(
                                                                            data?.amountB30
                                                                        ) * this.state.selectedCurrencyRate).toFixed(2)
                                                                        : ""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? (parseFloat(data.amountB) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                    {this.state.creditDays === "15 Days" ? (parseFloat(data.amountB15) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                    {this.state.creditDays === "30 Days" ? (parseFloat(data.amountB30) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                </>
                                                            }
                                                        </>
                                                    }</strong>
                                                </td>
                                            </tr>
                                        </>

                                    }
                                    {obj.coupanApply ? <>
                                        <tr>
                                            <th><strong>TOTAL AMOUNT</strong></th>
                                            <td><strong /> </td>
                                            <td><strong /> </td>
                                            <td><strong>
                                                {(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                    <>
                                                        {data?.creditDaysStatus ?
                                                            <>
                                                                {data?.creditDays === ""
                                                                    ? data?.customerCurrency +
                                                                    " " +
                                                                    parseFloat(
                                                                        parseFloat(
                                                                            data?.amountC
                                                                        ) -
                                                                        parseFloat(
                                                                            data?.taxAmountC
                                                                        )
                                                                    ).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "15 Days"
                                                                    ? data?.customerCurrency +
                                                                    " " +
                                                                    parseFloat(
                                                                        parseFloat(
                                                                            data?.amountC15
                                                                        ) -
                                                                        parseFloat(
                                                                            data?.taxAmountC15
                                                                        )
                                                                    ).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "30 Days"
                                                                    ? data?.customerCurrency +
                                                                    " " +
                                                                    parseFloat(
                                                                        parseFloat(
                                                                            data?.amountC30
                                                                        ) -
                                                                        parseFloat(
                                                                            data?.taxAmountC30
                                                                        )
                                                                    ).toFixed(2)
                                                                    : ""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === "" ? data.customerCurrency + " " + parseFloat(data.amountC - data.taxAmountC).toFixed(2) : ""}
                                                                {this.state.creditDays === "15 Days" ? data.customerCurrency + " " + parseFloat(data.amountC15 - data.taxAmountC15).toFixed(2) : ""}
                                                                {this.state.creditDays === "30 Days" ? data.customerCurrency + " " + parseFloat(data.amountC30 - data.taxAmountC30).toFixed(2) : ""}
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                        {data?.creditDaysStatus ?
                                                            <>
                                                                {data?.creditDays === ""
                                                                    ? (parseFloat(
                                                                        parseFloat(
                                                                            data?.amountB
                                                                        ) -
                                                                        parseFloat(
                                                                            data?.taxAmountB
                                                                        )
                                                                    ) * this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "15 Days"
                                                                    ? (parseFloat(
                                                                        parseFloat(
                                                                            data?.amountB15
                                                                        ) -
                                                                        parseFloat(
                                                                            data?.taxAmountB15
                                                                        )
                                                                    ) * this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "30 Days"
                                                                    ? (parseFloat(
                                                                        parseFloat(
                                                                            data?.amountB30
                                                                        ) -
                                                                        parseFloat(
                                                                            data?.taxAmountB30
                                                                        )
                                                                    ) * this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === "" ? (parseFloat(data.amountB - data.taxAmountB) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                {this.state.creditDays === "15 Days" ? (parseFloat(data.amountB15 - data.taxAmountB15) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                {this.state.creditDays === "30 Days" ? (parseFloat(data.amountB30 - data.taxAmountB30) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Discount on MRP</th>
                                            <td><strong /> </td>
                                            <td><strong /> </td>
                                            <td style={{color: '#03a685'}}>
                                                <strong>{(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                    <>
                                                        {data?.creditDaysStatus ?
                                                            <>
                                                                {data?.creditDays === ""
                                                                    ? data?.baseCurrency +
                                                                    " " +
                                                                    parseFloat(data?.discountAmount).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "15 Days"
                                                                    ? data?.baseCurrency +
                                                                    " " +
                                                                    parseFloat(data?.discountAmount15).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "30 Days"
                                                                    ? data?.baseCurrency +
                                                                    " " +
                                                                    parseFloat(data?.discountAmount30).toFixed(2)
                                                                    : ""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === "" ? data.baseCurrency + " " + parseFloat(obj.discountAmount).toFixed(2) : ""}
                                                                {this.state.creditDays === "15 Days" ? data.baseCurrency + " " + parseFloat(obj.discountAmount15).toFixed(2) : ""}
                                                                {this.state.creditDays === "30 Days" ? data.baseCurrency + " " + parseFloat(obj.discountAmount30).toFixed(2) : ""}
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                        {data?.creditDaysStatus ?
                                                            <>
                                                                {data?.creditDays === ""
                                                                    ?(parseFloat(data?.discountAmount)* this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "15 Days"
                                                                    ? (parseFloat(data?.discountAmount15)* this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "30 Days"
                                                                    ? (parseFloat(data?.discountAmount30)* this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === "" ? (parseFloat(obj.discountAmount)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                {this.state.creditDays === "15 Days" ? (parseFloat(obj.discountAmount15)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                {this.state.creditDays === "30 Days" ? (parseFloat(obj.discountAmount30)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                            </>
                                                        }
                                                    </>
                                                }</strong>
                                            </td>
                                        </tr>
                                        {data.taxAmountB !== null ?

                                            <tr>
                                                <th><strong>GST</strong></th>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>
                                                    {/*{this.props.switchCurrency == true ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                    {(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                        <>{data.customerCurrency}{" "}
                                                            {data?.creditDaysStatus ?
                                                                <>
                                                                    {data?.creditDays === ""
                                                                        ? data?.customerCurrency +
                                                                        " " +
                                                                        data?.taxAmountCDiscount !=
                                                                        undefined
                                                                            ? parseFloat(
                                                                                data?.taxAmountCDiscount
                                                                            ).toFixed(2)
                                                                            : ""
                                                                        : ""}
                                                                    {data?.creditDays === "15 Days"
                                                                        ? data?.customerCurrency +
                                                                        " " +
                                                                        data?.taxAmountC15Discount !=
                                                                        undefined
                                                                            ? parseFloat(
                                                                                data?.taxAmountC15Discount
                                                                            ).toFixed(2)
                                                                            : ""
                                                                        : ""}
                                                                    {data?.creditDays === "30 Days"
                                                                        ? data?.customerCurrency +
                                                                        " " +
                                                                        data?.taxAmountC30Discount !=
                                                                        undefined
                                                                            ? parseFloat(
                                                                                data?.taxAmountC30Discount
                                                                            ).toFixed(2)
                                                                            : ""
                                                                        : ""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? obj.taxAmountCDiscount != undefined ? parseFloat(obj.taxAmountCDiscount).toFixed(2) : "" : ""}
                                                                    {this.state.creditDays === "15 Days" ? obj.taxAmountC15Discount != undefined ? parseFloat(obj.taxAmountC15Discount).toFixed(2) : "" : ""}
                                                                    {this.state.creditDays === "30 Days" ? obj.taxAmountC30Discount != undefined ? parseFloat(obj.taxAmountC30Discount).toFixed(2) : "" : ""}
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>{this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                            {data?.creditDaysStatus ?
                                                                <>
                                                                    {data?.creditDays === ""
                                                                        ? data?.taxAmountBDiscount !=
                                                                        undefined
                                                                            ? (parseFloat(
                                                                                data?.taxAmountBDiscount
                                                                            )* this.state.selectedCurrencyRate).toFixed(2)
                                                                            : ""
                                                                        : ""}
                                                                    {data?.creditDays === "15 Days"
                                                                        ? data?.taxAmountB15Discount !=
                                                                        undefined
                                                                            ? (parseFloat(
                                                                                obj.taxAmountB15Discount
                                                                            )* this.state.selectedCurrencyRate).toFixed(2)
                                                                            : ""
                                                                        : ""}
                                                                    {data?.creditDays === "30 Days"
                                                                        ? data?.taxAmountB30Discount !=
                                                                        undefined
                                                                            ? (parseFloat(
                                                                                data?.taxAmountB30Discount
                                                                            )* this.state.selectedCurrencyRate).toFixed(2)
                                                                            : ""
                                                                        : ""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === "" ? obj.taxAmountBDiscount != undefined ? (parseFloat(obj.taxAmountBDiscount)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                    {this.state.creditDays === "15 Days" ? obj.taxAmountB15Discount != undefined ? (parseFloat(obj.taxAmountB15Discount)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                    {this.state.creditDays === "30 Days" ? obj.taxAmountB30Discount != undefined ? (parseFloat(obj.taxAmountB30Discount)* this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                            :
                                            undefined}
                                    </>: ""}

                                    {obj.coupanApply  ? <>
                                        <tr>
                                            <th>Net Payable Amount(After Discount)</th>
                                            <td><strong /> </td>
                                            <td><strong /> </td>
                                            <td>
                                                <strong>{(this.props.switchCurrency == true && !this.state.currencySelected )?
                                                    <>
                                                        {data?.creditDaysStatus ?
                                                            <>
                                                                {data?.creditDays === ""
                                                                    ? data?.customerCurrency +
                                                                    " " +
                                                                    parseFloat(data?.amountCDiscount).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "15 Days"
                                                                    ? data?.customerCurrency +
                                                                    " " +
                                                                    parseFloat(data?.amountC15Discount).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "30 Days"
                                                                    ? data?.customerCurrency +
                                                                    " " +
                                                                    parseFloat(data?.amountC30Discount).toFixed(2)
                                                                    : ""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === "" ? data.customerCurrency + " " + parseFloat(obj.amountCDiscount).toFixed(2) : ""}
                                                                {this.state.creditDays === "15 Days" ? data.customerCurrency + " " + parseFloat(obj.amountC15Discount).toFixed(2) : ""}
                                                                {this.state.creditDays === "30 Days" ? data.customerCurrency + " " + parseFloat(obj.amountC30Discount).toFixed(2) : ""}
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                        {data?.creditDaysStatus ?
                                                            <>
                                                                {data?.creditDays === ""
                                                                    ? (parseFloat(data?.amountBDiscount)* this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "15 Days"
                                                                    ? (parseFloat(data?.amountB15Discount)* this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                                {data?.creditDays === "30 Days"
                                                                    ? (parseFloat(data?.amountB30Discount)* this.state.selectedCurrencyRate).toFixed(2)
                                                                    : ""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === "" ? (parseFloat(obj.amountBDiscount)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                {this.state.creditDays === "15 Days" ? (parseFloat(obj.amountB15Discount)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                {this.state.creditDays === "30 Days" ? (parseFloat(obj.amountB30Discount)* this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                            </>
                                                        }
                                                    </>
                                                }</strong>
                                            </td>
                                        </tr> </>: ""}

                                </table>

                            </div>
                            <div className="terms-width list-fare text-left">

                                <p className="mt-2 "><strong>TERMS & CONDITIONS</strong></p>
                                {queryData.queryFor !== "Ocean" ?
                                    <>
                                        <ul className="text-left">
                                            <li> Rates are subject to space confirmation from the carrier.</li>
                                            <li> Rates are valid only for the current week or 7 days whichever is earlier.</li>
                                            <li> Rates are only valid for the shipment details shared in the query only, any changes will require rate revalidation.</li>
                                            <li> Rates are applicable for general cargo only.</li>
                                            <li> All inclusive (ALL-IN) rates quoted through instant rate quotes includes Air Freight, Fuel Surcharge, Security Surcharge, X-ray charges, Misc Charges, AMS charges. Any other charges must be considered as additional.</li>
                                            <li> For any special cargo, the instant live rates are not applicable, and will be quoted separately. </li>
                                            <li> Transit time mentioned is purely indicative and should be considered as tentative.</li>
                                            <li> Government Taxes (if any) shall be applicable as per Government norms.</li>
                                        </ul>

                                        {data && data.groupNameRemarks.length === 0 ?
                                            <>
                                                <p className="mt-2 " style={{marginBottom: "0rem"}}>
                                                    <strong>Remarks</strong></p>
                                                <ul className="text-left ">
                                                    
                                                    <li> {data && data.remarks !== "" ? data.remarks : ""}</li>


                                                </ul>
                                            </>
                                            :<>
                                            <p className="mt-2 " style={{marginBottom: "0rem"}}>
                                                <strong>Remarks</strong></p>
                                            <ul className="text-left ">
                                                {data.groupNameRemarks && data.groupNameRemarks.map((item,i)=>{
                                                    return(
                                                        <li> {item}</li>
                                                    );
                                                })
                                                }


                                            </ul>
                                        </>}
                                    </>
                                    :
                                    <>
                                        <ul className="text-left">
                                            <li>  Booking requests are strictly subject to space and equipment availability and confirmation.</li>
                                            <li>  Instant rates quoted are applicable for general commodities only, with no special handling requirements.</li>
                                            <li>  Rates are valid for the shipment details shared in the query.</li>
                                            <li>  Shipping Line surcharges are subject to change, unless fixed within the quotation validity.</li>
                                            <li>  For any special cargo, the instant live rates are not applicable. The price quotes shall be provided within 48-72 hours during the carriers’ working days. </li>
                                            <li>  Transit time mentioned are indicative based on the carriers’ information and should be considered as tentative. </li>
                                            <li>  Government Taxes (if any) shall be applicable as per Government norms.</li>
                                        </ul>

                                        {data && data.groupNameRemarks.length === 0 ?
                                            <>
                                                <p className="mt-2" style={{marginBottom: "0rem"}}>
                                                    <strong>Remarks</strong></p>
                                                <ul className="text-left ">
                                                    
                                                    <li> {data && data.remarks !== "" ? data.remarks : ""}</li>
                                                </ul>
                                            </>
                                            :
                                            <>
                                                <p className="mt-2" style={{marginBottom: "0rem"}}>
                                                    <strong>Remarks</strong></p>
                                                <ul className="text-left ">
                                                    {data.groupNameRemarks && data.groupNameRemarks.map((item,i)=>{
                                                        return(
                                                            <li> {item}</li>
                                                        );
                                                    })
                                                    }
                                                </ul>
                                            </>
                                            }
                                    </>
                                }
                                {obj.vesselSchedules.length > 0 ?
                                    <div className="pl-3 pb-2">
                                        <p className="mt-2 pl-1 mb-1"><strong>Vessel Schedule</strong></p>
                                        <div className="table-responsive table-vessles">
                                            <table
                                                className="table table-bordered table-striped table-hover table-sticky">
                                                <thead style={{background: '#ff7900', color: '#fff'}}>
                                                <tr>

                                                    <th className="text-center">Vessel</th>
                                                    <th className="text-center">Voyage No</th>
                                                    <th className="text-center">Departure Date</th>
                                                    <th className="text-center">Arrival Date</th>
                                                    <th className="text-center">Berthing Date</th>
                                                    <th className="text-center">Action</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    obj.vesselSchedules && obj.vesselSchedules.map((item,i)=>{
                                                        return(
                                                            <tr>
                                                                <td>{item.vessel}</td>
                                                                <td>{item.voyageNo}</td>
                                                                <td>{item.departure ? convertDateISO(item.departure) : ""}</td>
                                                                <td>{item.arrival ? convertDateISO(item.arrival) : ""}</td>
                                                                <td>{item.bearthing ? convertDateISO(item.bearthing) : ""}</td>
                                                                {this.checkIdAvailable(item._id)==true
                                                                    ?<td><button type="button"
                                                                                 className="border-cir delete"
                                                                                 onClick={e => {
                                                                                 }}>Mapped In Planning</button></td>
                                                                    :<td><button type="button"
                                                                                 className="border-cir delete"
                                                                                 onClick={e => {
                                                                                     this.sendToIntendedTransportPlan(item)
                                                                                 }}>Update Plan</button></td>}
                                                            </tr>
                                                        );
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :""}
                            </div>
                        </div>
                            </div>
                        </div>
                        <div className="name_company justify-content-start">
                            <h5>{obj && obj.serviceProviderName ? obj.serviceProviderName : ""}</h5>
                            {this.props.bookButtonHide ? "" :
                                <>
                                {queryData && queryData.quoteStatus === "Rates Quoted" ?
                                    this.props.bookButtonShipment?
                                        <button className="btn company_btn" onClick={(e)=>{
                                            this.props.bookButtonPress(true)
                                        }}>
                                            Book
                                        </button>
                                        :
                                        <button className="btn company_btn" onClick={(e)=>{
                                            this.getrateData({ id: obj._id }).then(
                                                (responce) => {
                                                    if (responce && responce.success) {
                                                        let rateData =
                                                            responce && Array.isArray(responce.result) && responce.result.length >= 1 ? responce.result[0] : "";
                                                        this.setState(
                                                            {
                                                                rateDataSelected: rateData,
                                                            },()=>{
                                                               
                                                                this.props.ratesSelect(this.state.rateDataSelected);
                                                                if(queryData.queryFor==="Ocean" || queryData.queryFor==="Air") {
                                                                    this.props.showCollapsSlab(this.state.rateDataSelected)
                                                                }
                                                            }
                                                        );
                                                    }
                                                }
                                            );
                                        }}>Book</button>
                                    :""}
                                </>
                            }
                        </div>

                    </div>
                    {/*spot_coupon_rates*/}

                    {this.state.isSlabRateOpen ?
                        <SlabRatePoup
                        BookingStatus={queryData.quoteStatus}
                        modalLarge={this.state.isSlabRateOpen}
                        ODPData={this.state.ODPData}
                        chargeableWeightForSlab={queryData.chargeableWeight}
                        setmodal={(value) => {
                            if (value === false) {
                                this.setState({
                                    isSlabRateOpen: false,
                                });
                            }
                        }}
                    />:""}
                    {this.state.isSlabRateOpenForOcean ?
                    <SlabRatePopupForOcean
                        modalLarge1={this.state.isSlabRateOpenForOcean}
                        ODPDataForOcean={this.state.ODPDataForOcean}
                        setmodal={(value) => {
                            if (value === false) {
                                this.setState({
                                    isSlabRateOpenForOcean: false,
                                });
                            }
                        }}
                    />:""}
                    <div
                        id="collapseOne2"
                        className={
                            this.state.showCollapsForRate === true
                                ? "collapse border-top show"
                                : "collapse border-top"
                        }
                        role="tabpanel"
                        aria-labelledby="headingOne2"
                        data-parent="#accordionEx"
                    >
                        <div className="d-flex flex-wrap justify-content-between padding-15">
                            <div className="list-fare pt-3" style={{ width: "100%" }}>
                                <p className="mb-3">
                                    <strong>All Slabs Rate</strong>
                                </p>
                                <table className="table table-bordered text-center">
                                    <tr style={{ background: "#eaeaea" }}>
                                        <th>SLABS</th>
                                        <th>MIN</th>
                                        <th>NORMAL</th>
                                        <th>+45</th>
                                        <th>+100</th>
                                        <th>+250</th>
                                        <th>+300</th>
                                        <th>+500</th>
                                        <th>+1000</th>
                                        <th>VALIDITY</th>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle" }}>
                                            {data.rawData && data.rawData.currency
                                                ? data.rawData.currency
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "min"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_min
                                                ? data.rawData.w_min
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" &&
                                                SelectedWightSlab === "normal"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_normal
                                                ? data.rawData.w_normal
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "45"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_45
                                                ? data.rawData.w_45
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "100"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_100
                                                ? data.rawData.w_100
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "250"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_250
                                                ? data.rawData.w_250
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "300"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_300
                                                ? data.rawData.w_300
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "500"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_500
                                                ? data.rawData.w_500
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "1000"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_1000
                                                ? data.rawData.w_1000
                                                : "-"}
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                            {data.rawData && data.rawData.validityEndDate
                                                ? convertDateISOToCustomiseFormat(
                                                    data.rawData.validityEndDate
                                                )
                                                : "-"}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FilterRates;
