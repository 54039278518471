import React from "react";
export const API_END_POINT_LOCAL = "";
// export const API_END_POINT_LOCAL_AIR = `https://air.devapi.zipaworld.com/`;
// export const API_END_POINT_LOCAL_AIR = `https://apidocair.zipaworld.com/`;
   //export const API_END_POINT_LOCAL_AIR = `https://zipaairapi.zipaworld.com/`;
 export const API_END_POINT_LOCAL_AIR = `https://demozipaairapi.zipaworld.com/`;
// export const API_END_POINT_LOCAL_OCEAN = `https://ocean.devapi.zipaworld.com/`;
// export const API_END_POINT_LOCAL_OCEAN = `https://apidococean.zipaworld.com/`;
//    export const API_END_POINT_LOCAL_OCEAN = `https://zipaoceanapi.zipaworld.com/`;
 export const API_END_POINT_LOCAL_OCEAN = `https://demozipaocean.zipaworld.com/`;
// export const API_END_POINT_LOCAL_ROAD = `https://road.devapi.zipaworld.com/`;
// export const API_END_POINT_LOCAL_ROAD = `https://apidocroad.zipaworld.com/`;
//    export const API_END_POINT_LOCAL_ROAD = `https://ziparoadapi.zipaworld.com/`;
 export const API_END_POINT_LOCAL_ROAD = `https://demoziparoad.zipaworld.com/`;
// export const API_END_POINT_LOCAL_COURIER = `https://courier.devapi.zipaworld.com/`;
// export const API_END_POINT_LOCAL_COURIER = `https://apidoccourier.zipaworld.com/`;
//    export const API_END_POINT_LOCAL_COURIER = `https://zipacouapi.zipaworld.com/`;
 export const API_END_POINT_LOCAL_COURIER = `https://demozipacourier.zipaworld.com/`;
// export const API_END_POINT_LOCAL_FIN = `https://finance.devapi.zipaworld.com/`;
// export const API_END_POINT_LOCAL_FIN = `https://apidocfin.zipaworld.com/`;
//   export const API_END_POINT_LOCAL_FIN = `https://zipafinapi.zipaworld.com/`;
 export const API_END_POINT_LOCAL_FIN = `https://demozipafinance.zipaworld.com/`;

// export const API_END_POINT_LOCAL_SUGGESTION = `https://suggestion.devapi.zipaworld.com/`
// export const API_END_POINT_LOCAL_SUGGESTION = `https://apidocsugg.zipaworld.com/`
// export const API_END_POINT_LOCAL_SUGGESTION = `https://31f8-125-63-100-6.ngrok-free.app/`
//    export const API_END_POINT_LOCAL_SUGGESTION = `https://zipasuggest.zipaworld.com/`
  export const API_END_POINT_LOCAL_SUGGESTION = `https://demozipasuggest.zipaworld.com/`

export const RAZOR_PAY_TEST_KEY = "13.235.140.208:4000";
export const GOOGLE_LOGIN_CLIENT_ID = "365945340002-mb3l0k2jqtc4cj088s2ktagfj5kvaud3.apps.googleusercontent.com";
export const PAGE_SIZE = 10;
export const CHART_COLORS = [
  `#311B92`,
  `#C62828`,
  `#0288D1`,
  `#FF8F00`,
  `#E65100`,
  `#1E88E5`,
  `#FFC600`,
  `rgba(105,51,2,0.98)`,
  `#7e24e6`,
  `#FF5500`,
  `#970000`,
  `#D95040`,
  `#333F79`,
  `#000000`,
  `#EE3524`,
  `#90ee7e`,
  `#f48024`,
  `#69d2e7`,
];
export const CHECK_API_ROUTE = "http://43.204.73.97:3010/api/masters/buySellReport/download";
export const GET_SHIPMENTMAIL_DATA_API = `${API_END_POINT_LOCAL_FIN}api/shipment/sendMail`;
export const GET_SHIPMENTMAIL_DATA_API_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/sendMail`;
export const GET_SHIPMENTMAIL_DATA_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/sendMail`;
export const GET_SHIPMENTMAIL_DATA_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/sendMail`;
export const GET_SHIPMENTMAIL_DATA_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/sendMail`;
export const GET_STATUS_BY_QUOTEID = `${API_END_POINT_LOCAL_FIN}api/shipment/trackByQuoteId`;
export const GET_STATUS_BY_QUOTEID_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/trackByQuoteId`;
export const GET_STATUS_BY_QUOTEID_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/trackByQuoteId`;
export const GET_STATUS_BY_QUOTEID_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/trackByQuoteId`;
export const GET_STATUS_BY_QUOTEID_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/trackByQuoteId`;
export const GRAPH_DATA_GET = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/graph1`;
export const GRAPH_TWO_DATA_GET = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/graph2`;
export const GRAPH_THREE_DATA_GET = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/graph3`;
export const GRAPH_FOUR_DATA_GET = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/graph4`;
export const GRAPH_FIVE_DATA_GET = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/graph5`;
export const GRAPH_SIX_DATA_GET = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/graph6`;
export const GET_ORIGIN_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/pincodeSuggestions/manager`;
export const GET_ORIGIN_DESTINATION_AIRPORT_API = `${API_END_POINT_LOCAL_FIN}unAuth/guest/originDestination/manager`;
export const PORT_MASTER_MANANGER_API = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/ports/manager`;
export const GET_HSN_COMMODITY_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/commodity/manager`;
export const GET_CHARGES_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/oceanFreight/getQueryRates`;
export const GET_CHARGES_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/oceanFreight/getQueryRates`;
export const GET_CHARGES_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/oceanFreight/getQueryRates`;
export const GET_CHARGES_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/masters/oceanFreight/getQueryRates`;
export const GET_CHARGES_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/masters/oceanFreight/getQueryRates`;
export const GET_LAST_SEARCH_DATA =`${API_END_POINT_LOCAL_FIN}api/auth/masters/oceanFreight/getLastLead`;
export const GET_COURIER_CHARGES_API =`${API_END_POINT_LOCAL_COURIER}api/auth/masters/oceanFreight/getCourierQueryRates`;
export const GET_COURIER_CHARGES_API_ROAD =`${API_END_POINT_LOCAL_ROAD}api/auth/masters/oceanFreight/getCourierQueryRates`;
export const GET_STORED_CHARGES_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getByQuoteId`;
export const GET_STORED_CHARGES_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getByQuoteId`;
export const GET_STORED_CHARGES_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getByQuoteId`;
export const GET_STORED_CHARGES_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/getByQuoteId`;
export const GET_STORED_CHARGES_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/getByQuoteId`;
export const GET_CURIER_STORED_CHARGES_API = `${API_END_POINT_LOCAL_FIN}api/courierShipment/getByQuoteId`;
// export const STORE_QUERY_API = `/customer/airExport/quote/create`;
export const STORE_QUERY_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/create`;
export const STORE_QUERY_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/create`;
export const STORE_QUERY_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/create`;
export const STORE_QUERY_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/create`;
export const STORE_QUERY_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/create`;
export const UPDATE_QUERY_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/update`;
export const UPDATE_QUERY_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/update`;
export const UPDATE_QUERY_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/update`;
export const UPDATE_QUERY_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/update`;
export const UPDATE_QUERY_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/update`;
export const STORE_QUERY_RATES_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/createRateLoggedUser`;
export const STORE_QUERY_RATES_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/createRateLoggedUser`;
export const STORE_QUERY_RATES_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/createRateLoggedUser`;
export const STORE_QUERY_RATES_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/createRateLoggedUser`;
export const STORE_QUERY_RATES_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/createRateLoggedUser`;
export const STORE_BOOKING_API = `${API_END_POINT_LOCAL_FIN}api/invoice/buySale/create`;
export const STORE_BOOKING_API_AIR = `${API_END_POINT_LOCAL_AIR}api/invoice/buySale/create`;
export const STORE_BOOKING_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/invoice/buySale/create`;
export const STORE_BOOKING_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/invoice/buySale/create`;
export const STORE_BOOKING_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/invoice/buySale/create`;
export const SPOT_RATE_BOOKING_API = `${API_END_POINT_LOCAL_OCEAN}api/auth/ocean/spotRate/viewData`;
export const SPOT_RATE_AIR_BOOKING_API = `${API_END_POINT_LOCAL_AIR}api/auth/air/spotRate/viewData`;
export const SPOT_RATE_AIR_BOOKING_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/air/spotRate/viewData`;
export const SPOT_RATE_AIR_BOOKING_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/air/spotRate/viewData`;
export const SPOT_RATE_AIR_BOOKING_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/air/spotRate/viewData`;
// export const SPOT_RATE_AIR_BOOKING_API = `${API_END_POINT_LOCAL_FIN}api/auth/air/spotRate/managerByPorts`;
export const SPOT_RATE_QUERY_STORE_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/create`;
export const SIGNUP_API = `${API_END_POINT_LOCAL_FIN}api/auth/customer/signup`;
export const GET_ALL_SLAB_RATE_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/airFreight/getByODP`;
export const GET_ALL_SLAB_RATE_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/oceanFreight/getByODP`;
// export const LOGIN_API = `/customer/login`;
export const LOGIN_API = `${API_END_POINT_LOCAL_FIN}api/auth/customer/login`;
export const LOGIN_API_VIA_PHONE = `${API_END_POINT_LOCAL_FIN}api/auth/customer/loginViaPhone`;
export const SPOT_RATE_MANAGER = `${API_END_POINT_LOCAL_FIN}users/airExport/spotRate/manager`;
export const GET_SPOT_RATE_DETAIL = `${API_END_POINT_LOCAL_FIN}users/airExport/spotRate/get`;
export const AIRLINE_UPDATE_URLs3 = `${API_END_POINT_LOCAL_FIN}api/s3Bucket`;
export const GET_SPOT_RATE_TICKER = `${API_END_POINT_LOCAL_OCEAN}api/auth/ocean/spotRate/managerOld`;
export const GET_SPOT_RATE_TICKER_NEW = `${API_END_POINT_LOCAL_FIN}api/auth/ocean/spotRate/manager`;
export const GET_ORIGIN_LIST = `${API_END_POINT_LOCAL_AIR}users/masters/originDestination/getAll`;
export const NOTIFICATION_SUBSCRIPTION = `${API_END_POINT_LOCAL_FIN}notification/subscribe`;

export const ADD_SPOT_RATE_DETAIL = `${API_END_POINT_LOCAL_FIN}users/airExport/spotRate/create`;
// export const SPOT_RATE_AIR_BOOKING_API = `unAuth/guest/spotRate/managerByOriginDestination`;
export const UPDATE_SPOT_RATE_DETAIL = `${API_END_POINT_LOCAL_FIN}unAuth/guest/spotRate/manager`;
export const STATE_DROPDOWN_API = `${API_END_POINT_LOCAL_FIN}unAuth/guest/state/manager`;
export const BOOKING_MANAGER_MASTER_GET_DATA = `${API_END_POINT_LOCAL_FIN}api/bookings/get`;
export const BOOKING_MANAGER_MASTER_GET_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/get`;
export const BOOKING_MANAGER_MASTER_GET_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/get`;
export const BOOKING_MANAGER_MASTER_GET_DATA_ROAD = `${API_END_POINT_LOCAL_ROAD}api/bookings/get`;
export const BOOKING_MANAGER_MASTER_GET_DATA_COURIER = `${API_END_POINT_LOCAL_COURIER}api/bookings/get`;
export const INVOICE_GET_PDF = `${API_END_POINT_LOCAL_FIN}api/invoice/getPdf`;
export const INVOICE_GET_PDF_AIR = `${API_END_POINT_LOCAL_AIR}api/invoice/getPdf`;
export const INVOICE_GET_PDF_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/invoice/getPdf`;
export const INVOICE_GET_PDF_COURIER = `${API_END_POINT_LOCAL_COURIER}api/invoice/getPdf`;
export const INVOICE_GET_PDF_ROAD = `${API_END_POINT_LOCAL_ROAD}api/invoice/getPdf`;
export const CUSTOMER_DATA_FOR_POS = `${API_END_POINT_LOCAL_FIN}api/masters/customer/get`;
export const PAY_LATER_API = `${API_END_POINT_LOCAL_FIN}api/masters/customerContract/checkContractNo`;
export const COMAPANY_GET_API = `${API_END_POINT_LOCAL_FIN}api/masters/company/branch/getAll`;
export const GET_AIRLINE_DETAILS_FOR_PERFORMA = `${API_END_POINT_LOCAL_FIN}users/airExport/bookings/getAirlineViaAwb`;
export const SEND_CONTACT_DETAILS = `${API_END_POINT_LOCAL_FIN}unAuth/guest/contactMail`;
// Airlines
export const GETALL_AIRLINE_API = `${API_END_POINT_LOCAL_AIR}api/masters/airline/getAll`;
// GET_ORIGIN_DESTINATION_AIRPORT_API
//INCOTERMS API
export const MANAGER_INCOTERMS_API = `${API_END_POINT_LOCAL_FIN}api/masters/incoTerms/manager`;
// export const INVOICE_MANAGER = `/users/invoice/managerAllInvoices`;
export const INVOICE_MANAGER = `${API_END_POINT_LOCAL_FIN}api/invoice/managerCustomer`;
export const DELETE_INVOICE = `${API_END_POINT_LOCAL_FIN}users/invoice/moveAnddelete`;
export const SAVE_INVOICE = `${API_END_POINT_LOCAL_FIN}users/airExport/sbilll/getByBookingId`;
export const UPDATE_INVOICE = `${API_END_POINT_LOCAL_FIN}users/airExport/customClearance/getByBookingId`;
export const INVOICE_PRINT_DATA = `${API_END_POINT_LOCAL_FIN}users/masters/charges/update`;
export const INVOICE_VIEW_DATA = `${API_END_POINT_LOCAL_FIN}users/invoice/get`;
export const INVOICE_CHEKED_DATA_API = `${API_END_POINT_LOCAL_FIN}users/invoice/checkInvoice`;
export const SHIPMENT_HISTORY_API = `${API_END_POINT_LOCAL_FIN}api/shipment/managerDashboard`;
export const SHIPMENT_HISTORY_API_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/managerDashboard`;
export const SHIPMENT_HISTORY_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/managerDashboard`;
export const SHIPMENT_HISTORY_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/managerDashboard`;
export const SHIPMENT_HISTORY_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/managerDashboard`;


export const COURIER_SHIPMENT_HISTORY_API = `${API_END_POINT_LOCAL_FIN}api/shipment/managerDashboard`;
export const MAWB_UPDATE_URL = `${API_END_POINT_LOCAL_FIN}users/airExport/bookings/awbUpdate`;
export const GET_COUNTRIES_LIST = `${API_END_POINT_LOCAL_FIN}api/auth/masters/country/getAll`;
export const COUNTRIES_LIST_MANAGER = `${API_END_POINT_LOCAL_FIN}api/auth/masters/country/manager`;
export const MANAGER_SHIPPER = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/managerCustomer`;
export const CONSIGNEE_FETCH_ALL_URL = `${API_END_POINT_LOCAL_FIN}users/masters/consignee/getAll`;
export const SHIPPER_FETCH_ALL_URL = `${API_END_POINT_LOCAL_FIN}users/masters/shipper/getAll`;
export const MANAGER_CONSIGNEE = `${API_END_POINT_LOCAL_FIN}api/masters/consignee/managerCustomer`;
export const COSIGNEE_BRANCH_MANAGER_GET_API = `${API_END_POINT_LOCAL_FIN}api/masters/consignee/Branch/manager`;
export const GET_CHARGES_LIST = `${API_END_POINT_LOCAL_FIN}api/masters/charges/getAll`;
export const CONVERT_CHARGES_CHARGES_LIST = `${API_END_POINT_LOCAL_FIN}users/masters/charges/mapOtherCharges`;
export const BOOKING_MANAGER_MASTER_GET_HOUSE_DATA = `${API_END_POINT_LOCAL_FIN}users/airExport/bookings/houseGet`;
export const BOOKING_MANAGER_MASTER_UPDATE_HOUSE_DATA = `${API_END_POINT_LOCAL_FIN}users/airExport/bookings/houseUpdate`;
export const GET_STATE_LIST = `${API_END_POINT_LOCAL_FIN}api/auth/masters/state/getByCountry`;
// export const GET_LAST_SEARCH_DATA = `unAuth/guest/getLastLead`;
export const GET_HAWB_NUMBER_UNIQUE = `${API_END_POINT_LOCAL_FIN}api/masters/hawbstock/createHawbNo`;
export const GET_HAWB_NUMBER_UNIQUE_AIR = `${API_END_POINT_LOCAL_AIR}api/masters/hawbstock/createHawbNo`;
export const GET_HAWB_NUMBER_UNIQUE_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/masters/hawbstock/createHawbNo`;
// export const GET_FCL_CONTAINER_DATA = `/users/masters/containerMaster/getAll`;
export const GET_FCL_CONTAINER_DATA = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/containers/getAll`;
export const PAYMENT_GATEWAY = `${API_END_POINT_LOCAL_FIN}api/masters/pay/createOrder`;
export const PAYMENT_GATEWAY_AIR = `${API_END_POINT_LOCAL_AIR}api/masters/pay/createOrder`;
export const PAYMENT_GATEWAY_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/masters/pay/createOrder`;
export const PAYMENT_GATEWAY_COURIER = `${API_END_POINT_LOCAL_COURIER}api/masters/pay/createOrder`;
export const PAYMENT_GATEWAY_ROAD = `${API_END_POINT_LOCAL_ROAD}api/masters/pay/createOrder`;
export const PAYMENT_SUCCESS_MAIL = `${API_END_POINT_LOCAL_FIN}api/masters/pay/paymentReceivedMail`;
export const PAYMENT_SUCCESS_MAIL_AIR = `${API_END_POINT_LOCAL_AIR}api/masters/pay/paymentReceivedMail`;
export const PAYMENT_SUCCESS_MAIL_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/masters/pay/paymentReceivedMail`;
export const PAYMENT_SUCCESS_MAIL_COURIER = `${API_END_POINT_LOCAL_COURIER}api/masters/pay/paymentReceivedMail`;
export const PAYMENT_SUCCESS_MAIL_ROAD = `${API_END_POINT_LOCAL_ROAD}api/masters/pay/paymentReceivedMail`;
export const STORE_INVOICE = `${API_END_POINT_LOCAL_FIN}customer/airExport/performaInvoice/create`;
export const UPDATE_INVOICE_DATA = `${API_END_POINT_LOCAL_FIN}customer/airExport/performaInvoice/update`;
export const APPLY_COUPONCODE_API = `${API_END_POINT_LOCAL_FIN}api/masters/couponMaster/apply`;
export const RETURN_TO_CONFIREM = `${API_END_POINT_LOCAL_FIN}api/masters/pay/verify`;
export const RETURN_TO_CONFIREM_AIR = `${API_END_POINT_LOCAL_AIR}api/masters/pay/verify`;
export const RETURN_TO_CONFIREM_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/masters/pay/verify`;
export const RETURN_TO_CONFIREM_COURIER = `${API_END_POINT_LOCAL_COURIER}api/masters/pay/verify`;
export const RETURN_TO_CONFIREM_ROAD = `${API_END_POINT_LOCAL_ROAD}api/masters/pay/verify`;
export const SAVE_CONSIGNEE = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/createConsigneeWithMultiBranch`;
export const MANAGER_SHIPPER_APPROVAL = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/managerCustomer`;
export const SAVE_SHIPPER = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/createShipperWithMultiBranch`;
export const MANAGER_CONSIGNEE_APPROVAL = `${API_END_POINT_LOCAL_FIN}api/masters/consignee/managerCustomer`;
export const SHIPPER_CONSIGNE_ADD_API = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/createShipperAndConsignee`;
export const GET_CURRENCY_LIST_FOR_REGISTRATION = `${API_END_POINT_LOCAL_FIN}api/masters/currencyMaster/getAll`;
export const REGISTRATION_MASTER_CREATE_HIT = `${API_END_POINT_LOCAL_FIN}users/masters/customer/update`;
export const CUSTOMER_MASTER_BRANCH_CREATE_HIT = `${API_END_POINT_LOCAL_FIN}users/masters/customer/branch/create`;
export const CUSTOMER_MASTER_BRANCH_UPDATE_HIT = `${API_END_POINT_LOCAL_FIN}api/masters/customer/Branch/update`;
export const GET_ALL_CUSTOMER_MASTER_LIST = `${API_END_POINT_LOCAL_FIN}demo`;
export const ADD_ATTACHMENT = `${API_END_POINT_LOCAL_FIN}api/queries/uploadDocAttachment`;
export const ADD_ATTACHMENT_AIR = `${API_END_POINT_LOCAL_AIR}api/queries/uploadDocAttachment`;
export const ADD_ATTACHMENT_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/queries/uploadDocAttachment`;
export const ADD_ATTACHMENT_ROAD = `${API_END_POINT_LOCAL_ROAD}api/queries/uploadDocAttachment`;
export const ADD_ATTACHMENT_COURIER = `${API_END_POINT_LOCAL_COURIER}api/queries/uploadDocAttachment`;
export const UPDATE_SHIPPER = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/updateShipperWithMultiBranch`;
export const DELETE_SHIPPER = `${API_END_POINT_LOCAL_FIN}users/masters/shipper/delete`;
export const E_DOCKET_REQUEST_REQUEST = `${API_END_POINT_LOCAL_FIN}api/shipment/getByQuoteId`;
export const E_DOCKET_REQUEST_REQUEST_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/getByQuoteId`;
export const E_DOCKET_REQUEST_REQUEST_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/getByQuoteId`;
export const E_DOCKET_REQUEST_REQUEST_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/getByQuoteId`;
export const E_DOCKET_REQUEST_REQUEST_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/getByQuoteId`;
export const E_DOCKET_JOBID_MANAGER_REQUEST = `${API_END_POINT_LOCAL_FIN}api/shipment/managerCustomer`;
export const E_DOCKET_MAWB_MANAGER_REQUEST = `${API_END_POINT_LOCAL_FIN}customer/airExport/quote/getAWBNosForEDOC`;
export const E_DOCKET_MANAGER_DATA_REQUEST_API = `${API_END_POINT_LOCAL_FIN}customer/airExport/quote/getQuoteDetailForEDoc`;
export const DELETE_CONSIGNEE_BY_ID = `${API_END_POINT_LOCAL_FIN}users/masters/consignee/delete`;
export const UPDATE_CONSIGNEE = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/updateConsigneeWithMultiBranch`;
export const INVOICE_DATA = `${API_END_POINT_LOCAL_FIN}customer/airExport/performaInvoice/get`;
export const NOTIFICATION_LOAD = `${API_END_POINT_LOCAL_FIN}api/masters/customerNotifications/manager`;
export const NOTIFICATION_READ = `${API_END_POINT_LOCAL_FIN}api/masters/customerNotifications/seen`;
export const AIR_EXPORT_TRACKING = `${API_END_POINT_LOCAL_FIN}users/airExport/tracking/trackAwb`;
export const AIR_EXPORT_TRACKING_FSU = `${API_END_POINT_LOCAL_FIN}users/airExport/tracking/manager`;
export let GET_NEWS_TICKER = `${API_END_POINT_LOCAL_FIN}api/auth/masters/news/managerNews`;
// export let GET_NEWS_TICKER = `users/masters/news/getNewsforTicker`;
// export let GET_ADDS_TICKER = `/users/masters/news/getAdsforTicker`;
export let GET_ADDS_TICKER = `${API_END_POINT_LOCAL_FIN}api/auth/masters/news/managerAds`;
export let OFFER_VIEW_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/news/managerAdsView`;
export let GET_All_NEWS_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/masters/news/getAllNews`;
export const QUERY_MANAGER_UPLOAD_MSDS = `${API_END_POINT_LOCAL_FIN}api/queries/readDg`;
export const GUEST_CUSTOMER_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/customer/guest`;
export const MANAGER_SHIPPER_BRANCH = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/Branch/getBranches`;
export const MANAGER_CONSIGNEE_BRANCH = `${API_END_POINT_LOCAL_FIN}api/masters/consignee/Branch/getBranches`;
export const CREATE_BOOKING_API = `${API_END_POINT_LOCAL_FIN}api/bookings/csApproval`;
export const CREATE_BOOKING_API_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/csApproval`;
export const CREATE_BOOKING_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/csApproval`;
export const CREATE_BOOKING_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/bookings/csApproval`;
export const CREATE_BOOKING_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/bookings/csApproval`;
export const UPDATE_RATE_FOR_CREDIT = `${API_END_POINT_LOCAL_FIN}api/queries/rates/update`;
export const UPDATE_RATE_FOR_CREDIT_AIR = `${API_END_POINT_LOCAL_AIR}api/queries/rates/update`;
export const UPDATE_RATE_FOR_CREDIT_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/queries/rates/update`;
export const UPDATE_RATE_FOR_CREDIT_ROAD = `${API_END_POINT_LOCAL_ROAD}api/queries/rates/update`;
export const UPDATE_RATE_FOR_CREDIT_COURIER = `${API_END_POINT_LOCAL_COURIER}api/queries/rates/update`;
export const CREATE_COURIER_BOOKING_API = `${API_END_POINT_LOCAL_FIN}api/courierbookings/csApproval`;
export const CURRENCY_RATE_CONVERSION_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/currencyConversionMaster/conversion`;
export const GET_SHIPPINGLINE_API = `${API_END_POINT_LOCAL_OCEAN}api/masters/shippingLine/get`;
export const GET_PROFORMA_ID_BY_BOOKINGID = `${API_END_POINT_LOCAL_FIN}api/proforma/getByBookingId`;
export const GET_PROFORMA_ID_BY_BOOKINGID_AIR = `${API_END_POINT_LOCAL_AIR}api/proforma/getByBookingId`;
export const GET_PROFORMA_ID_BY_BOOKINGID_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/proforma/getByBookingId`;
export const GET_PROFORMA_ID_BY_BOOKINGID_ROAD = `${API_END_POINT_LOCAL_ROAD}api/proforma/getByBookingId`;
export const GET_PROFORMA_ID_BY_BOOKINGID_COURIER = `${API_END_POINT_LOCAL_COURIER}api/proforma/getByBookingId`;
export const GET_PROFORMA_DATA = `${API_END_POINT_LOCAL_FIN}api/proforma/get`;
export const GET_PROFORMA_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/proforma/get`;
export const GET_PROFORMA_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/proforma/get`;
export const GET_PROFORMA_DATA_ROAD = `${API_END_POINT_LOCAL_ROAD}api/proforma/get`;
export const GET_PROFORMA_DATA_COURIER = `${API_END_POINT_LOCAL_COURIER}api/proforma/get`;
export const GET_SINGLE_CUSTOMER_BRANCH_DATA = `${API_END_POINT_LOCAL_FIN}api/masters/customer/Branch/get`;
export const UPDATE_BOOKING_API = `${API_END_POINT_LOCAL_FIN}api/bookings/update`;
export const UPDATE_BOOKING_API_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/update`;
export const UPDATE_BOOKING_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/update`;
export const UPDATE_BOOKING_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/bookings/update`;
export const UPDATE_BOOKING_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/bookings/update`;
export const GET_INVOICE_DATA = `${API_END_POINT_LOCAL_FIN}api/invoice/get`;
export const GET_INVOICE_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/invoice/get`;
export const GET_INVOICE_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/invoice/get`;
export const GET_INVOICE_DATA_COURIER = `${API_END_POINT_LOCAL_COURIER}api/invoice/get`;
export const GET_INVOICE_DATA_ROAD = `${API_END_POINT_LOCAL_ROAD}api/invoice/get`;
export const GET_ALL_SHIPER_BRANCH_API = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/Branch/getBranches`;
export const GET_ALL_CONSIGNEE_BRANCH_API = `${API_END_POINT_LOCAL_FIN}api/masters/consignee/Branch/getBranches`;
export const STORE_GUEST_QUERY_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/create`;
export const STORE_GUEST_QUERY_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/create`;
export const STORE_GUEST_QUERY_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/create`;
export const STORE_GUEST_QUERY_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/create`;
export const STORE_GUEST_QUERY_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/create`;
export const STORE_QUERY_COURIER_API = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/create`;
/*export const STORE_QUERY_COURIER_API = `${API_END_POINT_LOCAL_FIN}api/courierQueries/create`;*/
export const SAVE_HBL_DATA = `${API_END_POINT_LOCAL_FIN}api/bookings/updateHbl`;
export const SAVE_HBL_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/updateHbl`;
export const SAVE_HBL_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/updateHbl`;
export const NOTIFICATION_SEEN_ALL = `${API_END_POINT_LOCAL_FIN}api/masters/customerNotifications/seenAll`;
export const USER_LOGIN_SEND_MOBILE = `${API_END_POINT_LOCAL_FIN}api/auth/user/sendOtp`;
export const USER_LOGIN_CHECKOTP = `${API_END_POINT_LOCAL_FIN}api/auth/user/checkOtp`;
export const CHECK_USER = `${API_END_POINT_LOCAL_FIN}api/auth/user/checkCustomer`;
export const CHECK_USER_VIA_PHONE = `${API_END_POINT_LOCAL_FIN}api/auth/user/checkCustomerViaPhone`;
export const CHECK_USER_VIA_EMAIL = `${API_END_POINT_LOCAL_FIN}api/auth/user/checkCustomerViaEmail`;
export const UPDATE_CUSTOMER_DATA = `${API_END_POINT_LOCAL_FIN}api/masters/customer/updateByCustomer`;
export const UPDATE_CUSTOMER_DATA_BY_INDIVIDUAL = `${API_END_POINT_LOCAL_FIN}api/masters/customer/updateByCustomerForIndividual`;
export const ORIGIN_DESTINATION_MASTER_MANANGER_API = `${API_END_POINT_LOCAL_AIR}api/auth/masters/originDestination/manager`;
export const AIRLINE_MASTER_MANANGER_API = `${API_END_POINT_LOCAL_AIR}api/masters/airline/manager`;
export const GET_STATUS_BY_BOOKINGID = `${API_END_POINT_LOCAL_FIN}api/shipment/trackByBookingId`;
export const GET_STATUS_BY_BOOKINGID_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/trackByBookingId`;
export const GET_STATUS_BY_BOOKINGID_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/trackByBookingId`;
export const GET_STATUS_BY_BOOKINGID_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/trackByBookingId`;
export const GET_STATUS_BY_BOOKINGID_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/trackByBookingId`;
export const GET_QUERY_PREVIEW_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/shipment/getByQuoteId`;
export const GET_QUERY_PREVIEW_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/shipment/getByQuoteId`;
export const GET_QUERY_PREVIEW_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/shipment/getByQuoteId`;
export const GET_QUERY_PREVIEW_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/shipment/getByQuoteId`;
export const GET_QUERY_PREVIEW_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/shipment/getByQuoteId`;
export const GET_QUERY_REVERT_API = `${API_END_POINT_LOCAL_FIN}api/masters/oceanFreight/getQueryRates`;
export const GET_QUERY_REVERT_AUTO_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getByQuoteId`;
export const GET_QUERY_REVERT_AUTO_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getByQuoteId`;
export const GET_QUERY_REVERT_AUTO_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getByQuoteId`;
export const GET_QUERY_REVERT_AUTO_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/getByQuoteId`;
export const GET_QUERY_REVERT_AUTO_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/getByQuoteId`;
export const GET_STATE_API = `${API_END_POINT_LOCAL_FIN}api/masters/state/get`;
export const MARKET_QUERIES_API = `${API_END_POINT_LOCAL_FIN}api/queries/predict/marketQuery`;
export const QUERY_DATA_ADD_API = `${API_END_POINT_LOCAL_FIN}api/queries/create`;
export const QUERY_DATA_UPDATE_API = `${API_END_POINT_LOCAL_FIN}api/queries/update`;
export const QUERY_MANAGER_MASTER_DELETE = `${API_END_POINT_LOCAL_FIN}api/queries/delete`;
export const AWB_PDF_SEND_MANAGER = `${API_END_POINT_LOCAL_FIN}api/bookings/getMblUrl`;
export const HAWB_PDF_GETURL = `${API_END_POINT_LOCAL_FIN}api/bookings/getHawbPdf`;
export const QUERY_SHIPMENT_MANAGER_API = `${API_END_POINT_LOCAL_FIN}api/shipment/manager`;
export const QUERY_PAST_SHIPMENT_MANAGER_API = `${API_END_POINT_LOCAL_FIN}api/shipment/managerPastShipment`;
export const QUERY_SHIPMENT_MANAGER_HISTORY_API = `${API_END_POINT_LOCAL_FIN}api/shipment/managerDashboard`;
export const UPDATE_STATE_API = `${API_END_POINT_LOCAL_FIN}api/masters/state/update`;
export const ORIGIN_DESTINATION_MASTER_MANAGER_API = `${API_END_POINT_LOCAL_AIR}api/auth/masters/originDestination/manager`;
export const SPOT_RATE_AIR_DATA_MANAGER = `${API_END_POINT_LOCAL_FIN}api/auth/air/spotRate/manager`;
export const SPOT_RATE_AIR_DATA_MANAGERS = `${API_END_POINT_LOCAL_AIR}api/auth/air/spotRate/managerOld`;
export const DASHBOARD_STATICS_API = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/pricing/Counts`;
export const DASHBOARD_STATICS_API_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/dashboard/pricing/Counts`;
export const DASHBOARD_STATICS_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/dashboard/pricing/Counts`;
export const DASHBOARD_STATICS_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/dashboard/pricing/Counts`;
export const DASHBOARD_STATICS_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/dashboard/pricing/Counts`;
export const DASHBOARD_STATICS_VOLUME_COUNTS_API = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/volumeCounts`;
export const DASHBOARD_STATICS_VOLUME_COUNTS_MONTH_API = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/volumeCountsNew`;
export const DASHBOARD_STATICS_VOLUME_COUNTS_MONTH_API_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/dashboard/volumeCountsNew`;
export const DASHBOARD_STATICS_VOLUME_COUNTS_MONTH_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/dashboard/volumeCountsNew`;
export const DASHBOARD_STATICS_VOLUME_COUNTS_MONTH_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/dashboard/volumeCountsNew`;
export const DASHBOARD_STATICS_VOLUME_COUNTS_MONTH_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/dashboard/volumeCountsNew`;
export const FCL_TUES_ORIGIN_WISE_CUSTOMER = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/fclTuesOriginWiseCustomer`;
export const FCL_TUES_DESTINATION_WISE_CUSTOMER = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/fclTuesDestinationWiseCustomer`;
export const LCL_CBM_DESTINATION_WISE_CUSTOMER = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/lclCbmDestinationWiseCustomer`;
export const LCL_CBM_ORIGIN_WISE_CUSTOMER = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/lclCbmOriginWiseCustomer`;
export const LCL_CBM_Shipping_Line_WISE_CUSTOMER = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/lclCbmShippingLineWiseCustomer`;
export const AIR_VOLUME_ORIGIN_WISE_CUSTOMER = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/airVolumeOriginWiseCustomer`;
export const AIR_VOLUME_DESTINATION_WISE_CUSTOMER = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/airVolumeDestinationWiseCustomer`;
export const AIR_VOLUME_AIRLINE_WISE_CUSTOMER = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/airVolumeAirlineWiseCustomer`;
export const DASHBOARD_DISTINCT_SHIPPERWISE_TONNAGE = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/distinctShipperWiseTonnage`;
export const DASHBOARD_DISTINCT_DESTINATIONWISE_TONNAGE = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/distinctDestinationWiseTonnage`;
export const DASHBOARD_DISTINCT_CONSIGNEE_WISE_TONNAGE = `${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/distinctConsigneeWiseTonnage`;
export const FORGOT_PASSWORD_API = `${API_END_POINT_LOCAL_FIN}api/auth/customer/forgetPassword`;
export const RESET_PASSWORD_API = `${API_END_POINT_LOCAL_FIN}api/auth/customer/updateNewPassword`;
export const GET_VESSELS_DATA_API = `${API_END_POINT_LOCAL_FIN}api/masters/vesselSchedules/findVessels`;
export const GET_DATA_ADDINTENEDED_PLAN_API = `${API_END_POINT_LOCAL_FIN}api/queries/addIntendedPlan`;
export const SHIPER_BRANCH_MANAGER_GET_API = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/Branch/manager`;
export const PAYMENT_GATEWAY_CREATE_ORDER_NEW = `${API_END_POINT_LOCAL_FIN}api/masters/pay/createOrderStripe`;
export const PAYMENT_GATEWAY_CHECKOUT = `${API_END_POINT_LOCAL_FIN}api/masters/pay/checkout`;
export const PAYMENT_GATEWAY_UPDATE_PAYMENT_STRIPE = `${API_END_POINT_LOCAL_FIN}api/masters/pay/updatePaymentNew`;
export const MANAGER_PICKUPORIGIN_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/pickupOrigins/manager`;
export const MANAGER_PICKUPORIGINPINCODE_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/pickupOrigins/managerPincode`;
export const MANAGER_OMAN_CITY_LIST_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/city/manager`;
export const MANAGER_CITY_API = `${API_END_POINT_LOCAL_FIN}api/masters/city/manager`;
export const EMAILS_ALL = `${API_END_POINT_LOCAL_FIN}api/masters/emailMaster/getAll`;
export const COUPAN_GET = `${API_END_POINT_LOCAL_FIN}api/auth/masters/couponMaster/get`;
export const GET_ASYADDOCKET_PDF_API = `${API_END_POINT_LOCAL_FIN}api/bookings/getAsyadDocketPdf`;
export const GET_ASYADDOCKET_PDF_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/bookings/getAsyadDocketPdf`;
export const GET_ASYADDOCKET_PDF_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/bookings/getAsyadDocketPdf`;
export const GET_ASYADDOCKET_PDF_API_A5 = `${API_END_POINT_LOCAL_FIN}api/bookings/getAsyadDocketPdfA5`;
export const GET_ASYADDOCKET_PDF_API_A5_COURIER = `${API_END_POINT_LOCAL_COURIER}api/bookings/getAsyadDocketPdfA5`;
export const GET_ASYADDOCKET_PDF_API_A5_ROAD = `${API_END_POINT_LOCAL_ROAD}api/bookings/getAsyadDocketPdfA5`;
export const GET_TRACKING_API = `${API_END_POINT_LOCAL_FIN}api/masters/trackingMasters/getByQuoteId`;
export const GET_TRACKING_SEARCH_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/trackingMasters/getByQuoteId`;
export const GET_TRACKING_SEARCH_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/trackingMasters/getByQuoteId`;
export const GET_TRACKING_SEARCH_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/trackingMasters/getByQuoteId`;
export const GET_TRACKING_SEARCH_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/masters/trackingMasters/getByQuoteId`;
export const GET_TRACKING_SEARCH_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/masters/trackingMasters/getByQuoteId`;
export const SEARCH_TRACKING_BY_NUMBER = `${API_END_POINT_LOCAL_FIN}api/auth/queries/getByBlNo`;
export const SEARCH_TRACKING_BY_NUMBER_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/getByBlNo`;
export const SEARCH_TRACKING_BY_NUMBER_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/getByBlNo`;
export const SEARCH_TRACKING_BY_NUMBER_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/getByBlNo`;
export const SEARCH_TRACKING_BY_NUMBER_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/getByBlNo`;
export const UPDATE_EDIT_RATES = `${API_END_POINT_LOCAL_FIN}api/queries/rates/update`;
export const UPDATE_EDIT_RATES_AIR = `${API_END_POINT_LOCAL_AIR}api/queries/rates/update`;
export const UPDATE_EDIT_RATES_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/queries/rates/update`;
export const UPDATE_EDIT_RATES_ROAD = `${API_END_POINT_LOCAL_ROAD}api/queries/rates/update`;
export const UPDATE_EDIT_RATES_COURIER = `${API_END_POINT_LOCAL_COURIER}api/queries/rates/update`;
export const UPDATE_CONTAINER_RATES_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/updateContainer`;
export const UPDATE_CONTAINER_RATES_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/updateContainer`;
export const UPDATE_CONTAINER_RATES_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/updateContainer`;
export const UPDATE_CONTAINER_RATES_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/updateContainer`;
export const UPDATE_CONTAINER_RATES_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/updateContainer`;
export const MANAGER_PUBLISH_AIRFREIGHT_API = `${API_END_POINT_LOCAL_FIN}api/masters/publishAirFreights/manager`;
export const MANAGER_VEHICLETYPE_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/vehicleTypeMaster/manager`;
export const SURCHARGES_AIR_FREIGHT_API = `${API_END_POINT_LOCAL_AIR}api/masters/airFreightsSurcharges/getSurcharges`;
export const RATES_QUOTED_MANAGER_API = `${API_END_POINT_LOCAL_FIN}api/shipment/managerCustomerRatesQuoted`;
export const RATES_QUOTED_MANAGER_API_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/managerCustomerRatesQuoted`;
export const RATES_QUOTED_MANAGER_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/managerCustomerRatesQuoted`;
export const RATES_QUOTED_MANAGER_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/managerCustomerRatesQuoted`;
export const RATES_QUOTED_MANAGER_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/managerCustomerRatesQuoted`;
export const SHIPMENT_STATUS_MANAGER_API = `${API_END_POINT_LOCAL_FIN}api/shipment/managerCustomerShipment`;
export const SHIPMENT_STATUS_MANAGER_API_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/managerCustomerShipment`;
export const SHIPMENT_STATUS_MANAGER_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/managerCustomerShipment`;
export const SHIPMENT_STATUS_MANAGER_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/managerCustomerShipment`;
export const SHIPMENT_STATUS_MANAGER_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/managerCustomerShipment`;
export const CAREER_DATA_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/shipment/sendCareerMail`;
export const MANAGER_COMMODITY_GENERAL = `${API_END_POINT_LOCAL_FIN}api/unAuthMasters/commodityMasterGeneral/manager`;
export const CAREER_MASTER_MANAGER = `${API_END_POINT_LOCAL_FIN}api/auth/masters/careerMaster/manager`;
export const REMOVE_COUPAN = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/removeCoupan`;
export const REMOVE_COUPAN_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/removeCoupan`;
export const REMOVE_COUPAN_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/removeCoupan`;
export const REMOVE_COUPAN_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/removeCoupan`;
export const REMOVE_COUPAN_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/removeCoupan`;
export const ADD_COUPAN = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getRatesAfterApplyCoupan`;
export const ADD_COUPAN_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getRatesAfterApplyCoupan`;
export const ADD_COUPAN_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getRatesAfterApplyCoupan`;
export const ADD_COUPAN_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/getRatesAfterApplyCoupan`;
export const ADD_COUPAN_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/getRatesAfterApplyCoupan`;
export const IMAGE_CATEGORY_MANAGER = `${API_END_POINT_LOCAL_FIN}api/auth/masters/category/manager`;
export const IMAGE_CATEGORY_BRANCH_MANAGER = `${API_END_POINT_LOCAL_FIN}api/auth/masters/category/Branch/manager`;
export const IMAGE_CATEGORY_ALL_IMAGES = `${API_END_POINT_LOCAL_FIN}api/auth/masters/category/Branch/managerAllData`;
export const COUPON_OFFER_SEND = `${API_END_POINT_LOCAL_FIN}api/auth/queries/sendCoupon`;
export const MANAGER_BLOG_MASTER = `${API_END_POINT_LOCAL_FIN}api/unAuthMasters/blogMasters/manager`;
export const GET_BLOG_MASTER = `${API_END_POINT_LOCAL_FIN}api/unAuthMasters/blogMasters/get`;
export const ADD_COMMENT_BLOG = `${API_END_POINT_LOCAL_FIN}api/masters/blogMasters/addComment`;
export const REPLY_COMMENT_BLOG = `${API_END_POINT_LOCAL_FIN}api/masters/blogMasters/replyToComment`;
export const REPLY_TO_REPLY = `${API_END_POINT_LOCAL_FIN}api/masters/blogMasters/replyToReply`;
export const LIKE_DISLIKE_BLOG = `${API_END_POINT_LOCAL_FIN}api/masters/blogMasters/disableCommentAndLike`;
export const GENERATE_AGREEMENT_API = `${API_END_POINT_LOCAL_FIN}api/auth/customer/getAgreement`;
export const SEND_CARGO_MESSAGE_MAIL = `${API_END_POINT_LOCAL_FIN}api/bookings/sendCargoMessageMail`;
export const SEND_CARGO_MESSAGE_MAIL_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/sendCargoMessageMail`;
export const SEND_CARGO_MESSAGE_MAIL_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/sendCargoMessageMail`;
export const SEND_CARGO_MESSAGE_MAIL_ROAD = `${API_END_POINT_LOCAL_ROAD}api/bookings/sendCargoMessageMail`;
export const SEND_CARGO_MESSAGE_MAIL_COURIER = `${API_END_POINT_LOCAL_COURIER}api/bookings/sendCargoMessageMail`;
export const DRIVER_OTP_CHECK = `${API_END_POINT_LOCAL_FIN}api/bookings/verifyOTPforDriver`;
export const PICK_UP_MAIL_MSG = `${API_END_POINT_LOCAL_FIN}api/bookings/sendCargoPickupMessageMail`;
export const PICK_UP_MAIL_MSG_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/sendCargoPickupMessageMail`;
export const PICK_UP_MAIL_MSG_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/sendCargoPickupMessageMail`;
export const PICK_UP_MAIL_MSG_ROAD = `${API_END_POINT_LOCAL_ROAD}api/bookings/sendCargoPickupMessageMail`;
export const PICK_UP_MAIL_MSG_COURIER = `${API_END_POINT_LOCAL_COURIER}api/bookings/sendCargoPickupMessageMail`;
export const CITY_LIST_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/masters/city/managers`;
export const CONTRACT_RATE_DATA = `${API_END_POINT_LOCAL_FIN}api/masters/contractPricingRate/getByContractNo`
export const GET_BOOKING_NO = `${API_END_POINT_LOCAL_FIN}api/shipment/prs/getByBookingNo`;
export const GET_BOOKING_NO_AIR = `${API_END_POINT_LOCAL_AIR}api/shipment/prs/getByBookingNo`;
export const GET_BOOKING_NO_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/shipment/prs/getByBookingNo`;
export const GET_BOOKING_NO_ROAD = `${API_END_POINT_LOCAL_ROAD}api/shipment/prs/getByBookingNo`;
export const GET_BOOKING_NO_COURIER = `${API_END_POINT_LOCAL_COURIER}api/shipment/prs/getByBookingNo`;
export const COMPANYDETAILS = {
  companyName: `AAA 2 INNOVATE PVT LTD`,
};
export const CREATE_PAGE_LOGS = `${API_END_POINT_LOCAL_FIN}api/logsForPageTimerCustomer`;
export const RECOMMENDED_RATES_OF_ZIPA = `${API_END_POINT_LOCAL_SUGGESTION}api/unAuthMasters/oceanFreight/zipaRecommendedRates`;
export const TRANSIT_TIME_GET_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getDistinctTransitTime`;
export const TRANSIT_TIME_GET_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getDistinctTransitTime`;
export const TRANSIT_TIME_GET_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getDistinctTransitTime`;
export const TRANSIT_TIME_GET_DATA_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/getDistinctTransitTime`;
export const TRANSIT_TIME_GET_DATA_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/getDistinctTransitTime`;
export const GET_BY_QUOTE_ID = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getByQuoteIdWithServiceProvider`;
export const GET_BY_QUOTE_ID_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getByQuoteIdWithServiceProvider`;
export const GET_BY_QUOTE_ID_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getByQuoteIdWithServiceProvider`;
export const GET_BY_QUOTE_ID_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/getByQuoteIdWithServiceProvider`;
export const GET_BY_QUOTE_ID_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/getByQuoteIdWithServiceProvider`;
export const RATE_SINGLE_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/get`;
export const RATE_SINGLE_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/get`;
export const RATE_SINGLE_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/get`;
export const RATE_SINGLE_DATA_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/get`;
export const RATE_SINGLE_DATA_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/get`;
export const FASTEST_CHEAPEST_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getCheapestFastest`;
export const FASTEST_CHEAPEST_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getCheapestFastest`;
export const FASTEST_CHEAPEST_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getCheapestFastest`;
export const FASTEST_CHEAPEST_DATA_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/getCheapestFastest`;
export const FASTEST_CHEAPEST_DATA_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/getCheapestFastest`;
export const QUERY_MANAGER_MASTER_CUSTOMER_BRANCH = `${API_END_POINT_LOCAL_FIN}api/masters/customer/Branch/manager`;
export const PORT_BY_PREFERNCE = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/ports/getByPreference`;
export const AIRPORT_BY_PREFERNCE = `${API_END_POINT_LOCAL_AIR}api/auth/masters/originDestination/getByPreference`;
export const CITY_BY_PREFERNCE = `${API_END_POINT_LOCAL_FIN}api/auth/masters/city/getByPreference`;
export const GET_BY_QUOTE_ID_TRANSIT_TIME = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getByQuoteIdWithTransitTime`;
export const GET_BY_QUOTE_ID_TRANSIT_TIME_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getByQuoteIdWithTransitTime`;
export const GET_BY_QUOTE_ID_TRANSIT_TIME_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getByQuoteIdWithTransitTime`;
export const GET_BY_QUOTE_ID_TRANSIT_TIME_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/getByQuoteIdWithTransitTime`;
export const GET_BY_QUOTE_ID_TRANSIT_TIME_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/getByQuoteIdWithTransitTime`;
export const TRADE_DATA_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/shipment/sendTradeFinanceMail`;
export const ORIGIN_DESTINATION_ID = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/ports/getByCode`;
export const ORIGIN_DESTINATION_ID_AIRPORT = `${API_END_POINT_LOCAL_AIR}api/auth/masters/originDestination/getByCode`;
export const DESTINATION_WISE_TONNAGE_AIR_API = `${API_END_POINT_LOCAL_AIR}api/shipment/dashboard/distinctTopTenDestinationWiseTonnageAndCountAir`;
export const DESTINATION_WISE_TONNAGE_OCEAN_API = `${API_END_POINT_LOCAL_OCEAN}api/shipment/dashboard/distinctTopTenDestinationWiseTonnageAndCountOcean`;
export const AIR_VOLUME_TONNAGE_AIRLINE_WISE_CUSTOMER = `${API_END_POINT_LOCAL_AIR}api/shipment/dashboard/distinctTopTenAirLineWiseTonnageAndCount`;
export const SHIPPING_LINE_VOLUME_TONNAGE_AIRLINE_WISE_CUSTOMER = `${API_END_POINT_LOCAL_OCEAN}api/shipment/dashboard/distinctTopTenShippingLineWiseTonnageAndCount`;
export const MEMBERSHIP_DATA_SEND_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/shipment/membershipMail`;
export const CONTACT_DATA_SEND_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/shipment/contactMail`;
export const ADDITIONAL_ORIGIN_CHARGES = `${API_END_POINT_LOCAL_FIN}api/auth/masters/oceanFreight/getQueryOriginClearanceRates`;
export const ADDITIONAL_ORIGIN_CHARGES_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/oceanFreight/getQueryOriginClearanceRates`;
export const ADDITIONAL_ORIGIN_CHARGES_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/oceanFreight/getQueryOriginClearanceRates`;
export const ADDITIONAL_RATES_UPDATE = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/updateRatesWithAdditionalCharges`;
export const ADDITIONAL_RATES_UPDATE_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/updateRatesWithAdditionalCharges`;
export const ADDITIONAL_RATES_UPDATE_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/updateRatesWithAdditionalCharges`;
export const UPDATE_QUOTE_ID_API = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/updateQuote`;
export const UPDATE_QUOTE_ID_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/updateQuote`;
export const UPDATE_QUOTE_ID_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/updateQuote`;
export const UPDATE_QUOTE_ID_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/updateQuote`;
export const UPDATE_QUOTE_ID_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/updateQuote`;
export const CERTIFICATION_AWARDS_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/certification/getall`;
export const GET_COUNTRIES_LISTS = `${API_END_POINT_LOCAL_FIN}api/masters/country/getAll`;
export const GET_HAWB_SINGLE_PDF_URL = `${API_END_POINT_LOCAL_FIN}api/bookings/getHawbExactUrl`;
export const GET_HAWB_SINGLE_PDF_URL_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/getHawbExactUrl`;
export const GET_HAWB_SINGLE_PDF_URL_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/getHawbExactUrl`;
export const GET_HBL_PDF_API = `${API_END_POINT_LOCAL_FIN}api/bookings/getHblPdf`;
export const GET_HBL_PDF_API_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/getHblPdf`;
export const GET_HBL_PDF_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/getHblPdf`;
export const SEARCH_TRACKING_BY_BL  = `${API_END_POINT_LOCAL_FIN}api/auth/masters/containers/trackByBlNo`;
export const SEARCH_TRACKING_BY_BOOKING_REFERENCE_NO = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/CMA/Tracking`;
export const SEARCH_TRACKING_BY_CONTAINER = `${API_END_POINT_LOCAL_FIN}api/auth/masters/containers/trackByBookingReferenceNo`
export const SHIPPING_LINE_GET_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getDistinctShippingLines`;
export const SHIPPING_LINE_GET_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getDistinctShippingLines`;
export const SHIPPING_LINE_GET_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getDistinctShippingLines`;
export const SHIPPING_LINE_GET_DATA_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/getDistinctShippingLines`;
export const SHIPPING_LINE_GET_DATA_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/getDistinctShippingLines`;
export const SHIPPING_LINE_ACCORDING_TRANSIT_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getDistinctShippingLinesAccTransitTime`;
export const SHIPPING_LINE_ACCORDING_TRANSIT_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/getDistinctShippingLinesAccTransitTime`;
export const SHIPPING_LINE_ACCORDING_TRANSIT_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/getDistinctShippingLinesAccTransitTime`;
export const TRANSIT_ACCORDING_SHIPPING_LINE_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/getDistinctTransitTimeAccShippingLine`;
export const VESSEL_SCHEDULES_LISTING = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/vesselSchedules/getCmaSchedules`;
export const UPDATE_QUERY_GET_RATES = `${API_END_POINT_LOCAL_FIN}api/auth/queries/updateQueryAndGetRates`;
export const UPDATE_QUERY_GET_RATES_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/updateQueryAndGetRates`;
export const UPDATE_QUERY_GET_RATES_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/updateQueryAndGetRates`;
export const UPDATE_QUERY_GET_RATES_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/updateQueryAndGetRates`;
export const UPDATE_QUERY_GET_RATES_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/updateQueryAndGetRates`;
export const SEARCH_DRIVER_DETAILS =  `${API_END_POINT_LOCAL_FIN}api/ulip/sarthi`;
export const SEARCH_VEHICLE_FASTAGE_DETAILS = `${API_END_POINT_LOCAL_FIN}api/auth/ulip/fastag`;
export const SEARCH_VEHICLE_INFO_VEHICLENUMBER = `${API_END_POINT_LOCAL_FIN}api/ulip/vahanVehicle`;
export const SEARCH_VEHICLE_INFO_CHESIS = `${API_END_POINT_LOCAL_FIN}api/ulip/vahanChasis`;
export const SEARCH_VEHICLE_INFO_ENGINE = `${API_END_POINT_LOCAL_FIN}api/auth/ulip/vahanEngine`;
export const SEARCH_DESIGN_BY_BL =`${API_END_POINT_LOCAL_FIN}api/auth/masters/containers/trackByBlDesign`;
export const TOP_TEN_ORIGIN_CUSTOMER =`${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/distinctTopTenOrigin`;
export const TOP_TEN_ORIGIN_CUSTOMER_AIR =`${API_END_POINT_LOCAL_AIR}api/shipment/dashboard/distinctTopTenOrigin`;
export const TOP_TEN_ORIGIN_CUSTOMER_OCEAN =`${API_END_POINT_LOCAL_OCEAN}api/shipment/dashboard/distinctTopTenOrigin`;
export const TOP_TEN_ORIGIN_CUSTOMER_ROAD =`${API_END_POINT_LOCAL_ROAD}api/shipment/dashboard/distinctTopTenOrigin`;
export const TOP_TEN_ORIGIN_CUSTOMER_COURIER =`${API_END_POINT_LOCAL_COURIER}api/shipment/dashboard/distinctTopTenOrigin`;
export const TOP_TEN_DESTINATION_CUSTOMER =`${API_END_POINT_LOCAL_FIN}api/shipment/dashboard/distinctTopTenDestination`;
export const TOP_TEN_DESTINATION_CUSTOMER_AIR =`${API_END_POINT_LOCAL_AIR}api/shipment/dashboard/distinctTopTenDestination`;
export const TOP_TEN_DESTINATION_CUSTOMER_OCEAN =`${API_END_POINT_LOCAL_OCEAN}api/shipment/dashboard/distinctTopTenDestination`;
export const TOP_TEN_DESTINATION_CUSTOMER_ROAD =`${API_END_POINT_LOCAL_ROAD}api/shipment/dashboard/distinctTopTenDestination`;
export const TOP_TEN_DESTINATION_CUSTOMER_COURIER =`${API_END_POINT_LOCAL_COURIER}api/shipment/dashboard/distinctTopTenDestination`;
export const PORTS_GET_API =`${API_END_POINT_LOCAL_OCEAN}api/auth/masters/ports/get`;
export const SEARCH_GST_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/ulip/gst`;
export const SEARCH_CIN_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/ulip/mcaCompanyData`;
export const SEARCH_DIN_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/ulip/mcaDirectorData`;
export const CREATE_SHIPPING_BILL_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/shippingBillMasters/create`;
export const DOWNLOAD_SHIPPING_BILL_API = `${API_END_POINT_LOCAL_FIN}api/masters/shippingBillMasters/downLoadPdf`;
export const GET_SHIPPING_BILL_API = `${API_END_POINT_LOCAL_FIN}api/auth/masters/shippingBillMasters/get`;
export const QUERY_DATA_GET_API = `${API_END_POINT_LOCAL_FIN}api/queries/rates/get`;
export const QUERY_DATA_GET_API_AIR = `${API_END_POINT_LOCAL_AIR}api/queries/rates/get`;
export const QUERY_DATA_GET_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/queries/rates/get`;
export const QUERY_DATA_GET_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/queries/rates/get`;
export const QUERY_DATA_GET_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/queries/rates/get`;
export const SEARCH_AADHAR_DATA = `${API_END_POINT_LOCAL_FIN}api/ulip/digilockerAadhar`;
export const GET_RATE_VIA_PDF_DATA = `${API_END_POINT_LOCAL_FIN}api/auth/queries/autoRates`;
export const DELETE_HBL_API = `${API_END_POINT_LOCAL_FIN}api/bookings/deleteHbl`;
export const DELETE_HBL_API_AIR = `${API_END_POINT_LOCAL_AIR}api/bookings/deleteHbl`;
export const DELETE_HBL_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/bookings/deleteHbl`;
export const WITHOUT_ADDITIONAL_RATES_UPDATE = `${API_END_POINT_LOCAL_FIN}api/auth/queries/rates/updateRatesWithoutAdditionalCharges`;
export const WITHOUT_ADDITIONAL_RATES_UPDATE_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/rates/updateRatesWithoutAdditionalCharges`;
export const WITHOUT_ADDITIONAL_RATES_UPDATE_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/rates/updateRatesWithoutAdditionalCharges`;
export const WITHOUT_ADDITIONAL_RATES_UPDATE_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/rates/updateRatesWithoutAdditionalCharges`;
export const WITHOUT_ADDITIONAL_RATES_UPDATE_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/rates/updateRatesWithoutAdditionalCharges`;
export const SHIPPER_DATA_GET = `${API_END_POINT_LOCAL_FIN}api/masters/shipper/get`;
export const CONSIGNEE_DATA_GET = `${API_END_POINT_LOCAL_FIN}api/masters/consignee/get`;
export const CHECK_SHIPPING_BILL = `${API_END_POINT_LOCAL_AIR}api/masters/shippingBillmasters/checkShippingBill`;
export const GET_SINGLE_AIRPORT = `${API_END_POINT_LOCAL_AIR}api/auth/masters/originDestination/get`;
export const GET_SINGLE_PORT = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/ports/get`;
export const GET_BLOG_MASTER_TITLE  = `${API_END_POINT_LOCAL_FIN}api/unAuthMasters/blogMasters/getBlogByTitle`;

export const SEARCH_GST_DATA_AUTH = `${API_END_POINT_LOCAL_FIN}api/auth/ulip/gst`;
export const ADDITIONAL_DESTINATION_CHARGES = `${API_END_POINT_LOCAL_FIN}api/auth/masters/oceanFreight/getQueryDestinationClearanceRates`;
export const ADDITIONAL_DESTINATION_CHARGES_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/oceanFreight/getQueryDestinationClearanceRates`;
export const ADDITIONAL_DESTINATION_CHARGES_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/oceanFreight/getQueryDestinationClearanceRates`;

// shipping Bill

export const CREATE_SHIPPING_BILL_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/shippingBillMasters/create`;
export const CREATE_SHIPPING_BILL_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/shippingBillMasters/create`;
export const CREATE_SHIPPING_BILL_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/masters/shippingBillMasters/create`;
export const CREATE_SHIPPING_BILL_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/masters/shippingBillMasters/create`;



//  auto Rates 
export const GET_RATE_VIA_PDF_DATA_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/queries/autoRates`;
export const GET_RATE_VIA_PDF_DATA_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/queries/autoRatesDummy`;
export const GET_RATE_VIA_PDF_DATA_COURIER = `${API_END_POINT_LOCAL_COURIER}api/auth/queries/autoRates`;
export const GET_RATE_VIA_PDF_DATA_ROAD = `${API_END_POINT_LOCAL_ROAD}api/auth/queries/autoRates`;

// download pdf 

export const DOWNLOAD_SHIPPING_BILL_API_AIR = `${API_END_POINT_LOCAL_AIR}api/masters/shippingBillMasters/downLoadPdf`;
export const DOWNLOAD_SHIPPING_BILL_API_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/masters/shippingBillMasters/downLoadPdf`;
export const DOWNLOAD_SHIPPING_BILL_API_COURIER = `${API_END_POINT_LOCAL_COURIER}api/masters/shippingBillMasters/downLoadPdf`;
export const DOWNLOAD_SHIPPING_BILL_API_ROAD = `${API_END_POINT_LOCAL_ROAD}api/masters/shippingBillMasters/downLoadPdf`;


// check shipping bill
export const CHECK_SHIPPING_BILL_AIR = `${API_END_POINT_LOCAL_AIR}api/masters/shippingBillmasters/checkShippingBill`;
export const CHECK_SHIPPING_BILL_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/masters/shippingBillmasters/checkShippingBill`;
export const CHECK_SHIPPING_BILL_COURIER = `${API_END_POINT_LOCAL_COURIER}api/masters/shippingBillmasters/checkShippingBill`;
export const CHECK_SHIPPING_BILL_ROAD = `${API_END_POINT_LOCAL_ROAD}api/masters/shippingBillmasters/checkShippingBill`;


// Zipa 

export const ZIPA_SUGGESTION_BUY_SELL_CREATE = `${API_END_POINT_LOCAL_SUGGESTION}api/invoice/buySale/create`;

export const ZIPA_SUGGESTION_BUY_SELL_UPDATE = `${API_END_POINT_LOCAL_SUGGESTION}api/unAuthMasters/oceanFreight/zipaRecommendedRatesUpdate`;
// insurance Api
export const GET_INSURANCE_API = `${API_END_POINT_LOCAL_FIN}api/insurance/premium`;

export const CUSTOMER_UPDATE = `${API_END_POINT_LOCAL_FIN}api/masters/customer/update`;



export const MANAGER_INCOTERMS_API_AUTH = `${API_END_POINT_LOCAL_FIN}api/auth/masters/incoTerms/manager`;

export const MEARSK_VESSEL_API =`${API_END_POINT_LOCAL_OCEAN}api/auth/masters/MAERSK/vesseslSchedules`
export const CITY_LIST_DATA_BASES_COUNTRY = `${API_END_POINT_LOCAL_FIN}api/auth/masters/city/managersByCountry`;
export const AIRPORT_LIST_BASES_COUNTRY_API = `${API_END_POINT_LOCAL_AIR}api/auth/masters/originDestination/managerByCountry`;
export const PORT_LIST_BASES_COUNTRY_API = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/ports/managerByCountry`;

// coupan Zipa
export const ZIPA_SUGGESTION_COUPAN = `${API_END_POINT_LOCAL_SUGGESTION}api/auth/masters/oceanFreight/zipaRecommendedRates/coupanApply`;
export const ZIPA_SUGGESTION_REMOVE = `${API_END_POINT_LOCAL_SUGGESTION}api/auth/masters/oceanFreight/zipaRecommendedRates/removeCoupan`;




export const COURIER_API_TRACKING = `${API_END_POINT_LOCAL_SUGGESTION}api/tracking/courier/track`;
export const ROAD_API_TRACKING = `${API_END_POINT_LOCAL_SUGGESTION}api/tracking/road/track`;
export const AIR_API_TRACKING = `${API_END_POINT_LOCAL_SUGGESTION}api/tracking/air/trackShipment`;
export const OCEAN_API_TRACKING = `${API_END_POINT_LOCAL_SUGGESTION}api/tracking/ocean/trackShipment`;

export const ADDITIONAL_PICKUP_CHARGES = `${API_END_POINT_LOCAL_FIN}api/auth/masters/oceanFreight/getQueryPickupRates`;
export const ADDITIONAL_PICKUP_CHARGES_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/oceanFreight/getQueryPickupRates`;
export const ADDITIONAL_PICKUP_CHARGES_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/oceanFreight/getQueryPickupRates`;

export const ADDITIONAL_DROPOFF_CHARGES = `${API_END_POINT_LOCAL_FIN}api/auth/masters/oceanFreight/getQueryDropRates`;
export const ADDITIONAL_DROPOFF_CHARGES_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/oceanFreight/getQueryDropRates`;
export const ADDITIONAL_DROPOFF_CHARGES_OCEAN = `${API_END_POINT_LOCAL_OCEAN}api/auth/masters/oceanFreight/getQueryDropRates`;

export const ACTIVITYBASES_INCOTERMS_CHECK = `${API_END_POINT_LOCAL_FIN}api/auth/masters/incoTerms/getByActivity`;
export const CREATE_BL_BILL_API_AIR = `${API_END_POINT_LOCAL_AIR}api/auth/masters/billOfLadingMasters/create`;
export const CHECK_BL_BILL_AIR = `${API_END_POINT_LOCAL_AIR}api/masters/billOfLadingMasters/checkShippingBill`;
export const DOWNLOAD_BL_BILL_API = `${API_END_POINT_LOCAL_AIR}api/masters/billOfLadingMasters/downLoadPdf`;