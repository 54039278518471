import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


class PackagingServices extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        return (
    <>
    <MetaTags>
        <title> Packaging and Relocations  | Zipaworld </title>
        <meta name="title" content="Moving Services, Relocation Company in India -Zipaworld"/>
        <meta name="description" content="Moving to India - We are trusted Relocation Company in India providing Packers and Movers services. Ship your baggage, container, pets, cargo to, from India."/>
        <meta name="keywords" content="relocation service in india, how to ship baggage by air, how to send pet by air, ship, best packer and mover services in india, best pet relocation services in india, send extra baggage by cargo in india"/>
    </MetaTags>
            <AppLayout>
                <div ref={this.testRef} className="services_my services_content_packaging">
                    {/*NE-1310*/}
                </div>
                <div className="services_content paraHyperlink">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Packaging <span>Services</span></h1>

                                <p>In addition, <Link to="/">Zipaworld</Link> also arranges packaging, marking, labelling, and logistics insurance services. Zipaworld has experienced and <Link to="/OurTeam">professional personnel</Link> and <Link to="/AwardsAndRecognition">licence holders</Link> for handling of special cargo like Dangerous Goods, Odd sized cargo, Valuables, perishables, critical pharmaceuticals and medicines, automobiles, etc.</p>
                                <br/>
                                <p>Zipaworld can also be approached for virtuous and principled logistics consultation services and logistics audit.</p>
                                <br/>
                                <p>Zipaworld in its online platform embodies anything and everything related to logistics and supply chain. Hence, we have worked to address all pain areas of the valuable customers and clients. While the major aspects of supply chain like <Link to="/AirFreight">air freight</Link>, <Link to="/OceanFreight">ocean freight</Link>, <Link to="/RoadFreight">road and rail freight</Link>, first and last mile connectivity, <Link to="/WarehousingSolutions">warehousing</Link>, <Link to="/CustomsBrokerage">customs clearance</Link>, and so on are seamlessly featured in the Zipaworld portal, we also provide packaging services, marking, labelling, logistics insurance and relocation services which also from an integral part of logistics.</p>
                                <br/>


                                <p>We understand the care required for your <Link to="/AirFreight">goods and cargo</Link> at the various levels of transportation. As we understand the ground realities of transportation, we understand the packaging requirements for various commodities and items be it garments, leather goods, textiles, automobile spare parts, carpets, pharmaceuticals and medicines, chemicals, dangerous goods, and so on.</p>

                                <br/>

                                <p>Our operations and cargo handling experts have the proficiency of not only packaging and handling the general goods but also special cargo like perishables, goods that require temperature control like medicines and vaccines, hazardous substances, live animals and pets, odd sized and overhang goods, valuables, and so on.</p>
                                <br/>


                                <p>Zipaworld in no time has grown organically into the various parts of the world, with partners and associates spread across India and around the globe. With the vast network of transportation and distribution services, Zipaworld helps you also in your relocation requirements with proper documentation, technical and operational assistance. We can assist you with your relocations and movements within India as well as anywhere globally.</p>
                                <br/>


                                <p>Zipaworld as the name suggests, <Link to="/">we connect the zip codes of the world</Link> and take care of all logistics involved in-between. Entrust your packaging and relocations requisites to Zipaworld!</p>

                            </div>
                        </div>

                    </div>
                </div>
                <FooterHome/>
            </AppLayout>
            </>

        );
    }


}



export default PackagingServices;
