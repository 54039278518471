import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import Slider from "react-slick";
import RestClient from "../../../util/RestClient";
import {
    CAREER_MASTER_MANAGER,
    IMAGE_CATEGORY_ALL_IMAGES,
    IMAGE_CATEGORY_BRANCH_MANAGER,
    IMAGE_CATEGORY_MANAGER
} from "../../../constants";
import {toast} from "react-toastify";
import {SRLWrapper} from "simple-react-lightbox";

class LifeAtZipaworld extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData : [],
            categoryImageData : [],
            imageAllStatus : false,
            categoryNameStatus:false,
        }

    }
    componentDidMount() {
        this.categoryData().then(responce=>{
            if(responce.success){
                let categoryData = responce && responce.result && responce.result.data ? responce.result.data : [];
                this.setState({
                    categoryData:categoryData,
                },()=>{
                   this.allImages()
                })
            }
        })
    }

    categoryData = async (dataForServer) => {
        return await RestClient({
            url: IMAGE_CATEGORY_MANAGER,
            method: "POST",
            data: dataForServer
        })
    }
    categoryDataImage = async (dataForServer) => {
        return await RestClient({
            url: IMAGE_CATEGORY_BRANCH_MANAGER,
            method: "POST",
            data: dataForServer
        })
    }
    filterImages = (cateId) =>{
        const body = {
            categoryId: cateId,
        };
        this.categoryDataImage(body).then(responce=>{
            if(responce.success){
                let categoryImageData = responce && responce.result && responce.result.data ? responce.result.data : [];
                this.setState({
                    categoryImageData:categoryImageData,
                    categoryNameStatus:true,
                    cateId:cateId,
                    imageAllStatus:false,
                },()=>{
                    // console.log("category data",this.state.categoryImageData)
                })
            }else{
                toast.success("category id not valid", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    allImages = () =>{
        this.categoryDataAllImages().then(responce=>{
            if(responce.success){
                let categoryImageData = responce && responce.result && responce.result.data ? responce.result.data : [];
                this.setState({
                    categoryImageData:categoryImageData,
                    imageAllStatus : true,
                    categoryNameStatus:false,
                },()=>{
                    // console.log("category data",this.state.categoryImageData)
                })
            }
        })
    }
    categoryDataAllImages = async (dataForServer) => {
        return await RestClient({
            url: IMAGE_CATEGORY_ALL_IMAGES,
            method: "POST",
            data: dataForServer
        })
    }
    render() {
        const settings = {
            dots: true,
            autoplay: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        centerMode: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        centerMode: false,
                        initialSlide: 2,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        };
        return (
    <>

                <AppLayout>

                    {/*<div className="life_zipaworld">*/}
                    {/*    <div className="container-fluid">*/}
                    {/*        <div className="mt-4">*/}
                    {/*            <div className="our_team">*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-lg-12">*/}
                    {/*                        <h4>Our Office</h4>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <Slider {...settings}>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6977702911954713-1634984322864.JPG"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.2274058233390146-1634984341944.JPG"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9176867413363043-1634984390750.JPG"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.32155745675267444-1634984406648.jpg"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9300569998882926-1634984424429.JPG"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6479079842478364-1634984441701.JPG"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6195149661492305-1634984457970.JPG"/>*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.943805569337451-1634984475150.JPG"/>*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.3621534018657424-1634984493003.JPG"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="team_img">*/}
                    {/*                            <div className="team_section_img">*/}
                    {/*                                <img src = "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.7013438882378511-1634984509655.jpg"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </Slider>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                        <div className="invoiceDetail">
                            <SRLWrapper>
                                <div className="media_img">
                        <div className="container-fluid">
                            <div className="category_btns">
                                <div className="d-flex flex-wrap justify-content-center align-items-center">
                                    <div className={this.state.imageAllStatus === true ? "btn_image_category active":"btn_image_category"} onClick={e => {
                                        e.preventDefault()
                                        this.allImages()
                                    }} >All</div>
                                    {Array.isArray(this.state.categoryData) && this.state.categoryData.length >= 1
                                        ? this.state.categoryData.map((obj, i) => {
                                            return (
                                                <>

                                                    <div className={this.state.categoryNameStatus === true && this.state.cateId === obj._id ? "btn_image_category active":"btn_image_category"} onClick={e => {
                                                        e.preventDefault()
                                                        this.filterImages(obj._id)
                                                    }} >{obj.categoryName}</div>

                                                </>
                                            )
                                        }):""}
                                </div>
                            </div>
                            {/*<div className="row">*/}
                            {/*    <div className="col-lg-12">*/}
                            {/*        /!*<h4>FUN AT WORK</h4>*!/*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="media_my">
                                {Array.isArray(this.state.categoryImageData) && this.state.categoryImageData.length >= 1
                                    ? this.state.categoryImageData.map((item, i) => {
                                        return (
                                            <>
                                            <a className="team_img" href={item.fileLink}>
                                                <div >
                                                    <div className="team_section_img">
                                                        <img src = {item.fileLink} srl_gallery_image="true" alt={item.fileLink}/>
                                                    </div>
                                                </div>
                                            </a>
                                            </>
                                            )}):""}
                            </div>
                        </div>
                    </div>
                            </SRLWrapper>
                        </div>
                    <FooterHome/>
                </AppLayout>

            </>

        )}


};



export default LifeAtZipaworld;
