import {

    QUERY_PREVIEW_DATA,
    QUERY_PREVIEW_DATA_FAILURE,
    QUERY_PREVIEW_DATA_SUCCESS,


} from "../actions";

const initialState = {
    name:"",
    stateCode:"",
    abbr:"",

};

const QueryReducer = (state = initialState, {type, payload}) => {
  switch (type) {


      case QUERY_PREVIEW_DATA:
          return {...state};

      case QUERY_PREVIEW_DATA_SUCCESS:

          return {...state, QueryData: payload};

      case QUERY_PREVIEW_DATA_FAILURE:
          return {...state, message: payload.message};


    default:
        return initialState
  }
};
export default QueryReducer;