import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import {NavLink} from "react-router-dom";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AwaitedRateNotPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalLarges: false,

        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {

        if (this.props.modalLarges) {
            this.setState({
                modalLarges: this.props.modalLarges
            })
        }


    }

    toggleLarge = (e) => {
        e.preventDefault()
        history.push(PAGE_PATHS.MAIN_PAGE)
        window.location.reload("false")
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarges && this.props.modalLarges !== prevProps.modalLarges) {
            this.setState({
                modalLarges: this.props.modalLarges
            })
        }
    }



    render() {
        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <Modal className="model_contact"
                       isOpen={this.state.modalLarges}
                       size="md"
                       toggle={this.toggleLarge}
                >

                    <ModalHeader className="model_head" toggle={this.toggleLarge} style={{padding: "0"}}>
                        <NavLink to={`/`} className="white">
                        </NavLink>
                    </ModalHeader>
                    <ModalBody className="contact-later1">
                        <div className="contect-soon">
                            <div className="soon_text">
                                <div className="book-imgs">
                                    <img src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/mobile.png" alt="call icon"/>
                                </div>
                                <div className="book-text">
                                    <h5><FontAwesomeIcon icon={faCircle} style={{color:"green"}}/> Available Now</h5>
                                    <h2> Rates Not confirmed</h2>
                                    <h6>Awaiting For All Rates.</h6>
                                    <h6>For any problem Please call on <span style={{display:'block'}}> +91 120 691 6910.</span> </h6>
                                </div>
                            </div>
                            <div className="bottom_btn">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="request_btn" id="subscribeBtn" onClick={e=>{
                                            e.preventDefault()
                                            history.push(PAGE_PATHS.MAIN_PAGE)
                                            window.location.reload("false")
                                        }}>Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                </Modal>
            </>
        );
    }
}


export default AwaitedRateNotPopup;
