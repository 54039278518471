import {all, call, fork, put} from 'redux-saga/effects';

import RestClient from "../../util/RestClient";
import {
    DELETE_INVOICE,
    INVOICE_MANAGER,
    SAVE_INVOICE,
    UPDATE_INVOICE,
    INVOICE_PRINT_DATA,
    INVOICE_VIEW_DATA,
    INVOICE_CHEKED_DATA_API
} from "../../constants";
import {
    INVOICE_FETCH_MASTER,
    INVOICE_CREATE,
    INVOICE_UPDATE,
    INVOICE_DELETE,
    INVOICE_PRINT_REQUEST, INVOICE_GETDATA_REQUEST, INVOICE_CHECKED_REQUEST
} from "../actions";
import routeRedirectTo from "../../util/routeWrapper";
import {takeLatest} from "@redux-saga/core/effects";
import {PAGE_PATHS as PageRoute} from "../../util/PageRoute";
import {saveInvoiceResponse, onInvoiceMasterFetched, onInvoiceMasterFetchedFailed, updateInvoiceResponse,deleteInvoiceResponse,onInvoicePrintFetched,onInvoicePrintFetchedFailed,
    getInvoiceDataFetched,getInvoiceDataFailed,sendtochekedFetched,sendtochekedFailed} from "./invoiceAction";

export function* watchairfreight() {
    yield takeLatest(INVOICE_CREATE, storeInvoice);
    yield takeLatest(INVOICE_UPDATE, updateInvoice);
    yield takeLatest(INVOICE_DELETE, deleteInvoice);
    yield takeLatest(INVOICE_FETCH_MASTER, fetchInvoice);
    yield takeLatest(INVOICE_PRINT_REQUEST, fetchPrint);
    yield takeLatest(INVOICE_GETDATA_REQUEST, fetchInvoicedataForView);
    yield takeLatest(INVOICE_CHECKED_REQUEST, checkedFunction);

}



function* storeInvoice({payload}) {

// const {companyCode, email, password, source} = payload;


    try {
        const storeInvoiceResponse = yield call(storeInvoiceAsync, payload);

        if (storeInvoiceResponse.success) {

            yield put(saveInvoiceResponse(storeInvoiceResponse.message));

            yield call(forwardTo, PageRoute.INVOICE_MASTER);

        } else {
            // console.log("Not Success" + storeInvoiceResponse)
            yield put(saveInvoiceResponse(storeInvoiceResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(saveInvoiceResponse(error.response.data.message));
        }

    }
}

const storeInvoiceAsync = async (StoreInvoiceData) => {
    {
        // console.log(JSON.stringify("In airfreight Save API" + JSON.stringify(StoreInvoiceData)))

        return await RestClient({
            url: SAVE_INVOICE,
            method: "POST",
            data: StoreInvoiceData
        })

    }
}

function* updateInvoice({payload}) {
    try {
        const updateInvoiceData = yield call(updateInvoiceAsync, payload);
        // console.log("updateInvoiceResponse  response" + JSON.stringify(updateInvoiceData))
        if (updateInvoiceData.success) {
            // console.log("updateInvoiceResponse IN Success" + JSON.stringify(updateInvoiceData))
            yield put(updateInvoiceResponse(updateInvoiceData.message));
            yield call(forwardTo, PageRoute.INVOICE_MASTER);

        } else {
            // console.log("updateInvoiceResponse Not Success" + JSON.stringify(updateInvoiceData))
            yield put(updateInvoiceResponse(updateInvoiceData.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(updateInvoiceResponse(error.response.data.message));
        }

    }
}

const updateInvoiceAsync = async (StoreInvoiceData) => {
    {
        // console.log(JSON.stringify("In Origin Update API" + JSON.stringify(StoreInvoiceData)))

        return await RestClient({
            url: UPDATE_INVOICE,
            method: "POST",
            data: StoreInvoiceData
        })

    }
}

function* deleteInvoice({payload}) {
    try {
        const deleteInvoiceData = yield call(deleteInvoiceAsync, payload);
        // console.log("delete InvoiceResponse  response" + JSON.stringify(deleteInvoiceData))
        if (deleteInvoiceData.success) {
            // console.log("delete Invoice IN Success" + JSON.stringify(deleteInvoiceData))
            yield put(deleteInvoiceResponse(deleteInvoiceData.message));

        } else {
            // console.log("deleteInvoiceResponse Not Success" + JSON.stringify(deleteInvoiceData))
            yield put(deleteInvoiceResponse(deleteInvoiceData.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(deleteInvoiceResponse(error.response.data.message));
        }

    }
}

const deleteInvoiceAsync = async (idToDelete) => {
    {
        // console.log(JSON.stringify("In Origin delete API" + JSON.stringify(idToDelete)))
        return await RestClient({
            url: DELETE_INVOICE,
            method: "POST",
            data: idToDelete
        })

    }
}


function* fetchInvoice({payload}) {

    // console.log("In fetch Invoice Attempt" + JSON.stringify(payload))

    try {
        const fetchInvoiceResponse = yield call(fetchInvoiceAsync, payload);
        // console.log("fetch Invoice Master" + fetchInvoiceResponse);
        if (fetchInvoiceResponse.success) {
            yield put(onInvoiceMasterFetched(fetchInvoiceResponse.result));
        } else {
            // console.log(" fetch Invoice Master Not Success" + fetchInvoiceResponse)
            yield put(onInvoiceMasterFetchedFailed(fetchInvoiceResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch Origin Master" + JSON.stringify(error))
            yield put(onInvoiceMasterFetchedFailed(error.response.data.message));
        }

    }
}

const fetchInvoiceAsync = async (fetchInvoiceData) => {
    {
        // console.log(JSON.stringify("In fetch  Invoice  API" + JSON.stringify(fetchInvoiceData)))

        return await RestClient({
            url: INVOICE_MANAGER,
            method: "POST",
            data: fetchInvoiceData
        })

    }
}




function* fetchPrint({payload}) {
    // console.log("In fetch Invoice Attempt" + JSON.stringify(payload))

    try {
        const fetchInvoicePrintResponse = yield call(fetchInvoiceprintAsync, payload);
        // console.log("fetch Invoice print" + fetchInvoicePrintResponse);
        if (fetchInvoicePrintResponse.success) {
            yield put(onInvoicePrintFetched(fetchInvoicePrintResponse.result));
        } else {
            // console.log(" fetch Invoice print Not Success" + fetchInvoicePrintResponse)
            yield put(onInvoicePrintFetchedFailed(fetchInvoicePrintResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error Invoice print" + JSON.stringify(error))
            yield put(onInvoicePrintFetchedFailed(error.response.data.message));
        }

    }
}

const fetchInvoiceprintAsync = async (fetchInvoiceData) => {
    {
        // console.log(JSON.stringify("In fetch  Invoice  API" + JSON.stringify(fetchInvoiceData)))

        return await RestClient({
            url: INVOICE_PRINT_DATA,
            method: "POST",
            data: fetchInvoiceData
        })

    }
}
function* fetchInvoicedataForView({payload}) {
    // console.log("In fetch Invoice Attempt" + JSON.stringify(payload))

    try {
        const fetchInvoicePrintResponse = yield call(fetchInvoicedataForViewApi, payload);
        // console.log("fetch Invoice print" + fetchInvoicePrintResponse);
        if (fetchInvoicePrintResponse.success) {
            yield put(getInvoiceDataFetched(fetchInvoicePrintResponse.result));
        } else {
            // console.log(" fetch Invoice print Not Success" + fetchInvoicePrintResponse)
            yield put(getInvoiceDataFailed(fetchInvoicePrintResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error Invoice print" + JSON.stringify(error))
            yield put(getInvoiceDataFailed(error.response.data.message));
        }

    }
}

const fetchInvoicedataForViewApi = async (fetchInvoiceData) => {
    {
        // console.log(JSON.stringify("In fetch  Invoice  API" + JSON.stringify(fetchInvoiceData)))

        return await RestClient({
            url: INVOICE_VIEW_DATA,
            method: "POST",
            data: fetchInvoiceData
        })

    }
}

function* checkedFunction({payload}) {
    // console.log("In fetch Invoice Attempt" + JSON.stringify(payload))

    try {
        const fetchInvoicePrintResponse = yield call(checkedFunctionApi, payload);
        // console.log("fetch Invoice print" + fetchInvoicePrintResponse);
        if (fetchInvoicePrintResponse.success) {
            yield put(sendtochekedFetched(fetchInvoicePrintResponse.result));
        } else {
            // console.log(" fetch Invoice print Not Success" + fetchInvoicePrintResponse)
            yield put(sendtochekedFailed(fetchInvoicePrintResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error Invoice print" + JSON.stringify(error))
            yield put(sendtochekedFailed(error.response.data.message));
        }

    }
}

const checkedFunctionApi = async (fetchInvoiceData) => {
    {
        // console.log(JSON.stringify("In fetch  Invoice  API" + JSON.stringify(fetchInvoiceData)))

        return await RestClient({
            url: INVOICE_CHEKED_DATA_API,
            method: "POST",
            data: fetchInvoiceData
        })

    }
}

function forwardTo(location) {
    routeRedirectTo.push(location);
}

export default function* Saga() {
    yield all([
        fork(watchairfreight)
    ]);
}
