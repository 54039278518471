import React, {Component, Fragment} from "react";
import {Button} from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
import {connect} from "react-redux";
import '../../../assets/css/sass/customeCss.css';
import '../../../assets/css/invoice.css';
import {GstDataAction} from "../../../redux/mawbRedux/mawbAction";
import { loadStripe } from "@stripe/stripe-js";

import RestClient from "../../../util/RestClient";
import {
    BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
    BOOKING_MANAGER_MASTER_GET_DATA_COURIER,
    GET_PROFORMA_DATA_ROAD,
    GET_PROFORMA_DATA_COURIER,
    GET_PROFORMA_ID_BY_BOOKINGID_ROAD,
    GET_PROFORMA_ID_BY_BOOKINGID_COURIER,
    PAYMENT_GATEWAY_COURIER,
    PAYMENT_GATEWAY_ROAD,
    RETURN_TO_CONFIREM_COURIER,
    RETURN_TO_CONFIREM_ROAD
} from "../../../constants";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {
    convertDateISO, convertDateISOToCustomiseFormat,
    getCustomerName,
} from "../../../util/CommonUtility";
import {
    previewInvoiceDetail, getInvoiceData, sendtocheked
} from "../../../redux/invoiceRedux/invoiceAction";
import {withRouter} from "react-router";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../assets/css/shiphistory.css";
import "../../../assets/css/invoice.css";
import PaymentStatusCard from "../dashboards/PaymentStatusCard";
import NotPaidStatusCard from "./NotPaidStatusCard";
import PaymentPopUp from "./PaymentPopUp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

class PayNowForIndividual extends Component {
    totalUsedPiecesByHouses = []
    houseData = [];
    myDivCharges = null;

    constructor(props) {
        super(props);
        this.myDivCharges = React.createRef()
        this.state = {
            paymentPop:false,
            successPop:false,
            failedPop:true,
            showLoader:true,
            awbId: "",
            modal: false,
            NotPaidStatusShow: false,
            editAwbData: {},
            awbPdfUrl: undefined,
            bookingId: "",
            shippingLineName: "",
            DestinationName: "",
            bookingDate: "",
            blNo: "",
            quoteId: "",
            appliedOncharge: [],
            awbStatus: "",
            BlStatus: "",
            discount: "",
            creditLimit: 0,
            CustomerStreetaddress: "",
            performaStatus: "",
            finalInvoiceCreated:"",
            finalInvoice:"",
            finalInvoiceId:"",
            finalInvoiceApprovalStatus:"",
            offerModal: false,
            ShowDiscoutColumn: false,
            chargesListingData: [],
            sendData: {},
            customerId: "",
            skipStatus: "",
            customer: "",
            performaInvoiceNumber: "",
            mawbData: {},
            quoteData: {},
            otherChargeDueAgent: "",
            otherChargeDueCarrs: "",
            reciptNumber: "",
            invoiceType:"",
            dimensionCode: "",
            updatedPrice: "",
            PerformaId: "",
            CustomerCIty: "",
            bookingNo: "",
            to1: "",
            by1: "",
            CustomerName: "",
            flightCarr1: "",
            AirlineCode: "",
            CustomerPin: "",
            flightNum1: "",
            flightdate1: "",
            PdfUrl: "",
            to2: "",
            by2: "",
            flightCarr2: "",
            flightNum2: "",
            flightdate2: "",
            houses: "",
            awbNo: "",
            emailPopUp: false,
            KYCStatus: false,
            invoiceId: "",
            items: {},
            comapny: {},
            invoiceNo: "",
            taxvalue: "",
            paidStatus: "",
            convert: "",
            previewData: {},
            ResponcePayment: {},
            customerAddress: "",
            gstNumber: "",
            AirlineName: "",
            AirlineId: "",
            performaObjectID: "",
            chargesArray: [],
            posData: "",
            chargeAmountAfterDiscount: "",
            otherChargesClearnce: [],
            calculateTaxble: "",
            calculateNetAmount: "",
            calculateNetAmountB: "",
            chargeAmount: "",
            customerCode: "",
            chargeCgstAmount: "",
            chargeSgstAmount: "",
            chargeIgstAmount: "",
            originName: "",
            noOfContainers:"",
            gstLarge: false,
            offerCode: "",
            roundOff: "",
            csBuddyBranch: "",
            paymentStatusShow:false,
            payLarge:false,
            companyBranchData:"",
            switchCurrency:false,
            opacityChange : true

        };
    }


    getbookingData = async (dataForServer) => {
        return await RestClient({
            url: dataForServer && dataForServer.quoteFor === "Road" ? BOOKING_MANAGER_MASTER_GET_DATA_ROAD : BOOKING_MANAGER_MASTER_GET_DATA_COURIER,
            method: "POST",
            data: dataForServer
        })
    };




    componentDidMount() {
        let bookingId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let urlSecondValue = window.location.href.split("/")[window.location.href.split("/").length - 2];
        this.setState({
            quoteFor : urlSecondValue,bookingId:bookingId,
        },()=>{
            this.initialHit()
        })
    }


    initialHit =()=> {
        let data = {}
        if(!localStorage.getItem("customer") === false){
            data = JSON.parse(localStorage.getItem("customer")).result
            let tanNo = ""
            let gstNo = ""
            gstNo = data && data.customerBranchData && data.customerBranchData.gstNo ? data.customerBranchData.gstNo : ""
            let customerCode=data && data.customerData ? data.customerData.customerCode : ""
            let typeOfBussiness = data && data.customerData && data.customerData.typeOfBussiness ? data.customerData.typeOfBussiness : ""
            this.setState({tanNo: tanNo, gstNo: gstNo,customerCode: customerCode,typeOfBussiness:typeOfBussiness})
        }
        try {
            let bookingId = window.location.href.split("/")[window.location.href.split("/").length - 1];
            this.setState({bookingId: bookingId},()=>{
                this.GetProformaId({id:bookingId}).then(idResponce=>{
                    if(idResponce && idResponce.success){
                        let PerformaID = idResponce && idResponce.result && idResponce.result._id ? idResponce.result._id : ""
                        let quotationId = idResponce.result.quoteId
                        this.setState({PerformaId : PerformaID,quotationId},()=>{
                            this.GetProformaData({id:PerformaID}).then(responce=>{
                                if(responce.success && responce.result){
                                    let resultProformaData = responce.result;
                                    let proformaObj = {}

                                    proformaObj.consigneeName = resultProformaData.consigneeId && resultProformaData.consigneeId.consigneeName ? resultProformaData.consigneeId.consigneeName : "";
                                    proformaObj.shipperName = resultProformaData.shipperId && resultProformaData.shipperId.shipperName ? resultProformaData.shipperId.shipperName : "";
                                    proformaObj.shipperDetail = resultProformaData.shipperDetail  ? resultProformaData.shipperDetail : "";
                                    proformaObj.consigneeDetail = resultProformaData.consigneeDetail  ? resultProformaData.consigneeDetail : "";
                                    proformaObj.invoiceDate = resultProformaData.invoiceDate  ? convertDateISO(resultProformaData.invoiceDate)  : "";
                                    proformaObj.invoiceDueDate = resultProformaData.invoiceDueDate  ? convertDateISO(resultProformaData.invoiceDueDate)  : "";
                                    proformaObj.compleateInvoiceNo = resultProformaData.invoiceNo;
                                    proformaObj.invoiceNo = resultProformaData.invoiceNo  ?  resultProformaData.invoiceNo.split("_")[resultProformaData.invoiceNo.split("_").length - 1 ]  : "";
                                    proformaObj.invoiceType = resultProformaData.invoiceType  ? resultProformaData.invoiceType : "";
                                    proformaObj.destination = "";
                                    proformaObj.origin = "";
                                    proformaObj.xRate = resultProformaData.xRate  ? resultProformaData.xRate : "";
                                    proformaObj.totalPieces = resultProformaData.totalPieces  ? resultProformaData.totalPieces : "";
                                    proformaObj.awbNo = resultProformaData.awbNo  ? resultProformaData.awbNo : "";
                                    proformaObj.grossWeight = resultProformaData.grossWeight  ? resultProformaData.grossWeight : "";
                                    proformaObj.volumeWeight = resultProformaData.volumeWeight  ? resultProformaData.volumeWeight : "";
                                    proformaObj.chargeableWeight = resultProformaData.chargeableWeight  ? resultProformaData.chargeableWeight : "";
                                    proformaObj.CustomerGstType = resultProformaData.customer && resultProformaData.customer.gstType  ? resultProformaData.customer.gstType : "";
                                    proformaObj.customerId = resultProformaData.customer && resultProformaData.customer.customerId  ? resultProformaData.customer.customerId : "";
                                    proformaObj.customerBranchId = resultProformaData.customer && resultProformaData.customer.customerBranchId  ? resultProformaData.customer.customerBranchId : "";
                                    proformaObj.customer = resultProformaData.customer  ? resultProformaData.customer : "";
                                    proformaObj.customerCurrency = resultProformaData.customerCurrency  ? resultProformaData.customerCurrency : "";
                                    proformaObj.baseCurrency = resultProformaData.baseCurrency  ? resultProformaData.baseCurrency : "";
                                    proformaObj.shippingLine = "";
                                    proformaObj.bookingDate = "";
                                    proformaObj.jobNo = resultProformaData.jobNo  ? resultProformaData.jobNo : "";
                                    proformaObj.otherChargesClearnce = resultProformaData.otherCharges  ? resultProformaData.otherCharges : "";
                                    proformaObj.chargeAmount = resultProformaData.totalAmountC  ? resultProformaData.totalAmountC : "";
                                    proformaObj.totalAmountB = resultProformaData.totalAmountB  ? resultProformaData.totalAmountB : "";

                                    proformaObj.chargeIgstAmount = resultProformaData.igstTotalAmountC  ? resultProformaData.igstTotalAmountC : "";
                                    proformaObj.chargeSgstAmount = resultProformaData.sgstTotalAmountC  ? resultProformaData.sgstTotalAmountC : "";
                                    proformaObj.chargeCgstAmount = resultProformaData.cgstTotalAmountC  ? resultProformaData.cgstTotalAmountC : "";
                                    proformaObj.calculateNetAmount = resultProformaData.taxableTotalAmountC  ? resultProformaData.taxableTotalAmountC : "";
                                    proformaObj.calculateNetAmountB = resultProformaData.taxableTotalAmountB  ? resultProformaData.taxableTotalAmountB : "";
                                    proformaObj.taxableTotalAmountB = resultProformaData.taxableTotalAmountB  ? resultProformaData.taxableTotalAmountB : "";
                                    proformaObj.igstTotalAmountB = resultProformaData.igstTotalAmountB  ? resultProformaData.igstTotalAmountB : "";
                                    proformaObj.igstTotalAmountC = resultProformaData.igstTotalAmountC  ? resultProformaData.igstTotalAmountC : "";
                                    proformaObj.totalAmountB = resultProformaData.totalAmountB  ? resultProformaData.totalAmountB : "";
                                    proformaObj.calculateTaxble = resultProformaData.taxableTotalAmountC  ? resultProformaData.taxableTotalAmountC : "";
                                    proformaObj.baseCurrency = resultProformaData.baseCurrency  ? resultProformaData.baseCurrency : "";
                                    proformaObj.paidStatus = resultProformaData.paidStatus  ? resultProformaData.paidStatus : "";
                                    proformaObj.skipStatus = resultProformaData.skipStatus  ? resultProformaData.skipStatus : "";
                                    proformaObj.invoiceUrl = resultProformaData.invoiceUrl  ? resultProformaData.invoiceUrl : "";
                                    proformaObj.orderId = resultProformaData.orderId ? resultProformaData.orderId:"";
                                    proformaObj.customerCurrency = resultProformaData.customerCurrency
                                        ? resultProformaData.customerCurrency
                                        : "";
                                    proformaObj.receipt = resultProformaData.receipt ? resultProformaData.receipt:"";
                                    this.setState({payNowCustomerDetails: resultProformaData.customer,previewData:proformaObj,pData:resultProformaData ,customer:proformaObj.customer,paidStatus:proformaObj.paidStatus,
                                        skipStatus:proformaObj.skipStatus ,CustomerName :getCustomerName(),PdfUrl: proformaObj.invoiceUrl,orderIdOfPayment : proformaObj.orderId,
                                        customerCurrencyFromProforma : proformaObj.customerCurrency,
                                        receiptIdOfPayment : proformaObj.receipt,
                                    },()=>{
                                        if(this.state.skipStatus === "Paid"){
                                            this.props.history.push(PAGE_PATHS.PROFORMA_COURIER_VIEW +"/"+ this.state.quoteFor + "/" +this.state.bookingId)
                                            window.location.reload("false")
                                        }
                                        this.getbookingData({id: bookingId , quoteFor:this.state.quoteFor}).then(bookingData => {
                                            if (bookingData && bookingData.success) {
                                                let BookingResult = bookingData && bookingData.result ? bookingData.result : "";
                                                let quoteData = BookingResult && BookingResult.quoteId ? BookingResult.quoteId : undefined;
                                                let commodity = quoteData &&  quoteData.commodity  ? quoteData.commodity : "";
                                                let queryFor =  quoteData &&  quoteData.queryFor && quoteData.queryFor ? quoteData.queryFor : "";
                                                let shipmentMode = quoteData &&  quoteData.shipmentMode  ? quoteData.shipmentMode : "";
                                                let PickUpZipcode = quoteData &&  quoteData.Shipper && quoteData.Shipper.ZipCode ? quoteData.Shipper.ZipCode :  BookingResult &&  BookingResult.originDoor && BookingResult.originDoor.pincode ? BookingResult.originDoor.pincode:"" ;
                                                let PickUpCountry = quoteData &&  quoteData.Shipper && quoteData.Shipper.Country ? quoteData.Shipper.Country : "India";
                                                let DropToZipcode = quoteData &&  quoteData.Consignee && quoteData.Consignee.ZipCode ? quoteData.Consignee.ZipCode : BookingResult &&  BookingResult.destinationDoor && BookingResult.destinationDoor.pincode ? BookingResult.destinationDoor.pincode:"";
                                                let DropToCountry = quoteData &&  quoteData.Consignee && quoteData.Consignee.Country ? quoteData.Consignee.Country : "India";
                                                let jobNo = quoteData &&  quoteData.jobNo  ? quoteData.jobNo : "";
                                                let noOfPieces = BookingResult && BookingResult.noOfPieces ? BookingResult.noOfPieces :"";
                                                let grossWeight = BookingResult && BookingResult.grossWeight ? BookingResult.grossWeight :"";
                                                let chargeableWeight = BookingResult && BookingResult.chargeableWeight ? BookingResult.chargeableWeight :"";
                                                let volumeWeight = BookingResult && BookingResult.volumeWeight ? BookingResult.volumeWeight :"";
                                                let OriginName =  quoteData &&  quoteData.originDoor && quoteData.originDoor.pincode ? quoteData.originDoor.pincode : "";
                                                let DestinationName = quoteData &&  quoteData.destinationDoor && quoteData.destinationDoor.pincode ? quoteData.destinationDoor.pincode : "";
                                                let courierMode = quoteData &&  quoteData.courierMode  ? quoteData.courierMode : "";
                                                let clearenceDate = quoteData &&  quoteData.clearenceDate  ? quoteData.clearenceDate : "";

                                                this.setState({
                                                    originName: OriginName,
                                                    courierMode: courierMode,

                                                    volumeWeight:volumeWeight,
                                                    noOfPieces:noOfPieces,
                                                    chargeableWeight:chargeableWeight,
                                                    grossWeight:grossWeight,
                                                    clearenceDate:clearenceDate,
                                                    queryFor:queryFor,
                                                    shipmentMode:shipmentMode,
                                                    DestinationName : DestinationName,
                                                    DropToZipcode : DropToZipcode,
                                                    PickUpZipcode : PickUpZipcode,
                                                    DropToCountry : DropToCountry,
                                                    PickUpCountry : PickUpCountry,
                                                    commodity:commodity,
                                                    jobNo:jobNo,
                                                    customerMails:quoteData?.customerMails,


                                                })

                                            }
                                        })
                                    })
                                }else{
                                    this.setState({showLoader:false})
                                }
                            })

                        })

                    }
                })
            })
        } catch (e) {
        }
    }

    GetProformaId = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Courier"?GET_PROFORMA_ID_BY_BOOKINGID_COURIER :GET_PROFORMA_ID_BY_BOOKINGID_ROAD,
            method: "POST",
            data: dataForServer
        });
    };
    GetProformaData = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Courier"? GET_PROFORMA_DATA_COURIER : GET_PROFORMA_DATA_ROAD,
            method: "POST",
            data: dataForServer
        });
    };


    paymentHandler = async (invoiceId) => {
        const {chargeAmount,customerCurrency
        } = this.state.previewData;

        let obj ={
            customer:this.state.payNowCustomerDetails,
            performaInvoiceId:invoiceId,
            currency : customerCurrency ? customerCurrency : "",
            amount : Math.round(chargeAmount),
            receipt : this.state.customerCode+"_"+Date.now(),
            type:"PROFORMA INVOICE"
        }
        await RestClient({
            url: this.state.quoteFor === "Courier" ? PAYMENT_GATEWAY_COURIER : PAYMENT_GATEWAY_ROAD,
            method: "POST",
            data: obj
        }).then(response =>{

            if (response.success){
                this.setState({paymentKey:response.result.key,tokenKey:response.result.orderId,tokenData:response.result})
                let options = {
                    key: response.result.key,
                    name: "Zipaworld Innovation Pvt. Ltd.",
                    description: "Please Make your Payment",
                    order_id: response.result.orderId,
                    handler: async (response2) => {
                        try {


                            let sendData = {
                                coupanCode: this.state.offerCode !== "" && this.state.offerCode !== undefined ? this.state.offerCode : "NA",
                                discount:this.state.discount !== "" ? this.state.discount : 0 ,
                                orderId:response2.razorpay_order_id,
                                paymentId:response2.razorpay_payment_id,
                                signature:response2.razorpay_signature
                            }
                            this.StoreInvoiceDataReturnedAPI(sendData).then(captureResponse=>{
                                if(captureResponse.success && captureResponse.result.paidStatus === "Paid" || captureResponse.success && captureResponse.result.paymentstatus === "Paid" ){
                                    this.setState({paymentStatusShow:true ,opacityChange:false, ResponcePayment : captureResponse,paidStatus:captureResponse.result && captureResponse.result.paidStatus  ? captureResponse.result.paidStatus : captureResponse.success && captureResponse.result.paymentstatus ? captureResponse.result.paymentstatus : ""})
                                   localStorage.removeItem("SelectedCouponCode")
                                }else {

                                }
                            })
                        } catch (err) {
                            // alert(JSON.stringify(err))
                            console.log(err);
                        }
                    },
                    theme: {
                        color: "#686CFD",
                    },
                };
                const rzp1 = new window.Razorpay(options);
                rzp1.open();

            }else{
                toast.error("There Is Something Wrong With Payment Gateway Please Contact Customer Service", {
                    position: "top-left",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        })
    };

    StoreInvoiceDataReturnedAPI = async (data) => {
        return await RestClient({
            url: this.state.quoteFor === "Courier" ? RETURN_TO_CONFIREM_COURIER : RETURN_TO_CONFIREM_ROAD,
            method: "POST",
            data: data
        });
    };







    render() {
        return (
            <Fragment>
                <PaymentPopUp
                    paymentPop={this.state.paymentPop}
                    failedPop={this.state.failedPop}
                    successPop={this.state.successPop}
                    paymentId={this.state.paymentId}
                    setmodal={value => {
                        if (value === false) {
                            this.setState({paymentPop: false})
                        }
                    }}
                />



                <PaymentStatusCard
                    paymentStatusShow={this.state.paymentStatusShow}
                    bookingid={this.state.bookingId}
                    quotationId = {this.state.quotationId}
                    queryFor={this.state.queryFor}
                    ResponcePayment={this.state.ResponcePayment}
                    returnResult = {(value) => { if(value === true){
                        this.setState({paymentStatusShow:false},()=>{
                            this.props.history.push(PAGE_PATHS.PROFORMA_COURIER_VIEW +"/"+ this.state.quoteFor + "/" + this.state.bookingId)
                            window.location.reload("false")
                        })
                    }else{
                        this.props.history.push(PAGE_PATHS.PROFORMA_COURIER_VIEW +"/"+ this.state.quoteFor + "/" + this.state.bookingId)
                        window.location.reload("false")
                    }
                    }}
                />

                <NotPaidStatusCard
                    paymentStatusShow={this.state.NotPaidStatusShow}
                    bookingid={this.state.bookingId}
                    ResponcePayment={this.state.ResponcePayment}
                    returnResult = {(value) => { if(value === true){
                        this.setState({NotPaidStatusShow:false})
                    } }}
                />

                <div className="bookingNerForm">
                    <div className="container-fluid">
                        <div className="row shipment_details_up">
                            <div className="details_for_rate">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <h3>{this.state.originName}</h3>
                                        <div className="icon_origin_destination"><FontAwesomeIcon icon={faArrowRight}/>
                                        </div>
                                        <h3>{this.state.DestinationName}</h3>
                                    </div>
                                </div>
                                <div className="details-other">
                                    <div className="text-shipment"><span>
                                        {this.state.previewData && this.state.previewData.totalPieces+ " Pcs. | "}{this.state.courierMode + " | "}{this.state.grossWeight + " KG | "}
                                        {this.state.commodity ? this.state.commodity+" | ":""}  {this.state.clearenceDate  ? convertDateISOToCustomiseFormat(this.state.clearenceDate)  : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="textBooking">
                                <p>Dear <span>{this.state.CustomerName}</span>,</p>
                                <p>Thank you for your business with us.</p>
                                <p>Your eBooking(s) request has been received and will be processed on payment completion.</p>
                                <p>Kindly Note your Booking Reference Number : <span>{this.state.jobNo}</span>.</p>
                                {this.state.skipStatus !== "Paid" ?
                                <button onClick={(e) => {
                                    e.preventDefault()
                                    this.paymentHandler(this.state.PerformaId).then(r =>{} )
                                }} className="request_btn_payment"> Pay Now
                                </button>:""}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fixed-btnsbot">
                    <div className="d-flex flex-wrap justify-content-end">
                        <Button className="pdf manager" onClick={e => {
                            e.preventDefault();
                            if(this.state.typeOfBussiness === "Individual" && this.state.skipStatus !== "Paid"){
                                this.props.history.push(PAGE_PATHS.QUERY_RATES_VIEW_COURIER + "/" + this.state.quoteFor + "/" + this.state.quotationId)

                            }else if(this.state.quoteFor === "Courier") {
                                this.props.history.push(PAGE_PATHS.shipmentHistoryCourier)
                            }else  {
                                this.props.history.push(PAGE_PATHS.shipmentHistoryRoad)
                            }
                        }}>Previous</Button>

                        <Button className="pdf manager"
                                onClick={e => {
                            e.preventDefault();
                            this.props.history.push(PAGE_PATHS.MAIN_PAGE)
                        }}>Home</Button>
                        {this.state.skipStatus === "Paid" ?
                            <Button className="pdf manager"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.history.push(PAGE_PATHS.PROFORMA_COURIER_VIEW + "/" + this.state.quoteFor + "/" + this.state.bookingId)

                                    }}

                            >Next</Button>
                        :""}

                    </div>
                </div>
            </Fragment>
        );
    }


}


const
    mapStateToProps = state => {
        const {
            items,
            totalItemCount,
            loading,
            error,
            response,
            airfreightFormValues
        } = state.invoiceMasterReducer;
        const {
            gstNumber,
        } = state.mawbReducer;

        return {
            items,
            totalItemCount,
            loading,
            error,
            response,
            airfreightFormValues,
            gstNumber,
        };
    };
const PerformaInvoicePdfViewNewWithRoute = withRouter(PayNowForIndividual);

export default connect(mapStateToProps, {
    previewInvoiceDetail, getInvoiceData, sendtocheked,GstDataAction
})(PerformaInvoicePdfViewNewWithRoute);