import { takeEvery, call, put, all } from "redux-saga/effects";

import RestClient from "../../util/RestClient";
import {PAGE_PATHS} from "../../util/PageRoute";
import {
    ADD_STATE_API,
    DELETE_STATE_API,
    Edit_STATE_API,
    GET_QUERY_PREVIEW_API,
    GET_QUERY_PREVIEW_API_AIR,
    GET_QUERY_PREVIEW_API_OCEAN,
    GET_QUERY_REVERT_API,
    GET_QUERY_REVERT_AUTO_API,
    GET_STATE_API,
    GETALL_STATE_API,
    MANAGER_STATE_API,
    MARKET_QUERIES_API,
    QUERY_DATA_ADD_API,
    QUERY_DATA_UPDATE_API,
    QUERY_MANAGER_API,
    QUERY_MANAGER_MASTER_DELETE,
    AWB_PDF_SEND_MANAGER,
    HAWB_PDF_GETURL,
    QUERY_PAST_SHIPMENT_MANAGER_API,
    QUERY_SHIPMENT_MANAGER_API,
    QUERY_SHIPMENT_MANAGER_HISTORY_API,
    UPDATE_STATE_API, GET_QUERY_PREVIEW_API_COURIER, GET_QUERY_PREVIEW_API_ROAD
} from "../../constants";
import {
    ADD_STATE,
    EDIT_STATE_DATA_FETCH,
    MANAGER_PAST_QUERY_DATA,
    MANAGER_QUERY_DATA,
    MANAGER_QUERY_DATA_HISTORY,
    MANAGER_STATE_DATA,
    MARKET_QUERY_DATA,
    QUERY_DELETE,
    AWB_PDF_SEND,
    QUERY_PREVIEW_DATA,
    QUERY_REVERT_AUTO_DATA,
    QUERY_REVERT_DATA,
    QUERY_REVERT_DATA_FOR_MARKET_RATE,
    SEND_DATA_TO_ADD_QUERY,
    SEND_DATA_TO_UPDATE_QUERY,
    STATE_DELETE,
    STATE_UPDATE
} from "../actions";
import {toast} from "react-toastify";
// import {history} from "../../util/helpers/history";
import {
    deleteStateResponse,
    EditStateDataFailuer,
    EditStateDataSuccess,
    getStateManagerDataFailuer,
    getStateManagerDataSuccess,
    updateStateResponse
} from "../stateMaster/stateAction";
import {
    getMarketQueryDataFailuer,
    getMarketQueryDataSuccess,
    getPastQueryManagerDataFailuer,
    getPastQueryManagerDataSuccess,
    getQueryAutoRevertDataFailuer,
    getQueryAutoRevertDataSuccess,
    getQueryManagerDataFailuer,
    getQueryManagerDataForHistoryFailuer,
    getQueryManagerDataForHistorySuccess,
    getQueryManagerDataSuccess,
    getQueryPreviewDataFailuer,
    getQueryPreviewDataSuccess,
    getQueryRevertDataFailuer,
    getQueryRevertDataForMarketRateFailuer,
    getQueryRevertDataForMarketRateSuccess,
    getQueryRevertDataSuccess,
    queryMasterDeleteResponse,
    awbPdfSendResponse,
    sendDataToAddQueryFail,
    sendDataToAddQuerySuccess,
    sendDataToUpdateQueryFail,
    sendDataToUpdateQueryuccess
} from "../QueryRedux/QueryAction";


function* watchQueryUser() {
    yield takeEvery(SEND_DATA_TO_ADD_QUERY, addQueryData);
    yield takeEvery(SEND_DATA_TO_UPDATE_QUERY, updateQueryData);
    yield takeEvery(MANAGER_QUERY_DATA, QueryMasterData);
    yield takeEvery(MANAGER_PAST_QUERY_DATA, PastQueryMasterData);
    yield takeEvery(MANAGER_QUERY_DATA_HISTORY, QueryMasterHistoryData);
    yield takeEvery(MARKET_QUERY_DATA, MarketQueriesData);
    yield takeEvery(QUERY_PREVIEW_DATA, QueryPreviewGetData);
    yield takeEvery(QUERY_REVERT_DATA, QueryRevertGetData);
    yield takeEvery(QUERY_REVERT_DATA_FOR_MARKET_RATE, QueryRevertGetDataForMarket);
    yield takeEvery(QUERY_REVERT_AUTO_DATA, QueryRevertAutoGetData);
    yield takeEvery(EDIT_STATE_DATA_FETCH, EditStateMaster);
    yield takeEvery(STATE_UPDATE, updateState);
    yield takeEvery(QUERY_DELETE, deleteQuery);
    yield takeEvery(AWB_PDF_SEND, sendAwbPdf);
}



function* addQueryData({ payload }) {
    try {
        const result = yield call(addQueryDataAPI, payload);
        if (result.success) {

            yield put(sendDataToAddQuerySuccess(result));
        }else {
            toast.error(result.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(sendDataToAddQueryFail(result.message));
        }

    } catch (error) {
        yield put(sendDataToAddQueryFail(error));
    }
}
const addQueryDataAPI = async (params) => {
    return await RestClient({
        url: QUERY_DATA_ADD_API,
        method: "POST",
        data: params
    })
};

function* updateQueryData({ payload }) {
    try {
        const result = yield call(updateQueryDataAPI, payload);
        if (result.success) {
            yield put(sendDataToUpdateQueryuccess(result));
        }else {
            toast.error(result.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(sendDataToUpdateQueryFail(result.message));
        }

    } catch (error) {
        yield put(sendDataToUpdateQueryFail(error));
    }
}
const updateQueryDataAPI = async (params) => {
    return await RestClient({
        url: QUERY_DATA_UPDATE_API,
        method: "POST",
        data: params
    })
};

function* QueryMasterData({ payload }) {
    try {
        const result = yield call(QueryMasterDataAPI, payload);
        if (result.success) {
            yield put(getQueryManagerDataSuccess(result.result));
        }else {
            // toast.error(result.message, {
            //   position: "top-left",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            yield put(getQueryManagerDataFailuer(result.message));
        }
    } catch (error) {
        yield put(getQueryManagerDataFailuer(error));
    }
}
const QueryMasterDataAPI = async (params) => {
    return await RestClient({
        url: QUERY_SHIPMENT_MANAGER_API,
        method: "POST",
        data: params
    })
};


function* PastQueryMasterData({ payload }) {
    try {
        const result = yield call(PastQueryMasterDataAPI, payload);
        if (result.success) {
            yield put(getPastQueryManagerDataSuccess(result.result));
        }else {
            // toast.error(result.message, {
            //   position: "top-left",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            yield put(getPastQueryManagerDataFailuer(result.message));
        }
    } catch (error) {
        yield put(getPastQueryManagerDataFailuer(error));
    }
}
const PastQueryMasterDataAPI = async (params) => {
    return await RestClient({
        url: QUERY_PAST_SHIPMENT_MANAGER_API,
        method: "POST",
        data: params
    })
};


function* QueryMasterHistoryData({ payload }) {
    try {
        const result = yield call(QueryMasterHistoryDataAPI, payload);
        if (result.success) {
            yield put(getQueryManagerDataForHistorySuccess(result.result));
        }else {
            // toast.error(result.message, {
            //   position: "top-left",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            yield put(getQueryManagerDataForHistoryFailuer(result.message));
        }
    } catch (error) {
        yield put(getQueryManagerDataForHistoryFailuer(error));
    }
}
const QueryMasterHistoryDataAPI = async (params) => {
    return await RestClient({
        url: QUERY_SHIPMENT_MANAGER_HISTORY_API,
        method: "POST",
        data: params
    })
};


function* MarketQueriesData({ payload }) {
    try {
        const result = yield call(MarketQueriesDataDataAPI, payload);
        if (result.success) {
            debugger
            yield put(getMarketQueryDataSuccess(result));
        }else {

            // toast.error(result.message, {
            //   position: "top-left",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            yield put(getMarketQueryDataFailuer(result));
        }
    } catch (error) {
        yield put(getMarketQueryDataFailuer(error));
    }
}
const MarketQueriesDataDataAPI = async (params) => {
    return await RestClient({
        url: MARKET_QUERIES_API,
        method: "POST",
        data: params
    })
};


function* QueryPreviewGetData({ payload }) {
    try {
        const result = yield call(QueryPreviewGetDataAPI, payload);
        if (result.success) {
            yield put(getQueryPreviewDataSuccess(result.result));
        }else {
            // toast.error(result.message, {
            //   position: "top-left",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            yield put(getQueryPreviewDataFailuer(result));
        }
    } catch (error) {
        yield put(getQueryPreviewDataFailuer(error));
    }
}
const QueryPreviewGetDataAPI = async (params) => {
    return await RestClient({
        url: params && params.quoteFor === "Air"? GET_QUERY_PREVIEW_API_AIR : params && params.quoteFor === "Ocean"? GET_QUERY_PREVIEW_API_OCEAN : params && params.quoteFor === "Courier"? GET_QUERY_PREVIEW_API_COURIER : GET_QUERY_PREVIEW_API_ROAD ,
        method: "POST",
        data: params
    })
};

function* QueryRevertGetData({ payload }) {
    try {
        const result = yield call(QueryRevertGetDataAPI, payload);
        if (result.success) {
            yield put(getQueryRevertDataSuccess(result.result));
        }else {
            // toast.error(result.message, {
            //   position: "top-left",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            yield put(getQueryRevertDataFailuer(result));
        }
    } catch (error) {
        yield put(getQueryRevertDataFailuer(error));
    }
}
const QueryRevertGetDataAPI = async (params) => {
    return await RestClient({
        url: GET_QUERY_REVERT_API,
        method: "POST",
        data: params
    })
};

function* QueryRevertGetDataForMarket({ payload }) {

    try {
        const result = yield call(QueryRevertGetDataForMarketAPI, payload);
        if (result.success) {
            yield put(getQueryRevertDataForMarketRateSuccess(result.result));
        }else {
            // toast.error(result.message, {
            //   position: "top-left",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            yield put(getQueryRevertDataForMarketRateFailuer(result));
        }
    } catch (error) {
        yield put(getQueryRevertDataForMarketRateFailuer(error));
    }
}
const QueryRevertGetDataForMarketAPI = async (params) => {
    return await RestClient({
        url: GET_QUERY_REVERT_API,
        method: "POST",
        data: params
    })
};


function* QueryRevertAutoGetData({ payload }) {
    try {
        const result = yield call(QueryRevertAutoGetDataAPI, payload);
        if (result.success) {
            yield put(getQueryAutoRevertDataSuccess(result.result));
        }else {
            yield put(getQueryAutoRevertDataSuccess(result));
        }
    } catch (error) {
        yield put(getQueryAutoRevertDataSuccess(error));
    }
}
const QueryRevertAutoGetDataAPI = async (params) => {
    return await RestClient({
        url: GET_QUERY_REVERT_AUTO_API,
        method: "POST",
        data: params
    })
};



function* EditStateMaster({ payload }) {
    try {
        const result = yield call(EditStateMasterAPI, payload);
        if (result.success) {
            yield put(EditStateDataSuccess(result.result));
        }else {
            yield put(EditStateDataFailuer(result.message));
        }

    } catch (error) {
        yield put(EditStateDataFailuer(error));
    }
}
const EditStateMasterAPI = async (params) => {
    return await RestClient({
        url: GET_STATE_API,
        method: "POST",
        data: params
    })
};

function* updateState({payload}) {
    try {
        const updateStateData = yield call(updateStateAsync, payload);
        if (updateStateData.success) {
            // history.push(PAGE_PATHS.STATE_MANAGER_VIEW);
            // window.location.reload(false);
            yield put(updateStateResponse(updateStateData.message));
        } else {
            yield put(updateStateResponse(updateStateData.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(updateStateResponse(error.response.data.message));
        }

    }
}
const updateStateAsync = async (StoreStateData) => {
    {
        return await RestClient({
            url: UPDATE_STATE_API,
            method: "POST",
            data: StoreStateData
        })

    }
}


function* deleteQuery({payload}) {
    try {
        const deleteQueryMasterResponse = yield call(deleteQueryAsync, payload);

        if (deleteQueryMasterResponse.success){
            yield put(queryMasterDeleteResponse(deleteQueryMasterResponse));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(queryMasterDeleteResponse (error.response.data.message));
        }
    }
}

const deleteQueryAsync = async (payload) => {
    return await RestClient({
        url: QUERY_MANAGER_MASTER_DELETE,
        method: "POST",
        data: payload
    })
}
function* sendAwbPdf({payload}) {
    try {
        const sendAwbPdfMasterResponse = yield call(sendAwbPdfAsync, payload);

        if (sendAwbPdfMasterResponse.success){
            yield put(awbPdfSendResponse(sendAwbPdfMasterResponse));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(awbPdfSendResponse (error.response.data.message));
        }
    }
}

const sendAwbPdfAsync = async (payload) => {
    return await RestClient({
        url: HAWB_PDF_GETURL,
        method: "POST",
        data: payload
    })
}

export function* QuerySagaAir() {
    yield all([watchQueryUser()]);
}
