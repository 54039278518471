import {all, call, fork, put} from 'redux-saga/effects';

import RestClient from "../../util/RestClient";
import {

    MANAGER_SHIPPER,
    SAVE_SHIPPER,
    UPDATE_SHIPPER,DELETE_SHIPPER
} from "../../constants";
import {
    SHIPPER_MANAGER_FETCH_MASTER,
    SHIPPER_CREATE,

    SHIPPER_MASTER_DELETE,
    SHIPPER_MASTER_UPDATE,
} from "../actions";
import routeRedirectTo from "../../util/routeWrapper";
import {takeLatest} from "@redux-saga/core/effects";
import {
    showShipperResponce,
    onShipperMasterFetched,
    onShipperMasterFetchedFailed, updateShipperDetailResponse,  deleteShipperManagerResponse
} from "./shipperMasterActions";
import {PAGE_PATHS, PAGE_PATHS as PageRoute} from "../../util/PageRoute";
import {toast} from "react-toastify";
import {history} from "../../helpers/history";

export function* watchEmail() {
    yield takeLatest(SHIPPER_CREATE, storeShipper);
    yield takeLatest(SHIPPER_MANAGER_FETCH_MASTER, fetchShipperManager);
    yield takeLatest(SHIPPER_MASTER_DELETE, deleteShipperManagerdata);
    yield takeLatest(SHIPPER_MASTER_UPDATE, updateShipper);
    // yield takeLatest(SHIPPER_MASTER_DELETE, deleteEmail);


}
function* deleteShipperManagerdata({payload}) {
    try {
        const deleteShipperManager = yield call(deleteShipperManagerAsync, payload._id);
        //// console.log("delete Airfreight Surcharge Response " + JSON.stringify(deleteShipperManager))
        if (deleteShipperManager.success) {
            //// console.log("delete Airfreight Surcharge IN Success" + JSON.stringify(deleteShipperManager))
            yield put(deleteShipperManagerResponse(deleteShipperManager.message));
            yield call(forwardTo, PageRoute.SHIPPER_MANAGER);
        } else {
            //// console.log("deleteShipperManagerResponse Not Success" + JSON.stringify(deleteShipperManager))
            yield put(deleteShipperManagerResponse(deleteShipperManager.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(deleteShipperManagerResponse(error.response.data.message));
        }
    }
}
const deleteShipperManagerAsync = async (_id) => {
        //// console.log(JSON.stringify("Charge Master Manager delete API" + JSON.stringify(_id)))
        return await RestClient({
            url: DELETE_SHIPPER,
            method: "POST",
            data: {id: _id}
        })
}

function* storeShipper({payload}) {
    try {
        const storeShipperResponse = yield call(storeShipperAsync, payload);

        if (storeShipperResponse.success) {
            yield put(showShipperResponce(storeShipperResponse));
            toast.success("Shipper Successfully Added", {
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history.push(PAGE_PATHS.SHIPPER_CONSIGNEE_MANAGER);
            window.location.reload(false);



        } else {
            // console.log("Not Success" + storeShipperResponse)
            yield put(showShipperResponce(storeShipperResponse));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(showShipperResponce(error.response.data.message));
        }

    }
}

const storeShipperAsync = async (StoreShipperData) => {
    {
        // console.log(JSON.stringify("In Shipper Save API" + JSON.stringify(StoreShipperData)))

        return await RestClient({
            url: SAVE_SHIPPER,
            method: "POST",
            data: StoreShipperData
        })

    }
}

function* updateShipper({payload}) {
    try {
        const updateShipperData = yield call(updateShipperAsync, payload);
        // console.log("updateShipperDetailResponse  response" + JSON.stringify(updateShipperData))
        if (updateShipperData.success) {
            // console.log("updateShipperDetailResponse IN Success" + JSON.stringify(updateShipperData))
            yield put(updateShipperDetailResponse(updateShipperData.message));
            toast.success("Shipper Successfully Updated", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.push(PAGE_PATHS.SHIPPER_CONSIGNEE_MANAGER);
            window.location.reload(false);
            // yield call(forwardTo, PageRoute.SHIPPER_MANAGER);

        } else {
            // console.log("updateShipperDetailResponse Not Success" + JSON.stringify(updateShipperData.message))
            yield put(updateShipperDetailResponse(updateShipperData.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(updateShipperDetailResponse(error.response.data.message));
        }

    }
}

const updateShipperAsync = async (StoreShipperData) => {
    {
        // console.log(JSON.stringify("In shipper Update API" + JSON.stringify(StoreShipperData)))

        return await RestClient({
            url: UPDATE_SHIPPER,
            method: "POST",
            data: StoreShipperData
        })

    }
}




function* fetchShipperManager({payload}) {

    // console.log("In fetch shipper Attempt" + JSON.stringify(payload))

    try {
        const fetchShipperManagerResponse = yield call(fetchShipperManagerAsync, payload);
        // console.log("fetch shipper Master" + fetchShipperManagerResponse);
        if (fetchShipperManagerResponse.success) {
            yield put(onShipperMasterFetched(fetchShipperManagerResponse.result));
        } else {
            // console.log(" fetch shipper Master Not Success" + fetchShipperManagerResponse)
            yield put(onShipperMasterFetchedFailed(fetchShipperManagerResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch shipper Master" + JSON.stringify(error))
            yield put(onShipperMasterFetchedFailed(error.response.data.message));
        }
    }
}

const fetchShipperManagerAsync = async (fetchShipperManagerData) => {
    {
        // console.log(JSON.stringify("In fetch  shipper  API" + JSON.stringify(fetchShipperManagerData)))

        return await RestClient({
            url: MANAGER_SHIPPER,
            method: "POST",
            data: fetchShipperManagerData
        })

    }
}


function forwardTo(location) {
    routeRedirectTo.push(location);
}

export default function* Saga() {
    yield all([
        fork(watchEmail)
    ]);
}
