import React, {Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import FooterHome from "./FooterHome";
import RestClient from "../../../../src/util/RestClient";
import {GET_NEWS_TICKER,GET_All_NEWS_DATA} from "../../../constants";
import {convertDateISOToNewsFormat} from "../../../util/CommonUtility";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
class TrackingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter:"",
            search:"",
            postType:"",
      startDate: undefined,
            };

    }

    componentDidMount() {
        this.getNewsData().then(responce=> {
            if(responce.success && responce.result){
                let NewsDataResult = responce && responce.result ? responce.result.data : [];
                let postType = NewsDataResult[0] && NewsDataResult[0].postType ? NewsDataResult[0].postType : [];

                this.setState({
                    NewsDataResult:NewsDataResult,
                    postType:postType

                })
            }
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    getNewsData = async (dataForServer) => {
        return await RestClient({
            url: GET_NEWS_TICKER,
            method: "POST",
            data: dataForServer
        });
    }

    // handleChange(date) {
    //     this.setState({
    //         searchDate: date
    //     })
    // }

    handleChange = (e) => {
        this.setState({
            startDate: e.target.value }, () => {

            });
      };


        NewsData = () =>{

             let stateParameters = { startDate:this.state.startDate, postType: this.state.postType,
             };
            this.getAllNews(stateParameters).then(responce =>{
                if(responce.success && responce.result){
                    let AllNewsData = responce && responce.result ? responce.result : [];

                    this.setState({
                        NewsDataResult:AllNewsData,
                    })
                }else {
                    toast.error("Data Not Available", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({dataloader: false});                }
            })


        }
    getAllNews = async (dataForServer) => {
        return await RestClient({
            url: GET_All_NEWS_DATA,
            method: "POST",
            data: dataForServer
        });
    }

    render() {

        return (
            <>
                <AppLayout>
                    <div className="News_Section">
                        <h2>In Media</h2>
                
                    </div>
         
                   
                    <div className="form-group"  >
                    <div className="width-4">
                  <label className="origin-label" style={{color: "red",fontSize:"20Px",}} >Select News Date</label>
                  <input 
                    placeholder="Date"
                    type="Date"
                    name="searchDate"
                    className="form-control inputs1"
                    onChange={this.handleChange}
                    
                  />
                  </div>
                  </div>
                  <div className="form-group"   >
                  <div className="width-4">
                  <button 
                    className="btns-master display-blocks"
                    onClick={(e) => {
                      e.preventDefault();
                      {this.NewsData();}
                    }}
                  >
                    search news 
                  </button>
                </div>
                </div>

                    <div className="services_content ">
                        <div className="container">
                            <div className="row">
                                {this.state.NewsDataResult && this.state.NewsDataResult.map((obj, i) => {
                                    return (
                                        <>
                                            <div className="col-lg-3">
                                                <div className="latestNewsItem">
                                                    <div className="news_images">
                                                        <img src={obj.attachments[0]} alt={obj.title}/>
                                                    </div>
                                                    <div className="news_details">
                                                        <div className="my_list_date">{obj && obj.validityStartDate ? convertDateISOToNewsFormat(obj.validityStartDate):""}</div>
                                                        <h2>{obj.title ? obj.title.substring(0, Math.min(obj.title.length, 60)):""}</h2>
                                                        <div className="learnButton"
                                                               onClick={e => {
                                                                e.preventDefault()
                                                                window.open(obj.hitUrl)
                                                            }}
                                                        >Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <FooterHome/>
                </AppLayout>


            </>
        )}
}




export default TrackingPage;

