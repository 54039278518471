import {all, call, fork, put} from 'redux-saga/effects';

import RestClient from "../../util/RestClient";
import {
  SHIPPER_FETCH_ALL_URL,
  CONSIGNEE_FETCH_ALL_URL
} from "../../constants";
import {
  SHIPPER_FETCH_ALL,
  CONSIGNEE_FETCH_ALL,

} from "../actions";
import routeRedirectTo from "../../util/routeWrapper";
import {takeLatest} from "@redux-saga/core/effects";
import {
  onShipperGetAllRequestFetched,
  onShipperGetAllRequestFailed,
  onConsigneeGetAllRequestFetched,
  onConsigneeGetAllRequestFailed,

} from "./shipperConsigneeActions";


export function* watchShipperConsignee() {
  yield takeLatest(SHIPPER_FETCH_ALL, fetchShipper);
  yield takeLatest(CONSIGNEE_FETCH_ALL, fetchConsigneeAll);


}

function* fetchShipper({payload}) {

  try {
    const fetchShipperResponse = yield call(fetchShipperAsync, payload);

    if (fetchShipperResponse.success) {
      yield put(onShipperGetAllRequestFetched(fetchShipperResponse));

    } else {
     // console.log("Not Success" + fetchShipperResponse)
      yield put(onShipperGetAllRequestFailed(fetchShipperResponse.message));
    }
  } catch (error) {
    if (Object.keys(error).length !== 0) {
      yield put(onShipperGetAllRequestFailed(error.response.data.message));
    }

  }
}

const fetchShipperAsync = async (shipperConsigneeDataPayload) => {
  {
   // console.log(JSON.stringify("In Shipper Fetch API" + JSON.stringify(shipperConsigneeDataPayload)))
    return await RestClient({
      url: SHIPPER_FETCH_ALL_URL,
      method: "POST",
      data: shipperConsigneeDataPayload
    })

  }
}

function* fetchConsigneeAll({payload}) {
  try {
    const fetchConsigneeAllData = yield call(fetchConsigneeAllAsync, payload);
   // console.log("fetchConsigneeAllResponse  response" + JSON.stringify(fetchConsigneeAllData))
    if (fetchConsigneeAllData.success) {
     // console.log("fetchConsigneeAllResponse IN Success" + JSON.stringify(fetchConsigneeAllData))
      yield put(onConsigneeGetAllRequestFetched(fetchConsigneeAllData));


    } else {
     // console.log("fetchConsigneeAllResponse Not Success" + JSON.stringify(fetchConsigneeAllData.message))
      yield put(onConsigneeGetAllRequestFailed(fetchConsigneeAllData.message));
    }
  } catch
    (error) {
    if (Object.keys(error).length !== 0) {

      yield put(onConsigneeGetAllRequestFailed(error.response.data.message));
    }

  }
}

const fetchConsigneeAllAsync = async (shipperConsigneeDataPayload) => {
  {
   // console.log(JSON.stringify("In Consignee fetch API" + JSON.stringify(shipperConsigneeDataPayload)))

    return await RestClient({
      url: CONSIGNEE_FETCH_ALL_URL,
      method: "POST",
      data: shipperConsigneeDataPayload
    })

  }
}




export default function* Saga() {
  yield all([
    fork(watchShipperConsignee)
  ]);
}
