export const PAGE_PATHS = {
    ERROR_PAGE: "/error",
///Invoice
    INVOICE_MANAGER: "/ProformaInvoice",
    SHIPPER_INVOICE_MANAGER: "/InvoiceManager",
    VIEW_FOR_INVOICE_MANAGER: "/InvoiceManagerView",
    CREATE_INVOICE: "/invoice",
    SPOT_RATE_EDIT: "/spotRateEdit",
    SPOT_RATE_ADD: "/spotRateAdd",
    SPOT_RATE_PAGE: "/SpotRatePage",
    SPOT_RATE_AIR_PAGE: "/SpotRateAirPage",
    SPOT_RATE_AIR_PAGE_NEW:"/SpotRatePageAir",

    // DASHBOARD: "/app",
    DASHBOARD: "/Home",
    ABOUTPAGE: "/AboutUs",
    FORGETPASSWORD: "/ForgetPassword",
    AIRLOGIN:"https://shipper.zipaworld.com/Home/",
    EDIT_BOOKING: "/editBooking",
    shipmentHistory: "/ShipmentHistory",
    shipmentHistoryCourier: "/ShipmentHistoryCourier",
    shipmentHistoryAir: "/ShipmentHistoryAir",
    shipmentHistoryRoad: "/ShipmentHistoryRoad",
    VIEW_MAWB: "/MawbView",
    AIR_VIEW_MAWB: "/AirMawbView",
    VIEW_MAWB_CUSTOMER: "/MawbViewCustomer",
    OCEAN_VIEW_MAWB_CUSTOMER: "/OceanMawbViewCustomer",
    EDIT_MAWB: "/MawbEdit",
    EDIT_HAWB: "/hawbEdit",

    PERFORMA_INVOICE: "/ProformaInvoiceNew",
    AIR_EDIT_BOOKING : "/aireditbooking",
    PROFILE_VIEW: "/app/profile/profileView",
    CUSTOMER_KYC: "/CustomerKYC",
    CUSTOMER_KYC_FOR_INDIVIDUAL: "/CustomerKYCForIndividuals",
    SHIPPER_CONSIGNEE_MANAGER: "/ShipperConsigneeManager",
    ADD_SHIPPER_REQUEST: "/AddShipperRequest",
    ADD_CONSIGNEE_REQUEST: "/AddConsigneeRequest",
    ADD_SHIPPER_BRANCH_REQUEST: "/RequestShipperBranch",
    ADD_CONSIGNEE_BRANCH_REQUEST: "/RequestConsigneeBranch",
    EDOCKET_MANAGER: "/eDocketManager",
    ABOUT_PAGE: "/About",
    CONTACT_PAGE: "/Contact",
    PRIVACY_PAGE: "/Privacy",
    TERMS_AND_CONDITION_PAGE: "/TermsCondition",
    OFFER_PAGE: "/Offer",
    NEWS_PAGE: "/News",
    PRODUCT_PAGE: "/Products",
    MISSION_PAGE: "/Mission",
    VISION_PAGE: "/Vision",
    TEST_INVOICE_PAGE: "/OceanInvoiceNew",
    VALUE_PAGE: "/Values",
    FAQ_PAGE: "/Faq",
    DASHBOARD3: "/app/dashboards/dashBoard3",
    HOUSE_VIEW: "/houseView",
    HOUSE_VIEW_FINAL: "/houseViewFinal",
    HOUSE_VIEW_FINAL_OCEAN: "/HouseViewFinalOcean",

    AIR_QUERY_MANAGERPRIVIEW: "/AirQueryManagerPreview",
    EDIT_BUY_SALE_VIEW:"/EditBuySale",
    MANAGER_BUY_SALE_VIEW:"/BuySaleManager",
    AIR_CUSTOMER_REVERT_VIEW:"/AirCustomerRevert",
    AIR_COPY_QUERY_VIEW:"/AirCopyQuery",
    AIR_EDIT_QUERY_VIEW:"/AirEditQuery",
    AIRLINE_REVERT_VIEW:"/AirlineLineRevert",
    AIRLINE_STOK_ADD_VIEW:"/AddStock",
    AIR_BUY_SALE_VIEW:"/AirBuySale",
    AIR_EDIT_BOOKING_VIEW:"/AirEditBooking",
    AIR_BOOKING_VIEW:"/MawbViewCustomer",
    AIR_PROFORMA_VIEW:"/ProformaInvoiceNew",
    AIR_MAWB_VIEW:"/AirMawbView",
    HOUSE_MAWB_VIEW:"/HouseMawbView",
    AIR_MAWB_EDIT_VIEW:"/AirMblEdit",
    MBL_VIEW_PAGE_OLD: "/MawbView",
    INVOICE_APPROVAL_VIEW:"/InvoiceApproval",
    INVOICE_VIEW:"/InvoiceView",
    PROFORMA_VIEW_PAGE: "/ProformaView",
    PRICING_APPROVAL_VIEW:"/pricingApproval",
    HBL_VIEW_PAGE: "/HblView",
    AWB_POPUP: "/AwbPdfPopUp",
    EDIT_HOUSES:"/Airhbledit",
    EDIT_AWB:"/Editawb",
    EDIT_AIR_MAWB:"/Editawb",
    STATICS: "/statics",
    TRACKING: "/TrackingPage",
    EDOCKET_PDF_VIEW: "/EdocketPdfView",
    NEW_QUERY_FORM: "/NewUserQueryForms",

    BOOKING_COURIER_VIEW: "/CourierBookingView",
    PROFORMA_COURIER_VIEW: "/ProformaInvoiceNewCourier",

    COURIER_NEW_VIEW:"/CourierNewQuery",

    QUERY_RATES_VIEW : "/RatesQueryPreview",
    QUERY_RATES_VIEW_COURIER : "/RatesQueryPreviewCourier",
    AIRLINE_DOCUMENT_MANAGER:"/docManager",
    SEARCH_SHIPMENTS : "/TrackingSearchPage",
    FRIGHT_MANAGEMENT : "/FreightManagement",
    ROAD_NEW_VIEW : "/RoadNewQuery",
    AIR_FREIGHT : "/AirFreight",
    OCEAN_FREIGHT: "/OceanFreight",
    ROAD_FREIGHT : "/RoadFreight",
    COURIER_SERVICES : "/CourierFreight",
    WAREHOUSING_SERVICES : "/WarehousingSolutions",
   CUSTOMS_BROKERAGE : "/CustomsBrokerage",
   PACKAGE_RELOCATIONS : "/PackagingServices",
   OTHER_SERVICES : "/TradeFinance",
   NEWS_DIFFERENT : "/OurNews",
   JOB_OPENINGS : "/CareerPage",
   RESUME_POST : "/PostResume",
   LIFE_AT_ZIPA : "/LifeAtZipaworld",
   TEAM_SECTION : "/OurTeam",
   FAQ_SECTION : "/FaqPage",
   POPUP_NEW_USER : "/exporterPage",
   MAIN_PAGE : "/Home",
   BLOG_DESCRIPTION : "/BlogDescription",
   BLOG_PAGE : "/BlogPage",
   PICK_UP_DETAILS : "/DriverDetails",
   PICK_UP_DETAILS_DEMO : "/DriverDetailsDemo",
   OFFER_PAGE_OTHER : "/OfferMy",
   AWARDS_PAGE : "/AwardsAndRecognition",
   PRICING_PLAN : "/Pricing",
   CONTACT_FOR_PLAN : "/MemberShip",
    VESSEL_SCHEDULES : "/VesselScheduleSearch",
    INVOICE_DATA_PAGE : "/InvoiceDataForm",
    INDIVIDUAL_BOOKING_PAGE : "/BookingCourierIndividual",
    VIEW_SHIPPER_BRANCH_REQUEST: "/ViewShipperBranch",
    VIEW_CONSIGNEE_BRANCH_REQUEST: "/ViewConsigneeBranch",
    COURIER_TRACKING_PAGE_NEW: "/TrackShipment",
    OCEAN_TRACKING_PAGE_NEW: "/TrackShipmentOcean",
    AIR_TRACKING_PAGE_NEW: "/TrackShipmentAir",
    ROAD_TRACKING_PAGE_NEW: "/TrackShipmentRoad",

};