import {toastr} from "react-redux-toastr";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";

import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import RestClient from "../util/RestClient";
import {EMAILS_ALL} from "../constants";

class MultiSelectEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: "",
            emailsDropdown: false,
            emailsSelect: [],
            tagsEmailSearch: [],
            tags: [],
            queryEmails: "",
            showEmails: false,
            emailValidation: "",
        };
        this.getEmails().then();
    }

    componentDidMount() {
        this.setState({
            emailsSelect: this.props.selectedEmails ? this.props.selectedEmails : []
        },()=>{
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.selectedEmails !== this.state.emailsSelect &&
            this.props.selectedEmails
        ) {
            this.setState({
                emailsSelect: this.props.selectedEmails
            },()=>{
            });
        }
    }

    getEmails = async () => {
        this.fetchEmails().then(response=>{

            let FinalArray =[]
            const GroupArry = [];
            const allMailsArry = [];
            if (response && response.result === undefined) {
                toastr.error("emails doesn't found");
            } else {
                if( response && response.result && response.result.allGroups && response.result.allGroups.length >=1){
                    response.result.allGroups.map(itm => {
                        GroupArry.push(itm.groupName);
                    });
                }

                if(response && response.result && response.result.allMails && response.result.allMails.length >=1){
                    response.result && response.result.allMails.map(itm => {

                        allMailsArry.push(itm);
                    });
                }
                FinalArray = allMailsArry;

                this.setState({
                    tags: FinalArray
                },()=>{

                });
            }

        })

    };

    fetchEmails = async () => {
        {
            return await RestClient({
                url: EMAILS_ALL,
                method: "POST",
                data: null
            });
        }
    };

    SelectDropDown = () => {
        this.setState({emailsDropdown: !this.state.emailsDropdown});
    };
    deleteEmails = (i, e) => {
        e.preventDefault();
        let emailsSelect = [...this.state.emailsSelect];
        emailsSelect.splice(i, 1);
        this.setState({ emailsSelect }, () => {
            this.props.onEmailSelected(this.state.emailsSelect, this.props.name);
        });
    };
    validateEmail = email => {
        // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // return re.test(String(email).toLowerCase());
        let emailLower = String(email).toLowerCase();
        let re1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re2 = /^[a-zA-Z0-9][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re3 = /^.{1,64}@.{1,256}$/;
        let reOther = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re1.test(emailLower)){
            if(reOther.test(emailLower)){
                if(re.test(emailLower)){
                    if(re2.test(emailLower)){
                        if(re3.test(emailLower)){
                            return emailLower
                        }else{
                            this.setState({
                                emailValidation : "* Please enter a valid email Id."
                            })
                        }

                    }else{
                        this.setState({
                            emailValidation : "* Please enter a valid email Id."
                        })
                    }
                }else{
                    this.setState({
                        emailValidation : "* Please enter a valid email Id."
                    })
                }
            }else{
                this.setState({
                    emailValidation : "* Please enter a valid email Id."
                })
            }
        }else{
            this.setState({
                emailValidation : "* Please enter a valid email Id."
            })
        }
    };
    handleEmail = event => {

        try {
            this.setState({
                emailValidation : ""
            })
            const queryEmails = event.target.value;
            this.setState(prevState => {
                const tags = prevState.tags.filter(element => {
                    if(element !== null && element !== undefined && element !== "")
                        return element.toLowerCase().includes(queryEmails.toLowerCase());
                });

                return {
                    queryEmails,
                    tags
                };
            });
            if (event.keyCode === 13) {
                if (this.validateEmail(event.target.value)) {
                    this.state.emailsSelect.push(event.target.value);
                    this.setState(
                        { tags: this.state.tags, queryEmails: "" },
                        () => {
                            this.props.onEmailSelected(
                                this.state.emailsSelect,
                                this.props.name
                            );
                        },
                        () => {
                        }
                    );
                } else {
                    toastr.error("Emails is not Valid");
                }
            }
            this.getEmailsTo().then();
        } catch (e) {

        }
    };

    tagsSelect = value => {
        let emails = this.state.emailsSelect ? this.state.emailsSelect : [];
        emails.push(value);

        this.setState(
            {
                emailsSelect: emails,
                emailsDropdown: false
            },
            () => {
                this.props.onEmailSelected(this.state.emailsSelect, this.props.name);
            }
        );
    };

    getEmailsTo = async () => {
        try {
            let response = await this.fetchEmails();

            // console.log("emails", response);
            const GroupArry = [];
            const allMailsArry = [];
            response.result.allGroups.map(itm => {
                GroupArry.push(itm.groupName);
            });
            response.result.allMails.map(itm => {
                allMailsArry.push(itm);
            });
            let FinalArray = GroupArry.concat(allMailsArry);
            const { queryEmails } = this.state;
            FinalArray = FinalArray.filter(element => {
                return element.toLowerCase().includes(queryEmails.toLowerCase());
            });

            this.setState({
                tags: FinalArray
            });
        } catch (e) {

        }
    };
    emailsShow = () => {
        if (this.state.showEmails) this.setState({ showEmails: false });
        else this.setState({ showEmails: true });
    };

    render() {
        try {
            return (
                //email fiels start
                <Fragment>
                    <div className={this.props.propClass?"position-relative edit_inputs":"position-relative"}>
                        <div className="">
                            {/*<div className="">*/}
                            {/*    <h6>{this.props.placeHolder ? this.props.placeHolder : "Select the emails"}</h6>*/}
                            {/*</div>*/}
                        </div>
                        <Input className={this.props.propClass?"form-control ":"form-control inputs1 position-relative"}
                               type="text"
                               placeholder={this.props.placeHolder ? this.props.placeHolder : "Select the emails"}
                               onBlur={e => {
                                   setTimeout(() => {
                                       this.setState({
                                           emailsDropdown: false,
                                           queryEmails: ""
                                       });
                                   }, 500)
                                   return false;
                               }}
                               onKeyUp={e => {
                                   this.handleEmail(e);
                               }}
                               onChange={e => {
                                   if (this.props.disabled !== true) {
                                       this.handleEmail(e);
                                   }
                               }
                               }
                               id="basicinput"
                               name="emailId"
                               value={this.state.queryEmails}
                               onClick={() => this.SelectDropDown()}
                        />
                        <p style={{color:'red'}}>{this.state.emailValidation}</p>
                        <div className="position-relative mt-1">
                            <div className="email-rel">
                                {this.state.showEmails && (
                                    <Label className="d-flex flex-wrap">
                                        {this.state.emailsSelect &&
                                        this.state.emailsSelect.map(itm => {
                                            return (
                                                <>
                                                    <span>{itm}</span>
                                                </>
                                            );
                                        })}
                                    </Label>
                                )}
                            </div>
                            <div className="email-mullti">
                                <Label for="basicinput" className="d-flex flex-wrap" style={{ width: "100%" }}>
                                    {this.state.emailsSelect &&
                                    this.state.emailsSelect.length > 2 ? (
                                        this.state.emailsSelect.slice(0, 2).map((itm, indx) => {
                                            return (
                                                <>
                                                                    <span>
                                                                        {itm}
                                                                        <i onClick={e => this.deleteEmails(indx, e)}>X</i>
                                                                    </span>
                                                </>
                                            );
                                        })
                                    ) : (
                                        <>
                                            {this.state.emailsSelect.map((itm, indx) => {
                                                return (
                                                    <>
                                                                        <span>
                                                                            {itm}
                                                                            <i onClick={e => this.deleteEmails(indx, e)}>X</i>
                                                                        </span>
                                                    </>
                                                );
                                            })}
                                        </>
                                    )}
                                </Label>
                            </div>
                            <div className="email-plus">
                                {this.state.emailsSelect &&
                                this.state.emailsSelect.length > 2 ? (
                                    <span
                                        onClick={() => {
                                            this.emailsShow();
                                        }}
                                    >
                                                    {this.state.showEmails ? "-" : "+"}
                                                </span>
                                ) : (
                                    ""
                                )}

                            </div>
                        </div>
                        <span className="text-danger"></span>
                        {this.state.emailsDropdown &&
                        <div className="allmail_show">
                            {this.state.tags &&
                            this.state.tags.map(itms => {
                                return (
                                    <ul>
                                        <li onClick={() => this.tagsSelect(itms)}>
                                            {itms}
                                        </li>
                                    </ul>
                                );
                            })}
                        </div>
                        }
                    </div>

                </Fragment>
                //email fiels end
            );
        } catch (e) {
            //  alert(e);
            return (
                <>
                    <Input type="select" required>
                        <option> Please Reload</option>
                    </Input>
                </>
            );
        }
        //}
    }
}

const propTypes = {
    name: PropTypes.string.isRequired,
    placeHolder: PropTypes.func.isRequired,
    onEmailSelected: PropTypes.func.isRequired,
    selectedEmails: PropTypes.array
};

const defaultProps = {
    name: "",
    placeHolder: "",
    onEmailSelected: undefined,
    showEmails: false
};

MultiSelectEmail.propTypes = propTypes;
MultiSelectEmail.defaultProps = defaultProps;

export default MultiSelectEmail;
