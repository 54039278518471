import {
    GST_NUMBER_TO_PERFORMA,
    MAWB_UPDATE,
    MAWB_UPDATE_RESPONSE
} from "../actions";

export const updateMawb = (MawbDetails) => ({
    type: MAWB_UPDATE,
    payload: MawbDetails
});

export const GstDataAction = (GstDetails) => ({
    type: GST_NUMBER_TO_PERFORMA,
    payload: GstDetails
});

export const updateMawbResponce = (MawbDetailsResponse) => ({
    type: MAWB_UPDATE_RESPONSE,
    payload: MawbDetailsResponse
});
