import React, { Component } from "react";
import AppLayout from "../../../layout/AppLayout";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ModalFooter from "reactstrap/es/ModalFooter";
import {toast} from "react-toastify";
import RestClient from "../../../util/RestClient";
import {CAREER_DATA_API, CAREER_MASTER_MANAGER, GET_SPOT_RATE_TICKER, UPDATE_EDIT_RATES} from "../../../constants";
import RateChargesNotavilbale from "./RateChargesNotavilbale";
import MailSendSuccess from "./MailSendSuccess";
import UploadFilesCloudeCareer from "../../../components/uploadFilesCloudeCareer";
import {
    faCalendarAlt, faEdit,
    faEnvelope, faHandPointer,
    faMapMarkerAlt,
    faPencilAlt,
    faPhoneAlt,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


 class CareerPage extends Component {
     myDivToFocus = null
     constructor(props) {
         super(props);
         this.myDivToFocus = React.createRef()
         this.testRef = React.createRef();
         this.state = {
             mailSucessMailPopup:false,
             companyDocument: "",
         };
     }
     componentDidMount() {
         this.scrollToElement();
         this.careerApiData().then(responce=>{
             if(responce.success){
                 let JobsData = responce && responce.result && responce.result.data ? responce.result.data : [];
                 this.setState({
                     jobsData:JobsData,
                 })
             }
         })
     }

     careerApiData = async (dataForServer) => {
         return await RestClient({
             url: CAREER_MASTER_MANAGER,
             method: "POST",
             data: dataForServer
         })
     }
     applyButtonFunc = (event) => {
         if (this.myDivToFocus.current) {
             this.myDivToFocus.current.scrollIntoView({
                 behavior: "smooth",
                 block: "nearest"
             })
         }
     }
     // applyButtonFunc = () =>{
     //     this.setState({
     //         modalLarge:!this.state.modalLarge,})
     // }

     toggleLarges = () => {
         this.setState(prevState => ({
             modalLarge: !prevState.modalLarge
         }));
     };
     handleChange = e => {
         this.setState({[e.target.name]: e.target.value});
     };
     validateForm() {
         let formIsValid = true;
         let errors = {}

         if (!this.state.name) {
             formIsValid = false;
             errors["name"] = "*  Name is required";
         }
         if (!this.state.email) {
             formIsValid = false;
             errors["email"] = "* Email is required";
         }

         if (!this.state.phone) {
             formIsValid = false;
             errors["phone"] = "* Phone is required";
         }

         if (!this.state.experience) {
             formIsValid = false;
             errors["experience"] = "* Experience is Required";
         }
         if (!this.state.postName) {
             formIsValid = false;
             errors["postName"] = "* JobType is Required";
         }
         if (!this.state.companyDocument) {
             formIsValid = false;
             errors["companyDocument"] = "* Kindly upload your CV";
         }
         if (!this.state.coverLetter) {
             formIsValid = false;
             errors["coverLetter"] = "* Share brief information about yourself";
         }
         this.setState({
             errors: errors,
         });
         return formIsValid;
     }
     sendMailToHr = (e) =>{

         e.preventDefault();
         if (this.validateForm()) {
             const body = {
                 emailTo : ["shashank@zipaworld.com"],
                 // emailTo : ["kanchan@aaa2innovate.com"],
                 emailSubject : "For Job in Zipaworld",
                 attachments: [{
                     name:"Resume",
                     link:this.state.companyDocument,
                 }],
                 coverLetter : this.state.coverLetter,
                 name:this.state.name,
                 email:this.state.email,
                 phone:this.state.phone,
                 postName:this.state.postName,
                 experience:this.state.experience,

                 emailBody:"",
             };
             this.CareerApiCall(body).then(response=>{
                 if(response.success){
                     toast.success("Mail Sanded Successfully", {
                         position: "top-left",
                         autoClose: 3000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                     });
                     this.setState({
                         mailSucessMailPopup:true,
                     })
                 }
             })
         }

     }

     CareerApiCall = async (body)=> {
         return await RestClient({
             url: CAREER_DATA_API,
             method: "POST",
             data: body
         })
     };
     scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        const {errors } = this.state;
        return (
            <>
                <AppLayout>
                    <MailSendSuccess
                        mailPopupSucess={this.state.mailSucessMailPopup}
                    />
                    <div className="bg-white" ref={this.testRef}>
                        <div className="News_Section career_Section">
                            <h2>Career</h2>
                        </div>
                        <section className="hire_page">
                            {Array.isArray(this.state.jobsData) && this.state.jobsData.length >= 1 ?
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2>Working With Us</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {Array.isArray(this.state.jobsData) && this.state.jobsData.length >= 1
                                            ? this.state.jobsData.map((obj, i) => {
                                            return (
                                            <>
                                                <div className="job_box">
                                                    <div className="first_col_job">
                                                        <div className="time_job">{obj.jobType}</div>
                                                        <div className="job_title">{obj.jobTitle}</div>
                                                    </div>

                                                    <div className="ex_job"><span>Experience</span>{obj.experience}</div>
                                                    <div className="ex_job"><span>location</span><FontAwesomeIcon icon={faMapMarkerAlt}/> {obj.jobLocation}</div>
                                                    <div className="job_btns">
                                                        <div className="view_job btn">
                                                            <a href={obj.jobDescription!==""?obj.jobDescription:""} style={{color:'#fff'}}>Job Description</a>
                                                        </div>
                                                        <div className="view_job apply_job btn" onClick={e => {
                                                            e.preventDefault()
                                                            this.applyButtonFunc()
                                                        }}>
                                                            Apply Now
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            )
                                        }): undefined}
                                    </div>
                                </div>
                            </div>:undefined}
                            <div className={this.myDivToFocus ? "container margin_forms" :"container"} ref={this.myDivToFocus}>
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <h2>Submit Resume</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input className="input100" id="name" type="text" name="name" placeholder="Name" onChange={this.handleChange}/>
                                                    <label className="label-job" htmlFor="name">
                                                        <FontAwesomeIcon icon={faUser}/>
                                                        <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>
                                                    </label>
                                                    <div className="red_text1">{errors && errors.name}</div>
                                                </div>
                                            </div>
                                            {/*<div className="col-lg-6">*/}
                                            {/*    <div className="form-group">*/}
                                            {/*        <input className="input100" id="name" type="text" name="name" placeholder="Sur Name"/>*/}
                                            {/*        <label className="label-job" htmlFor="name">*/}
                                            {/*            <i className="fas fa-user"></i>*/}
                                            {/*        </label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input className="input100" id="email" type="text" name="email" placeholder="Email" onChange={this.handleChange}/>
                                                    <label className="label-job" htmlFor="email">
                                                        <FontAwesomeIcon icon={faEnvelope}/>
                                                        <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>
                                                    </label>
                                                    <div className="red_text1">{errors && errors.email}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input className="input100" id="phone" type="text" name="phone" placeholder="Phone" onChange={this.handleChange}/>
                                                    <label className="label-job" htmlFor="phone">
                                                        <FontAwesomeIcon icon={faPhoneAlt}/>
                                                        <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>
                                                    </label>
                                                    <div className="red_text1">{errors && errors.phone}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input className="input100" id="postName" type="text" name="postName" placeholder="Job Type" onChange={this.handleChange}
                                                    
                                                    />
                                                    
                                                    <label className="label-job" htmlFor="postName">

                                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                                        <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>
                                                    </label>
                                                    
                                                    <div className="red_text1">{errors && errors.postName}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    
                                                    <select className="input100" id="experience" name="experience" onChange={this.handleChange}>
                                                    
                                                        <option>Select Experience Year</option>
                                                        <option value="Fresher">Fresher</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                    </select>
                                                    <label className="label-job" htmlFor="postName" >
                                                        <FontAwesomeIcon icon={faHandPointer}/>
                                                        <sup style={{ fontWeight: '700', fontSize: '14px' }}>*</sup>
                                                    </label>
                                                    <div className="red_text1">{errors && errors.experience}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <textarea className="input100" name="coverLetter" placeholder="About you" rows="6" onChange={this.handleChange}></textarea>
                                                    <label className="label-job" htmlFor="postName">

                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </label>
                                                    <div className="red_text1">{errors && errors.coverLetter}</div>

                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    {/*<input className="input-checkbox100" type="file" name="resume"/>*/}
                                                    <div className="upload-inputs1">
                                                        <label htmlFor="Upload Company Document" style={{fontSize: '16px'}}><strong>Upload Resume</strong> <sup>*</sup> <span className="red_text1">(Note : Please upload .doc, docx and pdf file only)</span></label>
                                                        <UploadFilesCloudeCareer className="custom-file-label-upload"
                                                           name="companyDocument"
                                                           placeHolder="Upload Company Document"
                                                           isSingleUpload={true}
                                                           isUploadingFiles={isUploadingFiles => {
                                                               this.setState({
                                                                   isUploadingFiles: isUploadingFiles
                                                               })
                                                           }}
                                                           onUploadFinish={(name, uploadedUrls) => {
                                                               this.setState({
                                                                   [name]: uploadedUrls
                                                               }, () => {

                                                                   if (this.state.companyDocument.length >= 1) {
                                                                       toast.success("Uploaded Successfully", {
                                                                           position: "top-left",
                                                                           autoClose: 3000,
                                                                           hideProgressBar: false,
                                                                           closeOnClick: true,
                                                                           pauseOnHover: true,
                                                                           draggable: true,
                                                                           progress: undefined,
                                                                       });
                                                                   }
                                                               })
                                                           }}/>
                                                        <div className="red_text1">{errors && errors.companyDocument}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <button type="button" onClick={this.sendMailToHr} className="btn resume_btn">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="contact-info-box">
                                            <img src="../../../assets/img/mail.png"
                                                 className="left-right" alt="Email Icon"/>
                                            <strong>Email Us</strong>
                                            <p><a href="mailto:info@zipaworld.com">info@zipaworld.com</a></p>
                                        </div>
                                        <div className="contact-info-box"><img src="../../../assets/img/call-cont.png"  alt="Contact Icon"
                                                                               className="left-right"/><strong>Call Us</strong>
                                            <p>Call us: 0120-6916910</p>

                                        </div>
                                        <div className="contact-info-box">
                                            <img src="../../../assets/img/address.png" className="left-right"  alt="Office Icon"/>
                                            <strong>Corporate Office</strong>
                                            <p>B 29, 3rd floor Sector 1, <br />Noida-201301, Uttar Pradesh
                                                <br/>Contact No: 0120-6916910</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>



                    <Modal
                        isOpen={this.state.modalLarge}
                        size="md"
                        toggle={this.toggleLarges}
                    >
                        <>
                            <ModalHeader style={{borderBottom: '1px solid #d7d7d7'}} toggle={this.toggleLarges} className="rates_heads">
                                Fill The Below Details
                            </ModalHeader>
                            <ModalBody>
                                <div className="form_jobs">
                                    <div className="form-group">
                                        <input className="input100" id="name" type="text" name="name"
                                               placeholder="Name"/>
                                        <label className="label-job" htmlFor="name">
                                            <FontAwesomeIcon icon={faUser}/>
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <input className="input100" id="email" type="text" name="email"
                                               placeholder="Email"/>
                                        <label className="label-job" htmlFor="email">
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <input className="input100" id="phone" type="text" name="phone"
                                               placeholder="Phone"/>
                                        <label className="label-job" htmlFor="phone">
                                            <FontAwesomeIcon icon={faPhoneAlt}/>
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <input className="input-checkbox100" type="file" name="resume"/>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter className="justify-content-center rates-not">
                                <button type="button" className="btn btn-secondary" onClick={e => {
                                    e.preventDefault()
                                    this.yesButtonFunc()
                                }}>Send Email</button>

                            </ModalFooter>
                        </>
                    </Modal>

                </AppLayout>
            </>
        );
    }
}
export default CareerPage;