import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import { NavLink } from "react-router-dom";
import {PAGE_PATHS} from "../../../util/PageRoute";
import RestClient from "../../../util/RestClient";
import {
    PAYMENT_SUCCESS_MAIL, PAYMENT_SUCCESS_MAIL_AIR,
    PAYMENT_SUCCESS_MAIL_COURIER,
    PAYMENT_SUCCESS_MAIL_OCEAN,
    PAYMENT_SUCCESS_MAIL_ROAD,
} from "../../../constants";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class PaymentStatusCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: "",
            toUpdate: "",

            paymentStatusShow:false,
            message:"",
            bookingid:"",
            selectNewShipper: false,
            selectNewConsignee: false,
            ResponcePayment:{}


        };

    }



    componentDidMount() {
          if(this.props.paymentStatusShow)
        {
            this.setState({
                paymentStatusShow:this.props.paymentStatusShow
            })
        }
          if(this.props.ResponcePayment)
        {
            this.setState({
                ResponcePayment:this.props.ResponcePayment
            })
        }
          if(this.props.bookingid)
        {
            this.setState({
                bookingid:this.props.bookingid
            })
        }


    }
    toggleLarge = () => {
        this.setState(prevState => ({
            paymentStatusShow: !prevState.paymentStatusShow
        }),()=>{
            if(this.state.paymentStatusShow === false){
                this.props.returnResult(true)
            }
        });
    };


    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.paymentStatusShow && this.props.paymentStatusShow !== prevProps.paymentStatusShow)
        {
            this.setState({
                paymentStatusShow:this.props.paymentStatusShow
            })
        }
        if(this.props.ResponcePayment && this.props.ResponcePayment !== prevProps.ResponcePayment)
        {
            this.setState({
                ResponcePayment:this.props.ResponcePayment
            })
        }
        if(this.props.bookingid && this.props.bookingid !== prevProps.bookingid)
        {
            this.setState({
                bookingid:this.props.bookingid
            })
        }

    }

    sendConfirmMail = async (dataForServer) => {
        return await RestClient({
            url: this.props.queryFor === "Air" ? PAYMENT_SUCCESS_MAIL_AIR : this.props.queryFor === "Ocean" ? PAYMENT_SUCCESS_MAIL_OCEAN : this.props.queryFor === "Courier" ? PAYMENT_SUCCESS_MAIL_COURIER:this.props.queryFor === "Road" ? PAYMENT_SUCCESS_MAIL_ROAD:PAYMENT_SUCCESS_MAIL,
            method: "POST",
            data: dataForServer
        });
    };

    render() {

        return (
            <>
                <Modal className="contactsss"
                    isOpen={this.state.paymentStatusShow}
                    size="md"
                    toggle={this.toggleLarge}
                >


                            <ModalHeader toggle={this.toggleLarge} style={{padding: "0"}}>
                                <NavLink to={`/`} className="white">
                                </NavLink>
                            </ModalHeader>
                            <ModalBody className="contact-later1" >
                                <div className="contect-soon">
                                    <div className="soon_text">
                                        <div className="book-imgs" style={{background: "linear-gradient(96deg, #173ebc, #ff0600)"}}>
                                            <img src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/correct.png" alt="Tick Sign"/>
                                        </div>
                                        <div className="book-text">
                                            <h5><FontAwesomeIcon icon={faCircle} style={{color:"green"}}/> Available Now </h5>
                                            <h2> Payment Successfully Done </h2>
                                            <h6> Payment ID : {Object.keys(this.state.ResponcePayment).length >=1 && this.state.ResponcePayment.result.paymentId ? this.state.ResponcePayment.result.paymentId : "" } </h6>
                                            <h6>Receiept: {Object.keys(this.state.ResponcePayment).length >=1 && this.state.ResponcePayment.result.receipt ? this.state.ResponcePayment.result.receipt : "" }</h6>
                                        </div>
                                    </div>
                                    <div className="bottom_btn">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button onClick={(e) => {
                                                    e.preventDefault()
                                                    let paymentId = this.state.ResponcePayment && this.state.ResponcePayment.result && this.state.ResponcePayment.result.paymentId ? this.state.ResponcePayment.result.paymentId :""
                                                    this.sendConfirmMail({ quoteId: this.props.quotationId,payId : paymentId }).then((idResponce) => {
                                                        if (idResponce && idResponce.success) {
                                                            this.props.returnResult(true)
                                                        }else{
                                                            this.props.returnResult(true)
                                                        }
                                                    })

                                                }} className="request_btn"> OK
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ModalBody>


                </Modal>
            </>
        );
    }
}


export default PaymentStatusCard;
