import React, {Component} from "react";

import PropTypes from 'prop-types';
import {Button, Col, Collapse, FormGroup, Input, Label, Row,CustomInput} from "reactstrap";
import CSVReader from "react-csv-reader";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import {parseFloatValue} from "../util/CommonUtility";
import {toast} from "react-toastify";
import {faMinus, faPlane, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const dimensionParseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

const formSchema = Yup.object().shape({
    length: Yup.number("Only Number").required("Required"),
    width: Yup.number().required("Required"),
    height: Yup.number().required("Required"),
    pieces: Yup.number().required("Required")
});

class DimensionViewForHouse extends Component {
    MAX_LENGTH_CM = 318;
    MAX_WIDTH_CM = 224;
    MAX_HEIGHT_CM = 168;
    MAX_LENGTH_INCH = 125.197;
    MAX_WIDTH_INCH = 88.189;
    MAX_HEIGHT_INCH = 64.1732;
    MAX_LENGTH_MM = 3180;
    MAX_WIDTH_MM = 2240;
    MAX_HEIGHT_MM = 1680;
    MAX_LENGTH_MTR = 3.18;
    MAX_WIDTH_MTR = 2.24;
    MAX_HEIGHT_MTR = 1.63;

    MAX_LENGTH_FT = 10.4331;
    MAX_WIDTH_FT = 7.34908;
    MAX_HEIGHT_FT = 5.51181;

    currentEnteredData = {totalPieces: 0, totalVolume: 0, totalGross: 0, totalChargeable: 0, totalDensity: 0};


    constructor(props) {
        super(props);

        this.state = {
            uploadedDimensions: [],
            fileDimensions: [],
            selectedDimensionType: "CM",
            dimensionCollapse: true,
            hideDescriptionBanner: true,
            hideDimensionChangeBanner: true,
            totalPieces: "0",
            grossWeight: "",
            volumeWeight: "",
            chargeableWeight: "",
            calculatedDensity: "",
            allowedPiecesOverride: false,
            allowedEdit: true,
            fileName: "Up",
            attachments: [],
            remarks: "",
            queryFor: "Ocean",
            selectWeight: "Kgs",
            actualPieces:0
        };

    }


    componentDidMount() {
        if (this.state.allowedEdit !== this.props.allowedEdit) {

            this.setState({
                allowedEdit: this.props.allowedEdit
            })
        }
        if (this.props.selectedDimensionType) {
            this.setState({
                selectedDimensionType: this.props.selectedDimensionType
            })
        }
        if (this.props.queryFor) {
            this.setState({
                queryFor: this.props.queryFor
            })
        }
        if (this.props.actualPieces) {
            this.setState({
                actualPieces: this.props.actualPieces
            })
        }
        if (this.props.dimensionArray || this.props.selectedDimensionType ) {
            this.setState({
                uploadedDimensions: this.props.dimensionArray,
                selectedDimensionType:this.props.selectedDimensionType,
                case4:this.props.case4
            },()=>{
                if (this.state.case4){
                    // this.props.dimensionOverallChanges({
                    //     weight: this.calculateOverallDimensionData(this.state.uploadedDimensions),
                    //     dimensionArray: this.state.uploadedDimensions,
                    //     selectedDimensionType: this.state.selectedDimensionType,
                    // })
                }
            })
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.allowedEdit !== this.state.allowedEdit) {
            this.setState({
                allowedEdit: this.props.allowedEdit
            })
        }
        if (this.props.actualPieces && prevProps.actualPieces !== this.props.actualPieces) {
            this.setState({
                actualPieces: this.props.actualPieces
            })
        }
        if (parseFloatValue(prevProps.totalPieces) !== parseFloatValue(this.props.totalPieces)) {
            this.setState({
                totalPieces: parseFloatValue(this.props.totalPieces),
                grossWeight: parseFloatValue(this.props.grossWeight),
                volumeWeight: parseFloatValue(this.props.volumeWeight),
                chargeableWeight: parseFloatValue(this.props.chargeableWeight),
                allowedPiecesOverride: this.props.allowedPiecesOverride,

            })
        }
        if (this.props.dimensionArray && prevProps.dimensionArray !== this.props.dimensionArray) {
            this.setState({
                uploadedDimensions: this.props.dimensionArray
            })
        }
        if (this.props.selectedDimensionType && prevProps.selectedDimensionType !== this.props.selectedDimensionType) {
            this.setState({
                selectedDimensionType: this.props.selectedDimensionType
            })
        }
        if (this.props.queryFor && prevProps.queryFor !== this.props.queryFor) {
            this.setState({
                queryFor: this.props.queryFor
            })
        }

    }

    handleUploadedDimensions = data => {
        let totalPieces = 0;
        if (!data) {
            return;
        }

        for (let i = 0; i < data.length; i++) {
            if (data[i].length && data[i].height && data[i].width && data[i].pieces) {
                try {
                    totalPieces += parseInt(data[i].pieces);
                } catch (e) {
                }
            }
        }

        if (data && parseInt(totalPieces) + parseInt(this.calculateOverallDimensionData().totalPieces) > this.state.totalPieces) {
            this.setState({
                dimensionOverload: `Uploaded Document Contains ${totalPieces}  pieces (+ ${
                    this.calculateOverallDimensionData().totalPieces
                } already Added), required only ${
                    this.state.totalPieces ? this.state.totalPieces : 0
                } `
            });
            return;
        } else {
            this.setState({
                dimensionOverload: ""
            });
        }
        let dimensions = this.state.uploadedDimensions;

        let allDimensions = dimensions.concat(data);

        this.setState({
            uploadedDimensions: allDimensions
        });
    };

    addNewDimension = async () => {
        //alert(this.state.selectWeight)
        let values ={
            length : this.state.length,
            width : this.state.width,
            height : this.state.height,
            pieces : this.state.pieces,
            gw_pc : this.state.gw_pc,
            selectWeight : this.state.selectWeight,
        }
        //alert("length"+values.length+"width"+values.width+"pieces"+values.pieces+"selectWeight"+this.state.selectWeight+"gw_pc"+values.gw_pc)
        if (values.length && values.width && values.height && values.pieces ) {

            if ((await this.isTotalPiecesExceed(values)) === true) {
                //alert("exceeded")
                return
            }else{
                //alert("Not exceeded")
            }

            await this.validateMaxDimensions(values);
            //alert("validateMaxDimensions"+await this.validateMaxDimensions(values))
            let dimensions = this.state.uploadedDimensions;

            dimensions.push(values);
            this.setState({uploadedDimensions: dimensions}, () => {
                this.props.dimensionOverallChanges({
                    weight: this.calculateOverallDimensionData(this.state.uploadedDimensions),
                    dimensionArray: this.state.uploadedDimensions,
                    selectedDimensionType: this.state.selectedDimensionType,
                })
            });
        } else {
            //alert("Heee")
        }
    };
    validateMaxDimensions = values => {

        if (this.state.selectedTariff === "oddDimensions") {
            return true;
        }
        let isValid = false;
        let length = values.length;
        let width = values.width;
        let height = values.height;

        if (this.state.selectedDimensionType === "CM") {
            if (
                length <= this.MAX_LENGTH_CM &&
                width <= this.MAX_WIDTH_CM &&
                height <= this.MAX_HEIGHT_CM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "FT") {
            if (
                length <= this.MAX_LENGTH_FT &&
                width <= this.MAX_WIDTH_FT &&
                height <= this.MAX_HEIGHT_FT
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "MM") {
            if (
                length <= this.MAX_LENGTH_MM &&
                width <= this.MAX_WIDTH_MM &&
                height <= this.MAX_HEIGHT_MM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "INCHES") {
            if (
                length <= this.MAX_LENGTH_INCH &&
                width <= this.MAX_WIDTH_INCH &&
                height <= this.MAX_HEIGHT_INCH
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "METERS") {
            if (
                length <= this.MAX_LENGTH_MTR &&
                width <= this.MAX_WIDTH_MTR &&
                height <= this.MAX_HEIGHT_MTR
            ) {
                isValid = true;
            }
        }
        if (isValid === false) {
            // toastr.success(
            //     "Dimension Exceed Values ",
            //     // "Tariff Mode Changed to Odd Dimension ",
            //     {
            //         transitionIn: "bounceIn",
            //         transitionOut: "bounceOut"
            //     }
            // );
            toast.success("Dimension Exceed Values ", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // this.changeTariffModeTo("Odd Dimention");
        }

        return isValid;
    };


    handleChange = e => {
        this.setState({[e.target.name]: e.target.value}, () => {
            // this.props.dimensionOverallChanges({
            //     weight: this.calculateOverallDimensionData(),
            //     dimensionArray: this.state.uploadedDimensions
            // })
        });
    };

    toggleDimensionCollapse = () => {
        this.setState({dimensionCollapse: !this.state.dimensionCollapse});
    };

    render() {
        return (
            <div className="AAA-editbooking-house-details-4" hidden={!this.state.allowedEdit}>
                <div className="AAA-add-query-dimension-6">
                <div className="activity_types">
                                                        <div className="form-check form-check-inline">
                                                            <CustomInput
                                                                type="radio"
                                                                id="CM"
                                                                label="CM"
                                                                name="selectedDimensionType"
                                                                value={"CM"}
                                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                checked={this.state.selectedDimensionType === "CM"}
                                                            />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <CustomInput
                                                                type="radio"
                                                                id="MM"
                                                                label="MM"
                                                                name="selectedDimensionType"
                                                                value={"MM"}
                                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                checked={this.state.selectedDimensionType === "MM"}
                                                            />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <CustomInput
                                                                type="radio"
                                                                id="INCHES"
                                                                label="INCHES"
                                                                name="selectedDimensionType"
                                                                value={"INCHES"}
                                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                checked={this.state.selectedDimensionType === "INCHES"}
                                                            />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <CustomInput
                                                                type="radio"
                                                                id="METERS"
                                                                label="METERS"
                                                                name="selectedDimensionType"
                                                                value={"METERS"}
                                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                checked={this.state.selectedDimensionType === "METERS"}
                                                            />
                                                        </div>
                                                    </div>
                    <div className="">
                            <label className="text-white">Please Enter Dimensions</label>
                        {/*<Formik hidden={!this.state.allowedEdit} validationSchema={formSchema} onSubmit={this.addNewDimension} initialValues={{}}>*/}
                        {/*    {({ errors, touched }) => (*/}
                        {/*        <Form hidden={!this.state.allowedEdit}>*/}
                        {/*            <Label>*/}
                        {/*                {this.state.dimensionOverload}*/}
                        {/*            </Label>*/}
                        {/*            <ul>*/}
                        {/*                <li>*/}
                        {/*                    <FormGroup>*/}
                        {/*                        <Label for="length">Length</Label>*/}
                        {/*                        <Field*/}
                        {/*                            type="text"*/}
                        {/*                            className="form-control ipt11"*/}
                        {/*                            name="length"*/}
                        {/*                            id="length"*/}
                        {/*                            placeholder="Length"*/}
                        {/*                            value={this.state.length}*/}
                        {/*                        />*/}
                        {/*                        {errors.length && touched.length && (*/}
                        {/*                            <div className="invalid-feedback d-block">*/}
                        {/*                                {errors.length}*/}
                        {/*                            </div>*/}
                        {/*                        )}*/}
                        {/*                    </FormGroup>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FormGroup>*/}
                        {/*                        <Label for="width">Width</Label>*/}
                        {/*                        <Field*/}
                        {/*                            type="text"*/}
                        {/*                            className="form-control ipt11"*/}
                        {/*                            name="width"*/}
                        {/*                            placeholder="Width"*/}
                        {/*                            id="width"*/}
                        {/*                            value={this.state.width}*/}
                        {/*                        />*/}
                        {/*                        {errors.width && touched.width && (*/}
                        {/*                            <div className="invalid-feedback d-block">*/}
                        {/*                                {errors.width}*/}
                        {/*                            </div>*/}
                        {/*                        )}*/}
                        {/*                    </FormGroup>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FormGroup>*/}
                        {/*                        <Label for="height">Height</Label>*/}
                        {/*                        <Field*/}
                        {/*                            type="text"*/}
                        {/*                            className="form-control ipt11"*/}
                        {/*                            name="height"*/}
                        {/*                            placeholder="Height"*/}
                        {/*                            id="height"*/}
                        {/*                            value={this.state.height}*/}
                        {/*                        />*/}
                        {/*                        {errors.height && touched.height && (*/}
                        {/*                            <div className="invalid-feedback d-block">*/}
                        {/*                                {errors.height}*/}
                        {/*                            </div>*/}
                        {/*                        )}*/}
                        {/*                    </FormGroup>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FormGroup>*/}
                        {/*                        <Label for="pieces">Pieces</Label>*/}
                        {/*                        <Field*/}
                        {/*                            type="text"*/}
                        {/*                            className="form-control ipt11"*/}
                        {/*                            name="pieces"*/}
                        {/*                            placeholder="Pieces"*/}
                        {/*                            value={this.state.pieces}*/}
                        {/*                        />*/}
                        {/*                        {errors.pieces && touched.pieces && (*/}
                        {/*                            <div className="invalid-feedback d-block">*/}
                        {/*                                {errors.pieces}*/}
                        {/*                            </div>*/}
                        {/*                        )}*/}
                        {/*                    </FormGroup>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FormGroup>*/}
                        {/*                        <Label for="Gw/Pc">Gross Weight Per Piece</Label>*/}
                        {/*                        <Field*/}
                        {/*                            type="text"*/}
                        {/*                            className="form-control ipt11"*/}
                        {/*                            name="gw_pc"*/}
                        {/*                            placeholder="Gw/Pc"*/}
                        {/*                            value={this.state.gw_pc}*/}
                        {/*                        />*/}
                        {/*                    </FormGroup>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FormGroup className="ml-1">*/}
                        {/*                        <Label> Action</Label>*/}
                        {/*                        <Button*/}
                        {/*                            type="submit"*/}
                        {/*                            color="success"*/}
                        {/*                            name="addDimension"*/}
                        {/*                            onClick={this.addNewDimension}*/}
                        {/*                        >*/}
                        {/*                            <span className="flaticon-add-2"></span>Add*/}
                        {/*                    </Button>*/}
                        {/*                    </FormGroup>*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*            <div className="clearfix"></div>*/}
                        {/*        </Form>*/}
                        {/*    )}*/}
                        {/*</Formik>*/}
                        <Formik hidden={!this.state.allowedEdit} validationSchema={formSchema}
                                onSubmit={(values, {setSubmitting, resetForm}) => {
                                    // this.addNewDimension(values).then(r => {
                                    //     resetForm({
                                    //         length: "",
                                    //         width: "",
                                    //         height: "",
                                    //         pieces: "",
                                    //         selectWeight: "",
                                    //         gw_pc: ""
                                    //     })
                                    // }).catch()

                                    setSubmitting(false);
                                }} initialValues={{
                            length: "",
                            width: "",
                            height: "",
                            pieces: "",
                            selectWeight: "",
                            gw_pc: ""
                        }}>
                            {({errors, touched}) => (
                                <Form hidden={!this.state.allowedEdit}>
                                    <div className="d-flex flex-wrap align-items-center dimension_shipper calculate-inputs ">
                                        <div className="new-originbox length-widths">
                                            <FormGroup>
                                                <Label for="length">Length</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control ipt11"
                                                    name="length"
                                                    // placeholder="length"
                                                    onChange={this.handleChange}
                                                    value={this.state.length}
                                                />
                                                {/*<Field*/}
                                                {/*    type="text"*/}
                                                {/*    className="form-control ipt11"*/}
                                                {/*    name="length"*/}
                                                {/*    id="length"*/}
                                                {/*    placeholder="Length"*/}

                                                {/*/>*/}
                                                {/*{errors.length && touched.length && (*/}
                                                {/*    <div className="invalid-feedback d-block">*/}
                                                {/*        {errors.length}*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                            </FormGroup>
                                        </div>
                                        <div className="new-originbox length-widths">
                                            <FormGroup>
                                                <Label for="width">Width</Label>
                                                {/*<Field*/}
                                                {/*    type="text"*/}
                                                {/*    className="form-control ipt11"*/}
                                                {/*    name="width"*/}
                                                {/*    placeholder="Width"*/}
                                                {/*    id="width"*/}
                                                {/*/>*/}
                                                <Input
                                                    type="text"
                                                    className="form-control ipt11"
                                                    name="width"
                                                    // placeholder="width"
                                                    onChange={this.handleChange}
                                                    value={this.state.width}
                                                />
                                                {/*{errors.width && touched.width && (*/}
                                                {/*    <div className="invalid-feedback d-block">*/}
                                                {/*        {errors.width}*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                            </FormGroup>
                                        </div>
                                        <div className="new-originbox length-widths">
                                            <FormGroup>
                                                <Label for="height">Height</Label>
                                                {/*<Field*/}
                                                {/*    type="text"*/}
                                                {/*    className="form-control ipt11"*/}
                                                {/*    name="height"*/}
                                                {/*    placeholder="Height"*/}
                                                {/*    id="height"*/}
                                                {/*/>*/}
                                                <Input
                                                    type="text"
                                                    className="form-control ipt11"
                                                    name="height"
                                                    // placeholder="height"
                                                    onChange={this.handleChange}
                                                    value={this.state.height}
                                                />
                                                {/*{errors.height && touched.height && (*/}
                                                {/*    <div className="invalid-feedback d-block">*/}
                                                {/*        {errors.height}*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                            </FormGroup>
                                        </div>
                                        <div className="new-originbox dimens-widths">
                                            <FormGroup>
                                                <Label for="pieces">Pieces</Label>
                                                {/*<Field*/}
                                                {/*    type="text"*/}
                                                {/*    className="form-control ipt11"*/}
                                                {/*    name="pieces"*/}
                                                {/*    placeholder="Pieces"*/}
                                                {/*/>*/}
                                                <Input
                                                    type="text"
                                                    className="form-control ipt11"
                                                    name="pieces"
                                                    // placeholder="pieces"
                                                    onChange={this.handleChange}
                                                    value={this.state.pieces}
                                                />
                                                {/*{errors.pieces && touched.pieces && (*/}
                                                {/*    <div className="invalid-feedback d-block">*/}
                                                {/*        {errors.pieces}*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                            </FormGroup>
                                        </div>
                                        <div className="new-originbox dimens-widths">
                                            <FormGroup>
                                                <Label for="selectWeight">Select Weight</Label>
                                                <select
                                                    id="selectWeight"
                                                    className="custom-select"
                                                    name="selectWeight"
                                                    // placeholder="Select Weight"
                                                    value={this.state.selectWeight}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="Lbs">Lbs</option>
                                                    <option value="Kgs">Kgs</option>

                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="new-originbox dimens-widths">
                                            <FormGroup>
                                                <Label for="Gw/Pc">G.Wht. Per Pcs.</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control ipt11"
                                                    name="gw_pc"
                                                    onChange={this.handleChange}
                                                    value={this.state.gw_pc}
                                                />
                                                {/*<Field*/}
                                                {/*    type="text"*/}
                                                {/*    className="form-control ipt11"*/}
                                                {/*    name="gw_pc"*/}
                                                {/*    placeholder="Gw/Pc"*/}
                                                {/*    value={this.state.gw_pc}*/}
                                                {/*/>*/}
                                            </FormGroup>
                                        </div>
                                        <button className="plus-signs" type="submit" name="addDimension" onClick={e=>{
                                            this.addNewDimension();
                                        }}><FontAwesomeIcon icon={faPlus} size={"sm"} /></button>
                                    </div>
                                    <Label>
                                        {this.state.dimensionOverload}
                                    </Label>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="">
                            <Label className="text-white" onClick={this.toggleDimensionCollapse}><strong>Entered Dimensions</strong> </Label>
                        <Collapse isOpen={this.state.dimensionCollapse}>
                            {this.state.uploadedDimensions ? this.state.uploadedDimensions.map((dimensionRow, dimensionIndex) => {
                                return (
                                        <div className="d-flex flex-wrap align-items-center dimension_shipper calculate-inputs" key={dimensionIndex}>
                                            <div className="new-originbox length-widths">
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        className="form-control ipt11"
                                                        name="length"
                                                        placeholder="Length"
                                                        readOnly
                                                        value={dimensionRow.length}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div  className="new-originbox length-widths">
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        className="form-control ipt11"
                                                        name="width"
                                                        placeholder="Width"
                                                        readOnly
                                                        value={dimensionRow.width}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div  className="new-originbox length-widths">
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        className="form-control ipt11"
                                                        name="height"
                                                        readOnly
                                                        placeholder="Height"
                                                        value={dimensionRow.height}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="new-originbox dimens-widths">
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        className="form-control ipt11"
                                                        name="pieces"
                                                        readOnly
                                                        placeholder="Pieces"
                                                        value={dimensionRow.pieces}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="new-originbox dimens-widths">
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="selectWeight"
                                                        readOnly
                                                        placeholder="Select Weight"
                                                        value={dimensionRow.selectWeight}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="new-originbox dimens-widths">
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        className="form-control ipt11"
                                                        name="gw/pc"
                                                        placeholder="Gw/Pc"
                                                        readOnly
                                                        value={dimensionRow.gw_pc}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <button className="plus-signs" type="submit" name="removeDimension" disabled={!this.state.allowedEdit} onClick={e=>{
                                                e.preventDefault();
                                                let dimension = this.state.uploadedDimensions;
                                                dimension.splice(dimensionIndex, 1);
                                                this.setState({uploadedDimensions: dimension}, () => {
                                                    this.props.dimensionOverallChanges({
                                                        weight: this.calculateOverallDimensionData(),
                                                        dimensionArray: this.state.uploadedDimensions,
                                                        selectedDimensionType: this.state.selectedDimensionType,
                                                    })
                                                });
                                            }}><FontAwesomeIcon icon={faMinus} size={"sm"} /></button>
                                            {/*<div className="widths-15">*/}
                                            {/*    <FormGroup className="ml-1">*/}
                                            {/*        <Button style={{padding: '10px 42px',background:'#ff7900!important'}}*/}
                                            {/*            disabled={!this.state.allowedEdit}*/}
                                            {/*            type="button"*/}
                                            {/*                className="delete rounded-circles btn"*/}
                                            {/*            onClick={e => {*/}
                                            {/*                e.preventDefault();*/}
                                            {/*                let dimension = this.state.uploadedDimensions;*/}
                                            {/*                dimension.splice(dimensionIndex, 1);*/}
                                            {/*                this.setState({uploadedDimensions: dimension}, () => {*/}
                                            {/*                    this.props.dimensionOverallChanges({*/}
                                            {/*                        weight: this.calculateOverallDimensionData(),*/}
                                            {/*                        dimensionArray: this.state.uploadedDimensions,*/}
                                            {/*                        selectedDimensionType: this.state.selectedDimensionType,*/}
                                            {/*                    })*/}
                                            {/*                });*/}
                                            {/*            }}*/}
                                            {/*            name="removeDimension"*/}
                                            {/*        >*/}
                                            {/*            <span className="flaticon-delete-1"></span>Remove</Button>*/}
                                            {/*    </FormGroup>*/}
                                            {/*</div>*/}
                                        </div>
                                );
                            }) : ""}
                        </Collapse>
                    </div>
                </div>
            </div>
        )
    }

    isTotalPiecesExceed(values) {
        let isInValidEntry = false;
        let addedPiecesReport = this.calculateOverallDimensionData();

        if (
            parseInt(addedPiecesReport.totalPieces) + parseInt(values.pieces) > parseInt(this.state.actualPieces)
        ) {
            if (!this.state.allowedPiecesOverride) {
                toast.error("Number Of Pieces Mismatch. " + "Please Change In Mandatory Tab", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return true;
            } else {
                toast.error("Pieces Override allowed", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // toastr.success("", "Pieces Override allowed")
            }
        }

        if (addedPiecesReport.totalPieces === 0) {
            return false;
        } else {
            let wtType=parseFloat(this.state.uploadedDimensions[0].selectWeight)
            let isNeedToAdd = parseFloat(this.state.uploadedDimensions[0].gw_pc) > 0;
            let isGrossAddedInRow = !isNaN(parseFloat(values.gw_pc));
            if (isNeedToAdd && !isGrossAddedInRow) {
                toast.success("Gross Weight if added in one of dimension. " +
                    "Required in all ", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return true;
            } else if (!isNeedToAdd && isGrossAddedInRow) {
                toast.success("Gross Weight if added in one of dimension. " +
                    "Required in all ", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return true;
            } else {
                isInValidEntry = false;
            }
        }
        return isInValidEntry;
    }

    calculateVolume = values => {
        let length = parseInt(values.length);
        let width = parseInt(values.width);
        let height = parseInt(values.height);
        let pieceCount = parseInt(values.pieces);

        if (this.state.selectedDimensionType === "CM") {
            if(this.state.queryFor=="Ocean"){
                return (length * width * height * pieceCount) / 1000000;
            }else{
                return (length * width * height * pieceCount) / 6000;
            }
        } else if (this.state.selectedDimensionType === "FT") {
            if(this.state.queryFor=="Ocean") {
                return (length * width * height * pieceCount) / 0.211888;
            }else{
                return (length * width * height * pieceCount) / 0.211888;
            }
        } else if (this.state.selectedDimensionType === "MM") {
            if(this.state.queryFor=="Ocean"){
                return (length * width * height * pieceCount) / 1000000000;
            }else{
                return (length * width * height * pieceCount) / 6000000;
            }
        } else if (this.state.selectedDimensionType === "INCHES") {
            if(this.state.queryFor=="Ocean"){
                return (length * width * height * pieceCount) / 61023.8;
            }else{
                return (length * width * height * pieceCount) / 366;
            }
        } else if (this.state.selectedDimensionType === "METERS") {
            if(this.state.queryFor=="Ocean"){
                return (length * width * height * pieceCount);
            }else{
                return (length * width * height * pieceCount) / 0.006;
            }
        }
    };


    calculateOverallDimensionData() {

        let currentEnteredData = {};
        currentEnteredData.totalPieces = 0;
        currentEnteredData.totalGross = 0;
        currentEnteredData.totalVolume = 0;
        currentEnteredData.totalChargeable = 0;

        let uploadedDimensions = this.state.uploadedDimensions;


        for (let i = 0; i < uploadedDimensions.length; i++) {
            let volume = this.calculateVolume(uploadedDimensions[i])
            if (uploadedDimensions[i].selectWeight == "Lbs" && uploadedDimensions[i].converetd==undefined) {
                uploadedDimensions[i].gw_pc = parseFloat(parseFloatValue(uploadedDimensions[i].gw_pc) * 0.453592).toFixed(2)
                uploadedDimensions[i].selectWeight = "Kgs"
                uploadedDimensions[i].converetd = true
            }
            let pieces = parseInt(uploadedDimensions[i].pieces)
            let gross = parseFloatValue(uploadedDimensions[i].gw_pc).toFixed(2) * parseInt(uploadedDimensions[i].pieces);
            currentEnteredData.totalVolume = parseFloatValue(currentEnteredData.totalVolume + volume);
            if(this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"){
                let totalGross1 = parseFloatValue(parseFloatValue(currentEnteredData.totalGross) + gross);
                currentEnteredData.totalGross = (totalGross1/1000).toFixed(2);
            }else{
                currentEnteredData.totalGross = parseFloatValue(parseFloatValue(currentEnteredData.totalGross) + gross);
            }
            currentEnteredData.totalPieces = parseInt(currentEnteredData.totalPieces + pieces);
        }
        this.setState({currentEnteredData})

        try {

            // if (currentEnteredData.totalVolume && currentEnteredData.totalGross) {
            let grossWeight = currentEnteredData.totalGross ? currentEnteredData.totalGross : 0
            let volumeWeight = currentEnteredData.totalVolume ? currentEnteredData.totalVolume : 0
            volumeWeight = parseFloatValue(volumeWeight);
            grossWeight = parseFloatValue(grossWeight);

            grossWeight > volumeWeight ?
                currentEnteredData.totalChargeable = parseFloatValue(grossWeight)
                : currentEnteredData.totalChargeable = parseFloatValue(volumeWeight)

            if (grossWeight == "0") {
                currentEnteredData.totalDensity = 0
            } else {
                let density = (volumeWeight / grossWeight) * 100;

                currentEnteredData.totalDensity = parseFloatValue(density).toFixed(2)
            }




            // }
        } catch (e) {
            //alert(e)
        }

        this.setState({
            grossWeight: currentEnteredData.totalGross,
            volumeWeight: currentEnteredData.volumeWeight,
            chargeableWeight: currentEnteredData.totalChargeable,
            calculatedDensity: currentEnteredData.calculatedDensity,
        })
        return currentEnteredData;
    }

    calculateChargeable = async () => {
        try {
            if (this.state.volumeWeight && this.state.grossWeight) {
                let volumeWeight = parseInt(this.state.volumeWeight);
                let grossWeight = parseInt(this.state.grossWeight);

                grossWeight > volumeWeight
                    ? this.setState({
                        chargeableWeight: grossWeight
                    })
                    : this.setState({
                        chargeableWeight: volumeWeight
                    });

                if (this.state.grossWeight === "0") {
                    this.setState({
                        calculatedDensity: "0"
                    });
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.setState({
                        calculatedDensity: parseFloatValue(density).toFixed(2)
                    });
                }
            }
        } catch (e) {
        }
    };

    createDynamicDimensionUi() {
        return this.state.uploadedDimensions.map((dimensionRow, dimensionIndex) => {
            {
                //  console.log(JSON.stringify(dimensionRow))
            }
            return (

                <Row className="ml-1" key={dimensionIndex}>
                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control ipt11"
                            name="length"
                            placeholder="Length"
                            readOnly
                            value={dimensionRow.length}
                        />
                    </FormGroup>
                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control ipt11"
                            name="width"
                            placeholder="Width"
                            readOnly
                            value={dimensionRow.width}
                        />
                    </FormGroup>
                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control ipt11"
                            name="height"
                            readOnly
                            placeholder="Height"
                            value={dimensionRow.height}
                        />
                    </FormGroup>

                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control ipt11"
                            name="pieces"
                            readOnly
                            placeholder="Pieces"
                            value={dimensionRow.pieces}
                        />
                    </FormGroup>
                    <FormGroup className="ml-1">
                        <select
                            id="selectWeight"
                            className="form-control"
                            name="selectWeight"
                            readOnly
                            placeholder="Select Weight"
                            value={dimensionRow.selectWeight}
                        >
                            <option value="">Select Weight Type</option>
                            <option value="Lbs">Lbs</option>
                            <option value="Kgs">Kgs</option>

                        </select>
                    </FormGroup>

                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control ipt11"
                            name="gw/pc"
                            placeholder="Gw/Pc"
                            readOnly
                            value={dimensionRow.gw_pc}
                        />
                    </FormGroup>

                    <FormGroup className="ml-1">
                        <Button
                            type="button"
                            color="danger"
                            className="form-control ipt11"
                            onClick={e => {
                                e.preventDefault();
                                let dimension = this.state.uploadedDimensions;
                                dimension.splice(dimensionIndex, 1);
                                this.setState({uploadedDimensions: dimension});
                            }}
                            name="removeDimension"
                        >
                            Remove
                        </Button>
                    </FormGroup>
                </Row>
            );
        });
    }


}

const propTypes = {

    totalPieces: PropTypes.string,
    grossWeight: PropTypes.string,
    volumeWeight: PropTypes.string,
    chargeableWeight: PropTypes.string,
    allowedEdit: PropTypes.bool,

};

const defaultProps = {
    totalPieces: 0,
    grossWeight: 0,
    volumeWeight: 0,
    chargeableWeight: 0,
    allowedEdit: true,

};

DimensionViewForHouse.propTypes = propTypes;
DimensionViewForHouse.defaultProps = defaultProps;


export default DimensionViewForHouse;

