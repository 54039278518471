import {all, call, fork, put} from 'redux-saga/effects';

import RestClient from "../../util/RestClient";

import {
    ADDS_TICKER_REQUEST,
    NEWS_TICKER_REQUEST,

} from "../actions";

import {takeLatest} from "@redux-saga/core/effects";
import { onAddsTickerRespondBackFailure, onAddsTickerRespondBackSuccess,
    onNewsTickerRespondBackFailure, onNewsTickerRespondBackSuccess,

} from "./NewsFlashAction";

import {
    GET_ADDS_TICKER,
    GET_NEWS_TICKER,
    API_END_POINT_LOCAL_AIR
} from "../../constants";
import routeRedirectTo from "../../util/routeWrapper";



export function* watchUserMaster() {
    yield takeLatest(NEWS_TICKER_REQUEST, getNewsTicker);
    yield takeLatest(ADDS_TICKER_REQUEST, getAddsTicker);
}





function* getNewsTicker({payload}) {
    try {
        const getNewsTickerResponse = yield call(getNewsTickerAsync, payload);
        if (getNewsTickerResponse.success) {
            yield put(onNewsTickerRespondBackSuccess(getNewsTickerResponse.result?getNewsTickerResponse.result.data:[]));
        } else {
            yield put(onNewsTickerRespondBackFailure(getNewsTickerResponse.message));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(onNewsTickerRespondBackFailure(error.response.data.message));
        }
    }
}

const getNewsTickerAsync = async (payload) => {
    {
        return await RestClient({
            url: API_END_POINT_LOCAL_AIR+""+GET_NEWS_TICKER,
            method: "POST",
            data: payload
        });

    }
}


function* getAddsTicker({payload}) {
    try {
        const getAddsTickerResponse = yield call(getAddsTickerAsync, payload);
        if (getAddsTickerResponse.success) {
            yield put(onAddsTickerRespondBackSuccess(getAddsTickerResponse.result?getAddsTickerResponse.result.data:[]));
        } else {
            yield put(onAddsTickerRespondBackFailure(getAddsTickerResponse.message));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(onAddsTickerRespondBackFailure(error.response.data.message));
        }
    }
}

const getAddsTickerAsync = async (payload) => {
    {
        return await RestClient({
            url: GET_ADDS_TICKER,
            method: "POST",
            data: payload
        });

    }
}
//
// function forwardTo(location) {
//     routeRedirectTo.push(location);
// }


export default function* Saga() {
    yield all([
        fork(watchUserMaster)
    ]);
}
