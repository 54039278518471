import {all, call, fork, put} from 'redux-saga/effects';

import RestClient from "../../util/RestClient";
    import {

    E_DOCKET_JOBID_MANAGER_REQUEST,
    E_DOCKET_MANAGER_DATA_REQUEST_API,
    E_DOCKET_MAWB_MANAGER_REQUEST,
    E_DOCKET_REQUEST_REQUEST_COURIER,
    E_DOCKET_REQUEST_REQUEST_ROAD,
    E_DOCKET_REQUEST_REQUEST_OCEAN,
    E_DOCKET_REQUEST_REQUEST_AIR,
} from "../../constants";

import {takeLatest} from "@redux-saga/core/effects";
import {
    E_DOCKET_MANAGER_DATA_REQUEST,
    E_DOCKET_MANAGER_REQUEST,
    E_DOCKET_REQUEST,
    E_DOCKET_MANAGER_DATA_REQUEST_AWB
} from "../actions";
import { onEdocketGetResponseSuccess, onEdocketGetResponseFailure,eDocketManagerRequestByJobIdFailure,
    eDocketManagerRequestByJobIdSuccess,eDocketManagerRequestByMawbSuccess,eDocketManagerRequestByMawbFailure,
    datafetchedJobIdFailure,datafetchedIdSuccess} from "./eDocketActions";

export function* watchGetAwbPdfActions() {
    yield takeLatest(E_DOCKET_REQUEST, getEdocumentFetchRequest);
    yield takeLatest(E_DOCKET_MANAGER_REQUEST, getManagerDatawithJobIDRequest);
    yield takeLatest(E_DOCKET_MANAGER_DATA_REQUEST, DATAFETCHEDREQUEST);
    yield takeLatest(E_DOCKET_MANAGER_DATA_REQUEST_AWB, DATAFETCHEDREQUESTmawb);
}

function* getEdocumentFetchRequest({payload}) {

    try {
        const getEdocketRequestAsyncApiResponse = yield call(getEdocumentFetchRequestAsyncApi, payload);
        if (getEdocketRequestAsyncApiResponse.success) {
            yield put(onEdocketGetResponseSuccess(getEdocketRequestAsyncApiResponse.result));
        } else {
            yield put(onEdocketGetResponseFailure(getEdocketRequestAsyncApiResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(onEdocketGetResponseFailure(error.response.data.message));
        }
    }
}

const getEdocumentFetchRequestAsyncApi = async (Payload) => {
    {
        // console.log(JSON.stringify("In Filter Sync API" + JSON.stringify(Payload)))
        return await RestClient({
            url:  Payload && Payload.quoteFor === "Air" ? E_DOCKET_REQUEST_REQUEST_AIR : Payload && Payload.quoteFor === "Ocean" ? E_DOCKET_REQUEST_REQUEST_OCEAN : Payload && Payload.quoteFor === "Road" ? E_DOCKET_REQUEST_REQUEST_ROAD : E_DOCKET_REQUEST_REQUEST_COURIER,
            method: "POST",
            data: Payload
        })
    }
};

function* getManagerDatawithJobIDRequest({payload}) {

    try {
        const getEdocketRequestAsyncApiResponse = yield call(getbyJobIDAsyncApi, payload);
        if (getEdocketRequestAsyncApiResponse.success) {
            yield put(eDocketManagerRequestByJobIdSuccess(getEdocketRequestAsyncApiResponse.result));
        } else {
            yield put(eDocketManagerRequestByJobIdFailure(getEdocketRequestAsyncApiResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(eDocketManagerRequestByJobIdFailure(error.response.data.message));
        }
    }
}

const getbyJobIDAsyncApi = async (Payload) => {
    {
        // console.log(JSON.stringify("In Filter Sync API" + JSON.stringify(Payload)))
        return await RestClient({
            url:  E_DOCKET_JOBID_MANAGER_REQUEST,
            method: "POST",
            data: Payload
        })
    }
};

function* DATAFETCHEDREQUESTmawb({payload}) {

    try {
        const getEdocketRequestAsyncApiResponse = yield call(getbyJobIDAsyncMawbApi, payload);
        if (getEdocketRequestAsyncApiResponse.success) {
            yield put(eDocketManagerRequestByMawbSuccess(getEdocketRequestAsyncApiResponse.result));
        } else {
            yield put(eDocketManagerRequestByMawbFailure(getEdocketRequestAsyncApiResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(eDocketManagerRequestByMawbFailure(error.response.data.message));
        }
    }
}

const getbyJobIDAsyncMawbApi = async (Payload) => {
    {
        // console.log(JSON.stringify("In Filter Sync API" + JSON.stringify(Payload)))
        return await RestClient({
            url:  E_DOCKET_MAWB_MANAGER_REQUEST,
            method: "POST",
            data: Payload
        })
    }
};

function* DATAFETCHEDREQUEST({payload}) {

    try {
        const getEdocketRequestAsyncApiResponse = yield call(DATAFETCHEDREQUESTAPI, payload);
        if (getEdocketRequestAsyncApiResponse.success) {
            yield put(datafetchedIdSuccess(getEdocketRequestAsyncApiResponse.result));
        } else {
            yield put(datafetchedJobIdFailure(getEdocketRequestAsyncApiResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(datafetchedJobIdFailure(error.response.data.message));
        }
    }
}

const DATAFETCHEDREQUESTAPI = async (Payload) => {
    {
        // console.log(JSON.stringify("In Filter Sync API" + JSON.stringify(Payload)))
        return await RestClient({
            url:  E_DOCKET_MANAGER_DATA_REQUEST_API,
            method: "POST",
            data: Payload
        })
    }
};



export default function* Saga() {
    yield all([
        fork(watchGetAwbPdfActions)
    ]);
}
