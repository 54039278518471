import React, {Component,createRef} from "react";
import AppLayout from "../../../layout/AppLayout";
import {PAGE_PATHS} from "../../../util/PageRoute";

class ExhibitionLandingPage extends Component {
    constructor(props) {
        super(props);
        this.scollToRef = createRef();
    }
    componentDidMount(){

    }
    render() {
        return (
            <>
                <AppLayout>
                    <div className="scroll_btn" onClick={() => this.scollToRef.current.scrollIntoView({behavior: "smooth",})}>
                        Register Now
                    </div>
                    <div className="NewHomeSection">
                        <div className="exhibitionBanner">
                            <div className="container" style={{height:'100%'}}>
                                <div className="exhibitionBannerPosition position-relative">
                                    <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8916007462104736-1699956953097.png" />
                                    <h5>Explore the Future of Logistics<br/> with Zipaworld</h5>
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <button className="exhibitionBtn mr-3" onClick={(e)=>{
                                            e.preventDefault()
                                            this.props.history.push(PAGE_PATHS.CONTACT_PAGE);
                                                                                            window.location.reload();

                                        }}>Contact Us</button>
                                        <button className="exhibitionBtn ColorDiff" onClick={(e)=>{
                                            e.preventDefault()
                                            this.props.history.push(PAGE_PATHS.PRICING_PLAN);
                                            window.location.reload();
                                        }}>Become a Member</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="exhibitionDetails">
                            <div className="container">
                                <div className="exhibitionFirstSection">
                                    <div className="exhibitionText marginEx">
                                        <h6>Join Zipaworld at</h6>
                                        <h2>IICS 2023</h2>
                                        <p>The India International Cargo Show(IICS) is set to
                                            bring together industry leaders,innovators,and
                                            logistics enthusiasts under one roof. Zipaworld is
                                            thrilled to be a part of this grand event showcasing the latest advancements and solutions in the
                                            logistics sector.
                                        </p>
                                        <button className="exhibitionBtn" onClick={(e)=>{
                                            e.preventDefault()
                                            this.props.history.push(PAGE_PATHS.AIR_FREIGHT);
                                                                                            window.location.reload();

                                        }}>Our Service</button>
                                    </div>
                                    <div className="exhibitionImage">
                                        <img className="img-fluid" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6818363896528485-1699961268903.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="exhibitionDetails backgroundColorExhibition">
                            <div className="container">
                                <div className="exhibitionMiddle">
                                    <h2>Event Highlights</h2>
                                    <p>IICS Exhibition is the only platform in the country to bring the entire cargo and logistics fraternity
                                        including air, ocean, road, logistics, warehousing, ground handling, supply chain, technology,etc.
                                        under one roof.</p>
                                    <div className="exhibitionIconsGroup">
                                        <div className="exhibitionIcon">
                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.07594318975880188-1700025520036.png" />
                                            <p>150+</p>
                                            <p>Exhibiting Brands</p>
                                        </div>
                                        <div className="exhibitionIcon">
                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.08866497947018726-1700025599438.png" />
                                            <p>12,000+</p>
                                            <p>Trade Visitors</p>
                                        </div>
                                        <div className="exhibitionIcon">
                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.28836704202228836-1700025635574.png" />
                                            <p>60+</p>
                                            <p>Experts & Speakers</p>
                                        </div>
                                        <div className="exhibitionIcon">
                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.09371892046719488-1700025660822.png" />
                                            <p>3 Days</p>
                                            <p>Exhibitions and Conference</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="exhibitionDetails">
                            <div className="container">
                                <div className="exhibitionFirstSection">
                                    <div className="exhibitionImage marginEx">
                                        <img className="img-fluid" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.21926610932839563-1699965866230.png" />
                                    </div>
                                    <div className="exhibitionText text-right textIncreaseExhibition">
                                        <h6>Pioneering Innovation</h6>
                                        <h2>in Logistics</h2>
                                        <p>Zipaworld stands at the forefront of innovation, spearheading transformative changes with in the logistics
                                            landscape. With a commitment to embracing technological advancements and redefining industry
                                            standards, we have continuously pioneered ground breaking solutions.
                                        </p>
                                        <p>Zipaworld has consistently led the way in shaping a
                                            future-ready logistics ecosystem.Our unwavering
                                            dedication to innovation has not only elevated our
                                            services but has also set new benchmarks,ensuring
                                            that businesses stay ahead in an ever-evolving
                                            market.</p>
                                        <button className="exhibitionBtn" onClick={(e)=>{
                                            e.preventDefault()
                                            this.props.history.push(PAGE_PATHS.ABOUT_PAGE);
                                            window.location.reload();

                                        }}>Know More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="exhibitionDetails backgroundColorExhibition">
                            <div className="container">
                                <div className="exhibitionMiddle">
                                    <h2>What to Expect from<br/>
                                        Zipaworld</h2>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between exhibitionMarginBottom">
                                        <div className="exhibitionDivIcon">
                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6213050844897392-1700025194871.png" />
                                        </div>
                                        <div className="exhibitionDivContent">
                                            <h3>INNOVATION UNVEILED</h3>
                                            <p>Experience first hand our cutting-edge technology and innovative
                                                logistics solutions designed to streamline operations and enhance
                                                efficiency</p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between exhibitionMarginBottom">
                                        <div className="exhibitionDivIcon exhiHideMob">
                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.7416665646061422-1700025256673.png" />
                                        </div>
                                        <div className="exhibitionDivContent text-right">
                                            <h3>EXPERT INSIGHTS</h3>
                                            <p className="text-right">Engage with Zipaworld's industry experts and gain valuable insights
                                                into the future of logistics, uncovering trends and strategies poised
                                                to revolutionize the industry</p>
                                        </div>
                                        <div className="exhibitionDivIcon exhiHideDesktop">
                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.7416665646061422-1700025256673.png" />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between exhibitionMarginBottom">
                                        <div className="exhibitionDivIcon">
                                            <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8433986625252072-1700025485712.png" />
                                        </div>
                                        <div className="exhibitionDivContent">
                                            <h3>NETWORKING OPPORTUNITIES</h3>
                                            <p>Connect with fellow logistics professionals, establish meaningful
                                                connections, and explore collaborative opportunities to drive business growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="exhibitionDetails" ref={this.scollToRef}>
                            <div className="container">
                                <div className="exhibitionMeetUs">
                                    <h2 className="text-dark">Meet Us</h2>
                                </div>
                            </div>
                        </div>
                        <div className="exhibitionDetails backgroundColorExhibition meetUsExhibition">
                            <div className="container">
                                <div className="exhibitionMiddle">
                                    <h2>Booth No - F3</h2>
                                    <h6 className="redPatchExhibition"><span>13</span> <span>14</span> <span>15</span> <span>DEC</span></h6>
                                    <h5>JIO WORLD CONVENTION CENTRE, <br/><span>BANDRA KURLA COMPLEX, MUMBAI</span></h5>
                                </div>
                            </div>
                        </div>
                        <div className="exhibitionDetails buttonExhibition">
                            <div className="container">
                                <div className="exhibitionMeetUs text-center">
                                    <a href="https://www.iicsexpo.com/registrations" target="_blank"><button>Register Here</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </AppLayout>
            </>
        )
    }
}
export default ExhibitionLandingPage;



