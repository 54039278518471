import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {history} from "../../../helpers/history";
class MailSendSuccessTrade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mailPopupSucess:false,
        };

    }



    componentDidMount() {
          if(this.props.mailPopupSucess){
            this.setState({
                mailPopupSucess:this.props.mailPopupSucess
            })
        }

    }

    toggleLarge = (e) => {
        e.preventDefault()
        history.push(PAGE_PATHS.MAIN_PAGE)
        window.location.reload("false")
    };

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.mailPopupSucess && this.props.mailPopupSucess !== prevProps.mailPopupSucess)
        {
            this.setState({
                mailPopupSucess:this.props.mailPopupSucess
            })
        }

    }

    render() {
        return (
            <>
                <Modal className="model_contact"
                    isOpen={this.state.mailPopupSucess}
                    size="md"
                    toggle={this.toggleLarge}
                >
                    <ModalHeader className="model_head" toggle={this.toggleLarge} style={{padding: "0"}}>
                    </ModalHeader>
                    <ModalBody className="contact-later1">
                        <div className="contect-soon">
                            <div className="soon_text soon_text1">
                                <div className="book-imgs1">
                                    <img src="https://test231220.s3.amazonaws.com/unTracked/s3Bucketoo0.7298441922769248-1638340215472.png" className="img-fluid" alt="mail-icon"/>
                                </div>
                                <div className="book-text">
                                    <h4>Thank you for showing your interest</h4>

                                    <h2> Your Request has been received!</h2>
                                    <h6> You shall soon hear from us.</h6>
                                </div>
                            </div>
                            <div className="bottom_btn bottom_btn1">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="request_btn" id="subscribeBtn"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    history.push(PAGE_PATHS.MAIN_PAGE)
                                                    window.location.reload("false")
                                                }}> Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}


export default MailSendSuccessTrade;
