import {all, call, fork, put} from 'redux-saga/effects';
import RestClient from "../../util/RestClient";
import {
    ADD_SPOT_RATE_DETAIL,
    GET_SPOT_RATE_DETAIL,
    GET_SPOT_RATE_TICKER,
    UPDATE_SPOT_RATE_DETAIL
} from "../../constants";

import {
    SPOT_RATE_CREATE_REQUEST,
    SPOT_RATE_DETAIL_REQUEST,
    SPOT_RATE_TICKER_REQUEST,
    SPOT_RATE_UPDATE_REQUEST,
} from "../actions";


import {
    onSpotRateCreateRespondBackFailure,
    onSpotRateCreateRespondBackSuccess,
    onSpotRateDetailRespondBackFailure,
    onSpotRateDetailRespondBackSuccess, onSpotRateTickerRespondBackFailure,
    onSpotRateTickerRespondBackSuccess,
    onSpotRateUpdateRespondBackFailure,
    onSpotRateUpdateRespondBackSuccess
} from "./spotRateActions";
import {takeLatest} from "@redux-saga/core/effects";


export function* watchSpotRateAction() {
    yield takeLatest(SPOT_RATE_CREATE_REQUEST, createSpotRate);
    yield takeLatest(SPOT_RATE_UPDATE_REQUEST, updateSpotRateDetail);
    yield takeLatest(SPOT_RATE_DETAIL_REQUEST, getSpotRateDetail);
    yield takeLatest(SPOT_RATE_TICKER_REQUEST, getSpotRateTicker);
}

function* createSpotRate({payload}) {
    try {
        const createSpotRateResponse = yield call(createSpotRateAsync, payload);
        if (createSpotRateResponse.success) {
            yield put(onSpotRateCreateRespondBackSuccess(createSpotRateResponse.message));
        } else {
            yield put(onSpotRateCreateRespondBackFailure(createSpotRateResponse.message));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(onSpotRateCreateRespondBackFailure(error.response.data.message));
        }
    }
}

const createSpotRateAsync = async (payload) => {
    {
        return await RestClient({
            url: ADD_SPOT_RATE_DETAIL,
            method: "POST",
            data: payload
        });

    }
}

function* updateSpotRateDetail({payload}) {
    try {
        const updateSpotRateResponse = yield call(updateSpotRateDetailAsync, payload);
        if (updateSpotRateResponse.success) {
            yield put(onSpotRateUpdateRespondBackSuccess(updateSpotRateResponse.result));
        } else {
            yield put(onSpotRateUpdateRespondBackFailure(updateSpotRateResponse.message));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(onSpotRateUpdateRespondBackFailure(error.response.data.message));
        }
    }
}

const updateSpotRateDetailAsync = async (payload) => {
    {
        return await RestClient({
            url: UPDATE_SPOT_RATE_DETAIL,
            method: "POST",
            data: payload
        });

    }
}

function* getSpotRateDetail({payload}) {
    try {
        const getSpotRateResponse = yield call(getSpotRateDetailAsync, payload);
        if (getSpotRateResponse.success) {
            yield put(onSpotRateDetailRespondBackSuccess(getSpotRateResponse.result));
        } else {
            yield put(onSpotRateDetailRespondBackFailure(getSpotRateResponse.message));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(onSpotRateDetailRespondBackFailure(error.response.data.message));
        }
    }
}

const getSpotRateDetailAsync = async (payload) => {
    {
        return await RestClient({
            url: GET_SPOT_RATE_DETAIL,
            method: "POST",
            data: payload
        });

    }
}

function* getSpotRateTicker({payload}) {
    try {
        const getSpotRateTickerResponse = yield call(getSpotRateTickerAsync, payload);
        if (getSpotRateTickerResponse.success) {
            yield put(onSpotRateTickerRespondBackSuccess(getSpotRateTickerResponse.result));
        } else {
            yield put(onSpotRateTickerRespondBackFailure(getSpotRateTickerResponse.message));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(onSpotRateTickerRespondBackFailure(error.response.data.message));
        }
    }
}

const getSpotRateTickerAsync = async (payload) => {
    {
        return await RestClient({
            url: GET_SPOT_RATE_TICKER,
            method: "POST",
            data: payload
        });

    }
}


export default function* Saga() {
    yield all([
        fork(watchSpotRateAction)
    ]);
}
