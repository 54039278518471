import React, {Component, Fragment} from "react";
import {
    Row,
    Card,
    CardTitle,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Container,
    CardBody,
    Col
} from "reactstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {AlertOctagon, ThumbsUp,} from "react-feather";
import {toastr} from "react-redux-toastr";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    saveRegistrationMaster,
    saveRegistrationMasterResponse
} from '../../../redux/registrationMasterRedux/registrationAction';
import CurrencyDropDownForRegistration from "../../../components/currencyDropDownForRegistration";
import SimpleReactValidator from "simple-react-validator";
import Service from './Service'
import UploadFilesAAA from "../../../components/uploadFilesCloude";
import {Field, Form, Formik} from "formik";


import {withRouter} from "react-router";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {
    API_END_POINT_LOCAL,
    CUSTOMER_DATA_FOR_POS,
    CUSTOMER_MASTER_BRANCH_UPDATE_HIT,
    GET_SINGLE_CUSTOMER_BRANCH_DATA, GET_STATE_LIST
} from "../../../constants";
import RestClient from "../../../util/RestClient";
import {getLoggedInCustomerId, getLoggedInUserId, updateLoggedInCustomerId} from "../../../util/CommonUtility";
import '../../../assets/css/style1.css';
import CountryDropDown from "../../../components/countryDropDown";
import StateDropdown from "../../../components/stateDropdown";
import {faArrowLeft, faArrowRight, faChevronCircleRight, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class GstDeatils extends Component {
    constructor(props) {

        super(props);
        this.onOriginSelected = this.onOriginSelected.bind(this);
        this.state = {
            GSTNumber: "",
            address: "",
            phoneNo: "",
            modalLarge: false,
            message: "",
            openLogin: false,
            showFiled: false,
            tab1: true,
            count: 1,
            companyDocument: "",
            customerName:"",
            customerRole:"",
            customerNo:"",
            custType:{},
            customerType:"",
            customerLogo: "",
            PanDocument: "",
            GstDocument: "",
            originId: "",
            KYCStatus: "",
            originCode: "",
            originName: "",
            customerCode: "",
            branchesInfo: [],
            accountsInfo: [{
                accountName: "",
                bankName: "",
                accountNo: "",
                ifscCode: "",
                swiftCode: "",
                ibanNo: "",
                micrCode: ""
            }],
            // balance: {},
            // address:{},
            gstdetails: {},
            defaultBuddies: {},

            ownersInfo: [{
                ownerName: "",
                ownerAddress: "",
                ownerContactNo: "",
                ownerDinNo: "",
                ownerEmail: ""
            }],
            persons: [{}],
            buddies: [{}],
            country: "",
            statelist: [],
            cintype: true,
            panNo: '',
            cinNo:"",
            aadhaarNo:"",
            custCurrency:"",
            stateCode: '',
            selectedstate:'',
            branchesName: [],
            bWb: [],
            financeMail: "",
            emailsSelect: [],
            fiananceEmailsSelect: [],
            tags: [],
            fiananceTags: [],
            customerStatus: "",
            attachments: [{}],
            isChecked: undefined
        };
        this.validator = new SimpleReactValidator({autoForceUpdate: this});
        this.validatorTab1 = new SimpleReactValidator({autoForceUpdate: this});
        this.validatorTab2 = new SimpleReactValidator({autoForceUpdate: this});
        this.validatorTab3 = new SimpleReactValidator({autoForceUpdate: this});
        this.validatorTab4 = new SimpleReactValidator({autoForceUpdate: this});
        this.validatorGstType = new SimpleReactValidator({autoForceUpdate: this});
    }

    GetCustomerData = async (dataForServer) => {
        return await RestClient({
            url: GET_SINGLE_CUSTOMER_BRANCH_DATA,
            method: "POST",
            data: dataForServer
        });
    };

    updateCustomerData = async (dataForServer) => {
        return await RestClient({
            url: CUSTOMER_MASTER_BRANCH_UPDATE_HIT,
            method: "POST",
            data: dataForServer
        });
    };


    componentDidMount() {
        this.GetCustomerData({id: getLoggedInCustomerId()}).then(customerDataFetchResult => {
                if (customerDataFetchResult && customerDataFetchResult.success) {
                    let customerDataFetch = customerDataFetchResult && customerDataFetchResult.result ? customerDataFetchResult.result : {}
                    let gstNumber = customerDataFetch && customerDataFetch.branchesInfo && customerDataFetch.branchesInfo.gstdetails && customerDataFetch.branchesInfo[0].gstdetails.gstNo ? customerDataFetch.branchesInfo[0].gstdetails.gstNo : "";

                    this.setState({
                        customerId: customerDataFetch && customerDataFetch.customerId ? customerDataFetch.customerId : "",
                        customerRole: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.customerRole ? customerDataFetch.customerData.customerRole : "",
                        customerName: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.customerName ? customerDataFetch.customerData.customerName : "",
                        customerNo: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.customerNo ? customerDataFetch.customerData.customerNo : "",
                        custType: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.custType ? customerDataFetch.customerData.custType :"",
                        customerLogo: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.customerLogo ? customerDataFetch.customerData.customerLogo :"",
                        customerCode: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.customerCode ? customerDataFetch.customerData.customerCode : "",
                        custCurrency: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.custCurrency ? customerDataFetch.customerData.custCurrency : "",
                        branchName: customerDataFetch && customerDataFetch.branchName ? customerDataFetch.branchName : "",
                        KYCStatus: customerDataFetch && customerDataFetch.kycStatusAccordingCust ? customerDataFetch.kycStatusAccordingCust : "",
                        panNo: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.panNo ? customerDataFetch.customerData.panNo : "",
                        gstpannumber: customerDataFetch && customerDataFetch.panNo ? customerDataFetch.panNo : "",
                        cinNo: customerDataFetch && customerDataFetch.cinNo ? customerDataFetch.cinNo : "",
                        bWb: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.businessWithBranch ? customerDataFetch.customerData.businessWithBranch : "",
                        streetAddress: customerDataFetch && customerDataFetch.streetAddress ? customerDataFetch.streetAddress : "",
                        address: customerDataFetch && customerDataFetch.address ? customerDataFetch.address : "",
                        city: customerDataFetch && customerDataFetch.city ? customerDataFetch.city : "",
                        customerStatus: customerDataFetch && customerDataFetch.customerStatus ? customerDataFetch.customerStatus : "",
                        gstNo: customerDataFetch && customerDataFetch.gstNo ? customerDataFetch.gstNo : "",
                        countrySelect: customerDataFetch && customerDataFetch.country ? customerDataFetch.country : "",
                        selectedstate: customerDataFetch && customerDataFetch.stateId ? customerDataFetch.stateId : "",
                        pin: customerDataFetch && customerDataFetch.pin ? customerDataFetch.pin : "",
                        state: customerDataFetch && customerDataFetch.state ? customerDataFetch.state : "",
                        customerType: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.customerType ? customerDataFetch.customerData.customerType : "",
                        emailsSelect: customerDataFetch && customerDataFetch.emails ? customerDataFetch.emails : [],
                        fiananceEmailsSelect: customerDataFetch && customerDataFetch.financeMail ? customerDataFetch.financeMail : [],
                        stateCode: customerDataFetch && customerDataFetch.stateCode ? customerDataFetch.stateCode : "",
                        stateId: customerDataFetch && customerDataFetch.stateId ? customerDataFetch.stateId : "",
                        gststatecode: customerDataFetch && customerDataFetch.stateCode ? customerDataFetch.stateCode : "",
                        gstnumber: customerDataFetch && customerDataFetch.gstNo ? customerDataFetch.gstNo.substring(12) : "",
                        gstType: customerDataFetch && customerDataFetch.gstType ? customerDataFetch.gstType : "",
                        tan: customerDataFetch && customerDataFetch.tan ? customerDataFetch.tan : "",
                        aadhaarNo: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.aadhaarNo ? customerDataFetch.customerData.aadhaarNo : [],
                        iecCode: customerDataFetch && customerDataFetch.iecCode ? customerDataFetch.iecCode : "",
                        persons:customerDataFetch && customerDataFetch.persons  ? customerDataFetch.persons : [],
                        // ownerInfo:customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.ownersInfo  ? customerDataFetch.customerData.ownersInfo : [],
                        buddies:customerDataFetch && customerDataFetch.buddies  ? customerDataFetch.buddies : [],
                        // accountsInfo:customerDataFetch && customerDataFetch.accountsInfo  ? customerDataFetch.accountsInfo : [],
                        accountsInfo: customerDataFetch && customerDataFetch.accountsInfo[0] && customerDataFetch.accountsInfo.length >= 1 ? customerDataFetch.accountsInfo : [{
                            accountName: "",
                            bankName: "",
                            accountNo: "",
                            ifscCode:"",
                            swiftCode: "",
                            ibanNo: "",
                            micrCode: "",
                        }],
                        ownersInfo: customerDataFetch && customerDataFetch.customerData && customerDataFetch.customerData.ownersInfo[0] && customerDataFetch.customerData.ownersInfo.length >= 1 ? customerDataFetch.customerData.ownersInfo : [{
                            ownerName: "",
                            ownerAddress: "",
                            ownerContactNo: "",
                            ownerDinNo: "",
                            ownerEmail: "",
                        }],
                        // persons: customerDataFetch && customerDataFetch.persons[0] && customerDataFetch.persons.length >= 1 ? customerDataFetch.persons : [{
                        //     contactPersonName:customerDataFetch && customerDataFetch.persons && customerDataFetch.persons.contactPersonName ? customerDataFetch.persons.contactPersonName : "",
                        //     contactPersonEmail:customerDataFetch && customerDataFetch.persons && customerDataFetch.persons.contactPersonEmail ? customerDataFetch.persons.contactPersonEmail : "",
                        //     department:customerDataFetch && customerDataFetch.persons && customerDataFetch.persons.department ? customerDataFetch.persons.department : "",
                        //     mobileNo:customerDataFetch && customerDataFetch.persons && customerDataFetch.persons.mobileNo ? customerDataFetch.persons.mobileNo : "",
                        //     landlineNo:customerDataFetch && customerDataFetch.persons && customerDataFetch.persons.landlineNo ? customerDataFetch.persons.landlineNo : "",
                        //     userName:customerDataFetch && customerDataFetch.persons && customerDataFetch.persons.userName ? customerDataFetch.persons.userName : "",
                        //     password:customerDataFetch && customerDataFetch.persons && customerDataFetch.persons.password ? customerDataFetch.persons.password : "",
                        // }],
                        // buddies: customerDataFetch && customerDataFetch.buddies[0] && customerDataFetch.buddies.length >= 1 ? customerDataFetch.buddies : [{
                        //     originId:customerDataFetch && customerDataFetch.buddies && customerDataFetch.buddies.originId ? customerDataFetch.buddies.originId : "",
                        //     csBuddy:customerDataFetch && customerDataFetch.buddies && customerDataFetch.buddies.csBuddy ? customerDataFetch.buddies.csBuddy : "",
                        //     csBuddy2:customerDataFetch && customerDataFetch.buddies && customerDataFetch.buddies.csBuddy2 ? customerDataFetch.buddies.csBuddy2 : "",
                        //     pricingHead:customerDataFetch && customerDataFetch.buddies && customerDataFetch.buddies.pricingHead ? customerDataFetch.buddies.pricingHead : "",
                        //     salesPerson:customerDataFetch && customerDataFetch.buddies && customerDataFetch.buddies.salesPerson ? customerDataFetch.buddies.salesPerson : "",
                        //     financePerson:customerDataFetch && customerDataFetch.buddies && customerDataFetch.buddies.financePerson ? customerDataFetch.buddies.financePerson : "",
                        // }],
                    }, () => {
                        this.getState(this.state.countrySelect).then(r => {
                        })
                        this.props.KYCStatus(this.state.KYCStatus)
                    })
                }

            }
        )
        // let data = JSON.parse(localStorage.getItem("customer")).result
        // this.setState({
        //     customerName :data && data.userName ? data.userName : "" ,
        //     customerCode : data && data.customerCode ? data.customerCode : "" ,
        //     branchName : data && data.branch  && data.branch.branchName ? data.branch.branchName : "",
        //     custCurrency: data && data.custCurrency ? data.custCurrency : "" ,
        // })

        if (this.props.modalLarge) {

            this.setState({
                modalLarge: this.props.modalLarge
            })
        }

    }

    onCurrencySelected = ({currencyCode}) => {
        this.setState({
            custCurrency: currencyCode
        });
    };
    onOriginSelected = ({originName, originCode, originId}, i) => {
        // debugger
        //  console.log("Handing::" + JSON.stringify(originCode));
        this.setState({
            originName: originName,
            originCode: originCode,
            originId: originId
        }, () => {
            let buddies = [...this.state.buddies];
            buddies[i] = {...buddies[i], originName: originName};
            buddies[i] = {...buddies[i], originCode: originCode};
            buddies[i] = {...buddies[i], originId: originId};
            this.setState({buddies});
        });
    };

    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }), () => {
            if (this.state.modalLarge === false) {
                this.props.setmodal(false)
            }
        });
    };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }

    validategst = (value) => {

        if (!value) {
            toast.error("Please enter GST number properly", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        this.setState({
            GSTNumber: ""
        })
    }

    validateAddress = (value) => {

        if (!value) {
            toast.error("Please enter address Properly", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.setState({
                address: ""
            })
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {

            this.setState({
                modalLarge: this.props.modalLarge

            })
        }


    }

    // handleChange = selectedOption => {
    //
    //     this.setState({selectedOption});
    //
    // };

    handleSubmit = () => {

        let data = {
            GSTNumber: this.state.gstpannumber + this.state.gststatecode + this.state.gstnumber,
            address: this.state.address,
        }
        localStorage.setItem("GSTAddress", JSON.stringify(data))
        this.props.recivedContactInfo(data)
        this.setState({
            modalLarge: false
        })
        this.props.setmodal(false)

    }

    jumpToDashboard = () => {
        this.props.history.push(PAGE_PATHS.CREATE_INVOICE);
    };

    handleNormalSubmit = () => {

        this.props.normalClose(true)
        this.setState({
            modalLarge: false
        })
        this.props.setmodal(false)
    }

    handleChange = (e) => {

        if (e.target.name == "countrySelect") this.setState({[e.target.name]: e.target.value}, () => {
            this.getState(this.state.countrySelect)
        })
        else if (e.target.name == "cintype") {
            if (this.state.cintype == true) this.setState({cintype: false})
            else this.setState({cintype: true})
        } else this.setState({[e.target.name]: e.target.value})
    }
    handleBind = (e) => {

        if (e.target.name == "panNo") this.setState({panNo: e.target.value}, () => {
            this.setState({gstpannumber: this.state.panNo})
        })
        else this.setState({stateCode: e.target.value}, () => {
            this.setState({gststatecode: this.state.stateCode})
        })
    }

    handleChangeChecked = async (e, value) => {
        if (e.target.checked) {

            const newbranch = this.state.bWb
            newbranch.push(value._id)
            // console.log("bwbbbbb state", newbranch)
            await this.setState({bWb: newbranch})
            // console.log("stateeeeeeee of add customer", this.state)
        } else {

            const popId = this.state.bWb.indexOf(value._id);
            let bWb = [...this.state.bWb];
            bWb.splice(popId, 1);
            this.setState({bWb});

        }

    }
    deleteEmails = (i, e) => {
        let emailsSelect = [...this.state.emailsSelect];
        emailsSelect.splice(i, 1);
        this.setState({emailsSelect});
        e.preventDefault();
    };

    deleteFiananceEmails = (i, e) => {
        let fiananceEmailsSelect = [...this.state.fiananceEmailsSelect];
        fiananceEmailsSelect.splice(i, 1);
        this.setState({fiananceEmailsSelect});
        e.preventDefault();
    };
    validateEmail = email => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    handleChangeCSBuddy = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => {
            this.checkBuddy()
        })
    }
    handleChangeCSBuddyOrigin = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => {
            this.checkBuddyOrigin()
        })
    }

    checkBuddy = () => {
        if (this.state.defaultCSBuddy1 === this.state.defaultCSBuddy2) {
            toastr.error("CSBuddy1/CSBuddy2 can't  be same");
        }
    };
    handleEmail = (event) => {
        this.setState({[event.target.name]: event.target.value})
        if (event.keyCode === 13) {
            if (this.validateEmail(event.target.value)) {
                const tags = [...this.state.emailsSelect]
                tags.push(this.state.emails);

                this.setState({emailsSelect: tags, emails: ''});

            } else {
                toastr.error("Emails is not Valid");
            }
        }
    };

    handleFiananceEmail = (event) => {
        this.setState({[event.target.name]: event.target.value})
        if (event.keyCode === 13) {
            if (this.validateEmail(event.target.value)) {
                const tags = [...this.state.fiananceEmailsSelect]
                tags.push(this.state. financeMail);

                this.setState({fiananceEmailsSelect: tags,  financeMail: ''});

            } else {
                toastr.error("Emails is not Valid");
            }
        }
    };
    getState = async (id) => {

        const body = {
            countryId: id
        }
        this.GetStateData(body).then(responce => {
            if(responce.success){
                this.setState({statelist: responce && responce.result ? responce.result : [] })
            }
        })
        // let _r = await Service.location.getstateForRegistration(body)
        // console.log("statecountry", _r)

    }
    GetStateData = async (dataForServer) => {
        return await  RestClient({
            url : GET_STATE_LIST,
            method: "POST",
            data: dataForServer
        });
};
    check = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    saveImages = (e) => {
        const image = [];
        var t = e.target.files;
        for (let i = 0; i < t.length; i++) {
            image.push(t[i])
        }
        this.setState({[e.target.name]: image})
    }
    handleBankDetail = (e, i) => {
        const {name, value} = e.target;
        let accountsInfo = [...this.state.accountsInfo];
        accountsInfo[i] = {...accountsInfo[i], [name]: value};
        this.setState({accountsInfo});
    }
    handleOwnerDetail = (e, i) => {
        const {name, value} = e.target;
        let ownersInfo = [...this.state.ownersInfo];
        ownersInfo[i] = {...ownersInfo[i], [name]: value};
        this.setState({ownersInfo});
    }
    originBasedPriceHandle = (e, i) => {
        const {name, value} = e.target;
        let buddies = [...this.state.buddies];
        buddies[i] = {...buddies[i], [name]: value};
        this.setState({buddies});
    }
    originBasedPriceHandleCheck = (e, i) => {
        const {name, value} = e.target;
        let buddies = [...this.state.buddies];
        buddies[i] = {...buddies[i], [name]: value};
        this.setState({buddies}, () => this.checkOriginBuddy(i));
    }
    checkOriginBuddy = (i) => {
        if (this.state.buddies[i].csBudddy === this.state.buddies[i].csBuddy2) {
            toastr.error("CSBuddy1/CSBuddy2 can't  be same");
        } else {

        }
    }
    AddBankDetail = () => {
        this.setState(prevState => ({
            accountsInfo: [...prevState.accountsInfo, {
                accountName: "",
                bankName: "",
                accountNo: "",
                ifscCode:"",
                swiftCode: "",
                ibanNo: "",
                micrCode: "",
            }]
        }))
    }
    RemoveBankDetail = (i) => {
        let accountsInfo = [...this.state.accountsInfo];
        accountsInfo.splice(i, 1);
        this.setState({accountsInfo});
    }
    AddOwnerDetail = () => {
        this.setState(prevState => ({
            ownersInfo: [...prevState.ownersInfo, {
                ownername: '',
                owneraddress: '',
                contactnumber: '',
                dinnumber: '',
                emailId: ''
            }]
        }))
    }
    RemoveOwnerDetail = (i) => {
        let ownersInfo = [...this.state.ownersInfo];
        ownersInfo.splice(i, 1);
        this.setState({ownersInfo});
    }
    handleContactDetail = (e, i) => {
        const {name, value} = e.target;
        let persons = [...this.state.persons];
        persons[i] = {...persons[i], [name]: value};
        this.setState({persons});
    }
    AddContactDetail = () => {
        this.setState(prevState => ({
            persons: [...prevState.persons, {
                password: '',
                confirmpassword: '',
                invoiceShipperShown: '',
                invoiceConsigneeShown: '',
                invoicePaymentTermShown: '',
                invoiceInvoiceDueDateShown: '',
                revertedRatesShown: '',

                contactPersonName: '',
                contactPersonEmail: '',
                department: '',
                mobileNo: '',
                landlineNo: ''
            }]
        }))
    }
    RemoveContactDetail = (i) => {
        let persons = [...this.state.persons];
        persons.splice(i, 1);
        this.setState({persons});
    }
    AddOriginBasedtDetail = () => {
        //  debugger
        this.setState(prevState => ({
            buddies: [...prevState.buddies, {
                originId: '',
                originName: '',
                originCode: '',
                pricingHead: '',
                salespersonfinal: '',
                financePerson: '',
                originselect: ""
            }]
        }))

    }
    RemoveOriginBasedDetail = (x) => {
        let buddies = [...this.state.buddies];
        buddies.splice(x, 1);
        this.setState({buddies});
    }
    Submit = async () => {

        let custBranchId = await updateLoggedInCustomerId()
        // alert(custBranchId)
        const body = {

            "customerName": this.state.customerName,
            "toUpdate": custBranchId,
            "customerCode": this.state.customerCode,
            "customerRole": this.state.customerRole,
            "customerType": this.state.customerType,
            "customerNo" : this.state.customerNo,
            "custType" : true,
            "customerId": this.state.customerId,
            "panNo": this.state.panNo,
            "cinNo": this.state.cinNo,
            "branchName": this.state.branchName,
            "emails": this.state.emailsSelect,
            "kycStatusAccordingCust": true,
            "financeMail": this.state.fiananceEmailsSelect,
            "streetAddress": this.state.streetAddress,
            "address": this.state.address,
            "city": this.state.city,
            "state": this.state.selectedstate,
            "stateId": this.state.stateId,
            "country": this.state.countrySelect,
            "countryName": this.state.shipperCountryName,
            "pin": this.state.pin,
            "stateCode": this.state.stateCode,
            "gstNo": this.state.gststatecode + this.state.gstpannumber + this.state.gstnumber,
            "gstType": this.state.gstType,
            "tan": this.state.tan,
            "iecCode": this.state.iecCode,
            accountsInfo: this.state.accountsInfo,
                // [{
                // "accountsName": this.state.accountsName,
                // "bankName": this.state.bankName,
                // "accountNo": this.state.accountNo,
                // "ifscCode": this.state.ifscCode,
                // "swiftCode": this.state.swiftCode,
                // "ibanNo": this.state.ibanNo,
                // "micrCode": this.state.micrCode,
                // }],
            buddies: [{
                "originId": this.state.originId,
                "csBuddy": this.state.csBuddy,
                "csBuddy2": this.state.csBuddy2,
                "pricingHead": this.state.pricingHead,
                "salesPerson": this.state.salesPerson,
                "financePerson": this.state.financePerson,
            }],
            "csBuddy": this.state.csBuddy,
            "csBuddy2": this.state.csBuddy2,
            "pricingHead": this.state.pricingHead,
            "financePerson": this.state.financePerson,
            "salesPerson": this.state.salesPerson,
            "creditDays": this.state.creditDays,
            "creditLimit": this.state.creditLimit,
            "creditLimitAllowed": this.state.creditLimitAllowed,
            // "kycStatusAccordingCust": this.state. kycStatusAccordingCust,
            "kycStatus": this.state.kycStatus,
            "securityCheck": this.state.securityCheck,
            "businessWithBranch": this.state.bWb,
            "customerCategory": this.state.customerCategory,
            "customerStatus": this.state.customerStatus,

            persons: [{
                "contactPersonName": this.state.contactPersonName,
                "contactPersonEmail": this.state.contactPersonEmail,
                "department": this.state.department,
                "mobileNo": this.state.mobileNo,
                "landlineNo": this.state.landlineNo,
                "userName": this.state.userName,
                "password": this.state.password,
            }],
            "revertedRatesShown": this.state.revertedRatesShown,
            "invoiceShipperShown": this.state.invoiceShipperShown,
            "invoiceConsigneeShown": this.state.invoiceConsigneeShown,
            "invoicePaymentTermShown": this.state.invoicePaymentTermShown,
            "invoiceInvoiceDueDateShown": this.state.invoiceInvoiceDueDateShown,
            "outstandingAmountShown": this.state.outstandingAmountShown,
            "invoiceSalesApproval": this.state.invoiceSalesApproval,
            "financeDueDate": this.state.financeDueDate,
            "showOutstandingType": this.state.showOutstandingType,
            "getInvoices": this.state.getInvoices,
            "openingBalance": this.state.openingBalance,
            "overseasOpeningBalance": this.state.overseasOpeningBalance,
            "openingBalanceDate": this.state.openingBalanceDate,


            // defaultBuddies: {
            //     "csBuddy": this.state.defaultCSBuddy1,
            //     "csBuddy2": this.state.defaultCSBuddy2,
            //     "pricingHead": this.state.defaultPricingHead,
            //     "financePerson": this.state.defaultFinancePerson,
            //     "salesPerson": this.state.defaultSalesPerson,
            // },
            // "creditDays": this.state.creditDays,
            // "creditLimit": this.state.creditLimit,
            // "securityCheck": this.state.securityCheck,
            // "balance": {
            //     "openingBalance": this.state.openingBalance,
            //     "overseasOpeningBalance": this.state.overseasOpeningBalance,
            //     "openingBalanceDate": this.state.openingBalanceDate,
            // },
            // "getInvoices": this.state.getInvoices,
            // "invoiceSalesApproval": this.state.invoiceSalesApproval,
            // "financeDueDate": this.state.financeDueDate,
            // "showOutstandingType": this.state.showOutstandingType,


            ownersInfo:this.state.ownersInfo,
            //     [{
            //     "ownerName": this.state.ownerName,
            //     "ownerAddress": this.state.ownerAddress,
            //     "ownerContactNo": this.state.ownerContactNo,
            //     "ownerDinNo": this.state.ownerDinNo,
            //     "ownerEmail": this.state.ownerEmail,
            // }],
            "aadhaarNo": this.state.aadhaarNo,
            "custCurrency": this.state.custCurrency,
            "attachments": [
                {
                    "title": "Company Document",
                    "url": this.state.companyDocument
                },
                {
                    "title": "Pan Document",
                    "url": this.state.PanDocument
                },
                {
                    "title": "GST Document",
                    "url": this.state.GstDocument
                }
            ],
            "customerLogo": this.state.customerLogo,
            "isChecked": this.state.isChecked,
        }


        if (this.state.isChecked === true) {
            // this.props.saveRegistrationMaster(body)

            let r = await RestClient({
                url: API_END_POINT_LOCAL + CUSTOMER_MASTER_BRANCH_UPDATE_HIT,
                method: "POST",
                data: body
            })

            if (r.success === true) {

                toast.success("Submit Successfully", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.handleSubmit()
                this.props.checkKYC(true)
            } else {

                toast.error(r.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            console.log(JSON.stringify(this.props.saveRegistrationMasterResponse))
        } else if (this.state.isChecked === false) {
            toast.error("you are not agree to  Term's and conditions please check", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


        // this.updateCustomerData().then(async customerDataFetchResult => {
        //     if (customerDataFetchResult.success) {
        //         let customerDataFetch = customerDataFetchResult && customerDataFetchResult.result ? customerDataFetchResult.result : {}
        //         let gstNumber = customerDataFetch && customerDataFetch.branchesInfo && customerDataFetch.branchesInfo.gstdetails && customerDataFetch.branchesInfo[0].gstdetails.gstNo ? customerDataFetch.branchesInfo[0].gstdetails.gstNo : "";
        //         if (Array.isArray(this.state.aadhaarNo) && this.state.aadhaarNo.length >= 1) {
        //             const _self = this;
        //             const adhaarSplit = Array.isArray(_self.state.aadhaarNo) ? _self.state.aadhaarNo : _self.state.aadhaarNo.split(",");
        //             await _self.setState({aadhaarNo: adhaarSplit})
        //         }
        //     }
        // })
    }
    Next = (value) => {
        switch (value) {
            case 1:
                if (this.validatorTab1.allValid()) {

                    this.setState({
                        tab1: false,
                        tab2: true,
                        tab3: false,
                        tab4: false,
                        // tab5: false,
                        // tab6: false,
                        count: this.state.count + 1
                    })
                } else {
                    this.validatorTab1.showMessages();
                    this.forceUpdate();
                }
                break;
            case 2:
                if (this.state.gstType == "S-SEZ" || this.state.gstType == "T-Taxable" || this.state.gstType == "U-UIN") {

                    if (this.validatorTab2.allValid() && this.validatorGstType.allValid()) {

                        this.setState({
                            tab1: false,
                            tab2: false,
                            tab3: true,
                            tab4: false,
                            // tab5: false,
                            // tab6: false,
                            count: this.state.count + 1
                        })
                    } else {
                        this.validatorTab2.showMessages();
                        this.validatorGstType.showMessages();
                        this.forceUpdate();
                    }
                } else if (this.validatorTab2.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: true,
                        tab4: false,
                        // tab5: false,
                        // tab6: false,
                        count: this.state.count + 1
                    })
                } else {
                    this.validatorTab2.showMessages();
                    this.forceUpdate();
                }
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: true,
                    // tab5: false,
                    // tab6: false,
                    count: this.state.count + 1
                })
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    // tab5: true,
                    // tab6: false,
                    count: this.state.count + 1
                })
                break;
            case 5:
                if (this.validatorTab3.allValid()) {

                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: false,
                        tab4: false,
                        // tab5: false,
                        // tab6: true,
                        count: this.state.count + 1
                    })
                } else {
                    this.validatorTab3.showMessages();
                    this.forceUpdate();
                }
                break;
            default:
        }

    }
    Prev = (value) => {
        switch (value) {
            case 2:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    // tab5: false,
                    // tab6: false,
                    count: this.state.count - 1
                })
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: true,
                    tab3: false,
                    tab4: false,
                    // tab5: false,
                    // tab6: false,
                    count: this.state.count - 1
                })
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: true,
                    tab4: false,
                    // tab5: false,
                    // tab6: false,
                    count: this.state.count - 1
                })
                break;
            // case 5:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: true,
            //         // tab5: false,
            //         // tab6: false,
            //         count: this.state.count - 1
            //     })
            //     break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: true,
            //         tab6: false,
            //         count: this.state.count - 1
            //     })
            //     break;
            default:
        }

    }
    Select = (value) => {
        switch (value) {
            case 1:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    // tab5: false,
                    // tab6: false,
                    count: value
                })
                break;
            case 2:
                this.setState({
                    tab1: false,
                    tab2: true,
                    tab3: false,
                    tab4: false,
                    // tab5: false,
                    // tab6: false,
                    count: value
                })
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: true,
                    tab4: false,
                    // tab5: false,
                    // tab6: false,
                    count: value
                })
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: true,
                    // tab5: false,
                    // tab6: false,
                    count: value
                })
                break;
            // case 5:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: true,
            //         tab6: false,
            //         count: value
            //     })
            //     break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: false,
            //         tab6: true,
            //         count: value
            //     })
            //     break;
            default:
        }
    }

    validateAdharNumber = (value) => {

        if (!value) {
            toast.error("Please enter your Adhar Number", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(value)) {

            toast.error("Adhar Number Should be Valid", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                aadhaarNo: ""
            })
        }

    }
    validatePanNumber = (value) => {
        if (!value) {
            toast.error("Please enter your PAN Number", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(value)) {

            toast.error("PAN Number Should be Valid", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                panNo: ""
            })
        }

    }
    onCountrySelected = ({country, countryCode, countryId}) => {
        // console.log("onCountrySelected::" + JSON.stringify(countryCode));
        this.setState({
            shipperCountryName: country,
            shipperCountryCode: countryCode,
            shipperCountryId: countryId,
            countrySelect: countryId,
        });
    };
    onStateSelected = ({stateName, stateCode, stateId}) => {
        // console.log("onStateSelected::" + JSON.stringify(stateCode));
        // alert(stateCode)
        this.setState({
            shipperStateName: stateName,
            stateCode: stateCode,
            stateId: stateId,
            selectedstate:stateId,
        });
    };

    render() {
        const {password, email, repassword, customerName, gstNo, phoneNo, selectedState} = this.state;
        const initialValues = {email, password, repassword, customerName, gstNo, phoneNo, selectedState};

        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Modal
                    // className="contactsize"
                    isOpen={this.state.modalLarge}
                    size="lg"
                    toggle={this.toggleLarge}
                >

                    <>
                        <ModalHeader toggle={this.toggleLarge} className="" style={{padding: "0", border: "none"}}>
                            {/*<NavLink to={`/`} className=" white">*/}
                            {/*    /!*<span className="logo-single" style={{marginBottom: "-9px"}}/>*!/*/}
                            {/*</NavLink>*/}
                        </ModalHeader>
                        <ModalBody className="blue-gst1" style={{background: "#f5f5f5", padding: "0px"}}>
                            {/*<div className="back-img" style={{padding: "0px"}}>*/}
                            {/*    <div className="form-side">*/}

                            {/*            /!*<h5 className="mb-5  text-center" style={{fontWeight: "bold",color:"#fff"}} >*!/*/}
                            {/*            /!*    <span>CONTACT INFORMATION</span></h5>*!/*/}
                            {/*                <div className="custom-control custom-checkbox mb-3">*/}
                            {/*                    <input type="checkbox" className="custom-control-input"*/}
                            {/*                           id="customCheck1"*/}

                            {/*                           checked ={undefined}*/}
                            {/*                           onChange ={e=>{*/}
                            {/*                               e.target.checked === true ? this.setState({showFiled : true}) : this.setState({showFiled : false})*/}
                            {/*                             }}*/}
                            {/*                    />*/}
                            {/*                        <label className="custom-control-label font-weight-bold"*/}
                            {/*                               htmlFor="customCheck1" style={{fontSize:"18px",color: "#173ebc"}}>Do you want GST invoice ?*/}
                            {/*                        </label>*/}
                            {/*                </div>*/}
                            {/*            <form className="av-tooltip tooltip-label-bottom" >*/}

                            {/*                <div className="form-group " hidden={this.state.showFiled === false}><label*/}
                            {/*                    className="font-weight-bold"><span>GST Number</span></label>*/}
                            {/*                    <Input type="text" className="form-control"*/}
                            {/*                           placeholder="GST Type"*/}
                            {/*                           name="GSTNumber"*/}
                            {/*                           value={this.state.GSTNumber}*/}
                            {/*                           onBlur={e=>{this.validategst(e.target.value)}}*/}
                            {/*                           onChange={e=>{this.setState({GSTNumber: e.target.value})} }*/}
                            {/*                    />*/}
                            {/*                </div>*/}

                            {/*                <div className="form-group" hidden={this.state.showFiled === false}><label*/}
                            {/*                    className="font-weight-bold"><span>Address</span></label>*/}
                            {/*                    <Input type="text" className="form-control"*/}
                            {/*                           placeholder="Address"*/}
                            {/*                           name="address"*/}
                            {/*                           value={this.state.address}*/}
                            {/*                           onBlur={e=>{this.validateAddress(e.target.value)}}*/}
                            {/*                           onChange={e=>{this.setState({address: e.target.value})} }*/}
                            {/*                    />*/}
                            {/*                </div>*/}
                            {/*                <div className="form-group mt-4">*/}

                            {/*                    <button className="book-btn btn " style={{borderRadius: "50px;"}}*/}
                            {/*                    onClick={e=>{*/}
                            {/*                        e.preventDefault()*/}
                            {/*                        this.state.showFiled === true  ?*/}
                            {/*                    this.handleSubmit() : this.handleNormalSubmit()*/}
                            {/*                    }}*/}
                            {/*                    >*/}
                            {/*                        Submit*/}
                            {/*                    </button>*/}
                            {/*                </div>*/}
                            {/*            </form>*/}
                            {/*        </div>*/}
                            {/*</div>*/}

                            <Fragment>

                                <div style={{}}>

                                    <CardBody>
                                        <div className="mb-3">

                                            <Button onClick={() => this.Select(1)}
                                                    className={this.state.tab1 ? "gst-btn gst-top " : "gst-btn"}>
                                                Company detail
                                            </Button> {" "}


                                            <Button onClick={() => this.Select(2)}
                                                    className={this.state.tab2 ? "gst-btn gst-top" : "gst-btn"}>Tax
                                                detail
                                            </Button>{" "}


                                            <Button onClick={() => this.Select(3)}
                                                    className={this.state.tab3 ? "gst-btn gst-top" : "gst-btn"}>Bank
                                                detail
                                            </Button>{" "}


                                            <Button onClick={() => this.Select(4)}
                                                    className={this.state.tab4 ? "gst-btn gst-top " : "gst-btn"}>Owner
                                                detail
                                            </Button>{" "}


                                            <span style={{
                                                marginLeft: "600px",
                                                fontSize: "1.3rem"
                                            }} className="flaticon-close-1" onClick={e => {
                                                e.preventDefault();
                                                this.jumpToDashboard();
                                            }}/>

                                        </div>
                                        {this.state.tab1 &&
                                        <Form>
                                            <Row>
                                                <Col xl="6" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter Customer Name</Label>
                                                        <Input type="text" name="customerName"
                                                               onChange={(e) => this.handleChange(e)}
                                                               value={this.state.customerName}
                                                               placeholder="Enter Customer Name"/>
                                                        {/* <span
                                                          className="text-danger">{this.validatorTab1.message('customerName', this.state.customerName, 'required|alpha')}</span> */}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="6" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter Branch Name</Label>
                                                        <Input type="text" name="branchName"
                                                            // onChange={(e) => this.handleChange(e)}
                                                               readOnly
                                                               value={this.state.branchName}
                                                               placeholder="Enter Branch Name"/>
                                                        {/* <span
                                                          className="text-danger">{this.validatorTab1.message('branchName', this.state.branchName, 'required|alpha_num')}</span> */}
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col xl="4" lg="6" md="12">
                                                  <FormGroup>
                                                      <Label for="customerCode">Customer code</Label>
                                                      <Input type="text" value={this.state.custtomerCode}
                                                          name="customerCode"
                                                          placeholder="Enter Company Code" /> */}
                                                {/* <span className="text-danger">{ this.validator.message('customerCode', this.state.customerCode, 'required') }</span> */}
                                                {/* </FormGroup>
                                              </Col> */}
                                                {/*      <Col xl="4" lg="6" md="12">*/}
                                                {/*          <FormGroup>*/}
                                                {/*              <Label for="basicinput">Enter Customer Code</Label>*/}
                                                {/*              <Input type="text" name="customerCode"*/}
                                                {/*                     onChange={(e) => this.handleChange(e)}*/}
                                                {/*                     value={this.state.customerCode}*/}
                                                {/*                     placeholder="ENTER CUSTOMER CODE" />*/}
                                                {/*              /!* <span*/}
                                                {/*className="text-danger">{this.validatorTab1.message('customerCode', this.state.customerCode, 'required|alpha_num')}</span> *!/*/}
                                                {/*          </FormGroup>*/}
                                                {/*      </Col>*/}


                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter Address 1</Label>
                                                        <Input type="text" value={this.state.streetAddress}
                                                               onChange={(e) => this.handleChange(e)}
                                                               name="streetAddress" placeholder="Enter Address 1"/>
                                                        <span
                                                            className="text-danger">{this.validatorTab1.message('streetAddress', this.state.streetAddress, 'required')}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter Address 2</Label>
                                                        <Input type="text" name="address" value={this.state.address}
                                                               onChange={(e) => this.handleChange(e)}
                                                               placeholder="Enter Address 2"/>
                                                        {/* <span className="text-danger">{this.validatorTab1.message('address', this.state.address, 'required')}</span> */}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter City Name</Label>
                                                        <Input type="text" value={this.state.city}
                                                               onChange={(e) => this.handleChange(e)} name="city"
                                                               placeholder="Enter City Name"/>
                                                        {/* <span
                                                          className="text-danger">{this.validatorTab1.message('city', this.state.city, 'required|alpha')}</span> */}
                                                    </FormGroup>
                                                </Col>


                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicselect">Select Country</Label>
                                                        <CountryDropDown
                                                            handler={this.onCountrySelected}
                                                            selectedCountry={
                                                                this.state.shipperCountryId === undefined
                                                                    ? ""
                                                                    : this.state.shipperCountryId
                                                            }
                                                        />
                                                        <span
                                                            className="text-danger">{this.validator.message('countrySelect', this.state.countrySelect, 'required')}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        {/*<Label for="basicselect">Select State</Label>*/}
                                                        <StateDropdown
                                                            handler={this.onStateSelected}
                                                            Lable={"forms.state"}
                                                            selectedState={
                                                                this.state.selectedstate === undefined
                                                                    ? ""
                                                                    : this.state.selectedstate
                                                            }
                                                            countryId={this.state.shipperCountryId}
                                                            fromShipperConsignee={true}
                                                        />
                                                        <span
                                                            className="text-danger">{this.validatorTab1.message('selectedstate', this.state.selectedstate, 'required')}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter Pin Code</Label>
                                                        <Input type="number" value={this.state.pin}
                                                               onChange={(e) => this.handleChange(e)} name="pin"
                                                               placeholder="Enter Pin"
                                                        />
                                                        <span
                                                            className="text-danger">{this.validatorTab1.message('pin', this.state.pin, 'required')}</span>
                                                    </FormGroup>
                                                </Col>

                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicselect">Select Company Type</Label>
                                                        <Input type="select" className="select-gst"
                                                               value={this.state.customerType}
                                                               onChange={(e) => this.handleChange(e)}
                                                               name="customerType">
                                                            <option value="none" defaultValue="" disabled="">
                                                                Select Company Type
                                                            </option>
                                                            <option value="" selected="" data-select2-id="8">Select
                                                                Type
                                                            </option>
                                                            <option value="Sole Proprietorship"
                                                                    data-select2-id="25714">Sole Proprietorship
                                                            </option>
                                                            <option value="Partnership"
                                                                    data-select2-id="25715">Partnership
                                                            </option>
                                                            <option value="Limited Liability Company"
                                                                    data-select2-id="25716">Limited Liability Company
                                                            </option>
                                                            <option value="Co-operative Society"
                                                                    data-select2-id="25717">Co-operative Society
                                                            </option>
                                                            <option value="Private Limited Company"
                                                                    data-select2-id="25718">Private Limited Company
                                                            </option>
                                                            <option value="Public Limited Company"
                                                                    data-select2-id="25719">Public Limited Company
                                                            </option>
                                                            <option value="Hindu Undivided Family"
                                                                    data-select2-id="25720">Hindu Undivided Family
                                                            </option>
                                                            <option value="Association of Persons"
                                                                    data-select2-id="25721">Association of Persons
                                                            </option>
                                                            <option value="Govt. Organisations"
                                                                    data-select2-id="25722">Govt. Organisations
                                                            </option>
                                                        </Input>
                                                        <span
                                                            className="text-danger">{this.validatorTab1.message('customerType', this.state.customerType, 'required')}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="8" lg="6" md="12">
                                                    <div className="upload-inputs">
                                                        <label for="Upload Company Document" class="">Upload Company
                                                            Document</label>
                                                        <UploadFilesAAA className="custom-file-label-upload"
                                                                        name="companyDocument"
                                                                        placeHolder="Upload Company Document"
                                                                        isSingleUpload={true}
                                                                        isUploadingFiles={isUploadingFiles => {

                                                                            this.setState({
                                                                                isUploadingFiles: isUploadingFiles
                                                                            })
                                                                        }}
                                                                        onUploadFinish={(name, uploadedUrls) => {

                                                                            this.setState({
                                                                                [name]: uploadedUrls
                                                                            }, () => {

                                                                                if (this.state.companyDocument.length >= 1) {
                                                                                    toast.success("Uploaded Successfully", {
                                                                                        position: "top-left",
                                                                                        autoClose: 3000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true,
                                                                                        progress: undefined,
                                                                                    });
                                                                                }


                                                                            })
                                                                        }}/>
                                                    </div>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicselect">Select Customer Role</Label>
                                                        <Input className="select-gst" type="select"
                                                               value={this.state.customerRole}
                                                               onChange={(e) => this.handleChange(e)}
                                                               name="customerRole">
                                                            <option value="none" defaultValue="" disabled="">
                                                                Select Company Role
                                                            </option>
                                                            <option value="CHA" data-select2-id="8964">CHA</option>
                                                            <option value="Shipper" data-select2-id="8965">Shipper
                                                            </option>
                                                            <option value="Consignee" data-select2-id="8966">Consignee
                                                            </option>
                                                            <option value="Freight Forwarder"
                                                                    data-select2-id="8967">Freight Forwarder
                                                            </option>
                                                            <option value="Material Supplier"
                                                                    data-select2-id="8968">Material Supplier
                                                            </option>
                                                            <option value="Service Provider"
                                                                    data-select2-id="8969">Service Provider
                                                            </option>
                                                            <option value="Banks" data-select2-id="8970">Banks</option>
                                                            <option value="Other" data-select2-id="8971">Other</option>
                                                            <option value="Govt. Organisations"
                                                                    data-select2-id="8972">Govt. Organisations
                                                            </option>
                                                        </Input>
                                                        <span
                                                            className="text-danger">{this.validatorTab1.message('customerRole', this.state.customerRole, 'required')}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Default Email</Label>
                                                        {this.state.emailsSelect && this.state.emailsSelect.map((itm, indx) => {
                                                            return (
                                                                <span
                                                                    style={{
                                                                        background: "#eceff1",
                                                                        color: "black",
                                                                        padding: "5px",
                                                                        margin: "5px",
                                                                        borderRadius: "20px"
                                                                    }}
                                                                >
                                                            <Label>{itm}</Label>

                                                            <span
                                                                onClick={e => this.deleteEmails(indx, e)}
                                                            >
                                                                X
                                                               </span>
                                                        </span>
                                                            );
                                                        })}
                                                        <Input type="text" onKeyUp={e => {
                                                            this.handleEmail(e);
                                                        }} onChange={(e) => this.handleChange(e)}
                                                               value={this.state.emails} name="emails"
                                                               placeholder="Enter Email Address"/>
                                                        <span
                                                            className="text-danger">{this.validatorTab1.message('emailsSelect', this.state.emailsSelect, 'required')}</span>
                                                    </FormGroup>
                                                </Col>

                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Finance Email</Label>
                                                        {this.state.fiananceEmailsSelect.map((itm, indx) => {
                                                            return (
                                                                <span
                                                                    style={{
                                                                        background: "#eceff1",
                                                                        color: "black",
                                                                        padding: "5px",
                                                                        margin: "5px",
                                                                        borderRadius: "20px"
                                                                    }}
                                                                >
                                                            <Label>{itm}</Label>

                                                            <span
                                                                onClick={e => this.deleteFiananceEmails(indx, e)}
                                                            >
                                                                X
                                                               </span>
                                                        </span>
                                                            );
                                                        })}
                                                        <Input type="text" onKeyUp={e => {
                                                            this.handleFiananceEmail(e);
                                                        }} onChange={(e) => this.handleChange(e)}
                                                               value={this.state.financeMail} name="financeMail"
                                                               placeholder="Enter Email Address"/>
                                                        <span
                                                            className="text-danger">{this.validatorTab1.message('emailsSelect', this.state.fiananceEmailsSelect, 'required')}</span>
                                                    </FormGroup>
                                                </Col>


                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicselect">Select Currency</Label>
                                                        <CurrencyDropDownForRegistration className="select-gst"
                                                                                         handler={this.onCurrencySelected}
                                                            // value="currenc
                                                            //                              selectedCurrency={this.state.custCurrency}
                                                                                         value=
                                                                                             {this.state.custCurrency === undefined
                                                                                                 ? ""
                                                                                                 : this.state.custCurrency}
                                                                                         selectedCurrency={
                                                                                             this.state.custCurrency === undefined
                                                                                                 ? ""
                                                                                                 : this.state.custCurrency
                                                                                         }

                                                        />
                                                        {/* <Input type="select" value={this.state.custCurrency}
                                                          onChange={(e) => this.handleChange(e)}
                                                          name="custCurrency">
                                                          <option value="none" defaultValue="">
                                                              Select Curreny
                                                      </option>
                                                          {this.state.country.map(ex =>
                                                              <option
                                                                  value={ex.currencyCode}>{ex.currencyCode}</option>
                                                          )}

                                                      </Input> */}
                                                        <span
                                                            className="text-danger">{this.validatorTab1.message('custCurrency', this.state.custCurrency, 'required')}</span>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                        }
                                        {this.state.tab2 &&
                                        <Form>
                                            <Row>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter Pan Number</Label>
                                                        <Input type="text" value={this.state.panNo} name="panNo"
                                                               onChange={(e) => this.handleBind(e)}
                                                               onBlur={e => {
                                                                   this.validatePanNumber(e.target.value)
                                                               }}
                                                               placeholder="Enter Pan Number"/>
                                                        {/* <span
                                                          className="text-danger">{this.validatorTab2.message('panNo', this.state.panNo, 'required')}</span> */}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="8" lg="6" md="12">
                                                    <div className="upload-inputs">
                                                        <label for="Upload Pan Document" class="">Upload Pan
                                                            Document</label>
                                                        <UploadFilesAAA className="custom-file-label-upload"
                                                                        name="PanDocument"
                                                                        placeHolder="Upload Pan Document"
                                                                        isSingleUpload={true}
                                                                        isUploadingFiles={isUploadingFiles => {

                                                                            this.setState({
                                                                                isUploadingFiles: isUploadingFiles
                                                                            })
                                                                        }}
                                                                        onUploadFinish={(name, uploadedUrls) => {

                                                                            this.setState({
                                                                                [name]: uploadedUrls
                                                                            }, () => {

                                                                                if (this.state.PanDocument.length >= 1) {
                                                                                    toast.success("Uploaded Successfully", {
                                                                                        position: "top-left",
                                                                                        autoClose: 3000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true,
                                                                                        progress: undefined,
                                                                                    });
                                                                                }


                                                                            })
                                                                        }}/>
                                                    </div>
                                                </Col>
                                                <Col xl="8" lg="6" md="12">
                                                    <div className="upload-inputs">
                                                        <label for="Upload Gst Document" class="">Upload Gst
                                                            Document</label>
                                                        <UploadFilesAAA className="custom-file-label-upload"
                                                                        name="GstDocument"
                                                                        placeHolder="Upload Gst Document"
                                                                        isSingleUpload={true}
                                                                        isUploadingFiles={isUploadingFiles => {

                                                                            this.setState({
                                                                                isUploadingFiles: isUploadingFiles
                                                                            })
                                                                        }}
                                                                        onUploadFinish={(name, uploadedUrls) => {

                                                                            this.setState({
                                                                                [name]: uploadedUrls
                                                                            }, () => {

                                                                                if (this.state.GstDocument.length >= 1) {
                                                                                    toast.success("Uploaded Successfully", {
                                                                                        position: "top-left",
                                                                                        autoClose: 3000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true,
                                                                                        progress: undefined,
                                                                                    });
                                                                                }


                                                                            })
                                                                        }}/>
                                                    </div>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicselect">Select State Code</Label>
                                                        <Input type="text" className="select-gst form-control"
                                                               value={this.state.stateCode}
                                                               onChange={(e) => this.onStateSelected}
                                                               name="stateCode">
                                                            <option value="none" defaultValue="" disabled="">
                                                                Select State Code
                                                            </option>
                                                            {this.state.statelist && this.state.statelist.map(px =>
                                                                <option
                                                                    value={px.stateCode}>{px.stateCode}-{px.name}</option>
                                                            )}

                                                        </Input>
                                                         {/*<span*/}
                                                         {/* className="text-danger">{this.validatorTab2.message('statecode', this.state.stateCode, 'required')}</span>*/}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">GST Number</Label>
                                                        <Row>
                                                            <Col md="3">
                                                                <Input type="text" value={this.state.gststatecode}
                                                                       placeholder="state" disabled/>
                                                            </Col>
                                                            <Col md="4">
                                                                <Input type="text" value={this.state.gstpannumber}
                                                                       placeholder="Pan" disabled/>
                                                            </Col>
                                                            <Col md="4">
                                                                <Input type="text" value={this.state.gstnumber}
                                                                       name="gstnumber" maxLength="3" minLength="3"
                                                                       onChange={(e) => this.handleChange(e)}
                                                                       placeholder="Number"/>
                                                                {/* <span
                                                                  className="text-danger">{this.validatorGstType.message('gstnumber', this.state.gstnumber, 'required')}</span> */}
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>

                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>

                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" value={this.state.cintype}
                                                                   onChange={(e) => this.handleChange(e)}
                                                                   className="custom-control-input" id="customCheck"
                                                                   checked={this.state.cintype} name="cintype"/>
                                                            <label className="custom-control-label" for="customCheck">Cin
                                                                Number</label>
                                                        </div>
                                                        {this.state.cintype ?
                                                            <>
                                                                <Input type="text" value={this.state.cinNo}
                                                                       onChange={(e) => this.handleChange(e)}
                                                                       name="cinNo" placeholder="cin number"/>
                                                                {/* <span
                                                                  className="text-danger">{this.validatorTab2.message('cinNo', this.state.cinNo, 'required')}</span> */}
                                                            </>
                                                            :
                                                            <div className="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                       id="customFile"
                                                                       onChange={(e) => this.handleChange(e)}
                                                                       name="cinUpload"/>
                                                                <label className="custom-file-label" for="customFile">Upload
                                                                    Cin {this.state.cinupload}</label>
                                                            </div>
                                                        }

                                                    </FormGroup>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicselect">Select GST Type</Label>
                                                        <Input type="select" className="select-gst "
                                                               value={this.state.gstType}
                                                               onChange={(e) => this.handleChange(e)} name="gstType">

                                                            <option value="" data-select2-id="18">Select One</option>
                                                            <option value="N-NON Registered"
                                                                    data-select2-id="8527">N-NON Registered
                                                            </option>
                                                            <option value="O-OVERSEAS"
                                                                    data-select2-id="8528">O-OVERSEAS
                                                            </option>
                                                            <option value="S-SEZ" data-select2-id="8529">S-SEZ</option>
                                                            <option value="T-Taxable" data-select2-id="8530">T-Taxable
                                                            </option>
                                                            <option value="U-UIN"
                                                                    data-select2-id="8531">U-UIN(EMBASSIES)
                                                            </option>

                                                        </Input>
                                                        {/* <span
                                                          className="text-danger">{this.validatorTab2.message('gstType', this.state.gstType, 'required')}</span> */}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter Tan Number</Label>
                                                        <Input type="text" value={this.state.tan}
                                                               onChange={(e) => this.handleChange(e)} name="tan"
                                                               placeholder="Enter Tan Name"/>
                                                        {/* <span
                                                          className="text-danger">{this.validatorTab2.message('tan', this.state.tan, 'required')}</span> */}
                                                    </FormGroup>
                                                </Col>


                                                {/*<Col xl="4" lg="6" md="12">*/}
                                                {/*    <FormGroup>*/}
                                                {/*        <Label for="basicinput">Enter Adhar Number</Label>*/}
                                                {/*        <Input type="number" value={this.state.aadhaarNo}*/}
                                                {/*               onBlur={e => {*/}
                                                {/*                   this.validateAdharNumber(e.target.value)*/}
                                                {/*               }}*/}
                                                {/*               onChange={(e) => this.handleChange(e)} name="aadhaarNo"*/}
                                                {/*               placeholder="Enter Adhar Number"/>*/}
                                                {/*        /!* <span*/}
                                                {/*          className="text-danger">{this.validatorTab2.message('aadhaarNo', this.state.aadhaarNo, 'required')}</span> *!/*/}
                                                {/*    </FormGroup>*/}
                                                {/*</Col>*/}
                                                <Col xl="4" lg="6" md="12">
                                                    <FormGroup>
                                                        <Label for="basicinput">Enter IEC Code </Label>
                                                        <Input type="text" value={this.state.iecCode}
                                                               onChange={(e) => this.handleChange(e)} name="iecCode"
                                                               placeholder="Enter IEC Code"/>
                                                        {/* <span
                                                          className="text-danger">{this.validatorTab2.message('iecCode', this.state.iecCode, 'required')}</span> */}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                        }
                                        {this.state.tab3 &&
                                        <Form>

                                            {this.state.accountsInfo && this.state.accountsInfo.map((indx, i) => {

                                                return (
                                                    <>
                                                        <Row>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Account Name</Label>
                                                                    <Input type="text" name="accountName"
                                                                           onChange={(e) => this.handleBankDetail(e, i)}
                                                                           value={indx.accountName}
                                                                           placeholder="Enter Acc Name"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Account Number</Label>
                                                                    <Input type="text" name="accountNo"
                                                                           onChange={(e) => this.handleBankDetail(e, i)}
                                                                           value={indx.accountNo}
                                                                           placeholder="Enter Acc Number"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">IFCS Code</Label>
                                                                    <Input type="text" name="ifscCode"
                                                                           onChange={(e) => this.handleBankDetail(e, i)}
                                                                           value={indx.ifscCode}
                                                                           placeholder="Enter IFCD Code"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Swift Code</Label>
                                                                    <Input type="text"
                                                                           onChange={(e) => this.handleBankDetail(e, i)}
                                                                           name="swiftCode"
                                                                           value={indx.swiftCode}
                                                                           placeholder="Enter Swift Code"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">IBAN Number</Label>
                                                                    <Input type="text"
                                                                           onChange={(e) => this.handleBankDetail(e, i)}
                                                                           name="ibanNo"
                                                                           value={indx.ibanNo}
                                                                           placeholder="Enter IBAN Number"/>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">MICR Code</Label>
                                                                    <Input type="text"
                                                                           onChange={(e) => this.handleBankDetail(e, i)}
                                                                           name="micrCode"
                                                                           value={indx.micrCode}
                                                                           placeholder="Enter MICR Code"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Button className="btn-add mb-2" style={{width: "15%"}}
                                                                onClick={() => this.AddBankDetail()}>
                                                            ADD <FontAwesomeIcon icon={faPlus}/>
                                                        </Button>

                                                        {this.state.accountsInfo.length > 1 &&
                                                        <Col xl="4" lg="6" md="12">
                                                            <Button color="danger"
                                                                    onClick={() => this.RemoveBankDetail(indx, i)}>
                                                                <ThumbsUp size={16} color="#FFF"/> Remove
                                                            </Button>
                                                        </Col>
                                                        }
                                                    </>
                                                )
                                            })
                                            }
                                        </Form>
                                        }
                                        {this.state.tab4 &&
                                        <Form>

                                            {this.state.ownersInfo && this.state.ownersInfo.map((obj, dx) => {
                                                return (
                                                    <>
                                                        <Row>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Owner Name</Label>
                                                                    <Input type="text" name="ownerName"
                                                                           onChange={(e) => this.handleOwnerDetail(e, dx)}
                                                                           value={obj.ownerName}
                                                                           placeholder="Enter Owner Name"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Owner Address</Label>
                                                                    <Input type="text" name="ownerAddress"
                                                                           onChange={(e) => this.handleOwnerDetail(e, dx)}
                                                                           value={obj.ownerAddress}
                                                                           placeholder="Enter Owner Address"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Contact Number</Label>
                                                                    <Input type="text" name="ownerContactNo"
                                                                           onChange={(e) => this.handleOwnerDetail(e, dx)}
                                                                           value={obj.ownerContactNo}
                                                                           placeholder="Enter Contact Number"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Din Number</Label>
                                                                    <Input type="text"
                                                                           onChange={(e) => this.handleOwnerDetail(e, dx)}
                                                                           value={obj.ownerDinNo}
                                                                           name="ownerDinNo"
                                                                           placeholder="Enter Din Number"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Email ID</Label>
                                                                    <Input type="text"
                                                                           onChange={(e) => this.handleOwnerDetail(e, dx)}
                                                                           value={obj.ownerEmail}
                                                                           name="ownerEmail"
                                                                           placeholder="Enter Email ID"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="4" lg="6" md="12">
                                                                <FormGroup>
                                                                    <Label for="basicinput">Enter Adhar Number</Label>
                                                                    <Input type="number" value={this.state.aadhaarNo}
                                                                           onBlur={e => {
                                                                               this.validateAdharNumber(e.target.value)
                                                                           }}
                                                                           onChange={(e) => this.handleOwnerDetail(e, dx)} name="aadhaarNo"
                                                                           placeholder="Enter Adhar Number"/>
                                                                    {/* <span
                                                          className="text-danger">{this.validatorTab2.message('aadhaarNo', this.state.aadhaarNo, 'required')}</span> */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="4" lg="6" md="12">

                                                                <Label for="basicinput"
                                                                       className="visibility: hidden;">add</Label>
                                                                <Button className="btn-add"
                                                                        onClick={() => this.AddOwnerDetail()}>
                                                                    ADD <FontAwesomeIcon icon={faPlus}/>
                                                                </Button>
                                                                {this.state.ownersInfo.length > 1 &&

                                                                <Button color="danger"
                                                                        onClick={() => this.RemoveOwnerDetail(dx)}>
                                                                    <ThumbsUp size={16} color="#FFF"/> Remove
                                                                </Button>

                                                                }
                                                            </Col>

                                                        </Row>
                                                    </>
                                                )
                                            })
                                            }
                                            <Row>
                                                <Col xl="8" lg="6" md="12">
                                                    <div className="upload-inputs">
                                                        <label for="Upload Comapany Logo " class="">Upload
                                                            Comapany Logo</label>
                                                        <UploadFilesAAA
                                                            className="custom-file-label-upload"
                                                            name="customerLogo"
                                                            placeHolder="Upload Comapany Logo"
                                                            isSingleUpload={true}
                                                            isUploadingFiles={isUploadingFiles => {

                                                                this.setState({
                                                                    isUploadingFiles: isUploadingFiles
                                                                })
                                                            }}
                                                            onUploadFinish={(name, uploadedUrls) => {

                                                                this.setState({customerLogo:uploadedUrls
                                                                }, () => {

                                                                    if (this.state.customerLogo.length >= 1) {
                                                                        toast.success("Uploaded Successfully", {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    }


                                                                })
                                                            }}/>
                                                    </div>
                                                    <div className="width-3">
                                                        <div className="image-area">
                                                            <img id="imageResult" src={this.state.customerLogo} className="img-fluid rounded shadow-sm d-block" width="150px" height="120px" alt={this.state.customerLogo}/>
                                                        </div>
                                                    </div>
                                                </Col>


                                                <Col xl="12" lg="6" md="12">

                                                    <FormGroup>

                                                        <div
                                                            className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox"
                                                                   className="custom-control-input"
                                                                   id="checked" name="checked"
                                                                   checked={undefined}
                                                                   onChange={e => {
                                                                       e.target.checked === true ? this.setState({isChecked: true}) : this.setState({isChecked: false})
                                                                   }}
                                                            />
                                                            <label className="custom-control-label"
                                                                   for="checked">By
                                                                clicking this box,you agree to Our
                                                                Term's and
                                                                conditions</label>
                                                        </div>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                    </Form>
                                        }
                                        <Row>
                                            <div class="pl-3">
                                                {Number(this.state.count) > 1 &&
                                                // <Col xl="3" lg="6" md="12">
                                                <Button className="next-btns ornage1 mr-3"
                                                        onClick={() => this.Prev(this.state.count)}>
                                                    <FontAwesomeIcon icon={faArrowLeft}/> &nbsp; Previous
                                                </Button>
                                                    // </Col>
                                                }
                                                {Number(this.state.count) < 4 ?
                                                    <>
                                                        {/*<Col xl="3" lg="6" md="12">*/}
                                                        <Button className="next-btns"
                                                                onClick={() => this.Next(this.state.count)}>
                                                            Next &nbsp;<FontAwesomeIcon icon={faArrowRight}/>
                                                        </Button>
                                                        {/*</Col>*/}
                                                    </> : ""}
                                                {Number(this.state.count) == 4 ?
                                                    <>
                                                        {/*<Col xl="3" lg="6" md="12">*/}
                                                        <Button className="next-btns"
                                                                onClick={() => this.Submit()}>Submit</Button>
                                                        {/*</Col>*/}
                                                    </>
                                                    :
                                                    ""}
                                            </div>
                                        </Row>
                                    </CardBody>


                                </div>
                            </Fragment>

                        </ModalBody>
                    </>


                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {user, message, LoginMessage, closeModal} = state.authUser;
    const {
        error,
        loading,
        response
    } = state.RegistrationMasterData;
    return {
        user, loading, message, LoginMessage, closeModal, error,
        response
    };
};

const GstDeatilsWithRoute = withRouter(GstDeatils);

export default connect(
    mapStateToProps,
    {saveRegistrationMaster, saveRegistrationMasterResponse}
)(GstDeatilsWithRoute);
