import React, { Component } from "react";

 class Air extends Component {
     constructor(props) {
         super(props);
         this.state = {
         };
     }
     componentDidMount() {
        localStorage.setItem("queryType","AirQuery")
        this.props.history.push("/");
    //  let url = document.URL 
    //  if (url === "http://13.235.209.167:3001/Air"){
    //    localStorage.setItem("queryType","AirQuery")
    //    this.props.history.push("/");
    //  } else if (url === "http://13.235.209.167:3001/Ocean"){
    //    localStorage.setItem("queryType","OceanQuery")
    //    this.props.history.push("/");
 
    //  }else if (url === "http://13.235.209.167:3001/Courier") {
    //    localStorage.setItem("queryType","CourierQuery")
    //    this.props.history.push("/");
 
    //  } else {
    //    localStorage.setItem("queryType","RoadQuery")
    //    this.props.history.push("/");
    //  }
    //  }
     }
    render() {
        return(
           <></>
        )
     
    }
}
export default Air;