import {
    E_DOCKET_REQUEST,
    E_DOCKET_REQUEST_RESPONSE_SUCCESS,
    E_DOCKET_REQUEST_RESPONSE_FAILURE,
    E_DOCKET_MANAGER_REQUEST,
    E_DOCKET_MANAGER_SUCCESS,
    E_DOCKET_MANAGER_FAILURE,
    E_DOCKET_MANAGER_DATA_REQUEST,
    E_DOCKET_MANAGER_DATA_SUCCESS,
    E_DOCKET_MANAGER_DATA_FAILURE,
    E_DOCKET_MANAGER_DATA_REQUEST_AWB,
    E_DOCKET_MANAGER_DATA_REQUEST_AWB_SUCCESS,
    E_DOCKET_MANAGER_DATA_REQUEST_AWB_FAILURE
} from "../actions";

export const onEdocketGetRequested = (dataForServer) => ({
    type: E_DOCKET_REQUEST,
    payload: dataForServer
});

export const onEdocketGetResponseSuccess = (dataFromServer) => ({
    type: E_DOCKET_REQUEST_RESPONSE_SUCCESS,
    payload: dataFromServer
});
export const onEdocketGetResponseFailure = (dataFromServer) => ({
    type: E_DOCKET_REQUEST_RESPONSE_FAILURE,
    payload: dataFromServer
});

export const eDocketManagerRequestByJobId = (dataForServer) => ({
    type: E_DOCKET_MANAGER_REQUEST,
    payload: dataForServer
});

export const eDocketManagerRequestByJobIdSuccess = (dataFromServer) => ({
    type: E_DOCKET_MANAGER_SUCCESS,
    payload: dataFromServer
});
export const eDocketManagerRequestByJobIdFailure = (dataFromServer) => ({
    type: E_DOCKET_MANAGER_FAILURE,
    payload: dataFromServer
});
export const datafetched = (dataForServer) => ({
    type: E_DOCKET_MANAGER_DATA_REQUEST,
    payload: dataForServer
});

export const datafetchedIdSuccess = (dataFromServer) => ({
    type: E_DOCKET_MANAGER_DATA_SUCCESS,
    payload: dataFromServer
});
export const datafetchedJobIdFailure = (dataFromServer) => ({
    type: E_DOCKET_MANAGER_DATA_FAILURE,
    payload: dataFromServer
});

export const eDocketManagerRequestByMawb = (dataForServer) => ({
    type: E_DOCKET_MANAGER_DATA_REQUEST_AWB,
    payload: dataForServer
});

export const eDocketManagerRequestByMawbSuccess = (dataFromServer) => ({
    type: E_DOCKET_MANAGER_DATA_REQUEST_AWB_SUCCESS,
    payload: dataFromServer
});
export const eDocketManagerRequestByMawbFailure = (dataFromServer) => ({
    type: E_DOCKET_MANAGER_DATA_REQUEST_AWB_FAILURE,
    payload: dataFromServer
});