import {all, call, fork, put} from 'redux-saga/effects';

import RestClient from "../../util/RestClient";
import {
    MANAGER_CONSIGNEE,
    SAVE_CONSIGNEE,
    MANAGER_SHIPPER_APPROVAL,
    SAVE_SHIPPER, MANAGER_CONSIGNEE_APPROVAL, SHIPPER_CONSIGNE_ADD_API,
} from "../../constants";
import {
    SHIPPER_APPROVAL_FETCH_MASTER,
    SHIPPER_APPROVAL_CREATE,
    CONSIGNEE_APPROVAL_CREATE,
    CONSIGNEE_APPROVAL_FETCH_MASTER, ADD_SHIPPER_CONSIGNEE_FROM_POPUP,

} from "../actions";
import routeRedirectTo from "../../util/routeWrapper";
import {takeLatest} from "@redux-saga/core/effects";
import {
    showShipperResponce,
    onShipperMasterFetched,
    onShipperMasterFetchedFailed,
    showConsigneeResponce,
    onConsigneeMasterFetched,
    onConsigneeMasterFetchedFailed, AddShipperConsigneeSuccess, AddShipperConsigneeFailed,
} from "./shipperConsigneeApprovalAction";
import {PAGE_PATHS as PageRoute} from "../../util/PageRoute";

export function* watchEmail() {
    yield takeLatest(SHIPPER_APPROVAL_CREATE, storeShipper);
    yield takeLatest(SHIPPER_APPROVAL_FETCH_MASTER, fetchShipperManager);
    yield takeLatest(CONSIGNEE_APPROVAL_CREATE, storeConsignee);
    yield takeLatest(CONSIGNEE_APPROVAL_FETCH_MASTER, fetchConsigneeManager);
    yield takeLatest(ADD_SHIPPER_CONSIGNEE_FROM_POPUP, AddShipperConsigneeData);
}

function* storeShipper({payload}) {




    try {
        const storeShipperResponse = yield call(storeShipperAsync, payload);

        if (storeShipperResponse.success) {

            yield put(showShipperResponce(storeShipperResponse.message));
            yield call(forwardTo, PageRoute.SHIPPER_CONSIGNEE_APPROVAL);

        } else {
            // console.log("Not Success" + storeShipperResponse)
            yield put(showShipperResponce(storeShipperResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(showShipperResponce(error.response.data.message));
        }

    }
}

const storeShipperAsync = async (StoreShipperData) => {
    {
        // console.log(JSON.stringify("In Shipper Save API" + JSON.stringify(StoreShipperData)))

        return await RestClient({
            url: SAVE_SHIPPER,
            method: "POST",
            data: StoreShipperData
        })

    }
}


function* fetchShipperManager({payload}) {

    // console.log("In fetch shipper Attempt" + JSON.stringify(payload))

    try {
        const fetchShipperManagerResponse = yield call(fetchShipperManagerAsync, payload);
        // console.log("fetch shipper Master" + fetchShipperManagerResponse);
        if (fetchShipperManagerResponse.success) {
            yield put(onShipperMasterFetched(fetchShipperManagerResponse.result));
        } else {
            // console.log(" fetch shipper Master Not Success" + fetchShipperManagerResponse)
            yield put(onShipperMasterFetchedFailed(fetchShipperManagerResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch shipper Master" + JSON.stringify(error))
            yield put(onShipperMasterFetchedFailed(error.response.data.message));
        }
    }
}

const fetchShipperManagerAsync = async (fetchShipperManagerData) => {
    {
        // console.log(JSON.stringify("In fetch  shipper  API" + JSON.stringify(fetchShipperManagerData)))

        return await RestClient({
            url: MANAGER_SHIPPER_APPROVAL,
            method: "POST",
            data: fetchShipperManagerData
        })

    }
}


function* storeConsignee({payload}) {
    try {
        const storeConsigneeResponse = yield call(storeConsigneeAsync, payload);

        if (storeConsigneeResponse.success) {

            yield put(showConsigneeResponce(storeConsigneeResponse.message));
            yield call(forwardTo, PageRoute.SHIPPER_CONSIGNEE_APPROVAL);

        } else {
            // console.log("Not Success" + storeConsigneeResponse)
            yield put(showConsigneeResponce(storeConsigneeResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(showConsigneeResponce(error.response.data.message));
        }

    }
}

const storeConsigneeAsync = async (StoreConsigneeData) => {
    {
        // console.log(JSON.stringify("In Consignee Save API" + JSON.stringify(StoreConsigneeData)))

        return await RestClient({
            url: SAVE_CONSIGNEE,
            method: "POST",
            data: StoreConsigneeData
        })

    }
}


function* fetchConsigneeManager({payload}) {

    // console.log("In fetch shipper Attempt" + JSON.stringify(payload))

    try {
        const fetchConsigneeManagerResponse = yield call(fetchConsigneeManagerAsync, payload);
        // console.log("fetch shipper Master" + fetchConsigneeManagerResponse);
        if (fetchConsigneeManagerResponse.success) {
            yield put(onConsigneeMasterFetched(fetchConsigneeManagerResponse.result));
        } else {
            // console.log(" fetch shipper Master Not Success" + fetchConsigneeManagerResponse)
            yield put(onConsigneeMasterFetchedFailed(fetchConsigneeManagerResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch shipper Master" + JSON.stringify(error))
            yield put(onConsigneeMasterFetchedFailed(error.response.data.message));
        }
    }
}

const fetchConsigneeManagerAsync = async (fetchConsigneeManagerData) => {
    {
        // console.log(JSON.stringify("In fetch  shipper  API" + JSON.stringify(fetchConsigneeManagerData)))

        return await RestClient({
            url: MANAGER_CONSIGNEE_APPROVAL,
            method: "POST",
            data: fetchConsigneeManagerData
        })

    }
}

function* AddShipperConsigneeData({payload}) {

    // console.log("In fetch shipper Attempt" + JSON.stringify(payload))

    try {
        const fetchShiipperConsigneeAddResponse = yield call(AddShipperConsigneeDataAsync, payload);
        // console.log("fetch shipper Master" + fetchShiipperConsigneeAddResponse);
        if (fetchShiipperConsigneeAddResponse.success) {
            yield put(AddShipperConsigneeSuccess(fetchShiipperConsigneeAddResponse));
        } else {
            // console.log(" fetch shipper Master Not Success" + fetchShiipperConsigneeAddResponse)
            yield put(AddShipperConsigneeFailed(fetchShiipperConsigneeAddResponse));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch shipper Master" + JSON.stringify(error))
            yield put(AddShipperConsigneeFailed(error.response.data.message));
        }
    }
}

const AddShipperConsigneeDataAsync = async (fetchConsigneeManagerData) => {
    {
        // console.log(JSON.stringify("In fetch  shipper  API" + JSON.stringify(fetchConsigneeManagerData)))

        return await RestClient({
            url: SHIPPER_CONSIGNE_ADD_API,
            method: "POST",
            data: fetchConsigneeManagerData
        })

    }
}



function forwardTo(location) {
    routeRedirectTo.push(location);
}

export default function* Saga() {
    yield all([
        fork(watchEmail)
    ]);
}
