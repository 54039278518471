import {
    SHIPPER_CREATE,
    SHIPPER_CREATE_RESPONSE,

    SHIPPER_MANAGER_FETCH_FAILURE,
    SHIPPER_MANAGER_FETCH_MASTER,
    SHIPPER_MANAGER_FETCH_RESPONSE,

    SHIPPER_MASTER_PREVIEW,

    SHIPPER_MASTER_UPDATE,
    SHIPPER_MASTER_UPDATE_RESPONSE,

} from "../actions";

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
ShipperFormValues: {}
};


const masterShipperReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case SHIPPER_CREATE:
            // console.log("In Shipper Reducer Save " + payload);
            return {...state, loading: true, response: ''};

        case SHIPPER_CREATE_RESPONSE:
            // console.log("in Shipper CREATE Response" + JSON.stringify(payload));
            return {...state, loading: false, result: '', AddShipperResponse: payload.message};




        case SHIPPER_MANAGER_FETCH_MASTER:
            // console.log("In Shipper Master Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};
        case SHIPPER_MANAGER_FETCH_RESPONSE:
            // console.log("in Shipper Master Success");
            return {...state, loading: false, items: payload.data, totalItemCount: payload.total, error: ''};

        case SHIPPER_MANAGER_FETCH_FAILURE:
            // console.log("in Shipper Master Failure" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload.message};




        case SHIPPER_MASTER_PREVIEW:
            // console.log("In Shipper Master Preview  " + JSON.stringify(payload));
            return {...state, loading: false, ShipperFormValues: payload};



        case SHIPPER_MASTER_UPDATE:
            // console.log("In SHIPPER_MASTER_UPDATE " + payload);
            return {...state, loading: true, response: ''};

        case SHIPPER_MASTER_UPDATE_RESPONSE:
            // console.log("in SHIPPER_MASTER_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state, loading: false, responseShipper: payload};





        default:
            return initState;
    }
};

export default masterShipperReducer;
