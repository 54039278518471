import React, {Component} from "react";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter,} from "reactstrap";
import {connect} from "react-redux";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import {getQueryPreviewData} from "../../../redux/QueryRedux/QueryAction";
import MultiSelectEmail from "../../../components/MultiSelectEmail";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";
import RestClient from "../../../util/RestClient";
import {
    GET_SHIPMENTMAIL_DATA_API,
    GET_SHIPMENTMAIL_DATA_API_AIR,
    GET_SHIPMENTMAIL_DATA_API_COURIER, GET_SHIPMENTMAIL_DATA_API_OCEAN,
    GET_SHIPMENTMAIL_DATA_API_ROAD,
    GET_STATUS_BY_QUOTEID,
    GET_STATUS_BY_QUOTEID_AIR,
    GET_STATUS_BY_QUOTEID_COURIER,
    GET_STATUS_BY_QUOTEID_OCEAN,
    GET_STATUS_BY_QUOTEID_ROAD
} from "../../../constants";
import AppLayout from "../../../layout/AppLayout";
import UploadFilesCloudes from "../../../components/uploadFilesCloudes";
import RichTextEditor from "react-rte";
import {faCheckCircle, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



class DocManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalLarge: false,
            attachments: [
                {name : "",link:""}
            ],
            showPrintPopUp: false,
            returnResponce: false,
            printCount: [],
            item: [],
            fileName: "",
            quoteIdForDoc: "",
            QueryIds:'',
            emailTo:[],
            emailCc:[],
            emailBcc:[],
            emailSubject:"",
            custmail:[],
            emailBody:"",
            editorValue: RichTextEditor.createValueFromString("", "html"),
            firstDocPopup:true,

        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }
    GetNextStatus = async (dataForServer) => {
        return await RestClient({
            url: this.state.queryFor === "Air" ? GET_STATUS_BY_QUOTEID_AIR : this.state.queryFor === "Ocean" ? GET_STATUS_BY_QUOTEID_OCEAN : this.state.queryFor === "Courier" ? GET_STATUS_BY_QUOTEID_COURIER : GET_STATUS_BY_QUOTEID_ROAD,
            method: "POST",
            data: dataForServer
        });
    }

    componentDidMount() {
        let QueryId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let queryFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        let userData = {};
        let userSignature,userName,customerName
        let userResult={}
        let customerResult={}
        if (!localStorage.getItem("customer") === false) {
            userData = JSON.parse(localStorage.getItem("customer"));
            customerResult = userData && userData.result && userData.result
            userResult = userData && userData.result && userData.result.csBuddyData;
            userSignature = userResult && userResult.userSignature;
            userName = userResult && userResult.name;
            customerName = customerResult && customerResult.customerData && customerResult.customerData.customerName;
        }
        this.setState({QueryIds: QueryId,userSignature:userSignature,userName:userName,customerName:customerName,queryFor:queryFor},()=> {
            this.props.getQueryPreviewData({id: this.state.QueryIds,quoteFor:this.state.queryFor})
            this.GetNextStatus({id:this.state.QueryIds}).then(responce=>{
                if(responce.success && responce.result){
                    let statusResult = responce.result
                    let activeStatus =  statusResult && statusResult.activeStatus ? statusResult.activeStatus : undefined;
                    this.setState({activeStatus : activeStatus,
                        dataByBooking : statusResult
                    })
                    let BookingData = statusResult && statusResult.bookingData && statusResult.bookingData
                    let newArray = [];
                    let newArray1 = [];
                    let toMails = []
                    let toMail = []
                    toMails = BookingData && BookingData.consigneeEmail ? BookingData.consigneeEmail : []
                    toMail = BookingData && BookingData.shipperEmail ? BookingData.shipperEmail : []

                    if(toMails.length > 0){
                        newArray.push(toMails.shift());
                        newArray1.push(...toMails)

                    }
                    if(toMail.length > 0){
                        newArray.push(toMail.shift());

                        newArray1.push(...toMail)
                    }


                    this.setState({
                        emailTo: newArray,
                        emailBcc:newArray1

                    })


                }
            })
        })

    }
    getQuoteData = () => {
        this.props.getQueryPreviewData({ id: this.state.QueryIds , quoteFor : this.state.queryFor })
    }
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }
    onGetPdf = (i, row) => {
        if (row.target.value !== "") {
            document.location.href = row.target.value;
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.mawbPdfUrl !== this.props.mawbPdfUrl) {
            this.setState({
                mawbPdfUrl: this.props.mawbPdfUrl
            }, () => {
                if (this.state.mawbPdfUrl) {
                    document.location.href = this.state.mawbPdfUrl;

                }
            })
        }

        if (this.props.SubjectMessage && this.props.SubjectMessage !== prevProps.SubjectMessage) {
            this.setState({SubjectMessage: this.props.SubjectMessage})
        }

        if (this.props.QueryData && this.props.QueryData !== prevProps.QueryData) {
            this.setState({QueryData: this.props.QueryData}, () => {
                let {customerMails, referenceNo, queryEnteredByEmail,blNo} = this.state.QueryData
                let otherAttachement = this.state.QueryData && this.state.QueryData.attachments ?  this.state.QueryData.attachments : []
                let attachments = otherAttachement.filter(otherAttachement=>otherAttachement && otherAttachement.name)
                let NewSubjectMessage = referenceNo +(this.state.SubjectMessage !==undefined?this.state.SubjectMessage:"")
                // let emails = [];
                // let toMails = customerMails && customerMails[0]?customerMails[0]:"";
                // emails.push(toMails)
                let customerMail = queryEnteredByEmail
                this.setState({attachments: attachments,emailSubject:NewSubjectMessage,custmail :customerMail, item: attachments,blNo:blNo},()=>{
                    let message="<p>Dear Valued Patron,</p>" +
                        "<p>Please find attached the relevant documents related to the subject shipment for your kind perusal and records.</p>" +
                        `<p>Shipment Reference no:- <b>${this.state.blNo}</b> </p>` +
                        "<p>Thanks & Regards</p>" +
                        "<p>"+(this.state.customerName?this.state.customerName:"" )+"</p>"
                    if(message){
                        this.setState({
                            editorValue: RichTextEditor.createValueFromString(message, "html"),
                            emailBody : message
                        })
                    }else{
                        this.setState({
                            editorValue: RichTextEditor.createValueFromString("", "html"),
                        })
                    }

                    let newArray=[];
                    let newArray1=[];
                    if(Array.isArray(this.state.item)){
                        for (let i = 0; i < this.state.item.length; i++) {
                            if(this.state.item[i].name === "MAWB Executed PDF" || this.state.item[i].name === "Commercial Invoice" || this.state.item[i].name === "Packing List" || this.state.item[i].name === "MBL Executed PDF" || this.state.item[i].name==="Docket PDF" || this.state.item[i].isUploaded === true){
                                let isInserted=false
                                for (let j = 0; j < newArray.length; j++) {
                                    if((newArray[j].name===this.state.item[i].name)){
                                        newArray[j].link=this.state.item[i].link;
                                        newArray[j].selected=this.state.item[i].selected;
                                        newArray[j].selected = true;
                                        isInserted=true
                                    }
                                }
                                if(!isInserted){
                                    this.state.item[i].selected=true;
                                    newArray.push(this.state.item[i])
                                }
                            }else{
                                newArray1.push(this.state.item[i]);
                                let uniqueObjects = newArray1.filter((obj, index, self) =>
                                    index === self.findIndex((o) => o.name === obj.name)
                                );
                                for (let j = 0; j < uniqueObjects.length; j++) {
                                    uniqueObjects[j].selected = true;
                                }
                                newArray1 = uniqueObjects
                            }
                        }
                        this.setState({ attachments:newArray,attachments2:this.state.item,attachments3:newArray1 });
                    }
                })

            })


        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]:e.target.value})
    };
    handleEmailSelected = (emailMasterData, name) => {
        this.setState({
            [name]:emailMasterData
        });
    };

    documentSelectList =async (row,key) => {
        let attachments = this.state.attachments;
        let attachments3 = this.state.attachments3
        if(attachments[key].selected==true){
            // attachments[key].selected=false
            attachments3.push(row)
            attachments.splice(key, 1)
        }else{
            attachments[key].selected=true
        }
        this.setState({attachments,attachments3});
    };
    archiveDataSelectList = (row,key) =>{
        let attachments = this.state.attachments
        attachments.push(row)
        this.setState({
            attachments
        },()=>{
            let attachments3 = this.state.attachments3
            attachments3.splice(key, 1)
            this.setState({
                attachments3
            })
        })
    }
    emailSubmit = (e) =>{
        const body = {
            attachments: this.state.attachments,
            emailTo: this.state.emailTo,
            emailCc: this.state.emailCc,
            emailBcc: this.state.emailBcc,
            emailSubject: this.state.emailSubject,
            custmail:this.state.custmail,
            emailBody: this.state.emailBody,
            quoteId: this.state.QueryIds,
        };
        let attatchs = []
        if (Array.isArray(this.state.attachments) && this.state.attachments.length) {
            for (let i = 0; i < this.state.attachments.length; i++) {
                if (this.state.attachments[i].selected == true) {
                    attatchs.push(this.state.attachments[i])
                }
            }
        }
        body.attachments=attatchs
        if (Array.isArray(this.state.emailTo) && this.state.emailTo.length > 0) {
            if (this.state.emailSubject === "" ) {
                toast.error("Email Subject is Missing", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false
            }else{
                this.GetMailData(body).then(response=>{
                    toast.success("Sucessfully Sended Mail", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }, 3000);
                    // history.push(PAGE_PATHS.SEARCH_SHIPMENTS + "/" + this.state.queryFor + "/"+ this.state.QueryData.blNo)
                    // window.location.reload("false")
                    if(this.state.queryFor === "Air"){
                        history.push(PAGE_PATHS.AIR_TRACKING_PAGE_NEW + "/" + this.state.QueryIds + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                        window.location.reload("false")
                    }else if(this.state.queryFor === "Ocean"){
                        history.push(PAGE_PATHS.OCEAN_TRACKING_PAGE_NEW + "/" + this.state.QueryIds + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                        window.location.reload("false")
                    }else if(this.state.queryFor === "Courier"){
                        history.push(PAGE_PATHS.COURIER_TRACKING_PAGE_NEW + "/" + this.state.QueryIds + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                        window.location.reload("false")
                    }else if(this.state.queryFor === "Road"){
                        history.push(PAGE_PATHS.ROAD_TRACKING_PAGE_NEW + "/" + this.state.QueryIds + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                        window.location.reload("false")
                    }
                })
            }
        }else {
            toast.error("Please Enter Valid Emails To", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }
    GetMailData = async (body)=> {
        return await RestClient({
            url: this.state.queryFor === "Air" ? GET_SHIPMENTMAIL_DATA_API_AIR : this.state.queryFor === "Ocean" ? GET_SHIPMENTMAIL_DATA_API_OCEAN : this.state.queryFor === "Courier" ? GET_SHIPMENTMAIL_DATA_API_COURIER : GET_SHIPMENTMAIL_DATA_API_ROAD,
            method: "POST",
            data: body
        });
    };

    onChangeForEditior=(value)=>{
        this.setState({emailBody:value},()=>{
            let editValues = this.state.emailBody.toString("html")
            let isEmpty = !value.getEditorState().getCurrentContent().hasText();
            this.setState({editorValue:value,emailBody:editValues,checkMailBody:isEmpty});
        });


    }
    render() {
        return (
            <AppLayout>


                <Modal className="airline_revert_popup"
                       isOpen={this.state.firstDocPopup}
                       size="md"
                       toggle={()=>{this.setState({firstDocPopup:false})}}>
                    <ModalHeader toggle={()=>{this.setState({firstDocPopup:false})}} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                    </ModalHeader>
                    <ModalBody>
                        <div className="">
                            <div className="pop-text">
                                <h4>Download and select Document to send</h4>
                                <hr/>
                                <div className="dropdown_item_box">
                                    <div style={{padding:'5px 25px'}}>
                                        <div className="d-flex flex-wrap align-items-center justify-content-center pdf-downloads" >
                                            {this.state.mawbPdfUrl !== undefined  && this.state.mawbPdfUrl !== "" ? "" : this.createUI()}
                                        </div>
                                        <div className="mb-2 text-right">
                                            <button type="button" className="btn btns-master" data-toggle="collapse" data-target="#collapseExample4"
                                                    aria-expanded="false" aria-controls="collapseExample"
                                                    onClick={e=>{e.preventDefault()
                                                        this.setState({ShowArchiveData:!this.state.ShowArchiveData})}}>
                                                <FontAwesomeIcon icon={this.state.ShowArchiveData === true ? faMinus :faPlus}/> Archive Data</button>
                                        </div>

                                        <div className={this.state.ShowArchiveData === true ? "collapsed  mt-3" : "collapse  mt-3"}>
                                            <hr/>
                                            <div className="d-flex flex-wrap align-items-center justify-content-center" >
                                                {this.state.mawbPdfUrl !== undefined  && this.state.mawbPdfUrl !== "" ? "" : this.createUI2()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="btn_drop" onClick={()=>{this.setState({firstDocPopup:false})}}>Next</div>
                    </ModalFooter>
                </Modal>
                <div className="dashboard-wrapper">
                    <div className="page_doc">
                        <div className="container-fluid">
                            <div className="col-xl-12 col-lg-12 margin-auto">
                                <div className="back-img">
                                    <div className="doc_listing" >
                                        <div className="form-side doc_manager">
                                            <div className="d-flex flex-wrap align-items-end">
                                                <UploadFilesCloudes
                                                    className="custom-file-label-upload"
                                                    name={this.state.fileName}
                                                    quoteFor={this.state.queryFor}
                                                    classFileName = {true}
                                                    quoteId={this.state.QueryIds !== "" ?this.state.QueryIds : ""}
                                                    fromEdocket={true}
                                                    placeHolder="Upload Files"
                                                    isSingleUpload={true}
                                                    isUploaded = {true}
                                                    isUploadingFiles={(isUploadingFiles) => {
                                                        this.setState({
                                                            isUploadingFiles: isUploadingFiles,
                                                        });
                                                    }}
                                                    onUploadFinish={(name, uploadedUrls) => {
                                                        this.setState({
                                                            [name]: uploadedUrls,
                                                        });
                                                    }}
                                                    returnResponce={responce => {
                                                        this.setState({
                                                            returnResponce: responce
                                                        },()=>{
                                                            this.getQuoteData()
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="av-tooltip tooltip-label-bottom">
                                                <div className="mb-3">
                                                    <div className="oringin-master table_cardheader_revert d-flex flex-wrap align-items-center justify-content-between">
                                                        <h3 style={{color:'#fff'}}>Send Email</h3>
                                                        <button className="email_btn" onClick={(e)=>{
                                                            e.preventDefault()
                                                            this.setState({firstDocPopup:true})}
                                                        }>View Selected Document</button>
                                                    </div>
                                                    <div className="mail_editior p-3">
                                                        <div className="form-group edit_label_input">
                                                            <label className="origin-label">TO</label>
                                                            <MultiSelectEmail
                                                                id={"emailTo"}
                                                                name="emailTo"
                                                                propClass={true}
                                                                placeHolder="To"
                                                                selectedEmails={this.state.emailTo}
                                                                onEmailSelected={this.handleEmailSelected}
                                                            />
                                                        </div>

                                                        <div className="form-group edit_label_input">
                                                            <label className="origin-label">CC</label>
                                                            <MultiSelectEmail
                                                                id={"emailCc"}
                                                                name="emailCc"
                                                                propClass={true}
                                                                placeHolder="CC"
                                                                selectedEmails={this.state.emailCc}
                                                                onEmailSelected={this.handleEmailSelected}
                                                            />
                                                        </div>
                                                        <div className="form-group edit_label_input">
                                                            <label htmlFor="BCC" className="origin-label">BCC</label>
                                                            <MultiSelectEmail
                                                                id={"emailBcc"}
                                                                name="emailBcc"
                                                                propClass={true}
                                                                placeHolder="BCC"
                                                                selectedEmails={this.state.emailBcc}
                                                                onEmailSelected={this.handleEmailSelected}
                                                            />
                                                        </div>
                                                        <div className="form-group edit_label_input">
                                                            <label>Subject :</label>
                                                            <input name="emailSubject"
                                                                   className="edit_input"
                                                                   type="text"
                                                                   placeholder="Subject"
                                                                   defaultValue={""}
                                                                   value={this.state.emailSubject}
                                                                   onChange={e => { this.handleChange(e) }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <RichTextEditor
                                                                name="emailBody"
                                                                onChange={this.onChangeForEditior} value={this.state.editorValue}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="fixed-btnsbot">
                    <div className="d-flex flex-wrap justify-content-end">
                        { this.state.QueryData  && this.state.QueryData.queryFor == "Air" ?
                            <Button
                                onClick={e => {
                                    e.preventDefault()
                                    history.push(PAGE_PATHS.AIR_MAWB_VIEW + "/Air/"+this.state.QueryData.bookingId )
                                    // history.push(this.state.prevFlow)
                                    window.location.reload("false")
                                }}
                                type="button"
                                className="btn delete border-cir"
                            >Previous</Button>
                            :<Button
                                onClick={e => {
                                    e.preventDefault()
                                    if(this.state.QueryData  && this.state.QueryData.queryFor == "Ocean"){
                                        history.push(PAGE_PATHS.VIEW_MAWB + "/Ocean/"+this.state.QueryData.bookingId )
                                        // history.push(this.state.prevFlow)
                                        window.location.reload("false")
                                    }else {
                                        history.push(PAGE_PATHS.EDOCKET_PDF_VIEW + "/"+ this.state.QueryData.queryFor + "/" +this.state.QueryData.bookingId )
                                        // history.push(this.state.prevFlow)
                                        window.location.reload("false")
                                    }
                                }}
                                type="button"
                                className="btn delete border-cir"
                            >Previous</Button>}
                        <Button onClick={e => {
                            e.preventDefault()
                            history.push(PAGE_PATHS.DASHBOARD)
                            window.location.reload("false")
                        }}
                                type="button"
                                className="btn delete border-cir"
                        >Home</Button>
                        <Button onClick={this.emailSubmit}
                                type="button"
                                className="btn delete border-cir"
                        >Send Email & Next</Button>

                        <Button onClick={e => {
                            e.preventDefault()
                            if(this.state.queryFor === "Air"){
                                history.push(PAGE_PATHS.AIR_TRACKING_PAGE_NEW + "/" + this.state.QueryIds + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                                window.location.reload("false")
                            }else if(this.state.queryFor === "Ocean"){
                                history.push(PAGE_PATHS.OCEAN_TRACKING_PAGE_NEW + "/" + this.state.QueryIds + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                                window.location.reload("false")
                            }else if(this.state.queryFor === "Courier"){
                                history.push(PAGE_PATHS.COURIER_TRACKING_PAGE_NEW + "/" + this.state.QueryIds + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                                window.location.reload("false")
                            }else if(this.state.queryFor === "Road"){
                                history.push(PAGE_PATHS.ROAD_TRACKING_PAGE_NEW + "/" + this.state.QueryIds + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                                window.location.reload("false")
                            }

                            // history.push(PAGE_PATHS.SEARCH_SHIPMENTS + "/" + this.state.queryFor + "/"+this.state.QueryData.blNo )
                            // window.location.reload("false")
                        }}
                                type="button"
                                className="btn delete border-cir"
                        >Skip & Next</Button>
                    </div>
                </div>
            </AppLayout>
        );
    }

    createUI() {

        return this.state.attachments && this.state.attachments.map((row, i) => {
            if(this.state.QueryData  && this.state.QueryData.queryFor == "Air"){
                // if(row.name == "Reverted Rates" || row.name == "Commercial Invoice"  || row.name == "Packing List" || row.name == "MAWB Draft PDF"  || row.name == "MAWB Final PDF"|| row.name == "MAWB Executed PDF" ){

                return (<div key={i}>
                    <div className="docss">
                        <div className="files-preview" onClick={e=>{this.documentSelectList(row,i)}}>
                            {row && row.name !== undefined && row.name !== "" ? row.name : "Document"}
                            {row.selected ==true?<FontAwesomeIcon icon={faCheckCircle}/> :""}

                        </div>
                        <button className="download-preview" type="button"
                                value={row && row.link ? row.link : "#"}
                                onClick={e => {
                                    this.onGetPdf(i, e)
                                }}>Download</button>
                    </div>


                </div>)
                // }
            }else if(this.state.QueryData  && this.state.QueryData.queryFor == "Ocean"){
                // if(row.name == "Reverted Rates" || row.name == "Commercial Invoice"  || row.name == "Packing List" || row.name == "MBL Draft PDF" || row.name == "MBL Final PDF" || row.name == "MBL Executed PDF"){
                return (<div key={i}>
                    <div className="docss">
                        <div className="files-preview" onClick={e=>{this.documentSelectList(row,i)}}>
                            {row && row.name !== undefined && row.name !== "" ? row.name : "Document"}
                            {row.selected ==true?<FontAwesomeIcon icon={faCheckCircle}/> :""}

                        </div>
                        <button className="download-preview" type="button"
                                value={row && row.link ? row.link : "#"}
                                onClick={e => {
                                    this.onGetPdf(i, e)
                                }}>Download</button>
                    </div>


                </div>)
                // }

            }else{
                // if(row.name == "Reverted Rates" || row.name == "Commercial Invoice"  || row.name == "Packing List" || row.name == "Docket PDF" ){
                return (<div key={i}>
                    <div className="docss">
                        <div className="files-preview" onClick={e=>{this.documentSelectList(row,i)}}>
                            {row && row.name !== undefined && row.name !== "" ? row.name : "Document"}
                            {row.selected ==true?<FontAwesomeIcon icon={faCheckCircle}/> :""}

                        </div>
                        <button className="download-preview" type="button"
                                value={row && row.link ? row.link : "#"}
                                onClick={e => {
                                    this.onGetPdf(i, e)
                                }}>Download</button>
                    </div>


                </div>)
                // }

            }
        });
    }

    createUI2() {
        return this.state.attachments3 && this.state.attachments3.map((row, i) => (
            <div key={i}>
                <div className="docss">
                    <div className="files-preview" onClick={()=>{this.archiveDataSelectList(row,i)}}> {row && row.name !== undefined && row.name !== "" ? row.name : "Document"}</div>
                    <button className="download-preview" type="button"
                            value={row && row.link ? row.link : "#"}
                            onClick={e => {
                                this.onGetPdf(i, e)
                            }}>Download</button>
                </div>
            </div>
        ));
    }

}


const mapStateToProps = (state) => {
    const {QueryData,} = state.QueryReducer;
    return {QueryData};
};

export default connect(
    mapStateToProps,
    {getQueryPreviewData,}
)(DocManager);