import React, { Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import { toast } from "react-toastify";
import RestClient from "../../../util/RestClient";
import MetaTags from "react-meta-tags";

import {convertDateISO,convertDateISOToCustomiseFormat
} from "../../../util/CommonUtility";
import {MEARSK_VESSEL_API, PORT_MASTER_MANANGER_API, VESSEL_SCHEDULES_LISTING
} from "../../../constants";
import OriginDestinationDropdownForOcean from "../../../components/OriginDestinationDropdownForOcean";
import {Scrollbars} from "react-custom-scrollbars";
import {faChevronDown, faChevronUp, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Input} from "reactstrap";
class VesselSchedulePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vesselSection: false,
            vesslenotavailable:false,
            start: 0,
            vesselSchedulesmearsk:[],
            vesselSchedulesCma:[],
            page:1,
            originCode:"",
            destinationCode:'',
            vesselId : "",
            vesselOpen: false,
            clearenceDate: convertDateISOToCustomiseFormat(new Date()),
        };

    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    checkScroll = () => {
        const dimentions = this.Scrollbars.getValues();
        if (dimentions.top >= 0.9) {
            // if (this.state.page < 5) {
                this.setState({dataloader: true},()=>{
                    this.augmentData().then(r => {
                    });
                });

        }
    };
    augmentData = async () => {

        this.setState({ page: this.state.page + 1 },()=>{
            const page = {
                departureDate: this.state.clearenceDate,
                originCode:this.state.originCode,
                destinationCode:this.state.destinationCode,
                // page:this.state.page
            }
            this.vesselList(page).then(r => {
                    if (r.success){
                        this.setState(prevState => ({
                            vesselSchedules: prevState.vesselSchedules.concat(r.result),
                            dataloader: false
                        }));
                    }else{

                    }

                }
            ).catch();
        })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleClick = () =>{
        if(this.state.originCode!=="" && this.state.destinationCode!=="" && this.state.clearenceDate!==""){
            this.vesselList({page:1,originCode:this.state.originCode,destinationCode:this.state.destinationCode,departureDate:this.state.clearenceDate}).then(responce=>{
                if(responce.success){
                    let listingVesselSchedules = responce && responce.result ? responce.result :[]
                    this.setState({
                        vesselSchedulesCma : listingVesselSchedules,
                        vesselSection : true
                    },()=>{
                        let combinedResults = this.state.vesselSchedulesCma.concat(this.state.vesselSchedulesmearsk);
                        this.setState({
                            vesselSchedules: combinedResults,
                        })
                    })
                }else{
                    this.setState({
                        vesselSchedules : [],
                        vesselSection : true,
                        vesslenotavailable:true
                    })
                   
                }
            })


            let destinationName = this.state.destinationName
          
            const dest =destinationName && destinationName.split(',');
            const destCodeSplit = dest && dest[0] && dest[0].substring(0, 2);

            let originName = this.state.originName
            const originNamedest = originName && originName.split(',');
            const originNamedestCodeSplit = originNamedest && originNamedest[0] && originNamedest[0].substring(0, 2);
       

            let vessleData = {
                originCode : this.state.originCode,
                originCity :this.state.origincity,
                originCountry : originNamedestCodeSplit ? originNamedestCodeSplit:"",
                destinationCode: this.state.destinationCode,
                destinationCity: this.state.destinationcity,
                destinationCountry: destCodeSplit ? destCodeSplit:"",
                departureDate : this.state.clearenceDate  ? this.state.clearenceDate.substring(0, 10) : "",

            }

            this.vesselListMearsk(vessleData).then(responce=>{
                if(responce.success){
                    let listingVesselSchedules = responce && responce.result ? responce.result :[]
                    this.setState({
                        vesselSchedulesmearsk : listingVesselSchedules,
                        vesselSection : true
                    },()=>{
                        let combinedResults = this.state.vesselSchedulesCma.concat(this.state.vesselSchedulesmearsk);
                        this.setState({
                            vesselSchedules: combinedResults,
                        })
                    })
                }else{
                    this.setState({
                        vesselSchedules : [],
                        vesselSection : true,
                        vesslenotavailable:true

                    })
                   
                }
            })

            if(this.state.vesslenotavailable){
                toast.error("Schedule Not Available ", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }else{
            toast.error("Please Fill All Fields", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    vesselList =async (dataForServer)=>{
        return await RestClient({
            url: VESSEL_SCHEDULES_LISTING,
            method: "POST",
            data: dataForServer
        });
    }
    vesselListMearsk =async (dataForServer)=>{
        return await RestClient({
            url: MEARSK_VESSEL_API,
            method: "POST",
            data: dataForServer
        });
    }
    render() {

        return (
            <>

                <MetaTags>
                    <title>Vessel Schedules  | Zipaworld </title>
                </MetaTags>
                <AppLayout>
                    <div className="dashboard-tracking">
                        <div className={this.state.vesselSection?"domain-search bg-pblue not-track":"domain-search bg-pblue"}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 pb-5">
                                        <h2 className="form-title">Search Schedule</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="d-flex flex-wrap align-items-stretch vessel_fields" style={{width:'100%'}}>
                                            <div className="vessel_div_input border-rights">
                                                <div className="box_new_query lableNotShow">
                                                    <OriginDestinationDropdownForOcean
                                                        APIRout={PORT_MASTER_MANANGER_API}
                                                        Lable={"Origin Port"}
                                                        PlaceHolder={"Search Your Origin Port"}
                                                        AirportValue={this.state.OriginAirportValue}
                                                        spotRateQuery={false}
                                                        handler={(resultValue) => {
                                                            this.setState({
                                                                OriginAirportValue:resultValue,
                                                                originCode: resultValue.code,
                                                                origincity:resultValue.city,
                                                                        originName:resultValue.label

                                                            });

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vessel_div_input border-rights">
                                                {/*<label>Destination Port</label>*/}
                                                <div className="box_new_query lableNotShow">
                                                    <OriginDestinationDropdownForOcean
                                                        APIRout={PORT_MASTER_MANANGER_API}
                                                        Lable={"Destination Port"}
                                                        PlaceHolder={"Search Your Destination Port"}
                                                        spotRateQuery={false}
                                                        AirportValue={this.state.destinationAirportValue}
                                                        handler={(resultValue) => {
                                                            this.setState({
                                                                destinationAirportValue:resultValue,
                                                                destinationCode: resultValue.code,
                                                                destinationcity:resultValue.city,
                                                                destinationName:resultValue.label

                                                                
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vessel_div_input">
                                                <div className="box_new_query">
                                    
                                                <Input
                                                type="date"
                                                className="ipt1"
                                                id="clearenceDate"
                                                name="clearenceDate"
                                                placeholder="Handover Date"
                                                min={convertDateISO(
                                                    new Date()
                                                        .toJSON()
                                                        .slice(0, 10)
                                                        .replace(/-/g, "/")
                                                )}
                                                value={
                                                    this.state.clearenceDate === undefined
                                                        ? ""
                                                        : convertDateISO(this.state.clearenceDate)
                                                }
                                            
                                                onChange={e => {
                                                    if (
                                                        convertDateISO(e.target.value) <
                                                        convertDateISO(
                                                            new Date()
                                                                .toJSON()
                                                                .slice(0, 10)
                                                                .replace(/-/g, "/")
                                                        )
                                                    ) {
                                                        e.target.value = convertDateISO(
                                                            new Date()
                                                                .toJSON()
                                                                .slice(0, 10)
                                                                .replace(/-/g, "/")
                                                        );
                                                        this.handleChange(e)
                                                    } else {
                                                        this.handleChange(e)
                                                    }
                                                }}
                                                />
                                                </div>
                                            </div>
                                            <div className="vessel_btn_new notShowingVessel" onClick={this.handleClick}>
                                                <FontAwesomeIcon icon={faSearch}/>

                                            </div>
                                            <div className="vessel_btn_new ShowingVessel" onClick={this.handleClick}>Search</div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        {this.state.vesselSection ?
                            <div className="tracking-shipment changeVesselMArgin">
                                <div className="container-fluid">
                                    {/*{this.state.vesselSchedules && this.state.vesselSchedules.length>0 ?*/}
                                        <div className="row" style={{background:'#fff'}}>
                                            <div className="col-lg-12">
                                                <h3 className="vessel_text">Vessel Schedule</h3>
                                                <div className="d-flex flex-wrap align-items-stretch vessel_fields" style={{width:'100%'}}>
                                                    <div className="vessel_div_input border-rights">
                                                        <div className="box_new_query lableNotShow">
                                                            <OriginDestinationDropdownForOcean
                                                                APIRout={PORT_MASTER_MANANGER_API}
                                                                Lable={"Origin Port"}
                                                                PlaceHolder={"Search Your Origin Port"}
                                                                AirportValue={this.state.OriginAirportValue}
                                                                spotRateQuery={false}
                                                                handler={(resultValue) => {
                                                                    this.setState({
                                                                        originCode: resultValue.code,
                                                                        origincity:resultValue.city,
                                                                        originCountry:resultValue.countryName,
                                                                originName:resultValue.name

                                                                    });

                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="vessel_div_input border-rights">
                                                        <div className="box_new_query lableNotShow">
                                                            <OriginDestinationDropdownForOcean
                                                                APIRout={PORT_MASTER_MANANGER_API}
                                                                Lable={"Destination Port"}
                                                                PlaceHolder={"Search Your Destination Port"}
                                                                spotRateQuery={false}
                                                                AirportValue={this.state.destinationAirportValue}
                                                                handler={(resultValue) => {
                                                                    this.setState({
                                                                        destinationCode: resultValue.code,
                                                                        destinationcity:resultValue.city,
                                                                        destinationCountry:resultValue.countryName,
                                                                destinationName:resultValue.label

                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="vessel_div_input">
                                                        {/*<label>From Date</label>*/}
                                                        <div className="box_new_query">
                                                        <Input
                                                            type="date"
                                                            className="ipt1"
                                                            id="clearenceDate"
                                                            name="clearenceDate"
                                                            placeholder="Handover Date"
                                                            min={convertDateISO(
                                                                new Date()
                                                                    .toJSON()
                                                                    .slice(0, 10)
                                                                    .replace(/-/g, "/")
                                                            )}
                                                            value={
                                                                this.state.clearenceDate === undefined
                                                                    ? ""
                                                                    : convertDateISO(this.state.clearenceDate)
                                                            }
                                                        
                                                            onChange={e => {
                                                                if (
                                                                    convertDateISO(e.target.value) <
                                                                    convertDateISO(
                                                                        new Date()
                                                                            .toJSON()
                                                                            .slice(0, 10)
                                                                            .replace(/-/g, "/")
                                                                    )
                                                                ) {
                                                                    e.target.value = convertDateISO(
                                                                        new Date()
                                                                            .toJSON()
                                                                            .slice(0, 10)
                                                                            .replace(/-/g, "/")
                                                                    );
                                                                    this.handleChange(e)
                                                                } else {
                                                                    this.handleChange(e)
                                                                }
                                                            }}
                                                            />
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="vessel_btn_new notShowingVessel" onClick={this.handleClick}>
                                                <FontAwesomeIcon icon={faSearch}/>

                                            </div>
                                            <div className="vessel_btn_new ShowingVessel" onClick={this.handleClick}>Search</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="vessel_listings Edocket_manager">
                                                    {this.state.vesselSchedules && this.state.vesselSchedules.length>0 ?
                                                    <Scrollbars style={{ width: "100%", height:'500px' }}
                                                        autoHide
                                                        ref={e => this.Scrollbars = e}
                                                        onScrollStop={this.checkScroll}

                                                    >

                                                    {this.state.vesselSchedules && this.state.vesselSchedules.map((items,i)=>{
                                                        // let arrivalDate = ""
                                                        // let departureDate = ""
                                                        // let exportVoyageNo = ""
                                                        // items && items.PortCalls.map((item,index)=>{
                                                        //     if(item.unLocationCode === (this.state.originCode)){
                                                        //         exportVoyageNo = item.exportVoyageNo
                                                        //         if(item.eventType === "ESTIMATED ARRIVAL"){
                                                        //             arrivalDate = item.eventDate && convertDateISOToCustomiseFormat(item.eventDate)
                                                        //         }
                                                        //         if(item.eventType === "ESTIMATED DEPARTURE"){
                                                        //             departureDate = item.eventDate && convertDateISOToCustomiseFormat(item.eventDate)
                                                        //         }
                                                        //     }
                                                        // })
                                                        return(
                                                            <>

                                                                <div className="AAA-shipper-consignee-approve-data-12" key={i}>
                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                                        <div className="docket_items">
                                                                            <div className="docket_listing">
                                                                                <h3>Vessel Name</h3>
                                                                                <p >{items && items.routing[0] && items.routing[0].vesselName ? items.routing[0].vesselName :items && items.routing[items.routing.length - 1] && items.routing[items.routing.length - 1].vesselName ? items.routing[items.routing.length - 1].vesselName:""}</p>
                                                                            </div>
                                                                            <div className="docket_listing">
                                                                                <h3>Shipping Line</h3>
                                                                                <p title={items.shippingLineName}>{items.shippingLineName}</p></div>
                                                                            <div className="docket_listing">
                                                                                <h3>Vessel IMO No</h3>
                                                                                <p >{items && items.routing[0] && items.routing[0].imoNumber ? items.routing[0].imoNumber :items && items.routing[items.routing.length - 1] && items.routing[items.routing.length - 1].imoNumber ? items.routing[items.routing.length - 1].imoNumber:""}</p>
                                                                            </div>
                                                                            <div className="docket_listing">
                                                                                <h3>Voyage No</h3>
                                                                                <p >{items && items.routing[0] && items.routing[0].voyage ? items.routing[0].voyage.voyageReference :items && items.routing[items.routing.length - 1] && items.routing[items.routing.length - 1].voyage ? items.routing[items.routing.length - 1].voyage.voyageReference:""}</p>


                                                                            </div>

                                                                            <div className="docket_listing">
                                                                                <h3>ETD</h3>
                                                                                <p>{items && items.routing[0] && items.routing[0].originDepartureDate ? convertDateISOToCustomiseFormat(items.routing[0].originDepartureDate) :""}</p>
                                                                            </div>
                                                                            <div className="docket_listing">
                                                                                <h3>ETA</h3>
                                                                                <p >{items && items.routing[items.routing.length - 1] && items.routing[items.routing.length - 1].destinationArrivalDate ? convertDateISOToCustomiseFormat(items.routing[items.routing.length - 1].destinationArrivalDate):""}</p>

                                                                            </div>
                                                                        </div>
                                                                        <div className="docket_Btn">
                                                                            <button className="btn-showdocu" onClick={()=>{
                                                                                if(this.state.vesselOpen && this.state.vesselId === i){
                                                                                    this.setState({
                                                                                        vesselId : i,
                                                                                        vesselOpen:!this.state.vesselOpen
                                                                                    })
                                                                                }else{
                                                                                    this.setState({
                                                                                        vesselId : i,
                                                                                        vesselOpen:true
                                                                                    })
                                                                                }

                                                                            }}>View Details <FontAwesomeIcon icon={this.state.vesselId === i && this.state.vesselOpen ? faChevronUp :faChevronDown}/></button>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.vesselId === i && this.state.vesselOpen?
                                                                        <>
                                                                            <div className="tracking_vessels track_table_show">
                                                                            {items && items.routing.map((item, i) => {
                                                                                                return (<div className={items.routing && items.routing.length>1 ?"seprateVessel":""}>
                                                                                                    {items.routing && items.routing.length>1 ? <p className="vesselHeadingMAin" style={{paddingLeft:'10px'}}>Schedule {i+1}</p> :""}
                                                                                                    <div className="width_vessels">
                                                                                                        <div className="vesselItems">
                                                                                                            <p>Origin </p>
                                                                                                            <p><b>{item.originPortName ? item.originPortName : "-"}</b></p>
                                                                                                        </div>
                                                                                                        <div className="vesselItems">
                                                                                                            <p>Destination </p>
                                                                                                            <p><b>{item.destinationPortName ? item.destinationPortName : "-"}</b></p>
                                                                                                        </div>
                                                                                                        <div className="vesselItems">
                                                                                                            <p>Vessel Name </p>
                                                                                                            <p><b>{item.vesselName ? item.vesselName : "-"}</b></p>
                                                                                                        </div>
                                                                                                        <div className="vesselItems">
                                                                                                            <p>Imo Number </p>
                                                                                                            <p><b>{item.imoNumber ? item.imoNumber : "-"}</b></p>
                                                                                                        </div>
                                                                                                        <div className="vesselItems">
                                                                                                            <p>Mode </p>
                                                                                                            <p><b>{item && item.transportation && item.transportation.meanOfTransport ? item.transportation.meanOfTransport : "-"}</b></p>
                                                                                                        </div>
                                                                                                        <div className="vesselItems">
                                                                                                            <p>Departure </p>
                                                                                                            <p><b>{item && item.originDepartureDate ? convertDateISOToCustomiseFormat(item.originDepartureDate) : "-"}</b></p>
                                                                                                        </div>
                                                                                                        <div className="vesselItems">
                                                                                                            <p>Arrival </p>
                                                                                                            <p><b>{item && item.destinationArrivalDate ? convertDateISOToCustomiseFormat(item.destinationArrivalDate) : "-"}</b></p>
                                                                                                        </div>


                                                                                                    </div>
                                                                                                </div>
                                                                                                )

                                                                                            })}
                                                                                        </div>
                                                                                        <div className="all-price list-fare track_table_mob paddingDiffer">
                                                                                                <div>
                                                                                                
                                                                                                    {items && items.routing.map((item, i) => {
                                                                                                
                                                                                                            return(<div>
                                                                                                            {items.routing && items.routing.length>1 ? <p className="vesselHeadingMAin">Schedule {i+1}</p> :""}
                                                                                                            <table className="fare_table_design mb-2">
                                                                                                                <tr>
                                                                                                                    <th>Origin</th>
                                                                                                                    <td><b>{item.originPortName ? item.originPortName : "-"}</b></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th>Destination</th>
                                                                                                                    <td><b>{item.destinationPortName ? item.destinationPortName : "-"}</b></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th>Vessel Name</th>
                                                                                                                    <td><b>{item.vesselName ? item.vesselName : "-"}</b></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th>Imo Number</th>
                                                                                                                    <td><b>{item.imoNumber ? item.imoNumber : "-"}</b></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th>Mode</th>
                                                                                                                    <td><b>{item && item.transportation && item.transportation.meanOfTransport ? item.transportation.meanOfTransport : "-"}</b></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th>Departure</th>
                                                                                                                    <td><b>{item && item.originDepartureDate ? convertDateISOToCustomiseFormat(item.originDepartureDate) : "-"}</b></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th>Arrival</th>
                                                                                                                    <td><b>{item && item.destinationArrivalDate ? convertDateISOToCustomiseFormat(item.destinationArrivalDate) : "-"}</b></td>
                                                                                                                </tr>
                                                                                                                
                                                                                                            </table>
                                                                                                            </div>)
                                                                                                        })
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                        </>
                                                                        :""}
                                                                </div>
                                                            </>
                                                        )
                                                    })}

                                                    </Scrollbars>:
                                                    <h4 className="vessel_not">Data Not Available</h4>}
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        :""}
                    </div>
                </AppLayout>


            </>
        )
    }
};




export default VesselSchedulePage;

