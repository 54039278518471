import {
    SHIPPER_MANAGER_FETCH_FAILURE,
    SHIPPER_MANAGER_FETCH_MASTER,
    SHIPPER_MANAGER_FETCH_RESPONSE,
    SHIPPER_CREATE,
    SHIPPER_CREATE_RESPONSE,
    SHIPPER_MASTER_UPDATE,
    SHIPPER_MASTER_PREVIEW,
    SHIPPER_MASTER_UPDATE_RESPONSE,

    SHIPPER_MASTER_DELETE,
    SHIPPER_MASTER_DELETE_RESPONSE
} from "../actions";

export const saveShipper = (shipperDetails) => ({
    type: SHIPPER_CREATE,
    payload: shipperDetails
});
export const showShipperResponce = (message) => ({
    type: SHIPPER_CREATE_RESPONSE,
    payload: {message}
});

export const onShipperMasterLoadRequest = (shipperManagerParameters) => ({
    type: SHIPPER_MANAGER_FETCH_MASTER,
    payload: shipperManagerParameters
});
export const onShipperMasterFetched = (shipperManagerResponse) => ({
    type: SHIPPER_MANAGER_FETCH_RESPONSE,
    payload: shipperManagerResponse
});
export const onShipperMasterFetchedFailed = (message) => ({
    type: SHIPPER_MANAGER_FETCH_FAILURE,
    payload: {message}
});
export const previewShipperMasterDetail = (shipperDetails) => ({
    type: SHIPPER_MASTER_PREVIEW,
    payload: shipperDetails
});
export const updateShipperMasterDetail = (shipperDetails) => ({
    type: SHIPPER_MASTER_UPDATE,
    payload: shipperDetails
});
export const updateShipperDetailResponse = (message) => ({
    type: SHIPPER_MASTER_UPDATE_RESPONSE,
    payload: {message}
});



export const deleteShipperManagerDetail = (shipperDetails) => ({
    type: SHIPPER_MASTER_DELETE,
    payload: shipperDetails
});

export const deleteShipperManagerResponse = (message) => ({
    type: SHIPPER_MASTER_DELETE_RESPONSE,
    payload: {message}
});
