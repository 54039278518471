import {

    NOTIFICATION_LOAD_REQUEST,
    NOTIFICATION_LOAD_REQUEST_RESPONSE,
    NOTIFICATION_LOAD_REQUEST_RESPONSE_FAILURE,
    NOTIFICATION_READ_CONFIRM,
    NOTIFICATION_READ_CONFIRM_RESPONSE,
    NOTIFICATION_READ_CONFIRM_RESPONSE_FAILURE
} from "../actions";

export const onNotificationLoadRequest = (requestParameters) => ({
    type: NOTIFICATION_LOAD_REQUEST,
    payload: requestParameters
});

export const onNotificationLoadRequestServerRespondBackSuccess = (receivedParameters) => ({
    type: NOTIFICATION_LOAD_REQUEST_RESPONSE,
    payload: receivedParameters
});
export const onNotificationLoadRequestServerRespondBackFailure = (receivedParameters) => ({
    type: NOTIFICATION_LOAD_REQUEST_RESPONSE_FAILURE,
    payload: receivedParameters
});

export const readNotificationHit = (requestParameters) => ({
    type: NOTIFICATION_READ_CONFIRM,
    payload: requestParameters
});

export const readNotificationHitRespondBackSuccess = (receivedParameters) => ({
    type: NOTIFICATION_READ_CONFIRM_RESPONSE,
    payload: receivedParameters
});
export const readNotificationHitServerRespondBackFailure = (receivedParameters) => ({
    type: NOTIFICATION_READ_CONFIRM_RESPONSE_FAILURE,
    payload: receivedParameters
});
