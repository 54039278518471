import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import { NavLink } from "react-router-dom";
import {convertDateISOToCustomiseFormat} from "../../../util/CommonUtility";
class OfferCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: "",
            toUpdate: "",

            OfferModel:false,
            message:"",

            selectedOfferData:{},
            copied: false,

        };

    }



    componentDidMount() {
          if(this.props.OfferModel)
        {
            this.setState({
                OfferModel:this.props.OfferModel
            })
        }


    }

    toggleLarge = () => {
        this.setState(prevState => ({
            OfferModel: !prevState.OfferModel
        }),()=>{
            if(this.state.OfferModel === false){
                this.props.returnResult(true)
            }
        });

    };

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.OfferModel && this.props.OfferModel !== prevProps.OfferModel)
        {
            this.setState({
                OfferModel:this.props.OfferModel
            })
        }
        if(this.props.selectedOfferData && this.props.selectedOfferData !== prevProps.selectedOfferData)
        {
            this.setState({
                selectedOfferData:this.props.selectedOfferData
            })
        }

    }

    invoiceTypeName =(value)=>{
        if(value === "AE"){
            return("Air Export");
        }else if(value === "AI"){
            return("Air Import");
        } else if(value === "SE"){
            return("Sea Export");
        }else if(value === "SI"){
            return("Sea Import");
        }else if(value === "OS"){
            return("Other");
        }else{
            return(" ");
        }
    }

    airlineNameArray=(value)=>{
        let array = []
            if(Array.isArray(value) && value.length >=1){
                value && value.map((item,i)=>{
                    array.push(item.airlineName)
                })
                let result =array.join(", ")
            return result;
            }else {
                return array;
            }

    }

    ChargesArrayNames=(value)=>{
        let array = []
            if(Array.isArray(value) && value.length >=1){
                value && value.map((item,i)=>{
                    array.push(item.chargeName)
                })
                let result =array.join(", ")
            return result;
            }else {
                return array;
            }

    }


    render() {
        let scr = ""
let selectedOfferData = this.state.selectedOfferData
        return (
            <>
                <Modal className="model_offer"
                    isOpen={this.state.OfferModel}
                    size="md"
                    toggle={this.toggleLarge}
                >


                            <ModalHeader className="offer_head" toggle={this.toggleLarge} style={{padding: "0"}}>
                                <NavLink to={`/`} className="white">
                                    {/*<span className="logo-single" style={{marginBottom: "-9px"}}/>*/}
                                </NavLink>
                            </ModalHeader>
                            <ModalBody className="contact-later1">
                                <div className="offer-soon">
                                    <div className="coupon_box1">
                                        <div className="coupon_img coupon_imgs position-relative">
                                            <img src={selectedOfferData && selectedOfferData.attachments ? selectedOfferData.attachments[0] : undefined} alt="offer img"/>
                                            <div className="patch_terms_condition">
                                                {selectedOfferData && selectedOfferData.coupanData && selectedOfferData && selectedOfferData.coupanData.isInPersentage?
                                                <div className="offer_discount"><span>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.discountPercent ? selectedOfferData.coupanData.discountPercent : "" }%</span>DISCOUNT</div>
                                                :
                                                    <div className="offer_discount"><span>Up To {selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.isInPersentage ? "" : selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.currency ==="INR" ? "Rs." : selectedOfferData.coupanData && selectedOfferData.coupanData.currency ==="USD" ? "USD" : selectedOfferData.coupanData && selectedOfferData.coupanData.currency ==="EUR" ? "EUR" :""}
                                                        </span> {selectedOfferData && selectedOfferData.coupanData ? selectedOfferData.coupanData.maxDiscountAmount:""} Off</div>
                                                }
                                                    <div className="offer_valids">Offer valid till <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.validityEndDate ? convertDateISOToCustomiseFormat(selectedOfferData.coupanData.validityEndDate) : ""}</strong></div>
                                                <div className="patch_coupon">Coupon Code {selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.name ? selectedOfferData.coupanData.name : undefined}</div>
                                            </div>
                                        </div>
                                        {/*<div className="coupon_text text-left pl-3">*/}
                                        {/*    {selectedOfferData && selectedOfferData.isForCoupan === true ?*/}
                                        {/*        <>*/}
                                        {/*            <div className="coupon_code text-left">Coupon Code</div>*/}
                                        {/*            <div className="coupon_Code text-left">*/}
                                        {/*                <span className="cut-icon"><img*/}
                                        {/*                    src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/cut-icon.png"*/}
                                        {/*                    alt="cut"/></span>*/}
                                        {/*                <div className="coupon_codes"><span*/}
                                        {/*                    className="txt-Code">{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.name ? selectedOfferData.coupanData.name : undefined}</span>*/}
                                        {/*                </div>*/}
                                        {/*        </div>*/}
                                        {/*            <CopyToClipboard text={selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.name ? selectedOfferData.coupanData.name : undefined}*/}
                                        {/*                             onCopy={() => this.setState({copied: true})}>*/}
                                        {/*                <button type="submit" className="offer-btn" onClick={e=>{e.preventDefault()*/}
                                        {/*                    this.toggleLarge()*/}
                                        {/*                    localStorage.setItem("SelectedCouponCode",JSON.stringify(selectedOfferData))*/}
                                        {/*                    this.props.history.push(PAGE_PATHS.DASHBOARD)*/}
                                        {/*                }}>Copy Coupon Code</button>*/}
                                        {/*            </CopyToClipboard>*/}
                                        {/*        </>*/}
                                        {/*        :""}*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="">
                                        {selectedOfferData && selectedOfferData.isForCoupan === true ?  <div className="offer_inside">
                                            {selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.isInPersentage === true ?
                                                <h2 className="pb-3 term-test" style={{borderBottom:'1px solid #000'}}>Terms and Condition For {selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.discountPercent ? selectedOfferData.coupanData.discountPercent : "" }% off Zipaworld Discount</h2>
                                            :
                                                <h2 className="pb-3 term-test" style={{borderBottom:'1px solid #000'}}>Terms and Condition</h2>}
                                            <ul className="terms-list">
                                                {selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.isInPersentage === true ?
                                                    <li>Get flat <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.discountPercent ? selectedOfferData.coupanData.discountPercent : "" }%</strong> off Zipaworld Discount.</li>
                                                    :
                                                    undefined
                                                }
                                                <li>Minimum Invoice Value is Rs. <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.applicableAmount ? selectedOfferData.coupanData.applicableAmount : "" }</strong>.</li>

                                                <li>Offer can be availed <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.validityCount ? selectedOfferData.coupanData.validityCount : "" }</strong> times per user.</li>

                                                <li>Discount will be cancelled in case of full refunds or cancellation of order.</li>

                                                {selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.invoiceType) && selectedOfferData.coupanData.invoiceType.length >= 1  ?
                                                    <li>The discount is applicable on <strong>
                                                        {selectedOfferData.coupanData.invoiceType.map((itemInvoice,i)=>{
                                                            return (
                                                                <>
                                                                    {" " + itemInvoice.value+" , "}
                                                                </>
                                                            )
                                                        })} </strong> Service.</li>
                                                    :
                                                    ""
                                                }
                                                {((selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.origin) && selectedOfferData.coupanData.origin.length >= 1) &&
                                                (selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.destination) && selectedOfferData.coupanData.destination.length >= 1))?
                                                <li>The Discount is applicable only on
                                                    {selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.origin) && selectedOfferData.coupanData.origin.length >= 1  ?
                                                        <strong>
                                                            {selectedOfferData.coupanData.origin.map((itemOrigin,i)=>{
                                                                return (
                                                                    <>
                                                                        {" " + itemOrigin.code}
                                                                    </>
                                                                )
                                                            })}</strong>
                                                        :"  "} &
                                                    {selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.destination) && selectedOfferData.coupanData.destination.length >= 1  ?
                                                        <strong>
                                                            {selectedOfferData.coupanData.destination.map((itemDestination,i)=>{
                                                                return (
                                                                    <>
                                                                        {" " + itemDestination.code + " "}
                                                                    </>
                                                                )
                                                            })}</strong>
                                                        :"  " }
                                                    shipments.</li>:<li>The Discount is applicable on all <b>Origin and Destination</b></li>}

                                                {selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.scr) && selectedOfferData.coupanData.scr.length >= 1  ?
                                                    <li>The Discount is applicable on <strong>
                                                        {selectedOfferData.coupanData.scr.map((itemProduct,i)=>{
                                                        return (
                                                            <>
                                                                {" " + itemProduct.value+" , "}
                                                            </>
                                                        )
                                                    })} </strong> Product Categories.</li>
                                                    :
                                                    <li>The Discount is applicable on all Product Categories.</li>
                                                }


                                                {selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.airline) && selectedOfferData.coupanData.airline.length >= 1  ?
                                                    <li>The discount is applicable only for {selectedOfferData && selectedOfferData.coupanData && selectedOfferData && selectedOfferData.coupanData.queryFor === "Air"?"Airlines":"Shipping Lines"} <strong>
                                                        {selectedOfferData.coupanData.airline.map((itemAirline,i)=>{
                                                            return (
                                                                <>
                                                                     {" " + itemAirline.shippingLineName+" , "}
                                                                </>
                                                            )
                                                        })} </strong>.</li>
                                                    :
                                                    <li>The discount is applicable for all {selectedOfferData && selectedOfferData.coupanData && selectedOfferData && selectedOfferData.coupanData.queryFor === "Air"?"Airlines":selectedOfferData && selectedOfferData.coupanData && selectedOfferData && selectedOfferData.coupanData.queryFor === "Ocean"?"Shipping Lines":"Modes"}.</li>
                                                }

                                                <li>The Discount is applicable for Ch.Weight Between <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.chargeableWeightStart && selectedOfferData.coupanData.chargeableWeightStart !== 0 ? selectedOfferData.coupanData.chargeableWeightStart : "0" }</strong> to <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.chargeableWeightEnd ? selectedOfferData.coupanData.chargeableWeightEnd : "" }</strong> Kgs</li>

                                                {selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.isInPersentage === true ?
                                                    <li>The maximum Discount available is minium of <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.discountPercent ? selectedOfferData.coupanData.discountPercent : "" }</strong>% or INR <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.maxDiscountAmount ? selectedOfferData.coupanData.maxDiscountAmount : ""}</strong></li>

                                                    : <li>The maximum Discount available is  INR <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.maxDiscountAmount ? selectedOfferData.coupanData.maxDiscountAmount : ""}</strong></li> }

                                                {selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.charges) && selectedOfferData.coupanData.charges.length >= 1  ?
                                                    <li>The Discount is applicable only on <strong>
                                                        {selectedOfferData.coupanData.charges.map((itemCharges,i)=>{
                                                            return (
                                                                <>
                                                                    {" " + itemCharges.chargeName+", "}
                                                                </>
                                                            )
                                                        })} </strong> Charges.</li>
                                                    :
                                                    <li>The Discount is applicable on all Charges.</li>
                                                }

                                                {selectedOfferData && selectedOfferData.coupanData && Array.isArray(selectedOfferData.coupanData.tarrifMode) && selectedOfferData.coupanData.tarrifMode.length >= 1  ?
                                                    <li>The discount is applicable on only <strong>
                                                        {selectedOfferData.coupanData.tarrifMode.map((itemTarrif,i)=>{
                                                            return (
                                                                <>
                                                                    {" " + itemTarrif.value+", "}
                                                                </>
                                                            )
                                                        })} </strong> Tariff Mode.</li>
                                                    :
                                                    <li>The discount is applicable on all Tariff Mode.</li>
                                                }

                                                <li>The Applicable discount is In <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.currency ? selectedOfferData.coupanData.currency : ""}</strong>.</li>


                                                <li>The Discount is applicable from <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.validityStartDate ? convertDateISOToCustomiseFormat(selectedOfferData.coupanData.validityStartDate)  : ""}</strong> to <strong>{selectedOfferData && selectedOfferData.coupanData && selectedOfferData.coupanData.validityEndDate ? convertDateISOToCustomiseFormat(selectedOfferData.coupanData.validityEndDate)  : ""}</strong></li>

                                                        <li>How to Redeem Coupon:
                                                            <ul className="coupon-under" style={{paddingLeft: "25px", paddingBottom:"15px"}}>
                                                                <li>Click on Copy Coupon Code </li>
                                                                <li>Enter a Query for the shipment</li>
                                                                <li>Select Rate</li>
                                                                <li>Apply Coupon Code</li>

                                                            </ul>
                                                        </li>
                                            </ul>
                                        </div> : undefined}

                                    </div>
                                </div>
                            </ModalBody>

                </Modal>
            </>
        );
    }
}

export default OfferCard;
