import React, {Component, Fragment, lazy, Suspense} from "react";
import '../../../assets/css/sass/customeCss.css';
import '../../../assets/css/aboutstyle.css';
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";
import AppLayout from "../../../layout/AppLayout";

const FooterHome = lazy(() => import('./FooterHome'));
const renderLoader = () => <p></p>;

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutUsScroll:true,
            missionScroll:false,
            visionScroll:false,

        };
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});


    render() {
        return (
<>

<MetaTags>
        <title>ABOUT US</title>
        <meta name="title" content="Best freight forwarders in India | Digital Logistics Zipaworld"/>
        <meta name="description" content="Zipaworld is India’s most unique digital logistics platform that offers air freight, ocean freight,inland freight and courier."/>
        <meta name="keywords" content="Zipaworld is India’s most unique digital logistics platform,The Logistics E-Mall of India,  air freight, ocean freight,inland freight, road and rail freight, best courier service,fully digital concept, encourages reduced logistics costs, reduced turnaround time,complete transparency, most competitive freight pricing, instant notifications"/>
    </MetaTags>
            {/*<Suspense fallback={renderLoader()}>*/}
                <AppLayout>
                    <div className="dashboard-wrapper" ref={this.testRef}>
                        <div className="about-page paraHyperlink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 about_content">
                                        <div className="wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                                            <div className={this.state.aboutUsScroll === true ?"section-title style-two ":"section-title style-two margin_firstscroll"}>
                                                <h1 className="mb-3">About Us<span></span></h1>
                                                <p className="about-para"> <Link to="/"><b>Zipaworld</b></Link> is India’s most unique digital logistics platform. We call it ‘The Logistics
                                                    E-Mall of India, a logistics marketplace where you can get all services. What makes Zipaworld so unique and
                                                    different? Zipaworld is the only
                                                    platform that offers all logistics services from a single window. Zipaworld offers best freight forwarding
                                                    services for <Link to="/AirFreight"><b>air freight</b></Link>, <Link to="/OceanFreight"><b>ocean freight</b></Link>, <Link to="/RoadFreight"><b>road and rail freight</b></Link>  along with other multi-modal connectivity
                                                    integration. Not just this, Zipaworld has been working to enhance user experience of both B2B and B2C segments
                                                    with the <Link to="/CourierFreight"><b>best courier service</b></Link>, express and parcel pick-up and delivery services, <Link to="/CustomsBrokerage"><b>customs brokerage services</b></Link>,
                                                    <Link to="/WarehousingSolutions"><b>warehousing solutions</b></Link>, first and last mile connectivity solutions and much more.</p>
                                                <p className="about-para">Zipaworld is turning the tables for the way logistics and supply chain management is
                                                    perceived with a <Link to="/CourierFreight"><b>fully digital concept</b></Link> that <Link to="/"><b>encourages reduced logistics costs, reduced turnaround time</b></Link>,
                                                    easily understandable process, <Link to="/TrackingSearchPage"><b>complete transparency</b></Link>, <Link to="/"><b>most competitive freight pricing</b></Link>, instant notifications,
                                                    and so on.</p>
                                                <p className="about-para">Zipaworld provides the users the simplest user interface to avail logistics services.
                                                    It can be easily used by anyone without any training. Zipaworld encourages and empowers the manufacturers,
                                                    distributors, traders, exporters, importers, freight forwarders even at the remote locations to get the most
                                                    economical and most competitive logistics services avoiding too many mediators.</p>
                                                <p className="about-para">Zipaworld is on its way not just to become the best digital freight forwarder but also a
                                                    platform that integrates the complete supply chain with technology thereby providing the customers a sheer
                                                    digital logistics experience.</p>
                                                <p className="about-para">The end-to-end logistics service requirements from seller/ exporter end to the buyer/
                                                    importer end are encompassed within this digital sphere. Zipaworld takes your cargo through air, ocean and
                                                    inland, picks and delivers your cargo, books accommodation for your cargo, automates your cargo documentation,
                                                    updates you about the status of your cargo, and now you get to pay online for your cargo’s vacation!</p>
                                                <p className="about-para">This is the era of digital transformation of the logistics industry. Let us make the
                                                    logistics processes hassle-free. Now experience booking your cargo the same way as you book your travel and
                                                    vacation! Try out Zipaworld for much more than just logistics, what you get is luxury, membership programs,
                                                    deals, discounts, loyalty programs, and much more!</p>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-xl-12 col-lg-12 about_content">
                                        <div className="wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                                            <div className="section-title style-two margin-scroll">
                                                <h2 className="mb-3">Mission</h2>
                                                <p className="about-para">Zipaworld intends to simplify the logistics process using technology. The mission is not
                                                    just to provide a digital medium and interface, but also to integrate the whole supply chain and linking the
                                                    loose ends. Zipaworld is designed to reduce the turnaround time phenomenally. This will in-turn lead to
                                                    cost-effectiveness thereby bringing down the logistics cost of India.</p>
                                                <p className="about-para">Zipaworld encourages the manufacturers, exporters, shippers at every corner of the
                                                    country to enhance business through the digital logistics gateways. Zipaworld, an Indian born start-up
                                                    contributes to make India's ‘Aatmanirbhar’ and ‘Make in India’ story a success and to make India more visible
                                                    in the global supply chain index.</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-xl-12 col-lg-12 about_content">
                                        <div className="wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                                            <div className="section-title style-two margin-scroll">
                                                <h2 className="mb-3">Vision</h2>
                                                <p className="about-para">The vision of Zipaworld is to turn the table around for logistics services using
                                                    technology. Zipaworld is in pursuit for bringing a digital revolution in the field of logistics and
                                                    supply chain. A web platform which provides utmost transparency and visibility to the customers for
                                                    their business. Zipaworld aims at bringing in blockchain technology in logistics.</p>
                                                <p className="about-para">We aim at rendering opportunities for shippers, sellers, buyers, manufacturers even
                                                    in the remotest places to carry out their supply chain needs through a single window and get connected to
                                                    the world.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <FooterHome/>

                    </div>

                </AppLayout >

            {/*</Suspense>*/}
            </>
        );
    }


}



export default About;
