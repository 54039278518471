import React, {Component, Fragment} from "react";
import {
    Row,
    Col,
    CardTitle,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    CustomInput, Collapse, ModalFooter
} from "reactstrap";
import {connect} from "react-redux";
import {loginUser, registerUser} from "../../../redux/actions";

import SimpleReactValidator from "simple-react-validator";

import {Field, Form, Formik} from "formik";
import {
    COMAPANY_GET_API,
    GET_FCL_CONTAINER_DATA,
    GET_HAWB_NUMBER_UNIQUE,
    UPDATE_RATE_FOR_CREDIT,
    MANAGER_INCOTERMS_API,
    E_DOCKET_REQUEST_REQUEST,
    BOOKING_MANAGER_MASTER_GET_DATA,
    MANAGER_SHIPPER,
    MANAGER_CONSIGNEE,
    MANAGER_SHIPPER_BRANCH,
    MANAGER_CONSIGNEE_BRANCH,
    GET_HAWB_NUMBER_UNIQUE_AIR,
    GET_HAWB_NUMBER_UNIQUE_OCEAN,
    UPDATE_RATE_FOR_CREDIT_COURIER,
    UPDATE_RATE_FOR_CREDIT_ROAD,
    E_DOCKET_REQUEST_REQUEST_COURIER,
    E_DOCKET_REQUEST_REQUEST_ROAD,
    BOOKING_MANAGER_MASTER_GET_DATA_ROAD, BOOKING_MANAGER_MASTER_GET_DATA_COURIER
} from "../../../constants";

import StateDropdown from "../../../components/stateDropdown";
import CountryDropDown from "../../../components/countryDropDown";
import ShipperWithBranchDropdown from "../../../components/shipperWithBranchDropdown";
import ConsigneeWithBranchDropdown from "../../../components/consigneeWithBranchDropdown";
import {toast} from "react-toastify";
import {getCurrenctCompanyBranchData, getCustomerId, parseFloatValue} from "../../../util/CommonUtility";
import RestClient from "../../../util/RestClient";
import HouseDetailCard from "../hawb/houseDetailCard";
import Select from "react-select";
import '../../../assets/css/style1.css';
import {PAGE_PATHS} from "../../../util/PageRoute";
import {SendConatinerList, sendNextToDashboard} from "../../../redux/advanceSearch/advancSearchAction";
import {AddShipperConsignee} from "../../../redux/ShipperConsigneeApprovalRedux/shipperConsigneeApprovalAction";
import IncoTermsDropDown from "../../../components/IncoTermsDropdown";
import CommodityDropDown from "../../../components/CommodityDropDown";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import {history} from "../../../helpers/history";

import CSVReader from "react-csv-reader";
import ShipperWithBranchDropdownForShipperModal from "../../../components/shipperWithBranchDropdownForShipperModal";
import ConsigneeWithBranchDropdownForShipperModal from "../../../components/ConsigneeWithBranchDropdownForShipperModal";
import {faMinus, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const formSchema = Yup.object().shape({
    length: Yup.number("Only Number").required("Required"),
    width: Yup.number().required("Required"),
    height: Yup.number().required("Required"),
    pieces: Yup.number().required("Required")
});
class CourierShipperConsigneeModal extends Component {
    totalUsedPiecesByHouses = [];
    houseData = [];
    TotalContainer = [];
    TotalContainerCount = [];
    TotalPiecesMBL = [];
    TotalContainerWeight= [];
    AvailableHbLContainersArray= [];
    ArrayForHBL = [];
    InitialConatinerList =[]
    MAX_LENGTH_CM = 318;
    MAX_WIDTH_CM = 224;
    MAX_HEIGHT_CM = 168;
    MAX_LENGTH_INCH = 125.197;
    MAX_WIDTH_INCH = 88.189;
    MAX_HEIGHT_INCH = 64.1732;
    MAX_LENGTH_MM = 3180;
    MAX_WIDTH_MM = 2240;
    MAX_HEIGHT_MM = 1680;
    MAX_LENGTH_MTR = 3.18;
    MAX_WIDTH_MTR = 2.24;
    MAX_HEIGHT_MTR = 1.63;

    MAX_LENGTH_FT = 10.4331;
    MAX_WIDTH_FT = 7.34908;
    MAX_HEIGHT_FT = 5.51181;
    currentEnteredData = {totalPieces: 0, totalVolume: 0, totalGross: 0, totalChargeable: 0, totalDensity: 0};
    constructor(props) {
        super(props);
        this.state = {
            emailsSelect: [],
            emailsSelectConsignee:[],
            shipperContactNumber:"",
            response: "",
            NoOfContainer: "",
            totalGross: "",
            queryFor:"",
            totalVolume: "",
            accountType: "",
            totalPiecesFromQuery: "",
            selectedDimensionType: "CM",
            shipmentMode: "",
            NoOfPiecesForMBL: "",
            toUpdate: "",
            TotalContainerCount:[],
            reloadConsignee: false,
            ArrayCanChnage:true,
            reloadShipper: false,
            dimensionToggle:false,
            dimensionCollapse: true,
            allowedEdit:true,
            shipmentToggle:false,
            remainingContainer: "",
            checked: false,
            NoMoreHawb: false,
            showNewButton: false,
            checkAnotherValuation: false,
            shipperModel: false,
            OldShipperConsigneeHide: false,
            message: "",
            updateData: [],
            queryDimentionsData: [],
            ArrayForHBL: [],
            TotalContainer: [],
            dataObject: {},
            queryDataForcard: {},
            noOfHousesRequired: 0,
            selectNewShipper: false,
            selectNewConsignee: false,
            issuedBySelection: "",
            showNewShipper: false,
            showNewConsignee: false,
            errors: {},
            quoteData: {},
            totalPieces: "",
            houseContainerNumber: "",
            houseContainerNumberZero: "",
            gross: "",
            volume: "",
            chargeable: "",
            shipperName: "",
            chargeableWeight: "",
            totalQueryContainer: "",
            shipperId: "",
            shipperBranchName: "",
            shipperBranchId: "",
            shipperDetails: "",
            branchDropdownOptions: [],
            ContainerList: [],
            houseData: [],
            hawbDropDownOptions: [],
            housesDataForReturn: [],
            // InitialConatinerList: [],
            AvailableHbLContainersArray: [],
            houseShipperDetails: "",
            houseConsigneeDetails: "",
            changedData: false,
            selectedBranchForHawb: "",
            selectedBranchForHawbObj: {},
            consigneeName: "",
            consigneeId: "",
            consigneeBranchName: "",
            consigneeBranchId: "",
            consigneeDetails: "",
            totalUsedPiecesByHouses: 0,

            shipperAddressLine1: "",
            shipperAddressLine2: "",
            shipperCountryName: "",
            shipperCountryCode: "",
            shipperCountryId: "",
            shipperStateName: "",
            shipperStateCode: "",
            shipperStateId: "",
            shipperCity: "",
            shipperPincode: "",
            shipperContact: "",
            shipperContactName:"",
            shipperContactEmail:"",
            consigneeAddressLine1: "",
            consigneeAddressLine2: "",
            consigneeCountryName: "",
            consigneeCountryCode: "",
            consigneeCountryId: "",
            consigneeStateName: "",
            consigneeStateCode: "",
            consigneeStateId: "",
            consigneeCity: "",
            consigneePincode: "",
            consigneeContact: "",
            consigneeContactName: "",
            consigneeContactEmail:"",
            paymentType:"prepaid",
            packagingType:"",
            document:"",
            shipmentDescription:"",
            senderReference:"",
            uploadedDimensions: [],
            shipperlist: [{
                HSCode: "",
                ProductDescription: "",
                itemQuantity: "",
                productDeclaredValue: "",
                itemRef: "",
                ShipmentTypeCode: "",
                PackageTypeCode: "",
            }],
            refBookingNo: '',
            tab1: true,
            count: 1,
        };
        this.handleChecked = this.handleChecked.bind(this);
        this.validatorTab1 = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTab2 = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTab3 = new SimpleReactValidator({ autoForceUpdate: this });
    }

    phonenumber = (e) => {
        let phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
        if (e.target.value.match(phoneno)) {
            return true;
        } else {
            this.setState({consigneeContact: ""})
            return false;
        }
    }

    handleChecked() {
        this.setState({
            checked: !this.state.checked
        }, () => {
            if (this.state.checked === false) {
                this.setInitialStateAfterCheck()
            }
        });
    }

    AddAnotherHouse = serverObj => {
        //alert(this.state.issuedBySelection)
        if(this.state.issuedBySelection=="" || this.state.issuedBySelection==undefined || this.state.issuedBySelection==null || this.state.issuedBySelection=="Others"){
            if(this.state.issuedBySelection=="Others"){
            }else{
                toast.error("Please Select Issued By First", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }else{
            let obj = {
                noOfHawb: 1,
                companyName: this.state.issuedBySelection,
            }
            this.getHawbNumberFromServer(obj)

        }

    }
    calculateAvailablePieces = piecesUsedByHouse => {
        let pieces = 0;
        this.state.totalUsedPiecesByHouses.map(piece => {
            pieces += parseFloatValue(piece);
        });
        return parseFloatValue(this.state.totalPieces) - pieces;
    };

    getHawbUnique = async (dataForServer) => {
        return await RestClient({
            url: GET_HAWB_NUMBER_UNIQUE,
            method: "POST",
            data: dataForServer
        });
    }
    companyAndBranches = async (dataForServer) => {
        return await RestClient({
            url: COMAPANY_GET_API,
            method: "POST",
            data: dataForServer
        });
    }

    componentDidMount() {
        if (this.props.shipperModel) {
            this.setState({
                shipperModel: this.props.shipperModel
            })
        }

        let CompanyData  =  getCurrenctCompanyBranchData();
        let comapny = {
            companyLogo:CompanyData && CompanyData.branchCompanyLogo ? CompanyData.branchCompanyLogo : "",
        }
        this.setState({comapny: comapny})
        if (localStorage.getItem("already_registered") === "true") {
            this.setState({
                showNewShipper: false,
                showNewConsignee: false,
            })
            // this.setState({
            //     showNewShipper:true,
            //     showNewConsignee:true,
            //     OldShipperConsigneeHide:true
            // })
        }

        if (this.props.newShipper !== undefined) {
            if (this.props.newShipper === true) {
                this.setState({
                    showNewShipper: true,
                    showNewConsignee: true,
                    OldShipperConsigneeHide: true
                })
            }
        }

        if (this.props.queryDataForcard && this.props.queryDataForcard !== "") {

            this.setState({
                queryDataForcard: this.props.queryDataForcard
            }, () => {
                // console.log("querydatashipper", JSON.stringify(this.state.queryDataForcard))

                this.setState({
                    quoteData: this.state.queryDataForcard,
                    totalGross :this.state.queryDataForcard && this.state.queryDataForcard.grossWeight ? this.state.queryDataForcard.grossWeight : "",
                    activityType :this.state.queryDataForcard && this.state.queryDataForcard.activityType ? this.state.queryDataForcard.activityType : "",
                    queryFor :this.state.queryDataForcard && this.state.queryDataForcard.queryFor ? this.state.queryDataForcard.queryFor : "",
                    totalVolume :this.state.queryDataForcard && this.state.queryDataForcard.volumeWeight ? this.state.queryDataForcard.volumeWeight : "",
                    chargeableWeight :this.state.queryDataForcard && this.state.queryDataForcard.chargeableWeight ? this.state.queryDataForcard.chargeableWeight : "",
                    // chargeable :this.state.queryDataForcard.weight && this.state.queryDataForcard.weight.chargeable ? this.state.queryDataForcard.weight.chargeable : "",
                    NoOfContainer :this.state.queryDataForcard.noOfContainers ? this.state.queryDataForcard.noOfContainers : "",
                    totalQueryContainer: this.state.queryDataForcard.noOfContainers ? this.state.queryDataForcard.noOfContainers : "",
                    totalPiecesFromQuery: this.state.queryDataForcard.totalPieces ? this.state.queryDataForcard.totalPieces : "",
                    shipmentMode: this.state.queryDataForcard.shipmentMode ? this.state.queryDataForcard.shipmentMode : "",
                    containerType:this.state.queryDataForcard.containerType ? this.state.queryDataForcard.containerType : [],
                    courierMode: this.state.queryDataForcard.courierMode ? this.state.queryDataForcard.courierMode : "",
                
                })
                if(this.state.queryDataForcard.shipmentMode === "FCL"){
                    this.fclOptions(this.state.queryDataForcard.containerType)
                }
                if(localStorage.getItem("already_registered") === "true" ){
                    let QueryId = localStorage.getItem("queryNo");
                    let Id = JSON.parse(QueryId)

                    this.getQuoteData({ id: Id }).then(res => {
                        if (res.success) {
                            this.setState({
                                refBookingNo: res && res.result.refBookingId,
                            }, () => {
                            })
                        }
                    })
                    if(this.props.shipperModel) {
                        this.incotermsData()
                        this.shipperData()
                        this.consigneeData()
                    }
                }
                this.props.sendNextToDashboard(true)


            })
        }

    }
    incotermsData = () =>{
        this.getIncotermsDataApi({activityType : this.state.activityType}).then((data) => {
            if (data.success) {
                if (data.success && data.result.data !== undefined && data.result.data.length > 0) {
                    this.setState({
                        incotermsData:data.result &&  data.result.data ? data.result.data : []
                    });
                }
            } else {
                this.setState({
                    incotermsData: [],
                });
            }
        });
    }
    getIncotermsDataApi = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_INCOTERMS_API,
            method: "POST",
            data: dataForServer
        });
    };
    shipperData = () => {
        let customerId = getCustomerId()
        this.getShipperDataApi({customerId : customerId}).then((data) => {
            if (data.success) {
                if (data.success && data.result.data !== undefined && data.result.data.length > 0) {
                    this.setState({
                        ShipperNameData:data.result &&  data.result.data ? data.result.data : []
                    });
                }
            } else {
                this.setState({
                    ShipperNameData: [],
                });
            }
        });
    };
    getShipperDataApi = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_SHIPPER,
            method: "POST",
            data: dataForServer
        });
    };
    consigneeData = () => {
        let customerId = getCustomerId()
        this.getConsigneeDataApi({customerId : customerId}).then((data) => {
            if (data.success) {
                if (data.success && data.result.data !== undefined && data.result.data.length > 0) {
                    this.setState({
                        ConsigneeNameData:data.result &&  data.result.data ? data.result.data : []
                    });
                }
            } else {
                this.setState({
                    ConsigneeNameData: [],
                });
            }
        });
    };
    getConsigneeDataApi = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_CONSIGNEE,
            method: "POST",
            data: dataForServer
        });
    };
    toggleLarge = () => {
        this.setState(prevState => ({
            shipperModel: !prevState.shipperModel,
            shipperName:"",
            consigneeName:""
        }), () => {
            if (this.state.shipperModel === false) {
                this.props.setmodal(false)
            }
        });
    };

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }

    submitHouseData = () => {
        let maindata = {}
        if (Array.isArray(this.state.houseData) && this.state.houseData.length >= 1) {
            // maindata.houses = this.state.houseData ;
            // maindata.scacNumber = "" ;
            // maindata.svcContract = "" ;
            // maindata.vesselNo = "" ;
            // maindata.voyageNo = "" ;
            // maindata.portOfLoading = "" ;
            // maindata.portOfDischarge = "" ;
            this.props.HouseData(this.state.houseData)
        }

    }

    SubmitShipperAndConsignee = () => {
        let maindata = {}
        let topData = {}

        if (this.state.showNewShipper === false) {
            maindata.shipperName = this.state.shipperName
            maindata.shipperId = this.state.shipperId
            maindata.shipperBranchName = this.state.shipperBranchName
            maindata.shipperBranchId = this.state.shipperBranchId
            maindata.shipperDetails = this.state.shipperDetails
            maindata.shipperAccountNo = this.state.shipperAccountNo
            maindata.shipperEmail =this.state.shipperEmail
            maindata.shipperContactName=this.state.shipperContactName
            maindata.shipperContactEmail=this.state.emailsSelect
            maindata.shipperContactNumber=this.state.shipperContactNumber
            maindata.shipperPincode = this.state.shipperPincode
            maindata.isStoredShipper = true;
        }
        if (this.state.showNewConsignee === false) {
            maindata.consigneeName = this.state.consigneeName
            maindata.consigneeId = this.state.consigneeId
            maindata.consigneeBranchName = this.state.consigneeBranchName
            maindata.consigneeBranchId = this.state.consigneeBranchId
            maindata.consigneeDetails = this.state.consigneeDetails
            maindata.consigneeAccountNo = this.state.consigneeAccountNo
            maindata.consigneeContactName=this.state.consigneeContactName
            maindata.consigneeContactEmail=this.state.emailsSelectConsignee
            maindata.consigneeContactNumber=this.state.consigneeContactNumber
            maindata.consigneePincode = this.state.consigneePincode
            maindata.consigneeEmail =this.state.consigneeEmail


            maindata.isStoredConsignee = true;
        }
        if (this.state.showNewShipper === true) {
            maindata.shipperName = this.state.shipperName;
            maindata.shipperAddressLine1 = this.state.shipperAddressLine1;
            maindata.shipperAddressLine2 = this.state.shipperAddressLine2;
            maindata.shipperCountryName = this.state.shipperCountryName;
            maindata.shipperCountryCode = this.state.shipperCountryCode;
            maindata.shipperCountryId = this.state.shipperCountryId;
            maindata.shipperStateName = this.state.shipperStateName;
            maindata.shipperStateCode = this.state.shipperStateCode;
            maindata.shipperStateId = this.state.shipperStateId;
            maindata.shipperCity = this.state.shipperCity;
            maindata.shipperPincode = this.state.shipperPincode;
            maindata.shipperContact = this.state.shipperContact;
            maindata.shipperContactName = this.state.shipperContactName;
            maindata.shipperContactEmail = this.state.emailsSelect;
            maindata.isStoredShipper = false;
        }
        if (this.state.showNewConsignee === true) {
            maindata.consigneeName = this.state.consigneeName;
            maindata.consigneeAddressLine1 = this.state.consigneeAddressLine1;
            maindata.consigneeAddressLine2 = this.state.consigneeAddressLine2;
            maindata.consigneeCountryName = this.state.consigneeCountryName;
            maindata.consigneeCountryCode = this.state.consigneeCountryCode;
            maindata.consigneeCountryId = this.state.consigneeCountryId;
            maindata.consigneeStateName = this.state.consigneeStateName;
            maindata.consigneeStateCode = this.state.consigneeStateCode;
            maindata.consigneeStateId = this.state.consigneeStateId;
            maindata.consigneeCity = this.state.consigneeCity;
            maindata.consigneePincode = this.state.consigneePincode;
            maindata.consigneeContact = this.state.consigneeContact;
            maindata.consigneeContactName = this.state.consigneeContactName;
            maindata.consigneeContactEmail = this.state.emailsSelectConsignee
            maindata.isStoredConsignee = false;
        }

        topData.shipperConsignee = maindata;
        if (Array.isArray(this.state.houseData) && this.state.houseData.length >= 1) {
            topData.houses = this.state.houseData;
        }
        if(this.state.shipmentMode === "FCL"){
            let containerArry = []
            topData.NoOfContainer = this.state.NoOfContainer;
            if (this.state.shipmentMode === "FCL") {
                this.state.ContainerList.map((item, i) => {
                    if (item.count !== undefined) {
                        containerArry.push({
                            name: item.container,
                            count: item.count
                        })
                    }

                })
                topData.containerType = containerArry;
            }
        }
        else{
            topData.NoOfContainer = this.state.NoOfPiecesForMBL;
        }
        topData.totalGross = this.state.totalGross;
        topData.queryFor = this.state.queryFor;
        topData.totalVolume = this.state.totalVolume;
        topData.accountType = this.state.accountType;
        topData.incoTermsId = this.state.incoTermsId;
        topData.incoTerms = this.state.incoTerms;
        topData.paymentType = this.state.paymentType;
        topData.packagingType = this.state.packagingType;
        topData.document = this.state.document;
        topData.shipmentDescription = this.state.shipmentDescription;
        topData.senderReference = this.state.senderReference;
        topData.shipperlist = this.state.shipperlist;
        topData.totalPieces = this.state.totalPiecesFromQuery;
        topData.chargeableWeight = this.state.chargeableWeight;
        topData.dimentions = this.state.uploadedDimensions;
        topData.dimensions = this.state.uploadedDimensions;
        topData.dimentionType = this.state.selectedDimensionType;
        if (this.validateForm(topData)) {
            this.props.sendResultdata(topData)
            this.setState({
                shipperModel: true
            }, () => {
            })
            this.props.setmodal(true)
        }
    }



    SaveNewShipperAndConsignee = () => {
        let userdata = JSON.parse(localStorage.getItem("customer"))
        let customerId = userdata.result && userdata.result.customerBranchData  && userdata.result.customerBranchData.customerId ? userdata.result.customerBranchData.customerId : ""
        let branchId = userdata.result && userdata.result.csBuddyBranchData  && userdata.result.csBuddyBranchData._id ? userdata.result.csBuddyBranchData._id : ""
        let maindata = {}

        if (this.state.showNewShipper === false) {
            maindata.shipperName = this.state.shipperName
            maindata.shipperId = this.state.shipperId
            maindata.shipperBranchName = this.state.shipperBranchName
            maindata.shipperBranchId = this.state.shipperBranchId
            maindata.shipperDetails = this.state.shipperDetails
            maindata.isStoredShipper = true;
            maindata.customerId = customerId;
            maindata.userBranchId = branchId;
        }

        if (this.state.showNewConsignee === false) {
            maindata.consigneeName = this.state.consigneeName
            maindata.consigneeId = this.state.consigneeId
            maindata.consigneeBranchName = this.state.consigneeBranchName
            maindata.consigneeBranchId = this.state.consigneeBranchId
            maindata.consigneeDetails = this.state.consigneeDetails
            maindata.isStoredConsignee = true;
            maindata.customerId = customerId;
            maindata.userBranchId = branchId;
        }

        if (this.state.showNewShipper === true) {
            if(this.state.shipperName === "" || this.state.shipperAddressLine1 === "" || this.state.shipperCountryName === "" || this.state.shipperStateName === "" || this.state.shipperCity === ""){
                if(this.state.shipperName === "" || this.state.shipperName === undefined){
                    toast.error(
                        "Please enter Sender name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperAddressLine1 === "" || this.state.shipperAddressLine1 === undefined){
                    toast.error(
                        "Please enter sender Address details",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperCountryName === "" || this.state.shipperCountryName === undefined){
                    toast.error(
                        "Please enter country name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperStateName === "" || this.state.shipperStateName === undefined){
                    toast.error(
                        "Please enter state name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperCity === "" || this.state.shipperCity === undefined){
                    toast.error(
                        "Please enter city name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }
            }else{
            maindata.shipperName = this.state.shipperName;
            maindata.shipperAddressLine1 = this.state.shipperAddressLine1;
            maindata.shipperAddressLine2 = this.state.shipperAddressLine2;
            maindata.shipperCountryName = this.state.shipperCountryName;
            maindata.shipperCountryCode = this.state.shipperCountryCode;
            maindata.shipperCountryId = this.state.shipperCountryId;
            maindata.shipperStateName = this.state.shipperStateName;
            maindata.shipperStateCode = this.state.shipperStateCode;
            maindata.shipperStateId = this.state.shipperStateId;
            maindata.shipperCity = this.state.shipperCity;
            maindata.shipperPincode = this.state.shipperPincode;
            maindata.shipperContact = this.state.shipperContact;
            maindata.shipperContactName = this.state.shipperContactName;
                maindata.shipperContactEmail =this.state.emailsSelect
            maindata.isStoredShipper = false;
            maindata.customerId = customerId;
            maindata.userBranchId = branchId;
            }
        }
        if (this.state.showNewConsignee === true) {
            if(this.state.consigneeName === "" || this.state.consigneeAddressLine1 === "" || this.state.consigneeCountryName === "" || this.state.consigneeStateName === "" || this.state.consigneeCity === ""){
                if(this.state.consigneeName === "" || this.state.consigneeName === undefined){
                    toast.error(
                        "Please enter receiver name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeAddressLine1 === "" || this.state.consigneeAddressLine1 === undefined){
                    toast.error(
                        "Please enter receiver Address details",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeCountryName === "" || this.state.consigneeCountryName === undefined){
                    toast.error(
                        "Please select country name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeStateName === "" || this.state.consigneeStateName === undefined){
                    toast.error(
                        "Please select state name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeCity === "" || this.state.consigneeCity === undefined){
                    toast.error(
                        "Please enter city name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }
            }else{
            maindata.consigneeName = this.state.consigneeName;
            maindata.consigneeAddressLine1 = this.state.consigneeAddressLine1;
            maindata.consigneeAddressLine2 = this.state.consigneeAddressLine2;
            maindata.consigneeCountryName = this.state.consigneeCountryName;
            maindata.consigneeCountryCode = this.state.consigneeCountryCode;
            maindata.consigneeCountryId = this.state.consigneeCountryId;
            maindata.consigneeStateName = this.state.consigneeStateName;
            maindata.consigneeStateCode = this.state.consigneeStateCode;
            maindata.consigneeStateId = this.state.consigneeStateId;
            maindata.consigneeCity = this.state.consigneeCity;
            maindata.consigneePincode = this.state.consigneePincode;
            maindata.consigneeContactName = this.state.consigneeContactName;
                maindata.consigneeContactEmail=this.state.emailsSelectConsignee
            maindata.consigneeContact = this.state.consigneeContact;
            maindata.isStoredConsignee = false;
            maindata.customerId = customerId;
            maindata.userBranchId = branchId;
        }
    }
        this.props.AddShipperConsignee(maindata)

    }
    SaveNewShipper = () =>{
        let userdata = JSON.parse(localStorage.getItem("customer"))
        let customerId = userdata.result && userdata.result.customerBranchData  && userdata.result.customerBranchData.customerId ? userdata.result.customerBranchData.customerId : ""
        let branchId = userdata.result && userdata.result.csBuddyBranchData  && userdata.result.csBuddyBranchData._id ? userdata.result.csBuddyBranchData._id : ""

        let maindata = {}
        this.setState({
            consigneeDetails:""
        },()=>{

        })
        if (this.state.showNewShipper === true) {
            if(this.state.shipperName === "" || this.state.shipperAddressLine1 === "" || this.state.shipperCountryName === "" || this.state.shipperStateName === "" || this.state.shipperCity === ""){
                if(this.state.shipperName === "" || this.state.shipperName === undefined){
                    toast.error(
                        "Please enter Sender name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperAddressLine1 === "" || this.state.shipperAddressLine1 === undefined){
                    toast.error(
                        "Please enter sender Address details",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperCountryName === "" || this.state.shipperCountryName === undefined){
                    toast.error(
                        "Please enter country name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperStateName === "" || this.state.shipperStateName === undefined){
                    toast.error(
                        "Please enter state name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperCity === "" || this.state.shipperCity === undefined){
                    toast.error(
                        "Please enter city name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }
            }else{
                maindata.shipperName = this.state.shipperName;
                maindata.shipperAddressLine1 = this.state.shipperAddressLine1;
                maindata.shipperAddressLine2 = this.state.shipperAddressLine2;
                maindata.shipperCountryName = this.state.shipperCountryName;
                maindata.shipperCountryCode = this.state.shipperCountryCode;
                maindata.shipperCountryId = this.state.shipperCountryId;
                maindata.shipperStateName = this.state.shipperStateName;
                maindata.shipperStateCode = this.state.shipperStateCode;
                maindata.shipperStateId = this.state.shipperStateId;
                maindata.shipperCity = this.state.shipperCity;
                maindata.shipperPincode = this.state.shipperPincode;
                maindata.shipperContact = this.state.shipperContact;
                maindata.shipperContactName = this.state.shipperContactName;
                maindata.shipperContactEmail=this.state.emailsSelect;
                maindata.isStoredShipper = false;
                maindata.customerId = customerId;
                maindata.userBranchId = branchId;
                this.props.AddShipperConsignee(maindata)
                this.setState({
                    shipperId:"",
                    shipperName:"",
                    shipperBranchId:"",
                    shipperBranchName:"",
                    shipperDetails:""
                })
            }

        }
    }

    SaveNewConsignee = () =>{
        let userdata = JSON.parse(localStorage.getItem("customer"))
        let customerId = userdata.result && userdata.result.customerBranchData  && userdata.result.customerBranchData.customerId ? userdata.result.customerBranchData.customerId : ""
        let branchId = userdata.result && userdata.result.csBuddyBranchData  && userdata.result.csBuddyBranchData._id ? userdata.result.csBuddyBranchData._id : ""
        let maindata = {}
        if (this.state.showNewConsignee === true) {
            if(this.state.consigneeName === "" || this.state.consigneeAddressLine1 === "" || this.state.consigneeCountryName === "" || this.state.consigneeStateName === "" || this.state.consigneeCity === ""){
                if(this.state.consigneeName === "" || this.state.consigneeName === undefined){
                    toast.error(
                        "Please enter Receiver name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeAddressLine1 === "" || this.state.consigneeAddressLine1 === undefined){
                    toast.error(
                        "Please enter Receiver Address details",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeCountryName === "" || this.state.consigneeCountryName === undefined){
                    toast.error(
                        "Please select country name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeStateName === "" || this.state.consigneeStateName === undefined){
                    toast.error(
                        "Please select state name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeCity === "" || this.state.consigneeCity === undefined){
                    toast.error(
                        "Please enter city name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }
            }else{
                maindata.consigneeName = this.state.consigneeName;

                maindata.consigneeAddressLine1 = this.state.consigneeAddressLine1;
                maindata.consigneeAddressLine2 = this.state.consigneeAddressLine2;
                maindata.consigneeCountryName = this.state.consigneeCountryName;
                maindata.consigneeCountryCode = this.state.consigneeCountryCode;
                maindata.consigneeCountryId = this.state.consigneeCountryId;
                maindata.consigneeStateName = this.state.consigneeStateName;
                maindata.consigneeStateCode = this.state.consigneeStateCode;
                maindata.consigneeStateId = this.state.consigneeStateId;
                maindata.consigneeCity = this.state.consigneeCity;
                maindata.consigneePincode = this.state.consigneePincode;
                maindata.consigneeContact = this.state.consigneeContact;
                maindata.consigneeContactName = this.state.consigneeContactName;
                maindata.consigneeContactEmail=this.state.emailsSelectConsignee
                maindata.isStoredConsignee = false;
                maindata.customerId = customerId;
                maindata.userBranchId = branchId;
                this.props.AddShipperConsignee(maindata)
                this.setState({
                    consigneeId:"",
                    consigneeName:"",
                    consigneeBranchId:"",
                    consigneeBranchName:"",
                    consigneeDetails:""
                })
            }

        }
    }
    SubmitShipperAndConsigneeNew = () => {
        let maindata = {}
        let topData = {}
        if (this.state.showNewShipper === false) {
            maindata.shipperName = this.state.shipperName
            maindata.shipperId = this.state.shipperId
            maindata.shipperBranchName = this.state.shipperBranchName
            maindata.shipperBranchId = this.state.shipperBranchId
            maindata.shipperDetails = this.state.shipperDetails
            maindata.shipperAccountNo = this.state.shipperAccountNo
            maindata.shipperEmail =this.state.shipperEmail
            maindata.isStoredShipper = true;
        }

        if (this.state.showNewConsignee === false) {
            maindata.consigneeName = this.state.consigneeName
            maindata.consigneeId = this.state.consigneeId
            maindata.consigneeBranchName = this.state.consigneeBranchName
            maindata.consigneeBranchId = this.state.consigneeBranchId
            maindata.consigneeDetails = this.state.consigneeDetails
            maindata.consigneeAccountNo = this.state.consigneeAccountNo
            maindata.isStoredConsignee = true;
            maindata.consigneeEmail=this.state.consigneeEmail
        }

        if (this.state.showNewShipper === true) {
            maindata.shipperName = this.state.shipperName;
            maindata.shipperAddressLine1 = this.state.shipperAddressLine1;
            maindata.shipperAddressLine2 = this.state.shipperAddressLine2;
            maindata.shipperCountryName = this.state.shipperCountryName;
            maindata.shipperCountryCode = this.state.shipperCountryCode;
            maindata.shipperCountryId = this.state.shipperCountryId;
            maindata.shipperStateName = this.state.shipperStateName;
            maindata.shipperStateCode = this.state.shipperStateCode;
            maindata.shipperStateId = this.state.shipperStateId;
            maindata.shipperCity = this.state.shipperCity;
            maindata.shipperPincode = this.state.shipperPincode;
            maindata.shipperContact = this.state.shipperContact;
            maindata.shipperContactName = this.state.shipperContactName;
            maindata.shipperContactEmail=this.state.emailsSelect
            maindata.isStoredShipper = false;
        }
        if (this.state.showNewConsignee === true) {
            maindata.consigneeName = this.state.consigneeName;
            maindata.consigneeAddressLine1 = this.state.consigneeAddressLine1;
            maindata.consigneeAddressLine2 = this.state.consigneeAddressLine2;
            maindata.consigneeCountryName = this.state.consigneeCountryName;
            maindata.consigneeCountryCode = this.state.consigneeCountryCode;
            maindata.consigneeCountryId = this.state.consigneeCountryId;
            maindata.consigneeStateName = this.state.consigneeStateName;
            maindata.consigneeStateCode = this.state.consigneeStateCode;
            maindata.consigneeStateId = this.state.consigneeStateId;
            maindata.consigneeCity = this.state.consigneeCity;
            maindata.consigneePincode = this.state.consigneePincode;
            maindata.consigneeContact = this.state.consigneeContact;
            maindata.consigneeContactName = this.state.consigneeContactName;
            maindata.consigneeContactEmail = this.state.emailsSelectConsignee
            maindata.isStoredConsignee = false;
        }

        topData.shipperConsignee = maindata
        if (Array.isArray(this.state.houseData) && this.state.houseData.length >= 1) {
            topData.houses = this.state.houseData;
            let notValidHouseData=false
            // for (let i = 0; i < topData.houses.length; i++) {
            //     let chouse=topData.houses[i]
            //     if(
            //         chouse.houseNo=="" || chouse.houseNo==undefined || chouse.houseNo==null ||
            //         chouse.shipperId=="" || chouse.shipperId==undefined || chouse.shipperId==null ||
            //         chouse.shipperBranchId=="" || chouse.shipperBranchId==undefined || chouse.shipperBranchId==null ||
            //         chouse.consigneeId=="" || chouse.consigneeId==undefined || chouse.consigneeId==null ||
            //         chouse.consigneeBranchId=="" || chouse.consigneeBranchId==undefined || chouse.consigneeBranchId==null ||
            //         chouse.volumeWeight=="" || chouse.volumeWeight==undefined || chouse.volumeWeight==null ||
            //         chouse.noOfPieces=="" || chouse.noOfPieces==undefined || chouse.noOfPieces==null ||
            //         chouse.grossWeight=="" || chouse.grossWeight==undefined || chouse.grossWeight==null
            //     ){
            //         notValidHouseData=true
            //     }
            // }
            if(notValidHouseData){
                toast.error("Please Provide Required Houses Data", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false;
            }

        }
        if(this.state.shipmentMode === "FCL"){
            let containerArry = []
            topData.NoOfContainer = this.state.NoOfContainer;
            if (this.state.shipmentMode === "FCL") {
                this.state.ContainerList.map((item, i) => {
                    if (item.count !== undefined) {
                        containerArry.push({
                            name: item.container,
                            count: item.count
                        })
                    }

                })
                topData.containerType = containerArry;
            }
        }else{
            topData.NoOfContainer = this.state.NoOfPiecesForMBL;
        }

        topData.totalGross = this.state.totalGross;
        topData.queryFor = this.state.queryFor;
        topData.totalVolume = this.state.totalVolume;
        topData.accountType = this.state.accountType;
        topData.dimentions = this.state.uploadedDimensions;
        topData.dimensions = this.state.uploadedDimensions;
        topData.incoTermsId = this.state.incoTermsId;
        topData.incoTerms = this.state.incoTerms;
        topData.totalPieces = this.state.totalPiecesFromQuery;
        topData.chargeableWeight = this.state.chargeableWeight;
        this.props.sendResultdata(topData)

        this.setInitialState()
        // this.setState({
        //     shipperModel: false
        // })
        // this.props.setmodal(false)
    }
    setInitialState = () => {
        this.setState({
            shipperName: "",
            shipperId: "",
            shipperBranchName: "",
            shipperBranchId: "",
            shipperDetails: "",
            consigneeName: "",
            consigneeId: "",
            consigneeBranchName: "",
            consigneeBranchId: "",
            consigneeDetails: "",
            noOfHousesRequired: 0,
            totalUsedPiecesByHouses: 0,
            ArrayForHBL: [],
            AvailableHbLContainersArray :[],
            changedData: false,
            issuedBySelection: "",
            issuedBySelectionBranch: "",
            houseData: [],
            updateData: [],
            queryDimentionsData: [],
            selectedBranchForHawb: "",
        })
        this.houseData = [];
        this.ArrayForHBL = [];
        this.AvailableHbLContainersArray = [];
    }

    setInitialStateAfterCheck = () => {
        this.setState({
            noOfHousesRequired: 0,
            totalUsedPiecesByHouses: 0,
            ArrayForHBL: [],
            AvailableHbLContainersArray :[],
            changedData: false,
            NoMoreHawb: false,
            issuedBySelection: "",
            issuedBySelectionBranch: "",
            houseData: [],
            updateData: [],
            queryDimentionsData: [],
            selectedBranchForHawb: "",
        })
        this.houseData = [];
        this.ArrayForHBL = [];
        this.AvailableHbLContainersArray = [];
    }
    handleShipperSelected = ({shipperName, shipperId, shipperBranchName, shipperBranchId, shipperDetails}) => {
        this.setState({
            shipperName: shipperName,
            shipperId: shipperId,
            shipperBranchName: shipperBranchName,
            shipperBranchId: shipperBranchId,
            showShipperPop:false,
            showShipperBranchPop:false
        }, () => {
            this.setBranchesSelectionFromList(this.state.shipperId)
        
            if (shipperDetails) {
                if (Object.keys(shipperDetails).length > 0) {
                    try {
                        let stateName = shipperDetails && shipperDetails.stateId && shipperDetails.stateId.name ? shipperDetails.stateId.name : "";
                        let Countriname = shipperDetails && shipperDetails.countryId && shipperDetails.countryId.name ? shipperDetails.countryId.name : "";
                        let pincode = shipperDetails.pincode !== ""?shipperDetails.pincode :"";
                        let contactName = shipperDetails.contactName !== ""?shipperDetails.contactName :"";
                        let contactNumber = shipperDetails.contactNumber !== ""?shipperDetails.contactNumber :"";
                        let email = shipperDetails.email !== ""?shipperDetails.email :"";

                        this.setState({
                            shipperContactName:contactName,
                            shipperContactNumber:contactNumber,
                            shipperDetails: shipperDetails.addressLine1 + "\n" + shipperDetails.addressLine2 + "\n " + shipperDetails.city + " " + stateName + " " + Countriname + " " + pincode + "",
                            shipperDetailsForBookingSyncForBackend: shipperDetails,
                            shipperEmail:email,
                            shipperPincode:pincode
                        },()=>{
                            if (shipperDetails && shipperDetails.pincode === ""){
                                this.setState({
                                    shipperpincode:true
                                })

                            } else {
                                this.setState({
                                    shipperpincode:false
                                })

                            }
                            if (shipperDetails && shipperDetails.contactName === ""){
                                this.setState({
                                    shipperContactvalue:true
                                })

                            } else {
                                this.setState({
                                    shipperContactvalue:false
                                })

                            }
                            if (shipperDetails && shipperDetails.contactNumber === ""){
                                this.setState({
                                    shipperNumbervalue:true
                                })

                            } else {
                                this.setState({
                                    shipperNumbervalue:false
                                })

                            }
                        })


                    } catch (e) {

                    }
                }
            }
            if (shipperName){
                this.setState({
                    showShipperPop:false,
                    showShipperBranchPop:true
                })
            }
            if (shipperBranchName) {
                this.setState({
                    showConsigneePop:true,
                    showShipperBranchPop:false
                })
            }
        })
    }
    allShipperDeatils = () =>{
        let shipperDetails= this.state.branchDetail
        if (shipperDetails) {
            if (Object.keys(shipperDetails).length > 0) {
                try {
                    let stateName = shipperDetails && shipperDetails.stateId && shipperDetails.stateId.name ? shipperDetails.stateId.name : "";
                    let Countriname = shipperDetails && shipperDetails.countryId && shipperDetails.countryId.name ? shipperDetails.countryId.name : "";
                    let pincode = shipperDetails.pincode !== ""?shipperDetails.pincode :"";
                    let contactName = shipperDetails.contactName !== ""?shipperDetails.contactName :"";
                        let contactNumber = shipperDetails.contactNumber !== ""?shipperDetails.contactNumber :"";
                    let email = shipperDetails.email !== ""?shipperDetails.email :"";
                        this.setState({
                            shippercontactShow:true,
                            shipperContactName:contactName,
                            shipperContactNumber:contactNumber,
                        shipperDetails: shipperDetails.addressLine1 + "\n" + shipperDetails.addressLine2 + "\n " + shipperDetails.city + " " + stateName + " " + Countriname + " " + pincode + "",
                        shipperDetailsForBookingSyncForBackend: shipperDetails,
                            shipperEmail:email,
                            shipperPincode:pincode
                        },()=>{
                            if (shipperDetails && shipperDetails.pincode === ""){
                                this.setState({
                                    shipperpincode:true
                                })
                            } else {
                                this.setState({
                                    shipperpincode:false
                                })
                            }
                            if (shipperDetails && shipperDetails.contactName === ""){
                                this.setState({
                                    shipperContactvalue:true
                                })
                            } else {
                                this.setState({
                                    shipperContactvalue:false
                                })


                            }
                            if (shipperDetails && shipperDetails.contactNumber === ""){
                                this.setState({
                                    shipperNumbervalue:true
                                })
                            } else {
                                this.setState({
                                    shipperNumbervalue:false
                                })


                            }
                        })

                } catch (e) {

                }
            }
        }
    }
    allConsigneeDeatils = () =>{
        let consigneeDetails= this.state.branchDetailConsignee
        if (consigneeDetails) {
            if (Object.keys(consigneeDetails).length > 0) {
                try {
                    let stateName = consigneeDetails && consigneeDetails.stateId && consigneeDetails.stateId.name ? consigneeDetails.stateId.name : "";
                    let Countriname = consigneeDetails && consigneeDetails.countryId && consigneeDetails.countryId.name ? consigneeDetails.countryId.name : "";
                    let pincode = consigneeDetails.pincode !== ""?consigneeDetails.pincode :"";
                    let contactName = consigneeDetails.contactName !== ""?consigneeDetails.contactName :"";
                    let contactNumber = consigneeDetails.contactNumber !== ""?consigneeDetails.contactNumber :"";
                    let email = consigneeDetails.email !== ""?consigneeDetails.email :"";
                    this.setState({
                        consigneeContactName:contactName,
                        consigneeContactNumber:contactNumber,
                        consigneeDetails: consigneeDetails.addressLine1 + "\n" + consigneeDetails.addressLine2 + "\n " + consigneeDetails.city + " " + stateName + " " + Countriname + " " + pincode  + "",
                        consigneeDetailsForBookingSyncForBackend: consigneeDetails,
                        consigneeEmail:email,
                        consigneePincode:pincode
                    },()=>{
                        if (consigneeDetails && consigneeDetails.pincode === ""){
                            this.setState({
                                consigneepincode:true
                            })
                        } else {
                            this.setState({
                                consigneepincode:false
                            })
                        }
                        if (consigneeDetails && consigneeDetails.contactName === ""){
                            this.setState({
                                consigneeContactvalue:true
                            })
                        } else {
                            this.setState({
                                consigneeContactvalue:false
                            })
                        }
                        if ( consigneeDetails && consigneeDetails.contactNumber === ""){
                            this.setState({
                                consigneeNumbervalue:true
                            })
                        }else {
                            this.setState({
                                consigneeNumbervalue:false
                            })


                        }
                    })

                } catch (e) {

                }
            }
        }
    }
    handleConsigneeSelected = ({
                                   consigneeName,
                                   consigneeId,
                                   consigneeBranchName,
                                   consigneeBranchId,
                                   consigneeDetails
                               }) => {

        this.setState({
            consigneeName: consigneeName,
            consigneeId: consigneeId,
            consigneeBranchName: consigneeBranchName,
            consigneeBranchId: consigneeBranchId,
            showConsigneePop:false,
            showConsigneeBranchPop:false

        }, () => {
            this.setConsigneeBranchesSelectionFromList(this.state.consigneeId)
            if (consigneeDetails) {
                if (Object.keys(consigneeDetails).length > 0) {
                    try {

                        let stateName = consigneeDetails && consigneeDetails.stateId && consigneeDetails.stateId.name ? consigneeDetails.stateId.name : "";
                        let Countriname = consigneeDetails && consigneeDetails.countryId && consigneeDetails.countryId.name ? consigneeDetails.countryId.name : "";
                        let pincode = consigneeDetails.pincode !== ""?consigneeDetails.pincode :"";
                        let email = consigneeDetails.email !== ""?consigneeDetails.email :"";
                        this.setState({
                            consigneeDetails: consigneeDetails.addressLine1 + "\n" + consigneeDetails.addressLine2 + "\n " + consigneeDetails.city + " " + stateName + " " + Countriname + " " + pincode  + "",
                            consigneeDetailsForBookingSyncForBackend: consigneeDetails,
                            consigneeEmail:email,
                            consigneePincode:pincode
                        },()=>{
                            if (consigneeDetails && consigneeDetails.pincode === ""){
                                this.setState({
                                    consigneepincode:true
                                })

                            } else {
                                this.setState({
                                    consigneepincode:false
                                })
                            }
                            if (consigneeDetails && consigneeDetails.contactName === ""){
                                this.setState({
                                    consigneeContactvalue:true
                                })

                            } else {
                                this.setState({
                                    consigneeContactvalue:false
                                })
                            }
                            if ( consigneeDetails && consigneeDetails.contactNumber === ""){
                                this.setState({
                                    consigneeNumbervalue:true
                                })

                            }else {
                                this.setState({
                                    consigneeNumbervalue:false
                                })
                            }
                        })


                    } catch (e) {

                    }
                }
            }
            if (consigneeName){
                this.setState({
                    showConsigneePop:false,
                    showConsigneeBranchPop:true
                })
            }
            if (consigneeBranchName) {
                this.setState({
                    showConsigneeBranchPop:false
                })
            }
        })

    }
    checkContainer = () => {

// use for cheking another mbl have to produce or not .
        if (this.state.shipmentMode === "LCL") {
            if (this.state.totalPiecesFromQuery == this.state.NoOfPiecesForMBL) {
                this.setState({showNewButton: false}, () => {
                    // this.props.sendNext(true)
                    this.props.sendNextToDashboard(true)
                })
            } else if (this.state.totalPiecesFromQuery > this.state.NoOfPiecesForMBL) {
                this.setState({showNewButton: true}, () => {
                    // this.props.sendNext(false)
                    this.props.sendNextToDashboard(false)
                })
            } else if (this.state.totalPiecesFromQuery < this.state.NoOfPiecesForMBL) {
                toast.error(" Pieces Exceed ,Please Enter Valid Number of pieces.", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({showNewButton: false, NoOfPiecesForMBL: ""}, () => {
                    // this.props.sendNext(true)
                    this.props.sendNextToDashboard(true)
                })
            }

        } else {
            if (this.state.totalQueryContainer == this.state.NoOfContainer) {
                this.setState({showNewButton: false}, () => {
                    // this.props.sendNext(true)
                    this.props.sendNextToDashboard(true)
                })
            } else if (this.state.totalQueryContainer > this.state.NoOfContainer) {
                this.setState({showNewButton: true}, () => {
                    // this.props.sendNext(false)
                    this.props.sendNextToDashboard(false)
                })
            } else if (this.state.totalQueryContainer < this.state.NoOfContainer) {
                toast.error(" Conatiner Exceed ,Please Enter Valid Number of containers.", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({showNewButton: false, NoOfContainer: ""}, () => {
                    // this.props.sendNext(true)
                    this.props.sendNextToDashboard(true)
                })
            }


        }

    }
    checkRemainingContainer = () => {
        if (this.state.shipmentMode === "FCL") {
            if (this.state.remainingContainer == this.state.NoOfContainer) {
                this.setState({showNewButton: false}, () => {
                    // this.props.sendNext(true)
                    this.props.sendNextToDashboard(true)
                })
            } else if (this.state.remainingContainer > this.state.NoOfContainer) {
                this.setState({showNewButton: true}, () => {
                    // this.props.sendNext(false)
                    this.props.sendNextToDashboard(false)
                })
            } else if (this.state.remainingContainer < this.state.NoOfContainer) {
                toast.error(" Conatiner Exceed ,Please Enter Valid Number of containers.", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({showNewButton: false, NoOfContainer: ""}, () => {
                    // this.props.sendNext(true)
                    this.props.sendNextToDashboard(true)
                })
            }
        } else {
            if (this.state.remainingContainer == this.state.NoOfPiecesForMBL) {
                this.setState({showNewButton: false}, () => {
                    // this.props.sendNext(true)
                    this.props.sendNextToDashboard(true)
                })
            } else if (this.state.remainingContainer > this.state.NoOfPiecesForMBL) {
                this.setState({showNewButton: true}, () => {
                    // this.props.sendNext(false)
                    this.props.sendNextToDashboard(false)
                })
            } else if (this.state.remainingContainer < this.state.NoOfPiecesForMBL) {
                toast.error(" Pieces Exceed ,Please Enter Valid Number of Pieces.", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({showNewButton: false, NoOfPiecesForMBL: ""}, () => {
                    // this.props.sendNext(true)
                    this.props.sendNextToDashboard(true)
                })
            }
        }
    }
    updateRates = async (data) => {
        return await RestClient({
          url: this.state.queryDataForcard && this.state.queryDataForcard.queryFor === "Courier" ? UPDATE_RATE_FOR_CREDIT_COURIER : UPDATE_RATE_FOR_CREDIT_ROAD,
          method: "POST",
          data: data,
        });
      };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.refBookingNo != prevState.refBookingNo) {
            this.getbookingData({ id: this.state.refBookingNo }).then(bookingData => {
                if (bookingData.success) {
                    let BookingResult = bookingData && bookingData.result ? bookingData.result : "";
                    let fields = {}
                    fields.incoTerms = BookingResult && BookingResult.quoteId && BookingResult.quoteId.incoTerms ? BookingResult.quoteId.incoTerms : "";
                    fields.accountType = BookingResult && BookingResult.accountType ? BookingResult.accountType : "";
                    fields.shipperName = BookingResult && BookingResult.shipperId && BookingResult.shipperId.shipperName ? BookingResult.shipperId.shipperName : "";
                    fields.shipperId = BookingResult && BookingResult.shipperId && BookingResult.shipperId._id ? BookingResult.shipperId._id : "";
                    fields.shipperContactName = BookingResult && BookingResult.shipperContactName ? BookingResult.shipperContactName : "";
                    fields.shipperContactNumber = BookingResult && BookingResult.shipperContactNumber ? BookingResult.shipperContactNumber : "";
                    fields.shipperBranchName = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.branchName ? BookingResult.shipperBranchId.branchName : "";
                    fields.shipperBranchId = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId._id ? BookingResult.shipperBranchId._id : "";
                    fields.shipperAccountNo = BookingResult && BookingResult.shipperAccountNo ? BookingResult.shipperAccountNo : "";
                    fields.shipperEmail = BookingResult && BookingResult.shipperEmail ? BookingResult.shipperEmail:""
                    fields.shipperDetails = BookingResult && BookingResult.shipperDetails ? BookingResult.shipperDetails : "";
                    fields.consigneeName = BookingResult && BookingResult.consigneeId && BookingResult.consigneeId.consigneeName ? BookingResult.consigneeId.consigneeName : "";
                    fields.consigneeId = BookingResult && BookingResult.consigneeId && BookingResult.consigneeId._id ? BookingResult.consigneeId._id : "";

                    fields.consigneeAccountNo = BookingResult && BookingResult.consigneeAccountNo ? BookingResult.consigneeAccountNo : "";
                    fields.consigneeEmail = BookingResult && BookingResult.consigneeEmail ? BookingResult.consigneeEmail:""
                    fields.consigneeContactName = BookingResult && BookingResult.consigneeContactName ? BookingResult.consigneeContactName : "";
                    fields.consigneeContactNumber = BookingResult && BookingResult.consigneeContactNumber ? BookingResult.consigneeContactNumber : "";
                    fields.consigneeBranchName = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.branchName ? BookingResult.consigneeBranchId.branchName : "";
                    fields.consigneeBranchId = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId._id ? BookingResult.consigneeBranchId._id : "";
                    fields.consigneeDetails = BookingResult && BookingResult.consigneeDetails ? BookingResult.consigneeDetails : "";
                    fields.consigneePincode = BookingResult && BookingResult.consigneePincode ? BookingResult.consigneePincode : "";
                    fields.shipperPincode = BookingResult && BookingResult.shipperPincode ? BookingResult.shipperPincode : "";

                    
                    
                    this.setState({
                        shipperPincode:fields.shipperPincode,
                        consigneePincode:fields.consigneePincode,
                        incoTerms : fields.incoTerms,
                        accountType : fields.accountType,
                        shipperName : fields.shipperName,
                        shipperId : fields.shipperId,
                        shipperContactName : fields.shipperContactName,
                        shipperContactNumber : fields.shipperContactNumber,
                        shipperBranchName : fields.shipperBranchName,
                        shipperBranchId : fields.shipperBranchId,
                        shipperAccountNo : fields.shipperAccountNo,
                        shipperEmail:fields.shipperEmail,
                        consigneeEmail:fields.consigneeEmail,
                        shipperDetails : fields.shipperDetails,
                        consigneeName : fields.consigneeName,
                        consigneeId : fields.consigneeId,
                        consigneeAccountNo : fields.consigneeAccountNo,
                        consigneeContactName : fields.consigneeContactName,
                        consigneeContactNumber : fields.consigneeContactNumber,
                        consigneeBranchName : fields.consigneeBranchName,
                        consigneeBranchId : fields.consigneeBranchId,
                        consigneeDetails : fields.consigneeDetails,

                    })
                }
            })
        }
        if (this.props.shipperModel && this.props.shipperModel !== prevProps.shipperModel) {
            this.setState({
                shipperModel: this.props.shipperModel
            }, () => {
                let creditDays = localStorage.getItem('creditDays')
                let selectedCharge = JSON.parse(localStorage.getItem('selectedCharge'))
                if(creditDays!= ''){
                  this.updateRates({toUpdate  :selectedCharge && selectedCharge._id,creditDaysStatus : true,rateStatus :false,creditDays : creditDays}).then(res=>{
                    if(res.success){
                    //   localStorage.removeItem('creditDays')
                    }
                  })
                }
                this.companyAndBranches().then(response => {
                    {
                        let branchDropdownOptions = [];
                        if (response && response.success) {
                            response.result &&
                            response.result.map(branchData => {

                                let branchDataObj = {};
                                branchDataObj.label = branchData.branchName;
                                branchDataObj.value = branchData.branchCode;
                                branchDataObj.prefixQuote = branchData.prefixQuote;
                                branchDropdownOptions.push(branchDataObj);

                            });
                            this.setState({branchDropdownOptions: branchDropdownOptions});
                        }
                    }
                })
                    .catch();
            })
        }
        if (this.props.message && this.props.message !== prevProps.message) {
            toast.success(this.props.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                showNewShipper: false,
                showNewConsignee: false,
                OldShipperConsigneeHide: false,
                reloadConsignee: true,
                reloadShipper: true,

            },()=>{
                this.shipperData()
                this.consigneeData()
            })
        }

        if (this.props.queryDataForcard && this.props.queryDataForcard !== prevProps.queryDataForcard) {

            this.setState({
                queryDataForcard: this.props.queryDataForcard
            }, () => {

                this.setState({
                    quoteData: this.state.queryDataForcard,
                    totalGross :this.state.queryDataForcard && this.state.queryDataForcard.grossWeight ? this.state.queryDataForcard.grossWeight : "",
                    activityType :this.state.queryDataForcard && this.state.queryDataForcard.activityType ? this.state.queryDataForcard.activityType : "",
                    queryFor :this.state.queryDataForcard && this.state.queryDataForcard.queryFor ? this.state.queryDataForcard.queryFor : "",
                    totalVolume :this.state.queryDataForcard && this.state.queryDataForcard.volumeWeight ? this.state.queryDataForcard.volumeWeight : "",
                    chargeableWeight :this.state.queryDataForcard && this.state.queryDataForcard.chargeableWeight ? this.state.queryDataForcard.chargeableWeight : "",
                    // chargeable :this.state.queryDataForcard.weight && this.state.queryDataForcard.weight.chargeable ? this.state.queryDataForcard.weight.chargeable : "",
                    NoOfContainer :this.state.queryDataForcard.noOfContainers ? this.state.queryDataForcard.noOfContainers : "",
                    totalQueryContainer: this.state.queryDataForcard.noOfContainers ? this.state.queryDataForcard.noOfContainers : "",
                    totalPiecesFromQuery: this.state.queryDataForcard.totalPieces ? this.state.queryDataForcard.totalPieces : "",
                    shipmentMode: this.state.queryDataForcard.shipmentMode ? this.state.queryDataForcard.shipmentMode : "",
                    containerType:this.state.queryDataForcard.containerType ? this.state.queryDataForcard.containerType : [],
                })
                if(this.state.queryDataForcard.shipmentMode === "FCL"){
                    this.fclOptions(this.state.queryDataForcard.containerType)
                }
                if(localStorage.getItem("already_registered") === "true" ){
                    let QueryId = localStorage.getItem("queryNo");
                    let Id = JSON.parse(QueryId)

                    this.getQuoteData({ id: Id }).then(res => {
                        if (res.success) {
                            this.setState({
                                refBookingNo: res && res.result.refBookingId,
                            }, () => {
                            })
                        }
                    })
                }
                this.props.sendNextToDashboard(true)


            })
        }
        if (this.props.newShipper && this.props.newShipper !== prevProps.newShipper) {
            if (this.props.newShipper === true) {
                this.setState({
                    showNewShipper: true,
                    showNewConsignee: true,
                    OldShipperConsigneeHide: true
                })
            }
        }

        if (this.props.OpenPerForma && this.props.OpenPerForma !== prevProps.OpenPerForma) {

            if (this.props.BookingMassage && this.props.BookingMassage.success === true) {
                // let bookingid = this.props.BookingDataFromRevert && this.props.BookingDataFromRevert._id ? this.props.BookingDataFromRevert._id : ""
                if (this.props.OpenPerForma === "Rate Inserted Successfully") {
                    if (this.props.container !== undefined) {
                        this.setState({
                            remainingContainer: this.props.container,
                            checkAnotherValuation: true,
                            NoOfContainer: this.props.container,
                            totalGross: "",
                            totalVolume: "",
                            showNewButton: false,
                            checked: false,
                            NoMoreHawb: false
                        })
                        this.sendNextFunc()
                    }
                }
                if (this.props.OpenPerForma === "Booking Created Successfully") {
                    if (this.props.container !== undefined) {
                        this.setState({
                            remainingContainer: this.props.container,
                            checkAnotherValuation: true,
                            NoOfContainer: this.props.container,
                            totalGross: "",
                            totalVolume: "",
                            showNewButton: false,
                            checked: false,
                            NoMoreHawb: false
                        })
                        this.sendNextFunc()

                    }
                }
            }
            if (this.props.BookingMassage && this.props.BookingMassage.success === false) {
                this.setState({RateNotFoundModel: true})
            }

        }

    }
    getQuoteData = async (dataForServer) => {
        return await RestClient({
            url: this.state.queryDataForcard && this.state.queryDataForcard.queryFor === "Courier" ? E_DOCKET_REQUEST_REQUEST_COURIER : E_DOCKET_REQUEST_REQUEST_ROAD,
            method: "POST",
            data: dataForServer
        })
    };
    getbookingData = async (dataForServer) => {
        return await RestClient({
            url: this.state.queryDataForcard && this.state.queryDataForcard.queryFor === "Courier" ? BOOKING_MANAGER_MASTER_GET_DATA_COURIER : BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
            method: "POST",
            data: dataForServer
        })
    };
    fclOptions = (containers) => {
        // USE for Get Data from API And update conatinerllist state
        this.GetContainerData().then(result => {
            if (result && result.success) {
                let resultData = result && result.result ? result.result : ""

                if(Array.isArray(resultData) && resultData.length >=1){
                    let array = []
                    let MultipleDataSlab = containers;

                    // Upadating obj with adding some keys
                    resultData && resultData.map((obj, i) => {
                        MultipleDataSlab.map((itm, j) => {
                            if (obj.container == itm.name) {
                                obj.count = itm.count
                                obj.Initialcount = itm.count
                                this.state.TotalContainerCount[i] = itm.count
                                array.push(obj)
                            }

                        })

                    })
                    this.setState({ContainerList:array },()=>{
                        this.InitialConatinerList = array
                        // this.props.SendConatinerList (this.state.ContainerList)
                        // console.log("updating option container")
                    })
                }

            }
        })

    }

    calculateConatainerCount =()=>{

        let totalNumber = this.state.TotalContainerCount
        let sum = this.sumArray(totalNumber) // calcaulate to number of conatiner utilised
        return sum ;
    }
    sumArray = (input) => {

        if (toString.call(input) !== "[object Array]")
            return false;

        let total = 0;
        for (let i = 0; i < input.length; i++) {
            if (isNaN(input[i])) {
                continue;
            }
            total += Number(input[i]);
        }
        return total;
    }
    calculateConatainer = (item, i) => {
        let totalNumber = this.state.TotalContainerCount
        let sum = this.sumArray(totalNumber) // calcaulate to number of conatiner utilised
        this.setState({NoOfContainer : sum},()=>{
            if (this.state.checkAnotherValuation === true) {
                this.checkRemainingContainer() // for second MBL
            } else {
                this.checkContainer() // for first MBL
            }
        })
    }

    GetContainerData = async (dataForServer) => {
        return await RestClient({
            url: GET_FCL_CONTAINER_DATA,
            method: "POST",
            data: dataForServer
        });
    }
    sendNextFunc = () => {
        this.props.sendNextToDashboard(true)
    }
    validateConsigneePhonNumber = (value) => {
        if (!value) {
            toast.error("Please enter your Phone Number", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^\d{10}$/.test(value)) {

            toast.error("Phone Number Should be 10 digit", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                contactNumber: ""
            })
        }

    }
    ShowNewShipper = () => {
        this.setState({
            showNewShipper: !this.state.showNewShipper
        })
    }








    checkContainerForHouse = (i) => {

        if (this.state.shipmentMode === "LCL") {
            this.TotalPiecesMBL[i] = this.state.totalUsedPiecesByHouses
            let totalNumber = this.TotalPiecesMBL
            let sum = this.sumArray(totalNumber)

            if (sum <= this.state.NoOfPiecesForMBL) {
                if (this.state.totalUsedPiecesByHouses === this.state.NoOfPiecesForMBL) {
                    this.setState({NoMoreHawb: true})
                } else if (this.state.totalUsedPiecesByHouses < this.state.NoOfPiecesForMBL) {
                    if (sum == this.state.NoOfPiecesForMBL) {
                        this.setState({NoMoreHawb: true}, () => {
                            // alert(this.state.NoMoreHawb)
                        })
                    } else {
                        this.setState({NoMoreHawb: false}, () => {
                            // alert(this.state.NoMoreHawb)
                        })
                    }

                } else if (this.state.totalUsedPiecesByHouses > this.state.NoOfPiecesForMBL) {
                    toast.error(" Containers Exceed ,Please Enter Valid Number of containers in HBL.", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    let array = []
                    this.state.updateData.map((item, j) => {
                        if (i === j) {
                            item.noOfPieces = "";
                        }
                        array.push(item)
                    })
                    this.setState({
                        NoMoreHawb: false,
                        updateData: array,
                        houseContainerNumberZero: ""
                    })
                }
            } else {
                toast.error(" Containers Exceed ,Please Enter Valid Number of containers in HBL.", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                let array = []
                this.state.updateData.map((item, j) => {
                    if (i === j) {
                        item.NoOfContainer = "";
                    }
                    array.push(item)
                })
                this.setState({
                    NoMoreHawb: false,
                    updateData: array,
                    houseContainerNumberZero: ""
                })
            }

        } else {
            this.TotalContainer[i] = this.state.houseContainerNumber
            let totalNumber = this.TotalContainer
            let sum = this.sumArray(totalNumber)

            if (sum <= this.state.NoOfContainer) {
                if (this.state.houseContainerNumber === this.state.NoOfContainer) {
                    this.setState({NoMoreHawb: true})
                } else if (this.state.houseContainerNumber < this.state.NoOfContainer) {
                    if (sum == this.state.NoOfContainer) {
                        this.setState({NoMoreHawb: true}, () => {
                            // alert(this.state.NoMoreHawb)
                        })
                    } else {
                        this.setState({NoMoreHawb: false}, () => {
                            // alert(this.state.NoMoreHawb)
                        })
                    }

                } else if (this.state.houseContainerNumber > this.state.NoOfContainer) {
                    toast.error(" Containers Exceed ,Please Enter Valid Number of containers in HBL.", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    let array = []
                    this.state.updateData.map((item, j) => {
                        if (i === j) {
                            item.NoOfContainer = "";
                        }
                        array.push(item)
                    })
                    this.setState({
                        NoMoreHawb: false,
                        updateData: array,
                        houseContainerNumberZero: ""
                    })
                }
            } else {
                toast.error(" Containers Exceed ,Please Enter Valid Number of containers in HBL.", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                let array = []
                this.state.updateData.map((item, j) => {
                    if (i === j) {
                        item.NoOfContainer = "";
                    }
                    array.push(item)
                })
                this.setState({
                    NoMoreHawb: false,
                    updateData: array,
                    houseContainerNumberZero: ""
                })
            }
        }

    }

    sumArray = (input) => {

        if (toString.call(input) !== "[object Array]")
            return false;

        let total = 0;
        for (let i = 0; i < input.length; i++) {
            if (isNaN(input[i])) {
                continue;
            }
            total += Number(input[i]);
        }
        return total;
    }

    ShowNewConsignee = () => {
        if(this.state.showNewConsignee === true){
            this.setState({
                showNewConsignee: false

            })
        }else {
            this.setState({
                showNewConsignee: true

            })
        }

    }
    onCountrySelectedForShipper = ({country, countryCode, countryId}) => {
        // console.log("onCountrySelected::" + JSON.stringify(countryCode));

        this.setState({
            shipperCountryName: country,
            shipperCountryCode: countryCode,
            shipperCountryId: countryId
        });
    };
    onConsigneeCountrySelected = ({country, countryCode, countryId}) => {
        // console.log("onCountrySelected::" + JSON.stringify(countryCode));

        this.setState({
            consigneeCountryName: country,
            consigneeCountryCode: countryCode,
            consigneeCountryId: countryId
        });
    };


    onShipperStateSelected = ({stateName, stateCode, stateId}) => {
        // console.log("onStateSelected::" + JSON.stringify(stateCode));
        this.setState({
            shipperStateName: stateName,
            shipperStateCode: stateCode,
            shipperStateId: stateId
        });
    };

    onConsigneeStateSelected = ({stateName, stateCode, stateId}) => {
        // console.log("onStateSelected::" + JSON.stringify(stateCode));
        this.setState({
            consigneeStateName: stateName,
            consigneeStateCode: stateCode,
            consigneeStateId: stateId
        });
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});

    };
    handleEmail = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.keyCode === 13) {
            if (this.validateEmail(event.target.value)) {
                const tags = [...this.state.emailsSelect];
                tags.push(this.state.shipperContactEmail);

                this.setState({ emailsSelect: tags, shipperContactEmail: "",emailValidation:"" });
            } else {
                this.setState({
                    emailValidation : "* Please enter a valid email Id.",
                })
            }
        }
    };
    validateEmail = email => {
        let emailLower = String(email).toLowerCase();
        let re1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re2 = /^[a-zA-Z0-9][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re3 = /^.{1,64}@.{1,256}$/;
        let reOther = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re1.test(emailLower)){
            if(reOther.test(emailLower)){
                if(re.test(emailLower)){
                    if(re2.test(emailLower)){
                        if(re3.test(emailLower)){
                            return emailLower
                        }
                    }
                }
            }
        }
    };
    deleteEmails = (i, e) => {
        let emailsSelect = [...this.state.emailsSelect];
        emailsSelect.splice(i, 1);
        this.setState({ emailsSelect });
        e.preventDefault();
    };
    handleEmailConsignee = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.keyCode === 13) {
            if (this.validateEmail(event.target.value)) {
                const tags = [...this.state.emailsSelectConsignee];
                tags.push(this.state.consigneeContactEmail);

                this.setState({ emailsSelectConsignee: tags, consigneeContactEmail: "",emailValidationConsignee:"" });
            } else {
                this.setState({
                    emailValidationConsignee : "* Please enter a valid email Id.",
                })
            }
        }
    };
    deleteEmailsConsignee = (i, e) => {
        let emailsSelectConsignee = [...this.state.emailsSelectConsignee];
        emailsSelectConsignee.splice(i, 1);
        this.setState({ emailsSelectConsignee });
        e.preventDefault();
    };

    onIncoTermsSelected = (values) => {

        this.setState({
            incoTermsId: values.value,
            incoTerms: values.label,
        });
    };

    handleChangeForConsigneeConatact = e => {

        let input = e.target.value;
        this.setState({
            errors: {}
        }, () => {
            let errors = {};
            if (!input) {
                errors["phone"] = "Please enter your phone number.";
            }
            if (typeof input !== undefined) {

                let pattern = new RegExp(/^[0-9\b]+$/);
                if (!pattern.test(input["phone"])) {
                    errors["phone"] = "Please enter only number.";
                    this.setState({consigneeContact: ""});
                } else if (input["phone"].length !== 10) {
                    errors["phone"] = "Please enter valid phone number.";
                    this.setState({consigneeContact: ""});
                }

            }

            this.setState({
                errors: errors
            });
        });

    };
    validateForm(topData) {
        let formIsValid = true;
        if (!this.state.shipperId) {
            formIsValid = false;
            toast.error("Please select Sender Name", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (!this.state.shipperBranchId) {
            formIsValid = false;
            toast.error("Please select Sender Branch", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (!this.state.consigneeBranchId) {
            formIsValid = false;
            toast.error("Please select Receiver Branch", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (!this.state.consigneeId) {
            formIsValid = false;
            toast.error("Please select Receiver Name", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        // if (!this.state.incoTermsId) {
        //     formIsValid = false;
        //     toast.error("Please select Inco Terms", {
        //         position: "top-left",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // }
        // if (!this.state.accountType) {
        //     formIsValid = false;
        //     toast.error("Please select Account Type", {
        //         position: "top-left",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //
        // }

        if(Array.isArray(topData.houses)){
            for (let i = 0; i < topData.houses.length; i++) {
                let chouse=topData.houses[i]
                let newI=i+1
                if(formIsValid==true && (chouse.houseNumber=="" || chouse.houseNumber==undefined || chouse.houseNumber==null)){
                    formIsValid=false
                    toast.error("Please Provide House No."+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(formIsValid==true && (chouse.shipperId=="" || chouse.shipperId==undefined || chouse.shipperId==null)){
                    formIsValid=false
                    toast.error("Please Provide Sender "+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(formIsValid==true && (chouse.shipperBranchId=="" || chouse.shipperBranchId==undefined || chouse.shipperBranchId==null)){
                    formIsValid=false
                    toast.error("Please Provide Sender Branch"+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(formIsValid==true && (chouse.consigneeId=="" || chouse.consigneeId==undefined || chouse.consigneeId==null)){
                    formIsValid=false
                    toast.error("Please Provide Consignee"+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(formIsValid==true && (chouse.consigneeBranchId=="" || chouse.consigneeBranchId==undefined || chouse.consigneeBranchId==null)){
                    formIsValid=false
                    toast.error("Please Provide Receiver Branch"+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                // if(chouse.volumeWeight=="" || chouse.volumeWeight==undefined || chouse.volumeWeight==null){
                //     formIsValid=false
                // }
                // if(chouse.noOfPieces=="" || chouse.noOfPieces==undefined || chouse.noOfPieces==null){
                //     formIsValid=false
                // }
                // if(chouse.grossWeight=="" || chouse.grossWeight==undefined || chouse.grossWeight==null){
                //     formIsValid=false
                // }
            }
        }

        return formIsValid;
    }



    checkForValidatingHouseAndMasterData = message => {
        let quoteData =  this.state.quoteData
        let houseData = this.state.houseData
        let array = []

        let totalVolumeHouses=0
        let totalPiecesHouses=0
        let totalGrossHouses=0
        houseData && houseData.map((item, i) => {
            totalVolumeHouses=parseFloat(item.volumeWeight)+parseFloat(totalVolumeHouses)
            totalPiecesHouses=parseFloat(item.noOfPieces)+parseFloat(totalPiecesHouses)
            totalGrossHouses=parseFloat(item.grossWeight)+parseFloat(totalGrossHouses)
        })
        quoteData.volumeWeight=parseFloat(quoteData.volumeWeight).toFixed(2)
        quoteData.totalPieces=Math.round(quoteData.totalPieces)
        quoteData.grossWeight=parseFloat(quoteData.grossWeight).toFixed(2)

        totalVolumeHouses=parseFloat(totalVolumeHouses).toFixed(2)
        totalPiecesHouses=Math.round(totalPiecesHouses)
        totalGrossHouses=parseFloat(totalGrossHouses).toFixed(2)

        quoteData.totalVolumeHouses=totalVolumeHouses
        quoteData.totalPiecesHouses=totalPiecesHouses
        quoteData.totalGrossHouses=totalGrossHouses
        this.setState({quoteData})

    };

    handleCommodity = (values, i) => {
        let shipperlist = [...this.state.shipperlist];
        // alert(JSON.stringify(values.all.hsnCode))
        let HSCode = ""
        if (values.all != undefined) {
            HSCode = values.all.hsnCode
        }
        shipperlist[i] = {
            ...shipperlist[i],
            HSCode:HSCode,
        };
        this.setState({shipperlist},()=>{
            // this.props.shipmentItemReturn(shipperlist)
        });
    };
    handleShipmentInfoChange = (e, i) => {
        const {name, value} = e.target;
        let shipperlist = [...this.state.shipperlist];
        shipperlist[i] = {...shipperlist[i], [name]: value};
        this.setState({shipperlist}, () => {
            // this.calculateCharges(i)
            // this.props.shipmentItemReturn(shipperlist)
        });
    }
    AddShipperInfo = () => {
        this.setState(prevState => ({
            shipperlist: [...prevState.shipperlist, {
                HSCode: "",
                ProductDescription: "",
                itemQuantity: "",
                productDeclaredValue: "",
                itemRef: "",
                ShipmentTypeCode: "",
                PackageTypeCode: "",
            }]
        }))
    }
    RemoveShipperInfo = (j) => {
        let shipperlist = [...this.state.shipperlist];
        shipperlist.splice(j, 1);
        this.setState({shipperlist});
    }

    handleChangePT = e => {
        this.setState({[e.target.name]: e.target.value}, () => {

        });
    };
    toggleDimensionCollapse = () => {
        this.setState({dimensionCollapse: !this.state.dimensionCollapse});
    };
    toggleDimension = () => {

        this.setState({dimensionToggle: !this.state.dimensionToggle});
    };
    toggleShipmentDimension =() =>{
        this.setState({shipmentToggle: !this.state.shipmentToggle});
    }
    addNewDimension = async values => {
        // alert(values.length + " "+ values.width + " " + values.height + " " + values.pieces + " " +this.state.selectWeight);
        if (values.length && values.width && values.height && values.pieces) {
            values.selectWeight = this.state.selectWeight
            // if ((await this.isTotalPiecesExceed(values)) === true) {
            //     return
            // }
            await this.validateMaxDimensions(values);
            let dimensions = Array.isArray(this.state.uploadedDimensions) && this.state.uploadedDimensions.length >= 1 ? this.state.uploadedDimensions : [];
            let dimensionss=[]
            dimensions.push(values);
            this.setState({uploadedDimensions: dimensions}, () => {
                let totalPieces = 0
                for(let i=0;i<this.state.uploadedDimensions.length;i++){
                    totalPieces = totalPieces + Number(this.state.uploadedDimensions[i].pieces)
                }
                this.setState({
                    totalPiecesFromQuery : totalPieces
                })
            });
            this.calculateOverallDimensionData()

        } else {
        }
    }

    calculateOverallDimensionData() {

        let uploadedDimensions = this.state.uploadedDimensions;
        let totalVolume = this.state.totalVolume
        let totalGross = this.state.totalGross
        let totalGrossLbs = this.state.grossWeightLbs
        let totalPieces = this.state.totalPiecesFromQuery

        if (uploadedDimensions.length > 0) {
            totalVolume = 0
            totalGross = 0
            totalPieces = 0
        }
        for (let i = 0; i < uploadedDimensions.length; i++) {
            let vol = this.calculateVolume(uploadedDimensions[i])
            if (uploadedDimensions[i].selectWeight == "Lbs" && uploadedDimensions[i].converetd==undefined) {
                uploadedDimensions[i].gw_pc = parseFloatValue(uploadedDimensions[i].gw_pc) * 0.453592
                uploadedDimensions[i].selectWeight = "Kgs"
                uploadedDimensions[i].converetd = true
            }
            let gross = parseFloatValue(uploadedDimensions[i].gw_pc) * parseInt(uploadedDimensions[i].pieces)
            totalVolume = totalVolume + vol
            totalGross = parseFloatValue(parseFloatValue(totalGross) + gross);
            //totalGrossLbs = parseFloatValue(totalGross) * 0.453592
            totalGrossLbs = parseFloatValue(totalGross) * 2.20462;
            totalPieces = parseInt(totalPieces + parseInt(uploadedDimensions[i].pieces));
        }
        this.setState({uploadedDimensions})

        if (uploadedDimensions.length > 0) {
            this.currentEnteredData.totalVolume = totalVolume
            this.currentEnteredData.totalGross = totalGross
            this.currentEnteredData.totalGrossLbs = totalGrossLbs
            if(this.currentEnteredData.totalGross <=0)
            {
                this.currentEnteredData.totalGross = this.state.totalGross
            this.currentEnteredData.totalGrossLbs = this.state.grossWeightLbs
            this.setState({
                totalGross : this.currentEnteredData.totalGross
            })
            }
            else
            {
                this.currentEnteredData.totalGross = totalGross
                this.currentEnteredData.totalGrossLbs = totalGrossLbs
                this.setState({
                    totalGross : totalGross
                })

            }

            this.currentEnteredData.totalPieces = totalPieces
        } else {
            this.currentEnteredData.totalVolume = totalVolume
            this.currentEnteredData.totalGross = this.state.grossWeight
            this.currentEnteredData.totalGrossLbs = this.state.grossWeightLbs
            this.currentEnteredData.totalPieces = totalPieces
        }

        try {
            if (this.state.queryFor === "Ocean") {
            let volumeWeight = parseFloatValue(this.currentEnteredData.totalVolume);
            let grossWeight = parseFloatValue(this.currentEnteredData.totalGross) / 1000;
            let lclvol = 0;

            if (volumeWeight < 1) {
                lclvol = 1
            } else {
                lclvol = volumeWeight
            }
            if (this.state.shipmentMode === "LCL") {


                grossWeight > lclvol ?
                    this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                    :
                    this.currentEnteredData.totalChargeable = parseInt(lclvol)
            } else {
                grossWeight > volumeWeight ?
                    this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                    :
                    this.currentEnteredData.totalChargeable = parseInt(volumeWeight)
            }


            if (this.currentEnteredData.totalGross === "0") {
                this.currentEnteredData.totalDensity = "0"
            } else {
                let density = (volumeWeight / grossWeight) * 100;
                this.currentEnteredData.totalDensity = parseFloat(density).toFixed(2)
            }
        } else {
            let volumeWeight = parseFloatValue(this.currentEnteredData.totalVolume);
            let grossWeight = parseFloatValue(this.currentEnteredData.totalGross);

            grossWeight > volumeWeight ?
                this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                :
                this.currentEnteredData.totalChargeable = parseInt(volumeWeight)


            if (this.currentEnteredData.totalGross === "0") {
                this.currentEnteredData.totalDensity = "0"
            } else {
                let density = (volumeWeight / grossWeight) * 100;
                this.currentEnteredData.totalDensity = parseFloat(density).toFixed(2)
            }
        }
        } catch (e) {
            // alert(e)
        }

        this.setState({
            grossWeight: parseFloatValue(this.currentEnteredData.totalGross),
            grossWeightLbs: parseFloatValue(this.currentEnteredData.totalGrossLbs),
            volumeWeight: parseFloatValue(this.currentEnteredData.totalVolume).toFixed(3),
            chargeableWeight: parseFloatValue(this.currentEnteredData.totalChargeable),
            calculatedDensity: parseFloatValue(this.currentEnteredData.totalDensity),
            
        })
        return this.currentEnteredData;

    }

    calculateVolume = values => {
        let length = parseInt(values.length);
        let width = parseInt(values.width);
        let height = parseInt(values.height);
        let pieceCount = parseInt(values.pieces);

        if (this.state.selectedDimensionType === "CM") {
            return (length * width * height * pieceCount) / 5000;
        } else if (this.state.selectedDimensionType === "FT") {
            return (length * width * height * pieceCount) / 0.177;
        } else if (this.state.selectedDimensionType === "MM") {
            return (length * width * height * pieceCount) / 50000000 ;
        } else if (this.state.selectedDimensionType === "INCHES") {
            return (length * width * height * pieceCount) / 305.110;
        } else if (this.state.selectedDimensionType === "METERS") {
            return (length * width * height * pieceCount)/0.005;
        }

        // if(this.state.queryFor !== "Air")
        // {
        //     if (this.state.selectedDimensionType === "CM") {
        //         return (length * width * height * pieceCount) / 1000000;
        //     } else if (this.state.selectedDimensionType === "FT") {
        //         return (length * width * height * pieceCount) / 0.211888;
        //     } else if (this.state.selectedDimensionType === "MM") {
        //         return (length * width * height * pieceCount) / 1000000000;
        //     } else if (this.state.selectedDimensionType === "INCHES") {
        //         return (length * width * height * pieceCount) / 61023.8;
        //     } else if (this.state.selectedDimensionType === "METERS") {
        //         return (length * width * height * pieceCount);
        //     }
        // }
        // else
        // {
        //     if (this.state.selectedDimensionType === "CM") {
        //         return (length * width * height * pieceCount) / 6000;
        //     } else if (this.state.selectedDimensionType === "FT") {
        //         return (length * width * height * pieceCount) / 0.211888;
        //     } else if (this.state.selectedDimensionType === "MM") {
        //         return (length * width * height * pieceCount) / 6000000;
        //     } else if (this.state.selectedDimensionType === "INCHES") {
        //         return (length * width * height * pieceCount) / 366;
        //     } else if (this.state.selectedDimensionType === "METERS") {
        //         return (length * width * height * pieceCount) / 0.006;
        //     }
        // }

    };
    validateMaxDimensions = values => {
        if (this.state.selectedTariff === "oddDimensions") {
            return true;
        }
        let isValid = false;
        let length = values.length;
        let width = values.width;
        let height = values.height;

        if (this.state.selectedDimensionType === "CM") {
            if (
                length <= this.MAX_LENGTH_CM &&
                width <= this.MAX_WIDTH_CM &&
                height <= this.MAX_HEIGHT_CM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "FT") {
            if (
                length <= this.MAX_LENGTH_FT &&
                width <= this.MAX_WIDTH_FT &&
                height <= this.MAX_HEIGHT_FT
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "MM") {
            if (
                length <= this.MAX_LENGTH_MM &&
                width <= this.MAX_WIDTH_MM &&
                height <= this.MAX_HEIGHT_MM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "INCHES") {
            if (
                length <= this.MAX_LENGTH_INCH &&
                width <= this.MAX_WIDTH_INCH &&
                height <= this.MAX_HEIGHT_INCH
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "METERS") {
            if (
                length <= this.MAX_LENGTH_MTR &&
                width <= this.MAX_WIDTH_MTR &&
                height <= this.MAX_HEIGHT_MTR
            ) {
                isValid = true;
            }
        }
        if (isValid === false) {
            toastr.success(
                "Dimension Exceed Values ",
                "Tariff Mode Changed to Odd Dimension ",
                {
                    transitionIn: "bounceIn",
                    transitionOut: "bounceOut"
                }
            );
            // this.changeTariffModeTo("Odd Dimention");
        }

        return isValid;
    };
    Prev = (value) => {
        switch (value) {
            case 2:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: true,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: true,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 5:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: true,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1
                })
                break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: true,
            //         tab6: false,
            //         count: this.state.count - 1
            //     })
            //     break;
            default:
        }
    };
    Next = (value) => {
        switch (value) {
            case 1:
                if (this.validatorTab1.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: true,
                        tab3: false,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                } else {
                    this.validatorTab1.showMessages();
                    this.forceUpdate();
                }
                break;
            case 2:
                if(this.state.uploadedDimensions && this.state.uploadedDimensions.length === 0  && this.state.courierMode === "Parcel"){
                    toast.error("Please Add Dimension for Non-Documnet Query", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        tab2: true,
                       
                    });
                    this.forceUpdate();
                }else {
                    if (this.validatorTab2.allValid()) {
                        this.setState({
                            tab1: false,
                            tab2: false,
                            tab3: true,
                            tab4: false,
                            tab5: false,
                            // tab6: false,
                            count: this.state.count + 1,
                        });
                    } else {
                        this.validatorTab2.showMessages();
                        this.forceUpdate();
                    }
                    break;
                }
                
            
                
            default:
        }
    };
    Select = (value) => {
        switch (value) {
            case 1:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: value,
                });
                break;
            case 2:
                if (this.validatorTab1.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: true,
                        tab3: false,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: value,
                        showNewShipper:false,
                    showNewConsignee:false
                    });
                }else{
                    this.validatorTab1.showMessages();
                    this.forceUpdate();
                }
                break;
            case 3:
                if (this.validatorTab1.allValid() && this.validatorTab2.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: true,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: value,
                    });
                }else{
                    this.validatorTab1.showMessages();
                    this.validatorTab2.showMessages();
                    this.forceUpdate();
                }
                break;
            case 4:
                if (this.validatorTab1.allValid() && this.validatorTab2.allValid() && this.validatorTab3.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: false,
                        tab4: true,
                        tab5: false,
                        // tab6: false,
                        count: value,
                    });
                }else{
                    this.validatorTab1.showMessages();
                    this.validatorTab2.showMessages();
                    this.validatorTab3.showMessages();
                    this.forceUpdate();
                }
                break;
            case 5:
                if (this.validatorTab1.allValid() && this.validatorTab2.allValid() && this.validatorTab3.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: false,
                        tab4: false,
                        tab5: true,
                        // tab6: false,
                        count: value
                    })
                }else{
                    this.validatorTab1.showMessages();
                    this.validatorTab2.showMessages();
                    this.validatorTab3.showMessages();
                    this.forceUpdate();
                }
                break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: false,
            //         tab6: true,
            //         count: value
            //     })
            //     break;
            default:
        }
    };
    setBranchesSelectionFromList=(data) =>{
        if(data !== undefined){
            this.fetchShipperBranchList({id:data}).then(responce=>{
                if(responce && responce.result !== undefined){
                    this.setState({
                        shipperBranchDataList : responce && responce.result ? responce.result : []
                    })
                    let selectionOptionsListShipperBranch = []
                    for (let i = 0; i < responce.result.length; i++) {
                        let { _id, branchName } = responce.result[i]
                        let obj =
                            {
                                value: _id,
                                label: branchName,
                                branchDetail: responce.result[i],
                            };
                        selectionOptionsListShipperBranch.push(obj)
                    }

                    this.setState({
                        selectionOptionsShipperBranch: selectionOptionsListShipperBranch
                    });
                }
            })

        }

    }
    fetchShipperBranchList = async (shipperParameters) => {
        {
            return await RestClient({
                url: MANAGER_SHIPPER_BRANCH,
                method: "POST",
                data: shipperParameters
            });
        }
    };
    setConsigneeBranchesSelectionFromList=(data) =>{
        if(data !== undefined){
            this.fetchConsigneeBranchList({id:data}).then(responce=>{
                if(responce && responce.result !== undefined){
                    this.setState({
                        consigneeBranchDataList : responce && responce.result ? responce.result : []
                    })
                    let selectionOptionsListConsigneeBranch = []
                    for (let i = 0; i < responce.result.length; i++) {
                        let { _id, branchName } = responce.result[i]
                        let obj =
                            {
                                value: _id,
                                label: branchName,
                                branchDetail: responce.result[i],
                            };
                        selectionOptionsListConsigneeBranch.push(obj)
                    }

                    this.setState({
                        selectionOptionsConsigneeBranch: selectionOptionsListConsigneeBranch
                    });
                }
            })

        }

    }
    fetchConsigneeBranchList = async (shipperParameters) => {
        {
            return await RestClient({
                url: MANAGER_CONSIGNEE_BRANCH,
                method: "POST",
                data: shipperParameters
            });
        }
    };
    closeAllPop =() =>{
        this.setState({
            showIncoTermPop : false,
            showShipperBranchPop : false,
            showAccountTypePop : false,
            showShipperPop:false,
            showConsigneePop : false,
            showConsigneeBranchPop:false,
        })
    }
    render() {
        let shipperlist = this.state.shipperlist
        const { companyLogo} = this.state.comapny !== undefined ? this.state.comapny : {} ;
        let CompanyData;
        const {
            shipperName, branchName, addressLine1, addressLine2,
            countryId, countryName, stateId,ContainerList,
            stateName, city, pincode,
            others, contactPersonName,
            contactNumber, cinNumber,
            error,
            consigneeName, showNewConsignee, showNewShipper
        } = this.state;
        const initialValues = {
            shipperName,
            branchName,
            addressLine1,
            ContainerList,
            addressLine2,
            countryId,
            countryName,
            stateId,
            stateName,
            city,
            pincode,
            others,
            contactPersonName,
            contactNumber,
            cinNumber,
            error, consigneeName, showNewConsignee, showNewShipper
        };

        return (
            <>
                <Modal className="dangerous_pop dimen_editbooking_popup"
                       isOpen={this.state.dimensionToggle1}
                       size="lg"
                       toggle={this.toggleDimension}>
                    <ModalHeader toggle={this.toggleDimension} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                    </ModalHeader>
                    <ModalBody>
                        <div className="">
                            <div className="pop-text">
                                <h2>Dimension Details</h2>
                                <hr/>
                                <div className="dimension_types mt-4">
                                    <div className="d-flex flex-wrap align-items-center justify-content-start">
                                        <h4><strong>Dimension Type : </strong></h4>
                                        <div className="checkbox-widths">
                                            <CustomInput
                                                type="radio"
                                                id="CM"
                                                label="CM"
                                                name="selectedDimensionType"
                                                value={"CM"}
                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                checked={this.state.selectedDimensionType === "CM"}
                                            />
                                        </div>
                                        {/*<li>*/}
                                        {/*    <CustomInput*/}
                                        {/*        type="radio"*/}
                                        {/*        id="FT"*/}
                                        {/*        label="FT"*/}
                                        {/*        name="selectedDimensionType"*/}
                                        {/*        value={"FT"}*/}
                                        {/*        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}*/}
                                        {/*        checked={this.state.selectedDimensionType === "FT"}*/}
                                        {/*    />*/}
                                        {/*</li>*/}
                                        <div className="checkbox-widths">
                                            <CustomInput
                                                type="radio"
                                                id="MM"
                                                label="MM"
                                                name="selectedDimensionType"
                                                value={"MM"}
                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                checked={this.state.selectedDimensionType === "MM"}
                                            />
                                        </div>
                                        <div className="checkbox-widths">
                                            <CustomInput
                                                type="radio"
                                                id="INCHES"
                                                label="INCHES"
                                                name="selectedDimensionType"
                                                value={"INCHES"}
                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                checked={this.state.selectedDimensionType === "INCHES"}
                                            />
                                        </div>
                                        <div className="checkbox-widths">
                                            <CustomInput
                                                type="radio"
                                                id="METERS"
                                                label="METERS"
                                                name="selectedDimensionType"
                                                value={"METERS"}
                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                checked={this.state.selectedDimensionType === "METERS"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="AAA-add-query-dimension-7 calculate-inputs mt-4">
                                    <div className="AAA-editbooking-data-20">
                                        <label className="text-white" hidden={!this.state.allowedEdit}>Dimensions Info.</label>
                                    </div>
                                    <Formik hidden={!this.state.allowedEdit} validationSchema={formSchema}
                                            onSubmit={this.addNewDimension} initialValues={{}}>
                                        {({errors, touched}) => (
                                            <Form hidden={!this.state.allowedEdit}>
                                                {/*<Label>{this.state.dimensionOverload}</Label>*/}
                                                <div className="d-flex flex-wrap align-items-center justify-content-start mt-4 mb-40">
                                                    <div className="new-originbox length-widths">
                                                        <FormGroup>
                                                            <Label for="length">Length</Label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="length"
                                                                id="length"
                                                                placeholder="Length"
                                                                value={this.state.length}
                                                            />
                                                            {/*{errors.length && touched.length && (*/}
                                                            {/*    <div className="invalid-feedback d-block">*/}
                                                            {/*        {errors.length}*/}
                                                            {/*    </div>*/}
                                                            {/*)}*/}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="new-originbox length-widths">
                                                        <FormGroup>
                                                            <Label for="width">Width</Label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="width"
                                                                placeholder="Width"
                                                                id="width"
                                                                value={this.state.width}
                                                            />
                                                            {/*{errors.width && touched.width && (*/}
                                                            {/*    <div className="invalid-feedback d-block">*/}
                                                            {/*        {errors.width}*/}
                                                            {/*    </div>*/}
                                                            {/*)}*/}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="new-originbox length-widths">
                                                        <FormGroup>
                                                            <Label for="height">Height</Label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="height"
                                                                placeholder="Height"
                                                                id="height"
                                                                value={this.state.height}
                                                            />
                                                            {/*{errors.height && touched.height && (*/}
                                                            {/*    <div className="invalid-feedback d-block">*/}
                                                            {/*        {errors.height}*/}
                                                            {/*    </div>*/}
                                                            {/*)}*/}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="new-originbox dimens-widths">
                                                        <FormGroup>
                                                            <Label for="pieces">Pieces</Label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="pieces"
                                                                placeholder="Pieces"
                                                                value={this.state.pieces}
                                                            />
                                                            {/*{errors.pieces && touched.pieces && (*/}
                                                            {/*    <div className="invalid-feedback d-block">*/}
                                                            {/*        {errors.pieces}*/}
                                                            {/*    </div>*/}
                                                            {/*)}*/}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="new-originbox dimens-widths">
                                                        <FormGroup>
                                                            <Label for="selectWeight">Select Weight</Label>
                                                            <select
                                                                id="selectWeight"
                                                                className=" custom-select"
                                                                name="selectWeight"
                                                                placeholder="Select Weight"
                                                                value={this.state.selectWeight}
                                                                onChange={this.handleChange}
                                                            >
                                                                <option value="Kgs">Kgs</option>
                                                                <option value="Lbs">Lbs</option>

                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="new-originbox dimens-widths">
                                                        <FormGroup>
                                                            <Label for="Gw/Pc">Weight Per Piece</Label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="gw_pc"
                                                                placeholder="Gw/Pc"
                                                                value={this.state.gw_pc}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <button className="plus-signs" type="submit"
                                                            name="addDimension"
                                                            onClick={this.addNewDimension}>
                                                        <FontAwesomeIcon icon={faPlus}/>
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                <div className="AAA-add-query-dimension-7 calculate-inputs">
                                    <div className="AAA-editbooking-data-21">
                                        <Label className="text-white" onClick={this.toggleDimensionCollapse}>Entered Dimensions</Label>
                                    </div>
                                    <Collapse isOpen={this.state.dimensionCollapse}>
                                        {this.state.uploadedDimensions ? this.state.uploadedDimensions.map((dimensionRow, dimensionIndex) => {

                                            return (
                                                <Row>
                                                    <Col lg="12">
                                                        <div className="d-flex flex-wrap align-items-center justify-content-start" key={dimensionIndex}>
                                                            <div className="new-originbox length-widths">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="length"
                                                                        placeholder="Length"
                                                                        readOnly
                                                                        value={dimensionRow.length}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="new-originbox length-widths">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="width"
                                                                        placeholder="Width"
                                                                        readOnly
                                                                        value={dimensionRow.width}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="new-originbox length-widths">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="height"
                                                                        readOnly
                                                                        placeholder="Height"
                                                                        value={dimensionRow.height}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="new-originbox dimens-widths">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="pieces"
                                                                        readOnly
                                                                        placeholder="Pieces"
                                                                        value={dimensionRow.pieces}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="new-originbox dimens-widths">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="selectWeight"
                                                                        readOnly
                                                                        placeholder="Select Weight"
                                                                        value={dimensionRow.selectWeight}
                                                                    />

                                                                    {/*<select*/}
                                                                    {/*    id="selectWeight"*/}
                                                                    {/*    className="form-control"*/}
                                                                    {/*    name="selectWeight"*/}
                                                                    {/*    placeholder="Select Weight"*/}
                                                                    {/*    value={dimensionRow.selectWeight}*/}
                                                                    {/*>*/}
                                                                    {/*    <option value="">Select Weight Type</option>*/}
                                                                    {/*    <option value="Lbs">Lbs</option>*/}
                                                                    {/*    <option value="Kgs">Kgs</option>*/}

                                                                    {/*</select>*/}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="new-originbox dimens-widths">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="gw_pc"
                                                                        placeholder="Gw/Pc"
                                                                        readOnly
                                                                        value={dimensionRow.gw_pc}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <Button className="plus-signs"
                                                                    disabled={!this.state.allowedEdit}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        let dimension = this.state.uploadedDimensions;
                                                                        dimension.splice(dimensionIndex, 1);
                                                                        this.setState({uploadedDimensions: dimension}, () => {
                                                                            let totalPieces = 0
                                                                            for(let i=0;i<this.state.uploadedDimensions.length;i++){
                                                                                totalPieces = totalPieces + Number(this.state.uploadedDimensions[i].pieces)
                                                                            }
                                                                            this.setState({
                                                                                totalPiecesFromQuery : totalPieces
                                                                            })
                                                                        });
                                                                    }}
                                                                    name="addDimension"
                                                            > <FontAwesomeIcon icon={faTimes}/></Button>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            );
                                        }) : ""}
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.shipperModel}
                    size="lg"
                    className="ships-model shipper_con_pop"
                    toggle={this.toggleLarge}
                >
                    <ModalHeader toggle={this.toggleLarge} className="justify-content-center p-0">
                        {/*<div className="text-center textChange">*/}
                        {/*    <button type="button" className="btn gst-btn gst-top" onClick={() => this.Select(1)}*/}
                        {/*            className={this.state.tab1 ? "gst-btn gst-top " : "gst-btn"}>Sender Details</button>*/}
                        {/*    <button type="button" className="btn gst-btn" onClick={() => this.Select(2)}*/}
                        {/*            className={this.state.tab2 ? "gst-btn gst-top" : "gst-btn"}>Receiver Details</button>*/}
                        {/*    <button type="button" className="btn gst-btn" onClick={() => this.Select(3)}*/}
                        {/*            className={this.state.tab3 ? "gst-btn gst-top" : "gst-btn"}>Query Details</button>*/}
                        {/*    <button type="button" className="btn gst-btn" onClick={() => this.Select(4)}*/}
                        {/*            className={this.state.tab4 ? "gst-btn gst-top" : "gst-btn"}>Shipment Details</button>*/}

                        {/*</div>*/}
                    </ModalHeader>
                    <ModalBody className="shipper_con_pop">
                        <div className="container-fluid width_change">
                            <div className="banner_form_text">
                                <div className="mode_texts">
                                    <div className={this.state.tab1 ?"airModes active":"airModes"} onClick={() => this.Select(1)}>Sender/Receiver Details</div>
                                    <div className={this.state.tab2 ?"airModes active":"airModes"} onClick={() => this.Select(2)}>Query Details</div>
                                    <div className={this.state.tab3 ?"airModes active":"airModes"} onClick={() => this.Select(3)}>Shipment Details</div>
                                </div>
                                <div className="banner_text_background">
                                    {/* {this.state.tab1?<div className="activity_types">
                                        <div className="form-check form-check-inline">
                                            <FormGroup>
                                                <CustomInput
                                                    type="checkbox"
                                                    checked={this.state.showNewShipper}
                                                    onClick={() => this.setState({showNewShipper:!this.state.showNewShipper,showNewConsignee:false,shipperName:""})}
                                                    label="Add New Sender"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <FormGroup>
                                                <CustomInput
                                                    type="checkbox"
                                                    checked={this.state.showNewConsignee}
                                                    onClick={() => this.setState({showNewConsignee:!this.state.showNewConsignee,showNewShipper:false,consigneeName:""})}
                                                    label="Add New Receiver"
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>:""} */}
                                    {/*/////////////////////////////////Shipper and Consignee/////////////////////*/}
                                    {this.state.tab1?
                                        this.state.showNewShipper || this.state.showNewConsignee?
                                            this.state.showNewShipper?
                                                <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Sender Name/Address</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperPop : !this.state.showEnterShipperPop,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showEnterShipperContact:false,
                                                                showConsigneePop : false,
                                                                showEnterShipperCountry : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperName?this.state.shipperName:"Sender Name"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperPop !== true}>
                                                            <div className="popup_new origin_lables_door">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="shipperName">Sender Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="shipperName"
                                                                               name="shipperName"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperName === undefined ? "" : this.state.shipperName}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="addressLine1">Address Line 1 <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="addressLine1"
                                                                               name="shipperAddressLine1"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperAddressLine1 === undefined ? "" : this.state.shipperAddressLine1}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="addressLine2">Address Line 2</Label>
                                                                        <Input type="text" id="shipperAddressLine2"
                                                                               name="shipperAddressLine2" onChange={this.handleChange}
                                                                               value={this.state.shipperAddressLine2 === undefined ? "" : this.state.shipperAddressLine2}
                                                                        />
                                                                    </FormGroup>
                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.shipperName == ""){
                                                                                toast.error("Please Fill Sender Name", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.shipperAddressLine1 == ""){
                                                                                toast.error("Please Fill Sender Address", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else {
                                                                            this.setState({
                                                                                showEnterShipperCountry:true,
                                                                                showEnterShipperPop:false
                                                                            })
                                                                        }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Sender Country/State/City/Pincode</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperCountry : !this.state.showEnterShipperCountry,
                                                                showEnterShipperPop : false,
                                                                showEnterShipperContact : false,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperCountryName?this.state.shipperCountryName:"Sender Country"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperCountry !== true}>
                                                            <div className="popup_new origin_lables_door">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="countryId">Select Country <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <CountryDropDown
                                                                            isCountry={true}
                                                                            handler={this.onCountrySelectedForShipper}
                                                                            selectedCountry={
                                                                                this.state.shipperCountryId === undefined
                                                                                    ? ""
                                                                                    : this.state.shipperCountryId
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <StateDropdown
                                                                            handler={this.onShipperStateSelected}
                                                                            Lable={"Select State" }
                                                                            isState={true}
                                                                            selectedState={
                                                                                this.state.shipperStateId === undefined
                                                                                    ? ""
                                                                                    : this.state.shipperStateId
                                                                            }
                                                                            countryId={this.state.shipperCountryId}
                                                                            fromShipperConsignee={true}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="city">City Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="city"
                                                                               name="shipperCity"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperCity === undefined ? "" : this.state.shipperCity}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="pincode">Pincode <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="pincode"
                                                                               name="shipperPincode" onChange={this.handleChange}
                                                                               value={this.state.shipperPincode === undefined ? "" : this.state.shipperPincode}
                                                                        />
                                                                    </FormGroup>
                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.shipperCountryId == ""){
                                                                                toast.error("Please Fill Sender Country", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.shipperStateId == ""){
                                                                                toast.error("Please Fill Sender State", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.shipperCity == ""){
                                                                                toast.error("Please Fill Sender City", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.shipperPincode == ""){
                                                                                toast.error("Please Fill Sender pincode", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }
                                                                            else {
                                                                            this.setState({
                                                                                showEnterShipperCountry:false,
                                                                                showEnterShipperContact:true
                                                                            })
                                                                        }

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Sender Contact Details</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperContact : !this.state.showEnterShipperContact,
                                                                showEnterShipperPop : false,
                                                                showEnterShipperCountry : false,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperContact?this.state.shipperContact:"Sender Contact"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperContact !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="others">Contact Number  <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="number" id="others"
                                                                               name="shipperContact"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperContact === undefined ? "" : this.state.shipperContact}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="consigneeContactName">Contact Name</Label>
                                                                        <Input type="text" id="shipperContactName"
                                                                               name="shipperContactName"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperContactName === undefined ? "" : this.state.shipperContactName}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="consigneeContactName">Contact Email</Label>
                                                                        <Input type="text" id="shipperContactEmail"
                                                                               name="shipperContactEmail"
                                                                               onKeyUp={(e) => {
                                                                                   this.handleEmail(e);
                                                                               }}
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperContactEmail === undefined ? "" : this.state.shipperContactEmail}
                                                                        />
                                                                        <p style={{color:'red'}}>{this.state.emailValidation}</p>
                                                                        <div className="display-block">
                                                                            {this.state.emailsSelect &&
                                                                            this.state.emailsSelect.map(
                                                                                (itm, indx) => {
                                                                                    return (
                                                                                        <div className="mail-group">
                                                                                            <div className="kyc_email">
                                                                                                {itm}{" "}
                                                                                                <span
                                                                                                    className="cross_email_kyc"
                                                                                                    onClick={(e) =>
                                                                                                        this.deleteEmails(indx, e)
                                                                                                    }
                                                                                                >
                                                                                X</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                   {/*         <span className="text-danger">
                                                              {this.validatorTab1.message(
                                                                  "emailsSelect",
                                                                  this.state.emailsSelect,
                                                                  "required"
                                                              )}
                                                            </span>*/}
                                                                        </div>

                                                                    </FormGroup>

                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.shipperContact == ""){
                                                                                toast.error("Please Fill Sender Contact", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else {
                                                                            this.setState({
                                                                                showEnterShipperContact:false
                                                                            })
                                                                        }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Receiver Name/Address</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperPop : !this.state.showEnterShipperPop,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showEnterShipperContact:false,
                                                                showConsigneePop : false,
                                                                showEnterShipperCountry : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeName?this.state.consigneeName:"Receiver Name"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperPop !== true}>
                                                            <div className="popup_new origin_lables_door">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="shipperName">Receiver Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="consigneeName"
                                                                               placeholder="Receiver Name"
                                                                               name="consigneeName"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeName === undefined ? "" : this.state.consigneeName}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="addressLine1">Address Line 1 <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="addressLine1"
                                                                               placeholder="Address Line 1"
                                                                               name="consigneeAddressLine1"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeAddressLine1 === undefined ? "" : this.state.consigneeAddressLine1}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="addressLine2">Address Line 2</Label>
                                                                        <Input type="text" id="addressLine2"
                                                                               placeholder="Address Line 2"
                                                                               name="consigneeAddressLine2" onChange={this.handleChange}
                                                                               value={this.state.consigneeAddressLine2 === undefined ? "" : this.state.consigneeAddressLine2}
                                                                        />
                                                                    </FormGroup>
                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.consigneeName == ""){
                                                                                toast.error("Please Fill Receiver Name", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.consigneeAddressLine1 == ""){
                                                                                toast.error("Please Fill Receiver Address", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else {
                                                                            this.setState({
                                                                                showEnterShipperPop:false,
                                                                                showEnterShipperCountry:true
                                                                            })
                                                                        }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Receiver Country/State/City/Pincode</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperCountry : !this.state.showEnterShipperCountry,
                                                                showEnterShipperPop : false,
                                                                showEnterShipperContact : false,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeCountryName?this.state.consigneeCountryName:"Receiver Country"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperCountry !== true}>
                                                            <div className="popup_new origin_lables_door">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="countryId">Select Country <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <CountryDropDown
                                                                            isCountry={true}
                                                                            handler={this.onConsigneeCountrySelected}
                                                                            selectedCountry={
                                                                                this.state.consigneeCountryId === undefined
                                                                                    ? ""
                                                                                    : this.state.consigneeCountryId
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <StateDropdown
                                                                            handler={this.onConsigneeStateSelected}
                                                                            isState={true}
                                                                            Lable={"Select State"}
                                                                            selectedState={
                                                                                this.state.consigneeStateId === undefined
                                                                                    ? ""
                                                                                    : this.state.consigneeStateId
                                                                            }
                                                                            countryId={this.state.consigneeCountryId}
                                                                            fromShipperConsignee={true}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="city">City Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="city"
                                                                               placeholder="City"
                                                                               name="consigneeCity"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeCity === undefined ? "" : this.state.consigneeCity}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="pincode">Pincode <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="pincode"
                                                                               placeholder="Pincode"
                                                                               name="consigneePincode" onChange={this.handleChange}
                                                                               value={this.state.consigneePincode === undefined ? "" : this.state.consigneePincode}
                                                                        />
                                                                    </FormGroup>
                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.consigneeCountryId == ""){
                                                                                toast.error("Please Fill Receiver Country", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.consigneeStateId == ""){
                                                                                toast.error("Please Fill Receiver State", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.consigneeCity == ""){
                                                                                toast.error("Please Fill Receiver City", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }
                                                                            else if(this.state.consigneePincode == ""){
                                                                                toast.error("Please Fill Receiver Pincode", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }
                                                                            else {
                                                                            this.setState({
                                                                                showEnterShipperCountry:false,
                                                                                showEnterShipperContact:true
                                                                            })
                                                                        }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Receiver Contact Details</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperContact : !this.state.showEnterShipperContact,
                                                                showEnterShipperPop : false,
                                                                showEnterShipperCountry : false,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeContact?this.state.consigneeContact:"Receiver Contact"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperContact !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="others">Contact Number  <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="number" id="others"
                                                                               name="consigneeContact"
                                                                            // onBlur={e=>{this.validateConsigneePhonNumber(e.target.value)}}
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeContact === undefined ? "" : this.state.consigneeContact}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="consigneeContactName">Contact Name</Label>
                                                                        <Input type="text" id="consigneeContactName"
                                                                               name="consigneeContactName"
                                                                            // onBlur={e=>{this.validateConsigneePhonNumber(e.target.value)}}
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeContactName === undefined ? "" : this.state.consigneeContactName}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="consigneeContactName">Contact Email</Label>
                                                                        <Input type="text" id="consigneeContactEmail"
                                                                               name="consigneeContactEmail"
                                                                            // onBlur={e=>{this.validateConsigneePhonNumber(e.target.value)}}
                                                                               onKeyUp={(e) => {
                                                                                   this.handleEmailConsignee(e);
                                                                               }}
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeContactEmail === undefined ? "" : this.state.consigneeContactEmail}
                                                                        />
                                                                        <p style={{color:'red'}}>{this.state.emailValidationConsignee}</p>
                                                                        <div className="display-block">
                                                                            {this.state.emailsSelectConsignee &&
                                                                            this.state.emailsSelectConsignee.map(
                                                                                (itm, indx) => {
                                                                                    return (
                                                                                        <div className="mail-group">
                                                                                            <div className="kyc_email">
                                                                                                {itm}{" "}
                                                                                                <span
                                                                                                    className="cross_email_kyc"
                                                                                                    onClick={(e) =>
                                                                                                        this.deleteEmailsConsignee(indx, e)
                                                                                                    }
                                                                                                >
                                                                                X</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                            {/* <span className="text-danger">
                                                              {this.validatorTab1.message(
                                                                  "emailsSelectConsignee",
                                                                  this.state.emailsSelectConsignee,
                                                                  "required"
                                                              )}
                                                            </span> */}
                                                                        </div>

                                                                    </FormGroup>

                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.consigneeContact == ""){
                                                                                toast.error("Please Fill Receiver Contact", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else {
                                                                            this.setState({
                                                                                showEnterShipperContact:false,
                                                                            })
                                                                        }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                            <div className="d-flex flex-wrap justify-content-between houseShipper shipperConsigneeDatails mb-3">
                                                <div className="two_part_shipCong">
                                                    <div className="new_banner_input new_shipper_inputs">
                                                        <label>Sender Name</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showShipperPop : !this.state.showShipperPop,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperName?this.state.shipperName:"Sender Name"}</p>
                                                        </div>
                                                        <span className="text-danger">{this.validatorTab1.message(
                                                            "Sender Name",
                                                            this.state.shipperId,
                                                            "required"
                                                        )}</span>
                                                        <div className="button_new_add" onClick={() => this.setState({showNewShipper:!this.state.showNewShipper,showNewConsignee:false,shipperName:"",
                                                    shipperId:"",shipperAddressLine1 :"",shipperBranchName:"",shipperBranchId:"",shipperDetails:"",
                                                    shipperAddressLine2:"",
                                                    shipperCountryId:"",
                                                    shipperCity:"",
                                                    shipperPincode:"",
                                                    shipperContact:"",
                                                    shipperContactName:"",
                                                            shipperContactEmail:[],
                                                            emailsSelect:[],
                                                            shipperCountryName:""})}><FontAwesomeIcon icon={faPlus}/> New Sender</div>
                                                        <div className="popup_query_from" hidden={this.state.showShipperPop !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lable">
                                                                    <ShipperWithBranchDropdownForShipperModal
                                                                        onlyShipper={this.state.showShipperPop}
                                                                        reloadShipper={this.state.reloadShipper}
                                                                        isSelectionThroughName={false}
                                                                        selectedOptionCompany={this.state.selectedOptionCompany}
                                                                        selectionOptionsShipperBranch={this.state.selectionOptionsShipperBranch}
                                                                        branchData = {(values)=>{
                                                                            this.setState({
                                                                                selectionOptionsShipperBranch : values
                                                                            })
                                                                        }}
                                                                        selectedShipperId={this.state.shipperId}
                                                                        selectedShipperName={this.state.shipperName}
                                                                        selectedShipperBranchName={this.state.shipperBranchName}
                                                                        selectedShipperBranchId={this.state.shipperBranchId}
                                                                        shipperDetails={this.state.shipperDetails}
                                                                        onShipperConsigneeSelected={this.handleShipperSelected}
                                                                        shipperValid = {this.validatorTab1.message(
                                                                            "Sender Name",
                                                                            this.state.shipperId,
                                                                            "required"
                                                                        )}
                                                                        shipperBranchId= {this.validatorTab1.message(
                                                                            "Sender Branch Name",
                                                                            this.state.shipperBranchId,
                                                                            "required"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="popular_listing">
                                                                    <h4>Recently Use</h4>
                                                                    <div className="listing_items">
                                                                        {this.state.ShipperNameData !== undefined && this.state.ShipperNameData ?

                                                                            this.state.ShipperNameData.map((item, i) => {
                                                                                return (
                                                                                    <div className="items_list" onClick={() => {
                                                                                        let resultValue = {
                                                                                            value: item._id,
                                                                                            label: item.shipperName,
                                                                                        }
                                                                                        this.setState({
                                                                                            selectedOptionCompany: resultValue,
                                                                                            shipperId:resultValue.value,
                                                                                            shipperName:resultValue.label,
                                                                                        },()=>{
                                                                                            this.setBranchesSelectionFromList(this.state.shipperId)
                                                                                            this.setState({
                                                                                                showShipperPop:false,
                                                                                                showShipperBranchPop:true
                                                                                            })
                                                                                        })

                                                                                    }}>
                                                                                        <div className="airortName_listing">
                                                                                            <h5>{item && item.shipperName}</h5>
                                                                                            <h6>{item && item.name}</h6>
                                                                                        </div>
                                                                                        <div className="code_listing">{item && item.code}</div>
                                                                                    </div>
                                                                                )
                                                                            }) : ""}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input new_shipper_inputs">
                                                        <label>Sender Branch</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showShipperBranchPop : !this.state.showShipperBranchPop,
                                                                showShipperPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperBranchName?this.state.shipperBranchName:"Sender Branch"}</p>
                                                        </div>
                                                        <span className="text-danger">{this.validatorTab1.message(
                                                            "Sender Branch Name",
                                                            this.state.shipperBranchId,
                                                            "required")}</span>
                                                        <div className="popup_query_from" hidden={this.state.showShipperBranchPop !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lable">
                                                                    <ShipperWithBranchDropdownForShipperModal
                                                                        onlyShipperBranch={this.state.showShipperBranchPop}
                                                                        onlyShipper={this.state.onlyShipper}
                                                                        selectedOptionCompany={this.state.selectedOptionCompany}
                                                                        selectionOptionsShipperBranch={this.state.selectionOptionsShipperBranch}
                                                                        reloadShipper={this.state.reloadShipper}
                                                                        branchData = {(values)=>{
                                                                            this.setState({
                                                                                selectionOptionsShipperBranch : values
                                                                            })
                                                                        }}
                                                                        isSelectionThroughName={false}
                                                                        selectedShipperId={this.state.shipperId}
                                                                        selectedShipperName={this.state.shipperName}
                                                                        selectedShipperBranchName={this.state.shipperBranchName}
                                                                        selectedShipperBranchId={this.state.shipperBranchId}
                                                                        shipperDetails={this.state.shipperDetails}
                                                                        onShipperConsigneeSelected={this.handleShipperSelected}
                                                                        shipperValid = {this.validatorTab1.message(
                                                                            "Sender Name",
                                                                            this.state.shipperId,
                                                                            "required"
                                                                        )}
                                                                        shipperBranchId= {this.validatorTab1.message(
                                                                            "Sender Branch Name",
                                                                            this.state.shipperBranchId,
                                                                            "required"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="popular_listing">
                                                                    <h4>Sender Branch</h4>
                                                                    <div className="listing_items">
                                                                        {this.state.shipperBranchDataList !== undefined && this.state.shipperBranchDataList ?

                                                                            this.state.shipperBranchDataList.map((item, i) => {
                                                                                return (
                                                                                    <div className="items_list" onClick={() => {
                                                                                        let resultValue = {
                                                                                            value: item._id,
                                                                                            label: item.branchName,
                                                                                            branchDetail: item,
                                                                                        }
                                                                                        this.setState({
                                                                                            branchDetail: resultValue.branchDetail,
                                                                                            shipperBranchId:resultValue.value,
                                                                                            shipperBranchName:resultValue.label,
                                                                                        },()=>{
                                                                                            this.allShipperDeatils()
                                                                                            this.setState({
                                                                                                showShipperBranchPop:false,
                                                                                                showConsigneePop:true
                                                                                            })
                                                                                        })

                                                                                    }}>
                                                                                        <div className="airortName_listing">
                                                                                            <h5>{item && item.branchName}</h5>
                                                                                            <h6>{item && item.stateId && item.stateId.name}</h6>
                                                                                        </div>
                                                                                        {/*<div className="code_listing">{item && item.code}</div>*/}
                                                                                    </div>
                                                                                )
                                                                            }) : ""}

                                                                    </div> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="new_banner_input new_shipper_inputs">
                                                    {this.state.shipperContactvalue ?<>
                                                    <div className="">
                                                        <label>Sender Contact Name</label>
                                                    
                                                        
                                                        
                                                        <Input type="text" id="shipperContactName"
                                                                               name="shipperContactName"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperContactName === undefined ? "" : this.state.shipperContactName}
                                                                        />


                                                    </div>
                                                            <span className="text-danger">{this.validatorTab1.message(

                                                                "Sender Contact Name",
                                                                this.state.shipperContactName,
                                                                "required"
                                                            )}</span>
                                                        </>
                                                        :""}

                                                    </div>

                                                <div className="new_banner_input new_shipper_inputs">
                                                    {this.state.shipperNumbervalue ?<>
                                                    <div className="">
                                                    <label>Sender Contact Number</label>
                                                   
                                                   
                                                        
                                                       
                                                        <Input type="number" id="shipperContactNumber"
                                                                               name="shipperContactNumber"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperContactNumber === undefined ? "" : this.state.shipperContactNumber}
                                                                        />
                                                    </div>
                                                            <span className="text-danger">{this.validatorTab1.message(
                                                                "Sender Contact Number",
                                                                this.state.shipperContactNumber,
                                                                "required"
                                                            )}</span>
                                                    </>
                                                        :""}


                                                    </div>
                                                    <div className="new_banner_input shipperConsignee_input">
                                                        <label>Sender Address</label>
                                                        <div className="box_new_query">
                                                            <p>{this.state.shipperDetails ? this.state.shipperDetails : "Sender Address"}</p>
                                                        </div>
                                                    </div>
                                                    
                                                    {this.state.shipperpincode ?<>
                                                    <div className="new_banner_input shipperConsignee_input">
                                                        <label>Sender Pincode</label>
                                                        <div className="">
                                                        <Input type="text" id="shipperPincode"
                                                                               name="shipperPincode"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperPincode === undefined ? "" : this.state.shipperPincode}
                                                                        />
                                                           
                                                        </div>
                                                    </div>
                                                            <span className="text-danger">{this.validatorTab1.message(
                                                                "Sender Pincode",
                                                                this.state.shipperPincode,
                                                                "required"
                                                            )}</span>
                                                        </>
                                                         :""} 


                                                </div>
                                                <div className="separate_shipper"></div>
                                                <div className="two_part_shipCong">
                                                    <div className="new_banner_input new_shipper_inputs">
                                                        <label>Receiver Name</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showConsigneePop : !this.state.showConsigneePop,
                                                                showConsigneeBranchPop:false,
                                                                showShipperPop:false,
                                                                showShipperBranchPop:false
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeName?this.state.consigneeName:"Receiver Name"}</p>
                                                        </div>
                                                        <span className="text-danger">{this.validatorTab1.message(
                                                            "Receiver Name",
                                                            this.state.consigneeId,
                                                            "required"
                                                        )}</span>
                                                        <div className="button_new_add" onClick={() => this.setState({showNewConsignee:!this.state.showNewConsignee,showNewShipper:false,consigneeName:"",
                                                    consigneeAddressLine1:"",consigneeId:"",consigneeBranchName:"",consigneeBranchId:"",consigneeDetails:"",
                                                    consigneeAddressLine2:"",
                                                    consigneeCountryId:"",
                                                    consigneeCity:"",
                                                    consigneePincode:"",
                                                    consigneeContact:"",
                                                    consigneeContactName:"",
                                                            consigneeContactEmail:[],
                                                            emailsSelectConsignee:[],
                                                            consigneeCountryName:""})}><FontAwesomeIcon icon={faPlus}/> New Receiver</div>

                                                        <div className="popup_query_from" hidden={this.state.showConsigneePop !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lable">
                                                                    <ConsigneeWithBranchDropdownForShipperModal
                                                                        readOnly={this.state.selectNewConsignee !== false}
                                                                        reloadConsignee={this.state.reloadConsignee}
                                                                        onlyConsignee={this.state.showConsigneePop}
                                                                        selectedOptionConsignee={this.state.selectedOptionConsignee}
                                                                        selectionOptionsConsigneeBranch={this.state.selectionOptionsConsigneeBranch}
                                                                        branchData = {(values)=>{
                                                                            this.setState({
                                                                                selectionOptionsConsigneeBranch : values
                                                                            })
                                                                        }}
                                                                        isSelectionThroughName={false}
                                                                        selectedConsigneeId={this.state.consigneeId}
                                                                        selectedConsigneeName={this.state.consigneeName}
                                                                        selectedConsigneeBranchName={this.state.consigneeBranchName}
                                                                        consigneeDetails={this.state.consigneeDetails}
                                                                        onShipperConsigneeSelected={this.handleConsigneeSelected}
                                                                        consigneeValid = {this.validatorTab2.message(
                                                                            "Receiver Name",
                                                                            this.state.consigneeId,
                                                                            "required"
                                                                        )}
                                                                        consigneeBranchId= {this.validatorTab2.message(
                                                                            "Receiver Branch Name",
                                                                            this.state.consigneeBranchId,
                                                                            "required"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="popular_listing">
                                                                    <h4>Recently Use</h4>
                                                                    <div className="listing_items">
                                                                        {this.state.ConsigneeNameData !== undefined && this.state.ConsigneeNameData ?

                                                                            this.state.ConsigneeNameData.map((item, i) => {
                                                                                return (
                                                                                    <div className="items_list" onClick={() => {
                                                                                        let resultValue = {
                                                                                            value: item._id,
                                                                                            label: item.consigneeName,
                                                                                        }
                                                                                        this.setState({
                                                                                            selectedOptionConsignee: resultValue,
                                                                                            consigneeId:resultValue.value,
                                                                                            consigneeName:resultValue.label,
                                                                                        },()=>{
                                                                                            this.setConsigneeBranchesSelectionFromList(this.state.consigneeId)
                                                                                            this.setState({
                                                                                                showConsigneePop:false,
                                                                                                showConsigneeBranchPop:true
                                                                                            })
                                                                                        })

                                                                                    }}>
                                                                                        <div className="airortName_listing">
                                                                                            <h5>{item && item.consigneeName}</h5>
                                                                                            <h6>{item && item.name}</h6>
                                                                                        </div>
                                                                                        <div className="code_listing">{item && item.code}</div>
                                                                                    </div>
                                                                                )
                                                                            }) : ""}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input new_shipper_inputs">
                                                        <label>Receiver Branch</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showConsigneeBranchPop : !this.state.showConsigneeBranchPop,
                                                                showConsigneePop:false,
                                                                showShipperPop:false,
                                                                showShipperBranchPop:false
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeBranchName?this.state.consigneeBranchName:"Receiver Branch"}</p>
                                                        </div>
                                                        <span className="text-danger">{this.validatorTab1.message(
                                                            "Receiver Branch Name",
                                                            this.state.consigneeBranchId,
                                                            "required"
                                                        )}</span>
                                                        <div className="popup_query_from" hidden={this.state.showConsigneeBranchPop !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lable">
                                                                    <ConsigneeWithBranchDropdownForShipperModal
                                                                        readOnly={this.state.selectNewConsignee !== false}
                                                                        reloadConsignee={this.state.reloadConsignee}
                                                                        onlyConsignee={this.state.showConsigneePop}
                                                                        onlyConsigneeBranch={this.state.showConsigneeBranchPop}
                                                                        selectedOptionConsignee={this.state.selectedOptionConsignee}
                                                                        selectionOptionsConsigneeBranch={this.state.selectionOptionsConsigneeBranch}
                                                                        branchData = {(values)=>{
                                                                            this.setState({
                                                                                selectionOptionsConsigneeBranch : values
                                                                            })
                                                                        }}
                                                                        isSelectionThroughName={false}
                                                                        selectedConsigneeId={this.state.consigneeId}
                                                                        selectedConsigneeName={this.state.consigneeName}
                                                                        selectedConsigneeBranchName={this.state.consigneeBranchName}

                                                                        consigneeDetails={this.state.consigneeDetails}
                                                                        onShipperConsigneeSelected={this.handleConsigneeSelected}
                                                                        consigneeValid = {this.validatorTab2.message(
                                                                            "Receiver Name",
                                                                            this.state.consigneeId,
                                                                            "required"
                                                                        )}
                                                                        consigneeBranchId= {this.validatorTab2.message(
                                                                            "Receiver Branch Name",
                                                                            this.state.consigneeBranchId,
                                                                            "required"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="popular_listing">
                                                                    <h4>Receiver Branch</h4>
                                                                    <div className="listing_items">
                                                                        {this.state.consigneeBranchDataList !== undefined && this.state.consigneeBranchDataList ?

                                                                            this.state.consigneeBranchDataList.map((item, i) => {
                                                                                return (
                                                                                    <div className="items_list" onClick={() => {
                                                                                        let resultValue = {
                                                                                            value: item._id,
                                                                                            label: item.branchName,
                                                                                            branchDetail: item,
                                                                                        }
                                                                                        this.setState({
                                                                                            branchDetailConsignee: resultValue.branchDetail,
                                                                                            consigneeBranchId:resultValue.value,
                                                                                            consigneeBranchName:resultValue.label,
                                                                                        },()=>{
                                                                                            this.allConsigneeDeatils()
                                                                                            this.setState({
                                                                                                showConsigneeBranchPop:false
                                                                                            })
                                                                                        })

                                                                                    }}>
                                                                                        <div className="airortName_listing">
                                                                                            <h5>{item && item.branchName}</h5>
                                                                                            <h6>{item && item.stateId && item.stateId.name}</h6>
                                                                                        </div>
                                                                                        {/*<div className="code_listing">{item && item.code}</div>*/}
                                                                                    </div>
                                                                                )
                                                                            }) : ""}

                                                                    </div> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="new_banner_input new_shipper_inputs">
                                                    {this.state.consigneeContactvalue ?<>
                                                    <div className="">
                                                    <label>Receiver Contact Name</label>
                                                    
                                                    
                                                        
                                                        
                                                        <Input type="text" id="consigneeContactName"
                                                                               name="consigneeContactName"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeContactName === undefined ? "" : this.state.consigneeContactName}
                                                                        />


                                                    </div>
                                                        <span className="text-danger">{this.validatorTab1.message(

                                                        "Receiver Contact Name",
                                                        this.state.consigneeContactName,
                                                        "required"
                                                    )}</span></>:""}


                                                    </div>
                                                    <div className="new_banner_input new_shipper_inputs">

                                                    {this.state.consigneeNumbervalue ?<>
                                                    <div className="">
                                                    <label>Receiver Contact Number</label>
                                                    
                                                    
                                                        
                                                        <Input type="number" id="consigneeContactNumber"
                                                                               name="consigneeContactNumber"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeContactNumber === undefined ? "" : this.state.consigneeContactNumber}
                                                                        />
                                                    </div>
                                                        <span className="text-danger">{this.validatorTab1.message(

                                                            "Receiver Contact Number",
                                                            this.state.consigneeContactNumber,
                                                            "required"
                                                        )}</span></>:""}


                                                    </div>
                                                    <div className="new_banner_input shipperConsignee_input">
                                                        <label>Receiver Address</label>
                                                        <div className="box_new_query">
                                                            <p>{this.state.consigneeDetails ? this.state.consigneeDetails : "Receiver Address"}</p>
                                                        </div>
                                                    </div>
                                                    {this.state.consigneepincode ?<>
                                                    <div className="new_banner_input shipperConsignee_input">
                                                        <label>Receiver Pincode</label>
                                                        <div className="">
                                                        <Input type="text" id="consigneePincode"
                                                                               name="consigneePincode"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneePincode === undefined ? "" : this.state.consigneePincode}
                                                                        />
                                                           
                                                        </div>
                                                    </div>
                                                        <span className="text-danger">{this.validatorTab1.message(

                                                            "Receiver Pincode",
                                                            this.state.consigneePincode,
                                                            "required"
                                                        )}</span>
                                                        </>:""}


                                                </div>
                                                {/*<div className="new_banner_input new_shipper_inputs hideForMob">*/}
                                                {/*    <label>Sender Address</label>*/}
                                                {/*    <div className="box_new_query">*/}
                                                {/*        <p>{this.state.shipperDetails ? this.state.shipperDetails : "Sender Address"}</p>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="new_banner_input new_shipper_inputs hideForMob">*/}
                                                {/*    <label>Receiver Address</label>*/}
                                                {/*    <div className="box_new_query">*/}
                                                {/*        <p>{this.state.consigneeDetails ? this.state.consigneeDetails : "Receiver Address"}</p>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        :""}
                                    {this.state.tab2?
                                        <>
                                            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                                                <div className="new_banner_input new_Package_Details">
                                                    <label>No. Of Pieces</label>
                                                    <div className="box_new_query" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            showIncoTermPop : false,
                                                            showShipperBranchPop : false,
                                                            showAccountTypePop : !this.state.showAccountTypePop,
                                                            showShipperPop:false,
                                                            showConsigneePop : false,
                                                            showConsigneeBranchPop:false,
                                                        })
                                                    }}>
                                                        <p>Pieces : {this.state.totalPiecesFromQuery === undefined ? "" : this.state.totalPiecesFromQuery}</p>
                                                    </div>
                                                </div>
                                                <div className="new_banner_input new_Package_Details">
                                                    <label>Gross Weight (KGS)</label>
                                                    <div className="box_new_query" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            showIncoTermPop : !this.state.showIncoTermPop,
                                                            showShipperBranchPop : false,
                                                            showAccountTypePop : false,
                                                            showShipperPop:false,
                                                            showConsigneePop : false,
                                                            showConsigneeBranchPop:false,
                                                        })
                                                    }}>
                                                        <p>Gross Weight : {this.state.totalGross === undefined ? "" : this.state.totalGross}</p>
                                                    </div>
                                                </div>
                                                <div className="new_banner_input new_Package_Details">
                                                    <label>Volume Weight (KGS)</label>
                                                    <div className="box_new_query" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            showPackagePop : !this.state.showPackagePop,
                                                            showConsigneePop : false,
                                                            showConsigneeBranchPop:false,
                                                            showShipperPop:false,
                                                            showShipperBranchPop:false
                                                        })
                                                    }}>
                                                        <p>Volume Weight : {this.state.uploadedDimensions.length >0 ? this.state.volumeWeight : this.state.totalVolume} KGS</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap justify-content-end">
                                                    <button className="btn-add-dimen" onClick={this.toggleDimension}><FontAwesomeIcon icon={this.state.dimensionToggle === true ? faMinus :faPlus}/> Add Dimension</button>
                                            </div>
                                            {this.state.dimensionToggle ?
                                                <>
                                                    <div className="activity_types">
                                                        <div className="form-check form-check-inline">
                                                                <CustomInput
                                                                    type="radio"
                                                                    id="CM"
                                                                    label="CM"
                                                                    name="selectedDimensionType"
                                                                    value={"CM"}
                                                                    onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                    checked={this.state.selectedDimensionType === "CM"}
                                                                />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                                <CustomInput
                                                                    type="radio"
                                                                    id="MM"
                                                                    label="MM"
                                                                    name="selectedDimensionType"
                                                                    value={"MM"}
                                                                    onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                    checked={this.state.selectedDimensionType === "MM"}
                                                                />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                                <CustomInput
                                                                    type="radio"
                                                                    id="INCHES"
                                                                    label="INCHES"
                                                                    name="selectedDimensionType"
                                                                    value={"INCHES"}
                                                                    onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                    checked={this.state.selectedDimensionType === "INCHES"}
                                                                />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                                <CustomInput
                                                                    type="radio"
                                                                    id="METERS"
                                                                    label="METERS"
                                                                    name="selectedDimensionType"
                                                                    value={"METERS"}
                                                                    onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                    checked={this.state.selectedDimensionType === "METERS"}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="DimensionView calculate-inputs">
                                                            <label className="text-white" hidden={!this.state.allowedEdit}>Dimensions Info.</label>
                                                        <Formik hidden={!this.state.allowedEdit} validationSchema={formSchema}
                                                                onSubmit={this.addNewDimension} initialValues={{}}>
                                                            {({errors, touched}) => (
                                                                <Form hidden={!this.state.allowedEdit}>
                                                                    {/*<Label>{this.state.dimensionOverload}</Label>*/}
                                                                    <div className="d-flex flex-wrap align-items-center dimension_shipper calculate-inputs ">
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Label for="length">Length</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="length"
                                                                                    id="length"
                                                                                    placeholder="Length"
                                                                                    value={this.state.length}
                                                                                />
                                                                                {/*{errors.length && touched.length && (*/}
                                                                                {/*    <div className="invalid-feedback d-block">*/}
                                                                                {/*        {errors.length}*/}
                                                                                {/*    </div>*/}
                                                                                {/*)}*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Label for="width">Width</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="width"
                                                                                    placeholder="Width"
                                                                                    id="width"
                                                                                    value={this.state.width}
                                                                                />
                                                                                {/*{errors.width && touched.width && (*/}
                                                                                {/*    <div className="invalid-feedback d-block">*/}
                                                                                {/*        {errors.width}*/}
                                                                                {/*    </div>*/}
                                                                                {/*)}*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Label for="height">Height</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="height"
                                                                                    placeholder="Height"
                                                                                    id="height"
                                                                                    value={this.state.height}
                                                                                />
                                                                                {/*{errors.height && touched.height && (*/}
                                                                                {/*    <div className="invalid-feedback d-block">*/}
                                                                                {/*        {errors.height}*/}
                                                                                {/*    </div>*/}
                                                                                {/*)}*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Label for="pieces">Pieces</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="pieces"
                                                                                    placeholder="Pieces"
                                                                                    value={this.state.pieces}
                                                                                />
                                                                                {/*{errors.pieces && touched.pieces && (*/}
                                                                                {/*    <div className="invalid-feedback d-block">*/}
                                                                                {/*        {errors.pieces}*/}
                                                                                {/*    </div>*/}
                                                                                {/*)}*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Label for="selectWeight">Select Weight</Label>
                                                                                <select
                                                                                    id="selectWeight"
                                                                                    className=" custom-select"
                                                                                    name="selectWeight"
                                                                                    placeholder="Select Weight"
                                                                                    value={this.state.selectWeight}
                                                                                    onChange={this.handleChange}
                                                                                >
                                                                                    <option value="Kgs">Kgs</option>
                                                                                    <option value="Lbs">Lbs</option>

                                                                                </select>
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Label for="Gw/Pc">Weight Per Piece</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="gw_pc"
                                                                                    placeholder="Gw/Pc"
                                                                                    value={this.state.gw_pc}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <button className="plus-signs" type="submit"
                                                                                name="addDimension"
                                                                                onClick={this.addNewDimension}>
                                                                            <FontAwesomeIcon icon={faPlus}/>
                                                                        </button>
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </div>
                                                    <div className="DimensionView calculate-inputs">
                                                            <Label className="text-white" onClick={this.toggleDimensionCollapse}>Entered Dimensions</Label>
                                                        <Collapse isOpen={this.state.dimensionCollapse}>
                                                            {this.state.uploadedDimensions ? this.state.uploadedDimensions.map((dimensionRow, dimensionIndex) => {
                                                                return (
                                                                            <div className="d-flex flex-wrap align-items-center justify-content-start" key={dimensionIndex}>
                                                                                <div className="new-originbox length-widths">
                                                                                    <FormGroup>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="length"
                                                                                            placeholder="Length"
                                                                                            readOnly
                                                                                            value={dimensionRow.length}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <div className="new-originbox length-widths">
                                                                                    <FormGroup>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="width"
                                                                                            placeholder="Width"
                                                                                            readOnly
                                                                                            value={dimensionRow.width}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <div className="new-originbox length-widths">
                                                                                    <FormGroup>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="height"
                                                                                            readOnly
                                                                                            placeholder="Height"
                                                                                            value={dimensionRow.height}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <div className="new-originbox dimens-widths">
                                                                                    <FormGroup>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="pieces"
                                                                                            readOnly
                                                                                            placeholder="Pieces"
                                                                                            value={dimensionRow.pieces}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <div className="new-originbox dimens-widths">
                                                                                    <FormGroup>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="selectWeight"
                                                                                            readOnly
                                                                                            placeholder="Select Weight"
                                                                                            value={dimensionRow.selectWeight}
                                                                                        />

                                                                                        {/*<select*/}
                                                                                        {/*    id="selectWeight"*/}
                                                                                        {/*    className="form-control"*/}
                                                                                        {/*    name="selectWeight"*/}
                                                                                        {/*    placeholder="Select Weight"*/}
                                                                                        {/*    value={dimensionRow.selectWeight}*/}
                                                                                        {/*>*/}
                                                                                        {/*    <option value="">Select Weight Type</option>*/}
                                                                                        {/*    <option value="Lbs">Lbs</option>*/}
                                                                                        {/*    <option value="Kgs">Kgs</option>*/}

                                                                                        {/*</select>*/}
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <div className="new-originbox dimens-widths">
                                                                                    <FormGroup>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="gw_pc"
                                                                                            placeholder="Gw/Pc"
                                                                                            readOnly
                                                                                            value={dimensionRow.gw_pc}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <Button className="plus-signs"
                                                                                        disabled={!this.state.allowedEdit}
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            let dimension = this.state.uploadedDimensions;
                                                                                            dimension.splice(dimensionIndex, 1);
                                                                                            this.setState({uploadedDimensions: dimension}, () => {
                                                                                                let totalPieces = 0
                                                                                                for(let i=0;i<this.state.uploadedDimensions.length;i++){
                                                                                                    totalPieces = totalPieces + Number(this.state.uploadedDimensions[i].pieces)
                                                                                                }
                                                                                                this.setState({
                                                                                                    totalPiecesFromQuery : totalPieces
                                                                                                })
                                                                                            });
                                                                                        }}
                                                                                        name="addDimension"
                                                                                > <FontAwesomeIcon icon={faTimes}/></Button>

                                                                            </div>
                                                                );
                                                            }) : ""}
                                                        </Collapse>
                                                    </div>
                                                </>
                                            : ""}
                                        </>
                                        :""}
                                    {this.state.tab3 && (
                                        <div className="row shipperDetails">
                                            <div className="col-lg-12">
                                                <h4 className="mb-4" style={{fontWeight:'600',color:'#fff'}}>Do You Want to Add Shipment Contents</h4>
                                                {this.state.shipperlist && this.state.shipperlist.map((obj, j) => {
                                                    return (<>
                                                        <div className="d-flex flex-wrap shipment_contents">
                                                            {/*<div className="widths-15">*/}
                                                            {/*    <div className="form-group">*/}

                                                            {/*        <CommodityDropDown*/}
                                                            {/*            APIRout={GET_HSN_COMMODITY_API}*/}
                                                            {/*            PlaceHolder={"Select HSN code"}*/}
                                                            {/*            Lable={"HSN"}*/}
                                                            {/*            handler={values => {*/}
                                                            {/*                this.handleCommodity(values,j)*/}

                                                            {/*            }}*/}

                                                            {/*        />*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}
                                                            <div className="width_dim_20">
                                                                <div className="form-group">
                                                                    <label>Goods Description</label>
                                                                    <input
                                                                        name="ProductDescription"
                                                                        onChange={e => {
                                                                            this.handleShipmentInfoChange(e, j)
                                                                        }}
                                                                        value={obj.ProductDescription === undefined ? "" : obj.ProductDescription}
                                                                        placeholder="Description"
                                                                        className="form-control inputs11"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="width_dim_20">
                                                                <div className="form-group">
                                                                    <label>Item Quantity</label>
                                                                    <input
                                                                        name="itemQuantity"
                                                                        onChange={e => {
                                                                            this.handleShipmentInfoChange(e, j)
                                                                        }}
                                                                        value={obj.itemQuantity === undefined ? "" : obj.itemQuantity}
                                                                        placeholder="Quantity"
                                                                        className="form-control inputs11"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/*<div className="widths-15">*/}
                                                            {/*    <div className="form-group">*/}
                                                            {/*        <label><strong>Product Value(INR)</strong></label>*/}
                                                            {/*        <input*/}
                                                            {/*            name="productDeclaredValue"*/}
                                                            {/*            onChange={e => {*/}
                                                            {/*                this.handleShipmentInfoChange(e, j)*/}
                                                            {/*            }}*/}
                                                            {/*            value={obj.productDeclaredValue === undefined ? "" : obj.productDeclaredValue}*/}
                                                            {/*            placeholder="Product Declared"*/}
                                                            {/*            className="form-control inputs11"*/}
                                                            {/*        />*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}
                                                            <div className="width_dim_20">
                                                                <div className="form-group">
                                                                    <label>Item Ref</label>
                                                                    <input type="text"
                                                                           name="itemRef"
                                                                           placeholder="Item Ref"
                                                                           className="form-control inputs11"
                                                                           onChange={e => {
                                                                               this.handleShipmentInfoChange(e, j)
                                                                           }}
                                                                           value={obj.itemRef === undefined ? "" : obj.itemRef}

                                                                    />
                                                                </div>
                                                            </div>
                                                            {/*<div className="widths-20">*/}
                                                            {/*    <div className="form-group">*/}
                                                            {/*        <label><strong>Shipment Type Code</strong></label>*/}
                                                            {/*        <input type="text"*/}
                                                            {/*               name="ShipmentTypeCode"*/}
                                                            {/*               placeholder="Shipment Type Code"*/}
                                                            {/*               className="form-control inputs11"*/}
                                                            {/*               onChange={e => {*/}
                                                            {/*                   this.handleShipmentInfoChange(e, j)*/}
                                                            {/*               }}*/}
                                                            {/*               value={obj.ShipmentTypeCode === undefined ? "" : obj.ShipmentTypeCode}*/}

                                                            {/*        />*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}
                                                            {/*<div className="widths-20">*/}
                                                            {/*    <div className="form-group">*/}
                                                            {/*        <label><strong>Package Type Code</strong></label>*/}
                                                            {/*        <input type="text"*/}
                                                            {/*               name="PackageTypeCode"*/}
                                                            {/*               placeholder="Package Type Code"*/}
                                                            {/*               className="form-control inputs11"*/}
                                                            {/*               onChange={e => {*/}
                                                            {/*                   this.handleShipmentInfoChange(e, j)*/}
                                                            {/*               }}*/}
                                                            {/*               value={obj.PackageTypeCode === undefined ? "" : obj.PackageTypeCode}*/}

                                                            {/*        />*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}

                                                        </div>
                                                        <div className="btns-dimen text-right">
                                                            <button type="submit" name="addItem"
                                                                    onClick={e => {
                                                                        e.preventDefault()
                                                                        this.AddShipperInfo()
                                                                    }}
                                                                    className="btn btns-master mr-2">Add
                                                            </button>
                                                            {this.state.shipperlist && this.state.shipperlist.length > 1 &&
                                                            <button type="submit" name="removeItem"
                                                                    onClick={e => {
                                                                        e.preventDefault()
                                                                        this.RemoveShipperInfo(j)
                                                                    }}
                                                                    className="btn btns-master">Remove</button>
                                                            }
                                                        </div>
                                                    </>)
                                                })}
                                                <div className="d-flex flex-wrap shipment_contents">
                                                    {/* <div className="widths-20">
                                        <div className="form-group">
                                            <label><strong>Payment type</strong></label>
                                            <select
                                                name="paymentType"
                                                value={this.state.paymentType}
                                                className="custom-select inputs11"
                                                onChange={this.handleChangePT}

                                            >
                                                <option value="cod">COD</option>
                                                <option value="prepaid">Prepaid</option>
                                            </select>
                                        </div>
                                    </div> */}
                                                    {/*<div className="width_dim_20">*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label>Payment type</label>*/}
                                                    {/*        <select*/}
                                                    {/*            name="paymentType"*/}
                                                    {/*            value={this.state.paymentType}*/}
                                                    {/*            className="custom-select inputs11"*/}
                                                    {/*            onChange={this.handleChangePT}*/}
                                                    
                                                    {/*        >*/}
                                                    {/*            /!* <option value="cod">COD</option>*/}
                                                    {/*            <option value="prepaid">Prepaid</option> *!/*/}
                                                    {/*            <option value="cod">COD</option>*/}
                                                    {/*            <option value="prepaid">Prepaid</option>*/}
                                                    {/*        </select>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="widths-20">*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label><strong>Package type</strong></label>*/}
                                                    {/*        <select*/}
                                                    {/*            name="packageType"*/}
                                                    {/*            value={this.state.packageType}*/}
                                                    {/*            className="custom-select inputs11"*/}
                                                    {/*            onChange={this.handleChangePT}*/}

                                                    {/*        >*/}
                                                    {/*            <option value="Document">Document</option>*/}
                                                    {/*            <option value="Parcel">Parcel</option>*/}
                                                    {/*        </select>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="widths-20">*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label><strong>Document</strong></label>*/}
                                                    {/*        <select*/}
                                                    {/*            name="document"*/}
                                                    {/*            value={this.state.document}*/}
                                                    {/*            className="custom-select inputs11"*/}
                                                    {/*            onChange={this.handleChangePT}*/}

                                                    {/*        >*/}
                                                    {/*            <option value="No">No</option>*/}
                                                    {/*            <option value="Yes">Yes</option>*/}
                                                    {/*        </select>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className="width_dim_20">
                                                        <div className="form-group">
                                                            <label>Shipment Description</label>
                                                            <input
                                                                type="text"
                                                                name="shipmentDescription"
                                                                value={this.state.shipmentDescription}
                                                                placeholder="Shipment Description"
                                                                className="form-control inputs11"
                                                                onChange={this.handleChangePT}

                                                            />
                                                        </div>
                                                    </div>
                                                    {/*<div className="widths-20">*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label><strong>Sender Reference</strong></label>*/}
                                                    {/*        <input*/}
                                                    {/*            type="text"*/}
                                                    {/*            name="senderReference"*/}
                                                    {/*            value={this.state.senderReference}*/}
                                                    {/*            placeholder="Sender Reference"*/}
                                                    {/*            className="form-control inputs11"*/}
                                                    {/*            onChange={this.handleChangePT}*/}

                                                    {/*        />*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                    <div className="mt-3">
                                        <div className="d-flex justify-content-end">
                                        {/*{this.state.tab1?*/}
                                        {/*<>*/}
                                        {/*{!this.state.showNewShipper && !this.state.showNewConsignee  ?*/}
                                        {/*<button className="btn" type="button"*/}
                                        {/*                                                                    onClick={() => this.setState({showNewShipper:!this.state.showNewShipper,showNewConsignee:false,shipperName:""})}*/}
                                        {/*                                                                    >*/}
                                        {/*            Add New Sender*/}
                                        {/*        </button>:""}*/}
                                        {/*        {!this.state.showNewConsignee && !this.state.showNewShipper  ?*/}
                                        {/*        <button className="btn" type="button"*/}
                                        {/*                                                                    onClick={() => this.setState({showNewConsignee:!this.state.showNewConsignee,showNewShipper:false,consigneeName:""})}*/}
                                        {/*                                                                    >*/}
                                        {/*            Add New Receiver*/}
                                        {/*        </button>:""}*/}
                                        {/*        </> :""}*/}
                                            {this.state.tab1 ?
                                                <>
                                                    {this.state.showNewShipper || this.state.showNewConsignee ? "":
                                                    <button className="btn" type="button"
                                                            onClick={this.toggleLarge}>
                                                        Previous
                                                    </button>}
                                                    {this.state.showNewShipper?
                                                        <>
                                                            <button className="btn" type="button"
                                                                    onClick={() => this.setState({showNewShipper:false,})}>
                                                                Previous
                                                            </button>
                                                            <button className="btn" type="button"
                                                                    onClick={event => {
                                                                        event.preventDefault()
                                                                        this.SaveNewShipper();
                                                                    }}>
                                                                Save Sender Details
                                                            </button>
                                                            </>:
                                                        ""
                                                    }
                                                    {this.state.showNewConsignee ?
                                                        <>
                                                            <button className="btn" type="button"
                                                                    onClick={() => this.setState({showNewConsignee:false,})}>
                                                                Previous
                                                            </button>
                                                            <button className="btn"  type="button"
                                                                    onClick={event => {
                                                                        event.preventDefault()
                                                                        this.SaveNewConsignee();
                                                                    }}>
                                                                Save Receiver Details
                                                            </button>
                                                        </>
                                                        :
                                                        ""
                                                    }

                                                </>
                                                :<>
                                                {Number(this.state.count) > 1 && (
                                                    <button className="btn" type="button"
                                                            onClick={() => this.Prev(this.state.count)}>
                                                        Previous
                                                    </button>
                                                )}
                                            </>
                                            }
                                            {this.state.showNewShipper === true || this.state.showNewConsignee === true ?
                                                "" :
                                                <>
                                                    {Number(this.state.count) < 4 ? (

                                                        <>
                                                        {this.state.tab3 === true ?"":
                                                        <button className="btn" type="button"
                                                                onClick={() => this.Next(this.state.count)}>
                                                            Next
                                                        </button>}
                                                        </>
                                                        ) : ""}
                                                </>
                                            }
                                            {Number(this.state.count) == 3 ? (
                                                <button className="btn" type="button"
                                                        onClick={event => {
                                                            event.preventDefault()
                                                            this.SubmitShipperAndConsignee();
                                                        }}
                                                        hidden={this.state.showNewButton === true}>
                                                    Submit
                                                </button>):""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        );
    }

    // house details function start
    createDynamicHouseCard() {
        let houseItems = [];
        // console.log("housecard",JSON.stringify(this.state.houseData))
        for (let i = 0; i < this.state.noOfHousesRequired; i++) {
            // this.totalUsedPiecesByHouses.push(0);
            let houseNumber=""
            if(Array.isArray(this.state.hawbDropDownOptions) && this.state.hawbDropDownOptions.length>i){
                houseNumber=this.state.hawbDropDownOptions[i].value
            }
            //this.state.hawbDropDownOptions


            houseItems.push(
                <HouseDetailCard
                    houseIndex={i}
                    houseNumber={houseNumber}
                    houseData={this.state.houseData[i]}
                    deleteHouse={() => {
                        let houseData = this.houseData
                        let totalUsedPiecesByHouses = this.totalUsedPiecesByHouses
                        let ArrayForHBL = this.ArrayForHBL
                        let AvailableHbLContainersArray = this.AvailableHbLContainersArray

                        houseData.splice(i, 1)
                        totalUsedPiecesByHouses.splice(i, 1)
                        ArrayForHBL.splice(i, 1) // for deleting container array of current house number
                        AvailableHbLContainersArray.splice(i, 1) // for deleting container count array of current house number

                        this.setState({
                                changedData: true,
                                houseData: houseData,
                                updateData: houseData,
                                totalUsedPiecesByHouses: totalUsedPiecesByHouses,
                                ArrayForHBL :ArrayForHBL,
                                AvailableHbLContainersArray :AvailableHbLContainersArray,

                                noOfHousesRequired: parseFloatValue(this.state.noOfHousesRequired) - 1
                            }, () => {
                                try {
                                    this.calculateAvailablePieces(this.state.totalUsedPiecesByHouses[i])


                                } catch (e) {

                                }


                                if (this.state.noOfHousesRequired === 0) {
                                    this.setState({
                                        checked: false,
                                        issuedBySelectionBranch: undefined
                                    })
                                }
                            }
                        );

                    }}
                    totalHouses={parseFloatValue(this.state.noOfHousesRequired)}
                    selectedDimensionType={this.state.selectedDimensionType}
                    queryDetails={this.state.quoteData}
                    totalPieces={this.state.totalPiecesFromQuery}
                    NoOfContainer={ parseFloatValue(this.state.noOfHousesRequired) <= 1 ? this.state.NoOfContainer : undefined}
                    ContainerCountArray={this.state.TotalContainerCount}
                    grossWeight={this.state.totalGross}
                    queryFor={this.state.queryFor}
                    volumeWeight={this.state.totalVolume}
                    chargeableWeight={this.state.chargeableWeight}
                    dimensionArray={this.state.queryDimentionsData}
                    hawbDropDownOptions={this.state.hawbDropDownOptions}
                    updateData={this.state.updateData[i]}
                    ContainerListForHBL={this.state.ContainerList} // initial container list pass to first house
                    RemainingContainerListForHBL = {parseFloatValue(this.state.noOfHousesRequired) >= 2 ? this.ArrayForHBL[i] : undefined } // remaining container utilisation array use for 2 and more than that
                    RemainingContainerCountForHBL = {parseFloatValue(this.state.noOfHousesRequired) >= 2 ? this.AvailableHbLContainersArray[i] : undefined } // remaining container count utilisation array use for 2 and more than that
                    houseSelected={this.state.issuedBySelection === "Others"}
                    availablePieces={this.state.totalUsedPiecesByHouses
                        ? this.calculateAvailablePieces(this.totalUsedPiecesByHouses[i])
                        : this.state.totalPieces
                    }
                    availableDimension={
                        this.state.totalUsedPiecesByHouses
                            ? this.calculateAvailablePieces(this.state.totalUsedPiecesByHouses[i])
                            : this.state.totalPieces
                    }
                    houseDataChanged={houseData => {

                        this.houseData[i] = houseData;
                        this.setState({
                            changedData: true,
                            houseData: this.houseData,
                            updateData: this.houseData
                        }, () => {
                            // console.log("HouseData", JSON.stringify(this.state.houseData))
                            let houseData = this.state.houseData
                            let array = []
                            houseData && houseData.map((item, i) => {
                                //item.houseNo = item.selectedHawb &&  item.selectedHawb.value ? item.selectedHawb.value : "";

                                item.shipperId = item.shipperId &&  item.shipperId._id ? item.shipperId._id : item.shipperId;
                                item.shipperBranchId = item.shipperBranchId &&  item.shipperBranchId._id ? item.shipperBranchId._id : item.shipperBranchId;
                                item.consigneeId = item.consigneeId &&  item.consigneeId._id ? item.consigneeId._id : item.consigneeId;
                                item.consigneeBranchId = item.consigneeBranchId &&  item.consigneeBranchId._id ? item.consigneeBranchId._id : item.consigneeBranchId;
                                item.consigneeDetails = item.consigneeDetail ? item.consigneeDetail : "";
                                item.noOfPieces = item.noOfPieces ? item.noOfPieces : "";
                                let agentDetails = {
                                    agentName: "",
                                    agentCode: "",
                                    agentAccountInfo: "",
                                    agentPlace: "",
                                    agentAccountType: "",
                                }
                                item.agentDetails = agentDetails;
                                item.noOfContainers = item.NoOfContainer ? item.NoOfContainer : "";
                                item.dimensions = item.dimensions ? item.dimensions : [];
                                let chargesDetail = {
                                    volumeWeight: item.volumeWeight ? item.volumeWeight : "",
                                    noOfPieces: item.noOfPieces ? item.noOfPieces : "",
                                    grossWeight: item.grossWeight ? item.grossWeight : "",
                                    kgOrLBS: "kg",
                                    rateClass: "Q",
                                    commudityItemNo: "",
                                    chargeableWeight: item.chargeableWeight ? item.chargeableWeight : "",
                                    rateCharges: "",
                                    total: ""
                                }
                                if(this.state.shipmentMode === "FCL"){
                                    let containerArry = []
                                    if(Array.isArray(item.HblContainerList) && item.HblContainerList.length >=1 )
                                    {
                                        if(localStorage.getItem("selectedCharge")){
                                            let chargesData = JSON.parse(localStorage.getItem("selectedCharge"))
                                            let CurrencyData = JSON.parse(localStorage.getItem("countriesMaster"))
                                            let chargesCurrency = chargesData.row && chargesData.row.currency ?chargesData.row.currency : "INR";
                                            let currecyRate = "" ;
                                            if(CurrencyData && CurrencyData.result){
                                                CurrencyData.result.map((itm,j)=>{
                                                    if(itm.currencyCode === chargesCurrency){
                                                        currecyRate = itm.currencyRate;
                                                    }
                                                })
                                            }

                                            item.HblContainerList.map((obj,i)=>{
                                                let data = {} ;
                                                data.name = obj.container ;
                                                data.count = obj.count;

                                                containerArry.push(data);
                                            })

                                            item.containerType = containerArry;
                                        }else if(!localStorage.getItem("SpotRateQuery") === false){
                                            let chargesData = JSON.parse(localStorage.getItem("SpotRateQuery"))


                                            item.HblContainerList.map((obj,i)=>{
                                                let data = {} ;
                                                data.name = obj.container ;
                                                data.count = obj.count;
                                                containerArry.push(data);
                                            })

                                            item.containerType = containerArry;

                                        }

                                    }
                                }
                                item.chargesDetail = chargesDetail;
                                array.push(item)
                            })

                            this.setState({houseData: array},()=>{
                                this.checkForValidatingHouseAndMasterData()
                            })
                            // console.log("HouseDataAfter", JSON.stringify(array))
                        });
                    }}
                    noOfPiecesUsedByHouse={updatedData => {
                        this.totalUsedPiecesByHouses[updatedData.houseIndex] = updatedData.usedPieces;
                        this.setState({
                            totalUsedPiecesByHouses: this.totalUsedPiecesByHouses
                        }, () => {
                            if (this.state.shipmentMode === "LCL") {
                                //this.checkContainerForHouse(i)
                            }
                            // this.ShowAddNew()
                        });
                    }}
                    remainingArrayForNextHbl = {data=>{      // remaining utilising array of container got from current HBL
                        this.ArrayForHBL[data.houseIndex] = data.remainingListForNext;
                        this.setState({
                            ArrayForHBL: this.ArrayForHBL
                        },()=>{
                            // console.log("arrayForHbl" , JSON.stringify(this.state.ArrayForHBL))
                        })
                    }}
                    // remainingArrayCountForNextHbl = {data=>{ // remaining utilising array Count of container got from current HBL
                    //     this.AvailableHbLContainersArray[data.houseIndex] = data.remainingArrayCountForNext;
                    //     this.setState({
                    //         AvailableHbLContainersArray: this.AvailableHbLContainersArray
                    //     },()=>{
                    //         console.log("arrayCountForHbl" , JSON.stringify(this.state.AvailableHbLContainersArray))
                    //     })
                    // }}
                    // containerNumber={data => { // total conatiner used for current house
                    //     this.setState({houseContainerNumber: data}, () => {
                    //         if (this.state.shipmentMode === "FCL") {
                    //             this.checkContainerForHouse(i)
                    //         }
                    //     })
                    // }}
                    // houseContainerNumber={this.state.houseContainerNumberZero}
                />
            );
        }
        return <>{houseItems}</>;
    }

    // house details function end

    getHawbNumberFromServer = (serverObj) => {
        this.getHawbUnique(serverObj).then(response => {
            {

                let hawbDropDownOptions = this.state.hawbDropDownOptions;
                if (response && response.success) {
                    response.result &&
                    response.result.map(hawbNumber => {
                        let hawbDataObj = {};
                        hawbDataObj.label = hawbNumber;
                        hawbDataObj.value = hawbNumber;
                        hawbDropDownOptions.push(hawbDataObj);
                    });
                    this.setState({hawbDropDownOptions: hawbDropDownOptions});
                }
            }
        })
            .catch();
    }
}

const mapStateToProps = (state) => {
    const {user, loading, LoginMessage, closeModal, newShipper} = state.authUser;
    const {BookingMassage, OpenPerForma, container} = state.advanceSearchReducer;
    const {
        message
    } = state.masterShipperConsigneeApprovalReducer;
    return {user, loading, message, LoginMessage, closeModal, newShipper, BookingMassage, OpenPerForma, container};
};

export default connect(
    mapStateToProps,
    {
        registerUser, loginUser, sendNextToDashboard, AddShipperConsignee,SendConatinerList
    }
)(CourierShipperConsigneeModal);
