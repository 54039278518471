/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_OTP_SUCCESS = "LOGIN_OTP_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const CHEKE_OFFER_CODE = "CHEKE_OFFER_CODE";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const NOTIFICATION_USER = "NOTIFICATION_USER";
export const NOTIFICATION_USER_SUCCESS = "NOTIFICATION_USER_SUCCESS";
export const NOTIFICATION_USER_ERROR = "NOTIFICATION_USER_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* TODOAPP*/
export const TODO_GET_LIST = "TODO_GET_LIST";
export const TODO_GET_LIST_SUCCESS = "TODO_GET_LIST_SUCCESS";
export const TODO_GET_LIST_ERROR = "TODO_GET_LIST_ERROR";
export const TODO_GET_LIST_WITH_FILTER = "TODO_GET_LIST_WITH_FILTER";
export const TODO_GET_LIST_WITH_ORDER = "TODO_GET_LIST_WITH_ORDER";
export const TODO_GET_LIST_SEARCH = "TODO_GET_LIST_SEARCH";
export const TODO_ADD_ITEM = "TODO_ADD_ITEM";
export const TODO_ADD_ITEM_SUCCESS = "TODO_ADD_ITEM_SUCCESS";
export const TODO_ADD_ITEM_ERROR = "TODO_ADD_ITEM_ERROR";
export const TODO_SELECTED_ITEMS_CHANGE = "TODO_SELECTED_ITEMS_CHANGE";

/* CHAT APP*/
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONTACTS_SUCCESS = "CHAT_GET_CONTACTS_SUCCESS";
export const CHAT_GET_CONTACTS_ERROR = "CHAT_GET_CONTACTS_ERROR";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_GET_CONVERSATIONS_SUCCESS = "CHAT_GET_CONVERSATIONS_SUCCESS";
export const CHAT_GET_CONVERSATIONS_ERROR = "CHAT_GET_CONVERSATIONS_ERROR";
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  "CHAT_ADD_MESSAGE_TO_CONVERSATION";
export const CHAT_CREATE_CONVERSATION = "CHAT_CREATE_CONVERSATION";
export const CHAT_SEARCH_CONTACT = "CHAT_SEARCH_CONTACT";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";

/* SURVEY LIST APP*/
export const SURVEY_LIST_GET_LIST = "SURVEY_LIST_GET_LIST";
export const SURVEY_LIST_GET_LIST_SUCCESS = "SURVEY_LIST_GET_LIST_SUCCESS";
export const SURVEY_LIST_GET_LIST_ERROR = "SURVEY_LIST_GET_LIST_ERROR";
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  "SURVEY_LIST_GET_LIST_WITH_FILTER";
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  "SURVEY_LIST_GET_LIST_WITH_ORDER";
export const SURVEY_LIST_GET_LIST_SEARCH = "SURVEY_LIST_GET_LIST_SEARCH";
export const SURVEY_LIST_ADD_ITEM = "SURVEY_LIST_ADD_ITEM";
export const SURVEY_LIST_ADD_ITEM_SUCCESS = "SURVEY_LIST_ADD_ITEM_SUCCESS";
export const SURVEY_LIST_ADD_ITEM_ERROR = "SURVEY_LIST_ADD_ITEM_ERROR";
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  "SURVEY_LIST_SELECTED_ITEMS_CHANGE";

/* SURVEY DETAIL APP*/
export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";

/* AdvanceSearch*/
export const ORIGIN_GET_LIST = "ORIGIN_GET_LIST";
export const ORIGIN_GET_LIST_SUCCESS = "ORIGIN_GET_LIST_SUCCESS";
export const ORIGIN_GET_LIST_ERROR = "ORIGIN_GET_LIST_ERROR";

export const GET_CHARGES = "GET_CHARGES";
export const GET_CHARGES_SUCCESS = "GET_CHARGES_SUCCESS";
export const GET_CHARGES_ERROR = "GET_CHARGES_ERROR";

export const GET_COURIER_CHARGES = "GET_COURIER_CHARGES";
export const GET_COURIER_CHARGES_SUCCESS = "GET_COURIER_CHARGES_SUCCESS";
export const GET_COURIER_CHARGES_ERROR = "GET_COURIER_CHARGES_ERROR";


export const STORE_QUERY  = "STORE_QUERY";
export const STORE_QUERY_FOR_DATA  = "STORE_QUERY_FOR_DATA";
export const STORE_QUERY_SUCCESS = "STORE_QUERY_SUCCESS";
export const STORE_QUERY_ERROR = "STORE_QUERY_ERROR";

export const UPDATE_QUERY  = "UPDATE__QUERY";
export const UPDATE_QUERY_FOR_DATA  = "UPDATE_QUERY_FOR_DATA";
export const UPDATE_QUERY_SUCCESS = "UPDATE_QUERY_SUCCESS";
export const UPDATE_QUERY_ERROR = "UPDATE_QUERY_ERROR";

export const STORE_COURIER_QUERY  = "STORE_COURIER_QUERY";
export const STORE_COURIER_QUERY_FOR_DATA  = "STORE_COURIER_QUERY_FOR_DATA";
export const STORE_COURIER_QUERY_SUCCESS = "STORE_COURIER_QUERY_SUCCESS";
export const STORE_COURIER_QUERY_ERROR = "STORE_COURIER_QUERY_ERROR";

export const STORE_SELECTED_ALL_RATE  = "STORE_SELECTED_ALL_RATE";
export const STORE_SELECTED_ALL_RATE_SUCCESS = "STORE_SELECTED_ALL_RATE_SUCCESS";
export const STORE_SELECTED_ALL_RATE_ERROR = "STORE_SELECTED_ALL_RATE_ERROR";

export const STORE_SELECTED_BOOKING_RATE  = "STORE_SELECTED_BOOKING_RATE";
export const STORE_SELECTED_BOOKING_RATE_SUCCESS = "STORE_SELECTED_BOOKING_RATE_SUCCESS";
export const STORE_SELECTED_BOOKING_RATE_ERROR = "STORE_SELECTED_BOOKING_RATE_ERROR";

export const SEND_NEXT_MBL_SUCCESS = "SEND_NEXT_MBL_SUCCESS";
export const SEND_CONATAINER_LIST_TO_HBL = "SEND_CONATAINER_LIST_TO_HBL";

export const GET_SPOT_BOOKING_RATE  = "GET_SPOT_BOOKING_RATE";
export const GET_SPOT_BOOKING_RATE_SUCCESS = "GET_SPOT_BOOKING_RATE_SUCCESS";
export const GET_SPOT_BOOKING_RATE_SUCCESS_ERROR = "GET_SPOT_BOOKING_RATE_SUCCESS_ERROR";

export const SPOT_RATE_HOUSE_DATA  = "SPOT_RATE_HOUSE_DATA";
export const SPOT_RATE_QUERY_STORE  = "SPOT_RATE_QUERY_STORE";
export const SPOT_RATE_QUERY_STORE_SUCCESS = "SPOT_RATE_QUERY_STORE_SUCCESS";
export const SPOT_RATE_QUERY_STORE_SUCCESS_ERROR = "SPOT_RATE_QUERY_STORE_SUCCESS_ERROR";

export const SEND_TO_QUERY_FORM = "SEND_TO_QUERY_FORM";

export const OPEN_OFFER_SECTION_CARD = "OPEN_OFFER_SECTION_CARD";

export const SPOT_RATE_CREATE_REQUEST = "SPOT_RATE_CREATE_REQUEST";
export const SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE = "SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE";
export const SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS = "SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS";

export const SPOT_RATE_DETAIL_REQUEST = "SPOT_RATE_DETAIL_REQUEST";
export const SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE = "SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE";
export const SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS = "SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS";

export const SPOT_RATE_UPDATE_REQUEST = "SPOT_RATE_UPDATE_REQUEST";
export const SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE = "SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE";
export const SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS = "SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS";

export const SPOT_RATE_TICKER_REQUEST = "SPOT_RATE_TICKER_REQUEST";
export const SPOT_RATE_DATA_PAGE = "SPOT_RATE_DATA_PAGE";
export const SPOT_RATE_DATA_PAGE_AIR = "SPOT_RATE_DATA_PAGE_AIR";
export const SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE = "SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE";
export const SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS = "SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS";
export const CHANGE_CURRENCY_FOR_SPOT_RATE = "CHANGE_CURRENCY_FOR_SPOT_RATE";

export const SHIPMENT_HISTORY_DATA_REQUEST = "SHIPMENT_HISTORY_DATA_REQUEST";
export const MAWB_DATA_SEND_TO_MAWB_VIEW = "MAWB_DATA_SEND_TO_MAWB_VIEW";
export const SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS = "SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS";
export const SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE = "SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE";


export const COURIER_SHIPMENT_HISTORY_DATA_REQUEST = "COURIER_SHIPMENT_HISTORY_DATA_REQUEST";
// export const MAWB_DATA_SEND_TO_MAWB_VIEW = "MAWB_DATA_SEND_TO_MAWB_VIEW";
export const COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS = "COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS";
export const COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE = "COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE";

export const INVOICE_FETCH_FAILURE = "INVOICE_FETCH_FAILURE";
export const INVOICE_FETCH_MASTER = "INVOICE_FETCH_MASTER";
export const INVOICE_FETCH_RESPONSE = "INVOICE_FETCH_RESPONSE";
export const INVOICE_PREVIEW = "INVOICE_PREVIEW";
export const INVOICE_CREATE = "INVOICE_CREATE";
export const INVOICE_CREATE_RESPONSE = "INVOICE_CREATE_RESPONSE";
export const INVOICE_UPDATE = "INVOICE_UPDATE";
export const INVOICE_UPDATE_RESPONSE = "INVOICE_UPDATE_RESPONSE";
export const INVOICE_DELETE = "INVOICE_DELETE";
export const INVOICE_DELETE_RESPONSE = "INVOICE_DELETE_RESPONSE";
//INVOICE PRINT
export const INVOICE_PRINT_REQUEST = "INVOICE_PRINT_REQUEST";
export const INVOICE_PRINT_REQUEST_SUCCESS = "INVOICE_PRINT_REQUEST_SUCCESS";
export const INVOICE_PRINT_REQUEST_FAIL = "INVOICE_PRINT_REQUEST_FAIL";
//INVOICE PRINT
export const INVOICE_GETDATA_REQUEST = "INVOICE_GETDATA_REQUEST";
export const INVOICE_GETDATA_REQUEST_SUCCESS = "INVOICE_GETDATA_REQUEST_SUCCESS";
export const INVOICE_GETDATA_REQUEST_FAIL = "INVOICE_GETDATA_REQUEST_FAIL";
//INVOICE CHEKED
export const INVOICE_CHECKED_REQUEST = "INVOICE_CHECKED_REQUEST";
export const INVOICE_CHECKED_REQUEST_SUCCESS = "INVOICE_CHECKED_REQUEST_SUCCESS";
export const INVOICE_CHECKED_REQUEST_FAIL = "INVOICE_CHECKED_REQUEST_FAIL";

// MAWB UPDATE
export const MAWB_UPDATE = "MAWB_UPDATE";
export const MAWB_UPDATE_RESPONSE = "MAWB_UPDATE_RESPONSE";

//Query Data
export const QUERY_PREVIEW_DATA = "QUERY_PREVIEW_DATA";
export const QUERY_PREVIEW_DATA_SUCCESS = "QUERY_PREVIEW_DATA_SUCCESS";
export const QUERY_PREVIEW_DATA_FAILURE = "QUERY_PREVIEW_DATA_FAILURE";
// SEND GST TO PERFORMA
export const GST_NUMBER_TO_PERFORMA = "GST_NUMBER_TO_PERFORMA";

//ShipperConsigneeAll
export const CONSIGNEE_FETCH_ALL = "CONSIGNEE_FETCH_ALL";
export const CONSIGNEE_FETCH_ALL_FAILURE = "CONSIGNEE_FETCH_ALL_FAILURE";
export const CONSIGNEE_FETCH_ALL_RESPONSE = "CONSIGNEE_FETCH_ALL_RESPONSE";
export const SHIPPER_FETCH_ALL = "SHIPPER_FETCH_ALL";
export const SHIPPER_FETCH_ALL_FAILURE = "SHIPPER_FETCH_ALL_FAILURE";
export const SHIPPER_FETCH_ALL_RESPONSE = "SHIPPER_FETCH_ALL_RESPONSE";

// HAWB UPDATE
export const HAWB_UPDATE = "HAWB_UPDATE";
export const HAWB_UPDATE_RESPONSE = "HAWB_UPDATE_RESPONSE";

// SHIPPER CONSIGNEE APPROVAL MASTER
export const SHIPPER_APPROVAL_FETCH_FAILURE = "SHIPPER_APPROVAL_FETCH_FAILURE";
export const SHIPPER_APPROVAL_FETCH_MASTER = "SHIPPER_APPROVAL_FETCH_MASTER";
export const SHIPPER_APPROVAL_FETCH_RESPONSE = "SHIPPER_APPROVAL_FETCH_RESPONSE";
export const SHIPPER_APPROVAL_CREATE = "SHIPPER_APPROVAL_CREATE";
export const SHIPPER_APPROVAL_CREATE_RESPONSE = "SHIPPER_APPROVAL_CREATE_RESPONSE";
export const SHIPPER_APPROVAL_MASTER_PREVIEW = "SHIPPER_APPROVAL_MASTER_PREVIEW";

export const CONSIGNEE_APPROVAL_FETCH_FAILURE = "CONSIGNEE_APPROVAL_FETCH_FAILURE";
export const CONSIGNEE_APPROVAL_FETCH_MASTER = "CONSIGNEE_APPROVAL_FETCH_MASTER";
export const CONSIGNEE_APPROVAL_FETCH_RESPONSE = "CONSIGNEE_APPROVAL_FETCH_RESPONSE";
export const CONSIGNEE_APPROVAL_CREATE = "CONSIGNEE_APPROVAL_CREATE";
export const CONSIGNEE_APPROVAL_CREATE_RESPONSE = "CONSIGNEE_APPROVAL_CREATE_RESPONSE";
export const CONSIGNEE_APPROVAL_MASTER_PREVIEW = "CONSIGNEE_APPROVAL_MASTER_PREVIEW";

//cutomer master fecth data
export const CUSTOMER_MASTER_FETCH_MASTER = 'CUSTOMER_MASTER_FETCH_MASTER'
export const CUSTOMER_MASTER_FETCH_FAILURE = 'CUSTOMER_MASTER_FETCH_FAILURE'
export const CUSTOMER_MASTER_FETCH_RESPONSE = 'CUSTOMER_MASTER_FETCH_RESPONSE'

//add shipper consignee from shipper popup
    export const ADD_SHIPPER_CONSIGNEE_FROM_POPUP = 'ADD_SHIPPER_CONSIGNEE_FROM_POPUP'
export const ADD_SHIPPER_CONSIGNEE_FROM_POPUP_FAILURE = 'ADD_SHIPPER_CONSIGNEE_FROM_POPUP_FAILURE'
export const ADD_SHIPPER_CONSIGNEE_FROM_POPUP_SUCCESS = 'ADD_SHIPPER_CONSIGNEE_FROM_POPUP_SUCCESS'
//customer master delete
export const CUSTOMER_MASTER_DELETE = 'CUSTOMER_MASTER_DELETE'
export const CUSTOMER_MASTER_DELETE_RESPONSE = 'CUSTOMER_MASTER_DELETE_RESPONSE'

export const REGISTRATION_MASTER_CREATE = "REGISTRATION_MASTER_CREATE";
export const REGISTRATION_MASTER_CREATE_RESPONSE = "REGISTRATION_MASTER_CREATE_RESPONSE";

export const CUSTOMER_MASTER_BRANCH_CREATE = "CUSTOMER_MASTER_BRANCH_CREATE";
export const CUSTOMER_MASTER_BRANCH_CREATE_RESPONSE = "CUSTOMER_MASTER_BRANCH_CREATE_RESPONSE";

//CUSTOMER MASTER GET DETAILS
export const CUSTOMER_MASTER_DETAILS_GET = "CUSTOMER_MASTER_DETAILS_GET";
export const REGISTRATION_FETCH_MASTER = "REGISTRATION_FETCH_MASTER";
export const REGISTRATION_FETCH_FAILURE = "REGISTRATION_FETCH_FAILURE";
export const REGISTRATION_FETCH_RESPONSE = "REGISTRATION_FETCH_RESPONSE";

//EDOCKET DOWNLOAD
export const E_DOCKET_REQUEST = "E_DOCKET_REQUEST";
export const E_DOCKET_REQUEST_RESPONSE_SUCCESS = "E_DOCKET_REQUEST_RESPONSE_SUCCESS";
export const E_DOCKET_REQUEST_RESPONSE_FAILURE = "E_DOCKET_REQUEST_RESPONSE_FAILURE";

//EDOCKET Manager
export const E_DOCKET_MANAGER_REQUEST = "E_DOCKET_MANAGER_REQUEST";
export const E_DOCKET_MANAGER_SUCCESS = "E_DOCKET_MANAGER_SUCCESS";
export const E_DOCKET_MANAGER_FAILURE = "E_DOCKET_MANAGER_FAILURE";

export const E_DOCKET_MANAGER_DATA_REQUEST = "E_DOCKET_MANAGER_DATA_REQUEST";
export const E_DOCKET_MANAGER_DATA_SUCCESS = "E_DOCKET_MANAGER_DATA_SUCCESS";
export const E_DOCKET_MANAGER_DATA_FAILURE = "E_DOCKET_MANAGER_DATA_FAILURE";

export const E_DOCKET_MANAGER_DATA_REQUEST_AWB = "E_DOCKET_MANAGER_DATA_REQUEST_AWB";
export const E_DOCKET_MANAGER_DATA_REQUEST_AWB_SUCCESS = "E_DOCKET_MANAGER_DATA_REQUEST_AWB_SUCCESS";
export const E_DOCKET_MANAGER_DATA_REQUEST_AWB_FAILURE = "E_DOCKET_MANAGER_DATA_REQUEST_AWB_FAILURE";

//SHIPPER MANAGER
export const SHIPPER_MANAGER_FETCH_MASTER = "SHIPPER_MANAGER_FETCH_MASTER";
export const SHIPPER_MANAGER_FETCH_RESPONSE = "SHIPPER_MANAGER_FETCH_RESPONSE";
export const SHIPPER_MANAGER_FETCH_FAILURE = "SHIPPER_MANAGER_FETCH_FAILURE";
// SHIPPER Update
export const SHIPPER_MASTER_UPDATE = "SHIPPER_MASTER_UPDATE";
export const SHIPPER_MASTER_UPDATE_RESPONSE = "SHIPPER_MASTER_UPDATE_RESPONSE";
export const SHIPPER_MASTER_PREVIEW = "SHIPPER_MASTER_PREVIEW";
//SHIPPER_MASTER_delete
export const SHIPPER_MASTER_DELETE = "SHIPPER_MASTER_DELETE";
export const SHIPPER_MASTER_DELETE_RESPONSE = "SHIPPER_MASTER_DELETE_RESPONSE";
//SHIPPER CREATE
export const SHIPPER_CREATE = "SHIPPER_CREATE";
export const SHIPPER_CREATE_RESPONSE = "SHIPPER_CREATE_RESPONSE";


//CONSIGNEE CREATE
export const CONSIGNEE_CREATE = "CONSIGNEE_CREATE";
export const CONSIGNEE_CREATE_RESPONSE = "CONSIGNEE_CREATE_RESPONSE";
//CONSIGNEE MANAGER

export const CONSIGNEE_MANAGER_FETCH_MASTER = "CONSIGNEE_MANAGER_FETCH_MASTER";
export const CONSIGNEE_MANAGER_FETCH_RESPONSE = "CONSIGNEE_MANAGER_FETCH_RESPONSE";
export const CONSIGNEE_MANAGER_FETCH_FAILURE = "CONSIGNEE_MANAGER_FETCH_FAILURE";
//consignee delete
export const CONSIGNEE_MANAGER_DELETE = "CONSIGNEE_MANAGER_DELETE";
export const CONSIGNEE_MANAGER_DELETE_RESPONSE = "CONSIGNEE_MANAGER_DELETE_RESPONSE";
// CONSIGNEE Update
export const CONSIGNEE_MASTER_UPDATE = "CONSIGNEE_MASTER_UPDATE";
export const CONSIGNEE_MASTER_UPDATE_RESPONSE = "CONSIGNEE_MASTER_UPDATE_RESPONSE";
export const CONSIGNEE_MASTER_PREVIEW = "CONSIGNEE_MASTER_PREVIEW";

export const NOTIFICATION_LOAD_REQUEST = "NOTIFICATION_LOAD_REQUEST";
export const NOTIFICATION_LOAD_REQUEST_RESPONSE = "NOTIFICATION_LOAD_REQUEST_RESPONSE";
export const NOTIFICATION_LOAD_REQUEST_RESPONSE_FAILURE = "NOTIFICATION_LOAD_REQUEST_RESPONSE_FAILURE";

export const NOTIFICATION_READ_CONFIRM = "NOTIFICATION_READ_CONFIRM";
export const NOTIFICATION_READ_CONFIRM_RESPONSE = "NOTIFICATION_READ_CONFIRM_RESPONSE";
export const NOTIFICATION_READ_CONFIRM_RESPONSE_FAILURE = "NOTIFICATION_READ_CONFIRM_RESPONSE_FAILURE";

// News Flash  Master Manager
export const NEWSFLASH_MASTER_MANAGER_REQUEST = "NEWSFLASH_MASTER_MANAGER_REQUEST";
export const NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE = "NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE";
export const NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE_FAILURE = "NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE_FAILURE";
export const NEWSFLASH_MASTER_MANAGER_DELETE = "NEWSFLASH_MASTER_MANAGER_DELETE";
export const NEWSFLASH_MASTER_MANAGER_DELETE_RESPONSE = "NEWSFLASH_MASTER_MANAGER_DELETE_RESPONSE";
export const NEWSFLASH_MASTER_MANAGER_CREATE = "NEWSFLASH_MASTER_MANAGER_CREATE";
export const NEWSFLASH_MASTER_MANAGER_CREATE_RESPONSE = "NEWSFLASH_MASTER_MANAGER_CREATE_RESPONSE";
export const NEWSFLASH_MASTER_MANAGER_PREVIEW = "NEWSFLASH_MASTER_MANAGER_PREVIEW";
export const NEWSFLASH_MASTER_MANAGER_UPDATE = "NEWSFLASH_MASTER_MANAGER_UPDATE";
export const NEWSFLASH_MASTER_MANAGER_UPDATE_RESPONSE = "NEWSFLASH_MASTER_MANAGER_UPDATE_RESPONSE";
export const NEWS_TICKER_REQUEST = "NEWS_TICKER_REQUEST";
export const NEWS_TICKER_REQUEST_RESPONSE_FAILURE = "NEWS_TICKER_REQUEST_RESPONSE_FAILURE";
export const NEWS_TICKER_REQUEST_RESPONSE_SUCCESS = "NEWS_TICKER_REQUEST_RESPONSE_SUCCESS";

export const ADDS_TICKER_REQUEST = "ADDS_TICKER_REQUEST";
export const ADDS_TICKER_REQUEST_RESPONSE_FAILURE = "ADDS_TICKER_REQUEST_RESPONSE_FAILURE";
export const ADDS_TICKER_REQUEST_RESPONSE_SUCCESS = "ADDS_TICKER_REQUEST_RESPONSE_SUCCESS";
//Query
export const SEND_DATA_TO_ADD_QUERY = "SEND_DATA_TO_ADD_QUERY";
export const SEND_DATA_TO_ADD_QUERY_SUCCESS = "SEND_DATA_TO_ADD_QUERY_SUCCESS";
export const SEND_DATA_TO_ADD_QUERY_FAILURE = "SEND_DATA_TO_ADD_QUERY_FAILURE";

export const SEND_DATA_TO_UPDATE_QUERY = "SEND_DATA_TO_UPDATE_QUERY";
export const SEND_DATA_TO_UPDATE_QUERY_SUCCESS = "SEND_DATA_TO_UPDATE_QUERY_SUCCESS";
export const SEND_DATA_TO_UPDATE_QUERY_FAILURE = "SEND_DATA_TO_UPDATE_QUERY_FAILURE";
export const MANAGER_STATE_DATA = "MANAGER_STATE_DATA";
export const MANAGER_STATE_DATA_SUCCESS = "MANAGER_STATE_DATA_SUCCESS";
export const MANAGER_STATE_DATA_FAILURE = "MANAGER_STATE_DATA_FAILURE";
export const EDIT_STATE_DATA_FETCH = "EDIT_STATE_DATA_FETCH";
export const EDIT_STATE_DATA_SUCCESS = "EDIT_STATE_DATA_SUCCESS";
export const EDIT_STATE_DATA_FAILURE = "EDIT_STATE_DATA_FAILURE";
export const EDIT_STATE_DATA = "EDIT_STATE_DATA";
//STATE UPDATE
export const STATE_UPDATE = "STATE_UPDATE";
export const STATE_UPDATE_RESPONSE = "STATE_UPDATE_RESPONSE";
//STATE DELETE
export const STATE_DELETE = "STATE_DELETE";
export const STATE_DELETE_RESPONSE = "STATE_DELETE_RESPONSE";
export const QUERY_REVERT_DATA = "QUERY_REVERT_DATA";
export const QUERY_REVERT_DATA_SUCCESS = "QUERY_REVERT_DATA_SUCCESS";
export const QUERY_REVERT_DATA_FAILURE = "QUERY_REVERT_DATA_FAILURE";
export const QUERY_REVERT_DATA_FOR_MARKET_RATE = "QUERY_REVERT_DATA_FOR_MARKET_RATE";
export const QUERY_REVERT_DATA_MARKET_RATE_SUCCESS = "QUERY_REVERT_DATA_MARKET_RATE_SUCCESS";
export const QUERY_REVERT_DATA_MARKET_RATE_FAILURE = "QUERY_REVERT_DATA_MARKET_RATE_FAILURE";
export const QUERY_REVERT_AUTO_DATA = "QUERY_REVERT_AUTO_DATA";
export const QUERY_REVERT_AUTO_DATA_SUCCESS = "QUERY_REVERT_AUTO_DATA_SUCCESS";
export const QUERY_REVERT_AUTO_DATA_FAILURE = "QUERY_REVERT_AUTO_DATA_FAILURE";
export const MARKET_QUERY_DATA = "MARKET_QUERY_DATA";
export const MARKET_QUERY_DATA_SUCCESS = "MARKET_QUERY_DATA_SUCCESS";
export const MARKET_QUERY_DATA_FAILURE = "MARKET_QUERY_DATA_FAILURE";
export const MANAGER_QUERY_DATA = "MANAGER_QUERY_DATA";
export const MANAGER_QUERY_DATA_SUCCESS = "MANAGER_QUERY_DATA_SUCCESS";
export const MANAGER_QUERY_DATA_FAILURE = "MANAGER_QUERY_DATA_FAILURE";
export const MANAGER_PAST_QUERY_DATA = "MANAGER_PAST_QUERY_DATA";
export const MANAGER_QUERY_PAST_DATA_SUCCESS = "MANAGER_QUERY_PAST_DATA_SUCCESS";
export const MANAGER_QUERY_PAST_DATA_FAILURE = "MANAGER_QUERY_PAST_DATA_FAILURE";
export const MANAGER_QUERY_DATA_HISTORY = "MANAGER_QUERY_DATA_HISTORY";
export const MANAGER_QUERY_DATA_HISTORY_SUCCESS = "MANAGER_QUERY_DATA_HISTORY_SUCCESS";
export const MANAGER_QUERY_DATA_HISTORY_FAILURE = "MANAGER_QUERY_DATA_HISTORY_FAILURE";
//QUERY  DELETE
export const QUERY_DELETE = "QUERY_DELETE";
export const QUERY_DELETE_RESPONSE = "QUERY_DELETE_RESPONSE";
//AWBPDF send
export const AWB_PDF_SEND = "AWB_PDF_SEND";
export const AWB_PDF_SEND_RESPONSE = "AWB_PDF_SEND_RESPONSE";
export const SEND_TO_ADD_COPY_ACTION = "SEND_TO_ADD_COPY_ACTION";
export const ADD_STATE = "ADD_STATE";
export const ADD_STATE_SUCCESS = "ADD_STATE_SUCCESS";
export const ADD_STATE_FAILURE = "ADD_STATE_FAILURE";



export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./todo/actions";
export * from "./chat/actions";
export * from "./surveyList/actions";
export * from "./surveyDetail/actions";
