import React, {Component, Fragment} from "react";
import { Modal, ModalHeader, ModalBody} from "reactstrap";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import {
    ADD_COUPAN,
    RATE_SINGLE_DATA,
    ADD_COUPAN_AIR,
    ADD_COUPAN_OCEAN,
    ADD_COUPAN_ROAD,
    ADD_COUPAN_COURIER,
    RATE_SINGLE_DATA_AIR,
    RATE_SINGLE_DATA_OCEAN,
    RATE_SINGLE_DATA_ROAD,
    RATE_SINGLE_DATA_COURIER,
} from "../../../constants";
import RestClient from "../../../util/RestClient";
import {Link} from "react-router-dom";
import {convertDateISOToCustomiseFormat} from "../../../util/CommonUtility";
import {PAGE_PATHS} from "../../../util/PageRoute";

class AddCoupanApplyForPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMode:"",
            paymentId: "",
            modalLarge: false,
            message: "",
            openLogin: false,
            Compalsari: false,
            remarks:"",
            proformaId:"",
            utrId:"",
            tds:"",
            amount:"",
            customerCode:"",
            PerformaId:"",
            customerId:"",
            customer:"",
            currency:"",
            queryFor:"",
            coupanCodeEnter:"",
            applyButton:false
        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {
        let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        this.setState({
            quoteFor:quoteFor
        },()=>{

        })
        if(localStorage.getItem("customer")){
            let data = JSON.parse(localStorage.getItem("customer")).result;
            this.setState({
                customerIdForCoupon : data && data.customerBranchData && data.customerBranchData.customerId ? data.customerBranchData.customerId :"",
                customerBranchIdCoupon : data && data.customerBranchData && data.customerBranchData._id ? data.customerBranchData._id :""
            },()=>{
            })
        }
        if (this.props.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }

        if (this.props.couponData) {
            this.setState({
                couponData: this.props.couponData
            })
        }
        if (this.props.coupanName) {
            this.setState({
                coupanId: this.props.coupanName ? this.props.coupanName : ""
            })
        }
    }

    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if (this.state.modalLarge === false){
                this.props.setmodal1(false)
            }
        });
    };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value},()=>{
            if(this.state.coupanCodeEnter!==""){
                this.setState({
                    applyButton : true
                })
            }else{
                this.setState({
                    applyButton : false
                })

            }
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        if(this.props.couponData && this.props.couponData !== prevProps.couponData){
            this.setState({
                couponData: this.props.couponData
            })
        }
        if(this.props.coupanName && this.props.coupanName !== prevProps.coupanName){
            this.setState({
                coupanId: this.props.coupanName ? this.props.coupanName : ""
            })
        }

    }
    rateIdFound = (data) => {
        let rateLoggedData = localStorage.getItem('ratesLoggedUser')
        rateLoggedData = JSON.parse(rateLoggedData)
        if(rateLoggedData){
        if(rateLoggedData.length>0){
        let foundId = rateLoggedData.find(ele=>ele.amountB == data)
        if(foundId){
            return foundId._id
        }else{
            return ''
        }
    }
}else{
        return ''
    }
    }
    handleSubmit =(coupanCodeEnter)=>{

        let rateId
        if(this.props.rateId){
            rateId = this.props.rateId
        }else{
        let amount = localStorage.getItem('amountB')
         rateId = this.rateIdFound(amount)
        }
        this.handleCoupan({
            coupanCode : coupanCodeEnter,
            quoteId : this.props.quoteId,
            quoteFor : this.state.quoteFor,
            rateId : rateId,
            customerBranchId : this.state.customerBranchIdCoupon || this.props.custBranchId,
            customerId : this.state.customerIdForCoupon || this.props.customerId,
        }).then(res=>{
            if(res.success){
                // this.props.setmodal1(false)
                toast.success("Coupon Applied.", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.getRateAfterCoupan({id : this.props.rateId}).then(responce=>{
                    if(responce.success){
                        let rateData = responce && Array.isArray(responce.result) && responce.result.length >= 1 ? responce.result[0] : "";
                        this.setState(
                            {
                                rateDataSelected: rateData,
                            },()=>{
                                this.props.sendDataAfterCoupan(this.state.rateDataSelected);
                            }
                        );
                        // this.props.sendDataAfterCoupan(res.result)
                        setTimeout(()=>{
                            localStorage.setItem("afterDiscountCharges",JSON.stringify(responce.result))
                            localStorage.setItem("discountGiven",true)
                            this.toggleLarge()
                        },1000)

                    }
                })
                // this.getRateAfterCoupan({quoteId : this.props.quoteId,suggestedRates:this.props.suggestedRates ? this.props.suggestedRates:false}).then(res=>{
                //     if(res.success){
                //         this.props.sendDataAfterCoupan(res.result)
                //         setTimeout(()=>{
                //             localStorage.setItem("afterDiscountCharges",JSON.stringify(res.result))
                //             localStorage.setItem("discountGiven",true)
                //             this.toggleLarge()
                //         },5000)
                //
                //     }
                // })
            }else{
                toast.error(res.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    async handleCoupan(body) {
        return await RestClient({
            url: body && body.quoteFor === "Air" ? ADD_COUPAN_AIR : body && body.quoteFor === "Ocean" ? ADD_COUPAN_OCEAN :body && body.quoteFor === "Road" ? ADD_COUPAN_ROAD : ADD_COUPAN_COURIER,
            method: "POST",
            data: body
        });
    }
    async getRateAfterCoupan(body) {
        return await RestClient({
            // url: GET_STORED_CHARGES_API,
            url: this.state.quoteFor === "Air" ? RATE_SINGLE_DATA_AIR : this.state.quoteFor === "Ocean" ? RATE_SINGLE_DATA_OCEAN : this.state.quoteFor === "Road" ? RATE_SINGLE_DATA_ROAD : RATE_SINGLE_DATA_COURIER,
            method: "POST",
            data: body
        });
    }


    render() {
        // console.log('value of query',this.props.queryFor)
        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="coupanInput">
                    <div className="form-group">
                        <input name="coupanCodeEnter"
                               className="form-control inputs1"
                               type="text" id="coupanCodeEnter"
                               placeholder="Enter Coupon Code"
                               defaultValue =""
                               value={this.state.coupanCodeEnter}
                               onChange={e => {this.onChange(e)}}
                        />
                        {this.state.applyButton ?
                        <div className="apply_coupon_div" onClick={e=>{e.preventDefault()
                            if(localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0){
                                if(localStorage.getItem("customer")){
                                    let data = JSON.parse(localStorage.getItem("customer")).result;
                                    this.setState({
                                        customerIdForCoupon : data && data.customerBranchData && data.customerBranchData.customerId ? data.customerBranchData.customerId :"",
                                        customerBranchIdCoupon : data && data.customerBranchData && data.customerBranchData._id ? data.customerBranchData._id :""
                                    },()=>{
                                        this.handleSubmit(this.state.coupanCodeEnter)
                                    })
                                }
                            }else{
                                this.handleSubmit(this.state.coupanCodeEnter)
                            }

                        }}>Apply</div>:""}
                    </div>

                </div>
                {this.state.couponData && this.state.couponData.length >0?
                    this.state.couponData.map((item,index)=>{
                        if(item.coupanData && item.coupanData.queryFor === this.props.queryFor){
                            return(
                                <div className="allCoupon">
                                    <div className="coupon-base-customCheckbox">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="coupanCode"
                                            defaultChecked={false}
                                            value={item && item.coupanData && item.coupanData.name}
                                            checked={((item && item.coupanData._id ? item.coupanData._id : "") !== this.state.coupanId ) ? false :true}
                                            onChange = {(e)=>{
                                                e.preventDefault()
                                                this.setState({
                                                    coupanCode : item && item.coupanData ? item.coupanData.name : "",
                                                    coupanId : item && item.coupanData ? item.coupanData._id : "",
                                                },()=>{
                                                    if(localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0){
                                                        if(localStorage.getItem("customer")){
                                                            let data = JSON.parse(localStorage.getItem("customer")).result;
                                                            this.setState({
                                                                customerIdForCoupon : data && data.customerBranchData && data.customerBranchData.customerId ? data.customerBranchData.customerId :"",
                                                                customerBranchIdCoupon : data && data.customerBranchData && data.customerBranchData._id ? data.customerBranchData._id :""
                                                            },()=>{
                                                                this.handleSubmit(this.state.coupanCode)
                                                            })
                                                        }
                                                    }else{
                                                        this.handleSubmit(this.state.coupanCode)
                                                    }
                                                })
                                            }}
                                        />
                                        <span className="coupon-name">{item && item.coupanData ? item.coupanData.name : ""}</span>
                                    </div>
                                    <div className="coupon-details">
                                        <div className="coupon-base-benefit ">Upto <b> {item && item.coupanData && item.coupanData.isInPersentage ? "" : item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR" :""} {item && item.coupanData && item.coupanData.isInPersentage ?  item && item.coupanData ? item.coupanData.discountPercent + " %": "": item && item.coupanData ? item.coupanData.maxDiscountAmount:""}</b> Off</div>
                                        <div className="readmore-base-fullWidthContainer">Upto {item && item.coupanData && item.coupanData.isInPersentage ? "" : item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR" :""} {item && item.coupanData && item.coupanData.isInPersentage ?  item && item.coupanData ? item.coupanData.discountPercent + " %": "": item && item.coupanData ? item.coupanData.maxDiscountAmount:""} off on minimum order of {item && item.coupanData && item.coupanData.currency ==="INR" ? "Rs." : item.coupanData && item.coupanData.currency ==="USD" ? "USD" : item.coupanData && item.coupanData.currency ==="EUR" ? "EUR":""} {item && item.coupanData ? item.coupanData.applicableAmount:""}.</div>
                                        <div className="coupon-base-expiryBlock">Expires on :
                                            <b> {item && item.coupanData && item.coupanData.validityEndDate ? convertDateISOToCustomiseFormat(item.coupanData.validityEndDate) : ""}</b> <span className="coupon-base-expiryTime">11:59 PM</span>.
                                        </div>
                                        <Link to="/OfferMy" target="_blank">
                                            <div className="apply_coupon_div termsCondition">Terms & Conditions</div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        }

                    })
                    : ""}

                <Modal className="coupan_pop"
                    isOpen={this.state.modalLarges}
                    size="md"
                    toggle={this.toggleLarge}
                >

                        <>
                            <ModalHeader toggle={this.toggleLarge} className="" style={{padding: "0"}}>
                                {/*<NavLink to={`/`} className=" white">*/}
                                {/*    /!*<span className="logo-single" style={{marginBottom: "-9px"}}/>*!/*/}
                                {/*</NavLink>*/}
                            </ModalHeader>
                            <ModalBody className="padding-0">
                                <div className="back-img" style={{padding: "0px;"}}>
                                    <div className="auth-card1">
                                        <div className="cont-forms form-side">
                                            <h5 className=" text-center" style={{fontWeight: "bold"}} >
                                                <span>Coupon Information</span></h5>
                                                    <hr/>
                                                <form className="av-tooltip tooltip-label-bottom mt-3">

                                                        <div className="">
                                                            <div className="form-group">
                                                                <label htmlFor="issuedBySelection" className="origin-label">Coupon Code</label>
                                                                <input name="coupanCode"
                                                                       className="form-control inputs1"
                                                                       type="text" id="coupanCode"
                                                                       placeholder="Enter Coupon Code"
                                                                       defaultValue =""
                                                                       value={this.state.coupanCode}
                                                                       onChange={e => {this.onChange(e)}}
                                                                />
                                                            </div>
                                                             <p className="view_offers"><Link to="/OfferMy" target="_blank">View Offers</Link></p>
                                                        </div>
                                                    <div className="form-group mt-2 text-center">
                                                            <button className="book-btn btn border-cir" style={{borderRadius: "50px;"}}
                                                            onClick={e=>{e.preventDefault()
                                                            this.handleSubmit()
                                                            }}>
                                                                Submit
                                                            </button>
                                                        </div>

                                                </form>
                                        </div>
                                    </div>


                                </div>
                            </ModalBody>
                        </>




                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {} = state.QueryReducer;
    return {};
};

export default connect(
    mapStateToProps,
    {}
)(AddCoupanApplyForPreview);
