import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";
class CustomsBrokerage extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        return (
    <>


<MetaTags>
        <title>Customs Brokerage | Zipaworld </title>
        <meta name="title" content="Customs Broker Near Me for Customs Declarations"/>
        <meta name="description" content="As your customs clearing agent, Zipaworld prepares cargo documentation, calculate duties, manage delivery"/>
        <meta name="keywords" content="Customs clearance agent near me, best CHA for customs declaration in india, best customs brokerage services in india, CHA services in mundra, who is the best customs clearance agent in mumbai, cargo documentation services in india"/>
    </MetaTags>

            <AppLayout>
                <div ref={this.testRef} className="services_my services_content_brokerage">
                    {/*NE-1309*/}
                </div>
                <div className="services_content paraHyperlink">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Customs Brokerage  <span>Documentation</span></h1>
                                <p>Another painstaking task for the shippers, exporters, importers is to identify the right customs broker who provides the least turnaround time and quick documentation arrangement, for their import and export shipments. <Link to="/">Zipaworld</Link> understands the pain areas of the customers and hence provides the option of booking customs brokerage services right from the website at the <Link to="/">most competitive and reliable costs</Link>.</p>
                                <p>Zipaworld’s customs brokerage associates are highly experienced for clearance of all kinds of goods, be it garments, leather products, automobiles, pharmaceuticals, perishables, pets, and so on.</p>
                                <p>Moreover, Zipaworld offers a unique experience of automated documentation and preparation of Airwaybills instantly which usually takes hours when done manually.</p>
                                <p>Also, the <Link to="/OurTeam">Zipaworld professionals</Link> and its associates are well-versed with the procedures and authentications required for the various kinds of goods making it a smooth process that the customer need not take pains for.</p>
                                <p>Zipaworld assists the customers for consultation and providing the Trade Compliance Solutions for obtaining Government incentives, rebates and for preparation and <Link to="/AwardsAndRecognition">authentication of licenses and approvals from the trade authorities</Link>.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <FooterHome/>
            </AppLayout>
            </>

        );
    }


}



export default CustomsBrokerage;
