import React, {Component, Fragment} from "react";
import {Modal, ModalHeader, ModalBody, FormGroup} from "reactstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import ModalFooter from "reactstrap/es/ModalFooter";
import {NavLink} from "react-router-dom";
import RestClient from "../../../util/RestClient";
import {UPDATE_EDIT_RATES} from "../../../constants";
import CalculateDimentionRateData from "./CalculateDimentionRateData";
import RateNotFoundCard from "./RateNotFoundCard";
import AwaitedRateNotPopup from "./AwaitedRateNotPopup";



class AwaitedPopupShipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalLarge: false,
            message: "",
            openLogin: false,
            Compalsari: false,
            rateNotAvailable:false,
            DimentionModel:false,
            RateId:"",
            QueryId:""
        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {

        if (this.props.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        if (this.props.RateId) {
            this.setState({
                RateId: this.props.RateId
            },()=>{

            })
        }
        if(this.props.iRateIndex){
            // console.log("m index hu",this.props.iRateIndex)
        }
        if (this.props.queryDataForcard) {
            this.setState({
                queryDataForcard: this.props.queryDataForcard,
                quoteIdForShipment:this.props.queryDataForcard && this.props.queryDataForcard._id
            },()=>{
                // console.log("////////////////////////",JSON.stringify(this.state.queryDataForcard))
            })
        }
        if(this.props.queryNo) {
            this.setState({
                queryNo:this.props.queryNo
            },()=>{
                // console.log("awaitQueryNo",this.props.queryNo)
            })
        }
        if (this.props.data) {
            this.setState({
                data: this.props.data
            },()=>{
                // console.log("datas",JSON.stringify(this.props.data))
            })
        }
        if(this.props.availRatesForShipmentPage){
            this.setState({
                // BookingID
                availRatesForShipmentPage:this.props.availRatesForShipmentPage,
                availableContainers:this.props.availRatesForShipmentPage && this.props.availRatesForShipmentPage[this.props.iRateIndex].availableRates,
            },()=>{
                // console.log("availRatessss",JSON.stringify(this.props.availRatesForShipmentPage && this.props.availRatesForShipmentPage[this.props.iRateIndex].availableRates))
            })
        }

    }

    toggleLarges = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if (this.state.modalLarge === false){
                this.props.setmodal(false)
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        if (this.props.RateId && this.props.RateId !== prevProps.RateId) {
            this.setState({
                RateId: this.props.RateId
            })
        }
        if (this.props.queryDataForcard && this.props.queryDataForcard !== prevProps.queryDataForcard) {
            this.setState({
                queryDataForcard: this.props.queryDataForcard
            })
        }
        if (this.props.data && this.props.data !== prevProps.data) {
            this.setState({
                data: this.props.data
            },()=>{
                // console.log("datas",JSON.stringify(this.props.data))
            })
        }

    }

    noButtonFunc =()=>{

        // debugger
        const body = {
            toUpdate: this.state.RateId,
            rateStatus: true,

        };
        this.UpdateRates(body).then(response=>{
            if(response.success){
                // console.log("response UpdateRates "+JSON.stringify(response))
                toast.success("Rates Awaited Updated", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    rateNotAvailable:!this.state.rateNotAvailable,
                    modalLarge:false,
                },()=>{
                    // ratesQuotedclosePopup
                })
            }
        })

    }
    UpdateRates = async (body)=> {
        return await RestClient({
            url: UPDATE_EDIT_RATES,
            method: "POST",
            data: body
        })
    };

    yesButtonFunc = () =>{
        this.setState({
            DimentionModel:!this.state.DimentionModel,
            modalLarge:false,
        },()=>{

        })
    }
    volumecapacity = () => {
        try {
            if (this.state.QueryData.volumeWeight !== "") {
                //let grossWeight = parseInt(Math.round(this.state.gross));
                let volumeWeight = parseInt(Math.round(this.state.QueryData.volumeWeight));
                //let CalculatedGross = grossWeight / 1000;

                if (this.state.QueryData.shipmentMode === "FCL") {
                    if (this.state.totalConatinerCbm < volumeWeight) {
                        toast.error("Volume is more than containers capacity,Change number of containers ", {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({volumeWeight: ""})
                    }

                }

            }
        } catch (e) {
        }
    };
    weightcapacity = () => {
        try {
            if (this.state.QueryData.grossWeight !== "") {
                let grossWeight = parseInt(Math.round(this.state.QueryData.grossWeight));
                let CalculatedGross = grossWeight / 1000;

                if (this.state.QueryData.shipmentMode === "FCL") {
                    if (this.state.totalConatinerWeight < grossWeight) {
                        toast.error("Weight is more than containers capacity,Change number of containers ", {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({grossWeight: ""})
                    }
                }

            }
        } catch (e) {
        }
    };
    calculateChargeable = () => {

        try {
            if (this.state.QueryData.volumeWeight && this.state.QueryData.grossWeight) {
                let volumeWeight = parseFloat(this.state.QueryData.volumeWeight).toFixed(2);
                let grossWeight = parseInt(Math.round(this.state.QueryData.grossWeight));
                let CalculatedGross = grossWeight / 1000;
                let volumeInKG = volumeWeight * 1000;


                CalculatedGross > volumeWeight
                    ? this.setState({
                        TotalChargeable: CalculatedGross,
                        unit: "Tons"
                    }, () => {
                        if (this.state.TotalChargeable === "0") {
                            this.setState({
                                calculatedDensity: "0"
                            });
                        } else {
                            let density = (volumeWeight / CalculatedGross) * 100;
                            this.setState({
                                calculatedDensity: parseFloat(density).toFixed(2)
                            });
                        }

                    })
                    : this.setState({
                        TotalChargeable: volumeWeight,
                        unit: "m³"
                    }, () => {

                        if (this.state.TotalChargeable === "0") {
                            this.setState({
                                calculatedDensity: "0"
                            });
                        } else {
                            let density = (volumeWeight / CalculatedGross) * 100;
                            this.setState({
                                calculatedDensity: parseFloat(density).toFixed(2)
                            });
                        }
                    });
            }
        } catch (e) {
        }
    };




    render() {
        let {
            grossWeight, totalPieces,
            volumeWeight, chargeableWeight, density, dimentionType, dimentions,
            shipmentMode,_id
        } = this.props.queryDataForcard;
        let {availableRates,quoteId} = this.props.data;
        // console.log("dfdfdf kahaaha",JSON.stringify(this.props.data))
        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <AwaitedRateNotPopup
                    modalLarges={this.state.rateNotAvailable}
                    setmodals={values => values === false ? this.setState({rateNotAvailable: false}) : ""}
                />

                <CalculateDimentionRateData
                    quoteIdForPopup ={this.props.quoteIdForPopup}
                    DimentionModel={this.state.DimentionModel}
                    rateDataForCard = {this.props.rateDataForCard && this.props.rateDataForCard}
                    tarrifMode={this.state.tarrifMode}
                    queryDataForcard={this.props.queryDataForcard}
                    // quoteId={this.props.queryNo}
                    quoteId={this.props.queryNo}
                    quoteIdForShipment ={this.state.quoteIdForShipment}
                    Id={_id}
                    iRateIndex={this.props.iRateIndex}
                    data={this.props.data}
                    dimentionType={dimentionType}
                    chargeableWeight={chargeableWeight}
                    density={density}
                    RateIds={this.state.RateIds}
                    availableContainers ={this.state.availableContainers}
                    containerType={availableRates?availableRates : []}
                    shipmentMode={shipmentMode}
                    totalPieces={totalPieces}
                    attachmentss={attachmentss=>{
                        this.setState({attachments: attachmentss})
                    }}

                    gross={grossWeight}
                    volume={volumeWeight}
                    dimension={dimentions}
                    returnClose={(value) => {
                        if (value === true) {
                            this.setState({DimentionModel: false},()=>{
                                this.props.openShipperModel(true)
                            })
                        }

                    }}
                    returnResult={(value) => {
                        this.setState({
                            totalPieces: value.totalPieces ? value.totalPieces : "",
                            volume: value.volume ? value.volume : "",
                            gross: value.grossWeight,
                            dimentions: value.dimentions,
                        }, () => {
                            this.volumecapacity()
                            this.weightcapacity()
                            this.calculateChargeable()
                        })
                    }}

                />

                <Modal
                    isOpen={this.state.modalLarge}
                    size="md"
                    toggle={this.toggleLarges}
                >
                    <>
                        <ModalHeader style={{borderBottom: '1px solid #d7d7d7'}} toggle={this.toggleLarges} className="rates_heads">
                            Awaited Rates
                        </ModalHeader>
                        <ModalBody>
                            <div className="back-img">
                                <h2 className="rates_head">Rates Are Awaited. Do You Want to Proceed?</h2>
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center rates-not">
                            <button type="button" className="btn btn-secondary" onClick={e => {
                                e.preventDefault()
                                this.yesButtonFunc()
                            }}>Yes</button>
                            <button type="button" className="btn btn-danger" onClick={e => {
                                e.preventDefault()
                                this.noButtonFunc()
                            }}>No</button>
                        </ModalFooter>
                    </>
                </Modal>
            </>
        )};
};

export default AwaitedPopupShipment;