import {
    REGISTRATION_FETCH_FAILURE,
    REGISTRATION_FETCH_MASTER,
    REGISTRATION_FETCH_RESPONSE,
} from "../actions";

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
};


const masterRegistrationReducer = (state = initState, {type, payload}) => {
    switch (type) {

        case REGISTRATION_FETCH_MASTER:
            // console.log("In Master Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};

        case REGISTRATION_FETCH_RESPONSE:
            // console.log("in Master Success" +JSON.stringify(payload.data));
            return {...state, loading: false, items: payload.data, totalItemCount: payload.total, error: ''};

        case REGISTRATION_FETCH_FAILURE:
            // console.log("in Master Failure" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload.message};

        default:
            return initState;
    }
};

export default masterRegistrationReducer;
