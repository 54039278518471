import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody,} from "reactstrap";
import {connect} from "react-redux";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";


class BookSlabPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSlabSelected : false,
            modalLarge1: false,
            
        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {

        if (this.props.modalLarge1) {
            this.setState({
                modalLarge1: this.props.modalLarge1
            })
        }
 
    }

    toggleLarge = () => {
        this.setState(prevState => (
            {
            modalLarge1: !prevState.modalLarge1
        }),()=>{
            if (this.state.modalLarge1 === false){
                this.props.setmodal1(false)
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge1 && this.props.modalLarge1 !== prevProps.modalLarge1) {
            this.setState({
                modalLarge1: this.props.modalLarge1
            })
        }
        
    }

    render() {
       
        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Modal className=""
                    isOpen={this.state.modalLarge1}
                    size="lg"
                    toggle={this.toggleLarge}
                >

                        <>
                            <ModalHeader style={{borderBottom:'1px solid #ddd'}} toggle={this.toggleLarge} className="">
                                <h3><strong>Booking Slab confirmation</strong></h3>
                            </ModalHeader>
                            <ModalBody className="">
                                <div className="booking-slab">
                                    <h4>{this.props.minSlab && this.props.maxSlab ?  `To Book your Chargeable weight must lie between ${this.props.minSlab} KGS and ${this.props.maxSlab} KGS and your chargeable weight is ${this.props.chargeableWeightForSlab} KGS`  : `Please Select Slab` }</h4>
                                </div>
                            </ModalBody>
                        </>




                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {

};


export default connect(
    mapStateToProps,
    {}
)(BookSlabPopup);
