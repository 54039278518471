import React, { Component} from "react";


class DemoVedio extends Component {
    constructor(props) {
        super(props);
        this.state = {


        };

    }


    render() {
        return (
            <>


                <section className="vedio-screen" style={{backgroundImage:"url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.041530703953892134-1652448087294.png')",
                    minHeight: "100vh",
                    backgroundPosition: "center",
                    backgroundSize: "cover"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="laptop-wrapper text-center">
                                    {/*<ReactPlayer url='https://www.youtube.com/watch?v=vgV8EaAXsJE' playing="true"/>*/}
                                    <iframe width="100%" className="desktop_show_demo" height="550" src="https://www.youtube.com/embed/vgV8EaAXsJE?autoplay=1&mute=1" allow="autoplay"
                                            frameBorder="0" allowFullScreen></iframe>
                                    <iframe width="100%" className="mobile_show_demo" height="350" src="https://www.youtube.com/embed/vgV8EaAXsJE?autoplay=1&mute=1" allow="autoplay"
                                            frameBorder="0" allowFullScreen></iframe>
                                    <div className="vedio_btn">
                                        <button>Explore Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </>
        );
    }


}


export default DemoVedio;
