import React from 'react'
import "../../../assets/css/appDownload.css"

function DownLoadApp() {
    return (
        <>
            <div className='main_sections'>
                <div className='main-left'>

                    <div className='main-headings'>
                        <h1>DOWNLOAD <br/><span>THE APP NOW</span></h1>
                        <p>To activate the power of our worldwide logistics network on your smart phone.</p>
                    </div>
                    <div className='btns'>
                        {/* App Store div */}
                        <div className='app-btn'>
                            <a href='https://apps.apple.com/in/app/zipaworld/id1644871960 '><img className='app-img' src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9335272461779098-1671617244922.png" alt="Play Store Apple"/></a>
                        </div>
                        {/* Play Store Div */}
                        <div className='play-btn'>
                            <a href='https://play.google.com/store/apps/details?id=com.zipaworld.app'><img className='store-img' src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.40044682836883383-1671617280632.png" alt="Play Store Mobile"/></a>
                        </div>
                    </div>
                </div>
                <div className="rightImg">
                        <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9452232041946085-1671700280029.png" alt="red curve background"/>
                        {/*<img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.35367566883266655-1671619865328.png" />*/}
                </div>
                <div className="mobileImg">
                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.005542157121698477-1671703839157.png" alt="Mobile Image With "/>
                </div>
                <div className="bottomImg">
                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8500452952405408-1671700314826.png" alt="red curve background"/>
                    {/*<img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.37633621825596-1671619871352.png" />*/}
                </div>
            </div>
            {/*<div className='main_sections'>*/}
            {/*    <div className='inside-main'>*/}
            {/*        /!* left Div *!/*/}
            {/*        <div className='main-left'>*/}
            {/*            <div className='inside-main-left'>*/}
            {/*                <div className='main-headings'>*/}
            {/*                    <h1>DOWNLOAD</h1>*/}
            {/*                    <h2>THE APP NOW</h2>*/}
            {/*                    <p>To activate the power of our worldwide logistics network on your smart phone.</p>*/}
            {/*                </div>*/}
            {/*                <div className='btns'>*/}
            {/*                    /!* App Store div *!/*/}
            {/*                    <div className='app-btn'>*/}
            {/*                        <a href='https://apps.apple.com/in/app/zipaworld/id1644871960 '><img className='app-img' src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9335272461779098-1671617244922.png" alt="appimg"/></a>*/}
            {/*                    </div>*/}
            {/*                    /!* Play Store Div *!/*/}
            {/*                    <div className='play-btn'>*/}
            {/*                        <a href='https://play.google.com/store/apps/details?id=com.zipaworld.app'><img className='store-img' src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.40044682836883383-1671617280632.png" alt="appimg"/></a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        /!* Right Div *!/*/}
            {/*        <div className='main-rigth'>*/}
            {/*            /!* <img id='zipa-img'/> *!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}

export default DownLoadApp;