import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import advanceSearchReducer from './advanceSearch/advanceSearchReducer';
import spotRateReducer from './spotRateRedux/spotRateReducer';
import invoiceMasterReducer from './invoiceRedux/invoiceReducer';
import shipmentHistoryReducer from './shipmentHistoryRedux/shipmentHistoryReducer';
import mawbReducer from './mawbRedux/mawbReducer';
import shipperConsigneeReducer from './shipperConsigneeRedux/shipperConsigneeReducer';
import hawbReducer from './hawbRedux/hawbReducer';
import masterShipperConsigneeApprovalReducer from './ShipperConsigneeApprovalRedux/shipperConsigneeApprovalReducer';
import masterRegistrationReducer from "./registrationMasterRedux/registrationReducers";
import eDocketReducer from "./eDocketRedux/eDocketReducers";
import masterShipperReducer from "./shipperRedux/shipperReducer";
import masterConsigneeReducer from "./consigneeRedux/consigneeReducer";
import notificationReducer from "./notificationRedux/notificationReducer";
import NewsFlashMasterManagerReducer from "./NewsFlashRedux/NewsFlashReducer";
import QueryReducer from "./QueryModule/QueryReducer";
import QueryReducerAir from "./QueryRedux/QueryReducer";
import stateReducer from "./stateMaster/stateReducer";


const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  advanceSearchReducer : advanceSearchReducer,
  spotRateReducer : spotRateReducer,
  invoiceMasterReducer: invoiceMasterReducer,
  shipmentHistoryReducer: shipmentHistoryReducer,
  mawbReducer: mawbReducer,
  shipperConsigneeReducer: shipperConsigneeReducer,
  hawbReducer: hawbReducer,
  masterShipperConsigneeApprovalReducer: masterShipperConsigneeApprovalReducer,
  RegistrationMasterData: masterRegistrationReducer,
  eDocketReducer: eDocketReducer,
  masterShipperReducer: masterShipperReducer,
  masterConsigneeReducer: masterConsigneeReducer,
  notificationData: notificationReducer,
  NewsFlashData: NewsFlashMasterManagerReducer,
  QueryReducer:QueryReducer,
  QueryReducerAir:QueryReducerAir,
  stateReducer:stateReducer,
});

export default reducers;