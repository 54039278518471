import { toastr } from "react-redux-toastr";
import {FormGroup, Label} from "reactstrap";

import React, { Component, Fragment } from "react";
import Select from "react-select";

import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { onConsigneeGetAllRequest } from "../redux/shipperConsigneeRedux/shipperConsigneeActions";
import RestClient from "../util/RestClient";
import {MANAGER_CONSIGNEE, MANAGER_CONSIGNEE_BRANCH, MANAGER_SHIPPER} from "../constants";
import AsyncPaginate from "react-select-async-paginate";
import {getCustomerId} from "../util/CommonUtility";

class ConsigneeWithBranchDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originData: [],
      isLoading: true,
      error: "",
      consigneeData: [],
      branchDetail: {},
      selectedConsignee: {},
      selectedConsigneeBranch: undefined,
      selectionOptionsConsignee: [],
      selectionOptionsConsigneeBranch: []
    };

  }

  componentDidMount() {

    this.reloadConsignee().then(r => {})
    if(this.props.selectedOptionConsignee !== undefined){
      this.setState({selectedOptionCompany : this.props.selectedOptionConsignee})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.error) {
      toastr.success(this.props.error, {
        transitionIn: "bounceIn",
        transitionOut: "bounceOut"
      });
    }

    if(this.props.isSelectionThroughId === true && this.props.selectedConsigneeId && this.props.selectedConsigneeId !== prevProps.selectedConsigneeId
        && this.props.selectedConsigneeBranchId && this.props.selectedConsigneeBranchId !== prevProps.selectedConsigneeBranchId){

      try {
        let customerId = getCustomerId()
        this.setState({selectedConsigneeId:this.props.selectedConsigneeId})
        let consigneeParameters = { start: 0, search: "", sortBy: "",customerId:customerId };
        RestClient({
          url: MANAGER_CONSIGNEE,
          method: "POST",
          data: consigneeParameters
        }).then(response=>{
          if(response.success){
            let data = response?.result?.data
            let selectionOptionsListConsignee = []
            for (let i = 0; i < data.length; i++) {
              let { _id, consigneeName, branchesInfo } = data[i]
              let obj =
                  {
                    value: _id,
                    label: consigneeName,
                    branchesInfo: branchesInfo,
                  };
              selectionOptionsListConsignee.push(obj)
            }
// this.setState({selectionOptions:selectionOptionsListConsignee})
            selectionOptionsListConsignee.map(values=>{
              if(this.state.selectedConsigneeId === values.value) {

                this.setState({
                  selectedConsigneeName: values.label,
                  selectedOptionCompany: values,
                  selectedConsigneeId: values.value,
                  branchesInfo: values.branchesInfo,
                }, () => {
                  //    console.log("selectedShipperBranches::" + JSON.stringify(this.state))
                  this.sendDataToParent()
                });
              }
            })
          }



        }).catch()



      } catch (e) {

      }
    }

    if (this.props.selectedConsigneeBranchId && this.props.selectedConsigneeBranchId !== prevProps.selectedConsigneeBranchId) {


      this.setState({selectedConsigneeBranchId :this.props.selectedConsigneeBranchId })
      this.fetchConsigneeBranchList({id: this.props.selectedConsigneeId}).then(responce=>{

        if(responce && responce.result !== undefined){

          let selectionOptionsListConsigneeBranch = []

          for (let i = 0; i < responce.result.length; i++) {
            let { _id, branchName } = responce.result[i]
            let obj =
                {
                  value: _id,
                  label: branchName,
                  branchDetail: responce.result[i],
                };
            selectionOptionsListConsigneeBranch.push(obj)
          }

          this.setState({
            selectionOptionsConsigneeBranch: selectionOptionsListConsigneeBranch
          });
          selectionOptionsListConsigneeBranch.map(Branchvalues=>{

            if( this.state.selectedConsigneeBranchId === Branchvalues.value){
              this.setState({
                selectedOptionBranch: Branchvalues,
                branchDetail: Branchvalues.branchDetail,
                selectedConsigneeBranchName: Branchvalues.label,
                selectedConsigneeBranchId: Branchvalues.value,
              })
              // this.props.onShipperConsigneeSelected({
              //   consigneeName: this.state.selectedConsigneeName,
              //   consigneeId: this.state.selectedConsigneeId,
              //   consigneeBranchName: Branchvalues.label,
              //   consigneeBranchId: Branchvalues.value,
              //   consigneeDetails: Branchvalues.branchDetail
              // });
            }

          })
        }


      })
    }

    if(this.props.selectedOptionConsignee && this.props.selectedOptionConsignee !== prevProps.selectedOptionConsignee){

      this.setState({selectedOptionCompany : this.props.selectedOptionConsignee})
    }

    if (this.props.selectedConsigneeName !== this.state.selectedConsigneeName) {

      this.setState({
        selectedConsigneeName: this.props.selectedConsigneeName,
        selectedConsigneeId: this.props.selectedConsigneeId,
        selectedConsigneeBranchName: this.props.selectedConsigneeBranchName,
        selectedConsigneeBranchId: this.props.selectedConsigneeBranchId,
        selectedConsigneeAccountNo: this.props.selectedConsigneeAccountNo,
        consigneeEmail:this.props.consigneeEmail,

        branchDetail: this.props.consigneeDetails ? this.props.consigneeDetails : {},
        selectedOptionCompany: {
          label: this.props.selectedConsigneeName,
          value: this.props.selectedConsigneeId,
          branchesInfo: []
        }, selectedOptionBranch: {
          label: this.props.selectedConsigneeBranchName,
          value: this.props.selectedConsigneeBranchId,
          branchesInfo: []
        }
      })

    }

    if (this.props.selectedConsigneeBranch && this.state.selectedConsigneeBranch !== this.props.selectedConsigneeBranch) {

      this.setState({
        selectedConsigneeBranch: this.props.selectedConsigneeBranch
      })
    }

//     if (this.props.reloadConsignee && prevProps.reloadConsignee !== this.props.reloadConsignee) {
// if(this.props.reloadConsignee == true){
//   this.reloadConsignee().then()
// }
//          }

    // if (this.state.consigneeData !== this.props.consigneeData) {
    //   this.setState({
    //     consigneeData: this.props.consigneeData
    //   })
    //   // toastr.success("Consignee Loaded", "" + "", {
    //   //   transitionIn: "bounceIn",
    //   //   transitionOut: "bounceOut"
    //   // });
    //   this.setSelectionFromList(this.props.consigneeData ? this.props.consigneeData.result : [])
    // }


  }
  fetchConsignee = async (shipperParameters) => {
    {
      return await RestClient({
        url: MANAGER_CONSIGNEE,
        method: "POST",
        data: shipperParameters
      });
    }
  };

  reloadConsignee = async () => {
    // let shipperParameters = { start: loadedOptions.length, search: search, sortBy: "" };
    let customerId = getCustomerId()
    let data = await this.fetchConsignee({customerId : customerId});
    try {
      if (data.success && data.result.data !== undefined && data.result.data.length > 0) {
        //    console.log("In Save state")

        this.setState({
          ConsigneeName:data.result &&  data.result.data ? data.result.data : []
        }, () => {
          let selectionOptionsListConsignee = []
          let data = this.state.ConsigneeName
          if (data) {

            for (let i = 0; i < data.length; i++) {
              let { _id, consigneeName, branchesInfo } = data[i]
              let obj =
                  {
                    value: _id,
                    label: consigneeName,
                    branchesInfo: branchesInfo,
                  };
              selectionOptionsListConsignee.push(obj)
            }
          }
          this.setState({
            selectionOptions: selectionOptionsListConsignee
          });
        });
      }
    } catch (e) {
    }
  };

  loadOptions = async (search, loadedOptions) => {

    try {
      if(search !== "" && search !== undefined) {
        let customerId = getCustomerId()
        let consigneeParameters = {search: search,customerId : customerId};
        const response = await RestClient({
          url: MANAGER_CONSIGNEE,
          method: "POST",
          data: consigneeParameters
        });
        // debugger

        let data = response.result.data
        let selectionOptionsListConsignee = []
        for (let i = 0; i < data.length; i++) {
          let {_id, consigneeName, branchesInfo} = data[i]
          let obj =
              {
                value: _id,
                label: consigneeName,
                branchesInfo: branchesInfo,
              };
          selectionOptionsListConsignee.push(obj)
        }
        // this.setState({
        //     selectionOptionsConsignee: selectionOptionsListConsignee
        // })
        return {
          options: selectionOptionsListConsignee,
          hasMore: !search,
        };
      }else{
        let customerId = getCustomerId()
        let consigneeParameters = {search: search,customerId : customerId};
        const response = await RestClient({
          url: MANAGER_CONSIGNEE,
          method: "POST",
          data: consigneeParameters
        });
        // debugger

        let data = response.result.data
        let selectionOptionsListConsignee = []
        for (let i = 0; i < data.length; i++) {
          let {_id, consigneeName, branchesInfo} = data[i]
          let obj =
              {
                value: _id,
                label: consigneeName,
                branchesInfo: branchesInfo,
              };
          selectionOptionsListConsignee.push(obj)
        }
        const slicedOptions = selectionOptionsListConsignee.slice(0,10)
        return {
          options: slicedOptions,
          hasMore: false,
        };



      }
    } catch (e) {
      return {
        options: loadedOptions,
        hasMore: false,
      };
    }


  }


  sendDataToParent = () => {

    this.props.onShipperConsigneeSelected({
      consigneeName: this.state.selectedConsigneeName,
      consigneeId: this.state.selectedConsigneeId,
      consigneeBranchName: this.state.selectedConsigneeBranchName,
      consigneeBranchId: this.state.selectedConsigneeBranchId,
      consigneeDetails: this.state.branchDetail,
      selectedConsigneeAccountNo: this.state.selectedConsigneeAccountNo,
      consigneeEmail:this.state.consigneeEmail
    });

  }

  setSelectionFromList(data) {

    let selectionOptionsListConsignee = []
    for (let i = 0; i < data.length; i++) {
      let { _id, consigneeName, branchesInfo } = data[i]
      let obj =
          {
            value: _id,
            label: consigneeName,
            branchesInfo: branchesInfo,
          };
      selectionOptionsListConsignee.push(obj)
    }

    this.setState({
      selectionOptionsConsignee: selectionOptionsListConsignee
    });
  }

  setBranchesSelectionFromList(data) {
    if(data !== undefined){
      this.fetchConsigneeBranchList({id:data}).then(responce=>{
        if(responce && responce.result !== undefined){

          let selectionOptionsListConsigneeBranch = []
          for (let i = 0; i < responce.result.length; i++) {
            let { _id, branchName } = responce.result[i]
            let obj =
                {
                  value: _id,
                  label: branchName,
                  branchDetail: responce.result[i],
                };
            selectionOptionsListConsigneeBranch.push(obj)
          }

          this.setState({
            selectionOptionsConsigneeBranch: selectionOptionsListConsigneeBranch
          });
        }

      })

    }

  }

  fetchConsigneeBranchList = async (shipperParameters) => {
    {
      return await RestClient({
        url: MANAGER_CONSIGNEE_BRANCH,
        method: "POST",
        data: shipperParameters
      });
    }
  };

  handleChange = e => {
    let branchDetail = this.state.branchDetail
    branchDetail[e.target.name] = e.target.value
    this.setState({ branchDetail: branchDetail }, () => {
    });
  };

  sleep = ms =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, ms);
      });

  render() {
    try {
      return (
          <Fragment>
            <div className={this.props.className ? this.props.className : "width-4"}>
              <FormGroup disabled={this.props.hidden} hidden = {this.props.hidden}>
                {this.props.queryFor !== "Courier"  ?
                    <Label className="origin-label">Consignee Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                    :
                    <Label className>Receiver Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                }


                <AsyncPaginate
                    {...this.props}
                    value={this.state.selectedOptionCompany}
                    placeholder ="Search Consignee"
                    debounceTimeout={100}
                    readonly="true"
                    loadOptions={this.loadOptions}
                    onChange={values => {
                      //if(this.props.readonly !== true) {
                        this.setState({
                          selectedConsigneeName: values.label,
                          selectedOptionCompany: values,
                          selectedConsigneeId: values.value,
                          branchesInfo: values.branchesInfo,
                          selectedOptionBranch: "",
                        }, () => {
                          this.sendDataToParent()
                          this.setBranchesSelectionFromList(this.state.selectedConsigneeId)
                        });
                      //}
                    }}

                />
                {this.props.consigneeValid ? <span className="text-danger">{this.props.consigneeValid}</span>:""}

                {/*<Select*/}
                {/*    className="basic-single"*/}
                {/*    classNamePrefix="select"*/}
                {/*    name="selectedShipperName"*/}
                {/*    value={this.state.selectionOptions.filter(option => option.value === this.props.selectedConsigneeId)}*/}

                {/*    onChange={values => {*/}
                {/*      //   console.log("selectedShipperBranches::" + JSON.stringify(values))*/}
                {/*      this.setState({*/}
                {/*        selectedConsigneeName: values.label,*/}
                {/*        selectedOptionCompany: values,*/}
                {/*        selectedConsigneeId: values.value,*/}
                {/*        branchesInfo: values.branchesInfo,*/}
                {/*        selectedOptionBranch:"",*/}
                {/*      }, () => {*/}
                {/*        //    console.log("selectedShipperBranches::" + JSON.stringify(this.state))*/}
                {/*        this.sendDataToParent()*/}
                {/*        this.setBranchesSelectionFromList(this.state.selectedConsigneeId)*/}
                {/*      });*/}
                {/*    }}*/}
                {/*    options={this.state.selectionOptions}*/}
                {/*/>*/}

              </FormGroup>
            </div>
            <div className={this.props.className ? this.props.className : "width-4"}>
              <FormGroup  hidden = {this.props.hidden}>

                {this.props.queryFor !== "Courier" ?
                    <Label className="origin-label">Consignee Branch</Label>
                    :
                    <Label className="origin-label">Receiver Branch</Label>
                }
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    readonly=""
                    name="consigneeBranch"
                    value={this.state.selectedOptionBranch}
                    onChange={values => {
                      //if(this.props.readonly !== true) {
                        this.setState({
                          selectedOptionBranch: values,
                          branchDetail: values.branchDetail,
                          selectedConsigneeName: this.state.selectedConsigneeName,
                          selectedConsigneeId: this.state.selectedConsigneeId,
                          selectedConsigneeBranchName: values.label,
                          selectedConsigneeBranchId: values.value,
                          selectedConsigneeAccountNo: "",
                          consigneeEmail: values && values.branchDetail && values.branchDetail.email,
                        }, () => {

                          this.sendDataToParent()
                        })
                      //}

                    }}
                    options={this.state.selectionOptionsConsigneeBranch}
                />
                {this.props.consigneeBranchId ? <span className="text-danger">{this.props.consigneeBranchId}</span>:""}
              </FormGroup>

            </div>
          </Fragment>
      );
    } catch (e) {
      // alert(e)
      return ("");


      // <Input type="select" required>
      //   <option> Please Reload</option>
      // </Input>

    }
  }


}

const propTypes = {
  isSelectionThroughName: PropTypes.bool.isRequired,
  selectedConsignee: PropTypes.string.isRequired,
  selectedConsigneeName: PropTypes.string.isRequired,
  selectedConsigneeBranch: PropTypes.string.isRequired,
  selectedConsigneeBranchName: PropTypes.string.isRequired,
  onShipperConsigneeSelected: PropTypes.func.isRequired,
};

const defaultProps = {
  isSelectionThroughName: false,
  selectedConsigneeName: undefined,
  selectedConsigneeId: undefined,
  selectedConsigneeBranchName: undefined,
  selectedConsigneeBranchId: undefined,
  onShipperConsigneeSelected: undefined,
};

ConsigneeWithBranchDropdown.propTypes = propTypes;
ConsigneeWithBranchDropdown.defaultProps = defaultProps;


const mapStateToProps = state => {
  const { consigneeData, } = state.shipperConsigneeReducer;
  return { consigneeData, };
};


export default connect(mapStateToProps, {
  onConsigneeGetAllRequest,

})(ConsigneeWithBranchDropdown);

