import React, {Component,lazy, Suspense} from "react";
import RestClient from "../../util/RestClient";
import {
    CHECK_SHIPPING_BILL,
    DOWNLOAD_SHIPPING_BILL_API,
    GET_QUERY_PREVIEW_API_AIR,
    GET_QUERY_PREVIEW_API_OCEAN,
    GET_QUERY_PREVIEW_API_ROAD,
    GET_QUERY_PREVIEW_API_COURIER,
    QUERY_DATA_GET_API_AIR,
    QUERY_DATA_GET_API_COURIER,
    QUERY_DATA_GET_API_OCEAN,
    QUERY_DATA_GET_API_ROAD,
    RECOMMENDED_RATES_OF_ZIPA,
    CHECK_SHIPPING_BILL_AIR,
    CHECK_SHIPPING_BILL_OCEAN,
    CHECK_SHIPPING_BILL_ROAD,
    CHECK_SHIPPING_BILL_COURIER,
    DOWNLOAD_SHIPPING_BILL_API_AIR,
    DOWNLOAD_SHIPPING_BILL_API_OCEAN,
    DOWNLOAD_SHIPPING_BILL_API_ROAD,
    DOWNLOAD_SHIPPING_BILL_API_COURIER, CHECK_BL_BILL_AIR, DOWNLOAD_BL_BILL_API
} from "../../constants";
import AppLayout from "../../layout/AppLayout";
import {PAGE_PATHS} from "../../util/PageRoute";
import {convertDateISOToCustomiseFormat} from "../../util/CommonUtility";
import {faAngleLeft, faAngleRight, faArrowRight, faFilter, faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const RateQuotedCardNew = lazy(() => import('../../views/app/ShipementHistory/RateQuotedCardNew'));
const renderLoader = () => <p></p>;
class RatesQueryPreview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ChargeRateNotFoundModel:false,
            openTrack: true,
            rateQuotedTrack: false,
            bookingTrack: false,
            airwayBillTrack: false,
            invoiceTrack: false,
            zipaSuggestion: false,
            suggestNotAvailble: false,
            sideSuggestion: false,
            FilterSide: false,
            vesselSchedules: {},
            vesselSchedulesMearsk:{},
            suggestedRatesStatus:false,
            notDisplayClearance:false,
            additionalCharge:false,
            additionalIcons:false,
            shippingBillId:""
        }

    }

    componentDidMount() {
        let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        let quoteId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        this.setState({quoteFor: quoteFor,quoteIdUrl : quoteId},()=>{
            this.GET_BY_QUOTEID_DATA({id:quoteId}).then(responce=> {
                if(responce.success && responce.result){
                    let getQuoteResult = responce && responce.result ? responce.result : "";
                    let originName = getQuoteResult && getQuoteResult.originAirport && getQuoteResult.originAirport.name ? getQuoteResult.originAirport.name : "";
                    let originCode = getQuoteResult && getQuoteResult.originAirport && getQuoteResult.originAirport.code ? getQuoteResult.originAirport.code : "";
                    let originDoor = getQuoteResult && getQuoteResult.originDoor && getQuoteResult.originDoor.city ? getQuoteResult.originDoor.city : "";
                    let destinationName = getQuoteResult && getQuoteResult.destinationAirport && getQuoteResult.destinationAirport.name ? getQuoteResult.destinationAirport.name : "";
                    let destinationCode = getQuoteResult && getQuoteResult.destinationAirport && getQuoteResult.destinationAirport.code ? getQuoteResult.destinationAirport.code : "";
                    let destinationDoor = getQuoteResult && getQuoteResult.destinationDoor && getQuoteResult.destinationDoor.city ? getQuoteResult.destinationDoor.city : "";
                    let totalPieces = getQuoteResult && getQuoteResult.totalPieces ? getQuoteResult.totalPieces : "";
                    let volumeWeight = getQuoteResult && getQuoteResult.volumeWeight ? getQuoteResult.volumeWeight : "";
                    let grossWeight = getQuoteResult && getQuoteResult.grossWeight ? getQuoteResult.grossWeight : "";
                    let activityType = getQuoteResult && getQuoteResult.activityType ? getQuoteResult.activityType : "";
                    let clearenceDate = getQuoteResult && getQuoteResult.clearenceDate ? getQuoteResult.clearenceDate : "";
                    let chargeableWeight = getQuoteResult && getQuoteResult.chargeableWeight ? getQuoteResult.chargeableWeight : "";
                    let shipmentMode = getQuoteResult && getQuoteResult.shipmentMode ? getQuoteResult.shipmentMode : "";
                    let tarrifMode = getQuoteResult && getQuoteResult.tarrifMode ? getQuoteResult.tarrifMode : "";
                    let dimentions = getQuoteResult && getQuoteResult.dimentions ? getQuoteResult.dimentions : [];
                    let dimentionType = getQuoteResult && getQuoteResult.dimentionType ? getQuoteResult.dimentionType : "";
                    let density = getQuoteResult && getQuoteResult.density ? getQuoteResult.density :"";
                    let queryFor = getQuoteResult && getQuoteResult.queryFor ? getQuoteResult.queryFor : "";
                    let noOfContainers = getQuoteResult && getQuoteResult.noOfContainers ? getQuoteResult.noOfContainers :getQuoteResult && getQuoteResult.containerDetails && getQuoteResult.containerDetails.length ?getQuoteResult.containerDetails.length: "";
                    let commodity = getQuoteResult && getQuoteResult.commodity ? getQuoteResult.commodity : "";
                    let activeStatus = getQuoteResult && getQuoteResult.activeStatus ? getQuoteResult.activeStatus : "";
                    let quoteStatus = getQuoteResult && getQuoteResult.quoteStatus ? getQuoteResult.quoteStatus : "";
                    let invoiceId = getQuoteResult && getQuoteResult.prevNext && getQuoteResult.prevNext.InvoiceApproved && getQuoteResult.prevNext.InvoiceApproved.nId? getQuoteResult.prevNext.InvoiceApproved.nId : "";
                    let bookingId = getQuoteResult && getQuoteResult.prevNext && getQuoteResult.prevNext.Executed && getQuoteResult.prevNext.Executed.pId? getQuoteResult.prevNext.Executed.pId : "";
                    let referenceJobNo = getQuoteResult && getQuoteResult.referenceJobNo? getQuoteResult.referenceJobNo : "";
                    let jobNo = getQuoteResult && getQuoteResult.jobNo? getQuoteResult.jobNo : "";
                    let custType = getQuoteResult && getQuoteResult.custType? getQuoteResult.custType : "";
                    let originCountry = getQuoteResult && getQuoteResult.originCountry? getQuoteResult.originCountry : "";
                    let destinationCountry = getQuoteResult && getQuoteResult.destinationCountry? getQuoteResult.destinationCountry : "";
                    let rateIdForBooking = getQuoteResult && getQuoteResult.bookingData && getQuoteResult.bookingData.rateId ? getQuoteResult.bookingData.rateId : "";
                    let bookingQueryfor = getQuoteResult && getQuoteResult.bookingData && getQuoteResult.bookingData.queryFor ? getQuoteResult.bookingData.queryFor : "";
                    let bookingchargeableWeight =   getQuoteResult && getQuoteResult.bookingData && getQuoteResult.bookingData.chargeableWeight ? getQuoteResult.bookingData.chargeableWeight : "";
                    let queryType = getQuoteResult && getQuoteResult.queryType? getQuoteResult.queryType : "";
                    this.setState({
                        bookingQueryfor:bookingQueryfor,
                        bookingchargeableWeight:bookingchargeableWeight,

                        getQuoteResult:getQuoteResult,
                        // rateDataForCard:getQuoteResult,
                        originName:originName,
                        destinationName:destinationName,
                        totalPieces:totalPieces,
                        clearenceDate:clearenceDate,
                        volumeWeight:volumeWeight,
                        grossWeight:grossWeight,
                        activityType:activityType,
                        shipmentMode:shipmentMode,
                        chargeableWeight:chargeableWeight,
                        tarrifMode:tarrifMode,
                        commodity:commodity,
                        noOfContainers:noOfContainers,
                        dimentions:dimentions,
                        dimentionType:dimentionType,
                        density:density,
                        queryFor:queryFor,
                        activeStatus:activeStatus,
                        quoteStatus:quoteStatus,
                        invoiceId:invoiceId,
                        bookingId:bookingId,
                        quoteIdSuggestion:quoteId,
                        referenceJobNo:referenceJobNo,
                        originCode:originCode,
                        destinationCode:destinationCode,
                        jobNo:jobNo,
                        rateIdForBooking:rateIdForBooking,
                        originCountry:originCountry,
                        destinationCountry:destinationCountry,
                        custType:custType,
                        queryType : queryType
                    },()=>{
                        if(this.state.custType === "Exporter-Importer"){
                            this.checkShippingBill({ quoteId: quoteId,queryType : this.state.queryType }).then((responce) => {
                                if (responce.success) {
                                    let shippingBillId = responce && responce.result && responce.result._id ? responce.result._id: ""
                                    this.setState({
                                        shippingBillId : shippingBillId
                                    })
                                }
                            })
                        }

                        if (this.state.originCountry.toLowerCase() === "india" && this.state.destinationCountry.toLowerCase() === "india") {
                            this.setState({
                                notDisplayClearance : true
                            })
                        }
                        this.setState({
                            vesselSchedules : {
                                departureDate : this.state.clearenceDate  ? this.state.clearenceDate.substring(0, 10) : "",
                                originCode : this.state.originCode,
                                destinationCode : this.state.destinationCode,
                            }

                        })
                        let originName = this.state.originName

                        const parts = originName.split(',');
                        const originCodeSplit = parts && parts[0].substring(0, 2);
                        // const aejea = parts[0];
                        // const originCitySplit = parts[1].match(/(.*?)\(/)[1].trim();

                        let destinationName =  this.state.destinationName
                        const dest = destinationName.split(',');
                        const destCodeSplit = dest && dest[0].substring(0, 2);
                        // const destCountry = dest[0];
                        // const destCitySplit = dest[1].match(/(.*?)\(/)[1].trim();
                        this.setState({
                            // originCodesplit:aejea,
                            originCountrysplit:originCodeSplit ? originCodeSplit:"",
                            // originCitysplit:originCitySplit,
                            // destinationCodesplit:destCountry,
                            destinationCountrysplit:destCodeSplit ? destCodeSplit:"",
                            // destinationCitysplit:destCitySplit
                        },()=>{
                            this.setState({
                                vesselSchedulesMearsk : {
                                    originCode : this.state.originCode ? this.state.originCode :"",
                                    originCity :originDoor ? originDoor:"",
                                    originCountry : this.state.originCountrysplit ? this.state.originCountrysplit:"",
                                    destinationCode: this.state.destinationCode ? this.state.destinationCode:"",
                                    destinationCity: destinationDoor ? destinationDoor :"",
                                    destinationCountry: this.state.destinationCountrysplit ?this.state.destinationCountrysplit:"",
                                    departureDate : this.state.clearenceDate  ? this.state.clearenceDate.substring(0, 10) : "",

                                }

                            })
                        })

                        localStorage.setItem('queryData', JSON.stringify(getQuoteResult))

                        if(this.state.activityType === "Door To Door"){
                            this.setState({
                                originName:originDoor,
                                destinationName:destinationDoor,
                            })
                        }else if(this.state.activityType === "Airport To Door" || this.state.activityType === "Port To Door"){
                            this.setState({
                                destinationName:destinationDoor,
                            })
                        }else if(this.state.activityType === "Door To Airport" || this.state.activityType === "Door To Port"){
                            this.setState({
                                originName:originDoor,
                            })
                        }
                    })
                }
            })
        })
        if (localStorage.getItem("shippingBillId")) {
           
            let shippingBillId = localStorage.getItem("shippingBillId");
            this.setState({
                shippingBillIdforOcean:shippingBillId
            }, () => {
            })
        }



    }
    zipasuggestionFunction = () =>{
        let quoteId = this.state.quoteIdSuggestion
        if(this.state.queryFor ==="Air"){
            this.recommendedRates({ quoteId: quoteId,referenceJobNo:this.state.referenceJobNo }).then(res => {
                if (res.success) {
                    this.setState({ zipaRecommendedRates: res.result,sideSuggestion:true })
                }else{
                    this.setState({
                        suggestNotAvailble : true,sideSuggestion:false
                    })
                }
            })
        }
        else if(this.state.queryFor ==="Ocean"){
            if(this.state.shipmentMode === "LCL"){
                this.recommendedRates({ quoteId: quoteId,referenceJobNo:this.state.referenceJobNo }).then(res => {
                    if (res.success) {
                        this.setState({ zipaRecommendedRates: res.result,sideSuggestion:true })
                    }else{
                        this.setState({
                            suggestNotAvailble : true,sideSuggestion:false
                        })
                    }
                })
            }else if(this.state.quoteStatus!=="Open" && this.state.quoteStatus!=="Rates Quoted" && this.state.quoteStatus !== "Rates Awaited"){
                this.zipasuggestionForFCL({ id: this.state.rateIdForBooking}).then(res => {
                    if (res.success) {
                        let suggestedRatesResult = res && res.result ? res.result : []
                        this.setState({
                            suggestedRatesStatus : suggestedRatesResult && suggestedRatesResult[0].suggestedRates ? suggestedRatesResult[0].suggestedRates :false
                        },()=>{
                            if(this.state.suggestedRatesStatus === true){
                                this.recommendedRates({ quoteId: quoteId,referenceJobNo:this.state.referenceJobNo }).then(res => {
                                    if (res.success) {
                                        this.setState({ zipaRecommendedRates: res.result,sideSuggestion:true })
                                    }else{
                                        this.setState({
                                            suggestNotAvailble : true,sideSuggestion:false
                                        })
                                    }
                                })
                            }

                        })
                    }
                })
            }
        }
    }
    checkShippingBill = async (dataForServer) => {
        return await RestClient({
            // url: CHECK_SHIPPING_BILL,
            url: this.state.quoteFor === "Air" ? this.state.queryType === "Air Import" ? CHECK_BL_BILL_AIR : CHECK_SHIPPING_BILL_AIR : this.state.quoteFor === "Ocean" ? CHECK_SHIPPING_BILL_OCEAN : this.state.quoteFor === "Road" ? CHECK_SHIPPING_BILL_ROAD : CHECK_SHIPPING_BILL_COURIER,

            method: "POST",
            data: dataForServer,
        });
    };

    zipasuggestionForFCL = async (dataForServer) =>{
        return await RestClient({
            url: this.state.quoteFor === "Air" ? QUERY_DATA_GET_API_AIR : this.state.quoteFor === "Ocean" ? QUERY_DATA_GET_API_OCEAN : this.state.quoteFor === "Road" ? QUERY_DATA_GET_API_ROAD : QUERY_DATA_GET_API_COURIER,
            method: "POST",
            data: dataForServer
        });
    }
    recommendedRates = async (dataForServer) => {
        return await RestClient({
            url: RECOMMENDED_RATES_OF_ZIPA,
            method: "POST",
            data: dataForServer
        });
    }
    GET_BY_QUOTEID_DATA = async (getByQuoteData) => {
        {
            return await RestClient({
                url: this.state.quoteFor === "Air" ? GET_QUERY_PREVIEW_API_AIR : this.state.quoteFor === "Ocean" ? GET_QUERY_PREVIEW_API_OCEAN : this.state.quoteFor === "Road" ? GET_QUERY_PREVIEW_API_ROAD : GET_QUERY_PREVIEW_API_COURIER ,
                method: "POST",
                data: getByQuoteData
            });
        }
    };

    tracking = (value) => {
        let maindata = {}
        if (value === "Invoice") {

            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = true;
            maindata.invoiceTrack = false;

        }

        if (value === "Shipment Lost") {
            maindata.openTrack = false;
            maindata.rateQuotedTrack = false;
            maindata.bookingTrack = false;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Shipment Executed") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = true;
            maindata.invoiceTrack = false;
        }
        if (value === "Shipment Final") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Shipment Created") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = true;
            maindata.invoiceTrack = false;
        }
        if (value === "Booking") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = true;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Rates Confirmed") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = false;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Rates Quoted") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = true;
            maindata.bookingTrack = false;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        if (value === "Open") {
            maindata.openTrack = true;
            maindata.rateQuotedTrack = false;
            maindata.bookingTrack = false;
            maindata.airwayBillTrack = false;
            maindata.invoiceTrack = false;
        }
        return maindata;

    }
    DownloadShippingBill = () => {
        if(this.state.shippingBillIdforOcean){
        window.open("https://aaa2innovate.s3.ap-south-1.amazonaws.com/OceanShippingBill.pdf", "_blank");
        }else {
        this.setState({
            shippingBillStatus : true
        },()=>{
            this.downloadShippingBillApi({shippingBillId:this.state.shippingBillId}).then(responce=> {
                if(responce.success){
                    this.setState({
                        shippingBillStatus : false
                    },()=>{
                        let pdfLink = responce && responce.result
                        // document.location.href = pdfLink;
                        window.open(pdfLink, "_blank");
                    })

                }else{
                    this.setState({
                        shippingBillStatus : false
                    })
                }
            })

        })
    }
    };
    downloadShippingBillApi = async (getByQuoteData) => {
        {
            return await RestClient({

            url: this.state.quoteFor === "Air" ? this.state.queryType === "Air Import" ? DOWNLOAD_BL_BILL_API : DOWNLOAD_SHIPPING_BILL_API_AIR : this.state.quoteFor === "Ocean" ? DOWNLOAD_SHIPPING_BILL_API_OCEAN : this.state.quoteFor === "Road" ? DOWNLOAD_SHIPPING_BILL_API_ROAD : DOWNLOAD_SHIPPING_BILL_API_COURIER,

                // url: DOWNLOAD_SHIPPING_BILL_API,
                method: "POST",
                data: getByQuoteData
            });
        }
    };
    getByQuoteIdAgain = () =>{
        this.GET_BY_QUOTEID_DATA({id:this.state.quoteIdUrl}).then(responce=> {
            if(responce.success && responce.result) {
                let getQuoteResult = responce && responce.result ? responce.result : "";
                let referenceJobNo = getQuoteResult && getQuoteResult.referenceJobNo? getQuoteResult.referenceJobNo : "";

                this.setState({
                    getQuoteResult: getQuoteResult,
                    referenceJobNo:referenceJobNo,
                    additionalIcons:true
                })
            }
        })

    }


    render() {

        return (
            <Suspense fallback={renderLoader()}>
                <AppLayout>
                    <div className="NewHomeSection">
                        <div className={this.state.queryFor === "Ocean"?"ocean_freight_banner pb-4 change_theme":"air_freight_banner pb-4 change_theme"}>
                            <div className="container-fluid">
                                <div className="row shipment_details_up">
                                    <div className="details_for_rate">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                                            <div className="d-flex flex-wrap align-items-center">
                                                <h3>{this.state.originName}</h3>
                                                <div className="icon_origin_destination">
                                                    <FontAwesomeIcon icon={faArrowRight}/></div>
                                                <h3>{this.state.destinationName}</h3>
                                            </div>
                                            {/*{this.state.additionalCharge?*/}
                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                    {this.state.shippingBillId || this.state.shippingBillIdforOcean ?
                                                        <button className="edit back_black notDisplayZipa" style={{padding:'3px 12px',borderRadius:'4px',color:"#fff",backgroundColor:'#000!important'}}  onClick={(e)=>{
                                                            this.DownloadShippingBill()
                                                        }}>
                                                            {this.state.queryType === "Air Import" ? <>
                                                                    {this.state.shippingBillStatus ? "Processing...." :"Download Bill Of Entry" }
                                                                </>
                                                            :<>{this.state.shippingBillStatus ? "Processing...." :"Download Shipping Bill" }
                                                                </>}
                                                        </button>:""}


                                                {/*:""}*/}

                                                {this.state.shipmentMode==="FCL"?
                                                    <>
                                                        {this.state.suggestedRatesStatus === true ?
                                                            <>
                                                                {!this.state.suggestNotAvailble?
                                                                    this.state.queryFor !=="Courier" ||  this.state.queryFor !=="Road"?
                                                                        <>
                                                                            {this.state.ChargeRateNotFoundModel ?"":
                                                                                <button className="edit back_black notDisplayZipa" style={{padding:'3px 12px',borderRadius:'4px',color:"#fff",backgroundColor:'#000!important'}}  onClick={(e)=>{
                                                                                    e.preventDefault()
                                                                                    this.recommendedRates({ quoteId: this.state.quoteIdSuggestion ,referenceJobNo:this.state.referenceJobNo}).then(res => {
                                                                                        if (res.success) {
                                                                                            this.setState({ zipaRecommendedRates: res.result },()=>{
                                                                                                this.setState({
                                                                                                    zipaSuggestion:true
                                                                                                })
                                                                                            })
                                                                                        }
                                                                                    })

                                                                                }}>Smart Suggestions</button>}</>
                                                                        :""
                                                                    :""}
                                                            </>
                                                        :"" }
                                                    </>
                                                    :
                                                    <>
                                                        {!this.state.suggestNotAvailble?
                                                            this.state.queryFor !=="Courier" ||  this.state.queryFor !=="Road"?
                                                                <>
                                                                    {this.state.ChargeRateNotFoundModel ?"":
                                                                        <button className="edit back_black notDisplayZipa" style={{padding:'3px 12px',borderRadius:'4px',color:"#fff",backgroundColor:'#000!important'}}  onClick={(e)=>{
                                                                            e.preventDefault()
                                                                            this.recommendedRates({ quoteId: this.state.quoteIdSuggestion ,referenceJobNo:this.state.referenceJobNo}).then(res => {
                                                                                if (res.success) {
                                                                                    this.setState({ zipaRecommendedRates: res.result },()=>{
                                                                                        this.setState({
                                                                                            zipaSuggestion:true
                                                                                        })
                                                                                    })
                                                                                }
                                                                            })

                                                                        }}>Smart Suggestions</button>}</>
                                                                :""
                                                            :""}
                                                    </>
                                                    }
                                                </div>
                                        </div>
                                        <div className="details-other">
                                            <div className="text-shipment"><span>
                                            {this.state.jobNo + " | "} {this.state.queryFor === "Ocean" ? this.state.shipmentMode==="FCL" ? "" : this.state.totalPieces+ "Pcs. | ": this.state.totalPieces+ "Pcs. | "}
                                            {this.state.bookingQueryfor === "Ocean" ||   this.state.queryFor === "Ocean" ? this.state.bookingchargeableWeight ? this.state.bookingchargeableWeight + "CBM": this.state.chargeableWeight+ "CBM" : this.state.chargeableWeight+ "Kgs"} | {this.state.tarrifMode} |{" "}
                                                {this.state.queryFor === "Ocean" ? this.state.shipmentMode === "FCL" ? this.state.shipmentMode +" - "+this.state.noOfContainers + " Containers | " : this.state.shipmentMode + " | ":""}
                                                {this.state.commodity ? this.state.commodity+" | ":""}  {this.state.clearenceDate  ? convertDateISOToCustomiseFormat(this.state.clearenceDate)  : ""}</span></div>
                                        </div>
                                        {this.state.shipmentMode==="FCL" ?
                                            <>
                                                {this.state.suggestedRatesStatus === false ? "" :
                                                    <>
                                                        {!this.state.suggestNotAvailble?
                                                            this.state.queryFor !=="Courier" ||  this.state.queryFor !=="Road"?
                                                                <button className="edit back_black showDisplayZipa" style={{padding:'3px 12px',borderRadius:'4px',color:"#fff",backgroundColor:'#000!important'}}  onClick={(e)=>{
                                                                    e.preventDefault()
                                                                    this.recommendedRates({ quoteId: this.state.quoteIdSuggestion,referenceJobNo:this.state.referenceJobNo}).then(res => {
                                                                        if (res.success) {
                                                                            this.setState({ zipaRecommendedRates: res.result },()=>{
                                                                                this.setState({
                                                                                    zipaSuggestion:true
                                                                                })
                                                                            })
                                                                        }
                                                                    })

                                                                }}>Smart Suggestions</button>
                                                                :""
                                                            :""}
                                                    </>}
                                            </>
                                            :
                                            <>
                                            {!this.state.suggestNotAvailble?
                                                this.state.queryFor !=="Courier" ||  this.state.queryFor !=="Road"?
                                                <button className="edit back_black showDisplayZipa" style={{padding:'3px 12px',borderRadius:'4px',color:"#fff",backgroundColor:'#000!important'}}  onClick={(e)=>{
                                                    e.preventDefault()
                                                this.recommendedRates({ quoteId: this.state.quoteIdSuggestion,referenceJobNo:this.state.referenceJobNo}).then(res => {
                                                    if (res.success) {
                                                        this.setState({ zipaRecommendedRates: res.result },()=>{
                                                            this.setState({
                                                                zipaSuggestion:true
                                                            })
                                                        })
                                                    }
                                                })
                                                }}>Smart Suggestions</button>
                                                :""
                                            :""}
                                            </>
                                        }

                                    </div>
                                </div>
                                <div className="row rates_all_details">
                                    <div className="col-lg-12">

                                        <RateQuotedCardNew
                                            rateDataForCard={this.state.getQuoteResult}
                                            additionalIcons={this.state.additionalIcons}
                                            quoteFor={this.state.quoteFor}
                                            zipaRecommendedRates = {this.state.zipaRecommendedRates}
                                            shippingBillId = {this.state.shippingBillId}
                                            zipaSuggestion = {this.state.zipaSuggestion}
                                            sideSuggestion = {this.state.sideSuggestion}
                                            notDisplayClearance = {this.state.notDisplayClearance}
                                            setZipaModal={(values) =>
                                                values === false
                                                    ? this.setState({ zipaSuggestion: false })
                                                    : ""
                                            }
                                            serviceOther = {(value)=> {
                                                if (value === true) {
                                                    this.getByQuoteIdAgain()
                                                }
                                            }}

                                                shippingBillStatus={this.state.shippingBillStatus}
                                                additionCharge = {(values)=>{
                                                    if(values === false){
                                                        this.setState({
                                                            additionalCharge : true
                                                        })
                                                    }
                                                }}
                                                vesselSchedulesMearsk={this.state.vesselSchedulesMearsk}

                                                vesselSchedules = {this.state.vesselSchedules}
                                                FilterSide = {this.state.FilterSide}
                                                filterSideClose = {(value)=>{
                                                    if(value === false){
                                                        this.setState({
                                                            FilterSide : false
                                                        })
                                                    }
                                                }}
                                                ChargeRateNotFoundModel = {(value)=>{
                                                    if(value === true){
                                                        this.setState({
                                                            ChargeRateNotFoundModel : true,
                                                            sideSuggestion:false
                                                        })
                                                    }
                                                }}
                                                zipasuggestionApi = {(value)=>{
                                                    if(value== true){
                                                        this.zipasuggestionFunction()
                                                    }
                                                }}

                                            />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/*<LeafletMapShipmentManager origin={this.state.originName} destination={this.state.destinationName} />*/}
                    <div className="fixed-btnsbot fixed-btnsbot1">
                        <div className="d-flex flex-wrap justify-content-end">
                            <button type="button" className=" manager delete rounded-circles filterBtn btn btn-secondary" onClick={()=>{
                                this.setState({
                                    FilterSide : true
                                })
                            }}>
                                <FontAwesomeIcon icon={faFilter} size="lg"/> Filter
                            </button>
                            <button type="button" className=" manager delete rounded-circles btn btn-secondary" onClick={e => {
                                this.props.history.push(PAGE_PATHS.MAIN_PAGE)
                            }}>
                                <FontAwesomeIcon icon={faHome} size="lg"/> Home
                            </button>
                            {this.state.quoteStatus ===  "Open" || this.state.quoteStatus ===  "Rates Quoted" ?
                                <>
                            <button type="button" className="manager edit rounded-circles btn btn-secondary" onClick={e => {
                                e.preventDefault()
                                let queryData = JSON.parse(localStorage.getItem("queryData"));
                                if(this.state.queryFor ==="Air"){
                                    localStorage.setItem("queryType", "AirQuery");
                                    localStorage.setItem("shipmentHistoryEditDataAir", JSON.stringify(queryData));
                                    setTimeout(()=>{ this.props.history.push(PAGE_PATHS.MAIN_PAGE)},1000)
                                }
                                else{
                                    localStorage.setItem("queryType", "OceanQuery");
                                    localStorage.setItem("shipmentHistoryEditDataOcean", JSON.stringify(queryData));
                                    setTimeout(()=>{ this.props.history.push(PAGE_PATHS.MAIN_PAGE)},1000)
                                }

                            }}>
                                <FontAwesomeIcon icon={faAngleLeft} size="lg"/> Previous
                            </button>
                            </>
                                :""}
                            {this.state.quoteStatus ===  "Open" || this.state.quoteStatus ===  "Rates Quoted" ? "" :
                            <button type="button" className="manager edit rounded-circles btn btn-secondary"
                                    onClick={e => {
                                        if(this.state.quoteFor === "Air")
                                        {
                                            this.props.history.push(PAGE_PATHS.VIEW_MAWB_CUSTOMER + "/Air/" + this.state.bookingId)
                                        }
                                        else
                                        {
                                            this.props.history.push(PAGE_PATHS.VIEW_MAWB_CUSTOMER + "/Ocean/" + this.state.bookingId)
                                        }

                            }}>
                                 Next <FontAwesomeIcon icon={faAngleRight} size="lg"/>
                            </button>
                            }
                        </div>
                    </div>
                </AppLayout>
            </Suspense>
        )}

}



export default RatesQueryPreview;
