import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {onNotificationLoadRequest, readNotificationHit} from "../../redux/notificationRedux/notificationAction";
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Media, NavLink, Row, UncontrolledDropdown} from "reactstrap";

import {PAGE_PATHS} from "../../util/PageRoute";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactInterval from "react-interval";
import {toast, ToastContainer} from "react-toastify";
import RestClient from "../../util/RestClient";
import {NOTIFICATION_LOAD, NOTIFICATION_SEEN_ALL} from "../../constants";
import notificationSound from "../../assets/sound/pristine.mp3";
import {Scrollbars} from "react-custom-scrollbars";
import {convertDateFromISO, getCustomerId} from "../../util/CommonUtility";
import {withRouter} from "react-router";
import {faBell, faFileAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const audio = new Audio(notificationSound);


class Notification extends Component {


    constructor(props) {
        super(props);
        this.state = {
            goNextHouse: false,
            showMessage:undefined,
            awbStatus: "",
            notificationCount: "",
            notificationList: [],
            intervalStatus:true,
            play: false,
            start: 0,
            total:""
        };

    }

componentDidMount() {
        let customerId = getCustomerId()
    this.props.onNotificationLoadRequest({customerId:customerId})
    audio.addEventListener('ended', () => this.setState({ play: false }));
}

    componentWillUnmount() {
        audio.removeEventListener('ended', () => this.setState({ play: false }));
    }

    togglePlay = () => {
        this.setState({ play: !this.state.play }, () => {
            this.state.play ? audio.play() : audio.pause();
        });
    }

componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.notificationList && prevProps.notificationList != this.props.notificationList) {
        this.setState({
            notificationList: this.props.notificationList ? this.props.notificationList  :""
        })
    }
    if (this.props.refreshNotification && prevProps.refreshNotification != this.props.refreshNotification) {
        let customerId = getCustomerId()
        this.props.onNotificationLoadRequest({customerId:customerId})
    }
    if (this.props.notificationCount && prevProps.notificationCount != this.props.notificationCount) {
        this.setState({notificationCount:this.props.notificationCount,
            total:this.props.notificationCount
        },()=>{

            if(!localStorage.getItem("notificationCount") === false){
               let count  = JSON.parse(localStorage.getItem("notificationCount"))

                if(count !== this.state.notificationCount){
                    this.setState({showMessage: count + " New Notification is Arrived"})

                    let NewCount = this.state.notificationCount - count
                        if(NewCount > 0){
                            this.togglePlay()
                            toast.success(NewCount + " New Notification is Arrived" , {
                                position: "top-left",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }

                    localStorage.setItem("notificationCount",this.state.notificationCount)
                }
            }else {
                localStorage.setItem("notificationCount",this.state.notificationCount)
            }

        })
    }
}
    checkScroll = () => {
        const dimentions = this.Scrollbars.getValues();
        if (dimentions.top >= 1) {
            debugger
            if (this.state.start < this.state.total) {
                this.setState({dataloader: true});

                this.augmentData().then(r => {
                });
            } else {

                // toast.info('You Reached the Last Query', {
                //                 position: "top-left",
                //                 autoClose: 3000,
                //                 hideProgressBar: false,
                //                 closeOnClick: true,
                //                 pauseOnHover: true,
                //                 draggable: true,
                //                 progress: undefined,
                //             });
                this.setState({dataloader: false});
            }
        }
    };

    augmentData = async () => {
        this.setState({start: this.state.start + 10}, () => {
            if (this.state.search !== "") {
                const page = {
                    start: this.state.start,
                    customerId:getCustomerId()
                }
                this.getNotificationDetails(page).then(r => {

                        if (r.result.data)
                            this.setState(prevState => ({
                                notificationList: prevState.notificationList.concat(r.result.data),
                                total:r.result.total,
                                dataloader: false
                            }));
                    }
                ).catch();

            } else {
                const page = {
                    start: this.state.start,
                    customerId:getCustomerId()
                }
                this.getNotificationDetails(page).then(r => {

                        if (r.result && r.result.data)
                            this.setState(prevState => ({
                                notificationList: prevState.notificationList.concat(r.result.data),
                                total:r.result.total,
                                dataloader: false
                            }));
                    }
                ).catch();
            }
        });

    }

    getNotificationDetails = async (dataForServer) => {
        return await RestClient({
            url: NOTIFICATION_LOAD,
            method: "POST",
            data: dataForServer
        });
    };

    handleScrollStart = () => {
        console.log();
    };

    seenAll=()=>{
        let customerId = getCustomerId()
        this.seenAllApi({customerId:customerId}).then(responce=>{
            if(responce.success){
                let customerId = getCustomerId()
                this.props.onNotificationLoadRequest({customerId:customerId})
                window.location.reload();
            }
        })
    }


    async seenAllApi(body) {
        return await RestClient({
            url: NOTIFICATION_SEEN_ALL,
            method: "POST",
            data: body
        });
    }
    CallNotification = ()=>{
        // this.setState({intervalStatus : false})
        let customerId = getCustomerId()
        this.props.onNotificationLoadRequest({customerId:customerId})
    }
    render() {
        let {notificationList} = this.state
        return (
            <>
                <ReactInterval timeout={50000} enabled={this.state.intervalStatus === true}
                               callback={() => {
                                   this.CallNotification()
                               }} />
                {/*{this.state.showMessage !== undefined  ?*/}
                {/*    <h5>{this.state.showMessage}</h5>*/}
                {/*    :undefined*/}
                {/*}*/}
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />



                {/*<div className="ml-3 query-prev d-inline-block">*/}
                    <UncontrolledDropdown className="ml-2 mr-2 dropdown-menu-right hide-navsss">
                        <DropdownToggle className="notification-bell" style={{
                            background: "none",
                            border: "none",
                            color: "#ff0600"
                        }}>
                            <span className="">
                            {/*<Bell size={21} className=" notification-danger animate-shake"/>*/}
                                <FontAwesomeIcon style={{verticalAlign:'inherit'}} icon={faBell}/>
                                {/*<i className="simple-icon-heart"/> <span className="mob-not">Zipaworld</span>*/}
                                </span>
                            <span
                                className="badge">{this.state.notificationCount}</span>
                        </DropdownToggle>
                        <DropdownMenu right className="notification-dropdown notification-size">
                            <div className="header-notification-2 d-flex justify-content-between align-items-center">
                                <h6><span className="flaticon-bell"/>Notifications</h6>
                                <span className="clears-notify" onClick={e=>{e.preventDefault(); this.seenAll()}}>Clear All</span>
                            </div>
                            {/*{}*/}
                            {Array.isArray(notificationList) && notificationList.length >= 1 ?

                                    <Scrollbars
                                        style={{height: "100vh"}}
                                        autoHide
                                        ref={e => (this.Scrollbars = e)}
                                        onScrollStop={this.checkScroll}
                                        universal={true}
                                        onScrollStart={this.handleScrollStart}
                                    >
                                    {notificationList && notificationList.map((notificationItem, i) => {

                                        return (
                                            <Media key={i} body
                                                   className={"AAA-top-header-notification-4"}
                                                   onClick={event => {
                                                       event.preventDefault()
                                                       this.props.readNotificationHit({id: notificationItem && notificationItem._id ? notificationItem._id : ""})
                                                       let activeStatus =  notificationItem && notificationItem.track && notificationItem.track.activeStatus ? notificationItem.track.activeStatus : undefined;

                                                       if(activeStatus !== undefined && activeStatus === "Open"){
                                                           let  NextFlow =  notificationItem.track && notificationItem.track.Open && notificationItem.track.Open.pId ? notificationItem.track.Open.pId : ""
                                                           let  PrevFlow =  notificationItem.track && notificationItem.track.Open && notificationItem.track.Open.pId ? notificationItem.track.Open.pId : ""
                                                           this.props.history.push(PAGE_PATHS.shipmentHistory)
                                                           window.location.reload("false")
                                                           // this.setState({nextFlow : PAGE_PATHS.QUERY_PREVIEW_PAGE + "/" + notificationItem.track.nId,
                                                           //     prevFlow : PAGE_PATHS.QUERY_PREVIEW_PAGE + "/" + notificationItem.track.pId,
                                                           // })
                                                       }

                                                       if(activeStatus !== undefined && activeStatus === "RatesQuoted"){
                                                           let  NextFlow =  notificationItem.track && notificationItem.track.RatesQuoted && notificationItem.track.RatesQuoted.pId ?  notificationItem.track.RatesQuoted.pId : ""
                                                           let  PrevFlow =  notificationItem.track && notificationItem.track.RatesQuoted && notificationItem.track.RatesQuoted.pId ? notificationItem.track.RatesQuoted.pId : ""
                                                           this.props.history.push(PAGE_PATHS.shipmentHistory)
                                                           window.location.reload("false")
                                                           // this.setState({nextFlow : PAGE_PATHS.QUERY_PREVIEW_PAGE + "/" + statusResult.RatesQuoted.nId,
                                                           //     prevFlow : PAGE_PATHS.QUERY_PREVIEW_PAGE + "/" + statusResult.RatesQuoted.pId,
                                                           // })
                                                       }

                                                       if(activeStatus !== undefined && activeStatus === "RatesConfirmed"){
                                                           let  NextFlow =  notificationItem.track && notificationItem.track.RatesConfirmed && notificationItem.track.RatesConfirmed.pId ? notificationItem.track.quoteId +"/"+ notificationItem.track.RatesConfirmed.nId : ""
                                                           let  PrevFlow =  notificationItem.track && notificationItem.track.RatesConfirmed && notificationItem.track.RatesConfirmed.pId ? notificationItem.track.RatesConfirmed.pId : ""
                                                           this.props.history.push(PAGE_PATHS.shipmentHistory)
                                                           window.location.reload("false")
                                                           //
                                                           // this.setState({nextFlow : PAGE_PATHS.BOOKING_EDIT_PAGE + "/" + statusResult.RatesConfirmed.nId,
                                                           //     prevFlow : PAGE_PATHS.QUERY_PREVIEW_PAGE + "/" + statusResult.RatesConfirmed.pId,
                                                           // })
                                                       }

                                                       if(activeStatus !== undefined && activeStatus === "Pending"){
                                                           let  NextFlow =  notificationItem.track && notificationItem.track.Pending && notificationItem.track.Pending.nId ? notificationItem.track.Pending.nId : ""
                                                           let  PrevFlow =  notificationItem.track && notificationItem.track.Pending && notificationItem.track.Pending.pId ? notificationItem.track.Pending.pId : ""
                                                           this.props.history.push(PAGE_PATHS.shipmentHistory)
                                                           window.location.reload("false")
                                                           // this.setState({nextFlow : PAGE_PATHS.MBL_VIEW_PAGE + "/" + statusResult.Pending.nId,
                                                           //     prevFlow : PAGE_PATHS.BOOKING_EDIT_PAGE + "/" + statusResult.Pending.pId,
                                                           // })
                                                       }

                                                       if(activeStatus !== undefined && activeStatus === "Booked"){


                                                           let  NextFlow =  notificationItem.track && notificationItem.track.Booked && notificationItem.track.Booked.pId ? notificationItem.track.Booked.pId : ""
                                                           let  PrevFlow =  notificationItem.track && notificationItem.track.Booked && notificationItem.track.Booked.pId ? notificationItem.track.Booked.pId : ""
                                                           this.props.history.push(PAGE_PATHS.VIEW_MAWB_CUSTOMER + "/" + PrevFlow)
                                                           window.location.reload("false")

                                                           // this.setState({nextFlow : PAGE_PATHS.PROFORMA_VIEW_PAGE + "/" + statusResult.Booked.nId,
                                                           //     prevFlow : PAGE_PATHS.MBL_VIEW_PAGE + "/" + statusResult.Booked.pId,
                                                           // })
                                                       }

                                                       if(activeStatus !== undefined && activeStatus === "ProfomaApproved"){

                                                           let  NextFlow =  notificationItem.track && notificationItem.track.ProfomaApproved && notificationItem.track.ProfomaApproved.nId ? notificationItem.track.ProfomaApproved.nId : ""
                                                           let  PrevFlow =  notificationItem.track && notificationItem.track.ProfomaApproved && notificationItem.track.ProfomaApproved.pId ? notificationItem.track.ProfomaApproved.pId : ""
                                                           this.props.history.push(PAGE_PATHS.PERFORMA_INVOICE + "/" + NextFlow)
                                                           window.location.reload("false")

                                                           // this.setState({nextFlow : PAGE_PATHS.PROFORMA_VIEW_PAGE + "/" + statusResult.ProfomaApproved.nId,
                                                           //     prevFlow : PAGE_PATHS.MBL_VIEW_PAGE + "/" + statusResult.ProfomaApproved.pId,
                                                           // })
                                                       }

                                                       if(activeStatus !== undefined && activeStatus === "Final"){

                                                           let  NextFlow =  notificationItem.track && notificationItem.track.Final && notificationItem.track.Final.nId ? notificationItem.track.Final.nId : ""
                                                           let  PrevFlow =  notificationItem.track && notificationItem.track.Final && notificationItem.track.Final.pId ? notificationItem.track.Final.pId : ""
                                                           this.props.history.push(PAGE_PATHS.PERFORMA_INVOICE + "/" + NextFlow)
                                                           window.location.reload("false")

                                                           // this.setState({nextFlow : PAGE_PATHS.PROFORMA_VIEW_PAGE + "/" + statusResult.Final.nId,
                                                           //     prevFlow : PAGE_PATHS.MBL_VIEW_PAGE + "/" + statusResult.Final.pId,
                                                           // })
                                                       }

                                                       if(activeStatus !== undefined && activeStatus === "Executed"){

                                                           let  NextFlow =  notificationItem.track && notificationItem.track.Executed && notificationItem.track.Executed.nId ? notificationItem.track.Executed.pId : ""
                                                           let  PrevFlow =  notificationItem.track && notificationItem.track.Executed && notificationItem.track.Executed.pId ? notificationItem.track.Executed.pId : ""
                                                           // history.push(PAGE_PATHS.MBL_VIEW_PAGE + "/" + PrevFlow)
                                                           this.props.history.push(PAGE_PATHS.VIEW_FOR_INVOICE_MANAGER + "/" + PrevFlow)
                                                           window.location.reload("false")
                                                           // this.setState({nextFlow : PAGE_PATHS.PROFORMA_VIEW_PAGE + "/" + statusResult.Executed.nId,
                                                           //     prevFlow : PAGE_PATHS.MBL_VIEW_PAGE + "/" + statusResult.Executed.pId,
                                                           // })
                                                       }

                                                       if(activeStatus !== undefined && activeStatus === "InvoiceApproved") {

                                                           let NextFlow = notificationItem.track && notificationItem.track.InvoiceApproved && notificationItem.track.InvoiceApproved.nId ? notificationItem.track.InvoiceApproved.nId : ""
                                                           let PrevFlow = notificationItem.track && notificationItem.track.InvoiceApproved && notificationItem.track.InvoiceApproved.pId ? notificationItem.track.InvoiceApproved.pId : ""
                                                           if (NextFlow !== undefined) {
                                                               this.props.history.push(PAGE_PATHS.VIEW_FOR_INVOICE_MANAGER + "/" + NextFlow)
                                                               window.location.reload("false")
                                                           }
                                                       }

                                                   }}

                                                   >
                                                <div
                                                    className="d-flex justify-content-between notify-title">
                                                    <div className="noti-title">
                                                        <h2>{notificationItem.title}</h2>
                                                    </div>
                                                    {/*<span className="clears-notify">Clear All</span>*/}

                                                </div>
                                                <div
                                                    className="d-flex justify-content-between flex-wrap">
                                                    <div className="date-notify">
                                                        <h4>
                                                            <span>{convertDateFromISO(notificationItem.createdAt, "DD-MM-YYYY")}</span>
                                                        </h4>
                                                    </div>
                                                    <div className="noti-title">
                                                        <h3>
                                                            <span>{notificationItem.description}</span>: <span>{convertDateFromISO(notificationItem.createdAt, "h:mm a")}</span>
                                                        </h3>
                                                    </div>
                                                    <div className="date-notify">
                                                        <h5>

                                                        </h5>
                                                    </div>
                                                </div>
                                            </Media>)
                                    })}
                                    </Scrollbars>

                                : "Will get Notification available"

                            }
                            <div
                                className="p-1 text-center border-top-grey border-top-lighten-2 AAA-top-header-notification-5">
                                {/*<Notifications/>*/}
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                {/*</div>*/}
</>
        );
    }


}


const
    mapStateToProps = state => {
        let {notificationList,refreshNotification,notificationCount} = state.notificationData;

        return {notificationList,refreshNotification,notificationCount};
    };


const NotificationWithRoute = withRouter(Notification);


export default connect(mapStateToProps, {
    onNotificationLoadRequest,readNotificationHit
})
(
    NotificationWithRoute
)

