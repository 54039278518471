import {
    E_DOCKET_REQUEST,
    E_DOCKET_REQUEST_RESPONSE_SUCCESS,
    E_DOCKET_REQUEST_RESPONSE_FAILURE,
    E_DOCKET_MANAGER_REQUEST,
    E_DOCKET_MANAGER_SUCCESS,
    E_DOCKET_MANAGER_FAILURE,
    E_DOCKET_MANAGER_DATA_REQUEST,
    E_DOCKET_MANAGER_DATA_SUCCESS,
    E_DOCKET_MANAGER_DATA_FAILURE,
    E_DOCKET_MANAGER_DATA_REQUEST_AWB,
    E_DOCKET_MANAGER_DATA_REQUEST_AWB_SUCCESS,
    E_DOCKET_MANAGER_DATA_REQUEST_AWB_FAILURE,
} from "../actions";

const initState = {};


const eDocketReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case E_DOCKET_REQUEST:
            // console.log("In action Reducer  " + JSON.stringify(payload));
            return {...state, loading: true};

        case E_DOCKET_REQUEST_RESPONSE_SUCCESS:
            // console.log("In Edocket Reducer" + JSON.stringify(payload));
            return {...state, loading: false, item: payload};

        case E_DOCKET_REQUEST_RESPONSE_FAILURE:
            // console.log("In Edocket Data Change" + JSON.stringify(payload));
            return {...state, loading: false, approvalError: payload};

        case E_DOCKET_MANAGER_REQUEST:
            // console.log("In action Reducer  " + JSON.stringify(payload));
            return {...state, loading: true};

        case E_DOCKET_MANAGER_SUCCESS:
            // console.log("In Edocket Reducer" + JSON.stringify(payload));
            return {...state, loading: false, items: payload.data , totalItemCount :payload.total};

        case E_DOCKET_MANAGER_FAILURE:
            // console.log("In Edocket Data Change" + JSON.stringify(payload));
            return {...state, loading: false, approvalError: payload};

        case E_DOCKET_MANAGER_DATA_REQUEST:
            // console.log("In action Reducer  " + JSON.stringify(payload));
            return {...state, loading: true};

        case E_DOCKET_MANAGER_DATA_SUCCESS:
            // console.log("In Edocket Reducer" + JSON.stringify(payload));
            return {...state, loading: false, itemLoaded: payload};

        case E_DOCKET_MANAGER_DATA_FAILURE:
            // console.log("In Edocket Data Change" + JSON.stringify(payload));
            return {...state, loading: false, error: payload};

        case E_DOCKET_MANAGER_DATA_REQUEST_AWB:
            // console.log("In action Reducer  " + JSON.stringify(payload));
            return {...state, loading: true};

        case E_DOCKET_MANAGER_DATA_REQUEST_AWB_SUCCESS:
            // console.log("In Edocket Reducer" + JSON.stringify(payload));
            return {...state, loading: false, quoteId: payload};

        case E_DOCKET_MANAGER_DATA_REQUEST_AWB_FAILURE:
            // console.log("In Edocket Data Change" + JSON.stringify(payload));
            return {...state, loading: false, error: payload};
        default:
            return initState;
    }
};

export default eDocketReducer;
