import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";
class WarehousingSolutions extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();
    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        return (
            <>
                <MetaTags>
                    <title> Warehousing  Transportation  | Zipaworld </title>
                    <meta name="title" content="Best warehousing service in India |Zipaworld"/>
                    <meta name="description" content="Get the right Warehousing and logistics management with Zipaworld"/>
                    <meta name="keywords" content="where to get cheap warehousing service, digital warehousing software in india, store goods in india, cargo storage in india, cargo warehousing service in delhi, cargo warehousing in mundra, warehouse available in Mumbai, integrated warehousing management"/>
                </MetaTags>

                <AppLayout>
                    <div ref={this.testRef} className="services_my services_content_warehousing">
                    </div>
                    <div className="services_content paraHyperlink">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h1>Warehousing SERVICES <span>AND Solutions</span></h1>
                                    <p>In its quest to integrating the supply chain digitally, the customer can also avail warehousing services at their convenience through the website. <Link to="/">Zipaworld</Link> arranges secure and vigorous warehousing solutions packed with your complete logistics requirement.</p>
                                    <p>The warehousing counterparts of Zipaworld have been verified, tried, and tested by <Link to="/OurTeam">our team of professionals</Link> for the proper handling and storage of all kinds of goods.</p>
                                    <p>The warehousing solutions are aligned with the domestic network management to avoid any sort of spoilage, mishandling, damage, or delays during the transit of the goods. Also Zipaworld ensures proper inventory management and regular updates to encourage <Link to="/TrackingSearchPage">transparency and visibility of the goods</Link>.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <FooterHome/>
                </AppLayout>
            </>

        );
    }


}



export default WarehousingSolutions;

