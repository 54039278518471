import React, {Component, Fragment} from "react";
import {Row, Card, CardTitle, Label, Input, Button, Modal,CustomInput, ModalHeader, ModalBody, FormGroup} from "reactstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {loginUser, loginUserSuccess, registerUser, registerUserSuccess} from "../../redux/actions";

import FacebookLogin from 'react-facebook-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import IntlMessages from "../../helpers/IntlMessages";
import {Colxx} from "../../components/common/CustomBootstrap";
import SimpleReactValidator from "simple-react-validator";

import {NotificationManager} from "../../components/common/react-notifications";
import {Field, Form, Formik} from "formik";
import { GoogleLogin } from 'react-google-login';
import CountryDropDown from "../../components/countryDropDown";
import OriginDestinationDropdownForAirport from "../../components/OriginDestinationDropdownForAirport";
import {
    GET_ORIGIN_DESTINATION_AIRPORT_API,
    SEARCH_GST_DATA,SEARCH_CIN_DATA,
    SEARCH_DIN_DATA,
    STATE_DROPDOWN_API,LOGIN_API,
    USER_LOGIN_CHECKOTP,CHECK_USER_VIA_EMAIL,
    USER_LOGIN_SEND_MOBILE,CHECK_USER,LOGIN_API_VIA_PHONE,CHECK_USER_VIA_PHONE,GOOGLE_LOGIN_CLIENT_ID
} from "../../constants";
import StateDropdown from "../../components/stateDropdown";
import {PAGE_PATHS, FORGETPASSWORD} from "../../util/PageRoute";
import {withRouter} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {getLoggedInCustomerCode} from "../../util/CommonUtility";
import {aftwerOtpSuccess, subscribeNotification} from "../../redux/auth/actions";
import RestClient,{setToken} from "../../util/RestClient";
import FontAwesome from 'react-fontawesome'
import axios from "axios"
class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pickupCountryName:"India",
             ownersInfo : [
                {
                  "ownerName": "",
                  "ownerAddress": "",
                  "ownerContactNo": "",
                  "ownerDinNo": "",
                  "ownerEmail": "",
                  "aadhaarNo": "",
                  
                },
               
              ],
            gstcheck:false,
            cincheck:false,
            dincheck:false,
            email: "",
            sendOtpButtonClicked : false,
            password: "",
            otpTabOPen : false,
            repassword: "",
            customerName: "",
            gstNo: "",
            selectedState: "",
            phoneNo: "",
            buttonName : "Generate",
            modalLarge: false,
            message: "",
            openLogin: true,
            openOtpView:false,
            clicked:true,
            countryCode:"91",
            mobileNo:"",
            sessionId:"",
            typeOf:"Bussiness",
            getWhatsappNotifications:false,
            whatsappPhoneNo:"",
            isWhatsappNoValid:false,
            showCheckbox: false,
            gstcheckBox:false,
            checkCin:false,
            checkDin:false
            // whatsappOtp:"",
            // isWhatsappOtpSent:false,
        };
        this.validator = new SimpleReactValidator({autoForceUpdate: this});
    };


    componentDidMount() {
        if (this.props.openLogin) {
            this.setState({openLogin: true, password: "", loading: true})
        }
        if (this.props.modelLogin) {
            this.setState({modelLogin: true, password: "", loading: true})
        }

        if (this.props.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        if (!localStorage.getItem("ContactDeatails") === false ) {
            let data = JSON.parse(localStorage.getItem("ContactDeatails"))
            this.setState({email: data.contactEmail,phoneNo :data.contactNumber })
        }

        if (localStorage.getItem("already_registered") === "true") {
            this.setState({openLogin: false})


        } else if (localStorage.getItem("already_registered") === "false") {
            localStorage.clear();
            this.setState({openLogin: false})

        }
    }
    onGoogleLogin = (response) =>{
        const body = {
            email: response.profileObj.email,
            password: response.profileObj.email,
            // phoneNo: this.state.phoneNo,
            customerName: `${response.profileObj.name}${response.profileObj.googleId}`,
            state: this.state.selectedState,
            countryName : "India",
            typeOfBussiness : "Bussiness"
        }
        this.checkUserExists({email :response.profileObj.email}).then(res=>{
            if(res.success){
                this.props.registerUser(body)
            }else{
                const body = {
                    companyCode: "zworld_009",
                    email: response.profileObj.email,
                    password: response.profileObj.email,
                    source: "Web",
                    loginThrough: "Google",


                }
                this.props.loginUser(body)

                // this.handlePageReload(response)
            }
            // window.location.reload(false);
            /*this.setTimeout(this.reloadFunction(),7000)*/

        })
    }
    checkUserExists = async (obj)=>{
        return await RestClient({
            url: CHECK_USER_VIA_EMAIL,
            method: "POST",
            data: obj
        })
    }
    onGoogleLoginFailure = (err) =>{
    }
    onFacebookLogin = (response) =>{
        const body = {
            email: response.email,
            password: response.email,
            // phoneNo: this.state.phoneNo,
            customerName: `${response.name}${response.userID}`,
            state: this.state.selectedState,
            countryName : "India",
            typeOfBussiness : "Bussiness"
        }
        this.checkUserExists({email :response.email}).then(res=>{
            if(res.success){
                this.props.registerUser(body)
            }else{
                const body = {
                    companyCode: "zworld_009",
                    email: response.email,
                    password: response.email,
                    source: "Web",
                    loginThrough: "Facebook",
                }
                this.props.loginUser(body)
                // this.handlePageReload(response)
            }
            /*this.setTimeout(this.reloadFunction(),7000)*/

            // window.location.reload(false);
        })
    }
    componentClicked = (response) => {
      }
       
    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge,
            openLogin: true,
            otpTabOPen : false
        }),()=>{
            if(this.state.modalLarge === false)
            {
                this.props.setmodal(false)
            }
        });
    };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }

    validatePhonNumber = (value) => {

        if (!value) {
            toast.error("Please enter your Phone Number", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^\d{10}$/.test(value)) {

            toast.error("Phone Number Should be 10 digit", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                phoneNo:""
            })
        }

    }
    validateWhatsappNumber = (value) => {

        if (!value) {
            toast.error("Please enter your Phone Number", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^\d{10}$/.test(value)) {

            toast.error("Phone Number Should be 10 digit", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                whatsappPhoneNo:""
            })
        }else{
            this.setState({isWhatsappNoValid:true});
        }

    }

    validateEmail = (value) => {
        let error;
        if (!value) {
            error = "Please enter your email address";
        } else if (!this.validateEmail2(value)) {
            error = "Invalid email address";
        }
        return error;
    }
    validateEmail2 = (branchContactEmailss) => {
        let emailLower = String(branchContactEmailss).toLowerCase();
        let re1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re2 = /^[a-zA-Z0-9][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re3 = /^.{1,64}@.{1,256}$/;
        let reOther = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re1.test(emailLower)){
            if(reOther.test(emailLower)){
                if(re.test(emailLower)){
                    if(re2.test(emailLower)){
                        if(re3.test(emailLower)){
                            return emailLower
                        }else{
                            this.setState({
                                emailValidation : "* Please enter a valid email Id."
                            })
                        }
                    }
                }
            }
        }
    };
    handleSelectedRadioButton = (e) =>{
        this.setState({[e.target.name]: e.target.value})
    }
    validateEmailSignup = (value) => {

        if (!value) {
            toast.error("Please enter your email address", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!this.validateEmail2(value)) {

            toast.error("Invalid email address", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                email:""
            })
        }

    }
    onCountrySelected2 = ({country, countryCode, countryId}) => {

        let pickUpCountryId = countryId;
         let pickupCountryName = country;
        this.setState({
            pickUpCityId:"",
            pickUpCityName:"",
            pickUpCountryId: pickUpCountryId,
            pickupCountryName: pickupCountryName,
            pickUpStateName: "",
            pickUpStateId: "",
        },()=>{
            if(this.state.pickupCountryName.toUpperCase() == "INDIA"){
                this.setState({showCheckbox:true})
            }else{
                this.setState({showCheckbox:false})
                this.setState({getWhatsappNotifications:false})
            }
        })
    };
    validatePassword = (value) => {
        let error;
        if (!value) {
            error = "Please enter your password";
        } else if (value.length < 4) {
            error = "Value must be longer than 3 characters";
        }
        return error;
    }

    ReconfirmPassword = (value) => {
        let error;
        if (!value) {
            error = "Please enter your password";
        } else if (value !== this.state.password) {
            error = "Password Must be same";
        }
        return error;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.closeModal && this.props.closeModal !== prevProps.closeModal) {
            let customer = JSON.parse(localStorage.getItem("customer"))
            let otpVerification = customer && customer.result && customer.result.customerData && customer.result.customerData.otpVerification === true ? customer.result.customerData.otpVerification : false ;
            if (this.props.closeModal === true && otpVerification === true) {
                // this.setState(prevState => ({modalLarge: prevState.modalLarge}))
                this.setState( {openOtpView: true})
            }else {
                this.setState(prevState => ({modalLarge: prevState.modalLarge}))
                let message ="Login Successfully"
                this.props.aftwerOtpSuccess(message)
            }
            if (this.props.closeModal === true) {
                this.setState(prevState => ({modalLarge: prevState.modalLarge}))

            }
        }


        // if (this.props.openLogin && this.props.openLogin !== prevProps.openLogin) {
        //     this.setState({openLogin: true, password: "", loading: true})
        // }


        if (this.props.contactDeatilsEmail && this.props.contactDeatilsEmail !== prevProps.contactDeatilsEmail) {
            this.setState({email:this.props.contactDeatilsEmail })
        }
        if (this.props.contactDeatilsContact && this.props.contactDeatilsContact !== prevProps.contactDeatilsContact) {
            this.setState({phoneNo:this.props.contactDeatilsContact })
        }
        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            },()=>{
                if(this.state.modalLarge === true && !localStorage.getItem("ContactDeatails") === false){
                    let data = JSON.parse(localStorage.getItem("ContactDeatails"))
                    this.setState({email: data.contactEmail,phoneNo :data.contactNumber })
                }

            })
        }
        if (this.props.message && this.props.message !== prevProps.message) {
            // alert("message"+this.props.message)
            if (this.props.message === "Login Successfully") {
            // modalLarge: false,
                this.setState({openLogin: true, password: "", loading: true, openOtpView:true,modalLarge : false},()=>{
                    window.location.reload();
                })
            }

        }
        // if (this.props.signUpSuccessMsg && this.props.signUpSuccessMsg !== prevProps.signUpSuccessMsg) {
        //     alert("login message"+this.props.signUpSuccessMsg)
        //     if (this.props.signUpSuccessMsg === "Login Successfully") {
        //         alert("message inside")
        //         this.setState({
        //             openOtpView:true,
        //         })
        //
        //     }
        // }
        if (this.props.LoginMessage && this.props.LoginMessage !== prevProps.LoginMessage) {

            if (this.props.LoginMessage === "Login Successfully") {
                if (localStorage.getItem("auth_key") !== undefined && localStorage.getItem("auth_key").length >= 1) {
                    this.setState({
                        modalLarge: false
                    },()=>{
                        window.location.reload();
                    })
                }
                this.setState({openLogin: true, password: "", loading: true})
            }

        }
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    onUserRegister = (values) => {

        let whatsapp = this.state.getWhatsappNotifications ? this.state.getWhatsappNotifications : "";
        let whatsappNumber = this.state.whatsappPhoneNo ? this.state.whatsappPhoneNo : "";
        if (!this.props.loading) {
            if (this.state.pickupCountryName != 'India' && this.state.pickupCountryName != 'INDIA'){
                if (this.state.email !== "" && this.state.customerName !== "" && values.password !== "") {
                    // this.props.history.push("/");
                    // if (values.password === values.repassword) {

                    // let customer 
                    // let Branch
                //   const customer = {
                //         customerType:this.state.customerType,
                //         customerName: this.state.customerName,
                //         ownersInfo:this.state.persons,
                //         typeOfBussiness : this.state.typeOf,
                        
                //        }

                  const  Branch = {
                        email:this.state.email,
                        gstType:this.state.gstType,
                        address:this.state.address,
                        streetAddress:this.state.streetAddress,
                        pin:this.state.pin,
                        city:this.state.city,
                        stateName:this.state.stateName ? this.state.stateName :"",
                        isState:this.state.stateName ? true :false,
                        gstNo:this.state.GstNo ? this.state.GstNo:"",
                        cinNo:this.state.cin ? this.state.cin:"",
                        countryName:this.state.pickupCountryName ? this.state.pickupCountryName :this.state.countryName,
                        country:this.state.pickUpCountryId ? this.state.pickUpCountryId :"",
                        gstCheck:this.state.gstcheck,
                        cinCheck:this.state.cincheck,
                        dinCheck:this.state.dincheck
                       }

                        const body = {
                            email: this.state.email,
                            password: this.state.password,
                            phoneNo: this.state.phoneNo,
                            customerName: this.state.customerName,
                            state: this.state.selectedState ?this.state.selectedState : this.state.stateName,
                            countryName : this.state.pickupCountryName ? this.state.pickupCountryName :this.state.countryName,
                            country:this.state.pickUpCountryId ? this.state.pickUpCountryId :"",
                            typeOfBussiness : this.state.typeOf,
                            whatsapp:whatsapp,
                            whatsappNumber: whatsappNumber,
                            loginThrough:"webApp",
                            // customer:customer,
                            Branch:Branch
                            // customerType:this.state.customerType,
                            // gstType:this.state.gstType,
                            // address:this.state.address,
                            // streetAddress:this.state.streetAddress,
                            // pin:this.state.pin,
                            // city:this.state.city,
                            // persons:this.state.persons
                        }
                        let obj ={
                            email: this.state.email,
                            mobileNo : this.state.phoneNo,
                            countryName : this.state.pickupCountryName,

                        }
                        // if(this.state.pickupCountryName != 'India' && this.state.pickupCountryName != 'INDIA'){
                            this.checkUser(obj).then(res=>{
                                if(res.success){
                                    this.props.registerUser(body)
                                }else{
                                    toast.error("User already Exist For this Email or PhoneNumber", {
                                        position: "top-left",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }
                            })
                //             this.setState({
                //                 // openLogin: false,
                // // otpTabOPen : false
                //             })
                        // }else{
                        //     this.setState({otpTabOPen : true,openLogin : true})
                        // }
    
                        if (localStorage.getItem("already_registered") === "true") {
                            this.setState({
                                // modalLarge: false,
    
                            })
                        }
                        // if (!localStorage.getItem("firebaseBrowserId") === false) {
                        //     let obj = {
                        //         token : !localStorage.getItem("firebaseBrowserId") === false ? localStorage.getItem("firebaseBrowserId") : undefined,
                        //         topic: getLoggedInCustomerCode()
                        //     }
                        //     this.props.subscribeNotification(obj)
                        // }
    
                    // } 
                    // else {
                    //     toast.error("Password Not Match", {
                    //         position: "top-left",
                    //         autoClose: 3000,
                    //         hideProgressBar: false,
                    //         closeOnClick: true,
                    //         pauseOnHover: true,
                    //         draggable: true,
                    //         progress: undefined,
                    //     });
                    // }
    
    
                }else {
                    toast.error("Please fill all the field", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }else {
            if (this.state.email !== "" && this.state.phoneNo !== "" && this.state.customerName !== "" && values.password !== "") {
                // this.props.history.push("/");
                // if (values.password === values.repassword) {

                const customer = {
                    customerType:this.state.customerType,
                    customerName: this.state.customerName,
                    ownersInfo:this.state.persons,
                    typeOfBussiness : this.state.typeOf,
                    cinNo:this.state.cin ? this.state.cin:"",
                    dinCheck:this.state.dincheck,
                    cinCheck:this.state.cincheck,
                   }

              const  Branch = {
                    email:this.state.email,
                    gstType:this.state.gstType,
                    address:this.state.address,
                    streetAddress:this.state.streetAddress,
                    pin:this.state.pin,
                    city:this.state.city,
                    stateName:this.state.stateName ? this.state.stateName :"",
                    isState:this.state.stateName ? true :false,
                    gstNo:this.state.GstNo ? this.state.GstNo:"",
                    cinNo:this.state.cin ? this.state.cin:"",
                    countryName:this.state.pickupCountryName ? this.state.pickupCountryName :this.state.countryName,
                    country:this.state.pickUpCountryId ? this.state.pickUpCountryId :"",
                    gstCheck:this.state.gstcheck,
                    cinCheck:this.state.cincheck,
                    dinCheck:this.state.dincheck
                   }
                   const body = {
                    email: this.state.email,
                    password: this.state.password,
                    phoneNo: this.state.phoneNo,
                    customerName: this.state.customerName,
                    state: this.state.selectedState ?this.state.selectedState : this.state.stateName,
                    countryName : this.state.pickupCountryName ? this.state.pickupCountryName :this.state.countryName,
                    country:this.state.pickUpCountryId ? this.state.pickUpCountryId :"",
                    typeOfBussiness : this.state.typeOf,
                    whatsapp:whatsapp,
                    whatsappNumber: whatsappNumber,
                    loginThrough:"webApp",
                    customer:customer,
                    Branch:Branch
                    
                }
                    // const body = {
                    //     email: this.state.email,
                    //     password: this.state.password,
                    //     phoneNo: this.state.phoneNo,
                    //     customerName: this.state.customerName,
                    //     state: this.state.selectedState,
                    //     countryName : this.state.pickupCountryName,
                    //     typeOfBussiness : this.state.typeOf,
                    //     whatsapp:whatsapp,
                    //     whatsappNumber: whatsappNumber,
                    //     loginThrough:"webApp"
                    // }
                    let obj ={
                        email: this.state.email,
                        mobileNo : this.state.phoneNo,
                        countryName : this.state.pickupCountryName,

                    }
                    if(this.state.pickupCountryName != 'India' && this.state.pickupCountryName != 'INDIA'){
                        this.checkUser(obj).then(res=>{
                            if(res.success){
                                this.props.registerUser(body)
                            }else{
                                toast.error("User already Exist For this Email or PhoneNumber", {
                                    position: "top-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        })
            //             this.setState({
            //                 // openLogin: false,
            // // otpTabOPen : false
            //             })
                    }else{
                        this.setState({otpTabOPen : true,openLogin : true})
                    }

                    if (localStorage.getItem("already_registered") === "true") {
                        this.setState({
                            // modalLarge: false,

                        })
                    }
                    // if (!localStorage.getItem("firebaseBrowserId") === false) {
                    //     let obj = {
                    //         token : !localStorage.getItem("firebaseBrowserId") === false ? localStorage.getItem("firebaseBrowserId") : undefined,
                    //         topic: getLoggedInCustomerCode()
                    //     }
                    //     this.props.subscribeNotification(obj)
                    // }

                // } 
                // else {
                //     toast.error("Password Not Match", {
                //         position: "top-left",
                //         autoClose: 3000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     });
                // }


            }else {
                toast.error("Please fill all the field", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        }

    }
    checkUser = async (obj) =>{
        return await RestClient({
            url: CHECK_USER,
            method: "POST",
            data: obj
        })
    }
    onUserLogin = (values) => {


        // if (!this.props.loading) {
            if (values.email !== "" && values.password !== "") {
                const body = {
                    companyCode: "zworld_009",
                    email: this.state.email,
                    password: this.state.password,
                    source: "Web",
                    loginThrough: "webApp",


                }
                // this.loginApp(body)
                this.props.loginUser(body)
                // let loginData = this.props.loginUser(body)
                // console.log('JSON.stringify====',JSON.stringify(loginData))
                /*this.setTimeout(this.reloadFunction(),7000)*/
        // window.location.reload(false);

            }else {
                toast.error("Please enter your email address and Password Properly", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        // }


    }
    loginApp = async(dataForServer) =>{
        return await RestClient({
            url: LOGIN_API,
            method: "POST",
            data: dataForServer
        })
    }
    reloadFunction = () => {
        window.location.reload(false)
    }

    validatePhonNumberForOTP = (value) => {

        if (!value) {
            toast.error("Please enter your Phone Number", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^\d{10}$/.test(value)) {

            toast.error("Phone Number Should be 10 digit", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.setState({
                mobileNo:""
            })
        }

    }
    SendOtp = e => {

        this.setState({
            clicked:false
        },()=>{

            if (this.state.mobileNo == undefined || this.state.countryCode === "")
            {

                this.setState({
                    clicked: true
                });
                toast.error('Please Enter Phone Number.', {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else{
                localStorage.setItem("sendOtp",true)

                this.otpRequestApi({mobileNo:this.state.mobileNo, otp:this.state.otp, countryCode:this.state.countryCode}).then(responce=>{
                    if(responce.success){
                        toast.success(responce.message, {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        let sessionId =responce && responce.result && responce.result.Details ?  responce.result.Details : "";

                        this.setState({
                            sessionId:sessionId
                        });
                    }else {
                        toast.error(responce.message, {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
            }
        });
    };

    submitForm = e => {

        this.setState({
            clicked:false
        },()=>{

            const {mobileNo,countryCode, otp, sessionId} = this.state;
            this.otpConfirmApi({mobileNo:this.state.mobileNo, otp:this.state.otp, countryCode:this.state.countryCode,sessionId:sessionId}).then(responce=>{
                if(responce.success){
                    let details = responce.result && responce.result.Details ? responce.result.Details : undefined
                    if(details && details === "OTP Matched"){
                        // toast.success(details, {
                        //     position: "top-left",
                        //     autoClose: 3000,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        // });

                    }
                    else{
                        toast.error(details, {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                    if(details && details === "OTP Matched"){
                        localStorage.removeItem("sendOtp")
                        localStorage.setItem("otpVerifiedSession",JSON.stringify(this.state.sessionId))
                        localStorage.setItem("otpVerified",true)
                        let message ="Login Successfully"
                        this.props.aftwerOtpSuccess(message)
                        // history.push(PAGE_PATHS.LANDING_PAGE_VIEW);
                        // window.location.reload(false);
                    }
                }
                else {
                    toast.error(responce.message, {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })

        });
    };
    otpConfirmApi = async (dataForServer) => {
        return await RestClient({
            url: USER_LOGIN_CHECKOTP,
            method: "POST",
            data: dataForServer
        })
    };
    otpRequestApi = async (dataForServer) => {
        return await RestClient({
            url: USER_LOGIN_SEND_MOBILE,
            method: "POST",
            data: dataForServer
        })
    };
    sendOTP =  (e) =>{
        e.preventDefault()
        let obj ={
            countryCode : '+91',
            mobileNo : this.state.phoneNo
        }
        let ele ={
            email: this.state.email,
            mobileNo : this.state.phoneNo,
            countryName:this.state.pickupCountryName
        }
        this.setState({
            sendOtpButtonClicked : true
        })
        this.checkUser(ele).then(res=>{
            if(res.success){
                this.apiToSendOtp(obj).then(res=>{
                    if(res.success){
                        this.setState({
                            sessionId : res.result.Details
                        })
                    }
                })
            }else{
                toast.error("Account Already Exist For This Email or PhoneNumber", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    sendOTPForLogin =  (e) =>{
        e.preventDefault()
        let obj ={
            countryCode : '+91',
            mobileNo : this.state.phoneNo
        }
        this.setState({
        })
        this.checkUserForLogin({mobileNo:this.state.phoneNo}).then(res=>{
            if(res.success){
                this.apiToSendOtp(obj).then(res=>{
                    if(res.success){
                        this.setState({
                            sessionIds : res.result.Details,
                            buttonName : "Resend",
                            sendOtpButtonClicked : true
                        })
                    }
                })
            }else{
                toast.error("No User Found For This Phone Number,Please Sign Up", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
           
        
    }
    checkUserForLogin =async (obj)=>{
        return await RestClient({
            url: CHECK_USER_VIA_PHONE,
            method: "POST",
            data: obj
        }) 
    }
    loginViaPhone = (e) =>{
        e.preventDefault()
        let obj ={
            phoneNo : this.state.phoneNo,
            otp : this.state.otp,
            sessionId : this.state.sessionIds,
            source : 'Web'
        }
        this.loginApiUsingPhone(obj).then(res=>{
            if(res.success){
                localStorage.setItem('already_registered', JSON.stringify(true));
                localStorage.setItem('customer', JSON.stringify(res));
                localStorage.setItem('auth_key', res.result.authToken);
                localStorage.setItem('cust_id', res.result.customerData.customerName);
                localStorage.setItem('otpVerified', true);
                localStorage.setItem('typeOfBussiness', res && res.result &&  res.result.customerData && res.result.customerData.typeOfBussiness);

                localStorage.removeItem('ContactDeatails');
                setToken(res.result.authToken)
                this.setState({modalLarge:false})
                this.handlePageReload(e)
            }else{
                toast.error("Please Enter Valid OTP to Login", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
     handlePageReload = (e) =>{
        e.preventDefault()
        window.location.reload(false);
}
    loginApiUsingPhone =async (obj) =>{
        return await RestClient({
            url: LOGIN_API_VIA_PHONE,
            method: "POST",
            data: obj
        })
    }
    apiToSendOtp = async (obj) =>{
        return await RestClient({
            url: USER_LOGIN_SEND_MOBILE,
            method: "POST",
            data: obj
        })
    }
    handleCheckbox = () =>{
        this.setState({getWhatsappNotifications:!this.state.getWhatsappNotifications,isWhatsappOtpSent:false})
    }
    handleCheckboxGst = () =>{
        this.setState({gstcheckBox:!this.state.gstcheckBox})
    }
    handleCheckboxIec = () =>{
        this.setState({checkCin:!this.state.checkCin})
    }
    handleCheckboxDin = () =>{
        this.setState({checkDin:!this.state.checkDin})
    }
    // validateWhatsapp = async () =>{
    //     if(this.state.isWhatsappNoValid){
    //     //    let otp =  Math.floor(100000 + Math.random() * 900000);
    //         try{
    //             let res = await axios(
    //                 {
    //                     method: "POST",
    //                     url: "https://backend.aisensy.com/campaign/t1/api",
    //                     headers: {
    //                         "Content-Type": "application/json; charset=utf-8",
    //                     },
    //                     data:{
    //                         "apiKey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMjFiMTZiZjdiOGVjMGViYzlhNDdlYiIsIm5hbWUiOiJaaXBhd29ybGQiLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjIyMWIxNjQ0M2ViOWYwZWMwODZlZWUxIiwiYWN0aXZlUGxhbiI6IkJBU0lDX1RSSUFMIiwiaWF0IjoxNjQ2NDU5MjQxfQ.hlfUrvxfBUu4mUKColySc640kCtzv2icZpGtC4URjPg",
    //                         "campaignName": "Whatsapp Verification OTP",
    //                         "destination": `${this.state.whatsappPhoneNo}`,
    //                         "userName": "user",
    //                         "templateParams": [
    //                             "https://zipaworld.com"
    //                         ]
    //                     }
    //                 }
    //             )
    //             if(res.data == "Success."){
    //                 // this.setState({
    //                 //     isWhatsappOtpSent:true,
    //                 // })
    //                  toast.success("Whatsapp number validation successful", {
    //                      position: "top-left",
    //                      autoClose: 3000,
    //                      hideProgressBar: false,
    //                      closeOnClick: true,
    //                      pauseOnHover: true,
    //                      draggable: true,
    //                      progress: undefined,
    //                  });
    //             }
    //         }catch(err){
    //             toast.error("Whatsapp number validation was unsuccess", {
    //                 position: "top-left",
    //                 autoClose: 3000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         }
    //         this.setState({
    //             whatsappPhoneNo:"",
    //             isWhatsappNoValid:false
    //         })
    //     }else{
    //         toast.error("Can't send otp to an invalid number", {
    //             position: "top-left",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }
    // }
    verifyWhatsappOtp = () => {

    }
    verifyOTP = (e) =>{
        e.preventDefault()
        let obj = {
            otp : this.state.OTP,
            sessionId : this.state.sessionId,
        }
        this.apiToVerifyOtp(obj).then(res=>{
            if(res.result.Status == 'Success'){

                const customer = {
                    customerType:this.state.customerType,
                    customerName: this.state.customerName,
                    ownersInfo:this.state.persons,
                    typeOfBussiness : this.state.typeOf,
                    cinNo:this.state.cin ? this.state.cin:"",
                    dinCheck:this.state.dincheck,
                    cinCheck:this.state.cincheck,
                   }

              const  Branch = {
                    email:this.state.email,
                    gstType:this.state.gstType,
                    address:this.state.address,
                    streetAddress:this.state.streetAddress,
                    pin:this.state.pin,
                    city:this.state.city,
                    stateName:this.state.stateName ? this.state.stateName :"",
                    isState:this.state.stateName ? true :false,
                    gstNo:this.state.GstNo ? this.state.GstNo:"",
                    cinNo:this.state.cin ? this.state.cin:"",
                    countryName:this.state.pickupCountryName ? this.state.pickupCountryName :this.state.countryName,
                    country:this.state.pickUpCountryId ? this.state.pickUpCountryId :"",
                    gstCheck:this.state.gstcheck,
                    cinCheck:this.state.cincheck,
                    dinCheck:this.state.dincheck
                   }
                   const body = {
                    email: this.state.email,
                    password: this.state.password,
                    phoneNo: this.state.phoneNo,
                    customerName: this.state.customerName,
                    state: this.state.selectedState ?this.state.selectedState : this.state.stateName,
                    countryName : this.state.pickupCountryName ? this.state.pickupCountryName :this.state.countryName,
                    country:this.state.pickUpCountryId ? this.state.pickUpCountryId :"",
                    typeOfBussiness : this.state.typeOf,
                    loginThrough:"webApp",
                    customer:customer,
                    Branch:Branch
                    
                }


                // const body = {
                //     email: this.state.email,
                //     password: this.state.password,
                //     phoneNo: this.state.phoneNo,
                //     customerName: this.state.customerName,
                //     state: this.state.selectedState,
                //     countryName : this.state.pickupCountryName,
                //     typeOfBussiness : this.state.typeOf
                // }
                this.props.registerUser(body)
            }else{
                toast.error("OTP does not match", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    apiToVerifyOtp = async (obj) =>{
        return await RestClient({
            url: USER_LOGIN_CHECKOTP,
            method: "POST",
            data: obj
        })
    }
    verifyGst = (e) =>{
        e.preventDefault()
        let obj = {
            gstin : this.state.GstNo,
        }
        this.apiToVerifyGst(obj).then(res=>{
            if(res.success){
                // const body = {
                //     email: this.state.email,
                //     password: this.state.password,
                //     phoneNo: this.state.phoneNo,
                //     customerName: this.state.customerName,
                //     state: this.state.selectedState,
                //     countryName : this.state.pickupCountryName,
                //     typeOfBussiness : this.state.typeOf
                // }
         let  gstData  = res && res.result ? res.result :""
                this.setState({
                    customerName: gstData && gstData.tradeName ? gstData.tradeName :"",
                    selectedState:gstData && gstData.principalPlaceOfBusinessFields &&  gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress && gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.stateName ? gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.stateName :"",
                  
                    customerType: gstData && gstData.constitutionOfBusiness ? gstData.constitutionOfBusiness:"",
                    gstType: gstData && gstData.taxpayerType ? gstData.taxpayerType:"",
                    address:gstData && gstData.principalPlaceOfBusinessFields &&  gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress && gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.streetName ? gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.streetName :"",
                    streetAddress:gstData && gstData.principalPlaceOfBusinessFields &&  gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress && gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.buildingNumber ? gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.buildingNumber :"" + gstData && gstData.principalPlaceOfBusinessFields &&  gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress && gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.buildingName ? gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.buildingName :"",
                    pin:gstData && gstData.principalPlaceOfBusinessFields &&  gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress && gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.pincode ? gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.pincode :"",
                    stateName:gstData && gstData.principalPlaceOfBusinessFields &&  gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress && gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.stateName ? gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.stateName :"",
                    city:gstData && gstData.principalPlaceOfBusinessFields &&  gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress && gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.districtName ? gstData.principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress.districtName :"",
                    countryName:"India",
                    gstValue:gstData,
                    gstcheck:true



                })
                toast.success("Gst Number Verified", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
            }else{
                toast.error("Gst Number not Verified", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    apiToVerifyGst = async (obj) =>{
        return await RestClient({
            url: SEARCH_GST_DATA,
            method: "POST",
            data: obj
        })
    }

    verifyCin = (e) =>{
        e.preventDefault()
        let obj = {
            cin : this.state.cin,
        }
        this.apiToVerifyCin(obj).then(res=>{
            if(res.success){
                
         let  Data  = res && res.result && res.result ? res.result  :""
                this.setState({
                    customerName: Data && Data.companyName ? Data.companyName :"",
                    email:Data && Data.email ? Data.email:"",
                    address:Data && Data.registeredAddress ? Data.registeredAddress:"",
                    phoneNo: Data && Data.registeredContactNo ? Data.registeredContactNo:"",
                    cincheck:true,

                    
                })
                toast.success("Cin Number Verified", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
            }else{
                toast.error("Cin Number not Verified", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    apiToVerifyCin= async (obj) =>{
        return await RestClient({
            url: SEARCH_CIN_DATA,
            method: "POST",
            data: obj
        })
    }
    verifyDin = (e) =>{
        e.preventDefault()
        let obj = {
            cin : this.state.cin,
        }
        this.apiToVerifyDin(obj).then(res=>{
            if(res.success){
                
         let  Data  = res && res.result && res.result.response ?res.result.response  :""

          for (let i = 0; i < Data.length; i++) {
            const newArray = Data[i];
            
            const targetIndex = i;
            this.updateOwnersInfoWithNewArray(targetIndex, newArray);
          }
          
          const combinedArray = [];

// Push all the objects from ownersInfo array into the combinedArray
for (const obj of this.state.ownersInfo) {
  combinedArray.push(obj);
}

                this.setState({
                    persons:combinedArray,
                    dincheck:true
                })
                toast.success("Din Number Verified", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
            }else{
                toast.error("Din Number not Verified", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    apiToVerifyDin= async (obj) =>{
        return await RestClient({
            url: SEARCH_DIN_DATA,
            method: "POST",
            data: obj
        })
    }
    
     updateOwnersInfoWithNewArray = async(targetIndex, newArray)=> {
        
        const payload = {
          ownerName: newArray.name,
          ownerDinNo: newArray.din,
          ownerContactNo:"",
          ownerEmail:"",
          aadhaarNo:"",

        };
      
        this.state.ownersInfo[targetIndex] = {
          ...this.state.ownersInfo[targetIndex],
          ...payload
        };
      }
    render() {
        const {password, email, repassword, customerName, gstNo, phoneNo, selectedState} = this.state;
        const initialValues = {email, password, repassword, customerName, gstNo, phoneNo, selectedState};
        let openLogin = this.state.openLogin
        return (
            <>



                    {openLogin === false ?
                        <>
                            <Modal className="sizechange pop-login popup_sign_login pop-login-screen" modalClassName="spotRatesIndex"
                                   isOpen={this.state.modalLarge}
                                   size="lg"
                                   toggle={this.toggleLarge}
                            >
                            <ModalHeader toggle={this.toggleLarge} className="" style={{padding: "0"}}>
                            </ModalHeader>
                            <ModalBody className="padding-0 auth-card authCardOther">
                                {/*<Row className="h-100">*/}
                                <div className="form-side position-relative text-center">
                                    <button type="button" className="close" aria-label="Close"
                                            onClick={this.toggleLarge}/>
                                    <img className="img-fluid"
                                         src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.4091394021728352-1635504757044.png"
                                         alt="logo" style={{marginBottom: "20px"}}/>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={(values) => this.onUserRegister(values)}>
                                        {({errors, touched}) => (
                                            <Form className="av-tooltip tooltip-label-bottom">
                                                <div className="text-left has-float-label">
                                                  <Label className="">
                                                        I am a*
                                                    </Label>
                                                </div>
                                                <FormGroup className="text-left">
                                                    <div className="form-check-inline">
                                                        <strong>
                                                            <CustomInput
                                                                type="radio"
                                                                id="bussiness"
                                                                label="Business"
                                                                style={{fontWeight: '700'}}
                                                                name="typeOf"
                                                                value={"Bussiness"}
                                                                onChange={this.handleSelectedRadioButton}
                                                                checked={this.state.typeOf === "Bussiness"}
                                                            />
                                                        </strong>
                                                    </div>
                                                    <div className="form-check-inline">
                                                        <strong>
                                                            <CustomInput
                                                                type="radio"
                                                                id="individual"
                                                                label="Individual"
                                                                name="typeOf"
                                                                value={"Individual"}
                                                                onChange={this.handleSelectedRadioButton}
                                                                checked={this.state.typeOf === "Individual"}
                                                            />
                                                        </strong>
                                                    </div>
                                                </FormGroup>
                                                {/* {this.state.typeOf === "Individual" || this.state.pickupCountryName.toUpperCase() !== "INDIA" ? "" :
                                                    <FormGroup className="form-check has-float-label" style={{textAlign: "start"}}>
                                                        <input className="form-check-input"
                                                               onChange={this.handleCheckboxGst} type="checkbox"
                                                               value={this.state.gstcheckBox}
                                                               checked={this.state.gstcheckBox} id="flexCheckChecked"/>
                                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                                            Do you want to verify your Gst No
                                                        </label>
                                                    </FormGroup>} */}
                                                {/* {this.state.gstcheckBox && this.state.typeOf === "Bussiness" && this.state.pickupCountryName.toUpperCase() === "INDIA" ?
                                                    <>
                                                        <FormGroup className="has-float-label">
                                                            <Label className="red">
                                                                {"Verify your Gst Number"}
                                                            </Label>


                                                            <Field
                                                                placeholder="Enter your Gst No"
                                                                className="form-control"
                                                                name="GstNo"
                                                                onKeyUp={this.handleChange}
                                                                // validate={this.validateEmail}
                                                            />
                                                            <div className="d-flex justify-content-end align-items-center mt-2">
                                                                <Button className="btn verify_btn1"
                                                                        onClick={(e) => this.verifyGst(e)}>
                                                                    <span className="label">Verify Gst </span>
                                                                </Button>
                                                            </div>
                                                        </FormGroup>

                                                    </> :""} */}
                                                    {/* {this.state.typeOf === "Individual" || this.state.pickupCountryName.toUpperCase() !== "INDIA"  ? "" :
                                                <FormGroup className="form-check has-float-label" style={{textAlign: "start"}}>
                                                    <input className="form-check-input"
                                                           onChange={this.handleCheckboxIec} type="checkbox"
                                                           value={this.state.checkCin} checked={this.state.checkCin}
                                                           id="flexCheckChecked"/>
                                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                                        Do you want to verify your Cin/Din No
                                                    </label>
                                                </FormGroup>} */}
                                                {/* {this.state.checkCin && this.state.typeOf === "Bussiness"  && this.state.pickupCountryName.toUpperCase() === "INDIA"  ? 
                                                    <>

                                                        <FormGroup className="form-group has-float-label">
                                                            <Label className="red">
                                                                {"Verify your Cin Number"}
                                                            </Label>
                                                            <Field
                                                                placeholder="Enter your Cin No"
                                                                className="form-control"
                                                                name="cin"
                                                                onKeyUp={this.handleChange}
                                                            />
                                                            <div className="d-flex justify-content-end align-items-center mt-2">
                                                                <Button className="btn verify_btn1"
                                                                        onClick={(e) => this.verifyCin(e)}>
                                                                    <span className="label">Verify Cin </span>
                                                                </Button>
                                                                <Button className="btn verify_btn1"
                                                                        onClick={(e) => this.verifyDin(e)}>
                                                                    <span className="label">Verify Din </span>
                                                                </Button>
                                                            </div>
                                                        </FormGroup>

                                                    </>
                                                    :""
                                                    } */}
                                                <FormGroup className="form-group has-float-label">
                                                    <Label className="red">
                                                        {this.state.typeOf === "Individual" ?
                                                            "Name"
                                                            :
                                                            "Company Name"}*
                                                    </Label>
                                                    {this.state.typeOf === "Individual" ?
                                                        <Field
                                                            placeholder="Enter your  Name"
                                                            className="form-control"
                                                            name="customerName"
                                                            onChange={this.handleChange}
                                                            value={this.state.customerName}

                                                        />
                                                        :

                                                        <Field
                                                            placeholder="Enter your Company Name"
                                                            className="form-control"
                                                            name="customerName"
                                                            onChange={this.handleChange}
                                                            value={this.state.customerName}

                                                        />}

                                                </FormGroup>


                                                <FormGroup className="form-group has-float-label">
                                                    <Label className="red">
                                                        Email*
                                                    </Label>
                                                    <Field
                                                        placeholder="Enter your Email Address"
                                                        className="form-control"
                                                        name="email"
                                                        value={this.state.email}
                                                        onBlur={e => {
                                                            e.preventDefault()
                                                            this.validateEmailSignup(e.target.value)
                                                        }}
                                                        onChange={this.handleChange}
                                                    />

                                                </FormGroup>


                                                <FormGroup className="form-group has-float-label">
                                                    <Label className="red">
                                                        <IntlMessages id="Create Password"/>*
                                                    </Label>
                                                    <Field
                                                        className="form-control"
                                                        placeholder="Create Your Password"
                                                        type="password"
                                                        name="password"
                                                        validate={this.validatePassword}
                                                        onKeyUp={this.handleChange}
                                                    />
                                                    {errors.password && touched.password && (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.password}
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="form-group has-float-label country-dropdown">
                                                    <Label className="red">
                                                        Country*
                                                    </Label>

                                                    <CountryDropDown
                                                        handler={this.onCountrySelected2}
                                                        selectedCountry={
                                                            this.state.pickUpCountryId
                                                                ? this.state.pickUpCountryId
                                                                : ""
                                                        }
                                                    />
                                                    {errors.customerName && touched.customerName && (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.customerName}
                                                        </div>
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="form-group has-float-label">
                                                    {this.state.pickupCountryName != 'India' && this.state.pickupCountryName != 'INDIA' ?
                                                        <Label className="red" style={{zIndex: 'inherit'}}>
                                                            Contact-number
                                                        </Label> : <Label className="red" style={{zIndex: 'inherit'}}>
                                                            Contact-number*
                                                        </Label>}
                                                    <Field
                                                        placeholder="Enter your Contact Number "
                                                        className="form-control"
                                                        type="phoneNo"
                                                        value={this.state.phoneNo}
                                                        name="phoneNo"
                                                        onBlur={e => {
                                                            e.preventDefault()
                                                            this.validatePhonNumber(e.target.value)
                                                        }}
                                                        onChange={this.handleChange}
                                                    />
                                                    {errors.phoneNo && touched.phoneNo && (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.phoneNo}
                                                        </div>
                                                    )}
                                                </FormGroup>

                                                {
                                                    this.state.getWhatsappNotifications == true ?
                                                        <FormGroup className="form-group has-float-label">
                                                            <Label className="red">
                                                                Whatsapp-number*
                                                            </Label>
                                                            <div style={{display: 'flex', gap: "0.5rem"}}>
                                                                <Field
                                                                    className="form-control"
                                                                    type="phoneNo"
                                                                    value={this.state.whatsappPhoneNo}
                                                                    name="whatsappPhoneNo"
                                                                    onBlur={e => {
                                                                        e.preventDefault()
                                                                        this.validateWhatsappNumber(e.target.value)
                                                                    }}
                                                                    onChange={this.handleChange}
                                                                />

                                                            </div>
                                                            {errors.phoneNo && touched.phoneNo && (
                                                                <div className="invalid-feedback d-block">
                                                                    {errors.phoneNo}
                                                                </div>
                                                            )}
                                                        </FormGroup> : ""
                                                }

                                                {this.state.showCheckbox ?
                                                        <div className="form-check" style={{marginBottom: "1rem"}}>
                                                            <input className="form-check-input"
                                                                   onChange={this.handleCheckbox} type="checkbox"
                                                                   value={this.state.getWhatsappNotifications}
                                                                   checked={this.state.getWhatsappNotifications}
                                                                   id="flexCheckChecked"/>
                                                            <label className="form-check-label"
                                                                   htmlFor="flexCheckChecked">
                                                                I want to receive notifications over whatsapp
                                                            </label>
                                                        </div> : ""
                                                }

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "#000",
                                                        fontSize: "16px",
                                                        textDecoration: "underline",
                                                        cursor: "pointer"
                                                    }} onClick={e => {
                                                        e.preventDefault()

                                                        this.setState({
                                                            openLogin: true,
                                                            email: "",
                                                            password: "",
                                                            loading: false
                                                        }, () => {
                                                            if (this.state.modalLarge === true && !localStorage.getItem("ContactDeatails") === false) {
                                                                let data = JSON.parse(localStorage.getItem("ContactDeatails"))
                                                                this.setState({email: data.contactEmail})
                                                            }
                                                        })
                                                    }
                                                    }>
                                                        Login
                                                    </div>

                                                    <Button className="btn login_btn">
                                                        <span className="label">Sign Up</span>
                                                    </Button>

                                                </div>

                                            </Form>

                                        )}
                                    </Formik>
                                </div>
                                {/*</Row>*/}
                            </ModalBody>
                        </Modal>
                        </>
                        :
                        <>
                            <Modal className={this.state.otpTabOPen?"sizechange pop-login pop-login-screen":"sizechange pop-login-screen"} modalClassName="spotRatesIndex"
                                   isOpen={this.state.modalLarge}
                                   size="lg"
                                   toggle={this.toggleLarge}
                            >
                            <ModalHeader className="" toggle={this.toggleLarge} style={{padding: "0"}}>
                            </ModalHeader>

                             {this.state.otpTabOPen !== true ?
                                <ModalBody className="padding-0">
                                        <div className="">
                                            <div className="auth-card">

                                                <div className="form-side position-relative text-center">
                                                    <button type="button" className="close" aria-label="Close" onClick={this.toggleLarge}/>
                                                    <img className="img-fluid" alt="logo" src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.4091394021728352-1635504757044.png" style={{marginBottom: "20px"}}/>

                                                    <div className="d-flex flex-wrap align-items-stretch justify-content-between">
                                                        <div className="login_screen">
                                                            <CardTitle className="mb-4 login_title" style={{fontWeight:"bold",
                                                                fontSize: "25px", textAlign:"left"}}>
                                                                Login
                                                            </CardTitle>
                                                            <Formik initialValues={initialValues} onSubmit={this.onUserLogin}>
                                                                {({errors, touched}) => (
                                                                    <Form className="av-tooltip tooltip-label-bottom login-margin">
                                                                        <FormGroup className="form-group has-float-label">
                                                                            <Label className="red">
                                                                                Email*
                                                                            </Label>
                                                                            <Field
                                                                                className="form-control"
                                                                                name="email"
                                                                                // validate={this.validateEmail}
                                                                                onKeyUp={this.handleChange}
                                                                            />
                                                                            {/*{errors.email && touched.email && (*/}
                                                                            {/*    <div className="invalid-feedback d-block">*/}
                                                                            {/*      {errors.email}*/}
                                                                            {/*    </div>*/}
                                                                            {/*)}*/}
                                                                        </FormGroup>
                                                                        <FormGroup className="form-group has-float-label">
                                                                            <Label className="red">
                                                                                Password*
                                                                            </Label>
                                                                            <Field
                                                                                className="form-control"
                                                                                type="password"
                                                                                name="password"
                                                                                validate={this.validatePassword}
                                                                                onKeyUp={this.handleChange}
                                                                            />
                                                                            {errors.password && touched.password && (
                                                                                <div className="invalid-feedback d-block">
                                                                                    {errors.password}
                                                                                </div>
                                                                            )}
                                                                        </FormGroup>
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <div style={{fontWeight: "700",
                                                                                color: "#000",
                                                                                fontSize: "16px",
                                                                                textDecoration: "underline",
                                                                                cursor: "pointer"}} onClick={e => {
                                                                                e.preventDefault()
                                                                                this.setState({
                                                                                    openLogin: false,
                                                                                    email: "",
                                                                                    password: "",
                                                                                    repassword: "",
                                                                                    customerName: "",
                                                                                    gstNo: "",
                                                                                    phoneNo: "",
                                                                                    loading: false
                                                                                })
                                                                            }}>
                                                                                New Sign Up?
                                                                            </div>
                                                                            <div style={{fontWeight: "700",
                                                                                color: "#000",
                                                                                fontSize: "16px",
                                                                                textDecoration: "underline",
                                                                                cursor: "pointer"}}
                                                                                 onClick={e => {
                                                                                     e.preventDefault()
                                                                                     this.props.history.push(PAGE_PATHS.FORGETPASSWORD)
                                                                                 }}>
                                                                                Forgot Password
                                                                            </div>

                                                                        </div>
                                                                        <div className="mt-3 mb-2text-center">
                                                                            <Button className="btn login_btn">
                                                                                <span className="label">
                                                                                    Login</span>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="horizontal_lines position-relative">
                                                                            <h4>Or Login Using</h4>
                                                                        </div>
                                                                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                                            <div className="mr-3">
                                                                                <GoogleLogin
                                                                                    // clientId="365945340002-mb3l0k2jqtc4cj088s2ktagfj5kvaud3.apps.googleusercontent.com"
                                                                                    clientId={GOOGLE_LOGIN_CLIENT_ID}
                                                                                    buttonText=""
                                                                                    icon={false}
                                                                                    className="my-google-button-class"
                                                                                    onSuccess={this.onGoogleLogin}
                                                                                    onFailure={this.onGoogleLoginFailure}
                                                                                    cookiePolicy={'single_host_origin'}>
                                                                                    <FontAwesome
                                                                                        name='google'
                                                                                    />
                                                                                </GoogleLogin>
                                                                            </div>

                                                                            <div>
                                                                                <FacebookLogin
                                                                                    appId="4114770991961239"
                                                                                    autoLoad={false}
                                                                                    fields="name,email,picture"
                                                                                    textButton = ""
                                                                                    onClick={this.componentClicked}
                                                                                    callback={this.onFacebookLogin}
                                                                                    cssClass="my-facebook-button-class"
                                                                                    icon="fa fa-facebook"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                        <div className="mid_otp_line position-relative">
                                                            <h3>OR</h3>
                                                        </div>
                                                        <div className="login_otp_screen">
                                                            <CardTitle className="mb-4 login_title" style={{fontWeight:"bold",
                                                                fontSize: "25px", textAlign:"left"}}>
                                                                Login with mobile number
                                                            </CardTitle>
                                                            <Formik initialValues={initialValues}>
                                                                {({errors, touched}) => (
                                                                    <Form className="av-tooltip tooltip-label-bottom login-margin">
                                                                        <div className="otp_section">
                                                                            <Label style={{color:'#000'}}>
                                                                                Mobile No.*
                                                                            </Label>
                                                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                                                <div className="form-group has-float-label width_Code">
                                                                                    <input
                                                                                        name="phoneNumber"
                                                                                        className="form-control inputs1"
                                                                                        type="text"
                                                                                        placeholder="Phone Number"
                                                                                        value="+91"
                                                                                        readOnly
                                                                                        // onChange={onChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="form-group has-float-label width_number">

                                                                                    <Field
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        onKeyUp={this.handleChange}
                                                                                        name="phoneNo"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {this.state.sendOtpButtonClicked ? <div className="form-group has-float-label">
                                                                                <Label className="red">
                                                                                    Enter Otp
                                                                                </Label>
                                                                                <Field
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onKeyUp={this.handleChange}
                                                                                    name="otp"
                                                                                />
                                                                            </div> : ''}
                                                                            {this.state.buttonName === "Resend"?
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <div onClick={(e)=>this.sendOTPForLogin(e)} style={{fontWeight: "700",
                                                                                    color: "#000",
                                                                                    fontSize: "16px",
                                                                                    textDecoration: "underline",
                                                                                    cursor: "pointer"}}>
                                                                                    <span>{this.state.buttonName} Otp</span>
                                                                                </div>
                                                                            </div>:""}
                                                                            <div className="mt-3 d-flex flex-wrap align-items-center justify-content-center">
                                                                                {this.state.buttonName === "Generate"?
                                                                                <button className="btn login_btn" onClick={(e)=>this.sendOTPForLogin(e)}>
                                                                                    {this.state.buttonName} Otp
                                                                                </button>:""}
                                                                                {this.state.sendOtpButtonClicked ?
                                                                                <button className="btn login_btn" onClick={(e)=>this.loginViaPhone(e)}>
                                                                                    Login
                                                                                </button>
                                                                                    :""}
                                                                            </div>

                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                </ModalBody>
                                :
                                <ModalBody className="padding-0">
                                    <div className="">
                                        <div className="">
                                            <div className="auth-card">
                                                <div className="form-side position-relative text-center">
                                                    <button type="button" className="close" aria-label="Close" onClick={this.toggleLarge}/>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.4091394021728352-1635504757044.png" alt="logo" style={{marginBottom: "20px"}}/>
                                                    <p className="position-relative otp_heading mt-2 mb-4">Otp Verification</p>
                                                    <div className="form-group">
                                                        <input
                                                            name="phoneNumber"
                                                            className="form-control inputs1"
                                                            type="text"
                                                            placeholder="Phone Number"
                                                            value={this.state.phoneNo}
                                                            readOnly
                                                            // onChange={onChange}
                                                        />
                                                    </div>
                                                    {this.state.sendOtpButtonClicked ?
                                                        <div className="form-group">
                                                            <input
                                                                name="OTP"
                                                                className="form-control inputs1"
                                                                type="Number"
                                                                placeholder="Enter Your OTP"
                                                                onChange={this.handleChange}
                                                                value={this.state.OTP}
                                                            />
                                                        </div> : ''
                                                    }
                                                    {this.state.sendOtpButtonClicked == false ?
                                                        <div className="d-flex buttons mt-3">
                                                            <div className="form-group padding-lefts">
                                                                <button type="submit" className="btn login_btn" onClick={(e)=>this.sendOTP(e)}>
                                                                    Send OTP
                                                                </button>
                                                            </div>
                                                        </div> :
                                                        <>
                                                            <div className="d-flex buttons mt-3">
                                                                <div className="form-group padding-lefts">
                                                                    <button type="submit" className="btn login_btn" onClick={(e)=>this.sendOTP(e)}>
                                                                        Resend OTP
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex buttons mt-3">
                                                                <div className="form-group padding-lefts">
                                                                    <button type="submit" className="btn login_btn" onClick={(e)=>this.verifyOTP(e)}>
                                                                        Verify OTP
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                            }

                        </Modal>
                        </>
                    }



            </>
        )};
}

const mapStateToProps = (state) => {
    const {user, loading, message, LoginMessage, closeModal,signUpSuccessMsg} = state.authUser;
    return {user, loading, message, LoginMessage, closeModal,signUpSuccessMsg};
};

const SignUoWithRoute = withRouter(SignUp);

export default connect(
    mapStateToProps,
    {
        registerUser, loginUser,subscribeNotification,loginUserSuccess,aftwerOtpSuccess,registerUserSuccess
    }
)(SignUoWithRoute);
