import React, {Component} from "react";
import {Button, Card, CardBody, Container} from "reactstrap";
import {CircularProgressbar} from "react-circular-progressbar";
import IntlMessages from "../../../helpers/IntlMessages";
import PropTypes from "prop-types";
import {convertDateISO, convertDateISOToCustomiseFormat, parseFloatValue} from "../../../util/CommonUtility";
import '../../../assets/css/style1.css';
import '../../../assets/css/bootstrap.min.css';
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {
    getCharges
} from "../../../redux/advanceSearch/advancSearchAction";
import ContactDeatils from "./ContactDeatils";
import MultiSlabWeight from "./MultiSlabWeight";

class   SelectedDiffrentSlabCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            title: "title",
            detail: "detail",
            percent: 80,
            progressText: "8/10",
            BookingID: "1",
            SlabData:{},
            currentSpotRate: 0,
            gross: "",
            volume: "",
            NoPieces: "",
            calculatedDensity: "",
            TotalChargeable: "",
            preChargeAmount: 0,
            validTillWeight: 0,
            minWeight: 0,
            itemIndex: 0,
            frightAmount: undefined,
            selectedSlabs: [],
            selectedSlabsPublish: [],
            queryDataForcard:{},
            login: undefined,
            showCollaps:false,
            showCollapsForRate:false,
            slabWeightLarge:false,
            minBreakeven:"",
            maxBreakeven:""
        };

    }

    createHoverSurchargesInfo = (item, slab) => {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.SelectedWightSlab && prevProps.SelectedWightSlab !== this.props.SelectedWightSlab) {
            this.setState({
                selectedSlabs: this.props.SelectedWightSlab ? this.props.SelectedWightSlab : []
            }, () => {
                // this.state.spotRateCharges.map((item, i) => {
                //     if (item.scr === "Express") {
                //         this.setState({
                //             expressData: item,
                //             convertedExpressData : item.converted === false ? undefined : item.convertedData
                //         })
                //     }
                //     if (item.scr === "Economy") {
                //         this.setState({
                //             economyData: item,
                //             convertedEconomyData : item.converted === false ? undefined : item.convertedData
                //         })
                //     }
                //     if (item.scr === "Premium") {
                //         this.setState({
                //             premiumData: item,
                //             convertedPremiumData : item.converted === false ? undefined : item.convertedData
                //         })
                //     }
                //     if (item.originDetails && item.originDetails.originCode !== "") {
                //         this.setState({originCode: item.originDetails.originCode})
                //     }
                // })
            });
        }


        if ( this.props.data && prevProps.data !== this.props.data) {
            this.setState({SlabData :this.props.data })
        }
        if ( this.props.frightAmount && prevProps.frightAmount !== this.props.frightAmount) {
            this.setState({frightAmount :this.props.frightAmount })
        }
        if ( this.props.queryDataForcard && prevProps.queryDataForcard !== this.props.queryDataForcard) {
            this.setState({queryDataForcard :this.props.queryDataForcard })
        }
    }

    onSlabSelectionChange = e => {
        e.preventDefault();
        let currentSlabs = this.state.selectedSlabs;
        let selectedSlabsPublish = this.state.selectedSlabsPublish;
        let slabName = e.target.getAttribute("data-slab-name");
        let currentSpotRate = e.target.getAttribute("data-Charges-slab-rate-value")
        let validTillWeight = e.target.getAttribute("data-Charges-rate-valid-till-weight")
        let minWeight = e.target.getAttribute("data-Charges-rate-min-weight")
        let slabIndex = e.target.getAttribute("data-Charges-rate-index")
        let dataSlab = e.target.getAttribute("data-Charges-rate-dataslab")
        this.setState({
            itemIndex: slabIndex,
            dataSlab: dataSlab
        }, () => {
            if (this.state.dataSlab === "Economy") {
                this.setState({
                    item: this.state.SlabData
                })
            }
            if (this.state.dataSlab === "Express") {
                this.setState({
                    item: this.state.SlabData
                })
            }
            if (this.state.dataSlab === "Premium") {
                this.setState({
                    item: this.state.SlabData
                })
            }


        })
        if (currentSpotRate === 0 || currentSpotRate === "Infinity") {
            toast.info('Sorry This Slab Is not available yet,Please Select another Slab', {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (currentSlabs.includes(e.target.getAttribute("data-slab-name")) === true) {
            // let index = currentSlabs.indexOf(e.target.getAttribute("data-slab-name"));
            let index = slabIndex;
            currentSlabs.splice(index, 1);
            selectedSlabsPublish.splice(index, 1);
        } else {
            currentSlabs = []
            selectedSlabsPublish = []
            currentSlabs.push(e.target.getAttribute("data-slab-name"));
            selectedSlabsPublish.push(e.target.getAttribute("data-Charges-slab-rate-value"));
        }
        this.setState({
            selectedSlabs: currentSlabs,
            currentSlabName: slabName,
            currentSpotRate: currentSpotRate,
            selectedSlabsPublish: selectedSlabsPublish,
            validTillWeight: validTillWeight,
            minWeight: minWeight
        }, () => {
            // alert(JSON.stringify(this.state.currentSpotRate))

            let slabRowDataPacket = {};
            // slabRowDataPacket.rawData = this.state.item;
            // slabRowDataPacket.selectedSlabs = this.state.selectedSlabs
            // // slabRowDataPacket.indexAt = this.state.itemIndex
            // slabRowDataPacket.indexAt = slabIndex
            // slabRowDataPacket.selectedWeightSlab = slabName
            // slabRowDataPacket.spotRate = currentSpotRate
            // slabRowDataPacket.validTillWeight = validTillWeight
            // slabRowDataPacket.minWeight = minWeight
            // slabRowDataPacket.originCode = this.state.originCode
// console.log("abcd:" , slabRowDataPacket)
//             this.props.onSlabChange(slabRowDataPacket);

        });
    }

    BookProcess = ()=>{
        debugger
        let SelectedWightSlab =this.props.SelectedWightSlab !== "" && this.props.SelectedWightSlab !== undefined ? this.props.SelectedWightSlab :undefined
        let frightAmount = this.props.frightAmount !== undefined ? this.props.frightAmount : 0
        let currentSelelctedSlab = this.state.selectedSlabs !== undefined ? this.state.selectedSlabs[0] : 0
        if (SelectedWightSlab !== undefined){
            let SelectedSlabAmount = this.state.currentSpotRate * SelectedWightSlab
            if(frightAmount <= SelectedSlabAmount){
                this.setState({slabWeightLarge:true})
            }else {
                toast.info('This Slab is not available for your selected Weight', {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }
    }


    handleSubmit = () =>{
        debugger
        let queryData = this.props.queryDataForcard !== undefined ? this.props.queryDataForcard : ""
        let WeightObj = {}
        // WeightObj.gross = queryData && queryData.weight && queryData.weight.gross ? queryData.weight.gross : "" ;
        WeightObj.gross = this.state.gross ;
        // WeightObj.volume = queryData && queryData.weight && queryData.weight.gross ? queryData.weight.volume : "" ;
        WeightObj.volume = this.state.volume ;
        // WeightObj.chargeable = currentSelelctedSlab;
        WeightObj.chargeable = this.state.TotalChargeable;

        if(queryData !== ""){
            queryData.weight = WeightObj
            queryData.totalPieces = this.state.NoPieces
            queryData.density = this.state.calculatedDensity
        }
        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("customer").length > 0){
            this.props.getCharges(queryData)
            localStorage.setItem('queryData', JSON.stringify(queryData))
            this.props.sendDataToQuery(queryData)

        }else {
            localStorage.setItem('queryData', JSON.stringify(queryData))
            this.props.getCharges(queryData)
        }
    }
    render() {
        const {data,SelectedWightSlab,queryDataForcard} = this.props
        const DataItem = data && data.row ? data.row : {}
        const DataItemConverted = data && data.row && data.row.convertedData ? data.row.convertedData : {}
        return (
            <>
                <MultiSlabWeight
                    modalLarge={this.state.slabWeightLarge}
                    setmodal={values => values === false ? this.setState({slabWeightLarge: false}) : ""}
                    // volume = {queryData && queryData.weight && queryData.weight.volume ? queryData.weight.volume : ""}
                    minWeight ={this.state.minWeight}
                    validTillWeight ={this.state.validTillWeight}
                    recivedContactInfo = {value =>{
                        this.setState({
                            gross:value.gross,
                            volume:value.volume,
                            NoPieces:value.NoPieces,
                            calculatedDensity:value.calculatedDensity,
                            TotalChargeable:value.TotalChargeable,
                        },()=>{
                            this.handleSubmit()
                        })
                    }}
                />

                {data && data.row.converted === true ?
                    <tr>
                        <td style={{verticalAlign: "middle"}} >{data.row && data.row.convertedCurrency  ? data.row.convertedCurrency + " " : "INR"}</td>
                        <td
                            style={{verticalAlign: "middle",
                                textAlign: "center",
                                backgroundColor: this.state.selectedSlabs.includes("min") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                                color: this.state.selectedSlabs.includes("min") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "min" ? "chnageBack" : undefined}
                            data-slab-name={"min"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItemConverted.w_min)}
                            title={this.createHoverSurchargesInfo(DataItemConverted.w_min, "min")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_min_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(1)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}

                        >
                            {data.row && data.row.w_min && data.row.convertedData.w_min ? data.row.convertedData.w_min : "-"}

                        </td>


                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("normal") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("normal") && this.state.dataSlab === DataItem.scr ? "white" : "black"
                        }}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "normal" ? "chnageBack" : undefined}
                            data-slab-name={"normal"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItemConverted.w_normal)}
                            title={this.createHoverSurchargesInfo(DataItemConverted.w_normal, "normal")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_normal_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_normal_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_min && data.row.convertedData.w_normal ? data.row.convertedData.w_normal : "-"}</td>

                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("45") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("45") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "45" ? "chnageBack" : undefined}
                            data-slab-name={"45"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItemConverted.w_45)}
                            title={this.createHoverSurchargesInfo(DataItemConverted.w_45, "45")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_45_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_normal_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}>
                            {data.row && data.row.w_min && data.row.convertedData.w_45 ? data.row.convertedData.w_45 : "-"}</td>

                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("100") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("100") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "100" ? "chnageBack" : undefined}
                            data-slab-name={"100"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItemConverted.w_100)}
                            title={this.createHoverSurchargesInfo(DataItemConverted.w_100, "100")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_100_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_45_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}>
                            {data.row && data.row.w_min && data.row.convertedData.w_100 ? data.row.convertedData.w_100 : "-"}</td>

                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("250") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("250") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "250" ? "chnageBack" : undefined}
                            data-slab-name={"250"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItemConverted.w_250)}
                            title={this.createHoverSurchargesInfo(DataItemConverted.w_250, "250")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_250_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_100_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_min && data.row.convertedData.w_250 ? data.row.convertedData.w_250 : "-"}</td>

                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("300") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("300") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "300" ? "chnageBack" : undefined}
                            data-slab-name={"300"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItemConverted.w_300)}
                            title={this.createHoverSurchargesInfo(DataItemConverted.w_300, "300")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_300_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_250_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_min && data.row.convertedData.w_300 ? data.row.convertedData.w_300 : "-"}</td>

                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("500") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("500") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "500" ? "chnageBack" : undefined}
                            data-slab-name={"500"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItemConverted.w_500)}
                            title={this.createHoverSurchargesInfo(DataItemConverted.w_500, "500")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_500_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_300_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_min && data.row.convertedData.w_500 ? data.row.convertedData.w_500 : "-"}</td>


                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("1000") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("1000") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "1000" ? "chnageBack" : undefined}
                            data-slab-name={"1000"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItemConverted.w_1000)}
                            title={this.createHoverSurchargesInfo(DataItemConverted.w_1000, "1000")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(1000)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_500_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_min && data.row.convertedData.w_1000 ? data.row.convertedData.w_1000 : "-"}</td>

                        <td style={{verticalAlign: "middle"}}>{data.row && data.row.validityEndDate ? convertDateISOToCustomiseFormat(data.row.validityEndDate) : "-"}</td>

                    </tr>
                    :
                    <tr>
                        <td style={{verticalAlign: "middle"}} >{data.row && data.row.currency ? data.row.currency : "-"}</td>
                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("min") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("min") && this.state.dataSlab === DataItem.scr ? "white" : "black"

                        }}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "min" ? "chnageBack" : undefined}
                            data-slab-name={"min"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItem.w_min)}
                            title={this.createHoverSurchargesInfo(DataItem.w_min, "min")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_min_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(1)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_min ? data.row.w_min : "-"}
                        </td>

                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("normal") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("normal") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "normal" ? "chnageBack" : undefined}
                            data-slab-name={"normal"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItem.w_normal)}
                            title={this.createHoverSurchargesInfo(DataItem.w_normal, "normal")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_normal_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_normal_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_normal ? data.row.w_normal : "-"}</td>


                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("45") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("45") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "45" ? "chnageBack" : undefined}
                            data-slab-name={"45"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItem.w_45)}
                            title={this.createHoverSurchargesInfo(DataItem.w_45, "45")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_45_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_normal_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_45 ? data.row.w_45 : "-"}</td>



                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("100") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("100") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "100" ? "chnageBack" : undefined}
                            data-slab-name={"100"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItem.w_100)}
                            title={this.createHoverSurchargesInfo(DataItem.w_100, "100")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_100_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_45_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_100 ? data.row.w_100 : "-"}</td>


                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("250") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("250") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "250" ? "chnageBack" : undefined}
                            data-slab-name={"250"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItem.w_250)}
                            title={this.createHoverSurchargesInfo(DataItem.w_250, "250")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_250_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_100_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_250 ? data.row.w_250 : "-"}</td>


                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("300") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("300") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "300" ? "chnageBack" : undefined}
                            data-slab-name={"300"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItem.w_300)}
                            title={this.createHoverSurchargesInfo(DataItem.w_300, "300")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_300_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_250_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_300 ? data.row.w_300 : "-"}</td>

                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("500") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("500") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "500" ? "chnageBack" : undefined}
                            data-slab-name={"500"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItem.w_500)}
                            title={this.createHoverSurchargesInfo(DataItem.w_500, "500")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(DataItem.w_500_breakeven)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_300_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_500 ? data.row.w_500 : "-"}</td>

                        <td style={{verticalAlign: "middle",
                            textAlign: "center",
                            backgroundColor: this.state.selectedSlabs.includes("1000") && this.state.dataSlab === DataItem.scr ? "#22740a" : "",
                            color: this.state.selectedSlabs.includes("1000") && this.state.dataSlab === DataItem.scr ? "white" : "black"}}
                            className={SelectedWightSlab !== "" && SelectedWightSlab === "1000" ? "chnageBack" : undefined}
                            data-slab-name={"1000"}
                            data-Charges-slab-rate-value={parseFloatValue(DataItem.w_1000)}
                            title={this.createHoverSurchargesInfo(DataItem.w_1000, "1000")}
                            data-Charges-rate-valid-till-weight={parseFloatValue(1000)}
                            data-Charges-rate-min-weight={parseFloatValue(DataItem.w_500_breakeven)}
                            data-Charges-rate-index={0}
                            data-Charges-rate-dataslab={DataItem.scr}
                            onClick={this.onSlabSelectionChange}
                        >
                            {data.row && data.row.w_1000 ? data.row.w_1000 : "-"}</td>

                        <td style={{verticalAlign: "middle"}}>{data.row && data.row.validityEndDate ? convertDateISOToCustomiseFormat(data.row.validityEndDate) : "-"}</td>
                        <td hidden={false} className={"text-primary"}
                            aria-disabled = {Array.isArray(this.state.selectedSlabs) && this.state.selectedSlabs.length <=0  && parseFloatValue(this.state.currentSpotRate) === 0}
                            onClick={e => {
                                e.preventDefault()

                                let slabs = this.state.selectedSlabs
                                if (Array.isArray(slabs) && slabs.length >= 1 && parseFloatValue(this.state.currentSpotRate) !== 0 && this.props.SelectedWightSlab !== slabs[0]) {
                                    this.BookProcess(e)
                                }else {
                                    toast.info('Please Select Valid Slab', {
                                        position: "top-left",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }
                            }}><a className="btn border-cir book-btn text-white"> Book</a>
                        </td>
                    </tr>

                }



            </>
        );
    }

}

const mapStateToProps = (state) => {

    const {loading} = state.advanceSearchReducer;

    return {loading}
};

export default connect(mapStateToProps, {
    getCharges
})(SelectedDiffrentSlabCard);

