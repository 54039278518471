import React, {Component, Fragment} from "react";
import {Row, Card, CardTitle, Label, Input, Button, Modal, ModalHeader, ModalBody, FormGroup} from "reactstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IntlMessages from "../../../helpers/IntlMessages";

import SimpleReactValidator from "simple-react-validator";


import {Field, Form, Formik} from "formik";




import {withRouter} from "react-router";
import {Colxx} from "../../../components/common/CustomBootstrap";
import {PAGE_PATHS} from "../../../util/PageRoute";

class ContactDeatils extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactEmail:"",
            contactNumber:"",
            phoneNo: "",
            modalLarge: false,
            message: "",
            openLogin: false,
            Compalsari: false,

        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {

        if (this.props.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            },()=>{
                // alert(this.props.modalLarge)
            })
        }
        if (this.props.Compalsari) {
            this.setState({
                Compalsari: this.props.Compalsari
            })
        }

    }

    toggleLarge = () => {

        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if (this.state.modalLarge === false){
                this.props.setmodal(false)
            }
        });
    };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }

    validateEmail = (value) => {

        if (!value) {
            toast.error("Please enter your email address", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {

            toast.error("Invalid email address", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                contactEmail:""
            })
        }

    }

    validatePhonNumber = (value) => {

        if (!value) {
            toast.error("Please enter your Phone Number", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^\d{10}$/.test(value)) {

            toast.error("Phone Number Should be 10 digit", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                contactNumber:""
            })
        }

    }




    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }

        if (this.props.Compalsari && this.props.Compalsari !== prevProps.Compalsari) {
            this.setState({
                Compalsari: this.props.Compalsari
            })
        }


    }

    handleChange = selectedOption => {

        this.setState({selectedOption});

    };

    handleSubmit =()=>{
        if (this.props.couponCompulsari === true){
           if(this.mandatoryValidations.allValid() && this.state.contactEmail !== "")
           {
               let data = {
                   contactEmail:this.state.contactEmail,
                   contactNumber:this.state.contactNumber !== "" ? this.state.contactNumber : "",
               }
               localStorage.setItem("ContactDeatails",JSON.stringify(data))
               this.props.recivedContactInfo(data)
               this.setState({
                   modalLarge: false
               })
               this.props.setmodal(false)
           }else {
               this.mandatoryValidations.showMessages();
               this.forceUpdate();
           }
        }
        else{
            let data = {
                contactEmail:this.state.contactEmail,
                contactNumber:this.state.contactNumber !== "" ? this.state.contactNumber : "",
            }
            localStorage.setItem("ContactDeatails",JSON.stringify(data))
            this.props.recivedContactInfo (data)
            this.setState({
                modalLarge: false
            },()=>{

            })
            this.props.setmodal(false)

        }


    }
    handleSubmitOther =()=>{
        let data = {
            contactEmail:this.state.contactEmail,
            contactNumber:this.state.contactNumber !== "" ? this.state.contactNumber : "",
        }
        localStorage.setItem("ContactDeatails",JSON.stringify(data))
        this.props.recivedContactInfo (data)
        this.setState({
            modalLarge: false
        },()=>{
        })
        this.props.setmodal(false)
    }
    toggleLarge1 = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if (this.state.modalLarge === false){
                this.props.setmodal(false)
            }
        });
    };


    render() {
        const {password, email, repassword, customerName, gstNo, phoneNo, selectedState} = this.state;
        const initialValues = {email, password, repassword, customerName, gstNo, phoneNo, selectedState};

        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Modal className="sizechange pop-login"
                    isOpen={this.state.modalLarge}
                    size="md"
                    toggle={this.toggleLarge}
                >

                        <>
                            <ModalHeader toggle={this.handleSubmitOther} className="" style={{padding: "0"}}>

                            </ModalHeader>
                            <ModalBody className="padding-0">
                                {/*<div className="back-img" style={{padding: "0px;"}}>*/}
                                {/*    <div className="auth-card1">*/}
                                {/*        <div className="cont-forms form-side">*/}
                                {/*            <h5 className="mb-5  text-center cont-titles" style={{fontWeight: "bold",}} ><span>Contact Information</span></h5>*/}
                                {/*            <form className="av-tooltip tooltip-label-bottom">*/}
                                {/*                <div className="contact-popup">*/}
                                {/*                    <div className="form-group">*/}
                                {/*                        <label htmlFor="contactEmail" className="origin-label">{this.state.Compalsari === true ?  "Email *"  : "Email"}</label>*/}
                                {/*                        <input placeholder="Enter Email ID" type="text"*/}
                                {/*                               className="form-control inpt"*/}
                                {/*                               name="contactEmail"*/}
                                {/*                               value={this.state.contactEmail}*/}
                                {/*                               onBlur={e=>{this.validateEmail(e.target.value)}}*/}
                                {/*                               onChange={e=>{this.setState({contactEmail: e.target.value})} }*/}
                                {/*                               />*/}
                                {/*                    </div>*/}
                                {/*                    <p style={{color:"#ff1515",fontWeight:"700"}}>{this.mandatoryValidations.message(*/}
                                {/*                        "Email",*/}
                                {/*                        this.state.contactEmail,*/}
                                {/*                        "required",*/}
                                {/*                    )}</p>*/}
                                {/*                </div>*/}
                                {/*                <div className="contact-popup">*/}
                                {/*                    <div className="form-group">*/}
                                {/*                        <label htmlFor="contactNumber" className="origin-label">{this.state.Compalsari === true ?  "Contact Number *"  : "Contact Number"}</label>*/}
                                {/*                        <input placeholder="Enter Contact No." type="text"*/}
                                {/*                               className="form-control inpt"*/}
                                {/*                               name="contactNumber"*/}
                                {/*                               onBlur={e=>{this.validatePhonNumber(e.target.value)}}*/}
                                {/*                               value={this.state.contactNumber}*/}
                                {/*                               onChange={e=>{this.setState({contactNumber: e.target.value})} }/>*/}
                                {/*                    </div>*/}
                                {/*                    <p style={{color:"#ff1515",fontWeight:"700"}}>{this.mandatoryValidations.message(*/}
                                {/*                        "Contact",*/}
                                {/*                        this.state.contactNumber,*/}
                                {/*                        "required",*/}
                                {/*                    )}</p>*/}
                                {/*                </div>*/}
                                
                                {/*                <div className="form-group mt-4">*/}
                                
                                {/*                    <button className="book-btn contact-btnss btn " style={{borderRadius: "50px;"}}*/}
                                {/*                    onClick={e=>{e.preventDefault()*/}
                                {/*                    this.handleSubmit()*/}
                                {/*                    }}>*/}
                                {/*                        Submit*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}
                                {/*            </form>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="auth-card card">
                                            <div className="form-side text-center">
                                                <img className="mb-5" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.935186642892496-1623842844604.png" alt="logo"/>
                                                <h5 className="mb-5  text-center cont-titles" style={{fontWeight: "bold",}} ><span>Contact Information</span></h5>
                                                <div className="av-tooltip tooltip-label-bottom login-margin">
                                                        <div className="form-group has-float-label">
                                                            <label htmlFor="contactEmail" className="red">{this.props.couponCompulsari === true ?  "Email *"  : "Email"}</label>
                                                            <input placeholder="Enter Email ID" type="text"
                                                                   className="form-control inpt"
                                                                   name="contactEmail"
                                                                   value={this.state.contactEmail}
                                                                   onBlur={e=>{this.validateEmail(e.target.value)}}
                                                                   onChange={e=>{this.setState({contactEmail: e.target.value})} }
                                                            />
                                                        </div>
                                                        <p style={{color:"#ff1515",fontWeight:"700",fontSize:'13px',}}>{this.mandatoryValidations.message(
                                                            "Email",
                                                            this.state.contactEmail,
                                                            "required",
                                                        )}</p>
                                                        <div className="form-group has-float-label">
                                                            <label htmlFor="contactNumber" className="red">{this.state.Compalsari === true ?  "Contact Number"  : "Contact Number"}</label>
                                                            <input placeholder="Enter Contact No." type="text"
                                                                   className="form-control inpt"
                                                                   name="contactNumber"
                                                                   onBlur={e=>{this.validatePhonNumber(e.target.value)}}
                                                                   value={this.state.contactNumber}
                                                                   onChange={e=>{this.setState({contactNumber: e.target.value})} }/>
                                                        </div>
                                                        {/*<p style={{color:"#ff1515",fontWeight:"700"}}>{this.mandatoryValidations.message(*/}
                                                        {/*    "Contact",*/}
                                                        {/*    this.state.contactNumber,*/}
                                                        {/*    "required",*/}
                                                        {/*)}</p>*/}

                                                        <div className="mt-3 text-left">
                                                            <button className="book-btn contact-btnss btn " style={{borderRadius: "50px;"}}
                                                                    onClick={e=>{e.preventDefault()
                                                                        this.handleSubmit()
                                                                    }}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                        <div className="text-center mt-2 pop_text">
                                                            <p><sup>*</sup> For special Discount & Offers please provide you contact Details or close the icon</p>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </ModalBody>
                        </>
                </Modal>
            </>
        )}};

const mapStateToProps = (state) => {
    const {user, loading, message, LoginMessage, closeModal} = state.authUser;
    return {user, loading, message, LoginMessage, closeModal};
}

const contactDeatilsWithRoute = withRouter(ContactDeatils);

export default connect(
    mapStateToProps,
    {}
)(contactDeatilsWithRoute);
