import React, { Component} from "react";
import { Modal, ModalHeader, ModalBody, } from "reactstrap";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import { CONTRACT_RATE_DATA, STORE_QUERY_API, GET_CHARGES_API, GUEST_CUSTOMER_DATA,STORE_QUERY_RATES_API, STORE_QUERY_API_AIR, STORE_QUERY_API_OCEAN, STORE_QUERY_RATES_API_OCEAN, GET_CHARGES_API_OCEAN } from "../../../constants";
import RestClient from "../../../util/RestClient";
import { PAGE_PATHS } from "../../../util/PageRoute";

import { history } from "../../../helpers/history"

import {
    getBaseCurrency,
    getCustomerBranchId,
    getCustomerCurrency,
    getCustomerId,
    getCustomerUserBranchIDData,
    getLoggedInUserEmail,
} from "../../../util/CommonUtility";
import {
    getCharges,
    sendQuery,
} from "../../../redux/advanceSearch/advancSearchAction";
class BookSlabPopupForOcean extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSlabSelected: false,
            modalLarge1: false,
            queryNo: "",
            StoredRateData: []


        };
        this.mandatoryValidations = new SimpleReactValidator({ autoForceUpdate: this });
    }


    componentDidMount() {

        if (this.props.modalLarge1) {
            this.setState({
                modalLarge1: this.props.modalLarge1
            })
        }
        if (localStorage.getItem("already_registered") !== "true") {
        }

        if (
            localStorage.getItem("queryData") !== null &&
            localStorage.getItem("queryData").length > 0
        ) {
            let queryData = JSON.parse(localStorage.getItem("queryData"));
            this.setState({ mainQueryData: queryData }, () => {

            });
        }


    }
    getCustomerSetTimeOut = () => {
        this.getDummyCustomerData().then()
    }
    getDummyCustomerData = async (queryData) => {
        this.GetGuestCustomerData().then(responce => {
            let customerData = responce && responce.result ? responce.result : undefined;
            if (customerData !== undefined) {
                let customerId = customerData.customerData && customerData.customerData._id ? customerData.customerData._id : "";
                let customerEmail = customerData.customerBranchData && customerData.customerBranchData.emails ? customerData.customerBranchData.emails : "";
                let baseCurrency = customerData.csBuddyBranchData && customerData.csBuddyBranchData.currency ? customerData.csBuddyBranchData.currency : "";
                let customerCurrency = customerData.customerData && customerData.customerData.custCurrency ? customerData.customerData.custCurrency : "";
                let customerBranchId = customerData.customerBranchData && customerData.customerBranchData._id ? customerData.customerBranchData._id : null;
                let customerCategory = customerData.customerBranchData && customerData.customerBranchData.customerCategory ? customerData.customerBranchData.customerCategory : "A";
                let custType = customerData.customerData && customerData.customerData.custType ? customerData.customerData.custType : "Shipper";
                let customerRole = customerData.customerData && customerData.customerData.customerRole ? customerData.customerData.customerRole : "Shipper";
                let csBuddy = customerData && customerData.customerBranchData && customerData.customerBranchData.csBuddy ? customerData.customerBranchData.csBuddy : customerData && customerData.customerBranchData && customerData.customerBranchData.csBuddy2 ? customerData.customerBranchData.csBuddy2 : "";
                let pricingHead = customerData && customerData.customerBranchData && customerData.customerBranchData.pricingHead ? customerData.customerBranchData.pricingHead : "";
                let salesPerson = customerData && customerData.customerBranchData && customerData.customerBranchData.salesPerson ? customerData.customerBranchData.salesPerson : "";
                let financePerson = customerData && customerData.customerBranchData && customerData.customerBranchData.financePerson ? customerData.customerBranchData.financePerson : "";
                /*let UserBranchId = customerData && customerData.csBuddyData && customerData.csBuddyData.branchId && customerData.csBuddyData.branchId._id ? customerData.csBuddyData.branchId._id : "";*/
                let UserBranchId = customerData && customerData.customerBranchData && customerData.customerBranchData && customerData.customerBranchData.companyBranchId ? customerData.customerBranchData.companyBranchId : "";

                this.setState({
                    customerId: customerId,
                    customerCategory: customerCategory,
                    custType: custType,
                    customerRole: customerRole,
                    baseCurrency: baseCurrency,
                    customerCurrency: customerCurrency,
                    customerBranchId: customerBranchId,
                    csBuddy: csBuddy,
                    pricingHead: pricingHead,
                    salesPerson: salesPerson,
                    financePerson: financePerson,
                    UserBranchId: UserBranchId,
                    customerEmail:customerEmail
                },()=>{
                    queryData.customerId = this.state.customerId
                    queryData.customerBranchId = this.state.customerBranchId
                    queryData.customerCurrency = this.state.customerCurrency
                    queryData.baseCurrency =  this.state.baseCurrency
                    queryData.branchId =  this.state.UserBranchId
                    this.createQuery(queryData)
                })

            }


        })

    };
    GetGuestCustomerData = async (dataForServer) => {
        return await RestClient({
            url: GUEST_CUSTOMER_DATA,
            method: "POST",
            data: dataForServer
        });
    }
    toggleLarge = () => {
        this.setState(prevState => (
            {
                modalLarge1: !prevState.modalLarge1
            }), () => {
                if (this.state.modalLarge1 === false) {
                    this.props.setmodal1(false)
                }
            });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge1 && this.props.modalLarge1 !== prevProps.modalLarge1) {
            this.setState({
                modalLarge1: this.props.modalLarge1
            })
        }


    }
    SaveDataLogin = () => {
        let queryData = JSON.parse(localStorage.getItem("queryData"));
        queryData.shipperConsigneeData = this.state.shipperConsigneeData;
            let customerId = getCustomerId();
            let customerBrancId = getCustomerBranchId();
            let customerCurrency =  getCustomerCurrency();
            let baseCurrency = getBaseCurrency();
            let branchId =  getCustomerUserBranchIDData();
            queryData.customerId = customerId ? customerId :this.state.customerId
            queryData.customerBranchId = customerBrancId ?customerBrancId :this.state.customerBranchId
            queryData.customerCurrency = customerCurrency ?customerCurrency :this.state.customerCurrency
            queryData.baseCurrency =  baseCurrency ? baseCurrency :this.state.baseCurrency
            queryData.branchId =  branchId ? branchId : this.state.UserBranchId


        let containerType = []
        if(this.props.containerName === "LCL") {
            queryData.shipmentMode = "LCL"
            queryData.containerDetails = []
        }else{
            containerType = [{
                name: this.props.containerName,
                count:  queryData && queryData.noOfContainers  ? queryData.noOfContainers :"1",
            }]
        }

        queryData.containerType = containerType
        queryData.commercialInvoiceUrl = this.state.commercialInvoiceUrl;
        queryData.packingListUrl = this.state.packingListUrl;
        let EmailArry = [];
        let customerEmails =  getLoggedInUserEmail();
        let customerEmail = customerEmails ? customerEmails :this.state.customerEmail
        EmailArry.push(customerEmail)
        if (!localStorage.getItem("customer") === false) {
            let userData = JSON.parse(localStorage.getItem("customer"));
            let csBuddy = "";
            let pricingHead = "";
            let salesPerson = "";
            let financePerson = "";
            csBuddy =
                userData &&
                    userData.result &&
                    userData.result.csBuddyData &&
                    userData.result.csBuddyData._id
                    ? userData.result.csBuddyData._id
                    : "";

            pricingHead =
                userData &&
                    userData.result &&
                    userData.result.customerBranchData &&
                    userData.result.customerBranchData.pricingHead
                    ? userData.result.customerBranchData.pricingHead
                    : "";

            salesPerson =
                userData &&
                    userData.result &&
                    userData.result.customerBranchData &&
                    userData.result.customerBranchData.salesPerson
                    ? userData.result.customerBranchData.salesPerson
                    : "";

            financePerson =
                userData &&
                    userData.result &&
                    userData.result.customerBranchData &&
                    userData.result.customerBranchData.financePerson
                    ? userData.result.customerBranchData.financePerson
                    : "";
            queryData.csBuddy = csBuddy;
            queryData.pricingHead = pricingHead;
            queryData.salesPerson = salesPerson;
            queryData.financePerson = financePerson;
            queryData.customerMails = EmailArry;
        }
        let ContractmainData = {};
        ContractmainData.customerId = queryData.customerId;
        ContractmainData.customerBranchId = queryData.customerBranchId;

        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
            this.addContractQueryDataAPI(ContractmainData).then((responce) => {
                if (responce.success && responce.result) {
                    let Data = responce.result;
                    let contractNo = Data && Data.contractNo;
                    this.setState({
                        contractNo: contractNo
                    }, () => {
                        this.createQuery(queryData)
                    });
    
                }else{
                    this.createQuery(queryData)
                }
            })
        }else{
            this.getDummyCustomerData(queryData).then()

        }
        



    };
    createQuery = (queryData) =>{
        this.sendQuery(queryData).then((responce) => {
            if (responce.success) {
                let savedRate = responce && responce.result ? responce.result : undefined;
                let quoteId = savedRate && savedRate._id ? savedRate._id : ""
                this.setState({
                    quoteId: quoteId
                },()=>{
                    localStorage.setItem('queryData', JSON.stringify(queryData))
                })
                queryData.contractNo = this.state.contractNo ?this.state.contractNo:""
                queryData.quoteId = quoteId

                this.getRatesQuery(queryData).then((res) => {
                    if (res.success){
                        let getRatesQueryData = res && res.result ? res.result : undefined;
                        let shipmentMode = res && res.result2 && res.result2.shipmentMode ? res.result2.shipmentMode : undefined;
                        this.setState({
                            shipmentMode: shipmentMode,
                            getRatesQueryData: getRatesQueryData
                        }, () => {
                            localStorage.setItem(
                                "AllCharges",
                                JSON.stringify(this.state.getRatesQueryData)
                            )
                            let rateObj = {
                                quoteId: this.state.quoteId,
                                revertData: this.state.getRatesQueryData,
                                shipmentMode: this.state.shipmentMode,
                            }
                            this.StoreRatesApi(rateObj).then((responce) => {
                                if (responce.success) {
                                    let savedRate = responce && responce.result ? responce.result : undefined;
                                    history.push(PAGE_PATHS.QUERY_RATES_VIEW + "/Ocean/" + this.state.quoteId)
                                    window.location.reload();
                                }
                                this.props.setmodal1(false)
                            })

                        })
                    }else {
                        toast.error("Rates Not Available for Selected Slab", {
                            position: "top-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.props.setmodal1(false)
                    }
                })
            }

        })
    }
    StoreRatesApi = async (data) => {
        return await RestClient({
            url: STORE_QUERY_RATES_API_OCEAN,
            method: "POST",
            data: data,
        });
    }
    getRatesQuery = async (data) => {
        return await RestClient({
            url: GET_CHARGES_API_OCEAN,
            method: "POST",
            data: data,
        });
    };
    sendQuery = async (data) => {
        return await RestClient({
            url: STORE_QUERY_API_OCEAN,
            method: "POST",
            data: data,
        });
    };
    addContractQueryDataAPI = async (params) => {
        return await RestClient({
            url: CONTRACT_RATE_DATA,

            method: "POST",

            data: params,
        });
    };
    render() {

        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Modal className=""
                    isOpen={this.state.modalLarge1}
                    size="md"
                    toggle={this.toggleLarge}
                >

                    <>
                        <ModalHeader style={{ borderBottom: '1px solid #ddd' }} toggle={this.toggleLarge} className="">
                            <h3><strong>Booking Slab confirmation</strong></h3>
                        </ModalHeader>
                        <ModalBody className="">
                            <div className="booking-slab">
                                {this.props.containerName === "LCL" ?
                                    <>
                                        <h3 className="pb-3">Converting FCL to LCL</h3>
                                        <h3 className="pb-3">Gross Weight : {this.props.grossSlab}</h3>
                                        <h3 className="pb-3">Volume Wight : {this.props.volumeSlab}</h3>
                                    </>
                                    :
                                    <h3 className="pb-3">{this.props.slabWeight ? `To Book your slab max weight must be ${this.props.slabWeight}` : `Please Select Slab`}</h3>
                                }
                                <button onClick={e => {
                                e.preventDefault()
                                this.SaveDataLogin()

                            }} className="btn slab_btns" style={{width:'auto'}}>Proceed</button>
                            </div>

                            
                        </ModalBody>
                    </>




                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { queryNo, StoredRateData, allCharges } = state.advanceSearchReducer;

    return { queryNo, StoredRateData, allCharges };
};


export default connect(
    mapStateToProps,
    { sendQuery, getCharges }
)(BookSlabPopupForOcean);
