import {Input, Row} from "reactstrap";
import React, {Component, Fragment} from "react";
import RestClient from "../util/RestClient";
import {GET_COUNTRIES_LIST} from "../constants";
import {Colxx} from "./common/CustomBootstrap";

import Select from "react-select";

class   CountryDropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countries: [],
            isLoading: true,
            readOnlys:true,
            error: "",
            selectedCountry: undefined,
            selectionOptions: []
        };
    }

    reloadCountries = async () => {
        let data = await this.fetchCountries();
        try {
            if (data.success && data.result !== undefined && data.result.length > 0) {
               // console.log("In Save countries")
                localStorage.setItem("countriesMaster", JSON.stringify(data));
                this.setState({
                    countries: data.result
                },()=>{
                });
            }


        } catch (e) {
        }
    };


    getSelectedIndex = (label) => {
        for (let i = 0; i < this.state.selectionOptions.length; i++) {
            if (this.state.selectionOptions[i].label === label) {
                // console.log(JSON.stringify(this.state.selectionOptions[i]));
                return this.state.selectionOptions[i]

            }
        }
    }

    fetchCountries = async () => {
        {
            return await RestClient({
                url: GET_COUNTRIES_LIST,
                method: "POST",
                data: null
            });
        }
    };

    componentDidMount() {
        if(this.props.readOnlys){
            this.setState({readOnlys:this.props.readOnlys},
                () => {

                })
        }
        if (
            localStorage.getItem("countriesMaster") === undefined ||
            localStorage.getItem("countriesMaster") === null ||
            localStorage.getItem("countriesMaster").length === 0
        ) {
            this.fetchCountries().then(responce=>{
                try {
                    debugger
                    let data = responce && responce.result !== undefined ? responce.result :[];
                    if (data && data.length > 0) {
                        // console.log("In Save countries")
                        localStorage.setItem("countriesMaster", JSON.stringify(responce));
                        this.setState({
                            countries: data
                        });

                        let selectionOptionsList = []
                        // let data = JSON.parse(localStorage.getItem("countriesMaster")).result !== undefined  ? JSON.parse(localStorage.getItem("countriesMaster")).result : []
                        // let data = this.state.countries
                        for (let i = 0; i < data.length; i++) {
                            let {_id, name, sortName} = data[i]
                            let obj =
                                {
                                    value: _id,
                                    label: name,
                                    countryCode: sortName
                                };
                            selectionOptionsList.push(obj)
                        }

                        this.setState({
                            selectionOptions: selectionOptionsList
                        });

                    }


                } catch (e) {
                }



            })


        } else {
            let selectionOptionsList = []
            let data = JSON.parse(localStorage.getItem("countriesMaster")).result

            for (let i = 0; i < data.length; i++) {
                let {_id, name, sortName} = data[i]
                let obj =
                    {
                        value: _id,
                        label: name,
                        countryCode: sortName
                    };
                selectionOptionsList.push(obj)
            }

            this.setState({
                selectionOptions: selectionOptionsList
            });
        }
    }


    render() {
        // if (
        //     localStorage.getItem("countriesMaster") === undefined ||
        //     localStorage.getItem("countriesMaster") === null
        // ) {
        //     return (
        //         <Input type="select" required>
        //             <option> Please Use Refresh Icon</option>
        //         </Input>
        //     );
        // } else {
            try {
                return (
                    <Fragment>
                        {this.props.isCountry?
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="country"
                                value={this.state.selectionOptions.filter(option => option.value  === this.props.selectedCountry)}
                                readOnly={this.state.readOnlys}
                                onChange={values => {

                                    this.props.handler({
                                        country: values.label,
                                        countryCode: values.countryCode,
                                        countryId: values.value
                                    });
                                }}
                                options={this.state.selectionOptions}
                            />
                            :
                            <div className="mr-2">
                                <Row>
                                    <Colxx xxs="12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="country"
                                            value={this.state.selectionOptions.filter(option => option.value  === this.props.selectedCountry)}
                                            readOnly={this.state.readOnlys}
                                            onChange={values => {

                                                this.props.handler({
                                                    country: values.label,
                                                    countryCode: values.countryCode,
                                                    countryId: values.value
                                                });
                                            }}
                                            options={this.state.selectionOptions}
                                        />
                                    </Colxx>

                                </Row>
                            </div>
                        }

                    </Fragment>
                );
            } catch (e) {
              //  console.log(e)
                return (
                    <Input type="select" required>
                        <option> Please Reload</option>
                    </Input>
                );
            }
        // }


    }

}


export default CountryDropDown;
