import {

    ORIGIN_GET_LIST,
    ORIGIN_GET_LIST_SUCCESS,
    ORIGIN_GET_LIST_ERROR,
    GET_CHARGES,
    GET_CHARGES_SUCCESS,
    GET_CHARGES_ERROR,
    GET_COURIER_CHARGES,
    GET_COURIER_CHARGES_SUCCESS,
    GET_COURIER_CHARGES_ERROR,
    STORE_QUERY,
    STORE_QUERY_SUCCESS,
    STORE_QUERY_ERROR,
    UPDATE_QUERY,
    UPDATE_QUERY_SUCCESS,
    UPDATE_QUERY_ERROR,
    STORE_COURIER_QUERY,
    STORE_COURIER_QUERY_SUCCESS,
    STORE_COURIER_QUERY_ERROR,
    STORE_SELECTED_BOOKING_RATE,
    STORE_SELECTED_BOOKING_RATE_SUCCESS,
    STORE_SELECTED_BOOKING_RATE_ERROR,
    GET_SPOT_BOOKING_RATE,
    GET_SPOT_BOOKING_RATE_SUCCESS,
    GET_SPOT_BOOKING_RATE_SUCCESS_ERROR,
    SPOT_RATE_QUERY_STORE,
    SPOT_RATE_QUERY_STORE_SUCCESS,
    SPOT_RATE_QUERY_STORE_SUCCESS_ERROR,
    SEND_TO_QUERY_FORM,
    STORE_SELECTED_ALL_RATE,
    STORE_SELECTED_ALL_RATE_SUCCESS,
    STORE_SELECTED_ALL_RATE_ERROR,
    OPEN_OFFER_SECTION_CARD,
    SEND_NEXT_MBL_SUCCESS,
    SPOT_RATE_HOUSE_DATA, SEND_CONATAINER_LIST_TO_HBL
} from '../actions';

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
    adsPopupManagerFormValue: {}

};

const advanceSearchReducer =  (state = initState, {type, payload}) => {
    switch (type) {

        case ORIGIN_GET_LIST:
            return { ...state, loading: false };

        case ORIGIN_GET_LIST_SUCCESS:
            return { ...state, loading: true, allOriginItems: payload.data, todoItems: payload.total };

        case ORIGIN_GET_LIST_ERROR:
            return { ...state, loading: true, error: payload };

        case GET_CHARGES:
            return { ...state, loading: false };

        case GET_CHARGES_SUCCESS:
            return { ...state, loading: true,allCharges: payload.result, queryDataForcard:payload.result2, todoItems: payload };

        case GET_CHARGES_ERROR:
            return { ...state, loading: true,allCharges:[], error: payload,RateNotFound : payload };


            case GET_COURIER_CHARGES:
            return { ...state, loading: false };

        case GET_COURIER_CHARGES_SUCCESS:
            return { ...state, loading: true, allCharges: payload.result, queryDataForcard:payload.result2, todoItems: payload };

        case GET_COURIER_CHARGES_ERROR:
            return { ...state, loading: true,allCharges:[], error: payload,RateNotFound : payload };

        case STORE_QUERY:
        return { ...state, loading: false };

        case STORE_QUERY_SUCCESS:
            return { ...state, loading: true, queryNo: payload, todoItems: payload };

        case STORE_QUERY_ERROR:
            return { ...state, loading: true, error: payload };

        case UPDATE_QUERY:
            return { ...state, loading: false };

        case UPDATE_QUERY_SUCCESS:
            return { ...state, loading: true, queryNo: payload, todoItems: payload };

        case UPDATE_QUERY_ERROR:
            return { ...state, loading: true, error: payload };

            case STORE_COURIER_QUERY:
            return { ...state, loading: false };

        case STORE_COURIER_QUERY_SUCCESS:
            return { ...state, loading: true, queryNo: payload, todoItems: payload };

        case STORE_COURIER_QUERY_ERROR:
            return { ...state, loading: true, error: payload };

            case STORE_SELECTED_BOOKING_RATE:
            return { ...state, loading: false };

        case STORE_SELECTED_BOOKING_RATE_SUCCESS:
            return { ...state, loading: true, BookingMassage: payload.result, OpenPerForma :payload.message,container : payload.result2 ? payload.result2 : undefined, BookingDataFromRevert:payload.result && payload.result ? payload.result : "",  todoItems: payload };

            case SEND_NEXT_MBL_SUCCESS:
            return { ...state, loading: true,SendNext:payload};

        case STORE_SELECTED_BOOKING_RATE_ERROR:
            return { ...state, loading: true, error: payload };

        case SEND_CONATAINER_LIST_TO_HBL:
            return { ...state, loading: false , ContainerListForHBL : payload };

            case GET_SPOT_BOOKING_RATE:
            return { ...state, loading: false };

        case GET_SPOT_BOOKING_RATE_SUCCESS:
            return { ...state, loading: true, spotRateCharges: payload.data };

        case GET_SPOT_BOOKING_RATE_SUCCESS_ERROR:
            return { ...state, loading: true, error: payload };

            case SPOT_RATE_HOUSE_DATA:
            return { ...state, loading: false , SpotrateHouseData: payload};

            case SPOT_RATE_QUERY_STORE:
            return { ...state, loading: false };

        case SPOT_RATE_QUERY_STORE_SUCCESS:
            return { ...state, loading: true, sporRateQueryId: payload };

        case SPOT_RATE_QUERY_STORE_SUCCESS_ERROR:
            return { ...state, loading: true, error: payload };


        case STORE_SELECTED_ALL_RATE:
            return { ...state, loading: false };

        case STORE_SELECTED_ALL_RATE_SUCCESS:
            return { ...state, loading: true,StoredRateData:payload };

        case STORE_SELECTED_ALL_RATE_ERROR:
            return { ...state, loading: true, error: payload };


        case SEND_TO_QUERY_FORM:
            return { ...state, loading: true, sporRateDataForForm: payload };

        case OPEN_OFFER_SECTION_CARD:
            return { ...state, loading: true, OpenOffer: payload };

        default: return { ...state };
    }
}
export default advanceSearchReducer;