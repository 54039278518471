import React, {Component, Fragment} from "react";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
import {connect} from "react-redux";
import '../../../assets/css/sass/customeCss.css';
import '../../../assets/css/invoice.css';
import FullLoader from "../../../components/loader/FullLoader";
import {GstDataAction, updateMawb} from "../../../redux/mawbRedux/mawbAction";
import RestClient from "../../../util/RestClient";
import {
    APPLY_COUPONCODE_API,
    BOOKING_MANAGER_MASTER_GET_DATA,
    BOOKING_MANAGER_MASTER_GET_DATA_COURIER,
    BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
    COMAPANY_GET_API,
    CONVERT_CHARGES_CHARGES_LIST,
    CUSTOMER_DATA_FOR_POS,
    GET_AIRLINE_DETAILS_FOR_PERFORMA,
    GET_CHARGES_LIST,
    GET_PROFORMA_DATA,
    GET_PROFORMA_DATA_ROAD,
    GET_PROFORMA_DATA_COURIER,
    GET_PROFORMA_ID_BY_BOOKINGID,
    GET_PROFORMA_ID_BY_BOOKINGID_ROAD,
    GET_PROFORMA_ID_BY_BOOKINGID_COURIER,
    GET_SINGLE_CUSTOMER_BRANCH_DATA,
    INVOICE_GET_PDF,
    PAYMENT_GATEWAY,
    RAZOR_PAY_TEST_KEY,
    RETURN_TO_CONFIREM,
    STORE_INVOICE,
    UPDATE_INVOICE_DATA
} from "../../../constants";
import {toastr} from "react-redux-toastr";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {
    convertDateFromISO,
    convertDateISO, convertDateISOToCustomiseFormat,
    getCurrenctCompanyBranchData,
    getCurrenctCompanyData,
    getCustomerCode,
    getCustomerId,
    getCustomerName,
    getCustomerUserBranchCodeData,
    getLoggedInUserId,
    parseFloatValue
} from "../../../util/CommonUtility";
import {
    previewInvoiceDetail, getInvoiceData, sendtocheked
} from "../../../redux/invoiceRedux/invoiceAction";
import routeRedirectTo from "../../../util/routeWrapper";
import {withRouter} from "react-router";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../assets/css/shiphistory.css";
import "../../../assets/css/invoice.css";
import {checkIsObject} from "../../../util/checkObjectType";
import GstDeatils from "../dashboards/GstDeatils";
import PayLaterPerforma from "../dashboards/PayLaterPerforma";
import AppLayout from "../../../layout/AppLayout";

function Iframe(props) {
    return (
        <div>
            <iframe
                className="iframe-placeholder"
                src={props.src} height={props.height}
                width={props.width}
            />
        </div>
    )
}



class CourierBookingView extends Component {
    totalUsedPiecesByHouses = []
    houseData = [];
    myDivCharges = null;

    constructor(props) {
        super(props);
        this.myDivCharges = React.createRef()
        this.state = {
            showLoader:true,
            awbId: "",
            modal: false,
            NotPaidStatusShow: false,
            editAwbData: {},
            awbPdfUrl: undefined,
            bookingId: "",
            shippingLineName: "",
            DestinationName: "",
            bookingDate: "",
            quoteId: "",
            appliedOncharge: [],
            awbStatus: "",
            BlStatus: "",
            discount: "",
            creditLimit: 0,
            creditDays: 0,
            CustomerStreetaddress: "",
            performaStatus: "",
            finalInvoiceCreated:"",
            finalInvoice:"",
            finalInvoiceId:"",
            finalInvoiceApprovalStatus:"",
            offerModal: false,
            ShowDiscoutColumn: false,
            chargesListingData: [],
            sendData: {},
            customerId: "",
            skipStatus: "",
            customer: "",
            performaInvoiceNumber: "",
            mawbData: {},
            quoteData: {},
            otherChargeDueAgent: "",
            otherChargeDueCarrs: "",
            reciptNumber: "",
            invoiceType:"",
            dimensionCode: "",
            updatedPrice: "",
            PerformaId: "",
            CustomerCIty: "",
            bookingNo: "",
            to1: "",
            by1: "",
            CustomerName: "",
            flightCarr1: "",
            AirlineCode: "",
            CustomerPin: "",
            flightNum1: "",
            flightdate1: "",
            PdfUrl: "",
            to2: "",
            by2: "",
            flightCarr2: "",
            flightNum2: "",
            flightdate2: "",
            houses: [],
            ETA:"",
            ETD:"",
            awbNo: "",
            emailPopUp: false,
            KYCStatus: false,
            invoiceId: "",
            items: {},
            comapny: {},
            invoiceNo: "",
            taxvalue: "",
            paidStatus: "",
            convert: "",
            previewData: {},
            ResponcePayment: {},
            customerAddress: "",
            gstNumber: "",
            AirlineName: "",
            AirlineId: "",
            performaObjectID: "",
            chargesArray: [],
            posData: "",
            chargeAmountAfterDiscount: "",
            otherChargesClearnce: [],
            calculateTaxble: "",
            calculateNetAmount: "",
            chargeAmount: "",
            customerCode: "",
            chargeCgstAmount: "",
            chargeSgstAmount: "",
            chargeIgstAmount: "",
            originName: "",
            noOfContainers:"",
            gstLarge: false,
            offerCode: "",
            roundOff: "",
            csBuddyBranch: "",
            paymentStatusShow:false,
            companyBranchData:"",
            payLarge:false,
            // houses : [],

        };
    }

    jumpToBooking = () => {
        routeRedirectTo.push(PAGE_PATHS.INVOICE_MANAGER);
    };
    jumpToperforma = () => {
        // let data = JSON.parse(localStorage.getItem("customerBranchData")).result
        // let customerBranchData = data && data.customerBranchData
        this.props.history.push(PAGE_PATHS.PERFORMA_INVOICE + "/" + this.state.bookingId)
        if(this.state.GSTNumber !== ""){
            let data ={GSTNumber : this.state.GSTNumber , address : this.state.address}
            this.props.GstDataAction(data)
        }

    };
    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };
    jumpToInvoice = () => {
        routeRedirectTo.push(PAGE_PATHS.INVOICE_MANAGER);
    };

    jumpToFinal = () => {
        this.props.sendtocheked({id: this.state.invoiceId})
        // routeRedirectTo.push(PAGE_PATHS.INVOICE_MANAGER);
    };
    jumpToDashboard = () => {
        // routeRedirectTo.push(PAGE_PATHS.DASHBOARD);
        this.props.history.push(PAGE_PATHS.MAIN_PAGE)
    };

    getCSbuddyBranch = () => {


        this.setState({
            csBuddyBranch: getCurrenctCompanyBranchData()

        });

    };

    toggleCountryCheck = (item) => {
        this.setState({
            modal: !this.state.modal,
            // deleteItem:item
        });
    };

    jumpToDownload()
    {
        if (this.state.PdfUrl !== "") {
            // alert(JSON.stringify(response.result));
            document.location.href = this.state.PdfUrl;
            // this.setState({modal: false})
        } else {
            toast.success("Your PDF is Creating", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // this.storeInvoiceDataFirst().then(r =>{}
            //     // {
            //     // alert(JSON.stringify(r))
            //     //     if (this.state.PdfUrl !== "") {
            //     //         // alert(JSON.stringify(response.result));
            //     //         document.location.href = this.state.PdfUrl;
            //     //     }
            //     // }
            // )
        }
    }

    async getInvoicePdfForQuery(body) {
        return await RestClient({
            url: INVOICE_GET_PDF,
            method: "POST",
            data: body
        });
    }



    jumpToPrevious = () => {
        routeRedirectTo.push(PAGE_PATHS.EDIT_BOOKING + "/" + this.state.bookingId + "/" + this.state.quoteId);
    };
    getbookingData = async (dataForServer) => {
        return await RestClient({
            url: dataForServer && dataForServer.quoteFor === "Road" ? BOOKING_MANAGER_MASTER_GET_DATA_ROAD : dataForServer && dataForServer.quoteFor === "Courier" ? BOOKING_MANAGER_MASTER_GET_DATA_COURIER : BOOKING_MANAGER_MASTER_GET_DATA_COURIER,
            method: "POST",
            data: dataForServer
        })
    };

    getCompanyDeatils = async (dataForServer) => {
        return await RestClient({
            url: COMAPANY_GET_API,
            method: "POST",
            data: dataForServer
        })
    };
    getAirlineDeatils = async (dataForServer) => {
        return await RestClient({
            url: GET_AIRLINE_DETAILS_FOR_PERFORMA,
            method: "POST",
            data: dataForServer
        })
    };

    getChargesListing = async () => {
        return await RestClient({
            url: GET_CHARGES_LIST,
            method: "POST",
            data: {}
        });
    };
    getConvertedFormatCharges = async (dataForServer) => {
        return await RestClient({
            url: CONVERT_CHARGES_CHARGES_LIST,
            method: "POST",
            data: dataForServer
        });
    };
    GetCustomerData = async (dataForServer) => {
        return await RestClient({
            url: CUSTOMER_DATA_FOR_POS,
            method: "POST",
            data: dataForServer
        });
    };

    getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if (this.props.items !== undefined && this.props.items !== prevProps.items) {
            this.setState({items: this.props.items, taxvalue: "Performa Invoice"})
        }
        if(this.state.chargesArray !== undefined && this.state.chargesArray !== prevState.chargesArray && this.state.ShowDiscoutColumn !== true){
            this.calculateCharges()
        }

    }
    handleOnChargesget = (event) => {

        if(this.myDivCharges.current){

            this.myDivCharges.current.scrollIntoView({
                behavior: "smooth",
                // block: "nearest"
            })
        }
    }

    prepareChargesData=()=>{
        let array = [] ;
        let OceanArray = [] ;
        let obj = {}
        const {grossWeight,chargeableWeight,volumeWeight,containerType} = this.state.previewData
        let SellValue = this.state.previewData && this.state.previewData.saleValue ? this.state.previewData.saleValue : 0
        let ChargeableWeight = chargeableWeight

        this.getChargesListing().then(chargesListing => {
            if (checkIsObject(chargesListing)) {
                if (chargesListing.success) {
                    let AllCharges = chargesListing.result
                    AllCharges && AllCharges.map((item, i) => {

                        if (item.name === "Ocean Freight") {
                            if(Array.isArray(containerType) && containerType.length >= 1){
                                debugger
                                containerType.map((data,j)=>{
                                    let ContainerObj = {}
                                    debugger
                                    ContainerObj.altName = data.name;
                                    ContainerObj.x_rate = data.xrate;
                                    ContainerObj.chargeRate = data.rate;
                                    ContainerObj.conatinerName = data.name;
                                    ContainerObj.chargeQty = data.count;
                                    ContainerObj.chargeAmount = data.amount ;
                                    ContainerObj.chargeTaxable = data.amount;
                                    ContainerObj.chargeHsnCode = item.hsnCode;
                                    ContainerObj.chargeName = item.name ;
                                    ContainerObj.chargeType =  item.chargeType;
                                    ContainerObj.calculationUnit = item.calculationBase;
                                    ContainerObj.isTaxable = item.taxability !== "Non-Taxable" ;
                                    array.push(ContainerObj)
                                })
                            }else{
                                obj.altName = "";
                                obj.x_rate = "";
                                obj.chargeHsnCode = item.hsnCode;
                                obj.chargeRate = SellValue;
                                obj.chargeName = item.name ;
                                obj.chargeType =  item.chargeType;
                                obj.calculationUnit = item.calculationBase;
                                obj.chargeQty = ChargeableWeight;
                                obj.chargeAmount = SellValue ;
                                obj.chargeTaxable = SellValue;
                                obj.isTaxable = item.taxability !== "Non-Taxable" ;
                                array.push(obj)
                            }



                        }
                    })

                    // array.push(obj);
                    // array = OceanArray;
                }
            }

            let previewCharges = this.state.previewData && this.state.previewData.otherCharges ? this.state.previewData.otherCharges : undefined;
            if(previewCharges !== undefined){
                if(previewCharges && previewCharges.freightCharges && previewCharges.freightCharges.avail === true){
                    previewCharges.freightCharges.charge && previewCharges.freightCharges.charge.map((item,i)=>{
                        array.push(item);
                    })
                }

                if(previewCharges && previewCharges.originCustomCearence && previewCharges.originCustomCearence.avail === true){
                    previewCharges.originCustomCearence.charge && previewCharges.originCustomCearence.charge.map((item,i)=>{
                        array.push(item);
                    })
                }
                if(previewCharges && previewCharges.destinationCustomCearence && previewCharges.destinationCustomCearence.avail === true){
                    previewCharges.destinationCustomCearence.charge && previewCharges.destinationCustomCearence.charge.map((item,i)=>{
                        array.push(item);
                    })
                }
                if(previewCharges && previewCharges.originPickUpCharges && previewCharges.originPickUpCharges.avail === true){
                    previewCharges.originPickUpCharges.charge && previewCharges.originPickUpCharges.charge.map((item,i)=>{
                        array.push(item);
                    })
                }
                if(previewCharges && previewCharges.destinationDropCharges && previewCharges.destinationDropCharges.avail === true){
                    previewCharges.destinationDropCharges.charge && previewCharges.destinationDropCharges.charge.map((item,i)=>{
                        array.push(item);
                    })
                }

            }
            this.setState({chargesArray:array})


        })



    }

    componentDidMount() {

        let CustomerCode = ""
        let CustomerGstType="Non-Taxable"
        let posData=""
        let data = {}
        if (!localStorage.getItem("customer") === false) {
            let data = JSON.parse(localStorage.getItem("customer")).result
            let tanNo = ""
            let panNo = ""
            let cinNo = ""
            let websiteUrl = ""
            let phoneNo = ""
            let customerCode=data && data.customerData ? data.customerData.customerCode : ""
            let email = ""
            let typeOfBussiness = data && data.customerData && data.customerData.typeOfBussiness ? data.customerData.typeOfBussiness : ""
            let gstNo = data && data.customerBranchData && data.customerBranchData.gstNo ? data.customerBranchData.gstNo : ""
            panNo = data && data.companyData && data.companyData.panNo ? data.companyData.panNo : ""
            cinNo = data && data.companyData && data.companyData.cinNo ? data.companyData.cinNo : ""
            websiteUrl = data && data.companyData && data.companyData.websiteUrl ? data.companyData.websiteUrl : ""
            phoneNo = data && data.companyData && data.companyData.phoneNo ? data.companyData.phoneNo : ""
            email = data && data.companyData && data.companyData.email ? data.companyData.email : ""
            this.setState({panNo: panNo, cinNo: cinNo,customerCode: customerCode,websiteUrl:websiteUrl,phoneNo:phoneNo,email:email,typeOfBussiness:typeOfBussiness,tanNo: tanNo, gstNo: gstNo})
        }

        try {

            let bookingId = window.location.href.split("/")[window.location.href.split("/").length - 1];
            let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
            this.setState({bookingId: bookingId,quoteFor:quoteFor},()=>{
                this.GetProformaId({id:bookingId , quoteFor:quoteFor}).then(idResponce=>{
                    if(idResponce && idResponce.success){
                        let PerformaID = idResponce && idResponce.result && idResponce.result._id ? idResponce.result._id : ""
                        this.setState({PerformaId : PerformaID})
                        this.GetProformaData({id:PerformaID ,  quoteFor:quoteFor}).then(responce=>{
                            if(responce.success && responce.result){
                                let resultProformaData = responce.result;
                                let proformaObj = {}

                                this.setState({payNowCustomerDetails: resultProformaData.customer})

                                proformaObj.consigneeName = resultProformaData.consigneeId && resultProformaData.consigneeId.consigneeName ? resultProformaData.consigneeId.consigneeName : "";
                                proformaObj.shipperName = resultProformaData.shipperId && resultProformaData.shipperId.shipperName ? resultProformaData.shipperId.shipperName : "";
                                proformaObj.shipperDetail = resultProformaData.shipperDetail  ? resultProformaData.shipperDetail : "";
                                proformaObj.consigneeDetail = resultProformaData.consigneeDetail  ? resultProformaData.consigneeDetail : "";
                                proformaObj.invoiceDate = resultProformaData.invoiceDate  ? convertDateISO(resultProformaData.invoiceDate)  : "";

                                proformaObj.invoiceNo = resultProformaData.invoiceNo  ?  resultProformaData.invoiceNo.split("_")[resultProformaData.invoiceNo.split("_").length - 1 ]  : "";
                                proformaObj.invoiceType = resultProformaData.invoiceType  ? resultProformaData.invoiceType : "";
                                proformaObj.baseCurrency = resultProformaData.baseCurrency  ? resultProformaData.baseCurrency : "";
                                proformaObj.destination = "";
                                proformaObj.origin = "";
                                proformaObj.xRate = resultProformaData.xRate  ? resultProformaData.xRate : "";
                                proformaObj.totalPieces = resultProformaData.totalPieces  ? resultProformaData.totalPieces : "";
                                proformaObj.awbNo = resultProformaData.awbNo  ? resultProformaData.awbNo : "";
                                proformaObj.grossWeight = resultProformaData.grossWeight  ? resultProformaData.grossWeight : "";
                                proformaObj.chargeableWeight = resultProformaData.chargeableWeight  ? resultProformaData.chargeableWeight : "";
                                proformaObj.CustomerGstType = resultProformaData.customer && resultProformaData.customer.gstType  ? resultProformaData.customer.gstType : "";
                                proformaObj.customerId = resultProformaData.customer && resultProformaData.customer.customerId  ? resultProformaData.customer.customerId : "";
                                proformaObj.customerBranchId = resultProformaData.customer && resultProformaData.customer.customerBranchId  ? resultProformaData.customer.customerBranchId : "";
                                proformaObj.customer = resultProformaData.customer  ? resultProformaData.customer : "";
                                proformaObj.customerCurrency = resultProformaData.customerCurrency  ? resultProformaData.customerCurrency : "";
                                proformaObj.baseCurrency = resultProformaData.baseCurrency  ? resultProformaData.baseCurrency : "";
                                proformaObj.shippingLine = "";
                                proformaObj.bookingDate = "";
                                proformaObj.jobNo = resultProformaData.jobNo  ? resultProformaData.jobNo : "";
                                proformaObj.otherChargesClearnce = resultProformaData.otherCharges  ? resultProformaData.otherCharges : "";
                                proformaObj.chargeAmount = resultProformaData.totalAmountB  ? resultProformaData.totalAmountB : "";
                                proformaObj.totalAmountB = resultProformaData.totalAmountB  ? resultProformaData.totalAmountB : "";
                                proformaObj.totalAmountC = resultProformaData.totalAmountC  ? resultProformaData.totalAmountC: "";

                                proformaObj.chargeIgstAmount = resultProformaData.igstTotalAmountB  ? resultProformaData.igstTotalAmountB : "";
                                proformaObj.igstTotalAmountB = resultProformaData.igstTotalAmountB  ? resultProformaData.igstTotalAmountB : "";
                                proformaObj.igstTotalAmountC = resultProformaData.igstTotalAmountC  ? resultProformaData.igstTotalAmountC : "";

                                proformaObj.chargeSgstAmount = resultProformaData.sgstTotalAmountB  ? resultProformaData.sgstTotalAmountB : "";
                                proformaObj.chargeCgstAmount = resultProformaData.cgstTotalAmountB  ? resultProformaData.cgstTotalAmountB : "";
                                proformaObj.calculateNetAmount = resultProformaData.taxableTotalAmountB  ? resultProformaData.taxableTotalAmountB : "";
                                proformaObj.taxableTotalAmountB = resultProformaData.taxableTotalAmountB  ? resultProformaData.taxableTotalAmountB : "";
                                proformaObj.taxableTotalAmountC = resultProformaData.taxableTotalAmountC  ? resultProformaData.taxableTotalAmountC : "";

                                proformaObj.calculateTaxble = resultProformaData.taxableTotalAmountB  ? resultProformaData.taxableTotalAmountB : "";
                                proformaObj.paidStatus = resultProformaData.paidStatus  ? resultProformaData.paidStatus : "";
                                proformaObj.skipStatus = resultProformaData.skipStatus  ? resultProformaData.skipStatus : "";
                                proformaObj.invoiceUrl = resultProformaData.invoiceUrl  ? resultProformaData.invoiceUrl : "";

                                this.setState({previewData:proformaObj ,customer:proformaObj.customer,paidStatus:proformaObj.paidStatus,
                                    skipStatus:proformaObj.skipStatus ,CustomerName :getCustomerName(),PdfUrl: proformaObj.invoiceUrl
                                })

                                this.GetCustomerData({id: proformaObj.customerId}).then(customerDataFetch => {
                                    if(customerDataFetch && customerDataFetch.success) {
                                        let customerDataFetchResult = customerDataFetch && customerDataFetch.result ? customerDataFetch.result : {}
                                        let CustomerPhone = customerDataFetchResult && customerDataFetchResult.ownersInfo[0] && customerDataFetchResult.ownersInfo[0].ownerContactNo ? customerDataFetchResult.ownersInfo[0].ownerContactNo : ""
                                        let CustomerName = customerDataFetchResult && customerDataFetchResult.customerName ? customerDataFetchResult.customerName : "";
                                        let customerCode = customerDataFetchResult && customerDataFetchResult.customerCode ? customerDataFetchResult.customerCode : "";
                                        let adressObj = customerDataFetchResult && customerDataFetchResult.branchesInfo[0] && customerDataFetchResult.branchesInfo[0].address ? customerDataFetchResult.branchesInfo[0].address : ""
                                        let GstAddress= adressObj !== "" ? adressObj.streetAddress + " " + adressObj.address + "\n" + adressObj.city + " " + adressObj.state + " " + adressObj.pin + "\n" + CustomerPhone : ""
                                        let customerAddress = GstAddress.split ('\n').map ((item, i) => <p key={i}>{item}</p>);
                                        let custCurrency = customerDataFetchResult && customerDataFetchResult.custCurrency ? customerDataFetchResult.custCurrency : "";
                                        let cinNoo = customerDataFetchResult && customerDataFetchResult.cinNo ? customerDataFetchResult.cinNo : "";
                                        let customerPanNo = customerDataFetchResult && customerDataFetchResult.panNo ? customerDataFetchResult.panNo : "";


                                        this.setState({
                                            gstNumber: customerDataFetchResult && customerDataFetchResult.branchesInfo[0] && customerDataFetchResult.branchesInfo[0].gstdetails && customerDataFetchResult.branchesInfo[0].gstdetails.gstNo ? customerDataFetchResult.branchesInfo[0].gstdetails.gstNo : "",
                                            customerAddress: customerAddress,
                                            posData: customerDataFetchResult && customerDataFetchResult.branchesInfo[0] && customerDataFetchResult.branchesInfo[0].gstdetails && customerDataFetchResult.branchesInfo[0].gstdetails.gstNo ? (customerDataFetchResult.branchesInfo[0].gstdetails.gstNo).substring(0, 2) : "",
                                            CustomerName: CustomerName,
                                            custCurrency: custCurrency,
                                            cinNoo: cinNoo,
                                            customerPanNo:customerPanNo,
                                            customerCode: customerCode,
                                            KYCStatus: customerDataFetchResult && customerDataFetchResult.branchesInfo[0] && customerDataFetchResult.branchesInfo[0].kycStatusAccordingCust !== undefined ? customerDataFetchResult.branchesInfo[0].kycStatusAccordingCust : "",
                                        }, () => {
                                            if (this.state.KYCStatus === false) {
                                                this.setState({
                                                    gstLarge: true
                                                })
                                            }
                                            CustomerGstType = customerDataFetchResult && customerDataFetchResult.branchesInfo[0] && customerDataFetchResult.branchesInfo[0].gstdetails &&  customerDataFetchResult.branchesInfo[0].gstdetails.gstType ? customerDataFetchResult.branchesInfo[0].gstdetails.gstType : "";
                                            posData = customerDataFetchResult && customerDataFetchResult.branchesInfo[0] && customerDataFetchResult.branchesInfo[0].gstdetails && customerDataFetchResult.branchesInfo[0].gstdetails.gstNo ? (customerDataFetchResult.branchesInfo[0].gstdetails.gstNo).substring(0, 2) : ""
                                            CustomerCode = customerDataFetchResult && customerDataFetchResult.customerCode  ? customerDataFetchResult.customerCode : ""
                                            this.setState({customerCode:CustomerCode})

                                        })

                                    }

                                })
                                this.GetCustomerBranchData({id:proformaObj.customerBranchId}).then(customerBranchInfo=>{
                                    if(customerBranchInfo && customerBranchInfo.success) {
                                        let customerBranchData = customerBranchInfo && customerBranchInfo.result ? customerBranchInfo.result:undefined;
                                        if(customerBranchData !== undefined){
                                            let creditLimit = customerBranchData && customerBranchData.creditLimit ? customerBranchData.creditLimit : 0 ;
                                            let creditDays = customerBranchData && customerBranchData.creditDays ? customerBranchData.creditDays : 0 ;
                                            let Streetaddress = customerBranchData && customerBranchData.streetAddress ? customerBranchData.streetAddress : "" ;
                                            let address = customerBranchData && customerBranchData.address ? customerBranchData.address : "" ;
                                            let city = customerBranchData && customerBranchData.city ? customerBranchData.city : "" ;
                                            let state = customerBranchData && customerBranchData.state ? customerBranchData.state : "" ;
                                            let pin = customerBranchData && customerBranchData.state ? customerBranchData.pin : "" ;
                                            let customerGst = customerBranchData && customerBranchData.gstNo ? customerBranchData.gstNo : "" ;

                                            let addressTotal = Streetaddress + " " + address + " ";
                                            let cityTotal = city + " " + state + " ";
                                            let country = customerBranchData && customerBranchData.countryName ? customerBranchData.countryName : "" ;
                                            this.setState({creditLimit:creditLimit, customerGst:customerGst,creditDays:creditDays,country:country,CustomerStreetaddress : addressTotal , CustomerCIty : cityTotal ,CustomerPin : pin})
                                        }

                                    }
                                })
                                // this.getCompanyDeatils().then(result => {
                                // let resultData = result.result ? result.result : {}
                                // if (Array.isArray(resultData) && resultData.length >= 1) {
                                let CompanyData  =  getCurrenctCompanyData();
                                let comapny = {
                                    _id: CompanyData._id ? CompanyData._id : "",
                                    branchCode: CompanyData.branchCode ? CompanyData.branchCode : "",
                                    invoiceEmail: CompanyData.invoiceEmail ? CompanyData.invoiceEmail : "",
                                    bookingEmail: CompanyData.bookingEmail ? CompanyData.bookingEmail : "",
                                    companyName: CompanyData.branchCompanyName ? CompanyData.branchCompanyName : "",
                                    companyLogo: CompanyData.branchCompanyLogo ? CompanyData.branchCompanyLogo : "",
                                    agentCode: CompanyData.branchAgentCode ? CompanyData.branchAgentCode : "",
                                    companyCode: CompanyData.branchCode ? CompanyData.branchCode : "",
                                    websiteUrl: CompanyData.branchWebsiteUrl ? CompanyData.branchWebsiteUrl : "",
                                    panNo: CompanyData.panNo ? CompanyData.panNo : "",
                                    cinNo: CompanyData.cinNo ? CompanyData.cinNo : "",
                                    address: CompanyData && CompanyData.address ? CompanyData.address + " ," + CompanyData.streetAddress + ", " + CompanyData.city + " ,"
                                        + CompanyData.stateName + " ," + CompanyData.countryName
                                        : "",
                                    postalCode: CompanyData && CompanyData.postalCode ? CompanyData.postalCode : "",
                                    city: CompanyData && CompanyData.city ? CompanyData.city : "",
                                    phoneNo: CompanyData ? CompanyData.phoneNo : "",
                                    faxNo: CompanyData ? CompanyData.faxNo : "",
                                    branchEmail: CompanyData ? CompanyData.branchEmail : "",
                                    tanNo: CompanyData ? CompanyData.tanNo : "",
                                    gstNo: CompanyData ? CompanyData.gstNo : "",
                                    bankName:CompanyData ? CompanyData.bankName : "",
                                    branchBankName:CompanyData ? CompanyData.branchBankName : "",
                                    bankAccountNo:CompanyData ? CompanyData.bankAccountNo : "",
                                    // bankAccountNumber:csBuddyBranchDetails ? csBuddyBranchDetails.branchBankAccountNo : "",
                                    // bankAccountNumber:csBuddyBranchDetails ? csBuddyBranchDetails.branchBankAccountNo : "",
                                    bankAccountNumber:getCustomerCode(),
                                    bankIFSCcode:CompanyData ? CompanyData.branchBankIfscCode : "",
                                    bankSwiftCode:CompanyData ? CompanyData.bankSwiftCode : "",

                                }
                                this.setState({comapny: comapny})
                                // }
                                // }).catch()
                                this.setState({showLoader:false})
                            }
                            else{
                                this.setState({showLoader:false})
                            }
                        })
                    }

                })
                this.getbookingData({id: bookingId ,quoteFor:quoteFor}).then(bookingData => {
                    if (bookingData && bookingData.success) {

                        let BookingResult = bookingData && bookingData.result ? bookingData.result : "";
                        let houses = BookingResult && BookingResult.houses ? BookingResult.houses : "";
                        let companyBranchData = BookingResult && BookingResult.companyBranchData ? BookingResult.companyBranchData : "";
                        let housesCurrency = houses &&  houses.weightValue  ? houses.weightValue : "";
                        let quoteData = BookingResult && BookingResult.quoteId ? BookingResult.quoteId : undefined;
                        let blStatus = BookingResult && BookingResult.blStatus ? BookingResult.blStatus : undefined;
                        let blNo = BookingResult && BookingResult.blNo ? BookingResult.blNo : undefined;
                        let bookingDate = BookingResult && BookingResult.createdAt ? convertDateISO(BookingResult.createdAt)  : undefined;
                        let bookingNo = BookingResult && BookingResult.bookingNo ? BookingResult.bookingNo  : undefined;
                        let finalInvoiceCreated = BookingResult && BookingResult.invoice ? BookingResult.invoice : false;
                        let finalInvoiceApprovalStatus = BookingResult && BookingResult.invoiceData && BookingResult.invoiceData.approvalStatus ? BookingResult.invoiceData.approvalStatus : undefined;
                        let finalInvoiceId = BookingResult && BookingResult.invoiceData && BookingResult.invoiceData._id? BookingResult.invoiceData._id : undefined;
                        let hsnCode = quoteData &&  quoteData.commodityHsnCode  ? quoteData.commodityHsnCode : "";
                        let commodity = quoteData &&  quoteData.commodity  ? quoteData.commodity : "";
                        let queryFor =  quoteData &&  quoteData.queryFor && quoteData.queryFor ? quoteData.queryFor : "";
                        let OriginName =  quoteData &&  quoteData.originAirport && quoteData.originAirport.name ? quoteData.originAirport.name : "";
                        let shippingLineName = BookingResult && BookingResult.shippingLineName ? BookingResult.shippingLineName : "";
                        let noOfContainers = quoteData &&  quoteData.noOfContainers  ? quoteData.noOfContainers : "";
                        let shipmentMode = quoteData &&  quoteData.shipmentMode  ? quoteData.shipmentMode : "";
                        let DestinationName = quoteData &&  quoteData.destinationAirport && quoteData.destinationAirport.name ? quoteData.destinationAirport.name : "";
                        let PickUpZipcode = quoteData &&  quoteData.Shipper && quoteData.Shipper.ZipCode ? quoteData.Shipper.ZipCode : BookingResult &&  BookingResult.originDoor && BookingResult.originDoor.pincode ? BookingResult.originDoor.pincode:"" ;
                        let PickUpCountry = quoteData &&  quoteData.Shipper && quoteData.Shipper.Country ? quoteData.Shipper.Country : "India";
                        let DropToZipcode = quoteData &&  quoteData.Consignee && quoteData.Consignee.ZipCode ? quoteData.Consignee.ZipCode : BookingResult &&  BookingResult.destinationDoor && BookingResult.destinationDoor.pincode ? BookingResult.destinationDoor.pincode:"";
                        let DropToCountry = quoteData &&  quoteData.Consignee && quoteData.Consignee.Country ? quoteData.Consignee.Country : "India";
                        let jobNo = quoteData &&  quoteData.jobNo  ? quoteData.jobNo : "";
                        let vatNo = quoteData &&  quoteData.customerId.panNo  ? quoteData.customerId.panNo : "";
                        let AccountType = BookingResult && BookingResult.accountType ? BookingResult.accountType : "";
                        let incoTerms = BookingResult && BookingResult.incoTerms ? BookingResult.incoTerms : "";
                        let routing = BookingResult && BookingResult.routing ? BookingResult.routing : "";
                        let queryIntendedPlan = BookingResult && BookingResult.quoteId && BookingResult.quoteId.intendedTransportPlan ?BookingResult.quoteId.intendedTransportPlan:"" ;
                        let queryIntendedPlanLength=queryIntendedPlan.length;
                        let IntendedPlan = BookingResult && BookingResult.intendedTransportPlan ? BookingResult.intendedTransportPlan :"";
                        let IntenedPlanLength = IntendedPlan.length;
                        let ETD = IntendedPlan && IntendedPlan[0] && IntendedPlan[0].etd ? IntendedPlan[0].etd : queryIntendedPlan && queryIntendedPlan[0] && queryIntendedPlan[0].etd ? queryIntendedPlan[0].etd:"";
                        let ETA = IntendedPlan && IntendedPlan[IntendedPlan.length - 1] && IntendedPlan[IntendedPlan.length - 1].eta ? IntendedPlan[IntendedPlan.length - 1].eta : queryIntendedPlan && queryIntendedPlan[queryIntendedPlan.length - 1] && queryIntendedPlan[queryIntendedPlan.length - 1].eta ? queryIntendedPlan[queryIntendedPlan.length - 1].eta:"";
                        let noOfPieces = BookingResult && BookingResult.noOfPieces ? BookingResult.noOfPieces :"";
                        let grossWeight = BookingResult && BookingResult.grossWeight ? BookingResult.grossWeight :"";
                        let chargeableWeight = BookingResult && BookingResult.chargeableWeight ? BookingResult.chargeableWeight :"";


                        this.setState({
                            noOfPieces:noOfPieces,
                            chargeableWeight:chargeableWeight,
                            grossWeight:grossWeight,
                            routing: routing,
                            AccountType: AccountType,
                            incoTerms: incoTerms,
                            ETD: ETD,
                            ETA: ETA,
                            IntendedPlan: IntendedPlan,
                            IntenedPlanLength: IntenedPlanLength,
                            houses: houses,
                            quoteData: quoteData,
                            housesCurrency: housesCurrency,
                            queryFor: queryFor,
                            originName: OriginName,
                            noOfContainers: noOfContainers,
                            DestinationName: DestinationName,
                            DropToZipcode : DropToZipcode,
                            PickUpZipcode : PickUpZipcode,
                            DropToCountry : DropToCountry,
                            PickUpCountry : PickUpCountry,
                            shippingLineName: shippingLineName,
                            hsnCode: hsnCode,
                            BlStatus: blStatus,
                            blNo: blNo,
                            finalInvoiceCreated: finalInvoiceCreated,
                            finalInvoiceApprovalStatus: finalInvoiceApprovalStatus,
                            finalInvoiceId: finalInvoiceId,
                            bookingDate: bookingDate,
                            bookingNo: bookingNo,
                            commodity: commodity,
                            jobNo: jobNo,
                            vatNo: vatNo,
                            queryIntendedPlan: queryIntendedPlan,
                            queryIntendedPlanLength: queryIntendedPlanLength,
                            shipmentMode:shipmentMode,
                            companyBranchData:companyBranchData,
                        })

                    }
                })

            })

        } catch (e) {
        }



    }
    GetCustomerBranchData = async (dataForServer) => {
        return await RestClient({
            url: GET_SINGLE_CUSTOMER_BRANCH_DATA,
            method: "POST",
            data: dataForServer
        });
    };
    GetProformaId = async (dataForServer) => {
        return await RestClient({
            url: dataForServer && dataForServer.quoteFor === "Road" ? GET_PROFORMA_ID_BY_BOOKINGID_ROAD :  GET_PROFORMA_ID_BY_BOOKINGID_COURIER,
            method: "POST",
            data: dataForServer
        });
    };
    GetProformaData = async (dataForServer) => {
        return await RestClient({
            url: dataForServer && dataForServer.quoteFor === "Road" ? GET_PROFORMA_DATA_ROAD : GET_PROFORMA_DATA_COURIER,
            method: "POST",
            data: dataForServer
        });
    };

    calculateCharges() {

        // console.log('preview data', this.state.previewData);
        const {grossWeight,chargeableWeight,volumeWeight} = this.state.previewData
        let SellValue = this.state.previewData && this.state.previewData.saleValue ? this.state.previewData.saleValue : 0
        let ChargeableWeight = chargeableWeight
        // let chargesArray = Array.isArray(this.state.chargesArray) && this.state.chargesArray.length >= 1 ? this.state.chargesArray : []
        // let chargesArrayData = this.state.previewData && this.state.previewData.otherCharges ? this.state.previewData.otherCharges : []
        let chargesArrayData = this.state.chargesArray
        let CustomerGstType = this.state.previewData && this.state.previewData.CustomerGstType ? this.state.previewData.CustomerGstType : ""
        let posData = this.state.previewData && this.state.previewData.posData ? this.state.previewData.posData : ""
        let csBuddyBranch = this.state.previewData && this.state.previewData.csBuddyBranch ? this.state.previewData.csBuddyBranch : ""
        let TaxableAmount = undefined
        let ChargeAmount = undefined
        let array = []
        chargesArrayData && chargesArrayData.map((item, i) => {

            let obj = {}
            let chargeAccording = item.calculationUnit !== undefined ? item.calculationUnit : ""
            // if (chargeAccording !== "" && chargeAccording === "C"){
            //     TaxableAmount = SellValue * ChargeableWeight
            // }else if(chargeAccording !== "" && chargeAccording === "G"){
            //     TaxableAmount = SellValue * grossWeight
            // }else {
            //     TaxableAmount = SellValue * ChargeableWeight

            TaxableAmount = item && item.chargeTaxable !== "" ? item.chargeTaxable : "";
            ChargeAmount = item && item.chargeAmount !== "" ? item.chargeAmount : "";
            // }

            obj.chargeName = item.chargeName
            obj.chargeCode = ""
            obj.altName =item.altName !== undefined ? item.altName : item.chargeName ;
            obj.conatinerName =item.conatinerName !== undefined ? item.conatinerName : "" ;
            obj.chargeHsnCode = item && item.chargeHsnCode ? item.chargeHsnCode : ""
            // obj.chargeRate = SellValue ? SellValue : ""
            obj.chargeRate = item && item.chargeRate ? item.chargeRate : ""
            obj.x_rate = item && item.x_rate ? item.x_rate : ""
            obj.chargeQty = item && item.chargeQty ? item.chargeQty : ""
            obj.taxable = TaxableAmount ? TaxableAmount : ""
            obj.discount =  "0"
            obj.chargeTaxable = TaxableAmount ? TaxableAmount : ""


            let taxable = item && item.isTaxable !== "" ? item.isTaxable : ""
            // let gstType = previewDataForPos && previewDataForPos.customer &&  previewDataForPos.customer.customerBranchData && previewDataForPos.customer.customerBranchData.gstdetails &&
            // previewDataForPos.customer.customerBranchData.gstdetails.gstType ? previewDataForPos.customer.customerBranchData.gstdetails.gstType : ""
            if (CustomerGstType === "O-OVERSEAS" || CustomerGstType === "S-SEZ" || CustomerGstType === "U-UIN"){
                if (taxable !== "" && (taxable === false)) {
                    obj.chargeCgstRate = ""
                    obj.chargeCgstAmount = ""
                    obj.chargeSgstRate = ""
                    obj.chargeSgstAmount = ""
                    obj.chargeIgstRate = ""
                    obj.chargeIgstAmount = ""
                    obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                    obj.isTaxable = false
                } else if (taxable !== "" && taxable === true) {

                    if (posData !== "" && csBuddyBranch !== "" && csBuddyBranch === posData) {

                        let amountCalculateForPOS = TaxableAmount && item.rateOfTax ? ((item.rateOfTax / 2) / 100) * TaxableAmount : 0
                        obj.chargeCgstRate = item && item.rateOfTax ? (item.rateOfTax / 2) : ""
                        obj.chargeCgstAmount = amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""
                        obj.chargeSgstRate = item && item.rateOfTax ? (item.rateOfTax / 2) : ""
                        obj.chargeSgstAmount = amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""
                        obj.chargeIgstRate = ""
                        obj.chargeIgstAmount = ""
                        obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                        obj.isTaxable = true
                    } else {

                        obj.chargeCgstRate = ""
                        obj.chargeCgstAmount = ""
                        obj.chargeSgstRate = ""
                        obj.chargeSgstAmount = ""
                        obj.chargeIgstRate = item && item.rateOfTax ? item.rateOfTax : ""
                        let amountCalculate = TaxableAmount && item && item.rateOfTax ? (item.rateOfTax / 100) * TaxableAmount : 0
                        obj.chargeIgstAmount = amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""
                        obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                        obj.isTaxable = true
                    }

                }
                array.push(obj)
            }else {

                if (taxable !== "" && taxable === false) {
                    obj.chargeCgstRate = ""
                    obj.chargeCgstAmount = ""
                    obj.chargeSgstRate = ""
                    obj.chargeSgstAmount = ""
                    obj.chargeIgstRate = ""
                    obj.chargeIgstAmount = ""
                    obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                    obj.isTaxable = false
                }
                else if (taxable !== "" && taxable === true) {

                    if (posData !== "" && csBuddyBranch !== "" && csBuddyBranch === posData) {

                        let amountCalculateForPOS = TaxableAmount && item.rateOfTax ? ((item.rateOfTax / 2) / 100) * TaxableAmount : 0
                        obj.chargeCgstRate = item && item.rateOfTax ? (item.rateOfTax / 2) : ""
                        obj.chargeCgstAmount = amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""
                        obj.chargeSgstRate = item && item.rateOfTax ? (item.rateOfTax / 2) : ""
                        obj.chargeSgstAmount = amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : ""
                        obj.chargeIgstRate = ""
                        obj.chargeIgstAmount = ""
                        obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                        obj.isTaxable = true
                    } else {

                        obj.chargeCgstRate = ""
                        obj.chargeCgstAmount = ""
                        obj.chargeSgstRate = ""
                        obj.chargeSgstAmount = ""
                        obj.chargeIgstRate = item && item.rateOfTax ? item.rateOfTax : ""
                        let amountCalculate = TaxableAmount && item && item.rateOfTax ? (item.rateOfTax / 100) * TaxableAmount : 0
                        obj.chargeIgstAmount = amountCalculate !== "" ? parseFloatValue(amountCalculate) : ""
                        obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                        obj.isTaxable = true
                    }

                }
                array.push(obj)
                return null;
            }


        })
        this.setState({
            otherChargesClearnce: array
        }, () => {


            this.calculateTotal()
        })
    }

    calculateTotal = () => {

        const otherChargesClearnce = this.state.otherChargesClearnce;
        if (otherChargesClearnce && otherChargesClearnce.length > 0) {
            let calculateTaxble = 0;
            let chargeAmount = 0;
            let calculateNetAmount = 0;
            let chargeCgstAmount = 0;
            let chargeSgstAmount = 0;
            let chargeIgstAmount = 0;

            otherChargesClearnce.map(invoiceMap => {
                if (invoiceMap.taxable !== "") {
                    calculateTaxble = parseFloatValue(calculateTaxble) + parseFloatValue(invoiceMap.taxable)
                }
                if (invoiceMap.chargeTaxable !== "") {
                    calculateNetAmount = parseFloatValue(calculateNetAmount) + parseFloatValue(invoiceMap.chargeTaxable)
                }
                if (invoiceMap.chargeAmount !== "") {
                    chargeAmount = parseFloatValue(chargeAmount) + parseFloatValue(invoiceMap.chargeAmount)
                }
                if (invoiceMap.chargeCgstAmount !== "") {
                    chargeCgstAmount = parseFloatValue(chargeCgstAmount) + parseFloatValue(invoiceMap.chargeCgstAmount)
                }
                if (invoiceMap.chargeSgstAmount !== "") {
                    chargeSgstAmount = parseFloatValue(chargeSgstAmount) + parseFloatValue(invoiceMap.chargeSgstAmount)
                }
                if (invoiceMap.chargeIgstAmount !== "") {
                    chargeIgstAmount = parseFloatValue(chargeIgstAmount) + parseFloatValue(invoiceMap.chargeIgstAmount)
                }
                return null;
            })

            this.setState({
                calculateTaxble: parseFloatValue(calculateTaxble),
                calculateNetAmount: parseFloatValue(calculateNetAmount),
                chargeCgstAmount: parseFloatValue(chargeCgstAmount),
                chargeSgstAmount: parseFloatValue(chargeSgstAmount),
                chargeIgstAmount: parseFloatValue(chargeIgstAmount),
                chargeAmount: parseFloatValue(chargeAmount),
                roundOff: Math.round(chargeAmount)
            },()=>{
                this.storeInvoiceDataFirst()
            })
        }
    }

    calculateTotalAfterDiscount = () => {

        const otherChargesClearnce = this.state.otherChargesClearnce;
        if (otherChargesClearnce && otherChargesClearnce.length > 0) {
            let calculateTaxble = 0;
            let chargeAmount = 0;
            let calculateNetAmount = 0;
            let chargeCgstAmount = 0;
            let chargeSgstAmount = 0;
            let chargeIgstAmount = 0;

            otherChargesClearnce.map(invoiceMap => {
                if (invoiceMap.taxable !== "") {
                    calculateTaxble = parseFloatValue(calculateTaxble) + parseFloatValue(invoiceMap.taxable)
                }
                if (invoiceMap.chargeTaxable !== "") {
                    calculateNetAmount = parseFloatValue(calculateNetAmount) + parseFloatValue(invoiceMap.chargeTaxable)
                }
                if (invoiceMap.chargeAmount !== "") {
                    chargeAmount = parseFloatValue(chargeAmount) + parseFloatValue(invoiceMap.chargeAmount)
                }
                if (invoiceMap.chargeCgstAmount !== "") {
                    chargeCgstAmount = parseFloatValue(chargeCgstAmount) + parseFloatValue(invoiceMap.chargeCgstAmount)
                }
                if (invoiceMap.chargeSgstAmount !== "") {
                    chargeSgstAmount = parseFloatValue(chargeSgstAmount) + parseFloatValue(invoiceMap.chargeSgstAmount)
                }
                if (invoiceMap.chargeIgstAmount !== "") {
                    chargeIgstAmount = parseFloatValue(chargeIgstAmount) + parseFloatValue(invoiceMap.chargeIgstAmount)
                }
                return null;
            })

            this.setState({
                calculateTaxble: parseFloatValue(calculateTaxble),
                calculateNetAmount: parseFloatValue(calculateNetAmount),
                chargeCgstAmount: parseFloatValue(chargeCgstAmount),
                chargeSgstAmount: parseFloatValue(chargeSgstAmount),
                chargeIgstAmount: parseFloatValue(chargeIgstAmount),
                chargeAmount: parseFloatValue(chargeAmount),
                roundOff: Math.round(chargeAmount)
            },()=>{
                // this.storeInvoiceDataFirst()
            })
        }
    }

    calculateChargesAfterCoupon() {

        // console.log('preview data', this.state.previewData);
        const {grossWeight,chargeableWeight,volumeWeight} = this.state.previewData
        let SellValue = this.state.previewData && this.state.previewData.saleValue ? this.state.previewData.saleValue : 0
        let ChargeableWeight = chargeableWeight
        // let chargesArray = Array.isArray(this.state.chargesArray) && this.state.chargesArray.length >= 1 ? this.state.chargesArray : []
        // let chargesArrayData = this.state.previewData && this.state.previewData.otherCharges ? this.state.previewData.otherCharges : []
        let chargesArrayData = this.state.chargesArray
        let CustomerGstType = this.state.previewData && this.state.previewData.CustomerGstType ? this.state.previewData.CustomerGstType : ""
        let posData = this.state.previewData && this.state.previewData.posData ? this.state.previewData.posData : ""
        let csBuddyBranch = this.state.previewData && this.state.previewData.csBuddyBranch ? this.state.previewData.csBuddyBranch : ""
        let TaxableAmount = undefined
        let ChargeAmount = undefined
        let TaxableDsicountAmount = undefined
        let array = []
        chargesArrayData && chargesArrayData.map((item, i) => {

            let obj = {}
            let chargeAccording = item.calculationUnit !== undefined ? item.calculationUnit : ""
            // if (chargeAccording !== "" && chargeAccording === "C"){
            //     TaxableAmount = SellValue * ChargeableWeight
            // }else if(chargeAccording !== "" && chargeAccording === "G"){
            //     TaxableAmount = SellValue * grossWeight
            // }else {
            //     TaxableAmount = SellValue * ChargeableWeight
            TaxableAmount = item && item.taxable !== "" ? item.taxable : "";
            if(item.discount !== "" && item.discount !== undefined && item.discount !== "0"){
                TaxableDsicountAmount = item && item.taxable !== "" ? item.taxable - item.discount : "";
                if(TaxableDsicountAmount === 0){
                    TaxableDsicountAmount = "0"
                }
                ChargeAmount = item && item.chargeAmount !== "" ? item.chargeAmount - item.discount : "";
            }else {
                TaxableDsicountAmount = item && item.taxable !== "" ? item.taxable : "";
                ChargeAmount = item && item.chargeAmount !== "" ? item.chargeAmount : "";
            }


            // }
            obj.chargeName = item.chargeName
            obj.chargeCode = ""
            obj.altName = item.chargeName
            obj.chargeHsnCode = item && item.chargeHsnCode ? item.chargeHsnCode : ""
            // obj.chargeRate = SellValue ? SellValue : ""
            obj.chargeRate = item && item.chargeRate ? item.chargeRate : ""
            obj.chargeQty = item && item.chargeQty ? item.chargeQty : ""
            obj.taxable = TaxableAmount ? TaxableAmount : ""
            obj.chargeTaxable = TaxableDsicountAmount && TaxableDsicountAmount !== 0 ? TaxableDsicountAmount : "0"
            if(item.discount !== "" && item.discount !== undefined && item.discount !== "0"){
                obj.discount =  item.discount
            }else{
                obj.discount =  "0"
            }

            let taxable = item && item.isTaxable !== "" ? item.isTaxable : ""
            // let gstType = previewDataForPos && previewDataForPos.customer &&  previewDataForPos.customer.customerBranchData && previewDataForPos.customer.customerBranchData.gstdetails &&
            // previewDataForPos.customer.customerBranchData.gstdetails.gstType ? previewDataForPos.customer.customerBranchData.gstdetails.gstType : ""
            if (CustomerGstType === "O-OVERSEAS" || CustomerGstType === "S-SEZ" || CustomerGstType === "U-UIN"){
                if (taxable !== "" && (taxable === false)) {
                    obj.chargeCgstRate = ""
                    obj.chargeCgstAmount = ""
                    obj.chargeSgstRate = ""
                    obj.chargeSgstAmount = ""
                    obj.chargeIgstRate = ""
                    obj.chargeIgstAmount = ""
                    obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                    obj.isTaxable = false
                }else if (taxable !== "" && taxable === true) {

                    let amountCalculateForPOS = undefined;
                    let amountCalculate = undefined;
                    if (posData !== "" && csBuddyBranch !== "" && csBuddyBranch === posData) {

                        if(item.discount !== "" && item.discount !== undefined && item.discount !== "0"){
                            amountCalculateForPOS = TaxableDsicountAmount && item.rateOfTax ? ((item.rateOfTax / 2) / 100) * TaxableDsicountAmount : 0
                        }
                        else {
                            amountCalculateForPOS = TaxableAmount && item.rateOfTax ? ((item.rateOfTax / 2) / 100) * TaxableAmount : 0
                        }

                        obj.chargeCgstRate = item && item.rateOfTax ? (item.rateOfTax / 2) : ""
                        obj.chargeCgstAmount = amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : "0"
                        obj.chargeSgstRate = item && item.rateOfTax ? (item.rateOfTax / 2) : ""
                        obj.chargeSgstAmount = amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : "0"
                        obj.chargeIgstRate = ""
                        obj.chargeIgstAmount = ""
                        // obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                        obj.chargeAmount = amountCalculateForPOS !== 0 ? parseFloatValue(obj.chargeCgstAmount) + parseFloatValue(obj.chargeSgstAmount) + parseFloatValue(obj.chargeTaxable) : "0"

                        obj.isTaxable = true
                    } else {

                        obj.chargeCgstRate = ""
                        obj.chargeCgstAmount = ""
                        obj.chargeSgstRate = ""
                        obj.chargeSgstAmount = ""
                        obj.chargeIgstRate = item && item.rateOfTax ? item.rateOfTax : ""
                        if(item.discount !== "" && item.discount !== undefined && item.discount !== "0"){
                            amountCalculate = TaxableDsicountAmount && item && item.rateOfTax ? (item.rateOfTax / 100) * TaxableDsicountAmount : 0
                        }
                        else {
                            amountCalculate = TaxableAmount && item && item.rateOfTax ? (item.rateOfTax / 100) * TaxableAmount : 0
                        }

                        obj.chargeIgstAmount = amountCalculate !== "" ? parseFloatValue(amountCalculate) : "0"
                        obj.chargeAmount = TaxableDsicountAmount ? parseFloatValue(TaxableDsicountAmount) + parseFloatValue(amountCalculate) : ""
                        obj.isTaxable = true
                    }

                }
                array.push(obj)
            }else {

                if (taxable !== "" && taxable === false) {
                    obj.chargeCgstRate = ""
                    obj.chargeCgstAmount = ""
                    obj.chargeSgstRate = ""
                    obj.chargeSgstAmount = ""
                    obj.chargeIgstRate = ""
                    obj.chargeIgstAmount = ""
                    obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                    obj.isTaxable = false
                }
                else if (taxable !== "" && taxable === true) {

                    let amountCalculateForPOS = undefined;
                    let amountCalculate = undefined;
                    if (posData !== "" && csBuddyBranch !== "" && csBuddyBranch === posData) {

                        if(item.discount !== "" && item.discount !== undefined && item.discount !== "0"){
                            amountCalculateForPOS = TaxableDsicountAmount && item.rateOfTax ? ((item.rateOfTax / 2) / 100) * TaxableDsicountAmount : 0
                        }
                        else {
                            amountCalculateForPOS = TaxableAmount && item.rateOfTax ? ((item.rateOfTax / 2) / 100) * TaxableAmount : 0
                        }

                        obj.chargeCgstRate = item && item.rateOfTax ? (item.rateOfTax / 2) : ""
                        obj.chargeCgstAmount = amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : "0"
                        obj.chargeSgstRate = item && item.rateOfTax ? (item.rateOfTax / 2) : ""
                        obj.chargeSgstAmount = amountCalculateForPOS !== 0 ? parseFloatValue(amountCalculateForPOS) : "0"
                        obj.chargeIgstRate = ""
                        obj.chargeIgstAmount = ""
                        // obj.chargeAmount = ChargeAmount ? parseFloatValue(ChargeAmount) : ""
                        obj.chargeAmount = amountCalculateForPOS !== 0 ? parseFloatValue(obj.chargeCgstAmount) + parseFloatValue(obj.chargeSgstAmount) + parseFloatValue(obj.chargeTaxable) : "0"

                        obj.isTaxable = true
                    } else {

                        obj.chargeCgstRate = ""
                        obj.chargeCgstAmount = ""
                        obj.chargeSgstRate = ""
                        obj.chargeSgstAmount = ""
                        obj.chargeIgstRate = item && item.rateOfTax ? item.rateOfTax : ""
                        if(item.discount !== "" && item.discount !== undefined && item.discount !== "0"){
                            amountCalculate = TaxableDsicountAmount && item && item.rateOfTax ? (item.rateOfTax / 100) * TaxableDsicountAmount : 0
                        }
                        else {
                            amountCalculate = TaxableAmount && item && item.rateOfTax ? (item.rateOfTax / 100) * TaxableAmount : 0
                        }

                        obj.chargeIgstAmount = amountCalculate !== "" ? parseFloatValue(amountCalculate) : "0"
                        obj.chargeAmount = TaxableDsicountAmount ? parseFloatValue(TaxableDsicountAmount) + parseFloatValue(amountCalculate) : ""
                        obj.isTaxable = true
                    }

                }
                array.push(obj)
                return null;
            }


        })
        this.setState({
            otherChargesClearnce: array
        }, () => {
            this.calculateTotalAfterDiscount()
        })
    }

    inWords = totalAmount => {
        let a = [
            "",
            "One ",
            "Two ",
            "Three ",
            "Four ",
            "Five ",
            "Six ",
            "Seven ",
            "Eight ",
            "Nine ",
            "Ten ",
            "Eleven ",
            "Twelve ",
            "Thirteen ",
            "Fourteen ",
            "Fifteen ",
            "Sixteen ",
            "Seventeen ",
            "Eighteen ",
            "Nineteen "
        ];
        let b = [
            "",
            "",
            "Twenty",
            "Thirty",
            "Forty",
            "Fifty",
            "Sixty",
            "Seventy",
            "Eighty",
            "Ninety"
        ];
        let number = parseFloat(totalAmount)
            .toFixed(2)
            .split(".");
        let num = parseInt(number[0]);
        let digit = parseInt(number[1]);
        if (num.toString().length > 9)
            return "Max Amount Value Upto : Ten crore only";
        let n = ("000000000" + num)
            .substr(-9)
            .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        let d = ("00" + digit).substr(-2).match(/^(\d{2})$/);
        if (!n) return;
        let str = "";
        str +=
            Number(n[1]) !== 0
                ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
                : "";
        str +=
            Number(n[2]) !== 0
                ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
                : "";
        str +=
            Number(n[3]) !== 0
                ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
                : "";
        str +=
            Number(n[4]) !== 0
                ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
                : "";
        str +=
            Number(n[5]) !== 0
                ? (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + "Rupee "
                : "";
        str +=
            Number(d[1]) !== 0
                ? (str !== "" ? "and " : "") +
                (a[Number(d[1])] || b[d[1][0]] + " " + a[d[1][1]]) +
                "Paise "
                : "Only!";
        return str;
    };

    isObject(obj) {
        if ((typeof obj === "object" || typeof obj === 'function') && (obj !== null)) {
            return true;
        } else {
            return false;
        }
    }



    convertToIsoDateThenDMY = (dateValue) => {
        let date = ""
        if (dateValue instanceof Date) {
            return dateValue.toISOString();
        } else {
            dateValue = new Date(dateValue);
            if (dateValue != null && dateValue != undefined && dateValue != "Invalid Date") {
                date = new Date(dateValue.toISOString());
                return convertDateFromISO(date, "DD-MM-YYYY")
                // return date.getDate()+'-'+(date.getMonth()+1)+'-'+ date.getFullYear()
            }
        }
    }

    storeInvoiceDataWithoutPay =()=>{
        let previewData = Object.keys(this.state.previewData).length  >=1 ? this.state.previewData : undefined
        if (previewData !== undefined){
            previewData.taxableTotalAmount = this.state.calculateTaxble;
            previewData.cgstTotalAmount = this.state.chargeCgstAmount;
            previewData.sgstTotalAmount = this.state.chargeSgstAmount;
            previewData.igstTotalAmount = this.state.chargeIgstAmount;
            previewData.totalAmount = this.state.chargeAmount;
            previewData.roundOffAmount = this.state.roundOff;
            previewData.otherCharges = this.state.otherChargesClearnce;
            this.setState({previewData:previewData},()=>{
                this.StoreInvoiceDataAPI(this.state.previewData).then(result=>{
                    if(result.success){
                        this.props.history.push(PAGE_PATHS.shipmentHistory)
                    }
                })
            })
        }
    }

    updateInvoiceDataAfterPay =()=>{
        let previewData = Object.keys(this.state.previewData).length  >=1 ? this.state.previewData : undefined
        if (previewData !== undefined){
            previewData.taxableTotalAmount = this.state.calculateTaxble;
            previewData.cgstTotalAmount = this.state.chargeCgstAmount;
            previewData.sgstTotalAmount = this.state.chargeSgstAmount;
            previewData.igstTotalAmount = this.state.chargeIgstAmount;
            previewData.totalAmount = this.state.chargeAmount;
            previewData.roundOffAmount = this.state.roundOff;
            previewData.otherCharges = this.state.otherChargesClearnce;
            this.setState({previewData:previewData},()=>{
                this.UpdateInvoiceDataAfterPayAPI(this.state.previewData).then(result=>{
                    if(result.success){
                        // this.props.history.push(PAGE_PATHS.shipmentHistory)
                    }
                })
            })
        }
    }

    UpdateInvoiceDataAfterPayAPI = async (data) => {
        return await RestClient({
            url: UPDATE_INVOICE_DATA,
            method: "POST",
            data: data
        });
    };


    storeInvoiceDataFirst =  ()=>{
        let previewData = Object.keys(this.state.previewData).length  >=1 ? this.state.previewData : undefined
        if (previewData !== undefined){
            previewData.taxableTotalAmount = this.state.calculateTaxble;
            previewData.cgstTotalAmount = this.state.chargeCgstAmount;
            previewData.sgstTotalAmount = this.state.chargeSgstAmount;
            previewData.igstTotalAmount = this.state.chargeIgstAmount;
            previewData.totalAmount = this.state.chargeAmount;
            previewData.roundOffAmount = this.state.roundOff;
            previewData.otherCharges = this.state.otherChargesClearnce;

            this.StoreInvoiceDataAPI(previewData).then(result=>{
                if(result.success){
                    let performaInvoiceId = result && result.result && result.result.invoiceNo ? result.result.invoiceNo : ""
                    let performaObjectID = result && result.result && result.result._id ? result.result._id : ""
                    let paidStatus = result && result.result && result.result.paidStatus ? result.result.paidStatus : ""
                    let PdfUrl = result && result.result && result.result.invoiceUrl ? result.result.invoiceUrl : ""
                    let invoiceType = result && result.result && result.result.invoiceType ? result.result.invoiceType : ""
                    let performaInvoiceNumber = result && result.result && result.result.invoiceNo ? result.result.invoiceNo.split("_")[result.result.invoiceNo.split("_").length - 1 ] : ""
                    let otherChargesClearnce = result && result.result && result.result.otherCharges ? result.result.otherCharges : []
                    let calculateTaxble = result && result.result && result.result.taxableTotalAmount ? result.result.taxableTotalAmount : ""
                    let chargeCgstAmount = result && result.result && result.result.cgstTotalAmount ? result.result.cgstTotalAmount : ""
                    let chargeSgstAmount = result && result.result && result.result.sgstTotalAmount ? result.result.sgstTotalAmount : ""
                    let chargeIgstAmount = result && result.result && result.result.igstTotalAmount ? result.result.igstTotalAmount : ""
                    let chargeAmount = result && result.result && result.result.totalAmount ? result.result.totalAmount : ""
                    let roundOff = result && result.result && result.result.roundOffAmount ? result.result.roundOffAmount : ""
                    let discount = result && result.result && result.result.discount ? result.result.discount : ""
                    let updatedPrice = result && result.result && result.result.discount ? result.result.discount : ""

                    this.setState({reciptNumber: performaInvoiceId,paidStatus:paidStatus,PdfUrl:PdfUrl,performaInvoiceNumber:performaInvoiceNumber,
                        otherChargesClearnce:otherChargesClearnce,
                        calculateTaxblev:calculateTaxble,
                        chargeCgstAmount:chargeCgstAmount,
                        chargeSgstAmount:chargeSgstAmount,
                        chargeIgstAmount:chargeIgstAmount,
                        performaObjectID:performaObjectID,
                        chargeAmount:chargeAmount,
                        roundOff:roundOff,
                        discount:discount,
                        invoiceType:invoiceType,


                    },()=>{
                        // alert(JSON.stringify(this.state.paidStatus))

                    })
                }else if(!result.success){
                    let paidStatus = result && result.result && result.result.paidStatus ? result.result.paidStatus : ""
                    let PdfUrl = result && result.result && result.result.invoiceUrl ? result.result.invoiceUrl : ""
                    this.setState({paidStatus:paidStatus,PdfUrl:PdfUrl},()=>{
                        // alert(JSON.stringify(this.state.paidStatus))
                    })

                }
            })

        }
    }


    storeInvoiceData =()=>{
        let previewData = Object.keys(this.state.previewData).length  >=1 ? this.state.previewData : undefined
        if (previewData !== undefined){
            previewData.taxableTotalAmount = this.state.calculateTaxble;
            previewData.cgstTotalAmount = this.state.chargeCgstAmount;
            previewData.sgstTotalAmount = this.state.chargeSgstAmount;
            previewData.igstTotalAmount = this.state.chargeIgstAmount;
            previewData.totalAmount = this.state.chargeAmount;
            previewData.roundOffAmount = this.state.roundOff;
            previewData.otherCharges = this.state.otherChargesClearnce;
            this.setState({previewData:previewData},()=>{
                this.StoreInvoiceDataAPI(this.state.previewData).then(result=>{
                    if(result.success){
                        let performaInvoiceId = result && result.result && result.result._id ? result.result._id : ""
                        this.paymentHandler(performaInvoiceId).then(r =>{} )
                    }
                })
            })
        }
    }
    StoreInvoiceDataAPI = async (data) => {
        return await RestClient({
            url: STORE_INVOICE,
            method: "POST",
            data: data
        });
    };

    applyCouponCode =()=>{
        let previewData = Object.keys(this.state.previewData).length  >=1 ? this.state.previewData : undefined
        this.setState({
            updatedPrice : "",
            chargeAmountAfterDiscount : "",
            discount : "",
            roundOff : this.state.chargeAmount,
            ShowDiscoutColumn:false
        },()=>{
            if (previewData !== undefined){
                let obj = {airlineName :this.state.AirlineName,
                    airlineId :this.state.AirlineId,
                    airlineCode :this.state.AirlineCode,
                }
                previewData.coupanCode = this.state.offerCode;
                previewData.airline = obj;
                previewData.invoiceNo = this.state.reciptNumber;
                previewData.invoiceType = this.state.invoiceType;
                previewData.taxableTotalAmount = this.state.calculateTaxble;
                previewData.cgstTotalAmount = this.state.chargeCgstAmount;
                previewData.sgstTotalAmount = this.state.chargeSgstAmount;
                previewData.igstTotalAmount = this.state.chargeIgstAmount;
                previewData.totalAmount = this.state.chargeAmount;
                previewData.roundOffAmount = this.state.roundOff;
                previewData.otherCharges = this.state.otherChargesClearnce;

                this.ApplyCouponAPI(previewData).then(result=>{
                    if(result.success){
                        let resultData = result && result.result ? result.result : undefined
                        this.setState({
                            updatedPrice : resultData && resultData.updatedPrice ? resultData.updatedPrice: 0,
                            chargeAmountAfterDiscount : resultData && resultData.updatedPrice ? resultData.updatedPrice: this.state.chargeAmount,
                            roundOff : resultData && resultData.updatedPrice ? resultData.updatedPrice: this.state.chargeAmount,
                            discount : resultData && resultData.discount ? resultData.discount: 0,
                            appliedOncharge : resultData && resultData.applied ? resultData.applied: [],
                            ShowDiscoutColumn:true
                        },()=>{
                            this.recalculateCharges()
                        })
                        toast.success(result.message, {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.handleOnChargesget()
                    }else{
                        toast.error(result.result && result.result.message ? result.result.message :"Not Applicable" , {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })

            }


        })

    }


    ApplyCouponAPI = async (data) => {
        return await RestClient({
            url: APPLY_COUPONCODE_API,
            method: "POST",
            data: data
        });
    };


    recalculateCharges=()=>{

        let otherCharges = this.state.otherChargesClearnce
        let appliedOncharge = this.state.appliedOncharge
        let firstChargeArray = this.state.chargesArray
        let chargesArray= []
        let NewChargesArray= []
        let NotEqualArray= []
        let otherChargesObj =[]

        otherCharges && otherCharges.map((data,i)=>{
            appliedOncharge && appliedOncharge.map(item=>{
                if(item.chargeName === data.chargeName){
                    data.discount = item.discount ;
                    data.chargeTaxable =  data.taxable - item.discount;
                    if(data.chargeTaxable === 0){
                        data.chargeTaxable = "0"
                    }
                    // data.chargeTaxable =  data.chargeTaxable - item.discount;
                    chargesArray.push(data)
                }
            })

        })
        // console.log("otherChargesClearnce" + JSON.stringify(otherCharges))



        otherChargesObj = otherCharges.filter(data => data.chargeName !==
            appliedOncharge && appliedOncharge.map(item=> {
                return item.chargeName
            }))


        // let arrayabc = otherChargesObj.concat(chargesArray)
        //  console.log("arrayabc" + JSON.stringify(otherChargesObj))
        otherChargesObj && otherChargesObj.map(item=>{
            firstChargeArray && firstChargeArray.map((data,i)=>{
                if(item.chargeName === data.chargeName){
                    item.rateOfTax = data.rateOfTax;
                    item.taxAmount = data.taxAmount;
                    item.chargeType = data.chargeType;
                    item.calculationUnit = data.calculationUnit;

                    NewChargesArray.push(item)
                }
            })
        })
        // console.log("CHARGESARRAYCoupon",JSON.stringify(NewChargesArray))
        this.setState({otherChargesClearnce : chargesArray, chargesArray:NewChargesArray},()=>{
            this.calculateChargesAfterCoupon()
        })

    }
    paymentHandler = async (invoiceId) => {
        const {consigneeName,shipperName, shipperDetail, jobNo, consigneeDetail, invoiceDate, origin, destination, xRate, totalPieces, awbNo, grossWeight, chargeableWeight,CustomerGstType,bookingDate,
            shippingLine,chargeAmount,customerCurrency,chargeIgstAmount,chargeSgstAmount,invoiceNo,baseCurrency,chargeCgstAmount,calculateNetAmount,calculateTaxble,otherChargesClearnce
        } = this.state.previewData;

        let obj ={
            customer:this.state.payNowCustomerDetails,
            performaInvoiceId:invoiceId,
            currency : customerCurrency ? customerCurrency : "",
            amount : Math.round(chargeAmount),
            receipt : this.state.customerCode+"_"+Date.now(),
            type:"PROFORMA INVOICE"
        }
        await RestClient({
            url: PAYMENT_GATEWAY,
            method: "POST",
            data: obj
        }).then(response =>{

            if (response.success){
                let options = {
                    key: response.result.key,
                    name: "Zipaworld Innovation Pvt. Ltd.",
                    description: "Please Make your Payment",
                    order_id: response.result.orderId,
                    handler: async (response2) => {
                        try {
                            //alert(JSON.stringify(response2))
                            // console.log("--------payment Response---------------"+JSON.stringify(response2))
                            const paymentId = response2.razorpay_payment_id;
                            // const url = `${API_URL}capture/${paymentId}`;
                            // const captureResponse = await Axios.post(url, {})

                            let sendData = {
                                coupanCode: this.state.offerCode !== "" && this.state.offerCode !== undefined ? this.state.offerCode : "NA",
                                discount:this.state.discount !== "" ? this.state.discount : 0 ,
                                orderId:response2.razorpay_order_id,
                                paymentId:response2.razorpay_payment_id,
                                signature:response2.razorpay_signature
                            }
                            this.StoreInvoiceDataReturnedAPI(sendData).then(captureResponse=>{
                                if(captureResponse.success && captureResponse.result.paidStatus === "Paid" || captureResponse.success && captureResponse.result.paymentstatus === "Paid" ){
                                    this.setState({paymentStatusShow:true , ResponcePayment : captureResponse,paidStatus:captureResponse.result && captureResponse.result.paidStatus  ? captureResponse.result.paidStatus : captureResponse.success && captureResponse.result.paymentstatus ? captureResponse.result.paymentstatus : ""})
                                    localStorage.removeItem("SelectedCouponCode")
                                }else {

                                }
                            })
                        } catch (err) {
                            // alert(JSON.stringify(err))
                            console.log(err);
                        }
                    },
                    theme: {
                        color: "#686CFD",
                    },
                };
                const rzp1 = new window.Razorpay(options);
                rzp1.open();
            }else{
                toast.error("There Is Something Wrong With Payment Gateway Please Contact Customer Service", {
                    position: "top-left",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        })
    };

    StoreInvoiceDataReturnedAPI = async (data) => {
        return await RestClient({
            url: RETURN_TO_CONFIREM,
            method: "POST",
            data: data
        });
    };


    render() {
        // const otherChargesClearnce = this.state.otherChargesClearnce;
        const {bankName,branchBankName,bankAccountNo,bankBranchName,branchBankAccountNo,bankAccountNumber,bankIFSCcode,bankSwiftCode,companyName, city, agentCode, companyLogo, companyCode, websiteUrl,companyAddress, panNo, cinNo, address,bookingEmail, postalCode, phoneNo,invoiceEmail, tanNo, faxNo} = this.state.comapny;
        const {customerCurrency,consigneeName,shipperName, shipperDetail, jobNo, consigneeDetail, invoiceDate, origin, destination, xRate, totalPieces, awbNo, grossWeight, chargeableWeight,CustomerGstType,bookingDate,
            shippingLine,chargeAmount,chargeIgstAmount,igstTotalAmountB, igstTotalAmountC,chargeAmountB,taxableTotalAmountB, taxableTotalAmountC,totalAmountB, totalAmountC,chargeSgstAmount,invoiceNo,baseCurrency,invoiceType,chargeCgstAmount,calculateNetAmount,calculateTaxble,otherChargesClearnce,bookingNo,companyBranchData
        } = this.state.previewData;
        let word = chargeAmount ? chargeAmount : ""
        let firstletter = word !== "" ? word.substring(0, 1).toUpperCase() + word.substring(1) : ""
        // const companylogo = companyData && companyData.companyLogo ? companyData.companyLogo : "";
        let totalAmount = 0
        let totalDiscount = 0
        return (
<AppLayout>
    <FullLoader view={this.state.showLoader}/>
    <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />

    <GstDeatils
        modalLarge={this.state.gstLarge}
        setmodal={values => values === false ? this.setState({gstLarge: false}) : ""}
        KYCStatus={values => values === true ? this.setState({KYCStatus: true}, () => {
        }) : ""}
        recivedContactInfo={value => {

        }}
        normalClose={value => {

        }}
        checkKYC={value => {
            if (value === true) {
                this.setState({KYCStatus: true})
            }
        }}
    />
    <PayLaterPerforma
        modalLarge={this.state.payLarge}
        setmodal={values => values === false ? this.setState({payLarge: false}) : ""}
        performaId={this.state.PerformaId}
        customerId ={this.state.previewData && this.state.previewData.customer && this.state.previewData.customer.customerId? this.state.previewData.customer.customerId : ""}
        bookingid={this.state.bookingId}
        totalAmount={chargeAmount !== "NaN" ? Math.round(chargeAmount) : 0}
    />
<div className="dashboard-wrapper">
    <div className="invoiceDetail">
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-11 col-md-12 margin-auto">
                    <div className="bg-white p-4 border-shadows">
                        <div className="logo11">
                            <div className="invoice_mob_display">
                                <div className="header_invoice">
                                    <div className="mob_invoice">
                                        <img className="logo-pdfs" alt="logo" width="150" src={this.state.companyBranchData && this.state.companyBranchData.branchCompanyLogo !==undefined?this.state.companyBranchData.branchCompanyLogo:""}/>
                                    </div>
                                    <div className="invoice_text">
                                      <div className="full_width">
                                        <p
                                          className="title orange font-weight-bold"
                                          style={{ marginBottom: "0" }}
                                        >
                                          {this.state.companyBranchData &&
                                            this.state.companyBranchData.branchCompanyName}
                                        </p>
                                        <p className="office-address text-size">
                                          {this.state.companyBranchData &&
                                          this.state.companyBranchData.streetAddress !==
                                            undefined
                                            ? this.state.companyBranchData.streetAddress +
                                              " , "
                                            : ""}
                                          {this.state.companyBranchData &&
                                          this.state.companyBranchData.address !== undefined
                                            ? this.state.companyBranchData.address + " , "
                                            : ""}
                                          {this.state.companyBranchData &&
                                          this.state.companyBranchData.city !== undefined
                                            ? this.state.companyBranchData.city + " , "
                                            : ""}
                                          {this.state.companyBranchData &&
                                          this.state.companyBranchData.stateId &&
                                          this.state.companyBranchData.stateId.name !==
                                            undefined
                                            ? this.state.companyBranchData.stateId.name +
                                              " , "
                                            : ""}
                                          {this.state.companyBranchData &&
                                          this.state.companyBranchData.countryId &&
                                          this.state.companyBranchData.countryId.name !==
                                            undefined
                                            ? this.state.companyBranchData.countryId.name +
                                              " - "
                                            : ""}
                                          {this.state.companyBranchData &&
                                          this.state.companyBranchData.postalCode !==
                                            undefined
                                            ? this.state.companyBranchData.postalCode
                                            : ""}
                                        </p>
                                      </div>
                                    <div className="two_part ">
                                        <div className="first_parts">
                                          <p className="text-size">
                                            <strong>Phone No: </strong>
                                            {this.state.companyBranchData &&
                                            this.state.companyBranchData.phoneNo !==
                                              undefined
                                              ? this.state.companyBranchData.phoneNo
                                              : ""}
                                          </p>
                                          <p className="text-size">
                                            <strong>E-mail:</strong>{" "}
                                            {this.state.companyBranchData &&
                                            this.state.companyBranchData.branchEmail !==
                                              undefined
                                              ? this.state.companyBranchData.branchEmail
                                              : ""}
                                          </p>
                                          <p className="text-size">
                                            <strong>Website:</strong>
                                            {this.state.companyBranchData &&
                                            this.state.companyBranchData
                                              .branchWebsiteUrl !== undefined
                                              ? this.state.companyBranchData
                                                  .branchWebsiteUrl
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="second_parts">
                                          <p className="text-size">
                                            <strong>Tan No: </strong>
                                            {this.state.companyBranchData &&
                                            this.state.companyBranchData.branchTANNo !==
                                              undefined
                                              ? this.state.companyBranchData.branchTANNo
                                              : ""}
                                          </p>
                                          <p className="text-size">
                                            <strong>Cin No:</strong>{" "}
                                            {this.state.companyBranchData &&
                                            this.state.companyBranchData.branchCinNo !==
                                              undefined
                                              ? this.state.companyBranchData.branchCinNo
                                              : ""}
                                          </p>
                                          <p className="text-size">
                                            <strong>PAN No:</strong>
                                            {this.state.companyBranchData &&
                                            this.state.companyBranchData.branchPanNo !==
                                              undefined
                                              ? this.state.companyBranchData.branchPanNo
                                              : ""}
                                          </p>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="performa-text">
                            <div className="orange-back">BOOKING REQUEST RECEIVED REF. NUMBER - ({this.state.jobNo})</div>
                            <div className="performa-table">
                                <div className="invoice_mob_display">
                                    <div className=" d-flex flex-wrap justify-content-between">
                                        <div className="invoice_big_data d-flex flex-wrap flex-column">
                                            <h2 className="pb-2 no-desktop"><strong>Customer Details</strong></h2>
                                            <p><strong>Name:</strong><span>{this.state.CustomerName}</span></p>
                                            <p><strong>Address:</strong><span>{this.state.CustomerStreetaddress !== "" ? this.state.CustomerStreetaddress : ""}</span></p>
                                            <p><strong>Country:</strong><span>{this.state.country}</span></p>
                                            <p><strong>Pan Number:</strong><span>{this.state.customerPanNo}</span></p>
                                            <p><strong>Client Number:</strong><span>{this.state.customerCode}</span></p>
                                            <p><strong>GST Number:</strong><span>{this.state.customerGst}</span></p>
                                        </div>
                                        <div className="invoice_big_data d-flex flex-wrap flex-column">
                                            <h2 className="pb-2 no-desktop"><strong>Company Details</strong></h2>
                                            <p><strong>Booking Number:</strong> <span>{this.state.jobNo}</span></p>
                                            <p><strong>Date:</strong> <span>{convertDateISOToCustomiseFormat(invoiceDate)}</span></p>
                                            <p><strong>Payment Terms:</strong><span>{this.state.creditDays} Days</span></p>
                                            <p><strong>Our Gst Number:</strong> <span>{this.state.companyBranchData.gstNo}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="performa-text">
                            {this.state.houses.length > 0? <div className="orange-back">{this.state.queryFor ==="Air"?"HAWB DETAILS":"HBL DETAILS"}</div>:""}

                            <div className="performa-table">
                                {Array.isArray(this.state.houses) && this.state.houses.length > 0 ?
                                    this.state.houses.map((obj, i) => {
                                        return (
                                            <>
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{width:'25%'}}><strong>Shipper:</strong></td>
                                                        <td style={{width:'25%'}}>
                                                            <p className="small-12">
                                                                {obj.shipperId.shipperName}
                                                            </p>


                                                        </td>
                                                        <td style={{width:'25%'}}><strong>Consignee:</strong></td>
                                                        <td style={{width:'25%'}}>{obj.consigneeId.consigneeName}</td>
                                                    </tr>
                                                    <tr>

                                                        <td><strong>{this.state.queryFor ==="Air"?"HAWB No":"HBL No"}</strong></td>
                                                        <td>{obj.houseNo}</td>
                                                        <td><strong>No Of Pieces:</strong></td>
                                                        <td>{obj.noOfPieces}</td>
                                                    </tr>
                                                    <tr>
                                                        {this.state.queryFor !== "Air"?
                                                        <>
                                                            <td><strong>No. Of Container:</strong></td>
                                                            <td><p className="small-12">{obj.noOfContainers}</p></td>
                                                        </>
                                                            :""}
                                                    </tr>

                                                    </tbody>
                                                </table>
                                                <hr/>
                                            </>
                                        )
                                    })
                                    :
                                    undefined}
                            </div>
                        </div>
                        <div className="performa-text shipment-text">
                            <div className="orange-back shipment-back">Shipment Details</div>
                            <div className="performa-table">
                                <div className="invoice_big_data shipper_big_data d-flex flex-wrap justify-content-between">
                                    <p><strong>Gross weight(KGS):</strong> <span>{this.state.grossWeight ?this.state.grossWeight:grossWeight}</span></p>
                                    <p><strong>Quotation Number:</strong> <span>{this.state.jobNo}</span></p>
                                    <p><strong>Chargeable weight(KGS):</strong> <span>{this.state.chargeableWeight ? this.state.chargeableWeight: chargeableWeight}</span></p>
                                    <p><strong>No. of packages:</strong> <span>{this.state.noOfPieces ?this.state.noOfPieces:totalPieces}</span></p>
                                    <p><strong>Commodity HSN:</strong> <span>{this.state.hsnCode}</span></p>
                                    <p className="width-100"><strong>Commodity Description:</strong> <span>{this.state.commodity}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="performa-text shipper-text">
                            <div className="orange-back shipper-back">Sender/Receiver Details</div>
                            <div className="performa-table">
                                <div className="invoice_mob_display">
                                    <div className="d-flex flex-wrap">
                                        <div className="invoice_big_data shipper_big_data d-flex flex-wrap justify-content-between">
                                            <p><strong>SENDER:</strong> <span>{shipperName}</span></p>
                                            <p><strong>RECEIVER:</strong> <span>{consigneeName}</span></p>
                                            {this.state.PickUpCountry ==="India" ?
                                                <p><strong>Pick Up:</strong> <span>{this.state.PickUpZipcode}-{this.state.PickUpCountry}</span></p>:
                                                <p><strong>Pick Up:</strong> <span> {this.state.PickUpZipcode}-{this.state.PickUpCountry}</span></p>}
                                            {this.state.DropToCountry ==="India" ?
                                                <p><strong>Drop To:</strong> <span>{this.state.DropToZipcode}-{this.state.DropToCountry}</span></p>:
                                                <p><strong>Drop To:</strong> <span> {this.state.DropToZipcode}-{this.state.DropToCountry}</span></p>}
                                            <p><strong>Docket No:</strong> <span>{ this.state.blNo ==! ""  ?  this.state.blNo : this.state.jobNo}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="performa-text charges-text">
                            <div className="orange-back shipper-back">Charges</div>
                            <div className="performa-table">
                                <table className="table table-condensed">
                                    <thead>
                                    <tr>
                                        <td style={{width:'12.5%'}}><strong>Code</strong></td>
                                        <td style={{width:'12.5%'}} className="text-center"><strong>Description</strong></td>
                                    <td style={{width:'12.5%'}} className="text-center"><strong>Rate</strong></td>
                                    <td style={{width:'12.5%'}} className="text-center"><strong>QTY</strong></td>
                                    <td style={{width:'12.5%'}} className="text-center"><strong>Amount</strong></td>
                                    <td style={{width:'12.5%'}} className="text-center"><strong>Discount</strong></td>
                                        <td style={{width:'12.5%'}} className="text-center"><strong>GST</strong></td>
                                        <td style={{width:'12.5%'}} className="text-right"><strong>Total Amount</strong></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {otherChargesClearnce && otherChargesClearnce.length > 0 ?
                                        otherChargesClearnce.map((invoiceMap, i) => {
                                            invoiceMap.taxableC =invoiceMap.actualTaxableC || invoiceMap.taxableC
                                             totalAmount += invoiceMap.taxableC
                                             invoiceMap.discount = parseFloat(invoiceMap.discountC).toFixed(2)
                                             totalDiscount += Number(invoiceMap.discount)
                                            // console.log(JSON.stringify(invoiceMap))
                                            return (
                                                <tr key={i}>
                                                    <td>{invoiceMap && invoiceMap.chargeHsnCode ? invoiceMap.chargeHsnCode : ""}</td>
                                                    {invoiceMap && invoiceMap.chargeName == "Ocean Freight" ? <td className="text-center">{invoiceMap && invoiceMap.chargeName ? invoiceMap.chargeName : ""}{"-"}{invoiceMap && invoiceMap.chargeQty ? invoiceMap.chargeQty : " "}{" x "}{invoiceMap && invoiceMap.altName ? invoiceMap.altName : ""}</td> : <td className="text-center">{invoiceMap && invoiceMap.chargeName ? invoiceMap.chargeName : ""}</td>}
                                                    <td className="text-center">{invoiceMap && invoiceMap.currency ? invoiceMap.currency + " " : ""} {invoiceMap && invoiceMap.chargeRate ? parseFloat(invoiceMap.chargeRate).toFixed(2) : ""}</td>
                                                <td className="text-center">{invoiceMap && invoiceMap.chargeQty ? invoiceMap.chargeQty : ""}</td>
                                                <td className="text-center">{invoiceMap && invoiceMap.taxableC ? parseFloat(invoiceMap.taxableC).toFixed(2) : ""}</td>
                                                <td className="text-center">{invoiceMap && invoiceMap.discount ?parseFloat(invoiceMap.discount).toFixed(2) : ""}</td>

                                                    <td className="text-center">{invoiceMap && invoiceMap.chargeIgstRate ? invoiceMap.chargeIgstRate : ""}%</td>
                                                    <td className="text-right">{customerCurrency+' '} {invoiceMap && invoiceMap.chargeTaxableC ? parseFloat(invoiceMap.chargeTaxableC).toFixed(2) : ""}</td>
                                                </tr>)
                                        }) :
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }
                                    <tr>
                                        <td className="thick-line" />
                                        <td className="thick-line" />
                                        <td className="thick-line" />
                                        <td className="thick-line" />
                                        <td className="thick-line" />
                                        <td className="thick-line" />
                                        <td className="thick-line text-center"><strong>Total excl. GST</strong></td>
                                        <td className="thick-line text-right">{customerCurrency+' '} {taxableTotalAmountC !== "NaN" ? parseFloat(taxableTotalAmountC).toFixed(2) : 0}</td>
                                    </tr>
                                    <tr>
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line text-center"><strong>GST Amount</strong></td>
                                        <td className="no-line text-right">{customerCurrency+' '} {igstTotalAmountC !== "NaN" ? parseFloat(igstTotalAmountC).toFixed(2) : 0}</td>
                                    </tr>
                                    <tr>
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line" />
                                        <td className="no-line text-center"><strong>Total amount due</strong></td>
                                        <td className="no-line text-right">{customerCurrency+' '} {totalAmountC !== "NaN" ? parseFloat(totalAmountC).toFixed(2) : 0}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="performa-text shipper-text mb-2">
                            <div className="orange-back shipper-back text-left pl-1">Terms and Conditions</div>
                            <div className="performa-table">

                                <ul>
                                    <li>1.	Chargeable Weight: - Every shipment shall be charged by its chargeable weight as defined hereunder and not the actual weight. The chargeable weight shall be higher of (a) the actual weight rounded off to the next higher half kg. or one kg. as per the rate category agreed to or (b) the volume weight similarly rounded of as in (a) above. Volume weight of the shipment in kg. is its gross cubic Cms i.e., Length x Width x Height divided by 5000.</li>
                                    <li>2.	It is expressly understood by the Parties that all shipments agreed to be delivered by Zipaworld are on "SAID TO CONTAIN BASIS" i.e., Zipaworld shall be under no obligation to verify the description and contents of the shipments declared by the sender of the goods. The sender shall undertake to make proper, true, fair, correct and factual declaration regarding description and value of Shipments.</li>
                                    <li>3.	Non-Standard Shipment: - Single Piece weight more than 70 Kgs. Or any dimension more than 120 Cms.</li>
                                    <li>4.	Pick up Cut off timings: The pick-up requests raised prior to 12 noon will be picked up on the same day, whereas the pickup requests raised post 12 noon will be picked up on the next working day.</li>
                                    <li>5.	Zipaworld shall have the right to open / inspect the courier at the time of booking or during transit.</li>
                                    <li>6.	At the time of booking courier, the sender will be required to provide a declaration stating the value of the consignment and the detailed description of materials in the consignment and the same shall be binding on the receiver.</li>
                                    <li>7.	Unless and until otherwise specified, the delivery shall be made at the address mentioned in the consignment, in case of change in address extra charge will be applicable.</li>
                                    <li>8.	In the event of a failed pickup, the entire logistics charge / booking amount shall be refunded to the sender as per the refund policy.</li>
                                    <li>9.	The sender shall be solely responsible for all costs and expenses (GST, excise and other charges leviable by the Central / State / Local governments) and for all costs consequent to any penalty, action, proceedings in relation to the consignment.</li>
                                    <li>10.	Zipaworld shall not be liable for any delay in pickup, mis-delivery, non-delivery or loss or damage to it caused on account of an act of God, force majeure and or circumstances like strikes, riots, political and other disturbances or accidental fire, accident of vehicle carrying the goods explosions or any other cause reasonably beyond the control of Zipaworld.</li>
                                    <li>11.	Any claim (including for loss or damage) in respect of the courier shall be made in writing within three (3) days from the date of receipt and sent to info@zipaworld.com. In the case of damaged shipments, the consignor and / or consignee needs to share 3 photos of the damaged shipment and packaging along with the description of the damage. In absence of any such claims, made within the period stipulated here, Zipaworld shall not be liable for the same.</li>
                                    <li>12.	Zipaworld will be liable to compensate for any loss or damage of a shipment in transit to the extent of the declared value at the time of booking or Rs 2,000/- (Rupees Two Thousand Only), whichever is lower.</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="fixed-btnsbot">
    <div className="d-flex flex-wrap justify-content-end">
        <Button className="pdf manager" onClick={e => {
         if (this.state.queryFor == "Road"){
            e.preventDefault();
            this.props.history.push(PAGE_PATHS.shipmentHistoryRoad)
         }else {
            e.preventDefault();
            this.props.history.push(PAGE_PATHS.shipmentHistoryCourier)
         }
        }}>Previous</Button>
        <Button className="pdf manager" onClick={e => {
            e.preventDefault();
            this.props.history.push(PAGE_PATHS.MAIN_PAGE)
        }}>Home</Button>
        <Button className="pdf manager" onClick={e => {
            e.preventDefault();
            if(this.state.KycStatus === false){
                this.setState({gstLarge :true})
            } else {
                /*this.props.history.push(PAGE_PATHS.EDOCKET_PDF_VIEW + "/" + this.state.bookingId)*/
                if(this.state.quoteFor === "Road")
                {
                    this.props.history.push(PAGE_PATHS.PROFORMA_COURIER_VIEW + "/Road/" + this.state.bookingId)
                }

                else
                {
                    this.props.history.push(PAGE_PATHS.PROFORMA_COURIER_VIEW + "/Courier/" + this.state.bookingId)
                }
            }
        }}>Next</Button>

    </div>
</div>
</AppLayout>
        );
    }


}


const
    mapStateToProps = state => {
        const {
            items,
            totalItemCount,
            loading,
            error,
            response,
            airfreightFormValues
        } = state.invoiceMasterReducer;
        const {
            gstNumber,
        } = state.mawbReducer;

        return {
            items,
            totalItemCount,
            loading,
            error,
            response,
            airfreightFormValues,
            gstNumber,
        };
    };
const CourierBookingViewWithRoute = withRouter(CourierBookingView);

export default connect(mapStateToProps, {
    previewInvoiceDetail, getInvoiceData, sendtocheked,GstDataAction
})(CourierBookingViewWithRoute);