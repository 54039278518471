import {
    EDIT_STATE_DATA,
    EDIT_STATE_DATA_FAILURE,
    EDIT_STATE_DATA_FETCH,
    EDIT_STATE_DATA_SUCCESS,
    STATE_UPDATE,
    STATE_UPDATE_RESPONSE,
} from "../actions";

/**
 *
 * @Edit State
 */
export const EditStateData = (data) => ({
    type: EDIT_STATE_DATA_FETCH,
    payload:data,
});
export const EditStateDataSuccess = (data) => ({
    type: EDIT_STATE_DATA_SUCCESS,
    payload: data,
});
export const EditStateDataFailuer = (data) => ({
    type:EDIT_STATE_DATA_FAILURE,
    payload:data,
});

export const StateUpdateAction = (data) => ({
    type: STATE_UPDATE,
    payload:data,
});
export const updateStateResponse = (message) => ({
    type: STATE_UPDATE_RESPONSE,
    payload: {message}
});