import {
    HAWB_UPDATE,
    HAWB_UPDATE_RESPONSE
} from "../actions";

export const updateHawb = (HawbDetails) => ({
    type: HAWB_UPDATE,
    payload: HawbDetails
});

export const updateHawbResponce = (HawbDetailsResponse) => ({
    type: HAWB_UPDATE_RESPONSE,
    payload: HawbDetailsResponse
});
