import React, {Component} from "react";
import "react-tagsinput/react-tagsinput.css";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
import {connect} from "react-redux";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {parseFloatValue} from "../../../util/CommonUtility";
import DimensionViewForHouse from "../../../components/DimensionViewForHouse";
import {toast, ToastContainer} from "react-toastify";
import {GET_FCL_CONTAINER_DATA, MANAGER_CONSIGNEE_BRANCH, MANAGER_SHIPPER_BRANCH} from "../../../constants";
import RestClient from "../../../util/RestClient";
import ShipperWithBranchDropdownForShipperModal from "../../../components/shipperWithBranchDropdownForShipperModal";
import ConsigneeWithBranchDropdownForShipperModal from "../../../components/ConsigneeWithBranchDropdownForShipperModal";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const initialState = {
    shipperId: "",
    shipperName: "",
    shipperBranchName: "",
    shipperBranchId: "",
    shipperDetails: "",
    consigneeDetail: "",
    consigneeName: "",
    consigneeBranchName: "",
    queryDetails: {},
    hawbDropDownOptions: [],
    HblContainerList: [],
    remainingArrayCountForNext: [],
    remainingListForNext: [],
    availablePieces: 0,
    totalHouses: 0,
    houseNumber: "",
    shipmentMode: "",
    selectedHawb: "",
    noOfPieces: "",
    grossWeight: "",
    volumeWeight: "",
    queryFor: "",
    chargeableWeight: "",
    NoOfContainer: "",
    dimensions: [],
    updateData: {},
    asAgreed: "",
    shipperEmail:[],
    consigneeEmail:[],
    selectedDimensionType: "",
    houseSelected: false,
    houseDetails: true,
    houseQueryDetails: false,
};

class HouseDetailCard extends Component {
    TotalContainerCountHBL = [];

    constructor() {
        super();
        this.state = initialState;
        this.mandatoryValidations = new SimpleReactValidator({
            autoForceUpdate: this
        });
    }

    reset() {
        this.setState(initialState, () => {
            this.sendDataToParentComponent()
            setTimeout(() => {
                this.props.deleteHouse(this.props.houseIndex)
            }, 400)

        });
    }

    handleShipperSelected = ({shipperName, shipperId, shipperBranchName, shipperBranchId, shipperDetails}) => {
        let stateName= "";
        let Countriname ="";
        let pincode = "";
        let addressLine1 = "";
        let addressLine2 = "";

        if (shipperDetails) {
            if (Object.keys(shipperDetails).length > 0) {
                try {
                    addressLine1 = shipperDetails && shipperDetails.addressLine1 ? shipperDetails.addressLine1+ "\n " : ""
                    addressLine2 = shipperDetails && shipperDetails.addressLine2 ? shipperDetails.addressLine2+ "\n " : ""
                    stateName = shipperDetails && shipperDetails.stateId && shipperDetails.stateId.name ? shipperDetails.stateId.name : "";
                    Countriname = shipperDetails && shipperDetails.countryId && shipperDetails.countryId.name ? shipperDetails.countryId.name : "";
                    pincode = shipperDetails.pincode && shipperDetails.pincode !== ""?shipperDetails.pincode :"";

                } catch (e) {

                }
            }
        }

        this.setState({
            shipperName: shipperName,
            shipperId: shipperId,
            shipperBranchName: shipperBranchName,
            shipperBranchId: shipperBranchId,
            shipperEmail:shipperDetails.email ? shipperDetails.email : [],
            shipperDetails: shipperDetails && Object.keys(shipperDetails).length > 0 ? addressLine1 + addressLine2 + shipperDetails.city + " " + stateName + " " + Countriname + " " + pincode + "" : "",
        }, () => {
            this.setBranchesSelectionFromList(this.state.shipperId)
            this.sendDataToParentComponent()
            this.setState({
                showShipperPopHouse:false,
                  showShipperHouseBranchPop:false
                  
            })
        })
    }
    allShipperDeatils = () =>{
        let shipperDetails= this.state.branchDetail
        if (shipperDetails) {
            if (Object.keys(shipperDetails).length > 0) {
                try {
                    let stateName = shipperDetails && shipperDetails.stateId && shipperDetails.stateId.name ? shipperDetails.stateId.name : "";
                    let Countriname = shipperDetails && shipperDetails.countryId && shipperDetails.countryId.name ? shipperDetails.countryId.name : "";
                    let pincode = shipperDetails.pincode !== ""?shipperDetails.pincode :"";
                    let addressLine1 = shipperDetails && shipperDetails.addressLine1 ? shipperDetails.addressLine1+ "\n " : ""
                    let addressLine2 = shipperDetails && shipperDetails.addressLine2 ? shipperDetails.addressLine2+ "\n " : ""
                    this.setState({
                        shipperDetails: addressLine1 + addressLine2 + shipperDetails.city + " " + stateName + " " + Countriname + " " + pincode + "",
                    },()=>{
                        this.sendDataToParentComponent()
                    })
                } catch (e) {

                }
            }
        }
    }
    allConsigneeDeatils = () =>{
        let consigneeDetails= this.state.branchDetailConsignee
        if (consigneeDetails) {
            if (Object.keys(consigneeDetails).length > 0) {
                try {
                    let addressLine1 = consigneeDetails && consigneeDetails.addressLine1 ? consigneeDetails.addressLine1+ "\n " : ""
                    let addressLine2 = consigneeDetails && consigneeDetails.addressLine2 ? consigneeDetails.addressLine2+ "\n " : ""
                    let stateName = consigneeDetails && consigneeDetails.stateId && consigneeDetails.stateId.name ? consigneeDetails.stateId.name : "";
                    let Countriname = consigneeDetails && consigneeDetails.countryId && consigneeDetails.countryId.name ? consigneeDetails.countryId.name : "";
                    let pincode = consigneeDetails.pincode !== ""?consigneeDetails.pincode :"";
                    this.setState({
                        consigneeDetail: addressLine1 + addressLine2 + consigneeDetails.city + " " + stateName + " " + Countriname + " " + pincode  + "",

                        consigneeDetails: addressLine1 + addressLine2 + consigneeDetails.city + " " + stateName + " " + Countriname + " " + pincode  + "",
                    },()=>{
                        this.sendDataToParentComponent()
                    })
                } catch (e) {

                }
            }
        }
    }
    handleConsigneeSelected = ({
                                   consigneeName,
                                   consigneeId,
                                   consigneeBranchName,
                                   consigneeBranchId,
                                   consigneeDetails
                               }) => {
        let stateName= "";
        let Countriname= "";
        let pincode= "";
        let addressLine1 = "" ;
        let addressLine2 = "";
        if (consigneeDetails) {
            if (Object.keys(consigneeDetails).length > 0) {
                try {
                    addressLine1 = consigneeDetails && consigneeDetails.addressLine1 ? consigneeDetails.addressLine1+ "\n " : ""
                    addressLine2 = consigneeDetails && consigneeDetails.addressLine2 ? consigneeDetails.addressLine2+ "\n " : ""
                    stateName = consigneeDetails && consigneeDetails.stateId && consigneeDetails.stateId.name ? consigneeDetails.stateId.name : "";
                    Countriname = consigneeDetails && consigneeDetails.countryId && consigneeDetails.countryId.name ? consigneeDetails.countryId.name : "";
                    pincode =consigneeDetails.pincode && consigneeDetails.pincode !== ""?consigneeDetails.pincode :"";
                } catch (e) {

                }
            }
        }
        this.setState({
            consigneeName: consigneeName,
            consigneeId: consigneeId,
            consigneeBranchName: consigneeBranchName,
            consigneeBranchId: consigneeBranchId,
            consigneeEmail:consigneeDetails.email ? consigneeDetails.email :[],
            consigneeDetail: consigneeDetails && Object.keys(consigneeDetails).length > 0 ? addressLine1 + addressLine2 + consigneeDetails.city + " " + stateName + " " + Countriname + " " + pincode  + "" : ""
        }, () => {
            this.setConsigneeBranchesSelectionFromList(this.state.consigneeId)

            this.sendDataToParentComponent()
            this.setState({
                showConsigneeHousePop:false,
                showConsigneeHouseBranchPop:false
            })
        })
    }


    componentDidMount() {
        let getChargesObj = JSON.parse(localStorage.getItem('selectedCharge'))

        this.setState({
            getChargesObj:getChargesObj
        })
        if (Array.isArray(this.props.hawbDropDownOptions) && this.props.hawbDropDownOptions.length > 0) {
            this.setState({
                hawbDropDownOptions: this.props.hawbDropDownOptions
            },()=>{
            })
        }
        if (this.props.ContainerListForHBL) {

            if(this.props.RemainingContainerListForHBL !== undefined){ // for extra added house countainer list
                //     this.setState({
                //         HblContainerList: Array.isArray(this.props.RemainingContainerListForHBL) && this.props.RemainingContainerListForHBL.length >= 1 ? this.props.RemainingContainerListForHBL : []
                //     },()=>{
                //         let obj = {
                //             remainingListForNext : this.state.HblContainerList ,
                //             houseIndex: this.props.houseIndex
                //         }
                //         this.props.remainingArrayForNextHbl(obj)
                //     })
                //
                // }else{  // for First added house countainer list
                //
                //     this.setState({
                //         HblContainerList: Array.isArray(this.props.ContainerListForHBL) && this.props.ContainerListForHBL.length >= 1 ? this.props.ContainerListForHBL : []
                //     },()=>{
                //         let obj = {
                //             remainingListForNext : this.state.HblContainerList ,
                //             houseIndex: this.props.houseIndex
                //         }
                //         this.props.remainingArrayForNextHbl(obj)
                //     })



            }

        }


        if (this.props.NoOfContainer !== undefined ) {
            this.setState({
                NoOfContainer: this.props.NoOfContainer
            })
        }
        if (this.props.houseNumber !== undefined ) {
            this.setState({
                houseNumber: this.props.houseNumber
            })
        }


        if (this.props.ContainerCountArray) {

            if(this.props.RemainingContainerCountForHBL !== undefined){
                this.TotalContainerCountHBL = this.props.RemainingContainerCountForHBL
                // this.setState({
                //     NoOfContainer: sum
                // })

                // this.props.remainingArrayCountForNextHbl(obj)

            }else {
                this.TotalContainerCountHBL = this.props.ContainerCountArray

                // this.props.remainingArrayCountForNextHbl(obj)

            }

        }

        if (this.props.availablePieces) {
            this.setState({
                availablePieces: this.props.availablePieces
            })
        }
        if (this.props.houseSelected) {
            this.setState({
                houseSelected: this.props.houseSelected
            })
        }
        if (this.props.selectedDimensionType) {
            this.setState({
                selectedDimensionType: this.props.selectedDimensionType
            })
        }
        if (this.props.ShipperNameData) {
            this.setState({
                ShipperNameData: this.props.ShipperNameData
            })
        }
        if (this.props.ConsigneeNameData) {
            this.setState({
                ConsigneeNameData: this.props.ConsigneeNameData
            })
        }
        if (this.props.totalHouses) {
            this.setState({
                totalHouses: this.props.totalHouses,
                queryDetails: this.props.queryDetails,
                grossWeight:this.props.grossWeight,
                volumeWeight:this.props.volumeWeight,
                chargeableWeight:this.props.chargeableWeight

            }, () => {
                // if (this.state.totalHouses === 1) {

                this.setState({
                    noOfPieces: this.state.queryDetails.totalPieces ? this.state.queryDetails.totalPieces  : "" ,
                    actualnoOfPieces: this.state.queryDetails.totalPieces ? this.state.queryDetails.totalPieces  : "" ,
                    grossWeight: this.state.grossWeight ? this.state.grossWeight: this.state.queryDetails && this.state.queryDetails.grossWeight ? this.state.queryDetails.grossWeight : "",
                    volumeWeight:this.state.volumeWeight ? this.state.volumeWeight: this.state.queryDetails && this.state.queryDetails.volumeWeight ? this.state.queryDetails.volumeWeight : "",
                    queryFor: this.state.queryDetails && this.state.queryDetails.queryFor ? this.state.queryDetails.queryFor : "",
                    chargeableWeight:this.state.chargeableWeight ? this.state.chargeableWeight: this.state.queryDetails && this.state.queryDetails.chargeableWeight ? this.state.queryDetails.chargeableWeight : ""
                },()=>{
                })

                // this.props.noOfPiecesUsedByHouse({
                //     usedPieces: this.state.noOfPieces,
                //     houseIndex: this.props.houseIndex
                // })

                // }
                this.setState({ shipmentMode: this.state.queryDetails.shipmentMode})
            })
        }

        if (this.props.updateData) {

            this.setState({
                updateData: this.props.updateData
            }, () => {
                debugger
                this.setState({
                    shipperId: this.state.updateData.shipperDetails.shipperId,
                    shipperName: this.state.updateData.shipperDetails.shipperName,
                    shipperBranchId: this.state.updateData.shipperDetails.shipperBranchId,
                    shipperBranchName: this.state.updateData.shipperDetails.shipperBranchName,
                    shipperDetails: this.state.updateData.shipperDetails.shipperDetails,

                    consigneeId: this.state.updateData.consigneeDetail.consigneeId,
                    consigneeName: this.state.updateData.consigneeDetail.consigneeName,
                    consigneeBranchId: this.state.updateData.consigneeDetail.consigneeBranchId,
                    consigneeBranchName: this.state.updateData.consigneeDetail.consigneeBranchName,
                    consigneeDetail: this.state.updateData.consigneeDetail.consigneeDetail,

                    houseNumber: this.state.updateData.houseNumber,
                    selectedHawb: this.state.updateData.selectedHawb,
                    dimensions: this.state.updateData.dimensions,
                    // noOfPieces: this.state.updateData.chargesDetail.noOfPieces,
                    // grossWeight: this.state.updateData.chargesDetail.grossWeight,
                    // volumeWeight: this.state.updateData.chargesDetail.volumeWeight,
                    // chargeableWeight: this.state.updateData.chargesDetail.chargeableWeight,
                    // NoOfContainer: this.state.updateData.NoOfContainer,
                    // HblContainerList: this.state.updateData.HblContainerList,

                }, () => {
                    try {
                        if (!this.state.selectedHawb && this.state.houseNumber) {
                            this.setState({
                                selectedHawb: {
                                    label: this.state.houseNumber,
                                    value: this.state.houseNumber
                                }
                            })
                        }
                    } catch (e) {

                    }
                })
                this.setState({
                    totalPieces: this.state.updateData.totalPieces,
                    gross: this.state.updateData.gross,
                    volume: this.state.updateData.volume,
                    chargeable: this.state.updateData.chargeable,
                    dimensions: this.state.updateData.dimensions,
                    selectedDimensionType:this.state.updateData.selectedDimensionType,
                    shipperName: this.state.updateData.shipperName,
                    shipperBranchName: this.state.updateData.shipperBranchName,
                    consigneeName: this.state.updateData.consigneeName,
                    consigneeBranchName: this.state.updateData.consigneeBranchName,
                    houseNumber: this.state.updateData.houseNumber ?this.state.updateData.houseNumber:this.state.houseNumber,
                    selectedHawb: this.state.updateData.selectedHawb,
                    //      noOfPieces: this.state.updateData && this.state.updateData.chargesDetail.noOfPieces,
                    //     grossWeight:this.state.updateData && this.state.updateData.chargesDetail.grossWeight,
                    //     volumeWeight:this.state.updateData && this.state.updateData.chargesDetail.volumeWeight,
                    //    chargeableWeight:this.state.updateData && this.state.updateData.chargesDetail.chargeableWeight,
                    shipperId: this.state.updateData.shipperId,
                    shipperBranchId: this.state.updateData.shipperBranchId,
                    consigneeId: this.state.updateData.consigneeId,
                    consigneeBranchId: this.state.updateData.consigneeBranchId,
                    shipperDetails: this.state.updateData.shipperDetails,
                    consigneeDetail: this.state.updateData.consigneeDetail,
                    asAgreed: this.state.updateData.asAgreed,
                    NoOfContainer: this.state.updateData.NoOfContainer,
                    shipperEmail: this.state.updateData.shipperEmail,
                    consigneeEmail: this.state.updateData.consigneeEmail,
                    // HblContainerList: this.state.updateData.HblContainerList,
                    UploadedContainer: this.state.updateData.containerType !== undefined ? this.state.updateData.containerType : undefined,


                }, () => {
                })

                let {
                    totalPieces,
                    gross,
                    volume,
                    chargeable,
                    NoOfContainer,
                    dimensions,selectedDimensionType,
                    shipperName,
                    shipperBranchName,
                    consigneeName,
                    consigneeBranchName,
                    houseNumber,
                    selectedHawb,
                    noOfPieces,
                    HblContainerList,
                    grossWeight,
                    volumeWeight,
                    chargeableWeight,
                    shipperId,
                    shipperBranchId,
                    shipperEmail,
                    consigneeId,
                    consigneeBranchId,
                    consigneeEmail,
                    shipperDetails,
                    consigneeDetail,
                    asAgreed,
                } = this.state.updateData
                let houseData = {
                    totalPieces,
                    gross,
                    volume,
                    chargeable,
                    NoOfContainer,
                    HblContainerList,
                    dimensions,
                    shipperName,
                    shipperBranchName,
                    consigneeName,
                    consigneeBranchName,
                    houseNumber,
                    selectedHawb,
                    noOfPieces,
                    grossWeight,
                    volumeWeight,
                    chargeableWeight,
                    shipperId,
                    shipperBranchId,
                    consigneeId,
                    shipperEmail,
                    shipperDetails,
                    consigneeDetail,
                    consigneeBranchId,
                    consigneeEmail,
                    asAgreed,selectedDimensionType,
                    houseNo:houseNumber
                }
                this.props.houseDataChanged(houseData)

            })
        }
        this.fclOptions()
        this.sendDataToParentComponent()
    }

    fclOptions = () => {
        this.GetContainerData().then(result => {
            if (result && result.success) {
                let resultData = result && result.result ? result.result : ""

                this.setState({
                    HblContainerList: resultData
                }, () => {
                    let HblContainerList = [...this.state.HblContainerList];
                    if(this.state.UploadedContainer !== "" && this.state.UploadedContainer !== undefined && this.state.UploadedContainer.length >= 1 ){
                        if(Array.isArray(this.state.UploadedContainer)){
                            this.state.UploadedContainer.map((obj,i)=>{
                                this.state.HblContainerList.map((item,i)=>{
                                    if(item.container === obj.name){
                                        HblContainerList[i] = {...HblContainerList[i], count: obj.count};
                                    }
                                })
                            })
                        }
                    }
                    this.setState({HblContainerList:HblContainerList},()=>{
                        if(Array.isArray(HblContainerList)){
                            let NewArray=[]
                            for (let i = 0; i < HblContainerList.length; i++) {
                                if(Array.isArray(this.state.queryDetails.containerType)){
                                    for (let j = 0; j < this.state.queryDetails.containerType.length; j++) {
                                        if(this.state.queryDetails.containerType[j].name==HblContainerList[i].container){
                                            NewArray.push(HblContainerList[i])
                                        }
                                    }
                                }
                            }
                            this.setState({HblContainerList:NewArray})
                        }
                        //console.log("HBLCONTAINER&&&&&&&&&&"+JSON.stringify(this.state.HblContainerList))
                        //console.log("QUERY&&&&&&&&&&"+JSON.stringify(this.state.queryDetails.containerType))
                    })
                    if (!localStorage.getItem("SpotRateQuery") === false) {
                        let spotContainer =JSON.parse(localStorage.getItem("SpotRateQuery"))
                        let MultipleDataSlab = spotContainer && spotContainer.MultipleDataSlab ? spotContainer.MultipleDataSlab : []
                        this.state.HblContainerList.map((obj, i) => {
                            MultipleDataSlab.map((itm, j) => {
                                if (itm === "LCL") {
                                    this.setState({OnlyLCLSelected: true, shipmentMode: "LCL"})
                                } else if (obj.container == itm) {
                                    this.setState({OnlyFCLSelected:true})
                                    // this.incrementCount(obj, i)
                                }
                                // else {
                                //       this.state.ContainerList[i].push({disabled : true})
                                //
                                //   }
                            })

                        })

                    }
                })
            }
        })

    }
    GetContainerData = async (dataForServer) => {
        return await RestClient({
            url: GET_FCL_CONTAINER_DATA,
            method: "POST",
            data: dataForServer
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (Array.isArray(this.props.hawbDropDownOptions) && this.props.hawbDropDownOptions.length > 0 && prevProps.hawbDropDownOptions !== this.state.hawbDropDownOptions) {
            this.setState({
                hawbDropDownOptions: this.props.hawbDropDownOptions
            },()=>{
            })
        }
        if (prevProps.availablePieces !== this.props.availablePieces) {
            this.setState({
                availablePieces: this.props.availablePieces
            })
        }
        if (prevProps.houseNumber !== this.props.houseNumber) {
            this.setState({
                houseNumber: this.props.houseNumber
            },()=>{
                this.sendDataToParentComponent()
            })
        }

        if ( this.props.NoOfContainer !== undefined && prevProps.NoOfContainer !== this.props.NoOfContainer) {

            this.setState({
                NoOfContainer: this.props.NoOfContainer
            })

        }

        if (this.props.ContainerCountArray && prevProps.ContainerCountArray !== this.props.ContainerCountArray) {

            if(this.props.RemainingContainerCountForHBL !== undefined && prevProps.RemainingContainerCountForHBL !== this.props.RemainingContainerCountForHBL){
                this.TotalContainerCountHBL = this.props.RemainingContainerCountForHBL;
                // this.setState({
                //     NoOfContainer: sum
                // })

                // this.props.remainingArrayCountForNextHbl(obj)
            }else {
                this.TotalContainerCountHBL = this.props.ContainerCountArray;

                // this.props.remainingArrayCountForNextHbl(obj)

            }


        }
        if (prevProps.houseSelected !== this.props.houseSelected) {
            this.setState({
                houseSelected: this.props.houseSelected
            })
        }
        if (parseFloatValue(prevProps.totalPieces) !== parseFloatValue(this.props.totalPieces)) {
            this.setState({
                noOfPieces: parseFloatValue(this.props.totalPieces),
                grossWeight: parseFloatValue(this.props.grossWeight),
                volumeWeight: parseFloatValue(this.props.volumeWeight),
                chargeableWeight: parseFloatValue(this.props.chargeableWeight),
                allowedPiecesOverride: this.props.allowedPiecesOverride,
                availablePieces: this.props.availablePieces,

            })
        }
        if (this.props.dimensionArray && prevProps.dimensionArray !== this.props.dimensionArray) {
            this.setState({
                uploadedDimensions: this.props.dimensionArray
            })
        }
        if (this.props.houseContainerNumber && prevProps.houseContainerNumber !== this.props.houseContainerNumber) {

            this.setState({
                NoOfContainer: this.props.houseContainerNumber
            })
        }
        if (this.props.selectedDimensionType && prevProps.selectedDimensionType !== this.props.selectedDimensionType) {
            this.setState({
                selectedDimensionType: this.props.selectedDimensionType
            })
        }
        if (this.props.ContainerListForHBL && prevProps.ContainerListForHBL !== this.props.ContainerListForHBL) {

            if(this.props.RemainingContainerListForHBL !== undefined && prevProps.RemainingContainerListForHBL !== this.props.RemainingContainerListForHBL)
            { // for Extra added house countainer list
                // this.setState({
                //     HblContainerList: this.props.RemainingContainerListForHBL
                // },()=>{
                //     let obj = {
                //         remainingListForNext : this.state.HblContainerList ,
                //         houseIndex: this.props.houseIndex
                //     }
                //     this.props.remainingArrayForNextHbl(obj)
                // })

            }else { // for First added house countainer list
                //
                // this.setState({
                //     HblContainerList: this.props.ContainerListForHBL
                // },()=>{
                //     let obj = {
                //         remainingListForNext : this.state.HblContainerList ,
                //         houseIndex: this.props.houseIndex
                //     }
                //     this.props.remainingArrayForNextHbl(obj)
                // })

            }

        }

        if (this.props.totalHouses && prevProps.totalHouses !== this.props.totalHouses) {


            this.setState({
                totalHouses: this.props.totalHouses,
                queryDetails: this.props.queryDetails
            }, () => {
                if (this.state.totalHouses === 1) {
                    this.setState({
                        // noOfPieces: this.state.queryDetails.weight.noOfPieces,
                        // grossWeight: this.state.queryDetails.weight.gross,
                        // volumeWeight: this.state.queryDetails.weight.volume,
                        // chargeableWeight: this.state.queryDetails.weight.chargeable,

                    })

                }
                this.setState({ shipmentMode: this.state.queryDetails.shipmentMode})
            })
        }
        if (this.props.houseData && JSON.stringify(this.props.houseData) !== JSON.stringify(this.state.updateData)) {
            this.setState({
                updateData: this.props.houseData
            }, () => {

                // this.state.updateData.map((itm,i)=>{
                this.setState({
                    totalPieces: this.state.updateData.totalPieces,
                    gross: this.state.updateData.gross,
                    volume: this.state.updateData.volume,
                    chargeable: this.state.updateData.chargeable,
                    dimensions: this.state.updateData.dimensions,
                    shipperName: this.state.updateData.shipperName,
                    shipperBranchName: this.state.updateData.shipperBranchName,
                    consigneeName: this.state.updateData.consigneeName,
                    consigneeBranchName: this.state.updateData.consigneeBranchName,
                    houseNumber: this.state.updateData.houseNumber ?this.state.updateData.houseNumber:this.state.houseNumber,
                    selectedHawb: this.state.updateData.selectedHawb,
                    // noOfPieces: this.state.updateData.noOfPieces,
                    // grossWeight: this.state.updateData.grossWeight,
                    // volumeWeight: this.state.updateData.volumeWeight,
                    // chargeableWeight: this.state.updateData.chargeableWeight,
                    shipperId: this.state.updateData.shipperId,
                    shipperBranchId: this.state.updateData.shipperBranchId,
                    consigneeId: this.state.updateData.consigneeId,
                    consigneeBranchId: this.state.updateData.consigneeBranchId,
                    shipperDetails: this.state.updateData.shipperDetails,
                    consigneeDetail: this.state.updateData.consigneeDetail,
                    asAgreed: this.state.updateData.asAgreed,
                    NoOfContainer: this.state.updateData.NoOfContainer,
                    // HblContainerList: this.state.updateData.HblContainerList,
                    UploadedContainer: this.state.updateData.containerType !== undefined ? this.state.updateData.containerType : undefined,


                }, () => {
                    // alert(this.state.shipperName)
                })
                // })
            })
        }


    }

    sumArray = (input) => {
        // console.log("sumArray")
        if (toString.call(input) !== "[object Array]")
            return false;

        let total = 0;
        for (let i = 0; i < input.length; i++) {
            if (isNaN(input[i])) {
                continue;
            }
            total += Number(input[i]);
        }
        return total;
    }
    handleChangeGross = e => {
        let key = e.target.name
        let keyValue = e.target.value
        this.setState({[key]: keyValue}, () => {
            let grossWeightLbs = this.state.grossWeightLbs
            let grossWeight = this.state.grossWeight
            if (key == "grossWeightLbs") {
                //alert("gw changed")
                grossWeight = parseFloatValue(grossWeightLbs * 0.453592)
                this.setState({grossWeight: grossWeight}, () => {
                    this.props.dimensionOverallChanges({
                        weight: this.calculateOverallDimensionData(),
                        dimensionArray: this.state.uploadedDimensions,
                        selectedDimensionType: this.state.selectedDimensionType,
                    })
                })
            } else {
                //alert("gwL changed")
                grossWeightLbs= parseFloatValue(grossWeight*2.20462)
                this.setState({grossWeightLbs:grossWeightLbs},()=>{
                    this.props.dimensionOverallChanges({
                        weight: this.calculateOverallDimensionData(),
                        dimensionArray: this.state.uploadedDimensions,
                        selectedDimensionType: this.state.selectedDimensionType,
                    })
                })
            }
        });
    };
    handleChange = e => {
        this.setState({[e.target.name]: e.target.value}, () => {
            this.calculateChargeable().then(calculated => {
                this.sendDataToParentComponent()
            }).catch()
        });
    };
    calculateChargeable = async () => {
        try {
            if (this.state.volumeWeight && this.state.grossWeight) {
                let volumeWeight = parseInt(this.state.volumeWeight);
                let grossWeight = parseInt(this.state.grossWeight);

                grossWeight > volumeWeight
                    ? this.setState({
                        chargeableWeight: grossWeight
                    })
                    : this.setState({
                        chargeableWeight: volumeWeight
                    });

                if (this.state.grossWeight === "0") {
                    this.setState({
                        calculatedDensity: "0"
                    });
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.setState({
                        calculatedDensity: parseFloat(density).toFixed(2)
                    });
                }
            }
        } catch (e) {
        }
    };
    setBranchesSelectionFromList=(data) =>{
        if(data !== undefined){
            this.fetchShipperBranchList({id:data}).then(responce=>{
                if(responce && responce.result !== undefined){
                    this.setState({
                        shipperBranchDataList : responce && responce.result ? responce.result : []
                    })
                    let selectionOptionsListShipperBranch = []
                    for (let i = 0; i < responce.result.length; i++) {
                        let { _id, branchName } = responce.result[i]
                        let obj =
                            {
                                value: _id,
                                label: branchName,
                                branchDetail: responce.result[i],
                            };
                        selectionOptionsListShipperBranch.push(obj)
                    }

                    this.setState({
                        selectionOptionsShipperBranch: selectionOptionsListShipperBranch
                    });
                }
            })

        }

    }
    fetchShipperBranchList = async (shipperParameters) => {
        {
            return await RestClient({
                url: MANAGER_SHIPPER_BRANCH,
                method: "POST",
                data: shipperParameters
            });
        }
    };
    setConsigneeBranchesSelectionFromList=(data) =>{
        if(data !== undefined){
            this.fetchConsigneeBranchList({id:data}).then(responce=>{
                if(responce && responce.result !== undefined){
                    this.setState({
                        consigneeBranchDataList : responce && responce.result ? responce.result : []
                    })
                    let selectionOptionsListConsigneeBranch = []
                    for (let i = 0; i < responce.result.length; i++) {
                        let { _id, branchName } = responce.result[i]
                        let obj =
                            {
                                value: _id,
                                label: branchName,
                                branchDetail: responce.result[i],
                            };
                        selectionOptionsListConsigneeBranch.push(obj)
                    }

                    this.setState({
                        selectionOptionsConsigneeBranch: selectionOptionsListConsigneeBranch
                    });
                }
            })

        }

    }
    fetchConsigneeBranchList = async (shipperParameters) => {
        {
            return await RestClient({
                url: MANAGER_CONSIGNEE_BRANCH,
                method: "POST",
                data: shipperParameters
            });
        }
    };
    render() {
        return (
            // house details start
            <div className="house_card">
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="oringin-master">
                    {
    this.props.zipaSuggestionBook ?
        this.state.getChargesObj && this.state.getChargesObj.queryFor  === "Air" ?
            <>
                <h3># {this.props.houseIndex + 1} HAWB details</h3>
            </> :

            <>
                <h3># {this.props.houseIndex + 1} Hbl details</h3>
            </>
        :
        <>
            {this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air" ?
                <>
                   <h3># {this.props.houseIndex + 1} HAWB details</h3>
                </>
                :
                this.state.getChargesObj && this.state.getChargesObj.shipmentMode  !== "LCL" ?
                    <>
                       <h3># {this.props.houseIndex + 1} HBL details</h3>
                    </>
                    : ""
            }
        </>
}
                        {/* {
                            this.state.queryFor ==="Air" ?
                                <h3># {this.props.houseIndex + 1} HAWB details</h3>
                                :
                                <h3># {this.props.houseIndex + 1} HBL details</h3>
                        } */}

                    </div>
                    <div>
                        <button type="button" className={this.state.houseDetails?"btn active":"btn"} onClick={event => {
                            event.preventDefault()
                            this.setState({
                                houseDetails : true,
                                houseQueryDetails : false
                            })
                        }}> House Details
                        </button>
                        <button type="button" className={this.state.houseQueryDetails?"btn active":"btn"} onClick={event => {
                            event.preventDefault()
                            this.setState({
                                houseDetails : false,
                                houseQueryDetails : true
                            })
                        }}>House Query Details
                        </button>
                        <button type="button" className="btn" onClick={event => {
                            event.preventDefault()
                            this.reset()
                        }}>
                            <FontAwesomeIcon icon={faTrashAlt}/> Remove
                        </button>
                    </div>

                </div>
                <hr/>
                {this.state.houseDetails?
                <div className="d-flex flex-wrap justify-content-between align-items-start houseShipper mb-3">
                    <div className="new_banner_input ">
                    {
    this.props.zipaSuggestionBook ?
        this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air" ?
            <>
                <label> {" Hawb Number"}</label>
            </>
            :

            <>
                <label> {" Hbl Number"}</label>
            </>
        :
        <>
            {this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air" ?
                <>
                   <label> {" Hawb Number"}</label> 
                </>
                :
                this.state.getChargesObj && this.state.getChargesObj.shipmentMode !== "LCL" ?
                    <>
                      <label>{"Hbl Number"}</label>  
                    </>
                    : ""
            }
        </>
}
                        {/* <label>{this.state.queryFor ==="Air" ?"HAWB number":"HBL number"}</label> */}
                        <div className="box_new_query" onClick={()=>{
                            this.setState({
                                enterHouseNo : !this.state.enterHouseNo,
                                showConsigneeHouseBranchPop : false,
                                showConsigneeHousePop:false,
                                
                                showShipperHouseBranchPop : false,
                                showShipperPopHouse:false,
                                showShipperBranchPop:false,
                            })
                        }}>
                            <p>{this.state.houseNumber === undefined ? "" : this.state.houseNumber}</p>
                        </div>
                        <div className="popup_query_from" hidden={this.state.enterHouseNo !== true}>
                            <div className="popup_new">
                                <div className="origin_lables">
                                    {this.state.houseSelected === true ?
                                        <FormGroup>
                                            <Label for="houseNumber">
                                            {
    this.props.zipaSuggestionBook ?
        this.state.queryFor === "Air" ?
            "" :
            <>
               <b>{"HAWB number"}</b>

            </>
        :
        <>
            {this.state.queryFor === "Air" ?
                <>
                   <b>{"HAWB number"}</b>

                </>
                :
                this.state.shipmentMode !== "LCL" ?
                    <>
                        <b>{"Hbl number"}</b>

                    </>
                    : ""
            }
        </>
}
                                                
                                            </Label>
                                            <Input
                                                type="text"
                                                id="houseNumber"
                                                className="basicinput"
                                                name="houseNumber"
                                                onChange={this.handleChange}
                                                bsSize="sm"
                                                placeholder={this.state.queryFor ==="Air" ?"HAWB number":"HBL number"}
                                                value={this.state.houseNumber === undefined ? "" : this.state.houseNumber}
                                            />
                                        </FormGroup>
                                        :
                                        <>
                                            {!this.state.hawbDropDownOptions || this.state.houseNumber ?
                                                <div className="form-group">
                                                    {
    this.props.zipaSuggestionBook ?
        this.state.queryFor === "Air" ?
            "" :
            <>
                <Label for="accountType">{"HAWB Number"}</Label>
            </>
        :
        <>
            {this.state.queryFor === "Air" ?
                <>
                    <Label for="accountType">{" HAWB Number"}</Label>

                </>
                :
                this.state.shipmentMode !== "LCL" ?
                    <>
                        <Label for="accountType">{"Hbl Number"}</Label>

                    </>
                    : ""
            }
        </>
}
                                                    <Input
                                                        type="text"
                                                        id="houseNumber"
                                                        className="basicinput"
                                                        name="houseNumber"
                                                        onChange={this.handleChange}
                                                        bsSize="sm"
                                                        placeholder={this.state.queryFor ==="Air" ?"HAWB number":"HBL number"}
                                                        value={this.state.houseNumber === undefined ? "" : this.state.houseNumber}
                                                    />
                                                </div>:
                                                <FormGroup>
                                                    
                                                    {
    this.props.zipaSuggestionBook ?
        this.state.queryFor === "Air" ?
            "" :
            <>
                <Label for="House Number">{"Select HAWB"}</Label>
            </>
        :
        <>
            {this.state.queryFor === "Air" ?
                <>
                    <Label for="House Number">{"Select HAWB"}</Label>

                </>
                :
                this.state.shipmentMode !== "LCL" ?
                    <>
                        <Label for="House Number">{"Select Hbl"}</Label>

                    </>
                    : ""
            }
        </>
}
                                                    <Input
                                                        type="text"
                                                        min="1"
                                                        className="form-control ipt11"
                                                        name="houseNumber"
                                                        value={Array.isArray(this.state.hawbDropDownOptions) && this.state.hawbDropDownOptions.length>this.props.houseIndex?
                                                            this.state.hawbDropDownOptions[this.props.houseIndex].value:""}
                                                        onChange={this.handleChange}
                                                        placeholder="House Number"
                                                        readOnly={true}
                                                    />


                                                </FormGroup>
                                            }
                                        </>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="new_banner_input">
                        <label>Shipper Name</label>
                        <div className="box_new_query" onClick={e => {
                            e.preventDefault()
                            this.setState({
                                showShipperPopHouse : !this.state.showShipperPopHouse,
                                showShipperBranchPop:false,
                                showShipperHouseBranchPop:false,
                                showConsigneePop : false,
                                showConsigneeBranchPop:false,
                                showConsigneeHouseBranchPop : false,
                                showConsigneeHousePop:false,
                                enterHouseNo:false
                            })
                        }}>
                            <p>{this.state.shipperName?this.state.shipperName:"Shipper Name"}</p>
                        </div>
                        <div className="popup_query_from" hidden={this.state.showShipperPopHouse !== true}>
                            <div className="popup_new">
                                <div className="origin_lable">
                                    <ShipperWithBranchDropdownForShipperModal
                                        onlyShipper={this.state.showShipperPopHouse}
                                        onlyShipperBranch={this.state.showShipperHouseBranchPop}
                                        selectedOptionCompany={this.state.selectedOptionCompany}
                                        selectionOptionsShipperBranch={this.state.selectionOptionsShipperBranch}
                                        isSelectionThroughName={false}
                                        branchData = {(values)=>{
                                            this.setState({
                                                selectionOptionsShipperBranch : values
                                            })
                                        }}
                                        selectedShipperId={this.state.shipperId}
                                        selectedShipperName={this.state.shipperName}
                                        selectedShipperBranchName={this.state.shipperBranchName}
                                        selectedShipperBranchId={this.state.shipperBranchId}
                                        onShipperConsigneeSelected={this.handleShipperSelected}
                                        shipperEmail={this.state.shipperEmail}
                                    />
                                </div>
                                <div className="popular_listing">
                                    <h4>Recently Use</h4>
                                    <div className="listing_items">
                                        {this.state.ShipperNameData !== undefined && this.state.ShipperNameData ?

                                            this.state.ShipperNameData.map((item, i) => {
                                                return (
                                                    <div className="items_list" onClick={() => {
                                                        let resultValue = {
                                                            value: item._id,
                                                            label: item.shipperName,
                                                        }
                                                        this.setState({
                                                            selectedOptionCompany: resultValue,
                                                            shipperId:resultValue.value,
                                                            shipperName:resultValue.label,
                                                        },()=>{
                                                            this.setBranchesSelectionFromList(this.state.shipperId)
                                                this.sendDataToParentComponent()

                                                            this.setState({
                                                                showShipperPopHouse:false,
                                                                showShipperHouseBranchPop:true
                                                            })
                                                        })

                                                    }}>
                                                        <div className="airortName_listing">
                                                            <h5>{item && item.shipperName}</h5>
                                                            <h6>{item && item.name}</h6>
                                                        </div>
                                                        <div className="code_listing">{item && item.code}</div>
                                                    </div>
                                                )
                                            }) : ""}

                                    </div> </div>
                            </div>
                        </div>
                    </div>
                    <div className="new_banner_input">
                        <label>Shipper Branch</label>
                        <div className="box_new_query" onClick={e => {
                            e.preventDefault()
                            this.setState({
                                showShipperHouseBranchPop : !this.state.showShipperHouseBranchPop,
                                showShipperPopHouse:false,
                                showConsigneeHouseBranchPop : false,
                                showConsigneeHousePop:false,
                                showShipperPop:false,
                                showConsigneePop : false,
                                showConsigneeBranchPop:false,
                                enterHouseNo:false
                            })
                        }}>
                            <p>{this.state.shipperBranchName?this.state.shipperBranchName:"Shipper Branch"}</p>
                        </div>

                        <div className="popup_query_from" hidden={this.state.showShipperHouseBranchPop !== true}>
                            <div className="popup_new">
                                <div className="origin_lable">
                                    <ShipperWithBranchDropdownForShipperModal
                                        onlyShipperBranch={this.state.showShipperHouseBranchPop}
                                        onlyShipper={this.state.showShipperPopHouse}
                                        selectedOptionCompany={this.state.selectedOptionCompany}
                                        selectionOptionsShipperBranch={this.state.selectionOptionsShipperBranch}
                                        isSelectionThroughName={false}
                                        branchData = {(values)=>{
                                            this.setState({
                                                selectionOptionsShipperBranch : values
                                            })
                                        }}
                                        selectedShipperId={this.state.shipperId}
                                        selectedShipperName={this.state.shipperName}
                                        selectedShipperBranchName={this.state.shipperBranchName}
                                        selectedShipperBranchId={this.state.shipperBranchId}
                                        shipperEmail={this.state.shipperEmail}
                                        onShipperConsigneeSelected={this.handleShipperSelected}
                                    />
                                </div>
                                <div className="popular_listing">
                                    <h4>Shipper Branch</h4>
                                    <div className="listing_items">
                                        {this.state.shipperBranchDataList !== undefined && this.state.shipperBranchDataList ?

                                            this.state.shipperBranchDataList.map((item, i) => {
                                                return (
                                                    <div className="items_list" onClick={() => {
                                                        let resultValue = {
                                                            value: item._id,
                                                            label: item.branchName,
                                                            branchDetail: item,
                                                        }
                                                        this.setState({
                                                            branchDetail: resultValue.branchDetail,
                                                            shipperBranchId:resultValue.value,
                                                            shipperBranchName:resultValue.label,
                                                            shipperEmail:resultValue.branchDetail ? resultValue.branchDetail.email :[]
                                                        },()=>{
                                                            this.allShipperDeatils()
                                                            this.setState({
                                                                showShipperHouseBranchPop:false,
                                                                showConsigneeHousePop:true
                                                            })
                                                        })

                                                    }}>
                                                        <div className="airortName_listing">
                                                            <h5>{item && item.branchName}</h5>
                                                            <h6>{item && item.stateId && item.stateId.name}</h6>
                                                        </div>
                                                        {/*<div className="code_listing">{item && item.code}</div>*/}
                                                    </div>
                                                )
                                            }) : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="new_banner_input">
                        <label>Consignee Name</label>
                        <div className="box_new_query" onClick={e => {
                            e.preventDefault()
                            this.setState({
                                showConsigneeHousePop : !this.state.showConsigneeHousePop,
                                showConsigneeHouseBranchPop:false,
                                showShipperHouseBranchPop : false,
                                showShipperPopHouse:false,
                                showConsigneeBranchPop:false,
                                showShipperPop:false,
                                showShipperBranchPop:false,
                                enterHouseNo:false
                            })
                        }}>
                            <p>{this.state.consigneeName?this.state.consigneeName:"Consignee Name"}</p>
                        </div>
                        <div className="popup_query_from" hidden={this.state.showConsigneeHousePop !== true}>
                            <div className="popup_new">
                                <div className="origin_lable">
                                    <ConsigneeWithBranchDropdownForShipperModal
                                        reloadConsignee={this.state.showConsigneeHouseBranchPop}
                                        onlyConsignee={this.state.showConsigneeHousePop}
                                        onlyConsigneeBranch={this.state.showConsigneeBranchPop}
                                        selectedOptionConsignee={this.state.selectedOptionConsignee}
                                        selectionOptionsConsigneeBranch={this.state.selectionOptionsConsigneeBranch}
                                        branchData = {(values)=>{
                                            this.setState({
                                                selectionOptionsConsigneeBranch : values
                                            })
                                        }}
                                        isSelectionThroughName={false}
                                        selectedConsigneeId={this.state.consigneeId}
                                        selectedConsigneeName={this.state.consigneeName}
                                        selectedConsigneeBranchName={this.state.consigneeBranchName}
                                        consigneeDetails={this.state.consigneeDetails}
                                        consigneeEmail={this.state.consigneeEmail}
                                        onShipperConsigneeSelected={this.handleConsigneeSelected}
                                    />
                                </div>
                                <div className="popular_listing">
                                    <h4>Recently Use</h4>
                                    <div className="listing_items">
                                        {this.state.ConsigneeNameData !== undefined && this.state.ConsigneeNameData ?

                                            this.state.ConsigneeNameData.map((item, i) => {
                                                return (
                                                    <div className="items_list" onClick={() => {
                                                        let resultValue = {
                                                            value: item._id,
                                                            label: item.consigneeName,
                                                        }
                                                        this.setState({
                                                            selectedOptionConsignee: resultValue,
                                                            consigneeId:resultValue.value,
                                                            consigneeName:resultValue.label,
                                                        },()=>{
                                                            this.setConsigneeBranchesSelectionFromList(this.state.consigneeId)
                                                this.sendDataToParentComponent()

                                                            this.setState({
                                                                showConsigneeHousePop:false,
                                                                showConsigneeHouseBranchPop:true
                                                            })
                                                        })

                                                    }}>
                                                        <div className="airortName_listing">
                                                            <h5>{item && item.consigneeName}</h5>
                                                            <h6>{item && item.name}</h6>
                                                        </div>
                                                        <div className="code_listing">{item && item.code}</div>
                                                    </div>
                                                )
                                            }) : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="new_banner_input">
                        <label>Consignee Branch</label>
                        <div className="box_new_query" onClick={e => {
                            e.preventDefault()
                            this.setState({
                                showConsigneeHouseBranchPop : !this.state.showConsigneeHouseBranchPop,
                                showConsigneeHousePop:false,
                                showConsigneePop:false,
                                showShipperPop:false,
                                showShipperHouseBranchPop : false,
                                showShipperPopHouse:false,
                                showShipperBranchPop:false,
                                enterHouseNo:false
                            })
                        }}>
                            <p>{this.state.consigneeBranchName?this.state.consigneeBranchName:"Consignee Branch"}</p>
                        </div>
                        <div className="popup_query_from" hidden={this.state.showConsigneeHouseBranchPop !== true}>
                            <div className="popup_new">
                                <div className="origin_lable">
                                    <ConsigneeWithBranchDropdownForShipperModal
                                        reloadConsignee={this.state.showConsigneeHouseBranchPop}
                                        onlyConsignee={this.state.showConsigneeHousePop}
                                        onlyConsigneeBranch={this.state.showConsigneeHouseBranchPop}
                                        selectedOptionConsignee={this.state.selectedOptionConsignee}
                                        selectionOptionsConsigneeBranch={this.state.selectionOptionsConsigneeBranch}
                                        branchData = {(values)=>{
                                            this.setState({
                                                selectionOptionsConsigneeBranch : values
                                            })
                                        }}
                                        isSelectionThroughName={false}
                                        selectedConsigneeId={this.state.consigneeId}
                                        selectedConsigneeName={this.state.consigneeName}
                                        selectedConsigneeBranchName={this.state.consigneeBranchName}
                                        consigneeDetails={this.state.consigneeDetails}
                                        consigneeEmail={this.state.consigneeEmail}
                                        onShipperConsigneeSelected={this.handleConsigneeSelected}
                                    />

                                </div>
                                <div className="popular_listing">
                                    <h4>Consignee Branch</h4>
                                    <div className="listing_items">
                                        {this.state.consigneeBranchDataList !== undefined && this.state.consigneeBranchDataList ?

                                            this.state.consigneeBranchDataList.map((item, i) => {
                                                return (
                                                    <div className="items_list" onClick={() => {
                                                        let resultValue = {
                                                            value: item._id,
                                                            label: item.branchName,
                                                            branchDetail: item,
                                                        }
                                                        this.setState({
                                                            branchDetailConsignee: resultValue.branchDetail,
                                                            consigneeBranchId:resultValue.value,
                                                            consigneeBranchName:resultValue.label,
                                                            consigneeEmail: resultValue && resultValue.branchDetail.email ? resultValue.branchDetail.email :[]
                                                        },()=>{
                                                            this.allConsigneeDeatils()
                                                            this.setState({
                                                                showConsigneeHouseBranchPop:false
                                                            })
                                                        })

                                                    }}>
                                                        <div className="airortName_listing">
                                                            <h5>{item && item.branchName}</h5>
                                                            <h6>{item && item.stateId && item.stateId.name}</h6>
                                                        </div>
                                                        {/*<div className="code_listing">{item && item.code}</div>*/}
                                                    </div>
                                                )
                                            }) : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :""}
                {this.state.houseQueryDetails?
                    <>
                        {/*enter_shipper_inputs*/}
                        <div className="d-flex flex-wrap justify-content-between align-items-start houseShipper mb-3">
                            <div className="new_courier_input new_Package_Details">
                                <div className="form-group">
                                    <label>No of Pieces</label>
                                    <Input
                                        type="number"
                                        min="1"
                                        className="form-control ipt11"
                                        name="actualnoOfPieces"
                                        pattern="[0-9]"
                                        value={this.state.actualnoOfPieces}
                                        onChange={e => {
                                            e.preventDefault()
                                            this.setState({[e.target.name]: e.target.value}, () => {
                                                this.props.noOfPiecesUsedByHouse({
                                                    usedPieces: 0,
                                                    houseIndex: this.props.houseIndex
                                                })

                                            })
                                        }}
                                        placeholder="No of Pieces"
                                    />
                                    {this.mandatoryValidations.message(
                                        "actualnoOfPieces",
                                        this.state.actualnoOfPieces,
                                        "required"
                                    )}
                                </div>
                            </div>
                            <div className="new_courier_input new_Package_Details">
                                <FormGroup>
                                    <Label for="Gross Weight">Gr Wt{this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL" ? "(Tons)":"(Kg)"}</Label>
                                    <Input
                                        type="number"
                                        min="1"
                                        className="form-control ipt11"
                                        name="grossWeight"
                                        value={this.state.grossWeight}
                                        onBlur={this.handleBlur}
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                        placeholder="Weight"
                                    />
                                    <span className="text-danger">
                                        {this.mandatoryValidations.message(
                                            "grossWeight",
                                            this.state.grossWeight,
                                            "required|numeric"
                                        )}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="new_courier_input new_Package_Details">
                                <FormGroup>

                                    <Label for="Volume Weight">{this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air" ? "Vol Wt(Kg)" : "Vol Wt(CBM)"}</Label>
                                    <Input
                                        type="number"
                                        min="1"
                                        className="form-control ipt11"
                                        name="volumeWeight"
                                        value={this.state.volumeWeight}
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                        onBlur={this.handleBlur}
                                        placeholder="Volume"
                                    />
                                    <span className="text-danger">
                                        {this.mandatoryValidations.message(
                                            "volumeWeight",
                                            this.state.volumeWeight,
                                            "required|numeric"
                                        )}
                                    </span>
                                </FormGroup>
                            </div>
                            {this.props.zipaSuggestionBook ?
                                this.state.query === "Air"?"" : <div className="new_courier_input">
                                    <FormGroup>
                                        <Label for="Chargeable Weight">{this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air" ? "Ch Wt(Kg)" : "Ch Wt(CBM)"}
                                        </Label>
                                        <Input
                                            type="number"
                                            min="0"
                                            className="form-control ipt11"
                                            name="chargeableWeight"
                                            placeholder="Chargeable"
                                            onChange={e => {
                                                this.setState({[e.target.name]: e.target.value}, () => {
                                                    this.sendDataToParentComponent()
                                                });
                                            }}
                                            value={this.state.chargeableWeight}
                                        />
                                    </FormGroup>
                                </div>
                                :
                                this.state.query === "Air" ?
                                    <div className="new_courier_input">
                                        <FormGroup>
                                            <Label for="Chargeable Weight">Ch Wt(Kg)
                                            </Label>
                                            <Input
                                                type="number"
                                                min="0"
                                                className="form-control ipt11"
                                                name="chargeableWeight"
                                                placeholder="Chargeable"
                                                onChange={e => {
                                                    this.setState({[e.target.name]: e.target.value}, () => {
                                                        this.sendDataToParentComponent()
                                                    });
                                                }}
                                                value={this.state.chargeableWeight}
                                            />
                                        </FormGroup>
                                    </div>:""
                            }
                            {this.state.query === "Air" ?
                            <div className="new_courier_input">
                                <FormGroup>
                                    <Label for="Chargeable Weight">Ch Wt(Kg)
                                    </Label>
                                    <Input
                                        type="number"
                                        min="0"
                                        className="form-control ipt11"
                                        name="chargeableWeight"
                                        placeholder="Chargeable"
                                        onChange={e => {
                                            this.setState({[e.target.name]: e.target.value}, () => {
                                                this.sendDataToParentComponent()
                                            });
                                        }}
                                        value={this.state.chargeableWeight}
                                    />
                                </FormGroup>
                            </div>:""}
                        </div>
                        <Row>
                            <Col lg="3" hidden={true}>
                                <div className="AAA-editbooking-house-details-3">
                                    <FormGroup check>
                                        <Input
                                            type="checkbox"
                                            name="asAgreed"
                                            onChange={event => {
                                                event.preventDefault()
                                                this.setState({
                                                    asAgreed: event.target.checked
                                                })
                                            }}
                                            value={this.state.asAgreed}
                                            placeholder=""
                                        />
                                        <Label>As Agreed</Label>
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <DimensionViewForHouse
                                    case4={this.props.case4}
                                    allowedPiecesOverride={false}
                                    totalPieces={this.state.noOfPieces}
                                    actualPieces={this.state.actualnoOfPieces}
                                    queryFor={this.state.queryFor}
                                    grossWeight={this.state.grossWeight}
                                    volumeWeight={this.state.volumeWeight}
                                    chargeableWeight={this.state.chargeableWeight}
                                    dimensionArray={this.state.dimensions}
                                    selectedDimensionType={this.state.selectedDimensionType}
                                    shipmentMode={this.state.shipmentMode}
                                    dimensionOverallChanges={changedDimensionData => {
                                        this.setState({
                                            totalPieces: changedDimensionData.weight.totalPieces,
                                            noOfPieces: changedDimensionData.weight.totalPieces,
                                            gross: parseFloatValue(changedDimensionData.weight.totalGross) === 0 ? this.state.gross : changedDimensionData.weight.totalGross,
                                            grossWeight: parseFloatValue(changedDimensionData.weight.totalGross) === 0 ? this.state.gross : changedDimensionData.weight.totalGross,
                                            volume: changedDimensionData.weight.totalVolume,
                                            volumeWeight: changedDimensionData.weight.totalVolume,
                                            chargeable: changedDimensionData.weight.totalChargeable,
                                            dimensions: changedDimensionData.dimensionArray,
                                            selectedDimensionType: changedDimensionData.selectedDimensionType,
                                        }, () => {
                                            this.calculateChargeable().then(responce => {
                                                this.sendDataToParentComponent()
                                            }).catch()

                                        })
                                        // console.log("#" + (this.props.houseIndex + 1) + "houseData::" + JSON.stringify(changedDimensionData))
                                    }}
                                />
                            </Col>
                        </Row>
                    </>:""}

            </div>
            // house details end
        )
    }

    sendDataToParentComponent() {
        // console.log("sendDataToParentComponent")
        let {
            totalPieces,
            gross,
            volume,
            chargeable,
            NoOfContainer,
            dimensions,selectedDimensionType,
            shipperName,
            shipperBranchName,
            consigneeName,
            consigneeBranchName,
            houseNumber,
            selectedHawb,
            noOfPieces,
            HblContainerList,
            grossWeight,
            volumeWeight,
            chargeableWeight,
            shipperId,
            shipperBranchId,
            shipperEmail,
            consigneeId,
            consigneeBranchId,
            consigneeEmail,
            shipperDetails,
            consigneeDetail,
            asAgreed,
        } = this.state
        let houseData = {
            totalPieces,
            gross,
            volume,
            chargeable,
            NoOfContainer,
            HblContainerList,
            dimensions,
            shipperName,
            shipperBranchName,
            consigneeName,
            consigneeBranchName,
            houseNumber,
            selectedHawb,
            noOfPieces,
            grossWeight,
            volumeWeight,
            chargeableWeight,
            shipperId,
            shipperBranchId,
            consigneeId,
            shipperEmail,
            shipperDetails,
            consigneeDetail,
            consigneeBranchId,
            consigneeEmail,
            asAgreed,selectedDimensionType,
            houseNo:houseNumber
        }
        //alert(houseData.houseNumber)
        this.props.houseDataChanged(houseData)
    }
}


const mapStateToProps = state => {
    const {} = state.advanceSearchReducer;

    return {};
};

export default connect(mapStateToProps, {})(HouseDetailCard);
