import React, { Component} from "react";
import FooterHome from "./FooterHome";
import Slider from "react-slick";
import AppLayout from "../../../layout/AppLayout";
import RestClient from "../../../util/RestClient";
import {CERTIFICATION_AWARDS_API} from "../../../constants";
class AwardsAndRecognition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            certificationData : []
        }
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
        this.certificationsAwardsApiData().then(responce=>{
            if(responce.success){
                let CertificationData = responce && responce.result ? responce.result : [];

                this.setState({
                    certificationData:CertificationData,
                },()=>{
                    let certificateDataOnly = []
                    let awardDataOnly = []
                    Array.isArray(this.state.certificationData) && this.state.certificationData.map((obj, i) => {
                        if(obj.imageType === "Certifications"){
                            certificateDataOnly.push(obj)
                        }
                    })
                    Array.isArray(this.state.certificationData) && this.state.certificationData.map((item, i) => {
                        if(item.imageType === "Awards"){
                            awardDataOnly.push(item)
                        }
                    })
                    this.setState({
                        certificateDataOnly : certificateDataOnly,
                        awardDataOnly : awardDataOnly,
                    })
                })
            }
        })
    }
    certificationsAwardsApiData = async (dataForServer) => {
        return await RestClient({
            url: CERTIFICATION_AWARDS_API,
            method: "POST",
            data: dataForServer
        })
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            autoplay:true,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                        dots: true,
                        autoplay:true,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                        initialSlide: 2,
                        dots: true,
                        autoplay:true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        slidesToScroll: 1,
                        dots: false,
                        autoplay:true,
                    }
                }
            ]
        };
        return (
            <>
            <AppLayout>
            <div className="awards_section" ref={this.testRef}>
                <div className="container">
                    <div className="">
                        <h4>Certifications and <span>Memberships</span></h4>
                        {/*<div className="d-flex flex-wrap align-items-stretch">*/}
                        <Slider {...settings}>
                            {Array.isArray(this.state.certificateDataOnly) && this.state.certificateDataOnly.map((obj, i) => {
                                return(
                                    <>
                                        <div>
                                            <div className="certificate">
                                                <div className="certificate_image">
                                                    <img src={obj && obj.attachments && obj.attachments[0]?obj.attachments[0]:""} alt={obj.imageName}/>
                                                </div>
                                                <h3>{obj.imageName}</h3>
                                            </div>
                                        </div>

                                    </>
                                )
                            })
                        }
                        </Slider>
                        {/*</div>*/}
                    </div>
                    <div className="awards_items">
                        <h4>Awards and <span>Accolades</span></h4>
                        <div className="d-flex flex-wrap align-items-stretch">
                            {Array.isArray(this.state.awardDataOnly) && this.state.awardDataOnly.map((obj, i) => {
                                return (
                                    <>
                                        <div className="certificate awards_certificates">
                                            <div className="certificate_image">
                                                <img src={obj && obj.attachments && obj.attachments[0]?obj.attachments[0]:""} alt={obj.imageName}/>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            </AppLayout>
            <FooterHome/>
            </>
        );
    }


}




export default AwardsAndRecognition;
