import {all, call, fork, put} from 'redux-saga/effects';
import RestClient from "../../util/RestClient";
import {NOTIFICATION_LOAD, NOTIFICATION_READ,} from "../../constants";

import {NOTIFICATION_LOAD_REQUEST, NOTIFICATION_READ_CONFIRM} from "../actions";
import {takeLatest} from "@redux-saga/core/effects";

import {
    onNotificationLoadRequestServerRespondBackSuccess,
    readNotificationHitRespondBackSuccess
} from "./notificationAction";

export function* watchOriginDestination() {
    yield takeLatest(NOTIFICATION_LOAD_REQUEST, loadUserNotification);
    yield takeLatest(NOTIFICATION_READ_CONFIRM, loadReadNotification);
}

function* loadUserNotification({payload}) {
    // console.log("In fetch NOTIFICATION_LOAD_REQUEST" + JSON.stringify(payload))
    try {
        const notificationResponse = yield call(loadUserNotificationAsync, payload);
        // console.log("fetch Booking Detail" + notificationResponse);
        if (notificationResponse.success) {
            // toastr.success(notificationResponse.message)
            yield put(onNotificationLoadRequestServerRespondBackSuccess(notificationResponse.result));
        } else {
            // toastr.error(notificationResponse.message)
            // yield put(onNotificationLoadRequestServerRespondBackFailure(notificationResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error  notification " + JSON.stringify(error))
            // yield put(onNotificationLoadRequestServerRespondBackFailure(error.response.data.message));
        }
    }
}

const loadUserNotificationAsync = async (payload) => {
    {
        // console.log(JSON.stringify("In fetch  notification  API" + JSON.stringify(payload)))
        return await RestClient({
            url: NOTIFICATION_LOAD,
            method: "POST",
            data: payload
        })

    }
}

function* loadReadNotification({payload}) {
    // console.log("In fetch NOTIFICATION_LOAD_REQUEST" + JSON.stringify(payload))
    try {
        const notificationResponse = yield call(loadReadNotificationAsync, payload);
        // console.log("fetch Booking Detail" + notificationResponse);
        if (notificationResponse.success) {
            // toastr.success(notificationResponse.message)
            yield put(readNotificationHitRespondBackSuccess(notificationResponse.result));
        } else {
            // toastr.error(notificationResponse.message)
            // yield put(onNotificationLoadRequestServerRespondBackFailure(notificationResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error  notification " + JSON.stringify(error))
            // yield put(onNotificationLoadRequestServerRespondBackFailure(error.response.data.message));
        }
    }
}

const loadReadNotificationAsync = async (payload) => {
    {
        // console.log(JSON.stringify("In fetch  notification  API" + JSON.stringify(payload)))
        return await RestClient({
            url: NOTIFICATION_READ,
            method: "POST",
            data: payload
        })

    }
}


export default function* Saga() {
    yield all([
        fork(watchOriginDestination)
    ]);
}
