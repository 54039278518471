import {MapLayer}  from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import "lrm-google";
import { withLeaflet } from "react-leaflet";

class Routing extends MapLayer {
  createLeafletElement() {

    const { map } = this.props; 
    const {AllCode} = this.props;
    
    // console.log("AllCode",AllCode)
    
      // let arr=[];
      // AllCode.map(v=>arr.push(`L.latLng(${v})`))
      // console.log("arr",arr)
      



      // arr.map(e=>e = e.replace(/^"(.*)"$/,'$1'));
      // console.log("arr55",arr)
    let leafletElement = L.Routing.control({ 

      waypoints:AllCode.map(e=>L.latLng(e.split(",")[0],e.split(",")[1])) ,
      

      // waypoints: [
      //       L.latLng(28.057867,74.747246),
      //       L.latLng(27.726086,75.075979),
      //       L.latLng(29.298647,75.8199341),
      //       L.latLng(28.9974934,75.6043331),
      //       L.latLng(28.9974934,75.6043331),
      //       L.latLng(28.7015412,75.4758781),
      //       L.latLng(28.3458569,75.0682504),
      //       L.latLng(30.5938815,76.7600168),
      //       L.latLng(30.3912006,76.4995253),
      //       L.latLng(30.738834,76.897977)      
      // ],
      
      lineOptions: {
        styles: [
          {
            color: "blue",
            opacity: 0.6,
            weight: 4
          }
        ]
      },
      addWaypoints: false,
      draggableWaypoints: false,
      fitSelectedRoutes: false,
      showAlternatives: false
    }).addTo(map.leafletElement);
    return leafletElement.getPlan();
  }
}
export default withLeaflet(Routing);
