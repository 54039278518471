import React, {Component} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {getExchangeCurrency} from "../../../util/CommonUtility";
import RestClient from "../../../util/RestClient";
import {
    GET_SPOT_RATE_TICKER, ORIGIN_DESTINATION_MASTER_MANAGER_API, ORIGIN_DESTINATION_MASTER_MANANGER_API,
    SPOT_RATE_AIR_BOOKING_API,
    SPOT_RATE_AIR_DATA_MANAGER, SPOT_RATE_AIR_DATA_MANAGERS,
    SPOT_RATE_BOOKING_API, UPDATE_SPOT_RATE_DETAIL
} from "../../../constants";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";
import OriginDestinationDropdownForAirport from "../../../components/OriginDestinationDropdownForAirport";
import {faRedoAlt, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class   AirTickerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpotRateSelect:false,
            classSpotRateData:false,
        };
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }
    play() {
        this.slider.slickPlay();
    }
    pause() {
        this.slider.slickPause();
    }

    componentDidMount() {

        setTimeout(this.getSportRateData, 5000);

    }
    componentWillUnmount() {
        // clearInterval(MyInterval);

    }
    getSportRateData = () =>{
        this.getSpotRateAirData({currency: getExchangeCurrency()}).then(airSpotData => {
            if (airSpotData && airSpotData.success){
                // console.log("----- air spot rate"+JSON.stringify(airSpotData.result))
                let spotRateDataAir = airSpotData.result.data;
                // let forSpotRate = spotRateDataAir && spotRateDataAir.forSpotRate ? spotRateDataAir.forSpotRate : "";
                this.setState({spotRateDataAir:spotRateDataAir,
                })
            }
        })
    }
    getSpotRateAirData = async (dataForServer) => {
        return await RestClient({
            url: SPOT_RATE_AIR_DATA_MANAGERS,
            method: "POST",
            data: dataForServer
        })
    }

    OnDataView = (obj) =>{

        localStorage.setItem("SpotRateAirKeys", JSON.stringify(obj))
        history.push(PAGE_PATHS.SPOT_RATE_AIR_PAGE);
        window.location.reload("false")
        // this.getSpotRateAirViewData(obj).then(r => {
        //     // alert("hiiiiii")
        //     if(r.success){
        //         console.log("dfjsdk kjfk",r.success);
        //
        //     }
        // })
    }

    getSpotRateAirViewData = async (dataForServer) => {
        return await RestClient({
            url: SPOT_RATE_AIR_BOOKING_API,
            method: "POST",
            data: dataForServer
        })
    }
    getSpotRateDataApi =()=>{
        if(this.state.DestinationIdForSearch !== "" && this.state.OriginIdForSearch !== "" && this.state.DestinationIdForSearch !== undefined && this.state.OriginIdForSearch !== undefined){
            let searchData = {
                originId: this.state.OriginIdForSearch,
                destinationId: this.state.DestinationIdForSearch,
                // currency:getExchangeCurrency()
            }
            this.setState({showSpotRateSelect:false})
            this.searchSpotRateAirViewData(searchData).then(r => {
                if(r.success){
                    let spotRateDataAir = r && r.result && r.result.data;
                    if(spotRateDataAir.length>=1){
                        this.setState({spotRateDataAir:spotRateDataAir,classSpotRateData:!this.state.classSpotRateData})
                    }else{

                    }

                }
            })
        }
    }

    redoSpotRateDataApi =()=>{
        if(this.state.classSpotRateData === true){
                this.getSpotRateAirData({currency: getExchangeCurrency()}).then(airSpotData => {
                    if (airSpotData && airSpotData.success){
                        // console.log("----- air spot rate"+JSON.stringify(airSpotData.result))
                        let spotRateDataAir = airSpotData.result.data;
                        // let forSpotRate = spotRateDataAir && spotRateDataAir.forSpotRate ? spotRateDataAir.forSpotRate : "";
                        this.setState({spotRateDataAir:spotRateDataAir,classSpotRateData:false,
                        })
                    }
                })
        }

    }

    searchSpotRateAirViewData = async (dataForServer) => {
        return await RestClient({
            url: SPOT_RATE_AIR_DATA_MANAGERS,
            method: "POST",
            data: dataForServer
        })
    }


    render() {
        const settings = {

            dots: false,
            infinite: true,
            slidesToShow:1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            cols:5,
            vertical: true,
            verticalSwiping: true,
            beforeChange: function(currentSlide, nextSlide) {
                // console.log("before change", currentSlide, nextSlide);
            },
            afterChange: function(currentSlide) {
                // console.log("after change", currentSlide);
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,

                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <>
                <div className="responsive-padding position-relative mobile_ticker">
                    <Slider ref={slider => (this.slider = slider)} {...settings}>
                        {Array.isArray(this.state.spotRateDataAir) && this.state.spotRateDataAir.length >= 1
                            ? this.state.spotRateDataAir.map((obj, i) => {
                                // console.log("spotRateDataAir",JSON.stringify(this.state.spotRateDataAir))
                                return (
                                    <>
                                        <div className={this.state.classSpotRateData === true ? "space-ticker justify-content-start " : "space-ticker justify-content-start"} >
                                            {Array.isArray(obj) && obj.length >= 1
                                                ? obj.map((objN, k) => {
                                                    return (
                                                        <>
                                                            <div onClick={e => {
                                                                e.preventDefault()
                                                                // if(Array.isArray(obj) && obj.length >= 1 ){
                                                                    // obj.map((objN, k) => {
                                                                        let dataOcean = {
                                                                            originId: objN && objN.originDetails ? objN.originDetails._id : "",
                                                                            destinationId: objN && objN.destinationDetails ? objN.destinationDetails._id:"",
                                                                            chargeableWeight: objN.w_500 !== "FALSE" ? 500 : 1000,
                                                                            originName: objN && objN.originDetails ? objN.originDetails.name:"",
                                                                            destinationName: objN && objN.destinationDetails ? objN.destinationDetails.name : "",
                                                                            tarrifMode: objN && objN.tarrifMode ? objN.tarrifMode : "",

                                                                        }
                                                                        this.OnDataView(dataOcean)

                                                                    // })
                                                                // }

                                                            }} className={this.state.classSpotRateData === true ?"ticker-all ticker-alls spaces-ticker":"ticker-all ticker-alls"}>
                                                                <div className="ticker_section">
                                                                    <h4 className="origin_ticker" title={objN && objN.originDetails ? objN.originDetails.city : ""}>{objN && objN.originDetails ? objN.originDetails.city.substring(0, Math.min(objN.originDetails.city.length, 15)) : ""}</h4>
                                                                    <h4 title={objN && objN.destinationDetails ? objN.destinationDetails.city : ""} className="destination_ticker">{objN && objN.destinationDetails ? objN.destinationDetails.city.substring(0, Math.min(objN.destinationDetails.city.length,15)) : ""}</h4>
                                                                </div>
                                                                <div className="rate_ticker">
                                                                    <h4>{objN.currency}{" "}{objN.spotSellRate}</h4>
                                                                    <h5><strike>{objN.currency}{" "} {objN.spotStrikeRate}</strike></h5>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }): undefined}

                                        </div>
                                    </>
                                )


                            })
                            : undefined}
                    </Slider>

                    {Array.isArray(this.state.spotRateDataAir) && this.state.spotRateDataAir.length >= 1 ?
                        <>
                            <div className="search_reload">
                                <div className="icon_spot" onClick={e => {e.preventDefault()
                                    this.setState({showSpotRateSelect:!this.state.showSpotRateSelect})}}><FontAwesomeIcon icon={faSearch}/>
                                </div>
                                <div className="icon_spot" onClick={e => {e.preventDefault()
                                    this.redoSpotRateDataApi()}}><FontAwesomeIcon icon={faRedoAlt}/></div>
                                <div className="search-spotresult" hidden={this.state.showSpotRateSelect === false}>
                                    <div className="economy">
                                        <div className="">
                                            <OriginDestinationDropdownForAirport
                                                APIRout={ORIGIN_DESTINATION_MASTER_MANAGER_API}
                                                Lable={"Origin Airport"}
                                                spotRateQuery={false}
                                                PlaceHolder={"Search Your Origin Airport"}
                                                AirportValue={this.state.OriginAirportValue}
                                                handler={(resultValue) => {

                                                    this.setState({
                                                        OriginAirportValue: resultValue,
                                                        OriginIdForSearch : resultValue.value,

                                                    },()=>{
                                                        this.getSpotRateDataApi()
                                                        this.setState({
                                                            showSpotRateSelect:true,
                                                        })

                                                    });

                                                }}
                                            />
                                        </div>
                                        <div className="">
                                            <OriginDestinationDropdownForAirport
                                                APIRout={ORIGIN_DESTINATION_MASTER_MANAGER_API}
                                                Lable={"Destination Airport"}
                                                PlaceHolder={"Search Your Destination Airport"}
                                                spotRateQuery={false}
                                                AirportValue={this.state.destinationAirportValue}
                                                handler={(resultValue) => {
                                                    if (!localStorage.getItem("SpotRateAirQueryData")) {
                                                        this.setState({
                                                            destinationAirportValue: resultValue,
                                                            DestinationIdForSearch: resultValue.value,
                                                            showOrigin: false
                                                        },()=>{
                                                            this.getSpotRateDataApi()
                                                        });
                                                    } else {
                                                    }

                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="ticker_btn" style={{ textAlign: "center" }}>
                                <button className="button" onClick={this.play}>
                                    Play
                                </button>
                                <button className="button" onClick={this.pause}>
                                    Pause
                                </button>
                            </div>
                        </>
                        :""}
                </div>
            </>
        )};

};


export default AirTickerView;
