import React, {Component, Fragment} from "react";
import { Input,  Modal, ModalHeader, ModalBody,} from "reactstrap";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import {GET_ALL_SLAB_RATE_AIR} from "../../../constants";
import RestClient from "../../../util/RestClient";
import {convertDateISO, getCapacitorValue} from "../../../util/CommonUtility";
import BookSlabPopup from "./BookSlabPopup";

class SlabRatePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSlabSelected : false,
            modalLarge: false,
            modalLarge1 : false,
            isBookButtonClicked : false,
            MobileScreen : true,


        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {

        if (this.props.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        if (this.props.BookingStatus) {
            this.setState({
                BookingStatus: this.props.BookingStatus
            })
        }
        // this.getAllSlabRate({
        //     originDetails : this.props.originDetail,
        //     destinationDetails: this.props.destinationDetail,
        //     product : this.props.product
        // }).then(res =>{
        //     if(res.success){
        //         this.setState({
        //             ODPData : res.result
        //         })
        //     }
        // })


    }
    async getAllSlabRate(body) {
        return await RestClient({
            url: GET_ALL_SLAB_RATE_AIR,
            method: "POST",
            data: body
        });
    }
    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if (this.state.modalLarge === false){
                this.props.setmodal(false)
                this.setState({
                    isNormalSlabSelected : false,
                    isMinSlabSelected : false,
                    is45SlabSelected : false,
                    is100SlabSelected : false,
                    is250SlabSelected : false,
                    is300SlabSelected : false,
                    is500SlabSelected : false,
                    is1000SlabSelected : false,
                })

            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }

    }
    handleMaxSlabValuesForMinimum= (obj) =>{
        let valueForMaxSlab = ''
        if(obj.breakevenMin =='0'){
            if(obj.breakevenNormal == '0'){
                if(obj.breakeven45 == '0'){
                    if(obj.breakeven100 == '0'){
                        if(obj.breakeven250 == '0'){
                            if(obj.breakeven300 == '0'){
                                if(obj.breakeven500 == '0'){
                                    if(obj.breakeven1000 == '0'){
                                        valueForMaxSlab = '0'
                                    }else{
                                        valueForMaxSlab = obj.breakeven1000
                                    }
                                }else{
                                    valueForMaxSlab = obj.breakeven500
                                }
                            }else{
                                valueForMaxSlab = obj.breakeven300
                            }
                        }else{
                            valueForMaxSlab = obj.breakeven250
                        }
                    }else{
                        valueForMaxSlab = obj.breakeven100
                    }
                }else{
                    valueForMaxSlab = obj.breakeven45
                }
            }else{
                valueForMaxSlab = obj.breakevenNormal
            }
        }else{
            valueForMaxSlab = obj.breakevenMin
        }
        return valueForMaxSlab
    }
    handleMaxSlabValuesForNormal= (obj) =>{
        let valueForMaxSlab = ''

        if(obj.breakevenNormal == '0'){
            if(obj.breakeven45 == '0'){
                if(obj.breakeven100 == '0'){
                    if(obj.breakeven250 == '0'){
                        if(obj.breakeven300 == '0'){
                            if(obj.breakeven500 == '0'){
                                if(obj.breakeven1000 == '0'){
                                    valueForMaxSlab = '0'
                                }else{
                                    valueForMaxSlab = obj.breakeven1000
                                }
                            }else{
                                valueForMaxSlab = obj.breakeven500
                            }
                        }else{
                            valueForMaxSlab = obj.breakeven300
                        }
                    }else{
                        valueForMaxSlab = obj.breakeven250
                    }
                }else{
                    valueForMaxSlab = obj.breakeven100
                }
            }else{
                valueForMaxSlab = obj.breakeven45
            }
        }else{
            valueForMaxSlab = obj.breakevenNormal
        }

        return valueForMaxSlab
    }
    handleMaxSlabValuesFor45= (obj) =>{
        let valueForMaxSlab = ''

        if(obj.breakeven45 == '0'){
            if(obj.breakeven100 == '0'){
                if(obj.breakeven250 == '0'){
                    if(obj.breakeven300 == '0'){
                        if(obj.breakeven500 == '0'){
                            if(obj.breakeven1000 == '0'){
                                valueForMaxSlab = '0'
                            }else{
                                valueForMaxSlab = obj.breakeven1000
                            }
                        }else{
                            valueForMaxSlab = obj.breakeven500
                        }
                    }else{
                        valueForMaxSlab = obj.breakeven300
                    }
                }else{
                    valueForMaxSlab = obj.breakeven250
                }
            }else{
                valueForMaxSlab = obj.breakeven100
            }
        }else{
            valueForMaxSlab = obj.breakeven45
        }


        return valueForMaxSlab
    }
    handleMaxSlabValuesFor100= (obj) =>{
        let valueForMaxSlab = ''

        if(obj.breakeven100 == '0'){
            if(obj.breakeven250 == '0'){
                if(obj.breakeven300 == '0'){
                    if(obj.breakeven500 == '0'){
                        if(obj.breakeven1000 == '0'){
                            valueForMaxSlab = '0'
                        }else{
                            valueForMaxSlab = obj.breakeven1000
                        }
                    }else{
                        valueForMaxSlab = obj.breakeven500
                    }
                }else{
                    valueForMaxSlab = obj.breakeven300
                }
            }else{
                valueForMaxSlab = obj.breakeven250
            }
        }else{
            valueForMaxSlab = obj.breakeven100
        }



        return valueForMaxSlab
    }
    handleMaxSlabValuesFor250= (obj) =>{
        let valueForMaxSlab = ''

        if(obj.breakeven250 == '0'){
            if(obj.breakeven300 == '0'){
                if(obj.breakeven500 == '0'){
                    if(obj.breakeven1000 == '0'){
                        valueForMaxSlab = '0'
                    }else{
                        valueForMaxSlab = obj.breakeven1000
                    }
                }else{
                    valueForMaxSlab = obj.breakeven500
                }
            }else{
                valueForMaxSlab = obj.breakeven300
            }
        }else{
            valueForMaxSlab = obj.breakeven250
        }




        return valueForMaxSlab
    }
    handleMaxSlabValuesFor300= (obj) =>{
        let valueForMaxSlab = ''


        if(obj.breakeven300 == '0'){
            if(obj.breakeven500 == '0'){
                if(obj.breakeven1000 == '0'){
                    valueForMaxSlab = '0'
                }else{
                    valueForMaxSlab = obj.breakeven1000
                }
            }else{
                valueForMaxSlab = obj.breakeven500
            }
        }else{
            valueForMaxSlab = obj.breakeven300
        }




        return valueForMaxSlab
    }
    handleMaxSlabValuesFor500= (obj) =>{
        let valueForMaxSlab = ''
        if(obj.breakeven500 == '0'){
            if(obj.breakeven1000 == '0'){
                valueForMaxSlab = '0'
            }else{
                valueForMaxSlab = obj.breakeven1000
            }
        }else{
            valueForMaxSlab = obj.breakeven500
        }




        return valueForMaxSlab
    }
    handleMaxSlabValuesFor1000= (obj) =>{
        let valueForMaxSlab = ''
        if(obj.breakeven1000 == '0'){
            valueForMaxSlab = '0'
        }else{
            valueForMaxSlab = obj.breakeven1000
        }
        return valueForMaxSlab
    }
    handleMinSlabValuesFor1000 = (obj) => {
        let valueForMinSlab = ''
        if(obj.breakeven500 == '0'){
            if(obj.breakeven300 == '0'){
                if(obj.breakeven250 == '0'){
                    if(obj.breakeven100 == '0'){
                        if(obj.breakeven45 == '0'){
                            if(obj.breakevenNormal == '0'){
                                if(obj.breakevenMin == '0'){
                                    valueForMinSlab = '0'
                                }else{
                                    valueForMinSlab = obj.breakevenMin
                                }
                            }else{
                                valueForMinSlab = obj.breakevenNormal
                            }
                        }else{
                            valueForMinSlab = obj.breakeven45
                        }
                    }else{
                        valueForMinSlab = obj.breakeven100
                    }
                }else{
                    valueForMinSlab = obj.breakeven250
                }
            }else{
                valueForMinSlab = obj.breakeven300
            }
        }else{
            valueForMinSlab = obj.breakeven500
        }
        return valueForMinSlab
    }
    handleMinSlabValuesFor500 = (obj) => {
        let valueForMinSlab = ''
        if(obj.breakeven300 == '0'){
            if(obj.breakeven250 == '0'){
                if(obj.breakeven100 == '0'){
                    if(obj.breakeven45 == '0'){
                        if(obj.breakevenNormal == '0'){
                            if(obj.breakevenMin == '0'){
                                valueForMinSlab = '0'
                            }else{
                                valueForMinSlab = obj.breakevenMin
                            }
                        }else{
                            valueForMinSlab = obj.breakevenNormal
                        }
                    }else{
                        valueForMinSlab = obj.breakeven45
                    }
                }else{
                    valueForMinSlab = obj.breakeven100
                }
            }else{
                valueForMinSlab = obj.breakeven250
            }
        }else{
            valueForMinSlab = obj.breakeven300
        }
        return valueForMinSlab
    }
    handleMinSlabValuesFor300 = (obj) => {
        let valueForMinSlab = ''
        if(obj.breakeven250 == '0'){
            if(obj.breakeven100 == '0'){
                if(obj.breakeven45 == '0'){
                    if(obj.breakevenNormal == '0'){
                        if(obj.breakevenMin == '0'){
                            valueForMinSlab = '0'
                        }else{
                            valueForMinSlab = obj.breakevenMin
                        }
                    }else{
                        valueForMinSlab = obj.breakevenNormal
                    }
                }else{
                    valueForMinSlab = obj.breakeven45
                }
            }else{
                valueForMinSlab = obj.breakeven100
            }
        }else{
            valueForMinSlab = obj.breakeven250
        }
        return valueForMinSlab
    }
    handleMinSlabValuesFor250 = (obj) => {
        let valueForMinSlab = ''
        if(obj.breakeven100 == '0'){
            if(obj.breakeven45 == '0'){
                if(obj.breakevenNormal == '0'){
                    if(obj.breakevenMin == '0'){
                        valueForMinSlab = '0'
                    }else{
                        valueForMinSlab = obj.breakevenMin
                    }
                }else{
                    valueForMinSlab = obj.breakevenNormal
                }
            }else{
                valueForMinSlab = obj.breakeven45
            }
        }else{
            valueForMinSlab = obj.breakeven100
        }
        return valueForMinSlab
    }
    handleMinSlabValuesFor100 = (obj) => {
        let valueForMinSlab = ''
        if(obj.breakeven45 == '0'){
            if(obj.breakevenNormal == '0'){
                if(obj.breakevenMin == '0'){
                    valueForMinSlab = '0'
                }else{
                    valueForMinSlab = obj.breakevenMin
                }
            }else{
                valueForMinSlab = obj.breakevenNormal
            }
        }else{
            valueForMinSlab = obj.breakeven45
        }
        return valueForMinSlab
    }
    handleMinSlabValuesFor45 = (obj) => {
        let valueForMinSlab = ''
        if(obj.breakevenNormal == '0'){
            if(obj.breakevenMin == '0'){
                valueForMinSlab = '0'
            }else{
                valueForMinSlab = obj.breakevenMin
            }
        }else{
            valueForMinSlab = obj.breakevenNormal
        }
        return valueForMinSlab
    }
    handleMinSlabValuesForNormal = (obj) => {
        let valueForMinSlab = ''
        if(obj.breakevenMin == '0'){
            valueForMinSlab = '0'
        }else{
            valueForMinSlab = obj.breakevenMin
        }
        return valueForMinSlab
    }


    handleSelectedSlab = (e,forSlab,obj) => {
        e.preventDefault()

        if(forSlab === 'sellMin'){
            // this.handleSlabValuesForMinMax(obj)
            let maxValue = this.handleMaxSlabValuesForMinimum(obj)
            this.setState({
                isMinSlabSelected : !this.state.isMinSlabSelected,
                isNormalSlabSelected : false,
                is45SlabSelected : false,
                is100SlabSelected : false,
                is250SlabSelected : false,
                is300SlabSelected : false,
                is500SlabSelected : false,
                is1000SlabSelected : false,
                minSlab : '0',
                // maxSlab : obj.breakevenMin
                maxSlab : maxValue
            })
        }
        else if(forSlab === 'sellNormal'){
            let maxValue = this.handleMaxSlabValuesForNormal(obj)
            let minValue = this.handleMinSlabValuesForNormal(obj)
            this.setState({
                isNormalSlabSelected : !this.state.isNormalSlabSelected,
                isMinSlabSelected : false,
                is45SlabSelected : false,
                is100SlabSelected : false,
                is250SlabSelected : false,
                is300SlabSelected : false,
                is500SlabSelected : false,
                is1000SlabSelected : false,
                // minSlab : obj.breakevenMin,
                // maxSlab : obj.breakevenNormal
                minSlab : minValue,
                maxSlab : maxValue
            })
        }
        else if(forSlab === 'sell45'){
            // let valueForMinSlab = ''
            // if(obj.breakevenNormal == '0'){
            //     valueForMinSlab = obj.breakevenMin
            // }else{
            //     valueForMinSlab = obj.breakevenNormal
            // }
            let maxValue = this.handleMaxSlabValuesFor45(obj)
            let minValue = this.handleMinSlabValuesFor45(obj)
            this.setState({
                is45SlabSelected : !this.state.is45SlabSelected,
                isNormalSlabSelected : false,
                isMinSlabSelected : false,
                is100SlabSelected : false,
                is250SlabSelected : false,
                is300SlabSelected : false,
                is500SlabSelected : false,
                is1000SlabSelected : false,
                minSlab : minValue,
                // maxSlab : obj.breakeven45
                maxSlab : maxValue
            })
        }
        else if(forSlab === 'sell100'){
            // let valueForMinSlab = ''
            // if(obj.breakeven45 == '0'){
            //     valueForMinSlab = obj.breakevenNormal
            // }else{
            //     valueForMinSlab = obj.breakeven45
            // }
            let maxValue = this.handleMaxSlabValuesFor100(obj)
            let minValue = this.handleMinSlabValuesFor100(obj)
            this.setState({
                is100SlabSelected : !this.state.is100SlabSelected,
                isNormalSlabSelected : false,
                is45SlabSelected : false,
                isMinSlabSelected : false,
                is250SlabSelected : false,
                is300SlabSelected : false,
                is500SlabSelected : false,
                is1000SlabSelected : false,
                minSlab : minValue,
                maxSlab : maxValue
            })
        }
        else if(forSlab === 'sell250'){
            // let valueForMinSlab = ''
            // if(obj.breakeven100 == '0'){
            //     valueForMinSlab = obj.breakeven45
            // }else{
            //     valueForMinSlab = obj.breakeven100
            // }
            // let valueForMaxSlab = ''
            // if(obj.breakeven250 == '0'){
            //     valueForMaxSlab = obj.breakeven300
            // }else{
            //     valueForMaxSlab = obj.breakeven250
            // }
            let maxValue = this.handleMaxSlabValuesFor250(obj)
            let minValue = this.handleMinSlabValuesFor250(obj)
            this.setState({
                is250SlabSelected : !this.state.is250SlabSelected,
                isNormalSlabSelected : false,
                is45SlabSelected : false,
                is100SlabSelected : false,
                isMinSlabSelected : false,
                is300SlabSelected : false,
                is500SlabSelected : false,
                is1000SlabSelected : false,
                minSlab : minValue,
                maxSlab : maxValue
            })
        }
        else if(forSlab === 'sell300'){
            // let valueForMinSlab = ''
            // if(obj.breakeven250 == '0'){
            //     valueForMinSlab = obj.breakeven100
            // }else{
            //     valueForMinSlab = obj.breakeven250
            // }
            let maxValue = this.handleMaxSlabValuesFor300(obj)
            let minValue = this.handleMinSlabValuesFor300(obj)
            this.setState({
                is300SlabSelected : !this.state.is300SlabSelected,
                isNormalSlabSelected : false,
                is45SlabSelected : false,
                is100SlabSelected : false,
                is250SlabSelected : false,
                isMinSlabSelected : false,
                is500SlabSelected : false,
                is1000SlabSelected : false,
                minSlab : minValue,
                maxSlab : maxValue
            })
        }
        else if(forSlab === 'sell500'){
            // let valueForMinSlab = ''
            // if(obj.breakeven300 == '0'){
            //     valueForMinSlab = obj.breakeven250
            // }else{
            //     valueForMinSlab = obj.breakeven300
            // }
            let maxValue = this.handleMaxSlabValuesFor500(obj)
            let minValue = this.handleMinSlabValuesFor500(obj)
            this.setState({
                is500SlabSelected : !this.state.is500SlabSelected,
                isNormalSlabSelected : false,
                is45SlabSelected : false,
                is100SlabSelected : false,
                is250SlabSelected : false,
                is300SlabSelected : false,
                isMinSlabSelected : false,
                is1000SlabSelected : false,
                minSlab : minValue,
                maxSlab : maxValue
            })
        }
        else if(forSlab === 'sell1000'){
            // let valueForMinSlab = ''
            // if(obj.breakeven500 == '0'){
            //     valueForMinSlab = obj.breakeven300
            // }else{
            //     valueForMinSlab = obj.breakeven500
            // }
            let maxValue = this.handleMaxSlabValuesFor1000(obj)
            let minValue = this.handleMinSlabValuesFor1000(obj)
            this.setState({
                is1000SlabSelected : !this.state.is1000SlabSelected,
                isNormalSlabSelected : false,
                is45SlabSelected : false,
                is100SlabSelected : false,
                is250SlabSelected : false,
                is300SlabSelected : false,
                is500SlabSelected : false,
                isMinSlabSelected : false,
                // minSlab : valueForMinSlab,
                minSlab : minValue,
                maxSlab : maxValue
            })
        }

    }
    handleBook = (e) =>{
        e.preventDefault()
        this.setState({
            isBookButtonClicked : !this.state.isBookButtonClicked,
            modalLarge : false,
        },()=>{
            // alert(this.state.isBookButtonClicked)
            // this.setState({
            //     is100SlabSelected : false,
            //     isNormalSlabSelected : false,
            //     is45SlabSelected : false,
            //     isMinSlabSelected : false,
            //     is250SlabSelected : false,
            //     is300SlabSelected : false,
            //     is500SlabSelected : false,
            //     is1000SlabSelected : false,
            //
            // })
        })
    }
    handleData = () =>{
        // console.log('value of obj==============',JSON.stringify(this.props.ODPData))

        // for(let i=0;i<this.props.ODPData.length;i++){
        let obj = this.props.ODPData
        return(<>
            <tbody>
            <tr>
                <td className={this.state.isMinSlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sellMin',obj)}>{obj.sellMin}</td>
                <td className={this.state.isNormalSlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sellNormal',obj)}>{obj.sellNormal}</td>
                <td className={this.state.is45SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell45',obj)}>{obj.sell45}</td>
                <td className={this.state.is100SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell100',obj)}>{obj.sell100}</td>
                <td className={this.state.is250SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell250',obj)}>{obj.sell250}</td>
                <td className={this.state.is300SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell300',obj)}>{obj.sell300}</td>
                <td className={this.state.is500SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell500',obj)}>{obj.sell500}</td>
                <td className={this.state.is1000SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell1000',obj)}>{obj.sell1000}</td>
                <td>{this.props.quoteAvail?
                    this.props.quoteStatus ==="Rates Quoted" ?<button onClick ={e=>this.handleBook(e)} className="btn slab_btns">Book Now</button>:""
                    :<button onClick ={e=>this.handleBook(e)} className="btn slab_btns">Book Now</button>}</td>
            </tr>
            </tbody>


        </>)
        // }

    }
    handleDataMobile = () =>{
        let obj = this.props.ODPData
        return(<>


            <tr>
                <td><strong>Sell Minimum</strong></td>
                <td className={this.state.isMinSlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sellMin',obj)}>{obj.sellMin}</td>
            </tr>
            <tr>
                <td><strong>Sell Normal</strong></td>
                <td className={this.state.isNormalSlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sellNormal',obj)}>{obj.sellNormal}</td>
            </tr>
            <tr>
                <td><strong>Sell 45</strong></td>
                <td className={this.state.is45SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell45',obj)}>{obj.sell45}</td>
            </tr>
            <tr>
                <td><strong>Sell 100</strong></td>
                <td className={this.state.is100SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell100',obj)}>{obj.sell100}</td>
            </tr>
            <tr>
                <td><strong>Sell 250</strong></td>
                <td className={this.state.is250SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell250',obj)}>{obj.sell250}</td>
            </tr>
            <tr>
                <td><strong>Sell 300</strong></td>
                <td className={this.state.is300SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell300',obj)}>{obj.sell300}</td>
            </tr>
            <tr>
                <td><strong>Sell 500</strong></td>
                <td className={this.state.is500SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell500',obj)}>{obj.sell500}</td>
            </tr>
            <tr>
                <td><strong>Sell 1000</strong></td>
                <td className={this.state.is1000SlabSelected ?"select-table active" : 'select-table'} onClick= {e =>this.handleSelectedSlab(e,'sell1000',obj)}>{obj.sell1000}</td>
            </tr>
            <tr>
                <td ><strong>Action</strong></td>
                <td>{this.props.quoteAvail?
                    this.props.quoteStatus ==="Rates Quoted" ?<button onClick ={e=>this.handleBook(e)} className="btn slab_btns">Book Now</button>:""
                    :<button onClick ={e=>this.handleBook(e)} className="btn slab_btns">Book Now</button>}
                </td>
            </tr>

        </>)
        // }

    }

    render() {

        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <BookSlabPopup
                    modalLarge1 = {this.state.isBookButtonClicked}
                    minSlab = {this.state.minSlab}
                    maxSlab = {this.state.maxSlab}
                    chargeableWeightForSlab = {this.props.chargeableWeightForSlab}
                    setmodal1={value=>{
                        if(value === false){
                            this.setState({
                                modalLarge1:false,
                                isBookButtonClicked:false,
                                isNormalSlabSelected : false,
                                isMinSlabSelected : false,
                                is45SlabSelected : false,
                                is100SlabSelected : false,
                                is250SlabSelected : false,
                                is300SlabSelected : false,
                                is500SlabSelected : false,
                                is1000SlabSelected : false,
                            })
                        }else{
                            this.setState({
                                modalLarge1:true
                            })
                        }
                    }}
                />
                <Modal className="slab-width"
                       isOpen={this.state.modalLarge}
                       size="lg"
                       toggle={this.toggleLarge}
                >

                    <>
                        <ModalHeader style={{borderBottom:'1px solid #d7d7d7'}} toggle={this.toggleLarge} className="">
                            <h3 className="mb-2"><strong>All Slab Rates</strong></h3>
                        </ModalHeader>
                        <ModalBody className="">
                            <div className="background-white mb-2">

                                <div className="table-responsive">
                                    {this.state.MobileScreen ?
                                        <table className="slab_tables table">
                                            <thead>
                                            <tr>
                                                <th className="text-center">SLAB</th>
                                                <th className="text-center">PRICE</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.ODPData  ? this.handleDataMobile(): []
                                            }

                                            </tbody>
                                        </table>
                                        :
                                        <table
                                            className="table table-bordered table-striped table-hover table-sticky">
                                            <thead style={{background: '#1e91cf', color: '#fff'}}>
                                            <tr>

                                                <th className="text-center">Sell Minimum</th>
                                                <th className="text-center">Sell Normal</th>
                                                <th className="text-center">Sell 45</th>
                                                <th className="text-center">Sell 100</th>
                                                <th className="text-center">Sell 250</th>
                                                <th className="text-center">Sell 300</th>
                                                <th className="text-center">Sell 500</th>
                                                <th className="text-center">Sell 1000</th>
                                                {this.state.BookingStatus === "Rates Quoted" ?
                                                <th className="text-center">Action</th>:""}
                                            </tr>
                                            </thead>
                                            {
                                                this.props.ODPData  ? this.handleData(): []
                                            }
                                        </table>}
                                </div>
                            </div>
                        </ModalBody>
                    </>




                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {

};


export default connect(
    mapStateToProps,
    {}
)(SlabRatePopup);
