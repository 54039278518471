import React, { Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import { PAGE_PATHS } from "../../../util/PageRoute";
import MetaTags from "react-meta-tags";
import { history } from "../../../helpers/history";

import { Button} from "reactstrap";

import {
    faGift,
    faPlane, faSearch,
    faShip,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RestClient from "../../../util/RestClient";
import {
    AIR_API_TRACKING,
    COURIER_API_TRACKING,
    OCEAN_API_TRACKING, ROAD_API_TRACKING,
    SEARCH_TRACKING_BY_BOOKING_REFERENCE_NO
} from "../../../constants";


class TrackingSerachPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modes : "Air",
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    courierTrackingApiCalling = async (dataForServer) => {
        return await RestClient({
            url: this.state.modes === "Air" ? AIR_API_TRACKING : this.state.modes === "Ocean" ? OCEAN_API_TRACKING : this.state.modes === "Courier" ? COURIER_API_TRACKING : this.state.modes === "Road" ? ROAD_API_TRACKING :"",
            method: "POST",
            data: dataForServer
        });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    searchTracking = () =>{
        if(this.state.modes === "Courier"){
            this.courierTrackingApiCalling({ trackingNo: this.state.searchNumber}).then(responce => {
                if(responce.success){
                    history.push(PAGE_PATHS.COURIER_TRACKING_PAGE_NEW + "/" + this.state.modes + "/" +this.state.searchNumber)
                    window.location.reload("false")
                }
            })
        }else if(this.state.modes === "Air"){
            this.courierTrackingApiCalling({ trackingNo: this.state.searchNumber}).then(responce => {
                if(responce.success){
                    history.push(PAGE_PATHS.AIR_TRACKING_PAGE_NEW + "/" + this.state.modes + "/" +this.state.searchNumber)
                    window.location.reload("false")
                }
            })
        }else if(this.state.modes === "Ocean"){
            this.courierTrackingApiCalling({ trackingNo: this.state.searchNumber}).then(responce => {
                if(responce.success){
                    history.push(PAGE_PATHS.OCEAN_TRACKING_PAGE_NEW + "/" + this.state.modes + "/" +this.state.searchNumber)
                    window.location.reload("false")
                }
            })
        }else if(this.state.modes === "Road"){
            this.courierTrackingApiCalling({ trackingNo: this.state.searchNumber}).then(responce => {
                if(responce.success){
                    history.push(PAGE_PATHS.ROAD_TRACKING_PAGE_NEW + "/" + this.state.modes + "/" +this.state.searchNumber)
                    window.location.reload("false")
                }
            })
        }
    }
    render() {

        return (
            <>

                <MetaTags>
                    <title>Live Tracking  | Zipaworld </title>
                    <meta name="title" content="Live tracking for air, ocean, road cargo on Zipaworld" />
                    <meta name="description" content="Zipaworld helps in 24/7 live tracking of multimodal freight and cargo" />
                    <meta name="keywords" content="airwaybill tracking, AWB tracking, container tracking online, how to track my air cargo, track shipping container online" />
                </MetaTags>
                <AppLayout>
                    <div className="dashboard-tracking">
                        <div className="domain-search">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 pb-5">
                                        <h2 className="form-title">Tracking</h2>

                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mode_texts">
                                            <h3 className={this.state.modes === "Air" ? "airModes active" : "airModes"} onClick={()=>{
                                                this.setState({
                                                    modes : "Air"
                                                })
                                            }}>
                                                <FontAwesomeIcon icon={faPlane} className="air_icons"/> Air
                                            </h3>
                                            <h3 className={this.state.modes === "Ocean" ? "airModes active" : "airModes"} onClick={()=>{
                                                this.setState({
                                                    modes : "Ocean"
                                                })
                                            }}>
                                                <FontAwesomeIcon icon={faShip}/> Ocean
                                            </h3>
                                            <h3 className={this.state.modes === "Courier" ? "airModes active" : "airModes"} onClick={()=>{
                                                this.setState({
                                                    modes : "Courier"
                                                })
                                            }}>
                                                <FontAwesomeIcon icon={faGift}/> Courier
                                            </h3>
                                            <h3 className={this.state.modes === "Road" ? "airModes active" : "airModes"} onClick={()=>{
                                                this.setState({
                                                    modes : "Road"
                                                })
                                            }}>
                                                <FontAwesomeIcon icon={faTruck}/> Road
                                            </h3>
                                        </div>
                                        {/*<p className="pb-3">Please Enter BL/AWB/Docket Number</p>*/}
                                        <div className="inputWithButton" style={{width:'100%'}}>
                                            <input name="searchNumber" onChange={this.handleChange} type="text" placeholder={this.state.modes === "Air" ? "Search AWB/Job No" : this.state.modes === "Ocean" ? "Search BL/Job No":this.state.modes === "Courier" ? "Search Waybill/Job No" : this.state.modes === "Road" ? "Search Job No" : ""}/>
                                            <button onClick={()=>{
                                                this.searchTracking()

                                            }}>Track <FontAwesomeIcon icon={faSearch}/></button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                    {this.state.hideBottomButton === false ?
                        <div className="fixed-btnsbot fixed-btnsbot1">
                            <div className="d-flex flex-wrap justify-content-end">
                                <Button
                                    onClick={e => {
                                        e.preventDefault()
                                        history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + "/" + this.state.quoteFor + "/" +this.state.quoteId)
                                        window.location.reload("false")

                                    }}
                                    type="button"
                                    className="btn delete border-cir"
                                >Previous</Button>
                                <Button onClick={e => {
                                    e.preventDefault()
                                    history.push(PAGE_PATHS.DASHBOARD)
                                    window.location.reload("false")
                                }}
                                        type="button"
                                        className="btn delete border-cir"
                                >Home</Button>

                                <Button onClick={e => {
                                    e.preventDefault()
                                    if(this.state.InvoiceStatus === "Approved"){
                                        history.push(PAGE_PATHS.VIEW_FOR_INVOICE_MANAGER + "/" + this.state.quoteFor + "/" + this.state.InvoiceId)
                                        window.location.reload("false")
                                    }else{
                                        history.push(PAGE_PATHS.DASHBOARD)
                                        window.location.reload("false")
                                    }

                                }}
                                        type="button"
                                        className="btn delete border-cir"
                                >Next</Button>

                            </div>
                        </div>
                        : ""}
                </AppLayout>


            </>
        )
    }
}




export default TrackingSerachPage;

