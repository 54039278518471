import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import { NavLink } from "react-router-dom";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class RateNotFoundCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: "",
            toUpdate: "",

            RateNotFoundModel:false,
            message:"",



        };

    }



    componentDidMount() {
          if(this.props.RateNotFoundModel)
        {
            this.setState({
                RateNotFoundModel:this.props.RateNotFoundModel
            })
        }



    }

    toggleLarge = (e) => {
        e.preventDefault()
        history.push(PAGE_PATHS.MAIN_PAGE)
        window.location.reload("false")
    };

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.RateNotFoundModel && this.props.RateNotFoundModel !== prevProps.RateNotFoundModel)
        {
            this.setState({
                RateNotFoundModel:this.props.RateNotFoundModel
            })
        }

    }



    render() {

        return (
            <>
                <Modal className="model_contact"
                    isOpen={this.state.RateNotFoundModel}
                    size="md"
                    toggle={this.toggleLarge}
                >


                            <ModalHeader className="model_head" toggle={this.toggleLarge} style={{padding: "0"}}>
                                <NavLink to={`/`} className="white">
                                </NavLink>
                            </ModalHeader>
                            <ModalBody className="contact-later1">
                                <div className="contect-soon">
                                    <div className="soon_text">
                                        <div className="book-imgs">
                                            <img src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/mobile.png" alt="Mobile Icon"/>
                                        </div>
                                        <div className="book-text">
                                            <h5><FontAwesomeIcon icon={faCircle} style={{color:"green"}}/> Available Now</h5>
                                            <h2> Booking Not confirmed </h2>
                                            <h6>  Awaiting Booking Confirmation. </h6>
                                            <h6> For any problem Please call on <span style={{display:'block'}}> +91 120 691 6910.</span> </h6>
                                        </div>
                                    </div>
                                    <div className="bottom_btn">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button type="submit" className="request_btn" id="subscribeBtn" onClick={e=>{
                                                    e.preventDefault()
                                                    this.toggleLarge()
                                                }}>Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>

                </Modal>
            </>
        );
    }
}

export default RateNotFoundCard;
