// NEWSFLASH MASTER MANAGER REDUCER HERE
import {
    ADDS_TICKER_REQUEST, ADDS_TICKER_REQUEST_RESPONSE_FAILURE, ADDS_TICKER_REQUEST_RESPONSE_SUCCESS,
    NEWS_TICKER_REQUEST,
    NEWS_TICKER_REQUEST_RESPONSE_FAILURE,
    NEWS_TICKER_REQUEST_RESPONSE_SUCCESS,
    NEWSFLASH_MASTER_MANAGER_PREVIEW,
    NEWSFLASH_MASTER_MANAGER_REQUEST,
    NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE,
    NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE_FAILURE,
    NEWSFLASH_MASTER_MANAGER_UPDATE,
    NEWSFLASH_MASTER_MANAGER_UPDATE_RESPONSE
} from "../actions";

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
    SpotRateManagerFormValue: {}

};


const NewsFlashMasterManagerReducer = (state = initState, {type, payload}) => {
    switch (type) {

        case NEWSFLASH_MASTER_MANAGER_REQUEST:
            // console.log("In NEWSFLASH_MASTER_MANAGER_REQUEST::" + JSON.stringify(payload));
            return {...state, loading: true, response: ''};


        case NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE:
            // console.log("NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE");
            return {...state, loading: false, items: payload.data, totalItemCount: payload.total, error: ''};


        case NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE_FAILURE:
            // console.log("NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE_FAILURE" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload.message};

        case NEWSFLASH_MASTER_MANAGER_UPDATE:
            // console.log("In Airfreight_DESTINATION_UPDATE " + payload);
            return {...state, loading: true, response: ''};

        case NEWSFLASH_MASTER_MANAGER_UPDATE_RESPONSE:
            // console.log("NEWSFLASH_MASTER_MANAGER_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state, loading: false, result: '', response: payload.message};

        case NEWSFLASH_MASTER_MANAGER_PREVIEW:
            // console.log("NEWSFLASH_MASTER_MANAGER_PREVIEW  " + JSON.stringify(payload));
            return {...state, loading: false, SpotRateManagerFormValue: payload};

        case NEWS_TICKER_REQUEST:
            // console.log("NEWS_TICKER_REQUEST Reducer" + JSON.stringify(payload));
            return {...state, loading: true, error: ""};
        case NEWS_TICKER_REQUEST_RESPONSE_SUCCESS:
            // console.log("NEWS_TICKER_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, newsTickerList: payload};

        case NEWS_TICKER_REQUEST_RESPONSE_FAILURE:
            // console.log("NEWS_TICKER_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
            return {...state, loading: false, errorMessage: payload};


        case ADDS_TICKER_REQUEST:
            // console.log("NEWS_TICKER_REQUEST Reducer" + JSON.stringify(payload));
            return {...state, loading: true, error: ""};
        case ADDS_TICKER_REQUEST_RESPONSE_FAILURE:
            // console.log("NEWS_TICKER_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, AddsTickerList: payload};

        case ADDS_TICKER_REQUEST_RESPONSE_SUCCESS:
            // console.log("NEWS_TICKER_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
            return {...state, loading: false, errorMessage: payload};

        default:
            return initState;
    }
};

export default NewsFlashMasterManagerReducer;
