import {
    ADD_STATE,
    ADD_STATE_FAILURE,
    ADD_STATE_SUCCESS,
    EDIT_STATE_DATA, EDIT_STATE_DATA_FAILURE,
    EDIT_STATE_DATA_FETCH, EDIT_STATE_DATA_SUCCESS,
    MANAGER_STATE_DATA,
    MANAGER_STATE_DATA_FAILURE,
    MANAGER_STATE_DATA_SUCCESS, STATE_DELETE, STATE_DELETE_RESPONSE, STATE_UPDATE, STATE_UPDATE_RESPONSE,

} from "../actions";

const initialState = {
    name:"",
    stateCode:"",
    abbr:"",

};

const stateReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ADD_STATE:
            return {...state};

        case ADD_STATE_SUCCESS:
            // console.log("inreducer", JSON.stringify(payload))
            return {...state, message: payload.message};

        case ADD_STATE_FAILURE:
            return {...state, message: payload.message};

        case MANAGER_STATE_DATA:
            return {...state};

        case MANAGER_STATE_DATA_SUCCESS:
            // console.log("inreducer", JSON.stringify(payload))
            return {...state, managerData: payload};

        case MANAGER_STATE_DATA_FAILURE:
            return {...state};

        case EDIT_STATE_DATA_FETCH:
            return {...state};

        case EDIT_STATE_DATA_SUCCESS:
            // console.log("inreducer", JSON.stringify(payload))
            return {...state, EditData: payload};

        case EDIT_STATE_DATA_FAILURE:
            return {...state, message: payload.message};


        case EDIT_STATE_DATA:
            return {...state, data: payload};


        case STATE_UPDATE:
            // console.log("In STATE_UPDATE " + payload);
            return {...state,  response: ''};

        case STATE_UPDATE_RESPONSE:
            // console.log("in STATE_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state,  message: payload};

        case STATE_DELETE:
            // console.log("in STATE Delete RESPONSE" + JSON.stringify(payload));
            return {...state, message: payload.message};

        case STATE_DELETE_RESPONSE:
            // console.log("in STATE_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state, message: payload.message};

        default:
            return initialState
    }
};
export default stateReducer;