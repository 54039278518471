import {all, call, fork, put} from 'redux-saga/effects';
import RestClient from "../../util/RestClient";
import {
    COURIER_SHIPMENT_HISTORY_API,

    SHIPMENT_HISTORY_API,
    SHIPMENT_HISTORY_API_AIR,
    SHIPMENT_HISTORY_API_OCEAN,
    SHIPMENT_HISTORY_API_ROAD,
    SHIPMENT_HISTORY_API_COURIER,

} from "../../constants";

import {
    SHIPMENT_HISTORY_DATA_REQUEST,
    COURIER_SHIPMENT_HISTORY_DATA_REQUEST,

} from "../actions";


import {
    courierShipmentHistoryDataRequestFailure,
    courierShipmentHistoryDataRequestSuccess,
    shipmentHistoryDataRequestFailure, shipmentHistoryDataRequestSuccess,

} from "./shipmentHistoryActions";
import {takeLatest} from "@redux-saga/core/effects";


export function* watchShipmentHistoryAction() {
    yield takeLatest(SHIPMENT_HISTORY_DATA_REQUEST, fetchShipmentHistory);
    yield takeLatest(COURIER_SHIPMENT_HISTORY_DATA_REQUEST, fetchCourierShipmentHistory);

}

function* fetchShipmentHistory({payload}) {
    try {
        const Response = yield call(fetchShipmentHistoryApi, payload);
        if (Response.success) {
            yield put(shipmentHistoryDataRequestSuccess(Response));
        } else {
            yield put(shipmentHistoryDataRequestFailure(Response.message));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(shipmentHistoryDataRequestFailure(error.response.data.message));
        }
    }
}

const fetchShipmentHistoryApi = async (payload) => {
    {
        return await RestClient({
            url: payload && payload.queryFor==="Air"?SHIPMENT_HISTORY_API_AIR: payload && payload.queryFor==="Ocean"?SHIPMENT_HISTORY_API_OCEAN: payload && payload.queryFor==="Road"?SHIPMENT_HISTORY_API_ROAD: payload && payload.queryFor==="Courier"?SHIPMENT_HISTORY_API_COURIER: SHIPMENT_HISTORY_API,
            method: "POST",
            data: payload
        });

    }
}
function* fetchCourierShipmentHistory({payload}) {
    try {
        const Response = yield call(fetchCourierShipmentHistoryApi, payload);
        if (Response.success) {
            yield put(courierShipmentHistoryDataRequestSuccess(Response));
        } else {
            yield put(courierShipmentHistoryDataRequestFailure(Response.message));
        }

    } catch
        (error) {
        if (Object.keys(error).length !== 0) {

            yield put(courierShipmentHistoryDataRequestFailure(error.response.data.message));
        }
    }
}

const fetchCourierShipmentHistoryApi = async (payload) => {
    {
        return await RestClient({
            url: payload.queryFor === "Courier" ?SHIPMENT_HISTORY_API_COURIER : SHIPMENT_HISTORY_API_ROAD,
            method: "POST",
            data: payload
        });

    }
}

// function* updateSpotRateDetail({payload}) {
//     try {
//         const updateSpotRateResponse = yield call(updateSpotRateDetailAsync, payload);
//         if (updateSpotRateResponse.success) {
//             yield put(onSpotRateUpdateRespondBackSuccess(updateSpotRateResponse.message));
//         } else {
//             yield put(onSpotRateUpdateRespondBackFailure(updateSpotRateResponse.message));
//         }
//
//     } catch
//         (error) {
//         if (Object.keys(error).length !== 0) {
//
//             yield put(onSpotRateUpdateRespondBackFailure(error.response.data.message));
//         }
//     }
// }
//
// const updateSpotRateDetailAsync = async (payload) => {
//     {
//         return await RestClient({
//             url: UPDATE_SPOT_RATE_DETAIL,
//             method: "POST",
//             data: payload
//         });
//
//     }
// }
//
// function* getSpotRateDetail({payload}) {
//     try {
//         const getSpotRateResponse = yield call(getSpotRateDetailAsync, payload);
//         if (getSpotRateResponse.success) {
//             yield put(onSpotRateDetailRespondBackSuccess(getSpotRateResponse.result));
//         } else {
//             yield put(onSpotRateDetailRespondBackFailure(getSpotRateResponse.message));
//         }
//
//     } catch
//         (error) {
//         if (Object.keys(error).length !== 0) {
//
//             yield put(onSpotRateDetailRespondBackFailure(error.response.data.message));
//         }
//     }
// }
//
// const getSpotRateDetailAsync = async (payload) => {
//     {
//         return await RestClient({
//             url: GET_SPOT_RATE_DETAIL,
//             method: "POST",
//             data: payload
//         });
//
//     }
// }
//
// function* getSpotRateTicker({payload}) {
//     try {
//         const getSpotRateTickerResponse = yield call(getSpotRateTickerAsync, payload);
//         if (getSpotRateTickerResponse.success) {
//             yield put(onSpotRateTickerRespondBackSuccess(getSpotRateTickerResponse.result));
//         } else {
//             yield put(onSpotRateTickerRespondBackFailure(getSpotRateTickerResponse.message));
//         }
//
//     } catch
//         (error) {
//         if (Object.keys(error).length !== 0) {
//
//             yield put(onSpotRateTickerRespondBackFailure(error.response.data.message));
//         }
//     }
// }
//
// const getSpotRateTickerAsync = async (payload) => {
//     {
//         return await RestClient({
//             url: GET_SPOT_RATE_TICKER,
//             method: "POST",
//             data: payload
//         });
//
//     }
// }


export default function* Saga() {
    yield all([
        fork(watchShipmentHistoryAction)
    ]);
}
