import React,{Component} from 'react';
import sentaPNG from "../../assets/img/senta_img.png"

class FullLoader extends Component{
constructor(props) {
    super(props);

}


    render(){
        return(
            <>
                {/*<div className="loader-container loaderImg">*/}
                {/*    <img className="truck-image truckSenta" src={sentaPNG} alt="loading"/>*/}
                {/*</div>*/}
                <div className={this.props.view?'loading':'loading display-nonss'} >
                    {/*<div className="loader">*/}

                    {/*</div>*/}
                </div>
            </>
        );
    }
}
export default FullLoader;