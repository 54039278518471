import React, {Component, Fragment} from "react";
import AppLayout from "../../../layout/AppLayout";
import Slider from "react-slick";
import FooterHome from "./FooterHome";
import {convertDateISOToCustomiseFormat, getExchangeCurrency} from "../../../util/CommonUtility";
import RestClient from "../../../util/RestClient";
import {GET_SPOT_RATE_TICKER_NEW, MANAGER_BLOG_MASTER} from "../../../constants";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();
        this.state = {

        };
    }

    componentDidMount() {
        this.scrollToElement();
            this.getBlogsData({BlogPage:true,}).then(BlogData => {
                if (BlogData && BlogData.success){
                    this.setState({BlogDataResult:BlogData.result.data},()=>{
                        let logisticsExclusive = [];
                            let latestData = [];
                        this.state.BlogDataResult && this.state.BlogDataResult.map(ele=>{
                            if(ele.category == "Logistics Exclusive"){
                                logisticsExclusive.push(ele)
                            }
                            for(var i=0; i < 10; i++){
                                latestData.push(ele)
                            }
                        })

                        this.setState({logisticsExclusive,latestData},()=>{

                        })
                    })
                }
            })

    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    getBlogsData = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_BLOG_MASTER,
            method: "POST",
            data: dataForServer
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows:false,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        centerMode: false,
                        initialSlide: 2,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        slidesToScroll: 1,
                        dots: false
                    }
                }
            ]
        };
        const settings1 = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <AppLayout>
                <div className="blog_page" ref={this.testRef}>
                    <div className="container-fluid">
                        <div className="row mb-3">
                            {/*<div className="col-lg-12">*/}
                            {/*    <div className="blog_heading"><h4>Latest News</h4></div>*/}
                            {/*</div>*/}
                            <div className="col-lg-8">
                                <div className="big_blog">
                                    <Slider {...settings1}>
                                        {Array.isArray(this.state.BlogDataResult) && this.state.BlogDataResult.length >= 1
                                            ? this.state.BlogDataResult.map((obj, i) => {
                                                return(
                                                    <>
                                                        {obj && obj.Description ?
                                                            <div className="blog_img_big" key={i} onClick={e => {
                                                                if (obj.urlTitle){
                                                                    localStorage.setItem("BlogTitle","true");
                                                                    history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj.urlTitle)
                                                                    window.location.reload("false")

                                                                }else {

                                                                    e.preventDefault()
                                                                history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj._id)
                                                                window.location.reload("false")
                                                                }
                                                            }}>
                                                                <img src={obj && obj.imgLocation} alt="all news"/>
                                                                <div className="blog_description_big">
                                                                    <div className="blog_img_text">{obj && obj.title}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <a className="blog_img_big" href={obj && obj.newsCategoryLink} target="_blank" key={i} >
                                                                <img src={obj && obj.imgLocation} alt="all news"/>
                                                                <div className="blog_description_big">
                                                                    <div className="blog_img_text" >{obj && obj.title}</div>
                                                                </div>
                                                            </a>}


                                                    </>
                                                )
                                            })
                                            :""}
                                    </Slider>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="short_news slider_front_scroll">
                                    {Array.isArray(this.state.BlogDataResult) && this.state.BlogDataResult.length >= 1
                                        ? this.state.BlogDataResult.map((obj, i) => {
                                            return(
                                                <>
                                                    {obj.category === "Sneak Peak!"?
                                                        <>
                                                        <a className="slider_scroll_side" href={obj && obj.newsCategoryLink} target="_blank">
                                                            <div className="small_newss" key={i} >
                                                                <img src={obj && obj.imgLocation} alt="in the news"/>
                                                                <div className="small_description">
                                                                    <h3>{obj && obj.title}</h3>
                                                                    <h6>(<FontAwesomeIcon icon={faCalendarAlt}/> {obj && convertDateISOToCustomiseFormat(obj.date)})</h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        </>

                                                        :""}

                                                </>

                                            )
                                        })
                                        :""}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog_heading"><h4>Editor's Pick</h4></div>
                            </div>
                            <div className="col-lg-12">
                                <div className="all_blog">
                                    {Array.isArray(this.state.BlogDataResult) && this.state.BlogDataResult.length >= 1
                                        ? this.state.BlogDataResult.map((obj, i) => {
                                            return(
                                                <>
                                                    {obj.category === "Editor's Pick"?
                                                        <>
                                                            {obj && obj.Description ?
                                                                <div className="blog_img" key={i} onClick={e => {
                                                                    e.preventDefault()
                                                                    if (obj.urlTitle){
                                                                        localStorage.setItem("BlogTitle","true");
                                                                        history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj.urlTitle)
                                                                        window.location.reload("false")

                                                                    }else {

                                                                        e.preventDefault()
                                                                        history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj._id)
                                                                        window.location.reload("false")
                                                                    }
                                                                }}>
                                                                    <div className="blog_div">
                                                                        <img src={obj && obj.imgLocation}/>
                                                                        <div className="blog_description">
                                                                            <div className="blog_tit">{obj && obj.title}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <a className="blog_img" key={i} href={obj && obj.newsCategoryLink} target="_blank">
                                                                    <div className="blog_div">
                                                                        <img src={obj && obj.imgLocation}/>
                                                                        <div className="blog_description">
                                                                            <div className="blog_tit">{obj && obj.title}</div>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            }

                                                        </>

                                                    :""}

                                                </>

                                            )
                                        }):""}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 red_section">
                            <div className="col-lg-12">
                                <div className="blog_heading"><h4>In the News</h4></div>
                            </div>
                            <div className="col-lg-12 heightScroll">
                                <div className="all_blog_india">
                                    {Array.isArray(this.state.BlogDataResult) && this.state.BlogDataResult.length >= 1
                                        ? this.state.BlogDataResult.map((obj, i) => {
                                            return(
                                                <>
                                                    {obj.category === "In the News"?
                                                        <>
                                                            {obj && obj.Description ?
                                                            <div className="blog_img_india" key={i} onClick={e => {
                                                                if (obj.urlTitle){
                                                                    localStorage.setItem("BlogTitle","true");
                                                                    history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj.urlTitle)
                                                                    window.location.reload("false")

                                                                }else {

                                                                    e.preventDefault()
                                                                    history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj._id)
                                                                    window.location.reload("false")
                                                                }
                                                            }}>
                                                                <img src={obj && obj.imgLocation} alt="in the news"/>
                                                                <div className="blog_description_india">
                                                                    <a className="blog_img" href={obj && obj.newsCategoryLink} target="_blank">{obj && obj.title}</a>
                                                                </div>
                                                            </div>:
                                                                <div className="blog_img_india" key={i} >
                                                                    <img src={obj && obj.imgLocation} alt="in the news"/>
                                                                    <div className="blog_description_india">
                                                                        <a className="blog_img" href={obj && obj.newsCategoryLink} target="_blank">{obj && obj.title}</a>
                                                                    </div>
                                                                </div>}

                                                        </>

                                                        :""}

                                                </>

                                            )
                                        }):""}
                                </div>
                            </div>
                            {/*<div className="col-lg-12 text-center">*/}
                            {/*    <div className="form-group mt-2">*/}
                            {/*        <button className="btn">Show More <i className="fas fa-arrow-circle-right"></i></button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        <div className="row mt-3 blue_section">
                            <div className="col-lg-12">
                                <div className="blog_heading"><h4>Logistics Exclusive</h4></div>
                            </div>
                            <div className="col-lg-12">
                                <div className="all_blog_other">
                                    <Slider {...settings}>
                                        {Array.isArray(this.state.logisticsExclusive) && this.state.logisticsExclusive.length >= 1
                                            ? this.state.logisticsExclusive.map((obj, i) => {
                                                return(
                                                    <>
                                                    {obj && obj.Description ?
                                                        <div className="blog_img_other" key={i} onClick={e => {
                                                            e.preventDefault()
                                                            if (obj.urlTitle){
                                                                localStorage.setItem("BlogTitle","true");
                                                                history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj.urlTitle)
                                                                window.location.reload("false")

                                                            }else {

                                                                history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj._id)
                                                                window.location.reload("false")
                                                            }
                                                        }}>
                                                            <img src={obj && obj.imgLocation} alt="Logistics Exclusive"/>
                                                            <div className="blog_description_other">
                                                                <a title={obj && obj.title} className="blog_img" href={obj && obj.newsCategoryLink} target="_blank">{obj && obj.title ? obj.title.substring(0, Math.min(obj.title.length, 80))+"..." : undefined}</a>
                                                            </div>
                                                        </div>:
                                                        <div className="blog_img_other" key={i} >
                                                            <img src={obj && obj.imgLocation} alt="Logistics Exclusive"/>
                                                            <div className="blog_description_other">
                                                                <a title={obj && obj.title} className="blog_img" href={obj && obj.newsCategoryLink} target="_blank">{obj && obj.title ? obj.title.substring(0, Math.min(obj.title.length, 80))+"..." : undefined}</a>
                                                            </div>
                                                        </div>}

                                                    </>

                                                )
                                            }):""}

                                    </Slider>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 red_section">
                            <div className="col-lg-12">
                                <div className="blog_heading"><h4>Beyond Logistics</h4></div>
                            </div>
                            <div className="col-lg-12 heightScroll">
                                <div className="all_blog_india">
                                    {Array.isArray(this.state.BlogDataResult) && this.state.BlogDataResult.length >= 1
                                        ? this.state.BlogDataResult.map((obj, i) => {
                                            return(
                                                <>
                                                    {obj.category === "Beyond Logistics"?
                                                        <>
                                                            {obj && obj.Description ?
                                                                <div className="blog_img_india" key={i} onClick={e => {

                                                                    e.preventDefault()
                                                                    if (obj.urlTitle){
                                                                        localStorage.setItem("BlogTitle","true");
                                                                        history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj.urlTitle)
                                                                        window.location.reload("false")

                                                                    }else {

                                                                        history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj._id)
                                                                        window.location.reload("false")
                                                                    }
                                                                }}>
                                                                    <img src={obj && obj.imgLocation} alt="in the news"/>
                                                                    <div className="blog_description_india">
                                                                        <a className="blog_img">{obj && obj.title}</a>
                                                                    </div>
                                                                </div>:
                                                                <div className="blog_img_india" key={i} >
                                                                    <img src={obj && obj.imgLocation} alt="in the news"/>
                                                                    <div className="blog_description_india">
                                                                        <a className="blog_img" href={obj && obj.newsCategoryLink} target="_blank">{obj && obj.title}</a>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>

                                                        :""}

                                                </>

                                            )
                                        }):""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterHome/>
            </AppLayout>
        )
    }


};

export default BlogPage;
