
import React, {Component, Fragment} from "react";
import {QUERY_MANAGER_UPLOAD_MSDS} from "../constants";
import {toastr} from "react-redux-toastr";
import {Button, Col, FormGroup, Row} from "reactstrap";
import PropTypes from "prop-types";
import RestClient from "../util/RestClient";

class UploadMsdsNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdffile: [],
      pdffileSingle: "",
      isLoading: true,
      isUploading: false,
      error: "",
      selectedOrigin: undefined,
      selectionOptions: []
    };
  }

  onDrop = files => {
    this.setState({
      pdffile: files
    });
  };

  uploadAttachments = async values => {

    return await RestClient({
      url: QUERY_MANAGER_UPLOAD_MSDS,
      method: "POST",
      data: values,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  };

  componentDidUpdate() {
    if (this.props.error) {
      toastr.success("Upload Error", "" + this.props.error, {
        transitionIn: "bounceIn",
        transitionOut: "bounceOut"
      });
    }
  }

  saveImages = e => {

    const image = [];
    let t = e.target.files;

    for (let i = 0; i < t.length; i++) {
      image.push(t[i]);
    }
    this.setState({[e.target.name]: image});
    if (image && image.length > 0) {
      if (this.props.isSingleUpload) {
        this.setState({pdffileSingle: image[0]});
      } else {
        this.setState({[e.target.name]: image});
      }

    }
  };

  render() {
    try {
      return (
        <Fragment>

              <div className="margin-rite">
                  <div className="form-group">
                    <div className="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFile"
                      onChange={e => this.saveImages(e)}
                      name="pdffile"
                      multiple
                    />
                    <label
                      className="custom-file-label" for="customFile">
                      {this.props.placeHolder}{" "}
                      {(this.state.pdffile && !this.props.isSingleUpload) ? this.state.pdffile : ""}
                    </label>
                    {this.state.pdffile && !this.props.isSingleUpload ? this.state.pdffile.map(ex => (
                      <li>{ex.name}</li>)) : this.state.pdffileSingle ?
                      <li>{this.state.pdffileSingle.name}</li> : ""}
                  </div>
                  </div>
              </div>
              <div className="upload-newquery">
                  <div className="form-group">
                      <Button
                        color=""
                        className=""
                        disabled={this.state.isUploading}
                        onClick={e => {
                          e.preventDefault();
                          this.props.isUploadingFiles(true)
                          const data = new FormData();
                          let isFileEmpty = false;
                          if (this.props.isSingleUpload) {
                            data.append("awsFileUpload", this.state.pdffileSingle);
                            if (this.state.pdffileSingle === undefined) {
                              isFileEmpty = true
                            }
                          } else {
                            data.append("awsFileUpload", this.state.pdffile);
                            if (this.state.pdffile === undefined) {
                              isFileEmpty = true
                            }
                          }
                         // debugger
                          if (isFileEmpty) {
                            return
                          }
                          this.setState({
                            isUploading: true
                          })

                          this.uploadAttachments(data)
                            .then(response => {
                                debugger
                                this.props.isUploadingFiles(false)
                                if (response.success) {
                                  this.props.onUploadFinish(this.props.name, response.result)
                                  // if (this.props.isSingleUpload && response.result.length > 0) {
                                  //   this.props.onUploadFinish(this.props.name, response.result[0].url)
                                  // } else {
                                  //   this.props.onUploadFinish(this.props.name, response.result)
                                  // }
                                  this.setState({
                                    uploadedFilesUrls: response.result,
                                    isUploading: false
                                  })

                                  // toastr.success("Upload Successfully", "" + response.message, {
                                  //   transitionIn: "bounceIn",
                                  //   transitionOut: "bounceOut"
                                  // });
                                } else {
                                  this.setState({
                                    isUploading: false
                                  })
                                  toastr.success("Upload Error", "" + response.message, {
                                    transitionIn: "bounceIn",
                                    transitionOut: "bounceOut"
                                  });
                                }
                              }
                            )
                            .catch(
                              e => {
                                //alert(e)
                                this.props.onUploadFinish(this.props.name, [])
                                this.props.isUploadingFiles(false)
                                this.setState({
                                  isUploading: false
                                })
                                toastr.success("Upload Error", "" + "", {
                                  transitionIn: "bounceIn",
                                  transitionOut: "bounceOut"
                                });
                              }
                            );
                        }}>
                        Upload
                      </Button>
                  </div>
              </div>

        </Fragment>
      );
    } catch (e) {
      //alert(e);
      return "";
    }
  }
}

const propTypes = {
  isUploadingFiles: PropTypes.func.isRequired,
  isSingleUpload: PropTypes.bool.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired
};

const defaultProps = {
  isUploadingFiles: undefined,
  isSingleUpload: false,
  onUploadFinish: undefined,
  name: "",
  placeHolder: "Select Attachment"
};

UploadMsdsNew.propTypes = propTypes;
UploadMsdsNew.defaultProps = defaultProps;

export default UploadMsdsNew;
