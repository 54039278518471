import {
  SHIPPER_FETCH_ALL,
  SHIPPER_FETCH_ALL_FAILURE,
  SHIPPER_FETCH_ALL_RESPONSE,
  CONSIGNEE_FETCH_ALL,
  CONSIGNEE_FETCH_ALL_FAILURE,
  CONSIGNEE_FETCH_ALL_RESPONSE
} from "../actions";

export const onShipperGetAllRequest = (shipperConsigneeParams) => ({
  type: SHIPPER_FETCH_ALL,
  payload: shipperConsigneeParams
});

export const onShipperGetAllRequestFetched = (originDestinationMasterResponse) => ({
  type: SHIPPER_FETCH_ALL_RESPONSE,
  payload: originDestinationMasterResponse
});

export const onShipperGetAllRequestFailed = (message) => ({
  type: SHIPPER_FETCH_ALL_FAILURE,
  payload: {message}
});
export const onConsigneeGetAllRequest = (shipperConsigneeParams) => ({
  type: CONSIGNEE_FETCH_ALL,
  payload: shipperConsigneeParams
});

export const onConsigneeGetAllRequestFetched = (originDestinationMasterResponse) => ({
  type: CONSIGNEE_FETCH_ALL_RESPONSE,
  payload: originDestinationMasterResponse
});

export const onConsigneeGetAllRequestFailed = (message) => ({
  type: CONSIGNEE_FETCH_ALL_FAILURE,
  payload: {message}
});
