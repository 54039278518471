import React, {Component, Fragment} from "react";
import {Row, Card, CardTitle, Label, Input, Button, Modal, ModalHeader, ModalBody, FormGroup} from "reactstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IntlMessages from "../../../helpers/IntlMessages";

import SimpleReactValidator from "simple-react-validator";


import {Field, Form, Formik} from "formik";




import {withRouter} from "react-router";
import {parseFloatValue} from "../../../util/CommonUtility";

class MultiSlabWeight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gross:"",
            volume:"",
            phoneNo: "",
            modalLarge: false,
            message: "",
            openLogin: false,
            Compalsari: false,
            calculatedDensity:"",
            minWeight:"",
            validTillWeight:"",
            TotalChargeable:""
        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {


        if (this.props.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        if (this.props.Compalsari) {
            this.setState({
                Compalsari: this.props.Compalsari
            })
        }

    }

    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if (this.state.modalLarge === false){
                this.props.setmodal(false)
            }
        });
    };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }

    validateEmail = (value) => {

        if (!value) {
            toast.error("Please enter your email address", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {

            toast.error("Invalid email address", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                contactEmail:""
            })
        }

    }

    validatePhonNumber = (value) => {

        if (!value) {
            toast.error("Please enter your Phone Number", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!/^\d{10}$/.test(value)) {

            toast.error("Phone Number Should be 10 digit", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                contactNumber:""
            })
        }

    }




    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        if (this.props.Compalsari && this.props.Compalsari !== prevProps.Compalsari) {
            this.setState({
                Compalsari: this.props.Compalsari
            })
        }
        if (this.props.minWeight && this.props.minWeight !== prevProps.minWeight) {
            this.setState({
                minWeight: this.props.minWeight
            })
        }

        if (this.props.validTillWeight && this.props.validTillWeight !== prevProps.validTillWeight) {
            this.setState({
                validTillWeight: this.props.validTillWeight
            })
        }


    }

    handleChange = selectedOption => {

        this.setState({selectedOption});

    };

    calculateChargeable = () => {

        try {
            if (this.state.volume && this.state.gross) {
                let volumeWeight = parseInt(Math.round(this.state.volume));
                let grossWeight = parseInt(Math.round(this.state.gross));



                grossWeight > volumeWeight
                    ? this.setState({
                        TotalChargeable: parseFloatValue(grossWeight)
                    }, () => {

                        if (this.state.TotalChargeable === "0") {
                            this.setState({
                                calculatedDensity: "0"
                            });
                        } else {
                            let density = (volumeWeight / grossWeight) * 100;
                            this.setState({
                                calculatedDensity: parseFloat(density).toFixed(2)
                            });
                        }

                    })
                    : this.setState({
                        TotalChargeable: parseFloatValue(volumeWeight)
                    }, () => {
                        if (this.state.TotalChargeable === "0") {
                            this.setState({
                                calculatedDensity: "0"
                            });
                        } else {
                            let density = (volumeWeight / grossWeight) * 100;
                            this.setState({
                                calculatedDensity: parseFloat(density).toFixed(2)
                            });
                        }
                    });
            }
        } catch (e) {
        }
    };

    handleSubmit =()=>{


        if(this.mandatoryValidations.allValid() && this.state.gross !== "" &&  this.state.volume !== "" && this.state.NoPieces )
        {
            if(this.state.TotalChargeable >= this.state.minWeight && this.state.TotalChargeable <= this.state.validTillWeight){
                let data = {
                    gross:this.state.gross,
                    NoPieces:this.state.NoPieces,
                    volume:this.state.volume !== "" ? this.state.volume : "",
                    calculatedDensity:this.state.calculatedDensity !== "" ? this.state.calculatedDensity : "",
                    TotalChargeable:this.state.TotalChargeable !== "" ? this.state.TotalChargeable : "",
                }
                this.props.recivedContactInfo (data)
                this.setState({
                    modalLarge: false
                })
                this.props.setmodal(false)
            }
            else {
                toast.error("Chargeable Weight Not in  Selected Slab Valid Weight Range", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }else {
            this.mandatoryValidations.showMessages();
            this.forceUpdate();
        }

    }



    render() {
        const {password, email, repassword, customerName, gstNo, phoneNo, selectedState} = this.state;
        const initialValues = {email, password, repassword, customerName, gstNo, phoneNo, selectedState};

        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Modal className="chargeable-model"
                       isOpen={this.state.modalLarge}
                       size="md"
                       toggle={this.toggleLarge}
                >

                    <>
                        <ModalHeader toggle={this.toggleLarge} className="" style={{padding: "0"}}>
                            {/*<NavLink to={`/`} className=" white">*/}
                            {/*    /!*<span className="logo-single" style={{marginBottom: "-9px"}}/>*!/*/}
                            {/*</NavLink>*/}
                        </ModalHeader>
                        <ModalBody className="padding-0">
                            <div className="back-img" style={{padding: "0px;"}}>
                                <div className="auth-card2">
                                    <div className="cont-forms form-side">
                                        <h5 className="mb-5  text-center position-relative" style={{fontWeight: "bold",color:"#fff"}} >
                                            <span>Chargeable Weight</span></h5>
                                        <form className="av-tooltip tooltip-label-bottom">

                                            <div className="form-group "><label
                                                className=""><span>Number of pieces</span></label>
                                                <Input type="text" className="form-control"
                                                       placeholder="Number of Pieces"
                                                       name="NoPieces"
                                                       value={this.state.NoPieces}
                                                    // onBlur={e=>{this.validateEmail(e.target.value)}}
                                                       onChange={e=>{this.setState({NoPieces: e.target.value})} }
                                                    // disabled = {true}
                                                />
                                                {/*<p style={{color:"#ff1515",fontWeight:"700"}}>{this.mandatoryValidations.message(*/}
                                                {/*    "Email",*/}
                                                {/*    this.state.contactEmail,*/}
                                                {/*    "required",*/}
                                                {/*)}</p>*/}
                                            </div>


                                            <div className="form-group"><label
                                                className=""><span>Gross Weight </span></label>
                                                <Input type="number" className="form-control"
                                                       placeholder="Gross Weight"
                                                       name="gross"
                                                    // onBlur={e=>{this.validatePhonNumber(e.target.value)}}
                                                       value={this.state.gross}
                                                       onChange={e=>{this.setState({gross: e.target.value},()=>{
                                                           this.calculateChargeable()
                                                       })} }
                                                />
                                                <p style={{color:"#ff1515",fontWeight:"700"}}>{this.mandatoryValidations.message(
                                                    "Contact",
                                                    this.state.gross,
                                                    "required",
                                                )}</p>
                                            </div>

                                            <div className="form-group"><label
                                                className=""><span>Volume Weight </span></label>
                                                <Input type="number" className="form-control"
                                                       placeholder="Volume Weight"
                                                       name="volume"
                                                    // onBlur={e=>{this.validatePhonNumber(e.target.value)}}
                                                       value={this.state.volume}
                                                       onChange={e=>{this.setState({volume: e.target.value},()=>{
                                                           this.calculateChargeable()
                                                       })} }
                                                />
                                                <p style={{color:"#ff1515",fontWeight:"700"}}>{this.mandatoryValidations.message(
                                                    "Contact",
                                                    this.state.volume,
                                                    "required",
                                                )}</p>
                                            </div>

                                            <div className="form-group"><label
                                                className=""><span>Chargeable Weight </span></label>
                                                <Input type="number" className="form-control charge-input"
                                                       placeholder="Chargeable Weight"
                                                       disabled
                                                       name="TotalChargeable"
                                                    // onBlur={e=>{this.validatePhonNumber(e.target.value)}}
                                                       value={this.state.TotalChargeable}
                                                    // onChange={e=>{this.setState({volume: e.target.value})} }
                                                />
                                                <p style={{color:"#ff1515",fontWeight:"700"}}>{this.mandatoryValidations.message(
                                                    "Contact",
                                                    this.state.TotalChargeable,
                                                    "required",
                                                )}</p>
                                                <p className="text-capitalize" style={{color: "#edff00",
                                                    fontWeight: "600"}}>chargeable weight should between {this.state.minWeight}kgs-{this.state.validTillWeight}kgs according to selected weight slab.</p>
                                            </div>

                                            <div className="form-group mt-4 text-center">

                                                <button className="book-btn btn " style={{borderRadius: "50px;"}}
                                                        onClick={e=>{e.preventDefault()
                                                            this.handleSubmit()
                                                        }}>
                                                    Validate
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                            </div>
                        </ModalBody>
                    </>




                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {user, loading, message, LoginMessage, closeModal} = state.authUser;
    return {user, loading, message, LoginMessage, closeModal};
};

const MultiSlabWeightWithRoute = withRouter(MultiSlabWeight);

export default connect(
    mapStateToProps,
    {}
)(MultiSlabWeightWithRoute);
