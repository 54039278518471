import React, { Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {
    faAngleDown, faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RestClient from "../../../util/RestClient";
import {
    AIR_API_TRACKING,
    COURIER_API_TRACKING, E_DOCKET_REQUEST_REQUEST_AIR, E_DOCKET_REQUEST_REQUEST_OCEAN, OCEAN_API_TRACKING,
} from "../../../constants";
import {convertDateISOToCustomiseFormat} from "../../../util/CommonUtility";
import SignUp from "../../user/signUp";
import {Button} from "reactstrap";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";


class TrackingAirAndOceanPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modes : "Air",
            viewDetails:false,
            pickupConfirm:false,
            pickedUp:false,
            receivedAtHub:false,
            freightTrack:false,
            destinationClearanceTrack:false,
            originClearanceTrack:false,
            delivered:false,
            deliveryDate:"",
            expectedDeliveryDate : "",
            modelLogin : false,
            buttonShowing : false,
        };

    }
    componentDidMount() {
        let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        let TrackNo = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let quoteId = window.location.href.split("/")[window.location.href.split("/").length - 3];
        this.setState({quoteFor : quoteFor,quoteId : quoteId},()=>{
            if(this.state.quoteId && this.state.quoteId !== "TrackShipmentAir" && this.state.quoteId !== "TrackShipmentOcean"){
                this.quoteIdApiCalling({id : quoteId,quoteFor : quoteFor}).then((responce)=>{
                    if (responce.success) {
                        let QueryResult = responce && responce.result ? responce.result : "";
                        this.setState({
                            buttonShowing : true,
                            invoiceId: QueryResult && QueryResult.invoice ? QueryResult.invoice._id:"",
                            approvalStatus: QueryResult && QueryResult.invoice && QueryResult.invoice.approvalStatus ? QueryResult.invoice.approvalStatus:"",
                        })
                    }
                })
            }
            this.AirTrackingApiCalling({trackingNo: TrackNo}).then((responce) => {
                if (responce.success) {
                    let TrackingResult = responce && responce.result ? responce.result : "";
                    let shipmentDetails = TrackingResult && TrackingResult.shipmentData ? TrackingResult.shipmentData : {}
                    let activityType = TrackingResult && TrackingResult.shipmentData && TrackingResult.shipmentData.activityType ? TrackingResult.shipmentData.activityType : {}
                    let pickUpResult = TrackingResult && TrackingResult.pickup ? TrackingResult.pickup : []
                    let originClearanceResult = TrackingResult && TrackingResult.OC ? TrackingResult.OC : []
                    let freight = TrackingResult && TrackingResult.freight ? TrackingResult.freight : []
                    let destinationClearanceResult = TrackingResult && TrackingResult.DC ? TrackingResult.DC : []
                    let deliveryResult = TrackingResult && TrackingResult.delivery ? TrackingResult.delivery : []
                    let pickupArray = []
                    let deliveryArray = []
                    let newArray = []
                    pickUpResult && pickUpResult.length>0 && pickUpResult.map((item, index)=>{
                        pickupArray.push(...item.events)
                    })
                    pickupArray.reverse()
                    originClearanceResult.reverse()
                    freight.reverse()
                    destinationClearanceResult.reverse()
                    deliveryResult && deliveryResult.length>0 && deliveryResult.map((item, index)=>{
                        deliveryArray.push(...item.events)
                    })
                    deliveryArray.reverse()
                    this.setState({
                        pickupData : pickupArray,
                        deliveryData : deliveryArray,
                        freightData : freight,
                        destinationClearanceData : destinationClearanceResult,
                        originClearanceData : originClearanceResult,
                        BlNo : shipmentDetails && shipmentDetails.blNo ? shipmentDetails.blNo :"",
                        pickUpPinCode : activityType === "Door To Door" || activityType === "Door To Port" ? shipmentDetails && shipmentDetails.originDoor &&  shipmentDetails.originDoor.city ? shipmentDetails.originDoor.city :"" : shipmentDetails && shipmentDetails.originAirport && shipmentDetails.originAirport.name ? shipmentDetails.originAirport.name : "",
                        dropOffPinCode : activityType === "Door To Door" || activityType === "Port To Door" ? shipmentDetails && shipmentDetails.destinationDoor &&  shipmentDetails.destinationDoor.city ? shipmentDetails.destinationDoor.city :"" : shipmentDetails && shipmentDetails.destinationAirport && shipmentDetails.destinationAirport.name ? shipmentDetails.destinationAirport.name : "",
                    },()=>{
                        this.state.pickupData && this.state.pickupData.length>0 && this.state.pickupData.map((item, index)=>{
                            if(item.sNo === 1){
                                this.setState({
                                    pickupConfirm : true,
                                    pickupDate: item && item.pickupDate ? convertDateISOToCustomiseFormat(item.pickupDate) : "",
                                    expectedDeliveryDate: item && item.expectedDeliveryDate ? convertDateISOToCustomiseFormat(item.expectedDeliveryDate) : "",
                                })
                            }
                        })
                        if(this.state.originClearanceData && this.state.originClearanceData.length>0){
                            this.setState({
                                originClearanceTrack : true
                            })
                        }
                        if(this.state.freightData && this.state.freightData.length>0){
                            this.setState({
                                freightTrack : true
                            })
                        }
                        if(this.state.destinationClearanceData && this.state.destinationClearanceData.length>0){
                            this.setState({
                                destinationClearanceTrack : true
                            })
                        }
                        if(this.state.deliveryData && this.state.deliveryData.length>0){
                            if(this.state.deliveryData && this.state.deliveryData[0] && this.state.deliveryData[0].activityType && this.state.deliveryData[0].activityType.toUpperCase() === "DELIVERED"){
                              let date = this.state.deliveryData[0] && this.state.deliveryData[0].date ? this.state.deliveryData[0].date : ""
                                this.setState({
                                    delivered : true,
                                    deliveryDate : date ? convertDateISOToCustomiseFormat(date) :""
                                })
                            }

                        }
                    })


                }
            });

        })

    }
    quoteIdApiCalling = async (dataForServer) => {
        return await RestClient({
            url: dataForServer.quoteFor === "Air" ? E_DOCKET_REQUEST_REQUEST_AIR : E_DOCKET_REQUEST_REQUEST_OCEAN,
            method: "POST",
            data: dataForServer
        });
    };
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    courierTrackingApiCalling = async (dataForServer) => {
        return await RestClient({
            url: COURIER_API_TRACKING,
            method: "POST",
            data: dataForServer
        });
    };
    AirTrackingApiCalling = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Air" ? AIR_API_TRACKING : OCEAN_API_TRACKING,
            method: "POST",
            data: dataForServer
        });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {

        return (
            <>

                <MetaTags>
                    <title>Live Tracking  | Zipaworld </title>
                    <meta name="title" content="Live tracking for air, ocean, road cargo on Zipaworld" />
                    <meta name="description" content="Zipaworld helps in 24/7 live tracking of multimodal freight and cargo" />
                    <meta name="keywords" content="airwaybill tracking, AWB tracking, container tracking online, how to track my air cargo, track shipping container online" />
                </MetaTags>
                <AppLayout>
                    {this.state.modelLogin ?
                        <SignUp
                            modalLarge={this.state.modelLogin}
                            setmodal={(values) =>
                                values === false
                                    ? this.setState({ modelLogin: false })
                                    : ""
                            }
                        />
                        : ""}
                    <div className="dashboard-tracking">
                        <div className="tracking-shipment">
                            <div className="container"><h3 className="track-head">Tracking Details</h3>
                                <div className="stepper-wrapper">
                                    <div className={this.state.pickupConfirm ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">1</div>
                                        <div className="step-name">Pick Up</div>
                                    </div>
                                    <div className={this.state.originClearanceTrack ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">2</div>
                                        <div className="step-name">Origin Clearance</div>
                                    </div>

                                    <div className={this.state.freightTrack ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">3</div>
                                        <div className="step-name">Cargo Details</div>
                                    </div>
                                    <div className={this.state.destinationClearanceTrack ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">4</div>
                                        <div className="step-name">Destination Clearance</div>
                                    </div>
                                    <div className={this.state.delivered ? "stepper-item completed" : "stepper-item"} onClick={()=>{
                                        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                            this.setState({
                                                viewDetails: !this.state.viewDetails
                                            })
                                        }else{
                                            this.setState({
                                                modelLogin : true
                                            })
                                        }
                                    }}>
                                        <div className="step-counter">5</div>
                                        <div className="step-name">Delivery</div>
                                    </div>
                                </div>
                                <div className="tracking-search">
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="tracking-No ">
                                            <div className="heading-track">{this.state.quoteFor === "Air" ? "AWB No" : "BL No"}</div>
                                            <div className="tracking-left">{this.state.BlNo}</div>
                                        </div>
                                        <div className="tracking-No ">
                                            <div className="heading-track">Origin</div>
                                            <div className="tracking-left">{this.state.pickUpPinCode}</div>
                                        </div>
                                        <div className="tracking-No ">
                                            <div className="heading-track">Destination</div>
                                            <div className="tracking-left">{this.state.dropOffPinCode}</div>
                                        </div>
                                        {/*<div className="tracking-No ">*/}
                                        {/*    <div className="heading-track">Status</div>*/}
                                        {/*    <div className="tracking-left">{this.state.activityTypeRelative}</div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="tracking_details">
                                    <div className="d-flex flex-wrap justify-content-between align-stretched">
                                        <div className="d-flex flex-wrap align-items-center dd_track width-80">
                                            <img src="../../../assets/img/location.png" alt="Location Icon"/>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between width-80">
                                                <div className="">
                                                    <div className="heading-track">Pick Up Date</div>
                                                    <div className="trackingDate">{this.state.pickupDate}</div>
                                                </div>
                                                <div className="">
                                                    <div className="heading-track">{this.state.deliveryDate  ? "Date of Delivery" : "Expected Delivery Date"}</div>
                                                    <div className="trackingDate">{this.state.deliveryDate ? this.state.deliveryDate : this.state.expectedDeliveryDate}</div>
                                                </div>
                                                <div className="">
                                                    <div className="heading-track">Time of Delivery</div>
                                                    <div className="trackingDate">{this.state.deliveryDate ? this.state.deliveryDate : this.state.expectedDeliveryDate}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="view-btn" onClick={()=>{
                                            if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
                                                this.setState({
                                                    viewDetails: !this.state.viewDetails
                                                })
                                            }else{
                                                this.setState({
                                                    modelLogin : true
                                                })
                                            }
                                        }}>More Details {" "}{this.state.viewDetails === true ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>}
                                        </div>
                                    </div>
                                    {this.state.viewDetails ?
                                        <div className="more-details">
                                            <div>
                                                <div className="table-responsive tableHeightTracking">
                                                    <table className="table trackingTableData">
                                                        <thead>
                                                            <th className="text-left">Activity Type</th>
                                                            <th className="text-left">Date</th>
                                                            <th className="text-left">Time</th>
                                                            <th className="locationData">Location</th>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.deliveryData && this.state.deliveryData.length>0 ?<>

                                                                {this.state.deliveryData.map((obj, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className="text-left">
                                                                                    <p>{obj.activityType}</p>
                                                                                    {/*<p>{obj.vehicle}</p>*/}
                                                                                    {/*<p>{obj.driverName}{" / "}{obj.dl}</p>*/}
                                                                                </td>
                                                                                <td className="text-left">
                                                                                    <p>{obj.activityType === "Pickup Generated" ? obj.pickupDate ? convertDateISOToCustomiseFormat(obj.pickupDate):"" : obj.date ? convertDateISOToCustomiseFormat(obj.date):"" }</p>
                                                                                </td>
                                                                                <td className="text-left"><p>{obj.time ? obj.time : "-"}</p></td>
                                                                                <td className="locationData"><p>{(obj.activityType).toUpperCase() === "RECEIVED AT AIRPORT" || (obj.activityType).toUpperCase() === "RECEIVED AT WAREHOUSE" || (obj.activityType).toUpperCase() === "RECEIVED AT HUB" ? obj.warehouse ? obj.warehouse : "" :
                                                                                    (obj.activityType).toUpperCase() === "DELIVERED" || (obj.activityType).toUpperCase() === "OUT FOR DELIVERY" ? obj.to ? obj.to :"" : obj.from ? obj.from : "-"}</p></td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                            :""}
                                                        {this.state.destinationClearanceData && this.state.destinationClearanceData.length>0 ?
                                                            <>

                                                                {this.state.destinationClearanceData && this.state.destinationClearanceData.length>0 && this.state.destinationClearanceData.map((obj, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className="text-left"><p>{obj.activityType}</p></td>
                                                                                <td className="text-left"><p>{obj.date ? convertDateISOToCustomiseFormat(obj.date):""}</p></td>
                                                                                <td className="text-left"><p>{obj.time ? obj.time : "-"}</p></td>
                                                                                <td className="locationData"><p>{obj.location ? obj.location : "-"}</p></td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                            :""}
                                                        {this.state.freightData && this.state.freightData.length>0 ?
                                                            <>

                                                                {this.state.freightData.map((obj, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className="text-left"><p>{obj.activityType}</p>
                                                                                    {/*<p className="airlineDataHead">{obj.airlineName}</p>*/}
                                                                                    {/*<p className="airlineDataLocation">{obj.location}</p>*/}
                                                                                </td>
                                                                                <td className="text-left"><p>{obj.date ? convertDateISOToCustomiseFormat(obj.date):""}</p></td>
                                                                                <td className="text-left"><p>{obj.time ? obj.time : "-"}</p></td>
                                                                                <td className="locationData"><p>{obj.location ? obj.location : "-"}</p></td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                            :""}
                                                        {this.state.originClearanceData && this.state.originClearanceData.length>0 ?
                                                            <>

                                                                {this.state.originClearanceData.map((obj, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className="text-left"><p>{obj.activityType}</p></td>
                                                                                <td className="text-left"><p>{obj.date ? convertDateISOToCustomiseFormat(obj.date):"" }</p></td>
                                                                                <td className="text-left"><p>{obj.time ? obj.time : "-"}</p></td>
                                                                                <td className="locationData"><p>{obj.location ? obj.location : "-"}</p></td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                            :""}

                                                        {this.state.pickupData && this.state.pickupData.length>0 ?
                                                            <>
                                                                {this.state.pickupData.map((obj, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className="text-left">
                                                                                    <p>{obj.activityType}</p>
                                                                                    {/*<p>{obj.vehicle}</p>*/}
                                                                                    {/*<p>{obj.driverName}{" / "}{obj.dl}</p>*/}
                                                                                </td>
                                                                                <td className="text-left">
                                                                                    <p>{obj.activityType === "Pickup Generated" ? obj.pickupDate ? convertDateISOToCustomiseFormat(obj.pickupDate):"" : obj.date ? convertDateISOToCustomiseFormat(obj.date):""  }</p>
                                                                                </td>
                                                                                <td className="text-left"><p>{obj.time ? obj.time : "-"}</p></td>
                                                                                <td className="locationData"><p>{(obj.activityType).toUpperCase() === "RECEIVED AT AIRPORT" || (obj.activityType).toUpperCase() === "RECEIVED AT WAREHOUSE" ? obj.warehouse : obj.from ? obj.from : "-"}</p></td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                            :""}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    :""}

                                </div>
                            </div>
                        </div>
                    </div>

                </AppLayout>
                <div className="fixed-btnsbot fixed-btnsbot1">
                    <div className="d-flex flex-wrap justify-content-end">
                        {this.state.buttonShowing ?
                        <Button
                            onClick={e => {
                                e.preventDefault()
                                history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + "/" + this.state.quoteFor + "/" +this.state.quoteId)
                                window.location.reload("false")

                            }}
                            type="button"
                            className="btn delete border-cir"
                        >Previous</Button>:""}
                        <Button onClick={e => {
                            e.preventDefault()
                            history.push(PAGE_PATHS.DASHBOARD)
                            window.location.reload("false")
                        }}
                                type="button"
                                className="btn delete border-cir"
                        >Home</Button>
                        {this.state.buttonShowing ?
                            <Button onClick={e => {
                            e.preventDefault()
                            if(this.state.approvalStatus === "Approved"){
                                history.push(PAGE_PATHS.VIEW_FOR_INVOICE_MANAGER + "/" + this.state.quoteFor + "/" + this.state.invoiceId  )
                                window.location.reload("false")
                            }else{
                                history.push(PAGE_PATHS.DASHBOARD)
                                window.location.reload("false")
                            }

                        }}
                                type="button"
                                className="btn delete border-cir"
                        >Next</Button>:""}

                    </div>
                </div>

            </>
        )
    }
}




export default TrackingAirAndOceanPage;

