import {
    EDIT_STATE_DATA,
    EDIT_STATE_DATA_FAILURE,
    EDIT_STATE_DATA_FETCH,
    EDIT_STATE_DATA_SUCCESS,
    MANAGER_PAST_QUERY_DATA,
    MANAGER_QUERY_DATA,
    MANAGER_QUERY_DATA_FAILURE,
    MANAGER_QUERY_DATA_HISTORY,
    MANAGER_QUERY_DATA_HISTORY_FAILURE,
    MANAGER_QUERY_DATA_HISTORY_SUCCESS,
    MANAGER_QUERY_DATA_SUCCESS,
    MANAGER_QUERY_PAST_DATA_FAILURE,
    MANAGER_QUERY_PAST_DATA_SUCCESS,
    MANAGER_STATE_DATA,
    MANAGER_STATE_DATA_FAILURE,
    MANAGER_STATE_DATA_SUCCESS,
    MARKET_QUERY_DATA,
    MARKET_QUERY_DATA_FAILURE,
    MARKET_QUERY_DATA_SUCCESS,
    QUERY_DELETE,
    QUERY_DELETE_RESPONSE,
    AWB_PDF_SEND,
    AWB_PDF_SEND_RESPONSE,
    QUERY_PREVIEW_DATA,
    QUERY_PREVIEW_DATA_FAILURE,
    QUERY_PREVIEW_DATA_SUCCESS,
    QUERY_REVERT_AUTO_DATA,
    QUERY_REVERT_AUTO_DATA_FAILURE,
    QUERY_REVERT_AUTO_DATA_SUCCESS,
    QUERY_REVERT_DATA,
    QUERY_REVERT_DATA_FAILURE,
    QUERY_REVERT_DATA_FOR_MARKET_RATE,
    QUERY_REVERT_DATA_MARKET_RATE_FAILURE,
    QUERY_REVERT_DATA_MARKET_RATE_SUCCESS,
    QUERY_REVERT_DATA_SUCCESS,
    SEND_DATA_TO_ADD_QUERY,
    SEND_DATA_TO_ADD_QUERY_FAILURE,
    SEND_DATA_TO_ADD_QUERY_SUCCESS,
    SEND_DATA_TO_UPDATE_QUERY,
    SEND_DATA_TO_UPDATE_QUERY_FAILURE,
    SEND_DATA_TO_UPDATE_QUERY_SUCCESS,
    SEND_TO_ADD_COPY_ACTION,
    STATE_DELETE,
    STATE_DELETE_RESPONSE,
    STATE_UPDATE,
    STATE_UPDATE_RESPONSE,

} from "../actions";

const initialState = {
    name:"",
    stateCode:"",
    abbr:"",

};

const QueryReducerAir = (state = initialState, {type, payload}) => {
    switch (type) {
        case SEND_DATA_TO_ADD_QUERY:
            return {...state};

        case SEND_DATA_TO_ADD_QUERY_SUCCESS:

            return {...state, message: payload.message , Responcedata :payload.result};

        case SEND_DATA_TO_ADD_QUERY_FAILURE:
            return {...state, message: payload.message};


        case SEND_DATA_TO_UPDATE_QUERY:
            return {...state};

        case SEND_DATA_TO_UPDATE_QUERY_SUCCESS:

            return {...state, message: payload.message , Responcedata :payload.result};

        case SEND_DATA_TO_UPDATE_QUERY_FAILURE:
            return {...state, message: payload.message};


        case MANAGER_STATE_DATA:
            return {...state};

        case MANAGER_STATE_DATA_SUCCESS:

            return {...state, managerData: payload.data};

        case MANAGER_STATE_DATA_FAILURE:
            return {...state, message: payload.message};

        case EDIT_STATE_DATA_FETCH:
            return {...state};

        case EDIT_STATE_DATA_SUCCESS:

            return {...state, EditData: payload};

        case EDIT_STATE_DATA_FAILURE:
            return {...state, message: payload.message};


        case EDIT_STATE_DATA:
            return {...state, data: payload};


        case STATE_UPDATE:
            // console.log("In STATE_UPDATE " + payload);
            return {...state,  response: ''};

        case STATE_UPDATE_RESPONSE:
            // console.log("in STATE_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state,  message: payload};

        case STATE_DELETE:
            // console.log("in STATE Delete RESPONSE" + JSON.stringify(payload));
            return {...state, message: payload.message};

        case STATE_DELETE_RESPONSE:
            // console.log("in STATE_UPDATE_RESPONSE" + JSON.stringify(payload));
            return {...state, message: payload.message};


        case QUERY_PREVIEW_DATA:
            return {...state};

        case QUERY_PREVIEW_DATA_SUCCESS:

            return {...state, QueryData: payload};

        case QUERY_PREVIEW_DATA_FAILURE:
            return {...state, message: payload.message};

        case QUERY_REVERT_DATA:
            return {...state};

        case QUERY_REVERT_DATA_SUCCESS:

            return {...state, RevertRate: payload};

        case QUERY_REVERT_DATA_FAILURE:
            return {...state, message: payload.message};


        case QUERY_REVERT_DATA_FOR_MARKET_RATE:
            return {...state};

        case QUERY_REVERT_DATA_MARKET_RATE_SUCCESS:

            return {...state, RevertRateForMarket: payload};

        case QUERY_REVERT_DATA_MARKET_RATE_FAILURE:
            return {...state, message: payload.message};


        case QUERY_REVERT_AUTO_DATA:
            return {...state};

        case QUERY_REVERT_AUTO_DATA_SUCCESS:

            return {...state, RevertAutoRate: payload ,RevertMessage:payload.message };

        case QUERY_REVERT_AUTO_DATA_FAILURE:
            return {...state, message: payload.message};

        case MARKET_QUERY_DATA:
            return {...state};

        case MARKET_QUERY_DATA_SUCCESS:

            return {...state, marketQueryData: payload.result  };

        case MARKET_QUERY_DATA_FAILURE:
            return {...state, marketMessage: payload.message};


        case MANAGER_QUERY_DATA:
            return {...state};

        case MANAGER_QUERY_DATA_SUCCESS:

            return {...state, managerData: payload.data ,totalItemCount: payload.total};

        case MANAGER_QUERY_DATA_FAILURE:
            return {...state, message: payload.message};

        case MANAGER_PAST_QUERY_DATA:
            return {...state};

        case MANAGER_QUERY_PAST_DATA_SUCCESS:

            return {...state, PastManagerData: payload.data ,PasttotalItemCount: payload.total};

        case MANAGER_QUERY_PAST_DATA_FAILURE:
            return {...state, message: payload.message};


        case MANAGER_QUERY_DATA_HISTORY:
            return {...state};

        case MANAGER_QUERY_DATA_HISTORY_SUCCESS:

            return {...state, managerDataHistory: payload.data ,totalItemCountHistory: payload.total};

        case MANAGER_QUERY_DATA_HISTORY_FAILURE:
            return {...state, message: payload.message};

        case QUERY_DELETE:
            return {...state, loading: true};
        case QUERY_DELETE_RESPONSE:
            return {...state, loading: true, deleteResult: payload ,message: payload.message};
        case AWB_PDF_SEND:
            return {...state, loading: true};
        case AWB_PDF_SEND_RESPONSE:
            window.location.href=payload.result
            return {...state, loading: true, sendAwbResult: payload ,message: payload.message};

        case SEND_TO_ADD_COPY_ACTION:
            return {...state, loading: true, DataFromManager: payload};
        default:
            return initialState
    }
};
export default QueryReducerAir;