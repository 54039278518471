import React, { Component, Fragment } from "react";
import {Label, Input, FormGroup} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";

import "../../assets/css/shiphistory.css";
import RestClient from "../../util/RestClient";
import {GET_SHIPPING_BILL_API} from "../../constants";
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class InvoiceReaderField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: true,
            count: 1,
            exporter_details : {},
            invoice_details : {},
            item:[],
            editDataTable : false,
            indexTable : "",
            items:{},
            pdfData : [

            ]
        };
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTab1 = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTab2 = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTab3 = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTab4 = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorGstType = new SimpleReactValidator({ autoForceUpdate: this });
    }

    checkCountry = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });

        if (e.target.name === "isCustomerBelongsToEuropeanCountry") {
            this.setState(
                {
                    isCustomerBelongsToEuropeanCountry:
                        !this.state.isCustomerBelongsToEuropeanCountry,
                },
                () => {}
            );
        }
    };
    componentDidMount() {
        let id = window.location.href.split("/")[window.location.href.split("/").length - 1];
        this.getShippingBillData({id:id}).then(responce=> {
            if(responce.success){
                let exporter_details = responce && responce.result && responce.result.exporter_details? responce.result.exporter_details : {}
                let item = responce && responce.result && responce.result.item? responce.result.item : []
                let invoice_details = responce && responce.result && responce.result.invoice_details? responce.result.invoice_details : {}
                this.setState({
                    exporter_details : exporter_details,
                    item : item,
                    invoice_details : invoice_details,
                })
            }
        })

    }
    getShippingBillData = async (getByQuoteData) => {
        {
            return await RestClient({
                url: GET_SHIPPING_BILL_API,
                method: "POST",
                data: getByQuoteData
            });
        }
    };
    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    Next = (value) => {
        switch (value) {
            case 1:
                if (this.validatorTab1.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: true,
                        tab3: false,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                } else {
                    this.validatorTab1.showMessages();
                    this.forceUpdate();
                }
                break;
            case 2:
                if (
                    this.state.gstType == "S-SEZ" ||
                    this.state.gstType == "T-Taxable" ||
                    this.state.gstType == "U-UIN"
                ) {
                    if (
                        this.validatorTab2.allValid() &&
                        this.validatorGstType.allValid()
                    ) {
                        this.setState({
                            tab1: false,
                            tab2: false,
                            tab3: true,
                            tab4: false,
                            tab5: false,
                            // tab6: false,
                            count: this.state.count + 1,
                        });
                    } else {
                        this.validatorTab2.showMessages();
                        this.validatorGstType.showMessages();
                        this.forceUpdate();
                    }
                } else if (this.validatorTab2.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: true,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                } else {
                    this.validatorTab2.showMessages();
                    this.forceUpdate();
                }
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: true,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count + 1,
                });
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: true,
                    // tab6: false,
                    count: this.state.count + 1,
                });
                break;
            case 5:
                if (this.validatorTab3.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: false,
                        tab4: false,
                        tab5: false,
                        // tab6: true,
                        count: this.state.count + 1,
                    });
                } else {
                    this.validatorTab3.showMessages();
                    this.forceUpdate();
                }
                break;
            case 6:
                if (this.validatorTab4.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: false,
                        tab4: false,
                        // tab5: false,
                        // tab6: true,
                        count: this.state.count + 1,
                    });
                } else {
                    this.validatorTab4.showMessages();
                    this.forceUpdate();
                }
                break;
            default:
        }
    };
    Prev = (value) => {
        switch (value) {
            case 2:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: true,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: true,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 5:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: true,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1
                })
                break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: true,
            //         tab6: false,
            //         count: this.state.count - 1
            //     })
            //     break;
            default:
        }
    };
    Select = (value) => {
        switch (value) {
            case 1:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: value,
                });
                break;
            case 2:
                this.setState({
                    tab1: false,
                    tab2: true,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: value,
                });
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: true,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: value,
                });
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: true,
                    tab5: false,
                    // tab6: false,
                    count: value,
                });
                break;
            case 5:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: true,
                    // tab6: false,
                    count: value
                })
                break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: false,
            //         tab6: true,
            //         count: value
            //     })
            //     break;
            default:
        }
    };

    handleChangeData = (e) =>{
        // let item = [...this.state.item];
        // let items = this.state.item[i]
        // item[i] = { ...item[i], [e.target.name]: e.target.value };
        // this.setState({ item }, () => {
        //     console.log(item)
        // })
        let items = this.state.items;
        items = {...items,[e.target.name]: e.target.value}
        this.setState({
            items
        },()=>{
        })
    }
    itemUpdate = (i) =>{
        let item = [...this.state.item];
        item[i]={...item[i],...this.state.items}
        this.setState({
            item
        },()=>{
            this.setState({
                items : {},
                editDataTable:false
            },()=>{
                toast.success("Item Successfully Updated", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        })
    }
    handleChange = (e) =>{
        let exporter_details = this.state.exporter_details;
        exporter_details = {...exporter_details,[e.target.name]: e.target.value}
        this.setState({
            exporter_details
        },()=>{
            console.log("this.state.invoice_details",this.state.exporter_details)
        })

    }
    handleInvoiceChange = (e) =>{
        let invoice_details = this.state.invoice_details;
        invoice_details = {...invoice_details,[e.target.name]: e.target.value}
        this.setState({
            // invoice_details:{...invoice_details,[e.target.name]: e.target.value}
            invoice_details
        },()=>{
            console.log("this.state.invoice_details",this.state.invoice_details)
        })

    }

    render() {
        const {Exporter,GSTIN,buyer_details, consignee_address, consignee_country, consignee_name, destination_country, destination_port, discharge_country, discharge_port, exporter_address, exporter_city, exporter_pincode,exporter_state,port_of_loading,shipment_mode,state_of_origin,CHANumber,jobNo,formattedJobNo,ediJobNo,jobDate,sbNo,sbDate,NEFICategory,rbiWaiverNo,date,exporterType,iecStatus,iecCode,br,iecPan,adCode,gstType} = this.state.exporter_details;
        const {Country_of_Origin,Description,District_of_Origin,Fabric_Composition,IGST_Amount,IGST_Rate,Quantity_Unit,RITC_code,Rate_Price_usd,SQC,S_No,State_of_Origin,Total_Amount,
            Total_Amount_usd,Total_Taxable_Amount_INR,product_Id,tariffDescription,total,unit,rate,pwv,endUse,ptaDetails, igstPayment, cessAmount, igstCessAmount, hawbNo, ttlPackage, mfrCode, sourceState, transitCntry, accessoryStatus, strStatus, ar4Status, cessStatus, rewardStatus,
            packingStatus, reexportStatus, jobWorkStatus, thirdParty, accessory, quotaStatus, scheme,invoice_No,qtyTariff } = this.state.items;
        const {invoice_date, invoice_no,invSerialNo,pkgListNo,pkgDate,T_O_P,lcNo,lcDate,currency,invoice_rate,T_O_I,contractNo,inv_Value,period,terms_place,multipleInvoiceStatus,buyerDifferent,thirdPartyStatus,aeoCode,aeoRole,aeoCountry} = this.state.invoice_details
        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <>
                    <Fragment>
                        <div className="customerKycBackground ocean_freight_banner">
                            <div className="container-fluid">
                                <div className="banner_form_text invoiceReaderForm">
                                    <div className="mode_texts">
                                        <div className={this.state.tab1?"airModes active":"airModes"} onClick={() => this.Select(1)}>Job detail</div>
                                        <div className={this.state.tab2?"airModes active":"airModes"} onClick={() => this.Select(2)}>IEC detail</div>
                                        <div className={this.state.tab3?"airModes active":"airModes"} onClick={() => this.Select(3)}>Invoice detail</div>
                                        <div className={this.state.tab4?"airModes active":"airModes"} onClick={() => this.Select(4)}>Item detail</div>
                                        {/*<div className={this.state.tab5?"airModes active":"airModes"} onClick={() => this.Select(5)}>Notification Settings</div>*/}
                                    </div>
                                    <div className="banner_text_background">
                                        {this.state.tab1 && (
                                            <>
                                            <div className="d-flex flex-wrap">
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>CHA Number</Label>
                                                        <Input
                                                            type="text"
                                                            name="CHANumber"
                                                            onChange={(e) => this.handleChange(e)}
                                                            value={CHANumber}
                                                            placeholder=""
                                                        />
                                                        {/* <span className="text-danger">{this.validatorTab1.message('customerName', this.state.customerName, 'required|alpha')}</span> */}
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Job No.</Label>
                                                        <Input
                                                            type="text"
                                                            name="jobNo"
                                                            value={jobNo}
                                                            placeholder=""
                                                            readOnly
                                                        />
                                                        {/* <span className="text-danger">{this.validatorTab1.message('branchName', this.state.branchName, 'required|alpha_num')}</span> */}
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Formatted Job No.</Label>
                                                        <Input
                                                            type="text"
                                                            value={formattedJobNo}
                                                            onChange={(e) => this.handleChange(e)}
                                                            name="formattedJobNo"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>EDI Job No.</Label>
                                                        <Input
                                                            type="text"
                                                            name="ediJobNo"
                                                            value={ediJobNo}
                                                            onChange={(e) => this.handleChange(e)}
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label for="basicinput">Job Date</Label>
                                                        <Input
                                                            type="text"
                                                            value={jobDate}
                                                            onChange={(e) => this.handleChange(e)}
                                                            name="jobDate"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label for="basicselect">SB No</Label>
                                                        <Input
                                                            type="text"
                                                            value={sbNo}
                                                            onChange={(e) => this.handleChange(e)}
                                                            name="sbNo"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label for="basicselect">SB Date</Label>
                                                        <Input
                                                            type="text"
                                                            value={sbDate}
                                                            onChange={(e) => this.handleChange(e)}
                                                            name="sbDate"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label for="basicinput">NFEI Category</Label>
                                                        <Input
                                                            type="text"
                                                            value={NEFICategory}
                                                            onChange={(e) => this.handleChange(e)}
                                                            name="NEFICategory"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label for="basicselect">RBI Waiver No</Label>
                                                        <Input
                                                            type="text"
                                                            value={rbiWaiverNo}
                                                            onChange={(e) => this.handleChange(e)}
                                                            name="rbiWaiverNo"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <div className="upload-inputs">
                                                        <label>Date</label>
                                                        <Input
                                                            type="text"
                                                            value={date}
                                                            onChange={(e) => this.handleChange(e)}
                                                            name="date"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Exporter Type</Label>
                                                        <Input
                                                            type="text"
                                                            value={exporterType}
                                                            onChange={(e) => this.handleChange(e)}
                                                            name="exporterType"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Mode</Label>
                                                        <select name="shipment_mode" className="custom-select"
                                                                value={shipment_mode} onChange={(e) => this.handleChange(e)}>
                                                            <option name="Air">A-AIR</option>
                                                            <option name="Ocean">S-SEA</option>
                                                            <option name="L-ICD">L-ICD</option>
                                                            <option name="Road">D-LAND</option>
                                                        </select>
                                                        {/*<Input*/}
                                                        {/*    type="text"*/}
                                                        {/*    onChange={(e) => this.handleChange(e)}*/}
                                                        {/*    value={shipment_mode}*/}
                                                        {/*    name="shipment_mode"*/}
                                                        {/*    placeholder=""*/}
                                                        {/*/>*/}
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Port Of Loading</Label>
                                                        <Input
                                                            type="text"
                                                            onChange={(e) => this.handleChange(e)}
                                                            value={port_of_loading}
                                                            name="port_of_loading"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Country Of Destination</Label>
                                                        <Input
                                                            type="text"
                                                            onChange={(e) => this.handleChange(e)}
                                                            value={destination_country}
                                                            name="destination_country"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Port Of Destination</Label>
                                                        <Input
                                                            type="text"
                                                            onChange={(e) => this.handleChange(e)}
                                                            value={destination_port}
                                                            name="destination_port"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Country Of Discharge</Label>
                                                        <Input
                                                            type="text"
                                                            onChange={(e) => this.handleChange(e)}
                                                            value={discharge_country}
                                                            name="discharge_country"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="widths-20">
                                                    <FormGroup>
                                                        <Label>Port Of Discharge</Label>
                                                        <Input
                                                            type="text"
                                                            onChange={(e) => this.handleChange(e)}
                                                            value={discharge_port}
                                                            name="discharge_port"
                                                            placeholder=""
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>

                                            </>
                                        )}
                                        {this.state.tab2 && (
                                            <>
                                                <div className="d-flex flex-wrap">
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Standard IEC</Label>
                                                            <select name="iecStatus" value={iecStatus} className="custom-select" onChange={(e) => this.handleChange(e)}>
                                                                <option name="Y">Y</option>
                                                                <option name="N">N</option>
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>IEC Code</Label>
                                                            <Input
                                                                type="text"
                                                                name="iecCode"
                                                                value={iecCode}
                                                                placeholder=""
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Br</Label>
                                                            <Input
                                                                type="text"
                                                                value={br}
                                                                onChange={(e) => this.handleChange(e)}
                                                                name="br"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>IEC Pan</Label>
                                                            <Input
                                                                type="text"
                                                                name="iecPan"
                                                                value={iecPan}
                                                                onChange={(e) => this.handleChange(e)}
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>



                                                </div>
                                                <div className="Exporter">
                                                    <h3 className="text-white">Exporter Details</h3>
                                                    <hr/>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="width-6">
                                                            <FormGroup>
                                                                <Label>Name</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={Exporter}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="Exporter"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="width-6">
                                                            <FormGroup>
                                                                <Label>Address</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={exporter_address}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="exporter_address"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="widths-20">
                                                            <FormGroup>
                                                                <Label>City</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={exporter_city}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="exporter_city"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="widths-15">
                                                            <FormGroup>
                                                                <Label>Pin</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={exporter_pincode}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="exporter_pincode"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="widths-15">
                                                            <FormGroup>
                                                                <Label>State</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={exporter_state}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="exporter_state"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="widths-15">
                                                            <FormGroup>
                                                                <Label>AD Code</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={adCode}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="adCode"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="widths-15">
                                                            <div className="upload-inputs">
                                                                <label>GST Type</label>
                                                                <Input
                                                                    type="text"
                                                                    value={gstType}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="gstType"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="widths-15">
                                                            <FormGroup>
                                                                <Label>ID</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={GSTIN}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="GSTIN"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="Exporter">
                                                    <h3 className="text-white">Consignee Address</h3>
                                                    <hr/>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="width-6">
                                                            <FormGroup>
                                                                <Label>Consignee</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={consignee_name}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    name="consignee_name"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="width-6">
                                                            <FormGroup>
                                                                <Label>Consignee Address</Label>
                                                                <Input
                                                                    type="text"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    value={consignee_address}
                                                                    name="consignee_address"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="widths-20">
                                                            <FormGroup>
                                                                <Label>Country</Label>
                                                                <Input
                                                                    type="text"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    value={consignee_country}
                                                                    name="consignee_country"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="widths-20">
                                                            <FormGroup>
                                                                <Label>State Of Origin</Label>
                                                                <Input
                                                                    type="text"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    value={state_of_origin}
                                                                    name="state_of_origin"
                                                                    placeholder=""
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {this.state.tab3 && (
                                            <>
                                                <div className="d-flex flex-wrap">
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Inv.Serial No.</Label>
                                                            <Input
                                                                type="text"
                                                                name="invSerialNo"
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                value={invSerialNo}
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Invoice No.</Label>
                                                            <Input
                                                                type="text"
                                                                name="invoice_no"
                                                                value={invoice_no}
                                                                placeholder=""
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Date</Label>
                                                            <Input
                                                                type="text"
                                                                value={invoice_date}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="invoice_date"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Pkg. List No.</Label>
                                                            <Input
                                                                type="text"
                                                                value={pkgListNo}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="pkgListNo"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Date</Label>
                                                            <Input
                                                                type="text"
                                                                value={pkgDate}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="pkgDate"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>T.O.P</Label>
                                                            <select name="T_O_P" className="custom-select" value={T_O_P} onChange={(e) => this.handleInvoiceChange(e)}>
                                                                <option value="AP">AP - Advance Payment</option>
                                                                <option value="LC">LC - Letter of Credit</option>
                                                                <option selected="selected" value="DP">DP - Documents against Payment</option>
                                                                <option value="NA">NA - Not Applicable</option>
                                                                <option value="DA">DA - Documents against Acceptance</option>
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Period</Label>
                                                            <Input
                                                                type="text"
                                                                name="period"
                                                                value={period}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>LC No.</Label>
                                                            <Input
                                                                type="text"
                                                                value={lcNo}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="lcNo"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Date</Label>
                                                            <Input
                                                                type="text"
                                                                value={lcDate}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="lcDate"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Currency</Label>
                                                            <Input
                                                                type="text"
                                                                value={currency}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="currency"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Rate</Label>
                                                            <Input
                                                                type="text"
                                                                value={invoice_rate}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="invoice_rate"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>T.O.I</Label>
                                                            <Input
                                                                type="text"
                                                                value={T_O_I}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="T_O_I"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Contract No</Label>
                                                            <Input
                                                                type="text"
                                                                value={contractNo}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="contractNo"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Inv.Value</Label>
                                                            <Input
                                                                type="text"
                                                                value={inv_Value}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="inv_Value"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <div className="upload-inputs">
                                                            <label>Terms Place</label>
                                                            <Input
                                                                type="text"
                                                                value={terms_place}
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                name="terms_place"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Multiple Invoice</Label>
                                                            <select name="multipleInvoiceStatus" value={multipleInvoiceStatus} className="custom-select" onChange={(e) => this.handleInvoiceChange(e)}>
                                                                <option name="Y">Y</option>
                                                                <option name="N">N</option>
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Buyer is Different From Consignee</Label>
                                                            <select name="buyerDifferent" value={buyerDifferent} className="custom-select" onChange={(e) => this.handleInvoiceChange(e)}>
                                                                <option name="Y">Y</option>
                                                                <option name="N">N</option>
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>Third Party</Label>
                                                            <select name="thirdPartyStatus" value={thirdPartyStatus} className="custom-select" onChange={(e) => this.handleInvoiceChange(e)}>
                                                                <option name="Y">Y</option>
                                                                <option name="N">N</option>
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>AEO Code</Label>
                                                            <Input
                                                                type="text"
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                value={aeoCode}
                                                                name="aeoCode"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>AEO Role</Label>
                                                            <Input
                                                                type="text"
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                value={aeoRole}
                                                                name="aeoRole"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="widths-20">
                                                        <FormGroup>
                                                            <Label>AEO Country</Label>
                                                            <Input
                                                                type="text"
                                                                onChange={(e) => this.handleInvoiceChange(e)}
                                                                value={aeoCountry}
                                                                name="aeoCountry"
                                                                placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="table_summery_invoice">
                                                    <table >
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Amount</th>
                                                                <th>Currency</th>
                                                                <th>INR</th>
                                                                <th>Rate [%]</th>
                                                                <th>Amount</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>Freight</td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td><input type="text" name="freightCurrency" value="USD"/></td>
                                                            <td><input type="text" name="freightCurrency" value="81.9000"/></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Insurance</td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td><input type="text" name="freightCurrency" value="USD"/></td>
                                                            <td><input type="text" name="freightCurrency" value="81.9000"/></td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Commission</td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td><input type="text" name="freightCurrency" value="USD"/></td>
                                                            <td><input type="text" name="freightCurrency" value="81.9000"/></td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Discount</td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td><input type="text" name="freightCurrency" value="USD"/></td>
                                                            <td><input type="text" name="freightCurrency" value="81.9000"/></td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Other</td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td><input type="text" name="freightCurrency" value="USD"/></td>
                                                            <td><input type="text" name="freightCurrency" value="81.9000"/></td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <td>Packing Charges</td>
                                                            <td><input type="text" name="freightAmount" value=""/></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <td>Invoice Includes</td>
                                                            <td><select name="invoiceInclude" className="custom-select" onChange={(e) => this.handleChange(e)}>
                                                                <option name="N">N-None</option>
                                                            </select></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        )}
                                        {this.state.tab4 && (
                                            <>
                                                {this.state.editDataTable ?
                                                    <>
                                                        <div className="d-flex flex-wrap">
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Item Serial No.</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="S_No"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={S_No}
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Of Invoice No.</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="invoice_No"
                                                                        value={invoice_No}
                                                                        placeholder=""
                                                                        readOnly
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Product ID</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={product_Id}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="product_Id"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width-6">
                                                                <FormGroup>
                                                                    <Label>Description</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={Description}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="Description"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>RITC Code</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={RITC_code}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="RITC_code"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Tariff Description</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="tariffDescription"
                                                                        value={tariffDescription}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Quantity</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="Quantity_Unit"
                                                                        value={Quantity_Unit}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Total</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={total}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="total"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Unit</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={unit}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="unit"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Rate</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={Rate_Price_usd}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="rate"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Per</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={rate}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="Rate_Price_usd"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Unit</Label>
                                                                    <select name="unit" className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option value="DOZ">DOZ</option>
                                                                        <option value="PCS">PCS</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>PWV</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={pwv}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="pwv"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>End Use</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={endUse}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="endUse"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <div className="upload-inputs">
                                                                    <label>QTY. as per Tariff</label>
                                                                    <Input
                                                                        type="text"
                                                                        value={qtyTariff}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="qtyTariff"
                                                                        placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Units</Label>
                                                                    <Input
                                                                        type="number"
                                                                        value={SQC}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="SQC"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>State Of Origin</Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={State_of_Origin}
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        name="State_of_Origin"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>District</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={District_of_Origin}
                                                                        name="District_of_Origin"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>PTA/FTA Details</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={ptaDetails}
                                                                        name="ptaDetails"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>RoDTEP</Label>
                                                                    <select name="rodtepStatus" className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>IGST Payment Status</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={igstPayment}
                                                                        name="igstPayment"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Taxable Value</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={Total_Taxable_Amount_INR}
                                                                        name="Total_Taxable_Amount_INR"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>IGST Amount</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={IGST_Amount}
                                                                        name="IGST_Amount"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Cess Amount/Unit</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={cessAmount}
                                                                        name="cessAmount"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>IGST Cess Amount</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={igstCessAmount}
                                                                        name="igstCessAmount"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Hawb No.</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={hawbNo}
                                                                        name="hawbNo"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Total Package</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={ttlPackage}
                                                                        name="ttlPackage"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Mfr./Producer</Label>
                                                                    <select name="mfrStatus" className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Mfr./Producer Code</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={mfrCode}
                                                                        name="mfrCode"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Source State</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={sourceState}
                                                                        name="sourceState"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="widths-16">
                                                                <FormGroup>
                                                                    <Label>Transit Cntry</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={transitCntry}
                                                                        name="transitCntry"
                                                                        placeholder=""
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Accessory Status</Label>
                                                                    <select name="accessoryStatus" value={accessoryStatus} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name=""></option>
                                                                        <option name="0">0</option>
                                                                        <option name="1">1</option>
                                                                        <option name="2">2</option>
                                                                        <option name="3">3</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>STR</Label>
                                                                    <select name="strStatus" value={strStatus} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>AR4</Label>
                                                                    <select name="ar4Status" value={ar4Status} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>CESS</Label>
                                                                    <select name="cessStatus" value={cessStatus} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Quota</Label>
                                                                    <select name="quotaStatus" value={quotaStatus} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="Y">Y</option>
                                                                        <option name="N">N</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Accessory</Label>
                                                                    <select name="accessory" value={accessory} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Third Party</Label>
                                                                    <select name="thirdParty" value={thirdParty} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Job Work</Label>
                                                                    <select name="jobWorkStatus" value={jobWorkStatus} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Re-Export</Label>
                                                                    <select name="reexportStatus" value={reexportStatus} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Packing</Label>
                                                                    <select name="packingStatus" value={packingStatus} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Reward</Label>
                                                                    <select name="rewardStatus" value={rewardStatus} className="custom-select" onChange={(e) => this.handleChangeData(e)}>
                                                                        <option name="N">N</option>
                                                                        <option name="Y">Y</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="width_10">
                                                                <FormGroup>
                                                                    <Label>Scheme</Label>
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) => this.handleChangeData(e)}
                                                                        value={scheme}
                                                                        name="scheme"

                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="">
                                                                <FormGroup>
                                                                    <Label className="visi-hidden display_blocks">Scheme</Label>
                                                                    <button className="btn" onClick={(e)=>{this.itemUpdate(this.state.indexTable)}}>Update</button>
                                                                </FormGroup>
                                                            </div>
                                                        </div>

                                                        <hr/>
                                                    </>

                                                    :""}

                                                {this.state.item && this.state.item.length>0 ?
                                                    <table className="invoice_table_pdf table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>INV SI No</th>
                                                                <th>RITC</th>
                                                                <th>Description</th>
                                                                <th>Scheme</th>
                                                                <th>Qty</th>
                                                                <th>Unit</th>
                                                                <th>Price</th>
                                                                <th>Per</th>
                                                                <th>Unit</th>
                                                                <th>Total</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.item.map((data,i)=>{
                                                                return(
                                                                <>
                                                                    <tr key={i}>
                                                                        <td>{data.S_No}</td>
                                                                        <td>{data.RITC_code}</td>
                                                                        <td>{data.Description}</td>
                                                                        <td>{data.scheme}</td>
                                                                        <td>{data.Quantity_Unit}</td>
                                                                        <td></td>
                                                                        <td>{data.rate}</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td>{data.total}</td>
                                                                        <td><div className="d-flex flex-wrap align-items-center">
                                                                            <FontAwesomeIcon icon={faEdit} className="edit_data_icon" onClick={()=>{
                                                                                this.setState({
                                                                                    items : {},
                                                                                    editDataTable : true,
                                                                                    indexTable : i
                                                                                },()=>{
                                                                                    this.setState({
                                                                                        items : this.state.item[i]
                                                                                    })
                                                                                })
                                                                            }}/>
                                                                            <FontAwesomeIcon icon={faTrashAlt} className="delete_data_icon"/>
                                                                        </div> </td>
                                                                    </tr>
                                                                </>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>


                                                    :""}

                                            </>
                                        )}

                                        <div className="mt-3 kycBorderSeprate">
                                            <div className="d-flex justify-content-end">
                                                {Number(this.state.count) > 1 && (
                                                    <button className="btn" type="button" onClick={() => this.Prev(this.state.count)}>Previous</button>
                                                )}
                                                {Number(this.state.count) < 5 ?
                                                    <button className="btn" type="button" onClick={() => this.Next(this.state.count)}>Next</button>
                                                :""}
                                                {Number(this.state.count) == 5 ? (
                                                    <>
                                                    {this.state.kycAgreement !== "" ?
                                                        <button className="btn" type="button" onClick={() => this.Submit()}>Submit</button>:""}
                                                    </>
                                                ):""}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </Fragment>
                </>
            </>
        );
    }
}


export default InvoiceReaderField
