let stringConstructor = "test".constructor;
let arrayConstructor = [].constructor;
let objectConstructor = ({}).constructor;

export  function checkObjectType(object) {
    if (object === null) {
        return "null";
    }
    if (object === undefined) {
        return "undefined";
    }
    if (object.constructor === stringConstructor) {
        return "String";
    }
    if (object.constructor === arrayConstructor) {
        return "Array";
    }
    if (object.constructor === objectConstructor) {
        return "Object";
    }
    {
        return "don't know";
    }
}

export function checkIsObject(object) {

    if (object && object.constructor === objectConstructor) {
        return true;
    }
        return false;

}
