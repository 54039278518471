import {
    MANAGER_STATE_DATA,
    MANAGER_STATE_DATA_SUCCESS,
    MANAGER_STATE_DATA_FAILURE,
    EDIT_STATE_DATA,
    EDIT_STATE_DATA_FETCH,
    EDIT_STATE_DATA_SUCCESS,
    EDIT_STATE_DATA_FAILURE,
    STATE_UPDATE,
    STATE_UPDATE_RESPONSE,
    STATE_DELETE,
    STATE_DELETE_RESPONSE,
    SEND_DATA_TO_ADD_QUERY,
    SEND_DATA_TO_ADD_QUERY_SUCCESS,
    SEND_DATA_TO_ADD_QUERY_FAILURE,
    QUERY_PREVIEW_DATA,
    QUERY_PREVIEW_DATA_SUCCESS,
    QUERY_PREVIEW_DATA_FAILURE,
    QUERY_REVERT_DATA,
    QUERY_REVERT_DATA_SUCCESS,
    QUERY_REVERT_DATA_FAILURE,
    MANAGER_QUERY_DATA,
    MANAGER_QUERY_DATA_SUCCESS,
    MANAGER_QUERY_DATA_FAILURE,
    SEND_DATA_TO_UPDATE_QUERY,
    SEND_DATA_TO_UPDATE_QUERY_SUCCESS,
    SEND_DATA_TO_UPDATE_QUERY_FAILURE,
    QUERY_DELETE,
    QUERY_DELETE_RESPONSE,
    AWB_PDF_SEND,
    AWB_PDF_SEND_RESPONSE,
    QUERY_REVERT_AUTO_DATA,
    QUERY_REVERT_AUTO_DATA_SUCCESS,
    QUERY_REVERT_AUTO_DATA_FAILURE,
    MARKET_QUERY_DATA,
    MARKET_QUERY_DATA_SUCCESS,
    MARKET_QUERY_DATA_FAILURE,
    SEND_TO_ADD_COPY_ACTION,
    MANAGER_QUERY_DATA_HISTORY,
    MANAGER_QUERY_DATA_HISTORY_SUCCESS,
    MANAGER_QUERY_DATA_HISTORY_FAILURE,
    MANAGER_PAST_QUERY_DATA,
    MANAGER_QUERY_PAST_DATA_SUCCESS,
    MANAGER_QUERY_PAST_DATA_FAILURE,
    QUERY_REVERT_DATA_FOR_MARKET_RATE,
    QUERY_REVERT_DATA_MARKET_RATE_SUCCESS,
    QUERY_REVERT_DATA_MARKET_RATE_FAILURE
} from "../actions";

/**
 *
 * @param {username, password} creds
 */
export const sendDataToAddQuery = (data) => ({
    type: SEND_DATA_TO_ADD_QUERY,
    payload:data,
});
export const sendDataToAddQuerySuccess = (data) => ({
    type: SEND_DATA_TO_ADD_QUERY_SUCCESS,
    payload: data,
});
export const sendDataToAddQueryFail = (data) => ({
    type:SEND_DATA_TO_ADD_QUERY_FAILURE,
    payload:data,
});


/**
 *
 * @manager
 */
export const getQueryManagerData = (data) => ({
    type: MANAGER_QUERY_DATA,
    payload:data,
});
export const getQueryManagerDataSuccess = (data) => ({
    type: MANAGER_QUERY_DATA_SUCCESS,
    payload: data,
});
export const getQueryManagerDataFailuer = (data) => ({
    type:MANAGER_QUERY_DATA_FAILURE,
    payload:data,
});
/**
 *
 * @manager
 */
export const getPastQueryManagerData = (data) => ({
    type: MANAGER_PAST_QUERY_DATA,
    payload:data,
});
export const getPastQueryManagerDataSuccess = (data) => ({
    type: MANAGER_QUERY_PAST_DATA_SUCCESS,
    payload: data,
});
export const getPastQueryManagerDataFailuer = (data) => ({
    type:MANAGER_QUERY_PAST_DATA_FAILURE,
    payload:data,
});


/**
 *
 * @HistoryManager
 */
export const getQueryManagerDataForHistory = (data) => ({
    type: MANAGER_QUERY_DATA_HISTORY,
    payload:data,
});
export const getQueryManagerDataForHistorySuccess = (data) => ({
    type: MANAGER_QUERY_DATA_HISTORY_SUCCESS,
    payload: data,
});
export const getQueryManagerDataForHistoryFailuer = (data) => ({
    type:MANAGER_QUERY_DATA_HISTORY_FAILURE,
    payload:data,
});

/**
 *
 * @MarketQuerymanager
 */
export const getMarketQueryData = (data) => ({
    type: MARKET_QUERY_DATA,
    payload:data,
});
export const getMarketQueryDataSuccess = (data) => ({
    type: MARKET_QUERY_DATA_SUCCESS,
    payload: data,
});
export const getMarketQueryDataFailuer = (data) => ({
    type:MARKET_QUERY_DATA_FAILURE,
    payload:data,
});


export const SendToEdit = (data) => ({
    type: EDIT_STATE_DATA,
    payload:data,
});

/**
 *
 * @Edit State
 */
export const EditStateData = (data) => ({
    type: EDIT_STATE_DATA_FETCH,
    payload:data,
});
export const EditStateDataSuccess = (data) => ({
    type: EDIT_STATE_DATA_SUCCESS,
    payload: data,
});
export const EditStateDataFailuer = (data) => ({
    type:EDIT_STATE_DATA_FAILURE,
    payload:data,
});/**
 *
 * @Delete Query
 */
export const queryMasterDeleteRequest = (payloadData) => ({
        type: QUERY_DELETE,
        payload: payloadData
    });
export const queryMasterDeleteResponse = (payloadResponse) => ({
    type: QUERY_DELETE_RESPONSE,
    payload: payloadResponse
});

/**
 *
 * @AwbPdf SEND
 */
export const awbPdfSend = (payloadData) =>({
    type : AWB_PDF_SEND,
    payload : payloadData
});
export const awbPdfSendResponse = (payloadData) =>({
    type : AWB_PDF_SEND_RESPONSE,
    payload : payloadData
});


/**
 *
 * @Update State
 */

export const sendDataToUpdateQuery = (data) => ({
    type: SEND_DATA_TO_UPDATE_QUERY,
    payload:data,
});
export const sendDataToUpdateQueryuccess = (data) => ({
    type: SEND_DATA_TO_UPDATE_QUERY_SUCCESS,
    payload: data,
});
export const sendDataToUpdateQueryFail = (data) => ({
    type:SEND_DATA_TO_UPDATE_QUERY_FAILURE,
    payload:data,
});


/**
 *
 * @QueryPreview
 */
export const getQueryPreviewData = (data) => ({
    type: QUERY_PREVIEW_DATA,
    payload:data,
});
export const getQueryPreviewDataSuccess = (data) => ({
    type: QUERY_PREVIEW_DATA_SUCCESS,
    payload: data,
});
export const getQueryPreviewDataFailuer = (data) => ({
    type:QUERY_PREVIEW_DATA_FAILURE,
    payload:data,
});
/**
 *
 * @QueryRevert
 */
export const getQueryRevertData = (data) => ({
    type: QUERY_REVERT_DATA,
    payload:data,
});
export const getQueryRevertDataSuccess = (data) => ({
    type: QUERY_REVERT_DATA_SUCCESS,
    payload: data,
});
export const getQueryRevertDataFailuer = (data) => ({
    type:QUERY_REVERT_DATA_FAILURE,
    payload:data,
});


/**
 *
 * @QueryRevertForMarketRate
 */
export const getQueryRevertDataForMarketRate = (data) => ({
    type: QUERY_REVERT_DATA_FOR_MARKET_RATE,
    payload:data,
});
export const getQueryRevertDataForMarketRateSuccess = (data) => ({
    type: QUERY_REVERT_DATA_MARKET_RATE_SUCCESS,
    payload: data,
});
export const getQueryRevertDataForMarketRateFailuer = (data) => ({
    type:QUERY_REVERT_DATA_MARKET_RATE_FAILURE,
    payload:data,
});

/**
 *
 * @QueryAutoRevert
 */
export const getQueryAutoRevertData = (data) => ({
    type: QUERY_REVERT_AUTO_DATA,
    payload:data,
});
export const getQueryAutoRevertDataSuccess = (data) => ({
    type: QUERY_REVERT_AUTO_DATA_SUCCESS,
    payload: data,
});
export const getQueryAutoRevertDataFailuer = (data) => ({
    type:QUERY_REVERT_AUTO_DATA_FAILURE,
    payload:data,
});

export const sendToAddCopyAction = (data) => ({
    type:SEND_TO_ADD_COPY_ACTION,
    payload:data,
});