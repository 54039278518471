import React from "react";
import {Route,Redirect} from "react-router-dom";
import {connect} from "react-redux";
const MainRoutesManagerWithoutAuthWithoutLayout = ({render, ...rest}) => {
     switch (rest.path) {
            default:
                return (
                    <Route
                        {...rest}
                        render={matchProps => (
                            <div className="">
                            {render(matchProps)}
                            </div>

                        )}
                    />
                );
        }
};
const mapStateToProps = (state) => {
    return {state}
};

export default connect(mapStateToProps)(MainRoutesManagerWithoutAuthWithoutLayout);