import React, {Component} from "react";
import {convertDateISO, convertDateISOToCustomiseFormat, parseFloatValue,concatExtraStringWithCheck,getCustomerDetails} from "../../util/CommonUtility";
import {toast} from "react-toastify";
import RestClient from "../../util/RestClient";
import {GET_DATA_ADDINTENEDED_PLAN_API,GET_ALL_SLAB_RATE_AIR,GET_ALL_SLAB_RATE_OCEAN,REMOVE_COUPAN} from "../../constants";
import SlabRatePoup from "../../views/app/dashboards/SlabRatePopup";
import SlabRatePopupForOcean from "../../views/app/dashboards/SlabRatePopupForOcean";
import AddCoupanApplyForHome from "../../views/app/dashboards/AddCoupanApplyForHome";
import {PAGE_PATHS} from "../../util/PageRoute";
import {history} from "../../helpers/history";
import {Progress} from "reactstrap";
import {faArrowRight, faDollarSign, faEuroSign, faRupeeSign, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class GradientWithRadialProgressCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            availableIntends:[],
            createLoggedSucess:[],
            data: {},
            title: "title",
            detail: "detail",
            rate : [],
            percent: 80,
            progressText: "8/10",
            coupanId : "",
            isSlabRateOpen : false,
            // applyCoupan : false,
            isSlabRateOpenForOcean : false,
            BookingID: "1",
            login: undefined,
            switchCurrency : true,
            showCollaps:true,
            showCollapsSep:false,
            showCollapsForRate:false,
            // awaitPopup:false,
            noButtonStatus:false,
            creditDaysSTatus:false,
            creditDays:"",
            idUnderScoreNewExist : "",
            quoteIdPopNewExist:""
        }
        this.switchCurrency= this.switchCurrency.bind(this);
    }

    switchCurrency = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if(e.target.name == "switchCurrency"){
                this.setState({
                    switchCurrency : !this.state.switchCurrency,
                }, () =>{

                })
        }

    }

    sendToIntendedTransportPlan = (item) => {
        let QuoteId = localStorage.getItem("queryNo")
        QuoteId=JSON.parse(QuoteId)
        let intendedTransportPlan = [];
        let quoteId = QuoteId
        intendedTransportPlan.push({
            id: item._id,
            from: item.originDetails.name,
            to: item.destinationDetails.name,
            vesselNo: item.vessel,
            voyageNo: item.voyageNo,
            eta: item.arrival,
            etd: item.departure,})

        let availableIntends=this.state.availableIntends
        availableIntends.push({
            id: item._id,
            from: item.originDetails.name,
            to: item.destinationDetails.name,
            vesselNo: item.vessel,
            voyageNo: item.voyageNo,
            eta: item.arrival,
            etd: item.departure
        })
        this.setState({availableIntends:availableIntends,intendedTransportPlan: intendedTransportPlan,quoteId:QuoteId}, () => {
            this.addIntenededPlan({quoteId,intendedTransportPlan}).then(dd=>{

                if(dd.success){
                    toast.success("Planned For booking", {
                        position: "top-left",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }else{
                    toast.error("Something Wrong Please Contact Customer Service", {
                        position: "top-left",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        })

    }
    checkUser = () =>{
        let typeOfCust = JSON.parse(localStorage.getItem("customer"))
        if(typeOfCust){
            return true
        }else{
            return false
        }
    }
    checkIdAvailable = (id) => {
        let st=false
        for (let i = 0; i < this.state.availableIntends.length; i++) {
            if(id==this.state.availableIntends[i].id){
                st=true
            }
        }
        return st
    }
    addIntenededPlan = async (indenededData) => {
        {
            return await RestClient({
                url: GET_DATA_ADDINTENEDED_PLAN_API,
                method: "POST",
                data: indenededData
            });
        }
    };

    componentDidMount() {
        if(this.props.typeOfCust){
            this.setState({
                typeOfCust : true
            })
        }
        if (this.props.queryDataForcard) {
            this.setState({
                queryDataForcard: this.props.queryDataForcard,
                quoteId:this.props.queryDataForcard._id
            },()=>{
            })
        }

        if (this.props.data) {
            this.setState({
                data_my: this.props.data
            },()=>{
                // console.log("dataaaaaaaa charges",JSON.stringify(this.props.data))
            })
        }
        if(this.props.queryNo){
            this.setState({
                queryNo: this.props.queryNo,
            },()=>{
            })
        }
        if (this.props.mainQueryData) {

            this.setState({
                quoteId: this.props.mainQueryData && this.props.mainQueryData.quoteId?this.props.mainQueryData.quoteId:undefined
            },()=>{

            })
        }

        if (this.props.lengthCard) {
            this.setState({
            }, () => {

            })
        }
        if(this.props.rateDataForId){
            this.setState({
                rateDataForId:this.props.rateDataForId,
            },()=>{
            })
        }
        // if(this.props.createLoggedSucess){
        //     let newExistResult = this.props.createLoggedSucess && this.props.createLoggedSucess.result?this.props.createLoggedSucess.result : [];
        //     console.log("kya hua be",JSON.stringify(this.props.createLoggedSucess))
        //     this.setState({
        //         quoteIdPopNewExist: newExistResult && newExistResult ? newExistResult.quoteId:"",
        //         idUnderScoreNewExist: newExistResult && newExistResult ? newExistResult._id:"",
        //     },()=>{
        //
        //     })
        // }

    }
    componentDidUpdate = (prevProps,prevState,snapShot) =>{
        if (this.props.createLoggedSucess && this.props.createLoggedSucess !== prevProps.createLoggedSucess) {
            if (Array.isArray(this.props.createLoggedSucess) && this.props.createLoggedSucess.length >= 1) {
                this.props.createLoggedSucess.map(item => {
                    // alert(item._id)
                    localStorage.setItem('ratesLoggedUser',JSON.stringify(this.props.createLoggedSucess))
                    let quoteIdPopNewExist = item.quoteId;
                    let idUnderScoreNewExist = item._id;
                    // let rateArray = []
                    // rateArray.push({rateId: item._id,amount : item.amountB})
                    this.setState({
                        // rate : rateArray,
                        quoteIdPopNewExist: quoteIdPopNewExist,
                        idUnderScoreNewExist: idUnderScoreNewExist,
                    },()=>{
                        
                    })
                })
            }


        }
        if(prevProps.typeOfCust !== this.props.typeOfCust){
            this.setState({
                typeOfCust : true
            })
        }
        if(prevProps.coupanRemove !== this.props.coupanRemove){
            this.setState({
                applyCoupan : false
            },()=>{
                localStorage.removeItem('afterDiscountCharges')
                localStorage.removeItem('discountGiven')
            })
        }
        if(prevState.coupanName !== this.state.coupanName){
            this.setState({
                coupanName : this.state.coupanName
            })
        }
        if(prevState.coupanId !== this.state.coupanId){
                            this.props.getCoupanDetails({applyCoupan : this.state.applyCoupan,coupanId : this.state.coupanId,rateId : this.state.rateeId})
        }
    }
    creditDaysSTatusFunc = () =>{
        this.setState({
            creditDaysSTatus:!this.state.creditDaysSTatus
        },()=>{
            if(this.state.creditDaysSTatus === false){
                this.setState({
                    creditDays : ""
                })
            }
        })
    }
    switchNeedCredit = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },()=>{
            localStorage.setItem('creditDays',this.state.creditDays)
        });
    }
    custDetails = () => {
        let typeOfCust = JSON.parse(localStorage.getItem("customer"))
        const custType = typeOfCust && typeOfCust.result && typeOfCust.result.customerData && typeOfCust.result.customerData.typeOfBussiness
        const custKycStatus = typeOfCust && typeOfCust.result && typeOfCust.result.customerBranchData && typeOfCust.result.customerBranchData.kycStatus
        return {typeOfCust,custType,custKycStatus}
    }
    handleClickForSlab = () => {
        let {queryDataForcard,product,data} = this.props
        this.getAllSlabRate({
            originDetails : queryDataForcard.originId ? queryDataForcard.originId :data && data.originId,
            destinationDetails: queryDataForcard.destinationId ?queryDataForcard.destinationId :data && data.destinationId,
            product : product,
            tarrifMode: queryDataForcard.tarrifMode
        }).then(res =>{
            if(res.success){
                this.setState({
                    ODPData : res.result
                })
            }
        })
    }
    async getAllSlabRate(body) {
        return await RestClient({
            url: GET_ALL_SLAB_RATE_AIR,
            method: "POST",
            data: body
        });
    }
    handleClickForOceanSlab = () => {
        let {queryDataForcard,shippingLineId} = this.props
        this.getAllSlabRateForOcean({
            originDetails : queryDataForcard.originId,
            destinationDetails: queryDataForcard.destinationId,
            shippingLineId : shippingLineId
        }).then(res =>{
            if(res.success){
                this.setState({
                    ODPDataForOcean : res.result
                })
            }
        })

    }
    async getAllSlabRateForOcean(body) {
        return await RestClient({
            url: GET_ALL_SLAB_RATE_OCEAN,
            method: "POST",
            data: body
        });
    }
//     rateIdFound = (data) => {
//         let rateLoggedData = localStorage.getItem('ratesLoggedUser')
//         rateLoggedData = JSON.parse(rateLoggedData)
//         // console.log('rateLoggedData',rateLoggedData)
//         if(rateLoggedData){
//         if(rateLoggedData.length>0){
//             // alert(rateLoggedData.length)
//         // rateLoggedData = JSON.parse(JSON.stringify(rateLoggedData))
//         let foundId = rateLoggedData.find(ele=>ele.amount == data.amountB)
//         if(foundId){
//             return foundId._id
//         }else{
//             return ''
//         }
//     }
// }else{
//         return ''
//     }
//     }
    // awaitPopupOpen = (id) =>{
    //     this.setState({
    //         awaitPopup:!this.state.awaitPopup,
    //         RateIds:id,
    //     },()=>{
    //         // console.log("gradientwithRadial",JSON.stringify(this.props.RateIds))
    //     })
    // }
    removeCoupan = async () => {
        let customerBranchData = JSON.parse(localStorage.getItem('customer'))

        let obj ={
            rateId : this.props.data && this.props.data._id || this.state.idUnderScoreNewExist,
            customerBranchId : customerBranchData ?customerBranchData && customerBranchData.result && customerBranchData.result.customerBranchData._id : '',
            coupanId : this.state.coupanId
        }
        {
            return await RestClient({
                url: REMOVE_COUPAN,
                method: "POST",
                data: obj
            });
        }
    };
    // toggleLarges = () => {
    //     this.setState({
    //         awaitPopup: !this.state.awaitPopup,
    //
    //     })
    // };
    render() {
        // console.log('arrrrrrrrrrrrrrrrrrrrrrrr',this.state.rate)
        let {data, title, detail,queryDataForcard,product,shippingLineId, percent, progressText, BookingID, login} = this.props
        let obj = this.props.data
        let length=this.props.lengthCard
        // console.log("first time chala m",length)
        let customerBranchData = JSON.parse(localStorage.getItem('customer'))
        return (
            <>
                <AddCoupanApplyForHome
                    modalLarge={this.state.payLarge}
                    setmodal1={values => values === false ? this.setState({payLarge: false}) : ""}
                    quoteId = {obj.quoteId || this.state.quoteIdPopNewExist}
                    customerBranchId = {customerBranchData ?customerBranchData && customerBranchData.result && customerBranchData.result.customerBranchData._id : ''}
                    rateId = {obj._id}
                    // amount = {data.amountB}
                    // rateId = {this.rateIdFound(data)}
                    sendDataAfterCoupan = {values =>{
                        values && values.map((obj, i) => {
                            if(this.props.chargeId === values[i]._id){
                                this.setState({
                                    applyCoupan : obj.coupanApply,
                                    discountAmount : obj.discountAmount,
                                    discountAmount15 : obj.discountAmount15,
                                    discountAmount30 : obj.discountAmount30,
                                    amountCDiscount : obj.amountCDiscount,
                                    amountC15Discount : obj.amountC15Discount,
                                    amountC30Discount : obj.amountC30Discount,
                                    amountBDiscount : obj.amountBDiscount,
                                    amountB15Discount : obj.amountB15Discount,
                                    amountB30Discount : obj.amountB30Discount,
                                    taxAmountB15Discount : obj.taxAmountB15Discount,
                                    taxAmountB30Discount : obj.taxAmountB30Discount,
                                    taxAmountBDiscount : obj.taxAmountBDiscount,
                                    taxAmountC15Discount : obj.taxAmountC15Discount,
                                    taxAmountC30Discount : obj.taxAmountC30Discount,
                                    taxAmountCDiscount : obj.taxAmountCDiscount,
                                    coupanName : obj.coupanName,
                                    coupanId : obj.coupanId,
                                    rateeId : obj._id,
                                })
                            }
                        })

                    }}
                    

                />
                {/*{this.state.awaitPopup ?*/}

                {/*    <AwaitedPopup*/}
                {/*        modalLarge={this.state.awaitPopup}*/}
                {/*        quoteIdForPopup = {this.props.quoteIdForPopup}*/}
                {/*        RateId={this.state.RateIds}*/}
                {/*        iRateIndex={this.props.BookingID}*/}
                {/*        data={this.props.data}*/}
                {/*        queryNo={this.props.queryNo}*/}
                {/*        queryDataForcard={this.props.queryDataForcard}*/}
                {/*        setmodal={values => values === false ? this.setState({awaitPopup: false}) : ""}*/}
                {/*    />*/}
                {/*    :""}*/}
                <SlabRatePoup
                    modalLarge = {this.state.isSlabRateOpen}
                    ODPData = {this.state.ODPData}
                    chargeableWeightForSlab = {queryDataForcard.chargeableWeight}
                    setmodal={value=>{
                        if(value === false){
                            this.setState({
                                isSlabRateOpen:false
                            })
                        }
                    }}
                />
                <SlabRatePopupForOcean
                    modalLarge1 = {this.state.isSlabRateOpenForOcean}
                    ODPDataForOcean = {this.state.ODPDataForOcean}
                    setmodal={value=>{
                        if(value === false){
                            this.setState({
                                isSlabRateOpenForOcean:false
                            })
                        }
                    }}
                />
                <>
                    {queryDataForcard.queryFor !== "Air" ?
                        <div  id="three-cards" className="rate-card mb-3 half-rates">
                            <Progress multi>
                                <Progress striped animated bar color="success" value="60" />
                                <Progress striped animated bar color="danger" value="40" />
                            </Progress>
                            <div className="d-flex flex-wrap align-items-center rates_partition">
                                <div className="destination_origin_app">
                                    <div className="width-new-rates">
                                        <div className="images_destination">
                                            <img
                                                src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.15065220565728366-1661242165033.png"
                                                alt="ocean fright"
                                            />
                                        </div>
                                        <div className="origin_destination_app">
                                            <div className="origin_app">
                                                <p className="small_name">Transit Time</p>
                                                <p className="full_name">{detail}</p>
                                            </div>
                                            <div className="origin_app">
                                                <p className="small_name">Origin</p>
                                                {queryDataForcard.activityType == "Door To Door" ?
                                                    <p className="full_name">{queryDataForcard.originDoor && queryDataForcard.originDoor.pincode ?
                                                        queryDataForcard.originDoor.pincode : "NA"}</p> :
                                                    <p className="full_name">{queryDataForcard.originAirport && queryDataForcard.originAirport.name ?
                                                        queryDataForcard.originAirport.name.split("(")[queryDataForcard.originAirport.name.split("(").length - 2]
                                                        : queryDataForcard.originDoor && queryDataForcard.originDoor.pincode ? queryDataForcard.originDoor.pincode : ""}</p>}
                                            </div>
                                            <div className="origin_app">
                                                <p className="small_name">Destination</p>
                                                {queryDataForcard.activityType == "Door To Door" ?
                                                    <p className="full_name">{queryDataForcard.destinationDoor && queryDataForcard.destinationDoor.pincode ?
                                                        queryDataForcard.destinationDoor.pincode
                                                        : "NA"}</p> :

                                                    <p className="full_name">{queryDataForcard.destinationAirport && queryDataForcard.destinationAirport.name ?
                                                        queryDataForcard.destinationAirport.name.split("(")[queryDataForcard.destinationAirport.name.split("(").length - 2]
                                                        : queryDataForcard.destinationDoor && queryDataForcard.destinationDoor.pincode ?
                                                            queryDataForcard.destinationDoor.pincode : "NA"}</p>}
                                            </div>
                                            <div className="origin_app">
                                                <p className="full_name rate_new_design">
                                                    {data.customerCurrency == "INR" ?
                                                        <FontAwesomeIcon icon={faRupeeSign} size="lg"/> : data.customerCurrency == "USD" ?
                                                            <FontAwesomeIcon icon={faDollarSign} size="lg"/> : data.customerCurrency == "EUR" ?
                                                                <FontAwesomeIcon icon={faEuroSign} size="lg"/> : ""}{" "}
                                                    {this.state.switchCurrency==true?
                                                        this.state.applyCoupan ?
                                                            <>
                                                                {this.state.creditDays === ""? this.state.amountCDiscount  ? parseFloat(this.state.amountCDiscount).toFixed(2) :"":""}
                                                                {this.state.creditDays === "15 Days"? this.state.amountC15Discount  ? parseFloat(this.state.amountC15Discount).toFixed(2) : "":""}
                                                                {this.state.creditDays === "30 Days"? this.state.amountC30Discount  ? parseFloat(this.state.amountC30Discount).toFixed(2) : "":""}
                                                            </> :<>
                                                                {this.state.creditDays === ""? data && data.amountC  ? parseFloat(data.amountC).toFixed(2) :"":""}
                                                                {this.state.creditDays === "15 Days"? data && data.amountC15  ? parseFloat(data.amountC15).toFixed(2) : "":""}
                                                                {this.state.creditDays === "30 Days"? data && data.amountC30  ? parseFloat(data.amountC30).toFixed(2) : "":""}
                                                            </>
                                                        :
                                                        this.state.applyCoupan ? <>
                                                                {this.state.creditDays === ""?this.state.amountBDiscount  ? parseFloat(this.state.amountBDiscount).toFixed(2) : "":""}
                                                                {this.state.creditDays === "15 Days"?this.state.amountB15Discount  ? parseFloat(this.state.amountB15Discount).toFixed(2) : "":""}
                                                                {this.state.creditDays === "30 Days"?this.state.amountB30Discount  ? parseFloat(this.state.amountB30Discount).toFixed(2) : "":""}
                                                            </>:
                                                            <>
                                                                {this.state.creditDays === ""?data && data.amountB  ? parseFloat(data.amountB).toFixed(2) : "":""}
                                                                {this.state.creditDays === "15 Days"?data && data.amountB15  ? parseFloat(data.amountB15).toFixed(2) : "":""}
                                                                {this.state.creditDays === "30 Days"?data && data.amountB30  ? parseFloat(data.amountB30).toFixed(2) : "":""}
                                                            </>
                                                    }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="spot_coupon_rates">
                                        <div className="switch_currency">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" name="switchCurrency" checked={this.state.switchCurrency==true ? true : false}
                                                       onChange={(e) => {this.switchCurrency(e)}}
                                                       id=""/>
                                                <label className="form-check-label" htmlFor="">Switch to own Currency</label>
                                            </div>
                                        </div>
                                        <div className="switch_currency need_credit_query">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" onClick={this.creditDaysSTatusFunc}/>
                                                <label className="form-check-label" >Need Credit</label>
                                            </div>
                                            {this.state.creditDaysSTatus === true ?
                                                <select className="" name="creditDays" onChange={this.switchNeedCredit} value={this.state.creditDays}>
                                                    <option value={""}>No Credit</option>
                                                    <option value={"15 Days"}>15 Days</option>
                                                    <option value={"30 Days"}>30 Days</option>
                                                </select>
                                                :""}
                                        </div>
                                        {this.state.applyCoupan ?
                                            <>
                                                <div className="remove_btn_with_value d-flex flex-wrap align-items-stretch mr-2">
                                                    <h6 className="coupon_apply_pop">{this.state.coupanName}</h6>
                                                    <div className="cross_apply_coupan" onClick={e=>{
                                                        e.preventDefault()
                                                        this.removeCoupan().then(res=>{
                                                            if(res.success){
                                                                toast.success("Coupon Removed.", {
                                                                    position: "top-left",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                                this.setState({
                                                                    applyCoupan : false
                                                                },()=>{
                                                                    localStorage.removeItem('afterDiscountCharges')
                                                                    localStorage.removeItem('discountGiven')
                                                                })
                                                                // window.location.reload("false")
                                                            }
                                                        })
                                                    }}><FontAwesomeIcon icon={faTimes} size="lg"/>
                                                    </div>
                                                </div>

                                            </> :<div className="app_couply" onClick={e=>{
                                                e.preventDefault()
                                                localStorage.setItem('amountB',data.amountB)
                                                this.setState({payLarge : true})
                                            }}>
                                                Apply Coupon
                                            </div>}
                                        <div className="app_couply" onClick={e => {
                                            e.preventDefault()
                                            if(length === 1){
                                                this.setState({showCollaps: !this.state.showCollaps})
                                            }else{
                                                this.setState({showCollapsSep: !this.state.showCollapsSep})
                                            }
                                        }}>Freight Summery</div>
                                    </div>
                                </div>
                                <div className="name_company">
                                    <h5>{title}</h5>
                                    <button className="btn company_btn" onClick={(e) => {
                                        e.preventDefault()
                                        let value = this.custDetails()
                                        let typeOfCustomer = value && value.typeOfCust && value.typeOfCust.result && value.typeOfCust.result.customerData && value.typeOfCust.result.customerData.typeOfBussiness ? 'value.typeOfCust.result.customerData.typeOfBussiness' : "Bussiness"

                                        if(this.checkUser()){
                                            if(value.custKycStatus){
                                                this.props.login(true)
                                            }else{
                                                if(typeOfCustomer == "Bussiness"){
                                                    history.push(PAGE_PATHS.CUSTOMER_KYC);
                                                }else{
                                                    history.push(PAGE_PATHS.CUSTOMER_KYC_FOR_INDIVIDUAL);
                                                }
                                                window.location.reload("false")
                                            }

                                        }else{
                                            this.props.isUserLoggedIn(true)
                                        }
                                        // this.props.login(true)
                                    }}>Book Now</button>
                                </div>
                            </div>


                            {length === 1 ?
                                <div id="collapseOne1"  className={this.state.showCollaps === true ? "collapse border-top scroll-freight show " : "collapse border-top"} role="tabpanel">
                                    <div className="d-flex flex-wrap justify-content-between padding-15">
                                        <div className="all-price list-fare">

                                            <table className="table pricess-all">
                                                <tr>
                                                    <th>Charges Name</th>
                                                    <th>{queryDataForcard.shipmentMode === "FCL"? "No of Containers" :"Volume CBM" }</th>
                                                    <th>Rate</th>
                                                    <th>Total Amount</th>
                                                </tr>

                                                {data.charges !== undefined && queryDataForcard.shipmentMode === "FCL" ?

                                                    data.charges.map((item,i)=>{
                                                        return(


                                                            <tr>
                                                                <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null? item.chargeName +" - "+item.altName :item.chargeName }</th>
                                                                <td>{item.chargeQty}</td>
                                                                <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                    {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                    {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                                <td>
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>


                                                        )
                                                    })

                                                    :
                                                    data.charges.map((item,i)=>{
                                                        return(
                                                            <tr>
                                                                <th>{item.chargeName}</th>
                                                                <td>{item.chargeQty}</td>
                                                                <td>{item.currency+" "+item.chargeRate}</td>
                                                                <td>
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " +item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {this.state.applyCoupan ? ""
                                                    :
                                                    <>
                                                        {data.taxAmountB !== null ?
                                                            <tr>
                                                                <th>GST</th>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>
                                                                    {this.state.switchCurrency == true ?
                                                                        <>
                                                                            {this.state.creditDays === "" ? data.customerCurrency + " " + data.taxAmountC != undefined ? parseFloat(data.taxAmountC).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "15 Days" ? data.customerCurrency + " " + data.taxAmountC15 != undefined ? parseFloat(data.taxAmountC15).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "30 Days" ? data.customerCurrency + " " + data.taxAmountC30 != undefined ? parseFloat(data.taxAmountC30).toFixed(2) : "" : ""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === "" ? data.baseCurrency + " " + data.taxAmountB != undefined ? parseFloat(data.taxAmountB).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "15 Days" ? data.baseCurrency + " " + data.taxAmountB15 != undefined ? parseFloat(data.taxAmountB15).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "30 Days" ? data.baseCurrency + " " + data.taxAmountB30 != undefined ? parseFloat(data.taxAmountB30).toFixed(2) : "" : ""}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            :
                                                            undefined}
                                                    </>
                                                }
                                                <tr>
                                                    <th>TOTAL AMOUNT</th>
                                                    <td><strong /> </td>
                                                    <td><strong /> </td>
                                                    <td><strong>
                                                        {this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC ).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30 ).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30).toFixed(2) :""}
                                                            </>
                                                        }
                                                    </strong>
                                                    </td>
                                                </tr>
                                                {this.state.applyCoupan ?
                                                        <>
                                                        <tr>
                                                        <th>Discount on MRP</th>
                                                        <td><strong/></td>
                                                        <td><strong/></td>
                                                        <td style={{color: '#03a685'}}>
                                                            <strong>{this.state.switchCurrency==true?
                                                                <>
                                                                    {this.state.creditDays === ""? data.baseCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                    {this.state.creditDays === "15 Days"? data.baseCurrency +" " +  parseFloat(this.state.discountAmount15).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"? data.baseCurrency +" " +  parseFloat(this.state.discountAmount30).toFixed(2) :""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.discountAmount).toFixed(2):""}
                                                                    {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount15).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount30).toFixed(2) :""}
                                                                </>
                                                            }</strong>
                                                        </td>
                                                    </tr>
                                                    {data.taxAmountB !== null ?
                                                        <tr>
                                                            <th>GST</th>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>
                                                                {/*{this.state.switchCurrency == true ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                                {this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + this.state.taxAmountCDiscount != undefined ? parseFloat(this.state.taxAmountCDiscount).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + this.state.taxAmountC15Discount != undefined ? parseFloat(this.state.taxAmountC15Discount).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + this.state.taxAmountC30Discount != undefined ? parseFloat(this.state.taxAmountC30Discount).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + this.state.taxAmountBDiscount != undefined ? parseFloat(this.state.taxAmountBDiscount).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + this.state.taxAmountB15Discount != undefined ? parseFloat(this.state.taxAmountB15Discount).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + this.state.taxAmountB30Discount != undefined ? parseFloat(this.state.taxAmountB30Discount).toFixed(2) : "":""}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        undefined}
                                                 </> : ""}

                                                {this.state.applyCoupan ?
                                                        <tr>
                                                        <th>Net Payable Amount(After Discount)</th>
                                                        <td><strong/></td>
                                                        <td><strong/></td>
                                                        <td>
                                                            <strong>{this.state.switchCurrency==true?
                                                                <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(this.state.amountCDiscount).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC15Discount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC30Discount).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.amountBDiscount).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB15Discount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB30Discount).toFixed(2) :""}
                                                            </>
                                                            }</strong>
                                                        </td>
                                                    </tr>
                                                     : ""
                                                    }

                                            </table>
                                            <div>
                                                <h3><strong>Awaited Rates : </strong></h3>
                                                <ul>
                                                    {Array.isArray(data.awaitedRates) && data.awaitedRates.length >= 1 ? data.awaitedRates.map(item=>{
                                                        return(
                                                            <>
                                                                {item.chargeFor!=item.altName?
                                                                    <li> {item.chargeFor} : {item.altName} </li>
                                                                    :
                                                                    <li> {item.chargeFor} </li>
                                                                }
                                                            </>
                                                        );
                                                    }) :undefined}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="terms-width list-fare">
                                            <p className="mt-2"><strong>TERMS & CONDITIONS</strong></p>
                                            {queryDataForcard.queryFor !== "Ocean" ?
                                                <ul>
                                                    <li>  Rates are subject to space confirmation from the carrier.</li>
                                                    <li>  Rates are valid only for the current week or 7 days whichever is earlier.</li>
                                                    <li>  Rates are only valid for the shipment details shared in the query only, any changes will require rate revalidation.</li>
                                                    <li>  Rates are applicable for general cargo only.</li>
                                                    <li>  All inclusive (ALL-IN) rates quoted through instant rate quotes includes Air Freight, Fuel Surcharge, Security Surcharge, X-ray charges, Misc Charges, AMS charges. Any other charges must be considered as additional.</li>
                                                    <li>  For any special cargo, the instant live rates are not applicable, and will be quoted separately. </li>
                                                    <li>  Transit time mentioned is purely indicative and should be considered as tentative.</li>
                                                    <li>  Government Taxes (if any) shall be applicable as per Government norms.</li>
                                                </ul>
                                                :
                                                <ul>
                                                    <li>  Booking requests are strictly subject to space and equipment availability and confirmation.</li>
                                                    <li>  Instant rates quoted are applicable for general commodities only, with no special handling requirements.</li>
                                                    <li>  Rates are valid for the shipment details shared in the query.</li>
                                                    <li>  Shipping Line surcharges are subject to change, unless fixed within the quotation validity.</li>
                                                    <li>  For any special cargo, the instant live rates are not applicable. The price quotes shall be provided within 48-72 hours during the carriers’ working days. </li>
                                                    <li>  Transit time mentioned are indicative based on the carriers’ information and should be considered as tentative. </li>
                                                    <li>  Government Taxes (if any) shall be applicable as per Government norms.</li>
                                                </ul>

                                            }
                                            <p className="mt-2"><strong>Remarks</strong></p>
                                            <ul className="mb-2">
                                                {data.remarksDestination && data.remarksDestination.map((item,i)=>{
                                                    return(
                                                        <li> {item.rem !== null ? item.rem.label : ""}</li>
                                                    );
                                                })
                                                }
                                                {data.groupNameRemarks && data.groupNameRemarks.map((item,i)=>{
                                                    return(
                                                        <li> {item}</li>
                                                    );
                                                })
                                                }
                                                {data.demurrageDetentionDetails && data.demurrageDetentionDetails.map((item,i)=>{
                                                    return(
                                                        <li>
                                                            {queryDataForcard.originAirport && item.portDetails && item.portDetails.name==queryDataForcard.originAirport.name ?
                                                                <><span>
                                                                {/*origin Port Details*/}
                                                                    Free Period ({queryDataForcard.originAirport.code}) : Demurrage {item.exportDetentionDays} Days, Detention {item.exportDetentionDays} Days,{" "}
                                                                    {item.timings && item.timings.map((item2,j)=>{
                                                                        return(
                                                                            <span>
                                                                            {item2.detOrDem=="Detention" ?
                                                                                <>
                                                                                    {item2.detOrDem} will be levied At {item2.currency} {item2.chargeRate} for a period of {item2.daysCountStart}-{item2.daysCountEnd} days{j==(item.timings.length-1)?" ":", "}

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {/*{item2.detOrDem} will be levied At {item2.currency} {item2.chargeRate} for a period of {item2.daysCountStart}-{item2.daysCountEnd} days{j==(item.timings.length-1)?",":""}*/}
                                                                                </>
                                                                            }
                                                                    </span>)
                                                                    })}
                                                                </span>
                                                                </>
                                                                :
                                                                <><span>
                                                                {/*Destination Port Details*/}
                                                                    Free Period ({queryDataForcard.destinationAirport.code}) : Demurrage {item.exportDetentionDays} Days, Detention {item.exportDetentionDays} Days,{" "}
                                                                    {item.timings && item.timings.map((item2,j)=>{
                                                                        return(
                                                                            <span>
                                                                            {item2.detOrDem=="Detention" ?
                                                                                <>
                                                                                    {item2.detOrDem} will be levied At {item2.currency} {item2.chargeRate}/Per Day for a period of {item2.daysCountStart}-{item2.daysCountEnd} days{j==(item.timings.length-1)?" ":", "}

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {item2.detOrDem} will be levied At {item2.currency} {item2.chargeRate}/Per Day for a period of {item2.daysCountStart}-{item2.daysCountEnd} days{j==(item.timings.length-1)?" ":", "}
                                                                                </>
                                                                            }
                                                                    </span>)
                                                                    })}
                                                                </span>
                                                                </>
                                                            }
                                                        </li>

                                                    );
                                                })}

                                                {data.remarks !== "" ? <li>{data.remarks}</li> : undefined}
                                            </ul>
                                            {obj.vesselSchedules && obj.vesselSchedules.length > 0 ?
                                                <div className="pl-3 pb-2">
                                                    <p className="mt-2 pl-1 mb-1"><strong>Vessel Schedule</strong></p>
                                                    <div className="table-responsive table-vessles">
                                                        <table
                                                            className="table table-bordered table-striped table-hover table-sticky">
                                                            <thead style={{background: '#ff7900', color: '#fff'}}>
                                                            <tr>

                                                                <th className="text-center">Vessel</th>
                                                                <th className="text-center">Voyage No</th>
                                                                <th className="text-center">Departure Date</th>
                                                                <th className="text-center">Arrival Date</th>
                                                                <th className="text-center">Berthing Date</th>
                                                                {localStorage.getItem("already_registered") === "true" || this.state.showLogin === true ?<th className="text-center">Action</th>:""}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                obj.vesselSchedules && obj.vesselSchedules.map((item,i)=>{
                                                                    return(
                                                                        <tr>
                                                                            <td>{item.vessel}</td>
                                                                            <td>{item.voyageNo}</td>
                                                                            <td>{item.departure ? convertDateISO(item.departure) : ""}</td>
                                                                            <td>{item.arrival ? convertDateISO(item.arrival) : ""}</td>
                                                                            <td>{item.bearthing ? convertDateISO(item.bearthing) : ""}</td>
                                                                            {localStorage.getItem("already_registered") === "true"
                                                                                ?
                                                                                <>
                                                                                    {this.checkIdAvailable(item._id)==true
                                                                                        ?<td><button type="button"
                                                                                                     className="border-cir delete"
                                                                                                     onClick={e => {
                                                                                                     }}>Mapped In Planning</button></td>
                                                                                        :<td><button type="button"
                                                                                                     className="border-cir delete"
                                                                                                     onClick={e => {
                                                                                                         this.sendToIntendedTransportPlan(item)
                                                                                                     }}>Update Plan</button></td>
                                                                                    }
                                                                                </>
                                                                                : ""}
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> :""}
                                        </div>
                                    </div>
                                </div>:
                                <div id="collapseOne1"
                                     className={this.state.showCollapsSep === true ? "collapse border-top show " : "collapse border-top"} role="tabpanel">
                                    <div className="d-flex flex-wrap justify-content-between padding-15">
                                        <div className="all-price list-fare">
                                            <table className="table pricess-all">
                                                <tr>
                                                    <th>Charges Name</th>
                                                    <th>{queryDataForcard.shipmentMode === "FCL"? "No of Containers" :"Volume CBM" }</th>
                                                    <th>Rate</th>
                                                    <th>Total Amount</th>
                                                </tr>

                                                {data.charges !== undefined && queryDataForcard.shipmentMode === "FCL" ?

                                                    data.charges.map((item,i)=>{
                                                        return(


                                                            <tr>
                                                                <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null? item.chargeName +" - "+item.altName :item.chargeName }</th>
                                                                <td>{item.chargeQty}</td>
                                                                <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                    {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                    {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                                <td>
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>


                                                        )
                                                    })

                                                    :
                                                    data.charges.map((item,i)=>{
                                                        return(
                                                            <tr>
                                                                <th>{item.chargeName}</th>
                                                                <td>{item.chargeQty}</td>
                                                                <td>{item.currency+" "+item.chargeRate}</td>
                                                                <td>
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " +item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {this.state.applyCoupan ? ""
                                                    :
                                                    <>
                                                        {data.taxAmountB !== null ?
                                                            <tr>
                                                                <th>GST</th>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>
                                                                    {this.state.switchCurrency == true ?
                                                                        <>
                                                                            {this.state.creditDays === "" ? data.customerCurrency + " " + data.taxAmountC != undefined ? parseFloat(data.taxAmountC).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "15 Days" ? data.customerCurrency + " " + data.taxAmountC15 != undefined ? parseFloat(data.taxAmountC15).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "30 Days" ? data.customerCurrency + " " + data.taxAmountC30 != undefined ? parseFloat(data.taxAmountC30).toFixed(2) : "" : ""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === "" ? data.baseCurrency + " " + data.taxAmountB != undefined ? parseFloat(data.taxAmountB).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "15 Days" ? data.baseCurrency + " " + data.taxAmountB15 != undefined ? parseFloat(data.taxAmountB15).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "30 Days" ? data.baseCurrency + " " + data.taxAmountB30 != undefined ? parseFloat(data.taxAmountB30).toFixed(2) : "" : ""}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            :
                                                            undefined}
                                                            <tr>
                                                    <th>TOTAL AMOUNT</th>
                                                    <td><strong /> </td>
                                                    <td><strong /> </td>
                                                    <td><strong>
                                                        {this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30).toFixed(2) :""}
                                                            </>
                                                        }
                                                    </strong>
                                                    </td>
                                                </tr>
                                                    </>
                                                }
                                                
                                                {this.state.applyCoupan ?
                                                    <>
                                                    <tr>
                                                    <th>TOTAL AMOUNT</th>
                                                    <td><strong /> </td>
                                                    <td><strong /> </td>
                                                    <td><strong>
                                                        {this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC -data.taxAmountC).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15 -data.taxAmountC15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30 -data.taxAmountC30).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB -data.taxAmountB).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15 - data.taxAmountB15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30 - data.taxAmountB30).toFixed(2) :""}
                                                            </>
                                                        }
                                                    </strong>
                                                    </td>
                                                </tr>
                                                        <tr>
                                                            <th>Discount on MRP</th>
                                                            <td><strong/></td>
                                                            <td><strong/></td>
                                                            <td style={{color: '#03a685'}}>
                                                                <strong>{this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.baseCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                        {this.state.creditDays === "15 Days"? data.baseCurrency +" " +  parseFloat(this.state.discountAmount15).toFixed(2) :""}
                                                                        {this.state.creditDays === "30 Days"? data.baseCurrency +" " +  parseFloat(this.state.discountAmount30).toFixed(2) :""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.discountAmount).toFixed(2):""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount15).toFixed(2) :""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount30).toFixed(2) :""}
                                                                    </>
                                                                }</strong>
                                                            </td>
                                                        </tr>
                                                        {data.taxAmountB !== null ?
                                                            <tr>
                                                                <th>GST</th>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>
                                                                    {/*{this.state.switchCurrency == true ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + this.state.taxAmountCDiscount != undefined ? parseFloat(this.state.taxAmountCDiscount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + this.state.taxAmountC15Discount != undefined ? parseFloat(this.state.taxAmountC15Discount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + this.state.taxAmountC30Discount != undefined ? parseFloat(this.state.taxAmountC30Discount).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " + this.state.taxAmountBDiscount != undefined ? parseFloat(this.state.taxAmountBDiscount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + this.state.taxAmountB15Discount != undefined ? parseFloat(this.state.taxAmountB15Discount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + this.state.taxAmountB30Discount != undefined ? parseFloat(this.state.taxAmountB30Discount).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            :
                                                            undefined}
                                                    </> : ""}

                                                {this.state.applyCoupan ?
                                                    <tr>
                                                        <th>Net Payable Amount(After Discount)</th>
                                                        <td><strong/></td>
                                                        <td><strong/></td>
                                                        <td>
                                                            <strong>{this.state.switchCurrency==true?
                                                                <>
                                                                    {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(this.state.amountCDiscount).toFixed(2) :""}
                                                                    {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC15Discount).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC30Discount).toFixed(2) :""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.amountBDiscount).toFixed(2):""}
                                                                    {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB15Discount).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB30Discount).toFixed(2) :""}
                                                                </>
                                                            }</strong>
                                                        </td>
                                                    </tr>
                                                    : ""
                                                }

                                            </table>
                                            <div>
                                                <h3><strong>Awaited Rates :</strong></h3>
                                                <ul>
                                                    {Array.isArray(data.awaitedRates) && data.awaitedRates.length >= 1 ? data.awaitedRates.map(item=>{
                                                        return(
                                                            <>
                                                                {item.chargeFor!=item.altName?
                                                                    <li> {item.chargeFor} : {item.altName} </li>
                                                                    :
                                                                    <li> {item.chargeFor} </li>
                                                                }
                                                            </>
                                                        );
                                                    }) :undefined}
                                                </ul>

                                            </div>
                                        </div>
                                        <div className="terms-width list-fare">
                                            <p className="mt-2"><strong>TERMS & CONDITIONS</strong></p>
                                            {queryDataForcard.queryFor !== "Ocean" ?
                                                <ul>
                                                    <li>  Rates are subject to space confirmation from the carrier.</li>
                                                    <li>  Rates are valid only for the current week or 7 days whichever is earlier.</li>
                                                    <li>  Rates are only valid for the shipment details shared in the query only, any changes will require rate revalidation.</li>
                                                    <li>  Rates are applicable for general cargo only.</li>
                                                    <li>  All inclusive (ALL-IN) rates quoted through instant rate quotes includes Air Freight, Fuel Surcharge, Security Surcharge, X-ray charges, Misc Charges, AMS charges. Any other charges must be considered as additional.</li>
                                                    <li>  For any special cargo, the instant live rates are not applicable, and will be quoted separately. </li>
                                                    <li>  Transit time mentioned is purely indicative and should be considered as tentative.</li>
                                                    <li>  Government Taxes (if any) shall be applicable as per Government norms.</li>
                                                </ul>
                                                :
                                                <ul>
                                                    <li>  Booking requests are strictly subject to space and equipment availability and confirmation.</li>
                                                    <li>  Instant rates quoted are applicable for general commodities only, with no special handling requirements.</li>
                                                    <li>  Rates are valid for the shipment details shared in the query.</li>
                                                    <li>  Shipping Line surcharges are subject to change, unless fixed within the quotation validity.</li>
                                                    <li>  For any special cargo, the instant live rates are not applicable. The price quotes shall be provided within 48-72 hours during the carriers’ working days. </li>
                                                    <li>  Transit time mentioned are indicative based on the carriers’ information and should be considered as tentative. </li>
                                                    <li>  Government Taxes (if any) shall be applicable as per Government norms.</li>
                                                </ul>

                                            }
                                            <p className="mt-2"><strong>Remarks</strong></p>
                                            <ul className="mb-2">
                                                {data.remarksDestination && data.remarksDestination.map((item,i)=>{
                                                    return(
                                                        <li> {item.rem !== null ? item.rem.label : ""}</li>
                                                    );
                                                })
                                                }
                                                {data.groupNameRemarks && data.groupNameRemarks.map((item,i)=>{
                                                    return(
                                                        <li> {item}</li>
                                                    );
                                                })
                                                }
                                                {data.demurrageDetentionDetails && data.demurrageDetentionDetails.map((item,i)=>{
                                                    return(
                                                        <li>
                                                            {queryDataForcard.originAirport && item.portDetails && item.portDetails.name==queryDataForcard.originAirport.name ?
                                                                <><span>
                                                                {/*origin Port Details*/}
                                                                    Free Period ({queryDataForcard.originAirport.code}) : Demurrage {item.exportDetentionDays} Days, Detention {item.exportDetentionDays} Days,{" "}
                                                                    {item.timings && item.timings.map((item2,j)=>{
                                                                        return(
                                                                            <span>
                                                                            {item2.detOrDem=="Detention" ?
                                                                                <>
                                                                                    {item2.detOrDem} will be levied At {item2.currency} {item2.chargeRate} for a period of {item2.daysCountStart}-{item2.daysCountEnd} days{j==(item.timings.length-1)?" ":", "}

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {/*{item2.detOrDem} will be levied At {item2.currency} {item2.chargeRate} for a period of {item2.daysCountStart}-{item2.daysCountEnd} days{j==(item.timings.length-1)?",":""}*/}
                                                                                </>
                                                                            }
                                                                    </span>)
                                                                    })}
                                                                </span>
                                                                </>
                                                                :
                                                                <><span>
                                                                {/*Destination Port Details*/}
                                                                    Free Period ({queryDataForcard.destinationAirport.code}) : Demurrage {item.exportDetentionDays} Days, Detention {item.exportDetentionDays} Days,{" "}
                                                                    {item.timings && item.timings.map((item2,j)=>{
                                                                        return(
                                                                            <span>
                                                                            {item2.detOrDem=="Detention" ?
                                                                                <>
                                                                                    {item2.detOrDem} will be levied At {item2.currency} {item2.chargeRate}/Per Day for a period of {item2.daysCountStart}-{item2.daysCountEnd} days{j==(item.timings.length-1)?" ":", "}

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {item2.detOrDem} will be levied At {item2.currency} {item2.chargeRate}/Per Day for a period of {item2.daysCountStart}-{item2.daysCountEnd} days{j==(item.timings.length-1)?" ":", "}
                                                                                </>
                                                                            }
                                                                    </span>)
                                                                    })}
                                                                </span>
                                                                </>
                                                            }
                                                        </li>

                                                    );
                                                })}

                                                {data.remarks !== "" ? <li>{data.remarks}</li> : undefined}
                                            </ul>
                                            {obj.vesselSchedules && obj.vesselSchedules.length > 0 ?
                                                <div className="pl-3 pb-2">
                                                    <p className="mt-2 pl-1 mb-1"><strong>Vessel Schedule</strong></p>
                                                    <div className="table-responsive table-vessles">
                                                        <table
                                                            className="table table-bordered table-striped table-hover table-sticky">
                                                            <thead style={{background: '#ff7900', color: '#fff'}}>
                                                            <tr>

                                                                <th className="text-center">Vessel</th>
                                                                <th className="text-center">Voyage No</th>
                                                                <th className="text-center">Departure Date</th>
                                                                <th className="text-center">Arrival Date</th>
                                                                <th className="text-center">Berthing Date</th>
                                                                {localStorage.getItem("already_registered") === "true" || this.state.showLogin === true ?<th className="text-center">Action</th>:""}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                obj.vesselSchedules && obj.vesselSchedules.map((item,i)=>{
                                                                    return(
                                                                        <tr>
                                                                            <td>{item.vessel}</td>
                                                                            <td>{item.voyageNo}</td>
                                                                            <td>{item.departure ? convertDateISO(item.departure) : ""}</td>
                                                                            <td>{item.arrival ? convertDateISO(item.arrival) : ""}</td>
                                                                            <td>{item.bearthing ? convertDateISO(item.bearthing) : ""}</td>
                                                                            {localStorage.getItem("already_registered") === "true"
                                                                                ?
                                                                                <>
                                                                                    {this.checkIdAvailable(item._id)==true
                                                                                        ?<td><button type="button"
                                                                                                     className="border-cir delete"
                                                                                                     onClick={e => {
                                                                                                     }}>Mapped In Planning</button></td>
                                                                                        :<td><button type="button"
                                                                                                     className="border-cir delete"
                                                                                                     onClick={e => {
                                                                                                         this.sendToIntendedTransportPlan(item)
                                                                                                     }}>Update Plan</button></td>
                                                                                    }
                                                                                </>
                                                                                : ""}
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> :""}
                                        </div>
                                    </div>
                                </div>}
                            <div id="collapseOne2" className={this.state.showCollapsForRate === true ? "collapse border-top show" : "collapse border-top"} role="tabpanel"
                                 aria-labelledby="headingOne2"
                                 data-parent="#accordionEx">
                                <div className="d-flex flex-wrap justify-content-between padding-15">
                                    <div className="list-fare pt-3" style={{width:"100%"}}>
                                        <p className="mb-3"><strong>All Equipment Rate</strong></p>
                                        <table className="table table-bordered text-center table-responsive-md">
                                            <tr style={{background: "#eaeaea"}}>
                                                <th>Shipping Line Name</th>
                                                <th>LCL (Per CBM /USD)</th>
                                                <th>20 STD (USD)</th>
                                                <th>40 STD (USD)</th>
                                                <th>40 HC (USD)</th>
                                                <th>VALIDITY</th>c
                                                <th hidden={false}>Action</th>

                                            </tr>

                                            {/*<SelectedDiffrentSlabCard*/}
                                            {/*data={data}*/}
                                            {/*SelectedWightSlab={SelectedWightSlab}*/}
                                            {/*frightAmount = {totalFreight}*/}
                                            {/*queryDataForcard = {queryDataForcard}*/}
                                            {/*sendDataToQuery={mainQueryData => {*/}
                                            {/*    this.props.sendDataToAddQuery(mainQueryData)*/}
                                            {/*}}*/}
                                            {/*/>*/}



                                            {/*{data && data.row.converted === true ?*/}
                                            {/*    <tr>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} >{data.row && data.row.convertedCurrency  ? data.row.convertedCurrency + " " : "INR"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "min" ? "chnageBack" : undefined}>{data.row && data.row.w_min && data.row.convertedData.w_min ? data.row.convertedData.w_min : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "normal" ? "chnageBack" : undefined}>{data.row && data.row.w_min && data.row.convertedData.w_normal ? data.row.convertedData.w_normal : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "45" ? "chnageBack" : undefined}>{data.row && data.row.w_min && data.row.convertedData.w_45 ? data.row.convertedData.w_45 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "100" ? "chnageBack" : undefined}>{data.row && data.row.w_min && data.row.convertedData.w_100 ? data.row.convertedData.w_100 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "250" ? "chnageBack" : undefined}>{data.row && data.row.w_min && data.row.convertedData.w_250 ? data.row.convertedData.w_250 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "300" ? "chnageBack" : undefined}>{data.row && data.row.w_min && data.row.convertedData.w_300 ? data.row.convertedData.w_300 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "500" ? "chnageBack" : undefined}>{data.row && data.row.w_min && data.row.convertedData.w_500 ? data.row.convertedData.w_500 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "1000" ? "chnageBack" : undefined}>{data.row && data.row.w_min && data.row.convertedData.w_1000 ? data.row.convertedData.w_1000 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}}>{data.row && data.row.validityEndDate ? convertDateISOToCustomiseFormat(data.row.validityEndDate) : "-"}</td>*/}

                                            {/*    </tr>*/}
                                            {/*    :*/}
                                            {/*    <tr>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} >{data.row && data.row.currency ? data.row.currency : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "min" ? "chnageBack" : undefined}>{data.row && data.row.w_min ? data.row.w_min : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "normal" ? "chnageBack" : undefined}>{data.row && data.row.w_normal ? data.row.w_normal : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "45" ? "chnageBack" : undefined}>{data.row && data.row.w_45 ? data.row.w_45 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "100" ? "chnageBack" : undefined}>{data.row && data.row.w_100 ? data.row.w_100 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "250" ? "chnageBack" : undefined}>{data.row && data.row.w_250 ? data.row.w_250 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "300" ? "chnageBack" : undefined}>{data.row && data.row.w_300 ? data.row.w_300 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "500" ? "chnageBack" : undefined}>{data.row && data.row.w_500 ? data.row.w_500 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}} className={SelectedWightSlab !== "" && SelectedWightSlab === "1000" ? "chnageBack" : undefined}>{data.row && data.row.w_1000 ? data.row.w_1000 : "-"}</td>*/}
                                            {/*        <td style={{verticalAlign: "middle"}}>{data.row && data.row.validityEndDate ? convertDateISOToCustomiseFormat(data.row.validityEndDate) : "-"}</td>*/}

                                            {/*    </tr>*/}

                                            {/*}*/}


                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>
                        // </div>

                        :
                        // <div className="flights-types1 our_card d-flex flex-wrap justify-content-between aligns-items-center">

                        <div id="three-cards" className="rate-card half-rates">
                            <Progress multi>
                                <Progress striped animated bar color="success" value="60" />
                                <Progress striped animated bar color="danger" value="40" />
                            </Progress>
                            <div className="d-flex flex-wrap align-items-center rates_partition">
                                <div className="destination_origin_app">
                                    <div className="width-new-rates">
                                        <div className="images_destination">
                                            <img
                                                src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.3634671794418056-1637672857658.png"
                                                alt="ocean fright"
                                            />
                                        </div>
                                        <div className="origin_destination_app">

                                            <div className="origin_app">
                                                <p className="small_name">Transit Time</p>
                                                <p className="full_name">{detail}</p>
                                            </div>
                                            <div className="origin_app">
                                                <p className="small_name">Origin</p>
                                                {queryDataForcard.activityType == "Door To Door" ?
                                                    <p className="full_name">{queryDataForcard.originDoor && queryDataForcard.originDoor.pincode ?
                                                        queryDataForcard.originDoor.pincode : "NA"}</p> :
                                                    <p className="full_name">{queryDataForcard.originAirport && queryDataForcard.originAirport.name ?
                                                        queryDataForcard.originAirport.name.split("(")[queryDataForcard.originAirport.name.split("(").length - 2]
                                                        : queryDataForcard.originDoor && queryDataForcard.originDoor.pincode ? queryDataForcard.originDoor.pincode : ""}</p>}
                                            </div>
                                            <div className="origin_app">
                                                <p className="small_name">Destination</p>
                                                {queryDataForcard.activityType == "Door To Door" ?
                                                    <p className="full_name">{queryDataForcard.destinationDoor && queryDataForcard.destinationDoor.pincode ?
                                                        queryDataForcard.destinationDoor.pincode
                                                        : "NA"}</p> :

                                                    <p className="full_name">{queryDataForcard.destinationAirport && queryDataForcard.destinationAirport.name ?
                                                        queryDataForcard.destinationAirport.name.split("(")[queryDataForcard.destinationAirport.name.split("(").length - 2]
                                                        : queryDataForcard.destinationDoor && queryDataForcard.destinationDoor.pincode ?
                                                            queryDataForcard.destinationDoor.pincode : "NA"}</p>}
                                            </div>
                                            <div className="origin_app">
                                                <p className="full_name rate_new_design">
                                                    {data.customerCurrency == "INR" ?
                                                        <FontAwesomeIcon icon={faRupeeSign} size="lg"/> : data.customerCurrency == "USD" ?
                                                            <FontAwesomeIcon icon={faDollarSign} size="lg"/> : data.customerCurrency == "EUR" ?
                                                                <FontAwesomeIcon icon={faEuroSign} size="lg"/> : ""}{" "}
                                                    {this.state.switchCurrency==true?
                                                        this.state.applyCoupan ?
                                                            <>
                                                                {this.state.creditDays === ""? this.state.amountCDiscount  ? parseFloat(this.state.amountCDiscount).toFixed(2) :"":""}
                                                                {this.state.creditDays === "15 Days"? this.state.amountC15Discount  ? parseFloat(this.state.amountC15Discount).toFixed(2) : "":""}
                                                                {this.state.creditDays === "30 Days"? this.state.amountC30Discount  ? parseFloat(this.state.amountC30Discount).toFixed(2) : "":""}
                                                            </> :
                                                            <>
                                                                {this.state.creditDays === ""? data && data.amountC  ? parseFloat(data.amountC).toFixed(2) :"":""}
                                                                {this.state.creditDays === "15 Days"? data && data.amountC15  ? parseFloat(data.amountC15).toFixed(2) : "":""}
                                                                {this.state.creditDays === "30 Days"? data && data.amountC30  ? parseFloat(data.amountC30).toFixed(2) : "":""}
                                                            </>
                                                        :
                                                        this.state.applyCoupan ?
                                                            <>
                                                                {this.state.creditDays === ""?this.state.amountBDiscount  ? parseFloat(this.state.amountBDiscount).toFixed(2) : "":""}
                                                                {this.state.creditDays === "15 Days"?this.state.amountB15Discount  ? parseFloat(this.state.amountB15Discount).toFixed(2) : "":""}
                                                                {this.state.creditDays === "30 Days"?this.state.amountB30Discount  ? parseFloat(this.state.amountB30Discount).toFixed(2) : "":""}
                                                            </> : <>
                                                                {this.state.creditDays === ""?data && data.amountB  ? parseFloat(data.amountB).toFixed(2) : "":""}
                                                                {this.state.creditDays === "15 Days"?data && data.amountB15  ? parseFloat(data.amountB15).toFixed(2) : "":""}
                                                                {this.state.creditDays === "30 Days"?data && data.amountB30  ? parseFloat(data.amountB30).toFixed(2) : "":""}
                                                            </>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="spot_coupon_rates">
                                        <div className="switch_currency">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" name="switchCurrency" checked={this.state.switchCurrency==true ? true : false}
                                                       onChange={(e) => {this.switchCurrency(e)}}
                                                       id=""/>
                                                <label className="form-check-label" htmlFor="">Switch to own Currency</label>
                                            </div>
                                        </div>
                                        <div className="switch_currency need_credit_query">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" onClick={this.creditDaysSTatusFunc}/>
                                                <label className="form-check-label" >Need Credit</label>
                                            </div>
                                            {this.state.creditDaysSTatus === true ?
                                                <select className="" name="creditDays" onChange={this.switchNeedCredit} value={this.state.creditDays}>
                                                    <option value={""}>No Credit</option>
                                                    <option value={"15 Days"}>15 Days</option>
                                                    <option value={"30 Days"}>30 Days</option>
                                                </select>
                                                :""}
                                        </div>
                                        {this.state.applyCoupan ?
                                            <>
                                                <div className="remove_btn_with_value d-flex flex-wrap align-items-stretch mr-2">
                                                    <h6 className="coupon_apply_pop">{this.state.coupanName}</h6>
                                                    <div className="cross_apply_coupan" onClick={e=>{
                                                        e.preventDefault()
                                                        this.removeCoupan().then(res=>{
                                                            if(res.success){
                                                                toast.success("Coupon Removed.", {
                                                                    position: "top-left",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                                this.setState({
                                                                    applyCoupan : false
                                                                },()=>{
                                                                    localStorage.removeItem('afterDiscountCharges')
                                                                    localStorage.removeItem('discountGiven')
                                                                })
                                                            }
                                                        })
                                                    }}><FontAwesomeIcon icon={faTimes} size="lg"/>
                                                    </div>
                                                </div>

                                            </> :<div className="app_couply" onClick={e=>{
                                                e.preventDefault()
                                                localStorage.setItem('amountB',data.amountB)
                                                this.setState({payLarge : true})
                                            }}>
                                                Apply Coupon
                                            </div>}

                                        <div className="app_couply" onClick={e => {
                                            e.preventDefault()
                                            if(length === 1){
                                                this.setState({showCollaps: !this.state.showCollaps})
                                            }else{
                                                this.setState({showCollapsSep: !this.state.showCollapsSep})
                                            }
                                        }}>Freight Summery</div>
                                        <div className="app_couply" onClick={e => {
                                            e.preventDefault()
                                            this.handleClickForSlab()
                                            this.setState({isSlabRateOpen: !this.state.isSlabRateOpen})
                                        }}>Slab</div>
                                    </div>
                                </div>
                                <div className="name_company">
                                    <h5>{data.product}</h5>
                                    <button className="btn company_btn" onClick={(e) => {
                                        e.preventDefault()
                                        let value = this.custDetails()
                                        let typeOfCustomer = value && value.typeOfCust && value.typeOfCust.result && value.typeOfCust.result.customerData && value.typeOfCust.result.customerData.typeOfBussiness ? 'value.typeOfCust.result.customerData.typeOfBussiness' : "Bussiness"
                                        if(this.checkUser()){
                                            if(value.custKycStatus){
                                                this.props.login(true)
                                            }else{
                                                if(typeOfCustomer == "Bussiness"){
                                                    history.push(PAGE_PATHS.CUSTOMER_KYC);
                                                }else{
                                                    history.push(PAGE_PATHS.CUSTOMER_KYC_FOR_INDIVIDUAL);
                                                }
                                                window.location.reload("false")
                                            }
                                            // this.props.login(true)
                                        }else{
                                            this.props.isUserLoggedIn(true)
                                        }}
                                    }>Book Now <FontAwesomeIcon icon={faArrowRight} size="lg"/></button>
                                </div>
                            </div>
                            {length === 1 ?
                                <div id="collapseOne1"
                                     className={this.state.showCollaps === true ? "collapse border-top scroll-freight show " : "collapse border-top"} role="tabpanel">
                                    <div className="d-flex flex-wrap justify-content-between padding-15">
                                        <div className="all-price list-fare">

                                            <table className="table pricess-all">
                                                <tr>
                                                    <th>Charges Name</th>
                                                    <th>Chargeable Weight(KGS)</th>
                                                    <th>Rate</th>
                                                    <th>Total Amount</th>
                                                </tr>

                                                {data.charges !== undefined && queryDataForcard.shipmentMode === "FCL" ?

                                                    data.charges.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</th>
                                                                <td>{item.chargeQty}</td>
                                                                <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                    {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                    {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                                <td>{this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                    </>
                                                                }</td>
                                                            </tr>

                                                        )
                                                    })

                                                    :
                                                    data.charges.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</th>
                                                                <td>{item.chargeQty}</td>
                                                                <td>{item.currency + " " + item.chargeRate}</td>
                                                                <td>{this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                    </>
                                                                }</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {!this.state.applyCoupan ?
                                                    <>
                                                        {data.taxAmountC !== null ?

                                                            <tr>
                                                                <th>GST</th>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + data.taxAmountC != undefined ? parseFloat(data.taxAmountC).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + data.taxAmountC15 != undefined ? parseFloat(data.taxAmountC15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + data.taxAmountC30 != undefined ? parseFloat(data.taxAmountC30).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " + data.taxAmountB != undefined ? parseFloat(data.taxAmountB).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + data.taxAmountB15 != undefined ? parseFloat(data.taxAmountB15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + data.taxAmountB30 != undefined ? parseFloat(data.taxAmountB30).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                                

                                                            </tr>
                                                            
                                                            :
                                                            undefined}
                                                    <tr>
                                                    <th>TOTAL AMOUNT</th>
                                                    <td><strong/></td>
                                                    <td><strong/></td>
                                                    <td>
                                                        <strong>{this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC ).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30 ).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB ).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15 ).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30).toFixed(2) :""}
                                                            </>
                                                        }</strong>
                                                    </td>
                                                </tr>
                                                    </>
                                                    :""}

                                                
                                                    {this.state.applyCoupan ?
                                                        <>
                                                        <tr>
                                                    <th>TOTAL AMOUNT</th>
                                                    <td><strong/></td>
                                                    <td><strong/></td>
                                                    <td>
                                                        <strong>{this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC - data.taxAmountC).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15- data.taxAmountC15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30 - data.taxAmountC30).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB - data.taxAmountB).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15 -data.taxAmountB15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30- data.taxAmountB30).toFixed(2) :""}
                                                            </>
                                                        }</strong>
                                                    </td>
                                                </tr>
                                                        <tr>
                                                        <th>Discount on MRP</th>
                                                        <td><strong/></td>
                                                        <td><strong/></td>
                                                        <td style={{color: '#03a685'}}>
                                                            <strong>{this.state.switchCurrency==true?
                                                                <>
                                                                    {this.state.creditDays === ""? data.baseCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                    {this.state.creditDays === "15 Days"? data.baseCurrency +" " +  parseFloat(this.state.discountAmount15).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"? data.baseCurrency +" " +  parseFloat(this.state.discountAmount30).toFixed(2) :""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.discountAmount).toFixed(2):""}
                                                                    {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount15).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount30).toFixed(2) :""}
                                                                </>
                                                            }</strong>
                                                        </td>
                                                    </tr></> : ""
                                                    }
                                                {this.state.applyCoupan ?
                                                    <>
                                                        {data.taxAmountC !== null ?

                                                            <tr>
                                                                <th>GST</th>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>
                                                                    {/*{this.state.switchCurrency == true ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + this.state.taxAmountCDiscount != undefined ? parseFloat(this.state.taxAmountCDiscount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + this.state.taxAmountC15Discount != undefined ? parseFloat(this.state.taxAmountC15Discount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + this.state.taxAmountC30Discount != undefined ? parseFloat(this.state.taxAmountC30Discount).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " + this.state.taxAmountBDiscount != undefined ? parseFloat(this.state.taxAmountBDiscount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + this.state.taxAmountB15Discount != undefined ? parseFloat(this.state.taxAmountB15Discount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + this.state.taxAmountB30Discount != undefined ? parseFloat(this.state.taxAmountB30Discount).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                                

                                                            </tr>
                                                            :
                                                            undefined}
                                                    </>:""}
                                                {this.state.applyCoupan ?
                                                    <tr>
                                                        <th>Net Payable Amount(After Discount)</th>
                                                        <td><strong/></td>
                                                        <td><strong/></td>
                                                        <td>
                                                            <strong>{this.state.switchCurrency==true?
                                                                <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(this.state.amountCDiscount).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC15Discount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC30Discount).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.amountBDiscount).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB15Discount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB30Discount).toFixed(2) :""}
                                                            </>
                                                            }</strong>
                                                        </td>
                                                    </tr>
                                                    : ""
                                                    }
                                            </table>
                                            <div>
                                                <h3>Awaited Rates : </h3>
                                                <ul>
                                                    {Array.isArray(data.awaitedRates) && data.awaitedRates.length >= 1 ? data.awaitedRates.map(item => {
                                                        return (
                                                            <>
                                                                {item.chargeFor != item.altName ?
                                                                    <li> {item.chargeFor} : {item.altName} </li>
                                                                    :
                                                                    <li> {item.chargeFor} </li>
                                                                }
                                                            </>
                                                        );
                                                    }) : undefined}
                                                </ul>

                                            </div>
                                        </div>
                                        <div className="terms-width list-fare">

                                            <p className="mt-2 "><strong>TERMS & CONDITIONS</strong></p>
                                            {queryDataForcard.queryFor !== "Ocean" ?
                                                <ul>
                                                    <li> Rates are subject to space confirmation from the carrier.</li>
                                                    <li> Rates are valid only for the current week or 7 days whichever
                                                        is earlier.
                                                    </li>
                                                    <li> Rates are only valid for the shipment details shared in the
                                                        query only, any changes will require rate revalidation.
                                                    </li>
                                                    <li> Rates are applicable for general cargo only.</li>
                                                    <li> All inclusive (ALL-IN) rates quoted through instant rate quotes
                                                        includes Air Freight, Fuel Surcharge, Security Surcharge, X-ray
                                                        charges, Misc Charges, AMS charges. Any other charges must be
                                                        considered as additional.
                                                    </li>
                                                    <li> For any special cargo, the instant live rates are not
                                                        applicable, and will be quoted separately.
                                                    </li>
                                                    <li> Transit time mentioned is purely indicative and should be
                                                        considered as tentative.
                                                    </li>
                                                    <li> Government Taxes (if any) shall be applicable as per Government
                                                        norms.
                                                    </li>
                                                </ul>
                                                :
                                                <ul>
                                                    <li> Booking requests are strictly subject to space and equipment
                                                        availability and confirmation.
                                                    </li>
                                                    <li> Instant rates quoted are applicable for general commodities
                                                        only, with no special handling requirements.
                                                    </li>
                                                    <li> Rates are valid for the shipment details shared in the query.
                                                    </li>
                                                    <li> Shipping Line surcharges are subject to change, unless fixed
                                                        within the quotation validity.
                                                    </li>
                                                    <li> For any special cargo, the instant live rates are not
                                                        applicable. The price quotes shall be provided within 48-72
                                                        hours during the carriers’ working days.
                                                    </li>
                                                    <li> Transit time mentioned are indicative based on the carriers’
                                                        information and should be considered as tentative.
                                                    </li>
                                                    <li> Government Taxes (if any) shall be applicable as per Government
                                                        norms.
                                                    </li>
                                                </ul>

                                            }
                                            <p className="mt-2" style={{}}><strong>Remarks</strong></p>
                                            <ul className="mb-2">
                                            {data.remarksDestination && data.remarksDestination.map((item,i)=>{
                                                    return(
                                                        <li> {item.rem !== null ? item.rem.label : ""}</li>
                                                    );
                                                })
                                                }
                                                {data.groupNameRemarks && data.groupNameRemarks.map((item,i)=>{
                                                    return(
                                                        <li> {item}</li>
                                                    );
                                                })
                                                }
                                                <li> {data.remarks !== "" ? data.remarks : undefined}</li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                :
                                <div id="collapseOne1" className={this.state.showCollapsSep === true ? "collapse border-top show " : "collapse border-top"}
                                     role="tabpanel">
                                    <div className="d-flex flex-wrap justify-content-between padding-15">
                                        <div className="all-price list-fare">

                                            <table className="table pricess-all">
                                                <tr>
                                                    <th>Charges Name</th>
                                                    <th>Chargeable Weight(KGS)</th>
                                                    <th>Rate</th>
                                                    <th>Total Amount</th>
                                                </tr>

                                                {data.charges !== undefined && queryDataForcard.shipmentMode === "FCL" ?

                                                    data.charges.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</th>
                                                                <td>{item.chargeQty}</td>
                                                                <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                    {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                    {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                                <td>{this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                    </>
                                                                }</td>
                                                            </tr>

                                                        )
                                                    })

                                                    :
                                                    data.charges.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</th>
                                                                <td>{item.chargeQty}</td>
                                                                <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                    {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                    {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                                <td>{this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                    </>
                                                                }</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {!this.state.applyCoupan ?
                                                    <>
                                                        {data.taxAmountC !== null ?

                                                            <tr>
                                                                <th>GST</th>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + data.taxAmountC != undefined ? parseFloat(data.taxAmountC).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + data.taxAmountC15 != undefined ? parseFloat(data.taxAmountC15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + data.taxAmountC30 != undefined ? parseFloat(data.taxAmountC30).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " + data.taxAmountB != undefined ? parseFloat(data.taxAmountB).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + data.taxAmountB15 != undefined ? parseFloat(data.taxAmountB15).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + data.taxAmountB30 != undefined ? parseFloat(data.taxAmountB30).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                                

                                                            </tr>
                                                            :
                                                            undefined}
                                                    </>:""}

                                                <tr>
                                                    <th>TOTAL AMOUNT</th>
                                                    <td><strong/></td>
                                                    <td><strong/></td>
                                                    <td>
                                                        <strong>{this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30).toFixed(2) :""}
                                                            </>
                                                        }</strong>
                                                    </td>
                                                </tr>
                                                {this.state.applyCoupan ?
                                                    <>
                                                        <tr>
                                                            <th>Discount on MRP</th>
                                                            <td><strong/></td>
                                                            <td><strong/></td>
                                                            <td style={{color: '#03a685'}}>
                                                                <strong>{this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.baseCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                        {this.state.creditDays === "15 Days"? data.baseCurrency +" " +  parseFloat(this.state.discountAmount15).toFixed(2) :""}
                                                                        {this.state.creditDays === "30 Days"? data.baseCurrency +" " +  parseFloat(this.state.discountAmount30).toFixed(2) :""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.discountAmount).toFixed(2):""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount15).toFixed(2) :""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount30).toFixed(2) :""}
                                                                    </>
                                                                }</strong>
                                                            </td>
                                                        </tr></> : ""
                                                }
                                                {this.state.applyCoupan ?
                                                    <>
                                                        {data.taxAmountC !== null ?

                                                            <tr>
                                                                <th>GST</th>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>
                                                                    {/*{this.state.switchCurrency == true ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                                    {this.state.switchCurrency==true?
                                                                        <>
                                                                            {this.state.creditDays === ""? data.customerCurrency +" " + this.state.taxAmountCDiscount != undefined ? parseFloat(this.state.taxAmountCDiscount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " + this.state.taxAmountC15Discount != undefined ? parseFloat(this.state.taxAmountC15Discount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " + this.state.taxAmountC30Discount != undefined ? parseFloat(this.state.taxAmountC30Discount).toFixed(2) : "":""}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {this.state.creditDays === ""?data.baseCurrency +" " + this.state.taxAmountBDiscount != undefined ? parseFloat(this.state.taxAmountBDiscount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " + this.state.taxAmountB15Discount != undefined ? parseFloat(this.state.taxAmountB15Discount).toFixed(2) : "":""}
                                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " + this.state.taxAmountB30Discount != undefined ? parseFloat(this.state.taxAmountB30Discount).toFixed(2) : "":""}
                                                                        </>
                                                                    }
                                                                </td>
                                                                

                                                            </tr>
                                                            :
                                                            undefined}
                                                    </>:""}
                                                {this.state.applyCoupan ?
                                                    <tr>
                                                        <th>Net Payable Amount(After Discount)</th>
                                                        <td><strong/></td>
                                                        <td><strong/></td>
                                                        <td>
                                                            <strong>{this.state.switchCurrency==true?
                                                                <>
                                                                    {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(this.state.amountCDiscount).toFixed(2) :""}
                                                                    {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC15Discount).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC30Discount).toFixed(2) :""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.amountBDiscount).toFixed(2):""}
                                                                    {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB15Discount).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB30Discount).toFixed(2) :""}
                                                                </>
                                                            }</strong>
                                                        </td>
                                                    </tr>
                                                    : ""
                                                }
                                            </table>
                                            <div>
                                                <h3><strong>Awaited Rates :</strong></h3>
                                                <ul>
                                                    {Array.isArray(data.awaitedRates) && data.awaitedRates.length >= 1 ? data.awaitedRates.map(item => {
                                                        return (
                                                            <>
                                                                {item.chargeFor != item.altName ?
                                                                    <li> {item.chargeFor} : {item.altName} </li>
                                                                    :
                                                                    <li> {item.chargeFor} </li>
                                                                }
                                                            </>
                                                        );
                                                    }) : undefined}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="terms-width list-fare">

                                            <p className="mt-2 "><strong>TERMS & CONDITIONS</strong></p>
                                            {queryDataForcard.queryFor !== "Ocean" ?
                                                <ul>
                                                    <li> Rates are subject to space confirmation from the carrier.</li>
                                                    <li> Rates are valid only for the current week or 7 days whichever
                                                        is earlier.
                                                    </li>
                                                    <li> Rates are only valid for the shipment details shared in the
                                                        query only, any changes will require rate revalidation.
                                                    </li>
                                                    <li> Rates are applicable for general cargo only.</li>
                                                    <li> All inclusive (ALL-IN) rates quoted through instant rate quotes
                                                        includes Air Freight, Fuel Surcharge, Security Surcharge, X-ray
                                                        charges, Misc Charges, AMS charges. Any other charges must be
                                                        considered as additional.
                                                    </li>
                                                    <li> For any special cargo, the instant live rates are not
                                                        applicable, and will be quoted separately.
                                                    </li>
                                                    <li> Transit time mentioned is purely indicative and should be
                                                        considered as tentative.
                                                    </li>
                                                    <li> Government Taxes (if any) shall be applicable as per Government
                                                        norms.
                                                    </li>
                                                </ul>
                                                :
                                                <ul>
                                                    <li> Booking requests are strictly subject to space and equipment
                                                        availability and confirmation.
                                                    </li>
                                                    <li> Instant rates quoted are applicable for general commodities
                                                        only, with no special handling requirements.
                                                    </li>
                                                    <li> Rates are valid for the shipment details shared in the query.
                                                    </li>
                                                    <li> Shipping Line surcharges are subject to change, unless fixed
                                                        within the quotation validity.
                                                    </li>
                                                    <li> For any special cargo, the instant live rates are not
                                                        applicable. The price quotes shall be provided within 48-72
                                                        hours during the carriers’ working days.
                                                    </li>
                                                    <li> Transit time mentioned are indicative based on the carriers’
                                                        information and should be considered as tentative.
                                                    </li>
                                                    <li> Government Taxes (if any) shall be applicable as per Government
                                                        norms.
                                                    </li>
                                                </ul>

                                            }
                                            <p className="mt-2" style={{}}><strong>Remarks</strong></p>
                                            <ul className="mb-2">
                                            {data.remarksDestination && data.remarksDestination.map((item,i)=>{
                                                    return(
                                                        <li> {item.rem !== null ? item.rem.label : ""}</li>
                                                    );
                                                })
                                                }
                                                <li> {data.remarks !== "" ? data.remarks : undefined}</li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            }

                            <div id="collapseOne2" className={this.state.showCollapsForRate === true ? "collapse border-top show" : "collapse border-top"} role="tabpanel"
                                 aria-labelledby="headingOne2"
                                 data-parent="#accordionEx">
                                <div className="d-flex flex-wrap justify-content-between padding-15">
                                    <div className="list-fare pt-3" style={{width:"100%"}}>
                                        <p className="mb-3"><strong>All Equipment Rate</strong></p>
                                        <table className="table table-bordered text-center table-responsive-md">
                                            <tr style={{background: "#eaeaea"}}>
                                                <th>Shipping Line Name</th>
                                                <th>LCL (Per CBM /USD)</th>
                                                <th>20 STD (USD)</th>
                                                <th>40 STD (USD)</th>
                                                <th>40 HC (USD)</th>
                                                <th>VALIDITY</th>
                                                <th hidden={false}>Action</th>
                                            </tr>

                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>

                        // </div>
                    }
                </>
            </>
        )}

}



export default GradientWithRadialProgressCard;
