export const getUserAuthToken = function() {

  if (localStorage.getItem("already_registered") === null) {
    return null;
  }

  if (localStorage.getItem("already_registered") === "true")
  {
    return localStorage.getItem("auth_key");
  } else {
    return null;
  }
};
