import React, { Component, lazy, Suspense } from "react";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import Loader from "./components/loader/Loader";
import MainLayoutRouteWithManagers from "./layout/mainRoutesWithManagers";
import MainRoutesManagerWithoutAuth from "./layout/MainRoutesManagerWithoutAuth";
import MainRoutesManagerForProfileWithoutAuth from "./layout/MainRoutesManagerForProfileWithoutAuth";
import MainRoutesManagerWithoutAuthWithoutLayout from "./layout/MainRoutesManagerWithoutAuthWithoutLayout";
import FormStart from "./views/app/dashboards/FormStart";
import NewUserQuery from "./views/app/dashboards/NewUserQuery";
import NewUserQuery1 from "./views/app/dashboards/NewUserQuery1";
import TrackingPage from "./views/app/dashboards/TrackingPage";
import EdocketPdfView from "./views/app/Edocket/EdocketPdfView";
import CourierBookingView from "./views/app/mawb/CourierBookingView";
import CourierNewQuery from "./views/app/dashboards/courierNewQuery";
import RatesQueryPreview from "./components/cards/RatesQueryPreview";
import RatesQueryPreviewCourier from "./components/cards/RatesQueryPreviewCourier";
import TrackingSerachPage from "./views/app/dashboards/TrackingSerachPage";
import OceanTickerView from "./views/app/dashboards/OceanTickerView";
import AirTickerView from "./views/app/dashboards/AirTickerView";
import demoTicker from "./views/app/dashboards/demoTicker";
import ChatBotQuery from "./views/app/dashboards/ChatBotQuery";
import roadNewQuery from "./views/app/dashboards/roadNewQuery";
import AirFreight from "./views/app/dashboards/AirFreight";
import OceanFreight from "./views/app/dashboards/OceanFreight";
import RoadFreight from "./views/app/dashboards/RoadFreight";
import CourierFreight from "./views/app/dashboards/CourierFreight";
import CustomsBrokerage from "./views/app/dashboards/CustomsBrokerage ";
import WarehousingSolutions from "./views/app/dashboards/WarehousingSolutions ";
import OtherServices from "./views/app/dashboards/OtherServices";
import PackagingServices from "./views/app/dashboards/PackagingServices";
import NewsGrid from "./views/app/dashboards/NewsGrid";
import TestimonialsReviews from "./views/app/dashboards/testimonialsReviews";
import CareerPage from "./views/app/dashboards/CareerPage";
import PostResume from "./views/app/dashboards/PostResume";
import FaqPage from "./views/app/dashboards/faqPage";
import OurTeam from "./views/app/dashboards/OurTeam";
import LifeAtZipaworld from "./views/app/dashboards/LifeAtZipaworld";
import ExporterPage from "./views/app/dashboards/exporterPage";
import BannerDesign from "./views/app/dashboards/BannerDesign";
import NewHomePage from "./views/app/dashboards/NewHomePage";
import BlogPage from "./views/app/dashboards/BlogPage";
import BlogDescription from "./views/app/dashboards/BlogDescription";
import SeprateNews from "./views/app/dashboards/SeprateNews";
import Offer from "./views/app/dashboards/Offer";
import Pricing from "./views/app/dashboards/Pricing";
import DocManager from "./views/app/dashboards/docManager";
import DemoVedio from "./views/app/dashboards/DemoVedio";
import DriverDetails from "./views/app/Edocket/DriverDetails";
import DriverDetailsDemo from "./views/app/Edocket/DriverDetailsDemo";
import NewRatesScreen from "./components/cards/NewRatesScreen";
import DownLoadApp from "./views/app/dashboards/DownLoadApp";
import MainRoutesNew from "./layout/MainRoutesNew";
import AwardsAndRecognition from "./views/app/dashboards/AwardsAndRecognition";
import ViewAir from "./views/app/dashboards/Air";
import ViewOcean from "./views/app/dashboards/Ocean";
import ViewCourier from "./views/app/dashboards/Courier";
import ViewRoad from "./views/app/dashboards/Road";
import ViewOther from "./views/app/dashboards/Others";
import Support from "./views/app/dashboards/Support";
import VesselSchedulePage from "./views/app/dashboards/VesselSchedulePage";
import InvoiceReaderField from "./views/Profile/InvoiceReaderField";
import ErrorPage from "./views/Profile/Error";
import PaymentPopUp from "./views/app/Invoice/PaymentPopUp";
import PayNowForIndividual from "./views/app/Invoice/PayNowForIndividual";
import BlogPageOther from "./views/app/dashboards/BlogPageOther";
// import TrackingSerachPageDemoPage from "./views/app/dashboards/TrackingSearchPageDemoPage";
// import TrackingSearchPageDemoPage from "./views/app/dashboards/TrackingSearchPageDemoPage";
import TrackingNewPage from "./views/app/dashboards/TrackingNewPage";
import TrackingRoadPage from "./views/app/dashboards/TrackingRoadPage";
import TrackingAirAndOceanPage from "./views/app/dashboards/TrackingAirAndOceanPage";
import TrackingSearchPageDemoPage from "./views/app/dashboards/TrackingSearchPageDemoPage";
import ExhibitionLandingPage from "./views/app/dashboards/ExhibitionLandingPage";
import About from "./views/app/dashboards/About";
import FullLoader from "./components/loader/FullLoader";
const ViewMain = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/app/dashboards/dashBoard2")), 100);
  });
});
const LandingICICI = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/app/dashboards/ZipaworldICICI")), 100);
  });
});
const LandingMainPage = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/app/dashboards/BannerDesign")), 100);
  });
});
const ForgetPassword = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/user/forgetPassword")), 100);
  });
});

const AboutUs = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/user/AboutUs")), 100);
  });
});

const ReviewPage = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/app/dashboards/Review")), 100);
  });
});

const ResetPassword = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/user/ResetPassword")), 100);
  });
});

const ViewShipHistory = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/ShipmentHistory/shipmentHistory")), 200);
  });
});

const ViewShipHistoryCourier = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/ShipmentHistory/shipmentHistoryCourier")), 200);
  });
});

const ViewShipHistoryRoad = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/ShipmentHistory/ShipmentHistoryRoad")), 200);
  });
});

const ViewManagerPreview = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/ShipmentHistory/queryManagerPreview")), 200);
  });
});

const ViewShipHistoryAir = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/ShipmentHistory/shipmentHistoryAir")), 200);
  });
});

const ShipperManager = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/ShipperCosignee/shipperConsigneeApprovalRequest")), 100);
  });
});

const AddshipperRequest = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/ShipperCosignee/addShipperRequest")), 100);
  });
});

const AddConsigneeRequest = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/ShipperCosignee/addConsigneeRequest")), 100);
  });
});

const ShipperBranchAdd = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/ShipperCosignee/requestShipperBranch")), 100);
  });
});

const ConsigneeBranchAdd = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/ShipperCosignee/requestConsigneeBranch")), 100);
  });
});

const CustomerKyc = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/Profile/CustomerKycForm")), 100);
  });
});

const CustomerKycFormForIndividuals = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/Profile/CustomerKycFormForIndividuals")), 100);
  });
});

const FreightManagement = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/Profile/FreightManagement")), 100);
  });
});

const AboutData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/About")), 100);
  });
});

const FaqData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/Faq")), 100);
  });
});

const StaticsData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/StaticsPage")), 100);
  });
});

const MissionData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/Mission")), 100);
  });
});

const VisionData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/Vision")), 100);
  });
});

const ValuesData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/Values")), 100);
  });
});

const ProductsData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/Products")), 100);
  });
});

const SpotRateData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/app/dashboards/spotRateModal")), 100);
  });
});

const SpotRateDataOther = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./views/app/dashboards/spotRateModalOther")), 100);
  });
});

const SpotRateAirData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/spotRateAirModal")), 100);
  });
});
const SpotRateAirViewPage = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/spotRateAirView")), 100);
  });
});
const NewsData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/News")), 100);
  });
});

const TermsAncConditionData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/TermsAndCondition")), 100);
  });
});

const PrivacyData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import(  "./views/app/dashboards/PrivacyPolicy")), 100);
  });
});

const ContactData = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/dashboards/Contact")), 100);
  });
});



const InvoiceManager = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Invoice/invoiceManger")), 100);
  });
});

const ViewInvoiceManager = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Invoice/InvoicePdfViewForManager")), 100);
  });
});

const PayNowInvoice = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Invoice/PayNowInvoice")), 100);
  });
});

const Performa = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Invoice/FinaleInvoicePdfView")), 100);
  });
});

const PerformaNew = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Invoice/PerformaInvoicePdfViewNew")), 100);
  });
});

const ProformaInvoiceNewCourier = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Invoice/ProformaInvoiceNewCourier")), 100);
  });
});

const AirEditBookingHoc = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Invoice/AirEditBookingHoc")), 100);
  });
});

const PerformaNewTest = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Invoice/PerformaInvoicePdfViewNewTesting")), 100);
  });
});

const MawbPdf = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/mawb/mawbView")), 100);
  });
});

const AirMawbView = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/mawb/airMawbView")), 100);
  });
});

const HouseMawbView = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/mawb/houseMawbView")), 100);
  });
});

const MawbEdit = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/mawb/mawbEdit")), 100);
  });
});

const MawbViewCustomer = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import(  "./views/app/mawb/mawbViewForCustomer")), 100);
  });
});
const BookingCourierIndividual = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import(  "./views/app/Invoice/PayNowForIndividual")), 100);
  });
});
const Edocketmanager = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/Edocket/EdocketManagerNew")), 100);
  });
});

const HouseView = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/hawb/hawbView")), 100);
  });
});

const HouseViewFinal = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/hawb/hawbViewFinal")), 100);
  });
});
const HouseViewFinalOcean= lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/hawb/HawbViewOcean")), 100);
  });
});
const ProfilePage = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/Profile/ProfileView")), 100);
  });
});

const LandingPageHoc = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/landingPage/LandingPageHoc")), 100);
  });
});

const HawbEdit = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/hawb/hawbEdit")), 100);
  });
});

const AirHblEdit = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/ShipementHistory/AirHblEdit")), 100);
  });
});

const AirWBEdit = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/app/ShipementHistory/AwbEdit")), 100);
  });
});
const ConsigneeBranchView = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/ShipperCosignee/ViewConsigneeBranch")), 100);
  });
});
const ShipperBranchView = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import( "./views/ShipperCosignee/ViewShipperBranch")), 100);
  });
});

class Routers extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
              exact={true}
              path="/"
              // component={BannerDesign}
              // render={(props) => <BannerDesign {...props} />}
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <ViewMain {...matchprops} />
                  </Suspense>
              )}
          />
          <Route
              exact={true}
              path="/Zipaworld-ICICI"
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <LandingICICI {...matchprops} />
                  </Suspense>
              )}
          />

          <Route
                exact={false}
                path="/Home"
                // authUser={loginUser}
                // component={ViewMain}
                render={(matchprops) => (
                    <Suspense fallback={<Loader />}>
                      <ViewMain {...matchprops} />
                    </Suspense>
                )}
                // render={(props) => <ViewMain {...props} />

              /> 
          {/* <Route
                exact={true}
                path="/Home2"
                // authUser={loginUser}
                component={ViewMainDummy}
                render={(props) => <ViewMainDummy {...props} />}
              /> */}
    
              <Route
                exact={false}
                path="/ForgetPassword"
                component={ForgetPassword}
                render={(props) => <ForgetPassword {...props} />}
              />
    
              <Route
                exact={false}
                path="/AboutUs"
                component={AboutUs}
                render={(props) => <AboutUs {...props} />}
              />
               <Route
                exact={false}
                path="/ResetPassword"
                component={ResetPassword}
                render={(props) => <ResetPassword {...props} />}
              />
    
              <Route
                exact={false}
                path="/ReviewPage"
                component={ReviewPage}
                render={(props) => <ReviewPage {...props} />}
              />
              <Route
                exact={false}
                path="/Tracking"
                component={TrackingPage}
                render={(props) => <TrackingPage {...props} />}
              />
               <Route
                exact={false}
                path="/LandingPage"
                component={BannerDesign}
                render={(props) => <BannerDesign {...props} />}
              />

              <Route
                exact={false}
                path="/docManager"
                component={DocManager}
                render={(props) => <DocManager {...props} />}
              />
              
          <Route
                exact={false}
                path="/BannerDesign"
                component={BannerDesign}
                render={(props) => <BannerDesign {...props} />}
              />
          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/ShipmentHistory"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
              <ViewShipHistory {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/ShipmentHistoryCourier"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ViewShipHistoryCourier {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/ShipmentHistoryRoad"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ViewShipHistoryRoad {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/QueryManagerPreview"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ViewManagerPreview {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/ShipmentHistoryAir"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ViewShipHistoryAir {...matchprops} />
              </Suspense>
            )}
          />

          {/*<MainLayoutRouteWithManagers*/}
          {/*    exact*/}
          {/*    isPrivate={true}*/}
          {/*    path="/ShipmentHistoryAir"*/}
          {/*    render={matchprops => (*/}
          {/*        <Suspense fallback={<div className="loading" />}>*/}

          {/*            <ViewShipHistory {...matchprops} />*/}
          {/*        </Suspense>*/}
          {/*    )}*/}
          {/*/>*/}

          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/TrackingPage"
            render={(matchprops) => (
              <Suspense fallback={<div className="loading" />}>
                <ViewShipHistory {...matchprops} />
              </Suspense>
            )}
          />

          <MainRoutesNew
            exact
            isPrivate={true}
            path="/ShipperConsigneeManager"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ShipperManager {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/AddShipperRequest"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <AddshipperRequest {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/AddConsigneeRequest"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <AddConsigneeRequest {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            exact={false}
            isPrivate={true}
            path="/RequestShipperBranch"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ShipperBranchAdd {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            exact={false}
            isPrivate={true}
            path="/RequestConsigneeBranch"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ConsigneeBranchAdd {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerForProfileWithoutAuth
            exact
            isPrivate={true}
            path="/CustomerKYC"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                {/*<div className="image-change">*/}
                <CustomerKyc {...matchprops} />
                {/*</div>*/}
              </Suspense>
            )}
          />
          <MainRoutesManagerForProfileWithoutAuth
            exact
            isPrivate={true}
            path="/CustomerKYCForIndividuals"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                {/*<div className="image-change">*/}
                <CustomerKycFormForIndividuals {...matchprops} />
                {/*</div>*/}
              </Suspense>
            )}
          />
          <MainRoutesManagerForProfileWithoutAuth
            exact
            isPrivate={true}
            path="/FreightManagement"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                {/*<div className="image-change">*/}
                <FreightManagement {...matchprops} />
                {/*</div>*/}
              </Suspense>
            )}
          />

          {/*<MainRoutesManagerWithoutAuth*/}
          {/*  exact*/}
          {/*  isPrivate={true}*/}
          {/*  path="/About"*/}
          {/*  render={(matchprops) => (*/}
          {/*    <Suspense fallback={<Loader />}>*/}
          {/*      <AboutData {...matchprops} />*/}
          {/*    </Suspense>*/}
          {/*  )}*/}
          {/*/>*/}
          <Route
              exact={false}
              path="/About"
              component={About}
              render={(props) => <About {...props} />}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/Faq"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <FaqData {...matchprops} />
              </Suspense>
            )}
          />
          {/*<MainRoutesManagerWithoutAuth*/}
          {/*  exact*/}
          {/*  isPrivate={true}*/}
          {/*  path="/Statics"*/}
          {/*  render={(matchprops) => (*/}
          {/*    <Suspense fallback={<Loader />}>*/}
          {/*      <StaticsData {...matchprops} />*/}
          {/*    </Suspense>*/}
          {/*  )}*/}
          {/*/>*/}
          <Route
              exact
              isPrivate={true}
              path="/Statics"
              component={StaticsData}
              render={(props) => <StaticsData {...props} />}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/Mission"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <MissionData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/Vision"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <VisionData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/Values"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ValuesData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/Products"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ProductsData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/News"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <NewsData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/TermsCondition"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <TermsAncConditionData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/Privacy"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <PrivacyData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/Contact"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ContactData {...matchprops} />
              </Suspense>
            )}
          />

          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/SpotRatePage"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <SpotRateData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
              isPrivate={true}
              path="/SpotRateView"
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <SpotRateDataOther {...matchprops} />
                  </Suspense>
              )}
          />
          <MainRoutesManagerWithoutAuth
            exact
            isPrivate={true}
            path="/SpotRateAirPage"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <SpotRateAirData {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuth
              isPrivate={true}
              path="/SpotRateAirView"
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <SpotRateAirViewPage {...matchprops} />
                  </Suspense>
              )}
          />

          <MainLayoutRouteWithManagers
            exact
            isPrivate={true}
            path="/InvoiceManager"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <InvoiceManager {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/InvoiceManagerView"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ViewInvoiceManager {...matchprops} />
              </Suspense>
            )}
          />
          {/*<MainLayoutRouteWithManagers
            isPrivate={true}
            path="/payNow"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <PayNowInvoice {...matchprops} />
              </Suspense>
            )}
          />*/}
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/ProformaInvoice"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <Performa {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/ProformaInvoiceNew"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <PerformaNew {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/ProformaInvoiceNewCourier"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ProformaInvoiceNewCourier {...matchprops} />
              </Suspense>
            )}
          />
          {/*<MainLayoutRouteWithManagers*/}
          {/*    isPrivate={true}*/}
          {/*    path="/aireditbooking"*/}
          {/*    render={matchprops => (*/}
          {/*        <Suspense fallback={<div className="loading" />}>*/}
          {/*            <AirEditBooking {...matchprops} />*/}
          {/*        </Suspense>*/}
          {/*    )}*/}
          {/*/>*/}
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/aireditbooking"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <AirEditBookingHoc {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/OceanInvoiceNew"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <PerformaNewTest {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/MawbView"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <MawbPdf {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/AirMawbView"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <AirMawbView {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/HouseMawbView"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <HouseMawbView {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerForProfileWithoutAuth
            isPrivate={true}
            path="/MawbEdit"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <MawbEdit {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/MawbViewCustomer"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <MawbViewCustomer {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/DriverDetails"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <DriverDetails {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesNew
            isPrivate={true}
            path="/eDocketManager"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <Edocketmanager {...matchprops} />
              </Suspense>
            )}
          />
          {/*<Route*/}
          {/*    isPrivate={true}*/}
          {/*    path="/eDocketManager"*/}
          {/*    component={NewUserQuery}*/}
          {/*    render={(matchprops) => (*/}
          {/*        <Suspense fallback={<Loader />}>*/}
          {/*          <Edocketmanager {...matchprops} />*/}
          {/*        </Suspense>*/}
          {/*    )}*/}
          {/*/>*/}
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/houseView"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <HouseView {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/houseViewFinal"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <HouseViewFinal {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
              isPrivate={true}
              path="/HouseViewFinalOcean"
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <HouseViewFinalOcean {...matchprops} />
                  </Suspense>
              )}
          />

          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/ProfilePage"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ProfilePage {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/hawbEdit"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <HawbEdit {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/Airhbledit"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <AirHblEdit {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            isPrivate={true}
            path="/Editawb"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <AirWBEdit {...matchprops} />
              </Suspense>
            )}
          />
          <MainRoutesManagerWithoutAuthWithoutLayout
            isPrivate={false}
            path="/LandingPages"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <LandingPageHoc {...matchprops} />
              </Suspense>
            )}
          />

          <Route
            exact={false}
            path="/StartingForm"
            component={FormStart}
            render={(props) => <FormStart {...props} />}
          />

          <Route
            exact={false}
            path="/NewUserQueryForm"
            component={NewUserQuery}
            render={(props) => <NewUserQuery {...props} />}
          />
          <Route
            exact={false}
            path="/NewUserQueryForms"
            component={NewUserQuery1}
            render={(props) => <NewUserQuery1 {...props} />}
          />

          <Route
            exact={false}
            path="/Tracking"
            component={TrackingPage}
            render={(props) => <TrackingPage {...props} />}
          />
          <Route
            exact={false}
            path="/EdocketPdfView"
            component={EdocketPdfView}
            render={(props) => <EdocketPdfView {...props} />}
          />

          <Route
            exact={false}
            path="/CourierBookingView"
            component={CourierBookingView}
            render={(props) => <CourierBookingView {...props} />}
          />

          <Route
            exact={false}
            path="/CourierNewQuery"
            component={CourierNewQuery}
            render={(props) => <CourierNewQuery {...props} />}
          />

          <Route
            exact={false}
            path="/RatesQueryPreview"
            component={RatesQueryPreview}
            render={(props) => <RatesQueryPreview {...props} />}
          />

          <Route
            exact={false}
            path="/RatesQueryPreviewCourier"
            component={RatesQueryPreviewCourier}
            render={(props) => <RatesQueryPreviewCourier {...props} />}
          />
          <Route
            exact={false}
            path="/TrackingSearchPage"
            component={TrackingSerachPage}
            render={(props) => <TrackingSerachPage {...props} />}
          />

          <Route
            exact={false}
            path="/OceanTickerView"
            component={OceanTickerView}
            render={(props) => <OceanTickerView {...props} />}
          />

          <Route
            exact={false}
            path="/AirTickerView"
            component={AirTickerView}
            render={(props) => <AirTickerView {...props} />}
          />

          <Route
            exact={false}
            path="/demoTicker"
            component={demoTicker}
            render={(props) => <demoTicker {...props} />}
          />
          <Route
            exact={false}
            path="/ChatBot"
            component={ChatBotQuery}
            render={(props) => <ChatBotQuery {...props} />}
          />

          <Route
            exact={false}
            path="/RoadNewQuery"
            component={roadNewQuery}
            render={(props) => <roadNewQuery {...props} />}
          />

          <Route
            exact={false}
            path="/AirFreight"
            component={AirFreight}
            render={(props) => <AirFreight {...props} />}
          />

          <Route
            exact={false}
            path="/OceanFreight"
            component={OceanFreight}
            render={(props) => <OceanFreight {...props} />}
          />

          <Route
            exact={false}
            path="/RoadFreight"
            component={RoadFreight}
            render={(props) => <RoadFreight {...props} />}
          />

          <Route
            exact={false}
            path="/CourierFreight"
            component={CourierFreight}
            render={(props) => <CourierFreight {...props} />}
          />

          <Route
            exact={false}
            path="/CustomsBrokerage"
            component={CustomsBrokerage}
            render={(props) => <CustomsBrokerage {...props} />}
          />
          <Route
            exact={false}
            path="/WarehousingSolutions"
            component={WarehousingSolutions}
            render={(props) => <WarehousingSolutions {...props} />}
          />

          <Route
            exact={false}
            path="/TradeFinance"
            component={OtherServices}
            render={(props) => <OtherServices {...props} />}
          />

          <Route
            exact={false}
            path="/PackagingServices"
            component={PackagingServices}
            render={(props) => <PackagingServices {...props} />}
          />

          <Route
            exact={false}
            path="/OurNews"
            component={NewsGrid}
            render={(props) => <NewsGrid {...props} />}
          />

          <Route
            exact={false}
            path="/TestimonialsSection"
            component={TestimonialsReviews}
            render={(props) => <TestimonialsReviews {...props} />}
          />

          <Route
            exact={false}
            path="/CareerPage"
            component={CareerPage}
            render={(props) => <CareerPage {...props} />}
          />

          <Route
            exact={false}
            path="/PostResume"
            component={PostResume}
            render={(props) => <PostResume {...props} />}
          />

          <Route
            exact={false}
            path="/FaqPage"
            component={FaqPage}
            render={(props) => <FaqPage {...props} />}
          />

          <Route
            exact={false}
            path="/OurTeam"
            component={OurTeam}
            render={(props) => <OurTeam {...props} />}
          />
          <Route
            exact={false}
            path="/LifeAtZipaworld"
            component={LifeAtZipaworld}
            render={(props) => <LifeAtZipaworld {...props} />}
          />

          <Route
            exact={false}
            path="/exporterPage"
            component={ExporterPage}
            render={(props) => <ExporterPage {...props} />}
          />

          <Route
            exact={false}
            path="/LandingPage"
            component={BannerDesign}
            render={(props) => <BannerDesign {...props} />}
          />

          <Route
            exact={false}
            path="/NewHomePage"
            component={NewHomePage}
            render={(props) => <NewHomePage {...props} />}
          />

            <Route
                exact={false}
                path="/BlogPage"
                component={BlogPage}
                render={(props) => <BlogPage {...props} />}
            />

            <Route
                exact={false}
                path="/BlogDescription"
                component={BlogDescription}
                render={(props) => <BlogDescription {...props} />}
            />
            <Route
                exact={false}
                path="/SeparateNews"
                component={SeprateNews}
                render={(props) => <SeprateNews {...props} />}
            />
          <Route
              exact
              path="/OfferMy"
              component = {Offer}
              render={(props) => <Offer {...props} />}
          />
          <Route
              exact
              path="/Demo"
              component = {DemoVedio}
              render={(props) => <DemoVedio {...props} />}
          />
          <Route
              exact
              path="/NewRatesScreen"
              component = {NewRatesScreen}
              render={(props) => <NewRatesScreen {...props} />}
          />
          <MainLayoutRouteWithManagers
              isPrivate={true}
              path="/DriverDetailsDemo"
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <DriverDetailsDemo {...matchprops} />
                  </Suspense>
              )}
          />
          <Route
              exact
              path="/Loader"
              component = {Loader}
              render={(props) => <Loader {...props} />}
          />
          <Route
              exact
              path="/DownloadApp"
              component = {DownLoadApp}
              render={(props) => <DownLoadApp {...props} />}
          />
          {/*<MainRoutesNew*/}
          {/*    exact*/}
          {/*    path="/AwardsAndRecognition"*/}
          {/*    component = {AwardsAndRecognition}*/}
          {/*    render={(props) => <AwardsAndRecognition {...props} />}*/}
          {/*/>*/}
          <Route
              exact
              isPrivate={true}
              path="/AwardsAndRecognition"
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <AwardsAndRecognition {...matchprops} />
                  </Suspense>
              )}
          />
          <Route
              exact
              path="/Pricing"
              component = {Pricing}
              render={(props) => <Pricing {...props} />}
          />
          {/* Static Page */}
          <Route
                exact={false}
                path="/Air"
                // authUser={loginUser}
                component={ViewAir}
                // render={(props) => <ViewAir {...props} />}
          />
          <Route
            exact={false}
            path="/Ocean"
            component={ViewOcean}
          />
          <Route
            exact={false}
            path="/Courier"
            component={ViewCourier}
          />
          <Route
          exact={false}
          path="/Road"
          component={ViewRoad}
        />
        <Route
        exact={false}
        path="/Other"
        component={ViewOther}
        />
          <Route
              exact={false}
              path="/MemberShip"
              component={Support}
              render={(props) => <Support {...props} />}
          />
          <Route
              exact={false}
              path="/VesselScheduleSearch"
              component={VesselSchedulePage}
              render={(props) => <VesselSchedulePage {...props} />}
          />

          <MainRoutesManagerForProfileWithoutAuth
              exact={false}
              // isPrivate={true}
              path="/InvoiceDataForm"
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <InvoiceReaderField {...matchprops} />
                  </Suspense>
              )}
          />

          <MainLayoutRouteWithManagers
              isPrivate={true}
              path="/BookingCourierIndividual"
              render={(matchprops) => (
                  <Suspense fallback={<Loader />}>
                    <BookingCourierIndividual {...matchprops} />
                  </Suspense>
              )}
          />
          <MainLayoutRouteWithManagers
            exact={false}
            isPrivate={true}
            path="/ViewConsigneeBranch"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ConsigneeBranchView {...matchprops} />
              </Suspense>
            )}
          />
          <MainLayoutRouteWithManagers
            exact={false}
            isPrivate={true}
            path="/ViewShipperBranch"
            render={(matchprops) => (
              <Suspense fallback={<Loader />}>
                <ShipperBranchView {...matchprops} />
              </Suspense>
            )}
          />

          <Route
              exact={false}
              path="/blogTest"
              component={BlogPageOther}
              render={(props) => <BlogPageOther {...props} />}
          />
          <Route
              exact={false}
              path="/404"
              component={ErrorPage}
          />
          <Route
              exact={false}
              path="/TrackingSearchPageDemo"
              component={TrackingSearchPageDemoPage}
          />
          <Route
              exact={false}
              path="/TrackShipment"
              component={TrackingNewPage}
          />
          <Route
              exact={false}
              path="/TrackShipmentRoad"
              component={TrackingRoadPage}
          />
          <Route
              exact={false}
              path="/TrackShipmentAir"
              component={TrackingAirAndOceanPage}
          />
          <Route
              exact={false}
              path="/TrackShipmentOcean"
              component={TrackingAirAndOceanPage}
          />
          <Route
              exact={false}
              path="/ExhibitionLandingPage"
              component={ExhibitionLandingPage}
              render={(props) => <ExhibitionLandingPage {...props} />}
          />
          <Route
              exact
              path="/ChristmasLoader"
              component = {FullLoader}
              render={(props) => <FullLoader {...props} />}
          />
          <Route component={ErrorPage}/>

        </Switch>
      </Router>
    );
  }
}

export default Routers;
