import React, {Component} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {getExchangeCurrency} from "../../../util/CommonUtility";
import RestClient from "../../../util/RestClient";
import {withRouter} from "react-router";
import {history} from "../../../helpers/history.js";
import {
    GET_SPOT_RATE_TICKER,
    GET_SPOT_RATE_TICKER_NEW,
    SPOT_RATE_AIR_DATA_MANAGER,
    SPOT_RATE_BOOKING_API
} from "../../../constants";

import {PAGE_PATHS} from "../../../util/PageRoute";

class OceanTickerView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }
    play() {
        this.slider.slickPlay();
    }
    pause() {
        this.slider.slickPause();
    }

    componentDidMount() {
        this.getSpotRateOceanData({currency: getExchangeCurrency()}).then(oceanSpotData => {
            if (oceanSpotData && oceanSpotData.success){
                console.log("----- ocean spot rate"+JSON.stringify(oceanSpotData.result))
                this.setState({spotRateDataOcean:oceanSpotData.result.data},()=>{
                    this.groupTicker()
                })
            }
        })
    }
    getSpotRateOceanData = async (dataForServer) => {
        return await RestClient({
            url: GET_SPOT_RATE_TICKER,
            method: "POST",
            data: dataForServer
        })
    }
    groupTicker = () =>{
        if(Array.isArray(this.state.spotRateDataOcean) && this.state.spotRateDataOcean.length >= 1){
            // return this.state.spotRateDataAir.map((obj, i) => {
            let dataNew=[]
            let j=0;
            let arrN=[]
            for (let i = 0; i < this.state.spotRateDataOcean.length; i++){
                // console.log(i,j,arrN.length,dataNew.length)
                if(j<3){
                    arrN.push(this.state.spotRateDataOcean[i])
                    if((this.state.spotRateDataOcean.length-1)==i){
                        dataNew.push(arrN)
                    }
                    j++
                }else{
                    arrN.push(this.state.spotRateDataOcean[i])
                    dataNew.push(arrN)
                    j=0
                    arrN=[]
                }
                console.log("iiiii",i)
            }
            // console.log("datanewww",JSON.stringify(dataNew))
            this.setState({
                dataNew : dataNew
            },()=>{
                console.log("fdfd",this.state.dataNew)
            })


            // })
        }


    }
    OnDataView = (obj) =>{

        localStorage.setItem("SpotRateKeys", JSON.stringify(obj))
        this.getSpotRateOceanViewData(obj).then(r => {
            if(r.success){
                history.push(PAGE_PATHS.SPOT_RATE_PAGE);
                window.location.reload("false")
            }
        })
    }

    getSpotRateOceanViewData = async (dataForServer) => {
        return await RestClient({
            url: SPOT_RATE_BOOKING_API,
            method: "POST",
            data: dataForServer
        })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            vertical:true,
            verticalSwipe:true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <>

                <section className="Other-Page bg-white">
                <div className="container position-relative">
                    {/*        <div className="row">*/}
                    {/*<div className="col-lg-12">*/}
                    {/*    <h4>Spot Rate Ticker</h4>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-12">*/}
                    <div className="responsive-padding">
                        <Slider ref={slider => (this.slider = slider)} {...settings}>
                            {Array.isArray(this.state.spotRateDataOcean) && this.state.spotRateDataOcean.length >= 1
                                ? this.state.spotRateDataOcean.map((obj, i) => {


                                    return (
                                        <>
                                            <div className="space-ticker" style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }} onClick={e => {
                                                e.preventDefault()
                                                 if(Array.isArray(obj) && obj.length >= 1 ){
                                                    obj.map((objN, k) => {
                                                            let dataOcean = {
                                                                originId: objN && objN.originDetails ? objN.originDetails._id : "",
                                                                destinationId: objN && objN.destinationDetails ? objN.destinationDetails._id:"",
                                                                chargeableWeight: objN.w_500 !== "FALSE" ? 500 : 1000,
                                                                originName: objN && objN.originDetails ? objN.originDetails.name:"",
                                                                destinationName: objN && objN.destinationDetails ? objN.destinationDetails.name : "",
                                                            }
                                                            this.OnDataView(dataOcean)

                                                    })
                                                }

                                            }}>
                                                {Array.isArray(obj) && obj.length >= 1
                                                    ? obj.map((objN, k) => {
                                                        return (
                                                            <>
                                                            <div className="ticker-all ticker-alls">
                                                                <div className="ticker_section">
                                                                    <h4 className="origin_ticker">{objN && objN.originDetails ? objN.originDetails.city : ""}</h4>
                                                                    <h4 className="destination_ticker">{objN && objN.destinationDetails ? objN.destinationDetails.city : ""}</h4>
                                                                </div>
                                                                <div className="rate_ticker">
                                                                    <h4>{objN.currency}{" "}{objN.rate}</h4>
                                                                    <h5><strike>{objN.currency}{" "} {objN.strikeRate}</strike></h5>
                                                                </div>
                                                            </div>
                                                            </>
                                                        )
                                                }): undefined}

                                            </div>
                                        </>
                                    )


                                })
                                : undefined}
                        </Slider>
                    </div>
                    <div className="ticker_btn" style={{ textAlign: "center" }}>
                        <button className="button" onClick={this.play}>
                            Play
                        </button>
                        <button className="button" onClick={this.pause}>
                            Pause
                        </button>
                    </div>
                    {/*</div>*/}
                    {/*        </div>*/}

                </div>
                </section>

            </>
        )
    };

}


export default OceanTickerView;
