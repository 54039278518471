import React, {Component, Fragment} from "react";
import {Button, Col, Collapse, CustomInput, FormGroup, Input, Label, TabContent, TabPane, Nav, NavItem, NavLink,Row,} from "reactstrap";
import {
    BOOKING_MANAGER_MASTER_GET_DATA,
    BOOKING_MANAGER_MASTER_GET_DATA_COURIER,
    BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
    GET_ASYADDOCKET_PDF_API,
    GET_ASYADDOCKET_PDF_API_A5,
    GET_ASYADDOCKET_PDF_API_A5_COURIER, GET_ASYADDOCKET_PDF_API_A5_ROAD,
    GET_ASYADDOCKET_PDF_API_COURIER,
    GET_ASYADDOCKET_PDF_API_ROAD,
    GET_STATUS_BY_BOOKINGID,
    GET_STATUS_BY_BOOKINGID_COURIER,
    GET_STATUS_BY_BOOKINGID_ROAD
} from "../../../constants";
import RestClient from "../../../util/RestClient";
import {convertDateISOToCustomiseFormat} from "../../../util/CommonUtility";
import {toast} from "react-toastify";
import SideBarWithIcon from "../../../containers/navs/SideBarWithIcon";
import AppLayout from "../../../layout/AppLayout";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {history} from "../../../helpers/history";
class EdocketPdfView extends Component {
    constructor(props) {
        super(props);
        this.state ={
            activeTab : "1",
        }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    getbookingData = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Courier"?BOOKING_MANAGER_MASTER_GET_DATA_COURIER : BOOKING_MANAGER_MASTER_GET_DATA_ROAD,
            method: "POST",
            data: dataForServer
        })
    };
    TrackByBookingIdData = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Courier"?GET_STATUS_BY_BOOKINGID_COURIER : GET_STATUS_BY_BOOKINGID_ROAD,
            method: "POST",
            data: dataForServer
        })
    };
    componentDidMount() {
        let bookingId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        this.setState({bookingId:bookingId,quoteFor:quoteFor},()=>{
            this.getbookingData({id: bookingId}).then(bookingData => {
                if (bookingData && bookingData.success) {
                    let BookingResult = bookingData && bookingData.result ? bookingData.result : "";
                    console.log("BookingResultBookingResult"+JSON.stringify(BookingResult))
                    let quoteData = BookingResult && BookingResult.quoteId ? BookingResult.quoteId : undefined;
                    let custID = BookingResult && BookingResult.customerId ? BookingResult.customerId : "";
                    let shipperName = BookingResult && BookingResult.shipperId && BookingResult.shipperId.shipperName ? BookingResult.shipperId.shipperName : "";
                    let consigneeName = BookingResult && BookingResult.consigneeId && BookingResult.consigneeId.consigneeName ? BookingResult.consigneeId.consigneeName : "";
                    let consigneeAdd1 = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.addressLine1 ? BookingResult.consigneeBranchId.addressLine1 : "";
                    let consigneeAdd2 = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.addressLine2 ? BookingResult.consigneeBranchId.addressLine2 : "";
                    let consigneeCity = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.city ? BookingResult.consigneeBranchId.city : "";
                    let consigneeState = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.stateId && BookingResult.consigneeBranchId.stateId.name ? BookingResult.consigneeBranchId.stateId.name : "";
                    let consigneeCountry = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.countryId && BookingResult.consigneeBranchId.countryId.name ? BookingResult.consigneeBranchId.countryId.name : "";
                    let consigneePincode = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.pincode ? BookingResult.consigneeBranchId.pincode : BookingResult && BookingResult.consigneePincode ? BookingResult.consigneePincode:"";
                    let consigneeCname = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.contactName ? BookingResult.consigneeBranchId.contactName : BookingResult.consigneeContactName;
                    let consigneeCnumber = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.contactNumber ? BookingResult.consigneeBranchId.contactNumber :BookingResult.consigneeContactNumber;
                    let ConsigneeAddress = quoteData && quoteData.Consignee && quoteData.Consignee.AddressLine1 ? quoteData.Consignee.AddressLine1 : "";
                    let ConsigneeZipCode = quoteData && quoteData.Consignee && quoteData.Consignee.ZipCode ? quoteData.Consignee.ZipCode : "";
                    let chargeableWeight =  BookingResult && BookingResult.chargeableWeight ? BookingResult.chargeableWeight : quoteData && quoteData.chargeableWeight  ? quoteData.chargeableWeight : "";
                    let totalPieces = BookingResult && BookingResult.noOfPieces ? BookingResult.noOfPieces : quoteData && quoteData.totalPieces  ? quoteData.totalPieces : "";
                    let bookingNo = quoteData && quoteData.bookingNo  ? quoteData.bookingNo : "";
                    let blStatus = BookingResult && BookingResult.blStatus  ? BookingResult.blStatus : "";
                    let shipperAdd1 = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.addressLine1 ? BookingResult.shipperBranchId.addressLine1 : "";
                    let shipperAdd2 = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.addressLine2 ? BookingResult.shipperBranchId.addressLine2 : "";
                    let shipperCity = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.city ? BookingResult.shipperBranchId.city : "";
                    let shipperState = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.stateId && BookingResult.shipperBranchId.stateId.name ? BookingResult.shipperBranchId.stateId.name : "";
                    let shipperCountry = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.countryId && BookingResult.shipperBranchId.countryId.name ? BookingResult.shipperBranchId.countryId.name : "";
                    let shipperPincode = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.pincode ? BookingResult.shipperBranchId.pincode : BookingResult && BookingResult.shipperPincode ? BookingResult.shipperPincode:"";
                    let shipperCname = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.contactName ? BookingResult.shipperBranchId.contactName :BookingResult.shipperContactName ;
                    let shipperCnumber = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.contactNumber ? BookingResult.shipperBranchId.contactNumber : BookingResult.shipperContactNumber;
                    let branchWebUrl = BookingResult && BookingResult.companyBranchData && BookingResult.companyBranchData.branchWebsiteUrl ? BookingResult.companyBranchData.branchWebsiteUrl : "";
                    let branchCompanyName = BookingResult && BookingResult.companyBranchData && BookingResult.companyBranchData.branchCompanyName ? BookingResult.companyBranchData.branchCompanyName : "";
                    let branchCompanyLogo = BookingResult && BookingResult.companyBranchData && BookingResult.companyBranchData.branchCompanyLogo ? BookingResult.companyBranchData.branchCompanyLogo : "";
                    let courierType = BookingResult && BookingResult.quoteId && BookingResult.quoteId.courierMode ? BookingResult.quoteId.courierMode : "";
                    let jobNo = BookingResult && BookingResult.quoteId && BookingResult.quoteId.jobNo ? BookingResult.quoteId.jobNo : "";
                    let shipperContactName = BookingResult && BookingResult.shipperContactName  ? BookingResult.shipperContactName : "";
                    let shipperContactNumber = BookingResult && BookingResult.shipperContactNumber  ? BookingResult.shipperContactNumber : "";
                    let consigneeContactName = BookingResult && BookingResult.consigneeContactName  ? BookingResult.consigneeContactName : "";
                    let consigneeContactNumber = BookingResult && BookingResult.consigneeContactNumber  ? BookingResult.consigneeContactNumber : "";
                    let createdAt = BookingResult && BookingResult.createdAt  ? BookingResult.createdAt : "";
                    let dimensions = BookingResult && BookingResult.dimensions  ? BookingResult.dimensions : "";
                    let shipperAdderess = quoteData && quoteData.Shipper && quoteData.Shipper.AddressLine1 ? quoteData.Shipper.AddressLine1 : "";
                    let shipperZipCode = quoteData && quoteData.Shipper && quoteData.Shipper.ZipCode ? quoteData.Shipper.ZipCode : "";
                    let quoteId =  BookingResult && BookingResult.quoteId &&  BookingResult.quoteId._id  ?  BookingResult.quoteId._id : "";
                    let shipperEmail =  BookingResult  &&  BookingResult.shipperEmail  ?  BookingResult.shipperEmail : [];
                    let consigneeEmail =  BookingResult  &&  BookingResult.consigneeEmail ?  BookingResult.consigneeEmail: [];
                    let shipper
                    this.setState({
                        consigneeEmail:consigneeEmail,
                        shipperEmail:shipperEmail,
                        quoteId:quoteId,
                        ConsigneeAddress:ConsigneeAddress,
                        blStatus:blStatus,
                        ConsigneeZipCode:ConsigneeZipCode,
                        consigneeAdd1:consigneeAdd1,
                        consigneeAdd2:consigneeAdd2,
                        consigneeCity:consigneeCity,
                        consigneeState:consigneeState,
                        consigneeCountry:consigneeCountry,
                        consigneePincode:consigneePincode,
                        consigneeCname:consigneeCname,
                        consigneeCnumber:consigneeCnumber,
                        shipperAdd1:shipperAdd1,
                        shipperAdd2:shipperAdd2,
                        shipperCity:shipperCity,
                        shipperState:shipperState,
                        shipperCountry:shipperCountry,
                        shipperPincode:shipperPincode,
                        shipperCname:shipperCname,
                        shipperCnumber:shipperCnumber,
                        branchWebUrl:branchWebUrl,
                        branchCompanyName:branchCompanyName,
                        courierType:courierType,
                        jobNo:jobNo,
                        custID:custID,
                        branchCompanyLogo:branchCompanyLogo,
                        chargeableWeight:chargeableWeight,
                        totalPieces:totalPieces,
                        bookingNo:bookingNo,
                        shipperAdderess:shipperAdderess,
                        shipperName:shipperName,
                        consigneeName:consigneeName,
                        shipperZipCode:shipperZipCode,
                        shipperContactName:shipperContactName,
                        shipperContactNumber:shipperContactNumber,
                        consigneeContactName:consigneeContactName,
                        dimensions:dimensions,
                        consigneeContactNumber:consigneeContactNumber,
                        createdAt:createdAt
                    })


                }
            }).catch();
            this.TrackByBookingIdData({id : bookingId}).then(trackByBookingData => {
                if(trackByBookingData && trackByBookingData.success){
                    let dataByBooking = trackByBookingData && trackByBookingData.result ? trackByBookingData.result : "";
                    this.setState({
                        dataByBooking : dataByBooking,
                    },()=>{
                        console.log("trackByQuoteId",JSON.stringify(this.state.dataByBooking))
                    })
                }
            })
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    getDocketPdf = async (bookingId) => {
        let objServer={id:bookingId}
        let resp=await this.getDocketPdfApiHit(objServer)
        if(resp.success){
            window.open(resp?.result)
            //
            // document.location.href = resp?.result;
        }else{
            toast.warning(resp.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };
    getDocketPdfApiHit = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Courier"?GET_ASYADDOCKET_PDF_API_COURIER:GET_ASYADDOCKET_PDF_API_ROAD,
            method: "POST",
            data: dataForServer
        });
    }


    getDocketPdfA5 = async (bookingId) => {
        let objServer={id:bookingId}
        let resp=await this.getDocketPdfApiHitA5(objServer)
        if(resp.success){
            window.open(resp?.result)
            // document.location.href = ;
        }else{
            toast.warning(resp.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };
    getDocketPdfApiHitA5 = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Courier"?GET_ASYADDOCKET_PDF_API_A5_COURIER:GET_ASYADDOCKET_PDF_API_A5_ROAD,
            method: "POST",
            data: dataForServer
        });
    }

    navigateOnNextButton = () =>{

        if(this.state.custID != "614857a761e12f13179a9e6c")
        {

            if(this.state.blStatus === "Executed" ){
                history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + '/' + this.state.quoteFor+'/'+this.state.quoteId )
                window.location.reload("false")
            }
            else if(this.state.blStatus === "Executed" && (this.state.dataByBooking.activeStatus !== 'InvoiceApproved')){

                toast.error("Kindly Wait for Invoice Approval", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)


            }
            else if(this.state.blStatus != "Executed"){

                toast.error("Kindly Wait for Invoice Creation", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)

            }
        }
        else if(this.state.custID != "627e07b967dc79384b1c6410")
        {

            if(this.state.blStatus === "Executed" ){
                history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + '/' + this.state.quoteFor+'/'+ this.state.quoteId )
                window.location.reload("false")
            }
            else if(this.state.blStatus === "Executed" && (this.state.dataByBooking.activeStatus !== 'InvoiceApproved')){

                toast.error("Kindly Wait for Invoice Approval", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)


            }
            else if(this.state.blStatus != "Executed"){

                toast.error("Kindly Wait for Invoice Creation", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)

            }
        }
        else if(this.state.custID != "632bedb3e7f2c271a8091ed8")
        {

            if(this.state.blStatus === "Executed" ){
                history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + '/' + this.state.quoteFor+'/'+ this.state.quoteId )
                window.location.reload("false")
            }
            else if(this.state.blStatus === "Executed" && (this.state.dataByBooking.activeStatus !== 'InvoiceApproved')){

                toast.error("Kindly Wait for Invoice Approval", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)


            }
            else if(this.state.blStatus != "Executed"){

                toast.error("Kindly Wait for Invoice Creation", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)

            }
        }
        else if(this.state.custID != "610e2f73b4591d0a9a9ab5f2")
        {

            if(this.state.blStatus === "Executed" ){
                history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + '/' + this.state.quoteFor+'/'+ this.state.quoteId )
                window.location.reload("false")
            }
            else if(this.state.blStatus === "Executed" && (this.state.dataByBooking.activeStatus !== 'InvoiceApproved')){

                toast.error("Kindly Wait for Invoice Approval", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)


            }
            else if(this.state.blStatus != "Executed"){

                toast.error("Kindly Wait for Invoice Creation", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)

            }
        }
        else if(this.state.custID != "627620d9862c09376baea263")
        {

            if(this.state.blStatus === "Executed" ){
                history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + '/' + this.state.quoteFor+'/'+ this.state.quoteId )
                window.location.reload("false")
            }
            else if(this.state.blStatus === "Executed" && (this.state.dataByBooking.activeStatus !== 'InvoiceApproved')){

                toast.error("Kindly Wait for Invoice Approval", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)


            }
            else if(this.state.blStatus != "Executed"){

                toast.error("Kindly Wait for Invoice Creation", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)

            }
        }
        else
        {

             if(this.state.blStatus === "Executed"){
                history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + '/' + this.state.quoteFor+'/'+ this.state.quoteId )
                window.location.reload("false")
            }
            else if(this.state.blStatus === "Final"){
                toast.error("Kindly Wait for Invoice Approval", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPageExecute,3000)

            }
            else if(this.state.blStatus == "Draft"){

                toast.error("Kindly Wait for Invoice Creation", {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(this.navigateToPAge,3000)

            }
        }

    }
    navigateToPAge = ()=>{
        history.push(PAGE_PATHS.STATICS)
        window.location.reload("false")
    }
    navigateToPageExecute = () =>{
        history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + '/' + this.state.quoteFor+'/'+ this.state.quoteId )
        window.location.reload("false")
    }



    render() {

        return(
            <>
                <AppLayout>
                    <div className="dashboard-wrapper">
                        <div className="container">
                            <div className="invoiceDetail">
                                <div className="d-flex justify-content-center align-items-center">
                                    <Nav className="ship-package" tabs style={{marginBottom:'0'}}>
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeTab === '1'? "active": ""}
                                                onClick={() => { this.toggle('1'); }}
                                            >
                                                View Docket
                                            </NavLink>

                                        </NavItem>
                                        {/*<NavItem>
                                            <NavLink
                                                className={this.state.activeTab === '2'? "active": "" }
                                                onClick={() => { this.toggle('2'); }}
                                            >
                                                Label A5
                                            </NavLink>
                                        </NavItem>*/}
                                        {this.state.activeTab === '1' ?
                                            <button className="btn shipbuttons"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.getDocketPdf(this.state.bookingId)
                                                    }}>
                                                GET DOCKET PDF
                                            </button> :
                                            <button className="btn shipbuttons"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.getDocketPdfA5(this.state.bookingId)
                                                    }}>
                                                GET DOCKET PDF
                                            </button>
                                        }
                                    </Nav>

                                </div>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">

                                        <div className="edocket-pdfs">
                                            <div className="pdf-texts">

                                                <div style={{width: '100%', textAlign: 'center'}}>
                                                    {/*<div className="logo-asyad" style={{position: 'absolute', top: '80px', right: 0}}>*/}
                                                        <img className="logo-pdfs" src={this.state.branchCompanyLogo ? this.state.branchCompanyLogo : "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.4640747460731658-1625549892639.png"} alt="logo"/>
                                                    {/*</div>*/}
                                                    <div className="ed-009" style={{fontSize:'18px'}}><b>{this.state.branchWebUrl}</b></div>
                                                    <div className="doc-lines" style={{width: '100%', height: '1px', background: '#000', }} />
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <div className="half-parts">
                                                        <div className="ed-009"><b>Delivery details:</b> </div>
                                                        <div className="ed-009">{this.state.consigneeName}</div>
                                                        <div className="ed-009">{this.state.consigneeAdd1} {this.state.consigneeAdd2} {this.state.consigneeCity} {this.state.consigneeState}</div>
                                                        <div className="ed-009">{this.state.consigneeCountry}-{this.state.consigneePincode}</div>
                                                        <div className="ed-009">Contact Name: {this.state.consigneeCname}</div>
                                                        <div className="ed-009">Contact Number: {this.state.consigneeCnumber}</div>
                                                    </div>
                                                    <div className="half-parts">
                                                        <div className="ed-009"><b>Sender Details:</b></div>
                                                        <div className="ed-009">{this.state.shipperName}</div>
                                                        <div className="ed-009">{this.state.shipperAdd1} {this.state.shipperAdd2} {this.state.shipperCity} {this.state.shipperState}</div>
                                                        <div className="ed-009">{this.state.shipperCountry}-{this.state.shipperPincode}</div>
                                                        <div className="ed-009">Contact Name:   {this.state.shipperCname}</div>
                                                        <div className="ed-009">Contact Number:   {this.state.shipperCnumber}</div>
                                                    </div>
                                                </div>
                                                <div className="half-parts">
                                                    <div className="ed-009"><b>Shipment Details:</b></div>
                                                    <div className="ed-009">Shipment Weight: <b>{this.state.chargeableWeight} KG</b></div>
                                                    <div className="ed-009">Number Of Pieces: <b>{this.state.totalPieces}Pcs</b></div>
                                                    <div className="ed-009">Courier Type: <b>{this.state.courierType}</b></div>

                                                </div>
                                                {/*<div style={{width: '33%', position: 'absolute', left: '8px', top: '124px'}}>
                                                    <div className="ed-009"><b>Contact Details:</b></div>
                                                    <div className="ed-009">{this.state.consigneeContactName}</div>
                                                    <div className="ed-009">{this.state.consigneeContactNumber}</div>
                                                </div>*/}

                                                {/*<div style={{width: '33%', position: 'absolute', left: '8px', top: '195px'}}>*/}
                                                {/*    <div className="ed-009"><b>Special Inst:</b></div>*/}
                                                {/*</div>*/}
                                                {/*<div style={{width: '33%', position: 'absolute', left: '200px', top: '195px'}}>*/}
                                                {/*    <div className="ed-009"><b>Sender Ref:</b></div>*/}
                                                {/*    <div className="ed-009">{this.state.bookingNo}</div>*/}
                                                {/*</div>*/}

                                                {/*<div style={{border: '1px solid #000', position: 'absolute', width: '130px', height: '125px', textAlign: 'center', top: '128px', right: 0, padding: '15px 0'}}>
                                                    <div className="ed-009">COD</div>
                                                    <div className="ed-009" style={{fontSize: '25px', fontWeight: 700}}>10.9</div>
                                                    <div className="ed-009">CNY</div>
                                                </div>*/}

                                                {/*<div style={{width: '33%', position: 'absolute', left: '8px', top: '254px'}}>*/}
                                                {/*    <div className="ed-009">10000002408</div>*/}
                                                {/*    <div className="ed-009"><b>Tracking Number</b></div>*/}
                                                {/*</div>*/}
                                                {/*<div style={{width: '40%', position: 'absolute', left: '200px', top: '254px'}}>*/}
                                                {/*    <div className="ed-009">1Z671W850402912535</div>*/}
                                                {/*</div>*/}
                                                {/*<div style={{width: '23%', position: 'absolute', right: 0, top: '254px', textAlign: 'center'}}>*/}
                                                {/*    <div className="ed-009">Express</div>*/}
                                                {/*    <div className="ed-009"><b>Parcel</b></div>*/}
                                                {/*</div>*/}
                                                {/*<div style={{border: '1px solid #000', width: '130px', height: '54px', padding: '5px 0', position: 'absolute', right: 0, top: '295px', textAlign: 'center'}}>*/}
                                                {/*    <div className="ed-009">HUB</div>*/}
                                                {/*    <div className="ed-009">5620</div>*/}
                                                {/*</div>*/}
                                                {/*<div style={{position: 'absolute', width: '60px', height: '75px', lineHeight: '75px', background: '#000', textAlign: 'center', color: '#fff', fontSize: '25px', fontWeight: 600, top: '321px', right: 0}}>*/}
                                                {/*    <div className="ed-009">01</div>*/}
                                                {/*</div>*/}
                                                <div className="doc-lines" style={{width: '100%', height: '1px', background: '#000'}} />
                                                <div className="padding_edocket">
                                                    <div style={{width: '100%', textAlign: 'center'}}>
                                                        <div className="ed-009"><b>Creation:</b> {convertDateISOToCustomiseFormat(this.state.createdAt)}</div>
                                                        <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.3278196022668489-1622023357506.png" alt="Qr"/>
                                                    </div>
                                                    <div style={{textAlign: 'center', width: '100%'}}>
                                                        <div className="ed-009" style={{fontSize:'18px',fontWeight:'700'}}>{this.state.jobNo?this.state.jobNo:this.state.jobNo}</div>
                                                    </div>
                                                </div>

                                                <div className="doc-lines" style={{width: '100%', height: '1px', background: '#000'}} />
                                                <div className="padding_edocket">
                                                    <div style={{width: '100%', textAlign: 'center'}}>
                                                        <div className="ed-009"><b>{this.state.branchCompanyName}</b></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="edocket-pdfs-2">
                                            <div className="pdf-texts">
                                                <div className="doc-lines" style={{width: '100%', height: '1px', background: '#000', position: 'absolute', top: '20px', left: 0, right: 0}} />
                                                <div style={{position: 'absolute', top: '1px', width: '100%', textAlign: 'center'}}>
                                                    <div className="ed-009"><b>{this.state.branchWebUrl}</b></div>
                                                </div>
                                                <div style={{width: '40%', position: 'absolute', left: '8px', top: '20px'}}>
                                                    <div className="ed-009"><b>Delivery details: </b></div>
                                                    <div style={{width: '45%', position: 'absolute', left: '8px', top: '20px'}}>
                                                        <div className="ed-009"><b>Delivery details:</b> </div>
                                                        <div className="ed-009">{this.state.consigneeName}</div>
                                                        <div className="ed-009">{this.state.consigneeAdd1}{this.state.consigneeAdd2}{this.state.consigneeCity}{this.state.consigneeState}</div>
                                                        <div className="ed-009">{this.state.consigneeCountry}-{this.state.consigneePincode}</div>
                                                        <div className="ed-009">Contact Name{this.state.consigneeCname}</div>
                                                        <div className="ed-009">Contact Number{this.state.consigneeCnumber}</div>
                                                    </div>
                                                </div>
                                                <div style={{width: '40%', position: 'absolute', left: '330px', top: '20px'}}>
                                                    <div className="ed-009"><b>Shipment Weight: {this.state.chargeableWeight} KG </b></div>
                                                    {/*<div className="ed-009"><b>Size:</b>
                                                        {Array.isArray(this.state.dimensions) && this.state.dimensions.length > 0 ?
                                                            this.state.dimensions.map((obj, i) => {
                                                                return (
                                                                    <>
                                                                        <span>{obj.length}{" X "}{obj.width}{" X "}{obj.height}{" X "}{obj.pieces}</span>
                                                                    </>
                                                                )
                                                            })
                                                            :
                                                            undefined}</div>*/}
                                                    <div className="ed-009"><b>Number Of Pieces:{this.state.totalPieces}Pcs</b></div>
                                                    <div className="ed-009"><b>Courier Type: {this.state.courierType}</b></div>
                                                </div>
                                                <div style={{width: '40%', position: 'absolute', left: '330px', top: '124px'}}>
                                                    <div className="ed-009"><b>Sender Details:</b></div>
                                                    <div className="ed-009">{this.state.shipperName}</div>
                                                    <div className="ed-009">{this.state.shipperAdd1}{this.state.shipperAdd2}{this.state.shipperCity}{this.state.shipperState}</div>
                                                    <div className="ed-009">{this.state.shipperCountry}-{this.state.shipperPincode}</div>
                                                    <div className="ed-009">Contact Name   {this.state.shipperCname}</div>
                                                    <div className="ed-009">Contact Number   {this.state.shipperCnumber}</div>
                                                </div>
                                                {/*<div style={{width: '40%', position: 'absolute', left: '8px', top: '195px'}}>*/}
                                                {/*    <div className="ed-009"><b>Special Inst:</b></div>*/}
                                                {/*</div>*/}
                                                {/*<div style={{width: '40%', position: 'absolute', left: '330px', top: '195px'}}>*/}
                                                {/*    <div className="ed-009"><b>Sender Ref:</b></div>*/}
                                                {/*</div>*/}
                                                <div className="logo-asyad" style={{position: 'absolute', top: '85px', right: 0}}>
                                                    <img src={this.state.branchCompanyLogo ? this.state.branchCompanyLogo : "https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.3278196022668489-1622023357506.png"} alt="Bar Code"/>
                                                </div>
                                                <div style={{border: '1px solid #000', position: 'absolute', width: '130px', height: '125px', textAlign: 'center', top: '128px', right: 0, padding: '15px 0'}}>
                                                    <div className="ed-009">Prepaid</div>
                                                    <div className="ed-009" style={{fontSize: '25px', fontWeight: 700}}>0</div>
                                                    <div className="ed-009">OMR</div>
                                                </div>
                                                <div className="doc-lines" style={{width: '100%', height: '1px', background: '#000', position: 'absolute', top: '253px', left: 0, right: 0}} />
                                                {/*<div style={{width: '33%', position: 'absolute', left: '8px', top: '254px'}}>*/}
                                                {/*    <div className="ed-009">10000002408</div>*/}
                                                {/*    <div className="ed-009">Tracking Number</div>*/}
                                                {/*</div>*/}
                                                {/*<div style={{width: '23%', position: 'absolute', right: 0, top: '254px', textAlign: 'center'}}>*/}
                                                {/*    <div className="ed-009"><b>Express</b></div>*/}
                                                {/*    <div className="ed-009"><b>Parcel</b></div>*/}
                                                {/*</div>*/}
                                                {/*<div style={{border: '1px solid #000', width: '130px', height: '54px', padding: '5px 0', position: 'absolute', right: 0, top: '295px', textAlign: 'center'}}>*/}
                                                {/*    <div className="ed-009">HUB</div>*/}
                                                {/*    <div className="ed-009">5620</div>*/}
                                                {/*</div>*/}
                                                <div style={{position: 'absolute', width: '50px', height: '65px', lineHeight: '65px', background: '#000', textAlign: 'center', color: '#fff', fontSize: '25px', fontWeight: 600, top: '350px', right: 0}}>
                                                    <div className="ed-009">01</div>
                                                </div>
                                                <div className="doc-lines" style={{width: '100%', height: '1px', background: '#000', position: 'absolute', bottom: '25px', left: 0, right: 0}} />
                                                <div style={{position: 'absolute', width: '100%', bottom: '4px', textAlign: 'center', left: 0, right: 0}}>
                                                    <div className="ed-009"><b>ZIPAWORLD</b></div>
                                                </div>
                                                <div style={{position: 'absolute', bottom: '26px', width: '100%', textAlign: 'center', left: 0, right: 0}}>
                                                    <div className="ed-009"><b>Creation:</b> {convertDateISOToCustomiseFormat(this.state.createdAt)}</div>
                                                    <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.3278196022668489-1622023357506.png" alt="Bar Code"/>
                                                    <div className="ed-009" style={{fontSize:'18px',fontWeight:'700'}}>{this.state.jobNo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                    <div className="fixed-btnsbot">
                        <div className="d-flex flex-wrap justify-content-end">
                            <Button className="pdf manager" onClick={e => {
                                e.preventDefault();
                                this.props.history.push(PAGE_PATHS.PROFORMA_COURIER_VIEW + '/' + this.state.quoteFor+'/'+this.state.bookingId)
                            }}>Previous</Button>
                            <Button className="pdf manager" onClick={e => {
                                e.preventDefault();
                                this.props.history.push(PAGE_PATHS.MAIN_PAGE)
                            }}>Home</Button>
                            <Button className="pdf manager" onClick={e => {
                                e.preventDefault();
                                if(this.state.KycStatus === false){
                                    this.setState({gstLarge :true})
                                } else {
                                    this.navigateOnNextButton();
                                }
                            }}>Next</Button>
                            {/*<Button className="pdf manager" onClick={e => {*/}
                            {/*    e.preventDefault();*/}
                            {/*    if(this.state.KycStatus === false){*/}
                            {/*        this.setState({gstLarge :true})*/}
                            {/*    }*/}
                            {/*    else {*/}
                            {/*        this.jumpToperforma();*/}
                            {/*    }*/}
                            {/*}}>Next</Button>*/}
                        </div>
                        <div className="clearfix"/>
                    </div>
                </AppLayout>

            </>
        )}


}

export default EdocketPdfView;