import React, {Component} from "react";
import AppLayout from "../../../layout/AppLayout";


class NewHomePage extends Component {

    render() {

        return (
            <AppLayout>
                <>
                    <div className="">

                    </div>
                </>
            </AppLayout>
        )
    }
}
export default NewHomePage;



