import {all, call, fork, put} from 'redux-saga/effects';

import RestClient from "../../util/RestClient";
import {

    MANAGER_CONSIGNEE,
    SAVE_CONSIGNEE,
    DELETE_CONSIGNEE_BY_ID, UPDATE_CONSIGNEE

} from "../../constants";
import {
    CONSIGNEE_MANAGER_FETCH_MASTER,
    CONSIGNEE_CREATE, CONSIGNEE_MANAGER_DELETE,CONSIGNEE_MASTER_UPDATE
    

} from "../actions";
import routeRedirectTo from "../../util/routeWrapper";
import {takeLatest} from "@redux-saga/core/effects";
import {
    showConsigneeResponce,
    onConsigneeMasterFetched,
    onConsigneeMasterFetchedFailed,
    deleteConsigneeMasterResponse,
    updatConsigneeMasterResponse
} from "./consigneeAction";
import {PAGE_PATHS, PAGE_PATHS as PageRoute} from "../../util/PageRoute";
import {toast} from "react-toastify";
import {history} from "../../helpers/history";


export function* watchEmail() {
    yield takeLatest(CONSIGNEE_CREATE, storeConsignee);
    yield takeLatest(CONSIGNEE_MANAGER_DELETE, deleteConsigneeMaster);
    yield takeLatest(CONSIGNEE_MANAGER_FETCH_MASTER, fetchConsigneeManager);
    yield takeLatest(CONSIGNEE_MASTER_UPDATE,updateConsigneeManager);



}

function* updateConsigneeManager({ payload }) {
    try {
        const updateConsigneeManagerData = yield call(updateConsigneeAsync, payload);
        // console.log("updatConsigneeMasterResponse  response" + JSON.stringify(updateConsigneeManagerData))
        if (updateConsigneeManagerData.success) {
            // console.log("updatConsigneeMasterResponse IN Success" + JSON.stringify(updateConsigneeManagerData))
            yield put(updatConsigneeMasterResponse(updateConsigneeManagerData.message));
            toast.success("Consignee Successfully Updated", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.push(PAGE_PATHS.SHIPPER_CONSIGNEE_MANAGER);
            window.location.reload(false);
        } else {
            // console.log("updatConsigneeMasterResponse Not Success" + JSON.stringify(updateConsigneeManagerData.message))
            yield put(updatConsigneeMasterResponse(updateConsigneeManagerData.message));
        }
    } catch
    (error) {
        if (Object.keys(error).length !== 0) {

            yield put(updatConsigneeMasterResponse(error.response.data.message));
        }

    }
}

const updateConsigneeAsync = async (ConsigneeManangerDetails) => {

    // console.log(JSON.stringify("ConsigneeManangerDetails API" + JSON.stringify(ConsigneeManangerDetails)))

    return await RestClient({
        url: UPDATE_CONSIGNEE,
        method: "POST",
        data: ConsigneeManangerDetails
    })
}

function* deleteConsigneeMaster({payload}) {
    try {
        const deleteConsigneeMasterData = yield call(deleteConsigneeMasterAsync, payload._id);
        //// console.log("delete Airfreight Surcharge Response " + JSON.stringify(deleteConsigneeMasterData))
        if (deleteConsigneeMasterData.success) {
            //// console.log("delete Airfreight Surcharge IN Success" + JSON.stringify(deleteConsigneeMasterData))
            yield put(deleteConsigneeMasterResponse(deleteConsigneeMasterData.message));
            yield call(forwardTo, PageRoute.CONSIGNEE_MANAGER);
        } else {
            //// console.log("deleteConsigneeMasterResponse Not Success" + JSON.stringify(deleteConsigneeMasterData))
            yield put(deleteConsigneeMasterResponse(deleteConsigneeMasterData.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(deleteConsigneeMasterResponse(error.response.data.message));
        }
    }
}
const deleteConsigneeMasterAsync = async (_id) => {
        //// console.log(JSON.stringify("Charge Master Manager delete API" + JSON.stringify(_id)))
        return await RestClient({
            url: DELETE_CONSIGNEE_BY_ID,
            method: "POST",
            data: {id: _id}
        })
}

function* storeConsignee({payload}) {

    try {
        const storeConsigneeResponse = yield call(storeConsigneeAsync, payload);

        if (storeConsigneeResponse.success) {

            yield put(showConsigneeResponce(storeConsigneeResponse.message));
            toast.success("Consignee Successfully Added", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.push(PAGE_PATHS.SHIPPER_CONSIGNEE_MANAGER);
            window.location.reload(false);

        } else {
            // console.log("Not Success" + storeConsigneeResponse)
            yield put(showConsigneeResponce(storeConsigneeResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(showConsigneeResponce(error.response.data.message));
        }

    }
}

const storeConsigneeAsync = async (StoreConsigneeData) => {
    {
        // console.log(JSON.stringify("In Consignee Save API" + JSON.stringify(StoreConsigneeData)))

        return await RestClient({
            url: SAVE_CONSIGNEE,
            method: "POST",
            data: StoreConsigneeData
        })

    }
}


function* fetchConsigneeManager({payload}) {

    // console.log("In fetch shipper Attempt" + JSON.stringify(payload))

    try {
        const fetchConsigneeManagerResponse = yield call(fetchConsigneeManagerAsync, payload);
        // console.log("fetch shipper Master" + fetchConsigneeManagerResponse);
        if (fetchConsigneeManagerResponse.success) {
            yield put(onConsigneeMasterFetched(fetchConsigneeManagerResponse.result));
        } else {
            // console.log(" fetch shipper Master Not Success" + fetchConsigneeManagerResponse)
            yield put(onConsigneeMasterFetchedFailed(fetchConsigneeManagerResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch shipper Master" + JSON.stringify(error))
            yield put(onConsigneeMasterFetchedFailed(error.response.data.message));
        }
    }
}

const fetchConsigneeManagerAsync = async (fetchConsigneeManagerData) => {
    {
        // console.log(JSON.stringify("In fetch  shipper  API" + JSON.stringify(fetchConsigneeManagerData)))

        return await RestClient({
            url: MANAGER_CONSIGNEE,
            method: "POST",
            data: fetchConsigneeManagerData
        })

    }
}


function forwardTo(location) {
    routeRedirectTo.push(location);
}

export default function* Saga() {
    yield all([
        fork(watchEmail)
    ]);
}
