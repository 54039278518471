import React, {Component} from "react";

import PropTypes from 'prop-types';
import {Button, Col, Collapse, CustomInput, FormGroup, Input, Label, Row} from "reactstrap";
import CSVReader from "react-csv-reader";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import {parseFloatValue} from "../util/CommonUtility";
import {history} from "../helpers/history";
import '../assets/css/sass/customeCss.css';
import {toast} from "react-toastify";
import UploadFilesCloudes from "./uploadFilesCloudes";


const dimensionParseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

const formSchema = Yup.object().shape({
    length: Yup.number("Only Number").required("Required"),
    width: Yup.number().required("Required"),
    height: Yup.number().required("Required"),
    pieces: Yup.number().required("Required")
});

class DimensionViewCalculateRatePop extends Component {
    MAX_LENGTH_CM = 318;
    MAX_WIDTH_CM = 224;
    MAX_HEIGHT_CM = 168;
    MAX_LENGTH_INCH = 125.197;
    MAX_WIDTH_INCH = 88.189;
    MAX_HEIGHT_INCH = 64.1732;
    MAX_LENGTH_MM = 3180;
    MAX_WIDTH_MM = 2240;
    MAX_HEIGHT_MM = 1680;
    MAX_LENGTH_MTR = 3.18;
    MAX_WIDTH_MTR = 2.24;
    MAX_HEIGHT_MTR = 1.63;

    MAX_LENGTH_FT = 10.4331;
    MAX_WIDTH_FT = 7.34908;
    MAX_HEIGHT_FT = 5.51181;

    currentEnteredData = {totalPieces: 0, totalVolume: 0, totalGross: 0, totalChargeable: 0, totalDensity: 0};

    constructor(props) {
        super(props);

        this.state = {
            uploadedDimensions: [],
            fileDimensions: [],
            selectedDimensionType: "CM",
            dimensionCollapse: true,
            totalPieces: "0",
            grossWeight: "",
            volumeWeight: "",
            chargeableWeight: "",
            calculatedDensity: "",
            allowedPiecesOverride: false,
            allowedEdit: true,
            fileName: "Up",
            attachments: [],
            remarks: "",
            selectWeight: "Kgs",
            packingType:"",
            selectedOption :"Calculate Dimension By Package Details"

        };

    }

    componentDidMount() {
        let getChargesObj = JSON.parse(localStorage.getItem('selectedCharge'))

        this.setState({
            getChargesObj:getChargesObj
        })

        if (this.state.allowedEdit !== this.props.allowedEdit) {

            this.setState({
                allowedEdit: this.props.allowedEdit
            })
        }
        if (this.state.totalPieces !== this.props.totalPieces ||
            this.state.grossWeight !== this.props.grossWeight ||
            this.state.volumeWeight !== this.props.volumeWeight ||
            this.state.chargeableWeight !== this.props.chargeableWeight
        ) {
            this.setState({
                totalPieces: parseFloatValue(this.props.totalPieces),
                grossWeight: parseFloatValue(this.props.grossWeight),
                volumeWeight: parseFloatValue(this.props.volumeWeight),
                chargeableWeight: parseFloatValue(this.props.chargeableWeight),
            },()=>{
                let volumeweightmain
                let chargeableWeightmain
                let originalgrossmain
                let originalvolume = this.state.volumeWeight
                let originalchargeabl =this.state.chargeableWeight
                let originalgross = this.state.grossWeight

                if(this.props.zipaSuggestion){
                    if(this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air"){
                        volumeweightmain =  originalvolume * 167
                        originalgrossmain =  originalgross
                    }else if (this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"){
                        volumeweightmain =  originalvolume
                        originalgrossmain =  originalgross / 1000
                    } else {
                        volumeweightmain =  originalvolume / 167
                        originalgrossmain =  originalgross
                    }
                    if(volumeweightmain > originalgrossmain){
                        chargeableWeightmain = volumeweightmain
                    } else {
                        chargeableWeightmain = originalgrossmain
                    }
                }else {
                    volumeweightmain = originalvolume
                    chargeableWeightmain = originalchargeabl
                    originalgrossmain =originalgross
                }
                this.setState({
                    grossWeight :originalgrossmain,
                    volumeWeight : parseFloat(volumeweightmain).toFixed(2),
                    chargeableWeight :chargeableWeightmain,
                })



            })
        }

        if (this.props.dimensionArray) {
            this.setState({
                uploadedDimensions: this.props.dimensionArray
            })

        }

        if (this.props.selectedDimensionType) {
            this.setState({
                selectedDimensionType: this.props.selectedDimensionType
            })
        }
        if (this.props.tarrifMode) {
            this.setState({
                tarrifMode: this.props.tarrifMode
            })
        }
        if (this.props.shipmentMode) {
            this.setState({
                shipmentMode: this.props.shipmentMode
            })
        }
        if (this.props.queryFor) {
            this.setState({
                queryFor: this.props.queryFor
            })
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.allowedEdit !== this.state.allowedEdit) {
            this.setState({
                allowedEdit: this.props.allowedEdit
            })
        }

        if (
            parseFloatValue(prevProps.totalPieces) !== parseFloatValue(this.props.totalPieces)
            || parseFloatValue(prevProps.grossWeight) !== parseFloatValue(this.props.grossWeight)
            || parseFloatValue(prevProps.volumeWeight) !== parseFloatValue(this.props.volumeWeight)
            || parseFloatValue(prevProps.chargeableWeight) !== parseFloatValue(this.props.chargeableWeight)


        ) {
            this.setState({
                totalPieces: parseFloatValue(this.props.totalPieces),
                grossWeight: parseFloatValue(this.props.grossWeight),
                volumeWeight: parseFloatValue(this.props.volumeWeight),
                chargeableWeight: parseFloatValue(this.props.chargeableWeight),
                allowedPiecesOverride: this.props.allowedPiecesOverride,
            },()=>{
                let volumeweightmain
                let chargeableWeightmain
                let originalgrossmain
                let originalvolume = this.state.volumeWeight
                let originalchargeabl =this.state.chargeableWeight
                let originalgross = this.state.grossWeight

                if(this.props.zipaSuggestion){
                    if(this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air"){
                        volumeweightmain =  originalvolume * 167
                        originalgrossmain =  originalgross
                    }else if (this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"){
                        volumeweightmain =  originalvolume
                        originalgrossmain =  originalgross / 1000
                    } else {
                        volumeweightmain =  originalvolume / 167
                        originalgrossmain =  originalgross
                    }
                    if(volumeweightmain > originalgrossmain){
                        chargeableWeightmain = volumeweightmain
                    } else {
                        chargeableWeightmain = originalgrossmain
                    }
                }else {
                    volumeweightmain = originalvolume
                    chargeableWeightmain = originalchargeabl
                    originalgrossmain =originalgross
                }
                this.setState({
                    grossWeight :originalgrossmain,
                    volumeWeight : volumeweightmain,
                    chargeableWeight :chargeableWeightmain,
                })


            })
        }
        if (this.props.dimensionArray && prevProps.dimensionArray !== this.props.dimensionArray) {

            this.setState({
                uploadedDimensions: this.props.dimensionArray
            })
        }
        if (this.props.selectedDimensionType && prevProps.selectedDimensionType !== this.props.selectedDimensionType) {
            this.setState({
                selectedDimensionType: this.props.selectedDimensionType
            })
        }
        if (this.props.tarrifMode && prevProps.tarrifMode !== this.props.tarrifMode) {
            this.setState({
                tarrifMode: this.props.tarrifMode
            })
        }
        if (this.props.shipmentMode && prevProps.shipmentMode !== this.props.shipmentMode) {
            this.setState({
                shipmentMode: this.props.shipmentMode
            })
        }
        if (this.props.queryFor && prevProps.queryFor !== this.props.queryFor) {
            this.setState({
                queryFor: this.props.queryFor
            })
        }

    }

    handleUploadedDimensions = data => {
        let totalPieces = 0;
        if (!data) {
            return;
        }

        for (let i = 0; i < data.length; i++) {
            if (data[i].length && data[i].height && data[i].width && data[i].pieces) {
                try {
                    totalPieces += parseInt(data[i].pieces);
                } catch (e) {
                }
            }
        }

        if (data && parseInt(totalPieces) + parseInt(this.calculateOverallDimensionData().totalPieces) > this.state.totalPieces) {
            this.setState({
                dimensionOverload: `Uploaded Document Contains ${totalPieces}  pieces (+ ${
                    this.calculateOverallDimensionData().totalPieces
                } already Added), required only ${
                    this.state.totalPieces ? this.state.totalPieces : 0
                } `
            });
            return;
        } else {
            this.setState({
                dimensionOverload: ""
            });
        }
        let dimensions = this.state.uploadedDimensions;

        let allDimensions = dimensions.concat(data);

        this.setState({
            uploadedDimensions: allDimensions
        });
    };

    addNewDimension = async values => {
        // alert(JSON.stringify(values))

        if (values.length && values.width && values.height && values.pieces && this.state.selectWeight && values.gw_pc && this.state.packingType) {
            values.selectWeight = this.state.selectWeight
            values.packingType = this.state.packingType
            // if ((await this.isTotalPiecesExceed(values)) === true) {
            //     return
            // }
            await this.validateMaxDimensions(values);
            let dimensions = Array.isArray(this.state.uploadedDimensions) && this.state.uploadedDimensions.length >= 1 ? this.state.uploadedDimensions : [];
            dimensions.push(values);
            this.setState({uploadedDimensions: dimensions}, () => {
                this.props.dimensionOverallChanges({
                    weight: this.calculateOverallDimensionData(),
                    dimensionArray: this.state.uploadedDimensions,
                    selectedDimensionType: this.state.selectedDimensionType,
                })
            });
            this.calculateOverallDimensionData()

        } else {
        }
    };

    validateMaxDimensions = values => {
        if (this.state.selectedTariff === "oddDimensions") {
            return true;
        }
        let isValid = false;
        let length = values.length;
        let width = values.width;
        let height = values.height;

        if (this.state.selectedDimensionType === "CM") {
            if (
                length <= this.MAX_LENGTH_CM &&
                width <= this.MAX_WIDTH_CM &&
                height <= this.MAX_HEIGHT_CM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "FT") {
            if (
                length <= this.MAX_LENGTH_FT &&
                width <= this.MAX_WIDTH_FT &&
                height <= this.MAX_HEIGHT_FT
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "MM") {
            if (
                length <= this.MAX_LENGTH_MM &&
                width <= this.MAX_WIDTH_MM &&
                height <= this.MAX_HEIGHT_MM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "INCHES") {
            if (
                length <= this.MAX_LENGTH_INCH &&
                width <= this.MAX_WIDTH_INCH &&
                height <= this.MAX_HEIGHT_INCH
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "METERS") {
            if (
                length <= this.MAX_LENGTH_MTR &&
                width <= this.MAX_WIDTH_MTR &&
                height <= this.MAX_HEIGHT_MTR
            ) {
                isValid = true;
            }
        }
        if (isValid === false) {
            toastr.success(
                "Dimension Exceed Values ",
                "Tariff Mode Changed to Odd Dimension ",
                {
                    transitionIn: "bounceIn",
                    transitionOut: "bounceOut"
                }
            );
            // this.changeTariffModeTo("Odd Dimention");
        }

        return isValid;
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value}, () => {
            this.props.dimensionOverallChanges({
                weight: this.calculateOverallDimensionData(),
                dimensionArray: this.state.uploadedDimensions,
                selectedDimensionType: this.state.selectedDimensionType,
            })
        });
    };

    handleChangeGross = e => {
        let key = e.target.name
        let keyValue = e.target.value
        this.setState({[key]: keyValue}, () => {
            let grossWeightLbs = this.state.grossWeightLbs
            let grossWeight = this.state.grossWeight
            if (key == "grossWeightLbs") {
                //alert("gw changed")
                grossWeight = parseFloatValue(grossWeightLbs * 0.453592)
                this.setState({grossWeight: grossWeight}, () => {
                    this.props.dimensionOverallChanges({
                        weight: this.calculateOverallDimensionData(),
                        dimensionArray: this.state.uploadedDimensions,
                        selectedDimensionType: this.state.selectedDimensionType,
                    })
                })
            } else {
                //alert("gwL changed")
                grossWeightLbs= parseFloatValue(grossWeight*2.20462)
                this.setState({grossWeightLbs:grossWeightLbs},()=>{
                    this.props.dimensionOverallChanges({
                        weight: this.calculateOverallDimensionData(),
                        dimensionArray: this.state.uploadedDimensions,
                        selectedDimensionType: this.state.selectedDimensionType,
                    })
                })
            }
        });
    };

    handleChangeRe = e => {
        this.setState({[e.target.name]: e.target.value}, () => {
        });
    };



    toggleDimensionCollapse = () => {
        this.setState({dimensionCollapse: !this.state.dimensionCollapse});
    };
    handleChange1 = e => {
        this.setState({
            [e.target.name]: e.target.value,
            selectedOption: e.target.value
        }, () => {
            // console.log("values",e.target.value)
        });
    };
    render() {
        let data = this.props.data ? this.props.data :""
        let  suggestionQueryFor = data && data.queryFor ? data.queryFor:""
        return (

            <Row>
                <Col lg="12">
                    <Row>
                        <Col lg="12">
                            <div className="mb-2">
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input
                                            type="radio"
                                            id="enterCompleteValue"
                                            className="mr-2"
                                            name="enterCompleteValue"
                                            value={"Calculate Dimension By Package Details"}
                                            checked={this.state.selectedOption  === 'Calculate Dimension By Package Details'}
                                            onChange={this.handleChange1}
                                        /><strong>Calculate Dimension By Package Details</strong>
                                    </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input
                                            type="radio"
                                            id="enterPerItem"
                                            name="enterPerItem"
                                            className="mr-2"
                                            value={"Enter Dimension of Shipment"}
                                            checked={this.state.selectedOption  === 'Enter Dimension of Shipment'}
                                            onChange={this.handleChange1}
                                        /><strong>Enter Dimension of Shipment</strong>
                                    </label>
                                </div>

                            </div>
                            <div className="AAA-add-query-dimension-2">
                                <ul id="dimen_summ">
                                    <li>No. Of Pieces :<span>{this.state.totalPieces} Pcs.</span></li>
                                    <li>Weight :<span>{parseFloatValue(this.state.grossWeight)} {this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"?"Tons":"Kgs"}.</span></li>
                                    { this.props.zipaSuggestion == true ?
                                    <>
                                    {suggestionQueryFor === "Ocean"
                                    ?
                                         
                                        <li>Volume Weight :<span>{this.state.volumeWeight} CBM.</span></li>
                                        :
                                        <li>Volume Weight :<span>{this.state.volumeWeight} Kgs.</span></li>
                                    }
                                    </>:
                                    <>
                                    {this.state.queryFor =="Ocean"?
                                         
                                        <li>Volume Weight :<span>{this.state.volumeWeight} CBM.</span></li>
                                        :
                                        <li>Volume Weight :<span>{this.state.volumeWeight} Kgs.</span></li>
                                    }
                                    </>}

                                    
                                    {/*<li>Shipment Mode :<span>{this.state.queryFor}</span></li>
                                    <li>Chargeable Weight :<span>{this.state.chargeableWeight} Kgs.</span></li>*/}
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {/*<Col lg="12">*/}
                        {/*    <div className="AAA-add-query-dimension-3">*/}
                        {/*        <label><strong>Weight Type:</strong></label>*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                <CustomInput*/}
                        {/*                    type="radio"*/}
                        {/*                    id="KG"*/}
                        {/*                    label="KG"*/}
                        {/*                    name="selectWeightType"*/}
                        {/*                    value={"KG"}*/}

                        {/*                />*/}
                        {/*            </li>*/}

                        {/*            <li>*/}
                        {/*                <CustomInput*/}
                        {/*                    type="radio"*/}
                        {/*                    id="LBS"*/}
                        {/*                    label="LBS"*/}
                        {/*                    name="selectWeightType"*/}
                        {/*                    value={"LBS"}*/}

                        {/*                />*/}
                        {/*            </li>*/}

                        {/*        </ul>*/}

                        {/*    </div>*/}
                        {/*</Col>*/}
                        <Col lg="12" hidden={!this.state.allowedEdit}>
                            <Row>

                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        {this.state.shipmentMode === "FCL"?"":
                        <Col lg="3">
                            <Label for="length"><strong>Gross Weight ( Lbs )</strong></Label>
                            <input
                                type="text"
                                className="form-control ipt1"
                                name="grossWeightLbs"
                                placeholder="Gross Weight"
                                id="height" style={{borderRadius: '50px'}}
                                onChange={this.handleChangeGross}
                                value={this.state.grossWeightLbs}
                            />
                        </Col>}
                        <Col lg="3">
                            <Label for="length"><strong>Gross Weight {this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"?"( Tons )":"( Kgs )"}</strong></Label>
                            <input
                                type="text"
                                className="form-control ipt1"
                                name="grossWeight"
                                placeholder="Gross Weight"
                                id="height" style={{borderRadius: '50px'}}
                                onChange={this.handleChangeGross}
                                value={this.state.grossWeight}
                            />
                        </Col>

                        <Col lg="3">
                            <Label for="length"><strong>Total Pieces</strong></Label>
                            <input
                                type="text"
                                className="form-control ipt1"
                                name="totalPieces"
                                placeholder="Total Pieces"
                                id="height" style={{borderRadius: '50px'}}
                                onChange={this.handleChange}
                                value={this.state.totalPieces}
                                readOnly={this.state.uploadedDimensions.length > 0 ? true : false}
                            />
                        </Col>

                        <Col lg="3">
                        { this.props.zipaSuggestion == true ?
                                    <>
                                    {suggestionQueryFor === "Ocean"
                                    ?
                                         
                                    <Label for="length"><strong>Volume Weight (CBM)</strong></Label>
                                    :
                                    <Label for="length"><strong>Volume Weight (Kgs)</strong></Label>
                                    }
                                    </>:
                                    <>
                                    {this.state.queryFor =="Ocean"?
                                         
                                         <Label for="length"><strong>Volume Weight (CBM)</strong></Label>
                                         :
                                         <Label for="length"><strong>Volume Weight (Kgs)</strong></Label>
                                    }
                                    </>}
                            
                            <input
                                type="text"
                                className="form-control ipt1"
                                name="volumeWeight"
                                placeholder="Volume Weight"
                                id="height" style={{borderRadius: '50px'}}
                                onChange={this.handleChange}
                                value={this.state.volumeWeight}
                                readOnly={this.state.uploadedDimensions.length > 0 ? true : false}
                            />
                        </Col>
                    </Row>

                    <div className="AAA-add-query-dimension-6 mt-2"
                        // hidden={!this.state.allowedEdit}
                    >

                        {this.state.selectedOption  !== 'Calculate Dimension By Package Details'?
                            <>
                                <Row>
                                    <Col lg="12"
                                        // hidden={!this.state.allowedEdit}
                                    >
                                        <div className="AAA-add-query-dimension-3">
                                            <label><strong>Dimension Type:</strong></label>
                                            <ul>
                                                <li>
                                                    <CustomInput
                                                        type="radio"
                                                        id="CM"
                                                        label="CM"
                                                        name="selectedDimensionType"
                                                        value={"CM"}
                                                        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                        checked={this.state.selectedDimensionType === "CM"}
                                                    />
                                                </li>
                                                {/*<li>*/}
                                                {/*    <CustomInput*/}
                                                {/*        type="radio"*/}
                                                {/*        id="FT"*/}
                                                {/*        label="FT"*/}
                                                {/*        name="selectedDimensionType"*/}
                                                {/*        value={"FT"}*/}
                                                {/*        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}*/}
                                                {/*        checked={this.state.selectedDimensionType === "FT"}*/}
                                                {/*    />*/}
                                                {/*</li>*/}
                                                <li>
                                                    <CustomInput
                                                        type="radio"
                                                        id="MM"
                                                        label="MM"
                                                        name="selectedDimensionType"
                                                        value={"MM"}
                                                        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                        checked={this.state.selectedDimensionType === "MM"}
                                                    />
                                                </li>
                                                <li>
                                                    <CustomInput
                                                        type="radio"
                                                        id="INCHES"
                                                        label="INCHES"
                                                        name="selectedDimensionType"
                                                        value={"INCHES"}
                                                        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                        checked={this.state.selectedDimensionType === "INCHES"}
                                                    />
                                                </li>
                                                <li>
                                                    <CustomInput
                                                        type="radio"
                                                        id="METERS"
                                                        label="METERS"
                                                        name="selectedDimensionType"
                                                        value={"METERS"}
                                                        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                        checked={this.state.selectedDimensionType === "METERS"}
                                                    />
                                                </li>
                                            </ul>
                                            <div className="clearfix"></div>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="AAA-add-query-dimension-7 calculate-inputs">
                                            <hr/>
                                            <div className="AAA-editbooking-data-20">
                                                <label hidden={!this.state.allowedEdit}>Dimensions Info.</label>
                                            </div>
                                            <Formik hidden={!this.state.allowedEdit} validationSchema={formSchema}
                                                    onSubmit={this.addNewDimension} initialValues={{}}>
                                                {({errors, touched}) => (
                                                    <Form hidden={!this.state.allowedEdit}>
                                                        <Label>{this.state.dimensionOverload}</Label>
                                                        <ul>
                                                            <li>
                                                                <FormGroup>
                                                                    <Label for="length">Length</Label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="length"
                                                                        id="length"
                                                                        placeholder="Length"
                                                                        value={this.state.length}
                                                                    />
                                                                    {/*{errors.length && touched.length && (*/}
                                                                    {/*    <div className="invalid-feedback d-block">*/}
                                                                    {/*        {errors.length}*/}
                                                                    {/*    </div>*/}
                                                                    {/*)}*/}
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Label for="width">Width</Label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="width"
                                                                        placeholder="Width"
                                                                        id="width"
                                                                        value={this.state.width}
                                                                    />
                                                                    {/*{errors.width && touched.width && (*/}
                                                                    {/*    <div className="invalid-feedback d-block">*/}
                                                                    {/*        {errors.width}*/}
                                                                    {/*    </div>*/}
                                                                    {/*)}*/}
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Label for="height">Height</Label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="height"
                                                                        placeholder="Height"
                                                                        id="height"
                                                                        value={this.state.height}
                                                                    />
                                                                    {/*{errors.height && touched.height && (*/}
                                                                    {/*    <div className="invalid-feedback d-block">*/}
                                                                    {/*        {errors.height}*/}
                                                                    {/*    </div>*/}
                                                                    {/*)}*/}
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Label for="pieces">Pieces</Label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="pieces"
                                                                        placeholder="Pieces"
                                                                        value={this.state.pieces}
                                                                    />
                                                                    {/*{errors.pieces && touched.pieces && (*/}
                                                                    {/*    <div className="invalid-feedback d-block">*/}
                                                                    {/*        {errors.pieces}*/}
                                                                    {/*    </div>*/}
                                                                    {/*)}*/}
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Label for="selectWeight">Select Weight</Label>
                                                                    <select
                                                                        id="selectWeight"
                                                                        className="form-control"
                                                                        name="selectWeight"
                                                                        placeholder="Select Weight"
                                                                        value={this.state.selectWeight}
                                                                        onChange={this.handleChange}
                                                                    >
                                                                        
                                                                        <option value="Lbs">Lbs</option>
                                                                        <option value="Kgs">Kgs</option>

                                                                    </select>
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Label for="Gw/Pc">Weight Per Piece</Label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="gw_pc"
                                                                        placeholder="Gw/Pc"
                                                                        value={this.state.gw_pc}
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Label for="packingType">Packing Type</Label>
                                                                    <select
                                                                        id="packingType"
                                                                        className="form-control"
                                                                        name="packingType"
                                                                        placeholder="Select Packing Type"
                                                                        value={this.state.packingType}
                                                                        onChange={this.handleChange}
                                                                    >
                                                                        <option>Select Packing type</option>
                                                                        <option value="Bag">Bag</option>
                                                                        <option value="Bulk Bag">Bulk Bag</option>
                                                                        <option value="Break Bulk">Break Bulk</option>
                                                                        <option value="Bundle">Bundle</option>
                                                                        <option value="Bottle">Bottle</option>
                                                                        <option value="Box">Box</option>
                                                                        <option value="Basket">Basket</option>
                                                                        <option value="Case">Case</option>
                                                                        <option value="Container">Container</option>
                                                                        <option value="Coil">Coil</option>
                                                                        <option value="Cradle">Cradle</option>
                                                                        <option value="Crate">Crate</option>
                                                                        <option value="Carton">Carton</option>
                                                                        <option value="Cylinder">Cylinder</option>
                                                                        <option value="Dozen">Dozen</option>
                                                                        <option value="Drum">Drum</option>
                                                                        <option value="Envelope">Envelope</option>
                                                                        <option value="Gross">Gross</option>
                                                                        <option value="Mix">Mix</option>
                                                                        <option value="Piece">Piece</option>
                                                                        <option value="Package">Package</option>
                                                                        <option value="Pallet">Pallet</option>
                                                                        <option value="Reel">Reel</option>
                                                                        <option value="Roll">Roll</option>
                                                                        <option value="Sheet">Sheet</option>
                                                                        <option value="Skid">Skid</option>
                                                                        <option value="Spool">Spool</option>
                                                                        <option value="Tube">Tube</option>
                                                                        <option value="Unit">Unit</option>

                                                                    </select>
                                                                </FormGroup>
                                                            </li>
                                                            <li style={{width: '20%!important'}}>
                                                                <FormGroup className="ml-1">
                                                                    <Label className="invisible"> Action</Label>
                                                                    <Button
                                                                        type="submit"
                                                                        color="success"
                                                                        name="addDimension"
                                                                        onClick={this.addNewDimension}
                                                                    ><span className="flaticon-add-2"></span>Add</Button>
                                                                </FormGroup>
                                                            </li>
                                                        </ul>
                                                        <div className="clearfix"></div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="AAA-add-query-dimension-7 calculate-inputs">
                                    <div className="AAA-editbooking-data-21">
                                        <Label onClick={this.toggleDimensionCollapse}>Entered Dimensions</Label>
                                    </div>
                                    <Collapse isOpen={this.state.dimensionCollapse}>
                                        {this.state.uploadedDimensions ? this.state.uploadedDimensions.map((dimensionRow, dimensionIndex) => {
                                            return (
                                                <Row>
                                                    <Col lg="12">
                                                        <ul key={dimensionIndex}>
                                                            <li>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="length"
                                                                        placeholder="Length"
                                                                        readOnly
                                                                        value={dimensionRow.length}
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="width"
                                                                        placeholder="Width"
                                                                        readOnly
                                                                        value={dimensionRow.width}
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="height"
                                                                        readOnly
                                                                        placeholder="Height"
                                                                        value={dimensionRow.height}
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="pieces"
                                                                        readOnly
                                                                        placeholder="Pieces"
                                                                        value={dimensionRow.pieces}
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>

                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="selectWeight"
                                                                        readOnly
                                                                        placeholder="Select Weight"
                                                                        value={dimensionRow.selectWeight}
                                                                    />

                                                                    {/*<select*/}
                                                                    {/*    id="selectWeight"*/}
                                                                    {/*    className="form-control"*/}
                                                                    {/*    name="selectWeight"*/}
                                                                    {/*    placeholder="Select Weight"*/}
                                                                    {/*    value={dimensionRow.selectWeight}*/}
                                                                    {/*>*/}
                                                                    {/*    <option value="">Select Weight Type</option>*/}
                                                                    {/*    <option value="Lbs">Lbs</option>*/}
                                                                    {/*    <option value="Kgs">Kgs</option>*/}

                                                                    {/*</select>*/}
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="gw_pc"
                                                                        placeholder="Gw/Pc"
                                                                        readOnly
                                                                        value={dimensionRow.gw_pc}
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup>

                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="packingType"
                                                                        readOnly
                                                                        placeholder="Select Weight"
                                                                        value={dimensionRow.packingType}
                                                                    />

                                                                </FormGroup>
                                                            </li>
                                                            <li>
                                                                <FormGroup className="ml-1">
                                                                    <Button
                                                                        disabled={!this.state.allowedEdit}
                                                                        type="button"
                                                                        color="danger"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            let dimension = this.state.uploadedDimensions;
                                                                            dimension.splice(dimensionIndex, 1);
                                                                            this.setState({uploadedDimensions: dimension}, () => {
                                                                                this.props.dimensionOverallChanges({
                                                                                    weight: this.calculateOverallDimensionData(),
                                                                                    dimensionArray: this.state.uploadedDimensions,
                                                                                    selectedDimensionType: this.state.selectedDimensionType,
                                                                                })
                                                                            });
                                                                        }}
                                                                        name="addDimension"
                                                                    >
                                                                        <span
                                                                            className="flaticon-delete-1"></span> Remove</Button>
                                                                </FormGroup>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            );
                                        }) : ""}
                                    </Collapse>
                                </div>
                            </>
                            :""}
                        <div className="dimension-upload">
                            <hr/>
                            <div className="AAA-editbooking-data-20">
                                <label> <strong>Attachments ( Upload Related Files )</strong> </label><br/>
                                <label htmlFor="width" className="">Please upload your commercial invoice, packing list and other related documents to the shipment</label>

                            </div>

                            <div className="d-flex flex-wrap">

                                <div className="width-3">

                                    <input
                                        style={{borderRadius: '50px'}}
                                        name="remarks"
                                        onChange={this.handleChangeRe}
                                        type="text"
                                        className="form-control ipt1"
                                        placeholder="Mention Document Name"
                                        id="remarks"
                                        value={this.state.remarks}
                                    />
                                </div>


                                <UploadFilesCloudes
                                    name={this.state.fileName}
                                    fromEdocket={false}
                                    placeHolder="Upload Files"
                                    isSingleUpload={true}
                                    isUploadingFiles={isUploadingFiles => {
                                        // alert(JSON.stringify(isUploadingFiles))
                                        this.setState({
                                            isUploadingFiles: isUploadingFiles
                                        })
                                    }}
                                    onUploadFinish={(name, uploadedUrls) => {
                                        let attachments = this.state.attachments
                                        if (uploadedUrls != undefined && uploadedUrls != "") {
                                            attachments.push({
                                                name: this.state.remarks,
                                                link: uploadedUrls
                                            })
                                            this.setState({
                                                attachments: attachments
                                            })
                                            this.props.attachmentss(this.state.attachments)
                                        } else {
                                            toast.error("Please Select File", {
                                                position: "top-left",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        }
                                    }}

                                    returnResponce={responce => {
                                        this.setState({
                                            returnResponce: responce
                                        }, () => {

                                        })
                                    }}
                                />




                                {/*<div className="form-group padding-lefts">*/}
                                {/*    <label htmlFor="Upload Shipper Master Data" className="origin-label">Upload Shipper Master Data</label>*/}
                                {/*    <div className="custom-file "><input type="file" className="custom-file-input" id="customFile" name="filename" /><label className="custom-file-label" htmlFor="customFile">Choose file</label></div>*/}
                                {/*</div>*/}
                                {/*<div className="form-group ml-2"><label htmlFor="Add/Upload Master CSV" className="origin-label visi-hide">Data Format</label>*/}
                                {/*    <button className="upload-btn"><span className="flaticon-download-1" />Upload</button>*/}
                                {/*</div>*/}
                            </div>
                            <div className="">
                                {this.state.attachments.map((item5) => {
                                    return (<p><b><a href={item5.link}>File Name : <span
                                        style={{color: "blue"}}>{item5.name}</span> </a></b></p>)
                                })}
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>)

    }

    isTotalPiecesExceed(values) {

        let isInValidEntry = false;
        let addedPiecesReport = this.calculateOverallDimensionData();
        // let TotalFirstPieces = parseInt(addedPiecesReport.totalPieces)  + parseInt(values.pieces) ;
        // if (TotalFirstPieces > this.state.totalPieces) {
        //     if (!this.state.allowedPiecesOverride) {
        //         // toastr.success("Number Of Pieces Mismatch. ", "Please Change In Mandatory Tab",
        //         //     {
        //         //         transitionIn: "bounceIn",
        //         //         transitionOut: "bounceOut"
        //         //     }
        //         // );
        //         toast.error("Number Of Pieces Mismatch. Please Change In Mandatory Tab", {
        //             position: "top-left",
        //             autoClose: 3000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });
        //
        //         return true;
        //     } else {
        //         // toastr.success("", "Pieces Override allowed")
        //         // toast.error("Pieces Override allowed", {
        //         //     position: "top-left",
        //         //     autoClose: 3000,
        //         //     hideProgressBar: false,
        //         //     closeOnClick: true,
        //         //     pauseOnHover: true,
        //         //     draggable: true,
        //         //     progress: undefined,
        //         // });
        //     }
        //
        // }

        if (addedPiecesReport.totalPieces === 0) {
            return false;
        } else {
            let wtType=parseFloat(this.state.uploadedDimensions[0].selectWeight)
            let isNeedToAdd = parseFloat(this.state.uploadedDimensions[0].gw_pc) > 0;
            let isGrossAddedInRow = !isNaN(parseFloat(values.gw_pc));

            if (isNeedToAdd && !isGrossAddedInRow) {
                toastr.success(
                    "Gross Weight if added in one of dimension. ",
                    "Required in all ",
                    {
                        transitionIn: "bounceIn",
                        transitionOut: "bounceOut"
                    }
                );
                return true;
            } else if (!isNeedToAdd && isGrossAddedInRow) {
                toastr.success(
                    "Gross Weight if added in one of dimension. ",
                    "Required in all ",
                    {
                        transitionIn: "bounceIn",
                        transitionOut: "bounceOut"
                    }
                );
                return true;
            } else {
                isInValidEntry = false;
            }
        }
        return isInValidEntry;
    }

    calculateVolume = values => {
        let length = parseInt(values.length);
        let width = parseInt(values.width);
        let height = parseInt(values.height);
        let pieceCount = parseInt(values.pieces);
        if(this.state.queryFor !== "Air")
        {
            if (this.state.selectedDimensionType === "CM") {
                return (length * width * height * pieceCount) / 1000000;
            } else if (this.state.selectedDimensionType === "FT") {
                return (length * width * height * pieceCount) / 0.211888;
            } else if (this.state.selectedDimensionType === "MM") {
                return (length * width * height * pieceCount) / 1000000000;
            } else if (this.state.selectedDimensionType === "INCHES") {
                return (length * width * height * pieceCount) / 61023.8;
            } else if (this.state.selectedDimensionType === "METERS") {
                return (length * width * height * pieceCount);
            }
        }
        else
        {
            if (this.state.selectedDimensionType === "CM") {
                return (length * width * height * pieceCount) / 6000;
            } else if (this.state.selectedDimensionType === "FT") {
                return (length * width * height * pieceCount) / 0.211888;
            } else if (this.state.selectedDimensionType === "MM") {
                return (length * width * height * pieceCount) / 6000000;
            } else if (this.state.selectedDimensionType === "INCHES") {
                return (length * width * height * pieceCount) / 366;
            } else if (this.state.selectedDimensionType === "METERS") {
                return (length * width * height * pieceCount) / 0.006;
            }
        }

    };

    calculateOverallDimensionData() {

        let uploadedDimensions = this.state.uploadedDimensions;
        let totalVolume = this.state.volumeWeight
        let totalGross = this.state.grossWeight
        let totalGrossLbs = this.state.grossWeightLbs
        let totalPieces = this.state.totalPieces

        if (uploadedDimensions.length > 0) {
            totalVolume = 0
            totalGross = 0
            totalPieces = 0
        }
        for (let i = 0; i < uploadedDimensions.length; i++) {
            let vol = this.calculateVolume(uploadedDimensions[i])
            if (uploadedDimensions[i].selectWeight == "Lbs" && uploadedDimensions[i].converetd==undefined) {
                uploadedDimensions[i].gw_pc = parseFloatValue(uploadedDimensions[i].gw_pc) * 0.453592
                uploadedDimensions[i].selectWeight = "Kgs"
                uploadedDimensions[i].converetd = true
            }
            let gross = parseFloatValue(uploadedDimensions[i].gw_pc) * parseInt(uploadedDimensions[i].pieces)
            totalVolume = parseFloatValue(totalVolume + vol)
            if(this.state.shipmentMode==="FCL"){
                let totalGross1 = parseFloatValue(parseFloatValue(totalGross) + gross);
                totalGross = (totalGross1/1000).toFixed(2)
                totalGrossLbs = parseFloatValue(totalGross) * 2.20462
            }else{
                totalGross = parseFloatValue(parseFloatValue(totalGross) + gross);
                totalGrossLbs = parseFloatValue(totalGross) * 2.20462
            }

            totalPieces = parseInt(totalPieces + parseInt(uploadedDimensions[i].pieces));
        }
        this.setState({uploadedDimensions})

        if (uploadedDimensions.length > 0) {
            this.currentEnteredData.totalVolume = totalVolume
            this.currentEnteredData.totalGross = totalGross
            this.currentEnteredData.totalGrossLbs = totalGrossLbs
            this.currentEnteredData.totalPieces = totalPieces
        } else {
            this.currentEnteredData.totalVolume = totalVolume
            this.currentEnteredData.totalGross = this.state.grossWeight
            this.currentEnteredData.totalGrossLbs = this.state.grossWeightLbs
            this.currentEnteredData.totalPieces = totalPieces
        }

        try {
            if(this.state.queryFor !=="Air")
            {
                let volumeWeight = parseFloatValue(this.currentEnteredData.totalVolume);
                let grossWeight = parseFloatValue(this.currentEnteredData.totalGross) / 1000;
                let lclvol = 0;

                if (volumeWeight < 1)
                {lclvol = 1}
                else
                {lclvol = volumeWeight}
                if(this.state.shipmentMode==="LCL")
                {


                    grossWeight > lclvol ?
                        this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                        :
                        this.currentEnteredData.totalChargeable = parseInt(lclvol)
                }
                else
                {
                    grossWeight > volumeWeight ?
                        this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                        :
                        this.currentEnteredData.totalChargeable = parseInt(volumeWeight)
                }


                if (this.currentEnteredData.totalGross === "0") {
                    this.currentEnteredData.totalDensity = "0"
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.currentEnteredData.totalDensity = parseFloat(density).toFixed(2)
                }
            }
            else
            {
                let volumeWeight = parseFloatValue(this.currentEnteredData.totalVolume);
                let grossWeight = parseFloatValue(this.currentEnteredData.totalGross) ;

                grossWeight > volumeWeight ?
                    this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                    :
                    this.currentEnteredData.totalChargeable = parseInt(volumeWeight)



                if (this.currentEnteredData.totalGross === "0") {
                    this.currentEnteredData.totalDensity = "0"
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.currentEnteredData.totalDensity = parseFloat(density).toFixed(2)
                }
            }


            // }
            //   debugger
        } catch (e) {
            // alert(e)
        }

        this.setState({
            grossWeight: parseFloatValue(this.currentEnteredData.totalGross),
            grossWeightLbs: parseFloatValue(this.currentEnteredData.totalGrossLbs),
            volumeWeight: parseFloatValue(this.currentEnteredData.totalVolume).toFixed(3),
            chargeableWeight: parseFloatValue(this.currentEnteredData.totalChargeable),
            calculatedDensity: parseFloatValue(this.currentEnteredData.totalDensity),
        })
        return this.currentEnteredData;

    }

    calculateChargeable = async () => {
        try {

            if (this.state.volumeWeight && this.state.grossWeight) {

                let volumeWeight = parseInt(this.state.volumeWeight);
                let grossWeight = parseInt(this.state.grossWeight);
                let checkVol="";
                if(volumeWeight < 1 )
                {
                    checkVol=1;
                }
                else
                {
                    checkVol=volumeWeight
                }
                if(this.state.shipmentMode==="LCL")
                {

                    grossWeight > checkVol
                        ? this.setState({
                            chargeableWeight: grossWeight
                        })
                        : this.setState({
                            chargeableWeight: checkVol
                        });
                }
                else
                {
                    grossWeight > volumeWeight
                        ? this.setState({
                            chargeableWeight: grossWeight
                        })
                        : this.setState({
                            chargeableWeight: volumeWeight
                        });
                }


                if (this.state.grossWeight === "0") {
                    this.setState({
                        calculatedDensity: "0"
                    });
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.setState({
                        calculatedDensity: parseFloat(density).toFixed(2)
                    });
                }
            }
        } catch (e) {
        }
    };

    createDynamicDimensionUi() {
        return this.state.uploadedDimensions.map((dimensionRow, dimensionIndex) => {
            {
                // console.log(JSON.stringify(dimensionRow))
            }
            return (

                <Row className="ml-1" key={dimensionIndex}>
                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control"
                            name="length"
                            placeholder="Length"
                            readOnly
                            value={dimensionRow.length}
                        />
                    </FormGroup>
                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control"
                            name="width"
                            placeholder="Width"
                            readOnly
                            value={dimensionRow.width}
                        />
                    </FormGroup>
                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control"
                            name="height"
                            readOnly
                            placeholder="Height"
                            value={dimensionRow.height}
                        />
                    </FormGroup>

                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control"
                            name="pieces"
                            readOnly
                            placeholder="Pieces"
                            value={dimensionRow.pieces}
                        />
                    </FormGroup>
                    <li>
                        <FormGroup className="ml-1">
                            <select
                                id="selectWeight"
                                className="form-control"
                                name="selectWeight"
                                readOnly
                                placeholder="Select Weight"
                                value={dimensionRow.selectWeight}
                            >
                                <option value="">Select Weight Type</option>
                                <option value="Lbs">Lbs</option>
                                <option value="Kgs">Kgs</option>

                            </select>
                        </FormGroup>
                    </li>
                    <FormGroup className="ml-1">
                        <Input
                            type="text"
                            className="form-control"
                            name="gw_pc"
                            placeholder="Gw/Pc"
                            readOnly
                            value={dimensionRow.gw_pc}
                        />
                    </FormGroup>

                    <FormGroup className="ml-1">
                        <Button
                            type="button"
                            color="danger"
                            className="form-control"
                            onClick={e => {
                                e.preventDefault();
                                let dimension = this.state.uploadedDimensions;
                                dimension.splice(dimensionIndex, 1);
                                this.setState({uploadedDimensions: dimension});
                            }}
                            name="addDimension"
                        >
                            Remove
                        </Button>
                    </FormGroup>
                </Row>
            );
        });
    }


}

const propTypes = {

    totalPieces: PropTypes.string,
    grossWeight: PropTypes.string,
    volumeWeight: PropTypes.string,
    chargeableWeight: PropTypes.string,
    allowedEdit: PropTypes.bool,

};

const defaultProps = {
    totalPieces: 0,
    grossWeight: 0,
    volumeWeight: 0,
    chargeableWeight: 0,
    allowedEdit: true,

};

DimensionViewCalculateRatePop.propTypes = propTypes;
DimensionViewCalculateRatePop.defaultProps = defaultProps;


export default DimensionViewCalculateRatePop;

