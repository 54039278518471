// SPOTRATE MASTER MANAGER ACTION HERE
import {
    NEWSFLASH_MASTER_MANAGER_REQUEST,
    NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE,
    NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE_FAILURE,
    NEWSFLASH_MASTER_MANAGER_DELETE,
    NEWSFLASH_MASTER_MANAGER_DELETE_RESPONSE,
    NEWSFLASH_MASTER_MANAGER_CREATE,
    NEWSFLASH_MASTER_MANAGER_CREATE_RESPONSE,
    NEWSFLASH_MASTER_MANAGER_PREVIEW,
    NEWSFLASH_MASTER_MANAGER_UPDATE,
    NEWSFLASH_MASTER_MANAGER_UPDATE_RESPONSE,
    NEWS_TICKER_REQUEST,
    NEWS_TICKER_REQUEST_RESPONSE_SUCCESS,
    NEWS_TICKER_REQUEST_RESPONSE_FAILURE,
    ADDS_TICKER_REQUEST,
    ADDS_TICKER_REQUEST_RESPONSE_FAILURE,
    ADDS_TICKER_REQUEST_RESPONSE_SUCCESS

} from "../actions";




// on spot Rate Manager Fecth response 
export const onNewsFlashFetchedResponse = (NewsFlashResponse) => ({
    type: NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE,
    payload: NewsFlashResponse
});

// on spot Rate manager fecth Failed
export const onNewsFlashFetchedResponseFailed = (message) => ({
    type: NEWSFLASH_MASTER_MANAGER_REQUEST_RESPONSE_FAILURE,
    payload: { message }
});


export const saveNewsFlashResponse = (message) => ({
    type: NEWSFLASH_MASTER_MANAGER_CREATE_RESPONSE,
    payload: {message}
});

export const deleteNewsFlashResponse = (message) => ({
    type: NEWSFLASH_MASTER_MANAGER_DELETE_RESPONSE,
    payload: {message}
});



export const updateNewsFlashResponse = (message) => ({
    type: NEWSFLASH_MASTER_MANAGER_UPDATE_RESPONSE,
    payload: {message}
});





export const onNewsTickerRequested = (requestParameters) => ({
    type: NEWS_TICKER_REQUEST,
    payload: requestParameters
});

export const onNewsTickerRespondBackSuccess = (receivedParameters) => ({
    type: NEWS_TICKER_REQUEST_RESPONSE_SUCCESS,
    payload: receivedParameters
});
export const onNewsTickerRespondBackFailure = (message) => ({
    type: NEWS_TICKER_REQUEST_RESPONSE_FAILURE,
    payload: {message}
});

export const onAddsTickerRequested = (requestParameters) => ({
    type: ADDS_TICKER_REQUEST,
    payload: requestParameters
});

export const onAddsTickerRespondBackSuccess = (receivedParameters) => ({
    type: ADDS_TICKER_REQUEST_RESPONSE_FAILURE,
    payload: receivedParameters
});
export const onAddsTickerRespondBackFailure = (message) => ({
    type: ADDS_TICKER_REQUEST_RESPONSE_SUCCESS,
    payload: {message}
});
