import React, { Component} from "react";
import AppLayout from "../../../layout/AppLayout";
import { PAGE_PATHS } from "../../../util/PageRoute";
import { toast } from "react-toastify";
import RestClient from "../../../util/RestClient";
import MetaTags from "react-meta-tags";
import { history } from "../../../helpers/history";

import {convertDateISOToCustomiseFormat,
} from "../../../util/CommonUtility";
import { Button} from "reactstrap";
import {
    SEARCH_VEHICLE_FASTAGE_DETAILS,
    SEARCH_TRACKING_BY_CONTAINER,
    SEARCH_TRACKING_BY_BOOKING_REFERENCE_NO,
    SEARCH_TRACKING_BY_BL,
    GET_TRACKING_SEARCH_API,
    SEARCH_DESIGN_BY_BL,
    GET_TRACKING_SEARCH_API_AIR,
    GET_TRACKING_SEARCH_API_OCEAN,
    SEARCH_TRACKING_BY_NUMBER,
    SEARCH_TRACKING_BY_NUMBER_AIR,
    SEARCH_TRACKING_BY_NUMBER_OCEAN,
    SEARCH_TRACKING_BY_NUMBER_COURIER,
    SEARCH_TRACKING_BY_NUMBER_ROAD,
    GET_TRACKING_SEARCH_API_ROAD, GET_TRACKING_SEARCH_API_COURIER,
} from "../../../constants";

import Map from "../dashboards/Map"
import {faAngleDown, faAngleUp, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class TrackingSearchPageDemoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SearchThroughBookingRefrence:false,
            InvoiceId: "",
            awbStatus: "",
            viewDetails: false,
            searchNumber: "",
            viewTrack: false,
            hideBottomButton: false,
            latest:true,
            mapUrl : "https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.2500081408416126-1677148367410.png",
        };

    }

    componentDidMount() {
        let quoteFor = window.location.href.split("/")[window.location.href.split("/").length - 2];
        let blNo = window.location.href.split("/")[4]
        if(quoteFor === "Air" || quoteFor === "Ocean" || quoteFor === "Road" || quoteFor === "Courier")
        {
            blNo = window.location.href.split("/")[window.location.href.split("/").length - 1];
        }

        this.setState({
            searchNumber: blNo,
            quoteFor:quoteFor,
            TrackingValue:"AWB"
        }, () => {
            if (blNo) {
                this.SearchTrack()
            } else {
                this.setState({
                    hideBottomButton: true
                })
            }

        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value },
            () => {

            })
    }
    SearchTrack = () => {
        if (this.state.TrackingValue === "containerNo") {
            this.SearchWithcontainerNo({ trackingReference: this.state.searchNumber ,container:true,blNo:false}).then(responce => {
                if (responce.success) {
                    let TrackingDatabyContainer = responce && responce.result ? responce.result : [];
                    let container = TrackingDatabyContainer && TrackingDatabyContainer.containerNo ? TrackingDatabyContainer.containerNo:""
                    let value = TrackingDatabyContainer && TrackingDatabyContainer.events[TrackingDatabyContainer.events.length - 1]
                    let firstLocation = TrackingDatabyContainer && TrackingDatabyContainer.events[0] && TrackingDatabyContainer.events[0].transportCall && TrackingDatabyContainer.events[0].transportCall.location && TrackingDatabyContainer.events[0].transportCall.location.locationName ? TrackingDatabyContainer.events[0].transportCall.location.locationName : ""
                    let firstLocations = TrackingDatabyContainer && TrackingDatabyContainer.origin ? TrackingDatabyContainer.origin:firstLocation
                    let lastLocations = value && value.transportCall && value.transportCall.location && value.transportCall.location.locationName ? value.transportCall.location.locationName : ""
                    let lastLocation = TrackingDatabyContainer && TrackingDatabyContainer.destination  ? TrackingDatabyContainer.destination  : lastLocations

                    this.setState({
                        lastLocation: lastLocation,
                        firstLocation:firstLocations,
                        lastLocations:lastLocations,
                        container:container,
                        TrackingDatabyContainer: TrackingDatabyContainer,
                        SearchWithContainerNo: true,
                        viewDetails: false,
                        viewTrack: true,
                        MapView:false
                    })
                } else {
                    toast.error(responce.message, {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        }
        else  if (this.state.TrackingValue === "blNo") {
            this.SearchWithcontainerNo({ trackingReference: this.state.searchNumber ,blNo:true,container:false}).then(responce => {
                if (responce.success) {
                    let TrackingDatabyContainer = responce && responce.result ? responce.result : [];
                    let container = TrackingDatabyContainer && TrackingDatabyContainer.containerNo ? TrackingDatabyContainer.containerNo:""

                    let value = TrackingDatabyContainer && TrackingDatabyContainer.events[TrackingDatabyContainer.events.length - 1]
                    let firstLocation = TrackingDatabyContainer && TrackingDatabyContainer.events[0] && TrackingDatabyContainer.events[0].transportCall && TrackingDatabyContainer.events[0].transportCall.location && TrackingDatabyContainer.events[0].transportCall.location.locationName ? TrackingDatabyContainer.events[0].transportCall.location.locationName : ""
                    let firstLocations = TrackingDatabyContainer && TrackingDatabyContainer.origin ? TrackingDatabyContainer.origin:firstLocation

                    let lastLocations = value && value.transportCall && value.transportCall.location && value.transportCall.location.locationName ? value.transportCall.location.locationName : ""
                    let lastLocation = TrackingDatabyContainer && TrackingDatabyContainer.destination  ? TrackingDatabyContainer.destination  : lastLocations

                    this.setState({
                        lastLocation: lastLocation,
                        lastLocations:lastLocations,
                        container:container,
                        firstLocation:firstLocations,
                        TrackingDatabyContainer: TrackingDatabyContainer,
                        SearchWithContainerNo: true,
                        viewDetails: false,
                        viewTrack: true,
                        MapView:false
                    })
                }
                else {
                    this.SearchWithNumber({ blNo: this.state.searchNumber }).then(responce => {
                        if (responce.success) {

                            let trackingBlData = responce && responce.result ? responce.result : "";
                            let quoteId = responce && responce.result && responce.result.quoteId && responce.result.quoteId._id ? responce.result.quoteId._id : "";
                            let originName = trackingBlData && trackingBlData.originAirport && trackingBlData.originAirport.name ? trackingBlData.originAirport.name : "";
                            let destinationName = trackingBlData && trackingBlData.destinationAirport && trackingBlData.destinationAirport.name ? trackingBlData.destinationAirport.name : "";
                            let queryFor = trackingBlData && trackingBlData.queryFor ? trackingBlData.queryFor : "";
                            let pickUpCountry = trackingBlData && trackingBlData.Shipper && trackingBlData.Shipper.Country ? trackingBlData.Shipper.Country : trackingBlData && trackingBlData.shipperBranchId && trackingBlData.shipperBranchId.countryId && trackingBlData.shipperBranchId.countryId.name ? trackingBlData.shipperBranchId.countryId.name : "";
                            let dropOffCountry = trackingBlData && trackingBlData.Consignee && trackingBlData.Consignee.Country ? trackingBlData.Consignee.Country : trackingBlData && trackingBlData.consigneeBranchId && trackingBlData.consigneeBranchId.countryId && trackingBlData.consigneeBranchId.countryId.name ? trackingBlData.consigneeBranchId.countryId.name : "";
                            let pickUpZipCode = trackingBlData && trackingBlData.Shipper && trackingBlData.Shipper.ZipCode ? trackingBlData.Shipper.ZipCode : "";
                            let dropOffZipCode = trackingBlData && trackingBlData.Consignee && trackingBlData.Consignee.ZipCode ? trackingBlData.Consignee.ZipCode : "";
                            let InvoiceId = responce && responce.result && responce.result.invoiceData && responce.result.invoiceData._id ? responce.result.invoiceData._id : "";
                            let InvoiceStatus = responce && responce.result && responce.result.invoiceData && responce.result.invoiceData.approvalStatus ? responce.result.invoiceData.approvalStatus : "";

                            this.setState({
                                InvoiceStatus: InvoiceStatus,
                                viewTrack: !this.state.viewTrack, destinationName: destinationName,
                                originName: originName,
                                queryFor: queryFor,
                                InvoiceId: InvoiceId,
                                pickUpCountry: pickUpCountry,
                                pickUpZipCode: pickUpZipCode,
                                dropOffZipCode: dropOffZipCode, dropOffCountry: dropOffCountry,
                                data: responce.result && responce.result.data ? responce.result.data : "",
                                MapView:false,
                                quoteId: quoteId

                            },()=>{
                                this.TrackingApi({ quoteId: quoteId }).then(responce => {
                                    if (responce.success && responce.result) {

                                        let trackingData = responce && responce.result ? responce.result : "";
                                        let originName = trackingData && trackingData.queryData && trackingData.queryData.originAirport && trackingData.queryData.originAirport.name ? trackingData.queryData.originAirport.name : "";
                                        let destinationName = trackingData && trackingData.queryData && trackingData.queryData.destinationAirport && trackingData.queryData.destinationAirport.name ? trackingData.queryData.destinationAirport.name : "";
                                        let queryFor = trackingData && trackingData.queryData && trackingData.queryData.queryFor ? trackingData.queryData.queryFor : "";
                                        this.setState({
                                            data: responce.result && responce.result.data ? responce.result.data : "",
                                        })
                                    }
                                })
                            })

                        } else {

                        }
                    })

                    // toast.error(responce.message, {
                    //     position: "top-left",
                    //     autoClose: 3000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // });
                }
            })


        }
        else  if (this.state.TrackingValue === "AWB" || this.state.TrackingValue === "Docket Number") {
            this.SearchWithNumber({ blNo: this.state.searchNumber }).then(responce => {
                if (responce.success) {

                    let trackingBlData = responce && responce.result ? responce.result : "";
                    let quoteId = responce && responce.result && responce.result.quoteId && responce.result.quoteId._id ? responce.result.quoteId._id : "";
                    let originName = trackingBlData && trackingBlData.originAirport && trackingBlData.originAirport.name ? trackingBlData.originAirport.name : "";
                    let destinationName = trackingBlData && trackingBlData.destinationAirport && trackingBlData.destinationAirport.name ? trackingBlData.destinationAirport.name : "";
                    let queryFor = trackingBlData && trackingBlData.queryFor ? trackingBlData.queryFor : "";
                    let pickUpCountry = trackingBlData && trackingBlData.Shipper && trackingBlData.Shipper.Country ? trackingBlData.Shipper.Country : trackingBlData && trackingBlData.shipperBranchId && trackingBlData.shipperBranchId.countryId && trackingBlData.shipperBranchId.countryId.name ? trackingBlData.shipperBranchId.countryId.name : "";
                    let dropOffCountry = trackingBlData && trackingBlData.Consignee && trackingBlData.Consignee.Country ? trackingBlData.Consignee.Country : trackingBlData && trackingBlData.consigneeBranchId && trackingBlData.consigneeBranchId.countryId && trackingBlData.consigneeBranchId.countryId.name ? trackingBlData.consigneeBranchId.countryId.name : "";
                    let pickUpZipCode = trackingBlData && trackingBlData.Shipper && trackingBlData.Shipper.ZipCode ? trackingBlData.Shipper.ZipCode : "";
                    let dropOffZipCode = trackingBlData && trackingBlData.Consignee && trackingBlData.Consignee.ZipCode ? trackingBlData.Consignee.ZipCode : "";
                    let InvoiceId = responce && responce.result && responce.result.invoiceData && responce.result.invoiceData._id ? responce.result.invoiceData._id : "";
                    let InvoiceStatus = responce && responce.result && responce.result.invoiceData && responce.result.invoiceData.approvalStatus ? responce.result.invoiceData.approvalStatus : "";

                    this.setState({
                        InvoiceStatus: InvoiceStatus,
                        viewTrack: !this.state.viewTrack, destinationName: destinationName,
                        originName: originName,
                        queryFor: queryFor,
                        InvoiceId: InvoiceId,
                        pickUpCountry: pickUpCountry,
                        pickUpZipCode: pickUpZipCode,
                        dropOffZipCode: dropOffZipCode, dropOffCountry: dropOffCountry,
                        data: responce.result && responce.result.data ? responce.result.data : "",
                        MapView:false,
                        quoteId: quoteId

                    },()=>{
                        this.TrackingApi({ quoteId: quoteId }).then(responce => {
                            if (responce.success && responce.result) {

                                let trackingData = responce && responce.result ? responce.result : "";
                                let originName = trackingData && trackingData.queryData && trackingData.queryData.originAirport && trackingData.queryData.originAirport.name ? trackingData.queryData.originAirport.name : "";
                                let destinationName = trackingData && trackingData.queryData && trackingData.queryData.destinationAirport && trackingData.queryData.destinationAirport.name ? trackingData.queryData.destinationAirport.name : "";
                                let queryFor = trackingData && trackingData.queryData && trackingData.queryData.queryFor ? trackingData.queryData.queryFor : "";
                                this.setState({
                                    data: responce.result && responce.result.data ? responce.result.data : "",
                                })
                            }
                        })
                    })

                } else {

                }
            })
        }
        else if (this.state.TrackingValue === "jobNo") {
            this.SearchWithFastag({ jobNo: this.state.searchNumber }).then(responce => {
                if (responce.success) {
                    let FastTagData = responce && responce.result ? responce.result : "";

                    let Txn =  FastTagData && FastTagData.vehltxnList && FastTagData.vehltxnList.txn ?  FastTagData.vehltxnList.txn:[];
                    let TxnLength = Txn.length;
                    let vehicleNumber =  FastTagData && FastTagData.vehiclenumber ? FastTagData.vehiclenumber :"";

                    let longLang = Txn && Txn[0].tollPlazaGeocode ? Txn[0].tollPlazaGeocode :"";
                    let LastTollCross = Txn && Txn[TxnLength-1].tollPlazaName ? Txn[TxnLength-1].tollPlazaName :"";
                    let LastTollCrossDate = Txn && Txn[TxnLength-1].readerReadTime ? Txn[TxnLength-1].readerReadTime :"";
                    let firstlatitide =  longLang.split(",")[0]

                    let firstlongitude = longLang.split(",")[1]

                    this.setState({
                        firstlatitide:firstlatitide,
                        firstlongitude:firstlongitude,
                        vehicleNumber : vehicleNumber,
                        LastTollCross : LastTollCross,
                        LastTollCrossDate : LastTollCrossDate,
                    })

                    let AllCode =[]
                    {
                        Txn && Txn.map((obj, i) => {
                            //  let AllCode =[]
                            let tollPlazaGeocode  = obj && obj.tollPlazaGeocode? obj.tollPlazaGeocode:""
                            AllCode.push(tollPlazaGeocode);
                        })
                        this.setState({
                            AllCode:AllCode,
                            FastTagData: FastTagData,
                            MapView:true
                        })

                    }
                } else {
                    toast.error(responce.message, {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        }
        else {
            toast.error("Please Select Tracking Type", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    SearchTrack1 = () => {

        // if (this.state.TrackingValue === "blNo") {
        //     this.SearchWithblNo({ blNo: this.state.searchNumber, latest: this.state.latest }).then(responce => {
        //         if (responce.success) {
        //             let TrackingDatabyBl = responce && responce.result ? responce.result : [];
        //             let fromGateHouse = TrackingDatabyBl && TrackingDatabyBl.fromGateHouse ? TrackingDatabyBl.fromGateHouse : "";
        //             this.setState({
        //                 fromGateHouse: fromGateHouse
        //             })
        //
        //             if (this.state.fromGateHouse) {
        //                 if (responce.success && responce.result) {
        //
        //                     let trackingData = responce && responce.result ? responce.result : "";
        //                     let originName = trackingData && trackingData.queryData && trackingData.queryData.originAirport && trackingData.queryData.originAirport.name ? trackingData.queryData.originAirport.name : "";
        //                     let destinationName = trackingData && trackingData.queryData && trackingData.queryData.destinationAirport && trackingData.queryData.destinationAirport.name ? trackingData.queryData.destinationAirport.name : "";
        //                     let queryFor = trackingData && trackingData.queryData && trackingData.queryData.queryFor ? trackingData.queryData.queryFor : "";
        //
        //                     this.setState({
        //                         viewTrack: !this.state.viewTrack, destinationName: destinationName,
        //                         originName: originName,
        //                         queryFor: queryFor,
        //                         data: responce.result && responce.result.data ? responce.result.data : "",
        //
        //                     })
        //                 }
        //             } else {
        //                 let ContainersDetails = TrackingDatabyBl && TrackingDatabyBl.containers ? TrackingDatabyBl.containers : [];
        //                 {
        //                     ContainersDetails && ContainersDetails.map((obj, i) => {
        //
        //                         let value = obj && obj.events[obj.events.length - 1]
        //                         let firstLocation = obj && obj.events[0] && obj.events[0].transportCall && obj.events[0].transportCall.location && obj.events[0].transportCall.location.location_name ? obj.events[0].transportCall.location.location_name : ""
        //
        //                         let lastLocation = value && value.transportCall && value.transportCall.location && value.transportCall.location.location_name ? value.transportCall.location.location_name : ""
        //                         this.setState({
        //                             lastLocation: lastLocation,
        //                             firstLocation: firstLocation
        //
        //                         }, () => {
        //                         })
        //
        //                     })
        //                 }
        //                 this.setState({
        //                     TrackingDatabyBl: TrackingDatabyBl,
        //     ContainersDetails: ContainersDetails,
        //     SearchWithblNo: true,
        //     viewDetails: false,
        //     viewTrack: true,
        //     MapView:false
        //
        //                 })
        //                 this.SearchWithDesignTracking({ blNo: this.state.searchNumber, searchBy:"bill_of_lading_number"}).then(responce => {
        //                     if (responce.success) {
        //                         let TrackingMapData = responce && responce.result ? responce.result : {};
        //                         this.setState({
        //                             showTrackingImage:true,
        //                             TrackingMapData:TrackingMapData
        //                         },()=>{
        //
        //                             if(this.state.TrackingMapData && this.state.TrackingMapData.podGateOutEmpty && this.state.TrackingMapData.podGateOutEmpty.eventClassifier=="ACT"){
        //                                 this.setState({
        //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5196791701301409-1677146899644.png"
        //                                 })
        //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.podDischarged && this.state.TrackingMapData.podDischarged.eventClassifier=="ACT"){
        //                                 this.setState({
        //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.008414692113074329-1677146874844.png"
        //                                 })
        //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.PodArrival && this.state.TrackingMapData.PodArrival.eventClassifier=="ACT"){
        //                                 this.setState({
        //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.37160307689050165-1677146836768.png"
        //                                 })
        //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polDeparture && this.state.TrackingMapData.polDeparture.eventClassifier=="ACT"){
        //                                 this.setState({
        //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8152072824162842-1677146805455.png"
        //                                 })
        //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polLoaded && this.state.TrackingMapData.polLoaded.eventClassifier=="ACT"){
        //                                 this.setState({
        //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.01848402694373985-1677146776561.png"
        //                                 })
        //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polGateInFull && this.state.TrackingMapData.polGateInFull.eventClassifier=="ACT"){
        //                                 this.setState({
        //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5027198962412343-1677146742980.png"
        //                                 })
        //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polGateOutEmpty && this.state.TrackingMapData.polGateOutEmpty.eventClassifier=="ACT"){
        //                                 this.setState({
        //                                     mapUrl:"https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.11494619769525238-1677144184543.png"
        //                                 })
        //                             }else{
        //                                 this.setState({
        //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.2500081408416126-1677148367410.png"
        //                                 })
        //                             }
        //                         })
        //
        //                     } else {
        //
        //                     }
        //                 })
        //             }
        //         }
        //
        //         else {
        //             this.SearchWithNumber({ blNo: this.state.searchNumber }).then(responce => {
        //                 if (responce.success) {
        //
        //                     let trackingBlData = responce && responce.result ? responce.result : "";
        //                     let quoteId = responce && responce.result && responce.result.quoteId && responce.result.quoteId._id ? responce.result.quoteId._id : "";
        //                     let originName = trackingBlData && trackingBlData.originAirport && trackingBlData.originAirport.name ? trackingBlData.originAirport.name : "";
        //                     let destinationName = trackingBlData && trackingBlData.destinationAirport && trackingBlData.destinationAirport.name ? trackingBlData.destinationAirport.name : "";
        //                     let queryFor = trackingBlData && trackingBlData.queryFor ? trackingBlData.queryFor : "";
        //
        //                     this.setState({
        //                         viewTrack: !this.state.viewTrack, destinationName: destinationName,
        //                         originName: originName,
        //                         queryFor: queryFor,
        //
        //                         data: responce.result && responce.result.data ? responce.result.data : "",
        //                         MapView:false
        //
        //                     })
        //
        //
        //
        //                 } else {
        //                     toast.error(responce.message, {
        //                         position: "top-left",
        //                         autoClose: 3000,
        //                         hideProgressBar: false,
        //                         closeOnClick: true,
        //                         pauseOnHover: true,
        //                         draggable: true,
        //                         progress: undefined,
        //                     });
        //                 }
        //             })
        //         }
        //     })
        //
        //
        // }
        if (this.state.TrackingValue === "containerNo") {
            this.SearchWithcontainerNo({ trackingReference: this.state.searchNumber ,container:true,blNo:false}).then(responce => {
                if (responce.success) {
                    let TrackingDatabyContainer = responce && responce.result ? responce.result : [];
                    let container = TrackingDatabyContainer && TrackingDatabyContainer.containerNo ? TrackingDatabyContainer.containerNo:""


                    let value = TrackingDatabyContainer && TrackingDatabyContainer.events[TrackingDatabyContainer.events.length - 1]
                    let firstLocation = TrackingDatabyContainer && TrackingDatabyContainer.events[0] && TrackingDatabyContainer.events[0].transportCall && TrackingDatabyContainer.events[0].transportCall.location && TrackingDatabyContainer.events[0].transportCall.location.locationName ? TrackingDatabyContainer.events[0].transportCall.location.locationName : ""
                    let firstLocations = TrackingDatabyContainer && TrackingDatabyContainer.origin ? TrackingDatabyContainer.origin:firstLocation

                    let lastLocations = value && value.transportCall && value.transportCall.location && value.transportCall.location.locationName ? value.transportCall.location.locationName : ""
                    let lastLocation = TrackingDatabyContainer && TrackingDatabyContainer.destination  ? TrackingDatabyContainer.destination  : lastLocations


                    this.setState({
                        lastLocation: lastLocation,
                        firstLocation:firstLocations,
                        lastLocations:lastLocations,
                        container:container,

                    }, () => {
                    })



                    this.setState({
                        TrackingDatabyContainer: TrackingDatabyContainer,
                        SearchWithContainerNo: true,
                        viewDetails: false,
                        viewTrack: true,
                        MapView:false

                    })

                } else {
                    toast.error(responce.message, {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            // this.SearchWithDesignTracking({ containerNo: this.state.searchNumber,}).then(responce => {
            //     if (responce.success) {
            //         let TrackingMapData = responce && responce.result ? responce.result : [];
            //         this.setState({
            //             showTrackingImage:true,
            //             TrackingMapData:TrackingMapData
            //         },()=>{
            //             if(this.state.TrackingMapData && this.state.TrackingMapData.podGateOutEmpty && this.state.TrackingMapData.podGateOutEmpty.eventClassifier=="ACT"){
            //                 this.setState({
            //                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5196791701301409-1677146899644.png"
            //                 })
            //             }else if(this.state.TrackingMapData && this.state.TrackingMapData.podDischarged && this.state.TrackingMapData.podDischarged.eventClassifier=="ACT"){
            //                 this.setState({
            //                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.008414692113074329-1677146874844.png"
            //                 })
            //             }else if(this.state.TrackingMapData && this.state.TrackingMapData.PodArrival && this.state.TrackingMapData.PodArrival.eventClassifier=="ACT"){
            //                 this.setState({
            //                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.37160307689050165-1677146836768.png"
            //                 })
            //             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polDeparture && this.state.TrackingMapData.polDeparture.eventClassifier=="ACT"){
            //                 this.setState({
            //                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8152072824162842-1677146805455.png"
            //                 })
            //             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polLoaded && this.state.TrackingMapData.polLoaded.eventClassifier=="ACT"){
            //                 this.setState({
            //                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.01848402694373985-1677146776561.png"
            //                 })
            //             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polGateInFull && this.state.TrackingMapData.polGateInFull.eventClassifier=="ACT"){
            //                 this.setState({
            //                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5027198962412343-1677146742980.png"
            //                 })
            //             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polGateOutEmpty && this.state.TrackingMapData.polGateOutEmpty.eventClassifier=="ACT"){
            //                 this.setState({
            //                     mapUrl:"https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.11494619769525238-1677144184543.png"
            //                 })
            //             }else{
            //                 this.setState({
            //                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.2500081408416126-1677148367410.png"
            //                 })
            //             }
            //         })


            //     } else {
            //         toast.error(responce.message, {
            //             position: "top-left",
            //             autoClose: 3000,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //         });
            //     }
            // })

        } else  if (this.state.TrackingValue === "blNo") {
            this.SearchWithcontainerNo({ trackingReference: this.state.searchNumber ,blNo:true,container:false}).then(responce => {
                if (responce.success) {
                    let TrackingDatabyContainer = responce && responce.result ? responce.result : [];
                    let container = TrackingDatabyContainer && TrackingDatabyContainer.containerNo ? TrackingDatabyContainer.containerNo:""

                    let value = TrackingDatabyContainer && TrackingDatabyContainer.events[TrackingDatabyContainer.events.length - 1]
                    let firstLocation = TrackingDatabyContainer && TrackingDatabyContainer.events[0] && TrackingDatabyContainer.events[0].transportCall && TrackingDatabyContainer.events[0].transportCall.location && TrackingDatabyContainer.events[0].transportCall.location.locationName ? TrackingDatabyContainer.events[0].transportCall.location.locationName : ""
                    let firstLocations = TrackingDatabyContainer && TrackingDatabyContainer.origin ? TrackingDatabyContainer.origin:firstLocation

                    let lastLocations = value && value.transportCall && value.transportCall.location && value.transportCall.location.locationName ? value.transportCall.location.locationName : ""
                    let lastLocation = TrackingDatabyContainer && TrackingDatabyContainer.destination  ? TrackingDatabyContainer.destination  : lastLocations

                    this.setState({
                        lastLocation: lastLocation,
                        lastLocations:lastLocations,
                        container:container,
                        firstLocation:firstLocations
                    }, () => {
                    })



                    this.setState({
                        TrackingDatabyContainer: TrackingDatabyContainer,
                        SearchWithContainerNo: true,
                        viewDetails: false,
                        viewTrack: true,
                        MapView:false

                    })

                } else {
                    toast.error(responce.message, {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })


        }

            //    if (this.state.TrackingValue === "bookingReferenceNo") {
            //     this.SearchWithbookingReferenceNo({ bookingReferenceNo: this.state.searchNumber }).then(responce => {
            //         if (responce.success) {
            //             let TrackingDatabyBl = responce && responce.result ? responce.result : [];
            //
            //                 let ContainersDetails = TrackingDatabyBl && TrackingDatabyBl.containers ? TrackingDatabyBl.containers : [];
            //                 {
            //                     ContainersDetails && ContainersDetails.map((obj, i) => {
            //
            //                         let value = obj && obj.events[obj.events.length - 1]
            //                         let firstLocation = obj && obj.events[0] && obj.events[0].transportCall && obj.events[0].transportCall.location && obj.events[0].transportCall.location.location_name ? obj.events[0].transportCall.location.location_name : ""
            //
            //                         let lastLocation = value && value.transportCall && value.transportCall.location && value.transportCall.location.location_name ? value.transportCall.location.location_name : ""
            //                         this.setState({
            //                             lastLocation: lastLocation,
            //                             firstLocation: firstLocation
            //
            //                         }, () => {
            //                         })
            //
            //                     })
            //                 }
            //                 this.setState({
            //                     TrackingDatabyBl: TrackingDatabyBl,
            //     ContainersDetails: ContainersDetails,
            //     SearchWithblNo: true,
            //     viewDetails: false,
            //     viewTrack: true,
            //     MapView:false,
            //     SearchThroughBookingRefrence:true
            //
            //                 })
            //                 this.SearchWithDesignTracking({ bookingReferenceNo: this.state.searchNumber, searchBy:"booking_reference_number"}).then(responce => {
            //                     if (responce.success) {
            //                         let TrackingMapData = responce && responce.result ? responce.result : {};
            //                         this.setState({
            //                             showTrackingImage:true,
            //                             TrackingMapData:TrackingMapData
            //                         },()=>{
            //
            //                             if(this.state.TrackingMapData && this.state.TrackingMapData.podGateOutEmpty && this.state.TrackingMapData.podGateOutEmpty.eventClassifier=="ACT"){
            //                                 this.setState({
            //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5196791701301409-1677146899644.png"
            //                                 })
            //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.podDischarged && this.state.TrackingMapData.podDischarged.eventClassifier=="ACT"){
            //                                 this.setState({
            //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.008414692113074329-1677146874844.png"
            //                                 })
            //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.PodArrival && this.state.TrackingMapData.PodArrival.eventClassifier=="ACT"){
            //                                 this.setState({
            //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.37160307689050165-1677146836768.png"
            //                                 })
            //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polDeparture && this.state.TrackingMapData.polDeparture.eventClassifier=="ACT"){
            //                                 this.setState({
            //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8152072824162842-1677146805455.png"
            //                                 })
            //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polLoaded && this.state.TrackingMapData.polLoaded.eventClassifier=="ACT"){
            //                                 this.setState({
            //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.01848402694373985-1677146776561.png"
            //                                 })
            //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polGateInFull && this.state.TrackingMapData.polGateInFull.eventClassifier=="ACT"){
            //                                 this.setState({
            //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5027198962412343-1677146742980.png"
            //                                 })
            //                             }else if(this.state.TrackingMapData && this.state.TrackingMapData.polGateOutEmpty && this.state.TrackingMapData.polGateOutEmpty.eventClassifier=="ACT"){
            //                                 this.setState({
            //                                     mapUrl:"https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.11494619769525238-1677144184543.png"
            //                                 })
            //                             }else{
            //                                 this.setState({
            //                                     mapUrl:"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.2500081408416126-1677148367410.png"
            //                                 })
            //                             }
            //                         })
            //
            //                     } else {
            //
            //                     }
            //                 })
            //             }
            //         }
            //     )
            //
        // }
        else  if (this.state.TrackingValue === "AWB") {
            this.SearchWithNumber({ blNo: this.state.searchNumber }).then(responce => {
                if (responce.success) {

                    let trackingBlData = responce && responce.result ? responce.result : "";
                    let quoteId = responce && responce.result && responce.result.quoteId && responce.result.quoteId._id ? responce.result.quoteId._id : "";
                    let originName = trackingBlData && trackingBlData.originAirport && trackingBlData.originAirport.name ? trackingBlData.originAirport.name : "";
                    let destinationName = trackingBlData && trackingBlData.destinationAirport && trackingBlData.destinationAirport.name ? trackingBlData.destinationAirport.name : "";
                    let queryFor = trackingBlData && trackingBlData.queryFor ? trackingBlData.queryFor : "";
                    let pickUpCountry = trackingBlData && trackingBlData.Shipper && trackingBlData.Shipper.Country ? trackingBlData.Shipper.Country : trackingBlData && trackingBlData.shipperBranchId && trackingBlData.shipperBranchId.countryId && trackingBlData.shipperBranchId.countryId.name ? trackingBlData.shipperBranchId.countryId.name : "";
                    let dropOffCountry = trackingBlData && trackingBlData.Consignee && trackingBlData.Consignee.Country ? trackingBlData.Consignee.Country : trackingBlData && trackingBlData.consigneeBranchId && trackingBlData.consigneeBranchId.countryId && trackingBlData.consigneeBranchId.countryId.name ? trackingBlData.consigneeBranchId.countryId.name : "";
                    let pickUpZipCode = trackingBlData && trackingBlData.Shipper && trackingBlData.Shipper.ZipCode ? trackingBlData.Shipper.ZipCode : "";
                    let dropOffZipCode = trackingBlData && trackingBlData.Consignee && trackingBlData.Consignee.ZipCode ? trackingBlData.Consignee.ZipCode : "";
                    let InvoiceId = responce && responce.result && responce.result.invoiceData && responce.result.invoiceData._id ? responce.result.invoiceData._id : "";
                    let InvoiceStatus = responce && responce.result && responce.result.invoiceData && responce.result.invoiceData.approvalStatus ? responce.result.invoiceData.approvalStatus : "";

                    this.setState({
                        InvoiceStatus: InvoiceStatus,
                        viewTrack: !this.state.viewTrack, destinationName: destinationName,
                        originName: originName,
                        queryFor: queryFor,
                        InvoiceId: InvoiceId,
                        pickUpCountry: pickUpCountry,
                        pickUpZipCode: pickUpZipCode,
                        dropOffZipCode: dropOffZipCode, dropOffCountry: dropOffCountry,
                        data: responce.result && responce.result.data ? responce.result.data : "",
                        MapView:false

                    })
                    this.setState({ quoteId: quoteId })

                    this.TrackingApi({ quoteId: quoteId }).then(responce => {
                        if (responce.success && responce.result) {

                            let trackingData = responce && responce.result ? responce.result : "";
                            let originName = trackingData && trackingData.queryData && trackingData.queryData.originAirport && trackingData.queryData.originAirport.name ? trackingData.queryData.originAirport.name : "";
                            let destinationName = trackingData && trackingData.queryData && trackingData.queryData.destinationAirport && trackingData.queryData.destinationAirport.name ? trackingData.queryData.destinationAirport.name : "";
                            let queryFor = trackingData && trackingData.queryData && trackingData.queryData.queryFor ? trackingData.queryData.queryFor : "";
                            this.setState({
                                // viewTrack : !this.state.viewTrack,destinationName:destinationName,
                                // originName:originName,
                                // queryFor:queryFor,
                                data: responce.result && responce.result.data ? responce.result.data : "",
                            })
                        }
                    })

                } else {
                    toast.error(responce.message, {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        } else if (this.state.TrackingValue === "jobNo") {
            this.SearchWithFastag({ jobNo: this.state.searchNumber }).then(responce => {
                if (responce.success) {
                    let FastTagData = responce && responce.result ? responce.result : "";

                    let Txn =  FastTagData && FastTagData.vehltxnList && FastTagData.vehltxnList.txn ?  FastTagData.vehltxnList.txn:[];
                    let TxnLength = Txn.length;
                    let vehicleNumber =  FastTagData && FastTagData.vehiclenumber ? FastTagData.vehiclenumber :"";

                    let longLang = Txn && Txn[0].tollPlazaGeocode ? Txn[0].tollPlazaGeocode :"";
                    let LastTollCross = Txn && Txn[TxnLength-1].tollPlazaName ? Txn[TxnLength-1].tollPlazaName :"";
                    let LastTollCrossDate = Txn && Txn[TxnLength-1].readerReadTime ? Txn[TxnLength-1].readerReadTime :"";
                    let firstlatitide =  longLang.split(",")[0]

                    let firstlongitude = longLang.split(",")[1]

                    this.setState({
                        firstlatitide:firstlatitide,
                        firstlongitude:firstlongitude,
                        vehicleNumber : vehicleNumber,
                        LastTollCross : LastTollCross,
                        LastTollCrossDate : LastTollCrossDate,
                    })

                    let AllCode =[]
                    {
                        Txn && Txn.map((obj, i) => {
                            //  let AllCode =[]
                            let tollPlazaGeocode  = obj && obj.tollPlazaGeocode? obj.tollPlazaGeocode:""
                            AllCode.push(tollPlazaGeocode);





                        })


                        this.setState({
                            AllCode:AllCode,
                        })

                    }

                    // let LongLang =  Txn[0] && Txn[0].tollPlazaGeocode ? Txn[0].tollPlazaGeocode:""
                    // let londitude = LongLang.split(",")[0]
                    // let langitude = LongLang.split(",")[1]

                    // alert("LongLang"+ langitude + londitude)
                    this.setState({
                        FastTagData: FastTagData,
                        MapView:true
                    })

                } else {
                    toast.error(responce.message, {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        }else {
            toast.error("Please Select Tracking Type", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    SearchWithblNo = async (dataForServer) => {
        return await RestClient({
            url: SEARCH_TRACKING_BY_BL,
            method: "POST",
            data: dataForServer
        });
    };
    SearchWithFastag = async (dataForServer) => {
        return await RestClient({
            url: SEARCH_VEHICLE_FASTAGE_DETAILS,
            method: "POST",
            data: dataForServer
        });
    };
    SearchWithDesignTracking = async (dataForServer) => {
        return await RestClient({
            url: SEARCH_DESIGN_BY_BL,
            method: "POST",
            data: dataForServer
        });
    };
    SearchWithcontainerNo = async (dataForServer) => {
        return await RestClient({
            url: SEARCH_TRACKING_BY_BOOKING_REFERENCE_NO,
            method: "POST",
            data: dataForServer
        });
    };
    SearchWithbookingReferenceNo = async (dataForServer) => {
        return await RestClient({
            url: SEARCH_TRACKING_BY_CONTAINER,
            method: "POST",
            data: dataForServer
        });
    };
    SearchWithNumber = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor==="Air" ? SEARCH_TRACKING_BY_NUMBER_AIR : this.state.quoteFor==="Ocean" ? SEARCH_TRACKING_BY_NUMBER_OCEAN :   this.state.quoteFor==="Courier" ?SEARCH_TRACKING_BY_NUMBER_COURIER : SEARCH_TRACKING_BY_NUMBER_ROAD,
            method: "POST",
            data: dataForServer
        });
    };

    TrackingApi = async (dataForServer) => {
        return await RestClient({
            url: this.state.quoteFor === "Air" ? GET_TRACKING_SEARCH_API_AIR : this.state.quoteFor === "Ocean" ? GET_TRACKING_SEARCH_API_OCEAN : this.state.quoteFor === "Courier" ?GET_TRACKING_SEARCH_API_COURIER : GET_TRACKING_SEARCH_API_ROAD,
            method: "POST",
            data: dataForServer
        });
    };

    opneContainerDetails = (obj, i) => {
        this.setState({
            particularContainerDetails: obj,
            containerDetailsSingle: !this.state.containerDetailsSingle
        }, () => {
        })
    }
    opneContainerDetailsBooking = (obj, i) => {
        this.setState({
            particularBookingDetails: obj,
            bookingDetailsSingle: !this.state.bookingDetailsSingle
        }, () => {
        })
    }


    render() {

        return (
            <>

                <MetaTags>
                    <title>Live Tracking  | Zipaworld </title>
                    <meta name="title" content="Live tracking for air, ocean, road cargo on Zipaworld" />
                    <meta name="description" content="Zipaworld helps in 24/7 live tracking of multimodal freight and cargo" />
                    <meta name="keywords" content="airwaybill tracking, AWB tracking, container tracking online, how to track my air cargo, track shipping container online" />
                </MetaTags>
                <AppLayout>
                    <div className={this.state.MapView ===true?"displayNoneTrack":"dashboard-tracking"}>
                        <div className={this.state.viewTrack === true || this.state.MapView ===true? "domain-search bg-pblue not-track" : "domain-search bg-pblue"}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 pb-5">
                                        <h2 className="form-title">Tracking</h2>

                                    </div>
                                    <div className="col-lg-12">
                                        <p className="pb-3">Please Enter BL/AWB/Docket Number</p>
                                        <div className="d-flex flex-wrap align-items-center" style={{width:'100%'}}>
                                            <div className="input-group trackingDiv">
                                                <select
                                                    name="TrackingValue"
                                                    className="custom-select trackingSelection"
                                                    value={this.state.TrackingValue}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ TrackingValue: e.target.value },()=>{
                                                            if(this.state.TrackingValue === "AWB"){
                                                                this.setState({
                                                                    quoteFor : 'Air'
                                                                })
                                                            }else if(this.state.TrackingValue === "blNo"){
                                                                this.setState({
                                                                    quoteFor : 'Ocean'
                                                                })
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <option value={""}>Search By</option>
                                                    <option value={"AWB"}>By AWB Number</option>
                                                    <option value={"Docket Number"}>By Docket Number</option>
                                                    <option value={"blNo"}>By Bl Number</option>
                                                    <option value={"containerNo"}>By Container Number</option>

                                                    <option value={"jobNo"}>By jobNo</option>

                                                    {/* <option value={"bookingReferenceNo"}>Booking Reference No</option> */}

                                                </select>
                                            </div>
                                            <div className="input-group searchNumber">
                                                <input name="searchNumber"
                                                       type="text"
                                                       className="form-control ipt11"
                                                       onChange={this.handleChange}
                                                       value={this.state.searchNumber} />
                                                <span className="input-group-addon">
                                                        <input type="submit" value="Search" className="btn btn-primary" onClick={e => {
                                                            e.preventDefault()
                                                            if(this.state.TrackingValue === "AWB"){
                                                                this.setState({
                                                                    quoteFor : "Air"
                                                                },()=>{
                                                                    this.SearchTrack()

                                                                })
                                                            }else if(this.state.TrackingValue === "Docket Number"){
                                                                let searchNumber = this.state.searchNumber
                                                                let mode=searchNumber.split("-")[0][0]
                                                                if(mode === "C"){
                                                                    this.setState({
                                                                        quoteFor : "Courier"
                                                                    },()=>{
                                                                        this.SearchTrack()
                                                                    })
                                                                }else if(mode === "R"){
                                                                    this.setState({
                                                                        quoteFor : "Road"
                                                                    },()=>{
                                                                        this.SearchTrack()
                                                                    })
                                                                }else{
                                                                    toast.error("Please Enter Valid Docket Number", {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });                                                                }
                                                            }else
                                                            if(this.state.quoteFor === "Air" || this.state.quoteFor === "Ocean" || this.state.quoteFor === "Road" || this.state.quoteFor === "Courier")
                                                            {
                                                                this.SearchTrack()
                                                            }else{
                                                                let searchNumber = this.state.searchNumber
                                                                let mode=searchNumber.split("-")[0][0]
                                                                if(mode === "O"){
                                                                    this.setState({
                                                                        quoteFor : "Ocean"
                                                                    },()=>{
                                                                        this.SearchTrack()

                                                                    })
                                                                }else if(mode === "A"){
                                                                    this.setState({
                                                                        quoteFor : "Air"
                                                                    },()=>{
                                                                        this.SearchTrack()
                                                                    })
                                                                }else if(mode === "C"){
                                                                    this.setState({
                                                                        quoteFor : "Courier"
                                                                    },()=>{
                                                                        this.SearchTrack()
                                                                    })
                                                                }else if(mode === "R"){
                                                                    this.setState({
                                                                        quoteFor : "Road"
                                                                    },()=>{
                                                                        this.SearchTrack()
                                                                    })
                                                                }else{
                                                                    this.SearchTrack()
                                                                }
                                                            }
                                                        }} />
                                                    </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {this.state.viewTrack === true ?
                            <div className="tracking-shipment">
                                <div className="container">
                                    <h3 className="track-head">Tracking Details</h3>
                                    <div className="tracking-search">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                                            <div className="tracking-No ">
                                                <div className="heading-track">
                                                    {this.state.queryFor === "Air" ? "AWB Number" : ""}
                                                    {this.state.queryFor === "Ocean" ? "BL Number" : ""}
                                                    {this.state.queryFor === "Courier" ? "Docket Number" : ""}
                                                    {this.state.queryFor === "Road" ? "Docket Number" : ""}
                                                    {this.state.SearchWithblNo && !this.state.SearchThroughBookingRefrence ? "Bl No" : ""}
                                                    {this.state.SearchWithblNo && this.state.SearchThroughBookingRefrence ? "Booking Refrence No" : ""}

                                                    {this.state.SearchWithContainerNo ? "Container No" : ""}


                                                </div>
                                                <div className="tracking-left">{this.state.container ? this.state.container :this.state.searchNumber}</div>
                                            </div>
                                            <div className="tracking-No ">
                                                <div className="heading-track">{this.state.queryFor === "Courier" || this.state.queryFor === "Road" ? "Pick Up" : "Origin"}</div>
                                                {this.state.queryFor === "Courier" || this.state.queryFor === "Road" ?
                                                    <div className="tracking-left">{this.state.pickUpCountry} , {this.state.pickUpZipCode}</div>
                                                    : <div className="tracking-left">{this.state.originName ? this.state.originName:this.state.firstLocation }</div>
                                                }
                                            </div>
                                            <div className="tracking-No ">
                                                <div className="heading-track">{this.state.queryFor === "Courier" || this.state.queryFor === "Road" ? "Drop Off" : "Destination"}</div>

                                                {this.state.queryFor === "Courier" || this.state.queryFor === "Road" ?
                                                    <div className="tracking-left">{this.state.dropOffCountry} , {this.state.dropOffZipCode}</div>
                                                    : <div className="tracking-left">{this.state.destinationName?this.state.destinationName:this.state.lastLocation}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {this.state.showTrackingImage ?
                                    <div className="container-fluid">
                                        <div className="tracking_Design">
                                            <img src={this.state.mapUrl} alt="trackingImg"/>
                                            {/*<img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.11494619769525238-1677144184543.png"/>*/}

                                            <div className="firstStepTrack">
                                                <p>{this.state.TrackingMapData && this.state.TrackingMapData.polGateOutEmpty && this.state.TrackingMapData.polGateOutEmpty.loc ? this.state.TrackingMapData.polGateOutEmpty.loc :""}</p>
                                                <p><strong>({this.state.TrackingMapData && this.state.TrackingMapData.polGateOutEmpty && this.state.TrackingMapData.polGateOutEmpty.eventClassifier ? this.state.TrackingMapData.polGateOutEmpty.eventClassifier.slice(0,1) :"Na"})</strong> {" "} {this.state.TrackingMapData && this.state.TrackingMapData.polGateOutEmpty && this.state.TrackingMapData.polGateOutEmpty.date ? convertDateISOToCustomiseFormat(this.state.TrackingMapData.polGateOutEmpty.date) :""}</p>
                                                {/* <p>{this.state.TrackingMapData && this.state.TrackingMapData.polGateOutEmpty && this.state.TrackingMapData.polGateOutEmpty.vesselName ? this.state.TrackingMapData.polGateOutEmpty.vesselName :"-"} / {this.state.TrackingMapData && this.state.TrackingMapData.polGateOutEmpty && this.state.TrackingMapData.polGateOutEmpty.imo ? this.state.TrackingMapData.polGateOutEmpty.imo :"-"}</p> */}
                                            </div>
                                            <div className="secondStepTrack">
                                                <p>{this.state.TrackingMapData && this.state.TrackingMapData.polGateInFull && this.state.TrackingMapData.polGateInFull.loc ? this.state.TrackingMapData.polGateInFull.loc :""}</p>
                                                <p><strong>({this.state.TrackingMapData && this.state.TrackingMapData.polGateInFull && this.state.TrackingMapData.polGateInFull.eventClassifier ? this.state.TrackingMapData.polGateInFull.eventClassifier.slice(0,1) :"Na"})</strong> {" "} {this.state.TrackingMapData && this.state.TrackingMapData.polGateInFull && this.state.TrackingMapData.polGateInFull.date ? convertDateISOToCustomiseFormat(this.state.TrackingMapData.polGateInFull.date) :""}</p>
                                                {/* <p>{this.state.TrackingMapData && this.state.TrackingMapData.polGateInFull && this.state.TrackingMapData.polGateInFull.vesselName ? this.state.TrackingMapData.polGateInFull.vesselName :"-"}/ {this.state.TrackingMapData && this.state.TrackingMapData.polGateInFull && this.state.TrackingMapData.polGateInFull.imo ? this.state.TrackingMapData.polGateInFull.imo :"-"}</p> */}
                                            </div>
                                            <div className="thirdStepTrack">
                                                <p>{this.state.TrackingMapData && this.state.TrackingMapData.polLoaded && this.state.TrackingMapData.polLoaded.loc ? this.state.TrackingMapData.polLoaded.loc :""}</p>
                                                <p><strong>({this.state.TrackingMapData && this.state.TrackingMapData.polLoaded && this.state.TrackingMapData.polLoaded.eventClassifier ? this.state.TrackingMapData.polLoaded.eventClassifier.slice(0,1) :"Na"})</strong> {" "} {this.state.TrackingMapData && this.state.TrackingMapData.polLoaded && this.state.TrackingMapData.polLoaded.date ? convertDateISOToCustomiseFormat(this.state.TrackingMapData.polLoaded.date):""}</p>
                                                {/* <p>{this.state.TrackingMapData && this.state.TrackingMapData.polLoaded && this.state.TrackingMapData.polLoaded.vesselName ? this.state.TrackingMapData.polLoaded.vesselName :"-"} / {this.state.TrackingMapData && this.state.TrackingMapData.polLoaded && this.state.TrackingMapData.polLoaded.imo ? this.state.TrackingMapData.polLoaded.imo :"-"}</p> */}
                                            </div>
                                            <div className="forthStepTrack">
                                                <p>{this.state.TrackingMapData && this.state.TrackingMapData.polDeparture && this.state.TrackingMapData.polDeparture.loc ? this.state.TrackingMapData.polDeparture.loc :""}</p>
                                                <p><strong>({this.state.TrackingMapData && this.state.TrackingMapData.polDeparture && this.state.TrackingMapData.polDeparture.eventClassifier ? this.state.TrackingMapData.polDeparture.eventClassifier.slice(0,1) :"Na"})</strong> {" "} {this.state.TrackingMapData && this.state.TrackingMapData.polDeparture && this.state.TrackingMapData.polDeparture.date ? convertDateISOToCustomiseFormat(this.state.TrackingMapData.polDeparture.date) :""}</p>
                                                {/* <p>{this.state.TrackingMapData && this.state.TrackingMapData.polDeparture && this.state.TrackingMapData.polDeparture.vesselName ? this.state.TrackingMapData.polDeparture.vesselName :"-"} / {this.state.TrackingMapData && this.state.TrackingMapData.polDeparture && this.state.TrackingMapData.polDeparture.imo ? this.state.TrackingMapData.polDeparture.imo :"-"}</p> */}
                                            </div>
                                            <div className="fiveStepTrack">
                                                <p>{this.state.TrackingMapData && this.state.TrackingMapData.PodArrival && this.state.TrackingMapData.PodArrival.loc ? this.state.TrackingMapData.PodArrival.loc :""}</p>
                                                <p><strong>({this.state.TrackingMapData && this.state.TrackingMapData.PodArrival && this.state.TrackingMapData.PodArrival.eventClassifier ? this.state.TrackingMapData.PodArrival.eventClassifier.slice(0,1) :"Na"})</strong> {" "} {this.state.TrackingMapData && this.state.TrackingMapData.PodArrival && this.state.TrackingMapData.PodArrival.date ? convertDateISOToCustomiseFormat(this.state.TrackingMapData.PodArrival.date) :""}</p>
                                                {/* <p>{this.state.TrackingMapData && this.state.TrackingMapData.PodArrival && this.state.TrackingMapData.PodArrival.vesselName ? this.state.TrackingMapData.PodArrival.vesselName :"-"} / {this.state.TrackingMapData && this.state.TrackingMapData.PodArrival && this.state.TrackingMapData.PodArrival.imo ? this.state.TrackingMapData.PodArrival.imo :"-"}</p> */}
                                            </div>
                                            <div className="sixStepTrack">
                                                <p>{this.state.TrackingMapData && this.state.TrackingMapData.podDischarged && this.state.TrackingMapData.podDischarged.loc ? this.state.TrackingMapData.podDischarged.loc :""}</p>
                                                <p><strong>({this.state.TrackingMapData && this.state.TrackingMapData.podDischarged && this.state.TrackingMapData.podDischarged.eventClassifier ? this.state.TrackingMapData.podDischarged.eventClassifier.slice(0,1) :"Na"})</strong> {" "} {this.state.TrackingMapData && this.state.TrackingMapData.podDischarged && this.state.TrackingMapData.podDischarged.date ? convertDateISOToCustomiseFormat(this.state.TrackingMapData.podDischarged.date) :""}</p>
                                                {/* <p>{this.state.TrackingMapData && this.state.TrackingMapData.podDischarged && this.state.TrackingMapData.podDischarged.vesselName ? this.state.TrackingMapData.podDischarged.vesselName :"-"} / {this.state.TrackingMapData && this.state.TrackingMapData.podDischarged && this.state.TrackingMapData.podDischarged.imo ? this.state.TrackingMapData.podDischarged.imo :"-"}</p> */}
                                            </div>
                                            <div className="sevenStepTrack">
                                                <p>{this.state.TrackingMapData && this.state.TrackingMapData.podGateOutEmpty && this.state.TrackingMapData.podGateOutEmpty.loc ? this.state.TrackingMapData.podGateOutEmpty.loc :""}</p>
                                                <p><strong>({this.state.TrackingMapData && this.state.TrackingMapData.podGateOutEmpty && this.state.TrackingMapData.podGateOutEmpty.eventClassifier ? this.state.TrackingMapData.podGateOutEmpty.eventClassifier.slice(0,1) :"Na"})</strong> {" "} {this.state.TrackingMapData && this.state.TrackingMapData.podGateOutEmpty && this.state.TrackingMapData.podGateOutEmpty.date ? convertDateISOToCustomiseFormat(this.state.TrackingMapData.podGateOutEmpty.date) :""}</p>
                                                {/* <p>{this.state.TrackingMapData && this.state.TrackingMapData.podGateOutEmpty && this.state.TrackingMapData.podGateOutEmpty.vesselName ? this.state.TrackingMapData.podGateOutEmpty.vesselName :"-"} / {this.state.TrackingMapData && this.state.TrackingMapData.podGateOutEmpty && this.state.TrackingMapData.podGateOutEmpty.imo ? this.state.TrackingMapData.podGateOutEmpty.imo :"-"}</p> */}
                                            </div>
                                            <div className="eightStepTrack">
                                                <p>-</p>
                                                <p>-</p>
                                            </div>

                                        </div>
                                        <div className="d-flex flex-wrap align-items-center tracking_heading">
                                            <p>A (Actual),</p>
                                            <p>P (Predicted),</p>
                                            <p>E (Expected)</p>
                                        </div>
                                    </div>

                                    :""}
                                <div className="container">
                                    <div className="tracking_details">
                                        <div className="d-flex flex-wrap justify-content-between align-stretched">
                                            <div className="d-flex flex-wrap align-items-center dd_track">
                                                {/*<div className="tracking-No ">*/}
                                                {/*    <div className="d-flex flex-wrap align-items-center">*/}
                                                {/*        <div>*/}
                                                {/*            <div className="heading-track">Estimated arrival date</div>*/}
                                                {/*            <div className="tracking-text">12 Aug 2021</div>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="tracking-No">
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <img src="../../../assets/img/location.png" alt="Location Icon"/>
                                                        <div>
                                                            <div className="heading-track">Last Location</div>
                                                            {this.state.queryFor === "Courier" || this.state.queryFor === "Road" ?
                                                                <div className="tracking-text">{this.state.dropOffCountry} , {this.state.dropOffZipCode}</div>
                                                                :
                                                                <div className="tracking-text">{this.state.destinationName}</div>}

                                                            {this.state.SearchWithblNo || this.state.SearchWithContainerNo ? <div className="tracking-text">{this.state.lastLocations ? this.state.lastLocations:this.state.lastLocation}</div> : ""}
                                                        </div>
                                                        {/* {this.state.SearchWithblNo || this.state.SearchWithContainerNo ?

                                                        <div>
                                                        <div><span className="book-btn btn" onClick={e => {
                                                                                            e.preventDefault();

                                                                                            this.setState({
                                                                                                latest:true
                                                                                            },()=>{

                                                                                                this.SearchTrack()
                                                                                                toast.success("Refresh Tracking Information", {
                                                                                                    position: "top-left",
                                                                                                    autoClose: 3000,
                                                                                                    hideProgressBar: false,
                                                                                                    closeOnClick: true,
                                                                                                    pauseOnHover: true,
                                                                                                    draggable: true,
                                                                                                    progress: undefined,
                                                                                                });
                                                                                            })
                                                                                        }}>Refresh Tracking </span></div>
                                                            </div>:""} */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {this.state.viewDetails ? */}
                                            {this.state.SearchWithblNo || this.state.SearchWithContainerNo ? "" :
                                                <div className="view-btn" onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        viewDetails: !this.state.viewDetails,

                                                    })
                                                }}>
                                                    View Summary&nbsp;&nbsp; {this.state.viewDetails === true ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>}
                                                </div>}
                                            {/* : ""} */}

                                        </div>
                                        {this.state.viewDetails === true ?
                                            <div className="more-details">
                                                <div className="tracking_section desktopShowingTable">
                                                    <div className="d-flex flex-wrap" style={{width:'100%'}}>
                                                        <div className="small_tracking">Date</div>
                                                        <div className="small_tracking">Activity</div>
                                                        <div className="full_tracking">Details</div>
                                                        <div className="small_tracking text_right_track">Status</div>
                                                    </div>
                                                    {/*<div className="d-flex flex-wrap">*/}
                                                    {this.state.data && this.state.data.map((obj, i) => {
                                                        return (
                                                            <div className="d-flex flex-wrap content_tracking">
                                                                <div className="small_tracking">{obj.date ? convertDateISOToCustomiseFormat(obj.date) : ""}</div>
                                                                <div className="small_tracking">{obj.activityType}</div>
                                                                <div className="full_tracking">{obj.discription}</div>
                                                                <div className="small_tracking text_right_track"><span className={obj.status==="Pending"?"color_track color_track_red":"color_track"}>{obj.status}</span></div>
                                                            </div>
                                                        )
                                                    })}
                                                    {/*</div>*/}
                                                </div>
                                                <div id="top" className="mobileShowingTable">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Activity</th>
                                                                <th>Details</th>
                                                                <th>STATUS</th>
                                                            </tr>
                                                            {this.state.data && this.state.data.map((obj, i) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td><b>{obj.date ? convertDateISOToCustomiseFormat(obj.date) : ""}</b></td>
                                                                            <td><b>{obj.activityType}</b></td>
                                                                            <td><b>{obj.discription}</b></td>
                                                                            <td><b><span className={obj.status==="Pending"?"color_track color_track_red":"color_track"}>{obj.status}</span></b></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}
                                        {this.state.SearchWithblNo === true ?
                                            <div className="more-details">
                                                <div id="top">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <th>Container Name</th>
                                                                {this.state.SearchThroughBookingRefrence ?

                                                                    <th>Booking Reference No</th>:
                                                                    <th>Bl No</th>
                                                                }
                                                                <th>Current Location</th>
                                                                <th>Vessle No </th>
                                                                <th>Action</th>
                                                            </tr>
                                                            {this.state.ContainersDetails && this.state.ContainersDetails.map((obj, i) => {

                                                                let value = obj && obj.events[obj.events.length - 1]
                                                                let value1 ;
                                                                obj && obj.events.map(e=>{
                                                                    if( e && e.transportCall && e.transportCall.transport_call_type==="PORT_OF_LOADING" && e.equipmentEventTypeCode==="LOAD"){

                                                                        value1=e.transportCall.vessel.vessel_imo_number

                                                                    }
                                                                })



                                                                return (
                                                                    <>




                                                                        <tr>
                                                                            <td><b>{obj.containerNo}</b></td>
                                                                            {this.state.SearchThroughBookingRefrence ?

                                                                                <td><b>{this.state.TrackingDatabyBl && this.state.TrackingDatabyBl.bookingReferenceNo ? this.state.TrackingDatabyBl.bookingReferenceNo : ""}</b></td>:
                                                                                <td><b>{this.state.TrackingDatabyBl && this.state.TrackingDatabyBl.blNo ? this.state.TrackingDatabyBl.blNo : ""}</b></td>
                                                                            }
                                                                            <td><b>{value && value.transportCall && value.transportCall.location && value.transportCall.location.location_name ? value.transportCall.location.location_name : "--"}</b></td>
                                                                            <td><b>{value1 ? value1 : "--"}</b></td>

                                                                            <td


                                                                            ><b>
                                                                                {/* <div className="d-flex origin_actions"> */}
                                                                                <div><span className="book-btn btn" onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.opneContainerDetails(obj, i)
                                                                                }}>View  {this.state.containerDetailsSingle === true ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>}</span></div>
                                                                                {/* </div> */}
                                                                            </b></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div> : ""}
                                        {this.state.containerDetailsSingle ?
                                            <div >
                                                <div id="top" className="tableInsideTable">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <th> Location </th>
                                                                <th>  Description</th>
                                                                <th> Transport Mode</th>
                                                                <th>  Date </th>

                                                                <th>Status</th>
                                                                <th>Voyage No</th>


                                                            </tr>
                                                            {this.state.particularContainerDetails && this.state.particularContainerDetails.events.map((item2, i) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{item2 && item2.transportCall && item2.transportCall.un_location_code ? item2.transportCall.un_location_code : ""} - {item2 && item2.transportCall && item2.transportCall.location && item2.transportCall.location.location_name ? item2.transportCall.location.location_name : ""}</td>
                                                                            <td>{item2.eventDescription}</td>
                                                                            <td>{item2 && item2.transportCall && item2.transportCall.mode_of_transport ? item2.transportCall.mode_of_transport : ""}</td>

                                                                            <td>{item2.eventDateTime ? convertDateISOToCustomiseFormat(item2.eventDateTime) : item2.eventCreatedDateTime ? convertDateISOToCustomiseFormat(item2.eventCreatedDateTime) :"-"}</td>


                                                                            <td>{item2 && item2.eventClassifier ?  item2.eventClassifier: ""}</td>
                                                                            <td>{item2 && item2.transportCall && item2.transportCall.carrier_voyage_number && item2.transportCall.carrier_voyage_number ? item2.transportCall.carrier_voyage_number : "---"}</td>


                                                                        </tr>
                                                                    </>
                                                                )

                                                            })}

                                                            </tbody>
                                                        </table>
                                                    </div> </div></div> : ""}
                                        {this.state.SearchWithContainerNo === true ?
                                            <div>
                                                <div id="top" className="tableInsideTable">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <th> Port </th>
                                                                <th>  Description</th>
                                                                <th> Transport Mode</th>
                                                                <th>  Date </th>

                                                                <th>Location</th>
                                                                <th>Voyage No</th>
                                                            </tr>
                                                            {this.state.TrackingDatabyContainer && this.state.TrackingDatabyContainer.events.map((item2, i) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td><b>{item2 && item2.transportCall && item2.transportCall.UNLocationCode ? item2.transportCall.UNLocationCode : ""}</b></td>
                                                                            <td><b>{item2 && item2.carrierSpecificData && item2.carrierSpecificData.internalEventLabel ? item2.carrierSpecificData.internalEventLabel : ""}</b></td>


                                                                            <td><b>{item2 && item2.transportCall && item2.transportCall.modeOfTransport ? item2.transportCall.modeOfTransport : ""}</b></td>
                                                                            <td><b>{item2.eventDateTime ? convertDateISOToCustomiseFormat(item2.eventDateTime) : item2.eventCreatedDateTime ? convertDateISOToCustomiseFormat(item2.eventCreatedDateTime) :"-"}</b></td>

                                                                            <td><b>{item2 && item2.transportCall && item2.transportCall.location && item2.transportCall.location.locationName ? item2.transportCall.location.locationName : ""}</b></td>
                                                                            <td>{item2 && item2.carrierSpecificData && item2.carrierSpecificData.bookingExportVoyageReference && item2.carrierSpecificData.bookingExportVoyageReference ? item2.carrierSpecificData.bookingExportVoyageReference : "---"}</td>
                                                                        </tr>
                                                                    </>

                                                                )




                                                            })}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                        {this.state.SearchWithBookingNo === true ?
                                            <div className="more-details">
                                                <div id="top">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <th>Container Name</th>
                                                                <th> Bl No</th>
                                                                <th>Current Location</th>
                                                                <th>Voyage Number</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            {this.state.BookingRefrenceDetails && this.state.BookingRefrenceDetails.map((obj, i) => {

                                                                let value = obj && obj.events[obj.events.length - 1]
                                                                //  console.log("valuevaluevalue",value)

                                                                return (
                                                                    <>




                                                                        <tr>
                                                                            <td><b>{obj.containerNo}</b></td>
                                                                            <td><b>{this.state.TrackingDatabyBookingRefrenceNo && this.state.TrackingDatabyBookingRefrenceNo.blNo ? this.state.TrackingDatabyBookingRefrenceNo.blNo : ""}</b></td>

                                                                            <td><b>{value && value.transportCall && value.transportCall.location && value.transportCall.location.location_name ? value.transportCall.location.location_name : "--"}</b></td>
                                                                            <td><b>{value && value.transportCall && value.transportCall.location && value.transportCall.carrier_voyage_number ? value.transportCall.carrier_voyage_number : "--"}</b></td>

                                                                            <td


                                                                            ><b>
                                                                                {/* <div className="d-flex origin_actions"> */}
                                                                                <div><span className="book-btn btn mr-2" onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.opneContainerDetailsBooking(obj, i)
                                                                                }}>View  {this.state.bookingDetailsSingle === true ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>}</span></div>
                                                                                {/* </div> */}
                                                                            </b></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {this.state.bookingDetailsSingle ?
                                                        <div >
                                                            <div id="top" className="tableInsideTable">
                                                                <div className="table-responsive">
                                                                    <table className="table">
                                                                        <tbody>
                                                                        <tr>
                                                                            <th> Port </th>
                                                                            <th>  Description</th>
                                                                            <th> Transport Mode</th>
                                                                            <th>  Date </th>

                                                                            <th>Location</th>
                                                                            <th>Voyage No</th>


                                                                        </tr>
                                                                        {this.state.particularBookingDetails && this.state.particularBookingDetails.events.map((item2, i) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td><b>{item2 && item2.transportCall && item2.transportCall.un_location_code ? item2.transportCall.un_location_code : ""}</b></td>
                                                                                        <td><b>{item2.eventDescription}</b></td>
                                                                                        <td><b>{item2 && item2.transportCall && item2.transportCall.mode_of_transport ? item2.transportCall.mode_of_transport : ""}</b></td>

                                                                                        <td><b>{item2.eventDateTime ? convertDateISOToCustomiseFormat(item2.eventDateTime) : item2.eventCreatedDateTime ? convertDateISOToCustomiseFormat(item2.eventCreatedDateTime) :"-"}</b></td>


                                                                                        <td><b>{item2 && item2.transportCall && item2.transportCall.location && item2.transportCall.location.location_name ? item2.transportCall.location.location_name : ""}</b></td>
                                                                                        <td>{item2 && item2.transportCall && item2.transportCall.carrier_voyage_number && item2.transportCall.carrier_voyage_number ? item2.transportCall.carrier_voyage_number : "---"}</td>


                                                                                    </tr>
                                                                                </>
                                                                            )

                                                                        })}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                </div>
                                            </div> : ""}


                                    </div>
                                </div>

                            </div>
                            : ""}

                    </div>
                    {this.state.MapView ?
                        <div className="dashboard-tracking-section">
                            <div className="tracking-shipment">
                                <div className="container-fluid">
                                    <div className="row shipment_details_up">
                                        <div className="details_for_rate">
                                            <div
                                                className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <h3>Vehicle No</h3>
                                                    <div className="icon_origin_destination">
                                                        <FontAwesomeIcon icon={faArrowRight}/></div>
                                                    <h3>{this.state.vehicleNumber}</h3>
                                                </div>
                                            </div>
                                            <div className="details-other">
                                                <div className="text-shipment"><span>Last Toll Cross : {this.state.LastTollCross} | Date :  {convertDateISOToCustomiseFormat(this.state.LastTollCrossDate)},</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Map
                                            AllCode={this.state.AllCode}
                                            firstlatitide={this.state.firstlatitide}
                                            firstlongitude={this.state.firstlongitude}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                        :""}
                    {this.state.hideBottomButton === false ?
                        <div className="fixed-btnsbot fixed-btnsbot1">
                            <div className="d-flex flex-wrap justify-content-end">
                                <Button
                                    onClick={e => {
                                        e.preventDefault()
                                        history.push(PAGE_PATHS.AIRLINE_DOCUMENT_MANAGER + "/" + this.state.quoteFor + "/" +this.state.quoteId)
                                        window.location.reload("false")

                                    }}
                                    type="button"
                                    className="btn delete border-cir"
                                >Previous</Button>
                                <Button onClick={e => {
                                    e.preventDefault()
                                    history.push(PAGE_PATHS.DASHBOARD)
                                    window.location.reload("false")
                                }}
                                        type="button"
                                        className="btn delete border-cir"
                                >Home</Button>

                                <Button onClick={e => {
                                    e.preventDefault()
                                    if(this.state.InvoiceStatus === "Approved"){
                                        history.push(PAGE_PATHS.VIEW_FOR_INVOICE_MANAGER + "/" + this.state.quoteFor + "/" + this.state.InvoiceId)
                                        window.location.reload("false")
                                    }else{
                                        history.push(PAGE_PATHS.DASHBOARD)
                                        window.location.reload("false")
                                    }

                                }}
                                        type="button"
                                        className="btn delete border-cir"
                                >Next</Button>

                            </div>
                        </div>
                        : ""}
                </AppLayout>


            </>
        )
    }
};




export default TrackingSearchPageDemoPage;

