import {
    SHIPMENT_HISTORY_DATA_REQUEST,
    SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS,
    SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE,
    COURIER_SHIPMENT_HISTORY_DATA_REQUEST,
    COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS,
    COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE,
     MAWB_DATA_SEND_TO_MAWB_VIEW,SEND_TO_ADD_COPY_ACTION,
} from "../actions";

const initState = {
    loading: false,

};


const shipmentHistoryReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case SHIPMENT_HISTORY_DATA_REQUEST:
            // console.log("SPOT_RATE_CREATE_REQUEST Reducer" + JSON.stringify(payload));
            return {...state, loading: true, error: ""};
        case SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS:
            // console.log("SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, items: payload.result.data, totalItems: payload.result.total,successMessage:payload.message};

        case SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE:
            // console.log("SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
            return {...state, loading: false, items: [], errorMessage: payload};


        case COURIER_SHIPMENT_HISTORY_DATA_REQUEST:
            // console.log("SPOT_RATE_CREATE_REQUEST Reducer" + JSON.stringify(payload));
            return {...state, loading: true, error: ""};
        case COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS:
            // console.log("SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, items: payload.result.data, totalItems: payload.result.total,successMessage:payload.message};

        case COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE:
            // console.log("SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
            return {...state, loading: false, items: [], errorMessage: payload};

        case MAWB_DATA_SEND_TO_MAWB_VIEW:
            // console.log("SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
            return {...state, loading: false, items: payload.bookingData};

        // case SPOT_RATE_UPDATE_REQUEST:
        //     console.log("SPOT_RATE_UPDATE_REQUEST Reducer" + JSON.stringify(payload));
        //     return {...state, loading: true, error: ""};
        // case SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS:
        //     console.log("SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
        //     return {...state, loading: false, responseMessage: payload};
        //
        // case SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE:
        //     console.log("SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
        //     return {...state, loading: false, errorMessage: payload};
        //
        // case SPOT_RATE_DETAIL_REQUEST:
        //     console.log("SPOT_RATE_DETAIL_REQUEST Reducer" + JSON.stringify(payload));
        //     return {...state, loading: true, error: ""};
        // case SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS:
        //     console.log("SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
        //     return {...state, loading: false, spotRateDetail: payload};
        //
        // case SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE:
        //     console.log("SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
        //     return {...state, loading: false, errorMessage: payload};
        //
        // case SPOT_RATE_TICKER_REQUEST:
        //     console.log("SPOT_RATE_TICKER_REQUEST Reducer" + JSON.stringify(payload));
        //     return {...state, loading: true, error: ""};
        //
        // case SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS:
        //     console.log("SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS Reducer" + JSON.stringify(payload));
        //     return {...state, loading: false, spotRateList: payload.data};
        //
        // case SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE:
        //     console.log("SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE Reducer" + JSON.stringify(payload));
        //     return {...state, loading: false, errorMessage: payload};
        case SEND_TO_ADD_COPY_ACTION:
            return { ...state, loading: true, DataFromManager: payload };


        default:
            return initState;
    }
};

export default shipmentHistoryReducer;
