import React, { Component} from "react";
import '../../../assets/css/sass/customeCss.css';
import '../../../assets/css/aboutstyle.css';
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


class AirFreight extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        return (
<>
    <MetaTags>
        {/*<title> Zipaworld: Best Air Freight Forwarder & Cargo Digital Logistics </title>*/}
        <title>Air freight forwarder and air cargo</title>
        {/*<meta name="title" content="Zipaworld: Best Air Freight Forwarder & Cargo Digital Logistics"/>*/}
        <meta name="title" content="Seamless Air Freight Solutions for Swift Air Cargo Transport"/>
        <meta name="description" content="Explore Zipaworld, your trusted partner for seamless air freight and air cargo services.  Elevate your shipping experience with our commitment to excellence in air freight forwarding."/>
        <meta name="keywords" content="air freight forwarder,air cargo shipping,air freighter,air shipping,air cargo shipping,air freight service,air cargo logistics,air cargo shipping company,air cargo logistics in india"/>
    </MetaTags>
            <AppLayout>
                <div ref={this.testRef} className="services_my"></div>
                <div className="services_content paraHyperlink">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Air freight forwarder <span>and air cargo</span></h1>
                                <p>Zipaworld is the best digital air freight forwarder and air cargo logistics service provider in the world. Are you searching for the best air freight forwarder in Noida, Delhi? Then, you are at the <Link to="/About">right place!</Link> Air Freight is the most dynamic and fastest mode of transporting cargo from one place to another. However, air cargo lags in technological advancement. Air cargo still heavily depends on manual working and paper-based processes.</p>
                                <p>In air cargo logistics service, there is a dearth of online platforms and websites like in air travel where one can book their cargo so easily as they <Link to="/">book their travel and vacation</Link>. So, we have come up with the simplest form of managing your air cargo queries online and booking your air consignments with the <Link to="/">most competitive pricing</Link>, live rates, instant registration and log-in, instant automated documentation, promotional offers, membership programs, loyalty miles and much more.</p>
                            </div>
                        </div>

                        <div className="row other_image_services">
                            <div className="col-lg-6">
                                <div className="left_service_Air">
                                    <img className="lazyload mobileHidden" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.09049651030600736-1681905829455.jpg" alt="AirFreight"/>
                                    <img className="lazyload mobileShown" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/images/AirFreightOtherMobile.jpg" alt="AirFreight"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p>The customers are given the option of booking cargo as per their needs, whether it is airport to airport, airport to door, door to airport or door to door. As per the needs, one can select the service and get the all-inclusive cost of freight.</p>
                                <br/>
                                <p>Not just that, Zipaworld also promotes booking of multi-modal transportation booking from the website. Zipaworld personnel who are experienced professionals belonging to the logistics sector, have a strong rapport and thorough know-how of the air freight forwarding process. Zipaworld also provides services like in-<Link to="/CustomsBrokerage">house customs brokerage</Link> for air cargo shipments.</p>
                            </div>
                        </div>

                        <div className="row other_second_services">
                            <div className="col-lg-6">
                                <p>We provide customised solutions depending on your needs and requirements. Don’t worry about the box size, product dimensions, or weight of the cargo, as we provide specific solutions based on understanding the <a href="https://zipaworld.com/BlogDescription/commodities-that-are-allowed-in-air-freight" target="_blank">product characteristics</a> and how it needs to be handled. We, as professional experts in the domain of air freight, can provide you seamless & pocket friendly solutions. </p>
                                <br/>
                            </div>
                            <div className="col-lg-6">
                                <div className="left_service_Air">
                                    <img className="lazyload mobileHidden" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.1980491369127395-1681905867513.jpg" alt="AirFreight"/>
                                    <img className="lazyload mobileShown" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/images/AirFreightImageMobile.jpg" alt="AirFreight"/>
                                </div>
                            </div>
                        </div>
                        <div className="row differentContent">
                            <div className="col-lg-12">
                                <h5 className="marginParaBottom" style={{fontWeight:600}}>Why air freight?</h5>
                                <p>In today’s fast-paced global economy, the efficiency of transporting goods plays a pivotal role. This is where <a href="https://zipaworld.com/BlogDescription/trends_in_the_air_freight_forwarding" target="_blank">air freight</a> forwarding and air cargo logistics come into the picture, offering speed, global accessibility, and reliability. Characterized by speed and competence, this mode of transport plays a crucial role in the global supply chain. Moreover, air freight accounts for 35% of global trade by value.</p>
                                <h5 className="marginParaBottom" style={{fontWeight:600}}>Why choose us?</h5>
                                <p>Zipaworld is not just another name in the logistics industry, it’s a synonym for innovation and efficiency. Our services encompass a wide range of logistics solutions, tailored to meet diverse needs. We employ <a href="https://zipaworld.com/BlogDescription/Uses-Blockchain-Technology-In-International-Trade" target="_blank">cutting-edge technology</a> and practical solutions to address the dynamic needs of air freight forwarding. We ensure high-quality service through our innovative and effective approach.</p>
                                <p>We excel in customer relationship management, building strong relationships with clients through transparency, communication, and personalized services.</p>
                                <p>We offer a wide range of services to our clients.</p>
                                <div className="listsData">
                                    <p>1. Easy 5-step process, from query to invoicing.</p>
                                    <p>2. <a href="https://zipaworld.com/TrackingSearchPage" target="_blank">Real-time tracking</a> via email, SMS, and WhatsApp.</p>
                                    <p>3. 24*7 availability, eliminating time zone differences.</p>
                                    <p>4. Auto and <a href="https://zipaworld.com/BlogDescription/Navigating-Customs-an-Regulatory-Challenges" target="_blank">digital documentation</a>.</p>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <FooterHome/>
            </AppLayout>




            </>

        );
    }


}



export default AirFreight;
