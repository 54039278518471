import React, {Component, Fragment} from "react";
import AppLayout from "../../../layout/AppLayout";
import FooterHome from "./FooterHome";
import RestClient from "../../../util/RestClient";
import {MANAGER_BLOG_MASTER} from "../../../constants";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {history} from "../../../helpers/history";
import {PAGE_PATHS} from "../../../util/PageRoute";

class BlogPageOther extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();
        this.state = {
            page : 1,
            start : 0,
        };
    }

    componentDidMount() {
            this.getBlogsData({}).then(BlogData => {
                if (BlogData && BlogData.success){
                    let BlogResult = BlogData && BlogData.result && BlogData.result.data ? BlogData.result.data : []
                    let blogLength = BlogData && BlogData.result && BlogData.result.data && BlogData.result.data.length ? BlogData.result.data.length : 0
                    let TotalData = BlogData && BlogData.result && BlogData.result.total ? BlogData.result.total : 0
                    this.setState({BlogDataResult:BlogResult,TotalData:TotalData},()=>{
                        let count = TotalData/blogLength
                        if (Number.isInteger(count)) {
                            count = count
                        }
                        else {
                            count = Math.trunc(count)+1
                        }
                        this.setState({
                            count
                        },()=>{
                            let paginationValue = []
                            for(let i =1;i<=count;i++){
                                paginationValue.push(i)
                            }
                            this.setState({
                                paginationValue : paginationValue
                            })
                        })
                    })
                }
            })

    }
    otherPageDataLoad = (page) =>{
        let start = (page-1)*10
        this.setState({
            page:page,start:start
        },()=>{
            this.getBlogsData({start:this.state.start}).then(BlogData => {
                if (BlogData && BlogData.success){
                    let BlogResult = BlogData && BlogData.result && BlogData.result.data ? BlogData.result.data : []
                    let TotalData = BlogData && BlogData.result && BlogData.result.total ? BlogData.result.total : 0
                    this.setState({BlogDataResult:BlogResult,TotalData:TotalData})
                }
            })
        })
    }
    getBlogsData = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_BLOG_MASTER,
            method: "POST",
            data: dataForServer
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {

        return (
            <AppLayout>
                <div className="blog_page" ref={this.testRef}>
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div className="BlogTitle text-center">
                                    <h4>Welcome to Zipaworld blog</h4>
                                    <h6>Learn everything about logistics & beyond</h6>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-stretch justify-content-between">
                            {Array.isArray(this.state.BlogDataResult) && this.state.BlogDataResult.length >= 1
                                ? this.state.BlogDataResult.map((obj, i) => {
                                    return(
                                        <>
                                            <div className="imgWithblog">
                                                <div className="imgBlogs">
                                                    <img src={obj && obj.imgLocation} />
                                                </div>
                                                <div className="TextBlogs">
                                                    <h5 className="redTextBlog">{obj && obj.category}</h5>
                                                    <h4 className="TextBlogHeading">{obj && obj.title}</h4>

                                                    <h6 className="linkBlog" onClick={(e)=>{
                                                        if(obj && obj.Description){
                                                            if (obj.urlTitle){
                                                                localStorage.setItem("BlogTitle","true");
                                                                history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj.urlTitle)
                                                                window.location.reload("false")
                                                            }else {
                                                                e.preventDefault()
                                                                history.push(PAGE_PATHS.BLOG_DESCRIPTION + "/" + obj._id)
                                                                window.location.reload("false")
                                                            }
                                                        }else{
                                                            if(obj && obj.newsCategoryLink){
                                                                window.open(obj.newsCategoryLink)
                                                            }
                                                        }
                                                        }}>Read More <FontAwesomeIcon icon={faArrowRight}/></h6>
                                                </div>
                                            </div>
                                        </>
                                    )

                                })
                                :""
                            }

                        </div>
                        <div className="blogPagination d-flex flex-wrap align-items-center justify-content-center">
                            {Array.isArray(this.state.paginationValue) && this.state.paginationValue.length >= 1
                                ? this.state.paginationValue.map((obj, i) => {
                                    return (
                                        <>
                                            <div className={this.state.page === obj?"Blog_page active":"Blog_page"} onClick={()=>{
                                                this.otherPageDataLoad(obj)
                                            }}>{obj}</div>
                                        </>
                                    )
                                })
                                : ""
                            }

                        </div>
                    </div>
                </div>
                <FooterHome/>
            </AppLayout>
        )
    }


}

export default BlogPageOther;
