import {
    SHIPPER_APPROVAL_CREATE,
    SHIPPER_APPROVAL_CREATE_RESPONSE,
    SHIPPER_APPROVAL_FETCH_FAILURE,
    SHIPPER_APPROVAL_FETCH_MASTER,
    SHIPPER_APPROVAL_FETCH_RESPONSE,
    SHIPPER_APPROVAL_MASTER_PREVIEW,


    CONSIGNEE_APPROVAL_CREATE,
    CONSIGNEE_APPROVAL_CREATE_RESPONSE,
    CONSIGNEE_APPROVAL_FETCH_MASTER,
    CONSIGNEE_APPROVAL_FETCH_RESPONSE,
    CONSIGNEE_APPROVAL_FETCH_FAILURE,
    CONSIGNEE_APPROVAL_MASTER_PREVIEW,
    ADD_SHIPPER_CONSIGNEE_FROM_POPUP,
    ADD_SHIPPER_CONSIGNEE_FROM_POPUP_FAILURE,
    ADD_SHIPPER_CONSIGNEE_FROM_POPUP_SUCCESS
} from "../actions";

const initState = {
    items: [],
    loading: false,
    error: '',
    totalItemCount: 0,
    // ShipperFormValues: {}
};


const masterShipperConsigneeApprovalReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case SHIPPER_APPROVAL_CREATE:
            // console.log("In Shipper Reducer Save " + payload);
            return {...state, loading: true, response: ''};

        case SHIPPER_APPROVAL_CREATE_RESPONSE:
            // console.log("in Shipper CREATE Response" + JSON.stringify(payload));
            return {...state, loading: false, result: '', response: payload.message};

        case SHIPPER_APPROVAL_FETCH_MASTER:
            // console.log("In Shipper Master Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};
        case SHIPPER_APPROVAL_FETCH_RESPONSE:
            // console.log("in Shipper Master Success");
            return {...state, loading: false, items: payload.data, totalItemCount: payload.total, error: ''};

        case SHIPPER_APPROVAL_FETCH_FAILURE:
            // console.log("in Shipper Master Failure" + JSON.stringify(payload));
            return {...state, loading: false, items: [], error: payload.message};


        case SHIPPER_APPROVAL_MASTER_PREVIEW:
            // console.log("In Shipper Master Preview  " + JSON.stringify(payload));
            return {...state, loading: false, ShipperFormValues: payload};


        case CONSIGNEE_APPROVAL_CREATE:
            // console.log("In Consignee Reducer Save " + payload);
            return {...state, loading: true, response: ''};

        case CONSIGNEE_APPROVAL_CREATE_RESPONSE:
            // console.log("in Consignee CREATE Response" + JSON.stringify(payload));
            return {...state, loading: false, result: '', response: payload.message};


        case CONSIGNEE_APPROVAL_FETCH_MASTER:
            // console.log("In Consignee Master Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};
        case CONSIGNEE_APPROVAL_FETCH_RESPONSE:
            // console.log("in Consignee Master Success");
            return {...state, loading: false, itemsConsignee: payload.data, totalItemCountConsignee: payload.total, error: ''};

        case CONSIGNEE_APPROVAL_FETCH_FAILURE:
            // console.log("in Consignee Master Failure" + JSON.stringify(payload));
            return {...state, loading: false, itemsConsignee: [], error: payload.message};

            case ADD_SHIPPER_CONSIGNEE_FROM_POPUP:
            // console.log("In Consignee Master Reducer  " + JSON.stringify(payload));
            return {...state, loading: true, response: ''};
        case ADD_SHIPPER_CONSIGNEE_FROM_POPUP_FAILURE:
            // console.log("in Consignee Master Success");
            return {...state, loading: false, message:  payload.message};

        case ADD_SHIPPER_CONSIGNEE_FROM_POPUP_SUCCESS:
            // console.log("in Consignee Master Failure" + JSON.stringify(payload));
            return {...state, loading: false,  message: payload.message};




        case CONSIGNEE_APPROVAL_MASTER_PREVIEW:
            // console.log("In Consignee Master Preview  " + JSON.stringify(payload));
            return {...state, loading: false, ConsigneeFormValues: payload};



        default:
            return initState;
    }
};

export default masterShipperConsigneeApprovalReducer;
