import {
    SPOT_RATE_CREATE_REQUEST,
    SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS,
    SPOT_RATE_DETAIL_REQUEST,
    SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS,
    SPOT_RATE_UPDATE_REQUEST,
    SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS,

    SPOT_RATE_TICKER_REQUEST,
    SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS,
    SPOT_RATE_DATA_PAGE,
    CHANGE_CURRENCY_FOR_SPOT_RATE,
    SPOT_RATE_DATA_PAGE_AIR,
} from "../actions";


export const onSpotRateCreateRequested = (requestParameters) => ({
    type: SPOT_RATE_CREATE_REQUEST,
    payload: requestParameters
});

export const onSpotRateCreateRespondBackSuccess = (receivedParameters) => ({
    type: SPOT_RATE_CREATE_REQUEST_RESPONSE_SUCCESS,
    payload: receivedParameters
});
export const onSpotRateCreateRespondBackFailure = (message) => ({
    type: SPOT_RATE_CREATE_REQUEST_RESPONSE_FAILURE,
    payload: {message}
});

export const onSpotRateDetailRequested = (requestParameters) => ({
    type: SPOT_RATE_DETAIL_REQUEST,
    payload: requestParameters
});

export const onSpotRateDetailRespondBackSuccess = (receivedParameters) => ({
    type: SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS,
    payload: receivedParameters
});
export const onSpotRateDetailRespondBackFailure = (message) => ({
    type: SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE,
    payload: {message}
});



export const onSpotRateUpdateRequested = (requestParameters) => ({
    type: SPOT_RATE_UPDATE_REQUEST,
    payload: requestParameters
});

export const onSpotRateUpdateRespondBackSuccess = (receivedParameters) => ({
    type: SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS,
    payload: receivedParameters
});
export const onSpotRateUpdateRespondBackFailure = (message) => ({
    type: SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE,
    payload: {message}
});

export const onSpotRateTickerRequested = (requestParameters) => ({
    type: SPOT_RATE_TICKER_REQUEST,
    payload: requestParameters
});
export const openSpotRate = (requestParameters) => ({
    type: SPOT_RATE_DATA_PAGE,
    payload: requestParameters
});
export const openSpotRateAir = (requestParameters) => ({
    type: SPOT_RATE_DATA_PAGE_AIR,
    payload: requestParameters
});

export const changeCurrency = (requestParameters) => ({
    type: CHANGE_CURRENCY_FOR_SPOT_RATE,
    payload: requestParameters
});

export const onSpotRateTickerRespondBackSuccess = (receivedParameters) => ({
    type: SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS,
    payload: receivedParameters
});
export const onSpotRateTickerRespondBackFailure = (message) => ({
    type: SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE,
    payload: {message}
});
