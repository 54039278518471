import {Label} from "reactstrap";
import React, {Component, Fragment} from "react";

import RestClient from "../util/RestClient";
import AsyncPaginate from "react-select-async-paginate";


class OriginDestinationDropdownForOcean extends Component {
    constructor(props) {
        super(props);

        this.state = {
            designationMasterData: [],
            isLoading: true,
            response: [],
            error: "",
            selectedDesignation: undefined,
            selectionOptions: [],
            APIRoute: "",
            resultValue: ""
        };

    }

    loadOptions = async (search, loadedOptions) => {
        try {
            let SearchParameters
            if(this.props.pickUpCityId){
                SearchParameters = {search: search,countryId : this.props.pickUpCityId};
            }else{
                SearchParameters = {search: search};
            }
            if (SearchParameters.search.length >= 3) {
                // alert()
                const data = await RestClient({
                    url: this.state.APIRoute,
                    method: "POST",
                    data: SearchParameters
                });

                let selectionOriginOptionsList = []
                let originData = data.result.data;
                if (data) {
                    for (let i = 0; i < originData.length; i++) {
                        let {_id, name, code, city,countryId} = originData[i];
                        // let {countryName1} = originData[i].countryId.name;
                        // console.log("country Name ",originData[i])
                        let obj =
                            {
                                value: _id,
                                label: name,
                                code: code,
                                city: city,
                                countryName: countryId ?countryId.name : "",
                                countryId: countryId ?countryId._id : ""
                            };

                        selectionOriginOptionsList.push(obj)

                    }
                }
                return {
                    options: selectionOriginOptionsList,
                    hasMore: !search,
                };

            }
        } catch (e) {
            return {
                options: loadedOptions,
                hasMore: false,
            };
        }
    }

    componentDidMount() {
        if (this.props.APIRout && this.props.APIRout !== undefined) {
            this.setState({APIRoute: this.props.APIRout})
        }

        if (this.props.AirportValue && this.props.AirportValue !== undefined) {
            this.setState({resultValue: this.props.AirportValue})
        }


    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.AirportValue && this.props.AirportValue !== prevProps.AirportValue) {
            this.setState({resultValue: this.props.AirportValue})
        }
    }

    render() {


        try {
            return (
                <Fragment>

                    <Label for="from" className="origin-label text-dark" style={{paddingBottom: "0",fontWeight:"700"}}>
                        {this.props.Lable ? this.props.Lable : ""} <sup style={{fontWeight:'700',fontSize:'14px'}}>*</sup>

                    </Label>

                    <AsyncPaginate
                        {...this.props}
                        placeholder={this.props.PlaceHolder}
                        value={this.state.resultValue}
                        // name="pincodeMapped"
                        onChange={values => {
                            if (this.props.spotRateQuery === false) {

                                this.setState({resultValue: values},()=>{
                                })
                                this.props.handler(
                                    values
                                );
                            } else {

                            }


                        }}
                        loadOptions={this.loadOptions}
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                    />


                </Fragment>
            )
        } catch (e) {

        }
    }

}


export default (OriginDestinationDropdownForOcean);

