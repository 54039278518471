import React, {Component, Fragment} from "react";
import {Row, Col, CardTitle, Label, Input, Button, Modal, ModalHeader, ModalBody, FormGroup} from "reactstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {loginUser, registerUser} from "../../../redux/actions";

import SimpleReactValidator from "simple-react-validator";
import {PAGE_PATHS} from "../../../util/PageRoute";
import RestClient from "../../../util/RestClient";
import {history} from "../../../helpers/history";

import {
    calculateChargesAccordingtoStructure,calculateChargesAccordingtoStructureIfDiscount,calculateChargesAccordingtoStructureIf15DaysNoDiscount,calculateChargesAccordingtoStructureIf30DaysNoDiscount,
    calculateChargesAccordingtoStructureIf15DaysAndDiscount,calculateChargesAccordingtoStructureIf30DaysAndDiscount,
    convertDateISO,
    getBaseCurrency,
    getCustomerBranchId,
    getCustomerCurrency,
    getCustomerData,
    getCustomerGStTypeData,
    getCustomerId,
    getCustomerPosData,
    getCustomerUserBranchCodeData,
    getCustomerUserBranchIDData,
    getCustomerUserBranchNameData,
    getCustomerUserBranchPosData,
    getLoggedInUserEmail,
    parseFloatValue
} from "../../../util/CommonUtility";
import ModalFooter from "reactstrap/es/ModalFooter";
import {toast} from "react-toastify";
import {CREATE_BOOKING_API, STORE_QUERY_RATES_API, UPDATE_CONTAINER_RATES_API,UPDATE_RATE_FOR_CREDIT} from "../../../constants";
import ShipperConsigneeModal from "./addShipperAndConsignee";
import {
    sendBookingRates,
    sendBookingRatesSuccess,
    sendQuery, sendRatesForStore
} from "../../../redux/advanceSearch/advancSearchAction";
import DimensionViewCalculateRatePop from "../../../components/DimensionViewCalculateRatePop";

class CalculateDimentionRateData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: "",
            toUpdate: "",

            DimentionModel: false,
            message: "",

            selectNewShipper: false,
            selectNewConsignee: false,
            withLogin:true,
            showNewShipper: false,
            showNewConsignee: false,
            shipperModel:false,
            totalPieces: "",
            gross: "",
            volume: "",
            selectedDimensionType: "CM",
            chargeable: "",
            queryDimentionsData: [],
            attachments:[],
            customerIDByLocalStorage:"",
        };
        this.mandatoryValidations = new SimpleReactValidator({
            autoForceUpdate: this
        });
    }

    calculateChargeable = () => {

        try {
            if (this.state.volume && this.state.gross) {
                /*let volumeWeight = Math.round(this.state.volume);*/
                let volumeWeight = parseFloat(this.state.volume).toFixed(3);
                let grossWeight = Math.round(this.state.gross) / 1000;

                grossWeight > volumeWeight
                    ? this.setState({
                        chargeable: parseFloatValue(grossWeight)
                    })
                    : this.setState({
                        chargeable: parseFloatValue(volumeWeight)
                    });

                if (this.state.grossWeight === "0") {
                    this.setState({
                        calculatedDensity: "0"
                    });
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.setState({
                        calculatedDensity: parseFloat(density).toFixed(2)
                    });
                }
            }
            let obj = {
                volume: this.state.volume,
                totalPieces:this.state.totalPieces,
                grossWeight:this.state.gross,
                dimentions:this.state.queryDimentionsData
            }
            this.props.returnResult(obj)
        } catch (e) {
        }
    };

    componentDidMount() {

        if (this.props.DimentionModel) {
            this.setState({
                DimentionModel: this.props.DimentionModel
            })
        }
        if (this.props.data) {
            this.setState({
                data: this.props.data
            },()=>{
                // console.log("jhjjj",this.props.data)
            })
        }
        if(this.props.iRateIndex){

        }
        if (this.props.totalPieces) {
            this.setState({
                totalPieces: this.props.totalPieces
            })
        }
        if (this.props.shipmentMode) {
            this.setState({
                shipmentMode: this.props.shipmentMode
            })
        }
        if (this.props.queryFor) {
            this.setState({
                queryFor: this.props.queryFor
            })
        }
        if (this.props.gross) {
            this.setState({
                gross: this.props.gross
            })
        }
        if (this.props.tarrifMode) {
            this.setState({
                tarrifMode: this.props.tarrifMode
            })
        }
        if (this.props.containerType) {
            this.setState({
                containerType: this.props.containerType
            },()=>{
                // console.log("container type",JSON.stringify(this.state.containerType))
            })
        }
        if (this.props.dimension) {
            this.setState({
                queryDimentionsData: this.props.dimension
            })
        }
        if (this.props.quoteId) {
            this.setState({
                quoteId: this.props.quoteId
            },()=>{
                // console.log("meri query id ye  h",this.props.quoteId)
            })
        }
        if (this.props.RateIds) {
            this.setState({
                RateIds: this.props.RateIds
            })
        }
        if (this.props.queryDataForcard) {
            this.setState({
                queryDataForcard: this.props.queryDataForcard
            },()=>{

            })
        }
        if (this.props.Id) {
            this.setState({
                Id: this.props.Id
            })
        }
        if (this.props.dimentionType) {
            this.setState({
                dimentionType: this.props.dimentionType
            })
        }
        if (this.props.chargeableWeight) {
            this.setState({
                chargeableWeight: this.props.chargeableWeight
            })
        }
        if (this.props.density) {
            this.setState({
                density: this.props.density
            })
        }
        if (this.props.volume) {
            this.setState({
                volume: this.props.volume
            })
        }
        if(JSON.parse(localStorage.getItem("customer"))){
            let data = JSON.parse(localStorage.getItem("customer")).result;
            this.setState({
                customerIDByLocalStorage : data && data.customerBranchData && data.customerBranchData.customerId ? data.customerBranchData.customerId :""
            })
        }

    }
    /*this.redirect()
    {

    }*/

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.props.sendBookingRatesSuccess(),'pppppppppppppp')
        if (this.props.data && this.props.data !== prevProps.data) {
            this.setState({
                data: this.props.data
            })
        }
        if (this.props.OpenPerForma && this.props.OpenPerForma !== prevProps.OpenPerForma) {

            if (this.props.BookingMassage) {

                let bookingid = this.props.BookingDataFromRevert && this.props.BookingDataFromRevert._id ? this.props.BookingDataFromRevert._id : ""

                if (this.props.OpenPerForma === "Added Successfully"
                    // && this.state.sendNext === true
                ) {
                    localStorage.removeItem("selectedCharge")
                    this.setState({allCharges: [], rateSuccessResponce: this.props.BookingDataFromRevert}, () => {
                        this.sendtoFinalBooking()
                    })

                }
            }
            if (this.props.BookingMassage && this.props.BookingMassage.success === false) {
                this.setState({RateNotFoundModel: true})
            }
            this.props.sendBookingRatesSuccess({result: "", message: ""})

        }
        if (this.props.queryNo && this.props.queryNo !== prevProps.queryNo) {
            let queryNoId = this.props.queryNo && this.props.queryNo._id ? this.props.queryNo._id : ""
            let ShipperConsigneeAlreadyStoreData = this.props.queryNo && this.props.queryNo.shipperConsigneeData ? this.props.queryNo.shipperConsigneeData : ""
            this.setState({queryNo: queryNoId})

            localStorage.setItem('queryNo', JSON.stringify(queryNoId))
            let getCharges =localStorage.getItem('AllCharges')

            let containerTypeData = this.state.containerType;

            if (this.state.withLogin === true) {
                let obj = {
                    quoteId: queryNoId,
                    revertData: getCharges,
                    shipmentMode: this.state.shipmentMode
                }
                if (this.state.shipmentMode !== "") {
                    this.props.sendRatesForStore(obj)
                }

            } else {
                let rateObj = {
                    quoteId: queryNoId,
                    revertData: getCharges,
                    shipmentMode: this.state.shipmentMode
                }
                this.setState({quoteIdForVess: queryNoId})
                // console.log("else datanewnewnew",rateObj.revertData)
                if(rateObj.revertData!== null){
                    this.StoreRatesApi(rateObj).then(responce => {
                        if (responce.success) {
                            let savedRate = responce && responce.result ? responce.result : undefined;
                            if (savedRate !== undefined) {

                                let chargesData = JSON.parse(localStorage.getItem("selectedCharge"))
                                let selectedCharge = {}
                                if (chargesData !== undefined && chargesData !== "" && chargesData !== null) {

                                    // savedRate && savedRate.map((item, i) => {

                                    //     if (item.shippingLineId === chargesData.shippingLineId) {
                                    //         selectedCharge = item;
                                    //     }
                                    // })
                                    // let chargesArray = selectedCharge && selectedCharge.charges ? selectedCharge.charges : [];
                                    let CustGstType = getCustomerGStTypeData()
                                    let Custpos = getCustomerPosData()
                                    let branchPos = getCustomerUserBranchPosData()
                                    let chargesData = JSON.parse(localStorage.getItem("selectedCharge"));
                                    let chargesArray =
                                        chargesData && chargesData.charges ? chargesData.charges : [];
                                    let discountApply = localStorage.getItem("discountGiven")
                                    let creditDays = localStorage.getItem("creditDays")
                                    // let salevalue = calculateChargesAccordingtoStructure(chargesArray, CustGstType, Custpos, branchPos)
                                    let saleData = []
                                    if(discountApply){
                                        saleData =calculateChargesAccordingtoStructureIfDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays == '15 Days' && !discountApply){
                                        saleData =calculateChargesAccordingtoStructureIf15DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays == "30 Days" && !discountApply){
                                        saleData =calculateChargesAccordingtoStructureIf30DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays == '15 Days' && discountApply){
                                        saleData =calculateChargesAccordingtoStructureIf15DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays == '30 Days' && discountApply){
                                        saleData =calculateChargesAccordingtoStructureIf30DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    if(creditDays != "15 Days" && creditDays != '30 Days' && !discountApply){
                                        saleData =calculateChargesAccordingtoStructure(chargesArray,CustGstType,Custpos,branchPos)
                                    }
                                    let salevalue = saleData
                                    let Dataobj = {
                                        quoteId: queryNoId,
                                        houses: this.state.houses,
                                        rateId: selectedCharge && selectedCharge._id ? selectedCharge._id : "",
                                        remarks: "",
                                        otherRemarks: "",
                                        customer: getCustomerData(),
                                        branchId: getCustomerUserBranchIDData(),
                                        branchName: getCustomerUserBranchNameData(),
                                        branchCode: getCustomerUserBranchCodeData(),
                                        branchPos: getCustomerUserBranchPosData(),
                                        reason: "",
                                        buyValue: [],
                                        saleValue: salevalue ? salevalue : 'pk',
                                        otherIncentive: [],
                                        otherShipment: [],
                                        gstType: getCustomerGStTypeData() === "O-OVERSEAS" ? "IGST" : "",
                                        revertData: chargesData,
                                        remainingCharges: this.state.remainingCharges,
                                        shipperConsigneeData: ShipperConsigneeAlreadyStoreData,
                                        NoOfContainer: this.state.NoOfContainer,
                                        totalGross: this.state.totalGross,
                                        totalVolume: this.state.totalVolume,
                                        activityType: this.state.activityType,
                                        incoTermsId: this.state.incoTermsId,
                                        incoTermsName: this.state.incoTermsName,
                                        containerType: containerTypeData,

                                    }

                                    this.setState({BookRateData: Dataobj}, () => {
                                        this.props.sendBookingRates(Dataobj)
                                    },()=>{

                                    })

                                }
                            }

                        }else{
                        }

                    })
                }else{
                }


            }


        }
        if (this.props.messageShowShipper && this.props.messageShowShipper !== prevProps.messageShowShipper) {
            if (this.props.messageShowShipper && localStorage.getItem("ShowShipperAndConsignee") !== null && localStorage.getItem("ShowShipperAndConsignee") === "true") {

                this.setState({
                    shipperModel: true
                })
            }
        }



        if (this.props.DimentionModel && this.props.DimentionModel !== prevProps.DimentionModel) {
            this.setState({
                DimentionModel: this.props.DimentionModel
            })
        }
        if (this.props.totalPieces && this.props.totalPieces !== prevProps.totalPieces) {
            this.setState({
                totalPieces: this.props.totalPieces
            })
        }
        if (this.props.volume && this.props.volume !== prevProps.volume) {
            this.setState({
                volume: this.props.volume
            })
        }
        if (this.props.gross && this.props.gross !== prevProps.gross) {
            this.setState({
                gross: this.props.gross
            })
        }
        if (this.props.shipmentMode && this.props.shipmentMode !== prevProps.shipmentMode) {
            this.setState({
                shipmentMode: this.props.shipmentMode
            })
        }
        if (this.props.queryFor && this.props.queryFor !== prevProps.queryFor) {
            this.setState({
                queryFor: this.props.queryFor
            })
        }
        if (this.props.tarrifMode && this.props.tarrifMode !== prevProps.tarrifMode) {
            this.setState({
                tarrifMode: this.props.tarrifMode
            })
        }
        if (this.props.RateIds && this.props.RateIds !== prevProps.RateIds) {
            this.setState({
                RateIds: this.props.RateIds
            })
        }
        if (this.props.queryDataForcard && this.props.queryDataForcard !== prevProps.queryDataForcard) {
            this.setState({
                queryDataForcard: this.props.queryDataForcard
            },()=>{

            })
        }
        if (this.props.Id && this.props.Id !== prevProps.Id) {
            this.setState({
                Id: this.props.Id
            })
        }

        if (this.props.dimension && this.props.dimension !== prevProps.dimension) {
            this.setState({
                queryDimentionsData: this.props.dimension
            })
        }

        if (this.props.quoteId && this.props.quoteId !== prevProps.quoteId) {
            this.setState({
                quoteId: this.props.quoteId
            },()=>{
                // console.log("meri query id ye  h",this.props.quoteId)
            })
        }
        if (this.props.dimentionType && this.props.dimentionType !== prevProps.dimentionType) {
            this.setState({
                dimentionType: this.props.dimentionType
            })
        }
        if (this.props.chargeableWeight && this.props.chargeableWeight !== prevProps.chargeableWeight) {
            this.setState({
                chargeableWeight: this.props.chargeableWeight
            })
        }
        if (this.props.density && this.props.density !== prevProps.density) {
            this.setState({
                density: this.props.density
            })
        }
        if (this.props.rateDataForCard && this.props.rateDataForCard !== prevProps.rateDataForCard) {
            this.setState({
                queryData: this.props.rateDataForCard,
                priviousRateData: this.props.rateDataForCard && this.props.rateDataForCard.bookingData && this.props.rateDataForCard.bookingData.rateData  ? this.props.rateDataForCard.bookingData.rateData : {} ,
                //quoteData : this.props.rateDataForCard && this.props.rateDataForCard.bookingData && this.props.rateDataForCard.bookingData.quoteData  ? this.props.rateDataForCard.bookingData.quoteData : {} ,
                originId:this.props.rateDataForCard && this.props.rateDataForCard.originAirport && this.props.rateDataForCard.originAirport.name && this.props.rateDataForCard.originAirport.name ? this.props.rateDataForCard.originAirport.name : "",
                destinationId:this.props.rateDataForCard && this.props.rateDataForCard.destinationAirport && this.props.rateDataForCard.destinationAirport && this.props.rateDataForCard.destinationAirport.name ? this.props.rateDataForCard.destinationAirport.name : "",
                clearenceDate:this.props.rateDataForCard && this.props.rateDataForCard.clearenceDate  ? this.props.rateDataForCard.clearenceDate : "",
                quoteId:this.props.rateDataForCard && this.props.rateDataForCard._id  ? this.props.rateDataForCard._id : "",
                gross : this.props.rateDataForCard && this.props.rateDataForCard.grossWeight && this.props.rateDataForCard.grossWeight  ? this.props.rateDataForCard.grossWeight : "",
                volume : this.props.rateDataForCard && this.props.rateDataForCard.volumeWeight && this.props.rateDataForCard.volumeWeight  ? this.props.rateDataForCard.volumeWeight : "",
                chargeable : this.props.rateDataForCard && this.props.rateDataForCard.chargeableWeight && this.props.rateDataForCard.chargeableWeight  ? this.props.rateDataForCard.chargeableWeight : "",
                totalPieces : this.props.rateDataForCard && this.props.rateDataForCard.totalPieces   ? this.props.rateDataForCard.totalPieces : "",
                noOfContainers : this.props.rateDataForCard && this.props.rateDataForCard.noOfContainers   ? this.props.rateDataForCard.noOfContainers : "",
                density : this.props.rateDataForCard && this.props.rateDataForCard.density   ? this.props.rateDataForCard.density : "",
                commodity : this.props.rateDataForCard && this.props.rateDataForCard.commodity   ? this.props.rateDataForCard.commodity : "",
                custType : this.props.rateDataForCard && this.props.rateDataForCard.custType   ? this.props.rateDataForCard.custType : "",
                customerCurrency : this.props.rateDataForCard && this.props.rateDataForCard.customerCurrency   ? this.props.rateDataForCard.customerCurrency : "INR",
                baseCurrency : this.props.rateDataForCard && this.props.rateDataForCard.baseCurrency   ? this.props.rateDataForCard.baseCurrency : "INR",
                additionalService : this.props.rateDataForCard && this.props.rateDataForCard.additionalService   ? this.props.rateDataForCard.additionalService : [],
                customerId : this.props.rateDataForCard && this.props.rateDataForCard.customerId ? this.props.rateDataForCard.customerId : "",
                containerType : this.props.rateDataForCard && this.props.rateDataForCard.containerType   ? this.props.rateDataForCard.containerType : "",
                shipmentMode : this.props.rateDataForCard && this.props.rateDataForCard.shipmentMode   ? this.props.rateDataForCard.shipmentMode : "",
                tarrifMode : this.props.rateDataForCard && this.props.rateDataForCard.tarrifMode   ? this.props.rateDataForCard.tarrifMode : "",
                rateType : this.props.rateDataForCard && this.props.rateDataForCard.rateType   ? this.props.rateDataForCard.rateType : "",
                priviousSelectedId: this.props.rateDataForCard  && this.props.rateDataForCard.buySaleData   && this.props.rateDataForCard.buySaleData.rateId  ? this.props.rateDataForCard.buySaleData.rateId : {} ,
                shippingLineId : this.props.rateDataForCard && this.props.rateDataForCard.buySaleData  && this.props.rateDataForCard.buySaleData.ShipmentDetails && this.props.rateDataForCard.buySaleData.ShipmentDetails.shippingLineId  ? this.props.rateDataForCard.buySaleData.ShipmentDetails.shippingLineId :""

            },()=>{

                if(this.state.originId !== "" && this.state.destinationId !== ""){
                    let mainDataFortest = {}
                    mainDataFortest.quoteId = this.state.quoteId ;
                    mainDataFortest.originId = this.state.originId ;
                    mainDataFortest.destinationId = this.state.destinationId;
                    mainDataFortest.chargeableWeight = this.state.chargeable;
                    mainDataFortest.grossWeight = this.state.gross;
                    mainDataFortest.volumeWeight = this.state.volume;
                    mainDataFortest.tarrifMode = this.state.tarrifMode;
                    mainDataFortest.totalPieces = this.state.totalPieces;
                    mainDataFortest.density = this.state.density;
                    mainDataFortest.clearenceDate = this.state.clearanceDate;
                    mainDataFortest.rateType = this.state.rateType;
                    mainDataFortest.custType = this.state.custType;
                    mainDataFortest.commodity = this.state.commodity;
                    mainDataFortest.customerId = this.state.customerId;
                    mainDataFortest.noOfContainers = this.state.noOfContainers;
                    mainDataFortest.containerType = this.state.containerType;
                    mainDataFortest.shipmentMode = this.state.shipmentMode;
                    mainDataFortest.baseCurrency = this.state.baseCurrency;
                    mainDataFortest.customerCurrency = this.state.customerCurrency;
                    mainDataFortest.additionalService = this.state.additionalService;
                    this.GetRatesQuery(mainDataFortest).then(result=>{
                        if(result.success && result.message === "Data Available"){
                            this.setState({
                                rateData: result && result.result ? result.result : []
                            },()=>{

                            })
                        }
                    })
                }
            })
        }

    }
    StoreRatesApi = async (data) => {
        return await RestClient({
            url: STORE_QUERY_RATES_API,
            method: "POST",
            data: data
        });
    };
    toggleLarge = () =>{
        this.setState(prevState => ({
            DimentionModel: !prevState.DimentionModel
        }), () => {
            if (this.state.DimentionModel === false) {
                this.props.returnClose(true)
            }
        });
    }
    toggleLarge1 = () => {
        // console.log("quoteIDForShipment",this.props.quoteIdForShipment +this.props.quoteIdForPopup);
        const body = {
            toUpdate: this.props.quoteId ||this.props.quoteIdForShipment || this.props.quoteIdForPopup || this.props.data && this.props.data.quoteId,
            grossWeight: this.state.gross,
            volumeWeight: this.state.volume,
            chargeableWeight: this.state.chargeableWeight,
            totalPieces: this.state.totalPieces,
            density: this.state.density,
            dimension:this.state.queryDimentionsData,
            dimentionType: this.state.selectedDimensionType,
            containerType:this.state.containerType || this.props.availableContainers,

        }
        this.UpdateContinars(body).then(response=>{
            if (response.success) {
                let updateContainerResult = response && response.result ? response.result : {}
                this.setState({
                    updateContainerResult:updateContainerResult,
                });
                toast.success("Updated Successfully", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState(prevState => ({
                    DimentionModel: !prevState.DimentionModel,
                    shipperModel:!this.state.shipperModel,
                }), () => {
                    if (this.state.DimentionModel === false) {
                        this.props.returnClose(true)
                    }
                })
                // history.push(PAGE_PATHS.BUY_SALE_DETAIL + "/" + this.state.Id + "/" + this.state.RateIds);
                // window.location.reload("false");
            }
        })
    };

    UpdateContinars = async (body)=> {
        return await RestClient({
            url: UPDATE_CONTAINER_RATES_API,
            method: "POST",
            data: body
        });
    }

    validForm=() => {

        if (this.state.totalPieces === "" || this.state.totalPieces === undefined || this.state.gross === "" || this.state.gross === undefined ||
            this.state.volume === "" || this.state.volume === undefined) {
            this.makeToast("error", "Please Fill Gross weight , total pieces and volume weight")
        }
        else{
            this.setState(prevState => ({
                DimentionModel: !prevState.DimentionModel
            }), () => {
                if (this.state.DimentionModel === false) {
                    this.props.returnClose(true)

                }
                this.props.comopopup();

            });
        }

    }
    makeToast= (eventType,message) =>{
        if(eventType=="success"){
            toast.success(message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else{
            toast.error(message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }
    prepareToBookData = () => {

        let chargesDataa
        this.setState({showLoader: true})
        let chargesData = JSON.parse(localStorage.getItem("afterDiscountCharges"));
        let discountApply = localStorage.getItem("discountGiven")
        if(discountApply){
            chargesDataa  = chargesData[this.props.iRateIndex]
        }else{
            chargesDataa = this.state.data
        }
        let creditDays = localStorage.getItem("creditDays")
        let StoredRateData = this.state.StoredRateData

        // console.log("StoredRateData"+this.state.StoredRateData)
        let containerTypeData = this.state.containerType
        let RatesId = '';
        if(this.props.StoredRateData && this.props.StoredRateData.length){
            RatesId = this.props.StoredRateData && this.props.StoredRateData[this.props.iRateIndex]._id;
        }

        let SelectedRateId = ""
        let isRatesStored = false
        if (chargesDataa !== undefined) {

            // console.log("StoredRateData in ifelse "+JSON.stringify(chargesData))
            // this.props.iRateIndex
            /*StoredRateData.map(item => {
                if (item._id === chargesData._id) {
                    SelectedRateId = item._id
                    isRatesStored = true
                }
            })*/

            SelectedRateId = chargesDataa._id
            isRatesStored = true

            let chargesArray = chargesDataa && chargesDataa.charges ? chargesDataa.charges : [];
            let CustGstType = getCustomerGStTypeData()
            let Custpos = getCustomerPosData()
            let branchPos = getCustomerUserBranchPosData()

            // let salevalue = calculateChargesAccordingtoStructure(chargesArray, CustGstType, Custpos, branchPos)
            let saleData = []
            if(discountApply){
                saleData =calculateChargesAccordingtoStructureIfDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays == '15 Days' && !discountApply){
                saleData =calculateChargesAccordingtoStructureIf15DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays == "30 Days" && !discountApply){
                saleData =calculateChargesAccordingtoStructureIf30DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays == '15 Days' && discountApply){
                saleData =calculateChargesAccordingtoStructureIf15DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays == '30 Days' && discountApply){
                saleData =calculateChargesAccordingtoStructureIf30DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays != "15 Days" && creditDays != '30 Days' && !discountApply){
                saleData =calculateChargesAccordingtoStructure(chargesArray,CustGstType,Custpos,branchPos)
            }
            let salevalue = saleData

            let obj = {
                quoteId: this.state.quoteId || this.props.data && this.props.data.quoteId || 'prakhar',
                houses: this.state.houses,
                rateId: RatesId || SelectedRateId,
                remarks: "",
                otherRemarks: "",
                customer: getCustomerData(),
                branchId: getCustomerUserBranchIDData(),
                branchName: getCustomerUserBranchNameData(),
                branchCode: getCustomerUserBranchCodeData(),
                branchPos: getCustomerUserBranchPosData(),
                reason: "",
                buyValue: [],
                saleValue: salevalue ? salevalue : [],
                otherIncentive: [],
                otherShipment: [],
                gstType: getCustomerGStTypeData() === "O-OVERSEAS" ? "IGST" : "",
                revertData: chargesData,
                remainingCharges: this.state.remainingCharges,
                shipperConsigneeData: this.state.shipperConsigneeData,
                NoOfContainer: this.state.NoOfContainer,
                totalGross: this.state.totalGross,
                totalVolume: this.state.totalVolume,
                activityType: this.state.activityType,
                containerType: containerTypeData,
                isRatesStored: isRatesStored,
                accountType: this.state.accountType,
                incoTermsId: this.state.incoTermsId,
                incoTerms: this.state.incoTerms,
                confirmedRateId: SelectedRateId,

            }
            this.setState({BookRateData: obj}, () => {
                // console.log("objsdfd",JSON.stringify(obj))
                this.props.sendBookingRates(obj)
            })


        }
        else
        {

            // console.log("StoredRateData else part"+this.state.StoredRateData)
            let chargesArray = chargesDataa && chargesDataa.charges ? chargesDataa.charges : [];
            let CustGstType = getCustomerGStTypeData()
            let Custpos = getCustomerPosData()
            let branchPos = getCustomerUserBranchPosData()
            let saleData = []
            if(discountApply){
                saleData =calculateChargesAccordingtoStructureIfDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays == '15 Days' && !discountApply){
                saleData =calculateChargesAccordingtoStructureIf15DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays == "30 Days" && !discountApply){
                saleData =calculateChargesAccordingtoStructureIf30DaysNoDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays == '15 Days' && discountApply){
                saleData =calculateChargesAccordingtoStructureIf15DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays == '30 Days' && discountApply){
                saleData =calculateChargesAccordingtoStructureIf30DaysAndDiscount(chargesArray,CustGstType,Custpos,branchPos)
            }
            if(creditDays != "15 Days" && creditDays != '30 Days' && !discountApply){
                saleData =calculateChargesAccordingtoStructure(chargesArray,CustGstType,Custpos,branchPos)
            }
            let salevalue = saleData

            let obj = {
                quoteId:this.state.quoteId || this.props.data && this.props.data.quoteId,
                houses: this.state.houses,
                rateId: RatesId,
                remarks: "",
                otherRemarks: "",
                customer: getCustomerData(),
                branchId: getCustomerUserBranchIDData(),
                branchName: getCustomerUserBranchNameData(),
                branchCode: getCustomerUserBranchCodeData(),
                branchPos: getCustomerUserBranchPosData(),
                reason: "",
                buyValue: [],
                saleValue: salevalue ? salevalue : [],
                otherIncentive: [],
                otherShipment: [],
                gstType: getCustomerGStTypeData() === "O-OVERSEAS" ? "IGST" : "",

                revertData: chargesData,

                remainingCharges: this.state.remainingCharges,
                shipperConsigneeData: this.state.shipperConsigneeData,
                NoOfContainer: this.state.NoOfContainer,
                totalGross: this.state.totalGross,
                totalVolume: this.state.totalVolume,
                activityType: this.state.activityType,
                containerType: containerTypeData,
                isRatesStored: isRatesStored,
                accountType: this.state.queryDataForcard.accountType,
                incoTermsId: this.state.queryDataForcard.incoTermsId,
                incoTerms: this.state.queryDataForcard.incoTerms,
                confirmedRateId: SelectedRateId,


            }
            this.setState({BookRateData: obj}, () => {
                // console.log("objs",JSON.stringify(obj))
                this.props.sendBookingRates(obj)
            })
        }


    }
    prepareToSaveDataWithoutLogin = () => {
        debugger
        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("already_registered").length > 0) {
            let queryData = JSON.parse(localStorage.getItem("queryData"))
            queryData.shipperConsigneeData = this.state.shipperConsigneeData;
            queryData.customerId = getCustomerId();
            queryData.customerBranchId = getCustomerBranchId();
            queryData.customerCurrency = getCustomerCurrency();
            queryData.baseCurrency = getBaseCurrency();
            queryData.branchId = getCustomerUserBranchIDData();
            let customerEmail = getLoggedInUserEmail()
            let EmailArry = [];
            if (!localStorage.getItem("customer") === false) {
                let userData = JSON.parse(localStorage.getItem("customer"))
                let csBuddy = "";
                let pricingHead = "";
                let salesPerson = "";
                let financePerson = "";
                csBuddy = userData && userData.result && userData.result.csBuddyData && userData.result.csBuddyData._id ? userData.result.csBuddyData._id : "";

                pricingHead = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.pricingHead ? userData.result.customerBranchData.pricingHead : "";

                salesPerson = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.salesPerson ? userData.result.customerBranchData.salesPerson : "";


                financePerson = userData && userData.result && userData.result.customerBranchData && userData.result.customerBranchData.financePerson ? userData.result.customerBranchData.financePerson : "";
                queryData.csBuddy = csBuddy;
                queryData.pricingHead = pricingHead;
                queryData.salesPerson = salesPerson;
                queryData.financePerson = financePerson;
                queryData.customerMails = EmailArry;
            }

            this.props.sendQuery(queryData)
        }
    }
    sendtoFinalBooking = () => {

        const {rateId, _id, quoteId, customer, branchId, shippingLineName, shippingLineId} = this.state.rateSuccessResponce !== "" ? this.state.rateSuccessResponce : {};
        const {containerType, NoOfContainer, houses, shipperConsigneeData, totalGross, totalVolume, revertData,activityType} = this.state.BookRateData !== "" ? this.state.BookRateData : {};
        let obj = {}
        obj.rateId = rateId;
        obj.buySaleId = _id;
        obj.quoteId = quoteId;
        obj.isCopied = "";
        obj.copiedFrom = {};
        obj.isRejected = "Pending";
        obj.rejectedMessage = "";
        obj.shipmentType = "Direct";
        obj.customerId = customer && customer.customerId ? customer.customerId : "";
        obj.branchId = branchId;
        obj.containerType = containerType;
        obj.containerDetails = containerType;
        obj.noOfContainers = NoOfContainer;
        obj.noOfHouses = houses && houses.length !== undefined ? houses.length : 0;
        obj.houses = houses;
        obj.scacNumber = "";
        obj.shippingLineName = revertData && revertData.shippingLineName ? revertData.shippingLineName : "";
        obj.shippingLineId = revertData && revertData.shippingLineId ? revertData.shippingLineId : "";
        obj.svcContract = "";
        obj.vesselNo = "";
        obj.voyageNo = "";
        obj.placeOfReceipt = "";
        obj.placeOfDelivery = "";
        obj.asAgreed = "";
        obj.blPdfUrl = "";
        obj.blStatus = "Draft";
        obj.notifyPartyName = "";
        obj.notifyPartyAddress = "";
        obj.notifyPartyCity = "";
        obj.notifyPartyZipCode = "";
        obj.notifyPartyCountryId = "";
        obj.notifyPartyAccountInfo = "";
        obj.currency = "";
        obj.shipperOrAgentSignature = "";
        obj.dateOfSignature = convertDateISO(new Date());
        obj.carrierOrAgentSignature = "";
        obj.SCI = "";
        obj.otherInformation = "";
        obj.shipmentBookingNo = "";
        obj.shipmentBookingDate = "";
        obj.natureAndQuantityOfGoods = "";
        obj.isShipmentBooked = false;
        obj.isNegativeShipment = false;
        obj.shipperId = shipperConsigneeData && shipperConsigneeData.shipperId ? shipperConsigneeData.shipperId : "";
        obj.shipperBranchId = shipperConsigneeData && shipperConsigneeData.shipperBranchId ? shipperConsigneeData.shipperBranchId : "";
        obj.shipperAccountNo = shipperConsigneeData && shipperConsigneeData.shipperAccountNo ? shipperConsigneeData.shipperAccountNo : "";
        obj.consigneeId = shipperConsigneeData && shipperConsigneeData.consigneeId ? shipperConsigneeData.consigneeId : "";
        obj.consigneeBranchId = shipperConsigneeData && shipperConsigneeData.consigneeBranchId ? shipperConsigneeData.consigneeBranchId : "";
        obj.consigneeAccountNo = shipperConsigneeData && shipperConsigneeData.consigneeAccountNo ? shipperConsigneeData.consigneeAccountNo : "";
        obj.shipperDetails = shipperConsigneeData && shipperConsigneeData.shipperDetails ? shipperConsigneeData.shipperDetails : "";
        obj.consigneeDetails = shipperConsigneeData && shipperConsigneeData.consigneeDetails ? shipperConsigneeData.consigneeDetails : "";
        obj.issuedBySelectionBranch = "";
        obj.handlingInfo = "";
        obj.remarks = "";
        obj.bookingNo = "";
        obj.blNo = "";
        obj.volumeWeight = totalVolume;
        obj.noOfPieces = this.state.totalPieces;
        obj.accountType = this.state.accountType;
        obj.incoTermsId = this.state.incoTermsId;
        obj.incoTerms = this.state.incoTerms;
        obj.grossWeight = totalGross;
        obj.kgOrLBS = "kg";
        obj.chargeableWeight = this.state.chargeableWeight;

        this.FinalBookingApi(obj).then(responce => {
            if (responce.success) {
                let creditDays = localStorage.getItem('creditDays')
                if(creditDays != ""){
                    this.updateRates({toUpdate  :rateId,creditDaysStatus : true,rateStatus :false,creditDays : creditDays}).then(res=>{
                        if(res.success){
                            localStorage.removeItem('creditDays')
                        }
                    })
                }
                toast.success(responce.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                let bookingId = responce.result && responce.result._id ? responce.result._id : ""
                let HouseNo = responce.result && responce.result.noOfHouses >= 1 ? responce.result.noOfHouses : undefined;
                // if(HouseNo !== undefined && HouseNo >= 1){
                //     this.props.history.push(PAGE_PATHS.HOUSE_VIEW + "/" + bookingId)
                // }else{

                history.push(PAGE_PATHS.VIEW_MAWB_CUSTOMER + "/" + bookingId)
                window.location.reload("false")
                // }

            } else {
                toast.error(responce.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }


        })
    }
    updateRates = async (data) => {
        return await RestClient({
            url: UPDATE_RATE_FOR_CREDIT,
            method: "POST",
            data: data,
        });
    };
    FinalBookingApi = async (data) => {
        return await RestClient({
            url: CREATE_BOOKING_API,
            method: "POST",
            data: data
        });
    };
    render() {

        return (
            <>
                <ShipperConsigneeModal
                    shipperModel={this.state.shipperModel}
                    setmodal={values => values === false ? this.setState({shipperModel: false}) : ""}
                    queryDataForcard={this.state.updateContainerResult}
                    sendResultdata={values => {
                        this.setState({
                            shipperConsigneeData: values.shipperConsignee ? values.shipperConsignee : {},
                            houses: values.houses ? values.houses : undefined,
                            NoOfContainer: values.NoOfContainer ? values.NoOfContainer : undefined,
                            containerType: values.containerType ? values.containerType : undefined,
                            totalGross: values.totalGross ? values.totalGross : undefined,
                            totalVolume: values.totalVolume ? values.totalVolume : undefined,
                            totalPieces: values.totalPieces ? values.totalPieces : undefined,
                            activityType: values.activityType ? values.activityType : undefined,
                            accountType: values.accountType ? values.accountType : undefined,
                            incoTerms: values.incoTerms ? values.incoTerms : undefined,
                            incoTermsId: values.incoTermsId ? values.incoTermsId : undefined,
                            chargeableWeight: values.chargeableWeight ? values.chargeableWeight : undefined,
                        }, () => {
                            if (
                                localStorage.getItem(
                                    "already_registered"
                                ) === "true" &&
                                localStorage.getItem("already_registered")
                                    .length > 0 && this.state.customerId === this.state.customerIDByLocalStorage){
                                this.prepareToBookData()

                            }else{
                                this.prepareToSaveDataWithoutLogin()
                            }
                            // if (
                            //     localStorage.getItem(
                            //         "already_registered"
                            //     ) === "true" &&
                            //     localStorage.getItem("already_registered")
                            //         .length > 0 &&
                            //     this.state.withLogin === true
                            // ) {
                            //     this.prepareToBookData();
                            // } else {
                            //     this.prepareToSaveDataWithoutLogin();
                            // }

                        })
                    }}
                />


                <Modal className="model_contact dimen-width"
                       isOpen={this.state.DimentionModel}
                       size="lg"
                       toggle={this.toggleLarge}
                >


                    <ModalHeader className="model_head" toggle={this.toggleLarge} style={{padding: "0"}}>
                        <NavLink to={`/`} className="white">
                            {/*<span className="logo-single" style={{marginBottom: "-9px"}}/>*/}
                        </NavLink>
                    </ModalHeader>
                    <ModalBody className="">
                        <DimensionViewCalculateRatePop
                            shipmentMode={this.state.shipmentMode}
                            queryFor={this.state.queryFor}
                            tarrifMode={this.state.tarrifMode}
                            allowedPiecesOverride={false}
                            totalPieces={this.state.totalPieces}
                            packingType={this.state.packingType}
                            grossWeight={parseFloatValue(this.state.gross)}
                            getPieces={totalPieces=>{
                                this.setState({totalPieces: totalPieces},()=>{ this.calculateChargeable()})
                            }}
                            volumeWeight={this.state.volume}
                            selectedDimensionType={this.state.selectedDimensionType}
                            chargeableWeight={parseFloatValue(this.state.chargeable)}
                            dimensionArray={this.state.queryDimentionsData}
                            attachmentss={attachmentss=>{
                                this.setState({attachments:attachmentss},()=>{
                                    this.props.attachmentss(this.state.attachments)
                                })
                            }}
                            dimensionOverallChanges={changedDimensionData => {
                                this.setState(
                                    {
                                        totalPieces: changedDimensionData.weight.totalPieces,
                                        gross: parseFloatValue(changedDimensionData.weight.totalGross) === 0
                                            ? this.state.gross
                                            : changedDimensionData.weight.totalGross,
                                        volume: changedDimensionData.weight.totalVolume,
                                        queryDimentionsData: changedDimensionData.dimensionArray,
                                        selectedDimensionType: changedDimensionData.selectedDimensionType,
                                    },
                                    () => {
                                        this.calculateChargeable()
                                    }
                                );

                            }}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            // disabled={!this.state.allowedEdit}
                            type="button"
                            color="danger"
                            onClick={e => {
                                e.preventDefault();
                                this.toggleLarge1()
                            }}
                            name="addDimension"
                        > Submit</Button>
                    </ModalFooter>

                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {user, loading, message, LoginMessage, closeModal,messageShowShipper} = state.authUser;
    const {allOriginItems, allCharges, BookingMassage, SendNext, queryNo, sporRateQueryId, error, OpenPerForma, queryDataForcard, BookingDataFromRevert, StoredRateData, OpenOffer} = state.advanceSearchReducer;

    return {user, loading, message, LoginMessage, closeModal,
        allOriginItems,
        SendNext,
        allCharges,
        BookingMassage,
        queryNo,
        sporRateQueryId,
        queryDataForcard,
        error,
        OpenPerForma,
        BookingDataFromRevert,
        messageShowShipper,
        StoredRateData,
        OpenOffer};
};

export default connect(
    mapStateToProps,
    {registerUser, loginUser,sendBookingRates,sendBookingRatesSuccess,sendQuery,sendRatesForStore,}
)(CalculateDimentionRateData);
