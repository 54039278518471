import React, {Component} from "react";
import {Row, Col, Label, Input, Button, Modal, ModalHeader, ModalBody, FormGroup, CustomInput, Collapse} from "reactstrap";
import {connect} from "react-redux";
import {history} from "../../../helpers/history";
import {loginUser, registerUser} from "../../../redux/actions";
import {Field, Form, Formik} from "formik";
import {
    COMAPANY_GET_API,
    GET_FCL_CONTAINER_DATA,
    GET_HAWB_NUMBER_UNIQUE,
    GET_HAWB_NUMBER_UNIQUE_AIR,
    GET_HAWB_NUMBER_UNIQUE_OCEAN,
    UPDATE_RATE_FOR_CREDIT,
    COMPANYDETAILS,
    MANAGER_INCOTERMS_API,
    ADD_ATTACHMENT,
    E_DOCKET_REQUEST_REQUEST,
    E_DOCKET_REQUEST_REQUEST_OCEAN,
    E_DOCKET_REQUEST_REQUEST_AIR,
    ADD_ATTACHMENT_OCEAN,
    BOOKING_MANAGER_MASTER_GET_DATA,
    MANAGER_SHIPPER,
    MANAGER_SHIPPER_BRANCH,
    MANAGER_CONSIGNEE,
    MANAGER_CONSIGNEE_BRANCH,
    ADDITIONAL_RATES_UPDATE,
    ADD_ATTACHMENT_AIR,
    BOOKING_MANAGER_MASTER_GET_DATA_AIR,
    BOOKING_MANAGER_MASTER_GET_DATA_OCEAN,
    UPDATE_RATE_FOR_CREDIT_COURIER,
    UPDATE_RATE_FOR_CREDIT_ROAD,
    UPDATE_RATE_FOR_CREDIT_AIR,
    UPDATE_RATE_FOR_CREDIT_OCEAN,
    ADDITIONAL_RATES_UPDATE_OCEAN,
    ADDITIONAL_RATES_UPDATE_AIR,
} from "../../../constants";
import StateDropdown from "../../../components/stateDropdown";
import CountryDropDown from "../../../components/countryDropDown";
import ShipperWithBranchDropdownForShipperModal from "../../../components/shipperWithBranchDropdownForShipperModal";
import {toast} from "react-toastify";
import {
    getCurrenctCompanyBranchData,
    getCustomerId,
    parseFloatValue
} from "../../../util/CommonUtility";
import RestClient from "../../../util/RestClient";
import HouseDetailCard from "../hawb/houseDetailCard";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {SendConatinerList, sendNextToDashboard} from "../../../redux/advanceSearch/advancSearchAction";
import {AddShipperConsignee} from "../../../redux/ShipperConsigneeApprovalRedux/shipperConsigneeApprovalAction";
import IncoTermsDropDown from "../../../components/IncoTermsDropdown";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import CSVReader from 'react-csv-reader';
import UploadFilesCloudes from "../../../components/uploadFilesCloudes";
import SimpleReactValidator from "simple-react-validator";

import ConsigneeWithBranchDropdownForShipperModal from "../../../components/ConsigneeWithBranchDropdownForShipperModal";

import {faEye, faMinus, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const formSchema = Yup.object().shape({
    length: Yup.number("Only Number").required("Required"),
    width: Yup.number().required("Required"),
    height: Yup.number().required("Required"),
    pieces: Yup.number().required("Required")
});

const dimensionParseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
        header
            .toLowerCase()
            .replace(/\W/g, '_')
}
class ShipperConsigneeModal extends Component {
    MAX_LENGTH_CM = 318;
    MAX_WIDTH_CM = 224;
    MAX_HEIGHT_CM = 168;
    MAX_LENGTH_INCH = 125.197;
    MAX_WIDTH_INCH = 88.189;
    MAX_HEIGHT_INCH = 64.1732;
    MAX_LENGTH_MM = 3180;
    MAX_WIDTH_MM = 2240;
    MAX_HEIGHT_MM = 1680;
    MAX_LENGTH_MTR = 3.18;
    MAX_WIDTH_MTR = 2.24;
    MAX_HEIGHT_MTR = 1.63;

    MAX_LENGTH_FT = 10.4331;
    MAX_WIDTH_FT = 7.34908;
    MAX_HEIGHT_FT = 5.51181;
    totalUsedPiecesByHouses = [];
    houseData = [];
    TotalContainer = [];
    TotalContainerCount = [];
    TotalPiecesMBL = [];
    TotalContainerWeight= [];
    AvailableHbLContainersArray= [];
    ArrayForHBL = [];
    InitialConatinerList =[]

    currentEnteredData = {totalPieces: 0, totalVolume: 0, totalGross: 0, totalChargeable: 0, totalDensity: 0};
    constructor(props) {
        super(props);
        this.state = {
            case4:false,
            emailsSelect: [],
            emailsSelectConsignee:[],
            weightSlabRange:"",
            weightSlab:"",
            response: "",
            showslabpopup:false,
            NoOfContainer: "",
            totalGross: "",
            totalVolume: "",
            accountType: "Prepaid",
            totalPiecesFromQuery: "",
            shipmentMode: "",
            NoOfPiecesForMBL: "",
            toUpdate: "",
            TotalContainerCount:[],
            reloadConsignee: false,
            ArrayCanChnage:true,
            dimensionToggle:false,
            allowedEdit: true,
            selectedDimensionType: "CM",
            reloadShipper: false,
            dimensionCollapse: true,
            remainingContainer: "",
            checked: false,
            NoMoreHawb: false,
            showNewButton: false,
            checkAnotherValuation: false,
            shipperModel: false,
            OldShipperConsigneeHide: false,
            message: "",
            updateData: [],
            queryDimentionsData: [],
            ArrayForHBL: [],
            TotalContainer: [],
            dataObject: {},
            queryDataForcard: {},
            noOfHousesRequired: 0,
            selectNewShipper: false,
            selectNewConsignee: false,
            issuedBySelection: "",
            showNewShipper: false,
            showNewConsignee: false,
            errors: {},
            quoteData: {},
            totalPieces: "",
            houseContainerNumber: "",
            houseContainerNumberZero: "",
            gross: "",
            volume: "",
            chargeable: "",
            shipperName: "",
            chargeableWeight: "",
            totalQueryContainer: "",
            shipperId: "",
            shipperBranchName: "",
            shipperBranchId: "",
            shipperDetails: "",
            branchDropdownOptions: [],
            ContainerList: [],
            houseData: [],
            hawbDropDownOptions: [],
            housesDataForReturn: [],
            AvailableHbLContainersArray: [],
            houseShipperDetails: "",
            houseConsigneeDetails: "",
            changedData: false,
            selectedBranchForHawb: "",
            selectedBranchForHawbObj: {},
            consigneeName: "",
            consigneeId: "",
            consigneeBranchName: "",
            consigneeBranchId: "",
            consigneeDetails: "",
            totalUsedPiecesByHouses: 0,
            shipperAddressLine1: "",
            shipperAddressLine2: "",
            shipperCountryName: "",
            shipperCountryCode: "",
            shipperCountryId: "",
            shipperStateName: "",
            shipperStateCode: "",
            shipperStateId: "",
            shipperCity: "",
            shipperPincode: "",
            shipperContact: "",
            shipperContactName: "",
            shipperContactEmail:[],
            consigneeAddressLine1: "",
            consigneeAddressLine2: "",
            consigneeCountryName: "",
            consigneeCountryCode: "",
            consigneeCountryId: "",
            consigneeStateName: "",
            consigneeStateCode: "",
            consigneeStateId: "",
            consigneeCity: "",
            consigneePincode: "",
            uploadedDimensions: [],
            consigneeContact: "",
            consigneeContactName: "",
            consigneeContactEmail:[],
            commercialInvoiceUrl:"",
            packingListUrl:"",
            refBookingNo: '',
            tab1: true,
            count: 1,
            ShipperNameData: [],
            ConsigneeNameData:[],
            onlyShipper: false,
            showShipperBranchPop: false,
            selectWeight: "Kgs",

        };
        this.handleChecked = this.handleChecked.bind(this);
        this.validatorTab1 = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTab2 = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTab3 = new SimpleReactValidator({ autoForceUpdate: this });
    }



    handleChecked = () =>{
        this.setState({
            checked: !this.state.checked
        }, () => {
            if (this.state.checked === false) {
                this.setInitialStateAfterCheck()
            }
        })
    }

    AddAnotherHouse = (serverObj) => {
        if(this.state.issuedBySelection=="" || this.state.issuedBySelection==undefined || this.state.issuedBySelection==null || this.state.issuedBySelection=="Others"){
            if(this.state.issuedBySelection=="Others"){
            }else{
                toast.error("Please Select Issued By First", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }else{
            let obj = {
                noOfHawb: 1,
                companyName: this.state.issuedBySelection,
            }
            this.getHawbNumberFromServer(obj)

        }

    }
    calculateAvailablePieces = (piecesUsedByHouse) => {
        let pieces = 0;
        this.state.totalUsedPiecesByHouses.map(piece => {
            pieces += parseFloatValue(piece);
        });
        return parseFloatValue(this.state.totalPieces) - pieces;
    };

    getHawbUnique = async (dataForServer) => {
        return await RestClient({
            url: this.props.queryDataForcard && this.props.queryDataForcard.queryFor === "Air" ? GET_HAWB_NUMBER_UNIQUE_AIR : GET_HAWB_NUMBER_UNIQUE_OCEAN,
            method: "POST",
            data: dataForServer
        });
    }
    companyAndBranches = async (dataForServer) => {
        return await RestClient({
            url: COMAPANY_GET_API,
            method: "POST",
            data: dataForServer
        });
    }
    updateRates = async (data) => {
        return await RestClient({
            url: this.state.queryDataForcard && this.state.queryDataForcard.queryFor === "Courier" ? UPDATE_RATE_FOR_CREDIT_AIR : UPDATE_RATE_FOR_CREDIT_OCEAN,
            method: "POST",
            data: data,
        });
    };
    componentDidMount() {
        let userdata = JSON.parse(localStorage.getItem("customer"))

        let branchCompanyName = userdata.result && userdata.result.csBuddyBranchData  && userdata.result.csBuddyBranchData.branchCompanyName ? userdata.result.csBuddyBranchData.branchCompanyName : ""
        this.setState({
            branchCompanyName:branchCompanyName
        })

        let getChargesObj = JSON.parse(localStorage.getItem('selectedCharge'))

        this.setState({
            getChargesObj:getChargesObj
        })

        if(this.props.rateData && this.props.rateData !== ""){
            this.setState({
                rateData:this.props.rateData
            } ,()=>{
                this.setState({
                    weightSlab: this.state.rateData  && this.state.rateData[0] && this.state.rateData[0].charges[0].weightSlab  ? this.state.rateData[0].charges[0].weightSlab :"",
                    weightSlabRange:  this.state.rateData  && this.state.rateData[0] && this.state.rateData[0].charges[0].weightSlabRange  ? this.state.rateData[0].charges[0].weightSlabRange :""
                })


            })
        }

        let CompanyData  =  getCurrenctCompanyBranchData();
        let comapny = {
            companyLogo:CompanyData && CompanyData.branchCompanyLogo ? CompanyData.branchCompanyLogo : "",
        }
        this.setState({comapny: comapny})
        if (localStorage.getItem("already_registered") === "true") {
            this.setState({
                showNewShipper: false,
                showNewConsignee: false,
            })

        }

        if (this.props.newShipper !== undefined) {
            if (this.props.newShipper === true) {
                this.setState({
                    showNewShipper: true,
                    showNewConsignee: true,
                    OldShipperConsigneeHide: true
                })
            }
        }

        if (this.props.queryDataForcard && this.props.queryDataForcard !== "") {

            this.setState({
                queryDataForcard: this.props.queryDataForcard
            }, () => {
                let volumeweightmain
                let chargeableWeightmain
                let originalgrossmain
                let grossmainforcalculation
                let volumeweightmaincalculation

                let originalvolume = this.state.queryDataForcard && this.state.queryDataForcard.volumeWeight ? this.state.queryDataForcard.volumeWeight : ""
                let originalchargeabl =  this.state.queryDataForcard && this.state.queryDataForcard.chargeableWeight ? this.state.queryDataForcard.chargeableWeight : ""
                let originalgross =  this.state.queryDataForcard && this.state.queryDataForcard.grossWeight ? this.state.queryDataForcard.grossWeight : ""

                if(this.props.zipaSuggestionBook){

                    if(this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air"){
                        volumeweightmain =  originalvolume * 167
                        originalgrossmain =  originalgross
                        grossmainforcalculation = originalgross * 167
                        volumeweightmaincalculation = originalvolume * 167

                    }else if (this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"){
                        volumeweightmain =  originalvolume
                        originalgrossmain =  originalgross / 1000
                        grossmainforcalculation = originalgross / 1000
                        volumeweightmaincalculation = originalvolume /1000

                    } else {
                        volumeweightmain =  originalvolume / 167
                        volumeweightmaincalculation =  originalvolume / 167
                        originalgrossmain =  originalgross
                        grossmainforcalculation = originalgross /167

                    }
                    if(volumeweightmaincalculation > grossmainforcalculation){
                        chargeableWeightmain = volumeweightmaincalculation
                      }
                    else {
                        chargeableWeightmain = grossmainforcalculation
                    }
                }else {
                    volumeweightmain = originalvolume
                    chargeableWeightmain = originalchargeabl
                    originalgrossmain =originalgross
                }

                this.setState({
                    quoteData: this.state.queryDataForcard,
                    totalGross :parseFloat(originalgrossmain).toFixed(2),
                    activityType :this.state.queryDataForcard && this.state.queryDataForcard.activityType ? this.state.queryDataForcard.activityType : "",
                    queryFor :this.state.queryDataForcard && this.state.queryDataForcard.queryFor ? this.state.queryDataForcard.queryFor : "",
                    quoteId :this.state.queryDataForcard && this.state.queryDataForcard._id ? this.state.queryDataForcard._id : "",
                    totalVolume :parseFloat(volumeweightmain).toFixed(2),
                    chargeableWeight :parseFloat(chargeableWeightmain).toFixed(2),
                    // chargeable :this.state.queryDataForcard.weight && this.state.queryDataForcard.weight.chargeable ? this.state.queryDataForcard.weight.chargeable : "",
                    NoOfContainer :this.state.queryDataForcard.noOfContainers ? this.state.queryDataForcard.noOfContainers : "",
                    totalQueryContainer: this.state.queryDataForcard.noOfContainers ? this.state.queryDataForcard.noOfContainers : "",
                    totalPiecesFromQuery: this.state.queryDataForcard.totalPieces ? this.state.queryDataForcard.totalPieces : "",
                    shipmentMode: this.state.queryDataForcard.shipmentMode ? this.state.queryDataForcard.shipmentMode : "",
                    containerType:this.state.queryDataForcard.containerType ? this.state.queryDataForcard.containerType : [],
                    // incoTerms: this.state.queryDataForcard.incoTerms ? this.state.queryDataForcard.incoTerms : "",
                    // incoTermsId: this.state.queryDataForcard.incoTermsId ? this.state.queryDataForcard.incoTermsId : "",
              
                },()=>{
                    if(this.state.queryDataForcard.shipmentMode === "FCL"){
                        this.fclOptions(this.state.queryDataForcard.containerType)
                    }

                    if(localStorage.getItem("already_registered") === "true" ){
                        let QueryId = localStorage.getItem("queryNo");
                        let Id = JSON.parse(QueryId)

                        this.getQuoteData({ id: Id }).then(res => {
                            if (res.success) {
                                this.setState({
                                    refBookingNo: res && res.result.refBookingId,
                                }, () => {
                                })
                            }
                        })
                    }
                    if(this.props.shipperModel) {
                        this.incotermsData()
                    }
                })

                this.props.sendNextToDashboard(true)


            })
        }
        if (this.props.shipperModel) {
            this.setState({
                shipperModel: this.props.shipperModel
            },()=>{
                this.shipperData()
                this.consigneeData()
            })
        }
        if (this.props.incoTerms) {
            this.setState({
                incoTerms: this.props.incoTerms
            },()=>{
              
            })
        }
        if (this.props.incoTerms) {
            this.setState({
                incoTerms: this.props.incoTerms
            },()=>{

            })
        }
        if(this.props.isInsuranceTrue){
            this.setState({
                isInsuranceTrue : this.props.isInsuranceTrue
            },()=>{
                if(this.state.isInsuranceTrue === "No"){
                    this.setState({
                        isInsuranceTrue : false
                    })
                }
            })
        }
        if(!this.props.incoTerms){
          let value = localStorage.getItem("Incoterms")
          this.setState({
            incoTerms: value
        })
        }
        if (this.props.incoTermsId) {
            this.setState({
                incoTermsId: this.props.incoTermsId
            })
        }
        // MANAGER_INCOTERMS_API
    }
    incotermsData = () =>{
        this.getIncotermsDataApi({activityType : this.state.activityType}).then((data) => {
            if (data.success) {
                if (data.success && data.result.data !== undefined && data.result.data.length > 0) {
                    this.setState({
                        incotermsData:data.result &&  data.result.data ? data.result.data : []
                    });
                }
            } else {
                this.setState({
                    incotermsData: [],
                });
            }
        });
    }
    getIncotermsDataApi = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_INCOTERMS_API,
            method: "POST",
            data: dataForServer
        });
    };
    shipperData = () => {
        let customerId = getCustomerId()
        this.getShipperDataApi({customerId : customerId}).then((data) => {
            if (data.success) {
                if (data.success && data.result.data !== undefined && data.result.data.length > 0) {
                    this.setState({
                        ShipperNameData:data.result &&  data.result.data ? data.result.data : []
                    });
                }
            } else {
                this.setState({
                    ShipperNameData: [],
                });
            }
        });
    };
    getShipperDataApi = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_SHIPPER,
            method: "POST",
            data: dataForServer
        });
    };
    consigneeData = () => {
        let customerId = getCustomerId()
        this.getConsigneeDataApi({customerId : customerId}).then((data) => {
            if (data.success) {
                if (data.success && data.result.data !== undefined && data.result.data.length > 0) {
                    this.setState({
                        ConsigneeNameData:data.result &&  data.result.data ? data.result.data : []
                    });
                }
            } else {
                this.setState({
                    ConsigneeNameData: [],
                });
            }
        });
    };
    getConsigneeDataApi = async (dataForServer) => {
        return await RestClient({
            url: MANAGER_CONSIGNEE,
            method: "POST",
            data: dataForServer
        });
    };


    toggleLarge = () => {
        this.setState(prevState => ({
            shipperModel: !prevState.shipperModel,
            shipperName:"",
            consigneeName:""
        }), () => {
            if (this.state.shipperModel === false) {
                this.props.setmodal(false)
            }
        });
    };
    toggleLargePrevious = () =>{
        this.setState(prevState => ({
            shipperModel: !prevState.shipperModel,
            shipperName:"",
            consigneeName:""
        }), () => {
            if (this.state.shipperModel === false) {
                this.props.setmodal(false)
                this.props.previousBtns(false)
            }
        });
    }
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }



    isWeightSlabRangeValidFunction = () =>{
        let selectedCharge = JSON.parse(localStorage.getItem('selectedCharge'))
        let AllCharges = JSON.parse(localStorage.getItem('AllCharges'))

        let weighslabofcharges = AllCharges && AllCharges[0] && AllCharges[0].weightSlabRange  ? AllCharges[0].weightSlabRange:""

        let weightslabrangeAllcharges =  this.state.weightSlabRange  || weighslabofcharges

        let weightSlab = ''
        if(selectedCharge){
            weightSlab = selectedCharge.weightSlabRange
        }

        let weighSlab2 = ''
        if (AllCharges){
            weighSlab2 = weightslabrangeAllcharges
            this.setState({
                weighSlab2:weighSlab2
            })
        }

        let a = this.state.weightSlabRange || weightSlab  ||  weighSlab2
        let minimumWeight = a.split(" to ")[0]
        let maximumWeight = a.split(" to ")[1]


        if(this.state.chargeableWeight > maximumWeight ){
            if(this.state.chargeableWeight > maximumWeight)
            {
                this.setState({
                    isWeightSlabRangeValid :false,
                    showslabpopup:true

                })
            } else if (this.state.chargeableWeight < minimumWeight){
                this.setState({
                    isWeightSlabRangeValid :false,
                    showslabpopup:true

                })
            }
            else {
                this.setState({
                    isWeightSlabRangeValid :true
                },()=>{this.SubmitShipperAndConsignee()})
            }

        }
        else {
            this.setState({
                isWeightSlabRangeValid :true
            },()=>{
                this.SubmitShipperAndConsignee()
            })
        }
    }
    closeVolumentryPopup = () =>{
        this.setState({
                showslabpopup :false
            },()=>{
                history.push(PAGE_PATHS.DASHBOARD )
                window.location.reload("false")
            }
        )
    }
    additionalChargesApi = async (dataForServer) => {
        return await RestClient({
            url: this.state.queryDataForcard && this.state.queryDataForcard.queryFor === "Air" ? ADDITIONAL_RATES_UPDATE_AIR : ADDITIONAL_RATES_UPDATE_OCEAN,
            method: "POST",
            data:  dataForServer
        });
    }
    SubmitShipperAndConsignee = () => {

        let maindata = {}
        let topData = {}

        if (this.state.showNewShipper == false) {

            maindata.shipperName = this.state.shipperName
            maindata.shipperId = this.state.shipperId
            maindata.shipperBranchName = this.state.shipperBranchName
            maindata.shipperBranchId = this.state.shipperBranchId
            maindata.shipperDetails = this.state.shipperDetails
            maindata.shipperAccountNo = this.state.shipperAccountNo
            maindata.shipperEmail =this.state.shipperEmail
            maindata.isStoredShipper = true;
        }
        if (this.state.showNewConsignee == false) {
            maindata.consigneeName = this.state.consigneeName
            maindata.consigneeId = this.state.consigneeId
            maindata.consigneeBranchName = this.state.consigneeBranchName
            maindata.consigneeBranchId = this.state.consigneeBranchId
            maindata.consigneeDetails = this.state.consigneeDetails
            maindata.consigneeAccountNo = this.state.consigneeAccountNo
            maindata.consigneeEmail =  this.state.consigneeEmail
            maindata.isStoredConsignee = true;
        }
        if (this.state.showNewShipper == true) {
            maindata.shipperName = this.state.shipperName;
            maindata.shipperAddressLine1 = this.state.shipperAddressLine1;
            maindata.shipperAddressLine2 = this.state.shipperAddressLine2;
            maindata.shipperCountryName = this.state.shipperCountryName;
            maindata.shipperCountryCode = this.state.shipperCountryCode;
            maindata.shipperCountryId = this.state.shipperCountryId;
            maindata.shipperStateName = this.state.shipperStateName;
            maindata.shipperStateCode = this.state.shipperStateCode;
            maindata.shipperStateId = this.state.shipperStateId;
            maindata.shipperCity = this.state.shipperCity;
            maindata.shipperPincode = this.state.shipperPincode;
            maindata.shipperContact = this.state.shipperContact;
            maindata.shipperContactName = this.state.shipperContactName;
            maindata.shipperContactEmail = this.state.emailsSelect
            maindata.isStoredShipper = false;
        }
        if (this.state.showNewConsignee == true) {
            maindata.consigneeName = this.state.consigneeName;
            maindata.consigneeAddressLine1 = this.state.consigneeAddressLine1;
            maindata.consigneeAddressLine2 = this.state.consigneeAddressLine2;
            maindata.consigneeCountryName = this.state.consigneeCountryName;
            maindata.consigneeCountryCode = this.state.consigneeCountryCode;
            maindata.consigneeCountryId = this.state.consigneeCountryId;
            maindata.consigneeStateName = this.state.consigneeStateName;
            maindata.consigneeStateCode = this.state.consigneeStateCode;
            maindata.consigneeStateId = this.state.consigneeStateId;
            maindata.consigneeCity = this.state.consigneeCity;
            maindata.consigneePincode = this.state.consigneePincode;
            maindata.consigneeContact = this.state.consigneeContact;
            maindata.consigneeContactName = this.state.consigneeContactName;
            maindata.consigneeContactEmail =this.state.emailsSelectConsignee;
            maindata.isStoredConsignee = false;
        }

        topData.shipperConsignee = maindata
        if (Array.isArray(this.state.houseData) && this.state.houseData.length >= 1) {
            topData.houses = this.state.houseData;
        }
        if(this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"){
            let containerArry = []
            topData.NoOfContainer = this.state.NoOfContainer;
            if (this.state.shipmentMode === "FCL") {
                this.state.ContainerList.map((item, i) => {
                    if (item.count !== undefined) {
                        containerArry.push({
                            name: item.container,
                            count: item.count
                        })
                    }

                })
                topData.containerType = containerArry;
            }
        }else{
            topData.NoOfContainer = this.state.NoOfPiecesForMBL;
        }
        topData.totalGross = this.state.totalGross;
        topData.queryFor = this.state.queryFor;
        topData.totalVolume = this.state.uploadedDimensions.length >0 ? this.state.volumeWeight : this.state.totalVolume;
        topData.accountType = this.state.accountType;
        topData.commercialInvoiceUrl = this.state.commercialInvoiceUrl;
        topData.packingListUrl = this.state.packingListUrl;
        topData.incoTermsId = this.state.incoTermsId;
        topData.issuedBySelection = this.state.issuedBySelection  == "Others" ? this.state.issuedBySelection:this.state.issuedBySelection;
        topData.issuedBySelectionBranch = this.state.issuedBySelectionBranch;
        topData.natureAndQuantityOfGoods = this.state.queryDataForcard.commodity;
        topData.incoTerms = this.state.incoTerms;
        topData.totalPieces = this.state.totalPiecesFromQuery;
        topData.chargeableWeight = this.state.chargeableWeight;
        topData.dimentions = this.state.uploadedDimensions;
        topData.dimensions = this.state.uploadedDimensions;
        topData.dimentionType = this.state.selectedDimensionType;
        localStorage.removeItem("Incoterms")
        if (this.validateForm(topData)) {

            this.props.sendResultdata(topData)
            
            this.setState({
                shipperModel: true
            }, () => {
            })
            this.props.setmodal(true)
        }
    }


    SaveNewShipper = () =>{
        let userdata = JSON.parse(localStorage.getItem("customer"))
        let customerId = userdata.result && userdata.result.customerBranchData  && userdata.result.customerBranchData.customerId ? userdata.result.customerBranchData.customerId : ""
        let branchId = userdata.result && userdata.result.csBuddyBranchData  && userdata.result.csBuddyBranchData._id ? userdata.result.csBuddyBranchData._id : ""

        let maindata = {}
        this.setState({
            consigneeDetails:""
        },()=>{

        })
        if (this.state.showNewShipper === true) {
            if(this.state.shipperName === "" || this.state.shipperAddressLine1 === "" || this.state.shipperCountryName === "" || this.state.shipperStateName === "" || this.state.shipperCity === ""){
                if(this.state.shipperName === "" || this.state.shipperName === undefined){
                    toast.error(
                        "Please enter Shipper name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperAddressLine1 === "" || this.state.shipperAddressLine1 === undefined){
                    toast.error(
                        "Please enter shipper Address details",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperCountryName === "" || this.state.shipperCountryName === undefined){
                    toast.error(
                        "Please enter country name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperStateName === "" || this.state.shipperStateName === undefined){
                    toast.error(
                        "Please enter state name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.shipperCity === "" || this.state.shipperCity === undefined){
                    toast.error(
                        "Please enter city name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }
            }else{
                maindata.shipperName = this.state.shipperName;
                maindata.shipperAddressLine1 = this.state.shipperAddressLine1;
                maindata.shipperAddressLine2 = this.state.shipperAddressLine2;
                maindata.shipperCountryName = this.state.shipperCountryName;
                maindata.shipperCountryCode = this.state.shipperCountryCode;
                maindata.shipperCountryId = this.state.shipperCountryId;
                maindata.shipperStateName = this.state.shipperStateName;
                maindata.shipperStateCode = this.state.shipperStateCode;
                maindata.shipperStateId = this.state.shipperStateId;
                maindata.shipperCity = this.state.shipperCity;
                maindata.shipperPincode = this.state.shipperPincode;
                maindata.shipperContact = this.state.shipperContact;
                maindata.shipperContactName = this.state.shipperContactName;
                maindata.shipperContactEmail = this.state.emailsSelect;
                maindata.isStoredShipper = false;
                maindata.customerId = customerId;
                maindata.userBranchId = branchId;
                this.props.AddShipperConsignee(maindata)
                this.setState({
                    shipperId:"",
                    shipperName:"",
                    shipperBranchId:"",
                    shipperBranchName:"",
                    shipperDetails:""
                })
            }

        }
    }

    SaveNewConsignee = () =>{
        let userdata = JSON.parse(localStorage.getItem("customer"))
        let customerId = userdata.result && userdata.result.customerBranchData  && userdata.result.customerBranchData.customerId ? userdata.result.customerBranchData.customerId : ""
        let branchId = userdata.result && userdata.result.csBuddyBranchData  && userdata.result.csBuddyBranchData._id ? userdata.result.csBuddyBranchData._id : ""
        let maindata = {}
        if (this.state.showNewConsignee === true) {
            if(this.state.consigneeName === "" || this.state.consigneeAddressLine1 === "" || this.state.consigneeCountryName === "" || this.state.consigneeStateName === "" || this.state.consigneeCity === ""){
                if(this.state.consigneeName === "" || this.state.consigneeName === undefined){
                    toast.error(
                        "Please enter Consignee name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeAddressLine1 === "" || this.state.consigneeAddressLine1 === undefined){
                    toast.error(
                        "Please enter Consignee Address details",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeCountryName === "" || this.state.consigneeCountryName === undefined){
                    toast.error(
                        "Please select country name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeStateName === "" || this.state.consigneeStateName === undefined){
                    toast.error(
                        "Please select state name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }else if(this.state.consigneeCity === "" || this.state.consigneeCity === undefined){
                    toast.error(
                        "Please enter city name",
                        {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }
            }else{
                maindata.consigneeName = this.state.consigneeName;
                maindata.consigneeAddressLine1 = this.state.consigneeAddressLine1;
                maindata.consigneeAddressLine2 = this.state.consigneeAddressLine2;
                maindata.consigneeCountryName = this.state.consigneeCountryName;
                maindata.consigneeCountryCode = this.state.consigneeCountryCode;
                maindata.consigneeCountryId = this.state.consigneeCountryId;
                maindata.consigneeStateName = this.state.consigneeStateName;
                maindata.consigneeStateCode = this.state.consigneeStateCode;
                maindata.consigneeStateId = this.state.consigneeStateId;
                maindata.consigneeCity = this.state.consigneeCity;
                maindata.consigneePincode = this.state.consigneePincode;
                maindata.consigneeContact = this.state.consigneeContact;
                maindata.consigneeContactName = this.state.consigneeContactName;
                maindata.consigneeContactEmail =this.state.emailsSelectConsignee;
                maindata.isStoredConsignee = false;
                maindata.customerId = customerId;
                maindata.userBranchId = branchId;
                this.props.AddShipperConsignee(maindata)
                this.setState({
                    consigneeId:"",
                    consigneeName:"",
                    consigneeBranchId:"",
                    consigneeBranchName:"",
                    consigneeDetails:""
                })
            }

        }
    }

    setInitialState = () => {
        this.setState({
            shipperName: "",
            shipperId: "",
            shipperBranchName: "",
            shipperBranchId: "",
            shipperDetails: "",
            consigneeName: "",
            consigneeId: "",
            consigneeBranchName: "",
            consigneeBranchId: "",
            consigneeDetails: "",
            noOfHousesRequired: 0,
            totalUsedPiecesByHouses: 0,
            ArrayForHBL: [],
            AvailableHbLContainersArray :[],
            changedData: false,
            issuedBySelection: "",
            // issuedBySelectionBranch: "",
            houseData: [],
            updateData: [],
            queryDimentionsData: [],
            selectedBranchForHawb: "",
        })
        this.houseData = [];
        this.ArrayForHBL = [];
        this.AvailableHbLContainersArray = [];
    }

    setInitialStateAfterCheck = () => {
        this.setState({
            noOfHousesRequired: 0,
            totalUsedPiecesByHouses: 0,
            ArrayForHBL: [],
            AvailableHbLContainersArray :[],
            changedData: false,
            NoMoreHawb: false,
            issuedBySelection: "",
            // issuedBySelectionBranch: "",
            houseData: [],
            updateData: [],
            queryDimentionsData: [],
            selectedBranchForHawb: "",
        })
        this.houseData = [];
        this.ArrayForHBL = [];
        this.AvailableHbLContainersArray = [];
    }
    handleShipperSelected = ({shipperName, shipperId, shipperBranchName, shipperBranchId, shipperDetails}) => {
        this.setState({
            shipperName: shipperName,
            shipperId: shipperId,
            shipperBranchName: shipperBranchName,
            shipperBranchId: shipperBranchId,
            showShipperPop:false,
            showShipperBranchPop:false
        }, () => {

            this.setBranchesSelectionFromList(this.state.shipperId)
            if (shipperDetails) {
                if (Object.keys(shipperDetails).length > 0) {
                    try {
                        let addressLine1 = shipperDetails && shipperDetails.addressLine1 ? shipperDetails.addressLine1+ "\n " : ""
                        let addressLine2 = shipperDetails && shipperDetails.addressLine2 ? shipperDetails.addressLine2+ "\n " : ""
                        let stateName = shipperDetails && shipperDetails.stateId && shipperDetails.stateId.name ? shipperDetails.stateId.name : "";
                        let Countriname = shipperDetails && shipperDetails.countryId && shipperDetails.countryId.name ? shipperDetails.countryId.name : "";
                        let pincode = shipperDetails.pincode !== ""?shipperDetails.pincode :"";
                        let email = shipperDetails.email !== ""?shipperDetails.email :"";
                        this.setState({
                            shipperDetails: addressLine1 + addressLine2 + shipperDetails.city + " " + stateName + " " + Countriname + " " + pincode + " " ,
                            shipperDetailsForBookingSyncForBackend: shipperDetails,
                            shipperEmail:email

                        })
                    } catch (e) {

                    }
                }
            }
            if (shipperName){
            this.setState({
                showShipperPop:false,
                showShipperBranchPop:true
            })
        }
        if (shipperBranchName) {
            this.setState({
                showConsigneePop:true,
                showShipperBranchPop:false
            })
        }
        })
    }
    allShipperDeatils = () =>{
        let shipperDetails= this.state.branchDetail
        if (shipperDetails) {
            if (Object.keys(shipperDetails).length > 0) {
                try {
                    let addressLine1 = shipperDetails && shipperDetails.addressLine1 ? shipperDetails.addressLine1+ "\n " : ""
                    let addressLine2 = shipperDetails && shipperDetails.addressLine2 ? shipperDetails.addressLine2+ "\n " : ""
                    let stateName = shipperDetails && shipperDetails.stateId && shipperDetails.stateId.name ? shipperDetails.stateId.name : "";
                    let Countriname = shipperDetails && shipperDetails.countryId && shipperDetails.countryId.name ? shipperDetails.countryId.name : "";
                    let pincode = shipperDetails.pincode !== ""?shipperDetails.pincode :"";
                    let email = shipperDetails.email !== ""?shipperDetails.email :"";
                    this.setState({
                        shipperDetails: addressLine1 + addressLine2 + shipperDetails.city + " " + stateName + " " + Countriname + " " + pincode + "" ,
                        shipperDetailsForBookingSyncForBackend: shipperDetails,
                        shipperEmail:email

                    })
                } catch (e) {

                }
            }
        }
    }
    allConsigneeDeatils = () =>{
        let consigneeDetails= this.state.branchDetailConsignee
        if (consigneeDetails) {
            if (Object.keys(consigneeDetails).length > 0) {
                try {
                    let addressLine1 = consigneeDetails && consigneeDetails.addressLine1 ? consigneeDetails.addressLine1+ "\n " : ""
                    let addressLine2 = consigneeDetails && consigneeDetails.addressLine2 ? consigneeDetails.addressLine2+ "\n " : ""
                    let stateName = consigneeDetails && consigneeDetails.stateId && consigneeDetails.stateId.name ? consigneeDetails.stateId.name : "";
                    let Countriname = consigneeDetails && consigneeDetails.countryId && consigneeDetails.countryId.name ? consigneeDetails.countryId.name : "";
                    let pincode = consigneeDetails.pincode !== ""?consigneeDetails.pincode :"";
                    let email = consigneeDetails.email !== ""?consigneeDetails.email :"";
                    this.setState({
                        consigneeDetails: addressLine1 + addressLine2 + consigneeDetails.city + " " + stateName + " " + Countriname + " " + pincode  + "" ,
                        consigneeDetailsForBookingSyncForBackend: consigneeDetails,
                        consigneeEmail:email

                    })
                } catch (e) {

                }
            }
        }
    }
    handleConsigneeSelected = ({
                                   consigneeName,
                                   consigneeId,
                                   consigneeBranchName,
                                   consigneeBranchId,
                                   consigneeDetails
                               }) => {

        this.setState({
            consigneeName: consigneeName,
            consigneeId: consigneeId,
            consigneeBranchName: consigneeBranchName,
            consigneeBranchId: consigneeBranchId,
            showConsigneePop:false,
            showConsigneeBranchPop:false
        }, () => {
            this.setConsigneeBranchesSelectionFromList(this.state.consigneeId)
            if (consigneeDetails) {
                if (Object.keys(consigneeDetails).length > 0) {
                    try {
                        let addressLine1 = consigneeDetails && consigneeDetails.addressLine1 ? consigneeDetails.addressLine1+ "\n " : ""
                        let addressLine2 = consigneeDetails && consigneeDetails.addressLine2 ? consigneeDetails.addressLine2+ "\n " : ""
                        let stateName = consigneeDetails && consigneeDetails.stateId && consigneeDetails.stateId.name ? consigneeDetails.stateId.name : "";
                        let Countriname = consigneeDetails && consigneeDetails.countryId && consigneeDetails.countryId.name ? consigneeDetails.countryId.name : "";
                        let pincode = consigneeDetails.pincode !== ""?consigneeDetails.pincode :"";
                        let email = consigneeDetails.email !== ""?consigneeDetails.email :"";
                        this.setState({
                            consigneeDetails: addressLine1 + addressLine2 + consigneeDetails.city + " " + stateName + " " + Countriname + " " + pincode  + "" ,
                            consigneeDetailsForBookingSyncForBackend: consigneeDetails,
                            consigneeEmail:email

                        })
                    } catch (e) {

                    }
                }
            }
            if (consigneeName){
                this.setState({

                    showConsigneePop:false,
                    showConsigneeBranchPop:true
            })
    }
    if (consigneeBranchName) {
        this.setState({
            showConsigneeBranchPop:false
        })

    }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.isInsuranceTrue && this.props.isInsuranceTrue !== prevProps.isInsuranceTrue){
            this.setState({
                isInsuranceTrue : this.props.isInsuranceTrue
            },()=>{
                if(this.props.isInsuranceTrue === "No"){
                    this.setState({
                        isInsuranceTrue : false
                    })
                }
            })
        }
        if (this.state.refBookingNo != prevState.refBookingNo) {
            this.getbookingData({ id: this.state.refBookingNo }).then(bookingData => {
                if (bookingData.success) {
                    let BookingResult = bookingData && bookingData.result ? bookingData.result : "";
                    let fields = {}
                    fields.incoTerms = BookingResult && BookingResult.quoteId && BookingResult.quoteId.incoTerms ? BookingResult.quoteId.incoTerms : "";
                    fields.accountType = BookingResult && BookingResult.accountType ? BookingResult.accountType : "";
                    fields.shipperName = BookingResult && BookingResult.shipperId && BookingResult.shipperId.shipperName ? BookingResult.shipperId.shipperName : "";
                    fields.shipperId = BookingResult && BookingResult.shipperId && BookingResult.shipperId._id ? BookingResult.shipperId._id : "";
                    fields.shipperContactName = BookingResult && BookingResult.shipperContactName ? BookingResult.shipperContactName : "";
                    fields.shipperContactNumber = BookingResult && BookingResult.shipperContactNumber ? BookingResult.shipperContactNumber : "";
                    fields.shipperBranchName = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId.branchName ? BookingResult.shipperBranchId.branchName : "";
                    fields.shipperBranchId = BookingResult && BookingResult.shipperBranchId && BookingResult.shipperBranchId._id ? BookingResult.shipperBranchId._id : "";
                    fields.shipperAccountNo = BookingResult && BookingResult.shipperAccountNo ? BookingResult.shipperAccountNo : "";
                    fields.shipperEmail = BookingResult && BookingResult.shipperEmail ? BookingResult.shipperEmail:""
                    fields.shipperDetails = BookingResult && BookingResult.shipperDetails ? BookingResult.shipperDetails : "";
                    fields.consigneeName = BookingResult && BookingResult.consigneeId && BookingResult.consigneeId.consigneeName ? BookingResult.consigneeId.consigneeName : "";
                    fields.consigneeId = BookingResult && BookingResult.consigneeId && BookingResult.consigneeId._id ? BookingResult.consigneeId._id : "";

                    fields.consigneeAccountNo = BookingResult && BookingResult.consigneeAccountNo ? BookingResult.consigneeAccountNo : "";
                    fields.consigneeEmail = BookingResult && BookingResult.consigneeEmail ? BookingResult.consigneeEmail:""
                    fields.consigneeContactName = BookingResult && BookingResult.consigneeContactName ? BookingResult.consigneeContactName : "";
                    fields.consigneeContactNumber = BookingResult && BookingResult.consigneeContactNumber ? BookingResult.consigneeContactNumber : "";
                    fields.consigneeBranchName = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId.branchName ? BookingResult.consigneeBranchId.branchName : "";
                    fields.consigneeBranchId = BookingResult && BookingResult.consigneeBranchId && BookingResult.consigneeBranchId._id ? BookingResult.consigneeBranchId._id : "";
                    fields.consigneeDetails = BookingResult && BookingResult.consigneeDetails ? BookingResult.consigneeDetails : "";
                    this.setState({
                        incoTerms : fields.incoTerms,
                        accountType : fields.accountType,
                        shipperName : fields.shipperName,
                        shipperEmail : fields.shipperEmail,
                        shipperId : fields.shipperId,
                        shipperContactName : fields.shipperContactName,
                        shipperContactNumber : fields.shipperContactNumber,
                        shipperBranchName : fields.shipperBranchName,
                        shipperBranchId : fields.shipperBranchId,
                        shipperAccountNo : fields.shipperAccountNo,
                        shipperDetails : fields.shipperDetails,
                        consigneeName : fields.consigneeName,
                        consigneeId : fields.consigneeId,
                        consigneeAccountNo : fields.consigneeAccountNo,
                        consigneeEmail : fields.consigneeEmail,
                        consigneeContactName : fields.consigneeContactName,
                        consigneeContactNumber : fields.consigneeContactNumber,
                        consigneeBranchName : fields.consigneeBranchName,
                        consigneeBranchId : fields.consigneeBranchId,
                        consigneeDetails : fields.consigneeDetails,

                    })
                }
            })
        }
        if(this.props.incoTerms && this.props.incoTerms !== prevProps.incoTerms){
            this.setState({
                incoTerms:this.props.incoTerms
            })
        }
        if(this.props.incoTermsId && this.props.incoTermsId !== prevProps.incoTermsId){
            this.setState({
                incoTermsId:this.props.incoTermsId
            })
        }
        if(this.props.termsOfSales && this.props.termsOfSales !== prevProps.termsOfSales){
            this.setState({
                termsOfSales:this.props.termsOfSales
            })
        }
        if(this.props.allCharges && this.props.allCharges !== prevProps.allCharges){
            this.setState({
                allCharges:this.props.allCharges
            })
        }

        if(this.props.rateData && this.props.rateData !== prevProps.rateData){
            this.setState({
                rateData:this.props.rateData
            } ,()=>{
                this.setState({
                    weightSlab: this.state.rateData && this.state.rateData[0].weightSlab ? this.state.rateData[0].weightSlab :"" ,
                    weightSlabRange:  this.state.rateData  && this.state.rateData[0].weightSlabRange  ? this.state.rateData[0].weightSlabRange:""
                })
            })
        }
        if (this.props.shipperModel && this.props.shipperModel !== prevProps.shipperModel) {
            this.setState({
                shipperModel: this.props.shipperModel
            }, () => {
                let creditDays = localStorage.getItem('creditDays')
                let selectedCharge = JSON.parse(localStorage.getItem('selectedCharge'))
                if(creditDays!= ''){
                    this.updateRates({toUpdate  :selectedCharge && selectedCharge._id,creditDaysStatus : true,rateStatus :false,creditDays : creditDays}).then(res=>{
                        if(res.success){
                            //   localStorage.removeItem('creditDays')
                        }
                    })
                }
                this.companyAndBranches().then(response => {
                    {
                        let branchDropdownOptions = [];
                        if (response && response.success) {
                            response.result &&
                            response.result.map(branchData => {

                                let branchDataObj = {};
                                branchDataObj.label = branchData.branchName;
                                branchDataObj.value = branchData.branchCode;
                                branchDataObj.prefixQuote = branchData.prefixQuote;
                                branchDropdownOptions.push(branchDataObj);

                            });
                            this.setState({branchDropdownOptions: branchDropdownOptions});
                        }
                    }
                })
                    .catch();
            })
        }
        if (this.props.message && this.props.message !== prevProps.message) {
            toast.success(this.props.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                showNewShipper: false,
                showNewConsignee: false,
                OldShipperConsigneeHide: false,
                reloadConsignee: true,
                reloadShipper: true,

            },()=>{
                this.shipperData()
                this.consigneeData()
            })
        }

        if (this.props.queryDataForcard && this.props.queryDataForcard !== prevProps.queryDataForcard) {

            this.setState({
                queryDataForcard: this.props.queryDataForcard
            }, () => {
                let volumeweightmain
                let chargeableWeightmain
                let originalgrossmain
                let grossmainforcalculation
                let volumeweightmaincalculation

                let originalvolume = this.state.queryDataForcard && this.state.queryDataForcard.volumeWeight ? this.state.queryDataForcard.volumeWeight : ""
                let originalchargeabl =  this.state.queryDataForcard && this.state.queryDataForcard.chargeableWeight ? this.state.queryDataForcard.chargeableWeight : ""
                let originalgross =  this.state.queryDataForcard && this.state.queryDataForcard.grossWeight ? this.state.queryDataForcard.grossWeight : ""

                if(this.props.zipaSuggestionBook){

                    if(this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air"){
                        volumeweightmain =  originalvolume * 167
                        originalgrossmain =  originalgross
                        grossmainforcalculation = originalgross * 167
                        volumeweightmaincalculation = originalvolume * 167

                    }else if (this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"){
                        volumeweightmain =  originalvolume
                        originalgrossmain =  originalgross / 1000
                        grossmainforcalculation = originalgross / 1000
                        volumeweightmaincalculation = originalvolume /1000

                    } else {
                        volumeweightmain =  originalvolume / 167
                        volumeweightmaincalculation =  originalvolume / 167
                        originalgrossmain =  originalgross
                        grossmainforcalculation = originalgross /167

                    }
                    if(volumeweightmaincalculation > grossmainforcalculation){
                        chargeableWeightmain = volumeweightmaincalculation
                        }
                    else {
                        chargeableWeightmain = grossmainforcalculation
                    }
                }else {
                    volumeweightmain = originalvolume
                    chargeableWeightmain = originalchargeabl
                    originalgrossmain =originalgross
                }
                this.setState({
                    quoteData: this.state.queryDataForcard,
                    totalGross :parseFloat(originalgrossmain).toFixed(2),
                    activityType :this.state.queryDataForcard && this.state.queryDataForcard.activityType ? this.state.queryDataForcard.activityType : "",
                    queryFor :this.state.queryDataForcard && this.state.queryDataForcard.queryFor ? this.state.queryDataForcard.queryFor : "",
                    totalVolume :parseFloat(volumeweightmain).toFixed(2),
                    chargeableWeight :parseFloat(chargeableWeightmain).toFixed(2),
                    // totalVolume :this.state.queryDataForcard && this.state.queryDataForcard.volumeWeight ? this.state.queryDataForcard.volumeWeight : "",
                    // chargeableWeight :this.state.queryDataForcard && this.state.queryDataForcard.chargeableWeight ? this.state.queryDataForcard.chargeableWeight : "",

                    // chargeable :this.state.queryDataForcard.weight && this.state.queryDataForcard.weight.chargeable ? this.state.queryDataForcard.weight.chargeable : "",
                    NoOfContainer :this.state.queryDataForcard.noOfContainers ? this.state.queryDataForcard.noOfContainers : "",
                    totalQueryContainer: this.state.queryDataForcard.noOfContainers ? this.state.queryDataForcard.noOfContainers : "",
                    totalPiecesFromQuery: this.state.queryDataForcard.totalPieces ? this.state.queryDataForcard.totalPieces : "",
                    shipmentMode: this.state.queryDataForcard.shipmentMode ? this.state.queryDataForcard.shipmentMode : "",
                    containerType:this.state.queryDataForcard.containerType ? this.state.queryDataForcard.containerType : [],

                },()=>{
                    if(this.state.queryDataForcard.shipmentMode === "FCL"){
                        this.fclOptions(this.state.queryDataForcard.containerType)
                    }
                    if(localStorage.getItem("already_registered") === "true" ){
                        let QueryId = localStorage.getItem("queryNo");
                        let Id = JSON.parse(QueryId)
                        this.getQuoteData({ id: Id }).then(res => {
                            if (res.success) {
                                this.setState({
                                    refBookingNo: res && res.result.refBookingId,
                                }, () => {
                                })
                            }
                        })
                    }
                })

                this.props.sendNextToDashboard(true)


            })
        }
        if (this.props.newShipper && this.props.newShipper !== prevProps.newShipper) {
            if (this.props.newShipper === true) {
                this.setState({
                    showNewShipper: true,
                    showNewConsignee: true,
                    OldShipperConsigneeHide: true
                })
            }
        }

        if (this.props.OpenPerForma && this.props.OpenPerForma !== prevProps.OpenPerForma) {

            if (this.props.BookingMassage && this.props.BookingMassage.success === true) {
                // let bookingid = this.props.BookingDataFromRevert && this.props.BookingDataFromRevert._id ? this.props.BookingDataFromRevert._id : ""
                if (this.props.OpenPerForma === "Rate Inserted Successfully") {
                    if (this.props.container !== undefined) {
                        this.setState({
                            remainingContainer: this.props.container,
                            checkAnotherValuation: true,
                            NoOfContainer: this.props.container,
                            totalGross: "",
                            totalVolume: "",
                            showNewButton: false,
                            checked: false,
                            NoMoreHawb: false
                        })
                        this.sendNextFunc()
                    }
                }
                if (this.props.OpenPerForma === "Booking Created Successfully") {
                    if (this.props.container !== undefined) {
                        this.setState({
                            remainingContainer: this.props.container,
                            checkAnotherValuation: true,
                            NoOfContainer: this.props.container,
                            totalGross: "",
                            totalVolume: "",
                            showNewButton: false,
                            checked: false,
                            NoMoreHawb: false
                        })
                        this.sendNextFunc()

                    }
                }
            }
            if (this.props.BookingMassage && this.props.BookingMassage.success === false) {
                this.setState({RateNotFoundModel: true})
            }

        }

    }
    getQuoteData = async (dataForServer) => {
        return await RestClient({
            url: this.state.queryFor === "Air" ? E_DOCKET_REQUEST_REQUEST_AIR:this.state.queryFor === "Ocean" ?E_DOCKET_REQUEST_REQUEST_OCEAN : E_DOCKET_REQUEST_REQUEST,
            method: "POST",
            data: dataForServer
        })
    };
    getbookingData = async (dataForServer) => {
        return await RestClient({
            url: this.state.queryFor === "Air" ? BOOKING_MANAGER_MASTER_GET_DATA_AIR:this.state.queryFor === "Ocean" ?BOOKING_MANAGER_MASTER_GET_DATA_OCEAN : BOOKING_MANAGER_MASTER_GET_DATA,
            method: "POST",
            data: dataForServer
        })
    };
    fclOptions = (containers) => {
        // USE for Get Data from API And update conatinerllist state
        this.GetContainerData().then(result => {
            if (result && result.success) {
                let resultData = result && result.result ? result.result : ""

                if(Array.isArray(resultData) && resultData.length >=1){
                    let array = []
                    let MultipleDataSlab = containers;
                    resultData && resultData.map((obj, i) => {
                        MultipleDataSlab.map((itm, j) => {
                            if (obj.container == itm.name) {
                                obj.count = itm.count
                                obj.Initialcount = itm.count
                                this.state.TotalContainerCount[i] = itm.count
                                array.push(obj)
                            }

                        })

                    })
                    this.setState({ContainerList:array },()=>{
                        this.InitialConatinerList = array
                    })
                }

            }
        })

    }

    sumArray = (input) => {

        if (toString.call(input) !== "[object Array]")
            return false;

        let total = 0;
        for (let i = 0; i < input.length; i++) {
            if (isNaN(input[i])) {
                continue;
            }
            total += Number(input[i]);
        }
        return total;
    }


    GetContainerData = async (dataForServer) => {
        return await RestClient({
            url: GET_FCL_CONTAINER_DATA,
            method: "POST",
            data: dataForServer
        });
    }
    sendNextFunc = () => {
        this.props.sendNextToDashboard(true)
    }

    ShowNewShipper = () => {
        this.setState({
            showNewShipper: !this.state.showNewShipper
        })
    }

    onCountrySelectedForShipper = ({country, countryCode, countryId}) => {

        this.setState({
            shipperCountryName: country,
            shipperCountryCode: countryCode,
            shipperCountryId: countryId
        });
    };
    onConsigneeCountrySelected = ({country, countryCode, countryId}) => {

        this.setState({
            consigneeCountryName: country,
            consigneeCountryCode: countryCode,
            consigneeCountryId: countryId
        });
    };


    onShipperStateSelected = ({stateName, stateCode, stateId}) => {
        this.setState({
            shipperStateName: stateName,
            shipperStateCode: stateCode,
            shipperStateId: stateId
        });
    };

    onConsigneeStateSelected = ({stateName, stateCode, stateId}) => {
        this.setState({
            consigneeStateName: stateName,
            consigneeStateCode: stateCode,
            consigneeStateId: stateId
        });
    };

    handleChange = e => {this.setState({[e.target.name]: e.target.value});};
    handleEmail = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.keyCode === 13) {
            if (this.validateEmail(event.target.value)) {
                const tags = [...this.state.emailsSelect];
                tags.push(this.state.shipperContactEmail);

                this.setState({ emailsSelect: tags, shipperContactEmail: "",emailValidation:"" });
            } else {
                this.setState({
                    emailValidation : "* Please enter a valid email Id.",
                })
            }
        }
    };
    validateEmail = email => {
        let emailLower = String(email).toLowerCase();
        let re1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re2 = /^[a-zA-Z0-9][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let re3 = /^.{1,64}@.{1,256}$/;
        let reOther = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re1.test(emailLower)){
            if(reOther.test(emailLower)){
                if(re.test(emailLower)){
                    if(re2.test(emailLower)){
                        if(re3.test(emailLower)){
                            return emailLower
                        }
                    }
                }
            }
        }
    };
    deleteEmails = (i, e) => {
        let emailsSelect = [...this.state.emailsSelect];
        emailsSelect.splice(i, 1);
        this.setState({ emailsSelect });
        e.preventDefault();
    };
    handleEmailConsignee = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.keyCode === 13) {
            if (this.validateEmail(event.target.value)) {
                const tags = [...this.state.emailsSelectConsignee];
                tags.push(this.state.consigneeContactEmail);

                this.setState({ emailsSelectConsignee: tags, consigneeContactEmail: "",emailValidationConsignee:"" });
            } else {
                this.setState({
                    emailValidationConsignee : "* Please enter a valid email Id.",
                })
            }
        }
    };
    deleteEmailsConsignee = (i, e) => {
        e.preventDefault();

        let emailsSelectConsignee = [...this.state.emailsSelectConsignee];
        emailsSelectConsignee.splice(i, 1);
        this.setState({ emailsSelectConsignee });
    };
    onIncoTermsSelected = (values) => {

        this.setState({
            incoTermsId: values.value,
            incoTerms: values.label,
        });
    };


    handleUploadedDimensions = data => {
        let totalPieces = 0;
        if (!data) {
            return;
        }

        for (let i = 0; i < data.length; i++) {
            if (data[i].length && data[i].height && data[i].width && data[i].pieces) {
                try {
                    totalPieces += parseInt(data[i].pieces);
                } catch (e) {
                }
            }
        }

        if (data && parseInt(totalPieces) + parseInt(this.calculateOverallDimensionData().totalPieces) > this.state.totalPieces) {
            this.setState({
                dimensionOverload: `Uploaded Document Contains ${totalPieces}  pieces (+ ${
                    this.calculateOverallDimensionData().totalPieces
                } already Added), required only ${
                    this.state.totalPieces ? this.state.totalPieces : 0
                } `
            });
            // return;
        } else {
            this.setState({
                dimensionOverload: ""
            });
        }
        let dimensions = this.state.uploadedDimensions;

        let allDimensions = dimensions.concat(data);

        this.setState({
            uploadedDimensions: allDimensions
        },()=> {
            // this.props.dimensionOverallChanges({
            //     weight: this.calculateOverallDimensionData(),
            //     dimensionArray: this.state.uploadedDimensions,
            //     selectedDimensionType: this.state.selectedDimensionType,
            // })
        });
    };
    toggleDimensionCollapse = () => {
        this.setState({dimensionCollapse: !this.state.dimensionCollapse});
    };
    toggleDimension = () => {

        this.setState({dimensionToggle: !this.state.dimensionToggle});
    };
    toggleNewConsignee = () => {
        this.setState({showNewConsignee: !this.state.showNewConsignee});
    }
    toggleNewShipper = () =>{
        this.setState({showNewShipper: !this.state.showNewShipper});
    }
    DownloadCommercialInvoice = () => {
        document.location.href = this.state.commercialInvoiceUrl;
    };
    DownloadPackingList = () => {
        document.location.href = this.state.packingListUrl;
    };
    attachments = async values => {
        return await RestClient({
            url: this.state.queryFor === "Air" ?ADD_ATTACHMENT_AIR : ADD_ATTACHMENT_OCEAN,
            method: "POST",
            data: values,
        })
    }
    addNewDimension = async values => {
        if (values.length && values.length < 350 && values.width && values.width < 200  && values.height && values.height < 150 && values.pieces) {
            values.selectWeight = this.state.selectWeight
            // if ((await this.isTotalPiecesExceed(values)) === true) {
            //     return
            // }
            await this.validateMaxDimensions(values);
            let dimensions = Array.isArray(this.state.uploadedDimensions) && this.state.uploadedDimensions.length >= 1 ? this.state.uploadedDimensions : [];
            dimensions.push(values);
            this.setState({uploadedDimensions: dimensions}, () => {
                let totalPieces = 0
                for(let i=0;i<this.state.uploadedDimensions.length;i++){
                    totalPieces = totalPieces + Number(this.state.uploadedDimensions[i].pieces)
                }
                this.setState({
                    totalPiecesFromQuery : totalPieces
                })
            });
            this.calculateOverallDimensionData()

        } else if
        (values.length > 350 ) {
            toast.error("Maximun length not more then 300 cm", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if
        (values.width > 200 ) {
            toast.error("Maximun Width not more then 200 cm", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if
        (values.height > 350 ) {
            toast.error("Maximun height not more then 150 cm", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

    calculateOverallDimensionData =()=> {

        let uploadedDimensions = this.state.uploadedDimensions;
        let totalVolume = this.state.totalVolume
        let totalGross = this.state.totalGross
        let totalGrossLbs = this.state.grossWeightLbs
        let totalPieces = this.state.totalPiecesFromQuery


        if (uploadedDimensions.length > 0) {
            totalVolume = 0
            totalGross = 0
            totalPieces = 0
        }


        for (let i = 0; i < uploadedDimensions.length; i++) {
            let vol = this.calculateVolume(uploadedDimensions[i])

            if (uploadedDimensions[i].selectWeight == "Lbs" && uploadedDimensions[i].converetd==undefined) {
                uploadedDimensions[i].gw_pc = (parseFloatValue(uploadedDimensions[i].gw_pc) * 0.453592).toFixed(2)
                uploadedDimensions[i].selectWeight = "Kgs"
                uploadedDimensions[i].converetd = true
            }
            let gross = parseFloatValue(uploadedDimensions[i].gw_pc) * parseInt(uploadedDimensions[i].pieces)
            totalVolume = parseFloatValue(totalVolume + vol)
            if(this.state.shipmentMode === "FCL"){
                let totalGross1 = parseFloatValue(parseFloatValue(totalGross) + gross);
                totalGross = (totalGross1/1000).toFixed(2)
                totalGrossLbs = parseFloatValue(totalGross) * 2.20462
            }else{
                totalGross = parseFloatValue(parseFloatValue(totalGross) + gross);
                totalGrossLbs = parseFloatValue(totalGross) * 2.20462
            }
            totalPieces = parseInt(totalPieces + parseInt(uploadedDimensions[i].pieces));
        }
        this.setState({uploadedDimensions})

        if (uploadedDimensions.length > 0) {
            this.currentEnteredData.totalVolume = totalVolume
            this.currentEnteredData.totalGross = totalGross
            this.currentEnteredData.totalGrossLbs = totalGrossLbs
            if(this.currentEnteredData.totalGross <=0)
            {
                this.currentEnteredData.totalGross = this.state.totalGross
                this.currentEnteredData.totalGrossLbs = this.state.grossWeightLbs
                this.setState({
                    totalGross : this.currentEnteredData.totalGross
                })
            }
            else
            {
                this.currentEnteredData.totalGross = totalGross
                this.currentEnteredData.totalGrossLbs = totalGrossLbs
                this.setState({
                    totalGross : totalGross
                })

            }

            this.currentEnteredData.totalPieces = totalPieces
        } else {
            this.currentEnteredData.totalVolume = totalVolume
            this.currentEnteredData.totalGross = this.state.grossWeight
            this.currentEnteredData.totalGrossLbs = this.state.grossWeightLbs
            this.currentEnteredData.totalPieces = totalPieces
        }

        try {
            if(this.state.getChargesObj && this.state.getChargesObj.queryFor  !=="Air")
            {
                let volumeWeight = parseFloatValue(this.currentEnteredData.totalVolume);
                let grossWeight = parseFloatValue(this.currentEnteredData.totalGross) / 1000;
                let lclvol = 0;

                if (volumeWeight < 1)
                {lclvol = 1}
                else
                {lclvol = volumeWeight}
                if(this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "LCL")
                {


                    grossWeight > lclvol ?
                        this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                        :
                        this.currentEnteredData.totalChargeable = parseInt(lclvol)
                }
                else
                {
                    this.currentEnteredData.totalGross > lclvol

                        ?
                        this.currentEnteredData.totalChargeable = parseInt(this.currentEnteredData.totalGross)

                        :
                        this.currentEnteredData.totalChargeable = parseInt(lclvol)
                }


                if (this.currentEnteredData.totalGross === "0") {
                    this.currentEnteredData.totalDensity = "0"
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.currentEnteredData.totalDensity = parseFloat(density).toFixed(2)
                }
            }
            else
            {
                let volumeWeight = parseFloatValue(this.currentEnteredData.totalVolume);
                let grossWeight = parseFloatValue(this.currentEnteredData.totalGross) ;

                grossWeight > volumeWeight ?
                    this.currentEnteredData.totalChargeable = parseInt(grossWeight)
                    :
                    this.currentEnteredData.totalChargeable = parseInt(volumeWeight)



                if (this.currentEnteredData.totalGross === "0") {
                    this.currentEnteredData.totalDensity = "0"
                } else {
                    let density = (volumeWeight / grossWeight) * 100;
                    this.currentEnteredData.totalDensity = parseFloat(density).toFixed(2)
                }
            }


            // }
            //   debugger
        } catch (e) {
        }

        this.setState({
            grossWeight: parseFloatValue(this.currentEnteredData.totalGross),
            grossWeightLbs: parseFloatValue(this.currentEnteredData.totalGrossLbs),
            volumeWeight: parseFloatValue(this.currentEnteredData.totalVolume).toFixed(3),
            chargeableWeight: parseFloatValue(this.currentEnteredData.totalChargeable),
            calculatedDensity: parseFloatValue(this.currentEnteredData.totalDensity),

        })

        return this.currentEnteredData;


    }

    calculateVolume = values => {
        let length = values.length
        let width = values.width
        let height = values.height
        let pieceCount = parseInt(values.pieces);

        if(this.state.getChargesObj && this.state.getChargesObj.queryFor !== "Air")


        {

            if (this.state.selectedDimensionType === "CM") {
                return (length * width * height * pieceCount) / 1000000;
            } else if (this.state.selectedDimensionType === "FT") {
                return (length * width * height * pieceCount) / 0.211888;
            } else if (this.state.selectedDimensionType === "MM") {
                return (length * width * height * pieceCount) / 1000000000;
            } else if (this.state.selectedDimensionType === "INCHES") {
                return (length * width * height * pieceCount) / 61023.8;
            } else if (this.state.selectedDimensionType === "METERS") {
                return (length * width * height * pieceCount);
            }
        }
        else
        {
            if (this.state.selectedDimensionType === "CM") {
                return(length * width * height * pieceCount) / 6000;
            } else if (this.state.selectedDimensionType === "FT") {
                return(length * width * height * pieceCount) / 0.211888;

            } else if (this.state.selectedDimensionType === "MM") {
                return(length * width * height * pieceCount) / 6000000;

            } else if (this.state.selectedDimensionType === "INCHES") {
                return (length * width * height * pieceCount) / 366;

            } else if (this.state.selectedDimensionType === "METERS") {
                return (length * width * height * pieceCount) / 0.006;

            }
        }

    };
    validateMaxDimensions = values => {
        if (this.state.selectedTariff === "oddDimensions") {
            return true;
        }
        let isValid = false;
        let length = values.length;
        let width = values.width;
        let height = values.height;

        if (this.state.selectedDimensionType === "CM") {
            if (
                length <= this.MAX_LENGTH_CM &&
                width <= this.MAX_WIDTH_CM &&
                height <= this.MAX_HEIGHT_CM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "FT") {
            if (
                length <= this.MAX_LENGTH_FT &&
                width <= this.MAX_WIDTH_FT &&
                height <= this.MAX_HEIGHT_FT
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "MM") {
            if (
                length <= this.MAX_LENGTH_MM &&
                width <= this.MAX_WIDTH_MM &&
                height <= this.MAX_HEIGHT_MM
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "INCHES") {
            if (
                length <= this.MAX_LENGTH_INCH &&
                width <= this.MAX_WIDTH_INCH &&
                height <= this.MAX_HEIGHT_INCH
            ) {
                isValid = true;
            }
        } else if (this.state.selectedDimensionType === "METERS") {
            if (
                length <= this.MAX_LENGTH_MTR &&
                width <= this.MAX_WIDTH_MTR &&
                height <= this.MAX_HEIGHT_MTR
            ) {
                isValid = true;
            }
        }
        if (isValid === false) {
            toastr.success(
                "Dimension Exceed Values ",
                "Tariff Mode Changed to Odd Dimension ",
                {
                    transitionIn: "bounceIn",
                    transitionOut: "bounceOut"
                }
            );
            // this.changeTariffModeTo("Odd Dimention");
        }

        return isValid;
    };


    validateForm =(topData) =>{
        let formIsValid = true;
        if (!this.state.shipperId) {
            formIsValid = false;
            toast.error("Please Select Shipper Name", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (!this.state.consigneeId) {
            formIsValid = false;
            toast.error("Please Select Consignee Name", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (!this.state.shipperBranchId) {
            formIsValid = false;
            toast.error("Please Select Shipper Branch", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (!this.state.consigneeBranchId) {
            formIsValid = false;
            toast.error("Please Select Consignee Branch", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (!this.state.incoTerms) {
            formIsValid = false;
            toast.error("Please Select Inco Terms", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (!this.state.accountType) {
            formIsValid = false;
            toast.error("Please Select Account Type", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }

        if(Array.isArray(topData.houses)){
            for (let i = 0; i < topData.houses.length; i++) {

                let chouse=topData.houses[i]
                let newI=i+1
                if(formIsValid==true && (chouse.houseNumber=="" || chouse.houseNumber==undefined || chouse.houseNumber==null)){
                    formIsValid=false
                    toast.error("Please Provide House No."+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(formIsValid==true && (chouse.shipperId==="" || chouse.shipperId===undefined || chouse.shipperId===null)){
                    formIsValid=false
                    toast.error("Please Provide Shipper "+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(formIsValid==true && (chouse.shipperBranchId=="" || chouse.shipperBranchId==undefined || chouse.shipperBranchId==null)){
                    formIsValid=false
                    toast.error("Please Provide Shipper Branch"+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(formIsValid==true && (chouse.consigneeId=="" || chouse.consigneeId==undefined || chouse.consigneeId==null)){
                    formIsValid=false
                    toast.error("Please Provide Consignee"+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(formIsValid==true && (chouse.consigneeBranchId=="" || chouse.consigneeBranchId==undefined || chouse.consigneeBranchId==null)){
                    formIsValid=false
                    toast.error("Please Provide Consignee Branch"+"( In "+newI+" House )", {
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                // if(chouse.volumeWeight=="" || chouse.volumeWeight==undefined || chouse.volumeWeight==null){
                //     formIsValid=false
                // }
                // if(chouse.noOfPieces=="" || chouse.noOfPieces==undefined || chouse.noOfPieces==null){
                //     formIsValid=false
                // }
                // if(chouse.grossWeight=="" || chouse.grossWeight==undefined || chouse.grossWeight==null){
                //     formIsValid=false
                // }
            }
        }

        return formIsValid;
    }



    checkForValidatingHouseAndMasterData = message => {
        let quoteData =  this.state.quoteData
        let houseData = this.state.houseData

        let totalVolumeHouses=0
        let totalPiecesHouses=0
        let totalGrossHouses=0
        houseData && houseData.map((item, i) => {
            totalVolumeHouses=parseFloat(item.volumeWeight)+parseFloat(totalVolumeHouses)
            totalPiecesHouses=parseFloat(item.noOfPieces)+parseFloat(totalPiecesHouses)
            totalGrossHouses=parseFloat(item.grossWeight)+parseFloat(totalGrossHouses)
        })
        quoteData.volumeWeight=parseFloat(quoteData.volumeWeight).toFixed(2)
        quoteData.totalPieces=Math.round(quoteData.totalPieces)
        quoteData.grossWeight=parseFloat(quoteData.grossWeight).toFixed(2)

        totalVolumeHouses=parseFloat(totalVolumeHouses).toFixed(2)
        totalPiecesHouses=Math.round(totalPiecesHouses)
        totalGrossHouses=parseFloat(totalGrossHouses).toFixed(2)

        quoteData.totalVolumeHouses=totalVolumeHouses
        quoteData.totalPiecesHouses=totalPiecesHouses
        quoteData.totalGrossHouses=totalGrossHouses
        this.setState({quoteData})

    };

    Prev = (value) => {
        switch (value) {
            case 2:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: true,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: true,
                    tab4: false,
                    tab5: false,
                    case4:true,
                    // tab6: false,
                    count: this.state.count - 1,
                },()=>{

                });
                break;
            case 5:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: true,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1
                })
                break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: true,
            //         tab6: false,
            //         count: this.state.count - 1
            //     })
            //     break;
            default:
        }
    };
    Next = (value) => {
        switch (value) {
            case 1:
                if (this.validatorTab1.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: true,
                        tab3: false,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                } else {
                    this.validatorTab1.showMessages();
                    this.forceUpdate();
                }
                break;
            case 2:
                if (this.validatorTab2.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: true,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                } else {
                    this.validatorTab2.showMessages();
                    this.forceUpdate();
                }
                break;
            case 3:
                if (this.validatorTab3.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: false,
                        tab4: true,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                }else{
                    this.validatorTab3.showMessages();
                    this.forceUpdate();
                }
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: true,
                    // tab6: false,
                    count: this.state.count + 1,
                });
                break;
            case 5:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: true,
                    count: this.state.count + 1,
                });
                break;
            case 6:
                // if (this.validatorTab4.allValid()) {
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    // tab5: false,
                    // tab6: true,
                    count: this.state.count + 1,
                });
                // } else {
                //     this.validatorTab4.showMessages();
                //     this.forceUpdate();
                // }
                break;
            default:
        }
    };
    Select = (value) => {
        switch (value) {
            case 1:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: value,
                });
                break;
            case 2:
                if (this.validatorTab1.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: true,
                        tab3: false,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: value,
                        showNewShipper:false,
                        showNewConsignee:false

                    });
                }else{
                    this.validatorTab1.showMessages();
                    this.forceUpdate();
                }
                break;
            case 3:
                if (this.validatorTab1.allValid() && this.validatorTab2.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: true,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: value,
                    });
                }else{
                    this.validatorTab1.showMessages();
                    this.validatorTab2.showMessages();
                    this.forceUpdate();
                }
                break;
            case 4:
                if (this.validatorTab1.allValid() && this.validatorTab2.allValid() && this.validatorTab3.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: false,
                        tab4: true,
                        tab5: false,
                        // tab6: false,
                        count: value,
                    });
                }else{
                    this.validatorTab1.showMessages();
                    this.validatorTab2.showMessages();
                    this.validatorTab3.showMessages();
                    this.forceUpdate();
                }
                break;
            case 5:
                // if (this.validatorTab1.allValid() && this.validatorTab2.allValid() && this.validatorTab3.allValid()) {
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: true,
                    // tab6: false,
                    count: value
                })
                // }else{
                //     this.validatorTab1.showMessages();
                //     this.validatorTab2.showMessages();
                //     this.validatorTab3.showMessages();
                //     this.forceUpdate();
                // }
                break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: false,
            //         tab6: true,
            //         count: value
            //     })
            //     break;
            default:
        }
    };
    setBranchesSelectionFromList=(data) =>{
        if(data !== undefined){
            this.fetchShipperBranchList({id:data}).then(responce=>{
                if(responce && responce.result !== undefined){
                    this.setState({
                        shipperBranchDataList : responce && responce.result ? responce.result : []
                    })
                    let selectionOptionsListShipperBranch = []
                    for (let i = 0; i < responce.result.length; i++) {
                        let { _id, branchName } = responce.result[i]
                        let obj =
                            {
                                value: _id,
                                label: branchName,
                                branchDetail: responce.result[i],
                            };
                        selectionOptionsListShipperBranch.push(obj)
                    }

                    this.setState({
                        selectionOptionsShipperBranch: selectionOptionsListShipperBranch
                    });
                }
            })

        }

    }
    fetchShipperBranchList = async (shipperParameters) => {
        {
            return await RestClient({
                url: MANAGER_SHIPPER_BRANCH,
                method: "POST",
                data: shipperParameters
            });
        }
    };
    setConsigneeBranchesSelectionFromList=(data) =>{
        if(data !== undefined){
            this.fetchConsigneeBranchList({id:data}).then(responce=>{
                if(responce && responce.result !== undefined){
                    this.setState({
                        consigneeBranchDataList : responce && responce.result ? responce.result : []
                    })
                    let selectionOptionsListConsigneeBranch = []
                    for (let i = 0; i < responce.result.length; i++) {
                        let { _id, branchName } = responce.result[i]
                        let obj =
                            {
                                value: _id,
                                label: branchName,
                                branchDetail: responce.result[i],
                            };
                        selectionOptionsListConsigneeBranch.push(obj)
                    }

                    this.setState({
                        selectionOptionsConsigneeBranch: selectionOptionsListConsigneeBranch
                    });
                }
            })

        }

    }
    fetchConsigneeBranchList = async (shipperParameters) => {
        {
            return await RestClient({
                url: MANAGER_CONSIGNEE_BRANCH,
                method: "POST",
                data: shipperParameters
            });
        }
    };
    closeAllPop =() =>{
        this.setState({
            showIncoTermPop : false,
            showShipperBranchPop : false,
            showAccountTypePop : false,
            showShipperPop:false,
            showConsigneePop : false,
            showConsigneeBranchPop:false,
        })
    }
    render() {
        return (
            <>
                <Modal className="popup_activity_type" isOpen={this.state.showslabpopup} size="md" toggle={this.closeVolumentryPopup}>
                    <ModalHeader toggle={this.closeVolumentryPopup} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                    </ModalHeader>
                    <ModalBody>
                        <div className="">
                            <div className="pop-text">
                                <div className="dangerous_pop">
                                    <div className="pick_drop_details">
                                        <div className="d-flex flex-wrap align-items-stretch justify-content-between">
                                            <div className="width_pick_full pick_details">
                                                <h2> Weight Slab </h2>
                                                <hr/>
                                                <h4>
                                                    The volume weight should lie between {this.state.weighSlab2  || this.state.weightSlabRange} for quoted rates. Please re-check the volume weight or place the query again with the revised weight.
                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.shipperModel}
                    size="lg"
                    className="ships-model shipper_con_pop"
                    toggle={this.toggleLarge}>

                    <ModalHeader toggle={this.toggleLarge} className="justify-content-center p-0" >
                        {/*<div className="text-center textChange">*/}
                        {/*    <button type="button" className="btn gst-btn gst-top" onClick={() => this.Select(1)}*/}
                        {/*            className={this.state.tab1 ? "gst-btn gst-top " : "gst-btn"}>Shipper Details</button>*/}
                        {/*    <button type="button" className="btn gst-btn" onClick={() => this.Select(2)}*/}
                        {/*            className={this.state.tab2 ? "gst-btn gst-top" : "gst-btn"}>Consignee Details</button>*/}
                        {/*    <button type="button" className="btn gst-btn" onClick={() => this.Select(3)}*/}
                        {/*            className={this.state.tab3 ? "gst-btn gst-top" : "gst-btn"}>Query Details</button>*/}
                        {/*    <button type="button" className="btn gst-btn" onClick={() => this.Select(4)}*/}
                        {/*            className={this.state.tab4 ? "gst-btn gst-top" : "gst-btn"}>Packing/Commercial Details</button>*/}
                        {/*    <button type="button" className="btn gst-btn" onClick={() => this.Select(5)}*/}
                        {/*            className={this.state.tab5 ? "gst-btn gst-top " : "gst-btn"}>{this.state.queryFor ==="Air" ?*/}
                        {/*        "HAWB details"*/}
                        {/*        :<>{this.state.queryDataForcard.shipmentMode!=="LCL"?"HBL details":""}</>}</button>*/}
                        {/*</div>*/}
                        {/*house_scroll_check*/}
                    </ModalHeader>
                    <ModalBody className={this.state.checked && parseFloatValue(this.state.noOfHousesRequired) !== 0 ?"shipper_con_pops":"shipper_con_pop"}>
                        <div className=" container-fluid width_change">
                            <div className="banner_form_text">
                                <div className="mode_texts">
                                    <div className={this.state.tab1 ?"airModes active":"airModes"} onClick={() => this.Select(1)}>Shipper/Consignee Details</div>
                                    <div className={this.state.tab2 ?"airModes active":"airModes"} onClick={() => this.Select(2)}>Query Details</div>
                                    <div className={this.state.tab3 ?"airModes active":"airModes"} onClick={() => this.Select(3)}>Packing/Commercial Details</div>
                                    {this.props.zipaSuggestionBook ?
                                        this.state.getChargesObj && this.state.getChargesObj.queryFor ==="Air" ?
                                        <div className={this.state.tab4 ?"airModes active":"airModes"} onClick={() => this.Select(4)}>{"HAWB details"}</div>
                                            :
                                            <>
                                            {  this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"   ?
                                            <div className={this.state.tab4 ?"airModes active":"airModes"} onClick={() => this.Select(4)}>{"HBL details"}</div>:""}
                                       </>

                                        :
                                        <>
                                            {this.state.getChargesObj && this.state.getChargesObj.queryFor ==="Air" ?<div className={this.state.tab4 ?"airModes active":"airModes"} onClick={() => this.Select(4)}>HAWB details</div>:
                                                <>{this.state.getChargesObj && this.state.getChargesObj.shipmentMode!=="LCL"?<div className={this.state.tab4 ?"airModes active":"airModes"} onClick={() => this.Select(4)}>HBL details</div>:""}</>}

                                        </>
                                    }
                                </div>
                                <div className="banner_text_background">
                                    {/* {this.state.tab1?<div className="activity_types">
                                        <div className="form-check form-check-inline">
                                            <FormGroup>
                                                <CustomInput
                                                    type="checkbox"
                                                    checked={this.state.showNewShipper}
                                                    onClick={() => this.setState({showNewShipper:!this.state.showNewShipper,showNewConsignee:false,shipperName:""})}
                                                    label="Add New Shipper"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <FormGroup>
                                                <CustomInput
                                                    type="checkbox"
                                                    checked={this.state.showNewConsignee}
                                                    onClick={() => this.setState({showNewConsignee:!this.state.showNewConsignee,showNewShipper:false,consigneeName:""})}
                                                    label="Add New Consignee"
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>:""} */}
                                    {/*/////////////////////////////////Shipper and Consignee/////////////////////*/}
                                    {this.state.tab1?
                                        this.state.showNewShipper || this.state.showNewConsignee?
                                            this.state.showNewShipper?
                                                <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Shipper Name/Address 1</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperPop : !this.state.showEnterShipperPop,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showEnterShipperContact:false,
                                                                showConsigneePop : false,
                                                                showEnterShipperCountry : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperName?this.state.shipperName:"Shipper Name"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperPop !== true}>
                                                            <div className="popup_new origin_lables_door">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="shipperName">Shipper Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="shipperName"
                                                                               name="shipperName"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperName === undefined ? "" : this.state.shipperName}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="addressLine1">Address Line 1 <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="addressLine1"
                                                                               name="shipperAddressLine1"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperAddressLine1 === undefined ? "" : this.state.shipperAddressLine1}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="addressLine2">Address Line 2</Label>
                                                                        <Input type="text" id="shipperAddressLine2"
                                                                               name="shipperAddressLine2" onChange={this.handleChange}
                                                                               value={this.state.shipperAddressLine2 === undefined ? "" : this.state.shipperAddressLine2}
                                                                        />
                                                                    </FormGroup>
                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.shipperName == ""){
                                                                                toast.error("Please Fill Shipper Name", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.shipperAddressLine1 == ""){
                                                                                toast.error("Please Fill Shipper Address", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else {
                                                                                this.setState({
                                                                                    showEnterShipperCountry:true,
                                                                                    showEnterShipperPop:false
                                                                                })
                                                                            }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Shipper Address 2</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperCountry : !this.state.showEnterShipperCountry,
                                                                showEnterShipperPop : false,
                                                                showEnterShipperContact : false,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperCountryName?this.state.shipperCountryName:"Shipper Country"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperCountry !== true}>
                                                            <div className="popup_new origin_lables_door">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="countryId">Select Country <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <CountryDropDown
                                                                            isCountry={true}
                                                                            handler={this.onCountrySelectedForShipper}
                                                                            selectedCountry={
                                                                                this.state.shipperCountryId === undefined
                                                                                    ? ""
                                                                                    : this.state.shipperCountryId
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <StateDropdown
                                                                            handler={this.onShipperStateSelected}
                                                                            Lable={"Select State" }
                                                                            isState={true}
                                                                            selectedState={
                                                                                this.state.shipperStateId === undefined
                                                                                    ? ""
                                                                                    : this.state.shipperStateId
                                                                            }
                                                                            countryId={this.state.shipperCountryId}
                                                                            fromShipperConsignee={true}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="city">City Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="city"
                                                                               name="shipperCity"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperCity === undefined ? "" : this.state.shipperCity}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="pincode">Pincode <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="pincode"
                                                                               name="shipperPincode" onChange={this.handleChange}
                                                                               value={this.state.shipperPincode === undefined ? "" : this.state.shipperPincode}
                                                                        />
                                                                    </FormGroup>
                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.shipperCountryId == ""){
                                                                                toast.error("Please Fill Shipper Country", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.shipperStateId == ""){
                                                                                toast.error("Please Fill Shipper State", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.shipperCity == ""){
                                                                                toast.error("Please Fill Shipper City", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }
                                                                            else if(this.state.shipperPincode == ""){
                                                                                toast.error("Please Fill Shipper Pincode", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }
                                                                            else {
                                                                                this.setState({
                                                                                    showEnterShipperCountry:false,
                                                                                    showEnterShipperContact:true
                                                                                })
                                                                            }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Shipper Contact Details</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperContact : !this.state.showEnterShipperContact,
                                                                showEnterShipperPop : false,
                                                                showEnterShipperCountry : false,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperContact?this.state.shipperContact:"Shipper Contact"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperContact !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="others">Contact Number  <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="number" id="others"
                                                                               name="shipperContact"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperContact === undefined ? "" : this.state.shipperContact}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="consigneeContactName">Contact Name</Label>
                                                                        <Input type="text" id="shipperContactName"
                                                                               name="shipperContactName"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperContactName === undefined ? "" : this.state.shipperContactName}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="consigneeContactName">Contact Email</Label>
                                                                        <Input type="text" id="shipperContactEmail"
                                                                               name="shipperContactEmail"
                                                                               onKeyUp={(e) => {
                                                                                   this.handleEmail(e);
                                                                               }}
                                                                               onChange={this.handleChange}
                                                                               value={this.state.shipperContactEmail === undefined ? "" : this.state.shipperContactEmail}
                                                                        />
                                                                        <p style={{color:'red'}}>{this.state.emailValidation}</p>
                                                                        <div className="display-block">
                                                                            {this.state.emailsSelect &&
                                                                            this.state.emailsSelect.map(
                                                                                (itm, indx) => {
                                                                                    return (
                                                                                        <div className="mail-group">
                                                                                            <div className="kyc_email">
                                                                                                {itm}{" "}
                                                                                                <span
                                                                                                    className="cross_email_kyc"
                                                                                                    onClick={(e) =>
                                                                                                        this.deleteEmails(indx, e)
                                                                                                    }
                                                                                                >
                                                                                X</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                            <span className="text-danger">
                                                              {this.validatorTab1.message(
                                                                  "emailsSelect",
                                                                  this.state.emailsSelect,
                                                                  "required"
                                                              )}
                                                            </span>
                                                                        </div>

                                                                    </FormGroup>

                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.shipperContact == ""){
                                                                                toast.error("Please Fill Shipper Contact", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            } else {
                                                                            this.setState({
                                                                                showEnterShipperContact:false
                                                                            })
                                                                        }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Consignee Name/Address 1</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperPop : !this.state.showEnterShipperPop,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showEnterShipperContact:false,
                                                                showConsigneePop : false,
                                                                showEnterShipperCountry : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeName?this.state.consigneeName:"Consigneee Name"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperPop !== true}>
                                                            <div className="popup_new origin_lables_door">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="shipperName">Consignee Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="consigneeName"
                                                                               placeholder="Consignee Name"
                                                                               name="consigneeName"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeName === undefined ? "" : this.state.consigneeName}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="addressLine1">Address Line 1 <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="addressLine1"
                                                                               placeholder="Address Line 1"
                                                                               name="consigneeAddressLine1"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeAddressLine1 === undefined ? "" : this.state.consigneeAddressLine1}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="addressLine2">Address Line 2</Label>
                                                                        <Input type="text" id="addressLine2"
                                                                               placeholder="Address Line 2"
                                                                               name="consigneeAddressLine2" onChange={this.handleChange}
                                                                               value={this.state.consigneeAddressLine2 === undefined ? "" : this.state.consigneeAddressLine2}
                                                                        />
                                                                    </FormGroup>
                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.consigneeName == ""){
                                                                                toast.error("Please Fill Consignee Name", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.consigneeAddressLine1 == ""){
                                                                                toast.error("Please Fill Consignee Address", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else {
                                                                                this.setState({
                                                                                    showEnterShipperPop:false,
                                                                                    showEnterShipperCountry:true
                                                                                })
                                                                            }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Consignee Address 2</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperCountry : !this.state.showEnterShipperCountry,
                                                                showEnterShipperPop : false,
                                                                showEnterShipperContact : false,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeCountryName?this.state.consigneeCountryName:"Country Name"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperCountry !== true}>
                                                            <div className="popup_new origin_lables_door">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="countryId">Select Country <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <CountryDropDown
                                                                            isCountry={true}
                                                                            handler={this.onConsigneeCountrySelected}
                                                                            selectedCountry={
                                                                                this.state.consigneeCountryId === undefined
                                                                                    ? ""
                                                                                    : this.state.consigneeCountryId
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <StateDropdown
                                                                            handler={this.onConsigneeStateSelected}
                                                                            isState={true}
                                                                            Lable={"Select State"}
                                                                            selectedState={
                                                                                this.state.consigneeStateId === undefined
                                                                                    ? ""
                                                                                    : this.state.consigneeStateId
                                                                            }
                                                                            countryId={this.state.consigneeCountryId}
                                                                            fromShipperConsignee={true}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="city">City Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="city"
                                                                               placeholder="City"
                                                                               name="consigneeCity"
                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeCity === undefined ? "" : this.state.consigneeCity}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="pincode">Pincode <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="text" id="pincode"
                                                                               placeholder="Pincode"
                                                                               name="consigneePincode" onChange={this.handleChange}
                                                                               value={this.state.consigneePincode === undefined ? "" : this.state.consigneePincode}
                                                                        />
                                                                    </FormGroup>
                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.consigneeCountryId == ""){
                                                                                toast.error("Please Fill Consignee Country", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.consigneeStateId == ""){
                                                                                toast.error("Please Fill Consignee State", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.consigneeCity == ""){
                                                                                toast.error("Please Fill Consignee City", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else if(this.state.consigneePincode == ""){
                                                                                toast.error("Please Fill Consignee Pincode", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else {
                                                                                
                                                                                this.setState({
                                                                                    showEnterShipperCountry:false,
                                                                                    showEnterShipperContact:true
                                                                                })
                                                                            }
                                                                            // this.packageDetailsValidation()

                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input enter_shipper_inputs">
                                                        <label>Consignee Contact Details</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showEnterShipperContact : !this.state.showEnterShipperContact,
                                                                showEnterShipperPop : false,
                                                                showEnterShipperCountry : false,
                                                                showShipperPop : false,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeContact?this.state.consigneeContact:"Consignee Contact"}</p>
                                                        </div>
                                                        <div className="popup_query_from" hidden={this.state.showEnterShipperContact !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lables mb-4">
                                                                    <FormGroup>
                                                                        <Label for="others">Contact Number <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                        <Input type="number" id="others"
                                                                               name="consigneeContact"

                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeContact === undefined ? "" : this.state.consigneeContact}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="consigneeContactName">Contact Name</Label>
                                                                        <Input type="text" id="consigneeContactName"
                                                                               name="consigneeContactName"

                                                                               onChange={this.handleChange}
                                                                               value={this.state.consigneeContactName === undefined ? "" : this.state.consigneeContactName}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="consigneeContactEmail">Contact Email</Label>
                                                                        <Input type="text" id="consigneeContactEmail"
                                                                               name="consigneeContactEmail"

                                                                               onChange={this.handleChange}
                                                                               onKeyUp={(e) => {
                                                                                   this.handleEmailConsignee(e);
                                                                               }}
                                                                               value={this.state.consigneeContactEmail === undefined ? "" : this.state.consigneeContactEmail}
                                                                        />
                                                                        <p style={{color:'red'}}>{this.state.emailValidationConsignee}</p>
                                                                        <div className="display-block">
                                                                            {this.state.emailsSelectConsignee &&
                                                                            this.state.emailsSelectConsignee.map(
                                                                                (itm, indx) => {
                                                                                    return (
                                                                                        <div className="mail-group">
                                                                                            <div className="kyc_email">
                                                                                                {itm}{" "}
                                                                                                <span
                                                                                                    className="cross_email_kyc"
                                                                                                    onClick={(e) =>
                                                                                                        this.deleteEmailsConsignee(indx, e)
                                                                                                    }
                                                                                                >
                                                                                X</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                            <span className="text-danger">
                                                              {this.validatorTab1.message(
                                                                  "emailsSelectConsignee",
                                                                  this.state.emailsSelectConsignee,
                                                                  "required"
                                                              )}
                                                            </span>
                                                                        </div>

                                                                    </FormGroup>

                                                                    <div className="next">
                                                                        <div className="btn edit1" onClick={e => {
                                                                            e.preventDefault()
                                                                            if(this.state.consigneeContact == ""){
                                                                                toast.error("Please Fill Consignee Number", {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }else {
                                                                            this.setState({
                                                                                showEnterShipperContact:false,
                                                                            })
                                                                        }


                                                                        }}>
                                                                            Next

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                            <div className="d-flex flex-wrap justify-content-between houseShipper mb-3 shipperConsigneeDatails">
                                                <div className="two_part_shipCong">
                                                    <div className="new_banner_input new_shipper_inputs">
                                                        <label>Shipper Name</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showShipperPop : !this.state.showShipperPop,
                                                                showShipperBranchPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperName?this.state.shipperName:"Shipper Name"}</p>
                                                        </div>
                                                        <span className="text-danger">{this.validatorTab1.message(
                                                            "shipper Name",
                                                            this.state.shipperId,
                                                            "required"
                                                        )}</span>
                                                        <div className="button_new_add" onClick={() => this.setState({showNewShipper:!this.state.showNewShipper,showNewConsignee:false,shipperName:"",
                                                            shipperId:"",shipperAddressLine1 :"",shipperBranchName:"",shipperBranchId:"",shipperDetails:"",
                                                    shipperAddressLine2:"",
                                                    shipperCountryId:"",
                                                    shipperCity:"",
                                                    shipperPincode:"",
                                                    shipperContact:"",
                                                    shipperContactName:"",
                                                            shipperContactEmail:[],
                                                            emailsSelect:[],
                                                            shipperCountryName:""

                                                    })}><FontAwesomeIcon icon={faPlus}/> New Shipper</div>
                                                        <div className="popup_query_from" hidden={this.state.showShipperPop !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lable">
                                                                    <ShipperWithBranchDropdownForShipperModal
                                                                        onlyShipper={this.state.showShipperPop}
                                                                        reloadShipper={this.state.reloadShipper}
                                                                        isSelectionThroughName={false}
                                                                        selectedOptionCompany={this.state.selectedOptionCompany}
                                                                        selectionOptionsShipperBranch={this.state.selectionOptionsShipperBranch}
                                                                        branchData = {(values)=>{
                                                                            this.setState({
                                                                                selectionOptionsShipperBranch : values
                                                                            })
                                                                        }}
                                                                        selectedShipperId={this.state.shipperId}
                                                                        selectedShipperName={this.state.shipperName}
                                                                        selectedShipperBranchName={this.state.shipperBranchName}
                                                                        selectedShipperBranchId={this.state.shipperBranchId}
                                                                        shipperDetails={this.state.shipperDetails}
                                                                        onShipperConsigneeSelected={this.handleShipperSelected}
                                                                        shipperValid = {this.validatorTab1.message(
                                                                            "shipper Name",
                                                                            this.state.shipperId,
                                                                            "required"
                                                                        )}
                                                                        shipperBranchId= {this.validatorTab1.message(
                                                                            "shipper Branch Name",
                                                                            this.state.shipperBranchId,
                                                                            "required"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="popular_listing">
                                                                    <h4>Recently Use</h4>
                                                                    <div className="listing_items">
                                                                        {this.state.ShipperNameData !== undefined && this.state.ShipperNameData ?

                                                                            this.state.ShipperNameData.map((item, i) => {
                                                                                return (
                                                                                    <div className="items_list" onClick={() => {
                                                                                        let resultValue = {
                                                                                            value: item._id,
                                                                                            label: item.shipperName,
                                                                                        }
                                                                                        this.setState({
                                                                                            selectedOptionCompany: resultValue,
                                                                                            shipperId:resultValue.value,
                                                                                            shipperName:resultValue.label,
                                                                                        },()=>{
                                                                                            this.setBranchesSelectionFromList(this.state.shipperId)
                                                                                            this.setState({
                                                                                                showShipperPop:false,
                                                                                                showShipperBranchPop:true
                                                                                            })
                                                                                        })

                                                                                    }}>
                                                                                        <div className="airortName_listing">
                                                                                            <h5>{item && item.shipperName}</h5>
                                                                                            <h6>{item && item.name}</h6>
                                                                                        </div>
                                                                                        <div className="code_listing">{item && item.code}</div>
                                                                                    </div>
                                                                                )
                                                                            }) : ""}

                                                                    </div> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input new_shipper_inputs">
                                                        <label>Shipper Branch</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showShipperBranchPop : !this.state.showShipperBranchPop,
                                                                showShipperPop:false,
                                                                showConsigneePop : false,
                                                                showConsigneeBranchPop:false,
                                                            })
                                                        }}>
                                                            <p>{this.state.shipperBranchName?this.state.shipperBranchName:"Shipper Branch"}</p>
                                                        </div>
                                                        <span className="text-danger">{this.validatorTab1.message(
                                                            "shipper Branch Name",
                                                            this.state.shipperBranchId,
                                                            "required")}</span>
                                                        <div className="popup_query_from" hidden={this.state.showShipperBranchPop !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lable">
                                                                    <ShipperWithBranchDropdownForShipperModal
                                                                        onlyShipperBranch={this.state.showShipperBranchPop}
                                                                        onlyShipper={this.state.onlyShipper}
                                                                        selectedOptionCompany={this.state.selectedOptionCompany}
                                                                        selectionOptionsShipperBranch={this.state.selectionOptionsShipperBranch}
                                                                        reloadShipper={this.state.reloadShipper}
                                                                        branchData = {(values)=>{
                                                                            this.setState({
                                                                                selectionOptionsShipperBranch : values
                                                                            })
                                                                        }}
                                                                        isSelectionThroughName={false}
                                                                        selectedShipperId={this.state.shipperId}
                                                                        selectedShipperName={this.state.shipperName}
                                                                        selectedShipperBranchName={this.state.shipperBranchName}
                                                                        selectedShipperBranchId={this.state.shipperBranchId}
                                                                        shipperDetails={this.state.shipperDetails}
                                                                        onShipperConsigneeSelected={this.handleShipperSelected}
                                                                        shipperValid = {this.validatorTab1.message(
                                                                            "shipper Name",
                                                                            this.state.shipperId,
                                                                            "required"
                                                                        )}
                                                                        shipperBranchId= {this.validatorTab1.message(
                                                                            "shipper Branch Name",
                                                                            this.state.shipperBranchId,
                                                                            "required"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="popular_listing">
                                                                    <h4>Shipper Branch</h4>
                                                                    <div className="listing_items">
                                                                        {this.state.shipperBranchDataList !== undefined && this.state.shipperBranchDataList ?

                                                                            this.state.shipperBranchDataList.map((item, i) => {
                                                                                return (
                                                                                    <div className="items_list" onClick={() => {
                                                                                        let resultValue = {
                                                                                            value: item._id,
                                                                                            label: item.branchName,
                                                                                            branchDetail: item,
                                                                                        }
                                                                                        this.setState({
                                                                                            branchDetail: resultValue.branchDetail,
                                                                                            shipperBranchId:resultValue.value,
                                                                                            shipperBranchName:resultValue.label,
                                                                                        },()=>{
                                                                                            this.allShipperDeatils()
                                                                                            this.setState({
                                                                                                showShipperBranchPop:false,
                                                                                                showConsigneePop:true
                                                                                            })
                                                                                        })

                                                                                    }}>
                                                                                        <div className="airortName_listing">
                                                                                            <h5>{item && item.branchName}</h5>
                                                                                            <h6>{item && item.stateId && item.stateId.name}</h6>
                                                                                        </div>
                                                                                        {/*<div className="code_listing">{item && item.code}</div>*/}
                                                                                    </div>
                                                                                )
                                                                            }) : ""}

                                                                    </div> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input shipperConsignee_input">
                                                        <label>Shipper Address</label>
                                                        <div className="box_new_query">
                                                            <p>{this.state.shipperDetails ? this.state.shipperDetails : "Shipper Address"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="separate_shipper"></div>
                                                <div className="two_part_shipCong">
                                                    <div className="new_banner_input new_shipper_inputs">
                                                        <label>Consignee Name</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showConsigneePop : !this.state.showConsigneePop,
                                                                showConsigneeBranchPop:false,
                                                                showShipperPop:false,
                                                                showShipperBranchPop:false
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeName?this.state.consigneeName:"Consignee Name"}</p>
                                                        </div>
                                                        <span className="text-danger">{this.validatorTab1.message(
                                                            "consignee Name",
                                                            this.state.consigneeId,
                                                            "required"
                                                        )}</span>
                                                        <div className="button_new_add" onClick={() => this.setState({showNewConsignee:!this.state.showNewConsignee,showNewShipper:false,consigneeName:"",
                                                        
consigneeAddressLine1:"",consigneeId:"",consigneeBranchName:"",consigneeBranchId:"",consigneeDetails:"",
consigneeAddressLine2:"",
consigneeCountryId:"",
consigneeCity:"",
consigneePincode:"",
consigneeContact:"",
consigneeContactName:"",
                                                            consigneeCountryName:"",
                                                            consigneeContactEmail:[],
                                                            emailsSelectConsignee:[]


                                                        })}><FontAwesomeIcon icon={faPlus}/> New Consignee</div>
                                                        <div className="popup_query_from" hidden={this.state.showConsigneePop !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lable">
                                                                    <ConsigneeWithBranchDropdownForShipperModal
                                                                        readOnly={this.state.selectNewConsignee !== false}
                                                                        reloadConsignee={this.state.reloadConsignee}
                                                                        onlyConsignee={this.state.showConsigneePop}
                                                                        selectedOptionConsignee={this.state.selectedOptionConsignee}
                                                                        selectionOptionsConsigneeBranch={this.state.selectionOptionsConsigneeBranch}
                                                                        branchData = {(values)=>{
                                                                            this.setState({
                                                                                selectionOptionsConsigneeBranch : values
                                                                            })
                                                                        }}
                                                                        isSelectionThroughName={false}
                                                                        selectedConsigneeId={this.state.consigneeId}
                                                                        selectedConsigneeName={this.state.consigneeName}
                                                                        selectedConsigneeBranchName={this.state.consigneeBranchName}
                                                                        consigneeDetails={this.state.consigneeDetails}
                                                                        onShipperConsigneeSelected={this.handleConsigneeSelected}
                                                                        consigneeValid = {this.validatorTab2.message(
                                                                            "consignee Name",
                                                                            this.state.consigneeId,
                                                                            "required"
                                                                        )}
                                                                        consigneeBranchId= {this.validatorTab2.message(
                                                                            "consignee Branch Name",
                                                                            this.state.consigneeBranchId,
                                                                            "required"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="popular_listing">
                                                                    <h4>Recently Use</h4>
                                                                    <div className="listing_items">
                                                                        {this.state.ConsigneeNameData !== undefined && this.state.ConsigneeNameData ?

                                                                            this.state.ConsigneeNameData.map((item, i) => {
                                                                                return (
                                                                                    <div className="items_list" onClick={() => {
                                                                                        let resultValue = {
                                                                                            value: item._id,
                                                                                            label: item.consigneeName,
                                                                                        }
                                                                                        this.setState({
                                                                                            selectedOptionConsignee: resultValue,
                                                                                            consigneeId:resultValue.value,
                                                                                            consigneeName:resultValue.label,
                                                                                        },()=>{
                                                                                            this.setConsigneeBranchesSelectionFromList(this.state.consigneeId)
                                                                                            this.setState({
                                                                                                showConsigneePop:false,
                                                                                                showConsigneeBranchPop:true
                                                                                            })
                                                                                        })

                                                                                    }}>
                                                                                        <div className="airortName_listing">
                                                                                            <h5>{item && item.consigneeName}</h5>
                                                                                            <h6>{item && item.name}</h6>
                                                                                        </div>
                                                                                        <div className="code_listing">{item && item.code}</div>
                                                                                    </div>
                                                                                )
                                                                            }) : ""}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input new_shipper_inputs">
                                                        <label>Consignee Branch</label>
                                                        <div className="box_new_query" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showConsigneeBranchPop : !this.state.showConsigneeBranchPop,
                                                                showConsigneePop:false,
                                                                showShipperPop:false,
                                                                showShipperBranchPop:false
                                                            })
                                                        }}>
                                                            <p>{this.state.consigneeBranchName?this.state.consigneeBranchName:"Consignee Branch"}</p>
                                                        </div>
                                                        <span className="text-danger">{this.validatorTab1.message(
                                                            " Branch Name",
                                                            this.state.consigneeBranchId,
                                                            "required"
                                                        )}</span>
                                                        <div className="popup_query_from" hidden={this.state.showConsigneeBranchPop !== true}>
                                                            <div className="popup_new">
                                                                <div className="origin_lable">
                                                                    <ConsigneeWithBranchDropdownForShipperModal
                                                                        readOnly={this.state.selectNewConsignee !== false}
                                                                        reloadConsignee={this.state.reloadConsignee}
                                                                        onlyConsignee={this.state.showConsigneePop}
                                                                        onlyConsigneeBranch={this.state.showConsigneeBranchPop}
                                                                        selectedOptionConsignee={this.state.selectedOptionConsignee}
                                                                        selectionOptionsConsigneeBranch={this.state.selectionOptionsConsigneeBranch}
                                                                        branchData = {(values)=>{
                                                                            this.setState({
                                                                                selectionOptionsConsigneeBranch : values
                                                                            })
                                                                        }}
                                                                        isSelectionThroughName={false}
                                                                        selectedConsigneeId={this.state.consigneeId}
                                                                        selectedConsigneeName={this.state.consigneeName}
                                                                        selectedConsigneeBranchName={this.state.consigneeBranchName}
                                                                        consigneeDetails={this.state.consigneeDetails}
                                                                        onShipperConsigneeSelected={this.handleConsigneeSelected}
                                                                        consigneeValid = {this.validatorTab2.message(
                                                                            "consignee Name",
                                                                            this.state.consigneeId,
                                                                            "required"
                                                                        )}
                                                                        consigneeBranchId= {this.validatorTab2.message(
                                                                            "consignee Branch Name",
                                                                            this.state.consigneeBranchId,
                                                                            "required"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="popular_listing">
                                                                    <h4>Consignee Branch</h4>
                                                                    <div className="listing_items">
                                                                        {this.state.consigneeBranchDataList !== undefined && this.state.consigneeBranchDataList ?

                                                                            this.state.consigneeBranchDataList.map((item, i) => {
                                                                                return (
                                                                                    <div className="items_list" onClick={() => {
                                                                                        let resultValue = {
                                                                                            value: item._id,
                                                                                            label: item.branchName,
                                                                                            branchDetail: item,
                                                                                        }
                                                                                        this.setState({
                                                                                            branchDetailConsignee: resultValue.branchDetail,
                                                                                            consigneeBranchId:resultValue.value,
                                                                                            consigneeBranchName:resultValue.label,
                                                                                        },()=>{
                                                                                            this.allConsigneeDeatils()
                                                                                            this.setState({
                                                                                                showConsigneeBranchPop:false
                                                                                            })
                                                                                        })

                                                                                    }}>
                                                                                        <div className="airortName_listing">
                                                                                            <h5>{item && item.branchName}</h5>
                                                                                            <h6>{item && item.stateId && item.stateId.name}</h6>
                                                                                        </div>
                                                                                        {/*<div className="code_listing">{item && item.code}</div>*/}
                                                                                    </div>
                                                                                )
                                                                            }) : ""}

                                                                    </div> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_banner_input shipperConsignee_input">
                                                        <label>Consignee Address</label>
                                                        <div className="box_new_query">
                                                            <p>{this.state.consigneeDetails ? this.state.consigneeDetails : "Consignee Address"}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*<div className="new_banner_input width-100 showForMob">*/}
                                                {/*    <label>Shipper Address</label>*/}
                                                {/*    <div className="box_new_query">*/}
                                                {/*        <p>{this.state.shipperDetails ? this.state.shipperDetails : "Shipper Address"}</p>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}


                                                {/*<div className="new_banner_input width-100 showForMob">*/}
                                                {/*    <label>Consignee Address</label>*/}
                                                {/*    <div className="box_new_query">*/}
                                                {/*        <p>{this.state.consigneeDetails ? this.state.consigneeDetails : "Consignee Address"}</p>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}


                                            </div>
                                        :""}
                                    {this.state.tab2?
                                        <>
                                            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                                                <div className="new_banner_input new_Package_Details">
                                                    <label>Account Type</label>
                                                    <div className="box_new_query" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            showIncoTermPop : false,
                                                            showShipperBranchPop : false,
                                                            showAccountTypePop : !this.state.showAccountTypePop,
                                                            showShipperPop:false,
                                                            showConsigneePop : false,
                                                            showConsigneeBranchPop:false,
                                                            showPackagePop:false
                                                        })
                                                    }}>
                                                        <p>{this.state.accountType?this.state.accountType:"Account Type"}</p>
                                                    </div>
                                                    <span className="text-danger">{this.validatorTab2.message(
                                                        "Account Type",
                                                        this.state.accountType,
                                                        "required"
                                                    )}</span>
                                                    <div className="popup_query_from" hidden={this.state.showAccountTypePop !== true}>
                                                        <div className="popup_new">
                                                            <div className="origin_lables">
                                                                {/*<label>Select Courier Mode</label>*/}
                                                                <div className="selection_list">
                                                                    <div className="selection_doc" onClick={()=>{
                                                                        this.setState({
                                                                            accountType: "Prepaid",
                                                                            showIncoTermPop:this.state.isInsuranceTrue ?false : true,showAccountTypePop:false
                                                                        })
                                                                    }}>Prepaid</div>
                                                                    <div className="selection_doc" onClick={()=>{
                                                                        this.setState({
                                                                            accountType: "Collect",
                                                                            showIncoTermPop:this.state.isInsuranceTrue ?false : true,showAccountTypePop:false
                                                                        })
                                                                    }}>Collect</div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="origin_lables">
                                                            <div className="form-group">
                                                                <Label for="accountType">Account Type <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                <select
                                                                    name="accountType"
                                                                    className="mycustom1 custom-select"
                                                                    id="accountType"
                                                                    value={this.state.accountType === undefined ? "" : this.state.accountType}
                                                                    onChange={e => {
                                                                        this.setState({ accountType: e.target.value ,showIncoTermPop:true,showAccountTypePop:false}, () => {
                    
                                                                        })
                                                                    }}
                                                                >
                                                                    <option>Select Account Type</option>
                                                                    <option value="Prepaid">Prepaid</option>
                                                                    <option value="Collect">Collect</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="new_banner_input new_Package_Details">
                                                    <label>Select Incoterms </label>
                                                    <div className={this.state.isInsuranceTrue ? "box_new_query pointer_disabled" : "box_new_query"} onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            showIncoTermPop : !this.state.showIncoTermPop,
                                                            showShipperBranchPop : false,
                                                            showShipperPop:false,
                                                            showConsigneePop : false,
                                                            showConsigneeBranchPop:false,
                                                            showPackagePop:false,
                                                            showAccountTypePop:false
                                                        })
                                                    }}>
                                                        <p>{this.state.incoTerms?this.state.incoTerms:"Incoterms"}</p>
                                                    </div>
                                                    <span className="text-danger">{this.validatorTab2.message(
                                                        "incoterms",
                                                        this.state.incoTerms,
                                                        "required"
                                                    )}</span>
                                                    <div className="popup_query_from" hidden={this.state.showIncoTermPop !== true}>
                                                        <div className="popup_new">
                                                            <div className="origin_lable">
                                                                <FormGroup className="incoInput">
                                                                    <Label for="accountType">Select Incoterms <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                                                    <IncoTermsDropDown
                                                                        activityType={this.state.activityType}
                                                                        APIRout={MANAGER_INCOTERMS_API}
                                                                        PlaceHolder={"Select Inco Terms"}
                                                                        handler={values => {
                                                                            this.onIncoTermsSelected(values)
                                                                            this.setState({
                                                                                showIncoTermPop:false

                                                                            })
                                                                        }}
                                                                        selectedObj={{value: this.state.incoTermsId, label: this.state.incoTerms}}
                                                                    />

                                                                </FormGroup>
                                                            </div>
                                                            <div className="popular_listing">
                                                                <h4>Recently Use</h4>
                                                                <div className="listing_items">
                                                                    {this.state.incotermsData !== undefined && this.state.incotermsData ?
                                                                        this.state.incotermsData.map((item, i) => {
                                                                            return (
                                                                                <div className="items_list" onClick={() => {
                                                                                    let resultValue = {
                                                                                        value: item._id,
                                                                                        label: item.name,
                                                                                    }
                                                                                    this.setState({
                                                                                        incoTermsId:resultValue.value,
                                                                                        incoTerms:resultValue.label,
                                                                                    },()=>{
                                                                                        this.closeAllPop()
                                                                                    })
                                                                                }}>
                                                                                    <div className="airortName_listing">
                                                                                        {/*<h5>{item && item.consigneeName}</h5>*/}
                                                                                        <h5>{item && item.name}</h5>
                                                                                    </div>
                                                                                    {/*<div className="code_listing">{item && item.code}</div>*/}
                                                                                </div>
                                                                            )
                                                                        }) : ""}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="new_banner_input new_Package_Details">
                                                    <label>Package Details</label>
                                                    <div className="box_new_query" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            showPackagePop : !this.state.showPackagePop,
                                                            showConsigneePop : false,
                                                            showConsigneeBranchPop:false,
                                                            showShipperPop:false,
                                                            showShipperBranchPop:false,
                                                            showIncoTermPop:false,
                                                            showAccountTypePop:false
                                                        })
                                                    }}>
                                                        <p>Pcs. : {this.state.totalPiecesFromQuery} Pcs | {this.state.totalGross} {this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL" ? "(Tons)":"(Kgs)"} | {this.state.uploadedDimensions.length >0 ? this.state.volumeWeight : this.state.totalVolume} {this.state.getChargesObj && this.state.getChargesObj.queryFor ==="Ocean" ? "CBM" : "KGS"}</p>
                                                    </div>
                                                    {/* <div className="popup_query_from" hidden={this.state.showPackagePop !== true}>
                                                    <div className="popup_new origin_lables_door">
                                                        <div className="origin_lables">
                                                            {this.state.shipmentMode === "LCL" || this.state.shipmentMode === "Surface"?
                                                                    <FormGroup>
                                                                        <Label for="shipperName">No. Of Pieces</Label>
                                                                        <Input type="number" id="shipperName"
                                                                               name="NoOfPiecesForMBL"
                                                                               readOnly
                                                                               onChange={this.handleChange}
                                                                               onBlur={e => {
                                                                                   e.preventDefault()
                                                                                   if (this.state.checkAnotherValuation === true) {
                                                                                       this.checkRemainingContainer()
                                                                                   } else {
                                                                                       this.checkContainer()
                                                                                   }

                                                                               }}
                                                                               value={this.state.totalPiecesFromQuery === undefined ? "" : this.state.totalPiecesFromQuery}
                                                                        />
                                                                    </FormGroup>
                                                                :
                                                                    <FormGroup>
                                                                        <Label for="shipperName">No. Of Container</Label>
                                                                        <Input type="number" id="shipperName"
                                                                               name="NoOfContainer"
                                                                               readOnly
                                                                               onChange={this.handleChange}
                                                                               onBlur={e => {
                                                                                   e.preventDefault()
                                                                                   if (this.state.checkAnotherValuation === true) {
                                                                                       this.checkRemainingContainer()
                                                                                   } else {
                                                                                       this.checkContainer()
                                                                                   }

                                                                               }}
                                                                               value={this.state.NoOfContainer === undefined ? "" : this.state.NoOfContainer}
                                                                        />
                                                                    </FormGroup>
                                                            }
                                                                <FormGroup>
                                                                    <Label for="shipperName">G. Wt. {this.state.shipmentMode === "FCL" ? "(Tons)":"(Kgs)"}</Label>
                                                                    <Input type="number" id="totalGross"
                                                                           readonly=""
                                                                           name="totalGross"
                                                                           disabled={this.state.checked === true}
                                                                           onChange={this.handleChange}
                                                                           value={this.state.totalGross === undefined ? "" : this.state.totalGross}
                                                                        //    value={this.state.grossWeight === undefined ? this.state.totalGross : this.state.grossWeight}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label for="shipperName">{this.props.zipaSuggestionBook ? this.state.queryFor ==="Ocean" ? "Vol. Wt. (KGS)" : "Vol. Wt. (CBM)" : this.state.queryFor ==="Ocean" ? "Vol. Wt. (CBM)" : "Vol. Wt. (KGS)"}</Label>
                                                                    <Input type="number" id="totalVolume"
                                                                           name="totalVolume"
                                                                           readonly=""
                                                                           disabled={this.state.checked === true}
                                                                           onChange={this.handleChange}
                                                                        // value={this.state.totalVolume === undefined ? "" : this.state.totalVolume}
                                                                           value={this.state.uploadedDimensions.length >0 ? this.state.volumeWeight : this.state.totalVolume}
                                                                        // volumeWeight
                                                                    />
                                                                </FormGroup>
                                                                {this.props.zipaSuggestionBook ? "" :                                                                <>
                                                            {this.state.queryFor ==="Air" ?
                                                                    <FormGroup>
                                                                        <Label for="shipperName">Ch. Wt.(KGS)</Label>
                                                                        <Input type="number" id="chargeableWeight"
                                                                               name="chargeableWeight"
                                                                               disabled={this.state.checked === true}
                                                                               onChange={this.handleChange}
                                                                               readonly=""
                                                                               value={this.state.chargeableWeight === undefined ? "" : this.state.chargeableWeight}
                                                                        />
                                                                    </FormGroup>
                                                                    
                                                                :undefined}</>}
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </div>

                                            </div>
                                            <div className="d-flex flex-wrap justify-content-end">
                                                <button className="btn-add-dimen" onClick={this.toggleDimension}><FontAwesomeIcon icon={this.state.dimensionToggle === true ? faMinus :faPlus}/> Add Dimension</button>
                                            </div>
                                            {this.state.dimensionToggle ?
                                                <>
                                                    <div className="activity_types">
                                                        <div className="form-check form-check-inline">
                                                            <CustomInput
                                                                type="radio"
                                                                id="CM"
                                                                label="CM"
                                                                name="selectedDimensionType"
                                                                value={"CM"}
                                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                checked={this.state.selectedDimensionType === "CM"}
                                                            />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <CustomInput
                                                                type="radio"
                                                                id="MM"
                                                                label="MM"
                                                                name="selectedDimensionType"
                                                                value={"MM"}
                                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                checked={this.state.selectedDimensionType === "MM"}
                                                            />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <CustomInput
                                                                type="radio"
                                                                id="INCHES"
                                                                label="INCHES"
                                                                name="selectedDimensionType"
                                                                value={"INCHES"}
                                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                checked={this.state.selectedDimensionType === "INCHES"}
                                                            />
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <CustomInput
                                                                type="radio"
                                                                id="METERS"
                                                                label="METERS"
                                                                name="selectedDimensionType"
                                                                value={"METERS"}
                                                                onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                checked={this.state.selectedDimensionType === "METERS"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="DimensionView calculate-inputs">
                                                        <label className="text-white" hidden={!this.state.allowedEdit}>Dimensions Info.</label>
                                                        <Formik hidden={!this.state.allowedEdit} validationSchema={formSchema}
                                                                onSubmit={this.addNewDimension} initialValues={{}}>
                                                            {({errors, touched}) => (
                                                                <Form hidden={!this.state.allowedEdit}>
                                                                    {/*<Label>{this.state.dimensionOverload}</Label>*/}
                                                                    <div className="d-flex flex-wrap align-items-center dimension_shipper calculate-inputs ">
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Label for="length">Length</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="length"
                                                                                    id="length"
                                                                                    placeholder="Length"
                                                                                    value={this.state.length}
                                                                                />
                                                                                {/*{errors.length && touched.length && (*/}
                                                                                {/*    <div className="invalid-feedback d-block">*/}
                                                                                {/*        {errors.length}*/}
                                                                                {/*    </div>*/}
                                                                                {/*)}*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Label for="width">Width</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="width"
                                                                                    placeholder="Width"
                                                                                    id="width"
                                                                                    value={this.state.width}
                                                                                />
                                                                                {/*{errors.width && touched.width && (*/}
                                                                                {/*    <div className="invalid-feedback d-block">*/}
                                                                                {/*        {errors.width}*/}
                                                                                {/*    </div>*/}
                                                                                {/*)}*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Label for="height">Height</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="height"
                                                                                    placeholder="Height"
                                                                                    id="height"
                                                                                    value={this.state.height}
                                                                                />
                                                                                {/*{errors.height && touched.height && (*/}
                                                                                {/*    <div className="invalid-feedback d-block">*/}
                                                                                {/*        {errors.height}*/}
                                                                                {/*    </div>*/}
                                                                                {/*)}*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Label for="pieces">Pieces</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="pieces"
                                                                                    placeholder="Pieces"
                                                                                    value={this.state.pieces}
                                                                                />
                                                                                {/*{errors.pieces && touched.pieces && (*/}
                                                                                {/*    <div className="invalid-feedback d-block">*/}
                                                                                {/*        {errors.pieces}*/}
                                                                                {/*    </div>*/}
                                                                                {/*)}*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Label for="selectWeight">Select Weight</Label>
                                                                                <select
                                                                                    id="selectWeight"
                                                                                    className=" custom-select"
                                                                                    name="selectWeight"
                                                                                    placeholder="Select Weight"
                                                                                    value={this.state.selectWeight}
                                                                                    onChange={this.handleChange}
                                                                                >
                                                                                    <option value="Kgs">Kgs</option>
                                                                                    <option value="Lbs">Lbs</option>

                                                                                </select>
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Label for="Gw/Pc">Weight Per Piece</Label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="gw_pc"
                                                                                    placeholder="Gw/Pc"
                                                                                    value={this.state.gw_pc}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <button className="plus-signs" type="submit"
                                                                                name="addDimension"
                                                                                onClick={this.addNewDimension}>
                                                                            <FontAwesomeIcon icon={faPlus}/>
                                                                        </button>
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </div>
                                                    <div className="DimensionView calculate-inputs">
                                                        <Label className="text-white" onClick={this.toggleDimensionCollapse}>Entered Dimensions</Label>
                                                        <Collapse isOpen={this.state.dimensionCollapse}>
                                                            {this.state.uploadedDimensions ? this.state.uploadedDimensions.map((dimensionRow, dimensionIndex) => {
                                                                return (
                                                                    <div className="d-flex flex-wrap align-items-center justify-content-start" key={dimensionIndex}>
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="length"
                                                                                    placeholder="Length"
                                                                                    readOnly
                                                                                    value={dimensionRow.length}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="width"
                                                                                    placeholder="Width"
                                                                                    readOnly
                                                                                    value={dimensionRow.width}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox length-widths">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="height"
                                                                                    readOnly
                                                                                    placeholder="Height"
                                                                                    value={dimensionRow.height}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="pieces"
                                                                                    readOnly
                                                                                    placeholder="Pieces"
                                                                                    value={dimensionRow.pieces}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="selectWeight"
                                                                                    readOnly
                                                                                    placeholder="Select Weight"
                                                                                    value={dimensionRow.selectWeight}
                                                                                />

                                                                                {/*<select*/}
                                                                                {/*    id="selectWeight"*/}
                                                                                {/*    className="form-control"*/}
                                                                                {/*    name="selectWeight"*/}
                                                                                {/*    placeholder="Select Weight"*/}
                                                                                {/*    value={dimensionRow.selectWeight}*/}
                                                                                {/*>*/}
                                                                                {/*    <option value="">Select Weight Type</option>*/}
                                                                                {/*    <option value="Lbs">Lbs</option>*/}
                                                                                {/*    <option value="Kgs">Kgs</option>*/}

                                                                                {/*</select>*/}
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="new-originbox dimens-widths">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="gw_pc"
                                                                                    placeholder="Gw/Pc"
                                                                                    readOnly
                                                                                    value={dimensionRow.gw_pc}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <Button className="plus-signs"
                                                                                disabled={!this.state.allowedEdit}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    let dimension = this.state.uploadedDimensions;
                                                                                    dimension.splice(dimensionIndex, 1);
                                                                                    this.setState({uploadedDimensions: dimension}, () => {
                                                                                        let totalPieces = 0
                                                                                        for(let i=0;i<this.state.uploadedDimensions.length;i++){
                                                                                            totalPieces = totalPieces + Number(this.state.uploadedDimensions[i].pieces)
                                                                                        }
                                                                                        this.setState({
                                                                                            totalPiecesFromQuery : totalPieces
                                                                                        })
                                                                                    });
                                                                                }}
                                                                                name="addDimension"
                                                                        > <FontAwesomeIcon icon={faMinus}/></Button>

                                                                    </div>
                                                                );
                                                            }) : ""}
                                                        </Collapse>
                                                    </div>
                                                </>
                                                : ""}
                                        </>
                                        :""}
                                    {this.state.tab3 ?
                                        <div className="d-flex flex-wrap justify-content-center align-items-start mb-3">
                                            <div className="new_banner_input packing_Data">
                                                <label> Upload Packing List</label>
                                                <div className="d-flex flex-wrap upload-data shipperUpload">
                                                    <UploadFilesCloudes
                                                        name={this.state.packingListUrl}
                                                        fromEdocket={false}
                                                        quoteFor={this.state.queryFor}
                                                        placeHolder="Upload Files"
                                                        isSingleUpload={true}
                                                        isUploadingFiles={isUploadingFiles => {
                                                            this.setState({
                                                                isUploadingFiles: isUploadingFiles
                                                            })
                                                        }}
                                                        onUploadFinish={(name, uploadedUrls) => {

                                                            if (uploadedUrls != undefined && uploadedUrls != "") {
                                                                this.setState({
                                                                    uploadObj:{name:"Packing List",link:uploadedUrls},
                                                                    packingListUrl:uploadedUrls,
                                                                },()=>{
                                                                    this.attachments({uploadObj:this.state. uploadObj,id:this.state.quoteId})

                                                                })
                                                            } else {
                                                                toast.error("Please Select File", {
                                                                    position: "top-left",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                            }
                                                        }}

                                                        returnResponce={responce => {
                                                            this.setState({
                                                                returnResponce: responce
                                                            }, () => {

                                                            })
                                                        }}
                                                    />
                                                    {this.state.packingListUrl!== "" ?
                                                        <div className="doc_btnss">
                                                            <button className=" btn" onClick={this.DownloadPackingList}>
                                                                <FontAwesomeIcon icon={faEye}/> View</button>
                                                        </div>:""


                                                    }
                                                </div>
                                                {/* <div className="box_new_query" onClick={e => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        showPackingDetailsPop : !this.state.showPackingDetailsPop,
                                                        showCommercialDetailsPop : false,
                                                        showIncoTermPop : false,
                                                        showShipperBranchPop : false,
                                                        showAccountTypePop : false,
                                                        showShipperPop:false,
                                                        showConsigneePop : false,
                                                        showConsigneeBranchPop:false,
                                                    })
                                                }}>
                                                    <p>Packing Invoice Upload</p>
                                                </div> */}
                                            </div>
                                            <div className="new_banner_input packing_Data">
                                                <label>Upload Commercial Invoice</label>
                                                <div className="d-flex flex-wrap upload-data shipperUpload">
                                                    <UploadFilesCloudes
                                                        name={this.state.commercialInvoiceUrl}
                                                        fromEdocket={false}
                                                        quoteFor={this.state.queryFor}
                                                        placeHolder="Upload Files"
                                                        isSingleUpload={true}
                                                        isUploadingFiles={isUploadingFiles => {
                                                            this.setState({
                                                                isUploadingFiles: isUploadingFiles
                                                            })
                                                        }}
                                                        onUploadFinish={(name, uploadedUrls) => {

                                                            if (uploadedUrls != undefined && uploadedUrls != "") {
                                                                this.setState({
                                                                    uploadObj:{name:"Commercial Invoice",link:uploadedUrls},
                                                                    commercialInvoiceUrl:uploadedUrls
                                                                },()=>{
                                                                    this.attachments({uploadObj:this.state. uploadObj,id:this.state.quoteId})

                                                                })
                                                            } else {
                                                                toast.error("Please Select File", {
                                                                    position: "top-left",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                            }
                                                        }}

                                                        returnResponce={responce => {
                                                            this.setState({
                                                                returnResponce: responce
                                                            }, () => {

                                                            })
                                                        }}
                                                    />
                                                    {this.state.commercialInvoiceUrl!== "" ?
                                                        <div className="doc_btnss">
                                                            <button className=" btn"  onClick={this.DownloadCommercialInvoice} ><FontAwesomeIcon icon={faEye}/> View</button>
                                                        </div>:""}
                                                </div>
                                                {/* <div className="box_new_query" onClick={e => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        showCommercialDetailsPop:!this.state.showCommercialDetailsPop,
                                                        showPackingDetailsPop : false,
                                                        showIncoTermPop : false,
                                                        showShipperBranchPop : false,
                                                        showAccountTypePop : false,
                                                        showShipperPop:false,
                                                        showConsigneePop : false,
                                                        showConsigneeBranchPop:false,
                                                    })
                                                }}>
                                                    <p>Commercial Invoice Upload</p>
                                                </div> */}
                                            </div>
                                        </div>

                                        :""}
                                    {this.state.tab4 ?(
                                        <>
                                            <div className="houseDetails" hidden={this.state.showNewShipper === true || this.state.showNewConsignee === true}>
                                                <div>
                                                    {this.props.zipaSuggestionBook ?
                                                        this.state.queryFor ==="Air" ?
                                                            "":
                                                            <div className="AAA-editbooking-console-shipment-3 pb-2">
                                                                <FormGroup check>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        id="isConsole"
                                                                        checked={this.state.checked}
                                                                        onClick={this.handleChecked}
                                                                        label={ this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"?"HBL Required":"HAWB Required"}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        :
                                                        <>
                                                            {this.state.queryFor === "Air" ?
                                                                <div className="AAA-editbooking-console-shipment-3 pb-2">
                                                                    <FormGroup check>
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            id="isConsole"
                                                                            checked={this.state.checked}
                                                                            onClick={this.handleChecked}
                                                                            label="HAWB Required"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                :
                                                                this.state.queryDataForcard.shipmentMode!=="LCL"?
                                                                    <div className="AAA-editbooking-console-shipment-3 pb-2">
                                                                        <FormGroup check>
                                                                            <CustomInput
                                                                                type="checkbox"
                                                                                id="isConsole"
                                                                                checked={this.state.checked}
                                                                                onClick={this.handleChecked}
                                                                                label="HBL Required"
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                    :""
                                                            }
                                                        </>
                                                    }

                                                    {this.state.checked && (
                                                        <>
                                                            <div className="d-flex flex-wrap justify-content-between align-items-end houseShipper houseDetailsNo">
                                                                <div className="new_banner_input new_shipper_inputs">
                                                                    <FormGroup>
                                                                        <Label for="Select Stock From">
                                                                            <strong>Issued By</strong>
                                                                        </Label>

                                                                        <select name="issuedBySelection"
                                                                                className="mycustom1 custom-select"
                                                                                value={this.state.issuedBySelection}
                                                                                onChange={e => {
                                                                                    e.preventDefault()
                                                                                    if(this.state.noOfHousesRequired!=0){
                                                                                        toast.error("Houses Must Be 0 For Switching Issued By", {
                                                                                            position: "top-left",
                                                                                            autoClose: 3000,
                                                                                            hideProgressBar: false,
                                                                                            closeOnClick: true,
                                                                                            pauseOnHover: true,
                                                                                            draggable: true,
                                                                                            progress: undefined,
                                                                                        });
                                                                                        this.setState(
                                                                                            {issuedBySelection: e.target.value});
                                                                                    }else{

                                                                                        this.setState(
                                                                                            {
                                                                                                issuedBySelection: e.target.value
                                                                                            });
                                                                                    }
                                                                                }}>
                                                                            <option value="">Select</option>
                                                                            <option value={this.state.branchCompanyName ? this.state.branchCompanyName :COMPANYDETAILS.companyName}>{this.state.branchCompanyName ? this.state.branchCompanyName :COMPANYDETAILS.companyName}</option>
                                                                            <option value="Others">Others</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </div>
                                                                {this.state.issuedBySelection === "Others" ?
                                                                    <div className="new_banner_input">
                                                                        <div className="form-group">
                                                                            <label htmlFor="issuedBySelectionBranch" className="origin-label">Issued By</label>
                                                                            <input name="issuedBySelectionBranch"
                                                                                   className="form-control inputs1"
                                                                                   type="text" id="house"
                                                                                   placeholder="Code"
                                                                                   defaultValue =""
                                                                                   value={this.state.issuedBySelectionBranch}
                                                                                   onChange={e => {
                                                                                       this.setState({
                                                                                           issuedBySelectionBranch : e.target.value
                                                                                       },()=>{

                                                                                       })
                                                                                   }}
                                                                            />
                                                                        </div>
                                                                    </div>:""}
                                                                {this.state.checked ?
                                                                    <div className="new_banner_input new_shipper_inputs">
                                                                        <div className="form-group">
                                                                            <Label for="No of House">
                                                                                <strong>
                                                                                    {this.props.zipaSuggestionBook ?
                                                                                        this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air" ? <>{"No of Hawb"}</> :
                                                                                            <>"No of Hbl"</> :
                                                                                            <>
                                                                                                {this.state.queryFor === "Air" ?
                                                                                                    <>{"No of HAWB"}</> :
                                                                                                    this.state.queryDataForcard.shipmentMode !== "LCL" ?
                                                                                                        <>{"No of HBL"}</> : ""
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </strong>
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="noOfHousesRequired"
                                                                                readOnly
                                                                                value={this.state.noOfHousesRequired}
                                                                                onChange={e => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    :""}
                                                                <div className="form-group">
                                                                    <button className="btn"
                                                                            hidden={this.state.NoMoreHawb === true}
                                                                            onClick={e => {
                                                                                if (this.state.issuedBySelection == ""){
                                                                                    toast.error("Please Select Issues By", {
                                                                                        position: "top-left",
                                                                                        autoClose: 3000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true,
                                                                                        progress: undefined,
                                                                                    });
                                                                                }else {
                                                                                    this.setState({
                                                                                        noOfHousesRequired: parseFloatValue(this.state.noOfHousesRequired) + 1,
                                                                                        // totalUsedPiecesByHouses: [],
                                                                                        // houseData: []
                                                                                    }, () => {
                                                                                        let serverObj = {
                                                                                            noOfHawb: this.state.noOfHousesRequired,
                                                                                            companyName:this.state.branchCompanyName ? this.state.branchCompanyName : COMPANYDETAILS.companyName,
                                                                                            //branchName: this.state.selectedBranchForHawbObj.label,
                                                                                            //branchId: this.state.selectedBranchForHawbObj.value,
                                                                                            //branchPrefix: this.state.selectedBranchForHawbObj.prefixQuote
                                                                                        };
                                                                                        this.AddAnotherHouse(serverObj)
                                                                                    });
                                                                                }

                                                                            }}>
                                                                                {this.props.zipaSuggestionBook ?
                                                                                        this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air" ? <>{"Add Hawb"}</> :
                                                                                    <>
                                                                                        { this.state.getChargesObj && this.state.getChargesObj.shipmentMode === "FCL"?"Add Hbl":""}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {this.state.getChargesObj && this.state.getChargesObj.queryFor  === "Air" ?
                                                                                    <>{"Add Hawb"}</>
                                                                                    :
                                                                                            this.state.getChargesObj && this.state.getChargesObj.shipmentMode  !== "LCL" ?
                                                                                        <>{"Add Hbl"}</> : ""
                                                                                    }
                                                                                    </>
                                                                                }
                                                                        {/* {this.state.queryFor === "Air" ?"Add HAWB":"Add HBL"} */}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-wrap justify-content-end">

                                                            </div>
                                                        </>
                                                    )}
                                                    {/*<Col lg="12">*/}
                                                    {/*    {this.state.noOfHousesRequired!=0?*/}
                                                    {/*        <div className="d-flex align-items-center flex-wrap">*/}
                                                    {/*            <div className="width-3">*/}
                                                    {/*                <table>*/}
                                                    {/*                    <thead>*/}
                                                    {/*                    <tr>*/}
                                                    {/*                        <th>#</th>*/}
                                                    {/*                        <th>Volume Weight</th>*/}
                                                    {/*                        <th>Gross Weigh</th>*/}
                                                    {/*                        <th>No Of Pieces</th>*/}
                                                    {/*                    </tr>*/}

                                                    {/*                    </thead>*/}
                                                    {/*                    <tbody>*/}
                                                    {/*                    <tr>*/}
                                                    {/*                        <td>Houses</td>*/}
                                                    {/*                        <td>{this.state.quoteData.totalVolumeHouses}</td>*/}
                                                    {/*                        <td>{this.state.quoteData.totalGrossHouses}</td>*/}
                                                    {/*                        <td>{this.state.quoteData.totalPiecesHouses}</td>*/}
                                                    {/*                    </tr>*/}
                                                    {/*                    <tr>*/}
                                                    {/*                        <td>Master</td>*/}
                                                    {/*                        <td>{this.state.quoteData.volumeWeight}</td>*/}
                                                    {/*                        <td>{this.state.quoteData.grossWeight}</td>*/}
                                                    {/*                        <td>{this.state.quoteData.totalPieces}</td>*/}

                                                    {/*                    </tr>*/}
                                                    {/*                    <tr>*/}
                                                    {/*                        <td># </td>*/}
                                                    {/*                        {this.state.quoteData.volumeWeight==this.state.quoteData.totalVolumeHouses?<td style={{backgroundColor:"green"}} >Matched</td>:<td style={{backgroundColor:"red"}}>Unmatched</td>}*/}
                                                    {/*                        {this.state.quoteData.grossWeight==this.state.quoteData.totalGrossHouses?<td style={{backgroundColor:"green"}}>Matched</td>:<td style={{backgroundColor:"red"}}>Unmatched</td>}*/}
                                                    {/*                        {this.state.quoteData.totalPieces==this.state.quoteData.totalPiecesHouses?<td style={{backgroundColor:"green"}}>Matched</td>:<td style={{backgroundColor:"red"}}>Unmatched</td>}*/}
                                                    {/*                    </tr>*/}
                                                    {/*                    </tbody>*/}
                                                    {/*                </table>*/}

                                                    {/*            </div>*/}
                                                    {/*        </div>:""}*/}
                                                    {/*</Col>*/}
                                                </div>
                                            </div>
                                            {this.state.checked &&
                                            parseFloatValue(this.state.noOfHousesRequired) !== 0 &&
                                            this.createDynamicHouseCard()}
                                        </>):""}
                                    {/*/////////////////////////////////Shipper and Consignee/////////////////////*/}
                                    <div className="shipper_con_pop">
                                        <Modal className="dangerous_pop dimen_editbooking_popup"
                                               isOpen={this.state.dimensionToggle1}
                                               size="lg"
                                               toggle={this.toggleDimension}>
                                            <ModalHeader toggle={this.toggleDimension} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                                            </ModalHeader>
                                            <ModalBody>
                                                <div className="">
                                                    <div className="pop-text">
                                                        <h2>Dimension Details</h2>
                                                        <hr/>
                                                        <div className="dimension_types mt-4">
                                                            <div className="d-flex flex-wrap align-items-center justify-content-start">
                                                                <h4><strong>Dimension Type : </strong></h4>
                                                                <div className="checkbox-widths">
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="CM"
                                                                        label="CM"
                                                                        name="selectedDimensionType"
                                                                        value={"CM"}
                                                                        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                        checked={this.state.selectedDimensionType === "CM"}
                                                                    />
                                                                </div>
                                                                {/*<li>*/}
                                                                {/*    <CustomInput*/}
                                                                {/*        type="radio"*/}
                                                                {/*        id="FT"*/}
                                                                {/*        label="FT"*/}
                                                                {/*        name="selectedDimensionType"*/}
                                                                {/*        value={"FT"}*/}
                                                                {/*        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}*/}
                                                                {/*        checked={this.state.selectedDimensionType === "FT"}*/}
                                                                {/*    />*/}
                                                                {/*</li>*/}
                                                                <div className="checkbox-widths">
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="MM"
                                                                        label="MM"
                                                                        name="selectedDimensionType"
                                                                        value={"MM"}
                                                                        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                        checked={this.state.selectedDimensionType === "MM"}
                                                                    />
                                                                </div>
                                                                <div className="checkbox-widths">
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="INCHES"
                                                                        label="INCHES"
                                                                        name="selectedDimensionType"
                                                                        value={"INCHES"}
                                                                        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                        checked={this.state.selectedDimensionType === "INCHES"}
                                                                    />
                                                                </div>
                                                                <div className="checkbox-widths">
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="METERS"
                                                                        label="METERS"
                                                                        name="selectedDimensionType"
                                                                        value={"METERS"}
                                                                        onChange={this.state.allowedEdit !== false ? this.handleChange : ""}
                                                                        checked={this.state.selectedDimensionType === "METERS"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="AAA-add-query-dimension-7 calculate-inputs mt-4">
                                                            <div className="AAA-editbooking-data-20 ">
                                                                <label className="text-white" hidden={!this.state.allowedEdit}>Dimensions Info.</label>
                                                            </div>
                                                            <Formik hidden={!this.state.allowedEdit} validationSchema={formSchema}
                                                                    onSubmit={this.addNewDimension} initialValues={{}}>
                                                                {({errors, touched}) => (
                                                                    <Form hidden={!this.state.allowedEdit}>
                                                                        {/*<Label>{this.state.dimensionOverload}</Label>*/}

                                                                        <div className="d-flex flex-wrap align-items-center justify-content-start mt-4 mb-40">
                                                                            <div className="new-originbox length-widths">
                                                                                <FormGroup>

                                                                                    <Label for="length">Length</Label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="length"
                                                                                        id="length"
                                                                                        placeholder="Length"
                                                                                        value={this.state.length}
                                                                                    />
                                                                                    {/*{errors.length && touched.length && (*/}
                                                                                    {/*    <div className="invalid-feedback d-block">*/}
                                                                                    {/*        {errors.length}*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*)}*/}
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div className="new-originbox length-widths">
                                                                                <FormGroup>
                                                                                    <Label for="width">Width</Label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="width"
                                                                                        placeholder="Width"
                                                                                        id="width"
                                                                                        value={this.state.width}
                                                                                    />
                                                                                    {/*{errors.width && touched.width && (*/}
                                                                                    {/*    <div className="invalid-feedback d-block">*/}
                                                                                    {/*        {errors.width}*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*)}*/}
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div className="new-originbox length-widths">
                                                                                <FormGroup>
                                                                                    <Label for="height">Height</Label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="height"
                                                                                        placeholder="Height"
                                                                                        id="height"
                                                                                        value={this.state.height}
                                                                                    />
                                                                                    {/*{errors.height && touched.height && (*/}
                                                                                    {/*    <div className="invalid-feedback d-block">*/}
                                                                                    {/*        {errors.height}*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*)}*/}
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div className="new-originbox dimens-widths">
                                                                                <FormGroup>
                                                                                    <Label for="pieces">Pieces</Label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="pieces"
                                                                                        placeholder="Pieces"
                                                                                        value={this.state.pieces}
                                                                                    />
                                                                                    {/*{errors.pieces && touched.pieces && (*/}
                                                                                    {/*    <div className="invalid-feedback d-block">*/}
                                                                                    {/*        {errors.pieces}*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*)}*/}
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div className="new-originbox dimens-widths">
                                                                                <FormGroup>
                                                                                    <Label for="selectWeight">Select Weight</Label>
                                                                                    <select
                                                                                        id="selectWeight"
                                                                                        className=" custom-select"
                                                                                        name="selectWeight"
                                                                                        placeholder="Select Weight"
                                                                                        value={this.state.selectWeight}
                                                                                        onChange={this.handleChange}
                                                                                    >
                                                                                        <option value="Kgs">Kgs</option>
                                                                                        <option value="Lbs">Lbs</option>


                                                                                    </select>
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div  className="new-originbox dimens-widths">
                                                                                <FormGroup>
                                                                                    <Label for="Gw/Pc">Weight Per Piece</Label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="gw_pc"
                                                                                        placeholder="Gw/Pc"
                                                                                        value={this.state.gw_pc}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                            <button className="plus-signs" type="submit"
                                                                                    name="addDimension"
                                                                                    onClick={this.addNewDimension}>
                                                                                <FontAwesomeIcon icon={faPlus}/>
                                                                            </button>
                                                                        </div>

                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                        <div className="AAA-add-query-dimension-7 calculate-inputs">
                                                            <div className="AAA-editbooking-data-21">
                                                                <Label className="text-white" onClick={this.toggleDimensionCollapse}>Entered Dimensions</Label>
                                                            </div>
                                                            <Collapse isOpen={this.state.dimensionCollapse}>
                                                                {this.state.uploadedDimensions ? this.state.uploadedDimensions.map((dimensionRow, dimensionIndex) => {

                                                                    return (
                                                                        <Row>
                                                                            <Col lg="12">
                                                                                <div className="d-flex flex-wrap align-items-center justify-content-start" key={dimensionIndex}>
                                                                                    <div className="new-originbox length-widths">
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                name="length"
                                                                                                placeholder="Length"
                                                                                                readOnly
                                                                                                value={dimensionRow.length}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="new-originbox length-widths">
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                name="width"
                                                                                                placeholder="Width"
                                                                                                readOnly
                                                                                                value={dimensionRow.width}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="new-originbox length-widths">
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                name="height"
                                                                                                readOnly
                                                                                                placeholder="Height"
                                                                                                value={dimensionRow.height}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="new-originbox dimens-widths">
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                name="pieces"
                                                                                                readOnly
                                                                                                placeholder="Pieces"
                                                                                                value={dimensionRow.pieces}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="new-originbox dimens-widths">
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                name="selectWeight"
                                                                                                readOnly
                                                                                                placeholder="Select Weight"
                                                                                                value={dimensionRow.selectWeight}
                                                                                            />

                                                                                            {/*<select*/}
                                                                                            {/*    id="selectWeight"*/}
                                                                                            {/*    className="form-control"*/}
                                                                                            {/*    name="selectWeight"*/}
                                                                                            {/*    placeholder="Select Weight"*/}
                                                                                            {/*    value={dimensionRow.selectWeight}*/}
                                                                                            {/*>*/}
                                                                                            {/*    <option value="">Select Weight Type</option>*/}
                                                                                            {/*    <option value="Lbs">Lbs</option>*/}
                                                                                            {/*    <option value="Kgs">Kgs</option>*/}

                                                                                            {/*</select>*/}
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="new-originbox dimens-widths">
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                name="gw_pc"
                                                                                                placeholder="Gw/Pc"
                                                                                                readOnly
                                                                                                value={dimensionRow.gw_pc}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <Button className="plus-signs"
                                                                                            disabled={!this.state.allowedEdit}
                                                                                            name="addDimension"
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                let dimension = this.state.uploadedDimensions;
                                                                                                dimension.splice(dimensionIndex, 1);
                                                                                                this.setState({uploadedDimensions: dimension}, () => {
                                                                                                    let totalPieces = 0
                                                                                                    for(let i=0;i<this.state.uploadedDimensions.length;i++){
                                                                                                        totalPieces = totalPieces + Number(this.state.uploadedDimensions[i].pieces)
                                                                                                    }
                                                                                                    this.setState({
                                                                                                        totalPiecesFromQuery : totalPieces
                                                                                                    })
                                                                                                });
                                                                                            }}><FontAwesomeIcon icon={faTimes}/></Button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    );
                                                                }) : ""}
                                                            </Collapse>
                                                        </div>
                                                        <div>
                                                            <hr/>
                                                            <Row className="">
                                                                <Col lg="6">
                                                                    <div className="AAA-add-query-dimension-4">
                                                                        <FormGroup>
                                                                            <Label for="Add/Upload Dimension"><strong>Download Dimension Format</strong></Label>
                                                                            <div className="upload-btn-wrapper">
                                                                                <button>
                                                                                    <a
                                                                                        href="https://test231220.s3.ap-south-1.amazonaws.com/akash/s3Bucketoo0.7056215923785736-1612094389549.csv"
                                                                                        download="DummyDimension.csv"
                                                                                    >
                                                                                        <span className="flaticon-download"></span>Download CSV Format
                                                                                    </a>
                                                                                </button>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <div className="AAA-add-query-dimension-5">
                                                                        <FormGroup>
                                                                            <Label for="Upload Dimension"><strong>Upload Dimension</strong></Label>
                                                                            <div className="upload-btn-wrapper">
                                                                                <CSVReader
                                                                                    cssClass="csv-reader-input"
                                                                                    inputStyle={{color: "red"}}
                                                                                    onFileLoaded={this.handleUploadedDimensions}
                                                                                    parserOptions={dimensionParseOptions}
                                                                                />
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                        {/* console shipment start  */}
                                        {this.state.tab5 && (
                                            <>
                                                <div className="houseDetails" hidden={this.state.showNewShipper === true || this.state.showNewConsignee === true}>
                                                    <div>
                                                        {this.state.queryFor === "Air" ?
                                                            <Col lg="12">
                                                                <div className="AAA-editbooking-console-shipment-3 pb-2">
                                                                    <FormGroup check>
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            id="isConsole"
                                                                            checked={this.state.checked}
                                                                            onClick={this.handleChecked}
                                                                            label="HAWB Required"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                            :
                                                            <Col lg="12">
                                                                {this.state.queryDataForcard.shipmentMode!=="LCL"?
                                                                    <div className="AAA-editbooking-console-shipment-3 pb-2">
                                                                        <FormGroup check>
                                                                            <CustomInput
                                                                                type="checkbox"
                                                                                id="isConsole"
                                                                                checked={this.state.checked}
                                                                                onClick={this.handleChecked}
                                                                                label="HBL Required"
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                    :""}
                                                            </Col>
                                                        }
                                                        {this.state.checked && (
                                                            <Col lg="12">
                                                                <div className="AAA-editbooking-console-shipment-6 border-red">
                                                                    <Row>
                                                                        <Col lg="4">
                                                                            <FormGroup>
                                                                                <Label for="Select Stock From">
                                                                                    <strong>Issued By</strong>
                                                                                </Label>

                                                                                <select name="issuedBySelection"
                                                                                        className="mycustom1 custom-select"
                                                                                        value={this.state.issuedBySelection}
                                                                                        onChange={e => {
                                                                                            if(this.state.noOfHousesRequired!=0){
                                                                                                toast.error("Houses Must Be 0 For Switching Issued By", {
                                                                                                    position: "top-left",
                                                                                                    autoClose: 3000,
                                                                                                    hideProgressBar: false,
                                                                                                    closeOnClick: true,
                                                                                                    pauseOnHover: true,
                                                                                                    draggable: true,
                                                                                                    progress: undefined,
                                                                                                });
                                                                                                e.preventDefault()
                                                                                                this.setState(
                                                                                                    {
                                                                                                        issuedBySelection: e.target.value
                                                                                                    },
                                                                                                    () => {
                                                                                                    }
                                                                                                );

                                                                                            }else{

                                                                                                e.preventDefault()
                                                                                                this.setState(
                                                                                                    {
                                                                                                        issuedBySelection: e.target.value
                                                                                                    },
                                                                                                    () => {
                                                                                                    }
                                                                                                );

                                                                                            }


                                                                                        }}>
                                                                                    <option value="">Select</option>
                                                                                    <option value={this.state.branchCompanyName ? this.state.branchCompanyName :COMPANYDETAILS.companyName}>{this.state.branchCompanyName ? this.state.branchCompanyName :COMPANYDETAILS.companyName}</option>
                                                                                    <option value="Others">Others</option>
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {this.state.issuedBySelection === "Others" ?
                                                                            <Col lg="4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="issuedBySelection" className="origin-label">Issued By</label>
                                                                                    <input name="issuedBySelectionBranch"
                                                                                           className="form-control inputs1"
                                                                                           type="text" id="house"
                                                                                           placeholder="Code"
                                                                                           defaultValue =""
                                                                                           value={this.state.issuedBySelectionBranch}
                                                                                           onChange={e => {
                                                                                               this.setState({
                                                                                                   issuedBySelectionBranch : e.target.value
                                                                                               },()=>{

                                                                                               })
                                                                                           }}
                                                                                    />
                                                                                </div>
                                                                            </Col> : ''}
                                                                        {this.state.checked && (
                                                                            <>
                                                                                <Col lg="4">
                                                                                    <div className="AAA-editbooking-console-shipment-4 border-rad">
                                                                                        <Label for="No of House"><strong>{this.state.queryFor === "Air" ?"No of HAWB":"No Of HBL"}</strong></Label>
                                                                                        <Input
                                                                                            type="text"
                                                                                            name="noOfHousesRequired"
                                                                                            readOnly
                                                                                            value={this.state.noOfHousesRequired}
                                                                                            onChange={e => {
                                                                                                e.preventDefault();
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <div>
                                                                                    <label className="visi-hide" style={{display:'block'}}>Action</label>
                                                                                    <Button
                                                                                        hidden={this.state.NoMoreHawb === true}
                                                                                        onClick={e => {
                                                                                            this.setState({
                                                                                                noOfHousesRequired: parseFloatValue(this.state.noOfHousesRequired) + 1,

                                                                                            }, () => {
                                                                                                let serverObj = {
                                                                                                    noOfHawb: this.state.noOfHousesRequired,
                                                                                                    companyName:this.state.branchCompanyName ? this.state.branchCompanyName : COMPANYDETAILS.companyName,

                                                                                                };
                                                                                                this.AddAnotherHouse(serverObj)
                                                                                            });

                                                                                        }}
                                                                                    >
                                                                                        {this.state.getChargesObj && this.state.getChargesObj.queryFor === "Air" ?"Add HAWB":"Add HBL"}
                                                                                    </Button>
                                                                                </div>

                                                                            </>
                                                                        )}

                                                                        {this.state.issuedBySelection === "Others" && 1==3?
                                                                            <Col lg="4" hidden={this.state.issuedBySelection === "Others" && 1==3}>

                                                                            </Col>:""}
                                                                    </Row>
                                                                </div>
                                                            </Col>)}

                                                    </div>
                                                </div>
                                                <div className="AAA-editbooking-house-details-1">
                                                    {this.state.checked &&
                                                    parseFloatValue(this.state.noOfHousesRequired) !== 0 &&
                                                    this.createDynamicHouseCard()}
                                                </div>
                                            </>)}
                                        {/* house details end */}
                                    </div>
                                    <div className="mt-3">
                                        <div className="d-flex justify-content-end">



                                            {this.state.tab1?
                                                <>
                                                    {this.state.showNewShipper == false && this.state.showNewConsignee == false   ?
                                                        <button className="btn" type="button"
                                                                onClick={this.toggleLargePrevious}>
                                                            Previous
                                                        </button>:""}
                                                </>
                                                :""}
                                            {this.state.showNewShipper?
                                                <button className="btn" type="button"
                                                        onClick={() => this.setState({showNewShipper:false, })}>
                                                    Previous
                                                </button>
                                                :""}
                                            {this.state.showNewConsignee?
                                                <button className="btn" type="button"
                                                        onClick={() => this.setState({showNewConsignee:false,})}>
                                                    Previous
                                                </button>
                                                :
                                                <>
                                                    {Number(this.state.count) > 1 && (
                                                        <button className="btn" type="button"
                                                                onClick={() => this.Prev(this.state.count)}>
                                                            Previous
                                                        </button>
                                                    )}
                                                </>}
                                            {this.state.tab1 ?
                                                <>
                                                    {this.state.showNewShipper?
                                                        <button className="btn" type="button"
                                                                onClick={event => {
                                                                    event.preventDefault()
                                                                    this.SaveNewShipper();
                                                                }}
                                                        >
                                                            Save Shipper Details
                                                        </button>:
                                                        ""
                                                    }
                                                </>
                                                :""
                                            }
                                            {this.state.tab1 ?
                                                <>
                                                    {this.state.showNewConsignee ?
                                                        <button className="btn"  type="button"
                                                                onClick={event => {
                                                                    event.preventDefault()
                                                                    this.SaveNewConsignee();
                                                                }}
                                                        >
                                                            Save Consignee Details
                                                        </button>
                                                        :
                                                        ""
                                                    }
                                                </>
                                                :""
                                            }
                                            {this.state.showNewShipper === true || this.state.showNewConsignee === true ?
                                                "":
                                                <>
                                                    {this.props.zipaSuggestionBook ?
                                                        this.state.queryFor ==="Air" ?
                                                            <>
                                                                {Number(this.state.count) < 3 ? (
                                                                    <button className="btn" type="button" hidden={this.state.showNewConsignee || this.state.showNewConsignee}
                                                                            onClick={() => this.Next(this.state.count)}>
                                                                        Next
                                                                    </button>):""}
                                                            </>
                                                            :
                                                            <>
                                                                {Number(this.state.count) < 4 ? (
                                                                    <button className="btn" type="button" hidden={this.state.showNewConsignee || this.state.showNewConsignee}
                                                                            onClick={() => this.Next(this.state.count)}>
                                                                        Next
                                                                    </button>):""}
                                                            </>

                                                        :
                                                        <>
                                                            {this.state.queryFor ==="Air" ?
                                                                <>
                                                                    {Number(this.state.count) < 4 ? (
                                                                        <button className="btn" type="button" hidden={this.state.showNewConsignee || this.state.showNewConsignee}
                                                                                onClick={() => this.Next(this.state.count)}>
                                                                            Next
                                                                        </button>):""}
                                                                </>
                                                                :
                                                                <>{this.state.shipmentMode!=="LCL"?
                                                                    <>
                                                                        {Number(this.state.count) < 4 ? (
                                                                            <button className="btn" type="button" hidden={this.state.showNewConsignee || this.state.showNewConsignee}
                                                                                    onClick={() => this.Next(this.state.count)}>
                                                                                Next
                                                                            </button>):""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {Number(this.state.count) < 3 ? (
                                                                            <button className="btn" type="button" hidden={this.state.showNewConsignee || this.state.showNewConsignee}
                                                                                    onClick={() => this.Next(this.state.count)}>
                                                                                Next
                                                                            </button>):""}
                                                                    </>
                                                                }</>}
                                                        </>
                                                    }

                                                </>}
                                            {this.props.zipaSuggestionBook ?
                                                this.state.queryFor ==="Air" ?
                                                    <>
                                                        {Number(this.state.count) == 3 ? (
                                                            <button className="btn" type="button"
                                                                    onClick={event => {
                                                                        event.preventDefault()
                                                                        if (this.state.queryFor === "Air"){
                                                                            this.isWeightSlabRangeValidFunction()
                                                                        } else {
                                                                            this.SubmitShipperAndConsignee();
                                                                        }
                                                                    }}
                                                                    hidden={this.state.showNewButton === true}>
                                                                Submit
                                                            </button>):""}
                                                    </>
                                                    :
                                                    <>
                                                        {Number(this.state.count) == 4 ? (
                                                            <button className="btn" type="button"
                                                                    onClick={event => {
                                                                        event.preventDefault()
                                                                        if (this.state.queryFor === "Air"){
                                                                            this.isWeightSlabRangeValidFunction()
                                                                        } else {
                                                                            this.SubmitShipperAndConsignee();
                                                                        }
                                                                    }}
                                                                    hidden={this.state.showNewButton === true}>
                                                                Submit
                                                            </button>):""}
                                                    </>

                                                :
                                                <>
                                                    {this.state.queryFor ==="Air" ?
                                                        <>
                                                            {Number(this.state.count) == 4 ? (
                                                                <button className="btn" type="button"
                                                                        onClick={event => {
                                                                            event.preventDefault()
                                                                            if (this.state.queryFor === "Air"){
                                                                                this.isWeightSlabRangeValidFunction()
                                                                            } else {
                                                                                this.SubmitShipperAndConsignee();
                                                                            }
                                                                        }}
                                                                        hidden={this.state.showNewButton === true}>
                                                                    Submit
                                                                </button>):""}
                                                        </>
                                                        :
                                                        <>{this.state.shipmentMode!=="LCL"?
                                                            <>
                                                                {Number(this.state.count) == 4 ? (
                                                                    <button className="btn" type="button"
                                                                            onClick={event => {
                                                                                event.preventDefault()
                                                                                if (this.state.queryFor === "Air"){
                                                                                    this.isWeightSlabRangeValidFunction()
                                                                                } else {
                                                                                    this.SubmitShipperAndConsignee();
                                                                                }
                                                                            }}
                                                                            hidden={this.state.showNewButton === true}>
                                                                        Submit
                                                                    </button>):""}
                                                            </>
                                                            :
                                                            <>
                                                                {Number(this.state.count) == 3 ? (
                                                                    <button className="btn" type="button"
                                                                            onClick={event => {
                                                                                event.preventDefault()
                                                                                if (this.state.queryFor === "Air"){
                                                                                    this.isWeightSlabRangeValidFunction()
                                                                                } else {
                                                                                    this.SubmitShipperAndConsignee();
                                                                                }
                                                                            }}
                                                                            hidden={this.state.showNewButton === true}>
                                                                        Submit
                                                                    </button>):""}
                                                            </>
                                                        }</>}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>

                </Modal>
                <Modal className="new_shipper_popup"
                       isOpen={this.state.showNewShipper1}
                       size="lg"
                       toggle={this.toggleNewShipper}>
                    <ModalHeader toggle={this.toggleNewShipper} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                    </ModalHeader>
                    <ModalBody>
                        <div className="">
                            <div className="pop-text">
                                <h2>Add Shipper Details</h2>
                                <hr/>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-center dimen_radius_width">
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="shipperName">Shipper Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                        <Input type="text" id="shipperName"
                                               name="shipperName"
                                               onChange={this.handleChange}
                                               value={this.state.shipperName === undefined ? "" : this.state.shipperName}
                                        />
                                    </FormGroup>
                                </div>
                                {/*<Col lg="4">*/}
                                {/*    <FormGroup>*/}
                                {/*        <Label for="branchName">Enter Consignee Branch</Label>*/}
                                {/*        <Input type="text" id="branchName"*/}
                                {/*               name="branchName" onChange={this.handleChange}*/}
                                {/*               value={this.state.branchName === undefined ? "" : this.state.branchName}*/}
                                {/*        />*/}
                                {/*    </FormGroup>*/}
                                {/*</Col>*/}
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="addressLine1">Address Line 1 <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                        <Input type="text" id="addressLine1"
                                               name="shipperAddressLine1"
                                               onChange={this.handleChange}
                                               value={this.state.shipperAddressLine1 === undefined ? "" : this.state.shipperAddressLine1}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="addressLine2">Address Line 2</Label>
                                        <Input type="text" id="shipperAddressLine2"
                                               name="shipperAddressLine2" onChange={this.handleChange}
                                               value={this.state.shipperAddressLine2 === undefined ? "" : this.state.shipperAddressLine2}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <div className="AAA-add-shipper-data-4">
                                        <FormGroup>
                                            <Label for="countryId">Select Country <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                            <CountryDropDown
                                                handler={this.onCountrySelectedForShipper}
                                                selectedCountry={
                                                    this.state.shipperCountryId === undefined
                                                        ? ""
                                                        : this.state.shipperCountryId
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="width_shipper_consignee">
                                    <StateDropdown
                                        handler={this.onShipperStateSelected}
                                        Lable={"Select State" }
                                        selectedState={
                                            this.state.shipperStateId === undefined
                                                ? ""
                                                : this.state.shipperStateId
                                        }
                                        countryId={this.state.shipperCountryId}
                                        fromShipperConsignee={true}
                                    />
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="city">City Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                        <Input type="text" id="city"
                                               name="shipperCity"
                                               onChange={this.handleChange}
                                               value={this.state.shipperCity === undefined ? "" : this.state.shipperCity}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="pincode">Pincode</Label>
                                        <Input type="text" id="pincode"
                                               name="shipperPincode" onChange={this.handleChange}
                                               value={this.state.shipperPincode === undefined ? "" : this.state.shipperPincode}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="others">Contact Details</Label>
                                        <Input type="number" id="others"
                                               name="shipperContact"
                                               onChange={this.handleChange}
                                               value={this.state.shipperContact === undefined ? "" : this.state.shipperContact}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="consigneeContactName">Contact Name</Label>
                                        <Input type="text" id="shipperContactName"
                                               name="shipperContactName"
                                               onChange={this.handleChange}
                                               value={this.state.shipperContactName === undefined ? "" : this.state.shipperContactName}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <hr/>
                            <div className="consignee_btn float-right">
                                <Button className="" style={{background: '#ff0600!important',
                                    borderRadius: '4px'}} type="button"
                                        onClick={event => {
                                            event.preventDefault()
                                            this.SaveNewShipper();
                                        }}
                                >
                                    Save Shipper Details
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal className="new_shipper_popup"
                       isOpen={this.state.showNewConsignee1}
                       size="lg"
                       toggle={this.toggleNewConsignee}>
                    <ModalHeader toggle={this.toggleNewConsignee} style={{padding:'0',borderBottom:'1px solid transparent'}}>
                    </ModalHeader>
                    <ModalBody>
                        <div className="">
                            <div className="pop-text">
                                <h2>Add Consignee Details</h2>
                                <hr/>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-center dimen_radius_width">
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="consigneeName">Consignee Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                        <Input type="text" id="consigneeName"
                                               placeholder="Consignee Name"
                                               name="consigneeName"
                                               onChange={this.handleChange}
                                               value={this.state.consigneeName === undefined ? "" : this.state.consigneeName}
                                        />
                                    </FormGroup>
                                </div>
                                {/*<Col lg="4">*/}
                                {/*    <FormGroup>*/}
                                {/*        <Label for="branchName">Enter Consignee Branch</Label>*/}
                                {/*        <Input type="text" id="branchName"*/}
                                {/*               name="branchName" onChange={this.handleChange}*/}
                                {/*               value={this.state.branchName === undefined ? "" : this.state.branchName}*/}
                                {/*        />*/}
                                {/*    </FormGroup>*/}
                                {/*</Col>*/}
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="addressLine1">Address Line 1 <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                        <Input type="text" id="addressLine1"
                                               placeholder="Address Line 1"
                                               name="consigneeAddressLine1"
                                               onChange={this.handleChange}
                                               value={this.state.consigneeAddressLine1 === undefined ? "" : this.state.consigneeAddressLine1}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="addressLine2">Address Line 2</Label>
                                        <Input type="text" id="addressLine2"
                                               placeholder="Address Line 2"
                                               name="consigneeAddressLine2" onChange={this.handleChange}
                                               value={this.state.consigneeAddressLine2 === undefined ? "" : this.state.consigneeAddressLine2}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <div className="AAA-add-shipper-data-4">
                                        <FormGroup>
                                            <Label for="countryId">Select Country <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                            <CountryDropDown
                                                handler={this.onConsigneeCountrySelected}
                                                selectedCountry={
                                                    this.state.consigneeCountryId === undefined
                                                        ? ""
                                                        : this.state.consigneeCountryId
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="width_shipper_consignee">
                                    <StateDropdown
                                        handler={this.onConsigneeStateSelected}
                                        Lable={"Select State"}
                                        selectedState={
                                            this.state.consigneeStateId === undefined
                                                ? ""
                                                : this.state.consigneeStateId
                                        }
                                        countryId={this.state.consigneeCountryId}
                                        fromShipperConsignee={true}
                                    />
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="city">City <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                        <Input type="text" id="city"
                                               placeholder="City"
                                               name="consigneeCity"
                                               onChange={this.handleChange}
                                               value={this.state.consigneeCity === undefined ? "" : this.state.consigneeCity}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="pincode">Pincode</Label>
                                        <Input type="text" id="pincode"
                                               placeholder="Pincode"
                                               name="consigneePincode" onChange={this.handleChange}
                                               value={this.state.consigneePincode === undefined ? "" : this.state.consigneePincode}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="others">Contact Details</Label>
                                        <Input type="number" id="others"
                                               name="consigneeContact"

                                               onChange={this.handleChange}
                                               value={this.state.consigneeContact === undefined ? "" : this.state.consigneeContact}
                                        />
                                        <div className="text-danger">{this.state.errors.phone}</div>
                                    </FormGroup>
                                </div>
                                <div className="width_shipper_consignee">
                                    <FormGroup>
                                        <Label for="consigneeContactName">Contact Name</Label>
                                        <Input type="text" id="consigneeContactName"
                                               name="consigneeContactName"

                                               onChange={this.handleChange}
                                               value={this.state.consigneeContactName === undefined ? "" : this.state.consigneeContactName}
                                        />

                                    </FormGroup>
                                </div>
                            </div>
                            <hr/>
                            <div className="consignee_btn float-right">
                                <Button className="" style={{background: '#ff0600!important',
                                }} type="button"
                                        onClick={event => {
                                            event.preventDefault()
                                            this.SaveNewConsignee();
                                        }}
                                >
                                    Save Consignee Details
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }


    createDynamicHouseCard() {
        let houseItems = [];
        for (let i = 0; i < this.state.noOfHousesRequired; i++) {

            let houseNumber=""
            if(Array.isArray(this.state.hawbDropDownOptions) && this.state.hawbDropDownOptions.length>i){
                houseNumber=this.state.hawbDropDownOptions[i].value
            }



            houseItems.push(
                <HouseDetailCard
                    case4={this.state.case4}
                    houseIndex={i}
                    houseNumber={houseNumber}
                    houseData={this.state.houseData[i]}
                    ShipperNameData = {this.state.ShipperNameData}
                    ConsigneeNameData = {this.state.ConsigneeNameData}
                    zipaSuggestionBook = {this.props.zipaSuggestionBook}
                    deleteHouse={() => {
                        let houseData = this.houseData
                        let totalUsedPiecesByHouses = this.totalUsedPiecesByHouses
                        let ArrayForHBL = this.ArrayForHBL
                        let AvailableHbLContainersArray = this.AvailableHbLContainersArray

                        houseData.splice(i, 1)
                        totalUsedPiecesByHouses.splice(i, 1)
                        ArrayForHBL.splice(i, 1) // for deleting container array of current house number
                        AvailableHbLContainersArray.splice(i, 1) // for deleting container count array of current house number

                        this.setState({
                                changedData: true,
                                houseData: houseData,
                                updateData: houseData,
                                totalUsedPiecesByHouses: totalUsedPiecesByHouses,
                                ArrayForHBL :ArrayForHBL,
                                AvailableHbLContainersArray :AvailableHbLContainersArray,

                                noOfHousesRequired: parseFloatValue(this.state.noOfHousesRequired) - 1
                            }, () => {
                                try {
                                    this.calculateAvailablePieces(this.state.totalUsedPiecesByHouses[i])


                                } catch (e) {

                                }


                                if (this.state.noOfHousesRequired === 0) {
                                    this.setState({
                                        checked: false,

                                    })
                                }
                            }
                        );

                    }}
                    totalHouses={parseFloatValue(this.state.noOfHousesRequired)}
                    selectedDimensionType={this.state.selectedDimensionType}
                    queryDetails={this.state.quoteData}
                    totalPieces={this.state.totalPiecesFromQuery}
                    NoOfContainer={ parseFloatValue(this.state.noOfHousesRequired) <= 1 ? this.state.NoOfContainer : undefined}
                    ContainerCountArray={this.state.TotalContainerCount}
                    grossWeight={this.state.totalGross}
                    queryFor={this.state.queryFor}
                    volumeWeight={this.state.totalVolume}
                    chargeableWeight={this.state.chargeableWeight}
                    dimensionArray={this.state.queryDimentionsData}
                    hawbDropDownOptions={this.state.hawbDropDownOptions}
                    updateData={this.state.updateData[i]}
                    ContainerListForHBL={this.state.ContainerList} // initial container list pass to first house
                    RemainingContainerListForHBL = {parseFloatValue(this.state.noOfHousesRequired) >= 2 ? this.ArrayForHBL[i] : undefined } // remaining container utilisation array use for 2 and more than that
                    RemainingContainerCountForHBL = {parseFloatValue(this.state.noOfHousesRequired) >= 2 ? this.AvailableHbLContainersArray[i] : undefined } // remaining container count utilisation array use for 2 and more than that
                    houseSelected={this.state.issuedBySelection === "Others"}
                    availablePieces={this.state.totalUsedPiecesByHouses
                        ? this.calculateAvailablePieces(this.totalUsedPiecesByHouses[i])
                        : this.state.totalPieces
                    }
                    availableDimension={
                        this.state.totalUsedPiecesByHouses
                            ? this.calculateAvailablePieces(this.state.totalUsedPiecesByHouses[i])
                            : this.state.totalPieces
                    }
                    houseDataChanged={houseData => {
                        console.log(houseData)
                        this.houseData[i] = houseData;
                        this.setState({
                            changedData: true,
                            houseData: this.houseData,
                            updateData: this.houseData
                        }, () => {
                            let houseData = this.state.houseData
                            let array = []
                            houseData && houseData.map((item, i) => {


                                item.shipperId = item.shipperId &&  item.shipperId._id ? item.shipperId._id : item.shipperId;
                                item.shipperBranchId = item.shipperBranchId &&  item.shipperBranchId._id ? item.shipperBranchId._id : item.shipperBranchId;
                                item.consigneeId = item.consigneeId &&  item.consigneeId._id ? item.consigneeId._id : item.consigneeId;
                                item.consigneeBranchId = item.consigneeBranchId &&  item.consigneeBranchId._id ? item.consigneeBranchId._id : item.consigneeBranchId;
                                item.shipperEmail =item.shipperEmail
                                item.consigneeDetails = item.consigneeDetail ? item.consigneeDetail : "";
                                item.consigneeEmail =item.consigneeEmail
                                item.noOfPieces = item.noOfPieces ? item.noOfPieces : "";
                                let agentDetails = {
                                    agentName: "",
                                    agentCode: "",
                                    agentAccountInfo: "",
                                    agentPlace: "",
                                    agentAccountType: "",
                                }
                                item.agentDetails = agentDetails;
                                item.noOfContainers = item.NoOfContainer ? item.NoOfContainer : "";
                                item.dimensions = item.dimensions ? item.dimensions : [];
                                item.selectedDimensionType = item.selectedDimensionType ? item.selectedDimensionType : [];
                                let chargesDetail = {
                                    volumeWeight: item.volumeWeight ? item.volumeWeight : "",
                                    noOfPieces: item.noOfPieces ? item.noOfPieces : "",
                                    grossWeight: item.grossWeight ? item.grossWeight : "",
                                    kgOrLBS: "kg",
                                    rateClass: "Q",
                                    commudityItemNo: "",
                                    chargeableWeight: item.chargeableWeight ? item.chargeableWeight : "",
                                    rateCharges: "",
                                    total: ""
                                }
                                if(this.state.shipmentMode === "FCL"){
                                    let containerArry = []
                                    if(Array.isArray(item.HblContainerList) && item.HblContainerList.length >=1 )
                                    {
                                        if(localStorage.getItem("selectedCharge")){
                                            let chargesData = JSON.parse(localStorage.getItem("selectedCharge"))
                                            let CurrencyData = JSON.parse(localStorage.getItem("countriesMaster"))
                                            let chargesCurrency = chargesData.row && chargesData.row.currency ?chargesData.row.currency : "INR";
                                            let currecyRate = "" ;
                                            if(CurrencyData && CurrencyData.result){
                                                CurrencyData.result.map((itm,j)=>{
                                                    if(itm.currencyCode === chargesCurrency){
                                                        currecyRate = itm.currencyRate;
                                                    }
                                                })
                                            }

                                            item.HblContainerList.map((obj,i)=>{
                                                let data = {} ;
                                                data.name = obj.container ;
                                                data.count = obj.count;

                                                containerArry.push(data);
                                            })

                                            item.containerType = containerArry;
                                        }else if(!localStorage.getItem("SpotRateQuery") === false){


                                            item.HblContainerList.map((obj,i)=>{
                                                let data = {} ;
                                                data.name = obj.container ;
                                                data.count = obj.count;
                                                containerArry.push(data);
                                            })

                                            item.containerType = containerArry;

                                        }

                                    }
                                }
                                item.chargesDetail = chargesDetail;
                                array.push(item)
                            })

                            this.setState({houseData: array},()=>{
                                this.checkForValidatingHouseAndMasterData()
                            })

                        });
                    }}
                    noOfPiecesUsedByHouse={updatedData => {
                        this.totalUsedPiecesByHouses[updatedData.houseIndex] = updatedData.usedPieces;
                        this.setState({
                            totalUsedPiecesByHouses: this.totalUsedPiecesByHouses
                        }, () => {
                            if (this.state.shipmentMode === "LCL") {
                                //this.checkContainerForHouse(i)
                            }
                            // this.ShowAddNew()
                        });
                    }}
                    remainingArrayForNextHbl = {data=>{      // remaining utilising array of container got from current HBL
                        this.ArrayForHBL[data.houseIndex] = data.remainingListForNext;
                        this.setState({
                            ArrayForHBL: this.ArrayForHBL
                        },()=>{
                        })
                    }}

                />
            );
        }
        return <>{houseItems}</>;
    }



    getHawbNumberFromServer = (serverObj) => {
        this.getHawbUnique(serverObj).then(response => {
            {

                let hawbDropDownOptions = this.state.hawbDropDownOptions;
                if (response && response.success) {
                    response.result &&
                    response.result.map(hawbNumber => {
                        let hawbDataObj = {};
                        hawbDataObj.label = hawbNumber;
                        hawbDataObj.value = hawbNumber;
                        hawbDropDownOptions.push(hawbDataObj);
                    });
                    this.setState({hawbDropDownOptions: hawbDropDownOptions});
                }
            }
        })
            .catch();
    }
}

const mapStateToProps = (state) => {
    const {user, loading, LoginMessage, closeModal, newShipper} = state.authUser;
    const {BookingMassage, OpenPerForma, container} = state.advanceSearchReducer;
    const {
        message
    } = state.masterShipperConsigneeApprovalReducer;
    return {user, loading, message, LoginMessage, closeModal, newShipper, BookingMassage, OpenPerForma, container};
};

export default connect(
    mapStateToProps,
    {
        registerUser, loginUser, sendNextToDashboard, AddShipperConsignee,SendConatinerList
    }
)(ShipperConsigneeModal);
