import {Label} from "reactstrap";
import React, {Component, Fragment} from "react";
import AsyncPaginate from "react-select-async-paginate";
import RestClient from "../util/RestClient";

class IncoTermsDropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstHit: true,
            designationMasterData: [],
            isLoading: true,
            response: [],
            error: "",
            selectedDesignation: undefined,
            selectionOptions: [],
            APIRoute: "",
            resultValue: "",
            totalCount: 0,
            currentCount: 0,
        };
    }

    loadOptions = async (search, loadedOptions) => {
        try {

            let SearchParameters = {search: search,activityType:this.state.activityType};
            let selectionOriginOptionsList = [];
            let originData = []

            if (this.state.firstHit || this.state.search!=SearchParameters.search.length) {
                const data = await RestClient({
                    url: this.state.APIRoute,
                    method: "POST",
                    data: SearchParameters,
                });
                if (data.success) {
                    this.setState({
                        search:SearchParameters.search.length,
                        totalCount: data.result.total,
                        firstHit: false,
                        currentCount: (0+ data.result.data.length)
                    })
                    originData = data.result.data;
                }
            } else {
                if (this.state.currentCount < this.state.totalCount) {
                    SearchParameters.start = this.state.currentCount
                    const data = await RestClient({
                        url: this.state.APIRoute,
                        method: "POST",
                        data: SearchParameters,
                    });
                    if (data.success) {
                        this.setState({
                            totalCount: data.result.total,
                            currentCount: (this.state.currentCount + data.result.data.length)
                        })
                        originData = data.result.data;
                    }
                }
            }

            if (originData.length > 0) {
                for (let i = 0; i < originData.length; i++) {
                    let {_id,name,termsOfSales} = originData[i];
                    let obj = {
                        value: _id,
                        label: name,
                        termsOfSales:termsOfSales

                    };
                    selectionOriginOptionsList.push(obj);
                }
            }
            return {options: selectionOriginOptionsList, hasMore: !search,};

        } catch (e) {
            return {
                options: loadedOptions,
                hasMore: false,
            };
        }

    };

    componentDidMount() {
        if (this.props.APIRout && this.props.APIRout !== undefined) {
            this.setState({APIRoute: this.props.APIRout});
        }
        if (this.props.activityType && this.props.activityType !== undefined) {
            this.setState({activityType: this.props.activityType});
        }
        if (this.props.selectedObj && this.props.selectedObj !== undefined) {
            this.setState({selectedObj: this.props.selectedObj});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedObj && this.props.selectedObj !== prevProps.selectedObj) {
            this.setState({
                resultValue: this.props.selectedObj
            }, () => {
                //alert(JSON.stringify(this.state.resultValue))
            })
        }
        // if (this.props.activityType && this.props.activityType !== prevProps.activityType) {
        //     this.setState({
        //         resultValue: this.props.activityType
        //     })
        // }
    }


    render() {
        try {
            return (
                <Fragment>

                    <Label for="from">{this.props.Lable}</Label>

                    <AsyncPaginate
                        {...this.props}
                        placeholder={this.props.PlaceHolder}
                        value={this.state.resultValue}
                        // name="pincodeMapped"
                        onChange={(values) => {
                            // alert(JSON.stringify(values))
                            this.setState({resultValue: values},()=>{
                                this.props.handler(
                                    values,
                                );
                            });

                        }}
                        loadOptions={this.loadOptions}
                    />

                </Fragment>
            );
        } catch (e) {
        }
    }
}

export default IncoTermsDropDown;
