import React, {Component, Fragment} from "react";
import { Modal, ModalHeader, ModalBody} from "reactstrap";

import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import {ADD_COUPAN, GET_STORED_CHARGES_API, RATE_SINGLE_DATA} from "../../../constants";
import {PAGE_PATHS} from "../../../util/PageRoute";
import RestClient from "../../../util/RestClient";
import {Link} from "react-router-dom";
// import CurrencyDropDown from "../DropDowns/currencyDropDown";
// import {history} from "../../../util/helpers/history";

class AddCoupanApplyForHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMode:"",
            paymentId: "",
            modalLarge: false,
            message: "",
            openLogin: false,
            Compalsari: false,
            remarks:"",
            proformaId:"",
            utrId:"",
            tds:"",
            amount:"",
            customerCode:"",
            PerformaId:"",
            customerId:"",
            customer:"",
            currency:"",
            queryFor:""
        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {
        if(localStorage.getItem("customer")){
            let data = JSON.parse(localStorage.getItem("customer")).result;
            this.setState({
                customerIdForCoupon : data && data.customerBranchData && data.customerBranchData.customerId ? data.customerBranchData.customerId :"",
                customerBranchIdCoupon : data && data.customerBranchData && data.customerBranchData._id ? data.customerBranchData._id :""
            },()=>{
            })
        }
        if (this.props.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        
    }

    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if (this.state.modalLarge === false){
                this.props.setmodal1(false)
            }
        });
    };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
    }
    rateIdFound = (data) => {
        let rateLoggedData = localStorage.getItem('ratesLoggedUser')
        rateLoggedData = JSON.parse(rateLoggedData)
        if(rateLoggedData){
        if(rateLoggedData.length>0){
        let foundId = rateLoggedData.find(ele=>ele.amountB == data)
        if(foundId){
            return foundId._id
        }else{
            return ''
        }
    }
}else{
        return ''
    }
    }
    handleSubmit =()=>{
        let rateId
        if(this.props.rateId){
            rateId = this.props.rateId
        }else{
        let amount = localStorage.getItem('amountB')
         rateId = this.rateIdFound(amount)
        }
        this.handleCoupan({
            coupanCode : this.state.coupanCode,
            quoteId : this.props.quoteId,
            rateId : rateId,
            customerBranchId : this.state.customerBranchIdCoupon || this.props.custBranchId,
            customerId : this.state.customerIdForCoupon || this.props.customerId,
        }).then(res=>{
            if(res.success){
                // this.props.setmodal1(false)
                toast.success("Coupon Applied.", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.getRateAfterCoupan({id : this.props.rateId}).then(responce=>{
                    if(responce.success){
                        let rateData = responce && Array.isArray(responce.result) && responce.result.length >= 1 ? responce.result[0] : "";
                        this.setState(
                            {
                                rateDataSelected: rateData,
                            },()=>{
                                this.props.sendDataAfterCoupan(this.state.rateDataSelected);
                            }
                        );
                        // this.props.sendDataAfterCoupan(res.result)
                        setTimeout(()=>{
                            localStorage.setItem("afterDiscountCharges",JSON.stringify(responce.result))
                            localStorage.setItem("discountGiven",true)
                            this.toggleLarge()
                        },1000)

                    }
                })
                // this.getRateAfterCoupan({quoteId : this.props.quoteId,suggestedRates:this.props.suggestedRates ? this.props.suggestedRates:false}).then(res=>{
                //     if(res.success){
                //         this.props.sendDataAfterCoupan(res.result)
                //         setTimeout(()=>{
                //             localStorage.setItem("afterDiscountCharges",JSON.stringify(res.result))
                //             localStorage.setItem("discountGiven",true)
                //             this.toggleLarge()
                //         },5000)
                //
                //     }
                // })
            }else{
                toast.error(res.message, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    async handleCoupan(body) {
        return await RestClient({
            url: ADD_COUPAN,
            method: "POST",
            data: body
        });
    }
    async getRateAfterCoupan(body) {
        return await RestClient({
            // url: GET_STORED_CHARGES_API,
            url: RATE_SINGLE_DATA,
            method: "POST",
            data: body
        });
    }


    render() {
        // console.log('value of query',this.props.queryFor)
        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Modal className="coupan_pop"
                    isOpen={this.state.modalLarge}
                    size="md"
                    toggle={this.toggleLarge}
                >

                        <>
                            <ModalHeader toggle={this.toggleLarge} className="" style={{padding: "0"}}>
                                {/*<NavLink to={`/`} className=" white">*/}
                                {/*    /!*<span className="logo-single" style={{marginBottom: "-9px"}}/>*!/*/}
                                {/*</NavLink>*/}
                            </ModalHeader>
                            <ModalBody className="padding-0">
                                <div className="back-img" style={{padding: "0px;"}}>
                                    <div className="auth-card1">
                                        <div className="cont-forms form-side">
                                            <h5 className=" text-center" style={{fontWeight: "bold"}} >
                                                <span>Coupon Information</span></h5>
                                                    <hr/>
                                                <form className="av-tooltip tooltip-label-bottom mt-3">

                                                        <div className="">
                                                            <div className="form-group">
                                                                <label htmlFor="issuedBySelection" className="origin-label">Coupon Code</label>
                                                                <input name="coupanCode"
                                                                       className="form-control inputs1"
                                                                       type="text" id="coupanCode"
                                                                       placeholder="Enter Coupon Code"
                                                                       defaultValue =""
                                                                       value={this.state.coupanCode}
                                                                       onChange={e => {this.onChange(e)}}
                                                                />
                                                            </div>
                                                             <p className="view_offers"><Link to="/OfferMy" target="_blank">View Offers</Link></p>
                                                        </div>
                                                    <div className="form-group mt-2 text-center">
                                                            <button className="book-btn btn border-cir" style={{borderRadius: "50px;"}}
                                                            onClick={e=>{e.preventDefault()
                                                            this.handleSubmit()
                                                            }}>
                                                                Submit
                                                            </button>
                                                        </div>

                                                </form>
                                        </div>
                                    </div>


                                </div>
                            </ModalBody>
                        </>




                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {} = state.QueryReducer;
    return {};
};

export default connect(
    mapStateToProps,
    {}
)(AddCoupanApplyForHome);
