import React, { Component} from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router";
import FooterHome from "./FooterHome";
import {onAddsTickerRequested} from "../../../redux/NewsFlashRedux/NewsFlashAction";
import OfferCard from "./OfferCard";
import AppLayout from "../../../layout/AppLayout";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";


class Offer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            start: 0,
            AddsTickerList: [],
            OfferHomeModel: false,
            OfferModel: false,
            selectedOfferData: {},
            copied: false,
        };

    }

    componentDidMount() {
        this.props.onAddsTickerRequested()


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.OfferHomeModel && this.props.OfferHomeModel !== prevProps.OfferHomeModel) {
            this.setState({
                OfferHomeModel: this.props.OfferHomeModel
            })
        }

        if (this.props.AddsTickerList && this.props.AddsTickerList !== prevProps.AddsTickerList) {
            this.setState({
                AddsTickerList: this.props.AddsTickerList
            }, () => {
                let tickerList = []
                let AddsTickerList = Array.isArray(this.state.AddsTickerList) && this.state.AddsTickerList.length === true ? [] : this.state.AddsTickerList


                AddsTickerList && AddsTickerList.map((item, i) => {
                    try {
                        tickerList.push(
                            <span onClick={e => {
                                e.preventDefault()
                                window.open(item.hitUrl)
                            }}>
                                <div className="post-slide">
                                  <div className="ads-shadow d-flex">
                                      <div className="ads-img"><img src={item.attachments[0]} alt={item.title}/></div>
                                      <div className="ads-text">
                                          <p>{item.title}</p>
                                          <h2>{item.description}</h2>
                                      </div>
                                  </div>
                                </div>
                            </span>
                        )

                    } catch (e) {

                    }

                })

                this.setState({tickerItemList: tickerList})
            })
        }

    }
    copyClipboard = (index) =>{
        let AddsTickerList = Array.isArray(this.state.AddsTickerList) && this.state.AddsTickerList.length === true ? [] : this.state.AddsTickerList
        AddsTickerList && AddsTickerList.map((item, i) => {
            if(item._id === index){
                item.couponCopy = true;
            }else{
                item.couponCopy = false;
            }
        })
        this.setState({
            AddsTickerList:this.state.AddsTickerList,
        },()=>{

        })
    }

    render() {
        let AddsTickerList = Array.isArray(this.state.AddsTickerList) && this.state.AddsTickerList.length === true ? [] : this.state.AddsTickerList
        return (

            <AppLayout>
                <OfferCard
                    OfferModel={this.state.OfferModel}
                    selectedOfferData = {this.state.selectedOfferData}
                    returnResult = {(value) => { if(value === true){

                        this.setState({OfferModel:false,OfferHomeModel:false})
                    } }}
                />
                <div className="dashboard-wrapper">
                    <div className="offerss-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 privacy_content">
                                    <div className="privacy-title mt-3 mb-3">
                                        <h2>Super Offers</h2>
                                    </div>
                                </div>
                                { AddsTickerList && AddsTickerList.map((item,i)=>{
                                    return(<>
                                            {item && item.isForCoupan ?
                                            <div className="col-lg-4 col-md-6">
                                                <div className="coupon_box">
                                                    <div className="coupon_img">
                                                        <img src={item.attachments[0]} alt="offer img"/>
                                                    </div>
                                                    <div className="coupon_text">
                                                        <h3>Coupon Code : {item && item.coupanData && item.coupanData.name ? item.coupanData.name : undefined}</h3>
                                                        <h5 className="description_coupons mb-2">{item.description}</h5>

                                                        <div className="terms_link">Terms and Condition Applied</div>
                                                        {item.couponCopy ? <div className="copied_green">Coupon Copied Successfully</div>:""}
                                                        <div className="apply_btn">
                                                            <CopyToClipboard text={item && item.coupanData && item.coupanData.name ? item.coupanData.name : undefined}
                                                                             onCopy={e=>{
                                                                                 this.copyClipboard(item._id)
                                                                             }}
                                                                // onCopy={this.copyClipboard
                                                                // localStorage.setItem("SelectedCouponCode",JSON.stringify(item))}
                                                            >
                                                                <button className="btn coupon_code_btn">Copy Code</button>
                                                            </CopyToClipboard>
                                                            <button className="btn apply_now" onClick={e => {
                                                                e.preventDefault()
                                                                this.setState({selectedOfferData:item , OfferModel:true })
                                                                // this.props.selectedOfferData(item)
                                                            }}>View More</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                                :""}
                                        </>
                                    );


                                })}

                            </div>
                        </div>
                    </div>
                </div>


                <FooterHome
                />

            </AppLayout>
        )
    }


}

const mapStateToProps = (state) => {
    const {AddsTickerList, errorMessage} = state.NewsFlashData;
    return {AddsTickerList, errorMessage};
};

const OfferWithRoute = withRouter(Offer);

export default connect(mapStateToProps, {

    onAddsTickerRequested


})(OfferWithRoute);
