import React from "react";
import {Route,Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {getUserAuthToken} from "../util/getLoggedUserAuthToken";
import AppLayout from "./AppLayout";
const MainLayoutRouteWithManagers = ({render, ...rest}) => {
    if (getUserAuthToken() === null || getUserAuthToken() === "") {
        return (

          <Redirect
            to={{
              pathname: '/Home',
              }}
          />

        );
    } else {

        switch (rest.path) {
            default:
                return (
                    <Route
                        {...rest}
                        render={matchProps => (
                            <AppLayout>
                                <div className="dashboard-wrapper">
                                    {render(matchProps)}
                                </div>
                            </AppLayout>
                        )}
                    />
                );
        }
    }
};

const mapStateToProps = (state) => {
    return {state}
};

export default connect(mapStateToProps)(MainLayoutRouteWithManagers);