import {takeEvery, call, put, all, fork} from "redux-saga/effects";

import RestClient from "../../util/RestClient";
import {PAGE_PATHS} from "../../util/PageRoute";
import {
  ADD_STATE_API,
  DELETE_STATE_API,
  Edit_STATE_API,
  E_DOCKET_REQUEST_REQUEST,
  E_DOCKET_REQUEST_REQUEST_AIR,
  GET_QUERY_REVERT_API,
  GET_QUERY_REVERT_AUTO_API,
  GET_STATE_API,
  GETALL_STATE_API,
  MANAGER_STATE_API,
  MARKET_QUERIES_API,
  QUERY_DATA_ADD_API,
  QUERY_DATA_UPDATE_API,
  QUERY_MANAGER_API,
  QUERY_MANAGER_MASTER_DELETE,
  AWB_PDF_SEND_MANAGER,
  QUERY_PAST_SHIPMENT_MANAGER_API,
  QUERY_SHIPMENT_MANAGER_API,
  QUERY_SHIPMENT_MANAGER_HISTORY_API,
  UPDATE_STATE_API
} from "../../constants";
import {

  QUERY_PREVIEW_DATA,

} from "../actions";
import {toast} from "react-toastify";
// import {history} from "../../utils/helpers/history";

import {

  getQueryPreviewDataFailuer,
  getQueryPreviewDataSuccess,

} from "./QueryAction";
import {watchRegistrationMaster} from "../registrationMasterRedux/registrationSaga";


function* watchQueryUser() {

  yield takeEvery(QUERY_PREVIEW_DATA, QueryPreviewGetData);


}






function* QueryPreviewGetData({ payload }) {
    try {
    const result = yield call(QueryPreviewGetDataAPI, payload);
    if (result.success) {
      yield put(getQueryPreviewDataSuccess(result.result));
    }else {
      // toast.error(result.message, {
      //   position: "top-left",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      yield put(getQueryPreviewDataFailuer(result));
    }
  } catch (error) {
    yield put(getQueryPreviewDataFailuer(error));
  }
}
const QueryPreviewGetDataAPI = async (params) => {
  console.log("params1",JSON.stringify(params))
  return await RestClient({
    url:  params && params.quoteFor === "Air" ? E_DOCKET_REQUEST_REQUEST_AIR : E_DOCKET_REQUEST_REQUEST,
    method: "POST",
    data: params
  })
};







export default function* Saga() {
  yield all([fork(watchQueryUser)]);
}
