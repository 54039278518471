import React, {Component} from "react";
import {toast} from 'react-toastify';
import {convertDateISOToCustomiseFormat} from "../util/CommonUtility";
import {
    GET_DATA_ADDINTENEDED_PLAN_API,
    CURRENCY_RATE_CONVERSION_API,
    REMOVE_COUPAN,
    RATE_SINGLE_DATA,
    RATE_SINGLE_DATA_COURIER,
    RATE_SINGLE_DATA_ROAD,
    GUEST_CUSTOMER_DATA
} from "../constants";
import RestClient from "../util/RestClient";
import AddCoupanPopUp from "../views/app/dashboards/AddCoupanPopUp";
import {
    faChevronDown,
    faChevronUp,
    faDollarSign,
    faEuroSign,
    faGift, faPoundSign,
    faRupeeSign,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PreviousCourierShipmentHistoryRateCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableIntends:[],
            data: {},
            title: "title",
            detail: "detail",
            percent: 80,
            progressText: "8/10",
            BookingID: "1",
            login: undefined,
            showCollaps:false,
            showCollapsForRate:false,
            creditDays : "",
            creditDaysSTatus : false,
            switchCurrency : true,
            rateIdSelected:"",
            coupanApplied:false

        };

    }

    sendToIntendedTransportPlan = (item) => {
        let QuoteId = this.state.quoteId
        let intendedTransportPlan = [];
        let quoteId = QuoteId
        intendedTransportPlan.push({
            id: item._id,
            from: item.originDetails.name,
            to: item.destinationDetails.name,
            vesselNo: item.vessel,
            voyageNo: item.voyageNo,
            eta: item.arrival,
            etd: item.departure,})

        let availableIntends=this.state.availableIntends
        availableIntends.push({
            id: item._id,
            from: item.originDetails.name,
            to: item.destinationDetails.name,
            vesselNo: item.vessel,
            voyageNo: item.voyageNo,
            eta: item.arrival,
            etd: item.departure
        })
        this.setState({availableIntends:availableIntends,intendedTransportPlan: intendedTransportPlan,quoteId:QuoteId}, () => {
            this.addIntenededPlan({quoteId,intendedTransportPlan}).then(dd=>{

                if(dd.success){
                    toast.success("Planned For booking", {
                        position: "top-left",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }else{
                    toast.error("Something Wrong Please Contact Customer Service", {
                        position: "top-left",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        })

    }
    removeCoupan = async () => {
        let customerBranchData = JSON.parse(localStorage.getItem('customer'))
        let obj ={
            rateId : this.props.data && this.props.data._id,
            customerBranchId : customerBranchData ?customerBranchData && customerBranchData.result && customerBranchData.result.customerBranchData._id : '',
            coupanId : this.props.data && this.props.data.coupanId
        }
        {
            return await RestClient({
                url: REMOVE_COUPAN,
                method: "POST",
                data: obj
            });
        }
    };
    checkIdAvailable = (id) => {
        let st=false
        for (let i = 0; i < this.state.availableIntends.length; i++) {
            if(id==this.state.availableIntends[i].id){
                st=true
            }
        }
        return st
    }
    addIntenededPlan = async (indenededData) => {
        {
            return await RestClient({
                url: GET_DATA_ADDINTENEDED_PLAN_API,
                method: "POST",
                data: indenededData
            });
        }
    };

    componentDidMount() {
        if (this.props.queryData) {
            this.setState(
                {
                    queryData: this.props.queryData,
                    quoteIDdd: this.props.queryData && this.props.queryData._id,
                },
                () => {
                    // console.log("querydatass",JSON.stringify(this.props.queryData))
                    this.setState({
                        quoteId: this.state.queryData._id,
                        availableIntends: this.state.queryData.intendedTransportPlan,
                    });
                }
            );
        }
        if (this.props.data) {
            this.setState(
                {
                    creditDays1: this.props.data,
                },
                () => {
                    this.setState({
                        creditDays:
                            this.state.creditDays1 && this.state.creditDays1.creditDays
                                ? this.state.creditDays1.creditDays
                                : "",
                    });
                }
            );
        }
        if(this.props.currencySelected){
            this.setState({
                currencySelected : this.props.currencySelected === "Not" ? "" : this.props.currencySelected
            },()=>{
                this.currencyCalculation()
            })
        }
        // let selectedCurrency = localStorage.getItem("appCurrency");
        // if (
        //     selectedCurrency === "INR" ||
        //     selectedCurrency === "USD" ||
        //     selectedCurrency === "EUR" ||
        //     selectedCurrency === "GBP"
        // ) {
        //     let data = this.props.data;
        //     let obj = {};
        //     obj.to = selectedCurrency;
        //     obj.from = data.baseCurrency;
        //     this.handleCurrencyCovertor(obj).then((res) => {
        //         if (res.success) {
        //             this.setState(
        //                 {
        //                     currencySelected: selectedCurrency,
        //                     selectedCurrencyRate: res.result,
        //                 },
        //                 () => {
        //                     // console.log("selectedCurrencyRates",this.state.selectedCurrencyRate)
        //                 }
        //             );
        //         }
        //     });
        // }
        if (!localStorage.getItem("already_registered") === "true") {
            this.getDummyCustomerData().then();
        }
        if(this.props.coupanName){
            this.setState({
                showCollaps : false
            })
        }
        if(this.props.coupanNames){
            this.setState({
                showCollaps : false
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.switchCurrency && this.props.switchCurrency !== prevProps.switchCurrency) {
            this.setState({
                switchCurrency:this.props.switchCurrency
            })
        }
        if(this.props.currencySelected && this.props.currencySelected !== prevProps.currencySelected){
            this.setState({
                currencySelected : this.props.currencySelected === "Not" ? "" : this.props.currencySelected
            },()=>{
                this.currencyCalculation()
            })
        }
        if(this.props.coupanName && this.props.coupanName !== prevProps.coupanName){
            this.setState({
                showCollaps : false
            })
        }
        if(this.props.coupanNames && this.props.coupanNames !== prevProps.coupanNames){
            this.setState({
                showCollaps : false
            })
        }
    }
    getDummyCustomerData = async () => {
        this.GetGuestCustomerData().then((responce) => {
            let customerData =
                responce && responce.result ? responce.result : undefined;
            // console.log("customersdata",JSON.stringify(customerData))
            // console.log("customerData.customerBranchDatacustomerData.customerBranchData"+JSON.stringify(customerData.customerBranchData))
            let custKycStatusWithOutLogin =
                customerData && customerData.customerBranchData
                    ? customerData.customerBranchData.kycStatus
                    : "";
            this.setState(
                {
                    custKycStatus: custKycStatusWithOutLogin,
                },
                () => {
                    this.props.login(true);
                }
            );
        });
    };
    GetGuestCustomerData = async (dataForServer) => {
        return await RestClient({
            url: GUEST_CUSTOMER_DATA,
            method: "POST",
            data: dataForServer,
        });
    };
    handleCurrencyCovertor = async (dataForServer) =>{
        return await RestClient({
            url: CURRENCY_RATE_CONVERSION_API,
            method: "POST",
            data: dataForServer
        });
    }
    switchNeedCredit = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },()=>{
            localStorage.setItem('creditDays',this.state.creditDays)
        });
    }
    switchCurrency = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if(e.target.name == "switchCurrency"){
                this.setState({
                    switchCurrency : !this.props.switchCurrency,
                }, () =>{

                })
        }

    }
    creditDaysSTatusFunc = () =>{
        this.setState({
            creditDaysSTatus:!this.state.creditDaysSTatus
        },()=>{
            if(this.state.creditDaysSTatus === false){
                this.setState({
                    creditDays : ""
                })
            }
        })
    }
    getrateData = async (dataForServer) => {
        return await RestClient({
          url: dataForServer && dataForServer.quoteFor === "Road" ? RATE_SINGLE_DATA_ROAD : RATE_SINGLE_DATA_COURIER,
          method: "POST",
          data: dataForServer
    })}
    currencyCalculation = () =>{
        if(this.state.currencySelected === "INR"){
            this.setState({
                selectedCurrencyRate : this.props.xRateCustomerINR
            })
        }else if(this.state.currencySelected === "USD"){
            this.setState({
                selectedCurrencyRate : this.props.xRateCustomerUSD
            })
        }else if(this.state.currencySelected === "EUR"){
            this.setState({
                selectedCurrencyRate : this.props.xRateCustomerEUR
            })
        }else if(this.state.currencySelected === "GBP"){
            this.setState({
                selectedCurrencyRate : this.props.xRateCustomerGBP
            })
        }else{
            this.setState({
                selectedCurrencyRate : ""
            })
        }
    }
    render() {
        let {data, queryData} = this.props

        // detail=concatExtraStringWithCheck(detail,"Day","Days")
        // detail=concatExtraStringWithCheck(detail,"day","Days")

        const SelectedWightSlab =data && data.selectedWeightSlab ? data.selectedWeightSlab : ""
        const ValidityDate = data && data.validityEndDate ? new Date(data.validityEndDate) : ""
        if(ValidityDate !== "" && ValidityDate !== undefined){
            ValidityDate.setHours(0,0,0,0);
        }

        const CurrrentDate = new Date()
        if(CurrrentDate !== "" && CurrrentDate !== undefined){
            CurrrentDate.setHours(0,0,0,0);
        }

        let obj = this.props.data
        return (
            <>
                <AddCoupanPopUp
                    modalLarge={this.state.payLarge}
                    setmodal1={values => values === false ? this.setState({payLarge: false}) : ""}
                    quoteId = {this.state.quoteId}
                    rateId = {obj._id}
                    customerBranchId = {queryData.customerBranchId}
                    

                />
                <div id="three-cards" className="rate-card mb-3 half-rates">
                    <div className="d-flex flex-wrap align-items-stretch rates_partition">
                        <div className="destination_origin_app">
                            {Array.isArray(obj.charges) && obj.charges.length >= 1
                                ? obj.charges.map((item, i) => {
                                    return (
                                        <>
                                            <div className="width-new-rates">
                                                <div className="origin_destination_rates">
                                                    <div className="text_origin">
                                                        <p>Pick Up</p>
                                                        <div className="destination_name">
                                                            {item && item.originCode}
                                                        </div>
                                                    </div>
                                                    <div className="centerLine">
                                                        <div className="spot_transit2 visi-hide">
                                                            {item && item.transitTime ? item.transitTime : "transit"}
                                                        </div>
                                                        <div className="d-flex center_width">
                                                            <div className="lineCenter"></div>
                                                            {queryData.queryFor === "Courier" ?
                                                                <FontAwesomeIcon icon={faGift} />
                                                                : queryData.queryFor === "Road" ?
                                                                    <FontAwesomeIcon icon={faTruck} />:""
                                                            }
                                                        </div>
                                                        <div className="spot_transit2 color-product">
                                                            {item && item.product}
                                                        </div>
                                                    </div>
                                                    <div className="text_origin">
                                                        <p>Drop To</p>
                                                        <div className="destination_name">
                                                            {item && item.destinationCode}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="rates_new_screen">
                                                    <div className="origin_app">
                                                        <p className="full_name rate_new_design">
                                                            {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                <>
                                                                    {item.customerCurrency == "INR" ? (
                                                                        <FontAwesomeIcon icon={faRupeeSign} />
                                                                    ) : item.customerCurrency == "USD" ? (
                                                                        <FontAwesomeIcon icon={faDollarSign} />
                                                                    ) : item.customerCurrency == "EUR" ? (
                                                                        <FontAwesomeIcon icon={faEuroSign} />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                        <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                    ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}
                                                                </>
                                                            )}{" "}
                                                            {item.coupanApply ? (
                                                                <>
                                                                    {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                        <>
                                                                            {this.state.creditDays === ""
                                                                                ? parseFloat(
                                                                                    item.amountCDiscount
                                                                                ).toFixed(2)
                                                                                : ""}
                                                                            {this.state.creditDays === "15 Days"
                                                                                ? parseFloat(
                                                                                    item.amountC15Discount
                                                                                ).toFixed(2)
                                                                                : ""}
                                                                            {this.state.creditDays === "30 Days"
                                                                                ? parseFloat(
                                                                                    item.amountC30Discount
                                                                                ).toFixed(2)
                                                                                : ""}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {this.state.creditDays === "" ? item.amountBDiscount != undefined ? (parseFloat(item.amountBDiscount) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "15 Days" ? item.amountB15Discount != undefined ? (parseFloat(item.amountB15Discount) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "30 Days" ? item.amountB30Discount != undefined ? (parseFloat(item.amountB30Discount) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}

                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                        <>
                                                                            {this.state.creditDays === ""
                                                                                ? item && item.amountC
                                                                                    ? parseFloat(item.amountC).toFixed(
                                                                                        2
                                                                                    )
                                                                                    : ""
                                                                                : ""}
                                                                            {this.state.creditDays === "15 Days"
                                                                                ? item && item.amountC15
                                                                                    ? parseFloat(
                                                                                        item.amountC15
                                                                                    ).toFixed(2)
                                                                                    : ""
                                                                                : ""}
                                                                            {this.state.creditDays === "30 Days"
                                                                                ? item && item.amountC30
                                                                                    ? parseFloat(
                                                                                        item.amountC30
                                                                                    ).toFixed(2)
                                                                                    : ""
                                                                                : ""}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {this.state.creditDays === "" ? item.amountB != undefined ? (parseFloat(item.amountB) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "15 Days" ? item.amountB15 != undefined ? (parseFloat(item.amountB15) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                            {this.state.creditDays === "30 Days" ? item.amountB30 != undefined ? (parseFloat(item.amountB30) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}

                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                {item.isEdited === false && queryData.quoteStatus==="Rates Quoted" ?
                                                <div className="radio_rates">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="rateIdSelected"
                                                            value={item._id}
                                                            onChange={(e)=>{
                                                                this.setState({
                                                                    rateIdSelected : e.target.value
                                                                },()=>{
                                                                    this.props.sideBarCss(true)
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>:""}
                                                <div className="fright_summery_icon"
                                                     onClick={(e) => {
                                                         e.preventDefault();
                                                         this.setState({
                                                             coupanApplied : false
                                                         },()=>{
                                                             this.getrateData({ id: item && item._id , quoteFor : this.state.queryData.queryFor }).then(
                                                                 (responce) => {
                                                                     if (responce && responce.success) {
                                                                         let rateData =
                                                                             responce &&
                                                                             Array.isArray(responce.result) &&
                                                                             responce.result.length >= 1
                                                                                 ? responce.result[0]
                                                                                 : "";
                                                                         let coupanApplied = rateData && rateData.coupanApply?rateData.coupanApply:false

                                                                         this.setState(
                                                                             {
                                                                                 rateData: rateData,
                                                                                 selectedFreightId : item._id,
                                                                                 showCollaps: !this.state.showCollaps,
                                                                                 coupanApplied : coupanApplied,
                                                                             }
                                                                         );
                                                                     }
                                                                 }
                                                             );
                                                         })
                                                     }}
                                                >
                                                   {this.state.selectedFreightId === item._id  ? <>
                                                        {this.state.showCollaps?<FontAwesomeIcon icon={faChevronUp} />:
                                                            <FontAwesomeIcon icon={faChevronDown} />}
                                                    </>:<FontAwesomeIcon icon={faChevronDown} />}
                                                </div>
                                            </div>
                                            {this.state.selectedFreightId === item._id ?
                                            <div id="collapseOne1" className={this.state.showCollaps === true ? "collapse border-top show" : "collapse border-top"} role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
                                                <div className="d-flex flex-wrap justify-content-between padding-15">
                                                    <div className="all-price list-fare">
                                                        <table className="table pricess-all">
                                                            <tr>
                                                                <th>
                                                                    <strong>Charges Name</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Ch Wt.(Kg)</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Rate</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Total Amount</strong>
                                                                </th>
                                                            </tr>

                                                            {this.state.rateData &&
                                                                this.state.rateData.charges.map((item, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <th>
                                                                                <strong>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.altName : item.chargeName}</strong>
                                                                            </th>
                                                                            <td>{item.chargeQty}</td>
                                                                            <td>
                                                                                {this.state.creditDays === ""
                                                                                    ? item.currency + " " + item.chargeRate
                                                                                    : ""}
                                                                                {this.state.creditDays === "15 Days"
                                                                                    ? item.currency + " " + item.chargeRate15
                                                                                    : ""}
                                                                                {this.state.creditDays === "30 Days"
                                                                                    ? item.currency + " " + item.chargeRate30
                                                                                    : ""}
                                                                            </td>
                                                                            <td>
                                                                                {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                                    <>
                                                                                        {this.state.creditDays === ""
                                                                                            ? data.customerCurrency +
                                                                                            " " +
                                                                                            item.taxableC !=
                                                                                            undefined
                                                                                                ? parseFloat(item.taxableC).toFixed(2)
                                                                                                : ""
                                                                                            : ""}
                                                                                        {this.state.creditDays === "15 Days"
                                                                                            ? data.customerCurrency +
                                                                                            " " +
                                                                                            item.taxableC15 !=
                                                                                            undefined
                                                                                                ? parseFloat(item.taxableC15).toFixed(2)
                                                                                                : ""
                                                                                            : ""}
                                                                                        {this.state.creditDays === "30 Days"
                                                                                            ? data.customerCurrency +
                                                                                            " " +
                                                                                            item.taxableC30 !=
                                                                                            undefined
                                                                                                ? parseFloat(item.taxableC30).toFixed(2)
                                                                                                : ""
                                                                                            : ""}
                                                                                    </>
                                                                                ) : (
                                                                                    <>{this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                                        <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                                    ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                        {this.state.creditDays === "" ? item.taxableB != undefined ? (parseFloat(item.taxableB) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "15 Days" ? item.taxableB15 != undefined ? (parseFloat(item.taxableB15) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "30 Days" ? item.taxableB30 != undefined ? (parseFloat(item.taxableB30) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}

                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            {this.state.rateData && this.state.rateData.awaitedRates && this.state.rateData.awaitedRates.length >0 ?
                                                                this.state.rateData && this.state.rateData.awaitedRates.map((items , index)=>{
                                                                    return(
                                                                        <tr>
                                                                            <th><strong>{items.chargeFor}</strong></th>
                                                                            <td> - </td>
                                                                            <td> - </td>
                                                                            <td><strong>Awaited</strong></td>
                                                                        </tr>
                                                                    )
                                                                })

                                                            :""}
                                                            {this.state.coupanApplied ? (
                                                                ""
                                                            ) : (
                                                                <>
                                                                    {this.state.rateData && this.state.rateData.taxAmountB !== null ? (
                                                                        <tr>
                                                                            <th>
                                                                                <strong>GST</strong>
                                                                            </th>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td>

                                                                                {(this.props.switchCurrency == true && !this.state.currencySelected ) ?
                                                                                    <>
                                                                                        {this.state.creditDays === "" ? this.state.rateData && this.state.rateData.customerCurrency + " " + this.state.rateData && this.state.rateData.taxAmountC != undefined ? parseFloat(this.state.rateData && this.state.rateData.taxAmountC).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "15 Days" ? this.state.rateData && this.state.rateData.customerCurrency + " " + this.state.rateData && this.state.rateData.taxAmountC15 != undefined ? parseFloat(this.state.rateData && this.state.rateData.taxAmountC15).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "30 Days" ? this.state.rateData && this.state.rateData.customerCurrency + " " + this.state.rateData && this.state.rateData.taxAmountC30 != undefined ? parseFloat(this.state.rateData && this.state.rateData.taxAmountC30).toFixed(2) : "" : ""}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                        {this.state.creditDays === "" ? this.state.rateData && this.state.rateData.taxAmountB != undefined ? (parseFloat(this.state.rateData && this.state.rateData.taxAmountB) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "15 Days" ? this.state.rateData && this.state.rateData.taxAmountB15 != undefined ? (parseFloat(this.state.rateData && this.state.rateData.taxAmountB15) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "30 Days" ? this.state.rateData && this.state.rateData.taxAmountB30 != undefined ? (parseFloat(this.state.rateData && this.state.rateData.taxAmountB30) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ) : undefined}
                                                                    <tr>
                                                                        <th>
                                                                            <strong>TOTAL AMOUNT</strong>
                                                                        </th>
                                                                        <td>
                                                                            <strong />{" "}
                                                                        </td>
                                                                        <td>
                                                                            <strong />{" "}
                                                                        </td>
                                                                        <td>
                                                                            <strong>
                                                                                {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                                    <>
                                                                                        {this.state.creditDays === ""
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(
                                                                                                this.state.rateData &&
                                                                                                this.state.rateData.amountC
                                                                                            ).toFixed(2)
                                                                                            : ""}
                                                                                        {this.state.creditDays === "15 Days"
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(
                                                                                                this.state.rateData &&
                                                                                                this.state.rateData.amountC15
                                                                                            ).toFixed(2)
                                                                                            : ""}
                                                                                        {this.state.creditDays === "30 Days"
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(
                                                                                                this.state.rateData &&
                                                                                                this.state.rateData.amountC30
                                                                                            ).toFixed(2)
                                                                                            : ""}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                        {this.state.creditDays === "" ? this.state.rateData?.amountB != undefined ? (parseFloat(this.state.rateData?.amountB) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "15 Days" ? this.state.rateData?.amountB15 != undefined ? (parseFloat(this.state.rateData?.amountB15) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "30 Days" ? this.state.rateData?.amountB30 != undefined ? (parseFloat(this.state.rateData?.amountB30) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}

                                                                                    </>
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )}
                                                            {this.state.coupanApplied ? (
                                                                <>
                                                                    <tr>
                                                                        <th>
                                                                            <strong>TOTAL AMOUNT</strong>
                                                                        </th>
                                                                        <td>
                                                                            <strong />{" "}
                                                                        </td>
                                                                        <td>
                                                                            <strong />{" "}
                                                                        </td>
                                                                        <td>
                                                                            <strong>
                                                                                {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                                    <>
                                                                                        {this.state.creditDays === ""
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(
                                                                                                parseFloat(
                                                                                                    this.state.rateData &&
                                                                                                    this.state.rateData.amountC
                                                                                                ) -
                                                                                                parseFloat(
                                                                                                    this.state.rateData &&
                                                                                                    this.state.rateData.taxAmountC
                                                                                                )
                                                                                            ).toFixed(2)
                                                                                            : ""}
                                                                                        {this.state.creditDays === "15 Days"
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(
                                                                                                parseFloat(
                                                                                                    this.state.rateData &&
                                                                                                    this.state.rateData.amountC15
                                                                                                ) -
                                                                                                parseFloat(
                                                                                                    this.state.rateData &&
                                                                                                    this.state.rateData.taxAmountC15
                                                                                                )
                                                                                            ).toFixed(2)
                                                                                            : ""}
                                                                                        {this.state.creditDays === "30 Days"
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(
                                                                                                parseFloat(
                                                                                                    this.state.rateData &&
                                                                                                    this.state.rateData.amountC30
                                                                                                ) -
                                                                                                parseFloat(
                                                                                                    this.state.rateData &&
                                                                                                    this.state.rateData.taxAmountC30
                                                                                                )
                                                                                            ).toFixed(2)
                                                                                            : ""}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                        {this.state.creditDays === "" ? this.state.rateData?.amountB != undefined ? (parseFloat(this.state.rateData?.amountB - this.state.rateData?.taxAmountB) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "15 Days" ? this.state.rateData?.amountB15 != undefined ? (parseFloat(this.state.rateData?.amountB15 - this.state.rateData?.taxAmountB15) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "30 Days" ? this.state.rateData?.amountB30 != undefined ? (parseFloat(this.state.rateData?.amountB30 - this.state.rateData?.taxAmountB30) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}

                                                                                    </>
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Discount on MRP</th>
                                                                        <td>
                                                                            <strong />{" "}
                                                                        </td>
                                                                        <td>
                                                                            <strong />{" "}
                                                                        </td>
                                                                        <td style={{ color: "#03a685" }}>
                                                                            <strong>
                                                                                {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                                    <>
                                                                                        {this.state.creditDays === ""
                                                                                            ? this.state.rateData && this.state.rateData.baseCurrency +
                                                                                            " " +
                                                                                            parseFloat(this.state.rateData &&
                                                                                                this.state.rateData.discountAmount).toFixed(2)
                                                                                            : ""}
                                                                                        {this.state.creditDays === "15 Days"
                                                                                            ? this.state.rateData && this.state.rateData.baseCurrency +
                                                                                            " " +
                                                                                            parseFloat(this.state.rateData &&
                                                                                                this.state.rateData.discountAmount15).toFixed(2)
                                                                                            : ""}
                                                                                        {this.state.creditDays === "30 Days"
                                                                                            ? this.state.rateData && this.state.rateData.baseCurrency +
                                                                                            " " +
                                                                                            parseFloat(this.state.rateData &&
                                                                                                this.state.rateData.discountAmount30).toFixed(2)
                                                                                            : ""}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                        {this.state.creditDays === "" ? (parseFloat(this.state.rateData && this.state.rateData.discountAmount) * (this.state.selectedCurrencyRate)).toFixed(2) : ""}
                                                                                        {this.state.creditDays === "15 Days" ? (parseFloat(this.state.rateData && this.state.rateData.discountAmount15) * (this.state.selectedCurrencyRate)).toFixed(2) : ""}
                                                                                        {this.state.creditDays === "30 Days" ? (parseFloat(this.state.rateData && this.state.rateData.discountAmount30) * (this.state.selectedCurrencyRate)).toFixed(2) : ""}
                                                                                    </>
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                    {this.state.rateData &&
                                                                    this.state.rateData.taxAmountB !== null ? (
                                                                        <tr>
                                                                            <th>
                                                                                <strong>GST</strong>
                                                                            </th>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td>
                                                                                {/*{(this.props.switchCurrency == true && !this.state.currencySelected ) ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                                                {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                                    <>
                                                                                        {this.state.creditDays === ""
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            this.state.rateData && this.state.rateData.taxAmountCDiscount !=
                                                                                            undefined
                                                                                                ? parseFloat(
                                                                                                    this.state.rateData && this.state.rateData.taxAmountCDiscount
                                                                                                ).toFixed(2)
                                                                                                : ""
                                                                                            : ""}
                                                                                        {this.state.creditDays === "15 Days"
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            this.state.rateData && this.state.rateData.taxAmountC15Discount !=
                                                                                            undefined
                                                                                                ? parseFloat(
                                                                                                    this.state.rateData && this.state.rateData.taxAmountC15Discount
                                                                                                ).toFixed(2)
                                                                                                : ""
                                                                                            : ""}
                                                                                        {this.state.creditDays === "30 Days"
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            this.state.rateData && this.state.rateData.taxAmountC30Discount !=
                                                                                            undefined
                                                                                                ? parseFloat(
                                                                                                    this.state.rateData && this.state.rateData.taxAmountC30Discount
                                                                                                ).toFixed(2)
                                                                                                : ""
                                                                                            : ""}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                        {this.state.creditDays === "" ? this.state.rateData && this.state.rateData.taxAmountBDiscount != undefined ? (parseFloat(this.state.rateData && this.state.rateData.taxAmountBDiscount) * this.state.selectedCurrencyRate).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "15 Days" ? this.state.rateData && this.state.rateData.taxAmountB15Discount != undefined ? (parseFloat(this.state.rateData && this.state.rateData.taxAmountB15Discount) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                                        {this.state.creditDays === "30 Days" ? this.state.rateData && this.state.rateData.taxAmountB30Discount != undefined ? (parseFloat(this.state.rateData && this.state.rateData.taxAmountB30Discount) * (this.state.selectedCurrencyRate)).toFixed(2) : "" : ""}
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    ) : undefined}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}

                                                            {this.state.coupanApplied ? (
                                                                <>
                                                                    <tr>
                                                                        <th>Net Payable Amount(After Discount)</th>
                                                                        <td>
                                                                            <strong />{" "}
                                                                        </td>
                                                                        <td>
                                                                            <strong />{" "}
                                                                        </td>
                                                                        <td>
                                                                            <strong>
                                                                                {(this.props.switchCurrency == true && !this.state.currencySelected ) ? (
                                                                                    <>
                                                                                        {this.state.creditDays === ""
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(this.state.rateData && this.state.rateData.amountCDiscount).toFixed(2)
                                                                                            : ""}
                                                                                        {this.state.creditDays === "15 Days"
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(this.state.rateData && this.state.rateData.amountC15Discount).toFixed(2)
                                                                                            : ""}
                                                                                        {this.state.creditDays === "30 Days"
                                                                                            ? this.state.rateData && this.state.rateData.customerCurrency +
                                                                                            " " +
                                                                                            parseFloat(this.state.rateData && this.state.rateData.amountC30Discount).toFixed(2)
                                                                                            : ""}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {this.state.currencySelected == "INR" ? (<FontAwesomeIcon icon={faRupeeSign} />) : this.state.currencySelected == "USD" ? (
                                                                                            <FontAwesomeIcon icon={faDollarSign} />) : this.state.currencySelected == "EUR" ? ( <FontAwesomeIcon icon={faEuroSign} />
                                                                                        ) : this.state.currencySelected == "GBP" ? (<FontAwesomeIcon icon={faPoundSign} />) : ("")}{" "}
                                                                                        {this.state.creditDays === "" ? (parseFloat(this.state.rateData && this.state.rateData.amountBDiscount) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                                        {this.state.creditDays === "15 Days" ? (parseFloat(this.state.rateData && this.state.rateData.amountB15Discount) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                                        {this.state.creditDays === "30 Days" ? (parseFloat(this.state.rateData && this.state.rateData.amountB30Discount) * this.state.selectedCurrencyRate).toFixed(2) : ""}
                                                                                    </>
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>{" "}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </table>

                                                    </div>
                                                    <div className="terms-width list-fare text-left">
                                                        <p className="mt-2 ">
                                                            <strong>TERMS & CONDITIONS</strong>
                                                        </p>
                                                        {queryData.queryFor !== "Ocean" ? (
                                                            <>
                                                                <ul className="text-left">
                                                                    <li>
                                                                        {" "}
                                                                        Rates are subject to space confirmation from the
                                                                        carrier.
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Rates are valid only for the current week or 7 days
                                                                        whichever is earlier.
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Rates are only valid for the shipment details shared in
                                                                        the query only, any changes will require rate
                                                                        revalidation.
                                                                    </li>
                                                                    <li> Rates are applicable for general cargo only.</li>
                                                                    <li>
                                                                        {" "}
                                                                        All inclusive (ALL-IN) rates quoted through instant rate
                                                                        quotes includes Air Freight, Fuel Surcharge, Security
                                                                        Surcharge, X-ray charges, Misc Charges, AMS charges. Any
                                                                        other charges must be considered as additional.
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        For any special cargo, the instant live rates are not
                                                                        applicable, and will be quoted separately.{" "}
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Transit time mentioned is purely indicative and should
                                                                        be considered as tentative.
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Government Taxes (if any) shall be applicable as per
                                                                        Government norms.
                                                                    </li>
                                                                </ul>

                                                                {this.state.rateData && this.state.rateData.groupNameRemarks.length === 0 ? (
                                                                        <>
                                                                            <p className="mt-2" style={{ marginBottom: "0rem" }}>
                                                                                <strong>Remarks</strong>
                                                                            </p>
                                                                            <ul className="text-left ">
                                                    
                                                    <li> {this.state.rateData && this.state.rateData.remarks !== "" ? this.state.rateData.remarks : ""}</li>
                                                </ul>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <p className="mt-2" style={{ marginBottom: "0rem" }}>
                                                                                <strong>Remarks</strong>
                                                                            </p>
                                                                            <ul className="text-left ">
                                                    {this.state.rateData && this.state.rateData.groupNameRemarks && this.state.rateData.groupNameRemarks.map((item,i)=>{
                                                        return(
                                                            <li> {item}</li>
                                                        );
                                                    })
                                                    }
                                                </ul>
                                                                        </>
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ul className="text-left">
                                                                    <li>
                                                                        {" "}
                                                                        Booking requests are strictly subject to space and
                                                                        equipment availability and confirmation.
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Instant rates quoted are applicable for general
                                                                        commodities only, with no special handling requirements.
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Rates are valid for the shipment details shared in the
                                                                        query.
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Shipping Line surcharges are subject to change, unless
                                                                        fixed within the quotation validity.
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        For any special cargo, the instant live rates are not
                                                                        applicable. The price quotes shall be provided within
                                                                        48-72 hours during the carriers’ working days.{" "}
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Transit time mentioned are indicative based on the
                                                                        carriers’ information and should be considered as
                                                                        tentative.{" "}
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        Government Taxes (if any) shall be applicable as per
                                                                        Government norms.
                                                                    </li>
                                                                </ul>

                                                                {this.state.rateData && this.state.rateData.groupNameRemarks.length === 0  ? (
                                                                        <>
                                                                            <p className="mt-2" style={{ marginBottom: "0rem" }}>
                                                                                <strong>Remarks</strong>
                                                                            </p>
                                                                            <ul className="text-left ">
                                                    
                                                    <li> { this.state.rateData && this.state.rateData.remarks !== "" ? this.state.rateData.remarks : ""}</li>
                                                </ul>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <p className="mt-2" style={{ marginBottom: "0rem" }}>
                                                                                <strong>Remarks</strong>
                                                                            </p>
                                                                            <ul className="text-left ">
                                                    {this.state.rateData && this.state.rateData.groupNameRemarks && this.state.rateData.groupNameRemarks.map((item,i)=>{
                                                        return(
                                                            <li> {item}</li>
                                                        );
                                                    })
                                                    }
                                                </ul>
                                                                        </>
                                                                    )}
                                                            </>
                                                        )}
                                                        {/* {obj.vesselSchedules.length > 0 ?
                                                            <div className="pl-3 pb-2">
                                                             <p className="mt-2 pl-1 mb-1"><strong>Vessel Schedule</strong></p>
                                                            <div className="table-responsive table-vessles">
                                                                <table
                                                                    className="table table-bordered table-striped table-hover table-sticky">
                                                                    <thead style={{background: '#ff7900', color: '#fff'}}>
                                                                    <tr>

                                                                        <th className="text-center">Vessel</th>
                                                                        <th className="text-center">Voyage No</th>
                                                                        <th className="text-center">Departure Date</th>
                                                                        <th className="text-center">Arrival Date</th>
                                                                        <th className="text-center">Berthing Date</th>
                                                                        <th className="text-center">Action</th>

                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        obj.vesselSchedules && obj.vesselSchedules.map((item,i)=>{
                                                                            return(
                                                                                <tr>
                                                                                    <td>{item.vessel}</td>
                                                                                    <td>{item.voyageNo}</td>
                                                                                    <td>{item.departure ? convertDateISO(item.departure) : ""}</td>
                                                                                    <td>{item.arrival ? convertDateISO(item.arrival) : ""}</td>
                                                                                    <td>{item.bearthing ? convertDateISO(item.bearthing) : ""}</td>
                                                                                    {this.checkIdAvailable(item._id)==true
                                                                                        ?<td><button type="button"
                                                                                                     className="border-cir delete"
                                                                                                     onClick={e => {
                                                                                                     }}>Mapped In Planning</button></td>
                                                                                        :<td><button type="button"
                                                                                                     className="border-cir delete"
                                                                                                     onClick={e => {
                                                                                                         this.sendToIntendedTransportPlan(item)
                                                                                                     }}>Update Plan</button></td>}
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                            :""} */}
                                                    </div>
                                                </div>
                                                                    </div>
                                            :""}
                                        </>
                                    );
                                })
                                : ""}
                        </div>

                        <div className={this.state.selectedFreightId ? "name_company justify-content-start":"name_company"}>
                            <h5>{obj && obj.charges && obj.charges[0].serviceProviderName?obj.charges[0].serviceProviderName: ""}</h5>
                            {this.props.bookButtonHide ? "" :
                            <>
                            {queryData && queryData.quoteStatus === "Rates Quoted" ?
                            <button className={this.state.rateIdSelected!==""?"btn company_btn":"btn company_btn cursorNOtAllowed"} onClick={(e)=>{
                                this.getrateData({ id: this.state.rateIdSelected , quoteFor : this.state.queryData.queryFor }).then(
                                    (responce) => {
                                        if (responce && responce.success) {
                                            let rateData =
                                                responce && Array.isArray(responce.result) && responce.result.length >= 1 ? responce.result[0] : "";
                                            this.setState(
                                                {
                                                    rateDataSelected: rateData,
                                                },()=>{
                                                    this.props.ratesSelect(this.state.rateDataSelected);
                                                }
                                            );
                                        }
                                    }
                                );
                            }}>Book</button>:""}
                            </>}
                            {/*<button*/}
                            {/*    className="btn company_btn"*/}
                            {/*    onClick={(e) => {*/}
                            {/*        e.preventDefault();*/}
                            {/*        let value = this.custDetails();*/}
                            {/*        let typeOfCustomer =*/}
                            {/*            value &&*/}
                            {/*            value.typeOfCust &&*/}
                            {/*            value.typeOfCust.result &&*/}
                            {/*            value.typeOfCust.result.customerData &&*/}
                            {/*            value.typeOfCust.result.customerData*/}
                            {/*                .typeOfBussiness*/}
                            {/*                ? "value.typeOfCust.result.customerData.typeOfBussiness"*/}
                            {/*                : "Bussiness";*/}
                            {/*        if (this.checkUser()) {*/}
                            {/*            if (value.custKycStatus) {*/}
                            {/*                // this.awaitPopupOpen(data._id)*/}
                            {/*                this.props.login(true);*/}
                            {/*            } else {*/}
                            {/*                if (typeOfCustomer == "Bussiness") {*/}
                            {/*                    history.push(PAGE_PATHS.CUSTOMER_KYC);*/}
                            {/*                } else {*/}
                            {/*                    history.push(*/}
                            {/*                        PAGE_PATHS.CUSTOMER_KYC_FOR_INDIVIDUAL*/}
                            {/*                    );*/}
                            {/*                }*/}
                            {/*                window.location.reload("false");*/}
                            {/*            }*/}
                            {/*        } else {*/}
                            {/*            this.props.isUserLoggedIn(true);*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Select*/}
                            {/*</button>*/}
                        </div>

                    </div>
                    {/*spot_coupon_rates*/}
                    <div className="">
                        {/*<div className="switch_currency">*/}
                        {/*    <div className="form-check">*/}
                        {/*        <input type="checkbox" className="form-check-input" name="switchCurrency" checked={this.props.switchCurrency==true ? true : false}*/}
                        {/*               onChange={(e) => {this.switchCurrency(e)}}*/}
                        {/*               id=""/>*/}
                        {/*        <label className="form-check-label" htmlFor="">Switch to own Currency</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*{queryData.quoteStatus==="Rates Quoted"?*/}
                        {/*    <div className="switch_currency need_credit_query">*/}
                        {/*        <div className="form-check">*/}
                        {/*            <input type="checkbox" className="form-check-input" onClick={this.creditDaysSTatusFunc}/>*/}
                        {/*            <label className="form-check-label" >Need Credit</label>*/}
                        {/*        </div>*/}
                        {/*        {this.state.creditDaysSTatus === true ?*/}
                        {/*            <select className="" name="creditDays" onChange={this.switchNeedCredit} value={this.state.creditDays}>*/}
                        {/*                <option value={""}>No Credit</option>*/}
                        {/*                <option value={"15 Days"}>15 Days</option>*/}
                        {/*                <option value={"30 Days"}>30 Days</option>*/}
                        {/*            </select>*/}
                        {/*            :""}*/}
                        {/*    </div>*/}
                        {/*    :*/}
                        {/*    <>*/}
                        {/*        {this.state.creditDays !== "" ?*/}
                        {/*            <div className="coupon_apply_pop">{this.state.creditDays} Credit</div>*/}
                        {/*            :""}*/}
                        {/*    </>}*/}

                        {/*{obj.coupanApply ?*/}
                        {/*    <>*/}
                        {/*        <div className="remove_btn_with_value d-flex flex-wrap align-items-stretch mr-2">*/}
                        {/*            <h6 className="coupon_apply_pop">{obj.coupanName}</h6>*/}
                        {/*            {queryData.quoteStatus==="Rates Quoted"?*/}
                        {/*                <div className="cross_apply_coupan" onClick={e=>{*/}
                        {/*                    e.preventDefault()*/}
                        {/*                    this.removeCoupan().then(res=>{*/}
                        {/*                        if(res.success){*/}
                        {/*                            toast.success("Coupon Removed.", {*/}
                        {/*                                position: "top-left",*/}
                        {/*                                autoClose: 3000,*/}
                        {/*                                hideProgressBar: false,*/}
                        {/*                                closeOnClick: true,*/}
                        {/*                                pauseOnHover: true,*/}
                        {/*                                draggable: true,*/}
                        {/*                                progress: undefined,*/}
                        {/*                            });*/}
                        {/*                            window.location.reload("false")*/}
                        {/*                        }*/}
                        {/*                    })*/}
                        {/*                }}><i className="fas fa-times"></i>*/}
                        {/*                </div>:""}*/}
                        {/*        </div>*/}

                        {/*    </> :*/}
                        {/*    <>*/}
                        {/*        {queryData.quoteStatus==="Rates Quoted"?*/}
                        {/*            <div className="app_couply" onClick={e=>{*/}
                        {/*                e.preventDefault()*/}
                        {/*                this.setState({payLarge : true})*/}
                        {/*            }}>*/}
                        {/*                Apply Coupon*/}
                        {/*            </div>*/}

                        {/*            :""}*/}
                        {/*    </>*/}
                        {/*}*/}
                        {/*<div className="app_couply" onClick={e => {*/}
                        {/*    e.preventDefault()*/}
                        {/*    this.setState({showCollaps: !this.state.showCollaps})*/}
                        {/*}}>Freight Summery</div>*/}
                        {/*{ queryData.queryFor === "Air" ?*/}
                        {/*<div className="app_couply" onClick={e => {*/}
                        {/*    e.preventDefault()*/}
                        {/*    this.handleClickForSlab()*/}
                        {/*    this.setState({isSlabRateOpen: !this.state.isSlabRateOpen})*/}
                        {/*}}>Slab</div>:""}*/}
                    </div>
                    {/* <SlabRatePoup
                        BookingStatus={queryData.quoteStatus}
                        modalLarge={this.state.isSlabRateOpen}
                        ODPData={this.state.ODPData}
                        chargeableWeightForSlab={queryData.chargeableWeight}
                        setmodal={(value) => {
                            if (value === false) {
                                this.setState({
                                    isSlabRateOpen: false,
                                });
                            }
                        }}
                    />
                    <SlabRatePopupForOcean
                        modalLarge1={this.state.isSlabRateOpenForOcean}
                        ODPDataForOcean={this.state.ODPDataForOcean}
                        setmodal={(value) => {
                            if (value === false) {
                                this.setState({
                                    isSlabRateOpenForOcean: false,
                                });
                            }
                        }}
                    /> */}
                    <div id="collapseOne2" className={this.state.showCollapsForRate === true ? "collapse border-top show" : "collapse border-top"} role="tabpanel" aria-labelledby="headingOne2" data-parent="#accordionEx">
                        <div className="d-flex flex-wrap justify-content-between padding-15">
                            <div className="list-fare pt-3" style={{ width: "100%" }}>
                                <p className="mb-3">
                                    <strong>All Slabs Rate</strong>
                                </p>
                                <table className="table table-bordered text-center">
                                    <tr style={{ background: "#eaeaea" }}>
                                        <th>SLABS</th>
                                        <th>MIN</th>
                                        <th>NORMAL</th>
                                        <th>+45</th>
                                        <th>+100</th>
                                        <th>+250</th>
                                        <th>+300</th>
                                        <th>+500</th>
                                        <th>+1000</th>
                                        <th>VALIDITY</th>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle" }}>
                                            {data.rawData && data.rawData.currency
                                                ? data.rawData.currency
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "min"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_min
                                                ? data.rawData.w_min
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" &&
                                                SelectedWightSlab === "normal"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_normal
                                                ? data.rawData.w_normal
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "45"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_45
                                                ? data.rawData.w_45
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "100"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_100
                                                ? data.rawData.w_100
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "250"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_250
                                                ? data.rawData.w_250
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "300"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_300
                                                ? data.rawData.w_300
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "500"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_500
                                                ? data.rawData.w_500
                                                : "-"}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle" }}
                                            className={
                                                SelectedWightSlab !== "" && SelectedWightSlab === "1000"
                                                    ? "chnageBack"
                                                    : undefined
                                            }
                                        >
                                            {data.rawData && data.rawData.w_1000
                                                ? data.rawData.w_1000
                                                : "-"}
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                            {data.rawData && data.rawData.validityEndDate
                                                ? convertDateISOToCustomiseFormat(
                                                    data.rawData.validityEndDate
                                                )
                                                : "-"}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }

}


export default PreviousCourierShipmentHistoryRateCard;
