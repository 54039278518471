import React, {Component} from "react";
import AppLayout from "../../layout/AppLayout";
import {Progress, Input, Label, FormGroup, ModalHeader, Modal, ModalBody, Button, CustomInput, Col} from 'reactstrap';
import UploadFilesCloudes from "../uploadFilesCloudes";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faChevronDown,
    faChevronUp,
    faDollarSign,
    faPlane,
    faPlus
} from "@fortawesome/free-solid-svg-icons";

class NewRatesScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openTrack: true,
            rateQuotedTrack: false,
            bookingTrack: false,
            airwayBillTrack: false,
            invoiceTrack: false,
            zipaSuggestion: false,
            suggestNotAvailble: false,
            shipperModal: false,
            shipperModalSecond: false,
            shipperModalThird: false,
            tab1: false,
            count: 1,
        }
    }
    Prev = (value) => {
        switch (value) {
            case 2:
                this.setState({
                    tab1: true,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 3:
                this.setState({
                    tab1: false,
                    tab2: true,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: true,
                    tab4: false,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1,
                });
                break;
            case 5:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: true,
                    tab5: false,
                    // tab6: false,
                    count: this.state.count - 1
                })
                break;
            // case 6:
            //     this.setState({
            //         tab1: false,
            //         tab2: false,
            //         tab3: false,
            //         tab4: false,
            //         tab5: true,
            //         tab6: false,
            //         count: this.state.count - 1
            //     })
            //     break;
            default:
        }
    };
    Next = (value) => {
        switch (value) {
            case 1:
                // if (this.validatorTab1.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: true,
                        tab3: false,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                // } else {
                //     this.validatorTab1.showMessages();
                //     this.forceUpdate();
                // }
                break;
            case 2:
                // if (this.validatorTab2.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: true,
                        tab4: false,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                // } else {
                //     this.validatorTab2.showMessages();
                //     this.forceUpdate();
                // }
                break;
            case 3:
                // if (this.validatorTab3.allValid()) {
                    this.setState({
                        tab1: false,
                        tab2: false,
                        tab3: false,
                        tab4: true,
                        tab5: false,
                        // tab6: false,
                        count: this.state.count + 1,
                    });
                // }else{
                //     this.validatorTab3.showMessages();
                //     this.forceUpdate();
                // }
                break;
            case 4:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: true,
                    // tab6: false,
                    count: this.state.count + 1,
                });
                break;
            case 5:
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    tab5: false,
                    // tab6: true,
                    count: this.state.count + 1,
                });
                break;
            case 6:
                // if (this.validatorTab4.allValid()) {
                this.setState({
                    tab1: false,
                    tab2: false,
                    tab3: false,
                    tab4: false,
                    // tab5: false,
                    // tab6: true,
                    count: this.state.count + 1,
                });
                // } else {
                //     this.validatorTab4.showMessages();
                //     this.forceUpdate();
                // }
                break;
            default:
        }
    };

    render() {


        return (
            <>
                <Modal
                    isOpen={this.state.shipperModal}
                    size="lg"
                    className="ships-model shipper_con_pop"
                    toggle={()=>{this.setState({shipperModal : !this.state.shipperModal})}}>
                    <ModalHeader toggle={()=>{this.setState({shipperModal : !this.state.shipperModal})}} className="justify-content-center p-0"></ModalHeader>
                    <ModalBody>
                            <div className="shipper_con_pop design_new_shipper">
                                {this.state.tab1 || this.state.tab2 ?
                                <div className="row shipperDetails">
                                    {this.state.tab1 && (
                                        <>
                                        <div className="col-lg-12">
                                            <h4>Shipper Details</h4>
                                        </div>
                                        <div className="col-lg-12 AAA-edit-awb-data-4">
                                            <div className="row">
                                                <div className="shippers-popup new-ships col-sm-12 col-md-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group"><label className="">Shipper
                                                                Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                                <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Shipper Name"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group"><label className="">Shipper
                                                                Branch </label>
                                                                <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Shipper Branch"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group"><label className="">Shipper
                                                                Address</label>
                                                                <textarea className="form-control" type="text" name="shipperDetails" rows="2"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    )}
                                    {this.state.tab2 && (
                                        <>
                                            <div className="col-lg-12">
                                                <h4>Consignee Details</h4>
                                            </div>
                                            <div className="col-lg-12 AAA-edit-awb-data-4">
                                                <div className="row">
                                                    <div className="shippers-popup new-ships col-sm-12 col-md-12">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="form-group"><label className="">Consignee
                                                                    Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                                    <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Consignee Name"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group"><label className="">Consignee
                                                                    Branch </label>
                                                                    <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Consignee Branch"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group"><label className="">Shipper
                                                                    Address</label>
                                                                    <textarea className="form-control" type="text" name="shipperDetails" rows="2"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                    :""}
                                {this.state.tab3 && (
                                    <div className="row shipperDetails shipperDetails2">
                                        <div className="col-lg-12">
                                            <h4>Query Details</h4>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="d-flex flex-wrap align-items-center dimen_radius_width">
                                                <div className="width_dim_20">
                                                    <div className="form-group"><label htmlFor="accountType" className="">Account
                                                        Type <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label><select
                                                        name="accountType" className="mycustom1 custom-select"
                                                        id="accountType">
                                                        <option>Select Account Type</option>
                                                        <option value="Prepaid">Prepaid</option>
                                                        <option value="Collect">Collect</option>
                                                    </select><span className="text-danger"></span></div>
                                                </div>
                                                <div className="width_dim_20">
                                                    <div className="incoInput form-group">
                                                        <label htmlFor="accountType" className="">Select Incoterms <sup
                                                            style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                        <input id="shipperName" name="NoOfContainer"
                                                               disabled="" type="number"
                                                               className="form-control" value="incoterms"/>
                                                        <span className="text-danger"></span></div>
                                                </div>
                                                <div className="width_dim_20">
                                                    <div className="form-group"><label htmlFor="shipperName" className="">No.
                                                        Of Container</label><input id="shipperName" name="NoOfContainer"
                                                                                   disabled="" type="number"
                                                                                   className="form-control" value="2"/></div>
                                                </div>
                                                <div className="width_dim_20">
                                                    <div className="form-group"><label htmlFor="shipperName" className="">G.
                                                        Wt.(KGS)</label><input id="totalGross" readOnly="" name="totalGross"
                                                                               type="number" className="form-control"
                                                                               value="34"/></div>
                                                </div>
                                                <div className="width_dim_20">
                                                    <div className="form-group"><label htmlFor="shipperName" className="">Vol.
                                                        Wt. (CBM)</label><input id="totalVolume" name="totalVolume"
                                                                                readOnly="" type="number"
                                                                                className="form-control" value="25"/></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group text-right">
                                                    <button className="btn-add-dimen"><FontAwesomeIcon icon={faPlus} size="lg"/> Add
                                                        Dimension
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.tab4 && (
                                    <div className="packaging_commercial packaging_commercials">
                                        <div className="col-lg-12">
                                            <h4>Packing/Commercial Details</h4>
                                        </div>
                                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                                            <div className="upload-data">
                                                <label htmlFor="upload commercial invoice" className="origin-label">Upload Commercial Invoice</label>
                                                <div className="d-flex flex-wrap">
                                                    <UploadFilesCloudes
                                                        name={this.state.commercialInvoiceUrl}
                                                        fromEdocket={false}
                                                        placeHolder="Upload Files"
                                                        isSingleUpload={true}
                                                        isUploadingFiles={isUploadingFiles => {
                                                            this.setState({
                                                                isUploadingFiles: isUploadingFiles
                                                            })
                                                        }}
                                                        onUploadFinish={(name, uploadedUrls) => {

                                                            if (uploadedUrls != undefined && uploadedUrls != "") {
                                                                this.setState({
                                                                    uploadObj:{name:"Commercial Invoice",link:uploadedUrls},
                                                                    commercialInvoiceUrl:uploadedUrls,
                                                                })
                                                            } else {
                                                                toast.error("Please Select File", {
                                                                    position: "top-left",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                            }
                                                        }}

                                                        returnResponce={responce => {
                                                            this.setState({
                                                                returnResponce: responce
                                                            }, () => {

                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="upload-data">
                                                <label htmlFor="address" className="origin-label">Upload Packing Details</label>
                                                <div className="d-flex flex-wrap">
                                                    <UploadFilesCloudes
                                                        name={this.state.packingListUrl}
                                                        fromEdocket={false}
                                                        placeHolder="Upload Files"
                                                        isSingleUpload={true}
                                                        isUploadingFiles={isUploadingFiles => {
                                                            this.setState({
                                                                isUploadingFiles: isUploadingFiles
                                                            })
                                                        }}
                                                        onUploadFinish={(name, uploadedUrls) => {

                                                            if (uploadedUrls != undefined && uploadedUrls != "") {
                                                                this.setState({
                                                                    uploadObj:{name:"Packing List",link:uploadedUrls},
                                                                    packingListUrl:uploadedUrls,
                                                                })
                                                            } else {
                                                                toast.error("Please Select File", {
                                                                    position: "top-left",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                            }
                                                        }}

                                                        returnResponce={responce => {
                                                            this.setState({
                                                                returnResponce: responce
                                                            }, () => {

                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                {this.state.tab5 && (
                                    <div className="houseDetails">
                                        <div>
                                            <div className="col-lg-12">
                                                <h4>House Details</h4>
                                            </div>
                                            <Col lg="12">
                                                <div className="AAA-editbooking-console-shipment-3 pb-2">
                                                    <FormGroup check>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id="isConsole"
                                                            checked={this.state.checked}
                                                            onClick={this.handleChecked}
                                                            label="HAWB Required"
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>)}
                            </div>
                    </ModalBody>
                    <div className="justify-content-center modal-footer">
                        <div className="">
                            <div className="d-flex">
                                {Number(this.state.count) > 1 && (
                                    <Button className="edit rounded-circles" type="button"
                                            onClick={() => this.Prev(this.state.count)}>
                                        Previous
                                    </Button>
                                )}
                                {Number(this.state.count) < 5 ? (
                                    <Button className="edit rounded-circles" type="button"
                                            onClick={() => this.Next(this.state.count)}>
                                        Next
                                    </Button>):""}
                                {Number(this.state.count) == 5 ? (
                                    <Button className="delete rounded-circles" type="button">
                                        Submit
                                    </Button>):""}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.shipperModalSecond}
                    size="lg"
                    className="ships-model shipper_con_pop"
                    toggle={()=>{this.setState({shipperModalSecond : !this.state.shipperModalSecond})}}>
                    <ModalHeader toggle={()=>{this.setState({shipperModalSecond : !this.state.shipperModalSecond})}} className="justify-content-center">
                        <div className="rates_fright"><h3>Create Booking</h3></div>
                    </ModalHeader>
                    <ModalBody className={this.state.tab1 ? "house_scroll_check":""}>
                        <div className="shipper_con_pop design_new_shipper">
                            <div className="booking_list" onClick={()=>{this.setState({tab1:!this.state.tab1})}}>
                                <h5 className="heading-title">1. Shipper <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faPlus} size="lg"/></div>
                            </div>
                            {this.state.tab1 && (
                                <div className="row shipperDetails">
                                    <div className="col-lg-12 AAA-edit-awb-data-4">
                                        <div className="row">
                                            <div className="shippers-popup new-ships col-sm-12 col-md-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Shipper
                                                            Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                            <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Shipper Name"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Shipper
                                                            Branch </label>
                                                            <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Shipper Branch"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Shipper
                                                            Address</label>
                                                            <textarea className="form-control" type="text" name="shipperDetails" rows="3"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="booking_list">
                                <h5 className="heading-title">2. Consignee <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faPlus} size="lg"/></div>
                            </div>
                            {this.state.tab2 && (
                                <div className="row shipperDetails">
                                    <div className="col-lg-12 AAA-edit-awb-data-4">
                                        <div className="row">
                                            <div className="shippers-popup new-ships col-sm-12 col-md-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Consignee
                                                            Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                            <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Consignee Name"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Consignee
                                                            Branch </label>
                                                            <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Consignee Branch"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Shipper
                                                            Address</label>
                                                            <textarea className="form-control" type="text" name="shipperDetails" rows="3"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="booking_list">
                                <h5 className="heading-title">3. Query Details <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faPlus} size="lg"/></div>
                            </div>
                            <div className="booking_list">
                                <h5 className="heading-title">4. Packing/Commercial Details <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faPlus} size="lg"/></div>
                            </div>
                            <div className="booking_list">
                                <h5 className="heading-title">5. House Details <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faPlus} size="lg"/></div>
                            </div>

                            {this.state.tab3 && (
                                <div className="row shipperDetails shipperDetails2">
                                    <div className="col-lg-12">
                                        <h4>Query Details</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="d-flex flex-wrap align-items-center dimen_radius_width">
                                            <div className="width_dim_20">
                                                <div className="form-group"><label htmlFor="accountType" className="">Account
                                                    Type <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label><select
                                                    name="accountType" className="mycustom1 custom-select"
                                                    id="accountType">
                                                    <option>Select Account Type</option>
                                                    <option value="Prepaid">Prepaid</option>
                                                    <option value="Collect">Collect</option>
                                                </select><span className="text-danger"></span></div>
                                            </div>
                                            <div className="width_dim_20">
                                                <div className="incoInput form-group">
                                                    <label htmlFor="accountType" className="">Select Incoterms <sup
                                                        style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                    <input id="shipperName" name="NoOfContainer"
                                                           disabled="" type="number"
                                                           className="form-control" value="incoterms"/>
                                                    <span className="text-danger"></span></div>
                                            </div>
                                            <div className="width_dim_20">
                                                <div className="form-group"><label htmlFor="shipperName" className="">No.
                                                    Of Container</label><input id="shipperName" name="NoOfContainer"
                                                                               disabled="" type="number"
                                                                               className="form-control" value="2"/></div>
                                            </div>
                                            <div className="width_dim_20">
                                                <div className="form-group"><label htmlFor="shipperName" className="">G.
                                                    Wt.(KGS)</label><input id="totalGross" readOnly="" name="totalGross"
                                                                           type="number" className="form-control"
                                                                           value="34"/></div>
                                            </div>
                                            <div className="width_dim_20">
                                                <div className="form-group"><label htmlFor="shipperName" className="">Vol.
                                                    Wt. (CBM)</label><input id="totalVolume" name="totalVolume"
                                                                            readOnly="" type="number"
                                                                            className="form-control" value="25"/></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group text-right">
                                                <button className="btn-add-dimen"><FontAwesomeIcon icon={faPlus} size="lg"/> Add
                                                    Dimension
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.tab4 && (
                                <div className="packaging_commercial packaging_commercials">
                                    <div className="col-lg-12">
                                        <h4>Packing/Commercial Details</h4>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                                        <div className="upload-data">
                                            <label htmlFor="upload commercial invoice" className="origin-label">Upload Commercial Invoice</label>
                                            <div className="d-flex flex-wrap">
                                                <UploadFilesCloudes
                                                    name={this.state.commercialInvoiceUrl}
                                                    fromEdocket={false}
                                                    placeHolder="Upload Files"
                                                    isSingleUpload={true}
                                                    isUploadingFiles={isUploadingFiles => {
                                                        this.setState({
                                                            isUploadingFiles: isUploadingFiles
                                                        })
                                                    }}
                                                    onUploadFinish={(name, uploadedUrls) => {

                                                        if (uploadedUrls != undefined && uploadedUrls != "") {
                                                            this.setState({
                                                                uploadObj:{name:"Commercial Invoice",link:uploadedUrls},
                                                                commercialInvoiceUrl:uploadedUrls,
                                                            })
                                                        } else {
                                                            toast.error("Please Select File", {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        }
                                                    }}

                                                    returnResponce={responce => {
                                                        this.setState({
                                                            returnResponce: responce
                                                        }, () => {

                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="upload-data">
                                            <label htmlFor="address" className="origin-label">Upload Packing Details</label>
                                            <div className="d-flex flex-wrap">
                                                <UploadFilesCloudes
                                                    name={this.state.packingListUrl}
                                                    fromEdocket={false}
                                                    placeHolder="Upload Files"
                                                    isSingleUpload={true}
                                                    isUploadingFiles={isUploadingFiles => {
                                                        this.setState({
                                                            isUploadingFiles: isUploadingFiles
                                                        })
                                                    }}
                                                    onUploadFinish={(name, uploadedUrls) => {

                                                        if (uploadedUrls != undefined && uploadedUrls != "") {
                                                            this.setState({
                                                                uploadObj:{name:"Packing List",link:uploadedUrls},
                                                                packingListUrl:uploadedUrls,
                                                            })
                                                        } else {
                                                            toast.error("Please Select File", {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        }
                                                    }}

                                                    returnResponce={responce => {
                                                        this.setState({
                                                            returnResponce: responce
                                                        }, () => {

                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            {this.state.tab5 && (
                                <div className="houseDetails">
                                    <div>
                                        <div className="col-lg-12">
                                            <h4>House Details</h4>
                                        </div>
                                        <Col lg="12">
                                            <div className="AAA-editbooking-console-shipment-3 pb-2">
                                                <FormGroup check>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id="isConsole"
                                                        checked={this.state.checked}
                                                        onClick={this.handleChecked}
                                                        label="HAWB Required"
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </div>
                                </div>)}
                        </div>
                    </ModalBody>
                    <div className="justify-content-center modal-footer">
                        <div className="">
                            <div className="d-flex">
                                {Number(this.state.count) > 1 && (
                                    <Button className="edit rounded-circles" type="button"
                                            onClick={() => this.Prev(this.state.count)}>
                                        Previous
                                    </Button>
                                )}
                                {Number(this.state.count) < 5 ? (
                                    <Button className="edit rounded-circles" type="button"
                                            onClick={() => this.Next(this.state.count)}>
                                        Next
                                    </Button>):""}
                                {Number(this.state.count) == 5 ? (
                                    <Button className="delete rounded-circles" type="button">
                                        Submit
                                    </Button>):""}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.shipperModalThird}
                    size="lg"
                    className="ships-model shipper_con_pop"
                    toggle={()=>{this.setState({shipperModalThird : !this.state.shipperModalThird})}}>
                    <ModalHeader toggle={()=>{this.setState({shipperModalThird : !this.state.shipperModalThird})}} className="justify-content-center">
                        <div className="rates_fright"><h3>Create Booking</h3></div>
                    </ModalHeader>
                    <ModalBody className={this.state.tab1 ? "house_scroll_check":""}>
                        <div className="shipper_con_pop design_new_shipper">
                            <div className="booking_list_other" onClick={()=>{this.setState({tab1:!this.state.tab1})}}>
                                <h5 className="heading-title">1. Shipper <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow">{this.state.tab1?<FontAwesomeIcon icon={faChevronUp} size="lg"/>:<FontAwesomeIcon icon={faChevronDown} size="lg"/>}</div>
                            </div>
                            {this.state.tab1 && (
                                <div className="row shipperDetails">
                                    <div className="col-lg-12 AAA-edit-awb-data-4">
                                        <div className="row">
                                            <div className="shippers-popup new-ships col-sm-12 col-md-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Shipper
                                                            Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                            <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Shipper Name"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Shipper
                                                            Branch </label>
                                                            <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Shipper Branch"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Shipper
                                                            Address</label>
                                                            <textarea className="form-control" type="text" name="shipperDetails" rows="3"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="booking_list_other">
                                <h5 className="heading-title">2. Consignee <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faChevronDown} size="lg"/></div>
                            </div>
                            {this.state.tab2 && (
                                <div className="row shipperDetails">
                                    <div className="col-lg-12 AAA-edit-awb-data-4">
                                        <div className="row">
                                            <div className="shippers-popup new-ships col-sm-12 col-md-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Consignee
                                                            Name <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                            <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Consignee Name"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Consignee
                                                            Branch </label>
                                                            <input id="shipperName" name="shipperName" type="text" className="form-control" placeholder="Consignee Branch"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group"><label className="">Shipper
                                                            Address</label>
                                                            <textarea className="form-control" type="text" name="shipperDetails" rows="3"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="booking_list_other">
                                <h5 className="heading-title">3. Query Details <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faChevronDown} size="lg"/></div>
                            </div>
                            <div className="booking_list_other">
                                <h5 className="heading-title">4. Packing/Commercial Details <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faChevronDown} size="lg"/></div>
                            </div>
                            <div className="booking_list_other">
                                <h5 className="heading-title">5. House Details <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></h5>
                                <div className="icon_arrow"><FontAwesomeIcon icon={faChevronDown} size="lg"/></div>
                            </div>

                            {this.state.tab3 && (
                                <div className="row shipperDetails shipperDetails2">
                                    <div className="col-lg-12">
                                        <h4>Query Details</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="d-flex flex-wrap align-items-center dimen_radius_width">
                                            <div className="width_dim_20">
                                                <div className="form-group"><label htmlFor="accountType" className="">Account
                                                    Type <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label><select
                                                    name="accountType" className="mycustom1 custom-select"
                                                    id="accountType">
                                                    <option>Select Account Type</option>
                                                    <option value="Prepaid">Prepaid</option>
                                                    <option value="Collect">Collect</option>
                                                </select><span className="text-danger"></span></div>
                                            </div>
                                            <div className="width_dim_20">
                                                <div className="incoInput form-group">
                                                    <label htmlFor="accountType" className="">Select Incoterms <sup
                                                        style={{fontSize: '14px', fontWeight: '700'}}>*</sup></label>
                                                    <input id="shipperName" name="NoOfContainer"
                                                           disabled="" type="number"
                                                           className="form-control" value="incoterms"/>
                                                    <span className="text-danger"></span></div>
                                            </div>
                                            <div className="width_dim_20">
                                                <div className="form-group"><label htmlFor="shipperName" className="">No.
                                                    Of Container</label><input id="shipperName" name="NoOfContainer"
                                                                               disabled="" type="number"
                                                                               className="form-control" value="2"/></div>
                                            </div>
                                            <div className="width_dim_20">
                                                <div className="form-group"><label htmlFor="shipperName" className="">G.
                                                    Wt.(KGS)</label><input id="totalGross" readOnly="" name="totalGross"
                                                                           type="number" className="form-control"
                                                                           value="34"/></div>
                                            </div>
                                            <div className="width_dim_20">
                                                <div className="form-group"><label htmlFor="shipperName" className="">Vol.
                                                    Wt. (CBM)</label><input id="totalVolume" name="totalVolume"
                                                                            readOnly="" type="number"
                                                                            className="form-control" value="25"/></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group text-right">
                                                <button className="btn-add-dimen"><FontAwesomeIcon icon={faPlus} size="lg"/> Add
                                                    Dimension
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.tab4 && (
                                <div className="packaging_commercial packaging_commercials">
                                    <div className="col-lg-12">
                                        <h4>Packing/Commercial Details</h4>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                                        <div className="upload-data">
                                            <label htmlFor="upload commercial invoice" className="origin-label">Upload Commercial Invoice</label>
                                            <div className="d-flex flex-wrap">
                                                <UploadFilesCloudes
                                                    name={this.state.commercialInvoiceUrl}
                                                    fromEdocket={false}
                                                    placeHolder="Upload Files"
                                                    isSingleUpload={true}
                                                    isUploadingFiles={isUploadingFiles => {
                                                        this.setState({
                                                            isUploadingFiles: isUploadingFiles
                                                        })
                                                    }}
                                                    onUploadFinish={(name, uploadedUrls) => {

                                                        if (uploadedUrls != undefined && uploadedUrls != "") {
                                                            this.setState({
                                                                uploadObj:{name:"Commercial Invoice",link:uploadedUrls},
                                                                commercialInvoiceUrl:uploadedUrls,
                                                            })
                                                        } else {
                                                            toast.error("Please Select File", {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        }
                                                    }}

                                                    returnResponce={responce => {
                                                        this.setState({
                                                            returnResponce: responce
                                                        }, () => {

                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="upload-data">
                                            <label htmlFor="address" className="origin-label">Upload Packing Details</label>
                                            <div className="d-flex flex-wrap">
                                                <UploadFilesCloudes
                                                    name={this.state.packingListUrl}
                                                    fromEdocket={false}
                                                    placeHolder="Upload Files"
                                                    isSingleUpload={true}
                                                    isUploadingFiles={isUploadingFiles => {
                                                        this.setState({
                                                            isUploadingFiles: isUploadingFiles
                                                        })
                                                    }}
                                                    onUploadFinish={(name, uploadedUrls) => {

                                                        if (uploadedUrls != undefined && uploadedUrls != "") {
                                                            this.setState({
                                                                uploadObj:{name:"Packing List",link:uploadedUrls},
                                                                packingListUrl:uploadedUrls,
                                                            })
                                                        } else {
                                                            toast.error("Please Select File", {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        }
                                                    }}

                                                    returnResponce={responce => {
                                                        this.setState({
                                                            returnResponce: responce
                                                        }, () => {

                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            {this.state.tab5 && (
                                <div className="houseDetails">
                                    <div>
                                        <div className="col-lg-12">
                                            <h4>House Details</h4>
                                        </div>
                                        <Col lg="12">
                                            <div className="AAA-editbooking-console-shipment-3 pb-2">
                                                <FormGroup check>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id="isConsole"
                                                        checked={this.state.checked}
                                                        onClick={this.handleChecked}
                                                        label="HAWB Required"
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </div>
                                </div>)}
                        </div>
                    </ModalBody>
                    <div className="justify-content-center modal-footer">
                        <div className="">
                            <div className="d-flex">
                                {Number(this.state.count) > 1 && (
                                    <Button className="edit rounded-circles" type="button"
                                            onClick={() => this.Prev(this.state.count)}>
                                        Previous
                                    </Button>
                                )}
                                {Number(this.state.count) < 5 ? (
                                    <Button className="edit rounded-circles" type="button"
                                            onClick={() => this.Next(this.state.count)}>
                                        Next
                                    </Button>):""}
                                {Number(this.state.count) == 5 ? (
                                    <Button className="delete rounded-circles" type="button">
                                        Submit
                                    </Button>):""}
                            </div>
                        </div>
                    </div>
                </Modal>
                <AppLayout>
                    <div className="dashboard-wrapper padding-bottomss1">
                        <div className="homes-img video-img mt-4">
                            <div className="container-fluid">
                                <div className="row rates_all_details">
                                    <div className="col-lg-12">
                                        <div className="white-rates">
                                            <div className="details_for_rate">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <h3>INTKD,Tughlakabad(India)</h3>
                                                    <div className="icon_origin_destination">
                                                        <FontAwesomeIcon icon={faArrowRight} size="lg"/>
                                                    </div>
                                                    <h3>AEJEA, Jebel Ali (United Arab Emirates)</h3>
                                                </div>
                                                <div className="details-other">
                                                    <div className="text-shipment">
                                                        Total Pcs. : <span>19</span>,
                                                    </div>
                                                    <div className="text-shipment">
                                                        Volume Wt. :<span>19 CBM</span>,
                                                    </div>
                                                    <div className="text-shipment">
                                                        Gross Wt. : <span>19 kgs</span>,
                                                    </div>
                                                    <div className="text-shipment">
                                                        Chargeable Wt. :<span>19 CBM</span>,
                                                    </div>
                                                    <div className="text-shipment">
                                                        Handover Date : <span>18-Nov-2022</span>,
                                                    </div>
                                                    <div className="text-shipment">
                                                        Commodity : <span>Garments</span>,
                                                    </div>
                                                    <div className="text-shipment">
                                                        Shipment Mode : <span>LCL</span>,
                                                    </div>
                                                    <div className="text-shipment">
                                                        No. Of Containers : <span />,
                                                    </div>
                                                    <div className="text-shipment">
                                                        Tariff Mode : <span>General Cargo</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <div className="Query_filter">
                                                <h4>Popular Filter</h4>
                                                <div className="transit_filter">
                                                    <div className="heading_filter">Transit Time</div>
                                                    <div className="transit_checkBox">
                                                        <FormGroup check>
                                                            <Input id="exampleCheck" name="check" type="checkbox"/>
                                                            <Label check for="exampleCheck" > 1-2 Days </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input id="exampleCheck" name="check" type="checkbox"/>
                                                            <Label check for="exampleCheck" > 2-3 Days </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input id="exampleCheck" name="check" type="checkbox"/>
                                                            <Label check for="exampleCheck" > 3-4 Days </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input id="exampleCheck" name="check" type="checkbox"/>
                                                            <Label check for="exampleCheck" > 6 Days </Label>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="heading_filter">Service Provider</div>
                                                    <div className="select_clear_filter d-flex flex-wrap align-items-stretch">
                                                        <div className="tab_filter tab_btns">Select All</div>
                                                        <div className="tab_line"></div>
                                                        <div className="tab_filter tab_btns">Clear All</div>
                                                    </div>
                                                    <div className="transit_checkBox">
                                                        <FormGroup check>
                                                            <Input id="exampleCheck" name="check" type="checkbox"/>
                                                            <Label check for="exampleCheck" > Zipaworld </Label>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-ratesheading">
                                                <div className="dashboard-rate">
                                                    <div className="d-flex flex-wrap flex-column min-ratesheight">
                                                        <div className="sort_filter">
                                                            <div className="sort_label">
                                                                <label>Sort By :</label>
                                                                <select className="custom-select">
                                                                    <option>Select</option>
                                                                    <option>Fastest Option</option>
                                                                    <option>Cheapest Option</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <button onClick={()=>{
                                                            this.setState({shipperModal:!this.state.shipperModal})
                                                        }}>Shipper1</button>
                                                        <button onClick={()=>{
                                                            this.setState({shipperModalSecond:!this.state.shipperModalSecond})
                                                        }}>Shipper2</button>
                                                        <button onClick={()=>{
                                                            this.setState({shipperModalThird:!this.state.shipperModalThird})
                                                        }}>Shipper3</button>
                                                        <div className="form-side text-center">
                                                            <div id="three-cards" className="rate-card half-rates">
                                                                <Progress multi>
                                                                    <Progress striped animated bar color="success" value="60" />
                                                                    <Progress striped animated bar color="danger" value="40" />
                                                                </Progress>
                                                                <div className="d-flex flex-wrap align-items-stretch rates_partition">
                                                                    <div className="destination_origin_app" style={{padding:'10px 10px 0'}}>
                                                                        <div className="width-new-rates">
                                                                            <div className="radio_rates">
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input"
                                                                                           type="radio"
                                                                                           name="flexRadioDefault"
                                                                                           id="flexRadioDefault1"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="origin_destination_rates">
                                                                                <div className="text_origin">
                                                                                    <p>origin</p>
                                                                                    <div className="destination_name">DEL</div>
                                                                                </div>
                                                                                <div className="centerLine">
                                                                                    <div className="spot_transit2">4-5
                                                                                        days
                                                                                    </div>
                                                                                    <div className="d-flex center_width">
                                                                                        <div className="lineCenter"></div>
                                                                                        <FontAwesomeIcon icon={faPlane} size="lg"/>
                                                                                    </div>
                                                                                    <div className="spot_transit2 color-product">Product</div>
                                                                                </div>
                                                                                <div className="text_origin">
                                                                                    <p>Destination</p>
                                                                                    <div className="destination_name">DXB</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="rates_new_screen">
                                                                                <div className="origin_app">
                                                                                    <p className="full_name rate_new_design"><FontAwesomeIcon icon={faDollarSign} size="lg"/> 811.99</p>
                                                                                </div>
                                                                                {/*<div className="switch_currency need_credit_query">*/}
                                                                                {/*    <div className="form-check"><input*/}
                                                                                {/*        type="checkbox"*/}
                                                                                {/*        className="form-check-input"/><label*/}
                                                                                {/*        className="form-check-label">Need*/}
                                                                                {/*        Credit</label></div>*/}
                                                                                {/*</div>*/}
                                                                                <div className="fright_summery_icon">
                                                                                    <FontAwesomeIcon icon={faChevronDown} size="lg"/>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="width-new-rates">
                                                                            <div className="radio_rates">
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input"
                                                                                           type="radio"
                                                                                           name="flexRadioDefault"
                                                                                           id="flexRadioDefault1"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="origin_destination_rates">
                                                                                <div className="text_origin">
                                                                                    <p>origin</p>
                                                                                    <div className="destination_name">DEL</div>
                                                                                </div>
                                                                                <div className="centerLine">
                                                                                    <div className="spot_transit2">4-5
                                                                                        days
                                                                                    </div>
                                                                                    <div className="d-flex center_width">
                                                                                        <div className="lineCenter"></div>
                                                                                        <FontAwesomeIcon icon={faPlane} size="lg"/>
                                                                                    </div>
                                                                                    <div className="spot_transit2 color-product">Economy</div>
                                                                                </div>
                                                                                <div className="text_origin">
                                                                                    <p>Destination</p>
                                                                                    <div className="destination_name">DXB</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="rates_new_screen">
                                                                                <div className="origin_app">
                                                                                    <p className="full_name rate_new_design"><FontAwesomeIcon icon={faDollarSign} size="lg"/> 811.99</p>
                                                                                </div>
                                                                                {/*<div className="switch_currency need_credit_query">*/}
                                                                                {/*    <div className="form-check"><input*/}
                                                                                {/*        type="checkbox"*/}
                                                                                {/*        className="form-check-input"/><label*/}
                                                                                {/*        className="form-check-label">Need*/}
                                                                                {/*        Credit</label></div>*/}
                                                                                {/*</div>*/}
                                                                                <div className="fright_summery_icon">
                                                                                    <FontAwesomeIcon icon={faChevronDown} size="lg"/>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {/*<div className="spot_coupon_rates">*/}
                                                                        {/*    <div className="switch_currency">*/}
                                                                        {/*        <div className="form-check">*/}
                                                                        {/*            <input*/}
                                                                        {/*                type="checkbox" className="form-check-input"*/}
                                                                        {/*                name="switchCurrency" id=""*/}
                                                                        {/*                checked=""/><label*/}
                                                                        {/*            className="form-check-label" htmlFor="">Switch*/}
                                                                        {/*            to own Currency</label></div>*/}
                                                                        {/*    </div>*/}
                                                                        {/*    <div className="switch_currency">*/}
                                                                        {/*        <div className="form-check">*/}
                                                                        {/*            <input*/}
                                                                        {/*                type="checkbox" className="form-check-input"*/}
                                                                        {/*                name="switchCurrency" id=""*/}
                                                                        {/*                checked=""/><label*/}
                                                                        {/*            className="form-check-label" htmlFor="">Need Credit</label></div>*/}
                                                                        {/*    </div>*/}
                                                                        {/*    <div className="app_couply">Apply Coupon</div>*/}
                                                                        {/*    <div className="app_couply">Freight Summery</div>*/}
                                                                        {/*    <div className="app_couply">Slab</div>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                    <div className="name_company">
                                                                        <h5>ZipaWorld</h5>
                                                                        <button className="btn company_btn" onClick={()=>{
                                                                            this.setState({
                                                                                shipperModalThird : true
                                                                            })
                                                                        }}>Book Now <FontAwesomeIcon icon={faArrowRight} size="lg"/></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="checkBox_open">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </AppLayout>

            </>
        )}

};



export default NewRatesScreen;
