import React, { Component } from "react";

 class Courier extends Component {
     constructor(props) {
         super(props);
         this.state = {
         };
     }
     componentDidMount() {
        localStorage.setItem("queryType","CourierQuery")
        this.props.history.push("/");
     }
    render() {
        return(
           <></>
        )
     
    }
}
export default Courier;