import {all, call, fork, put} from 'redux-saga/effects';
import RestClient from "../../util/RestClient";
import {
    MAWB_UPDATE_URL
} from "../../constants";
import {
    MAWB_UPDATE

} from "../actions";

import {toastr} from "react-redux-toastr";
import routeRedirectTo from "../../util/routeWrapper";
import {takeLatest} from "@redux-saga/core/effects";

import {
    updateMawbResponce
} from "./mawbAction";

export function* watchmawbSaga() {
    yield takeLatest(MAWB_UPDATE, updateMawbDetails);

}



function* updateMawbDetails({payload}) {

    try {
        const updateMawbResponse = yield call(updateMawbDeatailAsync, payload);

        if (updateMawbResponse.success) {

            yield put(updateMawbResponce(updateMawbResponse));
        } else {
            toastr.error(updateMawbResponse.message)
            // yield put(onPreviewBookingDetailFetchedFailed(updateMawbResponse.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
        }
    }
}

const updateMawbDeatailAsync = async (mawbdeatils) => {
    {
        // console.log(JSON.stringify("In fetch  mawb update  API" + JSON.stringify(mawbdeatils)))
        return await RestClient({
            url: MAWB_UPDATE_URL,
            method: "POST",
            data: mawbdeatils
        })

    }
}

function forwardTo(location) {
    routeRedirectTo.push(location);
}

export default function* Saga() {
    yield all([
        fork(watchmawbSaga)
    ]);
}
