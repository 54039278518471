import React, {Component} from "react";
import {Button, Card, CardBody, Container} from "reactstrap";
import {CircularProgressbar} from "react-circular-progressbar";
import IntlMessages from "../../helpers/IntlMessages";
import PropTypes from "prop-types";
import {
    convertDateISO,
    convertDateISOToCustomiseFormat,
    parseFloatValue,
    concatExtraStringWithCheck
} from "../../util/CommonUtility";
import AddCoupanApplyForHome from "../../views/app/dashboards/AddCoupanApplyForHome";
import '../../assets/css/style1.css';
import '../../assets/css/bootstrap.min.css';
import SelectedDiffrentSlabCard from "../../views/app/dashboards/SelectedDiffrentSlabCard";
import {toast} from "react-toastify";
import RestClient from "../../util/RestClient";
import {history} from "../../helpers/history";
import {GET_DATA_ADDINTENEDED_PLAN_API,REMOVE_COUPAN} from "../../constants";
import {PAGE_PATHS} from "../../util/PageRoute";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign, faEuroSign, faRupeeSign, faTimes} from "@fortawesome/free-solid-svg-icons";

class CourierRatesCard extends Component {
              
    constructor(props) {
        super(props);

        this.state = {
            availableIntends: [],
            data: {},
            title: "title",
            detail: "detail",
            percent: 80,
            progressText: "8/10",
            BookingID: "1",
            login: undefined,
            switchCurrency: true,
            showCollapsSep:false,
            showCollaps: true,
            showCollapsForRate: false,
            creditDaysSTatus:false,
            creditDays:"",
        }
        this.switchCurrency = this.switchCurrency.bind(this);
    }

    switchCurrency = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.name == "switchCurrency") {
            this.setState({
                switchCurrency: true,
            }, () => {

            })
        }

    }

    sendToIntendedTransportPlan = (item) => {
        let QuoteId = localStorage.getItem("queryNo")
        QuoteId = JSON.parse(QuoteId)
        let intendedTransportPlan = [];
        let quoteId = QuoteId
        intendedTransportPlan.push({
            id: item._id,
            from: item.originDetails.name,
            to: item.destinationDetails.name,
            vesselNo: item.vessel,
            voyageNo: item.voyageNo,
            eta: item.arrival,
            etd: item.departure,
        })

        let availableIntends = this.state.availableIntends
        availableIntends.push({
            id: item._id,
            from: item.originDetails.name,
            to: item.destinationDetails.name,
            vesselNo: item.vessel,
            voyageNo: item.voyageNo,
            eta: item.arrival,
            etd: item.departure
        })
        this.setState({
            availableIntends: availableIntends,
            intendedTransportPlan: intendedTransportPlan,
            quoteId: QuoteId
        }, () => {
            this.addIntenededPlan({quoteId, intendedTransportPlan}).then(dd => {

                if (dd.success) {
                    toast.success("Planned For booking", {
                        position: "top-left",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("Something Wrong Please Contact Customer Service", {
                        position: "top-left",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        })

    }

    checkIdAvailable = (id) => {
        let st = false
        for (let i = 0; i < this.state.availableIntends.length; i++) {
            if (id == this.state.availableIntends[i].id) {
                st = true
            }
        }
        return st
    }
    addIntenededPlan = async (indenededData) => {
        {
            return await RestClient({
                url: GET_DATA_ADDINTENEDED_PLAN_API,
                method: "POST",
                data: indenededData
            });
        }
    };
    checkUser = () =>{
        let typeOfCust = JSON.parse(localStorage.getItem("customer"))
        if(typeOfCust){
            return true
        }else{
            return false
        }
    }
    componentDidUpdate = (prevProps,prevState,snapShot)=>{
        if (this.props.createLoggedSucess && this.props.createLoggedSucess !== prevProps.createLoggedSucess) {
            if (Array.isArray(this.props.createLoggedSucess) && this.props.createLoggedSucess.length >= 1) {
                this.props.createLoggedSucess.map(item => {
                    let quoteIdPopNewExist = item.quoteId;
                    let idUnderScoreNewExist = item._id;
                    this.setState({
                        quoteIdPopNewExist: quoteIdPopNewExist,
                        idUnderScoreNewExist: idUnderScoreNewExist,
                    },()=>{
                    })
                })
            }


        }
        if(prevProps.coupanRemove !== this.props.coupanRemove){
            this.setState({
                applyCoupan : false
            },()=>{
                localStorage.removeItem('afterDiscountCharges')
                localStorage.removeItem('discountGiven')
            })
        }
    }
    componentDidMount() {
        if (this.props.queryDataForcard) {
            this.setState({
                queryDataForcard: this.props.queryDataForcard
            }, () => {
                // console.log("rwrwqrwrwrwrqwr",this.state.queryDataForcard)
                this.setState({quoteId: this.state.queryDataForcard._id})
            })
        }
        if (this.props.mainQueryData) {
            //console.log("wddwdwdwdwdw"+JSON.stringify(this.props.mainQueryData))
            this.setState({
                quoteId: this.props.mainQueryData && this.props.mainQueryData.quoteId ? this.props.mainQueryData.quoteId : undefined
            }, () => {
            })
        }
        if (this.props.lengthCard) {
            this.setState({
            }, () => {
            })
        }

    }
    removeCoupan = async () => {
        let customerBranchData = JSON.parse(localStorage.getItem('customer'))
        let obj ={
            rateId : this.props.data && this.props.data._id || this.state.idUnderScoreNewExist,
            customerBranchId : customerBranchData ?customerBranchData && customerBranchData.result && customerBranchData.result.customerBranchData._id : '',
            coupanId : this.state.coupanId
        }
        {
            return await RestClient({
                url: REMOVE_COUPAN,
                method: "POST",
                data: obj
            });
        }
    };
    custDetails = () => {
        let typeOfCust = JSON.parse(localStorage.getItem("customer"))
    const custType = typeOfCust && typeOfCust.result && typeOfCust.result.customerData && typeOfCust.result.customerData.typeOfBussiness
    const custKycStatus = typeOfCust && typeOfCust.result && typeOfCust.result.customerBranchData && typeOfCust.result.customerBranchData.kycStatus
    return {typeOfCust,custType,custKycStatus}
        }
    creditDaysSTatusFunc = () =>{
        this.setState({
            creditDaysSTatus:!this.state.creditDaysSTatus
        },()=>{
            if(this.state.creditDaysSTatus === false){
                this.setState({
                    creditDays : ""
                })
            }
        })
    }
    switchNeedCredit = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },()=>{
            localStorage.setItem('creditDays',this.state.creditDays)
        });
    }
    render() {
        let {data, title, detail, queryDataForcard, percent, progressText, BookingID, login} = this.props
        let obj = this.props.data
        let length=this.props.lengthCard
        // detail=concatExtraStringWithCheck(detail,"Day","Days")
        // detail=concatExtraStringWithCheck(detail,"day","Days")
        let customerBranchData = JSON.parse(localStorage.getItem('customer'))

        return (
            <>
            <AddCoupanApplyForHome
                    modalLarge={this.state.payLarge}
                    setmodal1={values => values === false ? this.setState({payLarge: false}) : ""}
                    customerBranchId = {customerBranchData ?customerBranchData && customerBranchData.result && customerBranchData.result.customerBranchData._id : ''}
                    quoteId = {obj.quoteId|| this.state.quoteIdPopNewExist}
                    rateId = {obj._id|| this.state.idUnderScoreNewExist}
                    sendDataAfterCoupan = {values =>{
                        // this.props.newCharges(values)
                        this.setState({
                            applyCoupan : values && values[this.props.chargeIndex] && values[this.props.chargeIndex].coupanApply ? true : false,
                            discountAmount : values[this.props.chargeIndex].discountAmount,
                            amountCDiscount : values[this.props.chargeIndex].amountCDiscount,
                            amountC15Discount : values[this.props.chargeIndex].amountC15Discount,
                            amountC30Discount : values[this.props.chargeIndex].amountC30Discount,
                            amountBDiscount : values[this.props.chargeIndex].amountBDiscount,
                            amountB15Discount : values[this.props.chargeIndex].amountB15Discount,
                            amountB30Discount : values[this.props.chargeIndex].amountB30Discount,
                            taxAmountB15Discount : values[this.props.chargeIndex].taxAmountB15Discount,
                            taxAmountB30Discount : values[this.props.chargeIndex].taxAmountB30Discount,
                            taxAmountBDiscount : values[this.props.chargeIndex].taxAmountBDiscount,
                            taxAmountC15Discount : values[this.props.chargeIndex].taxAmountC15Discount,
                            taxAmountC30Discount : values[this.props.chargeIndex].taxAmountC30Discount,
                            taxAmountCDiscount : values[this.props.chargeIndex].taxAmountCDiscount,
                            coupanName : values[this.props.chargeIndex].coupanName,
                        },()=>{
                        })
                    }}
                    

                />
                <div id="three-cards" className="rate-card mb-3 half-rates">
                    <div className="d-flex flex-wrap align-items-center rates_partition">
                        <div className="destination_origin_app">
                            <div className="width-new-rates">
                                <div className="images_destination">
                                    {queryDataForcard.queryFor === "Courier"?<img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.815376889974007-1637672879575.png"
                                        alt="courier fright"
                                    /> :
                                        <img src="../../assets/img/truck1.png"
                                             alt="road fright"/>}
                                </div>
                                <div className="origin_destination_app">
                                    <div className="origin_app">
                                        {detail?
                                            <>
                                                <p className="small_name">Transit Time</p>
                                                <p className="full_name">{detail}</p>
                                            </>
                                        :
                                            <>
                                                <p className="small_name">Cargo Date</p>
                                                <p className="full_name">{queryDataForcard.clearenceDate ? convertDateISOToCustomiseFormat(queryDataForcard.clearenceDate) : "NA"}</p>
                                            </>}
                                    </div>
                                    <div className="origin_app">
                                        <p className="small_name">Pick Up</p>
                                        <p className="full_name">
                                        {queryDataForcard && queryDataForcard.originDoor && queryDataForcard.originDoor.address ? queryDataForcard.originDoor.address : ""}
                                        {queryDataForcard.Shipper && queryDataForcard.Shipper.CompanyName ? queryDataForcard.Shipper.CompanyName : ""}
                                        {queryDataForcard.Shipper && queryDataForcard.Shipper.Country ? queryDataForcard.Shipper.Country : ""}{" "}
                                        {queryDataForcard.Shipper && queryDataForcard.Shipper.stateName ? queryDataForcard.Shipper.stateName : ""}{" "}
                                        {queryDataForcard.Shipper && queryDataForcard.Shipper.City ? queryDataForcard.Shipper.City : ""}{" "}
                                            {queryDataForcard.Shipper && queryDataForcard.Shipper.ZipCode ? queryDataForcard.Shipper.ZipCode : ""}</p>
                                    </div>
                                    <div className="origin_app">
                                        <p className="small_name">Drop Off</p>
                                        <p className="full_name">{queryDataForcard && queryDataForcard.destinationDoor && queryDataForcard.destinationDoor.address ? queryDataForcard.destinationDoor.address : ""}
                                            {queryDataForcard.Consignee && queryDataForcard.Consignee.CompanyName ? queryDataForcard.Consignee.CompanyName : ""}
                                            {queryDataForcard.Consignee && queryDataForcard.Consignee.Country ? queryDataForcard.Consignee.Country : ""}{" "}
                                            {queryDataForcard.Consignee && queryDataForcard.Consignee.stateName ? queryDataForcard.Consignee.stateName : ""}{" "}
                                            {queryDataForcard.Consignee && queryDataForcard.Consignee.City ? queryDataForcard.Consignee.City : ""}
                                            {queryDataForcard.Consignee && queryDataForcard.Consignee.ZipCode ? queryDataForcard.Consignee.ZipCode : ""}</p>
                                    </div>
                                    <div className="origin_app">
                                        <p className="full_name rate_new_design">
                                            {data.customerCurrency == "INR" ?
                                                <FontAwesomeIcon icon={faRupeeSign} size="lg"/> : data.customerCurrency == "USD" ?
                                                    <FontAwesomeIcon icon={faDollarSign} size="lg"/> : data.customerCurrency == "EUR" ?
                                                        <FontAwesomeIcon icon={faEuroSign} size="lg"/> : ""}{" "}
                                            {this.state.applyCoupan ?
                                                <>
                                                    {/*{this.state.switchCurrency == true ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                    {this.state.switchCurrency==true?
                                                        <>
                                                            {this.state.creditDays === ""? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) :"":""}
                                                            {this.state.creditDays === "15 Days"? data && data.amountC15 ? parseFloat(this.state.amountC15Discount).toFixed(2) : "":""}
                                                            {this.state.creditDays === "30 Days"? data && data.amountC30 ? parseFloat(this.state.amountC30Discount).toFixed(2) : "":""}
                                                        </>
                                                        :
                                                        <>
                                                            {this.state.creditDays === ""?data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : "":""}
                                                            {this.state.creditDays === "15 Days"?data && data.amountB15 ? parseFloat(this.state.amountB15Discount).toFixed(2) : "":""}
                                                            {this.state.creditDays === "30 Days"?data && data.amountB30 ? parseFloat(this.state.amountB30Discount).toFixed(2) : "":""}
                                                        </>
                                                    }
                                                </> :
                                                <>
                                                    {/*{this.state.switchCurrency == true ? data && data.amountC ? parseFloat(data.amountC).toFixed(2) : "" : data && data.amountB ? parseFloat(data.amountB).toFixed(2) : ""}*/}
                                                    {this.state.switchCurrency==true?
                                                        <>
                                                            {this.state.creditDays === ""? data && data.amountC ? parseFloat(data.amountC).toFixed(2) :"":""}
                                                            {this.state.creditDays === "15 Days"? data && data.amountC15 ? parseFloat(data.amountC15).toFixed(2) : "":""}
                                                            {this.state.creditDays === "30 Days"? data && data.amountC30 ? parseFloat(data.amountC30).toFixed(2) : "":""}
                                                        </>
                                                        :
                                                        <>
                                                            {this.state.creditDays === ""?data && data.amountB ? parseFloat(data.amountB).toFixed(2) : "":""}
                                                            {this.state.creditDays === "15 Days"?data && data.amountB15 ? parseFloat(data.amountB15).toFixed(2) : "":""}
                                                            {this.state.creditDays === "30 Days"?data && data.amountB30 ? parseFloat(data.amountB30).toFixed(2) : "":""}
                                                        </>
                                                    }
                                                </>
                                            }</p>
                                    </div>
                                </div>
                            </div>
                            <div className="spot_coupon_rates">
                                {queryDataForcard.serviceType === "Domestic" ? "" :
                                <div className="switch_currency">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" name="switchCurrency" checked={this.state.switchCurrency==true ? true : false}
                                               onChange={(e) => {this.switchCurrency(e)}}
                                               id=""/>
                                        <label className="form-check-label" htmlFor="">Switch to own Currency</label>
                                    </div>
                                </div>}
                                {/*<div className="switch_currency need_credit_query">*/}
                                {/*    <div className="form-check">*/}
                                {/*        <input type="checkbox" className="form-check-input" onClick={this.creditDaysSTatusFunc}/>*/}
                                {/*        <label className="form-check-label" >Need Credit</label>*/}
                                {/*    </div>*/}
                                {/*    {this.state.creditDaysSTatus === true ?*/}
                                {/*        <select className="" name="creditDays" onChange={this.switchNeedCredit} value={this.state.creditDays}>*/}
                                {/*            <option value={""}>No Credit</option>*/}
                                {/*            <option value={"15 Days"}>15 Days</option>*/}
                                {/*            <option value={"30 Days"}>30 Days</option>*/}
                                {/*        </select>*/}
                                {/*        :""}*/}
                                {/*</div>*/}
                                {this.state.applyCoupan ?
                                    <>
                                        <div className="remove_btn_with_value d-flex flex-wrap align-items-stretch mr-2">
                                            <h6 className="coupon_apply_pop">{this.state.coupanName}</h6>
                                            <div className="cross_apply_coupan" onClick={e=>{
                                                e.preventDefault()
                                                this.removeCoupan().then(res=>{
                                                    if(res.success){
                                                        toast.success("Coupon Removed.", {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                        this.setState({
                                                            applyCoupan : false
                                                        },()=>{
                                                            localStorage.removeItem('afterDiscountCharges')
                                                            localStorage.removeItem('discountGiven')
                                                        })
                                                    }
                                                })
                                            }}><FontAwesomeIcon icon={faTimes} size="lg"/>
                                            </div>
                                        </div>

                                    </> :<div className="app_couply" onClick={e=>{
                                        e.preventDefault()
                                        localStorage.setItem('amountB',data.amountB)
                                        this.setState({payLarge : true})
                                    }}>
                                        Apply Coupon
                                    </div>}
                                <div className="app_couply" onClick={e => {
                                    e.preventDefault()
                                    if(length === 1){
                                        this.setState({showCollaps: !this.state.showCollaps})
                                    }else{
                                        this.setState({showCollapsSep: !this.state.showCollapsSep})
                                    }
                                }}>Freight Summery</div>
                            </div>
                        </div>
                        <div className="name_company">
                            <h5> {data.product}</h5>
                            <button className="btn company_btn" onClick={(e) => {
                                e.preventDefault()
                                let value = this.custDetails()
                                let typeOfCustomer = value && value.typeOfCust && value.typeOfCust.result && value.typeOfCust.result.customerData && value.typeOfCust.result.customerData.typeOfBussiness ? value.typeOfCust.result.customerData.typeOfBussiness : "Bussiness"

                                if(this.checkUser()){
                                    if(value.custKycStatus){
                                        this.props.login(true)
                                    }else{
                                        if(typeOfCustomer == "Bussiness"){
                                            history.push(PAGE_PATHS.CUSTOMER_KYC);
                                        }else{
                                            history.push(PAGE_PATHS.CUSTOMER_KYC_FOR_INDIVIDUAL);
                                        }
                                        window.location.reload("false")
                                    }
                                }else{
                                    this.props.isUserLoggedIn(true)

                                }
                            }}>Book Now</button>
                        </div>
                    </div>

                        {length === 1 ?
                            <div id="collapseOne1"
                                 className={this.state.showCollaps === true ? "collapse border-top scroll-freight show " : "collapse border-top"} role="tabpanel">
                                <div className="d-flex flex-wrap justify-content-between padding-15">
                                    <div className="all-price list-fare">

                                        <table className="table pricess-all">
                                            <tr>
                                                <th>Charges Name</th>
                                                <th>Chargeable Weight(KGS)</th>
                                                <th>Rate</th>
                                                <th>Total Amount</th>
                                            </tr>

                                            {data.charges !== undefined && queryDataForcard.shipmentMode === "FCL" ?

                                                data.charges.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</th>
                                                            <td>{item.chargeQty}</td>
                                                            <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                            {/*<td>{this.state.switchCurrency == true ? */}
                                                            {/*    data.customerCurrency + " " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "" */}
                                                            {/*    : data.baseCurrency + " " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : ""}</td>*/}
                                                            <td>
                                                                {this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>

                                                    )
                                                })

                                                :
                                                data.charges.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</th>
                                                            <td>{item.chargeQty}</td>
                                                            <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                            {/*<td>{this.state.switchCurrency == true ? */}
                                                            {/*    data.customerCurrency + " " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "" : */}
                                                            {/*    data.baseCurrency + " " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : ""}</td>*/}
                                                            <td>
                                                                {this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {this.state.applyCoupan ? "" :
                                                <>
                                                    {data.taxAmountC !== null ?

                                                        <tr>
                                                            <th>GST</th>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>
                                                                {this.state.switchCurrency == true ?
                                                                    <>
                                                                        {this.state.creditDays === "" ? data.customerCurrency + " " + data.taxAmountC != undefined ? parseFloat(data.taxAmountC).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "15 Days" ? data.customerCurrency + " " + data.taxAmountC15 != undefined ? parseFloat(data.taxAmountC15).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "30 Days" ? data.customerCurrency + " " + data.taxAmountC30 != undefined ? parseFloat(data.taxAmountC30).toFixed(2) : "" : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === "" ? data.baseCurrency + " " + data.taxAmountB != undefined ? parseFloat(data.taxAmountB).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "15 Days" ? data.baseCurrency + " " + data.taxAmountB15 != undefined ? parseFloat(data.taxAmountB15).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "30 Days" ? data.baseCurrency + " " + data.taxAmountB30 != undefined ? parseFloat(data.taxAmountB30).toFixed(2) : "" : ""}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        undefined}
                                                        <tr>
                                                <th>TOTAL AMOUNT</th>
                                                <td><strong/></td>
                                                <td><strong/></td>
                                                {this.state.applyCoupan ?
                                                    <>
                                                        <td>
                                                            <strong>{this.state.switchCurrency==true?
                                                                <>
                                                                    {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(parseFloat(data.amountC)-parseFloat(data.taxAmountC)).toFixed(2):""}
                                                                    {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(parseFloat(data.amountC15)-parseFloat(data.taxAmountC15)).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(parseFloat(data.amountC30)-parseFloat(data.taxAmountC30)).toFixed(2) :""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(parseFloat(data.amountB)-parseFloat(data.taxAmountB)).toFixed(2)  :""}
                                                                    {this.state.creditDays === "15 Days"?data.baseCurrency +" " + parseFloat(parseFloat(data.amountB15)-parseFloat(data.taxAmountB15)).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"?data.baseCurrency +" " + parseFloat(parseFloat(data.amountB30)-parseFloat(data.taxAmountB30)).toFixed(2) :""}
                                                                </>
                                                            }</strong>
                                                        </td>
                                                    </>:
                                                    <td>
                                                        <strong>{this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30).toFixed(2) :""}
                                                            </>
                                                        }</strong>
                                                    </td>}
                                            </tr>
                                                </>
                                            }
                                            
                                            {this.state.applyCoupan ? <>
                                                <tr>
                                                <th>TOTAL AMOUNT</th>
                                                <td><strong/></td>
                                                <td><strong/></td>
                                                {/*<td>*/}
                                                {/*    <strong>{this.state.switchCurrency == true ? */}
                                                {/*        data.customerCurrency + " " + parseFloat(data.amountC).toFixed(2) : */}
                                                {/*        data.baseCurrency + " " + parseFloat(data.amountB).toFixed(2)}</strong>*/}
                                                {/*</td>*/}
                                                <td><strong>
                                                    {this.state.switchCurrency==true?
                                                        <>
                                                            {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC-data.taxAmountC).toFixed(2) :""}
                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15-data.taxAmountC15).toFixed(2) :""}
                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30-data.taxAmountC30).toFixed(2) :""}
                                                        </>
                                                        :
                                                        <>
                                                            {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB-data.taxAmountB).toFixed(2):""}
                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15-data.taxAmountB15).toFixed(2) :""}
                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30-data.taxAmountB30).toFixed(2) :""}
                                                        </>
                                                    }
                                                </strong>
                                                </td>
                                            </tr>
                                                <tr>
                                                <th>Discount on MRP</th>
                                                <td><strong/></td>
                                                <td><strong/></td>
                                                    <td style={{color: '#03a685'}}>
                                                        <strong>{this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.discountAmount).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                            </>
                                                        }</strong>
                                                    </td>
                                            </tr>
                                                {data.taxAmountC !== null ?

                                                    <tr>
                                                        <th>GST</th>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>
                                                            {/*{this.state.switchCurrency == true ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                            {this.state.switchCurrency==true?
                                                                <>
                                                                    {this.state.creditDays === ""? data.customerCurrency +" " + this.state.taxAmountCDiscount != undefined ? parseFloat(this.state.taxAmountCDiscount).toFixed(2) : "":""}
                                                                    {this.state.creditDays === "15 Days"? data.customerCurrency +" " + this.state.taxAmountC15Discount != undefined ? parseFloat(this.state.taxAmountC15Discount).toFixed(2) : "":""}
                                                                    {this.state.creditDays === "30 Days"? data.customerCurrency +" " + this.state.taxAmountC30Discount != undefined ? parseFloat(this.state.taxAmountC30Discount).toFixed(2) : "":""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""?data.baseCurrency +" " + this.state.taxAmountBDiscount != undefined ? parseFloat(this.state.taxAmountBDiscount).toFixed(2) : "":""}
                                                                    {this.state.creditDays === "15 Days"?data.baseCurrency +" " + this.state.taxAmountB15Discount != undefined ? parseFloat(this.state.taxAmountB15Discount).toFixed(2) : "":""}
                                                                    {this.state.creditDays === "30 Days"?data.baseCurrency +" " + this.state.taxAmountB30Discount != undefined ? parseFloat(this.state.taxAmountB30Discount).toFixed(2) : "":""}
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                    :
                                                    undefined}
                                            </> : ""}

                                            {this.state.applyCoupan ? <>
                                            <tr>
                                                <th>Net Payable Amount(After Discount)</th>
                                                <td><strong/></td>
                                                <td><strong/></td>
                                                {/*<td>*/}
                                                {/*    <strong>{this.state.switchCurrency == true ?*/}
                                                {/*        data.customerCurrency + " " + parseFloat(this.state.amountBDiscount).toFixed(2) :*/}
                                                {/*        data.baseCurrency + " " + parseFloat(this.state.amountBDiscount).toFixed(2)}</strong>*/}
                                                {/*</td>*/}
                                                <td>
                                                    <strong>{this.state.switchCurrency==true?
                                                        <>
                                                            {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(this.state.amountCDiscount).toFixed(2) :""}
                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC15Discount).toFixed(2) :""}
                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC30Discount).toFixed(2) :""}
                                                        </>
                                                        :
                                                        <>
                                                            {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.amountBDiscount).toFixed(2):""}
                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB15Discount).toFixed(2) :""}
                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB30Discount).toFixed(2) :""}
                                                        </>
                                                    }</strong>
                                                </td>
                                            </tr>
                                            </> : ""}
                                            <tr>
                                                Awaited Rates :
                                                {Array.isArray(data.awaitedRates) && data.awaitedRates.length >= 1 ? data.awaitedRates.map(item => {
                                                    return (
                                                        <ul>
                                                            {item.chargeFor != item.altName ?
                                                                <li> {item.chargeFor} : {item.altName} </li>
                                                                :
                                                                <li> {item.chargeFor} </li>
                                                            }
                                                        </ul>
                                                    );
                                                }) : undefined}
                                            </tr>
                                        </table>

                                    </div>
                                    <div className="terms-width list-fare">

                                        <p className="mt-2"><strong>TERMS & CONDITIONS</strong></p>
                                        <ul>
                                            {/*<li>Rates quoted are valid subject to booking and onwards space confirmation from the carrier. Rates have been quoted for general cargo & prepaid shipments originating ex:-*/}
                                            {/*    {data.row && data.row.originDetails.originName ?*/}

                                            {/*        data.row.originDetails.originName.split("(")[data.row.originDetails.originName.split("(").length - 2 ]  + " "*/}
                                            {/*        : ""}*/}
                                            {/*    Rates quoted on the basis of non volumetric shipments with 1:1 gross/volume ratio. All inn rates include :- FREIGHT + FSC + SSC + XRAY + CTG + MISC Awb + pca ams (as applicable) would be additionaly charged.</li>*/}

                                            <li>Rates are subject to the declared pick up & delivery details, any changes will incur additional charges. </li>
                                            <li>Rates are not valid for Non-Standard Shipment (Weight not more than 70 Kgs. / Dimension not more than 120 Cms.)</li>
                                            <li>Rates are applicable for general commodity & documents only and any courier commodity that requires special handling (Like Restricted Items, Dangerous Goods etc.) will not be acceptable.</li>
                                            <li>Rates are applicable on the Chargeable Weight. (Chargeable weight will be Gross or Volume weight whichever is higher). The calculation for the volume weight will be L X W X H Divided by 5000.</li>
                                            <li>Same Day Pick can be arranged for request made prior to 12 noon.</li>
                                            <li>Transit time mentioned is purely indicative and should be considered as tentative.</li>
                                            <li>Government Taxes (if any) shall be applicable as per Government norms.</li>
                                        </ul>
                                        <p className="mt-2 pl-3" style={{marginBottom: "0rem"}}><strong>Remarks</strong></p>
                                        <ul>
                                            <li> {data.remarks !== "" ? data.remarks : undefined}</li>

                                        </ul>

                                    </div>
                                </div>
                            </div>:
                            <div id="collapseOne1"
                                 className={this.state.showCollapsSep === true ? "collapse border-top show " : "collapse border-top"} role="tabpanel">
                                <div className="d-flex flex-wrap justify-content-between padding-15">
                                    <div className="all-price list-fare">

                                        <table className="table pricess-all">
                                            <tr>
                                                <th>Charges Name</th>
                                                <th>Chargeable Weight(KGS)</th>
                                                <th>Rate</th>
                                                <th>Total Amount</th>
                                            </tr>

                                            {data.charges !== undefined && queryDataForcard.shipmentMode === "FCL" ?

                                                data.charges.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</th>
                                                            <td>{item.chargeQty}</td>
                                                            <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                            {/*<td>{this.state.switchCurrency == true ? */}
                                                            {/*    data.customerCurrency + " " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "" */}
                                                            {/*    : data.baseCurrency + " " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : ""}</td>*/}
                                                            <td>
                                                                {this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>

                                                    )
                                                })

                                                :
                                                data.charges.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <th>{item.altName !== "" && item.altName !== undefined && item.altName !== null ? item.chargeName + " - " + item.altName : item.chargeName}</th>
                                                            <td>{item.chargeQty}</td>
                                                            <td>{this.state.creditDays === "" ? item.currency + " " + item.chargeRate:""}
                                                                {this.state.creditDays === "15 Days" ? item.currency + " " + item.chargeRate15:""}
                                                                {this.state.creditDays === "30 Days" ? item.currency + " " + item.chargeRate30:""}</td>
                                                            {/*<td>{this.state.switchCurrency == true ? */}
                                                            {/*    data.customerCurrency + " " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "" : */}
                                                            {/*    data.baseCurrency + " " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : ""}</td>*/}
                                                            <td>
                                                                {this.state.switchCurrency==true?
                                                                    <>
                                                                        {this.state.creditDays === ""? data.customerCurrency +" " + item.taxableC != undefined ? parseFloat(item.taxableC).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"? data.customerCurrency +" " + item.taxableC15 != undefined ? parseFloat(item.taxableC15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"? data.customerCurrency +" " + item.taxableC30 != undefined ? parseFloat(item.taxableC30).toFixed(2) : "":""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === ""?data.baseCurrency +" " + item.taxableB != undefined ? parseFloat(item.taxableB).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "15 Days"?data.baseCurrency +" " + item.taxableB15 != undefined ? parseFloat(item.taxableB15).toFixed(2) : "":""}
                                                                        {this.state.creditDays === "30 Days"?data.baseCurrency +" " + item.taxableB30 != undefined ? parseFloat(item.taxableB30).toFixed(2) : "":""}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {this.state.applyCoupan ? "" :
                                                <>
                                                    {data.taxAmountC !== null ?

                                                        <tr>
                                                            <th>GST</th>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>
                                                                {this.state.switchCurrency == true ?
                                                                    <>
                                                                        {this.state.creditDays === "" ? data.customerCurrency + " " + data.taxAmountC != undefined ? parseFloat(data.taxAmountC).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "15 Days" ? data.customerCurrency + " " + data.taxAmountC15 != undefined ? parseFloat(data.taxAmountC15).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "30 Days" ? data.customerCurrency + " " + data.taxAmountC30 != undefined ? parseFloat(data.taxAmountC30).toFixed(2) : "" : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.state.creditDays === "" ? data.baseCurrency + " " + data.taxAmountB != undefined ? parseFloat(data.taxAmountB).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "15 Days" ? data.baseCurrency + " " + data.taxAmountB15 != undefined ? parseFloat(data.taxAmountB15).toFixed(2) : "" : ""}
                                                                        {this.state.creditDays === "30 Days" ? data.baseCurrency + " " + data.taxAmountB30 != undefined ? parseFloat(data.taxAmountB30).toFixed(2) : "" : ""}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        undefined}
                                                         <tr>
                                                <th>TOTAL AMOUNT</th>
                                                <td><strong/></td>
                                                <td><strong/></td>
                                                {/*<td>*/}
                                                {/*    <strong>{this.state.switchCurrency == true ? */}
                                                {/*        data.customerCurrency + " " + parseFloat(data.amountC).toFixed(2) : */}
                                                {/*        data.baseCurrency + " " + parseFloat(data.amountB).toFixed(2)}</strong>*/}
                                                {/*</td>*/}
                                                {this.state.applyCoupan ?
                                                    <>
                                                        <td>
                                                            <strong>{this.state.switchCurrency==true?
                                                                <>
                                                                    {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(parseFloat(data.amountC)-parseFloat(data.taxAmountC)).toFixed(2):""}
                                                                    {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(parseFloat(data.amountC15)-parseFloat(data.taxAmountC15)).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(parseFloat(data.amountC30)-parseFloat(data.taxAmountC30)).toFixed(2) :""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(parseFloat(data.amountB)-parseFloat(data.taxAmountB)).toFixed(2)  :""}
                                                                    {this.state.creditDays === "15 Days"?data.baseCurrency +" " + parseFloat(parseFloat(data.amountB15)-parseFloat(data.taxAmountB15)).toFixed(2) :""}
                                                                    {this.state.creditDays === "30 Days"?data.baseCurrency +" " + parseFloat(parseFloat(data.amountB30)-parseFloat(data.taxAmountB30)).toFixed(2) :""}
                                                                </>
                                                            }</strong>
                                                        </td>
                                                    </>:
                                                    <td>
                                                        <strong>{this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30).toFixed(2) :""}
                                                            </>
                                                        }</strong>
                                                    </td>}
                                            </tr>
                                                </>
                                            }
                                           

                                            {this.state.applyCoupan ? <>
                                                <tr>
                                                <th>TOTAL AMOUNT</th>
                                                <td><strong/></td>
                                                <td><strong/></td>
                                                {/*<td>*/}
                                                {/*    <strong>{this.state.switchCurrency == true ? */}
                                                {/*        data.customerCurrency + " " + parseFloat(data.amountC).toFixed(2) : */}
                                                {/*        data.baseCurrency + " " + parseFloat(data.amountB).toFixed(2)}</strong>*/}
                                                {/*</td>*/}
                                                <td><strong>
                                                    {this.state.switchCurrency==true?
                                                        <>
                                                            {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(data.amountC -data.taxAmountC).toFixed(2) :""}
                                                            {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(data.amountC15  -data.taxAmountC15).toFixed(2) :""}
                                                            {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(data.amountC30-data.taxAmountC30).toFixed(2) :""}
                                                        </>
                                                        :
                                                        <>
                                                            {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(data.amountB-data.taxAmountB).toFixed(2):""}
                                                            {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(data.amountB15 -data.taxAmountB15).toFixed(2) :""}
                                                            {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(data.amountB30-data.taxAmountB30).toFixed(2) :""}
                                                        </>
                                                    }
                                                </strong>
                                                </td>
                                            </tr>
                                                <tr>
                                                    <th>Discount on MRP</th>
                                                    <td><strong/></td>
                                                    <td><strong/></td>
                                                    <td style={{color: '#03a685'}}>
                                                        <strong>{this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.discountAmount).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.discountAmount).toFixed(2) :""}
                                                            </>
                                                        }</strong>
                                                    </td>
                                                </tr>
                                                {data.taxAmountC !== null ?

                                                    <tr>
                                                        <th>GST</th>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>
                                                            {/*{this.state.switchCurrency == true ? data && data.amountC ? parseFloat(this.state.amountCDiscount).toFixed(2) : "" : data && data.amountB ? parseFloat(this.state.amountBDiscount).toFixed(2) : ""}*/}
                                                            {this.state.switchCurrency==true?
                                                                <>
                                                                    {this.state.creditDays === ""? data.customerCurrency +" " + this.state.taxAmountCDiscount != undefined ? parseFloat(this.state.taxAmountCDiscount).toFixed(2) : "":""}
                                                                    {this.state.creditDays === "15 Days"? data.customerCurrency +" " + this.state.taxAmountC15Discount != undefined ? parseFloat(this.state.taxAmountC15Discount).toFixed(2) : "":""}
                                                                    {this.state.creditDays === "30 Days"? data.customerCurrency +" " + this.state.taxAmountC30Discount != undefined ? parseFloat(this.state.taxAmountC30Discount).toFixed(2) : "":""}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.creditDays === ""?data.baseCurrency +" " + this.state.taxAmountBDiscount != undefined ? parseFloat(this.state.taxAmountBDiscount).toFixed(2) : "":""}
                                                                    {this.state.creditDays === "15 Days"?data.baseCurrency +" " + this.state.taxAmountB15Discount != undefined ? parseFloat(this.state.taxAmountB15Discount).toFixed(2) : "":""}
                                                                    {this.state.creditDays === "30 Days"?data.baseCurrency +" " + this.state.taxAmountB30Discount != undefined ? parseFloat(this.state.taxAmountB30Discount).toFixed(2) : "":""}
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                    :
                                                    undefined}
                                            </> : ""}

                                            {this.state.applyCoupan ? <>
                                                <tr>
                                                    <th>Net Payable Amount(After Discount)</th>
                                                    <td><strong/></td>
                                                    <td><strong/></td>
                                                    {/*<td>*/}
                                                    {/*    <strong>{this.state.switchCurrency == true ?*/}
                                                    {/*        data.customerCurrency + " " + parseFloat(this.state.amountBDiscount).toFixed(2) :*/}
                                                    {/*        data.baseCurrency + " " + parseFloat(this.state.amountBDiscount).toFixed(2)}</strong>*/}
                                                    {/*</td>*/}
                                                    <td>
                                                        <strong>{this.state.switchCurrency==true?
                                                            <>
                                                                {this.state.creditDays === ""? data.customerCurrency +" " +  parseFloat(this.state.amountCDiscount).toFixed(2) :""}
                                                                {this.state.creditDays === "15 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC15Discount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"? data.customerCurrency +" " +  parseFloat(this.state.amountC30Discount).toFixed(2) :""}
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.creditDays === ""?data.baseCurrency +" " + parseFloat(this.state.amountBDiscount).toFixed(2):""}
                                                                {this.state.creditDays === "15 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB15Discount).toFixed(2) :""}
                                                                {this.state.creditDays === "30 Days"?data.baseCurrency +" " +  parseFloat(this.state.amountB30Discount).toFixed(2) :""}
                                                            </>
                                                        }</strong>
                                                    </td>
                                                </tr>
                                            </> : ""}
                                            <tr>
                                                Awaited Rates :
                                                {Array.isArray(data.awaitedRates) && data.awaitedRates.length >= 1 ? data.awaitedRates.map(item => {
                                                    return (
                                                        <ul>
                                                            {item.chargeFor != item.altName ?
                                                                <li> {item.chargeFor} : {item.altName} </li>
                                                                :
                                                                <li> {item.chargeFor} </li>
                                                            }
                                                        </ul>
                                                    );
                                                }) : undefined}
                                            </tr>
                                        </table>

                                    </div>
                                    <div className="terms-width list-fare">

                                        <p className="mt-2"><strong>TERMS & CONDITIONS</strong></p>
                                        <ul>
                                            {/*<li>Rates quoted are valid subject to booking and onwards space confirmation from the carrier. Rates have been quoted for general cargo & prepaid shipments originating ex:-*/}
                                            {/*    {data.row && data.row.originDetails.originName ?*/}

                                            {/*        data.row.originDetails.originName.split("(")[data.row.originDetails.originName.split("(").length - 2 ]  + " "*/}
                                            {/*        : ""}*/}
                                            {/*    Rates quoted on the basis of non volumetric shipments with 1:1 gross/volume ratio. All inn rates include :- FREIGHT + FSC + SSC + XRAY + CTG + MISC Awb + pca ams (as applicable) would be additionaly charged.</li>*/}

                                            <li>Rates are subject to the declared pick up & delivery details, any changes will incur additional charges. </li>
                                            <li>Rates are not valid for Non-Standard Shipment (Weight not more than 70 Kgs. / Dimension not more than 120 Cms.)</li>
                                            <li>Rates are applicable for general commodity & documents only and any courier commodity that requires special handling (Like Restricted Items, Dangerous Goods etc.) will not be acceptable.</li>
                                            <li>Rates are applicable on the Chargeable Weight. (Chargeable weight will be Gross or Volume weight whichever is higher). The calculation for the volume weight will be L X W X H Divided by 5000.</li>
                                            <li>Same Day Pick can be arranged for request made prior to 12 noon.</li>
                                            <li>Transit time mentioned is purely indicative and should be considered as tentative.</li>
                                            <li>Government Taxes (if any) shall be applicable as per Government norms.</li>
                                        </ul>
                                        <p className="mt-2 pl-3" style={{marginBottom: "0rem"}}><strong>Remarks</strong></p>
                                        <ul>
                                            <li> {data.remarks !== "" ? data.remarks : undefined}</li>

                                        </ul>

                                    </div>
                                </div>
                            </div>}

                    </div>
            </>
        )};

}


export default CourierRatesCard;
