import { toastr } from "react-redux-toastr";
import {FormGroup, Label} from "reactstrap";

import React, { Component, Fragment } from "react";
import Select from "react-select";

import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { onConsigneeGetAllRequest, onShipperGetAllRequest } from "../redux/shipperConsigneeRedux/shipperConsigneeActions";
import RestClient from "../util/RestClient";
import {MANAGER_SHIPPER, MANAGER_SHIPPER_BRANCH} from "../constants";
import AsyncPaginate from "react-select-async-paginate";
import {getCustomerId} from "../util/CommonUtility";

class ShipperWithBranchDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            originData: [],
            isLoading: true,
            error: "",
            shipperData: [],
            selectionOptions: [],
            branchDetail: {},
            selectedShipper: {},
            selectedShipperBranch: undefined,
            selectionOptionsShipper: [],
            selectionOptionsShipperBranch: [],
            nodata:false,
            selectedOptionCompany:"",
            shipperAccountNo:"",
            options:[]
        };

    }

    componentDidMount() {

        this.reloadShipper().then(r => {})
        if(this.props.selectedOptionCompany){
            this.setState({selectedOptionCompany:this.props.selectedOptionCompany})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.error) {
            toastr.success(this.props.error, {
                transitionIn: "bounceIn",
                transitionOut: "bounceOut"
            });
        }

        if(this.props.isSelectionThroughName === true && this.props.selectedShipperName && this.props.selectedShipperName !== prevProps.selectedShipperName
        ){

            try {

                let shipperParameters = { start: 0, search: this.props.selectedShipperName, sortBy: "" };
                RestClient({
                    url: MANAGER_SHIPPER,
                    method: "POST",
                    data: shipperParameters
                }).then(response=>{

                    let data = response.result.data
                    if(data !== undefined){
                        let selectionOptionsListShipper = []
                        for (let i = 0; i < data.length; i++) {
                            let { _id, shipperName, branchesInfo } = data[i]
                            let obj =
                                {
                                    value: _id,
                                    label: shipperName,
                                    branchesInfo: branchesInfo,
                                };
                            selectionOptionsListShipper.push(obj)
                        }
                        selectionOptionsListShipper.map(values=>{
                            this.setState({
                                selectedShipperName: values.label,
                                selectedShipperId: values.value,
                                selectedOptionCompany: values,
                                branchesInfo: values.branchesInfo,
                            }, () => {
                                //    console.log("selectedShipperBranches::" + JSON.stringify(this.state))
                                this.sendDataToParent()
                                // this.setBranchesSelectionFromList(this.state.branchesInfo)
                                let branchData = this.state.branchesInfo ;

                                let selectionOptionsListShipperBranch = []
                                for (let i = 0; i < branchData.length; i++) {
                                    let { _id, branchName } = branchData[i]
                                    if(_id  === this.props.selectedShipperBranchId){
                                        let obj =
                                            {
                                                value: _id,
                                                label: branchName,
                                                branchDetail: branchData[i],
                                            };
                                        selectionOptionsListShipperBranch.push(obj)
                                    }

                                }

                                this.setState({
                                    selectionOptionsShipperBranch: selectionOptionsListShipperBranch
                                });
                                selectionOptionsListShipperBranch.map(branchValues => {
                                    this.setState({
                                        selectedOptionBranch: branchValues,
                                        branchDetail: branchValues.branchDetail,
                                        selectedShipperName: this.state.selectedShipperName,
                                        selectedShipperId: this.state.selectedShipperId,
                                        selectedShipperBranchName: branchValues.label,
                                        selectedShipperBranchId: branchValues.value,
                                    })
                                    this.props.onShipperConsigneeSelected({
                                        shipperName: this.state.selectedShipperName,
                                        shipperId: this.state.selectedShipperId,
                                        shipperBranchName: branchValues.label,
                                        shipperBranchId: branchValues.value,
                                        shipperDetails: branchValues.branchDetail
                                    });
                                })


                            });
                        })
                    }





                }).catch()



            } catch (e) {
                return {
                    options: [],
                    hasMore: false,
                };
            }
        }


        if(this.props.isSelectionThroughId === true && this.props.selectedShipperId && this.props.selectedShipperId !== prevProps.selectedShipperId
            && this.props.selectedShipperBranchId && this.props.selectedShipperBranchId !== prevProps.selectedShipperBranchId
        ){

            try {
                let customerId = getCustomerId()
                let shipperParameters = { start: 0, search: "", sortBy: "",customerId:customerId };
                RestClient({
                    url: MANAGER_SHIPPER,
                    method: "POST",
                    data: shipperParameters
                }).then(response=>{

                    let data = response.result.data
                    if(data !== undefined){

                        let selectionOptionsListShipper = []
                        for (let i = 0; i < data.length; i++) {
                            let { _id, shipperName, branchesInfo } = data[i]
                            let obj =
                                {
                                    value: _id,
                                    label: shipperName,
                                    branchesInfo: branchesInfo,
                                };
                            selectionOptionsListShipper.push(obj)
                        }

                        selectionOptionsListShipper.map(values=>{
                            if(this.props.selectedShipperId === values.value){

                                this.setState({
                                    selectedShipperName: values.label,
                                    selectedShipperId: values.value,
                                    selectedOptionCompany: values,
                                    branchesInfo: values.branchesInfo,
                                }, () => {
                                    //    console.log("selectedShipperBranches::" + JSON.stringify(this.state))
                                    this.sendDataToParent()


                                });

                            }

                        })
                    }





                }).catch()



            } catch (e) {
                return {
                    options: [],
                    hasMore: false,
                };
            }
        }







        if (this.props.selectedShipperName !== this.state.selectedShipperName) {

            this.setState({
                selectedShipperName: this.props.selectedShipperName,
                selectedShipperId: this.props.selectedShipperId,
                selectedShipperBranchName: this.props.selectedShipperBranchName,
                selectedShipperBranchId: this.props.selectedShipperBranchId,
                selectedShipperAccountNo: this.props.selectedShipperAccountNo,
                shipperEmail:this.props.shipperEmail,


                branchDetail: this.props.shipperDetails ? this.props.shipperDetails : {},
                selectedOptionCompany: {
                    label: this.props.selectedShipperName,
                    value: this.props.selectedShipperId,
                    branchesInfo: []
                }, selectedOptionBranch: {
                    label: this.props.selectedShipperBranchName,
                    value: this.props.selectedShipperBranchId,
                    branchesInfo: []
                }
            })

        }
        if (this.props.selectedShipperBranch && this.state.selectedShipperBranch !== this.props.selectedShipperBranch) {

            this.setState({
                selectedShipperBranch: this.props.selectedShipperBranch
            })
        }

        // if (this.props.selectedOptionCompany && this.state.selectedOptionCompany !== this.props.selectedOptionCompany) {
        //     debugger
        //     this.setState({
        //         selectedOptionCompany: this.props.selectedOptionCompany
        //     })
        // }
        if(this.props.selectedOptionCompany && this.props.selectedOptionCompany !== prevProps.selectedOptionCompany){

            this.setState({selectedOptionCompany : this.props.selectedOptionCompany},()=>{
            })
        }
        if (this.props.selectedShipperBranchId && this.props.selectedShipperBranchId !== prevProps.selectedShipperBranchId) {


            this.setState({selectedShipperBranchId :this.props.selectedShipperBranchId })
            this.fetchShipperBranchList({id:this.props.selectedShipperId}).then(responce=>{

                let selectionOptionsListShipperBranch = []
                if(responce && responce.result !== undefined){

                    for (let i = 0; i < responce.result.length; i++) {
                        let { _id, branchName } = responce.result[i]
                        let obj =
                            {
                                value: _id,
                                label: branchName,
                                branchDetail: responce.result[i],
                            };
                        selectionOptionsListShipperBranch.push(obj)
                    }

                    this.setState({
                        selectionOptionsShipperBranch: selectionOptionsListShipperBranch
                    });

                    selectionOptionsListShipperBranch.map(Branchvalues=>{

                        if( this.state.selectedShipperBranchId === Branchvalues.value){
                            this.setState({
                                selectedOptionBranch: Branchvalues,
                                branchDetail: Branchvalues.branchDetail,
                                selectedShipperBranchName: Branchvalues.label,
                                selectedShipperBranchId: Branchvalues.value,
                            })
                            // this.props.onShipperConsigneeSelected({
                            //     shipperName: this.state.selectedShipperName,
                            //     shipperId: this.state.selectedShipperId,
                            //     shipperBranchName: Branchvalues.label,
                            //     shipperBranchId: Branchvalues.value,
                            //     shipperDetails: Branchvalues.branchDetail
                            // });
                        }

                    })

                }

            })
        }

        // if (this.props.reloadShipper && prevProps.reloadShipper !== this.props.reloadShipper) {
        //
        //     if(this.props.reloadShipper == true){
        //         this.reloadShipper().then()
        //     }
        // }
        //
        // if (this.state.shipperData !== this.props.shipperData) {
        //     this.setState({
        //         shipperData: this.props.shipperData
        //     })
        //     // toastr.success("Shipper Loaded", "" + "", {
        //     //     transitionIn: "bounceIn",
        //     //     transitionOut: "bounceOut"
        //     // });
        //     this.setSelectionFromList(this.props.shipperData ? this.props.shipperData.result : [])
        // }


    }

    fetchShipper = async (shipperParameters) => {
        {
            return await RestClient({
                url: MANAGER_SHIPPER,
                method: "POST",
                data: shipperParameters
            });
        }
    };

    reloadShipper = async () => {
        // let shipperParameters = { start: loadedOptions.length, search: search, sortBy: "" };
        let customerId = getCustomerId()
        let data = await this.fetchShipper({customerId : customerId});
        try {
            if (data.success && data.result.data !== undefined && data.result.data.length > 0) {
                //    console.log("In Save state")

                this.setState({
                    ShipperName:data.result &&  data.result.data ? data.result.data : []
                }, () => {
                    let selectionOptionsListShipper = []
                    let data = this.state.ShipperName
                    if (data) {

                        for (let i = 0; i < data.length; i++) {
                            let { _id, shipperName, branchesInfo } = data[i]
                            let obj =
                                {
                                    value: _id,
                                    label: shipperName,
                                    branchesInfo: branchesInfo,
                                };
                            selectionOptionsListShipper.push(obj)
                        }
                    }

                    this.setState({
                        selectionOptions: selectionOptionsListShipper,
                        options:selectionOptionsListShipper
                    });
                });
            }
        } catch (e) {
        }
    };

    loadOptions = async (search, loadedOptions) => {


        try {
            if(search !== ""){
                let customerId = getCustomerId()
                let shipperParameters = { search: search ,customerId : customerId};
                const response = await RestClient({
                    url: MANAGER_SHIPPER,
                    method: "POST",
                    data: shipperParameters
                });
                // debugger

                let data = response.result.data
                let selectionOptionsListShipper = []
                for (let i = 0; i < data.length; i++) {
                    let { _id, shipperName, branchesInfo } = data[i]
                    let obj =
                        {
                            value: _id,
                            label: shipperName,
                            branchesInfo: branchesInfo,
                        };
                    selectionOptionsListShipper.push(obj)
                }
                // this.setState({
                //     selectionOptionsShipper: selectionOptionsListShipper
                // })
                return {
                    options: selectionOptionsListShipper,
                    hasMore: !search,
                };
            }else{
                let customerId = getCustomerId()
                let shipperParameters = { search: "" ,customerId : customerId};
                const response = await RestClient({
                    url: MANAGER_SHIPPER,
                    method: "POST",
                    data: shipperParameters
                });
                let data = response.result.data
                let selectionOptionsListShipper = []
                for (let i = 0; i < data.length; i++) {
                    let { _id, shipperName, branchesInfo } = data[i]
                    let obj =
                        {
                            value: _id,
                            label: shipperName,
                            branchesInfo: branchesInfo,
                        };
                    selectionOptionsListShipper.push(obj)
                }
                const slicedOptions = selectionOptionsListShipper.slice(0,10)
                return {
                    options: slicedOptions,
                    hasMore: false,
                };

            }


        } catch (e) {

            return {
                options: loadedOptions,
                hasMore: false,
            };

        }


    }


    sendDataToParent = () => {
        //
        this.props.onShipperConsigneeSelected({
            shipperName: this.state.selectedShipperName,
            shipperId: this.state.selectedShipperId,
            shipperBranchName: this.state.selectedShipperBranchName,
            shipperBranchId: this.state.selectedShipperBranchId,
            shipperDetails: this.state.branchDetail,
            selectedOptionCompany: this.state.selectedOptionCompany,
            shipperAccountNo : this.state.selectedShipperAccountNo,
            shipperEmail:this.state.shipperEmail
        });
    }

    setSelectionFromList(data) {

        let selectionOptionsListShipper = []
        for (let i = 0; i < data.length; i++) {
            let { _id, shipperName, branchesInfo } = data[i]
            let obj =
                {
                    value: _id,
                    label: shipperName,
                    branchesInfo: branchesInfo,
                };
            selectionOptionsListShipper.push(obj)
        }

        this.setState({
            selectionOptionsShipper: selectionOptionsListShipper
        });
    }

    setBranchesSelectionFromList(data) {
        if(data !== undefined){
            this.fetchShipperBranchList({id:data}).then(responce=>{
                if(responce && responce.result !== undefined){
                    let selectionOptionsListShipperBranch = []
                    for (let i = 0; i < responce.result.length; i++) {
                        let { _id, branchName } = responce.result[i]
                        let obj =
                            {
                                value: _id,
                                label: branchName,
                                branchDetail: responce.result[i],
                            };
                        selectionOptionsListShipperBranch.push(obj)
                    }

                    this.setState({
                        selectionOptionsShipperBranch: selectionOptionsListShipperBranch
                    });
                }


            })

        }

    }

    fetchShipperBranchList = async (shipperParameters) => {
        {
            return await RestClient({
                url: MANAGER_SHIPPER_BRANCH,
                method: "POST",
                data: shipperParameters
            });
        }
    };

    handleChange = e => {
        let branchDetail = this.state.branchDetail
        branchDetail[e.target.name] = e.target.value
        this.setState({ branchDetail: branchDetail }, () => {
        });
    };

    sleep = ms =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, ms);
        });

    render() {
        try {
            return (
                <Fragment>
                    <div className={this.props.className ? this.props.className : "width-4"}>
                        <FormGroup disabled={this.props.hidden} hidden = {this.props.hidden}>
                            {this.props.queryFor !== "Courier"  ?
                                <Label className>Shipper Name  <sup
                                    style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                                :
                                <Label className>Sender Name <sup
                                    style={{fontSize: '14px', fontWeight: '700'}}>*</sup></Label>
                            }
                            <AsyncPaginate
                                {...this.props}
                                placeholder ="Search Shipper"
                                value={this.state.selectedOptionCompany}
                                debounceTimeout={100}
                                disable="true"
                                loadOptions={this.loadOptions}
                                onChange={values => {
                                   // if (this.props.readOnly !== true) {
                                        //console.log("selectedShipperBranches::" + JSON.stringify(values))
                                        this.setState({
                                            selectedShipperName: values.label,
                                            selectedShipperId: values.value,
                                            selectedOptionCompany: values,
                                            branchesInfo: values.branchesInfo,
                                            selectedOptionBranch: ""
                                        }, () => {
                                            //    console.log("selectedShipperBranches::" + JSON.stringify(this.state))
                                            this.sendDataToParent()
                                            this.setBranchesSelectionFromList(this.state.selectedShipperId)
                                        });
                                    ///}
                                }}
                            />

                            {/*<Select*/}
                            {/*    className="basic-single"*/}
                            {/*    classNamePrefix="select"*/}
                            {/*    name="selectedShipperName"*/}
                            {/*    value={this.state.selectionOptions.filter(option => option.value === this.props.selectedShipperId)}*/}

                            {/*    onChange={values => {*/}
                            {/*        //   console.log("selectedShipperBranches::" + JSON.stringify(values))*/}
                            {/*        this.setState({*/}
                            {/*            selectedShipperName: values.label,*/}
                            {/*            selectedShipperId: values.value,*/}
                            {/*            selectedOptionCompany: values,*/}
                            {/*            branchesInfo: values.branchesInfo,*/}
                            {/*            selectedOptionBranch:""*/}
                            {/*        }, () => {*/}
                            {/*            //    console.log("selectedShipperBranches::" + JSON.stringify(this.state))*/}
                            {/*            this.sendDataToParent()*/}
                            {/*            this.setBranchesSelectionFromList(this.state.selectedShipperId)*/}
                            {/*        });*/}
                            {/*    }}*/}
                            {/*    options={this.state.selectionOptions}*/}
                            {/*/>*/}
                            {this.props.shipperValid ? <span className="text-danger">{this.props.shipperValid}</span>:""}
                        </FormGroup>

                    </div>
                    <div className={this.props.className ? this.props.className : "width-4"}>
                        <FormGroup disabled={this.props.hidden} hidden = {this.props.hidden}>
                            {this.props.queryFor !== "Courier" ?
                                <Label className>Shipper Branch </Label>
                                :
                                <Label className>Sender Branch </Label>
                            }

                            <Select
                                className="basic-single text-black-50"
                                classNamePrefix="select"
                                name="shipperBranch"
                                readonly=""
                                value={this.state.selectedOptionBranch}

                                onChange={values => {
                                    //if (this.props.readOnly !== true) {
                                        this.setState({
                                            selectedOptionBranch: values,
                                            branchDetail: values.branchDetail,
                                            selectedShipperName: this.state.selectedShipperName,
                                            selectedShipperId: this.state.selectedShipperId,
                                            selectedShipperBranchName: values.label,
                                            selectedShipperBranchId: values.value,
                                            selectedShipperAccountNo: "",
                                            shipperEmail: values && values.branchDetail && values.branchDetail.email,
                                        }, () => {
                                            this.sendDataToParent()
                                        })
                                    ///}
                                }}
                                options={this.state.selectionOptionsShipperBranch}
                            />
                            {this.props.shipperBranchId ? <span className="text-danger">{this.props.shipperBranchId}</span>:""}
                        </FormGroup>

                    </div>


                </Fragment>
            );
        } catch (e) {
            return ("");
            // return <Input type="select" required>
            //     <option> Please Reload</option>
            // </Input>

        }
    }


}

const propTypes = {
    isSelectionThroughName: PropTypes.bool.isRequired,
    selectedShipper: PropTypes.string.isRequired,
    selectedShipperName: PropTypes.string.isRequired,
    selectedShipperBranch: PropTypes.string.isRequired,
    selectedShipperBranchName: PropTypes.string.isRequired,
    onShipperConsigneeSelected: PropTypes.func.isRequired,
};

const defaultProps = {
    isSelectionThroughName: false,
    selectedShipperName: undefined,
    selectedShipperId: undefined,
    selectedShipperBranchName: undefined,
    selectedShipperBranchId: undefined,
    onShipperConsigneeSelected: undefined,
};

ShipperWithBranchDropdown.propTypes = propTypes;
ShipperWithBranchDropdown.defaultProps = defaultProps;


const mapStateToProps = state => {
    const { shipperData, } = state.shipperConsigneeReducer;
    return { shipperData, };
};


export default connect(mapStateToProps, {
    onShipperGetAllRequest,
    onConsigneeGetAllRequest
})(ShipperWithBranchDropdown);

