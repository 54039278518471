import {
    INVOICE_FETCH_FAILURE,
    INVOICE_FETCH_MASTER,
    INVOICE_FETCH_RESPONSE,
    INVOICE_PREVIEW,
    INVOICE_CREATE,
    INVOICE_CREATE_RESPONSE,
    INVOICE_UPDATE,
    INVOICE_UPDATE_RESPONSE,
    INVOICE_DELETE,
    INVOICE_DELETE_RESPONSE,
    INVOICE_PRINT_REQUEST,
    INVOICE_PRINT_REQUEST_SUCCESS,
    INVOICE_PRINT_REQUEST_FAIL,
    INVOICE_GETDATA_REQUEST,
    INVOICE_GETDATA_REQUEST_SUCCESS,
    INVOICE_GETDATA_REQUEST_FAIL,
    INVOICE_CHECKED_REQUEST,
    INVOICE_CHECKED_REQUEST_SUCCESS,
    INVOICE_CHECKED_REQUEST_FAIL,
} from "../actions";

export const onInvoiceMasterLoadRequest = (InvoiceMasterApiParameters) => ({
    type: INVOICE_FETCH_MASTER,
    payload: InvoiceMasterApiParameters
});

export const onInvoiceMasterFetched = (InvoiceMasterResponse) => ({
    type: INVOICE_FETCH_RESPONSE,
    payload: InvoiceMasterResponse
});

export const onInvoiceMasterFetchedFailed = (message) => ({
    type: INVOICE_FETCH_FAILURE,
    payload: {message}
});

export const saveInvoice = (InvoiceDetails) => ({
    type: INVOICE_CREATE,
    payload: InvoiceDetails
});

export const saveInvoiceResponse = (message) => ({
    type: INVOICE_CREATE_RESPONSE,
    payload: {message}
});



export const updateInvoice = (InvoiceDetails) => ({
    type: INVOICE_UPDATE,
    payload: InvoiceDetails
});

export const previewInvoiceDetail = (InvoiceDetails) => ({
    type: INVOICE_PREVIEW,
    payload: InvoiceDetails
});

export const SelectAirlinesInvoice = (InvoiceDetails) => ({
    type: INVOICE_PREVIEW,
    payload: InvoiceDetails
});

export const deleteInvoiceDetail = (InvoiceDetails) => ({
    type: INVOICE_DELETE,
    payload: InvoiceDetails
});


export const deleteInvoiceResponse = (message) => ({
    type: INVOICE_DELETE_RESPONSE,
    payload: {message}
});

export const updateInvoiceResponse = (message) => ({
    type: INVOICE_UPDATE_RESPONSE,
    payload: {message}
});



export const onInvoicePrintLoadRequest = (InvoiceMasterApiParameters) => ({
    type: INVOICE_PRINT_REQUEST,
    payload: InvoiceMasterApiParameters
});
export const onInvoicePrintFetched = (InvoiceMasterResponse) => ({
    type: INVOICE_PRINT_REQUEST_SUCCESS,
    payload: InvoiceMasterResponse
});
export const onInvoicePrintFetchedFailed = (message) => ({
    type: INVOICE_PRINT_REQUEST_FAIL,
    payload: {message}
});
export const getInvoiceData = (InvoiceMasterApiParameters) => ({
    type: INVOICE_GETDATA_REQUEST,
    payload: InvoiceMasterApiParameters
});

export const getInvoiceDataFetched = (InvoiceMasterResponse) => ({
    type: INVOICE_GETDATA_REQUEST_SUCCESS,
    payload: InvoiceMasterResponse
});
export const getInvoiceDataFailed = (message) => ({
    type: INVOICE_GETDATA_REQUEST_FAIL,
    payload: {message}
});

export const sendtocheked = (InvoiceMasterApiParameters) => ({
    type: INVOICE_CHECKED_REQUEST,
    payload: InvoiceMasterApiParameters
});
export const sendtochekedFetched = (InvoiceMasterResponse) => ({
    type: INVOICE_CHECKED_REQUEST_SUCCESS,
    payload: InvoiceMasterResponse
});
export const sendtochekedFailed = (message) => ({
    type: INVOICE_CHECKED_REQUEST_FAIL,
    payload: {message}
});