import {Input} from "reactstrap";
import React, {Component, Fragment} from "react";
import RestClient from "../util/RestClient";
import {GET_CURRENCY_LIST_FOR_REGISTRATION} from "../constants";

import Select from "react-select";

class   CurrencyDropDownForRegistration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countries: [],
            isLoading: true,
            error: "",
            selectedCountry: undefined,
            selectionOptions: []
        };
    }

    reloadCountries = async () => {
        let data = await this.fetchCountries();
        try {
            if (data.success && data.result !== undefined && data.result.length > 0) {
                // console.log("In Save countries")
                localStorage.setItem("countriesMasterCurrency", JSON.stringify(data));
                this.setState({
                    countries: data.result
                });
            }

        } catch (e) {
        }
    };

    getSelectedIndex = (label) => {
        for (let i = 0; i < this.state.selectionOptions.length; i++) {
            if (this.state.selectionOptions[i].label === label) {
                // console.log(JSON.stringify(this.state.selectionOptions[i]));
                return this.state.selectionOptions[i]

            }
        }
    }

    fetchCountries = async () => {
        {
            return await RestClient({
                url: GET_CURRENCY_LIST_FOR_REGISTRATION,
                method: "POST",
                data: null
            });
        }
    };

    componentDidMount() {
        if (
            localStorage.getItem("countriesMasterCurrency") === undefined ||
            localStorage.getItem("countriesMasterCurrency") === null ||
            localStorage.getItem("countriesMasterCurrency").length === 0
        ) {
            this.reloadCountries().then(response => {
                let selectionOptionsList = []
                if(!localStorage.getItem("countriesMasterCurrency") === false){
                    let data = JSON.parse(localStorage.getItem("countriesMasterCurrency")).result
                    for (let i = 0; i < data.length; i++) {
                        let {currencyCode} = data[i]
                        let obj =
                            {
                                value: currencyCode,
                                label: currencyCode,
                                // currencyRate:currencyRate
                            };
                        selectionOptionsList.push(obj)
                    }

                    this.setState({
                        selectionOptions: selectionOptionsList
                    });
                }

            }).catch()
        } else {
            let selectionOptionsList = []
            let data = JSON.parse(localStorage.getItem("countriesMasterCurrency")).result
            for (let i = 0; i < data.length; i++) {
                let {currencyCode} = data[i]
                let obj =
                    {
                        value: currencyCode,
                        label: currencyCode,

                    };
                selectionOptionsList.push(obj)
            }

            this.setState({
                selectionOptions: selectionOptionsList
            });
        }
    }


    render() {
        if (
            localStorage.getItem("countriesMasterCurrency") === undefined ||
            localStorage.getItem("countriesMasterCurrency") === null
        ) {
            return (
                <Input type="select" required>
                    <option> Please Use Refresh Icon</option>
                </Input>
            );
        } else {
            try {
                return (
                    <Fragment>
                        {/*<div className="mr-2">*/}
                        {/*    <Row>*/}
                        {/*        <Colxx xxs="11">*/}
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name="currencyName"
                                        value={this.state.selectionOptions.filter(option => option.value  === this.props.selectedCurrency)}
                                        onChange={values => {
                                            this.props.handler({
                                                currencyName:values.label,
                                                currencyCode: values.value,

                                            });
                                        }}
                                        options={this.state.selectionOptions}
                                    />
                            {/*    </Colxx>*/}
                            {/*</Row>*/}
                        {/*</div>*/}
                    </Fragment>
                );
            } catch (e) {
                console.log(e)
                return (
                    <Input type="select" required>
                        <option> Please Reload</option>
                    </Input>
                );
            }
        }


    }

}


export default CurrencyDropDownForRegistration;
