import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    GET_CHARGES,
    GET_COURIER_CHARGES,
    GET_SPOT_BOOKING_RATE,
    ORIGIN_GET_LIST, SPOT_RATE_QUERY_STORE,
    STORE_QUERY,STORE_COURIER_QUERY, STORE_SELECTED_ALL_RATE, UPDATE_QUERY,
    STORE_SELECTED_BOOKING_RATE,
} from "../actions";

import {
    getOriginListSuccess,
    getOriginListError,
    getChargesSuccess,
    getChargestError,
    sendQuerySuccess,
    sendQueryError,
    sendBookingRatesSuccess,
    sendBookingRatesError,
    spotRateChargesError,
    spotRateChargesSuccess,
    SpotRateQueryAddError,
    SpotRateQueryAddSuccess,
    sendRatesForStoreSuccess,
    sendRatesForStoreError,
    sendCourierQuerySuccess,
    sendCourierQueryError,
    getCourierChargesSuccess,
    getCourierChargestError, updateQueryError, updateQuerySuccess
} from "./advancSearchAction";
import {
    GET_CHARGES_API,
    GET_CHARGES_API_AIR,
    GET_CHARGES_API_OCEAN,
    GET_CHARGES_API_ROAD,
    GET_CHARGES_API_COURIER,
    GET_ORIGIN_API,
    SPOT_RATE_BOOKING_API,
    SPOT_RATE_QUERY_STORE_API,
    STORE_BOOKING_API,
    STORE_BOOKING_API_AIR,
    STORE_BOOKING_API_OCEAN,
    STORE_BOOKING_API_COURIER,
    STORE_BOOKING_API_ROAD,
    STORE_QUERY_API,
    STORE_QUERY_API_AIR,
    STORE_QUERY_API_OCEAN,
    STORE_QUERY_API_ROAD,
    STORE_QUERY_API_COURIER,
    STORE_QUERY_RATES_API,
    STORE_QUERY_COURIER_API,
    GET_COURIER_CHARGES_API,
    GET_COURIER_CHARGES_API_ROAD,
    UPDATE_QUERY_API,
    UPDATE_QUERY_API_AIR,
    UPDATE_QUERY_API_OCEAN,
    UPDATE_QUERY_API_ROAD,
    UPDATE_QUERY_API_COURIER,
    STORE_QUERY_RATES_API_AIR,
    STORE_QUERY_RATES_API_OCEAN,
    STORE_QUERY_RATES_API_ROAD,
    STORE_QUERY_RATES_API_COURIER,
    ZIPA_SUGGESTION_BUY_SELL_CREATE,
} from "../../constants";
import RestClient from "../../util/RestClient";



export function* watchGetSearch() {
    yield takeEvery(ORIGIN_GET_LIST, getOriginListItems);
    yield takeEvery(GET_CHARGES, getChargesItems);
    yield takeEvery(GET_COURIER_CHARGES, getCourierChargesItems);
    yield takeEvery(STORE_QUERY, storeQueryItems);
    yield takeEvery(UPDATE_QUERY, updateQueryItems);
    yield takeEvery(STORE_COURIER_QUERY, storeCourierQueryItems);
    yield takeEvery(STORE_SELECTED_ALL_RATE, storeQueryRatesForData);
    yield takeEvery(STORE_SELECTED_BOOKING_RATE, storeBookingRate);
    yield takeEvery(GET_SPOT_BOOKING_RATE, GetSpotBookingRate);
    yield takeEvery(SPOT_RATE_QUERY_STORE, storeSpotRateQuery);
}

function* getOriginListItems({payload}) {
    try {
        const response = yield call(fetchOriginList, payload);
        if (response.success) {
            yield put(getOriginListSuccess(response.result));
        } else {
            // console.log("Not Success" + response);

            yield put(getOriginListError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(getOriginListError(error.response.data.message));
        }

    }
}
const fetchOriginList = async (params) => {
    // console.log(JSON.stringify("In Hit API" + JSON.stringify(params)));
    return await RestClient({
        url: GET_ORIGIN_API,
        method: "POST",
        data: params
    })

};
function* getChargesItems({payload}) {
    try {
        const response = yield call(fetchCharges, payload);
        if (response.success) {
            yield put(getChargesSuccess(response));


        } else {
            toast.error('Sorry Charges Not available!', {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(getChargestError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(getChargestError(error.response.data.message));
        }

    }
}

const fetchCharges = async (params) => {
     /*console.log(JSON.stringify("In Hit API" + JSON.stringify(params)));
     console.log(JSON.stringify("In Hit API" + params.queryFor));*/

    return await RestClient({
        url: params && params.queryFor ==="Air" ? GET_CHARGES_API_AIR : params && params.queryFor ==="Ocean" ? GET_CHARGES_API_OCEAN : params && params.queryFor ==="Road" ? GET_CHARGES_API_ROAD : GET_CHARGES_API_COURIER,
        //url:GET_CHARGES_API,
        method: "POST",
        data: params
    })

};

function* getCourierChargesItems({payload}) {
    try {
        const response = yield call(fetchCourierCharges, payload);
        if (response.success) {
            yield put(getCourierChargesSuccess(response));
            // toast.success(' Charges Get Successfully!', {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
        } else {
            toast.error('Sorry Charges Not available!', {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // console.log("Not Success" + response);
            yield put(getCourierChargestError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(getChargestError(error.response.data.message));
        }

    }
}

const fetchCourierCharges = async (params) => {
    // console.log(JSON.stringify("In Hit API" + JSON.stringify(params)));
    return await RestClient({
        url: params && params.queryFor === "Road" ? GET_COURIER_CHARGES_API_ROAD : params && params.queryFor === "Courier" ? GET_COURIER_CHARGES_API : GET_COURIER_CHARGES_API,
        method: "POST",
        data: params
    })

};

function* storeQueryItems({payload}) {
    try {
        const response = yield call(storeQuerApi, payload);
        if (response.success) {
            // toast.info('Query Added Successfully', {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            yield put(sendQuerySuccess(response.result));

        } else {
            // console.log("Not Success" + response);
            toast.error(response.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(sendQueryError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(sendQueryError(error.response.data.message));
        }

    }
}
const storeQuerApi = async (params) => {
    // console.log(JSON.stringify("In Hit API" + JSON.stringify(params)));
    return await RestClient({
        url: params && params.queryFor === "Air" ? STORE_QUERY_API_AIR : params && params.queryFor === "Ocean" ? STORE_QUERY_API_OCEAN : params && params.queryFor === "Courier" ? STORE_QUERY_API_COURIER : STORE_QUERY_API_ROAD,
        method: "POST",
        data: params
    })

};

function* updateQueryItems({payload}){
    try {
        const response = yield call(updateQueryApi, payload);
        if (response.success) {
            yield put(updateQuerySuccess(response.result));

        } else {
            // console.log("Not Success" + response);
            toast.error(response.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(updateQueryError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            yield put(updateQueryError(error.response.data.message));
        }

    }
}
const updateQueryApi = async (params) => {
    return await RestClient({
        url: params && params.queryFor ==="Air" ? UPDATE_QUERY_API_AIR : params && params.queryFor ==="Ocean" ? UPDATE_QUERY_API_OCEAN : params && params.queryFor ==="Road" ? UPDATE_QUERY_API_ROAD : params && params.queryFor ==="Courier" ? UPDATE_QUERY_API_COURIER : UPDATE_QUERY_API,
        method: "POST",
        data: params
    })

};


function* storeCourierQueryItems({payload}) {
    try {
        const response = yield call(storeCourierQuerApi, payload);
        if (response.success) {
            // toast.info('Query Added Successfully', {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            yield put(sendCourierQuerySuccess(response.result));

        } else {
            // console.log("Not Success" + response);
            toast.error(response.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(sendCourierQueryError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(sendCourierQueryError(error.response.data.message));
        }

    }
}
const storeCourierQuerApi = async (params) => {
    // console.log(JSON.stringify("In Hit API" + JSON.stringify(params)));
    return await RestClient({
        url: params && params.queryFor === "Courier" ? STORE_QUERY_COURIER_API : STORE_QUERY_API_ROAD,
        method: "POST",
        data: params
    })

};


function* storeQueryRatesForData({payload}) {
    try {
        const response = yield call(storeQuerRatesDataApi, payload);
        if (response.success) {
            // toast.info('Query Added Successfully', {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            yield put(sendRatesForStoreSuccess(response.result));

        } else {
            // console.log("Not Success" + response);
            // toast.error(response.message, {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            yield put(sendRatesForStoreError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(sendQueryError(error.response.data.message));
        }

    }
}
const storeQuerRatesDataApi = async (params) => {
   let queryType = localStorage.getItem('queryType')

    return await RestClient({
        url: queryType === "AirQuery" ? STORE_QUERY_RATES_API_AIR : queryType === "OceanQuery" ? STORE_QUERY_RATES_API_OCEAN : queryType === "RoadQuery" ? STORE_QUERY_RATES_API_ROAD : STORE_QUERY_RATES_API_COURIER ,
        method: "POST",
        data: params
    })

};

function* storeBookingRate({payload ,suggestion}) {
    try {
        const response = yield call(storeBookingRateApi, payload,suggestion);
        if (response.success) {
            // toast.success('Booking Confirmed Successfully', {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            // localStorage.removeItem("selectedCharge")
            localStorage.removeItem("SpotRateQuery")
            localStorage.removeItem("countriesMaster")
            localStorage.removeItem("ShowShipperAndConsignee")
            localStorage.removeItem("ContactDeatails")
            yield put(sendBookingRatesSuccess(response));

        } else {
            // console.log("Not Success" + response);
            toast.error(response.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(sendBookingRatesError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(sendBookingRatesError(error.response.data.message));
        }

    }
}
const storeBookingRateApi = async (params,suggestion) => {
   
    if(suggestion){
        return await RestClient({
            url: ZIPA_SUGGESTION_BUY_SELL_CREATE,
            method: "POST",
            data: params
        })

    } else {
        return await RestClient({
            url: params && params.revertData && params.revertData.queryFor === "Air" ? STORE_BOOKING_API_AIR : params && params.revertData && params.revertData.queryFor === "Ocean" ? STORE_BOOKING_API_OCEAN : params && params.revertData && params.revertData.queryFor === "Road" ? STORE_BOOKING_API_ROAD : STORE_BOOKING_API_COURIER,
            method: "POST",
            data: params
        })

    }
    

};


function* GetSpotBookingRate({payload}) {
    try {
        const response = yield call(getSpotBookingRateApi, payload);
        if (response.success) {
            // toast.success('Booking Confirmed Successfully', {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            yield put(spotRateChargesSuccess(response.result));

        } else {
            // console.log("Not Success" + response);
            toast.error(response.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(spotRateChargesError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(spotRateChargesError(error.response.data.message));
        }

    }
}
const getSpotBookingRateApi = async (params) => {
    // console.log(JSON.stringify("In Hit API" + JSON.stringify(params)));
    return await RestClient({
        url: SPOT_RATE_BOOKING_API,
        method: "POST",
        data: params
    })
};

function* storeSpotRateQuery({payload}) {
    try {
        const response = yield call(storSpotrateApi, payload);
        if (response.success) {
            // toast.success('Spot Query Added Successfully', {
            //     position: "top-left",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            // localStorage.removeItem("spotRateSelectedCharge")
            yield put(SpotRateQueryAddSuccess(response.result));

        } else {
            // console.log("Not Success" + response);
            toast.error(response.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(SpotRateQueryAddError(response.message));
        }
    } catch
        (error) {
        if (Object.keys(error).length !== 0) {
            // console.log("In Error fetch " + JSON.stringify(error));
            yield put(SpotRateQueryAddError(error.response.data.message));
        }

    }
}
const storSpotrateApi = async (params) => {
    // console.log(JSON.stringify("In Hit API" + JSON.stringify(params)));
    return await RestClient({
        url: SPOT_RATE_QUERY_STORE_API,
        method: "POST",
        data: params
    })

};

export default function* rootSaga() {
    yield all([
        fork(watchGetSearch)
    ]);
}
