import React from 'react';
import {createBrowserHistory} from 'history';


const routeRedirectTo = createBrowserHistory();
export default routeRedirectTo;


export const RouteTo = {
    ERROR_PAGE: "/error",

};
