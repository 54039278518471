import React, {Component, Fragment} from "react";
import {Row, Card, CardTitle, Label, Input, Button, Modal, ModalHeader, ModalBody, FormGroup} from "reactstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IntlMessages from "../../../helpers/IntlMessages";

import SimpleReactValidator from "simple-react-validator";


import {Field, Form, Formik} from "formik";




import {withRouter} from "react-router";
import RestClient from "../../../util/RestClient";
import {CUSTOMER_DATA_FOR_POS, PAY_LATER_API} from "../../../constants";
import {PAGE_PATHS} from "../../../util/PageRoute";

class PayLaterPerforma extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactEmail:"",
            contactNumber:"",
            phoneNo: "",
            modalLarge: false,
            message: "",
            openLogin: false,
            Compalsari: false,
            contractNo:"",
            proformaId:"",
            customerId:"",
            bookingid:"",
            totalAmount:""
        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {


        if (this.props.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }
        if (this.props.Compalsari) {
            this.setState({
                Compalsari: this.props.Compalsari
            })
        }
        if (this.props.performaId) {
            this.setState({
                proformaId: this.props.performaId
            })
        }
        if (this.props.customerId) {
            this.setState({
                customerId: this.props.customerId
            })
        }
        if (this.props.bookingid) {
            this.setState({
                bookingid: this.props.bookingid
            })
        }
        if (this.props.totalAmount ) {
            this.setState({
                totalAmount: this.props.totalAmount
            })
        }

    }

    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }),()=>{
            if (this.state.modalLarge === false){
                this.props.setmodal(false)
            }
        });
    };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})

    }

    // validateEmail = (value) => {
    //
    //     if (!value) {
    //         toast.error("Please enter your email address", {
    //             position: "top-left",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    //
    //         toast.error("Invalid email address", {
    //             position: "top-left",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //         this.setState({
    //             contactEmail:""
    //         })
    //     }
    //
    // }

    // validatePhonNumber = (value) => {
    //
    //     if (!value) {
    //         toast.error("Please enter your Phone Number", {
    //             position: "top-left",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     } else if (!/^\d{10}$/.test(value)) {
    //
    //         toast.error("Phone Number Should be 10 digit", {
    //             position: "top-left",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //         this.setState({
    //             contactNumber:""
    //         })
    //     }
    //
    // }




    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.modalLarge && this.props.modalLarge !== prevProps.modalLarge) {
            this.setState({
                modalLarge: this.props.modalLarge
            })
        }

        if (this.props.Compalsari && this.props.Compalsari !== prevProps.Compalsari) {
            this.setState({
                Compalsari: this.props.Compalsari
            })
        }

        if (this.props.performaId && this.props.performaId !== prevProps.performaId) {
            this.setState({
                proformaId: this.props.performaId
            })
        }
        if (this.props.totalAmount && this.props.totalAmount !== prevProps.totalAmount) {
            this.setState({
                totalAmount: this.props.totalAmount
            })
        }
        if (this.props.customerId && this.props.customerId !== prevProps.customerId) {
            this.setState({
                customerId: this.props.customerId
            })
        }

        if (this.props.bookingid && this.props.bookingid !== prevProps.bookingid) {
            this.setState({
                bookingid: this.props.bookingid
            })
        }


    }

    handleChange = selectedOption => {

        this.setState({selectedOption});

    };

    handleSubmit =()=>{
debugger

           if(this.mandatoryValidations.allValid() && this.state.contractNo !== ""  )
           {
               let data = {
                   contractNo:this.state.contractNo,
                   customerId:this.state.customerId,
                   proformaId:this.state.proformaId,
                   amount:this.state.totalAmount,
               }
               this.PaylaterCheck(data).then(responce=>{
                   if(responce.success){
                       toast.success(responce.message, {
                           position: "top-left",
                           autoClose: 3000,
                           hideProgressBar: false,
                           closeOnClick: true,
                           pauseOnHover: true,
                           draggable: true,
                           progress: undefined,
                       });
                       this.props.history.push(PAGE_PATHS.EDIT_MAWB + "/" + this.state.bookingid)
                       this.setState({
                           modalLarge: false
                       })
                       this.props.setmodal(false)
                   }else {
                       toast.error(responce.message, {
                           position: "top-left",
                           autoClose: 3000,
                           hideProgressBar: false,
                           closeOnClick: true,
                           pauseOnHover: true,
                           draggable: true,
                           progress: undefined,
                       });
                   }

               })


           }else {
               this.mandatoryValidations.showMessages();
               this.forceUpdate();
           }

    }

    async PaylaterCheck(body) {
        return await RestClient({
            url: PAY_LATER_API,
            method: "POST",
            data: body
        });
    }


    render() {
        const {password, email, repassword, customerName, gstNo, phoneNo, selectedState} = this.state;
        const initialValues = {email, password, repassword, customerName, gstNo, phoneNo, selectedState};

        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Modal className="contactsize"
                    isOpen={this.state.modalLarge}
                    size="md"
                    toggle={this.toggleLarge}
                >

                        <>
                            <ModalHeader toggle={this.toggleLarge} className="" style={{padding: "0"}}>
                                {/*<NavLink to={`/`} className=" white">*/}
                                {/*    /!*<span className="logo-single" style={{marginBottom: "-9px"}}/>*!/*/}
                                {/*</NavLink>*/}
                            </ModalHeader>
                            <ModalBody className="padding-0">
                                <div className="back-img" style={{padding: "0px;"}}>

                                    <div className="auth-card1">


                                        <div className="cont-forms form-side">

                                            <h5 className="mb-5  text-center" style={{fontWeight: "bold"}} >
                                                <span>CONTRACT INFORMATION</span></h5>
                                            <form className="av-tooltip tooltip-label-bottom">
                                                <div className="form-group"><label
                                                    className=""><span>Contract Number</span></label>
                                                    <Input type="text" className="form-control"
                                                           placeholder="Contract Number"
                                                           name="contractNo"
                                                           // onBlur={e=>{this.validatePhonNumber(e.target.value)}}
                                                           value={this.state.contractNo}
                                                           onChange={e=>{this.setState({contractNo: e.target.value})} }
                                                    />
                                                    <p style={{color:"#ff1515",fontWeight:"700"}}>{this.mandatoryValidations.message(
                                                        "Contact",
                                                        this.state.contractNo,
                                                        "required",
                                                    )}</p>
                                                </div>

                                                <div className="form-group mt-4">

                                                    <button className="book-btn btn " style={{borderRadius: "50px;"}}
                                                    onClick={e=>{e.preventDefault()
                                                    this.handleSubmit()
                                                    }}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>


                                </div>
                            </ModalBody>
                        </>




                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {user, loading, message, LoginMessage, closeModal} = state.authUser;
    return {user, loading, message, LoginMessage, closeModal};
};

const PayLaterPerformaWithRoute = withRouter(PayLaterPerforma);

export default connect(
    mapStateToProps,
    {}
)(PayLaterPerformaWithRoute);
