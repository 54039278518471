import {API_END_POINT_LOCAL, GET_STATE_LIST} from '../../../../constants'
import RestClient from "../../../../util/RestClient";

async function postData(url, body) {
    try {
        let _r = await RestClient({
            url: url,
            method: "POST",
            data: body
        })
       // console.log("post rrrrrrrrrrr", _r)
        return _r
    } catch
        (e) {

    }
}


const location = {
    selectCountry: async (body) => {
        const url = API_END_POINT_LOCAL + `users/masters/country/getAll`
        let r = await postData(url, body)
        return r
    },
    selectCountryForRegistration: async (body) => {
        const url = API_END_POINT_LOCAL + `unAuth/cronJobs/currency/countryGetAll`
        let r = await postData(url, body)
        return r
    },
    getstate: async (body) => {
        const url = API_END_POINT_LOCAL + `users/masters/states/getByCountry`
        let r = await postData(url, body)
        return r
    },
    getstateForRegistration: async (body) => {
        const url = API_END_POINT_LOCAL + GET_STATE_LIST
        let r = await postData(url, body)
        return r
    },
    getUser: async (body) => {
        const url = API_END_POINT_LOCAL + `users/user/getAll`
        let r = await postData(url, body)
        return r
    },
    companyAndBranches: async (body) => {
        const url = API_END_POINT_LOCAL + `users/masters/company/getAll`
        let r = await postData(url, body)
        return r
    },
    getHawbUnique: async (body) => {
        const url = API_END_POINT_LOCAL + `users/airExport/hawbStock/getHawbUnique`
        let r = await postData(url, body)
        return r
    },
    createCustomer: async (body) => {
        const url = API_END_POINT_LOCAL + `users/masters/customer/create`
        let r = await postData(url, body)
        return r
    },
    updateCustomer: async (body) => {
        const url = API_END_POINT_LOCAL + `users/masters/customer/update`
        let r = await postData(url, body)
        return r
    },
    deleteCustomer: async (body) => {
        const url = API_END_POINT_LOCAL + `users/masters/customer/delete`
        let r = await postData(url, body)
        //console.log("service rrrrrr", r)
        return r
    },
    getAllcustomer: async (body) => {
        const url = API_END_POINT_LOCAL + "users/masters/customer/manager"
        let r = await postData(url, body)
        return r
    },
    createBranch: async (body) => {
        const url = API_END_POINT_LOCAL + "users/masters/customer/branch/create"
        let r = await postData(url, body)
        return r
    },
    updateBranch: async (body) => {
        const url = API_END_POINT_LOCAL + "users/masters/customer/branch/update"
        let r = await postData(url, body)
        return r
    }

}

export default {
    location
}