import React, {Component} from "react";

import {getCurrenctCompanyBranchData} from "../../../util/CommonUtility";
import RestClient from "../../../util/RestClient";
import {GUEST_CUSTOMER_DATA} from "../../../constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {Link} from "react-router-dom";
library.add(fab)

class FooterHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            title: "title",
            detail: "detail",
            percent: 80,
            progressText: "8/10",
            BookingID: "1",
            login: undefined,
            showCollaps:false,
            showCollapsForRate:false,
            LogoFooter : "https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5998214349555855-1640085198550.png",
            // LogoFooter : "https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.43087163708218656-1672405888553.png",
        };

    }
    componentDidMount() {
        let locationHome = window.location.href.split("/")[window.location.href.split("/").length - 1];
        if (localStorage.getItem("already_registered") === "true" && localStorage.getItem("auth_key") !== undefined) {
            let CompanyData  =  getCurrenctCompanyBranchData();
            let LogoHeader = CompanyData && CompanyData.branchCompanyLogo
            this.setState({
                LogoFooter : LogoHeader
            })
        }else{
            if(locationHome !== "AirFreight" && locationHome !== "OceanFreight" && locationHome !=="RoadFreight" && locationHome !=="CourierFreight" && locationHome !=="WarehousingSolutions" && locationHome !=="CustomsBrokerage" && locationHome !=="PackagingServices" && locationHome !=="TradeFinance"
                && locationHome !=="About" && locationHome !== "OurTeam" && locationHome !== "AwardsAndRecognition" && locationHome !== "LifeAtZipaworld" && locationHome !== "Pricing" && locationHome !== "FaqPage" && locationHome !=="CareerPage"){
                this.getDummyCustomerData().then()
            }else{

            }
        }
    }
    getDummyCustomerData = async () => {
        this.GetGuestCustomerData().then(responce => {
            let customerData = responce && responce.result ? responce.result : undefined;
            if (customerData !== undefined) {
                let CompanyData = customerData && customerData.csBuddyData && customerData.csBuddyData.branchId ? customerData.csBuddyData.branchId : null;
                let LogoHeader = CompanyData && CompanyData.branchCompanyLogo
                this.setState({
                    LogoFooter : LogoHeader
                })
            }
        })

    };
    GetGuestCustomerData = async (dataForServer) => {
        return await RestClient({
            url: GUEST_CUSTOMER_DATA,
            method: "POST",
            data: dataForServer
        });
    }


    render() {
        const {companyLogo} = this.state.comapny !== undefined ? this.state.comapny : {} ;

        let CompanyData  =  getCurrenctCompanyBranchData();


        return (
            <>
                <footer className="footer position-relative">
                    <div className="footer-black pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="foot_logos text-center">
                                        {/*<img data-src={this.state.LogoFooter ? this.state.LogoFooter : "https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.43087163708218656-1672405888553.png"} alt="footerLogo" className="lazyload img-fluid"  width={"100%"} height={"100%"}/>*/}
                                        {/*<img data-src={"https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6791711182543703-1635936860355.png"} alt="footerLogo" className="lazyload img-fluid"  width={"100%"} height={"100%"}/>*/}
                                        {/*//////////////////////////////christmas logo//////////////////////////////*/}
                                        <img data-src={"https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.7682568786368993-1711429836089.png"} alt="footerLogo" className="lazyload img-fluid"  width={"100%"} height={"100%"}/>

                                    </div>
                                </div>
                            </div>
                            <div className="row footer-orange">
                                <div className="container">
                                    <div className="row">
                                        <h3 className="cont-head">CONTACT US</h3>
                                    </div>
                                    <div className="row p-4">
                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                            <h5>Address Corporate Office</h5>
                                            <p>B 29, 3rd floor Sector 1, <br/>
                                                Noida-201301, Uttar Pradesh</p>

                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                            <h5>E-mail</h5>
                                            <p><a href="mailto:@aaa2innovate.com"><span
                                                className="text-bold">info</span>@zipaworld.com</a></p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                            <h5>PHONE</h5>
                                            <p><a href="tel:+0120-6916910">+91 120 691 6910 </a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-12 footer--follow mb-3">
                                    <h3 className="text-center" style={{color:'#ff0600'}}>follow us</h3>
                                    <div className="social_media d-flex flex-wrap align-items-center justify-content-center">
                                        <h4><a href="https://www.linkedin.com/company/zipaworld/" target="_blank">
                                            <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="lg" className="footerIconSocial"/> LinkedIn</a></h4>
                                        <h4><a href="https://www.facebook.com/zipaworld" target="_blank">
                                            <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" className="footerIconSocial"/> Facebook</a></h4>
                                        <h4><a href="https://twitter.com/zipaworld" target="_blank">
                                            <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" className="footerIconSocial"/> Twitter</a></h4>
                                        <h4><a href="https://www.instagram.com/zipaworld/" target="_blank">
                                            <FontAwesomeIcon icon={['fab', 'instagram']} size="lg" className="footerIconSocial"/> Instagram</a></h4>
                                        <h4><a href="https://www.youtube.com/@zipaworld1133" target="_blank">
                                            <FontAwesomeIcon icon={['fab', 'youtube']} size="lg" className="footerIconSocial"/> Youtube</a></h4>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <ul className="nav-2 footer--follow d-flex flex-wrap justify-content-center align-items-center">
                                        <li>
                                            <Link to="/Contact" className="footer-link" style={{fontWeight:'700',color:'#000'}}>Contact Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/FaqPage" className="footer-link" style={{fontWeight:'700',color:'#000'}}>FAQ</Link>
                                        </li>
                                        <li><Link to="/Privacy" className="footer-link" style={{fontWeight:'700',color:'#000'}}>
                                            Privacy Policy</Link></li>
                                        <li><Link to="/TermsCondition" className="footer-link" style={{fontWeight:'700',color:'#000'}}>
                                            Terms and conditions</Link></li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </footer>

            </>
        );
    }

}


export default FooterHome;
