import React, {Component} from "react";
import {injectIntl} from "react-intl";

import {connect} from "react-redux";
import "../../assets/css/style1.css"
import {PAGE_PATHS} from "../../util/PageRoute";
import {
    onConsigneeMasterLoadRequest,
    onShipperMasterLoadRequest, previewConsigneeMasterDetail,
    previewShipperMasterDetail
} from "../../redux/ShipperConsigneeApprovalRedux/shipperConsigneeApprovalAction";
import {withRouter} from "react-router";
import {DropdownItem} from "reactstrap";
import {faQuestionCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


let typeOfCust = JSON.parse(localStorage.getItem("customer"))
const custType = typeOfCust && typeOfCust.result && typeOfCust.result.customerData && typeOfCust.result.customerData.typeOfBussiness

class SideBarWithIcon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInFullScreen: false,
            searchKeyword: "",
            notificationList: [],
            LogOut: false,
            modalLarge: false,
            showLogin: false,
            currentCurrency: "INR",
            OfferHomeModel: false,
            OfferModel: false,
            selectedOfferData: {},
            isOpen: false,
            showSideBar:false,
        };

    }


componentDidMount() {
    if (this.props.showSideBar) {
        this.setState({
            showSideBar: this.props.showSideBar
        })
    }
    if(this.props.showSideBarOther){
        this.setState({
            showSideBarOther: this.props.showSideBarOther
        })
    }
}

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.showSideBar && this.props.showSideBar !== prevProps.showSideBar) {
            this.setState({
                showSideBar: this.props.showSideBar
            })
        }
        if (this.props.showSideBarOther && this.props.showSideBarOther !== prevProps.showSideBarOther) {
            this.setState({
                showSideBarOther: this.props.showSideBarOther
            })
        }
    }

    render() {
        let locationHome = window.location.href.split("/")[window.location.href.split("/").length - 1];
        return (
            <>

                {locationHome === "Home" ?
                    <>
                        {this.props.showSideBar === true ?
                            <div className="sidebar">
                                <div className="main-menu1">
                                    <div className="scroll">
                                        <div className="scrollbar-container ps ps--active-y">
                                            <ul className="list-unstyled nav flex-column">
                                                <li className="nav-item" onClick={e => {
                                                    e.preventDefault()
                                                    // this.setRedirect()
                                                    this.props.history.push(PAGE_PATHS.STATICS)
                                                    window.location.reload();
                                                }}>
                                                    <a className="active"
                                                       href="#"><img src="../../assets/img/home.png" width="30" alt="Dashboard"/>
                                                        <span>Dashboard</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={e => {
                                                    if(custType =='Individual'){
                                                        this.props.history.push(PAGE_PATHS.shipmentHistoryCourier)
                                                    }else{
                                                        this.props.history.push(PAGE_PATHS.shipmentHistory)
                                                    }
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        <img src="../../assets/img/history.png" width="40" alt="Past Shipment"/>
                                                        <span>Past Shipment</span></a>
                                                </li>

                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.SHIPPER_INVOICE_MANAGER)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        <img src="../../assets/img/invoice-manager.png" width="40" alt="invoice Manager"/>
                                                        <span>Invoice Manager</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.EDOCKET_MANAGER)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        <img src="../../assets/img/invoice.png" width="40" alt="Document Manager"/>
                                                        <span>Document Manager</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.SHIPPER_CONSIGNEE_MANAGER)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        {/*<i className="iconsminds-digital-drawing"></i>*/}
                                                        <img src="../../assets/img/shipper.png" width="40" alt="Shipper Consignee Master"/>
                                                        <span>Shipper Consignee Master</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={e => {
                                                    if(custType =='Individual'){
                                                        this.props.history.push(PAGE_PATHS.CUSTOMER_KYC_FOR_INDIVIDUAL)
                                                    }else{
                                                        this.props.history.push(PAGE_PATHS.CUSTOMER_KYC)
                                                    }
                                                    // this.props.history.push(PAGE_PATHS.CUSTOMER_KYC)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        {/*<i className="iconsminds-digital-drawing"></i>*/}
                                                        <img src="../../assets/img/user-orange.png" width="40" alt="Profile"/>
                                                        <span>Profile</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" onClick={e => {
                                                    e.preventDefault()
                                                    this.props.history.push(PAGE_PATHS.FRIGHT_MANAGEMENT)
                                                }}>
                                                    <a href="#">
                                                        <img src="../../assets/img/user-orange.png" width="40" alt="Freight Management"/>
                                                        <span>Freight Management</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.FAQ_PAGE)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        {/*<i className="iconsminds-digital-drawing"></i>*/}
                                                        <FontAwesomeIcon icon={faQuestionCircle} className="my-iconss"/>
                                                        <span>Faq</span>
                                                    </a>
                                                </li>




                                                {/*<div onClick={e => {*/}
                                                {/*    e.preventDefault()*/}
                                                {/*    this.props.history.push(PAGE_PATHS.FAQ_PAGE)*/}
                                                {/*}}>*/}
                                                {/*    <DropdownItem*/}
                                                {/*        style={{fontWeight: "700"}}><i*/}
                                                {/*        className="far fa-question-circle my-iconss"></i> Faq</DropdownItem>*/}
                                                {/*</div>*/}

                                                {/*<li className="nav-item">*/}
                                                {/*    <a  href="#"> <img src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/tracking-orange.png" width="50"/>*/}

                                                {/*        <span>Tracking</span></a>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :""}
                    </>
                    :
                    <>
                        {this.props.showSideBarOther === true ?
                            <div className="sidebar">
                                <div className="main-menu1">
                                    <div className="scroll">
                                        <div className="scrollbar-container ps ps--active-y">
                                            <ul className="list-unstyled nav flex-column">
                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.STATICS)
                                                    window.location.reload();
                                                }}>
                                                    <a className="active"
                                                       href="#"><img src="../../assets/img/home.png" width="30" alt="Dashboard"/>
                                                        <span>Dashboard</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.shipmentHistory)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        <img src="../../assets/img/history.png" width="40" alt="Past Shipment"/>
                                                        <span>Past Shipment</span></a>
                                                </li>

                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.SHIPPER_INVOICE_MANAGER)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        <img src="../../assets/img/invoice-manager.png" width="40" alt="Invoice Manager"/>
                                                        <span>Invoice Manager</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.EDOCKET_MANAGER)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        <img src="../../assets/img/invoice.png" width="40" alt="Document Manager"/>
                                                        <span>Document Manager</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.SHIPPER_CONSIGNEE_MANAGER)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        {/*<i className="iconsminds-digital-drawing"></i>*/}
                                                        <img src="../../assets/img/shipper.png" width="40" alt="Shipper Consignee Manager"/>
                                                        <span>Shipper Consignee Master</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.CUSTOMER_KYC)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        {/*<i className="iconsminds-digital-drawing"></i>*/}
                                                        <img src="../../assets/img/user-orange.png" width="40" alt="Profile"/>
                                                        <span>Profile</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" onClick={e => {
                                                    e.preventDefault()
                                                    this.props.history.push(PAGE_PATHS.FRIGHT_MANAGEMENT)
                                                }}>
                                                    <a href="#">
                                                        <img src="../../assets/img/user-orange.png" width="40" alt="Freight Management"/>
                                                        <span>Freight Management</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" onClick={e => {

                                                    this.props.history.push(PAGE_PATHS.FAQ_SECTION)
                                                    window.location.reload();
                                                }}>
                                                    <a href="#">
                                                        {/*<i className="iconsminds-digital-drawing"></i>*/}
                                                        <FontAwesomeIcon icon={faQuestionCircle} className="my-iconss"/>
                                                        <span>Faq</span>
                                                    </a>
                                                </li>




                                                {/*<div onClick={e => {*/}
                                                {/*    e.preventDefault()*/}
                                                {/*    this.props.history.push(PAGE_PATHS.FAQ_PAGE)*/}
                                                {/*}}>*/}
                                                {/*    <DropdownItem*/}
                                                {/*        style={{fontWeight: "700"}}><i*/}
                                                {/*        className="far fa-question-circle my-iconss"></i> Faq</DropdownItem>*/}
                                                {/*</div>*/}

                                                {/*<li className="nav-item">*/}
                                                {/*    <a  href="#"> <img src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/tracking-orange.png" width="50"/>*/}

                                                {/*        <span>Tracking</span></a>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :""}
                    </>
                }

            </>

        )};
}

const mapStateToProps = (state) => {

    return {

    }
};
const SideBarWithIconWithRoute = withRouter(SideBarWithIcon);

export default connect(mapStateToProps, {

})(SideBarWithIconWithRoute);
