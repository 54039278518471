import React, {Component, Fragment} from "react";
import AppLayout from "../../../layout/AppLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import {PAGE_PATHS} from "../../../util/PageRoute";
import NewUserQuery from "./NewUserQuery";
import NewUserQuery1 from "./NewUserQuery1";
import CourierNewQuery from "./courierNewQuery";
import RoadNewQuery from "./roadNewQuery";
import {toast} from "react-toastify";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {NavLink} from "react-router-dom";
import {faCheck, faChevronLeft, faChevronRight, faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



class exporterPage extends Component {
    constructor(props) {
        super(props);
        this.myDivCharges = React.createRef()
        this.state = {
            exportYes:false,
            firstShipmentYesDomestic:false,
            exportSlide:true,
            firstShipmentYes:false,
            showAirTab:false,
            submitClickPopup:false,
            firstShipmentNo:false,
            iecYes:false,
            iecNo:false,
            gstNo:false,
            mode : "Road",
            airQueryForm:false,
            courierQueryForm:false,
            domesticClick:false,
            shipmentFirst:false,
            iecStatus:"",
            gstStatus:"",
            customerBusinessType:"",
            chatResponse : "",
            typeOfCustomer : "",
            CustomerDetailsStatus:false,
            formDetailsOpen:false,
            formDetailsCallOpen:false,
            forworderStatus:false,
            consigneeStatus:false,
            shipperStatus:false,
        };

    }
    componentDidMount() {
        AOS.init({
            duration: 600,
            delay: 100,
        });
    }

    exportFunction = () =>{
        this.setState({
            exportYes: !this.state.exportYes,
            exportSlide:false,
            typeOfCustomer:"Business",
            mode : "Road"
        })
    }
    individualFunction = () =>{
        this.setState({
            individualYes: !this.state.individualYes,
            exportSlide:false,
            typeOfCustomer:"Individual",
            mode : "Relocation",
            shipperStatus:false,
            forworderStatus:false,

        })
    }
    shipperFunction = () =>{
        this.setState({
            shipperStatus : !this.state.shipperStatus,
            customerType:"Shipper",
            exportYes:false,
        })
    }
    consigneeFunction = () =>{
        this.setState({
            consigneeStatus : !this.state.consigneeStatus,
            exportYes:false,
        })
    }
    forworderFunction = () =>{
        this.setState({
            forworderStatus : !this.state.forworderStatus,
            exportYes:false,
            customerType:"Sub Agent",
        })
    }
    forworderAfter = () =>{
        if(this.state.customerType=== "Shipper"){
            this.setState({
                exportYes:false,
                shipperStatus:true

            })
        }else if(this.state.customerType === "Sub Agent"){
            this.setState({
                exportYes:false,
                forworderStatus:true,
            })
        }else{
            toast.error("Please select either of the options", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    forworderBefore =()=>{
        this.setState({
            exportYes:false,
            exportSlide:true,
        })
    }
    jumpTypeCustomerAfter = () =>{
        if(this.state.typeOfCustomer === "Business"){
            this.setState({
                exportYes:true,
                individualYes:false,
                exportSlide:false,
            })
        }else if(this.state.typeOfCustomer === "Individual"){
            this.setState({
                individualYes:true,
                exportYes:false,
                exportSlide:false,
                mode: "Relocation"
            })
        }else{
            toast.error("Please select either of the options", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    domesticFunction = () =>{
        this.setState({
            domesticClick:!this.state.domesticClick,
            customerBusinessType:"Domestic",
            forworderStatus:false,
            shipperStatus:false,
        })
    }
    internationalFunction = () =>{
        this.setState({
            internationalClick:!this.state.internationalClick,
            customerBusinessType:"International",
            forworderStatus:false,
            shipperStatus:false,
        })
    }

    jumpCustomerBusinessAfter = () =>{
        if(this.state.customerBusinessType === "Domestic"){
            this.setState({
                domesticClick:true,
                forworderStatus:false,
                shipperStatus:false,
            })
        }else if(this.state.customerBusinessType === "International"){
            this.setState({
                internationalClick:true,
                forworderStatus:false,
                shipperStatus:false,
            })
        }else{
            toast.error("Please select either of the options", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    jumpCustomerBusinessPrev= ()=>{
        if(this.state.customerType === "Shipper"){
            this.setState({
                exportYes:true,
                shipperStatus:false,
            })
        }else{
            this.setState({
                exportYes:true,
                forworderStatus:false,

            })
        }

}

    firstShipment = () =>{
        if(this.state.typeOfCustomer === "Business"){
            if(this.state.customerBusinessType === "Domestic"){
                this.setState({
                    firstShipmentYesDomestic: !this.state.firstShipmentYesDomestic,
                    exportYes:false,
                    shipmentFirst:"Yes",
                    firstShipmentNo:false,
                    domesticClick:false,
                })
            }else{
                this.setState({
                    firstShipmentYes: !this.state.firstShipmentYes,
                    exportYes:false,
                    shipmentFirst:"Yes",
                    firstShipmentNo:false,
                    internationalClick:false,
                })
            }

        }else{
            this.setState({
                firstShipmentNo: !this.state.firstShipmentNo,
                individualYes:false,
                exportYes:false,
                shipmentFirst:"Yes",
            })
        }

    }
    firstShipmentNoFunc = () =>{
        this.setState({
            firstShipmentNo: !this.state.firstShipmentNo,
            domesticClick:false,
            internationalClick:false,
            exportYes:false,
            individualYes:false,
            firstShipmentYes:false,
            shipmentFirst:"No",
        })
        // if(this.state.typeOfCustomer === "Business"){
        //     this.setState({
        //         firstShipmentNo: !this.state.firstShipmentNo,
        //         exportYes:false,
        //         firstShipmentYes:false,
        //         shipmentFirst:"No",
        //     })
        // }else{
        //     this.setState({
        //         firstShipmentNo: !this.state.firstShipmentNo,
        //         individualYes:false,
        //         exportYes:false,
        //         firstShipmentYes:false,
        //         shipmentFirst:"No",
        //     })
        // }

    }
    firstShipmentPrev = () =>{
        if(this.state.typeOfCustomer==="Individual"){
            this.setState({
                exportSlide:true,
                individualYes:false,
                forworderStatus:false,
                shipperStatus:false,
            })
        }else if(this.state.typeOfCustomer==="Business"){
            if(this.state.customerBusinessType === "Domestic"){
                if(this.state.customerType === "Sub Agent"){
                    this.setState({
                        forworderStatus:true,
                        shipperStatus:false,
                        domesticClick:false,
                    })
                }else  if(this.state.customerType === "Shipper"){
                    this.setState({
                        shipperStatus:true,
                        forworderStatus:false,
                        domesticClick:false,
                    })
                }
            }else if(this.state.customerBusinessType === "International"){
                if(this.state.customerType === "Sub Agent"){
                    this.setState({
                        forworderStatus:true,
                        shipperStatus:false,
                        internationalClick:false,
                    })
                }else  if(this.state.customerType === "Shipper"){
                    this.setState({
                        shipperStatus:true,
                        forworderStatus:false,
                        internationalClick:false,
                    })
                }
            }
        }

    }

    jumpFirstShipmentAfter = () =>{
        if(this.state.customerBusinessType === "Domestic"){
            if(this.state.shipmentFirst === "Yes"){
                this.setState({
                    firstShipmentYesDomestic:true,
                    firstShipmentNo:false,
                    domesticClick:false,
                    exportYes:false,
                    individualYes:false,

                })
            }else if(this.state.shipmentFirst === "No"){
                this.setState({
                    firstShipmentNo:true,
                    firstShipmentYes:false,
                    domesticClick:false,
                    exportYes:false,
                    individualYes:false,
                })
            }else{
                toast.error("Please select either of the options", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }else if(this.state.customerBusinessType === "International"){
            if(this.state.shipmentFirst === "Yes"){
                this.setState({
                    firstShipmentYes:true,
                    internationalClick:false,
                    firstShipmentNo:false,
                    exportYes:false,
                    individualYes:false,
                })
            }else if(this.state.shipmentFirst === "No"){
                this.setState({
                    firstShipmentNo:true,
                    firstShipmentYes:false,
                    exportYes:false,
                    internationalClick:false,
                    individualYes:false,
                })
            }

        }else {
            this.setState({
                firstShipmentNo:true,
                firstShipmentYes:false,
                exportYes:false,
                individualYes:false,
            })
        }

    }

    iecYesFunc = () =>{
        this.setState({
            iecYes: true,
            firstShipmentYes:false,
            iecStatus:"Yes",
            gstStatus:"",
            iecNo:false,
        })
    }
    iecNoFunc = () =>{
        this.setState({
            iecNo: true,
            iecYes:false,
            firstShipmentYes:false,
            iecStatus:"No",
            gstStatus:"",
            gstNo:false,
        })
    }
    iecPrev = () =>{
        if(this.state.customerBusinessType === "Domestic"){
            this.setState({
                domesticClick:true,
                firstShipmentYes:false,
            })
        }else{
            this.setState({
                internationalClick:true,
                firstShipmentYes:false,
            })
        }

    }
    jumpIecSectionAfter = () =>{
        if(this.state.iecStatus === "Yes"){
            this.setState({
                iecYes:true,
                firstShipmentYes:false,
                iecNo:false,

            })
        }else if(this.state.iecStatus === "No"){
            this.setState({
                iecYes:false,
                firstShipmentYes:false,
                iecNo:true,
            })
        }else{
            toast.error("Please select either of the options", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    gstNoFunc = () =>{
        this.setState({
            gstNo: true,
            firstShipmentYesDomestic:false,
            iecYes:false,
            gstYes:false,
            iecNo:false,
            iecStatus:"Yes",
            gstStatus:"No",
        })
    }
    gstYesFunc = () =>{
        if(this.state.customerBusinessType === "Domestic"){
            this.setState({
                gstYes: true,
                gstNo:false,
                iecYes:false,
                firstShipmentYesDomestic:false,
                gstStatus:"Yes",
                iecStatus:"No",
            })
        }else{
            this.setState({
                gstYes: true,
                gstNo:false,
                iecYes:false,
                firstShipmentYesDomestic:false,
                gstStatus:"Yes",
                iecStatus:"Yes",
            })
        }


    }
    gstPrev = () =>{
        if(this.state.customerBusinessType === "Domestic"){
            this.setState({
                domesticClick:true,
                firstShipmentYesDomestic:false
            })
        }else{
            this.setState({
                iecYes:false,
                firstShipmentYes:true,
                gstStatus:"No",
            })
        }

    }
    jumpGstSectionAfter = () =>{
        if(this.state.gstStatus === "Yes"){
            this.setState({
                gstYes:true,
                iecYes:false,
                gstNo:false,

            })
        }else if(this.state.gstStatus === "No"){
            this.setState({
                gstYes:false,
                iecYes:false,
                gstNo:true,
            })
        }else{
            toast.error("Please select either of the options", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    otherSericesYes = () =>{
        this.setState({

        })
    }
    otherSericesNo = () =>{
        this.setState({

        })
    }

    formDetailsFunc = () =>{
        if(this.state.iecStatus==="Yes" && this.state.gstStatus==="No"){
            this.setState({
                formDetailsOpen: !this.state.formDetailsOpen,
                gstNo:false,
                chatResponse:"chat",
            })
        }else if(this.state.iecStatus==="No"){
            this.setState({
                formDetailsOpen: !this.state.formDetailsOpen,
                iecNo:false,
                gstNo:false,
                chatResponse:"chat",
            })
        }

    }
    formDetailsFuncCall = () =>{
        if(this.state.iecStatus==="Yes" && this.state.gstStatus==="No"){
            this.setState({
                formDetailsOpen: !this.state.formDetailsOpen,
                gstNo:false,
                chatResponse:"call",
            })
        }else if(this.state.iecStatus==="No"){
            this.setState({
                formDetailsOpen: !this.state.formDetailsOpen,
                iecNo:false,
                chatResponse:"call",
            })
        }

    }

    assistfuncBefore = () =>{
        if(this.state.chatResponse === ""){
            toast.error("Please Select Only One Option", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else if(this.state.gstStatus === "No"){
            this.setState({
                formDetailsOpen:true,
                iecNo:false,
                gstNo:false,

            })
        }else if(this.state.iecStatus === "No"){
            this.setState({
                iecNo:false,
                gstNo:false,
                formDetailsOpen:true,
            })
        }{

        }

    }

    assistfuncPrevWithIecNo = () =>{
        if(this.state.iecStatus === "Yes" && this.state.gstStatus === "No"){
            this.setState({
                iecYes:true,
                iecNo:false,
                gstNo:false,

            })
        }else if(this.state.iecStatus === "No"){
            this.setState({
                iecNo:false,
                gstNo:false,
                firstShipmentYes:true,
            })
        }

    }

    assistfuncPrevWithGstNo = () =>{
        this.setState({
            iecYes: true,
            gstNo:false,
        })
    }

    exporterYesNoJump = () =>{
        this.setState({
            exportYes : true,
            firstShipmentNo:false,
        })
    }
    jumpCustomerDetails = () =>{
        this.setState({
            formDetailsOpen : true,
            firstShipmentNo:false,
            CustomerDetailsStatus:false,
        })
    }
    PrevDomesticInternational = () =>{
        if(this.state.customerBusinessType === "Domestic"){
            if(this.state.shipmentFirst === "No"){
                this.setState({
                    domesticClick:true,
                    firstShipmentNo:false,
                })
            }else if(this.state.shipmentFirst === "Yes"){
                if(this.state.gstStatus === "Yes"){
                    this.setState({
                        firstShipmentYesDomestic:true,
                        gstYes:false,
                    })
                }

            }
        }else if(this.state.customerBusinessType === "International"){
            if(this.state.shipmentFirst === "No"){
                this.setState({
                    internationalClick:true,
                    firstShipmentNo:false,
                })
            }else if(this.state.shipmentFirst === "Yes"){
                if(this.state.gstStatus === "Yes"){
                    this.setState({
                        firstShipmentYesDomestic:true,
                        gstYes:false,
                    })
                }

            }
        }

    }
    gstJumpSection = () =>{
        this.setState({
            iecYes:true,
            gstYes:false,
        })
    }

    assistSectionJump =() =>{
        this.setState({
            iecYes:true,
            gstYes:false,
        })
    }
    formCustomerDetails = () =>{
        if(this.state.contactName==="" || this.state.contactName === undefined || this.state.contactEmail === "" || this.state.contactEmail  === undefined|| this.state.contactNumber === "" || this.state.contactNumber === undefined){
            toast.error("Please Fill Name, Email and Contact No.", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else{
            this.setState({
                submitClickPopup:!this.state.submitClickPopup,
                // CustomerDetailsStatus : !this.state.CustomerDetailsStatus,
                formDetailsOpen:false,
                formDetailsCallOpen:false,

            })
        }

    }
    jumpToIecSection = () =>{
        if(this.state.gstStatus !== "Yes"){
            this.setState({
                formDetailsOpen:false,
                gstNo:true,
                iecNo:false,
            })
        }else if(this.state.iecStatus !=="Yes"){
            this.setState({
                iecNo:true,
                formDetailsOpen:false,
                gstNo:false,
            })
        }

    }

    formBeforeSection = () =>{

        if(this.state.contactName==="" || this.state.contactName === undefined || this.state.contactEmail === "" || this.state.contactEmail  === undefined|| this.state.contactNumber === "" || this.state.contactNumber === undefined){
            toast.error("Please Fill Name, Email and Contact No.", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else{
            this.setState({
                CustomerDetailsStatus:true,
                formDetailsOpen:false,
            })
        }
    }

    jumpToGstSection = () =>{
        this.setState({
            formDetailsOpen:false,
            formDetailsCallOpen:false,
            gstNo:true,
            iecNo:false,
        })
    }
    AirUsertab = () =>{
        localStorage.setItem("queryTypeForpopup","AirQuery")
        localStorage.setItem("queryType","AirQuery")
        this.setState({
            showOceanTab : false,
            showOceanTabPrev : false,
            showAirTabPrev : true,
            showCourierTabPrev : false,
            showRoadTabPrev : false,
            showAirTab : true,
            showCourierTab:false,
            showRoadTab:false,
        })
        if(this.state.firstShipmentNo === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                firstShipmentNo:false,
                prevForAirOceanShipment:true,
                prevForAirOceangst:false,
                gstYes:false,
                showAirTab : !this.state.showAirTab,
                showAirTabPrev : true,
                showOceanTab:false,
                showOceanTabPrev:false,
                showCourierTab:false,
                showCourierTabPrev:false,
                showRoadTab:false,
                showRoadTabPrev:false,

            })
        }else if(this.state.gstYes === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                firstShipmentNo:false,
                prevForAirOceangst:true,
                prevForAirOceanShipment:false,
                gstYes:false,
                showAirTab : !this.state.showAirTab,
                showOceanTab:false,
                showOceanTabPrev:false,
                showCourierTab:false,
                showCourierTabPrev:false,
                showRoadTab:false,
                showRoadTabPrev:false,
                showAirTabPrev : true,
            })
        }else if(this.state.CustomerDetailsStatus === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                gstNoStatus : true,
                iecNoStatus:false,
                CustomerDetailsStatus:false,
                customerForm:true,
                showAirTab : !this.state.showAirTab,
                showOceanTab:false,
                showOceanTabPrev:false,
                showCourierTab:false,
                showCourierTabPrev:false,
                showRoadTab:false,
                showRoadTabPrev:false,
                showAirTabPrev : true,

            })
        }else if(this.state.iecNo === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                gstNoStatus : false,
                iecNoStatus:true,
            })
        }
        // this.setState({
        //     airQueryForm: !this.state.airQueryForm,
        //     firstShipmentNo:false,
        //     prevForAirOcean:true,
        //     gstYes:false,
        // })
        // this.props.history.push(PAGE_PATHS.NEW_QUERY_FORM);
    }
    oceanUsertab = () =>{
        localStorage.setItem("queryTypeForpopup","OceanQuery")
        localStorage.setItem("queryType","OceanQuery")
        this.setState({
            showOceanTab : !this.state.showOceanTab,
            showOceanTabPrev : true,
            showAirTabPrev : false,
            showCourierTabPrev : false,
            showRoadTabPrev : false,
            showAirTab : false,
            showCourierTab:false,
            showRoadTab:false,
        })
        if(this.state.firstShipmentNo === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                firstShipmentNo:false,
                prevForAirOceanShipment:true,
                prevForAirOceangst:false,
                gstYes:false,
                showOceanTab : !this.state.showOceanTab,
                showOceanTabPrev : true,
                showAirTabPrev : false,
                showCourierTabPrev : false,
                showRoadTabPrev : false,
                showAirTab : false,
                showCourierTab:false,
                showRoadTab:false,
            })
        }else if(this.state.gstYes === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                firstShipmentNo:false,
                prevForAirOceangst:true,
                prevForAirOceanShipment:false,
                gstYes:false,
                showOceanTab : !this.state.showOceanTab,
                showOceanTabPrev : true,
                showAirTabPrev : false,
                showCourierTabPrev : false,
                showRoadTabPrev : false,
                showAirTab : false,
                showCourierTab:false,
                showRoadTab:false,
            })
        }else if(this.state.CustomerDetailsStatus === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                gstNoStatus : true,
                iecNoStatus:false,
                CustomerDetailsStatus:false,
                customerForm:true,
                showOceanTab : !this.state.showOceanTab,
                showOceanTabPrev : true,
                showAirTabPrev : false,
                showCourierTabPrev : false,
                showRoadTabPrev : false,
                showAirTab : false,
                showCourierTab:false,
                showRoadTab:false,

            })
        }else if(this.state.iecNo === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                gstNoStatus : false,
                iecNoStatus:true,
            })
        }


    }
    courierUsertab = () =>{
        localStorage.setItem("queryTypeForpopup","CourierQuery")
        localStorage.setItem("queryType","CourierQuery")
        this.setState({
            showOceanTab : false,
            showOceanTabPrev : false,
            showAirTabPrev : false,
            showCourierTabPrev : true,
            showRoadTabPrev : false,
            showAirTab : true,
            showCourierTab:!this.state.showCourierTab,
            showRoadTab:false,
        })
        if(this.state.firstShipmentNo === true){
            this.setState({
                courierQueryForm: !this.state.courierQueryForm,
                firstShipmentNo:false,
                prevForAirOceanShipment:true,
                prevForAirOceangst:false,
                gstYes:false,
                showOceanTab : false,
                showOceanTabPrev : false,
                showAirTabPrev : false,
                showCourierTabPrev : true,
                showRoadTabPrev : false,
                showAirTab : true,
                showCourierTab:!this.state.showCourierTab,
                showRoadTab:false,

            })
        }else if(this.state.gstYes === true){
            this.setState({
                courierQueryForm: !this.state.courierQueryForm,
                firstShipmentNo:false,
                prevForAirOceangst:true,
                prevForAirOceanShipment:false,
                gstYes:false,
                showOceanTab : false,
                showOceanTabPrev : false,
                showAirTabPrev : false,
                showCourierTabPrev : true,
                showRoadTabPrev : false,
                showAirTab : true,
                showCourierTab:!this.state.showCourierTab,
                showRoadTab:false,
            })
        }else if(this.state.CustomerDetailsStatus === true){
            this.setState({
                courierQueryForm: !this.state.courierQueryForm,
                gstNoStatus : true,
                iecNoStatus:false,
                CustomerDetailsStatus:false,
                customerForm:true,
                showOceanTab : false,
                showOceanTabPrev : false,
                showAirTabPrev : false,
                showCourierTabPrev : true,
                showRoadTabPrev : false,
                showAirTab : true,
                showCourierTab:!this.state.showCourierTab,
                showRoadTab:false,

            })
        }else if(this.state.iecNo === true){
            this.setState({
                airQueryForm: !this.state.airQueryForm,
                gstNoStatus : false,
                iecNoStatus:true,
            })
        }
    }
    roadUsertab = () =>{
        localStorage.setItem("queryTypeForpopup","RoadQuery")
        localStorage.setItem("queryType","RoadQuery")
        this.setState({
            showOceanTab : false,
            showOceanTabPrev : false,
            showAirTabPrev : false,
            showCourierTabPrev : false,
            showRoadTabPrev : true,
            showAirTab : true,
            showCourierTab:false,
            showRoadTab:true,
        })
        if(this.state.firstShipmentNo === true){
            this.setState({
                roadQueryForm: !this.state.roadQueryForm,
                firstShipmentNo:false,
                prevForAirOceanShipment:true,
                prevForAirOceangst:false,
                gstYes:false,
                showOceanTab : false,
                showOceanTabPrev : false,
                showAirTabPrev : false,
                showCourierTabPrev : false,
                showRoadTabPrev : true,
                showAirTab : true,
                showCourierTab:false,
                showRoadTab:true,

            })
        }else if(this.state.gstYes === true){
            this.setState({
                roadQueryForm: !this.state.roadQueryForm,
                firstShipmentNo:false,
                prevForAirOceangst:true,
                prevForAirOceanShipment:false,
                gstYes:false,
                showOceanTab : false,
                showOceanTabPrev : false,
                showAirTabPrev : false,
                showCourierTabPrev : false,
                showRoadTabPrev : true,
                showAirTab : true,
                showCourierTab:false,
                showRoadTab:true,
            })
        }else if(this.state.CustomerDetailsStatus === true){
            this.setState({
                roadQueryForm: !this.state.roadQueryForm,
                gstNoStatus : true,
                iecNoStatus:false,
                CustomerDetailsStatus:false,
                customerForm:true,
                showOceanTab : false,
                showOceanTabPrev : false,
                showAirTabPrev : false,
                showCourierTabPrev : false,
                showRoadTabPrev : true,
                showAirTab : true,
                showCourierTab:false,
                showRoadTab:true,

            })
        }else if(this.state.iecNo === true){
            this.setState({
                roadQueryForm: !this.state.roadQueryForm,
                gstNoStatus : false,
                iecNoStatus:true,
            })
        }
    }

    transportPreviousOption = () =>{

            this.setState({
                individualYes : true,
                firstShipmentNo:false,
            })

    }

    jumpQueryForScreen = () =>{
        if(this.state.showAirTab === true){
            localStorage.setItem("queryTypeForpopup","AirQuery")
            if(this.state.shipmentFirst === "No"){
                this.setState({
                    airQueryForm : true,
                    firstShipmentNo : false,
                    gstYes:false,
                    CustomerDetailsStatus:false,
                })
            }else if(this.state.gstStatus === "No"){
                this.setState({
                    airQueryForm : true,
                    gstYes : false,
                    firstShipmentNo:false,
                    CustomerDetailsStatus:false,
                })
            }
        }

        if(this.state.showOceanTab === true) {
            localStorage.setItem("queryTypeForpopup", "OceanQuery")
            if (this.state.shipmentFirst === "No") {
                this.setState({
                    airQueryForm: true,
                    firstShipmentNo: false,
                    gstYes: false,
                    CustomerDetailsStatus: false,
                })
            } else if (this.state.gstStatus === "No") {
                this.setState({
                    airQueryForm: true,
                    gstYes: false,
                    firstShipmentNo: false,
                    CustomerDetailsStatus: false,
                })
            }
        }
        if(this.state.showCourierTab === true) {
            localStorage.setItem("queryTypeForpopup", "CourierQuery")
            if (this.state.shipmentFirst === "No") {
                this.setState({
                    courierQueryForm: true,
                    firstShipmentNo: false,
                    gstYes: false,
                    CustomerDetailsStatus: false,
                })
            } else if (this.state.gstStatus === "No") {
                this.setState({
                    courierQueryForm: true,
                    gstYes: false,
                    firstShipmentNo: false,
                    CustomerDetailsStatus: false,
                })
            }
        }
        if(this.state.showRoadTab === true){
            localStorage.setItem("queryTypeForpopup","RoadQuery")
            if (this.state.shipmentFirst === "No") {
                this.setState({
                    roadQueryForm: true,
                    firstShipmentNo: false,
                    gstYes: false,
                    CustomerDetailsStatus: false,
                })
            } else if (this.state.gstStatus === "No") {
                this.setState({
                    roadQueryForm: true,
                    gstYes: false,
                    firstShipmentNo: false,
                    CustomerDetailsStatus: false,
                })
            }
         }
    }

    handleChange =(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    goToHome = () =>{
        this.props.history.push(PAGE_PATHS.MAIN_PAGE);
    }

    toggleLarge = () => {
        this.setState(prevState => ({
            submitClickPopup: !prevState.submitClickPopup
        }),()=>{
            this.setState({
                CustomerDetailsStatus:!this.state.CustomerDetailsStatus,
            })
        });
    };
    render() {

        return (
            <>
                <AppLayout>
                    <div className="dashboard-wrapper change-color_popup popup_margins">
                        <div className="container">
                            <div className="row">

                                <div className="diff-forms position-relative">

                                    {this.state.exportSlide === true?
                                     <div className="third-sec margin-50" data-aos={"fade-left"}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                                <p className="text-center">I am looking for logistics services for </p>
                                                <div className="d-flex align-items-center justify-content-center">
                                                <div className={this.state.typeOfCustomer === "Business"?"user-box active":"user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.exportFunction()

                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.263408313114615-1635857058999.png" alt="My Business"/>
                                                    <p>My Business</p>
                                                </div>
                                                <div className={this.state.typeOfCustomer === "Individual"?"user-box active":"user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.individualFunction()

                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.19089121190016578-1635234815843.png" alt="Personal" className="build-width"/>
                                                    <p>Personal</p>
                                                </div>
                                            </div>
                                                <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">

                                                        <button className="btn next_my_Button" onClick={e => {
                                                            e.preventDefault()
                                                            this.jumpTypeCustomerAfter()
                                                        }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                                    {this.state.typeOfCustomer === "" ? "" :
                                                    <button className="btn prev_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.goToHome()
                                                    }}><FontAwesomeIcon icon={faChevronLeft}/></button>}
                                                </div>
                                            </div>
                                        </div>
                                        :""}
                                    {this.state.exportYes === true ?
                                        <div className="third-sec margin-50" data-aos={"fade-left"}>
                                        <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                            <p className="text-center">Are you a</p>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className={this.state.customerType === "Shipper"?"user-box active":"user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.shipperFunction()

                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.24871732184555984-1642745183618.png" alt="Shipper/Consignee"/>
                                                    <p>Shipper/Consignee</p>
                                                </div>

                                                <div className={this.state.customerType === "Sub Agent"?"user-box active":"user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.forworderFunction()

                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.004246898915509334-1642745190841.png" alt="CHA/Forwarder"/>
                                                    <p>CHA/Forwarder</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                <button className="btn next_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.forworderAfter()
                                                }}><FontAwesomeIcon icon={faChevronRight}/></button>

                                                <button className="btn prev_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.forworderBefore()
                                                }}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                            </div>
                                        </div>
                                    </div>
                                        :""}
                                    {this.state.forworderStatus=== true  || this.state.shipperStatus=== true?
                                    <div className="third-sec margin-50" data-aos={"fade-left"}>
                                        <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                            <p className="text-center">Type of business</p>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className={this.state.customerBusinessType === "Domestic"?"user-box active":"user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.domesticFunction()
                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.amazonaws.com/unTracked/s3Bucketoo0.35429954304244005-1635850585733.png" alt="Domestic"/>
                                                    <p>Domestic</p>
                                                </div>
                                                <div className={this.state.customerBusinessType === "International"?"user-box active":"user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.internationalFunction()

                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.013114066399758206-1635850904619.png" alt="International" className="build-width"/>
                                                    <p>International</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                <button className="btn next_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.jumpCustomerBusinessAfter()
                                                }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                                <button className="btn prev_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.jumpCustomerBusinessPrev()
                                                }}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                            </div>
                                        </div>
                                    </div>
                                        :""}
                                    { this.state.individualYes === true || this.state.domesticClick === true || this.state.internationalClick === true?
                                         <div className="third-sec margin-50" data-aos={"fade-left"}>
                                             <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                                    <p className="text-center">Is this your first shipment ?</p>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div className={this.state.shipmentFirst === "Yes" ? "user-box active" : "user-box"} onClick={e => {
                                                            e.preventDefault()
                                                            this.firstShipment()
                                                        }}>
                                                            <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                            <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9397556525089072-1635167846689.jpg" alt="Yes"/>
                                                            <p>Yes</p>
                                                        </div>
                                                        <div className={this.state.shipmentFirst === "No" ? "user-box active" : "user-box"} onClick={e => {
                                                            e.preventDefault()
                                                            this.firstShipmentNoFunc()
                                                        }}>
                                                            <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                            <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.17403900426407892-1635167970594.jpg" alt="No"/>
                                                            <p>No</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                        <button className="btn prev_my_Button" onClick={e => {
                                                            e.preventDefault()
                                                            this.firstShipmentPrev()
                                                        }}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                                        <button className="btn next_my_Button" onClick={e => {
                                                            e.preventDefault()
                                                            this.jumpFirstShipmentAfter()
                                                        }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                                    </div>
                                                </div>
                                         </div>
                                        :""}
                                    {this.state.firstShipmentYes === true ?
                                        <div className="third-sec margin-50" data-aos={"fade-left"}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                            <p className="text-center">Do you have IEC ?</p>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className={this.state.iecStatus === "Yes" ? "user-box active" : "user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.iecYesFunc()
                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9397556525089072-1635167846689.jpg" alt="Yes"/>
                                                    <p>Yes</p>
                                                </div>
                                                <div className={this.state.iecStatus === "No" ? "user-box active" : "user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.iecNoFunc()
                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.17403900426407892-1635167970594.jpg" alt="No" />
                                                    <p>No</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                {/*<button className="btn query-btns" onClick={e => {*/}
                                                {/*    e.preventDefault()*/}
                                                {/*    this.goToHome()*/}
                                                {/*}}>Home</button>*/}
                                                <button className="btn prev_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.iecPrev()
                                                }}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                                <button className="btn next_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.jumpIecSectionAfter()
                                                }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                            </div>
                                        </div>
                                        </div>
                                        :""}
                                    {this.state.iecYes === true || this.state.firstShipmentYesDomestic === true?
                                        <div className="third-sec margin-50" data-aos={"fade-left"}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                            <p className="text-center">Do you have GST No.?</p>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className={this.state.gstStatus === "Yes" ? "user-box active" : "user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.gstYesFunc()
                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9397556525089072-1635167846689.jpg" alt="Yes"/>
                                                    <p>Yes</p>
                                                </div>
                                                <div className={this.state.gstStatus === "No" ? "user-box active" : "user-box"} onClick={e => {
                                                    e.preventDefault()
                                                    this.gstNoFunc()
                                                }}>
                                                    <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                    <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.17403900426407892-1635167970594.jpg" alt="No"/>
                                                    <p>No</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                {/*<button className="btn query-btns" onClick={e => {*/}
                                                {/*    e.preventDefault()*/}
                                                {/*    this.goToHome()*/}
                                                {/*}}>Home</button>*/}
                                                <button className="btn prev_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.gstPrev()
                                                }}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                                <button className="btn next_my_Button" onClick={e => {
                                                    e.preventDefault()
                                                    this.jumpGstSectionAfter()
                                                }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                            </div>
                                        </div>
                                        </div>
                                    :""}
                                    {/*{this.state.iecYes === true ?*/}
                                        <div className="third-sec margin-50" data-aos={"fade-left"}>
                                            {/*<div className="d-flex flex-wrap justify-content-center flex-column mins-height">*/}
                                            {/*    <p className="text-center">Would you like us to assist for other services like as Website Designing, Custom Clearance and Web Devlopment</p>*/}
                                            {/*    <div className="d-flex align-items-center justify-content-center">*/}
                                            {/*        <div className={this.state.ShowAirPortTab === true ? "user-box active" : "user-box"} onClick={e => {*/}
                                            {/*            e.preventDefault()*/}
                                            {/*            this.otherSericesYes()*/}
                                            {/*        }}>*/}
                                            {/*            <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>*/}
                                            {/*            <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9397556525089072-1635167846689.jpg" alt="pick"/>*/}
                                            {/*            <p>Yes</p>*/}
                                            {/*        </div>*/}
                                            {/*        <div className={this.state.showOriginAddressTab === true ? "user-box active" : "user-box"} onClick={e => {*/}
                                            {/*            e.preventDefault()*/}
                                            {/*            this.otherSericesNo()*/}
                                            {/*        }}>*/}
                                            {/*            <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>*/}
                                            {/*            <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.17403900426407892-1635167970594.jpg" alt="drop"/>*/}
                                            {/*            <p>No</p>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">*/}
                                            {/*        <button className="btn query-btns" onClick={e => {*/}
                                            {/*            e.preventDefault()*/}
                                            {/*            this.goToHome()*/}
                                            {/*        }}>Home</button>*/}
                                            {/*        <button className="btn query-btns" onClick={e => {*/}
                                            {/*            e.preventDefault()*/}

                                            {/*        }}><FontAwesomeIcon icon={faChevronLeft}/></button>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*// :""}*/}

                                    {this.state.gstNo === true || this.state.iecNo === true?
                                        <div className="third-sec margin-50" data-aos={"fade-left"}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                                {/*<p className="text-center">Would You like us in getting the doucment</p>*/}
                                                <p className="text-center">Do you want our representative to help you with obtaining other services ?</p>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <div className={this.state.chatResponse === "chat" ? "user-box active" : "user-box"} onClick={e => {
                                                        e.preventDefault()
                                                        this.formDetailsFunc()
                                                    }}>
                                                        <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                        <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.27243397542288816-1635492870697.png" alt="Chat"/>
                                                        <p>Chat</p>
                                                    </div>
                                                    <div className={this.state.chatResponse === "call" ? "user-box active" : "user-box"} onClick={e => {
                                                        e.preventDefault()
                                                        this.formDetailsFuncCall()
                                                    }}>
                                                        <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                        <img src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.522704658650955-1635492829570.png" alt="Call"/>
                                                        <p>Call</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                    {/*<button className="btn query-btns" onClick={e => {*/}
                                                    {/*    e.preventDefault()*/}
                                                    {/*    this.goToHome()*/}
                                                    {/*}}>Home</button>*/}
                                                    {/*{this.state.iecStatus === "No" ?*/}
                                                    <button className="btn prev_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.assistfuncPrevWithIecNo()
                                                    }}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                                    <button className="btn next_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.assistfuncBefore()
                                                    }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                                        {/*:""}*/}
                                                    {/*{this.state.gstStatus === "No" ?*/}
                                                    {/*    <button className="btn query-btns" onClick={e => {*/}
                                                    {/*        e.preventDefault()*/}
                                                    {/*        this.assistfuncPrevWithGstNo()*/}
                                                    {/*    }}><FontAwesomeIcon icon={faChevronLeft}/></button>*/}
                                                    {/*    :""}*/}
                                                </div>
                                            </div>
                                        </div>
                                        :""}

                                    {this.state.formDetailsOpen === true?
                                        <div className="form_with_new margin-50" data-aos={"fade-left"}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                                <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                    <div className="width_forms_new">
                                                        <div className="form-group">
                                                            <label className="">Name</label>
                                                            <input  className="ipt1" type="text" name="contactName" placeholder="Name" value={this.state.contactName}
                                                                    onChange={e=>{e.preventDefault()
                                                                        this.handleChange(e)
                                                                    }}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="">Email</label>
                                                            <input  className="ipt1" type="text" name="contactEmail" placeholder="Email" value={this.state.contactEmail}
                                                                    onChange={e=>{e.preventDefault()
                                                                        this.handleChange(e)
                                                                    }}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="">Contact No.</label>
                                                            <input  className="ipt1" type="text" name="contactNumber" placeholder="Contact Number" value={this.state.contactNumber}
                                                                    onChange={e=>{e.preventDefault()
                                                                        this.handleChange(e)
                                                                    }}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <button className="btn btn_new_form" onClick={e => {
                                                                e.preventDefault()
                                                                this.formCustomerDetails()
                                                            }}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                    {/*<button className="btn query-btns" onClick={e => {*/}
                                                    {/*    e.preventDefault()*/}
                                                    {/*    this.goToHome()*/}
                                                    {/*}}>Home</button>*/}

                                                    <button className="btn prev_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.jumpToIecSection()
                                                    }}><FontAwesomeIcon icon={faChevronLeft}/></button>

                                                    <button className="btn next_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.formBeforeSection()
                                                    }}><FontAwesomeIcon icon={faChevronRight}/></button>

                                                </div>
                                            </div>
                                        </div>
                                        :""}

                                    {this.state.firstShipmentNo=== true || this.state.gstYes === true || this.state.CustomerDetailsStatus === true?
                                        <div className={this.state.typeOfCustomer === "Individual"?"third-sec":"second-sec"} data-aos={"fade-left"}>
                                            <div className="d-flex flex-wrap justify-content-center flex-column mins-height">
                                                <p className="text-center">Modes of Transportation</p>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    {this.state.typeOfCustomer === "Individual" ? '': <><div className={this.state.showAirTab===true?"user-box-first active":"user-box-first"} onClick={e => {
                                                        e.preventDefault()
                                                        this.AirUsertab()
                                                    }}>
                                                        <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                        <img src="../../../assets/img/airplane-new.png" alt="Air Freight"/>
                                                        <p>Air Freight</p>
                                                    </div>
                                                    {this.state.typeOfCustomer === "Business" && this.state.customerBusinessType === "Domestic"?"":
                                                        <div className={this.state.showOceanTab===true?"user-box-first active":"user-box-first"} onClick={e => {
                                                            e.preventDefault()
                                                            this.oceanUsertab()
                                                        }}>
                                                            <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                            <img src="../../../assets/img/ship-new.png" alt="Ocean Freight"/>
                                                            <p>Ocean Freight</p>
                                                        </div>}
                                                    </>}
                                                    <div className={this.state.showCourierTab===true?"user-box-first active":"user-box-first"} onClick={e => {
                                                        e.preventDefault()
                                                        this.courierUsertab()
                                                    }}>
                                                        <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                        <img src="../../../assets/img/new-courier.png" alt="Courier"/>
                                                        <p>Courier</p>
                                                    </div>
                                                    {this.state.typeOfCustomer === "Business" && this.state.customerBusinessType === "International"?"":
                                                        <div className={this.state.showRoadTab===true?"user-box-first active":"user-box-first"} onClick={e => {
                                                            e.preventDefault()
                                                            this.roadUsertab()
                                                        }}>
                                                            <div className="triangle-topleft"><FontAwesomeIcon icon={faCheck}/></div>
                                                            <img src="../../../assets/img/truck_new.png" alt="ocean"/>
                                                            <p>{this.state.mode}</p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center justify-content-center query-btn-groups">
                                                    {this.state.gstYes === true ?
                                                        <button className="btn prev_my_Button" onClick={e => {
                                                            e.preventDefault()
                                                            this.gstJumpSection()
                                                        }}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                                    :""}

                                                    {this.state.firstShipmentNo === true ?
                                                    <button className="btn prev_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.exporterYesNoJump()
                                                    }}><FontAwesomeIcon icon={faChevronLeft}/></button>:""}
                                                    {this.state.CustomerDetailsStatus === true ?
                                                        <button className="btn prev_my_Button" onClick={e => {
                                                            e.preventDefault()
                                                            this.jumpCustomerDetails()
                                                        }}><FontAwesomeIcon icon={faChevronLeft}/></button>:""}
                                                    {this.state.customerBusinessType===""?"":
                                                        <button className="btn prev_my_Button" onClick={e => {
                                                            e.preventDefault()
                                                            this.PrevDomesticInternational()
                                                        }}><FontAwesomeIcon icon={faChevronLeft}/></button>}
                                                    {this.state.typeOfCustomer === "Individual"?
                                                    <button className="btn prev_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.transportPreviousOption()
                                                    }}><FontAwesomeIcon icon={faChevronLeft}/></button>:""}
                                                    <button className="btn next_my_Button" onClick={e => {
                                                        e.preventDefault()
                                                        this.jumpQueryForScreen()
                                                    }}><FontAwesomeIcon icon={faChevronRight}/></button>
                                                </div>
                                            </div>
                                        </div>
                                        :""}
                                        </div>

                                {/*<div className="text-right position-relative" style={{width:'100%'}}>*/}
                                {/*    <div className="pre-btns1">*/}
                                {/*        <button className="btn delete" onClick={e => {*/}
                                {/*            e.preventDefault()*/}
                                {/*            this.goToHome()*/}
                                {/*        }}>*/}
                                {/*            Home*/}
                                {/*        </button>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <Modal className="model_contact"
                               isOpen={this.state.submitClickPopup}
                               size="md"
                               toggle={this.toggleLarge}
                        >


                            <ModalHeader className="model_head" toggle={this.toggleLarge} style={{padding: "0"}}>
                                <NavLink to={`/`} className="white">
                                </NavLink>
                            </ModalHeader>
                            <ModalBody className="contact-later1 new_other_popup">
                                <div className="contect-soon">
                                    <div className="soon_text">

                                        <div className="book-text">
                                            <h5><FontAwesomeIcon icon={faCircle} style={{color: "green"}}/> Available Now </h5>
                                            <h2> We have received your query. </h2>
                                            <h6> Our executive will get back soon.</h6>
                                            <h6> For any problem Please call on <span>+91 120 691 6910 .</span> </h6>
                                        </div>
                                    </div>
                                    {/*<div className="bottom_btn">*/}
                                    {/*    <div className="col-md-12">*/}
                                    {/*        <div className="form-group">*/}
                                    {/*            <button type="submit" className="request_btn" id="subscribeBtn"*/}
                                    {/*                    onClick={event => {*/}
                                    {/*                        window.location.reload();*/}
                                    {/*                    }}> Ok*/}
                                    {/*            </button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </ModalBody>

                        </Modal>

                        {this.state.airQueryForm === true ?

                            <NewUserQuery1
                                firstShipmentNo ={this.state.prevForAirOceanShipment}
                                showAirTab = {this.state.showAirTabPrev}
                                showOceanTab ={this.state.showOceanTabPrev}
                                showCourierTab = {this.state.showCourierTabPrev}
                                showRoadTab = {this.state.showRoadTabPrev}
                                gstYes ={this.state.prevForAirOceangst}
                                gstNo = {this.state.gstNoStatus}
                                iecNo = {this.state.iecNoStatus}
                                CustomerDetailsStatus = {this.state.customerForm}
                                shipmentFirst = {this.state.shipmentFirst}
                                iecStatus = {this.state.iecStatus}
                                gstStatus = {this.state.gstStatus}
                                chatResponse = {this.state.chatResponse}
                                typeOfCustomer = {this.state.typeOfCustomer}
                                customerType = {this.state.customerType}
                                contactName = {this.state.contactName}
                                contactEmail = {this.state.contactEmail}
                                contactNumber = {this.state.contactNumber}

                                prevAir={prevAir=>{
                                    this.setState({
                                        firstShipmentNo : prevAir.firstShipmentNo,
                                        gstYes : prevAir.gstYes,
                                        CustomerDetailsStatus : prevAir.CustomerDetailsStatus,
                                        showAirTab : prevAir.showAirTab,
                                        showOceanTab : prevAir.showOceanTab,
                                        showCourierTab : prevAir.showCourierTab,
                                        showRoadTab : prevAir.showRoadTab,
                                        airQueryForm:false,
                                    },()=>{

                                    })
                                }}
                            />
                            :""}

                        {this.state.courierQueryForm === true ?
                            <CourierNewQuery
                                firstShipmentNo ={this.state.prevForAirOceanShipment}
                                showAirTab = {this.state.showAirTabPrev}
                                showOceanTab ={this.state.showOceanTabPrev}
                                showCourierTab = {this.state.showCourierTabPrev}
                                showRoadTab = {this.state.showRoadTabPrev}
                                gstYes ={this.state.prevForAirOceangst}
                                gstNo = {this.state.gstNoStatus}
                                iecNo = {this.state.iecNoStatus}
                                CustomerDetailsStatus = {this.state.customerForm}
                                shipmentFirst = {this.state.shipmentFirst}
                                iecStatus = {this.state.iecStatus}
                                gstStatus = {this.state.gstStatus}
                                chatResponse = {this.state.chatResponse}
                                typeOfCustomer = {this.state.typeOfCustomer}
                                contactName = {this.state.contactName}
                                contactEmail = {this.state.contactEmail}
                                contactNumber = {this.state.contactNumber}
                                customerType = {this.state.customerType}
                                prevCourier={prevCourier=>{
                                    this.setState({
                                        firstShipmentNo : prevCourier.firstShipmentNo,
                                        gstYes : prevCourier.gstYes,
                                        CustomerDetailsStatus : prevCourier.CustomerDetailsStatus,
                                        showAirTab : prevCourier.showAirTab,
                                        showOceanTab : prevCourier.showOceanTab,
                                        showCourierTab : prevCourier.showCourierTab,
                                        showRoadTab : prevCourier.showRoadTab,
                                        courierQueryForm:false,
                                    },()=>{

                                    })
                                }}
                            />
                            :""}

                            {this.state.roadQueryForm === true ?
                                    <RoadNewQuery
                                        firstShipmentNo ={this.state.prevForAirOceanShipment}
                                        showAirTab = {this.state.showAirTabPrev}
                                        showOceanTab ={this.state.showOceanTabPrev}
                                        showCourierTab = {this.state.showCourierTabPrev}
                                        showRoadTab = {this.state.showRoadTabPrev}
                                        gstYes ={this.state.prevForAirOceangst}
                                        gstNo = {this.state.gstNoStatus}
                                        iecNo = {this.state.iecNoStatus}
                                        CustomerDetailsStatus = {this.state.customerForm}
                                        shipmentFirst = {this.state.shipmentFirst}
                                        iecStatus = {this.state.iecStatus}
                                        gstStatus = {this.state.gstStatus}
                                        chatResponse = {this.state.chatResponse}
                                        typeOfCustomer = {this.state.typeOfCustomer}
                                        contactName = {this.state.contactName}
                                        contactEmail = {this.state.contactEmail}
                                        contactNumber = {this.state.contactNumber}
                                        customerType = {this.state.customerType}
                                        prevRoad={prevRoad=>{
                                            this.setState({
                                                firstShipmentNo : prevRoad.firstShipmentNo,
                                                gstYes : prevRoad.gstYes,
                                                CustomerDetailsStatus : prevRoad.CustomerDetailsStatus,
                                                showAirTab : prevRoad.showAirTab,
                                                showOceanTab : prevRoad.showOceanTab,
                                                showCourierTab : prevRoad.showCourierTab,
                                                showRoadTab : prevRoad.showRoadTab,
                                                roadQueryForm:false,
                                            },()=>{

                                            })
                                        }}
                                    />
                            :""}
                    </div>
                </AppLayout>


            </>
        )}
}

export default exporterPage;

