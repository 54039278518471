import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import App from "./App";
import SimpleReactLightbox from 'simple-react-lightbox'
import "./assets/css/style1.css";
import "./assets/css/media.css";

ReactDOM.render(
  <Provider store={configureStore()}>
    {/*<Suspense fallback={<div className="loading" />}>*/}
      <SimpleReactLightbox>
      <App />
      </SimpleReactLightbox>
    {/*</Suspense>*/}
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
