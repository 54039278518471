import React, {Component, Suspense} from "react";
import AppLayout from "../../../layout/AppLayout";
import {PAGE_PATHS} from "../../../util/PageRoute";
import {history} from "../../../helpers/history";
import FooterHome from "./FooterHome";

import OtherPagesSection from "./OtherPagesSection";
import MetaTags from "react-meta-tags";
import Loader from "../../../components/loader/Loader";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretLeft, faCaretRight, faCaretUp, faTimes} from "@fortawesome/free-solid-svg-icons";

class BannerDesign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            airCargoPopup : true,
            offerCoupan : false,
            width:0,
            height:0
        }

    }
    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    render() {



        return (
            <>
                <MetaTags>

                    <title> Logistics company </title>

                    <meta name="title" content="Logistics company"/>
                    <meta name="description" content="Zipaworld is India’s most unique single window digital logistics company."/>

                </MetaTags>

                <AppLayout>
                    {/*<Modal modalClassName="myModalNew" className="airCargo_modal"*/}
                    {/*       isOpen={this.state.airCargoPopup}*/}
                    {/*       size="lg"*/}
                    {/*       toggle={()=>{this.setState({airCargoPopup:false,newExistPop:true})}}>*/}

                    {/*    <>*/}
                    {/*        <ModalHeader  style={{padding: "0"}}>*/}

                    {/*        </ModalHeader>*/}
                    {/*        <ModalBody style={{padding:'0'}}>*/}
                    {/*            <div className="aircargo_popup offer_coupon text-center">*/}
                    {/*                /!*<video className="" width="100%" preload="auto" autoPlay loop muted>*!/*/}
                    {/*                /!*    <source src="https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/15Aug23.mp4" type="video/mp4"/>*!/*/}
                    {/*                /!*    /!*<source src="https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.36343416663462347-1678171041076.mp4" type="video/mp4"/>*!/*!/*/}
                    {/*                /!*</video>*!/*/}
                    {/*                <video*/}
                    {/*                    loop*/}
                    {/*                    muted*/}
                    {/*                    autoPlay*/}
                    {/*                    playsInline*/}
                    {/*                    preload="metadata"*/}
                    {/*                    width="100%"*/}
                    {/*                >*/}
                    {/*                    <source src="https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/15Aug23.mp4" type="video/mp4"/>*/}
                    {/*                </video>*/}

                    {/*            </div>*/}
                    {/*            <h4 onClick={()=>{this.setState({airCargoPopup:false,newExistPop:true})}}><FontAwesomeIcon icon={faTimes}/> Skip the video</h4>*/}
                    {/*        </ModalBody>*/}
                    {/*    </>*/}
                    {/*</Modal>*/}
                    <div className="NewHomeSections">
                        <div className="queryPageImage1 ocean_freight_banner" >
                            <div className="frontBanner">

                                <div className="position-relative content_front">
                                    <div className="banner_form_text1">
                                        <h1><span>Zipaworld is the best multimodal digital logistics company</span></h1>
                                    </div>
                                    <p>Zipaworld is India’s most unique single window digital logistics company. We call it ‘The Logistics E-Mall of India, a logistics marketplace where you can get all services. What makes Zipaworld so unique and different? Zipaworld is the only platform that offers all logistics services from a single window. Zipaworld offers best freight forwarding services for air freight, ocean freight, road and rail freight along with other multi-modal connectivity integration. Not just this, Zipaworld has been working to enhance user experience of both B2B and B2C segments with the best courier service, express and parcel pick-up and delivery services, customs brokerage services,warehousing solutions, first and last mile connectivity solutions and much more.</p>

                                    <button className="banner_btns" onClick={e=>{
                                        // localStorage.setItem("queryType","OceanQuery")
                                        history.push(PAGE_PATHS.DASHBOARD)
                                        window.location.reload("false")
                                    }}>Explore Rate</button>
                                </div>
                                <div className="position-relative img_front" onClick={e=>{

                                    // localStorage.setItem("queryType","OceanQuery")
                                    history.push(PAGE_PATHS.DASHBOARD)
                                    window.location.reload("false")
                                }}>
                                    <div className="banner_form_text notShowDesk"><h1>Zipaworld is the best multimodal digital logistics company</h1></div>
                                    <img width="100%" height="100%" className="lazyload img-fluid" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5085120722063341-1685791262370.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Suspense fallback={<Loader />}>
                        <OtherPagesSection />
                    </Suspense>
                    <div className="footer-paddingbot">
                        <Suspense fallback={<Loader />}>
                        <FooterHome/>
                        </Suspense>
                    </div>
                    <div className="smallDevice">
                        <div className={this.state.offerCoupan ? "OfferSideBar borderRadiusOffer" : "OfferSideBar"}>
                            <div className="offer-arrow" onClick={()=>{
                                this.setState({
                                    offerCoupan : !this.state.offerCoupan
                                })
                            }}>
                                {this.state.width < 768 ?
                                    this.state.offerCoupan ? <FontAwesomeIcon icon={faCaretDown}/> :
                                        <FontAwesomeIcon icon={faCaretUp}/>
                                    :
                                    this.state.offerCoupan ? <FontAwesomeIcon icon={faCaretRight}/> :
                                        <FontAwesomeIcon icon={faCaretLeft}/>
                                }</div>
                            <div className="offer-sidebar-content">FLAT ₹ 75 OFF</div>
                            {this.state.offerCoupan ?
                                <div className="offerImg" onClick={e=>{
                                    history.push(PAGE_PATHS.OFFER_PAGE_OTHER)
                                    window.location.reload("false")
                                }}> <img src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/images/Courier75Rakhi.png" alt="courier_offer"/></div>
                                :""}
                        </div>

                    </div>

                </AppLayout>
            </>
        )
    }
}
export default BannerDesign;



