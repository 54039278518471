import React, {Component} from "react";
import FooterHome from "./FooterHome";
import AppLayout from "../../../layout/AppLayout";
import MetaTags from "react-meta-tags";
import TopNav from "../../../containers/navs/Topnav";
import {Link} from "react-router-dom";


class  RoadFreight extends Component {
    myDivToFocus = null;
    constructor(props) {
        super(props);
        this.testRef = React.createRef();

    }
    componentDidMount() {
        this.scrollToElement();
    }
    scrollToElement = () => this.testRef.current.scrollIntoView({block: "start", behavior: "smooth"});
    render() {
        return (
    <>

<MetaTags>
    {/*<title> Road Freight Transport And Inland Logistics Services In Noida, Delhi | ZIPAWORLD </title>*/}
    <title>inland transport and road freight</title>
    {/*<meta name="title" content="Road Freight Transport And Inland Logistics Services In Noida, Delhi "/>*/}
    <meta name="title" content="inland transport and road freight"/>
    <meta name="description" content="Zipaworld offer door to door Inland transport and road freight with high quality and cost-effective logistics services."/>
        <meta name="keywords" content="inland transport,inland logistics,road freight transport,road freight,road freight transport services,inland services,road freight services"/>
    </MetaTags>

            <AppLayout>
                {/*<TopNav />*/}
                <div  ref={this.testRef} className="services_my services_content_road">
                    {/*NE-1306*/}
                </div>
                <div className="services_content paraHyperlink" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Inland transport <span>and road freight</span></h1>
                                {/*<p>Zipaworld offers the customers the convenience to book their pickup and drop requirements from door to door, through its website. The customer can avail, port to port, door to door, door to port or port to door costing in one shot from the online platform. There is no hassle of going to various local transporters for pick-up and drop facilities. Alongside, even for domestic movement of goods, Zipaworld offers attractive options.</p>*/}
                                <p>Zipaworld offer door to door Inland transport and road freight with high quality and cost-effective logistics services. <Link to="/">Zipaworld</Link> offers customers the convenience to book their pickup and drop requirements from door to door, through its website. The customer can avail port-to-port, door-to-door, door-to-port, or port-to-door costing in <Link to="/">one shot from the online platform</Link>. There is no hassle of going to various local transporters for pick-up and drop facilities. Alongside, even for domestic movement of goods, Zipaworld offers attractive options.</p>
                                    {/*<p>Zipaworld has professional tie ups with a wide range of our associates for seamless road and rail transportation across India.</p>*/}
                                    <p>Zipaworld has professional tie-ups with a wide range of our associates for seamless road freight transport and rail transportation across India.</p>
                            </div>
                        </div>

                        <div className="row other_image_services">
                            <div className="col-lg-6">
                                <div className="left_service_Air">
                                    <img className="lazyload mobileHidden" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6138331579505243-1681908504334.jpg" alt="Road Freight"/>
                                    <img className="lazyload mobileShown" src="https://aaa2innovate.s3.ap-south-1.amazonaws.com/images/InlandTranportImageMobile.jpg" alt="Road Freight"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/*<p>Moreover, Zipaworld with a strategic alliance with various trade bodies and stakeholders promotes multi-modal transportation for connectivity from and to the remotest manufacturing and supply hubs of the country.Zipaworld ensures a tactical planning of the domestic network management so that supply and demand gap is minimised, mitigating risks based on various geographical and regional factors.</p>*/}
                                <p>Moreover, Zipaworld with a strategic alliance with various trade bodies and stakeholders promotes multi-modal transportation for connectivity from and to the remotest manufacturing and supply hubs of the country. Zipaworld ensures tactical planning of the domestic network management so that the supply and demand gap is minimized, mitigating risks based on various geographical and regional factors.</p>
                                <br/>
                                {/*<p>Zipaworld with the affinity with various bonded and non-bonded road and rail services ensures all kinds of cargo with diverse handling requirements are catered smoothly avoiding undue delays or discrepancies.</p>*/}
                                <p>If you are looking for cost-effective and high-quality road freight transport and inland transport services, Zipaworld is the best choice. Zipaworld with its affinity with various bonded and non-bonded road freight services and rail services ensures all kinds of cargo with diverse handling requirements are catered smoothly avoiding undue delays or discrepancies.</p>
                            </div>
                        </div>



                    </div>
                </div>
                <FooterHome/>
            </AppLayout>
            </>

        );
    }


}



export default RoadFreight;
