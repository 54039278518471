import {
    //get query
    CUSTOMER_MASTER_FETCH_FAILURE,
     CUSTOMER_MASTER_FETCH_MASTER,
    CUSTOMER_MASTER_FETCH_RESPONSE,
    //delete
    CUSTOMER_MASTER_DELETE, 
    CUSTOMER_MASTER_DELETE_RESPONSE,
    //fecth
    REGISTRATION_MASTER_CREATE,
    REGISTRATION_MASTER_CREATE_RESPONSE,
    CUSTOMER_MASTER_BRANCH_CREATE,
    CUSTOMER_MASTER_BRANCH_CREATE_RESPONSE,
    CUSTOMER_MASTER_DETAILS_GET
} from "../actions";

//delete
export const deleteonRegistrationMaster = (originDestinationDetails) => ({
    type: CUSTOMER_MASTER_DELETE,
    payload: originDestinationDetails
});

//delete response
export const deleteonRegistrationMasterResponse = (message) => ({
    type: CUSTOMER_MASTER_DELETE_RESPONSE,
    payload: {message}
});
//on get 
export const onRegistrationMasterLoadRequest = (RegistrationMasterApiParameters) => ({
    type: CUSTOMER_MASTER_FETCH_MASTER,
    payload: RegistrationMasterApiParameters
});
//on get response
export const onRegistrationMasterFetchedResponse = (RegistrationMasterResponse) => ({
    type: CUSTOMER_MASTER_FETCH_RESPONSE,
    payload: RegistrationMasterResponse
});
//on get response failed 
export const onRegistrationMasterFetchedFailed = (message) => ({
    type: CUSTOMER_MASTER_FETCH_FAILURE,
    payload: {message}
});

//on create new RegistrationMaster
export const saveRegistrationMaster = (RegistrationMaster) => ({
    type: REGISTRATION_MASTER_CREATE,
    payload: RegistrationMaster
});
//on createnew master response 
export const saveRegistrationMasterResponse = (message) => ({
    type: REGISTRATION_MASTER_CREATE_RESPONSE,
    payload: {message}
});
// on branch crate new 
export const saveRegistrationMasterBranch = (RegistrationMasterBranch) => ({
    type: CUSTOMER_MASTER_BRANCH_CREATE,
    payload: RegistrationMasterBranch
});
//on branch create respone
export const saveRegistrationMasterBranchResponse = (RegistrationMasterBranch) => ({
    type: CUSTOMER_MASTER_BRANCH_CREATE_RESPONSE,
    payload: RegistrationMasterBranch
});

//get customer Details on id basis section
export const getRegistrationMaster = (RegistrationMasterdata) => ({
    type: CUSTOMER_MASTER_DETAILS_GET,
    payload: RegistrationMasterdata
});