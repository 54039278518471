import axios from 'axios'
import {API_END_POINT_LOCAL} from "../constants";

import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignUp from "../views/user/signUp";
import {PAGE_PATHS} from "./PageRoute";


/**
 * Create an Axios Client with defaults
 */
export let accessToken;
const client = axios.create({
    baseURL: API_END_POINT_LOCAL,
    // headers: {'x-access-token': localStorage.getItem("auth_key")}
});

client.defaults.timeout=10*60*60;
// export client
// export const getAddressIoClient = axios.create({
//     baseURL: ADDRESS_IO_END_POINT_LOCAL,
//     // headers: {'x-access-token': localStorage.getItem("auth_key")}
// });
// export const getAddressH2RClient = axios.create({
//     baseURL: ADDRESS_H2R_END_POINT_LOCAL,
//     // headers: {'x-access-token': localStorage.getItem("auth_key")}
// });

/**
 * Request Wrapper with default success/error actions
 */

export let setToken = (token) => {
    accessToken = token;
    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['authKey'] = `${token}`;
};

const RestClient = function (options, optionalClient) {

    // setToken(localStorage.getItem("auth_key"));
    if (localStorage.getItem("already_registered") == "true") {
        setToken(localStorage.getItem("auth_key"))
    }

    const onSuccess = function (response) {

        if (response.data) {
            return response.data;
        } else {
            return "";
        }
    };

    const onError = function (error) {
        try {
            if (error.response.status== 401) {

                // toast('Your Session Has been Expired ,Please Login to continue.', {
                //     position: "top-left",
                //     autoClose: 3000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });

                // if(error.response.data.message === "jwt expired" || error.response.data.message === "jwt malformed" || error.response.data.message === "jwt must be provided" || error.response.data.message === "invalid signature")
                // {
                    localStorage.clear()
                    window.location.href = window.location.origin +  PAGE_PATHS.DASHBOARD;
                //}

            } else
                if (!error.response.data.success) {
                if (error.response.data.error) {
                    // message.error(error.response.data.error, 10)
                } else if (error.response.data.message) {
                    // message.error(error.response.data.message, 10)
                }
                return error.response.data;
            } else {
                return "";
            }
        } catch (e) {
            // message.error('Server Under Schedule Maintenance, Please try again later', 10);

            return "Something Went Wrong";
        }

    };

    if (optionalClient) {
        return optionalClient(options)
            .then(onSuccess)
            .catch(onError);
    }
    return client(options)
        .then(onSuccess)
        // .then(onUploadProgress)
        .catch(onError);
};

export default RestClient;
