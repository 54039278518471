import React, {Component, Fragment} from "react";
import { Input,  Modal, ModalHeader, ModalBody,} from "reactstrap";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import {GET_FCL_CONTAINER_DATA,GET_ALL_SLAB_RATE_OCEAN} from "../../../constants";
import RestClient from "../../../util/RestClient";
import {convertDateISO, getCapacitorValue} from "../../../util/CommonUtility";
import BookSlabPopupForOcean from "./BookSlabPopupForOcean";

class SlabRatePopupForOcean extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBookButtonClicked : false,
            MobileScreen:true,
            selectedSlab :[
                {
                    isLCLClicked : false,
                    is20StandardClicked : false,
                    is40StandardClicked : false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false,
                }
            ],
            // selectedSlab : false,
            slabValue : '',
            modalLarge1: false,

        };
        this.mandatoryValidations = new SimpleReactValidator({autoForceUpdate: this});
    }


    componentDidMount() {
        if(this.props.ODPDataForOcean){
            this.setState({
                ODPDataForOcean : this.props.ODPDataForOcean
            },()=>{
                if(this.state.ODPDataForOcean && this.state.ODPDataForOcean.length>0){
                    this.state.ODPDataForOcean.map(element => (element.isActive = false ))
                    this.setState({ODPDataForOcean : this.state.ODPDataForOcean,},()=>{
                    })
                }

            })
        }
        this.getAllContainer().then(res=>{
            if(res.success){
                this.setState({
                    allContainerData : res.result
                },()=>{

                })
            }
        })
        if (this.props.modalLarge1) {
            this.setState({
                modalLarge1: this.props.modalLarge1
            })
        }


    }

    async getAllContainer(body) {
        return await RestClient({
            url: GET_FCL_CONTAINER_DATA,
            method: "POST",
            data: body
        });
    }
    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge1: !prevState.modalLarge1
        }),()=>{
            if (this.state.modalLarge1 === false){
                this.props.setmodal(false)
            }
        });
    };

    handleClick = (e,obj) =>{
        e.preventDefault();
        this.setState({
            selectedSlab : !this.state.selectedSlab,
            slabValue:obj
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.selectedSlab !== this.state.selectedSlab){
        }
        if (this.props.modalLarge1 && this.props.modalLarge1 !== prevProps.modalLarge1) {
            this.setState({
                modalLarge1: this.props.modalLarge1
            })
        }
        if(this.props.ODPDataForOcean && this.props.ODPDataForOcean !== prevProps.ODPDataForOcean){
            this.setState({
                ODPDataForOcean : this.props.ODPDataForOcean
            },()=>{
                if(this.state.ODPDataForOcean && this.state.ODPDataForOcean.length>0){
                    this.state.ODPDataForOcean.map(element => (element.isActive = false ))
                    this.setState({ODPDataForOcean : this.state.ODPDataForOcean,},()=>{
                    })
                }

            })
        }

    }

    handleSubmit = (e,obj,i)=>{
        e.preventDefault()
        this.setState({
            selectedSlab : !this.state.selectedSlab,
            slabValue : obj
        })
    }
    // handleSelectedSlab = (e,name,obj,i) =>{
    //     e.preventDefault()
    //     alert(name+'prakhar'+i)
    //     let selectedSlab = [...this.state.selectedSlab]
    //     if(name ==='LCL'){
    //     // selectedSlab[i]={...selectedSlab[i].isLCLClicked= true}
    //     this.state.selectedSlab[i].isLCLClicked = true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='20Standard'){
    //         // selectedSlab[i]={...selectedSlab[i].is20StandardClicked= true}
    //         this.state.selectedSlab[i].is20StandardClicked= true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='40Standard'){
    //         // selectedSlab[i]={...selectedSlab[i].is40StandardClicked= true}
    //         this.state.selectedSlab[i].is40StandardClicked= true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='40HC'){
    //         // selectedSlab[i]={...selectedSlab[i].is40HCClicked= true}
    //         this.state.selectedSlab[i].is40HCClicked= true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='40Reefer'){
    //         // selectedSlab[i]={...selectedSlab[i].is40ReeferClicked = true}
    //         this.state.selectedSlab[i].is40ReeferClicked = true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='20Reefer'){
    //         // selectedSlab[i]={...selectedSlab[i].is20ReeferClicked = true}
    //         this.state.selectedSlab[i].is20ReeferClicked = true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='20OT'){
    //         // selectedSlab[i]={...selectedSlab[i].is20OTClicked = true}
    //         this.state.selectedSlab[i].is20OTClicked = true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='40OT'){
    //         // selectedSlab[i]={...selectedSlab[i].is40OTClicked = true}
    //         this.state.selectedSlab[i].is40OTClicked = true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='20FR'){
    //         // selectedSlab[i]={...selectedSlab[i].is20FRClicked = true}
    //         this.state.selectedSlab[i].is20FRClicked = true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='40FR'){
    //         // selectedSlab[i]={...selectedSlab[i].is40FRClicked = true}
    //         this.state.selectedSlab[i].is40FRClicked = true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }
    //     else if(name ==='45HC'){
    //         // selectedSlab[i]={...selectedSlab[i].is45HCClicked = true}
    //         this.state.selectedSlab[i].is45HCClicked = true
    //         this.setState({selectedSlab},()=>{
    //             console.log('value of 40HCCCCCCCCCCCCCCCCCCCCCCCCC',JSON.stringify(this.state.selectedSlab))
    //         })
    //     }

    // }
    handleSelectedSlab = (e,name,obj,i) =>{
        e.preventDefault()
        this.state.ODPDataForOcean && this.state.ODPDataForOcean.map((data,index)=>{
            if(index === i){
                data.isActive = true
            }else{
                data.isActive = false
            }
        })
        let containerName = name
        this.setState({
            containerName:containerName
        },()=>{
            let containerdata = this.state.allContainerData
            let selectedSlab = [...this.state.selectedSlab]
            if(name ==='LCL'){

                this.setState({
                    isLCLClicked : !this.state.isLCLClicked,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='20 Standard'){
                containerdata.map(ele=>{
                    if(ele.container === '20 Standard' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: !this.state.is20StandardClicked,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='40 Standard'){
                containerdata.map(ele=>{
                    if(ele.container === '40 Standard' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: !this.state.is40StandardClicked,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='40 High Cube'){
                containerdata.map(ele=>{
                    if(ele.container === '40 High Cube' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : !this.state.is40HCClicked,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='40 Reefer'){
                containerdata.map(ele=>{
                    if(ele.container === '40 Reefer' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : !this.state.is40ReeferClicked,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='20 Reefer'){
                containerdata.map(ele=>{
                    if(ele.container === '20 Reefer' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : !this.state.is20ReeferClicked,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='20 Open Top'){
                containerdata.map(ele=>{
                    if(ele.container === '20 Open Top' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : !this.state.is20OTClicked,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='40 Open Top'){
                containerdata.map(ele=>{
                    if(ele.container === '40 Open Top' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked :false,
                    is20OTClicked : false,
                    is40OTClicked :  !this.state.is40OTClicked,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='20 Flat Rack'){
                containerdata.map(ele=>{
                    if(ele.container === '20 Flat Rack' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : !this.state.is20FRClicked,
                    is40FRClicked : false,
                    is45HCClicked : false

                })
            }
            else if(name ==='40 Flat Rack'){
                containerdata.map(ele=>{
                    if(ele.container === '40 Flat Rack' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : !this.state.is40FRClicked,
                    is45HCClicked : false

                })
            }
            else if(name ==='45 High Cube'){
                containerdata.map(ele=>{
                    if(ele.container === '45 High Cube' ){
                        this.setState({
                            slabWeight : ele.maxCargoWeight
                        })
                    }
                })
                this.setState({
                    isLCLClicked : false,
                    is20StandardClicked: false,
                    is40StandardClicked: false,
                    is40HCClicked : false,
                    is40ReeferClicked : false,
                    is20ReeferClicked : false,
                    is20OTClicked : false,
                    is40OTClicked : false,
                    is20FRClicked : false,
                    is40FRClicked : false,
                    is45HCClicked : !this.state.is20ReeferClicked

                })
            }
        })


    }
    handleBook = (e) => {
        e.preventDefault()
        this.setState({
            isBookButtonClicked : true,
            modalLarge : false,

        })

    }
    render() {

        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {this.state.isBookButtonClicked ?
                <BookSlabPopupForOcean
                    modalLarge1 = {this.state.isBookButtonClicked}
                    slabWeight = {this.state.slabWeight}
                    containerName={this.state.containerName}
                    grossSlab = {this.props.grossSlab}
                    volumeSlab = {this.props.volumeSlab}
                    setmodal1={value=>{
                        if(value === false){
                            this.setState({
                                isBookButtonClicked:false,
                                isLCLClicked:false,
                                is20StandardClicked : false,
                                is40StandardClicked : false,
                                is40HCClicked : false,
                                is20ReeferClicked : false,
                                is40ReeferClicked : false,
                                is20OTClicked : false,
                                is40OTClicked : false,
                                is20FRClicked : false,
                                is40FRClicked : false,
                                is45HCClicked : false,
                            },()=>{
                                if(this.state.ODPDataForOcean && this.state.ODPDataForOcean.length>0){
                                    this.state.ODPDataForOcean.map(element => (element.isActive = false ))
                                    this.setState({ODPDataForOcean : this.state.ODPDataForOcean,})
                                }
                            })
                        }else{
                            this.setState({
                                modalLarge1:true
                            })
                        }
                    }}
                /> :""}
                <Modal className="slab-width-ocean"
                       isOpen={this.state.modalLarge1}
                       size="lg"
                       toggle={this.toggleLarge}
                >

                    <>
                        <ModalHeader style={{borderBottom:'1px solid #d7d7d7'}} toggle={this.toggleLarge} className="">
                            {/*<NavLink to={`/`} className=" white">*/}
                            {/*    /!*<span className="logo-single" style={{marginBottom: "-9px"}}/>*!/*/}
                            {/*</NavLink>*/}
                            <h3><strong>All Slab Rates For Ocean</strong></h3>
                        </ModalHeader>
                        <ModalBody className="">
                            <div className="background-white mb-2">

                                <div className="table-responsive">
                                    {this.state.MobileScreen ?
                                        <table className="slab_tables table tablesSlabs">
                                            <thead>
                                            <tr>
                                                <th className="text-center">Container Name</th>
                                                <th className="text-center">Price</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.ODPDataForOcean ? this.state.ODPDataForOcean.map((obj, i) => {

                                                    return (<>

                                                        <tr>
                                                            <td><strong>{obj.containerName? obj.containerName :"LCL"}</strong></td>
                                                            
                                                                {obj.shipmentMode === "LCL" ?
                                                                    <td className={this.state.isLCLClicked ? "select-table active" : 'select-table'}
                                                                        onClick = {(e)=>{
                                                                            this.handleSelectedSlab(e, 'LCL', obj, i)
                                                                        }}
                                                                        >{obj.rate}</td> :""
                                                                    }
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "20 Standard" ?
                                                                    <td className={this.state.is20StandardClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '20 Standard', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "40 Standard" ?
                                                                    <td className={this.state.is40StandardClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '40 Standard', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "40 High Cube" ?
                                                                    <td className={this.state.is40HCClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '40 High Cube', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "40 Reefer" ?
                                                                    <td className={this.state.is40ReeferClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '40 Reefer', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "20 Reefer" ?
                                                                    <td className={this.state.is20ReeferClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '20 Reefer', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "20 Open Top" ?
                                                                    <td className={this.state.is20OTClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '20 Open Top', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "40 Open Top" ?
                                                                    <td className={this.state.is40OTClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '40OT', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "20 Flat Rack" ?
                                                                    <td className={this.state.is20OTClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '20 Flat Rack', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "40 Flat Rack" ?
                                                                    <td className={this.state.is40FRClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '40 Flat Rack', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                {obj.shipmentMode === "FCL" && obj.containerName === "45 High Cube" ?
                                                                    <td className={this.state.is45HCClicked ? "select-table active" : 'select-table'}
                                                                        onClick={e => this.handleSelectedSlab(e, '45 High Cube', obj, i)}>{obj.rate}</td> :
                                                                    ""}
                                                                

                                                                <td>
                                                                {this.props.quoteAvail?
                                                                    this.props.quoteStatus ==="Rates Quoted" ?<button onClick ={e=>this.handleBook(e)} className="btn slab_btns"
                                                                                                                      disabled={(obj.isActive)?false:true}
                                                                    >Book Now</button>:""
                                                                    :<button
                                                                        disabled={(obj.isActive)?false:true}
                                                                        onClick ={e=>this.handleBook(e)} className="btn slab_btns">Book Now</button>}
                                                            </td>

                                                        </tr>
                                                    </>)
                                                })
                                                : []
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        ""
                                        // <table className="table slab_tables table">
                                        //     <thead>
                                        //     <tr>
                                        //         <th className="text-center">LCL</th>
                                        //         {this.state.allContainerData && this.state.allContainerData.map(obj => {
                                        //             return (<th className="text-center">{obj.container}</th>)
                                        //         })}
                                        //         <th className="text-center">Action</th>
                                        //
                                        //
                                        //     </tr>
                                        //     </thead>
                                        //     {
                                        //         this.props.ODPDataForOcean ?
                                        //             this.props.ODPDataForOcean.map((obj, i) => {
                                        //                 // console.log('objjjjjjjjjjjj valuesssssss==================',JSON.stringify(this.state.selectedSlab,i))
                                        //                 return (<>
                                        //                     <tbody>
                                        //                     <tr>
                                        //                         {obj.shipmentMode === "LCL" ?
                                        //                             <td className={this.state.isLCLClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, 'LCL', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "20 Standard" ?
                                        //                             <td className={this.state.is20StandardClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '20Standard', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "40 Standard" ?
                                        //                             <td className={this.state.is40StandardClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '40Standard', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "40 High Cube" ?
                                        //                             <td className={this.state.is40HCClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '40HC', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "40 Reefer" ?
                                        //                             <td className={this.state.is40ReeferClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '40Reefer', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "20 Reefer" ?
                                        //                             <td className={this.state.is20ReeferClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '20Reefer', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "20 Open Top" ?
                                        //                             <td className={this.state.is20OTClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '20OT', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "40 Open Top" ?
                                        //                             <td className={this.state.is40OTClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '40OT', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "20 Flat Rack" ?
                                        //                             <td className={this.state.is20OTClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '20FR', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "40 Flat Rack" ?
                                        //                             <td className={this.state.is40FRClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '40FR', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         {obj.shipmentMode === "FCL" && obj.containerName === "45 High Cube" ?
                                        //                             <td className={this.state.is45HCClicked ? "select-table active" : 'select-table'}
                                        //                                 onClick={e => this.handleSelectedSlab(e, '45HC', obj, i)}>{obj.rate}</td> :
                                        //                             <td>---</td>}
                                        //                         <td>{this.props.quoteAvail?
                                        //                             this.props.quoteStatus ==="Rates Quoted" ?<button onClick ={e=>this.handleBook(e)} className="btn slab_btns">Book Now</button>:""
                                        //                             :<button onClick ={e=>this.handleBook(e)} className="btn slab_btns">Book Now</button>}
                                        //                         </td>
                                        //
                                        //                     </tr>
                                        //                     </tbody>
                                        //                 </>)
                                        //             })
                                        //             : []
                                        //     }
                                        // </table>
                                    }
                                </div>
                            </div>
                        </ModalBody>
                    </>




                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {

};


export default connect(
    mapStateToProps,
    {}
)(SlabRatePopupForOcean);
