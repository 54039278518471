import {
    SHIPMENT_HISTORY_DATA_REQUEST,
    SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS,
    SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_DETAIL_REQUEST,
    SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS,
    SPOT_RATE_UPDATE_REQUEST,
    SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS,

    SPOT_RATE_TICKER_REQUEST,
    SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE,
    SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS,
    MAWB_DATA_SEND_TO_MAWB_VIEW,
    COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS,
    COURIER_SHIPMENT_HISTORY_DATA_REQUEST,
    COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE,SEND_TO_ADD_COPY_ACTION,
} from "../actions";


export const shipmentHistoryDataRequest = (requestParameters) => ({
    type: SHIPMENT_HISTORY_DATA_REQUEST,
    payload: requestParameters
});

export const sendMawbData = (requestParameters) => ({
    type: MAWB_DATA_SEND_TO_MAWB_VIEW,
    payload: requestParameters
});

export const shipmentHistoryDataRequestSuccess = (receivedParameters) => ({
    type: SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS,
    payload: receivedParameters
});
export const shipmentHistoryDataRequestFailure = (message) => ({
    type: SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE,
    payload: {message}
});

export const courierShipmentHistoryDataRequest = (requestParameters) => ({
    type: COURIER_SHIPMENT_HISTORY_DATA_REQUEST,
    payload: requestParameters
});

// export const sendMawbData = (requestParameters) => ({
//     type: MAWB_DATA_SEND_TO_MAWB_VIEW,
//     payload: requestParameters
// });

export const courierShipmentHistoryDataRequestSuccess = (receivedParameters) => ({
    type: COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_SUCCESS,
    payload: receivedParameters
});
export const courierShipmentHistoryDataRequestFailure = (message) => ({
    type: COURIER_SHIPMENT_HISTORY_DATA_REQUEST_RESPONSE_FAILURE,
    payload: {message}
});

// export const onSpotRateDetailRequested = (requestParameters) => ({
//     type: SPOT_RATE_DETAIL_REQUEST,
//     payload: requestParameters
// });
//
// export const onSpotRateDetailRespondBackSuccess = (receivedParameters) => ({
//     type: SPOT_RATE_DETAIL_REQUEST_RESPONSE_SUCCESS,
//     payload: receivedParameters
// });
// export const onSpotRateDetailRespondBackFailure = (message) => ({
//     type: SPOT_RATE_DETAIL_REQUEST_RESPONSE_FAILURE,
//     payload: {message}
// });
//
//
//
// export const onSpotRateUpdateRequested = (requestParameters) => ({
//     type: SPOT_RATE_UPDATE_REQUEST,
//     payload: requestParameters
// });
//
// export const onSpotRateUpdateRespondBackSuccess = (receivedParameters) => ({
//     type: SPOT_RATE_UPDATE_REQUEST_RESPONSE_SUCCESS,
//     payload: receivedParameters
// });
// export const onSpotRateUpdateRespondBackFailure = (message) => ({
//     type: SPOT_RATE_UPDATE_REQUEST_RESPONSE_FAILURE,
//     payload: {message}
// });
//
// export const onSpotRateTickerRequested = (requestParameters) => ({
//     type: SPOT_RATE_TICKER_REQUEST,
//     payload: requestParameters
// });
//
// export const onSpotRateTickerRespondBackSuccess = (receivedParameters) => ({
//     type: SPOT_RATE_TICKER_REQUEST_RESPONSE_SUCCESS,
//     payload: receivedParameters
// });
// export const onSpotRateTickerRespondBackFailure = (message) => ({
//     type: SPOT_RATE_TICKER_REQUEST_RESPONSE_FAILURE,
//     payload: {message}
// });
export const sendToAddCopyAction = (data) => ({
    type: SEND_TO_ADD_COPY_ACTION,
    payload: data,
});
