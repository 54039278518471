import {FormGroup,Label, Row} from "reactstrap";
import React, {Component, Fragment} from "react";
import Select from "react-select";
import {Colxx} from "./common/CustomBootstrap";
import RestClient from "../util/RestClient";
import AsyncPaginate from "react-select-async-paginate";

import {GET_STATE_LIST} from "../constants";


class StateDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            designationMasterData: [],
            isLoading: true,
            response: [],
            error: "",
            selectedDesignation: undefined,
            selectionOptions: [],
            APIRoute: "",
            resultValue: "",
            fromShipperConsignee: false
        };

    }

    loadOptions = async (search, loadedOptions) => {
        try {
            let SearchParameters = {search: search};
            if (SearchParameters.search.length >= 3) {
                const data = await RestClient({
                    url: this.state.APIRoute,
                    method: "POST",
                    data: SearchParameters
                });

                // console.log('pincode data', data, 'loadedoptions', SearchParameters)
                let selectionOriginOptionsList = []
                let dropDownData = data.result.data;
                if (data) {
                    for (let i = 0; i < dropDownData.length; i++) {
                        let {taluk, _id, name, stateCode, abbr, countryId} = dropDownData[i];
                        let obj =
                            {
                                value: _id,
                                label: name,
                                stateCode: stateCode,
                                abbr: abbr,
                                countryId: countryId
                            };

                        selectionOriginOptionsList.push(obj)
                    }
                }
                return {
                    options: selectionOriginOptionsList,
                    hasMore: !search,
                };
            }
        } catch (e) {
            return {
                options: loadedOptions,
                hasMore: false,
            };
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.countryId !== this.props.countryId) {
            let selectedCountryId = this.props.countryId
            //  console.log("countryId" + this.props.countryId)
            let selectionOptionsList = []
            this.reloadState(selectedCountryId)

        }
    }

    reloadState = async (countryId) => {

        let data = await this.fetchState(countryId);
        try {
            if (data.success && data.result !== undefined && data.result.length > 0) {
                //    console.log("In Save state")

                this.setState({
                    stateName: data.result
                }, () => {
                    let selectionOptionsList = []
                    let data = this.state.stateName
                    if (data) {

                        for (let i = 0; i < data.length; i++) {
                            let {_id, name, stateCode} = data[i]
                            let obj =
                                {
                                    value: _id,
                                    label: name,
                                    stateCode: stateCode
                                };
                            selectionOptionsList.push(obj)
                        }
                    }
                    this.setState({
                        selectionOptions: selectionOptionsList
                    });
                });
            }


        } catch (e) {
        }
    };

    fetchState = async (countryId) => {
        {
            return await RestClient({
                url: GET_STATE_LIST,
                method: "POST",
                data: {countryId: countryId}
            });
        }
    };

    componentDidMount() {
        if (this.props.APIRoute && this.props.APIRoute !== undefined) {
            this.setState({APIRoute: this.props.APIRoute})
        }
        if (this.props.fromShipperConsignee === true) {
            this.setState({fromShipperConsignee: true})
            let selectedCountryId = this.props.countryId
            //  console.log("countryId" + this.props.countryId)
            let selectionOptionsList = []
            this.reloadState(selectedCountryId)
        }


    }


    render() {

        try {
            return (
                <Fragment>
                    {this.props.isState ?
                        <FormGroup>
                            <Label  for="from">
                                {this.props.Lable ? this.props.Lable : ""}
                                <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup>
                            </Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="stateName"
                                value={this.state.selectionOptions.filter(option => option.value === this.props.selectedState)}

                                onChange={values => {

                                    this.props.handler({
                                        stateName: values.label,
                                        stateCode: values.stateCode,
                                        stateId: values.value
                                    });
                                }}
                                options={this.state.selectionOptions}
                            />
                        </FormGroup>
                        :
                        <div className="">
                            <Row>

                                {this.state.fromShipperConsignee === true ?
                                    <Colxx xxs="12">
                                        <FormGroup>
                                            <Label  for="from">
                                                {this.props.Lable ? this.props.Lable : ""}
                                                <sup style={{fontSize: '14px', fontWeight: '700'}}>*</sup>
                                            </Label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="stateName"
                                                value={this.state.selectionOptions.filter(option => option.value === this.props.selectedState)}

                                                onChange={values => {

                                                    this.props.handler({
                                                        stateName: values.label,
                                                        stateCode: values.stateCode,
                                                        stateId: values.value
                                                    });
                                                }}
                                                options={this.state.selectionOptions}
                                            />
                                        </FormGroup>
                                    </Colxx>
                                    :
                                    <Colxx xxs="12">
                                        <FormGroup>
                                            <Label for="from">
                                                {this.props.Lable ? this.props.Lable : ""}

                                            </Label>
                                            <AsyncPaginate
                                                {...this.props}
                                                placeholder={this.props.PlaceHolder}
                                                value={this.state.resultValue}
                                                // name="pincodeMapped"
                                                onChange={values => {
                                                    this.setState({resultValue: values})
                                                    this.props.handler({
                                                        resultValue: values
                                                    });

                                                }}

                                                loadOptions={this.loadOptions}
                                                // className="basic-multi-select"
                                                // classNamePrefix="select"
                                            />

                                        </FormGroup>
                                    </Colxx>
                                }


                            </Row>
                        </div>
                    }

                </Fragment>
            );
        } catch (e) {

        }
    }

}


export default (StateDropdown);

