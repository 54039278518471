import React, { Component, Fragment } from "react";
import {faCalendarAlt, faLocationArrow} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class ChatBotQuery extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        return (

            <Fragment>
                <div className="popup-box chat-popup" id="qnimate">
                    <div className="popup-head ">
                        <div className="popup-head-left d-flex flex-wrap align-items-center">
                            <div className="chat_img">
                                <img src="http://bootsnipp.com/img/avatars/bcf1c0d13e5500875fdd5a7e8ad9752ee16e7462.jpg"
                                     alt="iamgurdeeposahan"/>
                            </div>
                            <h5>Ask Zipa</h5>
                        </div>
                    </div>

                    <div className="popup_chat">
                        <div className="d-flex align-items-center text-right justify-content-end  bot_chat">
                            <div className="pr-2">
                                <p className="msg">I am a</p>
                            </div>
                            <div><img src="https://img.icons8.com/color/40/000000/guest-female.png" width="30" className="img1"/></div>
                        </div>
                        <div className="d-flex align-items-center user_chat">
                            <div className="text-left pr-1"><img src="https://i.imgur.com/HpF4BFG.jpg" width="30" className="img1"/></div>
                            <div className="pr-2 pl-1">
                                <p className="msg">Hi Dr. Hendrikson, I haven't been falling well for past few days.</p>
                            </div>
                        </div>

                        <div className="d-flex align-items-center text-right justify-content-end  bot_chat">
                            <div className="pr-2">
                                <p className="msg">Let's jump on a video call</p>
                            </div>
                            <div><img src="https://img.icons8.com/color/40/000000/guest-female.png" width="30" className="img1"/></div>
                        </div>
                        <div className="d-flex align-items-center user_chat">
                            <div className="text-left pr-1"><img src="https://i.imgur.com/HpF4BFG.jpg" width="30" className="img1"/></div>
                            <div className="pr-2 pl-1">
                                <p className="msg">Hi Dr. Hendrikson, I haven't been falling well for past few days.</p>
                            </div>
                        </div>

                        <div className="d-flex align-items-center text-right justify-content-end  bot_chat">
                            <div className="pr-2">
                                <p className="msg">Let's jump on a video call</p>
                            </div>
                            <div><img src="https://img.icons8.com/color/40/000000/guest-female.png" width="30" className="img1"/></div>
                        </div>
                        <div className="d-flex align-items-center user_chat">
                            <div className="text-left pr-1"><img src="https://i.imgur.com/HpF4BFG.jpg" width="30" className="img1"/></div>
                            <div className="pr-2 pl-1">
                                <p className="msg">Hi Dr. Hendrikson, I haven't been falling well for past few days.</p>
                            </div>
                        </div>
                    </div>

                    <div className="popup-messages-footer d-flex flex-wrap align-items-center justify-content-between">
                        <input id="status_message" placeholder="How may I help you?"
                                  name="message"/>
                        <div className="send_chat_message"><FontAwesomeIcon icon={faLocationArrow}/></div>

                    </div>
                </div>


            </Fragment >

        );
    }


}


export default ChatBotQuery;
