import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {ToastContainer} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
class PaymentPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalLarge: false,
            successPop:false,
            failedPop:false,

        };

    }

    componentDidMount() {
        if (this.props.paymentPop) {
            this.setState({
                modalLarge: this.props.paymentPop,
                successPop :this.props.successPop,
                failedPop :this.props.failedPop,
                paymentId :this.props.paymentId
            })
        }

    }

    toggleLarge = () => {
        this.setState(prevState => ({
            modalLarge: !prevState.modalLarge
        }), () => {
            if (this.state.modalLarge === false) {
                this.props.setmodal(false)
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.paymentPop && this.props.paymentPop !== prevProps.paymentPop) {
            this.setState({
                modalLarge: this.props.paymentPop
            })
        }
        if (this.props.paymentId && this.props.paymentId !== prevProps.paymentId) {
            this.setState({
                paymentId: this.props.paymentId
            })
        }



    }

    render() {

        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />


                <Modal className="contactsss sucess_pop"
                       isOpen={this.state.modalLarge}
                       size="md"
                       toggle={this.toggleLarge}
                >

                    <>
                        <ModalHeader toggle={this.toggleLarge} style={{padding: "0"}}>

                        </ModalHeader>
                        <ModalBody className="contact-later1" >

                            {this.props.successPop === false ?
                            <div className="contect-soon">
                                <div className="soon_text payment-texts">
                                    <div className="book-imgs tick-signs" style={{background: "green"}}>
                                        <img src="https://d2ra2wln3jgzfj.cloudfront.net/assets/img/correct.png" alt="Success"/>
                                    </div>
                                    <div className="book-text1">
                                            <h2 style={{fontStyle:'normal'}}> Payment Successful!  </h2>
                                        <p className="payment_id"><strong>Payment Id :</strong> {this.state.paymentId}</p>
                                    </div>
                                </div>
                                <div className="bottom_btn">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <button className="request_btn" onClick={this.toggleLarge}> OK </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                <div className="contect-soon bg-white">
                                    <div className="soon_text payment-texts" onClick={this.toggleLarge}>
                                        <div className="book-imgs tick-signs" style={{background: "red"}}>
                                            <FontAwesomeIcon icon={faTimes}/>
                                        </div>
                                        <div className="book-text1">
                                            <h2 style={{fontStyle:'normal'}}> Payment Failed!  </h2>

                                        </div>
                                    </div>
                                    <div className="bottom_btn bordertop-none" onClick={this.toggleLarge}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button className="request_btn"> Try Again Later </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </ModalBody>
                    </>


                </Modal>
            </>
        );
    }


}

export default PaymentPopUp;
